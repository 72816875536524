import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { flatten, sort } from 'remeda';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { getFirstTaskWithPayload } from '~utilities/getFirstTask';
import * as fromRouterConstants from '../../../app-routing.constants';
var LmoOrdersCalendarDayViewComponent = /** @class */ (function () {
    function LmoOrdersCalendarDayViewComponent(orderService, routerState) {
        this.orderService = orderService;
        this.routerState = routerState;
    }
    LmoOrdersCalendarDayViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.events$ = this.orderService.scheduledDrivers$.pipe(map(function (drivers) { return convertDriversToEvents(drivers); }));
        combineLatest([
            this.routerState.listenForParamChange$(fromRouterConstants.YEAR),
            this.routerState.listenForParamChange$(fromRouterConstants.MONTH),
            this.routerState.listenForParamChange$(fromRouterConstants.DAY),
        ]).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 3), year = _b[0], month = _b[1], date = _b[2];
            _this.viewDate = new Date(+year, +month - 1, +date);
        });
    };
    return LmoOrdersCalendarDayViewComponent;
}());
export { LmoOrdersCalendarDayViewComponent };
var colors = [
    '#6d6683',
    '#cd3e2a',
    '#8a43d5',
    '#497e34',
    '#512993',
    '#886e2f',
    '#bf38a0',
    '#3a4828',
    '#6068c0',
    '#974d34',
    '#3d265f',
    '#c4385f',
    '#497671',
    '#924578',
    '#2b2939',
    '#5b272b',
];
function convertDriversToEvents(fakeDrivers) {
    return flatten(fakeDrivers.map(convertDriverToEvent));
}
function convertDriverToEvent(fakeDriver) {
    return sort(fakeDriver.ordersList, sortOrders).map(function (order, index) {
        var firstTask = getFirstTaskWithPayload(order.taskSummariesList);
        var billingTask = order.taskSummariesList.find(function (task) { return task.isBillable; });
        return {
            color: {
                primary: colors[firstTask.payload ? firstTask.payload.type.id % 16 : 0],
                secondary: colors[firstTask.payload ? firstTask.payload.type.id % 16 : 0],
            },
            end: new Date(order.endUnix * 1000),
            meta: {
                driverId: fakeDriver.tempId,
                endSiteName: billingTask.site.name,
                orderNumber: order.id,
                startSiteName: firstTask.site.name,
            },
            start: new Date(order.startUnix * 1000),
            title: firstTask.payload.name || 'No Payload',
        };
    });
}
function sortOrders(a, b) {
    return a.startUnix - b.startUnix;
}
