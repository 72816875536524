import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-labeled-button',
  styleUrls: ['./labeled-button.component.scss'],
  templateUrl: './labeled-button.component.html',
})
export class LabeledButtonComponent implements OnInit {
  @Input() public automatedSelector: string;
  @Input() public label: string;
  @Input() public text: string;
  @Output() public click = new EventEmitter();

  constructor() {}

  public ngOnInit() {}
}
