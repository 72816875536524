import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BusinessLineService } from '~lmo/services/business-line.service';
import { BusinessLine } from '~proto/businessline/businessline_pb';
import { CreateEmailUserV2Request, CreateUserV2Response } from '~proto/user/user_api_pb';
import { RoleService } from '~services/role.service';
import { trackById } from '~utilities/trackById';
import { UsersService } from '../../services/users.service';
import { UserPermissionsMap } from './../../../../proto/cybertron/types/types_pb.d';

interface FormValue {
  name: string;
  email: string;
  password: string;
  role: 1 | 2 | 3 | 4;
  businessLine: Array<number>;
  permission: Array<0 | 1 | 2>;
}

interface UserRole {
  id: number;
  name: string;
}

@Component({
  selector: 'ct-lmo-user-management-create',
  styleUrls: ['./lmo-user-management-create.component.scss'],
  templateUrl: './lmo-user-management-create.component.html',
})
export class LmoUserManagementCreateComponent implements OnInit {
  public networkActive$ = new BehaviorSubject(false);
  public formGroup: FormGroup;
  public trackById = trackById;
  public hide = true;
  public businessLines$: Observable<BusinessLine.AsObject[]>;
  public isBusinessLineEnabled$$ = new BehaviorSubject<Boolean>(false);
  public userPermissions$: Observable<UserPermissionsMap[keyof UserPermissionsMap][]>;

  public setUserRoles: UserRole[] = [
    { id: 1, name: 'Admin' },
    { id: 2, name: 'Default' },
    { id: 4, name: 'View Only' },
  ];

  public setUserPermissions = {
    1: 'Can Approve Ticket',
    2: 'Can Approve Invoice',
    4: 'Can Create Cost Center',
  };

  public errorMessages = {
    hasRestrictedCharacter: 'only dash, dot, plus, underscore are allowed',
    lessThanTwoCharacter: 'minimum 2 character are required',
    minPasswordLength: 'minimum 6 characters are required',
    nameRequired: '*required',
  };

  public get nameInputValueCheck() {
    const inputVal = this.formGroup.get('name').value || '';
    const inputLength = inputVal.length;
    const isRequired = inputLength === 0 ? this.errorMessages.nameRequired : '_';
    const errorMsg = inputLength > 0 && inputLength < 2 ? this.errorMessages.lessThanTwoCharacter : isRequired;
    return errorMsg;
  }

  public get emailValueCheck() {
    const inputVal = this.formGroup.get('email').value || '';
    const inputLength = inputVal.length;
    const isRequired = inputLength === 0 ? this.errorMessages.nameRequired : '_';
    return isRequired;
  }

  public get passwordValueCheck() {
    const inputVal = this.formGroup.get('password').value || '';
    const inputLength = inputVal.length;
    const isRequired = inputLength === 0 ? this.errorMessages.nameRequired : '_';
    const errorMsg = inputLength > 0 && inputLength < 6 ? this.errorMessages.minPasswordLength : isRequired;
    return errorMsg;
  }

  constructor(
    private fb: FormBuilder,
    private userService: UsersService,
    private router: Router,
    private snackbar: MatSnackBar,
    private businessLineService: BusinessLineService,
    private roleService: RoleService,
  ) {}

  private formValue(): FormValue {
    return this.formGroup.value;
  }

  public ngOnInit() {
    this.formGroup = this.fb.group({
      businessLine: [''],
      email: ['', [Validators.required]],
      name: ['', [Validators.required]],
      password: ['', [Validators.required]],
      permission: [[]],
      role: [[]],
    });
    this.checkIfBusinessLineEnabled();
    if (this.isBusinessLineEnabled$$.value) {
      this.businessLines$ = this.businessLineService.businessLines$;
    }
    this.userPermissions$ = this.userService.userPermissions$;
  }

  public async addUser() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    const formValue = this.formValue();

    this.networkActive$.next(true);
    this.createUser$(formValue)
      .pipe(
        finalize(() => {
          this.networkActive$.next(false);
        }),
      )
      .subscribe(async () => {
        this.snackbar.open(`${formValue.name} created!`, null, {
          duration: 2000,
        });

        await this.router.navigate(['/', 'lmo', 'users']);
      });
  }

  private checkIfBusinessLineEnabled() {
    this.roleService.isBusinessLineEnabled$.subscribe((isBusinessLineEnabled) => {
      this.isBusinessLineEnabled$$.next(isBusinessLineEnabled);
    });
  }

  private createUser$(formValue: FormValue): Observable<CreateUserV2Response.AsObject> {
    if (formValue.role !== 1) {
      formValue.permission = [];
    }
    const createUserRequest = new CreateEmailUserV2Request();
    createUserRequest.setName(formValue.name);
    createUserRequest.setEmail(formValue.email.toLowerCase());
    createUserRequest.setPassword(formValue.password);
    createUserRequest.setRole(formValue.role);
    createUserRequest.setBusinesslineidsList(formValue.businessLine);
    createUserRequest.setPermissionsList(formValue.permission);

    return this.userService.createUser$(createUserRequest);
  }
}
