import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import {
  CreateNewTrailerRequest,
  DeleteTrailersRequest,
  TrailersDetailsRequest,
  TrailersDetailsResponse,
  UpdateTrailersRequest,
} from '~proto/trailer/trailer_api_pb';
import { TrailerAPI } from '~proto/trailer/trailer_api_pb_service';
import { TrailerDetails } from '~proto/trailer/trailer_pb';
import { GrpcService } from '~services/grpc.service';
import { RouterStateService } from '~services/router-state.service';
import * as fromRouterConstants from '../app-routing.constants';

@Injectable({
  providedIn: 'root',
})
export class TrailerService {
  private trailers$$ = new BehaviorSubject<TrailerDetails.AsObject[]>([]);
  private networkActive$$ = new BehaviorSubject<boolean>(false);

  public get trailers$(): Observable<TrailerDetails.AsObject[]> {
    return this.trailers$$.asObservable();
  }

  public get networkActive$(): Observable<boolean> {
    return this.networkActive$$.asObservable();
  }

  public get currentTrailer$(): Observable<TrailerDetails.AsObject> {
    this.getTrailers();
    return combineLatest([
      this.routerState.listenForParamChange$(fromRouterConstants.TRAILER_ID),
      this.trailers$$,
    ]).pipe(map(([trailerId, trailers]) => trailers.find((trailer) => trailer.id === +trailerId)));
  }

  constructor(private grpc: GrpcService, private routerState: RouterStateService) {
    this.getTrailers();
  }

  private getTrailers() {
    if (!this.trailers$$.value || !this.trailers$$.value.length) {
      this.networkActive$$.next(true);
    }
    const trailerReq = new TrailersDetailsRequest();
    this.grpc
      .invoke$(TrailerAPI.ListTrailersWithDetails, trailerReq)
      .pipe(
        finalize(() => {
          this.networkActive$$.next(false);
        }),
      )
      .subscribe((trailerResp: TrailersDetailsResponse) => {
        this.trailers$$.next(trailerResp.toObject().trailersList);
      });
  }

  public addTrailer(createTrailerReq: CreateNewTrailerRequest) {
    return this.grpc.invoke$(TrailerAPI.CreateTrailerWithModel, createTrailerReq).pipe(
      tap((_) => {
        this.getTrailers();
      }),
    );
  }

  public deleteTrailer(deleteTrailerReq: DeleteTrailersRequest) {
    return this.grpc.invoke$(TrailerAPI.DeleteTrailer, deleteTrailerReq).pipe(
      tap((_) => {
        this.getTrailers();
      }),
    );
  }

  public updateTrailer(updateTrailerReq: UpdateTrailersRequest) {
    return this.grpc.invoke$(TrailerAPI.UpdateTrailer, updateTrailerReq).pipe(
      tap((_) => {
        this.getTrailers();
      }),
    );
  }
}
