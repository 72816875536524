/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./versioning.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./versioning.component";
import * as i3 from "@angular/service-worker";
var styles_VersioningComponent = [i0.styles];
var RenderType_VersioningComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VersioningComponent, data: {} });
export { RenderType_VersioningComponent as RenderType_VersioningComponent };
export function View_VersioningComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "version-warning-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" A new version of ShaleApps HAULi is available. Please refresh to get the latest version of the app.\n"]))], null, null); }
export function View_VersioningComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ct-versioning", [], [[8, "className", 0], [2, "wrong-version", null]], null, null, View_VersioningComponent_0, RenderType_VersioningComponent)), i1.ɵdid(1, 114688, null, 0, i2.VersioningComponent, [i3.SwUpdate, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; var currVal_1 = i1.ɵnov(_v, 1).isWrongVersion; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var VersioningComponentNgFactory = i1.ɵccf("ct-versioning", i2.VersioningComponent, View_VersioningComponent_Host_0, {}, {}, []);
export { VersioningComponentNgFactory as VersioningComponentNgFactory };
