import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { PayloadService } from '~lmo/services/payload.service';
import { Payload } from '~proto/payload/payload_pb';
import { fuse } from '~utilities/fuse';
import { trackById } from '~utilities/trackById';

const searchOptions: Fuse.FuseOptions<any> = {
  distance: 100,
  keys: ['name'],
  location: 0,
  maxPatternLength: 16,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.2,
};

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-lmo-payload-management',
  styleUrls: ['./lmo-payload-management.component.scss'],
  templateUrl: './lmo-payload-management.component.html',
})
export class LmoPayloadManagementComponent implements OnInit {
  public payloads$: Observable<Payload.AsObject[]>;
  public payloadSearch: FormControl = new FormControl();
  public trackById = trackById;

  constructor(public payloadService: PayloadService) {}

  public ngOnInit() {
    this.payloads$ = combineLatest([
      this.payloadService.allPayloads$.pipe(map(fuse<Payload.AsObject>(searchOptions))),
      this.payloadSearch.valueChanges.pipe(
        startWith(''),
        debounceTime(200),
      ),
    ]).pipe(
      map(([fused, searchTerm]) => {
        let finalPayloads: Payload.AsObject[];
        if (!searchTerm || searchTerm === '') {
          finalPayloads = fused.data;
        } else {
          finalPayloads = fused.fuse.search(searchTerm);
        }

        return finalPayloads.sort((a, b) => a.name.localeCompare(b.name));
      }),
    );
  }
}
