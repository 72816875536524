import { OnInit } from '@angular/core';
var StockAmountPercentageComponent = /** @class */ (function () {
    function StockAmountPercentageComponent() {
    }
    Object.defineProperty(StockAmountPercentageComponent.prototype, "stock", {
        set: function (stock) {
            if (!stock || !stock.maxQuantity || !stock.quantity) {
                this.decimalPercent = 0;
            }
            else {
                this.decimalPercent = stock.quantity / stock.maxQuantity;
            }
        },
        enumerable: true,
        configurable: true
    });
    StockAmountPercentageComponent.prototype.ngOnInit = function () { };
    StockAmountPercentageComponent.prototype.getFillHeight = function () {
        return Math.min(this.decimalPercent * 100, 100) + "%";
    };
    return StockAmountPercentageComponent;
}());
export { StockAmountPercentageComponent };
