import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, finalize, map, startWith, takeUntil } from 'rxjs/operators';
import { UserV2 } from 'src/proto/user/user/user_pb';
import { BusinessLineService } from '~lmo/services/business-line.service';
import { UsersService } from '~lmo/services/users.service';
import { CreateNewBusinessLineRequest, CreateNewBusinessLineResponse } from '~proto/businessline/businessline_api_pb';
import { CostCenter } from '~proto/order/order_pb';
import { RoleService } from '~services/role.service';
import { trackById } from '~utilities/trackById';
import { CostCenterService } from './../../../billing/services/cost-center.service';

interface FormValue {
  id: number;
  name: string;
  user: Array<string>;
  costCenter: Array<number>;
}

@Component({
  selector: 'ct-lmo-business-line-management-create',
  styleUrls: ['./lmo-business-line-management-create.component.scss'],
  templateUrl: './lmo-business-line-management-create.component.html',
})
export class LmoBusinessLineManagementCreateComponent implements OnInit {
  public networkActive$ = new BehaviorSubject(false);
  public formGroup: FormGroup;
  public hide = true;
  public usersList$: Observable<UserV2.AsObject[]>;
  public costCentersList$: Observable<CostCenter.AsObject[]>;
  public trackById = trackById;
  public userSearch = new FormControl();
  public costCenterSearch = new FormControl();
  public destroy$$ = new Subject();
  public isBusinessLineCostCenterRelationEnabled$$ = new BehaviorSubject<Boolean>(false);

  public errorMessages = {
    lessThanTwoCharacter: 'minimum 2 character are required',
    minPasswordLength: 'minimum 6 characters are required',
    nameRequired: '*required',
  };

  public get nameInputValueCheck() {
    const inputVal = this.formGroup.get('name').value || '';
    const inputLength = inputVal.length;
    const isRequired = inputLength === 0 ? this.errorMessages.nameRequired : '_';
    const errorMsg = inputLength > 0 && inputLength < 2 ? this.errorMessages.lessThanTwoCharacter : isRequired;
    return errorMsg;
  }

  constructor(
    private fb: FormBuilder,
    private businessLineService: BusinessLineService,
    private router: Router,
    private snackbar: MatSnackBar,
    public userService: UsersService,
    private costCenterService: CostCenterService,
    private roleService: RoleService,
  ) {}

  private formValue(): FormValue {
    return this.formGroup.value;
  }

  public ngOnInit() {
    this.formGroup = this.fb.group({
      costCenter: [''],
      name: ['', [Validators.required]],
      user: [''],
    });
    this.usersList$ = combineLatest([
      this.userService.users$.pipe(
        takeUntil(this.destroy$$)
      ),
      this.userSearch.valueChanges.pipe(
        takeUntil(this.destroy$$),
        debounceTime(500),
        startWith(''),
      )
    ]).pipe(
      map(([users, filterText]) => {
        return users.filter((user) => user.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0);
      }),
    );
    this.costCentersList$ = combineLatest([
      this.costCenterService.costCenters$.pipe(
        takeUntil(this.destroy$$)
      ),
      this.costCenterSearch.valueChanges.pipe(
        takeUntil(this.destroy$$),
        debounceTime(500),
        startWith(''),
      )
    ]).pipe(
      map(([costCenters, filterText]) => {
        return costCenters.filter((costCenter) => costCenter.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0);
      }),
    );
    this.checkIsBusinessLineCostCenterRelationEnabled();
  }

  public async addBusinessLine() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    const formValue = this.formValue();

    this.networkActive$.next(true);
    this.createBusinessLine$(formValue)
      .pipe(
        finalize(() => {
          this.networkActive$.next(false);
        }),
      )
      .subscribe(async () => {
        this.snackbar.open(`${formValue.name} created!`, null, {
          duration: 2000,
        });

        await this.router.navigate(['/', 'lmo', 'business-lines']);
      });
  }

  private checkIsBusinessLineCostCenterRelationEnabled() {
    this.roleService.isBusinessLineCostCenterRelationEnabled$.subscribe((isBLCCRelationEnabled: Boolean) => {
      this.isBusinessLineCostCenterRelationEnabled$$.next(isBLCCRelationEnabled);
    });
  }

  private createBusinessLine$(formValue: FormValue): Observable<CreateNewBusinessLineResponse.AsObject> {
    const createBusinessLineRequest = new CreateNewBusinessLineRequest();
    createBusinessLineRequest.setName(formValue.name);
    createBusinessLineRequest.setUserIdsList(formValue.user);
    if (this.isBusinessLineCostCenterRelationEnabled$$.value) {
      createBusinessLineRequest.setCostCenterIdsList(formValue.costCenter);
    }

    return this.businessLineService.createBusinessLine$(createBusinessLineRequest);
  }
}
