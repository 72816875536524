import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'ct-confirm-save-dialogue',
  styleUrls: ['./confirm-save-dialogue.component.scss'],
  templateUrl: './confirm-save-dialogue.component.html',
})
export class ConfirmSaveDialogueComponent implements OnInit {
  constructor(private matDialogRef: MatDialogRef<ConfirmSaveDialogueComponent>) {}

  public ngOnInit() {}
  public cancel() {
    this.matDialogRef.close();
  }
}
