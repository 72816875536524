import { ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { pluck, startWith, takeUntil, tap } from 'rxjs/operators';
var InputDirective = /** @class */ (function () {
    function InputDirective(renderer, el, control) {
        var _this = this;
        this.renderer = renderer;
        this.el = el;
        this.control = control;
        this.destroy$$ = new Subject();
        this.text$$ = new BehaviorSubject(null);
        this.enableAutocomplete = false;
        this.text$.pipe(takeUntil(this.destroy$$)).subscribe(function (value) {
            if (!value ||
                (typeof value === 'string' && value.trim() === '' && _this.el.nativeElement.classList.contains('not-empty'))) {
                _this.renderer.removeClass(_this.el.nativeElement, 'not-empty');
            }
            else if (!_this.el.nativeElement.classList.contains('not-empty')) {
                _this.renderer.addClass(_this.el.nativeElement, 'not-empty');
            }
        });
    }
    Object.defineProperty(InputDirective.prototype, "text$", {
        get: function () {
            return this.text$$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    InputDirective.prototype.ngOnInit = function () {
        var _this = this;
        if (this.enableAutocomplete === false) {
            this.renderer.setProperty(this.el.nativeElement, 'autocomplete', 'on');
        }
        if (this.control && this.control.valueChanges) {
            this.control.valueChanges
                .pipe(startWith(this.control.value))
                .subscribe(function (change) { return _this.text$$.next(change); });
        }
        else {
            fromEvent(this.el.nativeElement, 'input')
                .pipe(tap(function (event) {
                event.preventDefault();
                event.stopImmediatePropagation();
                event.stopPropagation();
            }), pluck('target', 'value'), takeUntil(this.destroy$$))
                .subscribe(function (value) {
                _this.text$$.next(value);
            });
        }
    };
    InputDirective.prototype.ngOnDestroy = function () {
        this.destroy$$.next();
        this.destroy$$.unsubscribe();
    };
    return InputDirective;
}());
export { InputDirective };
