import { differenceInMinutes } from 'date-fns';
import {
  BackHaulRequest,
  CreateOrderRequest,
  CreateSubTaskRequest,
  CreateTaskRequest,
} from '~proto/order/order_api_pb';
import { Payload } from '~proto/payload/payload_pb';
import { Site } from '~proto/site/site_pb';
import { Trailer } from '~proto/trailer/trailer_pb';
import { Truck } from '~proto/truck/truck_pb';
import { IdName, TaskType, TaskTypeMap } from '~proto/types/types_pb';

export interface CreateOrderFormValue {
  description: string;
  dropoffSite: Site.AsObject;
  loads: number;
  orderNumber: string;
  payload: Payload.AsObject;
  pickupFrom: Site.AsObject;
  purchaseOrderName: string;
  quantity: number;
  scheduledDeliveryWindow: [Date, Date];
  isAsapOrder: boolean;
  trailer?: Trailer.AsObject;
  trailerPickupLocation?: Site.AsObject;
  trailerDropoffLocation?: Site.AsObject;
  truck?: Truck.AsObject;
  truckPickupLocation?: Site.AsObject;
  truckDropoffLocation?: Site.AsObject;
  truckingVendor?: IdName.AsObject;
  costCenter?: string;
  vendorContractId: number;
  contractTypeId: number;
  costCenterId: number;
  businessLine: number;
  maintenanceOrderNumber: string;
}

export type CustomOrderFormValue = Pick<
  CreateOrderFormValue,
  | 'description'
  | 'loads'
  | 'orderNumber'
  | 'purchaseOrderName'
  | 'scheduledDeliveryWindow'
  | 'isAsapOrder'
  | 'truckingVendor'
  | 'trailerPickupLocation'
  | 'trailerDropoffLocation'
  | 'costCenter'
  | 'vendorContractId'
  | 'contractTypeId'
  | 'costCenterId'
  | 'businessLine'
  | 'maintenanceOrderNumber'
>;
type withOptionalTruckAndTrailer = Partial<Pick<CreateOrderFormValue, 'truck' | 'trailer'>>;

export interface CustomStop {
  assetNumber: Trailer.AsObject;
  location: Site.AsObject;
  payload: Payload.AsObject;
  quantity: number;
  stopType: TaskTypeMap[keyof TaskTypeMap];
  isBillable: boolean;
  salesOrderNumber: string;
  purchaseOrderName: string;
  sequence?: number;
  maintenanceOrderNumber: string;
}

export function createOrderRequestFactory(formValue: CreateOrderFormValue): CreateOrderRequest {
  const createOrderRequest = getBasicCreateOrderRequest(formValue);
  addRegularTasksToOrderRequest(createOrderRequest, formValue);
  return makeLastDeliveryTaskBillable(flattenTasks(createOrderRequest));
}

export function createCustomOrderRequestFactory(
  formValue: CustomOrderFormValue,
  customStops: CustomStop[],
): CreateOrderRequest {
  const createOrderRequest = getBasicCreateOrderRequest(formValue);
  if (formValue.trailerPickupLocation) {
    createOrderRequest.addTaskRequests(createTrailerPickupTask(formValue));
  }
  addCustomTasksToOrderRequest(createOrderRequest, customStops);
  if (formValue.trailerDropoffLocation) {
    createOrderRequest.addTaskRequests(createTrailerDropoffTask(formValue));
  }
  return makeLastDeliveryTaskBillable(flattenTasks(createOrderRequest));
}

function getBasicCreateOrderRequest(formValue: CustomOrderFormValue & withOptionalTruckAndTrailer): CreateOrderRequest {
  const createOrderRequest = new CreateOrderRequest();
  createOrderRequest.setOrderQuantity(formValue.loads);
  createOrderRequest.setPurchaseOrderName(formValue.purchaseOrderName);
  createOrderRequest.setSalesOrderNumber(formValue.orderNumber);
  createOrderRequest.setIsAsapOrder(formValue.isAsapOrder);
  createOrderRequest.setDescription(formValue.description);
  createOrderRequest.setCostCenter(formValue.costCenter);
  createOrderRequest.setVendorContractId(formValue.vendorContractId);
  createOrderRequest.setContractTypeId(formValue.contractTypeId);
  createOrderRequest.setCostCenterId(formValue.costCenterId);
  createOrderRequest.setBusinessLineId(formValue.businessLine);
  if (formValue.scheduledDeliveryWindow && formValue.scheduledDeliveryWindow.length) {
    differenceInMinutes(formValue.scheduledDeliveryWindow[1], formValue.scheduledDeliveryWindow[0]);
    createOrderRequest.setDeliveryWindowStart(Math.round(formValue.scheduledDeliveryWindow[0].getTime() / 1000));
    createOrderRequest.setDeliveryWindowDurationMinutes(
      differenceInMinutes(formValue.scheduledDeliveryWindow[1], formValue.scheduledDeliveryWindow[0]),
    );
  }

  if (formValue.trailer) {
    createOrderRequest.setTrailerId(formValue.trailer.id);
  }

  if (formValue.truck) {
    createOrderRequest.setTruckId(formValue.truck.id);
  }

  if (formValue.truckingVendor) {
    createOrderRequest.setTruckingVendorId(formValue.truckingVendor.id);
  }
  return createOrderRequest;
}

export function createTruckPickupTask(formValue: Pick<CreateOrderFormValue, 'truckPickupLocation'>): CreateTaskRequest {
  const pickupTruckTask = new CreateTaskRequest();
  pickupTruckTask.setDescription(formValue.truckPickupLocation.directions);
  pickupTruckTask.setSiteId(formValue.truckPickupLocation.id);
  const pickupTruckSubTask = new CreateSubTaskRequest();
  pickupTruckSubTask.setTypeName('pickup-truck');
  pickupTruckTask.addCreateSubTaskRequest(pickupTruckSubTask);
  return pickupTruckTask;
}

export function createTruckDropoffTask(
  formValue: Pick<CreateOrderFormValue, 'truckDropoffLocation'>,
): CreateTaskRequest {
  const dropoffTruckTask = new CreateTaskRequest();
  dropoffTruckTask.setDescription(formValue.truckDropoffLocation.directions);
  dropoffTruckTask.setSiteId(formValue.truckDropoffLocation.id);
  const dropoffTruckSubTask = new CreateSubTaskRequest();
  dropoffTruckSubTask.setTypeName('dropoff-truck');
  dropoffTruckTask.addCreateSubTaskRequest(dropoffTruckSubTask);
  return dropoffTruckTask;
}

export function createTrailerPickupTask(
  formValue: Pick<CreateOrderFormValue, 'trailerPickupLocation'>,
): CreateTaskRequest {
  const pickupTrailerTask = new CreateTaskRequest();
  pickupTrailerTask.setDescription(formValue.trailerPickupLocation.directions);
  pickupTrailerTask.setSiteId(formValue.trailerPickupLocation.id);
  const pickupTrailerSubTask = new CreateSubTaskRequest();
  pickupTrailerSubTask.setTypeName('pickup-trailer');
  pickupTrailerTask.addCreateSubTaskRequest(pickupTrailerSubTask);
  return pickupTrailerTask;
}

export function createTrailerDropoffTask(
  formValue: Pick<CreateOrderFormValue, 'trailerDropoffLocation'>,
): CreateTaskRequest {
  const dropoffTrailerTask = new CreateTaskRequest();
  dropoffTrailerTask.setDescription(formValue.trailerDropoffLocation.directions);
  dropoffTrailerTask.setSiteId(formValue.trailerDropoffLocation.id);
  const dropoffTrailerSubTask = new CreateSubTaskRequest();
  dropoffTrailerSubTask.setTypeName('dropoff-trailer');
  dropoffTrailerTask.addCreateSubTaskRequest(dropoffTrailerSubTask);
  return dropoffTrailerTask;
}

export function createPayloadPickupTask(
  formValue: Pick<CreateOrderFormValue, 'payload' | 'pickupFrom' | 'quantity' | 'purchaseOrderName' | 'orderNumber' | 'maintenanceOrderNumber'>,
): CreateTaskRequest {
  const pickupPayloadTask = new CreateTaskRequest();
  pickupPayloadTask.setDescription(formValue.pickupFrom.directions);
  pickupPayloadTask.setSiteId(formValue.pickupFrom.id);
  const pickupPayloadSubTask = new CreateSubTaskRequest();
  pickupPayloadSubTask.setOrderedQuantity(formValue.quantity);
  pickupPayloadSubTask.setPayloadId(formValue.payload.id);
  pickupPayloadSubTask.setTypeName('pickup-payload');
  pickupPayloadSubTask.setPurchaseOrderName(formValue.purchaseOrderName);
  pickupPayloadSubTask.setSalesOrderNumber(formValue.orderNumber);
  pickupPayloadSubTask.setMaintenanceOrderNumber(formValue.maintenanceOrderNumber)
  pickupPayloadTask.addCreateSubTaskRequest(pickupPayloadSubTask);
  return pickupPayloadTask;
}

export function createPayloadDropoffTask(
  formValue: Pick<CreateOrderFormValue, 'payload' | 'dropoffSite' | 'quantity' | 'purchaseOrderName' | 'orderNumber' | 'maintenanceOrderNumber'>,
): CreateTaskRequest {
  const dropoffPayloadTask = new CreateTaskRequest();
  dropoffPayloadTask.setDescription(formValue.dropoffSite.directions);
  dropoffPayloadTask.setSiteId(formValue.dropoffSite.id);
  const dropoffPayloadSubTask = new CreateSubTaskRequest();
  dropoffPayloadSubTask.setOrderedQuantity(formValue.quantity);
  dropoffPayloadSubTask.setPayloadId(formValue.payload.id);
  dropoffPayloadSubTask.setTypeName('dropoff-payload');
  dropoffPayloadSubTask.setPurchaseOrderName(formValue.purchaseOrderName);
  dropoffPayloadSubTask.setSalesOrderNumber(formValue.orderNumber);
  dropoffPayloadSubTask.setMaintenanceOrderNumber(formValue.maintenanceOrderNumber)
  dropoffPayloadTask.addCreateSubTaskRequest(dropoffPayloadSubTask);
  return dropoffPayloadTask;
}

function addRegularTasksToOrderRequest(createOrderRequest: CreateOrderRequest, formValue: CreateOrderFormValue) {
  // Task setup
  if (formValue.truckPickupLocation && formValue.truck) {
    createOrderRequest.addTaskRequests(createTruckPickupTask(formValue));
  }
  if (formValue.trailerPickupLocation && formValue.trailer) {
    createOrderRequest.addTaskRequests(createTrailerPickupTask(formValue));
  }

  createOrderRequest.addTaskRequests(createPayloadPickupTask(formValue));
  createOrderRequest.addTaskRequests(createPayloadDropoffTask(formValue));

  if (formValue.trailerDropoffLocation && formValue.trailer) {
    createOrderRequest.addTaskRequests(createTrailerDropoffTask(formValue));
  }

  if (formValue.truckDropoffLocation && formValue.truck) {
    createOrderRequest.addTaskRequests(createTruckDropoffTask(formValue));
  }
}

function addCustomTasksToOrderRequest(request: CreateOrderRequest, customStops: CustomStop[]): void {
  customStops.forEach((stop) => {
    if (stop.stopType === TaskType.TASK_TYPE_PICKUP) {
      request.addTaskRequests(
        createPayloadPickupTask({
          maintenanceOrderNumber: stop.maintenanceOrderNumber,
          orderNumber: stop.salesOrderNumber,
          payload: stop.payload,
          pickupFrom: stop.location,
          purchaseOrderName: stop.purchaseOrderName,
          quantity: stop.quantity,
        }),
      );
    } else if (stop.stopType === TaskType.TASK_TYPE_DROPOFF) {
      request.addTaskRequests(
        createPayloadDropoffTask({
          dropoffSite: stop.location,
          maintenanceOrderNumber: stop.maintenanceOrderNumber,
          orderNumber: stop.salesOrderNumber,
          payload: stop.payload,
          purchaseOrderName: stop.purchaseOrderName,
          quantity: stop.quantity,
        }),
      );
    }
  });
}

export function flattenTasks(createOrderRequest: CreateOrderRequest): CreateOrderRequest {
  const clone = createOrderRequest.clone() as CreateOrderRequest;
  const flattenedTasks = clone.getTaskRequestsList().reduce((tasks: CreateTaskRequest[], task: CreateTaskRequest) => {
    // First task
    if (!tasks.length) {
      tasks.push(task);
    } else {
      const currentTask = tasks[tasks.length - 1];
      // Same site and right next to each other so merge subTasks
      if (currentTask.getSiteId() === task.getSiteId()) {
        const subTasksToAdd = task.getCreateSubTaskRequestList();
        subTasksToAdd.forEach((subTask) => {
          currentTask.addCreateSubTaskRequest(subTask);
        });
        if (task.getIsBillable()) {
          currentTask.setIsBillable(true);
        }
      } else {
        // They do not share the same site ID so this task is now the current task
        tasks.push(task);
      }
    }
    return tasks;
  }, []);
  flattenedTasks.forEach((task, index) => {
    task.setSequence(index + 1);
  });
  clone.setTaskRequestsList(flattenedTasks);
  return clone;
}

export function makeLastDeliveryTaskBillable(createOrderRequest: CreateOrderRequest): CreateOrderRequest {
  const clone = createOrderRequest.clone() as CreateOrderRequest;
  const lastTaskWithPayloadDeliveryIndex = findLastIndex(clone.getTaskRequestsList(), (task) =>
    task.getCreateSubTaskRequestList().some((subTask) => subTask.getTypeName() === 'dropoff-payload'),
  );
  if (lastTaskWithPayloadDeliveryIndex >= 0) {
    const lastTaskWithDelivery = clone
      .getTaskRequestsList()
      [lastTaskWithPayloadDeliveryIndex].clone() as CreateTaskRequest;
    if (lastTaskWithDelivery) {
      lastTaskWithDelivery.setIsBillable(true);
      const requests = [...clone.getTaskRequestsList()];
      requests[lastTaskWithPayloadDeliveryIndex] = lastTaskWithDelivery;
      clone.setTaskRequestsList(requests);
    }
  }
  return clone;
}

type SavedFormValues = Pick<
  CreateOrderFormValue,
  'loads' | 'orderNumber' | 'payload' | 'pickupFrom' | 'purchaseOrderName' | 'quantity' | 'truckingVendor' | 'maintenanceOrderNumber'
>;

export function saveFormToLocalStorage(formValue: SavedFormValues, siteId: number, storageKey: string) {
  const savedSettings: SavedFormValues = {
    loads: formValue.loads,
    maintenanceOrderNumber: formValue.maintenanceOrderNumber,
    orderNumber: formValue.orderNumber,
    payload: formValue.payload,
    pickupFrom: formValue.pickupFrom,
    purchaseOrderName: formValue.purchaseOrderName,
    quantity: formValue.quantity,
    truckingVendor: formValue.truckingVendor,
  };
  const existingSavedSettings = JSON.parse(localStorage.getItem(storageKey)) || {};
  localStorage.setItem(
    storageKey,
    JSON.stringify({
      ...existingSavedSettings,
      [siteId]: savedSettings,
    }),
  );
}

export function getFormFromLocalStorage(siteId: number, storageKey: string): SavedFormValues {
  const saved = JSON.parse(localStorage.getItem(storageKey)) as SavedFormValues;
  if (!saved || !saved[siteId]) {
    return {
      loads: null,
      maintenanceOrderNumber: null,
      orderNumber: null,
      payload: null,
      pickupFrom: null,
      purchaseOrderName: null,
      quantity: null,
      truckingVendor: null,
    };
  }
  return saved[siteId];
}

function findLastIndex<T>(array: T[], comparator: (t: T) => boolean): number {
  for (let i = array.length - 1; i >= 0; i--) {
    if (comparator(array[i])) {
      return i;
    }
  }
  return -1;
}

export function addCustomTasksToBackhaulRequest(request: BackHaulRequest, customStops: CustomStop[]): void {
  customStops.forEach((stop) => {
    if (stop.stopType === TaskType.TASK_TYPE_PICKUP) {
      request.addTaskRequests(
        createPayloadPickupTask({
          maintenanceOrderNumber: stop.maintenanceOrderNumber,
          orderNumber: stop.salesOrderNumber,
          payload: stop.payload,
          pickupFrom: stop.location,
          purchaseOrderName: stop.purchaseOrderName,
          quantity: stop.quantity,
        }),
      );
    } else if (stop.stopType === TaskType.TASK_TYPE_DROPOFF) {
      request.addTaskRequests(
        createPayloadDropoffTask({
          dropoffSite: stop.location,
          maintenanceOrderNumber: stop.maintenanceOrderNumber,
          orderNumber: stop.salesOrderNumber,
          payload: stop.payload,
          purchaseOrderName: stop.purchaseOrderName,
          quantity: stop.quantity,
        }),
      );
    }
  });
}
