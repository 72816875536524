import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnInit, } from '@angular/core';
import { Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize } from 'rxjs/operators';
import { SelectBusinessHoursComponent } from '~common/select-business-hours/select-business-hours.component';
import { CreateSiteRequest } from '~proto/site/site_api_pb';
import { createCircle } from '~utilities/createCircle';
import { trackById } from '~utilities/trackById';
// const metersInAYard = 0.914;
var metersInAMile = 1609;
var CreateJobComponent = /** @class */ (function () {
    function CreateJobComponent(fb, router, jobSitesService, driverCertificationService, lmoDistrictService, dialog, matDialogRef, matSnackbar) {
        this.fb = fb;
        this.router = router;
        this.jobSitesService = jobSitesService;
        this.driverCertificationService = driverCertificationService;
        this.lmoDistrictService = lmoDistrictService;
        this.dialog = dialog;
        this.matDialogRef = matDialogRef;
        this.matSnackbar = matSnackbar;
        this.networkActive$$ = new BehaviorSubject(false);
        this.trackById = trackById;
        this.businessHours = [];
        this.modalInstance = false;
        this.weekMap = {
            0: 'MONDAY',
            1: 'TUESDAY',
            2: 'WEDNESDAY',
            3: 'THURSDAY',
            4: 'FRIDAY',
            5: 'SATURDAY',
            6: 'SUNDAY',
        };
    }
    Object.defineProperty(CreateJobComponent.prototype, "radius", {
        get: function () {
            return this.createForm.get('radius');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateJobComponent.prototype, "latitude", {
        get: function () {
            return this.createForm.get('latitude');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateJobComponent.prototype, "longitude", {
        get: function () {
            return this.createForm.get('longitude');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateJobComponent.prototype, "formValue", {
        get: function () {
            return this.createForm.value;
        },
        enumerable: true,
        configurable: true
    });
    CreateJobComponent.prototype.ngOnInit = function () {
        this.driverCertifications$ = this.driverCertificationService.driverCertifications$;
        this.lmoDistricts$ = this.lmoDistrictService.lmoDistricts$;
        this.createForm = this.fb.group({
            addToPinnedJobs: [false, []],
            address: ['', []],
            businessHours: ['', []],
            direction: ['', []],
            districtId: [null, []],
            driverCertificationIds: [[]],
            latitude: [39.7392, [Validators.required]],
            longitude: [-104.9903, [Validators.required]],
            name: [this.jobName, [Validators.required]],
            radius: [1, [Validators.required, Validators.min(0.02)]],
            type: ['well', [Validators.required]],
        });
        this.radius.valueChanges
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(this.handleRadiusInputChanges.bind(this));
    };
    CreateJobComponent.prototype.ngAfterViewInit = function () {
        this.initMap();
    };
    CreateJobComponent.prototype.editBusinessHours = function () {
        var _this = this;
        var dialogRef = this.dialog.open(SelectBusinessHoursComponent, {
            data: this.businessHours,
        });
        dialogRef
            .afterClosed()
            .pipe(filter(function (value) { return !!value && value.length; }))
            .subscribe(function (value) {
            _this.businessHours = tslib_1.__spread(value);
            var hoursValue = '';
            value.forEach(function (day) {
                hoursValue += "" + (hoursValue !== '' ? ', ' : '') + _this.weekMap[day.getDay() - 1] + "(" + day.getOpeningTime() + " - " + day.getClosingTime() + ")";
            });
            _this.createForm.controls['businessHours'].setValue(hoursValue);
        });
    };
    CreateJobComponent.prototype.initMap = function () {
        if (this.map == null) {
            var center = {
                lat: this.latitude.value,
                lng: this.longitude.value,
            };
            var mapConfig = {
                center: center,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    position: 3,
                },
                mapTypeId: google.maps.MapTypeId.HYBRID,
                streetViewControl: false,
                // styles: <any>LightTheme,
                zoom: 12,
                zoomControlOptions: {
                    position: 3,
                },
            };
            if (this.mapElement != null) {
                this.map = new google.maps.Map(this.mapElement.nativeElement, mapConfig);
                this.mineMarker = new google.maps.Marker({
                    animation: google.maps.Animation.DROP,
                    draggable: true,
                    map: this.map,
                    position: this.map.getCenter(),
                });
                this.geofence = new google.maps.Circle({
                    center: this.map.getCenter(),
                    editable: true,
                    fillColor: '#00FF00',
                    fillOpacity: 0.35,
                    map: this.map,
                    radius: this.radius.value * metersInAMile,
                    strokeColor: '#00FF00',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    zIndex: 10,
                });
                this.geofence.bindTo('center', this.mineMarker, 'position');
                google.maps.event.addListener(this.mineMarker, 'position_changed', this.handleMineMarkerMove.bind(this));
                google.maps.event.addListener(this.geofence, 'radius_changed', this.handleGeofenceRadiusChange.bind(this));
            }
        }
    };
    CreateJobComponent.prototype.createJob = function () {
        var _this = this;
        this.createForm.markAllAsTouched();
        if (this.createForm.invalid) {
            return;
        }
        var formValue = this.formValue;
        if (formValue.name.replace(/\s/g, "") === "") {
            this.matSnackbar.open('Site name cannot be empty', null, { duration: 2000 });
            return;
        }
        this.networkActive$$.next(true);
        var createSiteRequest = new CreateSiteRequest();
        var circle = createCircle(formValue.latitude, formValue.longitude, formValue.radius);
        createSiteRequest.setGeofence(circle);
        createSiteRequest.setName(formValue.name);
        createSiteRequest.setDirections('');
        createSiteRequest.setSiteType(formValue.type);
        createSiteRequest.setDirections(formValue.direction);
        createSiteRequest.setFavorited(formValue.addToPinnedJobs);
        createSiteRequest.setAddress(formValue.address);
        createSiteRequest.setDriverCertificationIdsList(formValue.driverCertificationIds);
        if (formValue.districtId) {
            createSiteRequest.setDistrictId(formValue.districtId);
        }
        if (this.createForm.get('type').value !== 'well') {
            createSiteRequest.setAvailabledurationsList(this.businessHours);
        }
        this.jobSitesService
            .createSite$(createSiteRequest)
            .pipe(finalize(function () {
            _this.networkActive$$.next(false);
        }))
            .subscribe(function (site) {
            if (_this.modalInstance) {
                _this.matDialogRef.close(site);
            }
            else {
                _this.router.navigate(['/', 'lmo', 'jobs', site.id]);
            }
        });
    };
    CreateJobComponent.prototype.centerMarkerOnMap = function () {
        var currentMapCenter = this.map.getCenter();
        this.mineMarker.setPosition({
            lat: currentMapCenter.lat(),
            lng: currentMapCenter.lng(),
        });
    };
    CreateJobComponent.prototype.centerMapOnMarker = function () {
        var currentMarkerPosition = this.mineMarker.getPosition();
        this.map.setCenter(currentMarkerPosition);
    };
    CreateJobComponent.prototype.handleLatitudeInputChange = function () {
        var latitude = this.formValue.latitude;
        var currentPosition = this.mineMarker.getPosition();
        this.mineMarker.setPosition({ lat: +latitude, lng: currentPosition.lng() });
        this.centerMapOnMarker();
    };
    CreateJobComponent.prototype.handleLongitudeInputChange = function () {
        var longitude = this.formValue.longitude;
        var currentPosition = this.mineMarker.getPosition();
        this.mineMarker.setPosition({ lng: +longitude, lat: currentPosition.lat() });
        this.centerMapOnMarker();
    };
    CreateJobComponent.prototype.handleMineMarkerMove = function () {
        var newPosition = this.mineMarker.getPosition();
        this.latitude.setValue(newPosition.lat(), { emitEvent: false });
        this.longitude.setValue(newPosition.lng(), { emitEvent: false });
    };
    CreateJobComponent.prototype.handleGeofenceRadiusChange = function () {
        var newRadius = this.geofence.getRadius() / metersInAMile;
        if (newRadius <= 10) {
            this.radius.setValue(parseFloat(newRadius.toFixed(2)), { emitEvent: false });
        }
        else {
            this.geofence.setRadius(10 * metersInAMile);
        }
    };
    CreateJobComponent.prototype.handleRadiusInputChanges = function (radius) {
        this.geofence.setRadius(+radius * metersInAMile);
    };
    return CreateJobComponent;
}());
export { CreateJobComponent };
