/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.cbv1.NotificationGroup', null, global);
goog.exportSymbol('proto.cbv1.NotificationType', null, global);
goog.exportSymbol('proto.cbv1.NotificationUserPreference', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.NotificationGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.NotificationGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.NotificationGroup.displayName = 'proto.cbv1.NotificationGroup';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.NotificationGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.NotificationGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.NotificationGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NotificationGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.NotificationGroup}
 */
proto.cbv1.NotificationGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.NotificationGroup;
  return proto.cbv1.NotificationGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.NotificationGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.NotificationGroup}
 */
proto.cbv1.NotificationGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.NotificationGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.NotificationGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.NotificationGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NotificationGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.NotificationGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.NotificationGroup.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.NotificationGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.NotificationGroup.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.NotificationType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.NotificationType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.NotificationType.displayName = 'proto.cbv1.NotificationType';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.NotificationType.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.NotificationType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.NotificationType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NotificationType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    prettyPrintName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    notificationGroup: (f = msg.getNotificationGroup()) && proto.cbv1.NotificationGroup.toObject(includeInstance, f),
    globalEmailEnabled: jspb.Message.getFieldWithDefault(msg, 5, false),
    createdUnix: jspb.Message.getFieldWithDefault(msg, 6, 0),
    updatedUnix: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.NotificationType}
 */
proto.cbv1.NotificationType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.NotificationType;
  return proto.cbv1.NotificationType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.NotificationType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.NotificationType}
 */
proto.cbv1.NotificationType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrettyPrintName(value);
      break;
    case 4:
      var value = new proto.cbv1.NotificationGroup;
      reader.readMessage(value,proto.cbv1.NotificationGroup.deserializeBinaryFromReader);
      msg.setNotificationGroup(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGlobalEmailEnabled(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedUnix(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdatedUnix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.NotificationType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.NotificationType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.NotificationType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NotificationType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrettyPrintName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNotificationGroup();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.cbv1.NotificationGroup.serializeBinaryToWriter
    );
  }
  f = message.getGlobalEmailEnabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getUpdatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.NotificationType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.NotificationType.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.NotificationType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.NotificationType.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pretty_print_name = 3;
 * @return {string}
 */
proto.cbv1.NotificationType.prototype.getPrettyPrintName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.NotificationType.prototype.setPrettyPrintName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional NotificationGroup notification_group = 4;
 * @return {?proto.cbv1.NotificationGroup}
 */
proto.cbv1.NotificationType.prototype.getNotificationGroup = function() {
  return /** @type{?proto.cbv1.NotificationGroup} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.NotificationGroup, 4));
};


/** @param {?proto.cbv1.NotificationGroup|undefined} value */
proto.cbv1.NotificationType.prototype.setNotificationGroup = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.cbv1.NotificationType.prototype.clearNotificationGroup = function() {
  this.setNotificationGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.NotificationType.prototype.hasNotificationGroup = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool global_email_enabled = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.NotificationType.prototype.getGlobalEmailEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.cbv1.NotificationType.prototype.setGlobalEmailEnabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 created_unix = 6;
 * @return {number}
 */
proto.cbv1.NotificationType.prototype.getCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.NotificationType.prototype.setCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 updated_unix = 7;
 * @return {number}
 */
proto.cbv1.NotificationType.prototype.getUpdatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.NotificationType.prototype.setUpdatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.NotificationUserPreference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.NotificationUserPreference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.NotificationUserPreference.displayName = 'proto.cbv1.NotificationUserPreference';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.NotificationUserPreference.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.NotificationUserPreference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.NotificationUserPreference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NotificationUserPreference.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firebaseUid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    notificationType: (f = msg.getNotificationType()) && proto.cbv1.NotificationType.toObject(includeInstance, f),
    userEmailEnabled: jspb.Message.getFieldWithDefault(msg, 4, false),
    createdUnix: jspb.Message.getFieldWithDefault(msg, 5, 0),
    updatedUnix: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.NotificationUserPreference}
 */
proto.cbv1.NotificationUserPreference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.NotificationUserPreference;
  return proto.cbv1.NotificationUserPreference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.NotificationUserPreference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.NotificationUserPreference}
 */
proto.cbv1.NotificationUserPreference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebaseUid(value);
      break;
    case 3:
      var value = new proto.cbv1.NotificationType;
      reader.readMessage(value,proto.cbv1.NotificationType.deserializeBinaryFromReader);
      msg.setNotificationType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUserEmailEnabled(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedUnix(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdatedUnix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.NotificationUserPreference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.NotificationUserPreference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.NotificationUserPreference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NotificationUserPreference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirebaseUid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNotificationType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cbv1.NotificationType.serializeBinaryToWriter
    );
  }
  f = message.getUserEmailEnabled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getUpdatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.cbv1.NotificationUserPreference.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.NotificationUserPreference.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string firebase_uid = 2;
 * @return {string}
 */
proto.cbv1.NotificationUserPreference.prototype.getFirebaseUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.NotificationUserPreference.prototype.setFirebaseUid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NotificationType notification_type = 3;
 * @return {?proto.cbv1.NotificationType}
 */
proto.cbv1.NotificationUserPreference.prototype.getNotificationType = function() {
  return /** @type{?proto.cbv1.NotificationType} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.NotificationType, 3));
};


/** @param {?proto.cbv1.NotificationType|undefined} value */
proto.cbv1.NotificationUserPreference.prototype.setNotificationType = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv1.NotificationUserPreference.prototype.clearNotificationType = function() {
  this.setNotificationType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.NotificationUserPreference.prototype.hasNotificationType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool user_email_enabled = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.NotificationUserPreference.prototype.getUserEmailEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.cbv1.NotificationUserPreference.prototype.setUserEmailEnabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 created_unix = 5;
 * @return {number}
 */
proto.cbv1.NotificationUserPreference.prototype.getCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.NotificationUserPreference.prototype.setCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 updated_unix = 6;
 * @return {number}
 */
proto.cbv1.NotificationUserPreference.prototype.getUpdatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.NotificationUserPreference.prototype.setUpdatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


goog.object.extend(exports, proto.cbv1);
