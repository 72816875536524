import * as tslib_1 from "tslib";
import * as Sentry from '@sentry/browser';
import * as firebase from 'firebase/app';
import { BehaviorSubject, race, timer } from 'rxjs';
import { filter, map, mapTo, share, take, tap } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { AccountType } from '~proto/types/types_pb';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "./local-storage.service";
import * as i3 from "@angular/router";
import * as i4 from "@angular/material/snack-bar";
import * as i5 from "ngx-logger";
import * as i6 from "@angular/common/http";
var AuthService = /** @class */ (function () {
    function AuthService(afAuth, localStorage, router, matSnackBar, logger, http) {
        var _this = this;
        this.afAuth = afAuth;
        this.localStorage = localStorage;
        this.router = router;
        this.matSnackBar = matSnackBar;
        this.logger = logger;
        this.http = http;
        this._accountName$ = this.afAuth.idTokenResult.pipe(map(function (token) {
            return token.claims.account_session_name;
        }));
        this._isShaleAppsUser$ = this.afAuth.idTokenResult.pipe(map(function (token) { return token && token.claims && token.claims.email && token.claims.email.includes('@shaleapps.com'); }));
        this.isLMOOnce$ = this.isAccountType$(AccountType.ACCOUNT_TYPE_LMO);
        this.isTruckingVendorOnce$ = this.isAccountType$(AccountType.ACCOUNT_TYPE_TRUCKING_VENDOR);
        this.isAdminOnce$ = this.isAccountType$(AccountType.ACCOUNT_TYPE_SHALEAPPS);
        this.isLoggedIn$$ = new BehaviorSubject(false);
        this.afAuth.authState.subscribe(function (user) {
            _this._user = user;
            // may have to add user contract from local storage
            if (user) {
                return user.getIdTokenResult().then(function (idTokenResult) {
                    if (idTokenResult && idTokenResult.token && idTokenResult.token !== '') {
                        _this.sentryIdentify();
                        _this.logger.log(idTokenResult);
                    }
                    else {
                        console.error('this idTokenResult did not have a token', idTokenResult);
                    }
                });
            }
        });
    }
    Object.defineProperty(AuthService.prototype, "authenticated", {
        get: function () {
            return this._user !== null && this._user !== undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "user", {
        get: function () {
            return this._user;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "idToken$", {
        get: function () {
            return this.afAuth.idTokenResult;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "jwt$", {
        get: function () {
            return this.afAuth.idToken;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "isLoggedIn$", {
        get: function () {
            return this.isLoggedIn$$.asObservable().pipe(share());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "userEmail$", {
        get: function () {
            return this.afAuth.idTokenResult.pipe(filter(function (token) { return !!token; }), map(function (token) { return token.claims.email; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "accountName$", {
        get: function () {
            return this._accountName$;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.login = function (username, password) {
        var _this = this;
        console.log('Begin Login:', username);
        var isE2ETesting = JSON.parse(localStorage.getItem('e2e')) === true;
        this.afAuth.auth
            .setPersistence(isE2ETesting ? firebase.auth.Auth.Persistence.NONE : firebase.auth.Auth.Persistence.LOCAL)
            .then(function () { return _this.afAuth.auth.signInWithEmailAndPassword(username, password); })
            .then(function (fbUser) {
            console.log('Login Successful', username);
            _this.isLoggedIn$$.next(true);
            _this.storeUserInCache({
                email: fbUser.user.email,
                id: fbUser.user.uid,
                name: fbUser.user.displayName,
            });
        })
            .catch(function (error) {
            console.error('Error logging in', error);
            _this.matSnackBar.open('Invalid username or password', null, { panelClass: 'snackbar-error', duration: 2000 });
        });
    };
    AuthService.prototype.loginWithCustomToken = function (token) {
        var _this = this;
        return this.afAuth.auth
            .signInWithCustomToken(token)
            .then(function (fbUser) {
            _this.isLoggedIn$$.next(true);
            _this.storeUserInCache({
                email: fbUser.user.email,
                id: fbUser.user.uid,
                name: fbUser.user.displayName,
            });
        })
            .catch(function (error) {
            _this.matSnackBar.open('Invalid username or password', null, { panelClass: 'snackbar-error', duration: 2000 });
        });
    };
    AuthService.prototype.ssoURL$ = function (email) {
        return this.http.post(environment.api + '/sso_url', { email: email }).pipe(tap(console.log));
    };
    AuthService.prototype.logout = function () {
        this.afAuth.auth.signOut();
        this.removeUserFromCache();
        this.isLoggedIn$$.next(false);
        this.router.navigateByUrl('/login');
        Sentry.setUser(null);
    };
    AuthService.prototype.redirectIfLoggedIn = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var idToken, expirationTime;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afAuth.idTokenResult.pipe(take(1)).toPromise()];
                    case 1:
                        idToken = _a.sent();
                        if (!idToken) {
                            return [2 /*return*/];
                        }
                        expirationTime = new Date(idToken.expirationTime);
                        if (expirationTime < new Date()) {
                            return [2 /*return*/];
                        }
                        if (isAccountType(AccountType.ACCOUNT_TYPE_TRUCKING_VENDOR, idToken.claims.account_session_type)) {
                            this.router.navigate(['/', 'dispatcher']);
                        }
                        else if (isAccountType(AccountType.ACCOUNT_TYPE_LMO, idToken.claims.account_session_type)) {
                            this.router.navigate(['/', 'lmo']);
                        }
                        else if (isAccountType(AccountType.ACCOUNT_TYPE_SHALEAPPS, idToken.claims.account_session_type)) {
                            this.router.navigate(['/', 'shaleapps']);
                        }
                        else {
                            this.logger.error('Unknown account type', idToken);
                            this.logout();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.getUserFromCache = function () {
        return this.localStorage.getItem('user');
    };
    AuthService.prototype.removeUserFromCache = function () {
        this.localStorage.removeItem('user');
    };
    AuthService.prototype.storeSSOEmailInCache = function (user) {
        this.localStorage.setItem('ssoEmail', user.email);
    };
    AuthService.prototype.getSSOEmailFromCache = function () {
        return this.localStorage.getItem('ssoEmail');
    };
    AuthService.prototype.hasAccountRole = function (accountType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var idToken;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afAuth.idTokenResult.pipe(take(1)).toPromise()];
                    case 1:
                        idToken = _a.sent();
                        if (!idToken) {
                            return [2 /*return*/, false];
                        }
                        if (!idToken.claims.account_session) {
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/, hasAccountRole(accountType, idToken.claims.role)];
                }
            });
        });
    };
    AuthService.prototype.isLMO$ = function () {
        return this.isLMOOnce$;
    };
    AuthService.prototype.isTruckingVendor$ = function () {
        return this.isTruckingVendorOnce$;
    };
    AuthService.prototype.isAdmin$ = function () {
        return this.isAdminOnce$;
    };
    AuthService.prototype.isAccountType$ = function (accountType) {
        // return of(true);
        return this.afAuth.idTokenResult.pipe(map(function (idToken) {
            if (!idToken) {
                return false;
            }
            if (!idToken.claims.account_session) {
                console.error('No claims on id token', idToken);
                return false;
            }
            return isAccountType(accountType, idToken.claims.account_session_type);
        }));
    };
    AuthService.prototype.isShaleAppsUser$ = function () {
        return this._isShaleAppsUser$;
    };
    AuthService.prototype.sentryIdentify = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, afUser, idTokenResult, claims;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        user = {};
                        return [4 /*yield*/, this.afAuth.authState.pipe(take(1)).toPromise()];
                    case 1:
                        afUser = _a.sent();
                        return [4 /*yield*/, afUser.getIdTokenResult()];
                    case 2:
                        idTokenResult = _a.sent();
                        claims = idTokenResult.claims;
                        user.email = claims.email;
                        user.username = claims.name;
                        user.id = claims.user_id;
                        Sentry.setUser(user);
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.storeUserInCache = function (user) {
        this.localStorage.setItem('user', user);
    };
    AuthService.prototype.getAccountTypePrettyName = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, race(this.afAuth.idTokenResult.pipe(filter(function (token) { return !!token; })), timer(1000).pipe(mapTo(null)))
                        .pipe(take(1), map(function (idToken) {
                        if (!idToken) {
                            return null;
                        }
                        if (!idToken.claims.account_session) {
                            return null;
                        }
                        if (idToken.claims.account_session_type === AccountType.ACCOUNT_TYPE_LMO) {
                            return 'lmo';
                        }
                        else if (idToken.claims.account_session_type === AccountType.ACCOUNT_TYPE_TRUCKING_VENDOR) {
                            return 'trucking-vendor';
                        }
                        return null;
                    }))
                        .toPromise()];
            });
        });
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.AngularFireAuth), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.MatSnackBar), i0.ɵɵinject(i5.NGXLogger), i0.ɵɵinject(i6.HttpClient)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
function hasAccountRole(allowedRoles, userRoles) {
    if (!userRoles || !Array.isArray(userRoles) || userRoles.length === 0) {
        return false;
    }
    if (Array.isArray(allowedRoles)) {
        return allowedRoles.some(function (role) { return userRoles.includes(role); });
    }
    else {
        return userRoles.includes(allowedRoles);
    }
}
function isAccountType(allowedAccountTypes, accountType) {
    if (!accountType) {
        return false;
    }
    if (Array.isArray(allowedAccountTypes)) {
        return allowedAccountTypes.some(function (type) { return type === accountType; });
    }
    else {
        return allowedAccountTypes === accountType;
    }
}
