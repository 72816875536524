import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { grpc } from '@improbable-eng/grpc-web';
import { ProtobufMessage } from '@improbable-eng/grpc-web/dist/typings/message';
import { MethodDefinition } from '@improbable-eng/grpc-web/dist/typings/service';
import * as moment from 'moment';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { Observable, race, Subject, timer } from 'rxjs';
import { filter, mapTo, switchMap, take, throttleTime } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { AuthService } from './auth.service';
import { VersioningService } from './versioning.service';

const lastInvokeTime: Record<string, moment.Moment> = {};

export class GRPCError extends Error {
  constructor(public code: grpc.Code, public message: string | undefined, public trailers: grpc.Metadata) {
    super(message);
  }
}

@Injectable({
  providedIn: 'root',
})
export class GrpcService {
  private errorDisplay$$ = new Subject<string>();
  constructor(
    private authService: AuthService,
    private matSnackBar: MatSnackBar,
    private logger: NGXLogger,
    private versionService: VersioningService,
    private router: Router,
  ) {
    this.authService.jwt$.subscribe((jwt) => {
      this.logger.setCustomHttpHeaders(new HttpHeaders({ authorization: `bearer ${jwt}` }));
    });
    if (sessionStorage.getItem('SA_LOG_ON') || localStorage.getItem('SA_LOG_ON')) {
      this.logger.updateConfig({ level: NgxLoggerLevel.DEBUG });
    }
    this.errorDisplay$$.pipe(throttleTime(3000)).subscribe((errorMessage) => {
      this.matSnackBar.open(errorMessage, null, { panelClass: 'snackbar-error', duration: 2000 });
    });
  }

  public invoke$<T extends ProtobufMessage, U extends ProtobufMessage, M extends MethodDefinition<T, U>>(
    method: M,
    request: T,
    backgroundRequest = false, // If this is set to true, we will not show the user an error banner
  ): Observable<U> {
    this.logger.log(`⬆ Request: ${method.methodName}`, request.toObject());
    this.warnAboutLastInvokeTimeIfNeeded(method);
    return race<string, string>(
      this.authService.jwt$.pipe(filter((jwt) => !!jwt)),
      timer(12000).pipe(mapTo(null)),
    ).pipe(
      take(1),
      switchMap((jwt) => {
        const metadata: Record<string, string> = {};
        if (jwt) {
          metadata.Authorization = `Bearer ${jwt}`;
        }
        return new Observable<U>((observer) => {
          grpc.invoke(method, {
            host: environment.api,
            metadata,
            onEnd: (code: grpc.Code, msg: string, trailers: grpc.Metadata) => {
              if (code !== grpc.Code.OK) {
                if (code === grpc.Code.Unauthenticated) {
                  this.authService.logout();
                } else {
                  let info: string[];
                  try {
                    info = JSON.parse(msg).info;
                    this.logger.error({
                      clientVersion: this.versionService.currentVersion,
                      errorCode: code,
                      errorMessage: info.join(' '),
                      methodName: method.methodName,
                      requestPayload: JSON.stringify(request.toObject()),
                    });
                  } catch (_err) {
                    this.logger.error({
                      clientVersion: this.versionService.currentVersion,
                      errorCode: code,
                      errorMessage: msg,
                      methodName: method.methodName,
                      requestPayload: JSON.stringify(request.toObject()),
                    });
                  }
                  // permission denied to access resource, redirect to home screen
                  if (code === grpc.Code.PermissionDenied) {
                    this.router.navigate(['/']);
                  }
                  if (!backgroundRequest) {
                    this.errorDisplay$$.next(info ? info.join(' ') : 'Something Went Wrong');
                  }
                }
                observer.error(new GRPCError(code, msg, trailers));
              }
              observer.complete();
            },
            onMessage: (message: U) => {
              this.logger.log(`Response ⬇: ${method.methodName}`, message.toObject());
              observer.next(message);
            },
            request,
          });
        });
      }),
    );
  }

  public invokeWithoutLogin$<T extends ProtobufMessage, U extends ProtobufMessage, M extends MethodDefinition<T, U>>(
    method: M,
    request: T,
  ): Observable<U> {
    return new Observable<U>((observer) => {
      this.logger.log(`${method.methodName} ok`);
      const metadata: Record<string, string> = {};
      metadata.Authorization = `Bearer alsdhjakjshdashdjhsajhdkjahskjhsajhd`;
      grpc.invoke(method, {
        host: environment.api,
        metadata,
        onEnd: (code: grpc.Code, msg: string, trailers: grpc.Metadata) => {
          if (code === grpc.Code.OK) {
            this.logger.log(`${method.methodName} ok`);
          }
          if (code !== grpc.Code.OK) {
            let info: string[];
            try {
              info = JSON.parse(msg).info;
              this.logger.error({
                clientVersion: this.versionService.currentVersion,
                errorCode: code,
                errorMessage: info.join(' '),
                methodName: method.methodName,
                requestPayload: request.toObject(),
              });
            } catch (_err) {
              this.logger.error({
                clientVersion: this.versionService.currentVersion,
                errorCode: code,
                errorMessage: msg,
                methodName: method.methodName,
                requestPayload: JSON.stringify(request.toObject()),
              });
            }
            this.errorDisplay$$.next(info ? info.join(' ') : 'Something Went Wrong');
            observer.error(new GRPCError(code, msg, trailers));
          }
          observer.complete();
        },
        onMessage: (message: U) => {
          this.logger.log(`${method.methodName}`, message.toObject());
          observer.next(message);
        },
        request,
      });
    });
  }

  private warnAboutLastInvokeTimeIfNeeded<T extends MethodDefinition<any, any>>(rpcCall: T) {
    const rpcName = rpcCall.methodName;
    const now = moment();
    const lastInvoked = lastInvokeTime[rpcName];
    if (!lastInvoked) {
      lastInvokeTime[rpcName] = now;
    } else {
      const diffInMs = now.diff(lastInvoked, 'milliseconds');
      if (diffInMs < 1000) {
        this.logger.debug(`Recently invoked ${rpcName} (last ${diffInMs}ms), this might need some throttling`);
      }
      lastInvokeTime[rpcName] = now;
    }
  }
}
