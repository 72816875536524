import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { SwUpdate } from '@angular/service-worker';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { VERSION } from '~environments/version';

@Injectable({
  providedIn: 'root',
})
export class VersioningService {
  public isWrongVersion$: Observable<boolean>;

  public get currentVersion(): string {
    if (VERSION && VERSION.version && VERSION.hash) {
      return `${VERSION.version}.${VERSION.hash}`;
    }
    return VERSION.version;
  }

  constructor(private db: AngularFirestore, private swUpdate: SwUpdate) {
    this.db
      .doc<{ version: string }>(`cybertron-version/${environment.environment}`)
      .valueChanges()
      .pipe(map((version) => version && version.version !== this.currentVersion))
      .subscribe((isWrongVersion) => {
        if (isWrongVersion) {
          if (swUpdate.isEnabled) {
            this.swUpdate.checkForUpdate();
          }
        }
      });
  }
}
