import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { Bidder, SearchBidderRequest } from '~proto/marketplace/rfq_api_pb';
import { trackById } from '~utilities/trackById';
import { RfqAddBidderDialogComponent } from '../rfq-add-bidder-dialog/rfq-add-bidder-dialog.component';
var RfqVendorFormComponent = /** @class */ (function () {
    function RfqVendorFormComponent(fb, marketplaceService, matDialog, rfqFormService, router, route, snackbar) {
        this.fb = fb;
        this.marketplaceService = marketplaceService;
        this.matDialog = matDialog;
        this.rfqFormService = rfqFormService;
        this.router = router;
        this.route = route;
        this.snackbar = snackbar;
        this.trackById = trackById;
        this.networkActive$$ = new BehaviorSubject(false);
        this.selectable = true;
        this.suggestedVendors = [];
        this.vendorSuggestions = [];
        this.selectedVendors = [];
        this.rfqType$ = new BehaviorSubject('');
    }
    Object.defineProperty(RfqVendorFormComponent.prototype, "vendorName", {
        get: function () {
            return this.rfqVendorForm.get('vendor').value;
        },
        enumerable: true,
        configurable: true
    });
    RfqVendorFormComponent.prototype.ngOnInit = function () {
        this.getSuggestions();
        this.rfqType$.next(this.route.parent.snapshot.params.type);
        this.rfqFormService.setRfqType(this.rfqType$.value);
        if (this.rfqFormService.isFirstFormNotFilled()) {
            this.router.navigate(['', 'lmo', 'marketplace', 'schedule-rfq', this.rfqType$.value, '1']);
        }
        else {
            this.buildForm();
        }
    };
    RfqVendorFormComponent.prototype.getSuggestions = function () {
        var _this = this;
        this.marketplaceService.rfqSuggestions$
            .pipe(map(function (suggestions) { return (suggestions && suggestions.biddersList) || []; }))
            .subscribe(function (biddersList) {
            _this.suggestedVendors = tslib_1.__spread(biddersList);
            _this.vendorSuggestions = tslib_1.__spread(biddersList);
        });
    };
    RfqVendorFormComponent.prototype.buildForm = function () {
        var _this = this;
        this.rfqVendorForm = this.fb.group({
            // message: [null, []],
            vendor: [null, [Validators.required]],
        });
        this.rfqVendorForm.controls['vendor'].valueChanges
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(function (value) {
            if (value) {
                _this.filterSuggestedVendor();
                var searchBidderRequest = new SearchBidderRequest();
                searchBidderRequest.setEmail(value.toLowerCase());
                _this.marketplaceService.searchVendor(searchBidderRequest).subscribe(function (vendors) {
                    _this.suggestedVendors = tslib_1.__spread(vendors.toObject().biddersList);
                    _this.filterSuggestedVendor();
                });
            }
            else {
                _this.suggestedVendors = tslib_1.__spread(_this.vendorSuggestions);
                _this.filterSuggestedVendor();
            }
        });
    };
    RfqVendorFormComponent.prototype.remove = function (selectedVendor) {
        var index = this.selectedVendors.indexOf(selectedVendor);
        if (index >= 0) {
            this.selectedVendors.splice(index, 1);
            this.filterSuggestedVendor();
        }
    };
    RfqVendorFormComponent.prototype.showAddVendor = function () {
        var _this = this;
        return (this.vendorName &&
            !this.selectedVendors.find(function (vendor) {
                return vendor.getName().toLowerCase() === _this.vendorName.toLowerCase() ||
                    vendor.getEmail().toLowerCase() === _this.vendorName.toLowerCase();
            }) &&
            !this.suggestedVendors.find(function (vendor) {
                return vendor.name.toLowerCase() === _this.vendorName.toLowerCase() ||
                    vendor.email.toLowerCase() === _this.vendorName.toLowerCase();
            }));
    };
    RfqVendorFormComponent.prototype.addVendor = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var addVendorDialogRef;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                addVendorDialogRef = this.matDialog.open(RfqAddBidderDialogComponent, {
                    data: this.vendorName,
                });
                addVendorDialogRef
                    .afterClosed()
                    .pipe(take(1))
                    .subscribe(function (vendorData) {
                    _this.setSelectedVendor(vendorData);
                });
                return [2 /*return*/];
            });
        });
    };
    RfqVendorFormComponent.prototype.addExistingVendor = function (selectedvendor) {
        var vendor = new Bidder();
        vendor.setId(selectedvendor.id);
        vendor.setName(selectedvendor.name);
        vendor.setEmail(selectedvendor.email);
        vendor.setAccount(selectedvendor.account);
        this.setSelectedVendor(vendor);
    };
    RfqVendorFormComponent.prototype.setSelectedVendor = function (vendor) {
        this.selectedVendors.push(vendor);
        this.filterSuggestedVendor();
        this.rfqVendorForm.controls['vendor'].setValue('');
    };
    RfqVendorFormComponent.prototype.filterSuggestedVendor = function () {
        var _this = this;
        this.suggestedVendors = this.suggestedVendors.filter(function (vendor) {
            return !_this.selectedVendors.find(function (selectedVendor) { return selectedVendor.getId() === vendor.id; });
        });
    };
    RfqVendorFormComponent.prototype.createRFQ = function () {
        if (this.selectedVendors.length) {
            var rfqVendorRequest = {
                vendors: this.selectedVendors,
            };
            this.rfqFormService.saveFormData(rfqVendorRequest, 'VENDOR_DATA');
        }
        else {
            this.snackbar.open('Please select a valid vendor', null, {
                duration: 2000,
                panelClass: ['snackbar-error'],
            });
        }
    };
    RfqVendorFormComponent.prototype.trackByGetId = function (_index, bidder) {
        return bidder.getId();
    };
    return RfqVendorFormComponent;
}());
export { RfqVendorFormComponent };
