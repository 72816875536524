import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { differenceInHours } from 'date-fns';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, startWith, take } from 'rxjs/operators';
import { createOrderRequestFactory, getFormFromLocalStorage, saveFormToLocalStorage, } from '~lmo/utilities/create-order';
import { UserDetailsForIntercomRequest } from '~proto/user/user_api_pb';
import { UserAPI } from '~proto/user/user_api_pb_service';
import { trackById } from '~utilities/trackById';
var CREATE_ORDER_SAVED_SETTINGS = 'CREATE_ORDER_PAYLOAD_AND_TRAILER_SAVED_SETTINGS';
var CreateOrderPayloadAndTrailerComponent = /** @class */ (function () {
    function CreateOrderPayloadAndTrailerComponent(orderService, site, fb, router, recentService, snackBar, payloadService, contractService, 
    // Leave this here so we make sure all the constants are loaded
    _constantsService, // tslint-ignore-line
    roleService, grpcService) {
        this.orderService = orderService;
        this.site = site;
        this.fb = fb;
        this.router = router;
        this.recentService = recentService;
        this.snackBar = snackBar;
        this.payloadService = payloadService;
        this.contractService = contractService;
        this._constantsService = _constantsService;
        this.roleService = roleService;
        this.grpcService = grpcService;
        this.networkActive$ = new BehaviorSubject(false);
        this.truckingContracts = [];
        this.trackById = trackById;
        this.businessLinesList$$ = new BehaviorSubject(null);
        this.isBusinessLineEnabled$$ = new BehaviorSubject(false);
        this.isCostCenterRequired$$ = new BehaviorSubject(false);
        this.isPurchaseOrderRequired$$ = new BehaviorSubject(false);
        this.isSalesOrderRequired$$ = new BehaviorSubject(false);
        this.isMaintenanceOrderRequired$$ = new BehaviorSubject(false);
        this.isBusinessLineCostCenterRelationEnabled$$ = new BehaviorSubject(false);
    }
    CreateOrderPayloadAndTrailerComponent.prototype.formValue = function () {
        return this.formGroup.value;
    };
    CreateOrderPayloadAndTrailerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentSite$ = this.site.currentSite$;
        this.currentSitePayloads$ = this.payloadService.currentSitePayloads$;
        this.getStats();
        this.siteName$ = this.currentSite$.pipe(filter(function (site) { return !!site; }), map(function (site) { return site.name; }));
        this.withoutSiteIds$ = this.currentSite$.pipe(map(function (site) { return (site ? [site.id] : []); }));
        this.currentSite$
            .pipe(take(1))
            .toPromise()
            .then(function (site) {
            _this.loadRequiredFieldSettings();
            var lastPayloadChoices = getFormFromLocalStorage(site.id, CREATE_ORDER_SAVED_SETTINGS);
            _this.formGroup = _this.fb.group({
                businessLine: [null],
                contractTypeId: [null],
                costCenter: [null, _this.isCostCenterRequired$$.value ? [Validators.required] : ''],
                costCenterId: [null, []],
                description: [null, []],
                isAsapOrder: [null, Validators.required],
                loads: [1, [Validators.required, Validators.min(1), Validators.max(50)]],
                maintenanceOrderNumber: [
                    lastPayloadChoices.maintenanceOrderNumber,
                    _this.isMaintenanceOrderRequired$$.value ? [Validators.required] : '',
                ],
                orderNumber: [lastPayloadChoices.orderNumber, _this.isSalesOrderRequired$$.value ? [Validators.required] : ''],
                payload: [lastPayloadChoices.payload, Validators.required],
                pickupFrom: [lastPayloadChoices.pickupFrom, Validators.required],
                purchaseOrderName: [
                    lastPayloadChoices.purchaseOrderName,
                    _this.isPurchaseOrderRequired$$.value ? [Validators.required] : '',
                ],
                quantity: [lastPayloadChoices.quantity, Validators.required],
                scheduledDeliveryWindow: [null, [Validators.required]],
                trailer: [null],
                trailerDropoffLocation: [site, [Validators.required]],
                trailerPickupLocation: [lastPayloadChoices.pickupFrom, [Validators.required]],
                truckingVendor: [lastPayloadChoices.truckingVendor],
                vendorContractId: [null],
            });
            _this.recentService.loadSalesOrder();
            if (_this.formGroup.get('payload').value) {
                _this.recentService.loadPurchaseOrders(_this.formGroup.get('payload').value.id);
            }
            _this.listenToAsap();
            _this.listenToWarnings();
            _this.setupPayloads();
            _this.listenToPickupLocationChange();
            _this.listenToTrailer();
            _this.listenContractChange(lastPayloadChoices.truckingVendor);
            _this.listenContractTypeChanges();
            _this.checkIfBusinessLineEnabled();
            _this.checkIsBusinessLineCostCenterRelationEnabled();
            _this.formGroup.get('businessLine').valueChanges.subscribe(function () {
                _this.formGroup.get('costCenter').setValue(null);
                _this.formGroup.get('costCenterId').setValue(null);
            });
        });
    };
    CreateOrderPayloadAndTrailerComponent.prototype.listenContractTypeChanges = function () {
        var _this = this;
        this.contractType$ = this.formGroup.get('contractTypeId').valueChanges;
        this.contractTypes$ = this.contractService.contractTypes$;
        combineLatest([
            this.formGroup.get('contractTypeId').valueChanges,
            this.contractService.nonExpiredContracts$,
        ]).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), contractTypeId = _b[0], contracts = _b[1];
            var contractTypeContracts = contracts.filter(function (contract) {
                if (contract.contractType) {
                    return +contractTypeId <= 0 || contract.contractType.id === +contractTypeId;
                }
                return +contractTypeId <= 0;
            });
            var vendor = _this.formGroup.get('truckingVendor').value;
            if (vendor) {
                var vendorContract = contractTypeContracts.find(function (contract) { return contract.vendor.id === vendor.id; });
                if (!(vendorContract && vendorContract.id > 0)) {
                    _this.formGroup.get('truckingVendor').setValue(null);
                }
            }
        });
    };
    CreateOrderPayloadAndTrailerComponent.prototype.listenContractChange = function (initialVendor) {
        var _this = this;
        combineLatest([
            this.formGroup.get('truckingVendor').valueChanges.pipe(startWith(initialVendor)),
            this.formGroup.get('contractTypeId').valueChanges.pipe(startWith(-1)),
            this.contractService.nonExpiredContracts$,
        ]).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 3), vendor = _b[0], contractTypeId = _b[1], contracts = _b[2];
            var contractTypeContracts = contracts.filter(function (contract) {
                if (contract.contractType) {
                    return +contractTypeId <= 0 || contract.contractType.id === +contractTypeId;
                }
                return +contractTypeId <= 0;
            });
            var contractId = _this.formGroup.get('vendorContractId').value;
            if (contractId) {
                var contractById = contractTypeContracts.find(function (contract) { return contract.id === +contractId; });
                if (!(contractById && contractById.id > 0)) {
                    _this.formGroup.get('vendorContractId').setValue(null);
                }
            }
            if (vendor) {
                _this.truckingContracts = contracts.filter(function (contract) {
                    return contract.vendor.id === vendor.id &&
                        (contractTypeId <= 0 || (contract.contractType && contract.contractType.id === contractTypeId));
                });
            }
        });
        combineLatest([
            this.formGroup.get('vendorContractId').valueChanges,
            this.contractService.nonExpiredContracts$,
        ]).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), contractId = _b[0], contracts = _b[1];
            if (contractId) {
                var contractById = contracts.find(function (contract) { return contract.id === +contractId; });
                _this.formGroup.get('contractTypeId').setValue(contractById.contractType ? contractById.contractType.id : -1);
            }
        });
    };
    CreateOrderPayloadAndTrailerComponent.prototype.getStats = function () {
        var _this = this;
        this.orderService.getStats$().subscribe(function (stats) {
            _this.stats = stats;
        });
    };
    CreateOrderPayloadAndTrailerComponent.prototype.selected = function (event) {
        if (event.pickupFrom && event.pickupFrom.direction) {
            event.pickupFrom.directions = event.pickupFrom.direction;
        }
        this.formGroup.patchValue(event);
        if (event.payload) {
            this.recentService.loadPurchaseOrders(event.payload.id);
        }
    };
    CreateOrderPayloadAndTrailerComponent.prototype.selectedTrailerPickupLocation = function (event) {
        this.formGroup.patchValue({ trailerPickupLocation: event.pickupFrom });
    };
    CreateOrderPayloadAndTrailerComponent.prototype.selectedTrailerDropoffLocation = function (event) {
        this.formGroup.patchValue({ trailerDropoffLocation: event.pickupFrom });
    };
    CreateOrderPayloadAndTrailerComponent.prototype.create = function ($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var site, formValue, createOrderRequest;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.formGroup.markAllAsTouched();
                        if (this.formGroup.invalid) {
                            return [2 /*return*/];
                        }
                        this.networkActive$.next(true);
                        return [4 /*yield*/, this.currentSite$.pipe(take(1)).toPromise()];
                    case 1:
                        site = _a.sent();
                        formValue = this.formValue();
                        formValue.dropoffSite = site;
                        saveFormToLocalStorage(formValue, site.id, CREATE_ORDER_SAVED_SETTINGS);
                        createOrderRequest = createOrderRequestFactory(formValue);
                        this.requiredFieldsCheck(formValue);
                        if (!this.isCCValid || !this.isPOValid || !this.isSOValid || !this.isMOValid) {
                            this.snackBar.open('Please fill all mandatory fields', null, {
                                duration: 3000,
                            });
                            this.networkActive$.next(false);
                            return [2 /*return*/];
                        }
                        this.orderService
                            .createOrder$(createOrderRequest)
                            .pipe(finalize(function () {
                            _this.networkActive$.next(false);
                        }))
                            .subscribe(function (order) {
                            _this.snackBar.open('Load successfully scheduled!', null, {
                                duration: 3000,
                            });
                            _this.router.navigate(['/', 'lmo', 'jobs', site.id, 'orders', 'pending', order.orderId]);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateOrderPayloadAndTrailerComponent.prototype.requiredFieldsCheck = function (formValue) {
        this.isCCValid = this.isCostCenterRequired$$.value ? formValue.costCenter : 'true';
        this.isPOValid = this.isPurchaseOrderRequired$$.value ? formValue.purchaseOrderName : 'true';
        this.isSOValid = this.isSalesOrderRequired$$.value ? formValue.orderNumber : 'true';
        this.isMOValid = this.isMaintenanceOrderRequired$$.value ? formValue.orderNumber : 'true';
    };
    CreateOrderPayloadAndTrailerComponent.prototype.listenToAsap = function () {
        var _this = this;
        this.formGroup
            .get('isAsapOrder')
            .valueChanges.pipe(startWith(this.formGroup.get('isAsapOrder').value))
            .subscribe(function (value) {
            if (value) {
                _this.formGroup.get('scheduledDeliveryWindow').disable();
                _this.formGroup.get('scheduledDeliveryWindow').reset();
            }
            else {
                _this.formGroup.get('scheduledDeliveryWindow').enable();
                if (_this.formGroup.get('loads').value && _this.formGroup.get('loads').value > 1) {
                }
            }
        });
    };
    CreateOrderPayloadAndTrailerComponent.prototype.listenToPickupLocationChange = function () {
        var _this = this;
        this.formGroup.get('pickupFrom').valueChanges.subscribe(function (pickupFrom) {
            var trailerPickup = _this.formGroup.get('trailerPickupLocation');
            if (!trailerPickup.value) {
                trailerPickup.setValue(pickupFrom);
            }
        });
    };
    CreateOrderPayloadAndTrailerComponent.prototype.setupPayloads = function () {
        this.payloads$ = this.formGroup
            .get('payload')
            .valueChanges.pipe(startWith(this.formGroup.get('payload').value))
            .pipe(map(function (payload) { return (payload ? [payload] : []); }));
    };
    CreateOrderPayloadAndTrailerComponent.prototype.businessLinesList = function () {
        var _this = this;
        this.roleService.businessLinesList$.subscribe(function (businessLines) {
            _this.businessLinesList$$.next(businessLines);
            if (businessLines.length === 1) {
                _this.formGroup.controls['businessLine'].setValue(businessLines[0].id);
            }
        });
    };
    CreateOrderPayloadAndTrailerComponent.prototype.checkIfBusinessLineEnabled = function () {
        var _this = this;
        this.roleService.isBusinessLineEnabled$.subscribe(function (isBusinessLineEnabled) {
            _this.isBusinessLineEnabled$$.next(isBusinessLineEnabled);
            if (isBusinessLineEnabled) {
                _this.businessLinesList();
            }
        });
    };
    CreateOrderPayloadAndTrailerComponent.prototype.checkIsBusinessLineCostCenterRelationEnabled = function () {
        var _this = this;
        this.roleService.isBusinessLineCostCenterRelationEnabled$.subscribe(function (isBLCCRelationEnabled) {
            _this.isBusinessLineCostCenterRelationEnabled$$.next(isBLCCRelationEnabled);
        });
    };
    CreateOrderPayloadAndTrailerComponent.prototype.listenToWarnings = function () {
        this.showDetentionWarning$ = this.formGroup.valueChanges.pipe(map(function (formValue) {
            if (!formValue.isAsapOrder &&
                formValue.scheduledDeliveryWindow &&
                formValue.scheduledDeliveryWindow.length > 1) {
                if (differenceInHours(formValue.scheduledDeliveryWindow[1], formValue.scheduledDeliveryWindow[0]) < 3) {
                    return true;
                }
            }
            return false;
        }));
    };
    CreateOrderPayloadAndTrailerComponent.prototype.listenToTrailer = function () {
        var _this = this;
        this.formGroup
            .get('trailer')
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe(function (trailer) {
            if (trailer) {
                _this.formGroup.get('trailerPickupLocation').setValue(trailer.site);
            }
        });
    };
    CreateOrderPayloadAndTrailerComponent.prototype.loadRequiredFieldSettings = function () {
        var _this = this;
        var request = new UserDetailsForIntercomRequest();
        this.grpcService.invoke$(UserAPI.UserDetailsForIntercom, request).subscribe(function (response) {
            _this.isCostCenterRequired$$.next(response.toObject().userDetails.isCostCenterRequired);
            _this.isPurchaseOrderRequired$$.next(response.toObject().userDetails.isPurchaseOrderRequired);
            _this.isSalesOrderRequired$$.next(response.toObject().userDetails.isSalesOrderRequired);
            _this.isMaintenanceOrderRequired$$.next(response.toObject().userDetails.isMaintenanceOrderRequired);
        });
    };
    CreateOrderPayloadAndTrailerComponent.prototype.trackByIndex = function (index) {
        return index;
    };
    CreateOrderPayloadAndTrailerComponent.prototype.trackByName = function (_index, record) {
        return record.name;
    };
    return CreateOrderPayloadAndTrailerComponent;
}());
export { CreateOrderPayloadAndTrailerComponent };
