<div class="confirm-cancel">
  <ct-mini-modal title="Are you sure you want to cancel {{ name }}?">
    <footer>
      <div class="content">
        <button ct-button (click)="cancel()">No</button>
        <button ct-danger-button (click)="cancelConfirm()" ctAutomatedSelector="order__cancel_confirm">Yes</button>
      </div>
    </footer>
  </ct-mini-modal>
</div>
