import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, filter, finalize, map, startWith, take, takeUntil } from 'rxjs/operators';
import { UpdateNewBusinessLineRequest } from '~proto/businessline/businessline_api_pb';
import { trackById } from '~utilities/trackById';
var LmoBusinessLineManagementEditComponent = /** @class */ (function () {
    function LmoBusinessLineManagementEditComponent(fb, businessLineService, router, snackbar, userService, routerState, costCenterService, roleService) {
        this.fb = fb;
        this.businessLineService = businessLineService;
        this.router = router;
        this.snackbar = snackbar;
        this.userService = userService;
        this.routerState = routerState;
        this.costCenterService = costCenterService;
        this.roleService = roleService;
        this.networkActive$ = new BehaviorSubject(false);
        this.hide = true;
        this.trackById = trackById;
        this.userSearch = new FormControl();
        this.costCenterSearch = new FormControl();
        this.destroy$$ = new Subject();
        this.isBusinessLineCostCenterRelationEnabled$$ = new BehaviorSubject(false);
    }
    LmoBusinessLineManagementEditComponent.prototype.formValue = function () {
        return this.formGroup.value;
    };
    LmoBusinessLineManagementEditComponent.prototype.ngOnInit = function () {
        this.backUrl$ = this.routerState.backUrlOr$(['../../']);
        this.formGroup = this.fb.group({
            costCenters: [''],
            name: [''],
            users: [[]],
        });
        this.setBusinessLine().then();
        this.usersList$ = combineLatest([
            this.userService.users$.pipe(takeUntil(this.destroy$$)),
            this.userSearch.valueChanges.pipe(takeUntil(this.destroy$$), debounceTime(500), startWith(''))
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), users = _b[0], filterText = _b[1];
            return users.filter(function (user) { return user.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0; });
        }));
        this.costCentersList$ = combineLatest([
            this.costCenterService.costCenters$.pipe(takeUntil(this.destroy$$)),
            this.costCenterSearch.valueChanges.pipe(takeUntil(this.destroy$$), debounceTime(500), startWith(''))
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), costCenters = _b[0], filterText = _b[1];
            return costCenters.filter(function (costCenter) { return costCenter.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0; });
        }));
        this.checkIsBusinessLineCostCenterRelationEnabled();
    };
    LmoBusinessLineManagementEditComponent.prototype.setBusinessLine = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.businessLineService.currentBusinessLine$
                                .pipe(filter(function (businessLine) { return !!businessLine; }), take(1))
                                .toPromise()];
                    case 1:
                        _a.businessLine = _b.sent();
                        this.businessLineService
                            .usersAttachedToBusinessLine$(this.businessLine.id)
                            .pipe(filter(function (users) { return !!users; }))
                            .subscribe(function (users) {
                            _this.formGroup.patchValue({
                                users: _this.getUsersAttachedToBusinessLine(users),
                            });
                        });
                        this.formGroup.get('name').disable();
                        this.formGroup.get('users').reset();
                        this.formGroup.patchValue({
                            costCenters: this.getCostCentersAttachedToBusinessLine(),
                            name: this.businessLine.name,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    LmoBusinessLineManagementEditComponent.prototype.getUsersAttachedToBusinessLine = function (users) {
        return users.map(function (user) { return user.authId; });
    };
    LmoBusinessLineManagementEditComponent.prototype.getCostCentersAttachedToBusinessLine = function () {
        return this.businessLine.costCentersList.map(function (cc) { return cc.id; });
    };
    LmoBusinessLineManagementEditComponent.prototype.updateBusinessLine = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formValue;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.formGroup.markAllAsTouched();
                if (this.formGroup.invalid) {
                    return [2 /*return*/];
                }
                formValue = this.formValue();
                this.networkActive$.next(true);
                this.updateBusinessLine$(formValue)
                    .pipe(finalize(function () {
                    _this.networkActive$.next(false);
                }))
                    .subscribe(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.snackbar.open(this.businessLine.name + " updated!", null, {
                                    duration: 2000,
                                });
                                return [4 /*yield*/, this.router.navigate(['/', 'lmo', 'business-lines'])];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    LmoBusinessLineManagementEditComponent.prototype.checkIsBusinessLineCostCenterRelationEnabled = function () {
        var _this = this;
        this.roleService.isBusinessLineCostCenterRelationEnabled$.subscribe(function (isBLCCRelationEnabled) {
            _this.isBusinessLineCostCenterRelationEnabled$$.next(isBLCCRelationEnabled);
        });
    };
    LmoBusinessLineManagementEditComponent.prototype.updateBusinessLine$ = function (formValue) {
        var updateBusinessLineRequest = new UpdateNewBusinessLineRequest();
        updateBusinessLineRequest.setId(this.businessLine.id);
        updateBusinessLineRequest.setName(this.businessLine.name);
        updateBusinessLineRequest.setUserIdsList(formValue.users);
        if (this.isBusinessLineCostCenterRelationEnabled$$.value) {
            updateBusinessLineRequest.setCostCenterIdsList(formValue.costCenters);
        }
        return this.businessLineService.updateBusinessLine$(updateBusinessLineRequest);
    };
    return LmoBusinessLineManagementEditComponent;
}());
export { LmoBusinessLineManagementEditComponent };
