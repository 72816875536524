/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_cybertron_fields_fields_pb = require('../../../proto/cybertron/fields/fields_pb.js');
goog.exportSymbol('proto.cbv1.CreateCustomFieldSettingRequest', null, global);
goog.exportSymbol('proto.cbv1.CreateCustomFieldSettingResponse', null, global);
goog.exportSymbol('proto.cbv1.GetCustomFieldSettingsRequest', null, global);
goog.exportSymbol('proto.cbv1.GetCustomFieldSettingsResponse', null, global);
goog.exportSymbol('proto.cbv1.UpdateCustomFieldSettingsRequest', null, global);
goog.exportSymbol('proto.cbv1.UpdateCustomFieldSettingsResponse', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateCustomFieldSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateCustomFieldSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateCustomFieldSettingRequest.displayName = 'proto.cbv1.CreateCustomFieldSettingRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateCustomFieldSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateCustomFieldSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateCustomFieldSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateCustomFieldSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customFieldSetting: (f = msg.getCustomFieldSetting()) && proto_cybertron_fields_fields_pb.CustomFieldSetting.toObject(includeInstance, f),
    customFieldResource: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateCustomFieldSettingRequest}
 */
proto.cbv1.CreateCustomFieldSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateCustomFieldSettingRequest;
  return proto.cbv1.CreateCustomFieldSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateCustomFieldSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateCustomFieldSettingRequest}
 */
proto.cbv1.CreateCustomFieldSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_fields_fields_pb.CustomFieldSetting;
      reader.readMessage(value,proto_cybertron_fields_fields_pb.CustomFieldSetting.deserializeBinaryFromReader);
      msg.setCustomFieldSetting(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomFieldResource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateCustomFieldSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateCustomFieldSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateCustomFieldSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateCustomFieldSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomFieldSetting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_fields_fields_pb.CustomFieldSetting.serializeBinaryToWriter
    );
  }
  f = message.getCustomFieldResource();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional CustomFieldSetting custom_field_setting = 1;
 * @return {?proto.cbv1.CustomFieldSetting}
 */
proto.cbv1.CreateCustomFieldSettingRequest.prototype.getCustomFieldSetting = function() {
  return /** @type{?proto.cbv1.CustomFieldSetting} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_fields_fields_pb.CustomFieldSetting, 1));
};


/** @param {?proto.cbv1.CustomFieldSetting|undefined} value */
proto.cbv1.CreateCustomFieldSettingRequest.prototype.setCustomFieldSetting = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.CreateCustomFieldSettingRequest.prototype.clearCustomFieldSetting = function() {
  this.setCustomFieldSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CreateCustomFieldSettingRequest.prototype.hasCustomFieldSetting = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string custom_field_resource = 2;
 * @return {string}
 */
proto.cbv1.CreateCustomFieldSettingRequest.prototype.getCustomFieldResource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.CreateCustomFieldSettingRequest.prototype.setCustomFieldResource = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateCustomFieldSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.CreateCustomFieldSettingResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.CreateCustomFieldSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateCustomFieldSettingResponse.displayName = 'proto.cbv1.CreateCustomFieldSettingResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.CreateCustomFieldSettingResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateCustomFieldSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateCustomFieldSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateCustomFieldSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateCustomFieldSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customFieldSettingsList: jspb.Message.toObjectList(msg.getCustomFieldSettingsList(),
    proto_cybertron_fields_fields_pb.CustomFieldSetting.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateCustomFieldSettingResponse}
 */
proto.cbv1.CreateCustomFieldSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateCustomFieldSettingResponse;
  return proto.cbv1.CreateCustomFieldSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateCustomFieldSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateCustomFieldSettingResponse}
 */
proto.cbv1.CreateCustomFieldSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_fields_fields_pb.CustomFieldSetting;
      reader.readMessage(value,proto_cybertron_fields_fields_pb.CustomFieldSetting.deserializeBinaryFromReader);
      msg.addCustomFieldSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateCustomFieldSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateCustomFieldSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateCustomFieldSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateCustomFieldSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomFieldSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_fields_fields_pb.CustomFieldSetting.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CustomFieldSetting custom_field_settings = 1;
 * @return {!Array<!proto.cbv1.CustomFieldSetting>}
 */
proto.cbv1.CreateCustomFieldSettingResponse.prototype.getCustomFieldSettingsList = function() {
  return /** @type{!Array<!proto.cbv1.CustomFieldSetting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_fields_fields_pb.CustomFieldSetting, 1));
};


/** @param {!Array<!proto.cbv1.CustomFieldSetting>} value */
proto.cbv1.CreateCustomFieldSettingResponse.prototype.setCustomFieldSettingsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.CustomFieldSetting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.CustomFieldSetting}
 */
proto.cbv1.CreateCustomFieldSettingResponse.prototype.addCustomFieldSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.CustomFieldSetting, opt_index);
};


proto.cbv1.CreateCustomFieldSettingResponse.prototype.clearCustomFieldSettingsList = function() {
  this.setCustomFieldSettingsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetCustomFieldSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetCustomFieldSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetCustomFieldSettingsRequest.displayName = 'proto.cbv1.GetCustomFieldSettingsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetCustomFieldSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetCustomFieldSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetCustomFieldSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetCustomFieldSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customFieldResource: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetCustomFieldSettingsRequest}
 */
proto.cbv1.GetCustomFieldSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetCustomFieldSettingsRequest;
  return proto.cbv1.GetCustomFieldSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetCustomFieldSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetCustomFieldSettingsRequest}
 */
proto.cbv1.GetCustomFieldSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomFieldResource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetCustomFieldSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetCustomFieldSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetCustomFieldSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetCustomFieldSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomFieldResource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string custom_field_resource = 1;
 * @return {string}
 */
proto.cbv1.GetCustomFieldSettingsRequest.prototype.getCustomFieldResource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.GetCustomFieldSettingsRequest.prototype.setCustomFieldResource = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetCustomFieldSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.GetCustomFieldSettingsResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.GetCustomFieldSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetCustomFieldSettingsResponse.displayName = 'proto.cbv1.GetCustomFieldSettingsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.GetCustomFieldSettingsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetCustomFieldSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetCustomFieldSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetCustomFieldSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetCustomFieldSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customFieldSettingsList: jspb.Message.toObjectList(msg.getCustomFieldSettingsList(),
    proto_cybertron_fields_fields_pb.CustomFieldSetting.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetCustomFieldSettingsResponse}
 */
proto.cbv1.GetCustomFieldSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetCustomFieldSettingsResponse;
  return proto.cbv1.GetCustomFieldSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetCustomFieldSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetCustomFieldSettingsResponse}
 */
proto.cbv1.GetCustomFieldSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_fields_fields_pb.CustomFieldSetting;
      reader.readMessage(value,proto_cybertron_fields_fields_pb.CustomFieldSetting.deserializeBinaryFromReader);
      msg.addCustomFieldSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetCustomFieldSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetCustomFieldSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetCustomFieldSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetCustomFieldSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomFieldSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_fields_fields_pb.CustomFieldSetting.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CustomFieldSetting custom_field_settings = 1;
 * @return {!Array<!proto.cbv1.CustomFieldSetting>}
 */
proto.cbv1.GetCustomFieldSettingsResponse.prototype.getCustomFieldSettingsList = function() {
  return /** @type{!Array<!proto.cbv1.CustomFieldSetting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_fields_fields_pb.CustomFieldSetting, 1));
};


/** @param {!Array<!proto.cbv1.CustomFieldSetting>} value */
proto.cbv1.GetCustomFieldSettingsResponse.prototype.setCustomFieldSettingsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.CustomFieldSetting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.CustomFieldSetting}
 */
proto.cbv1.GetCustomFieldSettingsResponse.prototype.addCustomFieldSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.CustomFieldSetting, opt_index);
};


proto.cbv1.GetCustomFieldSettingsResponse.prototype.clearCustomFieldSettingsList = function() {
  this.setCustomFieldSettingsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateCustomFieldSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.UpdateCustomFieldSettingsRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.UpdateCustomFieldSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateCustomFieldSettingsRequest.displayName = 'proto.cbv1.UpdateCustomFieldSettingsRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.UpdateCustomFieldSettingsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateCustomFieldSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateCustomFieldSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateCustomFieldSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateCustomFieldSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customFieldResource: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customFieldSettingsList: jspb.Message.toObjectList(msg.getCustomFieldSettingsList(),
    proto_cybertron_fields_fields_pb.CustomFieldSetting.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateCustomFieldSettingsRequest}
 */
proto.cbv1.UpdateCustomFieldSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateCustomFieldSettingsRequest;
  return proto.cbv1.UpdateCustomFieldSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateCustomFieldSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateCustomFieldSettingsRequest}
 */
proto.cbv1.UpdateCustomFieldSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomFieldResource(value);
      break;
    case 2:
      var value = new proto_cybertron_fields_fields_pb.CustomFieldSetting;
      reader.readMessage(value,proto_cybertron_fields_fields_pb.CustomFieldSetting.deserializeBinaryFromReader);
      msg.addCustomFieldSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateCustomFieldSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateCustomFieldSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateCustomFieldSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateCustomFieldSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomFieldResource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomFieldSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_cybertron_fields_fields_pb.CustomFieldSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional string custom_field_resource = 1;
 * @return {string}
 */
proto.cbv1.UpdateCustomFieldSettingsRequest.prototype.getCustomFieldResource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.UpdateCustomFieldSettingsRequest.prototype.setCustomFieldResource = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated CustomFieldSetting custom_field_settings = 2;
 * @return {!Array<!proto.cbv1.CustomFieldSetting>}
 */
proto.cbv1.UpdateCustomFieldSettingsRequest.prototype.getCustomFieldSettingsList = function() {
  return /** @type{!Array<!proto.cbv1.CustomFieldSetting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_fields_fields_pb.CustomFieldSetting, 2));
};


/** @param {!Array<!proto.cbv1.CustomFieldSetting>} value */
proto.cbv1.UpdateCustomFieldSettingsRequest.prototype.setCustomFieldSettingsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cbv1.CustomFieldSetting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.CustomFieldSetting}
 */
proto.cbv1.UpdateCustomFieldSettingsRequest.prototype.addCustomFieldSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cbv1.CustomFieldSetting, opt_index);
};


proto.cbv1.UpdateCustomFieldSettingsRequest.prototype.clearCustomFieldSettingsList = function() {
  this.setCustomFieldSettingsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateCustomFieldSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.UpdateCustomFieldSettingsResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.UpdateCustomFieldSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateCustomFieldSettingsResponse.displayName = 'proto.cbv1.UpdateCustomFieldSettingsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.UpdateCustomFieldSettingsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateCustomFieldSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateCustomFieldSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateCustomFieldSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateCustomFieldSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customFieldSettingsList: jspb.Message.toObjectList(msg.getCustomFieldSettingsList(),
    proto_cybertron_fields_fields_pb.CustomFieldSetting.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateCustomFieldSettingsResponse}
 */
proto.cbv1.UpdateCustomFieldSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateCustomFieldSettingsResponse;
  return proto.cbv1.UpdateCustomFieldSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateCustomFieldSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateCustomFieldSettingsResponse}
 */
proto.cbv1.UpdateCustomFieldSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_fields_fields_pb.CustomFieldSetting;
      reader.readMessage(value,proto_cybertron_fields_fields_pb.CustomFieldSetting.deserializeBinaryFromReader);
      msg.addCustomFieldSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateCustomFieldSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateCustomFieldSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateCustomFieldSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateCustomFieldSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomFieldSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_fields_fields_pb.CustomFieldSetting.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CustomFieldSetting custom_field_settings = 1;
 * @return {!Array<!proto.cbv1.CustomFieldSetting>}
 */
proto.cbv1.UpdateCustomFieldSettingsResponse.prototype.getCustomFieldSettingsList = function() {
  return /** @type{!Array<!proto.cbv1.CustomFieldSetting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_fields_fields_pb.CustomFieldSetting, 1));
};


/** @param {!Array<!proto.cbv1.CustomFieldSetting>} value */
proto.cbv1.UpdateCustomFieldSettingsResponse.prototype.setCustomFieldSettingsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.CustomFieldSetting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.CustomFieldSetting}
 */
proto.cbv1.UpdateCustomFieldSettingsResponse.prototype.addCustomFieldSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.CustomFieldSetting, opt_index);
};


proto.cbv1.UpdateCustomFieldSettingsResponse.prototype.clearCustomFieldSettingsList = function() {
  this.setCustomFieldSettingsList([]);
};


goog.object.extend(exports, proto.cbv1);
