// package: cbv1
// file: proto/cybertron/trailer/trailer_api.proto

var proto_cybertron_trailer_trailer_api_pb = require("../../../proto/cybertron/trailer/trailer_api_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var TrailerAPI = (function () {
  function TrailerAPI() {}
  TrailerAPI.serviceName = "cbv1.TrailerAPI";
  return TrailerAPI;
}());

TrailerAPI.CreateTrailerWithModel = {
  methodName: "CreateTrailerWithModel",
  service: TrailerAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_trailer_trailer_api_pb.CreateNewTrailerRequest,
  responseType: proto_cybertron_trailer_trailer_api_pb.CreateNewTrailerResponse
};

TrailerAPI.ListTrailersWithDetails = {
  methodName: "ListTrailersWithDetails",
  service: TrailerAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_trailer_trailer_api_pb.TrailersDetailsRequest,
  responseType: proto_cybertron_trailer_trailer_api_pb.TrailersDetailsResponse
};

TrailerAPI.UpdateTrailer = {
  methodName: "UpdateTrailer",
  service: TrailerAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_trailer_trailer_api_pb.UpdateTrailersRequest,
  responseType: proto_cybertron_trailer_trailer_api_pb.UpdateTrailersResponse
};

TrailerAPI.DeleteTrailer = {
  methodName: "DeleteTrailer",
  service: TrailerAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_trailer_trailer_api_pb.DeleteTrailersRequest,
  responseType: proto_cybertron_trailer_trailer_api_pb.DeleteTrailersResponse
};

TrailerAPI.CreateTrailer = {
  methodName: "CreateTrailer",
  service: TrailerAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_trailer_trailer_api_pb.CreateTrailerRequest,
  responseType: proto_cybertron_trailer_trailer_api_pb.CreateTrailerResponse
};

TrailerAPI.ListTrailers = {
  methodName: "ListTrailers",
  service: TrailerAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_trailer_trailer_api_pb.ListTrailersRequest,
  responseType: proto_cybertron_trailer_trailer_api_pb.ListTrailersResponse
};

TrailerAPI.SetTrailerLastLocation = {
  methodName: "SetTrailerLastLocation",
  service: TrailerAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_trailer_trailer_api_pb.SetTrailerLastLocationRequest,
  responseType: proto_cybertron_trailer_trailer_api_pb.SetTrailerLastLocationResponse
};

exports.TrailerAPI = TrailerAPI;

function TrailerAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

TrailerAPIClient.prototype.createTrailerWithModel = function createTrailerWithModel(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TrailerAPI.CreateTrailerWithModel, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TrailerAPIClient.prototype.listTrailersWithDetails = function listTrailersWithDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TrailerAPI.ListTrailersWithDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TrailerAPIClient.prototype.updateTrailer = function updateTrailer(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TrailerAPI.UpdateTrailer, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TrailerAPIClient.prototype.deleteTrailer = function deleteTrailer(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TrailerAPI.DeleteTrailer, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TrailerAPIClient.prototype.createTrailer = function createTrailer(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TrailerAPI.CreateTrailer, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TrailerAPIClient.prototype.listTrailers = function listTrailers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TrailerAPI.ListTrailers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TrailerAPIClient.prototype.setTrailerLastLocation = function setTrailerLastLocation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TrailerAPI.SetTrailerLastLocation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.TrailerAPIClient = TrailerAPIClient;

