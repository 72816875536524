import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { sort } from 'remeda';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, map, startWith, switchMap, take } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { SiteShort } from '~proto/site/site_pb';

const sortArchivedLast = sort((a: SiteShort.AsObject, b: SiteShort.AsObject) =>
  a.archived === b.archived ? 0 : a.archived ? 1 : -1,
);

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-site-search',
  styleUrls: ['./site-search.component.scss'],
  templateUrl: './site-search.component.html',
})
export class SiteSearchComponent implements OnInit {
  @Input() public subHeaderSearch = false;
  public sites$: Observable<SiteShort.AsObject[]>;
  public textChanges$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public isSearching$: Observable<boolean>;

  constructor(private siteService: JobSitesService, private router: Router) {}

  public ngOnInit() {
    this.isSearching$ = this.siteService.searching$;

    this.sites$ = this.textChanges$$
      .pipe(
        debounceTime(100),
        startWith(''),
      )
      .pipe(
        switchMap((text) => {
          if (!text || text === '' || text.length <= 1) {
            return of([]);
          }
          return this.siteService.searchForSite$(text).pipe();
        }),
        map((result) => sortArchivedLast(result)),
      );
  }

  public inputTextChange(event: string) {
    this.textChanges$$.next(event.trim());
  }

  public togglePin(event: Event, siteId: number, isFavorited: boolean) {
    event.stopPropagation();
    event.preventDefault();
    this.siteService.setFavorite(isFavorited, siteId);
  }

  public handleEnter() {
    this.sites$.pipe(take(1)).subscribe((sites) => {
      if (sites.length) {
        this.router.navigate(['/', 'lmo', 'jobs', sites[0].id]);
      }
    });
  }
}
