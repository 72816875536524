import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LmoMarketplaceInviteComponent } from 'src/app/lmo/components';
import { AuthService } from '~services/auth.service';
import { IntercomService } from '~services/intercom.service';
import { RoleService } from '~services/role.service';
import { ConfigValuesService } from './../../config-values.service';

@Component({
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))]),
    ]),
  ],
  selector: 'ct-side-nav',
  styleUrls: ['./side-nav.component.scss'],
  templateUrl: './side-nav.component.html',
})
export class SideNavComponent implements OnInit {
  @Input() public visible = false;
  @Output() public onMenuClose: EventEmitter<void> = new EventEmitter();
  public userEmail$: Observable<string>;
  public name$: Observable<string>;
  public isBusinessLineEnabled$$ = new BehaviorSubject<Boolean>(false);
  public ssoLoginUrl$: Observable<string>;
  public isSsoUser$$ = new BehaviorSubject<Boolean>(false);

  @HostListener('document:keyup', ['$event'])
  public onKeyUp(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape': {
        if (this.visible) {
          this.onMenuClose.emit();
        }
      }
    }
  }

  constructor(
    public authService: AuthService,
    private matDialog: MatDialog,
    private intercom: IntercomService,
    private roleService: RoleService,
    private snackBar: MatSnackBar,
    private configService: ConfigValuesService,
  ) {
    this.ssoLoginUrl$ = this.configService.configs$.pipe(map((configs) => configs.sso_login_url));
  }

  public ngOnInit() {
    this.userEmail$ = this.authService.userEmail$.pipe(filter((email) => !!email));

    this.name$ = this.authService.accountName$.pipe(filter((name) => !!name));
    this.checkIfBusinessLineEnabled();
    this.checkIfSsoUser();
  }

  public close() {
    if (this.visible) {
      this.onMenuClose.emit();
    }
  }

  public logout() {
    this.authService.logout();
  }

  public goToSandi() {
    (<any>window).open('https://itunes.apple.com/US/app/id1360876904?mt=8');
  }

  public goToGohawkIOS() {
    (<any>window).open('https://itunes.apple.com/US/app/id1296380634?mt=8');
  }

  public goToGohawkAndroid() {
    (<any>window).open('https://play.google.com/store/apps/details?id=com.shaleapps.agnus');
  }

  public openInviteModal(email: string) {
    const dialog = this.matDialog.open(LmoMarketplaceInviteComponent);
    dialog.componentInstance.userEmail = email;
  }

  public openIntercom() {
    this.intercom.showNewMessage();
  }

  private checkIfBusinessLineEnabled() {
    this.roleService.isBusinessLineEnabled$.subscribe((isBusinessLineEnabled) => {
      this.isBusinessLineEnabled$$.next(isBusinessLineEnabled);
    });
  }

  private checkIfSsoUser() {
    this.roleService.isSsoUser$.subscribe((isSsoUser) => {
      this.isSsoUser$$.next(isSsoUser);
    });
  }

  public ssoLogin() {
    this.authService.ssoURL$(this.authService.user.email).subscribe(
      (url) => {
        window.open(url, '_self');
      },
      (error) => {
        this.snackBar.open(error.error, null, {
          duration: 5000,
          panelClass: ['snackbar-error'],
        });
      },
    );
  }
}
