import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonComponentsModule } from './common/common.module';
import { containers } from './containers';
import { MaterialModule } from './material-module/material.module';
import { OnlyEnvDirective } from './only-env.directive';
import { SentryErrorHandler } from './sentry.io';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, ...containers, OnlyEnvDirective],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    MaterialModule,
    BrowserAnimationsModule,
    CommonComponentsModule,
    CommonModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: window.location.hostname.includes('hauli.shaleapps.com'),
    }),
    HttpClientModule,
    LoggerModule.forRoot({
      enableSourceMaps: !environment.production,
      level: environment.production ? NgxLoggerLevel.OFF : NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
      serverLoggingUrl: `${environment.api}/web_error_log`,
    }),
    KeyboardShortcutsModule.forRoot(),
  ],
  providers: [{ provide: ErrorHandler, useClass: SentryErrorHandler }],
})
export class AppModule {}
