<div class="notification-preferences">
  <ct-header #header [noSubheader]="true">
    <ng-container title class="title">
      <span>Notification Preferences</span>
    </ng-container>
    <ng-container mainMenuButton class="menu-items">
      <img
        class="menu-button"
        src="assets/icons/menu-24.svg"
        alt="menu"
        (click)="header.toggleNav()"
        ctAutomatedSelector="menu__open"
      />
    </ng-container>
  </ct-header>
  <div class="settings-container">
    <div class="heading">
      Notifications
    </div>
    <div class="notification-group" *ngFor="let group of groups$ | async">
      <h2>{{ group.name }}</h2>
      <div
        class="notification"
        ctAutomatedSelector="notification__row"
        *ngFor="let notification of group.preferences; trackBy: notificationTrackBy"
      >
        <span class="name" ctAutomatedSelector="notification__name">{{
          notification.notificationType.prettyPrintName
        }}</span>
        <span
          class="toggle"
          ctAutomatedSelector="notification__emailContainer"
          [class.enabled]="notification.userEmailEnabled"
          (click)="updatePreference(notification)"
        >
          <mat-icon class="clickable" *ngIf="notification.notificationType.globalEmailEnabled && !notification.loading"
            >email</mat-icon
          >
          <mat-spinner diameter="24" *ngIf="notification.loading"> </mat-spinner>
        </span>
      </div>
    </div>
  </div>
</div>
