/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./long-escape.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./long-escape.component";
var styles_LongEscapeComponent = [i0.styles];
var RenderType_LongEscapeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LongEscapeComponent, data: {} });
export { RenderType_LongEscapeComponent as RenderType_LongEscapeComponent };
function View_LongEscapeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "instructions"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Hold "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "key"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ESC"])), (_l()(), i1.ɵted(-1, null, [" to go back"]))], null, null); }
function View_LongEscapeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "instructions"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Let Go!"]))], null, null); }
function View_LongEscapeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "long-escape"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LongEscapeComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LongEscapeComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, ":svg:svg", [["class", "progress__svg"], ["height", "24"], ["viewBox", "0 0 24 24"], ["width", "24"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, ":svg:circle", [["class", "progress__meter"], ["cx", "12"], ["cy", "12"], ["stroke-width", "3"]], [[1, "r", 0]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, ":svg:circle", [["class", "progress__value"], ["cx", "12"], ["cy", "12"], ["stroke-width", "3"]], [[4, "strokeDasharray", null], [4, "strokeDashoffset", null], [1, "r", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.ngIf < _co.circumference); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.ngIf === _co.circumference); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = 9; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.circumference; var currVal_4 = _v.context.ngIf; var currVal_5 = 9; _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_LongEscapeComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_LongEscapeComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.dashOffset$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LongEscapeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ct-long-escape", [], null, null, null, View_LongEscapeComponent_0, RenderType_LongEscapeComponent)), i1.ɵdid(1, 114688, null, 0, i3.LongEscapeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LongEscapeComponentNgFactory = i1.ɵccf("ct-long-escape", i3.LongEscapeComponent, View_LongEscapeComponent_Host_0, {}, {}, []);
export { LongEscapeComponentNgFactory as LongEscapeComponentNgFactory };
