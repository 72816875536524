import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Optional, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-select-date-range',
  styleUrls: ['./select-date-range.component.scss'],
  templateUrl: './select-date-range.component.html',
})
export class SelectDateRangeComponent implements OnInit {
  public selectedDates: [Date, Date];
  public buttonText = 'export';
  @Output() public selectedPayload = new EventEmitter();
  constructor(@Optional() private matDialogRef: MatDialogRef<SelectDateRangeComponent>) {}

  public ngOnInit() {}

  public selected() {
    if (!this.selectedDates || !this.selectedDates[0] || !this.selectedDates[1]) {
      return;
    }
    this.selectedPayload.emit(this.selectedDates);
    if (this.matDialogRef) {
      this.matDialogRef.close(this.selectedDates);
    }
  }
}
