import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { LMOSiteSummary } from '~proto/site/site_pb';
import { getSumOfProperty } from '~utilities/getSumOfProperty';
import { trackById } from '~utilities/trackById';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-lmo-pinned-yards-card',
  styleUrls: ['./lmo-pinned-yards-card.component.scss'],
  templateUrl: './lmo-pinned-yards-card.component.html',
})
export class LmoPinnedYardsCardComponent implements OnInit {
  public trackById = trackById;
  public getSumOfProperty = getSumOfProperty;
  public pinnedJobs$: Observable<LMOSiteSummary.AsObject[]>;
  public pinnedYards$: Observable<LMOSiteSummary.AsObject[]>;
  public pendingCount$: Observable<number>;
  public needsAttentionCount$: Observable<number>;
  public inboundCount$: Observable<number>;
  public outboundCount$: Observable<number>;
  public onSiteCount$: Observable<number>;
  public alarmCount$: Observable<number>;

  constructor(private siteService: JobSitesService) {}

  public ngOnInit() {
    this.pinnedJobs$ = this.siteService.pinnedSites$.pipe(filter((sites) => !!sites));
    this.pinnedYards$ = this.pinnedJobs$.pipe(map((sites) => sites.filter((site) => site.siteType === 'yard')));
    this.pendingCount$ = this.pinnedYards$.pipe(map((sites) => getSumOfProperty(sites, 'pendingCount')));
    this.inboundCount$ = this.pinnedYards$.pipe(map((sites) => getSumOfProperty(sites, 'inboundCount')));
    this.outboundCount$ = this.pinnedYards$.pipe(map((sites) => getSumOfProperty(sites, 'outboundCount')));
    this.needsAttentionCount$ = this.pinnedYards$.pipe(map((sites) => getSumOfProperty(sites, 'needsAttentionCount')));
    this.onSiteCount$ = this.pinnedYards$.pipe(map((sites) => getSumOfProperty(sites, 'onSiteCount')));
    this.alarmCount$ = this.pinnedYards$.pipe(map((sites) => getSumOfProperty(sites, 'alarmCount')));
  }
}
