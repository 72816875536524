import * as Fuse from 'fuse.js';

type fuseReturn<T> = (tArray: T[], _index: number) => { data: T[]; fuse: Fuse<T, Fuse.FuseOptions<T>> };

export function fuse<T>(searchOptions: Fuse.FuseOptions<T>): fuseReturn<T> {
  return (tArray: T[], _index: number) => {
    return fuseWithOptions(tArray, searchOptions);
  };
}

function fuseWithOptions<T>(tArray: T[], searchOptions: Fuse.FuseOptions<T>) {
  return {
    data: tArray,
    fuse: new Fuse(tArray, searchOptions),
  };
}
