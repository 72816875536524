<div class="long-escape" *ngIf="dashOffset$ | async as dashOffset">
  <span class="instructions" *ngIf="dashOffset < circumference">Hold <span class="key">ESC</span> to go back</span>
  <span class="instructions" *ngIf="dashOffset === circumference">Let Go!</span>
  <div class="spinner">
    <svg width="24" height="24" viewBox="0 0 24 24" class="progress__svg">
      <circle [attr.r]="9" cx="12" cy="12" stroke-width="3" class="progress__meter" />
      <circle
        [style.strokeDasharray]="circumference"
        [style.strokeDashoffset]="dashOffset"
        [attr.r]="9"
        cx="12"
        cy="12"
        stroke-width="3"
        class="progress__value"
      />
    </svg>
  </div>
</div>
