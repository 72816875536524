/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.cbv1.AccountStatus', null, global);
goog.exportSymbol('proto.cbv1.AccountType', null, global);
goog.exportSymbol('proto.cbv1.AttachmentCategory', null, global);
goog.exportSymbol('proto.cbv1.BillingStatus', null, global);
goog.exportSymbol('proto.cbv1.ChargeType', null, global);
goog.exportSymbol('proto.cbv1.DriverStatusType', null, global);
goog.exportSymbol('proto.cbv1.EventType', null, global);
goog.exportSymbol('proto.cbv1.FieldType', null, global);
goog.exportSymbol('proto.cbv1.FuelChargeType', null, global);
goog.exportSymbol('proto.cbv1.IdName', null, global);
goog.exportSymbol('proto.cbv1.ManualChangeType', null, global);
goog.exportSymbol('proto.cbv1.MarketplaceRfqBidderStatus', null, global);
goog.exportSymbol('proto.cbv1.MarketplaceRfqStatus', null, global);
goog.exportSymbol('proto.cbv1.NullableBool', null, global);
goog.exportSymbol('proto.cbv1.NullableDouble', null, global);
goog.exportSymbol('proto.cbv1.NullableInt32', null, global);
goog.exportSymbol('proto.cbv1.NullableInt64', null, global);
goog.exportSymbol('proto.cbv1.NullableString', null, global);
goog.exportSymbol('proto.cbv1.NullableTime', null, global);
goog.exportSymbol('proto.cbv1.OrderOrderBy', null, global);
goog.exportSymbol('proto.cbv1.OrderStatus', null, global);
goog.exportSymbol('proto.cbv1.OrderType', null, global);
goog.exportSymbol('proto.cbv1.PayloadOrderBy', null, global);
goog.exportSymbol('proto.cbv1.PointToPointCostOrderBy', null, global);
goog.exportSymbol('proto.cbv1.RequirementLevel', null, global);
goog.exportSymbol('proto.cbv1.Role', null, global);
goog.exportSymbol('proto.cbv1.SiteAvailability', null, global);
goog.exportSymbol('proto.cbv1.SiteEventType', null, global);
goog.exportSymbol('proto.cbv1.SiteOrderBy', null, global);
goog.exportSymbol('proto.cbv1.SiteStatus', null, global);
goog.exportSymbol('proto.cbv1.SiteType', null, global);
goog.exportSymbol('proto.cbv1.StockOrderBy', null, global);
goog.exportSymbol('proto.cbv1.StockStatus', null, global);
goog.exportSymbol('proto.cbv1.TaskStatus', null, global);
goog.exportSymbol('proto.cbv1.TaskType', null, global);
goog.exportSymbol('proto.cbv1.UserPermissions', null, global);
goog.exportSymbol('proto.cbv1.UsersActionType', null, global);
goog.exportSymbol('proto.cbv1.VendorContractTravelCostType', null, global);
goog.exportSymbol('proto.cbv1.WeekDay', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.IdName = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.IdName, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.IdName.displayName = 'proto.cbv1.IdName';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.IdName.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.IdName.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.IdName} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.IdName.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.IdName}
 */
proto.cbv1.IdName.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.IdName;
  return proto.cbv1.IdName.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.IdName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.IdName}
 */
proto.cbv1.IdName.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.IdName.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.IdName.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.IdName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.IdName.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.IdName.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.IdName.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.IdName.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.IdName.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.NullableString = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.NullableString, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.NullableString.displayName = 'proto.cbv1.NullableString';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.NullableString.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.NullableString.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.NullableString} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NullableString.toObject = function(includeInstance, msg) {
  var f, obj = {
    valid: jspb.Message.getFieldWithDefault(msg, 1, false),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.NullableString}
 */
proto.cbv1.NullableString.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.NullableString;
  return proto.cbv1.NullableString.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.NullableString} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.NullableString}
 */
proto.cbv1.NullableString.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.NullableString.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.NullableString.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.NullableString} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NullableString.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool valid = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.NullableString.prototype.getValid = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.NullableString.prototype.setValid = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.cbv1.NullableString.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.NullableString.prototype.setValue = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.NullableInt64 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.NullableInt64, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.NullableInt64.displayName = 'proto.cbv1.NullableInt64';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.NullableInt64.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.NullableInt64.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.NullableInt64} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NullableInt64.toObject = function(includeInstance, msg) {
  var f, obj = {
    valid: jspb.Message.getFieldWithDefault(msg, 1, false),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.NullableInt64}
 */
proto.cbv1.NullableInt64.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.NullableInt64;
  return proto.cbv1.NullableInt64.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.NullableInt64} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.NullableInt64}
 */
proto.cbv1.NullableInt64.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.NullableInt64.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.NullableInt64.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.NullableInt64} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NullableInt64.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional bool valid = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.NullableInt64.prototype.getValid = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.NullableInt64.prototype.setValid = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 value = 2;
 * @return {number}
 */
proto.cbv1.NullableInt64.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.NullableInt64.prototype.setValue = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.NullableDouble = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.NullableDouble, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.NullableDouble.displayName = 'proto.cbv1.NullableDouble';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.NullableDouble.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.NullableDouble.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.NullableDouble} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NullableDouble.toObject = function(includeInstance, msg) {
  var f, obj = {
    valid: jspb.Message.getFieldWithDefault(msg, 1, false),
    value: +jspb.Message.getFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.NullableDouble}
 */
proto.cbv1.NullableDouble.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.NullableDouble;
  return proto.cbv1.NullableDouble.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.NullableDouble} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.NullableDouble}
 */
proto.cbv1.NullableDouble.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.NullableDouble.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.NullableDouble.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.NullableDouble} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NullableDouble.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional bool valid = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.NullableDouble.prototype.getValid = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.NullableDouble.prototype.setValid = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.cbv1.NullableDouble.prototype.getValue = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.cbv1.NullableDouble.prototype.setValue = function(value) {
  jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.NullableTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.NullableTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.NullableTime.displayName = 'proto.cbv1.NullableTime';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.NullableTime.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.NullableTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.NullableTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NullableTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    valid: jspb.Message.getFieldWithDefault(msg, 1, false),
    time: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.NullableTime}
 */
proto.cbv1.NullableTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.NullableTime;
  return proto.cbv1.NullableTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.NullableTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.NullableTime}
 */
proto.cbv1.NullableTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.NullableTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.NullableTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.NullableTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NullableTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool valid = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.NullableTime.prototype.getValid = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.NullableTime.prototype.setValid = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string time = 2;
 * @return {string}
 */
proto.cbv1.NullableTime.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.NullableTime.prototype.setTime = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.NullableBool = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.NullableBool, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.NullableBool.displayName = 'proto.cbv1.NullableBool';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.NullableBool.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.NullableBool.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.NullableBool} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NullableBool.toObject = function(includeInstance, msg) {
  var f, obj = {
    valid: jspb.Message.getFieldWithDefault(msg, 1, false),
    bool: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.NullableBool}
 */
proto.cbv1.NullableBool.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.NullableBool;
  return proto.cbv1.NullableBool.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.NullableBool} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.NullableBool}
 */
proto.cbv1.NullableBool.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBool(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.NullableBool.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.NullableBool.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.NullableBool} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NullableBool.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBool();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool valid = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.NullableBool.prototype.getValid = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.NullableBool.prototype.setValid = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool bool = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.NullableBool.prototype.getBool = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.NullableBool.prototype.setBool = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.NullableInt32 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.NullableInt32, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.NullableInt32.displayName = 'proto.cbv1.NullableInt32';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.NullableInt32.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.NullableInt32.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.NullableInt32} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NullableInt32.toObject = function(includeInstance, msg) {
  var f, obj = {
    valid: jspb.Message.getFieldWithDefault(msg, 1, false),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.NullableInt32}
 */
proto.cbv1.NullableInt32.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.NullableInt32;
  return proto.cbv1.NullableInt32.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.NullableInt32} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.NullableInt32}
 */
proto.cbv1.NullableInt32.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.NullableInt32.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.NullableInt32.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.NullableInt32} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NullableInt32.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional bool valid = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.NullableInt32.prototype.getValid = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.NullableInt32.prototype.setValid = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.cbv1.NullableInt32.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.NullableInt32.prototype.setValue = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.cbv1.OrderOrderBy = {
  ORDER_ORDERBY_ID_DESC: 0,
  ORDER_ORDERBY_ID_ASC: 1,
  ORDER_ORDERBY_UPDATED_ASC: 2,
  ORDER_ORDERBY_UPDATED_DESC: 3,
  ORDER_ORDERBY_CREATED_DESC: 4,
  ORDER_ORDERBY_CREATED_ASC: 5,
  ORDER_ORDERBY_STATUS_DESC: 6,
  ORDER_ORDERBY_STATUS_ASC: 7,
  ORDER_ORDERBY_SCHEDULED_AT_DESC: 8,
  ORDER_ORDERBY_SCHEDULED_AT_ASC: 9
};

/**
 * @enum {number}
 */
proto.cbv1.OrderStatus = {
  ORDER_STATUS_INVALID: 0,
  ORDER_STATUS_CONFIRMATION_NEEDED: 1,
  ORDER_STATUS_PENDING: 2,
  ORDER_STATUS_DISPATCHED: 3,
  ORDER_STATUS_DRIVER_ACCEPTED: 4,
  ORDER_STATUS_DECLINED: 5,
  ORDER_STATUS_COMPLETED: 6,
  ORDER_STATUS_CANCELLED: 7,
  ORDER_STATUS_DRIVER_REJECTED: 8
};

/**
 * @enum {number}
 */
proto.cbv1.MarketplaceRfqStatus = {
  RFQ_STATUS_INVALID: 0,
  RFQ_STATUS_SCHEDULED: 1,
  RFQ_STATUS_BIDDING_OPEN: 2,
  RFQ_STATUS_BIDDING_CLOSED: 3,
  RFQ_STATUS_AWARDED: 4,
  RFQ_STATUS_CANCELLED: 5
};

/**
 * @enum {number}
 */
proto.cbv1.MarketplaceRfqBidderStatus = {
  RFQ_BIDDER_STATUS_INVALID: 0,
  RFQ_BIDDER_STATUS_OPEN_TO_BIDDING: 1,
  RFQ_BIDDER_STATUS_NOT_BIDDING: 2
};

/**
 * @enum {number}
 */
proto.cbv1.OrderType = {
  ORDER_TYPE_INVALID: 0,
  ORDER_TYPE_DELIVERY: 1,
  ORDER_TYPE_PICKUP: 2,
  ORDER_TYPE_LABOR: 3,
  ORDER_TYPE_DELIVERY_AND_RETURN: 4,
  ORDER_TYPE_CUSTOM: 5
};

/**
 * @enum {number}
 */
proto.cbv1.StockOrderBy = {
  STOCK_ORDERBY_ID_DESC: 0,
  STOCK_ORDERBY_ID_ASC: 1,
  STOCK_ORDERBY_UPDATED_ASC: 2,
  STOCK_ORDERBY_UPDATED_DESC: 3,
  STOCK_ORDERBY_CREATED_DESC: 4,
  STOCK_ORDERBY_CREATED_ASC: 5,
  STOCK_ORDERBY_QUANTITY_DESC: 6,
  STOCK_ORDERBY_QUANTITY_ASC: 7,
  STOCK_ORDERBY_MAX_QUANTITY_DESC: 8,
  STOCK_ORDERBY_MAX_QUANTITY_ASC: 9
};

/**
 * @enum {number}
 */
proto.cbv1.SiteStatus = {
  SITE_STATUS_INVALID: 0,
  SITE_STATUS_PLANNING: 1,
  SITE_STATUS_DRILLING: 2,
  SITE_STATUS_COMPLETION: 3,
  SITE_STATUS_PRODUCTION: 4,
  SITE_STATUS_WORK_OVER: 5,
  SITE_STATUS_NONE: 6
};

/**
 * @enum {number}
 */
proto.cbv1.SiteAvailability = {
  SITE_AVAILABILITY_INVALID: 0,
  SITE_AVAILABILITY_AVAILABLE: 1,
  SITE_AVAILABILITY_UNAVAILABLE: 2
};

/**
 * @enum {number}
 */
proto.cbv1.SiteOrderBy = {
  SITE_ORDERBY_NAME_DESC: 0,
  SITE_ORDERBY_NAME_ASC: 1,
  SITE_ORDERBY_ID_DESC: 2,
  SITE_ORDERBY_ID_ASC: 3,
  SITE_ORDERBY_UPDATED_DESC: 4,
  SITE_ORDERBY_UPDATED_ASC: 5,
  SITE_ORDERBY_CREATED_DESC: 6,
  SITE_ORDERBY_CREATED_ASC: 7,
  SITE_ORDERBY_STATUS_DESC: 8,
  SITE_ORDERBY_STATUS_ASC: 9
};

/**
 * @enum {number}
 */
proto.cbv1.PayloadOrderBy = {
  PAYLOAD_ORDERBY_NAME_DESC: 0,
  PAYLOAD_ORDERBY_NAME_ASC: 1,
  PAYLOAD_ORDERBY_ID_DESC: 2,
  PAYLOAD_ORDERBY_ID_ASC: 3,
  PAYLOAD_ORDERBY_UPDATED_ASC: 4,
  PAYLOAD_ORDERBY_UPDATED_DESC: 5,
  PAYLOAD_ORDERBY_CREATED_DESC: 6,
  PAYLOAD_ORDERBY_CREATED_ASC: 7
};

/**
 * @enum {number}
 */
proto.cbv1.TaskStatus = {
  TASK_STATUS_INVALID: 0,
  TASK_STATUS_QUEUED: 1,
  TASK_STATUS_IN_PROGRESS: 2,
  TASK_STATUS_COMPLETED: 3,
  TASK_STATUS_CANCELLED: 4
};

/**
 * @enum {number}
 */
proto.cbv1.TaskType = {
  TASK_TYPE_INVALID: 0,
  TASK_TYPE_DROPOFF: 1,
  TASK_TYPE_PICKUP: 2,
  TASK_TYPE_RETURN_MATERIALS: 3,
  TASK_TYPE_OTHER: 4,
  TASK_TYPE_ASSET_PICKUP: 5,
  TASK_TYPE_AD_HOC: 6
};

/**
 * @enum {number}
 */
proto.cbv1.AccountType = {
  ACCOUNT_TYPE_INVALID: 0,
  ACCOUNT_TYPE_LMO: 1,
  ACCOUNT_TYPE_TRUCKING_VENDOR: 2,
  ACCOUNT_TYPE_PAYLOAD_VENDOR: 3,
  ACCOUNT_TYPE_SHALEAPPS: 4,
  ACCOUNT_TYPE_SERVICE_COORDINATOR: 5,
  ACCOUNT_TYPE_OTHER: 6
};

/**
 * @enum {number}
 */
proto.cbv1.Role = {
  ROLE_INVALID: 0,
  ROLE_ADMIN: 1,
  ROLE_DEFAULT: 2,
  ROLE_DRIVER: 3,
  ROLE_VIEW_ONLY: 4
};

/**
 * @enum {number}
 */
proto.cbv1.DriverStatusType = {
  DRIVER_STATUS_TYPE_INVALID: 0,
  DRIVER_STATUS_TYPE_OFFLINE: 1,
  DRIVER_STATUS_TYPE_ONLINE: 2,
  DRIVER_STATUS_TYPE_ACTION_NEEDED: 3,
  DRIVER_STATUS_TYPE_UNREACHABLE: 4
};

/**
 * @enum {number}
 */
proto.cbv1.EventType = {
  INSERT: 0,
  UPDATE: 1,
  DELETE: 2
};

/**
 * @enum {number}
 */
proto.cbv1.SiteEventType = {
  SITE_EVENT_TYPE_NA: 0,
  SITE_EVENT_TYPE_EXIT: 1,
  SITE_EVENT_TYPE_ENTER: 2,
  SITE_EVENT_TYPE_INSIDE: 3,
  SITE_EVENT_TYPE_OUTSIDE: 4,
  SITE_EVENT_TYPE_CROSS: 5
};

/**
 * @enum {number}
 */
proto.cbv1.RequirementLevel = {
  REQUIREMENT_OPTIONAL: 0,
  REQUIREMENT_REQUIRED: 1,
  REQUIREMENT_CONDITIONAL: 2,
  REQUIREMENT_OTHER: 3
};

/**
 * @enum {number}
 */
proto.cbv1.SiteType = {
  SITE_TYPE_JOB: 0,
  SITE_TYPE_YARD: 1,
  SITE_TYPE_MINE: 2,
  SITE_TYPE_OTHER: 3
};

/**
 * @enum {number}
 */
proto.cbv1.StockStatus = {
  STOCK_STATUS_DEPLETED: 0,
  STOCK_STATUS_MAXCAPACITY: 1,
  STOCK_STATUS_OKAY: 2
};

/**
 * @enum {number}
 */
proto.cbv1.AccountStatus = {
  ACCOUNT_STATUS_ACTIVE: 0,
  ACCOUNT_STATUS_ARCHIVED: 1
};

/**
 * @enum {number}
 */
proto.cbv1.FieldType = {
  OTHER_FIELD_TYPE: 0,
  SITE_FIELD_TYPE: 1,
  ORDER_FIELD_TYPE: 2,
  PAYLOAD_FIELD_TYPE: 3,
  ACCOUNT_FIELD_TYPE: 4
};

/**
 * @enum {number}
 */
proto.cbv1.VendorContractTravelCostType = {
  UNKNOWN: 0,
  MILEAGE_BRACKET: 1,
  PER_HOUR: 2,
  PER_MILE: 3,
  FIXED_POINT: 4
};

/**
 * @enum {number}
 */
proto.cbv1.AttachmentCategory = {
  ATTACHMENT_CATEGORY_INVALID: 0,
  ATTACHMENT_CATEGORY_BOL_IMAGE: 1,
  ATTACHMENT_CATEGORY_TICKET_IMAGE: 2,
  ATTACHMENT_CATEGORY_OTHER_IMAGE: 3
};

/**
 * @enum {number}
 */
proto.cbv1.WeekDay = {
  WEEK_DAY_INVALID: 0,
  WEEK_DAY_MONDAY: 1,
  WEEK_DAY_TUESDAY: 2,
  WEEK_DAY_WEDNESDAY: 3,
  WEEK_DAY_THURSDAY: 4,
  WEEK_DAY_FRIDAY: 5,
  WEEK_DAY_SATURDAY: 6,
  WEEK_DAY_SUNDAY: 7
};

/**
 * @enum {number}
 */
proto.cbv1.PointToPointCostOrderBy = {
  POINTTOPOINTCOST_ORDER_BY_ORIGIN_CITY_ASC: 0,
  POINTTOPOINTCOST_ORDER_BY_ORIGIN_STATE_ASC: 1,
  POINTTOPOINTCOST_ORDER_BY_ORIGIN_ZIP_ASC: 2,
  POINTTOPOINTCOST_ORDER_BY_DESTINATION_CITY_ASC: 3,
  POINTTOPOINTCOST_ORDER_BY_DESTINATION_STATE_ASC: 4,
  POINTTOPOINTCOST_ORDER_BY_DESTINATION_ZIP_ASC: 5,
  POINTTOPOINTCOST_ORDER_BY_COST_ASC: 6,
  POINTTOPOINTCOST_ORDER_BY_ORIGIN_CITY_DESC: 7,
  POINTTOPOINTCOST_ORDER_BY_ORIGIN_STATE_DESC: 8,
  POINTTOPOINTCOST_ORDER_BY_ORIGIN_ZIP_DESC: 9,
  POINTTOPOINTCOST_ORDER_BY_DESTINATION_CITY_DESC: 10,
  POINTTOPOINTCOST_ORDER_BY_DESTINATION_STATE_DESC: 11,
  POINTTOPOINTCOST_ORDER_BY_DESTINATION_ZIP_DESC: 12,
  POINTTOPOINTCOST_ORDER_BY_COST_DESC: 13
};

/**
 * @enum {number}
 */
proto.cbv1.UserPermissions = {
  USER_PERMISSIONS_INVALID: 0,
  USER_PERMISSIONS_CAN_APPROVE_TICKET: 1,
  USER_PERMISSIONS_CAN_APPROVE_INVOICE: 2,
  USER_PERMISSIONS_CAN_BULK_EXPORT: 3,
  USER_PERMISSIONS_CAN_CREATE_COST_CENTER: 4,
  USER_PERMISSIONS_CAN_CRUD_USERS: 5
};

/**
 * @enum {number}
 */
proto.cbv1.ChargeType = {
  LMO_CHARGE_TYPE_INVALID: 0,
  LMO_CHARGE_TYPE_LINEHAUL: 1,
  LMO_CHARGE_TYPE_DEADHEAD: 2,
  LMO_CHARGE_TYPE_UNLOADING_DETENTION: 3,
  LMO_CHARGE_TYPE_LOADING_DETENTION: 4,
  LMO_CHARGE_TYPE_FUEL_SURCHARGE: 5,
  LMO_CHARGE_TYPE_ACCESSORIAL: 6,
  LMO_CHARGE_TYPE_BACK_HAUL: 7
};

/**
 * @enum {number}
 */
proto.cbv1.BillingStatus = {
  BILLING_STATUS_TYPE_INVALID: 0,
  BILLING_STATUS_TYPE_NOT_BILLED: 1,
  BILLING_STATUS_TYPE_APPROVED_BY_VENDOR: 2,
  BILLING_STATUS_TYPE_APPROVED_BY_LMO: 3,
  BILLING_STATUS_TYPE_BILLED: 4,
  BILLING_STATUS_TYPE_APPROVED: 5
};

/**
 * @enum {number}
 */
proto.cbv1.ManualChangeType = {
  CHANGE_TYPE_INVALID: 0,
  CHANGE_TYPE_TRIP: 1,
  CHANGE_TYPE_BOL: 2,
  CHANGE_TYPE_PRICING: 3,
  CHANGE_TYPE_MANUAL: 4
};

/**
 * @enum {number}
 */
proto.cbv1.FuelChargeType = {
  FUEL_CHARGE_TYPE_INVALID: 0,
  FUEL_CHARGE_TYPE_PER_MILE_FIXED: 1,
  FUEL_CHARGE_TYPE_PERCENT_HIKE: 2
};

/**
 * @enum {number}
 */
proto.cbv1.UsersActionType = {
  USER_ACTION_INVALID: 0,
  USER_ACTION_SEE_STAY_ON_TOP_OF_DISPATCHES_POP_UP: 1
};

goog.object.extend(exports, proto.cbv1);
