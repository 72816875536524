import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from '~services/auth.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sa-signin-with-custom-token',
  styleUrls: ['./signin-with-custom-token.component.scss'],
  templateUrl: './signin-with-custom-token.component.html',
})
export class SigninWithCustomTokenComponent implements OnInit {
  public working$ = new BehaviorSubject(true);
  constructor(private authService: AuthService, private route: ActivatedRoute, private snackbar: MatSnackBar) {
    this.authService.idToken$.pipe(filter((token) => !!token)).subscribe((token) => {
      if (token) {
        this.authService.redirectIfLoggedIn();
      }
    });
  }

  public async ngOnInit() {
    const token = this.route.snapshot.paramMap.get('jwt');
    if (token) {
      try {
        await this.authService.loginWithCustomToken(token);
      } catch (error) {
        this.snackbar.open(error.message, null, {
          duration: 4000,
          panelClass: 'snackbar-error',
        });
        this.working$.next(false);
      }
    } else {
      this.snackbar.open('Invalid Token, please login again', null, {
        duration: 4000,
        panelClass: 'snackbar-error',
      });
      this.working$.next(false);
    }
  }
}
