import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogStreamerRequest } from '~proto/googledialog/dialog_pb';
import { DialogAPI } from '~proto/googledialog/dialog_pb_service';
import * as i0 from "@angular/core";
import * as i1 from "./services/grpc.service";
var ChatbotService = /** @class */ (function () {
    function ChatbotService(grpcService) {
        this.grpcService = grpcService;
        this.onRight$$ = new BehaviorSubject(true);
        this.chat$$ = new BehaviorSubject([]);
    }
    Object.defineProperty(ChatbotService.prototype, "chat$", {
        get: function () {
            return this.chat$$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatbotService.prototype, "onRight$", {
        get: function () {
            return this.onRight$$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    ChatbotService.prototype.sendMessage = function (message) {
        var _this = this;
        var request = new DialogStreamerRequest();
        this.chat$$.next(tslib_1.__spread(this.chat$$.value, [
            { localUrl: '', localUrlText: '', message: message, statusUnix: '', orderStatus: '', sender: 'client' },
        ]));
        request.setMessage(message);
        request.setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
        return this.grpcService
            .invoke$(DialogAPI.clientMessageNoStreaming, request)
            .pipe(map(function (response) { return response.toObject(); }))
            .subscribe(function (responseMessage) {
            _this.chat$$.next(tslib_1.__spread(_this.chat$$.value, [tslib_1.__assign({}, responseMessage, { sender: 'bot' })]));
        });
    };
    ChatbotService.prototype.onRight = function (isOnRight) {
        this.onRight$$.next(isOnRight);
    };
    ChatbotService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChatbotService_Factory() { return new ChatbotService(i0.ɵɵinject(i1.GrpcService)); }, token: ChatbotService, providedIn: "root" });
    return ChatbotService;
}());
export { ChatbotService };
