import * as tslib_1 from "tslib";
import { EventEmitter, OnInit, TemplateRef, } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { trackById } from '~utilities/trackById';
var CreateOrderCostCenterSelectorComponent = /** @class */ (function () {
    function CreateOrderCostCenterSelectorComponent(recentService, matDialog, costCenterService, snackBar, constantsService) {
        this.recentService = recentService;
        this.matDialog = matDialog;
        this.costCenterService = costCenterService;
        this.snackBar = snackBar;
        this.constantsService = constantsService;
        // private topResult: string;
        this.trackById = trackById;
        this.additionalRecentCCs$$ = new BehaviorSubject([]);
        this.selected = new EventEmitter();
        this.invalid = false;
        this.textChanges$$ = new BehaviorSubject(null);
        this.newCostCenterForm = new FormControl();
        this.newCostCenterId = new FormControl();
    }
    Object.defineProperty(CreateOrderCostCenterSelectorComponent.prototype, "businessLineId", {
        set: function (businessLineId) {
            this.businessLine = businessLineId;
            this.textChanges$$.next('');
        },
        enumerable: true,
        configurable: true
    });
    CreateOrderCostCenterSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.costCenterName$ = combineLatest([
            this.recentService.costCenters$.pipe(map(function (costCenters) { return _this.constantsService.sortDataValues(costCenters); })),
            this.textChanges$$.pipe(startWith('')),
            this.additionalRecentCCs$$,
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 3), costCenters = _b[0], text = _b[1], additionalRecentCCs = _b[2];
            if (!costCenters) {
                return [];
            }
            var asArray = [
                {
                    costCenters: costCenters,
                    name: 'search results',
                },
            ];
            if (!text || text === '') {
                return asArray.filter(function (group) { return group.costCenters && group.costCenters.length > 0; });
            }
            return asArray
                .map(function (group) { return ({
                costCenters: (group.costCenters || []).filter(function (s) { return s.name.toLowerCase().includes(text.toLowerCase()); }),
                name: group.name,
            }); })
                .filter(function (group) { return group.costCenters.length > 0; });
        }));
        this.showCreateCCButton$ = combineLatest([
            this.recentService.costCenters$,
            this.textChanges$$.pipe(startWith(null)),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), costCenterNames = _b[0], text = _b[1];
            if (!text || !text.length) {
                return false;
            }
            return (costCenterNames || []).every(function (po) { return po.name !== text; });
        }));
        this.textChanges$$.pipe(debounceTime(250)).subscribe(function (text) {
            _this.recentService.searchCostCenters(text, _this.businessLine);
        });
    };
    CreateOrderCostCenterSelectorComponent.prototype.selectCostCenterName = function (costCenter) {
        if (costCenter) {
            this.selected.emit({ costCenter: costCenter.name, costCenterId: costCenter.id });
        }
        else {
            this.selected.emit({ costCenter: '', costCenterId: -1 });
        }
        this.smartDropdown.close();
    };
    CreateOrderCostCenterSelectorComponent.prototype.addCostCenterName = function () {
        var _this = this;
        this.newCostCenterFormDialog = this.matDialog.open(this.newCostCenterFormModal, {
            maxWidth: '500px;',
        });
        this.newCostCenterFormDialog.afterOpened().subscribe(function () {
            _this.newCostCenterForm.setValue(_this.textChanges$$.value || '');
            _this.newCostCenterId.setValue(0);
        });
    };
    CreateOrderCostCenterSelectorComponent.prototype.inputTextChange = function (event) {
        this.textChanges$$.next(event.trim());
    };
    CreateOrderCostCenterSelectorComponent.prototype.createCostCenter = function () {
        var _this = this;
        var costCenterName = this.newCostCenterForm.value;
        this.costCenterService.createCostCenter$(costCenterName, []).subscribe(function (costCenter) {
            _this.closeTab();
            _this.snackBar.open("Cost Center has been created", null, {
                duration: 2500,
            });
        }, function (err) {
            _this.snackBar.open('Something Went Wrong', null, {
                duration: 2500,
                panelClass: ['snackbar-error'],
            });
        });
    };
    CreateOrderCostCenterSelectorComponent.prototype.closeTab = function () {
        this.newCostCenterFormDialog.close();
        this.newCostCenterFormDialog = null;
    };
    return CreateOrderCostCenterSelectorComponent;
}());
export { CreateOrderCostCenterSelectorComponent };
