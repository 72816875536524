/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./input.component";
var styles_InputComponent = [i0.styles];
var RenderType_InputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputComponent, data: {} });
export { RenderType_InputComponent as RenderType_InputComponent };
function View_InputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
export function View_InputComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { suffixRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "input-wrapper"]], [[2, "static-label", null], [4, "height", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["suffixRef", 1]], null, 1, "div", [["class", "suffix"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.label; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.staticLabel !== false); var currVal_1 = (_co.rows ? (((32 + 32) + (_co.rows * 20)) + "px") : "initial"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.suffix; _ck(_v, 3, 0, currVal_2); }); }
export function View_InputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ct-input", [], null, null, null, View_InputComponent_0, RenderType_InputComponent)), i1.ɵdid(1, 4440064, null, 2, i3.InputComponent, [i1.Renderer2], null, null), i1.ɵqud(603979776, 1, { ctNumber: 0 }), i1.ɵqud(603979776, 2, { ctInput: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InputComponentNgFactory = i1.ɵccf("ct-input", i3.InputComponent, View_InputComponent_Host_0, { suffix: "suffix", staticLabel: "staticLabel", label: "label", rows: "rows" }, {}, ["*"]);
export { InputComponentNgFactory as InputComponentNgFactory };
