import { Routes } from '@angular/router';
import { AccountType } from '~proto/types/types_pb';
import { IsAccountTypeGuard } from '~services/guards/is-account-type.guard';
import { IsAdminRoleTypeGuard } from '~services/guards/is-admin-role-type.guard';
import * as fromRouterConstants from './app-routing.constants';
import * as fromContainers from './containers';
var ɵ0 = { accountTypes: [AccountType.ACCOUNT_TYPE_TRUCKING_VENDOR] }, ɵ1 = { accountTypes: [AccountType.ACCOUNT_TYPE_TRUCKING_VENDOR, AccountType.ACCOUNT_TYPE_LMO] }, ɵ2 = { accountTypes: [AccountType.ACCOUNT_TYPE_LMO] }, ɵ3 = { accountTypes: [AccountType.ACCOUNT_TYPE_TRUCKING_VENDOR, AccountType.ACCOUNT_TYPE_LMO] }, ɵ4 = { accountTypes: [AccountType.ACCOUNT_TYPE_TRUCKING_VENDOR, AccountType.ACCOUNT_TYPE_LMO] }, ɵ5 = { accountTypes: [AccountType.ACCOUNT_TYPE_TRUCKING_VENDOR, AccountType.ACCOUNT_TYPE_LMO] }, ɵ6 = function () { return import("./shaleapps/shaleapps.module.ngfactory").then(function (m) { return m.ShaleappsModuleNgFactory; }); };
var routes = [
    {
        canActivate: [IsAccountTypeGuard],
        data: ɵ0,
        loadChildren: 'src/app/dispatcher/dispatcher.module#DispatcherModule',
        path: 'dispatcher',
    },
    {
        canActivate: [IsAccountTypeGuard, IsAdminRoleTypeGuard],
        data: ɵ1,
        loadChildren: 'src/app/billing/billing.module#BillingModule',
        path: 'billing',
    },
    {
        data: ɵ2,
        loadChildren: 'src/app/lmo/lmo.module#LmoModule',
        path: 'lmo',
    },
    {
        canActivate: [IsAccountTypeGuard],
        component: fromContainers.TrailerManagementComponent,
        data: ɵ3,
        path: 'trailers',
    },
    {
        canActivate: [IsAccountTypeGuard],
        component: fromContainers.AddTrailerComponent,
        data: ɵ4,
        path: 'trailers/add',
    },
    {
        canActivate: [IsAccountTypeGuard],
        component: fromContainers.EditTrailerComponent,
        data: ɵ5,
        path: "trailers/:" + fromRouterConstants.TRAILER_ID + "/edit",
    },
    {
        component: fromContainers.NotificationPreferencesComponent,
        path: 'notification-preferences',
    },
    {
        component: fromContainers.SigninWithCustomTokenComponent,
        path: 'sso/:jwt',
    },
    {
        component: fromContainers.ResetPasswordComponent,
        path: 'reset-password',
    },
    {
        component: fromContainers.LoginPageComponent,
        path: 'login',
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
    },
    {
        component: fromContainers.UpdatePasswordComponent,
        path: 'update-password',
    },
    { path: 'shaleapps', loadChildren: ɵ6 },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
