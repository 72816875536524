import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, ContentChild, Input, OnInit } from '@angular/core';
import { MatButton } from '@angular/material';
import { ButtonComponent } from '~common/button/button.component';

@Component({
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms ease-in', style({ opacity: 1 }))]),
      transition(':leave', [animate('200ms ease-in', style({ opacity: 0 }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-network-active-button',
  styleUrls: ['./network-active-button.component.scss'],
  templateUrl: './network-active-button.component.html',
})
export class NetworkActiveButtonComponent implements OnInit {
  private _active = false;
  @ContentChild(MatButton, { static: false }) private button: MatButton;
  @ContentChild(ButtonComponent, { static: false }) private ctButton: ButtonComponent;
  @Input() public set active(active: boolean) {
    this._active = active;
    this.toggleButtonDisabled();
  }

  public get active() {
    return this._active;
  }

  constructor() {}

  public ngOnInit() {}

  private toggleButtonDisabled() {
    if (this.button) {
      if (this.active) {
        this.button.disabled = true;
      } else {
        this.button.disabled = false;
      }
    }

    if (this.ctButton) {
      if (this.active) {
        this.ctButton.disabled = true;
      } else {
        this.ctButton.disabled = false;
      }
    }
  }
}
