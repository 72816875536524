import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContractsService } from '~lmo/services/contracts.service';
import { UserDetails } from '~proto/billingsettings/settings_pb';
import { VendorContract } from '~proto/contracts/contracts_pb';
import { trackById } from '~utilities/trackById';

interface ContractListItem {
  contractName: string;
  createdDate: number;
  id: number;
  isDefault: boolean;
  payloadNames: string;
  vendorName: string;
  contractType: string;
  zipcodesnotmappedList?: string[];
  isExpired: boolean;
  expirationDate: string;
  createdBy: string | UserDetails.AsObject;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-lmo-trucking-contracts-manage',
  styleUrls: ['./lmo-trucking-contracts-manage.component.scss'],
  templateUrl: './lmo-trucking-contracts-manage.component.html',
})
export class LmoTruckingContractsManageComponent implements OnInit {
  public trackById = trackById;
  public contracts$: Observable<ContractListItem[]>;

  constructor(private contractService: ContractsService) {}

  public ngOnInit() {
    this.contracts$ = this.contractService.allContracts$.pipe(
      map((contracts) => contracts.map(convertProtoContractToListItem)),
    );
  }
}

function convertProtoContractToListItem(contract: VendorContract.AsObject, ...args: any[]): ContractListItem {
  return {
    contractName: contract.name,
    contractType: contract.contractType ? contract.contractType.name : '-',
    createdBy: contract.createdBy && contract.createdBy.name ? contract.createdBy.name : '-',
    createdDate: contract.createdUnix * 1000,
    expirationDate: contract.expirationTime.valid ? contract.expirationTime.time : null,
    id: contract.id,
    isDefault: false,
    isExpired: contract.isExpired,
    payloadNames: contract.payloadtypesList.map((type) => type.name).join(', '),
    vendorName: contract.vendor ? contract.vendor.name : 'Not implemented yet',
    zipcodesnotmappedList: contract.zipcodesnotmappedList,
  };
}
