import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoleService } from './../role.service';

@Injectable({
  providedIn: 'root',
})
export class IsAdminRoleTypeGuard implements CanActivate {
  constructor(
    private userRole: RoleService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  public canActivate(): Observable<boolean> | boolean {
    return this.userRole.userRole$.pipe(
      map( (role) => {
        if (role === 1) {
          return true;
        } else {
          this.snackBar.open('User not authorized to access the page', null, {
            duration: 2000,
          });
          setTimeout(() => this.router.navigate(['/', 'login']), 100);
        }
      })
    );
  }
}
