import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, filter, finalize, map, startWith, take, takeUntil } from 'rxjs/operators';
import { BusinessLineService } from '~lmo/services/business-line.service';
import { UpdateNewBusinessLineRequest, UpdateNewBusinessLineResponse } from '~proto/businessline/businessline_api_pb';
import { BusinessLine } from '~proto/businessline/businessline_pb';
import { CostCenter } from '~proto/order/order_pb';
import { RoleService } from '~services/role.service';
import { RouterStateService } from '~services/router-state.service';
import { trackById } from '~utilities/trackById';
import { UserV2 } from '../../../../proto/user/user/user_pb';
import { UsersService } from '../../services/users.service';
import { CostCenterService } from './../../../billing/services/cost-center.service';

interface FormValue {
  id: number;
  name: string;
  users: Array<string>;
  costCenters: Array<number>;
}

@Component({
  selector: 'ct-lmo-business-line-management-edit',
  styleUrls: ['./lmo-business-line-management-edit.component.scss'],
  templateUrl: './lmo-business-line-management-edit.component.html',
})
export class LmoBusinessLineManagementEditComponent implements OnInit {
  public networkActive$ = new BehaviorSubject(false);
  public formGroup: FormGroup;
  public hide = true;
  public usersList$: Observable<UserV2.AsObject[]>;
  public costCentersList$: Observable<CostCenter.AsObject[]>;
  public trackById = trackById;
  public backUrl$: Observable<string[]>;
  public businessLine: BusinessLine.AsObject;
  public userSearch = new FormControl();
  public costCenterSearch = new FormControl();
  public destroy$$ = new Subject();
  public isBusinessLineCostCenterRelationEnabled$$ = new BehaviorSubject<Boolean>(false);

  constructor(
    private fb: FormBuilder,
    private businessLineService: BusinessLineService,
    private router: Router,
    private snackbar: MatSnackBar,
    public userService: UsersService,
    private routerState: RouterStateService,
    private costCenterService: CostCenterService,
    private roleService: RoleService,
  ) {}

  private formValue(): FormValue {
    return this.formGroup.value;
  }

  public ngOnInit() {
    this.backUrl$ = this.routerState.backUrlOr$(['../../']);
    this.formGroup = this.fb.group({
      costCenters: [''],
      name: [''],
      users: [[]],
    });
    this.setBusinessLine().then();
    this.usersList$ = combineLatest([
      this.userService.users$.pipe(
        takeUntil(this.destroy$$)
      ),
      this.userSearch.valueChanges.pipe(
        takeUntil(this.destroy$$),
        debounceTime(500),
        startWith(''),
      )
    ]).pipe(
      map(([users, filterText]) => {
        return users.filter((user) => user.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0);
      }),
    );
    this.costCentersList$ = combineLatest([
      this.costCenterService.costCenters$.pipe(
        takeUntil(this.destroy$$)
      ),
      this.costCenterSearch.valueChanges.pipe(
        takeUntil(this.destroy$$),
        debounceTime(500),
        startWith(''),
      )
    ]).pipe(
      map(([costCenters, filterText]) => {
        return costCenters.filter((costCenter) => costCenter.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0);
      }),
    );
    this.checkIsBusinessLineCostCenterRelationEnabled();
  }

  public async setBusinessLine() {
    this.businessLine = await this.businessLineService.currentBusinessLine$
      .pipe(
        filter((businessLine) => !!businessLine),
        take(1),
      )
      .toPromise();

    this.businessLineService
      .usersAttachedToBusinessLine$(this.businessLine.id)
      .pipe(filter((users) => !!users))
      .subscribe((users) => {
        this.formGroup.patchValue({
          users: this.getUsersAttachedToBusinessLine(users),
        });
      });

    this.formGroup.get('name').disable();
    this.formGroup.get('users').reset();

    this.formGroup.patchValue({
      costCenters: this.getCostCentersAttachedToBusinessLine(),
      name: this.businessLine.name,
    });
  }

  private getUsersAttachedToBusinessLine(users: UserV2.AsObject[]): Array<string> {
    return users.map((user) => user.authId);
  }

  private getCostCentersAttachedToBusinessLine() {
    return this.businessLine.costCentersList.map((cc) => cc.id);
  }

  public async updateBusinessLine() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    const formValue = this.formValue();

    this.networkActive$.next(true);
    this.updateBusinessLine$(formValue)
      .pipe(
        finalize(() => {
          this.networkActive$.next(false);
        }),
      )
      .subscribe(async () => {
        this.snackbar.open(`${this.businessLine.name} updated!`, null, {
          duration: 2000,
        });

        await this.router.navigate(['/', 'lmo', 'business-lines']);
      });
  }

  private checkIsBusinessLineCostCenterRelationEnabled() {
    this.roleService.isBusinessLineCostCenterRelationEnabled$.subscribe((isBLCCRelationEnabled: Boolean) => {
      this.isBusinessLineCostCenterRelationEnabled$$.next(isBLCCRelationEnabled);
    });
  }

  private updateBusinessLine$(formValue: FormValue): Observable<UpdateNewBusinessLineResponse> {
    const updateBusinessLineRequest = new UpdateNewBusinessLineRequest();
    updateBusinessLineRequest.setId(this.businessLine.id);
    updateBusinessLineRequest.setName(this.businessLine.name);
    updateBusinessLineRequest.setUserIdsList(formValue.users);
    if (this.isBusinessLineCostCenterRelationEnabled$$.value) {
      updateBusinessLineRequest.setCostCenterIdsList(formValue.costCenters);
    }

    return this.businessLineService.updateBusinessLine$(updateBusinessLineRequest);
  }
}
