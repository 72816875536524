import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { trackById } from '~utilities/trackById';
var CreateOrderMaintenanceOrderNumberSelectorComponent = /** @class */ (function () {
    function CreateOrderMaintenanceOrderNumberSelectorComponent(recentService, roleService) {
        this.recentService = recentService;
        this.roleService = roleService;
        this.additionalRecentMaintenanceOrderNumbers$$ = new BehaviorSubject([]);
        this.selected = new EventEmitter();
        this.invalid = false;
        this.textChanges$$ = new BehaviorSubject(null);
        this.isMaintenanceOrderApiEnabled$$ = new BehaviorSubject(false);
        this.trackById = trackById;
    }
    Object.defineProperty(CreateOrderMaintenanceOrderNumberSelectorComponent.prototype, "additionalRecentMaintenanceOrderNumbers", {
        set: function (SOs) {
            if (SOs) {
                this.additionalRecentMaintenanceOrderNumbers$$.next(SOs);
            }
        },
        enumerable: true,
        configurable: true
    });
    CreateOrderMaintenanceOrderNumberSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.maintenanceOrderNumbers$ = combineLatest([
            this.recentService.maintenanceOrders$,
            this.textChanges$$,
            this.additionalRecentMaintenanceOrderNumbers$$,
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 3), maintenanceOrders = _b[0], text = _b[1], additionalRecentMaintenanceOrderNumbers = _b[2];
            if (!maintenanceOrders) {
                return [];
            }
            var asArray = [
                {
                    maintenanceOrderNumbers: maintenanceOrders.searchResults,
                    name: 'search results',
                },
                {
                    maintenanceOrderNumbers: Array.from(new Set(tslib_1.__spread((maintenanceOrders.recentlyUsedList || []), additionalRecentMaintenanceOrderNumbers))),
                    name: 'recently used',
                },
                {
                    maintenanceOrderNumbers: maintenanceOrders.frequentlyUsedList,
                    name: 'frequently used',
                },
            ];
            if (!text || text === '') {
                return asArray.filter(function (group) { return group.maintenanceOrderNumbers && group.maintenanceOrderNumbers.length > 0; });
            }
            return asArray
                .map(function (group) { return ({
                maintenanceOrderNumbers: (group.maintenanceOrderNumbers || []).filter(function (s) { return s.includes(text); }),
                name: group.name,
            }); })
                .filter(function (group) { return group.maintenanceOrderNumbers.length > 0; });
        }));
        this.showCreateMOButton$ = combineLatest([
            this.recentService.maintenanceOrders$,
            this.textChanges$$.pipe(startWith(null)),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), maintenanceOrders = _b[0], text = _b[1];
            if (!text || !text.length) {
                return false;
            }
            if (!maintenanceOrders) {
                return true;
            }
            return ((maintenanceOrders.searchResults || []).every(function (so) { return so !== text; }) &&
                (maintenanceOrders.frequentlyUsedList || []).every(function (so) { return so !== text; }) &&
                (maintenanceOrders.recentlyUsedList || []).every(function (so) { return so !== text; }));
        }));
        this.textChanges$$.pipe(debounceTime(250)).subscribe(function (text) {
            _this.recentService.searchMaintenanceOrders(text);
        });
        this.checkIsMaintenanceOrderApiEnabled();
    };
    CreateOrderMaintenanceOrderNumberSelectorComponent.prototype.selectMaintenanceOrderNumber = function (maintenanceOrderNumber) {
        this.selected.emit({ maintenanceOrderNumber: maintenanceOrderNumber });
        this.smartDropdown.close();
    };
    CreateOrderMaintenanceOrderNumberSelectorComponent.prototype.addMaintenanceOrderNumber = function () {
        if (this.textChanges$$.value && this.textChanges$$.value !== '') {
            this.selectMaintenanceOrderNumber(this.textChanges$$.value);
        }
    };
    CreateOrderMaintenanceOrderNumberSelectorComponent.prototype.inputTextChange = function (event) {
        this.textChanges$$.next(event.trim());
    };
    CreateOrderMaintenanceOrderNumberSelectorComponent.prototype.enterKeyPressed = function () {
        if (!this.isMaintenanceOrderApiEnabled$$.value) {
            if (this.topResult) {
                this.selectMaintenanceOrderNumber(this.topResult);
            }
            else {
                this.addMaintenanceOrderNumber();
            }
        }
    };
    CreateOrderMaintenanceOrderNumberSelectorComponent.prototype.trackByName = function (_number, name) {
        return name;
    };
    CreateOrderMaintenanceOrderNumberSelectorComponent.prototype.checkIsMaintenanceOrderApiEnabled = function () {
        var _this = this;
        this.roleService.isMaintenanceOrderApiEnabled$.subscribe(function (isSalesOrderApiEnabled) {
            _this.isMaintenanceOrderApiEnabled$$.next(isSalesOrderApiEnabled);
        });
    };
    return CreateOrderMaintenanceOrderNumberSelectorComponent;
}());
export { CreateOrderMaintenanceOrderNumberSelectorComponent };
