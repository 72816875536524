import { map } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { VERSION } from '~environments/version';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@angular/service-worker";
var VersioningService = /** @class */ (function () {
    function VersioningService(db, swUpdate) {
        var _this = this;
        this.db = db;
        this.swUpdate = swUpdate;
        this.db
            .doc("cybertron-version/" + environment.environment)
            .valueChanges()
            .pipe(map(function (version) { return version && version.version !== _this.currentVersion; }))
            .subscribe(function (isWrongVersion) {
            if (isWrongVersion) {
                if (swUpdate.isEnabled) {
                    _this.swUpdate.checkForUpdate();
                }
            }
        });
    }
    Object.defineProperty(VersioningService.prototype, "currentVersion", {
        get: function () {
            if (VERSION && VERSION.version && VERSION.hash) {
                return VERSION.version + "." + VERSION.hash;
            }
            return VERSION.version;
        },
        enumerable: true,
        configurable: true
    });
    VersioningService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VersioningService_Factory() { return new VersioningService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.SwUpdate)); }, token: VersioningService, providedIn: "root" });
    return VersioningService;
}());
export { VersioningService };
