import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import { flatten, omit, sort } from 'remeda';
import { BehaviorSubject } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';
import { getBillableTask } from 'src/app/dispatcher/utilities/orderHelpers';
import { LMOUpdateOrderRequest, SubTaskBillingUpdate } from '~proto/order/order_api_pb';
import { BillingStatus, NullableDouble, NullableString, OrderStatus, OrderType } from '~proto/types/types_pb';
import { UserDetailsForIntercomRequest } from '~proto/user/user_api_pb';
import { UserAPI } from '~proto/user/user_api_pb_service';
import { trackById } from '~utilities/trackById';
var LmoEditOrderComponent = /** @class */ (function () {
    function LmoEditOrderComponent(orderService, fb, snackBar, contractService, costCenterService, grpcService, roleService) {
        this.orderService = orderService;
        this.fb = fb;
        this.snackBar = snackBar;
        this.contractService = contractService;
        this.costCenterService = costCenterService;
        this.grpcService = grpcService;
        this.roleService = roleService;
        this.networkActive$ = new BehaviorSubject(false);
        this.orderStatus = OrderStatus;
        this.billingStatus = BillingStatus;
        this.truckingContracts = [];
        this.trackById = trackById;
        this.isCostCenterRequired$$ = new BehaviorSubject(false);
        this.isPurchaseOrderRequired$$ = new BehaviorSubject(false);
        this.isBusinessLineCostCenterRelationEnabled$$ = new BehaviorSubject(false);
        this.businessLineLinked$$ = new BehaviorSubject(null);
        this.isPOValid = 'true';
    }
    LmoEditOrderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadRequiredFieldSettings();
        this.costCenters$ = this.costCenterService.costCenters$;
        this.currentOrder$ = this.orderService.currentOrder$;
        this.formGroup = this.fb.group({
            costCenterId: [null, this.isCostCenterRequired$$.value ? [Validators.required] : ''],
            description: null,
            purchaseOrderName: null,
            ticketNumber: null,
            trailer: null,
            truck: null,
            vendorContractId: null,
        });
        this.currentOrder$
            .pipe(filter(function (order) { return !!order; }), take(1))
            .subscribe(function (order) {
            var billableTask = getBillableTask(order);
            if (!billableTask) {
                return;
            }
            _this.businessLineLinked$$.next(order.businessLine);
            if (_this.isPurchaseOrderRequired$$.value && order.costCenter === '') {
                _this.isCCValid = -1;
            }
            if (order && order.type !== OrderType.ORDER_TYPE_CUSTOM) {
                _this.isPurchaseOrderRequired$$.value
                    ? _this.formGroup.controls['purchaseOrderName'].setValidators(Validators.required)
                    : _this.formGroup.controls['purchaseOrderName'].clearValidators();
            }
            _this.costCenterObj = order.costCenterObj;
            _this.formGroup.patchValue({
                bolNumber: billableTask.billing ? billableTask.billing.bolNumber : null,
                costCenterId: order.costCenterObj ? order.costCenterObj.id : -1,
                description: order.description,
                purchaseOrderName: billableTask.billing ? billableTask.billing.purchaseOrderName : null,
                ticketNumber: order.ticketNumber,
                trailer: order.trailer,
                truck: order.truck,
                vendorContractId: order.vendorContract ? order.vendorContract.id : null,
            });
            _this.siteBillingInfo = getQuantitiesAsFormGroup(order);
            _this.contractService.nonExpiredContracts$.subscribe(function (contracts) {
                _this.truckingContracts = contracts.filter(function (contract) {
                    return contract.vendor.id === order.truckingVendor.id &&
                        (order.contractType == null ||
                            (contract.contractType && order.contractType.id === contract.contractType.id));
                });
            });
            _this.listenCostCenterChanges();
            _this.checkIsBusinessLineCostCenterRelationEnabled();
        });
    };
    LmoEditOrderComponent.prototype.listenCostCenterChanges = function () {
        var _this = this;
        this.formGroup.get('costCenterId').valueChanges.subscribe(function (value) {
            _this.isCCValid = value;
        });
    };
    LmoEditOrderComponent.prototype.selected = function (event) {
        this.formGroup.patchValue(event);
    };
    LmoEditOrderComponent.prototype.isCustomOrder = function (order) {
        return order.type === OrderType.ORDER_TYPE_CUSTOM;
    };
    LmoEditOrderComponent.prototype.updateOrder = function (currentOrder) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var value, order, isCustomOrder, request, mask, siteActualQuantities, subtaskInputs, subtaskActualQuantityUpdates;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        value = this.formGroup.value;
                        this.requiredFieldsCheck(value, currentOrder);
                        if (!this.isCCValid || this.isCCValid === -1 || !this.isPOValid) {
                            this.snackBar.open("\n        " + (this.isCostCenterRequired$$.value && (!this.isCCValid || this.isCCValid === -1) ? 'Cost Center, ' : '') + "\n        " + (this.isPurchaseOrderRequired$$.value && !this.isPOValid ? 'PO Number ' : '') + "\n        fields are mandatory", null, {
                                duration: 3000,
                            });
                            return [2 /*return*/];
                        }
                        if (this.formGroup.invalid) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.currentOrder$.pipe(take(1)).toPromise()];
                    case 1:
                        order = _a.sent();
                        isCustomOrder = this.isCustomOrder(order);
                        this.networkActive$.next(true);
                        request = new LMOUpdateOrderRequest();
                        if (!isCustomOrder) {
                            request.setPurchaseOrderName(value.purchaseOrderName);
                        }
                        request.setTicketNumber(value.ticketNumber);
                        mask = new FieldMask();
                        mask.addPaths('ticketNumber');
                        if (!isCustomOrder) {
                            mask.addPaths('purchaseOrderName');
                        }
                        mask.addPaths('description');
                        if (value.truck) {
                            request.setTruckId(value.truck.id);
                            mask.addPaths('truckId');
                        }
                        if (value.trailer) {
                            request.setTrailerId(value.trailer.id);
                            mask.addPaths('trailerId');
                        }
                        request.setDescription(value.description);
                        mask.addPaths('lmoCostCenterId');
                        console.log('cost center id is,', value.costCenterId);
                        request.setCostCenterId(value.costCenterId);
                        if (order.vendorContract.id !== value.vendorContractId &&
                            order.status !== this.orderStatus.ORDER_STATUS_COMPLETED) {
                            mask.addPaths('vendorContractId');
                            mask.addPaths('vendorContractJson');
                            request.setVendorContractId(value.vendorContractId);
                        }
                        request.setMask(mask);
                        siteActualQuantities = this.siteBillingInfo || [];
                        subtaskInputs = flatten(siteActualQuantities.map(function (site) { return site.subtaskSpecificStuffs; }));
                        subtaskActualQuantityUpdates = subtaskInputs.map(function (subtaskInput) {
                            var update = new SubTaskBillingUpdate();
                            update.setSubTaskId(subtaskInput.subtaskId);
                            var actualQuantity = new NullableDouble();
                            actualQuantity.setValid(subtaskInput.actualQuantity.value !== null);
                            actualQuantity.setValue(subtaskInput.actualQuantity.value);
                            update.setActualQuantity(actualQuantity);
                            if (subtaskInput.bol && subtaskInput.type === 'dropoff') {
                                var bol = new NullableString();
                                bol.setValid(subtaskInput.bol.value !== null);
                                bol.setValue(subtaskInput.bol.value);
                                update.setBolNumber(bol);
                            }
                            return update;
                        });
                        request.setSubtaskBillingUpdateList(flatten(subtaskActualQuantityUpdates));
                        this.orderService
                            .updateOrder$(request)
                            .pipe(finalize(function () { return _this.networkActive$.next(false); }))
                            .subscribe(function () {
                            _this.snackBar.open('Order Updated', null, { duration: 2000 });
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    LmoEditOrderComponent.prototype.requiredFieldsCheck = function (formValue, order) {
        this.isCCValid = this.isCostCenterRequired$$.value ? formValue.costCenterId : 'true';
        if (order && order.type !== OrderType.ORDER_TYPE_CUSTOM) {
            this.isPOValid = this.isPurchaseOrderRequired$$.value ? formValue.purchaseOrderName : 'true';
        }
    };
    LmoEditOrderComponent.prototype.trackBySiteName = function (_, actualValueList) {
        return actualValueList.siteName;
    };
    LmoEditOrderComponent.prototype.trackByPayloadName = function (_, actualQuantities) {
        return actualQuantities.payloadName;
    };
    LmoEditOrderComponent.prototype.checkIsBusinessLineCostCenterRelationEnabled = function () {
        var _this = this;
        this.roleService.isBusinessLineCostCenterRelationEnabled$.subscribe(function (isBLCCRelationEnabled) {
            _this.isBusinessLineCostCenterRelationEnabled$$.next(isBLCCRelationEnabled);
            if (isBLCCRelationEnabled) {
                _this.costCenterService.loadCostCenters(_this.businessLineLinked$$.value);
            }
        });
    };
    LmoEditOrderComponent.prototype.loadRequiredFieldSettings = function () {
        var _this = this;
        var request = new UserDetailsForIntercomRequest();
        this.grpcService.invoke$(UserAPI.UserDetailsForIntercom, request).subscribe(function (response) {
            _this.isCostCenterRequired$$.next(response.toObject().userDetails.isCostCenterRequired);
            _this.isPurchaseOrderRequired$$.next(response.toObject().userDetails.isPurchaseOrderRequired);
        });
    };
    return LmoEditOrderComponent;
}());
export { LmoEditOrderComponent };
var payloadPickupTypes = {
    pickup: true,
    'pickup-payload': true,
};
var payloadDropoffTypes = {
    dropoff: true,
    'dropoff-payload': true,
};
var payloadSubTaskTypes = tslib_1.__assign({}, payloadPickupTypes, payloadDropoffTypes);
function getQuantitiesAsFormGroup(order) {
    if (order.type === OrderType.ORDER_TYPE_CUSTOM) {
        return getQuantitiesAsFormGroupForCustomOrder(order);
    }
    return getQuantitiesAsFormGroupForRegularOrder(order);
}
function getQuantitiesAsFormGroupForCustomOrder(order) {
    var sortedTasks = sort(order.tasksList, function (a, b) { return a.sequence - b.sequence; });
    return sortedTasks
        .map(function (task) {
        var payloadSubTasks = task.subTasksList.filter(function (subtask) { return payloadSubTaskTypes[subtask.type.name]; });
        var subtaskSpecificStuffList = {
            siteName: task.site.name,
            subtaskSpecificStuffs: payloadSubTasks.map(function (subTask) {
                var billingInfo = {
                    actualQuantity: new FormControl(subTask.actualQuantity > Number.MIN_SAFE_INTEGER ? subTask.actualQuantity : null),
                    orderedQuantity: subTask.orderedQuantity,
                    payloadName: subTask.payload.name,
                    payloadUnitAbbrev: subTask.payload.unit.abbreviation,
                    subtaskId: subTask.id,
                    type: getType(subTask.type.name),
                };
                if (billingInfo.type === 'dropoff') {
                    billingInfo.bol = new FormControl(subTask.billing ? subTask.billing.bolNumber : null);
                }
                return billingInfo;
            }),
        };
        return subtaskSpecificStuffList;
    })
        .filter(function (actualQuantity) { return actualQuantity.subtaskSpecificStuffs.length; });
}
function getQuantitiesAsFormGroupForRegularOrder(order) {
    var sortedTasks = sort(order.tasksList, function (a, b) { return a.sequence - b.sequence; });
    var dropOffTasks = sortedTasks
        .map(function (task) {
        var payloadSubTasks = task.subTasksList.filter(function (subtask) { return payloadDropoffTypes[subtask.type.name]; });
        var actualValueList = {
            siteName: task.site.name,
            subtaskSpecificStuffs: payloadSubTasks.map(function (subTask) {
                var billingInfo = {
                    actualQuantity: new FormControl(subTask.actualQuantity > Number.MIN_SAFE_INTEGER ? subTask.actualQuantity : null),
                    orderedQuantity: subTask.orderedQuantity,
                    payloadName: subTask.payload.name,
                    payloadUnitAbbrev: subTask.payload.unit.abbreviation,
                    subtaskId: subTask.id,
                    type: getType(subTask.type.name),
                };
                if (billingInfo.type === 'dropoff') {
                    billingInfo.bol = new FormControl(subTask.billing ? subTask.billing.bolNumber : null);
                }
                return billingInfo;
            }),
        };
        return actualValueList;
    })
        .filter(function (actualQuantity) { return actualQuantity.subtaskSpecificStuffs.length; });
    // There should only be one task since this is not a custom order, but being safe
    if (dropOffTasks.length !== 1 || dropOffTasks[0].subtaskSpecificStuffs.length !== 1) {
        return [];
    }
    var pickupSubtask = flatten(sortedTasks.map(function (task) { return task.subTasksList; })).find(function (subTask) { return payloadPickupTypes[subTask.type.name]; });
    if (pickupSubtask) {
        dropOffTasks.push({
            hiddenInUi: true,
            siteName: null,
            subtaskSpecificStuffs: [omit(dropOffTasks[0].subtaskSpecificStuffs[0], ['bol'])],
        });
        dropOffTasks[1].subtaskSpecificStuffs[0].subtaskId = pickupSubtask.id;
    }
    return dropOffTasks;
}
function getType(type) {
    if (payloadPickupTypes[type]) {
        return 'pickup';
    }
    if (payloadDropoffTypes[type]) {
        return 'dropoff';
    }
    return null;
}
