import { ChangeDetectionStrategy, Component, Input, OnInit, Optional, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { EventEmitter } from 'events';
import { Unit } from '~proto/payload/payload_pb';
import { ConstantsService } from '~services/constants.service';
import { trackById } from '~utilities/trackById';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-select-units',
  styleUrls: ['./select-payload-units.component.scss'],
  templateUrl: './select-payload-units.component.html',
})
export class SelectPayloadUnitsComponent implements OnInit {
  public trackById = trackById;
  @Input() public payloadName = 'this';
  @Output() public selectedUnit = new EventEmitter();
  public fc = new FormControl(null, { validators: Validators.required });
  public units: Unit.AsObject[];
  constructor(
    private constants: ConstantsService,
    @Optional() private matDialogRef: MatDialogRef<SelectPayloadUnitsComponent>,
  ) {}

  public ngOnInit() {
    this.units = Object.values(this.constants.units);
    this.units.sort((a, b) => a.name.localeCompare(b.name));
  }

  public selected() {
    if (this.fc.invalid) {
      return;
    }
    this.selectedUnit.emit(this.fc.value);
    if (this.matDialogRef) {
      this.matDialogRef.close(this.fc.value);
    }
  }
}
