import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';
import { ConfirmCancelComponent } from '~common/confirm-cancel/confirm-cancel.component';
import { OrderStatus, TaskType } from '~proto/types/types_pb';
import { getFirstTask, getFirstTaskWithPayload } from '~utilities/getFirstTask';
import { trackById } from '~utilities/trackById';
var OrderDetailPendingComponent = /** @class */ (function () {
    function OrderDetailPendingComponent(orderService, router, siteService, matDialog, activatedRoute) {
        this.orderService = orderService;
        this.router = router;
        this.siteService = siteService;
        this.matDialog = matDialog;
        this.activatedRoute = activatedRoute;
        this.networkActive$ = new BehaviorSubject(false);
        this.taskType = TaskType;
        this.orderStatus = OrderStatus;
        this.trackById = trackById;
        this.driverCertifications = [];
    }
    OrderDetailPendingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentOrder$ = this.orderService.currentOrder$;
        this.currentOrder$.pipe(filter(function (currentOrder) { return !!currentOrder; })).subscribe(function (currentOrder) {
            _this.firstTask = getFirstTask(currentOrder.tasksList);
            var firstTaskWithPayload = getFirstTaskWithPayload(currentOrder.tasksList);
            if (firstTaskWithPayload.subTasksList.length > 0) {
                _this.firstSubTaskBilling = firstTaskWithPayload.subTasksList[0].billing;
            }
            _this.driverCertifications = currentOrder.driverCertificationsList.map(function (cert) { return cert.name; });
        });
    };
    OrderDetailPendingComponent.prototype.confirmCancel = function (orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cancelDialog, confirmed;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cancelDialog = this.matDialog.open(ConfirmCancelComponent);
                        cancelDialog.componentInstance.name = "#" + orderId;
                        return [4 /*yield*/, cancelDialog.afterClosed().toPromise()];
                    case 1:
                        confirmed = _a.sent();
                        if (!confirmed) {
                            return [2 /*return*/];
                        }
                        this.cancelOrder(orderId);
                        return [2 /*return*/];
                }
            });
        });
    };
    OrderDetailPendingComponent.prototype.cancelOrder = function (orderId) {
        var _this = this;
        this.networkActive$.next(true);
        this.siteService.currentSite$.pipe(take(1)).subscribe(function (site) {
            _this.orderService
                .cancelPendingOrder$(orderId)
                .pipe(finalize(function () { return _this.networkActive$.next(false); }))
                .subscribe(function () {
                _this.router.navigate(['../', 'list'], { relativeTo: _this.activatedRoute });
            });
        });
    };
    return OrderDetailPendingComponent;
}());
export { OrderDetailPendingComponent };
