export const environment = {
  api: 'https://prime.shaleapps.com',
  environment: 'prod',
  firebaseConfig: {
    apiKey: 'AIzaSyD7X5KQtp-W5y1OaF48mz9l2ckn281Cj1w',
    authDomain: 'agnus-2fff8.firebaseapp.com',
    databaseURL: 'https://agnus-2fff8.firebaseio.com',
    messagingSenderId: '889599006301',
    projectId: 'agnus-2fff8',
    storageBucket: 'agnus-2fff8.appspot.com',
  },
  production: true,
};
