<div class="custom-token-signin" *ngIf="working$ | async; else error">
  <div class="message">Signing you in</div>
  <div class="loading">
    <mat-spinner></mat-spinner>
  </div>
</div>

<ng-template #error>
  <div class="custom-token-signin">
    <div class="message">
      Something went wrong, please
      <a
        href="https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=7b4966b5-5b70-4a6d-8d92-d41f8600c3ee&redirect_uri=https://dev.sso.shaleapps.com/azure&scope=User.Read.All Group.Read.All&response_type=code&response_mode=query"
        >sign in</a
      >
      again
    </div>
  </div>
</ng-template>
