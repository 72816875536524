import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { OrdersService } from 'src/app/lmo/services/orders.service';
import { ConfirmCancelComponent } from '~common/confirm-cancel/confirm-cancel.component';
import { Order, SubTaskBilling, Task } from '~proto/order/order_pb';
import { OrderStatus, TaskType } from '~proto/types/types_pb';
import { getFirstTask, getFirstTaskWithPayload } from '~utilities/getFirstTask';
import { trackById } from '~utilities/trackById';
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-order-detail-pending',
  styleUrls: ['./order-detail-pending.component.scss'],
  templateUrl: './order-detail-pending.component.html',
})
export class OrderDetailPendingComponent implements OnInit {
  public currentOrder$: Observable<Order.AsObject>;
  public firstTask: Task.AsObject;
  public firstSubTaskBilling: SubTaskBilling.AsObject;
  public networkActive$ = new BehaviorSubject<boolean>(false);
  public taskType = TaskType;
  public orderStatus = OrderStatus;
  public trackById = trackById;

  public driverCertifications = [];

  constructor(
    private orderService: OrdersService,
    private router: Router,
    private siteService: JobSitesService,
    private matDialog: MatDialog,
    private activatedRoute: ActivatedRoute,
  ) {}

  public ngOnInit() {
    this.currentOrder$ = this.orderService.currentOrder$;
    this.currentOrder$.pipe(filter((currentOrder) => !!currentOrder)).subscribe((currentOrder) => {
      this.firstTask = getFirstTask(currentOrder.tasksList);

      const firstTaskWithPayload = getFirstTaskWithPayload(currentOrder.tasksList);
      if (firstTaskWithPayload.subTasksList.length > 0) {
        this.firstSubTaskBilling = firstTaskWithPayload.subTasksList[0].billing;
      }

      this.driverCertifications = currentOrder.driverCertificationsList.map((cert) => cert.name);
    });
  }

  public async confirmCancel(orderId: number) {
    const cancelDialog = this.matDialog.open(ConfirmCancelComponent);
    cancelDialog.componentInstance.name = `#${orderId}`;

    const confirmed: boolean = await cancelDialog.afterClosed().toPromise();
    if (!confirmed) {
      return;
    }
    this.cancelOrder(orderId);
  }

  public cancelOrder(orderId: number) {
    this.networkActive$.next(true);

    this.siteService.currentSite$.pipe(take(1)).subscribe((site) => {
      this.orderService
        .cancelPendingOrder$(orderId)
        .pipe(finalize(() => this.networkActive$.next(false)))
        .subscribe(() => {
          this.router.navigate(['../', 'list'], { relativeTo: this.activatedRoute });
        });
    });
  }
}
