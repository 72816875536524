/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signin-with-custom-token.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./signin-with-custom-token.component";
import * as i8 from "../../services/auth.service";
import * as i9 from "@angular/router";
import * as i10 from "@angular/material/snack-bar";
var styles_SigninWithCustomTokenComponent = [i0.styles];
var RenderType_SigninWithCustomTokenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SigninWithCustomTokenComponent, data: {} });
export { RenderType_SigninWithCustomTokenComponent as RenderType_SigninWithCustomTokenComponent };
function View_SigninWithCustomTokenComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "custom-token-signin"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Signing you in"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(5, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 5).diameter; var currVal_2 = i1.ɵnov(_v, 5).diameter; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }); }
function View_SigninWithCustomTokenComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "custom-token-signin"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Something went wrong, please "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["href", "https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=7b4966b5-5b70-4a6d-8d92-d41f8600c3ee&redirect_uri=https://dev.sso.shaleapps.com/azure&scope=User.Read.All Group.Read.All&response_type=code&response_mode=query"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["sign in"])), (_l()(), i1.ɵted(-1, null, [" again "]))], null, null); }
export function View_SigninWithCustomTokenComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_SigninWithCustomTokenComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["error", 2]], null, 0, null, View_SigninWithCustomTokenComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.working$)); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SigninWithCustomTokenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sa-signin-with-custom-token", [], null, null, null, View_SigninWithCustomTokenComponent_0, RenderType_SigninWithCustomTokenComponent)), i1.ɵdid(1, 114688, null, 0, i7.SigninWithCustomTokenComponent, [i8.AuthService, i9.ActivatedRoute, i10.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SigninWithCustomTokenComponentNgFactory = i1.ɵccf("sa-signin-with-custom-token", i7.SigninWithCustomTokenComponent, View_SigninWithCustomTokenComponent_Host_0, {}, {}, []);
export { SigninWithCustomTokenComponentNgFactory as SigninWithCustomTokenComponentNgFactory };
