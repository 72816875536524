<div class="chat-bot-container" [class.on-left]="!(onRight$ | async)" [class.active]="!hide">
  <div class="chat-bot-wrap">
    <div class="heading" *ngIf="!hide" (click)="toggleHide()">HAULi&trade;&nbsp;<span class="emoji">🤖</span></div>
    <div class="heading" *ngIf="hide" (click)="toggleHide()" ctHotkey="h" (hotkeyPressed)="toggleHide()">
      <mat-icon>chat</mat-icon>
    </div>
    <div class="message-container" #scroller>
      <div [class]="'message ' + chat.sender" *ngFor="let chat of chat$ | async; trackBy: trackBy">
        <div *ngIf="chat.statusUnix; else messageDontHaveStatusUnix">
          <div
            [innerHTML]="
              'Load ' +
                chat.message.slice(chat.message.indexOf(' '), chat.message.lastIndexOf('w')) +
                ' was ' +
                chat.orderStatus +
                ' on ' +
                (chat.statusUnix * 1000 | date: 'MM/dd/yy') +
                ' at ' +
                (chat.statusUnix * 1000 | date: 'h:mmaa') +
                ' ' +
                chat.message.split(' ').pop() | safeHtml
            "
          ></div>
          <a *ngIf="chat.localUrl && chat.localUrl.length" [routerLink]="chat.localUrl">{{ chat.localUrlText }}</a>
        </div>
        <ng-template #messageDontHaveStatusUnix>
          <div [innerHTML]="chat.message | safeHtml"></div>
          <a *ngIf="chat.localUrl && chat.localUrl.length" [routerLink]="chat.localUrl">{{ chat.localUrlText }}</a>
        </ng-template>
      </div>
    </div>
    <div class="input-wrap">
      <input
        #chatBotInput
        type="text"
        [formControl]="formControl"
        placeholder="Enter Message"
        (keydown.enter)="sendMessage()"
        (keydown.escape)="escapeKey($event)"
      />
      <button ct-button (click)="sendMessage()">Send</button>
    </div>
  </div>
</div>
