import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { FormArray, Validators } from '@angular/forms';
import { flatten } from 'remeda/dist/es';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import { addCustomTasksToBackhaulRequest } from '~lmo/utilities/create-order';
import { BackHaulRequest } from '~proto/order/order_api_pb';
import { OrderType, TaskStatus, TaskType } from '~proto/types/types_pb';
import { UserDetailsForIntercomRequest } from '~proto/user/user_api_pb';
import { UserAPI } from '~proto/user/user_api_pb_service';
import { moveItemInFormArray } from '~utilities/moveItemInFormArray';
import { trackById } from '~utilities/trackById';
var AddTasksComponent = /** @class */ (function () {
    function AddTasksComponent(fb, router, snackBar, customFieldOrderService, activatedRoute, orderService, payloadService, grpcService) {
        this.fb = fb;
        this.router = router;
        this.snackBar = snackBar;
        this.customFieldOrderService = customFieldOrderService;
        this.activatedRoute = activatedRoute;
        this.orderService = orderService;
        this.payloadService = payloadService;
        this.grpcService = grpcService;
        this.destroy$ = new Subject();
        this.networkActive$ = new BehaviorSubject(false);
        this.trackById = trackById;
        this.customStops = [];
        this.isPurchaseOrderRequired$$ = new BehaviorSubject(false);
        this.isSalesOrderRequired$$ = new BehaviorSubject(false);
        this.allowedPayloadTypes$$ = new BehaviorSubject([]);
        this.canLoadBeBackHaul$$ = new BehaviorSubject(false);
        this.customStops$$ = new BehaviorSubject([]);
        this.customStopsStatusChanges$$ = new BehaviorSubject([]);
        this.taskStatus = TaskStatus;
        this.backHaulMessage = 'BackHaul can be Yes if the added tasks are in sequence.';
        this.backHaulToolTipDisabled = true;
        this.stopTypes = [
            {
                name: 'Pickup Payload',
                value: TaskType.TASK_TYPE_PICKUP,
            },
            {
                name: 'Dropoff Payload',
                value: TaskType.TASK_TYPE_DROPOFF,
            },
        ];
    }
    AddTasksComponent.prototype.ngOnInit = function () {
        this.currentOrder$ = this.orderService.currentOrder$;
        this.loadRequiredFieldSettings();
        this.setupCustomStops();
        this.setupPayloadSummaries();
        this.setupValidationErrors();
        this.setupPayloads();
        this.setupPurchaseOrders();
        this.setupSalesOrderNumbers();
        this.formGroup = this.fb.group({
            isBackHaul: [false],
        });
    };
    AddTasksComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    };
    AddTasksComponent.prototype.addNewCustomStop = function (taskIndex, taskSequence) {
        var fg = this.getNewFormLine(taskSequence, taskIndex);
        fg.get('stopType').setValue(TaskType.TASK_TYPE_PICKUP);
        this.customStops[taskIndex].push(fg);
        this.customStops.forEach(function (stops, index) {
            var e_1, _a;
            if (index > taskIndex) {
                if (stops && stops.length > 0) {
                    try {
                        for (var _b = tslib_1.__values(stops.value), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var stop_1 = _c.value;
                            stop_1.sequence = stop_1.sequence + 1;
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                }
            }
        });
        this.checkTasksContinuity();
    };
    AddTasksComponent.prototype.removeCustomStop = function (taskIndex) {
        this.customStops[taskIndex].removeAt(this.customStops[taskIndex].length - 1);
        this.customStops.forEach(function (stops, index) {
            var e_2, _a;
            if (index > taskIndex) {
                if (stops && stops.length > 0) {
                    try {
                        for (var _b = tslib_1.__values(stops.value), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var stop_2 = _c.value;
                            stop_2.sequence = stop_2.sequence - 1;
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                }
            }
        });
        this.checkTasksContinuity();
    };
    AddTasksComponent.prototype.checkTasksContinuity = function () {
        var e_3, _a;
        var previousStopSequence = -1;
        if (this.customStopsValue().length > 1) {
            try {
                for (var _b = tslib_1.__values(this.customStopsValue()), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var stop_3 = _c.value;
                    if (previousStopSequence === -1 || previousStopSequence === stop_3.sequence - 1) {
                        previousStopSequence = stop_3.sequence;
                    }
                    else {
                        this.disbaleBackHaulDropdown();
                        break;
                    }
                    this.enableBackHaulDropdown();
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
        else {
            this.enableBackHaulDropdown();
        }
    };
    AddTasksComponent.prototype.disbaleBackHaulDropdown = function () {
        this.backHaulToolTipDisabled = false;
        this.formGroup.get('isBackHaul').setValue(false);
        this.formGroup.get('isBackHaul').disable();
    };
    AddTasksComponent.prototype.enableBackHaulDropdown = function () {
        this.backHaulToolTipDisabled = true;
        this.formGroup.get('isBackHaul').enable();
    };
    AddTasksComponent.prototype.stopChanged = function () {
        var stops = [];
        this.customStops.forEach(function (value) {
            stops.push.apply(stops, tslib_1.__spread(value.value));
        });
        this.customStops$$.next(stops);
    };
    AddTasksComponent.prototype.stopChangedStatus = function () {
        var stops = [];
        this.customStops.forEach(function (value) {
            stops.push.apply(stops, tslib_1.__spread(value.status));
        });
        this.customStopsStatusChanges$$.next(stops);
    };
    AddTasksComponent.prototype.setupCustomStops = function () {
        var _this = this;
        this.currentOrder$.pipe(filter(function (order) { return order !== null; })).subscribe(function (order) {
            if (order) {
                _this.currentOrderId = order.id;
                _this.customFieldOrderService.canLoadBeBackHaulRequest$(order.id).subscribe(function (res) {
                    _this.canLoadBeBackHaul$$.next(res.canBeBackHaul);
                    _this.allowedPayloadTypes$$.next(res.allowedPayloadTypeIdsList);
                });
                order.tasksList.forEach(function (task) {
                    var formArray = new FormArray([]);
                    formArray.valueChanges.subscribe(function () { return _this.stopChanged(); });
                    formArray.statusChanges.subscribe(function () { return _this.stopChangedStatus(); });
                    _this.customStops.push(formArray);
                });
            }
        });
    };
    AddTasksComponent.prototype.customStopsValue = function () {
        var customStops = [];
        this.customStops.forEach(function (stop) {
            customStops.push.apply(customStops, tslib_1.__spread(stop.value));
        });
        return customStops;
    };
    AddTasksComponent.prototype.getPreviousCustomStopsValue = function (taskIndex) {
        var customStops = [];
        var i = 0;
        while (i <= taskIndex) {
            customStops.push.apply(customStops, tslib_1.__spread(this.customStops[i].value));
            i++;
        }
        return customStops;
    };
    AddTasksComponent.prototype.loadRequiredFieldSettings = function () {
        var _this = this;
        var request = new UserDetailsForIntercomRequest();
        this.grpcService.invoke$(UserAPI.UserDetailsForIntercom, request).subscribe(function (response) {
            _this.isPurchaseOrderRequired$$.next(response.toObject().userDetails.isPurchaseOrderRequired);
            _this.isSalesOrderRequired$$.next(response.toObject().userDetails.isSalesOrderRequired);
        });
    };
    AddTasksComponent.prototype.requiredFieldsCheck = function (customStopsValue) {
        this.isPOValid = this.isPurchaseOrderRequired$$.value ? customStopsValue[0].purchaseOrderName : 'true';
        this.isSOValid = this.isSalesOrderRequired$$.value ? this.checkDropOffSO(customStopsValue) : true;
    };
    AddTasksComponent.prototype.checkDropOffSO = function (customStopsValue) {
        return (customStopsValue &&
            customStopsValue.map(function (customStop) {
                if (customStop.stopType === 1 && customStop.salesOrderNumber === null) {
                    return false;
                }
                return true;
            }));
    };
    AddTasksComponent.prototype.create = function () {
        var e_4, _a;
        var _this = this;
        var customStopsValue = this.customStopsValue();
        this.requiredFieldsCheck(customStopsValue);
        if (!this.isPOValid || !this.isSOValid) {
            this.snackBar.open("\n        " + (this.isPurchaseOrderRequired$$.value && !customStopsValue[0].purchaseOrderName ? 'Purchase Order Name, ' : '') + "\n        " + (this.isSalesOrderRequired$$.value && !this.isSOValid ? 'Sales Order Number' : '') + "\n        fields are mandatory", null, {
                duration: 3000,
            });
            return;
        }
        var payloadSummary = this.validationErrors;
        if (payloadSummary && payloadSummary.invalidTotals) {
            this.snackBar.open(payloadSummary.invalidTotals, null, {
                duration: 3000,
            });
            return;
        }
        this.formGroup.markAllAsTouched();
        if (this.formGroup.invalid) {
            return;
        }
        try {
            for (var _b = tslib_1.__values(this.customStops), _c = _b.next(); !_c.done; _c = _b.next()) {
                var customStop = _c.value;
                if (!customStop.valid) {
                    return;
                }
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_4) throw e_4.error; }
        }
        this.networkActive$.next(true);
        var backhaulRequest = new BackHaulRequest();
        addCustomTasksToBackhaulRequest(backhaulRequest, customStopsValue);
        backhaulRequest.getTaskRequestsList().map(function (value, index) {
            value.setSequence(customStopsValue[index].sequence);
            value.setIsBackHaul(_this.formGroup.get('isBackHaul').value);
        });
        backhaulRequest.setLoadId(this.currentOrderId);
        this.orderService
            .createBackHaul$(backhaulRequest)
            .pipe(finalize(function () {
            _this.networkActive$.next(false);
        }))
            .subscribe(function () {
            _this.snackBar.open('Tasks successfully created!', null, {
                duration: 3000,
            });
            _this.router.navigate(['..'], { relativeTo: _this.activatedRoute });
        });
    };
    AddTasksComponent.prototype.isCustomOrder = function (order) {
        return order != null && order.type === OrderType.ORDER_TYPE_CUSTOM;
    };
    AddTasksComponent.prototype.drop = function (taskIndex, event) {
        moveItemInFormArray(this.customStops[taskIndex], event.previousIndex, event.currentIndex);
    };
    AddTasksComponent.prototype.trackByControl = function (_index, control) {
        return control;
    };
    AddTasksComponent.prototype.trackByName = function (_index, record) {
        return record.name;
    };
    AddTasksComponent.prototype.trackByKey = function (_index, record) {
        return record.key;
    };
    AddTasksComponent.prototype.selectCustomOrderLineSite = function (taskIndex, index, event) {
        this.customStops[taskIndex]
            .at(index)
            .get('location')
            .setValue(event.pickupFrom);
    };
    AddTasksComponent.prototype.setupValidationErrors = function () {
        var _this = this;
        combineLatest([
            this.payloadSummaries$,
            this.customStops$$.asObservable().pipe(startWith(null), map(function () { return _this.customStopsValue(); })),
            this.customStopsStatusChanges$$.asObservable(),
        ])
            .pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 1), payloadSummaries = _b[0];
            var hasError = false;
            var validationErrors = {};
            _this.customStops.forEach(function (stops) {
                if (stops.invalid && stops.errors) {
                    hasError = true;
                    validationErrors.customStops = tslib_1.__assign({}, stops.errors);
                }
            });
            _this.customStops.forEach(function (stops) {
                if (stops.invalid && stops.errors) {
                    hasError = true;
                    validationErrors.customStops = tslib_1.__assign({}, stops.errors);
                }
                if (stops.invalid && stops.errors) {
                    hasError = true;
                    validationErrors.customStops = tslib_1.__assign({}, stops.errors);
                }
                if (stops.controls.some(function (control) { return control.invalid; })) {
                    hasError = true;
                    validationErrors.customStopsLine = 'Please fill in all fields';
                }
            });
            if (payloadSummaries.some(function (summary) { return summary.leftOver !== 0; })) {
                hasError = true;
                validationErrors.invalidTotals = 'All payloads must 0 out';
            }
            if (hasError) {
                return validationErrors;
            }
            return null;
        }), takeUntil(this.destroy$))
            .subscribe(function (errors) {
            _this.validationErrors = errors;
        });
    };
    Object.defineProperty(AddTasksComponent.prototype, "invalid", {
        get: function () {
            return !!this.validationErrors;
        },
        enumerable: true,
        configurable: true
    });
    AddTasksComponent.prototype.validate = function () {
        return this.validationErrors;
    };
    AddTasksComponent.prototype.selectCustomOrderLinePayload = function (taskIndex, index, event) {
        this.customStops[taskIndex]
            .at(index)
            .get('payload')
            .setValue(event.payload);
        this.customStops[taskIndex]
            .at(index)
            .get('quantity')
            .setValue(event.quantity || 0);
    };
    AddTasksComponent.prototype.selectCustomOrderPurchaseOrderName = function (taskIndex, index, event) {
        this.customStops[taskIndex]
            .at(index)
            .get('purchaseOrderName')
            .setValue(event.purchaseOrderName);
    };
    AddTasksComponent.prototype.selectCustomSalesOrderNumber = function (taskIndex, index, event) {
        this.customStops[taskIndex]
            .at(index)
            .get('salesOrderNumber')
            .setValue(event.orderNumber);
    };
    AddTasksComponent.prototype.setupPayloadSummaries = function () {
        var _this = this;
        this.payloadSummaries$ = this.customStops$$.asObservable().pipe(map(function (stops) {
            var summariesMap = stops.reduce(function (acc, stop) {
                var keyValue = _this.isPurchaseOrderRequired$$.value ? stop.purchaseOrderName : stop.quantity;
                if (stop.payload && keyValue) {
                    var key = stop.payload.id + "-" + keyValue;
                    if (!acc[key]) {
                        acc[key] = {
                            droppedOff: 0,
                            key: key,
                            leftOver: 0,
                            payloadName: stop.payload.name,
                            pickedUp: 0,
                            purchaseOrderName: stop.purchaseOrderName ? stop.purchaseOrderName : '-',
                        };
                    }
                    if (stop.stopType === TaskType.TASK_TYPE_PICKUP) {
                        acc[key].pickedUp += stop.quantity;
                        acc[key].leftOver += stop.quantity;
                    }
                    else if (stop.stopType === TaskType.TASK_TYPE_DROPOFF) {
                        acc[key].droppedOff += stop.quantity;
                        acc[key].leftOver -= stop.quantity;
                    }
                }
                return acc;
            }, {});
            return Object.values(summariesMap);
        }));
    };
    AddTasksComponent.prototype.setupPayloads = function () {
        this.payloadGroups$ = combineLatest([
            this.payloadService.allPayloadGroups$,
            this.allowedPayloadTypes$$.asObservable(),
            this.customStops$$.asObservable().pipe(startWith([])),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 3), sitePayloads = _b[0], allowedPayloadTypes = _b[1], formValues = _b[2];
            var payloadsOnForm = formValues.filter(function (stop) { return stop.payload; }).map(function (stop) { return stop.payload; });
            var allPayloads = tslib_1.__spread(flatten(sitePayloads.map(function (payloadGroup) { return payloadGroup.payloadsList; })), payloadsOnForm);
            var filteredPayloads = allPayloads.filter(function (payload) {
                return allowedPayloadTypes.includes(payload.type.id);
            });
            var uniquePayloads = filteredPayloads.reduce(function (acc, payload) {
                acc[payload.id] = payload;
                return acc;
            }, {});
            return [
                {
                    name: '',
                    payloadsList: Object.values(uniquePayloads),
                },
            ];
        }));
    };
    AddTasksComponent.prototype.getNewFormLine = function (taskSequence, taskIndex, site) {
        var _this = this;
        var stopSequence = taskSequence + 1 + this.getPreviousCustomStopsValue(taskIndex).length;
        var fg = this.fb.group({
            location: [site, [Validators.required]],
            payload: [null, [Validators.required]],
            purchaseOrderName: [null, this.isPurchaseOrderRequired$$.value ? [Validators.required] : ''],
            quantity: [null, [Validators.required]],
            salesOrderNumber: [null, this.isSalesOrderRequired$$.value ? [Validators.required] : ''],
            sequence: [stopSequence],
            stopType: [null, [Validators.required]],
        });
        fg.get('stopType')
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe(function () {
            if (_this.isSalesOrderRequired$$.value && fg.controls['stopType'].value === 1) {
                fg.controls['salesOrderNumber'].setValidators(Validators.required);
            }
            else {
                fg.controls['salesOrderNumber'].clearValidators();
            }
        });
        return fg;
    };
    AddTasksComponent.prototype.setupPurchaseOrders = function () {
        this.purchaseOrderNames$ = this.customStops$$.asObservable().pipe(map(function (formValues) {
            return formValues.filter(function (stop) { return stop.purchaseOrderName; }).map(function (stop) { return stop.purchaseOrderName; });
        }), shareReplay(1));
    };
    AddTasksComponent.prototype.setupSalesOrderNumbers = function () {
        this.salesOrderNumbers$ = this.customStops$$.asObservable().pipe(map(function (formValues) {
            return formValues.filter(function (stop) { return stop.salesOrderNumber; }).map(function (stop) { return stop.salesOrderNumber; });
        }), shareReplay(1));
    };
    return AddTasksComponent;
}());
export { AddTasksComponent };
