import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { PayloadService } from '~lmo/services/payload.service';
import { DriverCertification } from '~proto/drivercertification/driverCertification_pb';
import { UpdatePayloadRequest } from '~proto/payload/payload_api_pb';
import { Payload, PayloadType, Unit } from '~proto/payload/payload_pb';
import { ConstantsService } from '~services/constants.service';
import { recordToArray } from '~utilities/recordToArray';
import { trackById } from '~utilities/trackById';
import { DriverCertificationService } from '../../../dispatcher/services/driver-certification.service';

interface FormValue {
  name: string;
  payloadTypeId: number;
  unitId: number;
  driverCertificationIds: number[];
}

@Component({
  selector: 'ct-lmo-payload-management-edit',
  styleUrls: ['./lmo-payload-management-edit.component.scss'],
  templateUrl: './lmo-payload-management-edit.component.html',
})
export class LmoPayloadManagementEditComponent implements OnInit {
  public networkActive$ = new BehaviorSubject(false);
  public formGroup: FormGroup;
  public payload: Payload.AsObject;
  public payloadTypes: PayloadType.AsObject[];
  public units: Unit.AsObject[];
  public driverCertifications$: Observable<DriverCertification.AsObject[]>;
  public trackById = trackById;

  constructor(
    private fb: FormBuilder,
    private payloadService: PayloadService,
    private constantsService: ConstantsService,
    private driverCertificationService: DriverCertificationService,
    private router: Router,
    private snackbar: MatSnackBar,
  ) {}

  private formValue(): FormValue {
    return this.formGroup.value;
  }

  public ngOnInit() {
    this.setPayload().then();

    this.payloadTypes = recordToArray(this.constantsService.payloadTypes).sort((a, b) => a.name.localeCompare(b.name));
    this.units = recordToArray(this.constantsService.units).sort((a, b) => a.name.localeCompare(b.name));
    this.driverCertifications$ = this.driverCertificationService.driverCertifications$;

    this.formGroup = this.fb.group({
      driverCertificationIds: [[]],
      name: ['', [Validators.required]],
      payloadTypeId: ['', [Validators.required]],
      unitId: ['', [Validators.required]],
    });
  }

  public async setPayload() {
    this.payload = await this.payloadService.currentPayload$
      .pipe(
        filter((payload) => !!payload),
        take(1),
      )
      .toPromise();

    this.formGroup.patchValue({
      driverCertificationIds: this.payload.driverCertificationsList.map((cert) => cert.id),
      name: this.payload.name,
      payloadTypeId: this.payload.type.id,
      unitId: this.payload.unit.id,
    });
  }

  public updatePayload() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }
    const formValue = this.formValue();

    this.networkActive$.next(true);

    const updatePayloadRequest = new UpdatePayloadRequest();
    updatePayloadRequest.setId(this.payload.id);
    updatePayloadRequest.setName(formValue.name);
    updatePayloadRequest.setPayloadTypeId(formValue.payloadTypeId);
    updatePayloadRequest.setUnitId(formValue.unitId);
    updatePayloadRequest.setDriverCertificationIdsList(formValue.driverCertificationIds);

    const mask = new FieldMask();
    mask.setPathsList(['name', 'payloadTypeId', 'unitId']);
    updatePayloadRequest.setMask(mask);

    this.payloadService.updatePayload$(updatePayloadRequest).subscribe(() => {
      this.networkActive$.next(false);
      this.router.navigate(['/', 'lmo', 'payloads']).then();

      this.snackbar.open('Payload Updated', null, {
        duration: 2000,
      });
    });
  }
}
