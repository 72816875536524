import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import { once } from 'remeda';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, take } from 'rxjs/operators';
import { SelectBusinessHoursComponent } from '~common/select-business-hours/select-business-hours.component';
import { UpdateSiteRequest } from '~proto/site/site_api_pb';
import { SiteAvailableDuration } from '~proto/site/site_pb';
import { createCircle } from '~utilities/createCircle';
import { trackById } from '~utilities/trackById';
var metersInAMile = 1609;
var LmoEditJobComponent = /** @class */ (function () {
    function LmoEditJobComponent(fb, router, jobSitesService, driverCertificationService, lmoDistrictService, snackBar, dialog) {
        this.fb = fb;
        this.router = router;
        this.jobSitesService = jobSitesService;
        this.driverCertificationService = driverCertificationService;
        this.lmoDistrictService = lmoDistrictService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.networkActive$$ = new BehaviorSubject(false);
        this.trackById = trackById;
        this.businessHours = [];
        this.weekMap = {
            0: 'MONDAY',
            1: 'TUESDAY',
            2: 'WEDNESDAY',
            3: 'THURSDAY',
            4: 'FRIDAY',
            5: 'SATURDAY',
            6: 'SUNDAY',
        };
    }
    Object.defineProperty(LmoEditJobComponent.prototype, "radius", {
        get: function () {
            return this.editForm.get('radius');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LmoEditJobComponent.prototype, "latitude", {
        get: function () {
            return this.editForm.get('latitude');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LmoEditJobComponent.prototype, "longitude", {
        get: function () {
            return this.editForm.get('longitude');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LmoEditJobComponent.prototype, "formValue", {
        get: function () {
            return this.editForm.value;
        },
        enumerable: true,
        configurable: true
    });
    LmoEditJobComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.site$ = this.jobSitesService.currentSite$;
        this.driverCertifications$ = this.driverCertificationService.driverCertifications$;
        this.lmoDistricts$ = this.lmoDistrictService.lmoDistricts$;
        this.editForm = this.fb.group({
            address: ['', []],
            businessHours: ['', []],
            directions: ['', []],
            districtId: [null, []],
            driverCertificationIds: [[]],
            latitude: [null, [Validators.required]],
            longitude: [null, [Validators.required]],
            name: [null, [Validators.required]],
            radius: [null, [Validators.required, Validators.min(0.02)]],
            type: ['well', [Validators.required]],
        });
        var initMapOnce = once(function () { return _this.initMap(); });
        this.site$
            .pipe(filter(function (site) { return !!site; }), take(1))
            .subscribe(function (site) {
            _this.editForm.patchValue({
                address: site.address,
                directions: site.directions,
                districtId: site.district ? site.district.id : null,
                driverCertificationIds: site.driverCertificationsList.map(function (cert) { return cert.id; }),
                latitude: site.geofence.center.lat,
                longitude: site.geofence.center.lon,
                name: site.name,
                radius: Math.round((site.geofence.radius / 1609) * 100) / 100,
                type: site.siteType,
            });
            initMapOnce();
            _this.setBusinessHourString(site.availabledurationsList);
            _this.radius.valueChanges
                .pipe(debounceTime(500), distinctUntilChanged())
                .subscribe(_this.handleRadiusInputChanges.bind(_this));
        });
    };
    LmoEditJobComponent.prototype.ngAfterViewInit = function () { };
    LmoEditJobComponent.prototype.setBusinessHourString = function (days) {
        var _this = this;
        var openDays = [];
        var hoursValue = '';
        days.forEach(function (dayValue) {
            var availableDuration = new SiteAvailableDuration();
            availableDuration.setDay(dayValue.day); // Proto Starts from Invalid as 0, Monday as 1 and so on...
            availableDuration.setOpeningTime(dayValue.openingTime);
            availableDuration.setClosingTime(dayValue.closingTime);
            openDays.push(availableDuration);
            hoursValue += "" + (hoursValue !== '' ? ', ' : '') + _this.weekMap[dayValue.day - 1] + "(" + dayValue.openingTime + " - " + dayValue.closingTime + ")";
        });
        this.businessHours = tslib_1.__spread(openDays);
        this.editForm.controls['businessHours'].setValue(hoursValue);
    };
    LmoEditJobComponent.prototype.initMap = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var i, center, mapConfig;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < 10 && !this.mapElement)) return [3 /*break*/, 4];
                        return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 200); })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4:
                        if (!this.mapElement) {
                            console.log('Failed to find map element');
                            return [2 /*return*/];
                        }
                        center = {
                            lat: this.latitude.value,
                            lng: this.longitude.value,
                        };
                        mapConfig = {
                            center: center,
                            mapTypeControl: true,
                            mapTypeControlOptions: {
                                position: 3,
                            },
                            mapTypeId: google.maps.MapTypeId.HYBRID,
                            streetViewControl: false,
                            zoom: 12,
                            zoomControlOptions: {
                                position: 3,
                            },
                        };
                        this.map = new google.maps.Map(this.mapElement.nativeElement, mapConfig);
                        this.mineMarker = new google.maps.Marker({
                            animation: google.maps.Animation.DROP,
                            draggable: true,
                            map: this.map,
                            position: this.map.getCenter(),
                        });
                        this.geofence = new google.maps.Circle({
                            center: this.map.getCenter(),
                            editable: true,
                            fillColor: '#00FF00',
                            fillOpacity: 0.35,
                            map: this.map,
                            radius: this.radius.value * metersInAMile,
                            strokeColor: '#00FF00',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            zIndex: 10,
                        });
                        this.geofence.bindTo('center', this.mineMarker, 'position');
                        google.maps.event.addListener(this.mineMarker, 'position_changed', this.handleMineMarkerMove.bind(this));
                        google.maps.event.addListener(this.geofence, 'radius_changed', this.handleGeofenceRadiusChange.bind(this));
                        return [2 /*return*/];
                }
            });
        });
    };
    LmoEditJobComponent.prototype.editBusinessHours = function () {
        var _this = this;
        var dialogRef = this.dialog.open(SelectBusinessHoursComponent, {
            data: this.businessHours,
        });
        dialogRef
            .afterClosed()
            .pipe(filter(function (value) { return !!value && value.length; }))
            .subscribe(function (value) {
            _this.businessHours = tslib_1.__spread(value);
            var hoursValue = '';
            value.forEach(function (day) {
                hoursValue += "" + (hoursValue !== '' ? ', ' : '') + _this.weekMap[day.getDay() - 1] + "(" + day.getOpeningTime() + " - " + day.getClosingTime() + ")";
            });
            _this.editForm.controls['businessHours'].setValue(hoursValue);
        });
    };
    LmoEditJobComponent.prototype.updateJob = function () {
        var _this = this;
        this.editForm.markAllAsTouched();
        if (this.editForm.invalid) {
            return;
        }
        this.networkActive$$.next(true);
        var formValue = this.formValue;
        var request = new UpdateSiteRequest();
        var circle = createCircle(formValue.latitude, formValue.longitude, formValue.radius);
        request.setName(formValue.name);
        request.setAddress(formValue.address);
        request.setGeofence(circle);
        request.setSiteType(formValue.type);
        request.setDirections(formValue.directions);
        request.setDriverCertificationIdsList(formValue.driverCertificationIds);
        if (this.editForm.get('type').value !== 'well') {
            request.setAvailabledurationsList(this.businessHours);
        }
        var fieldMask = new FieldMask();
        fieldMask.addPaths('name');
        fieldMask.addPaths('location');
        fieldMask.addPaths('address');
        fieldMask.addPaths('radius');
        fieldMask.addPaths('siteTypeId');
        fieldMask.addPaths('directions');
        fieldMask.addPaths('availableDurations');
        if (formValue.districtId) {
            request.setDistrictId(formValue.districtId);
            fieldMask.addPaths('lmoDistrictId');
        }
        request.setMask(fieldMask);
        this.jobSitesService
            .updateSite$(request)
            .pipe(finalize(function () {
            _this.networkActive$$.next(false);
        }))
            .subscribe(function (site) {
            _this.snackBar.open(site.name + " Updated", null, { duration: 5000 });
            _this.router.navigate(['/', 'lmo', 'jobs', site.id]);
        });
    };
    LmoEditJobComponent.prototype.centerMarkerOnMap = function () {
        var currentMapCenter = this.map.getCenter();
        this.mineMarker.setPosition({
            lat: currentMapCenter.lat(),
            lng: currentMapCenter.lng(),
        });
    };
    LmoEditJobComponent.prototype.centerMapOnMarker = function () {
        var currentMarkerPosition = this.mineMarker.getPosition();
        this.map.setCenter(currentMarkerPosition);
    };
    LmoEditJobComponent.prototype.handleLatitudeInputChange = function () {
        var latitude = this.formValue.latitude;
        var currentPosition = this.mineMarker.getPosition();
        this.mineMarker.setPosition({ lat: +latitude, lng: currentPosition.lng() });
        this.centerMapOnMarker();
    };
    LmoEditJobComponent.prototype.handleLongitudeInputChange = function () {
        var longitude = this.formValue.longitude;
        var currentPosition = this.mineMarker.getPosition();
        this.mineMarker.setPosition({ lng: +longitude, lat: currentPosition.lat() });
        this.centerMapOnMarker();
    };
    LmoEditJobComponent.prototype.handleMineMarkerMove = function () {
        var newPosition = this.mineMarker.getPosition();
        this.latitude.setValue(newPosition.lat(), { emitEvent: false });
        this.longitude.setValue(newPosition.lng(), { emitEvent: false });
    };
    LmoEditJobComponent.prototype.handleGeofenceRadiusChange = function () {
        var newRadius = this.geofence.getRadius() / metersInAMile;
        if (newRadius <= 10) {
            this.radius.setValue(Math.round(newRadius * 100) / 100);
        }
        else {
            this.geofence.setRadius(10 * metersInAMile);
        }
    };
    LmoEditJobComponent.prototype.handleRadiusInputChanges = function (radius) {
        this.geofence.setRadius(+radius * metersInAMile);
    };
    return LmoEditJobComponent;
}());
export { LmoEditJobComponent };
