import { OnDestroy } from '@angular/core';
var PageTitleDirective = /** @class */ (function () {
    function PageTitleDirective(titleService) {
        this.titleService = titleService;
    }
    Object.defineProperty(PageTitleDirective.prototype, "pageTitle", {
        set: function (title) {
            this.titleService.setTitle(title);
        },
        enumerable: true,
        configurable: true
    });
    PageTitleDirective.prototype.ngOnDestroy = function () {
        this.titleService.setTitle(null);
    };
    return PageTitleDirective;
}());
export { PageTitleDirective };
