<div class="sa-flex-container" ctPageTitle="Reset Password">
  <div class="reset-password">
    <img class="shale-logo" src="/assets/icons/shale-logo.svg" />
    <div class="back-wrap">
      <i class="material-icons">arrow_back</i><a [routerLink]="['/', 'login']">Back to login</a>
    </div>
    <h1>Request Password Reset</h1>
    <form
      (ngSubmit)="sendResetEmail()"
      [formGroup]="resetForm"
      *ngIf="status === 'awaitingUserInput' || status === 'error'"
    >
      <div class="instructions">
        Enter your email address and we will send you a link that will allow you to reset your password.
      </div>
      <ct-input label="Email" staticLabel>
        <input ctInput formControlName="email" ctAutomatedSelector="reset__email" autocomplete="off" />
      </ct-input>
      <div class="form-buttons">
        <button type="submit" mat-raised-button color="primary">Get Email</button>
      </div>
    </form>

    <mat-spinner mode="indeterminate" *ngIf="status === 'submitting'"></mat-spinner>
    <div *ngIf="status === 'success'">
      An email has been sent to your account with a link that will allow you to reset your password.
      <div class="emphasis">Please Note: This link will expire in one hour.</div>
    </div>
    <div class="error" *ngIf="status === 'error'">{{ errorMessage }}</div>
  </div>
</div>
