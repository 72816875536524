import { ChangeDetectorRef, OnInit } from '@angular/core';
// temp repeat until I can full untwist ui-components
var AutomatedSelectorDirective = /** @class */ (function () {
    function AutomatedSelectorDirective(cd) {
        this.cd = cd;
    }
    Object.defineProperty(AutomatedSelectorDirective.prototype, "automatedName", {
        get: function () {
            return this._automatedName;
        },
        set: function (name) {
            this._automatedName = name;
            this.automatedDataName = this._automatedName;
            this.cd.markForCheck();
        },
        enumerable: true,
        configurable: true
    });
    AutomatedSelectorDirective.prototype.ngOnInit = function () {
        this.automatedTag = this.ctAutomatedSelector;
        this.automatedDataName = this.automatedName;
    };
    return AutomatedSelectorDirective;
}());
export { AutomatedSelectorDirective };
