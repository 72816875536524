import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatCheckbox,
  MatCheckboxChange,
  MatDialog,
  MatDialogRef,
  MatSelectionList,
  MatSnackBar,
} from '@angular/material';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { OrdersService } from 'src/app/lmo/services/orders.service';
import { GroupedOrders, OrderCard, PayloadDetails } from 'src/app/models/type-with-order.model';
import { loadPluralMapping } from '~plural/loads';
import { orderPluralMapping } from '~plural/orders';
import {
  LMOBulkCancelOrderRequest,
  LMOBulkMoveScheduleForOrdersRequest,
  LMOBulkSetAsASAPOrdersRequest,
  LMOBulkSetAsScheduledOrdersRequest,
} from '~proto/order/order_api_pb';
import { Site } from '~proto/site/site_pb';
import { OrderStatus } from '~proto/types/types_pb';
import { groupOrdersByBillingTaskPayloadName } from '~utilities/groupOrders';
import { trackById } from '~utilities/trackById';

enum RescheduleType {
  convertToAsap,
  convertToScheduled,
  moveEarlier,
  moveLater,
}

interface RescheduleTypeGroupValue {
  deliveryWindow: [Date, Date];
  minutesMoved: number;
  daysMovedCustom: number;
  type: RescheduleType;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-orders-pending-view',
  styleUrls: ['./orders-pending-view.component.scss'],
  templateUrl: './orders-pending-view.component.html',
})
export class OrdersPendingViewComponent implements OnInit {
  private dialogRef: MatDialogRef<any>;
  public RescheduleType = RescheduleType;
  public rescheduleTypeGroup: FormGroup;
  public loadPluralMapping = loadPluralMapping;
  public networkActive$ = new BehaviorSubject(false);
  public siteId$: Observable<number>;
  public ordersPlural = orderPluralMapping;
  public mappableOrders$: Observable<GroupedOrders<string>[]>;
  public currentSite$: Observable<Site.AsObject>;
  public trackById = trackById;
  public taskDelDate = new Date();
  public orderStatus = OrderStatus;
  @ViewChild('selectedOrderIds', { static: true }) private selectedOrders: MatSelectionList;
  @ViewChild('rescheduleModal', { static: true }) private rescheduleModal: TemplateRef<any>;
  @ViewChildren(MatCheckbox) private groupCheckBoxes: QueryList<MatCheckbox>;

  constructor(
    private ordersService: OrdersService,
    private siteService: JobSitesService,
    private matDialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
  ) {}

  public ngOnInit() {
    this.mappableOrders$ = this.ordersService.mappablePendingOrders$.pipe(
      map((orders) => groupOrdersByBillingTaskPayloadName(orders)),
    );
    this.siteId$ = this.siteService.currentSite$.pipe(map((site) => site && site.id));

    this.rescheduleTypeGroup = this.fb.group({
      daysMovedCustom: [null, [Validators.required]],
      deliveryWindow: [null],
      minutesMoved: [30],
      type: [RescheduleType.convertToAsap, [Validators.required]],
    });

    this.listenToRescheduleType();
  }

  public getUpForGrabsPrettyText(order: OrderCard) {
    switch (order.upForGrabsStatus) {
      case 'all':
        return 'Up For Grabs';
      case 'haulis_pick':
        return "HAULi's Pick";
      default:
        return null;
    }
  }

  public togglePayloadChecks(checked: MatCheckboxChange, payloadName: string) {
    const payloadMatches = this.selectedOrders.options.filter((option) => {
      const order = option.value as OrderCard;
      return order.payloadDetails.length === 1 && order.payloadDetails[0].payloadName === payloadName;
    });
    payloadMatches.forEach((option) => {
      if (option.selected !== checked.checked) {
        option.toggle();
      }
    });
  }

  public cancelSelectedOrders() {
    const orderIDs = this.getSelectedOrderIDs();
    if (orderIDs.length === 0) {
      return;
    }
    const request = new LMOBulkCancelOrderRequest();
    request.setOrderIdsList(orderIDs);
    this.networkActive$.next(true);
    this.ordersService
      .bulkCancelOrders$(request)
      .pipe(finalize(() => this.networkActive$.next(false)))
      .subscribe();
  }

  public rescheduleOrdersPrompt() {
    this.dialogRef = this.matDialog.open(this.rescheduleModal, { width: '450px' });
  }

  public rescheduleOrders() {
    const orderIDs = this.getSelectedOrderIDs();
    if (this.rescheduleTypeGroup.invalid || orderIDs.length === 0) {
      return;
    }
    const value: RescheduleTypeGroupValue = this.rescheduleTypeGroup.value;
    this.networkActive$.next(true);
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    let obs$: Observable<any>;
    if (value.type === RescheduleType.convertToAsap) {
      const request = new LMOBulkSetAsASAPOrdersRequest();
      request.setOrderIdsList(orderIDs);
      obs$ = this.ordersService.bulkConvertToAsap$(request);
    } else if (value.type === RescheduleType.convertToScheduled) {
      const request = new LMOBulkSetAsScheduledOrdersRequest();
      request.setOrderIdsList(orderIDs);
      const start = moment(value.deliveryWindow[0]);
      const end = moment(value.deliveryWindow[1]);
      const windowDuration = moment.duration(end.diff(start)).asMinutes();
      request.setDeliveryWindowStart(start.unix());
      request.setDeliveryWindowDurationMinutes(windowDuration);
      obs$ = this.ordersService.bulkConvertToScheduled$(request);
    } else if (value.type === RescheduleType.moveEarlier || value.type === RescheduleType.moveLater) {
      const request = new LMOBulkMoveScheduleForOrdersRequest();
      request.setOrderIdsList(orderIDs);
      const multiplier = value.type === RescheduleType.moveEarlier ? -1 : 1;
      request.setMinutesMoved(multiplier * (value.daysMovedCustom * 60 * 24 || value.minutesMoved));
      obs$ = this.ordersService.bulkMoveOrders$(request);
    }
    if (obs$) {
      obs$.pipe(finalize(() => this.networkActive$.next(false))).subscribe(() => {
        this.rescheduleTypeGroup.reset();
        this.rescheduleTypeGroup.get('type').setValue(RescheduleType.convertToAsap);
        this.snackBar.open('Orders Updated', null, { duration: 2000 });
        this.selectedOrders.options.forEach((option) => {
          if (option.selected) {
            option.toggle();
          }
        });
        this.groupCheckBoxes.forEach((checkbox) => {
          if (checkbox.checked) {
            checkbox.toggle();
          }
        });
      });
    }
  }

  private getSelectedOrderIDs(): number[] {
    return this.selectedOrders.options
      .map((option) => {
        if (option.selected) {
          const order = option.value as OrderCard;
          return order.id;
        }
      })
      .filter((id) => !!id);
  }

  private listenToRescheduleType() {
    const deliveryWindow = this.rescheduleTypeGroup.get('deliveryWindow');
    const minutesMoved = this.rescheduleTypeGroup.get('minutesMoved');
    const daysMovedCustom = this.rescheduleTypeGroup.get('daysMovedCustom');

    this.rescheduleTypeGroup
      .get('type')
      .valueChanges.pipe(startWith(this.rescheduleTypeGroup.get('type').value))
      .subscribe((type: RescheduleType) => {
        switch (type) {
          case RescheduleType.convertToAsap:
            deliveryWindow.disable();
            minutesMoved.disable();
            daysMovedCustom.disable();
            break;
          case RescheduleType.convertToScheduled:
            deliveryWindow.enable();
            minutesMoved.disable();
            daysMovedCustom.disable();
            break;
          case RescheduleType.moveLater:
          case RescheduleType.moveEarlier:
            deliveryWindow.disable();
            minutesMoved.enable();
            break;
        }
      });

    minutesMoved.valueChanges.pipe(startWith(minutesMoved.value)).subscribe((minutesMovedValue) => {
      if (minutesMovedValue === 'CUSTOM') {
        daysMovedCustom.enable();
      } else {
        daysMovedCustom.disable();
        daysMovedCustom.reset();
      }
    });
  }

  public trackByName(_index: number, group: GroupedOrders<string>): string {
    return group.groupHeader;
  }

  public trackByPayloadDetails(_: number, details: PayloadDetails) {
    return details.payloadName;
  }
}
