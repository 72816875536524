/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_cybertron_notification_notification_pb = require('../../../proto/cybertron/notification/notification_pb.js');
goog.exportSymbol('proto.cbv1.NotificationUserPreferencesRequest', null, global);
goog.exportSymbol('proto.cbv1.NotificationUserPreferencesResponse', null, global);
goog.exportSymbol('proto.cbv1.SetNewUserPreferencesRequest', null, global);
goog.exportSymbol('proto.cbv1.SetNewUserPreferencesRequests', null, global);
goog.exportSymbol('proto.cbv1.SetNewUserPreferencesResponse', null, global);
goog.exportSymbol('proto.cbv1.UpdatePreferenceRequest', null, global);
goog.exportSymbol('proto.cbv1.UpdatePreferenceRequests', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdatePreferenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UpdatePreferenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdatePreferenceRequest.displayName = 'proto.cbv1.UpdatePreferenceRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdatePreferenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdatePreferenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdatePreferenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdatePreferenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userEmailEnabled: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdatePreferenceRequest}
 */
proto.cbv1.UpdatePreferenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdatePreferenceRequest;
  return proto.cbv1.UpdatePreferenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdatePreferenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdatePreferenceRequest}
 */
proto.cbv1.UpdatePreferenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUserEmailEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdatePreferenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdatePreferenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdatePreferenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdatePreferenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserEmailEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.cbv1.UpdatePreferenceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.UpdatePreferenceRequest.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool user_email_enabled = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.UpdatePreferenceRequest.prototype.getUserEmailEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.UpdatePreferenceRequest.prototype.setUserEmailEnabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdatePreferenceRequests = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.UpdatePreferenceRequests.repeatedFields_, null);
};
goog.inherits(proto.cbv1.UpdatePreferenceRequests, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdatePreferenceRequests.displayName = 'proto.cbv1.UpdatePreferenceRequests';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.UpdatePreferenceRequests.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdatePreferenceRequests.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdatePreferenceRequests.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdatePreferenceRequests} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdatePreferenceRequests.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatesList: jspb.Message.toObjectList(msg.getUpdatesList(),
    proto.cbv1.UpdatePreferenceRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdatePreferenceRequests}
 */
proto.cbv1.UpdatePreferenceRequests.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdatePreferenceRequests;
  return proto.cbv1.UpdatePreferenceRequests.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdatePreferenceRequests} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdatePreferenceRequests}
 */
proto.cbv1.UpdatePreferenceRequests.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cbv1.UpdatePreferenceRequest;
      reader.readMessage(value,proto.cbv1.UpdatePreferenceRequest.deserializeBinaryFromReader);
      msg.addUpdates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdatePreferenceRequests.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdatePreferenceRequests.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdatePreferenceRequests} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdatePreferenceRequests.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cbv1.UpdatePreferenceRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UpdatePreferenceRequest updates = 1;
 * @return {!Array<!proto.cbv1.UpdatePreferenceRequest>}
 */
proto.cbv1.UpdatePreferenceRequests.prototype.getUpdatesList = function() {
  return /** @type{!Array<!proto.cbv1.UpdatePreferenceRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.UpdatePreferenceRequest, 1));
};


/** @param {!Array<!proto.cbv1.UpdatePreferenceRequest>} value */
proto.cbv1.UpdatePreferenceRequests.prototype.setUpdatesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.UpdatePreferenceRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.UpdatePreferenceRequest}
 */
proto.cbv1.UpdatePreferenceRequests.prototype.addUpdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.UpdatePreferenceRequest, opt_index);
};


proto.cbv1.UpdatePreferenceRequests.prototype.clearUpdatesList = function() {
  this.setUpdatesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.NotificationUserPreferencesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.NotificationUserPreferencesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.NotificationUserPreferencesRequest.displayName = 'proto.cbv1.NotificationUserPreferencesRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.NotificationUserPreferencesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.NotificationUserPreferencesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.NotificationUserPreferencesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NotificationUserPreferencesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.NotificationUserPreferencesRequest}
 */
proto.cbv1.NotificationUserPreferencesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.NotificationUserPreferencesRequest;
  return proto.cbv1.NotificationUserPreferencesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.NotificationUserPreferencesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.NotificationUserPreferencesRequest}
 */
proto.cbv1.NotificationUserPreferencesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.NotificationUserPreferencesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.NotificationUserPreferencesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.NotificationUserPreferencesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NotificationUserPreferencesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.NotificationUserPreferencesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.NotificationUserPreferencesResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.NotificationUserPreferencesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.NotificationUserPreferencesResponse.displayName = 'proto.cbv1.NotificationUserPreferencesResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.NotificationUserPreferencesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.NotificationUserPreferencesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.NotificationUserPreferencesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.NotificationUserPreferencesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NotificationUserPreferencesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    preferencesList: jspb.Message.toObjectList(msg.getPreferencesList(),
    proto_cybertron_notification_notification_pb.NotificationUserPreference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.NotificationUserPreferencesResponse}
 */
proto.cbv1.NotificationUserPreferencesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.NotificationUserPreferencesResponse;
  return proto.cbv1.NotificationUserPreferencesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.NotificationUserPreferencesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.NotificationUserPreferencesResponse}
 */
proto.cbv1.NotificationUserPreferencesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_notification_notification_pb.NotificationUserPreference;
      reader.readMessage(value,proto_cybertron_notification_notification_pb.NotificationUserPreference.deserializeBinaryFromReader);
      msg.addPreferences(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.NotificationUserPreferencesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.NotificationUserPreferencesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.NotificationUserPreferencesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NotificationUserPreferencesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_notification_notification_pb.NotificationUserPreference.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NotificationUserPreference preferences = 1;
 * @return {!Array<!proto.cbv1.NotificationUserPreference>}
 */
proto.cbv1.NotificationUserPreferencesResponse.prototype.getPreferencesList = function() {
  return /** @type{!Array<!proto.cbv1.NotificationUserPreference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_notification_notification_pb.NotificationUserPreference, 1));
};


/** @param {!Array<!proto.cbv1.NotificationUserPreference>} value */
proto.cbv1.NotificationUserPreferencesResponse.prototype.setPreferencesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.NotificationUserPreference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.NotificationUserPreference}
 */
proto.cbv1.NotificationUserPreferencesResponse.prototype.addPreferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.NotificationUserPreference, opt_index);
};


proto.cbv1.NotificationUserPreferencesResponse.prototype.clearPreferencesList = function() {
  this.setPreferencesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SetNewUserPreferencesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.SetNewUserPreferencesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SetNewUserPreferencesRequest.displayName = 'proto.cbv1.SetNewUserPreferencesRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SetNewUserPreferencesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SetNewUserPreferencesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SetNewUserPreferencesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SetNewUserPreferencesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    firebaseUid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SetNewUserPreferencesRequest}
 */
proto.cbv1.SetNewUserPreferencesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SetNewUserPreferencesRequest;
  return proto.cbv1.SetNewUserPreferencesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SetNewUserPreferencesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SetNewUserPreferencesRequest}
 */
proto.cbv1.SetNewUserPreferencesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebaseUid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SetNewUserPreferencesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SetNewUserPreferencesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SetNewUserPreferencesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SetNewUserPreferencesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirebaseUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string firebase_uid = 1;
 * @return {string}
 */
proto.cbv1.SetNewUserPreferencesRequest.prototype.getFirebaseUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.SetNewUserPreferencesRequest.prototype.setFirebaseUid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SetNewUserPreferencesRequests = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.SetNewUserPreferencesRequests.repeatedFields_, null);
};
goog.inherits(proto.cbv1.SetNewUserPreferencesRequests, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SetNewUserPreferencesRequests.displayName = 'proto.cbv1.SetNewUserPreferencesRequests';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.SetNewUserPreferencesRequests.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SetNewUserPreferencesRequests.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SetNewUserPreferencesRequests.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SetNewUserPreferencesRequests} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SetNewUserPreferencesRequests.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestsList: jspb.Message.toObjectList(msg.getRequestsList(),
    proto.cbv1.SetNewUserPreferencesRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SetNewUserPreferencesRequests}
 */
proto.cbv1.SetNewUserPreferencesRequests.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SetNewUserPreferencesRequests;
  return proto.cbv1.SetNewUserPreferencesRequests.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SetNewUserPreferencesRequests} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SetNewUserPreferencesRequests}
 */
proto.cbv1.SetNewUserPreferencesRequests.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cbv1.SetNewUserPreferencesRequest;
      reader.readMessage(value,proto.cbv1.SetNewUserPreferencesRequest.deserializeBinaryFromReader);
      msg.addRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SetNewUserPreferencesRequests.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SetNewUserPreferencesRequests.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SetNewUserPreferencesRequests} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SetNewUserPreferencesRequests.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cbv1.SetNewUserPreferencesRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SetNewUserPreferencesRequest requests = 1;
 * @return {!Array<!proto.cbv1.SetNewUserPreferencesRequest>}
 */
proto.cbv1.SetNewUserPreferencesRequests.prototype.getRequestsList = function() {
  return /** @type{!Array<!proto.cbv1.SetNewUserPreferencesRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.SetNewUserPreferencesRequest, 1));
};


/** @param {!Array<!proto.cbv1.SetNewUserPreferencesRequest>} value */
proto.cbv1.SetNewUserPreferencesRequests.prototype.setRequestsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.SetNewUserPreferencesRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.SetNewUserPreferencesRequest}
 */
proto.cbv1.SetNewUserPreferencesRequests.prototype.addRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.SetNewUserPreferencesRequest, opt_index);
};


proto.cbv1.SetNewUserPreferencesRequests.prototype.clearRequestsList = function() {
  this.setRequestsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SetNewUserPreferencesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.SetNewUserPreferencesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SetNewUserPreferencesResponse.displayName = 'proto.cbv1.SetNewUserPreferencesResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SetNewUserPreferencesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SetNewUserPreferencesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SetNewUserPreferencesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SetNewUserPreferencesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SetNewUserPreferencesResponse}
 */
proto.cbv1.SetNewUserPreferencesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SetNewUserPreferencesResponse;
  return proto.cbv1.SetNewUserPreferencesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SetNewUserPreferencesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SetNewUserPreferencesResponse}
 */
proto.cbv1.SetNewUserPreferencesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SetNewUserPreferencesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SetNewUserPreferencesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SetNewUserPreferencesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SetNewUserPreferencesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.cbv1);
