<div class="main-content">
  <ct-mini-modal title="Do you want to save the changes to the contract?">
    <main>
      <div class="button-wrap">
        <button ct-button [mat-dialog-close]="'nosave'" class="nosave">Don't Save</button>
        <ng-container *ctAllowedAccountRoles="[1, 2]">
          <button ct-danger-button (click)="cancel()" class="cancel">Cancel</button>
          <button ct-dark-button [mat-dialog-close]="'save'" class="save">Save</button>
        </ng-container>
      </div>
    </main>
  </ct-mini-modal>
</div>
