/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../side-nav/side-nav.component.ngfactory";
import * as i4 from "../side-nav/side-nav.component";
import * as i5 from "../../services/auth.service";
import * as i6 from "@angular/material/dialog";
import * as i7 from "../../services/intercom.service";
import * as i8 from "../../services/role.service";
import * as i9 from "@angular/material/snack-bar";
import * as i10 from "../../config-values.service";
import * as i11 from "./header.component";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: { "animation": [{ type: 7, name: "slideInOut", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateY(-100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateY(0%)" }, offset: null }, timings: "500ms ease-in" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { transform: "translateY(-100%)" }, offset: null }, timings: "200ms ease-in" }], options: null }], options: {} }] } });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_HeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "search"]], null, null, null, null, null)), i1.ɵncd(null, 3)], null, null); }
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "sub-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵncd(null, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "pipe"]], null, null, null, null, null)), i1.ɵncd(null, 4)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showSearch ? "content-with-search" : "content"); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.showSearch; _ck(_v, 6, 0, currVal_1); }, null); }
function View_HeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_HeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "scroll-header"]], [[24, "@slideInOut", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "menu-button"]], null, null, null, null, null)), i1.ɵncd(null, 5), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "hide-handheld"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_5)), i1.ɵdid(5, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "content"]], null, null, null, null, null)), i1.ɵncd(null, 6), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [["class", "pipe"]], null, null, null, null, null)), i1.ɵncd(null, 7)], function (_ck, _v) { var currVal_1 = i1.ɵnov(_v.parent, 19); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
function View_HeaderComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titleString; _ck(_v, 1, 0, currVal_0); }); }
function View_HeaderComponent_8(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 8), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_HeaderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_7)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_8)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titleString; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.titleString; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "main-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "menu-button"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(7, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "shale-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["alt", "logo"], ["src", "assets/icons/shale-logo-small.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "actions"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_4)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "ct-side-nav", [], null, [[null, "onMenuClose"], ["document", "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onKeyUp($event) !== false);
        ad = (pd_0 && ad);
    } if (("onMenuClose" === en)) {
        var pd_1 = (_co.toggleNav() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_SideNavComponent_0, i3.RenderType_SideNavComponent)), i1.ɵdid(17, 114688, null, 0, i4.SideNavComponent, [i5.AuthService, i6.MatDialog, i7.IntercomService, i8.RoleService, i9.MatSnackBar, i10.ConfigValuesService], { visible: [0, "visible"] }, { onMenuClose: "onMenuClose" }), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["titleRef", 2]], null, 0, null, View_HeaderComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 19); _ck(_v, 7, 0, currVal_0); var currVal_1 = !_co.noSubheader; _ck(_v, 13, 0, currVal_1); var currVal_2 = _co.showScrollHeader; _ck(_v, 15, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform(_co.displayNav$)); _ck(_v, 17, 0, currVal_3); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ct-header", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i11.HeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("ct-header", i11.HeaderComponent, View_HeaderComponent_Host_0, { showScrollHeader: "showScrollHeader", noScrollHeader: "noScrollHeader", noSubheader: "noSubheader", showSearch: "showSearch", titleString: "titleString" }, {}, ["[mainMenuButton]", "[headerActionsButtons]", "[subHeaderContent]", "[subHeaderSearch]", "[subHeaderActions]", "[scrollMenuButton]", "[scrollContent]", "[scrollActions]", "[title]"]);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
