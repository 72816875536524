<div class="select-units">
  <ct-mini-modal title="Select Units" subTitle="How should we track {{ payloadName }}?">
    <main>
      <div class="content">
        <div class="input-wrapper">
          <mat-select #unitSelect [formControl]="fc" ctAutomatedSelector="payload_units__unit">
            <mat-select-trigger>
              <ng-container *ngIf="unitSelect.value && unitSelect.value.id">
                <span class="unit-name">{{ unitSelect.value.name }}</span
                >&nbsp;<span class="unit-abbreviation">({{ unitSelect.value.abbreviation }})</span>
              </ng-container>
            </mat-select-trigger>
            <mat-option *ngFor="let unit of units; trackBy: trackById" [value]="unit"
              ><span class="unit-name" ctAutomatedSelector="{{ 'payload_units__name_' + unit.name }}">{{
                unit.name
              }}</span
              >&nbsp;<span class="unit-abbreviation">({{ unit.abbreviation }})</span></mat-option
            >
          </mat-select>
          <label>units</label>
        </div>
      </div>
    </main>
    <footer>
      <div class="content">
        <button ct-dark-button class="full-button" (click)="selected()" ctAutomatedSelector="payload_units__save">
          save
        </button>
      </div>
    </footer>
  </ct-mini-modal>
</div>
