import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {
  CanUpdateBidderInformationRequest,
  CanUpdateBidderInformationResponse,
  updateBidderInformationRequest,
  updateBidderInformationResponse,
} from '~proto/marketplace/rfq_api_pb';
import { MarketplaceBidder } from '~proto/marketplace/rfq_pb';
import { MarketplaceService } from '~services/marketplace.service';

@Component({
  selector: 'ct-marketplace-email-update-profile',
  styleUrls: ['./marketplace-email-update-profile.component.scss'],
  templateUrl: './marketplace-email-update-profile.component.html',
})
export class MarketplaceEmailUpdateProfileComponent implements OnInit {
  public profileForm: FormGroup;
  private bidderId: number;
  private token: string;
  public bidder: MarketplaceBidder.AsObject;
  public networkActive$$ = new BehaviorSubject(false);
  public placeholderString$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private marketplaceService: MarketplaceService) {}

  public ngOnInit() {
    this.bidderId = +this.route.snapshot.params['bidderId'];
    this.token = this.route.snapshot.params['token'];
    this.checkIfCanUpdate();
  }

  private buildForm() {
    this.profileForm = this.fb.group({
      account: [this.bidder.account, [Validators.required]],
      email: [{ value: this.bidder.email, disabled: true }, [Validators.required]],
      name: [this.bidder.name, [Validators.required]],
    });
  }

  private setPlaceholderString(value: string) {
    this.placeholderString$.next(value);
  }

  private checkIfCanUpdate() {
    const canUpdateProfileRequest = new CanUpdateBidderInformationRequest();
    canUpdateProfileRequest.setBidderId(this.bidderId);
    canUpdateProfileRequest.setToken(this.token);
    this.marketplaceService.canUpdateProfile(canUpdateProfileRequest).subscribe(
      (resp: CanUpdateBidderInformationResponse) => {
        this.bidder = { ...resp.getBidder().toObject() };
        this.buildForm();
      },
      (err) => {
        this.setPlaceholderString('Bidder Not Found');
      },
    );
  }

  public updateBidder() {
    if (this.profileForm.invalid) {
      this.profileForm.markAllAsTouched();
    } else {
      const updateBidderRequest = new updateBidderInformationRequest();
      updateBidderRequest.setAccount(this.profileForm.controls['account'].value);
      updateBidderRequest.setBidderId(this.bidder.id);
      updateBidderRequest.setName(this.profileForm.controls['name'].value);
      updateBidderRequest.setToken(this.token);
      this.marketplaceService.updateBidderInformation(updateBidderRequest).subscribe(
        (resp: updateBidderInformationResponse) => {
          if (resp.getUpdated()) {
            this.setPlaceholderString('YOUR PROFILE DETAILS UPDATED SUCCESSFULLY');
          } else {
            this.setPlaceholderString(
              'UPDATING YOUR PROFILE INFORMATION FAILED FOR SOME REASON. PLEASE TRY AGAIN LATER',
            );
          }
          this.networkActive$$.next(false);
        },
        (err) => {
          this.setPlaceholderString('UPDATING YOUR PROFILE INFORMATION FAILED FOR SOME REASON. PLEASE TRY AGAIN LATER');
          this.networkActive$$.next(false);
        },
      );
    }
  }
}
