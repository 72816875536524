import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  // animations: [slideInAndOutAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-dashboard-card',
  styleUrls: ['./dashboard-card.component.scss'],
  templateUrl: './dashboard-card.component.html',
})
export class DashboardCardComponent implements OnInit {
  @Input() public isLoading = false;
  @Input() public showVisibilityToggle = false;
  @Input() public localStorageKey: string;
  private visible$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public visible$: Observable<boolean> = this.visible$$.asObservable();
  private get localStorageKeyWithPrefix(): string {
    if (this.localStorageKey) {
      return `card-start-visible:${this.localStorageKey}`;
    }
  }

  constructor() {}

  public ngOnInit() {
    if (this.localStorageKeyWithPrefix) {
      const storageSetting = localStorage.getItem(this.localStorageKeyWithPrefix);
      let visible = false;
      // No setting from the user, default to visible
      if (storageSetting === null) {
        visible = true;
      } else {
        // The user has an existing setting,  use that
        visible = storageSetting === 'true';
      }
      this.visible$$.next(visible);
    }
  }

  public toggleVisiblity() {
    const newState = !this.visible$$.value;
    this.visible$$.next(newState);
    if (this.localStorageKeyWithPrefix) {
      localStorage.setItem(this.localStorageKeyWithPrefix, `${newState}`);
    }
  }
}
