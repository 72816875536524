<div class="business-hours-wrap">
  <ct-mini-modal title="Choose Business Hours">
    <form [formGroup]="weekForm">
      <ng-container
        *ngFor="let control of daysArray.controls; let i = index; trackBy: trackByIndex"
        formArrayName="days"
      >
        <ng-container [formGroupName]="i">
          <div class="week">
            <div class="week-day">
              <mat-checkbox formControlName="day">{{ weekMap[i] }}</mat-checkbox>
              <ng-container *ngIf="daysArray.at(i).value.day">
                <mat-select
                  class="opening-select"
                  formControlName="openingTime"
                  [compareWith]="compareByTime"
                  [placeholder]="weekMap[i] + ' Opening Time'"
                >
                  <mat-option *ngFor="let time of durationHours; trackBy: trackDurationHours" [value]="time">
                    {{ time.time }}
                  </mat-option>
                </mat-select>
                <span>-</span>
                <mat-select
                  [compareWith]="compareByTime"
                  formControlName="closingTime"
                  [placeholder]="weekMap[i] + ' Closing Time'"
                >
                  <mat-option *ngFor="let time of durationHours; trackBy: trackDurationHours" [value]="time">
                    {{ time.time }}
                  </mat-option>
                </mat-select>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </form>
    <footer>
      <div class="content">
        <button ct-button (click)="cancel()">Cancel</button>
        <button ct-danger-button (click)="continue()">Continue</button>
      </div>
    </footer>
  </ct-mini-modal>
</div>
