import * as tslib_1 from "tslib";
import { differenceInMinutes } from 'date-fns';
import { CreateOrderRequest, CreateSubTaskRequest, CreateTaskRequest, } from '~proto/order/order_api_pb';
import { TaskType } from '~proto/types/types_pb';
export function createOrderRequestFactory(formValue) {
    var createOrderRequest = getBasicCreateOrderRequest(formValue);
    addRegularTasksToOrderRequest(createOrderRequest, formValue);
    return makeLastDeliveryTaskBillable(flattenTasks(createOrderRequest));
}
export function createCustomOrderRequestFactory(formValue, customStops) {
    var createOrderRequest = getBasicCreateOrderRequest(formValue);
    if (formValue.trailerPickupLocation) {
        createOrderRequest.addTaskRequests(createTrailerPickupTask(formValue));
    }
    addCustomTasksToOrderRequest(createOrderRequest, customStops);
    if (formValue.trailerDropoffLocation) {
        createOrderRequest.addTaskRequests(createTrailerDropoffTask(formValue));
    }
    return makeLastDeliveryTaskBillable(flattenTasks(createOrderRequest));
}
function getBasicCreateOrderRequest(formValue) {
    var createOrderRequest = new CreateOrderRequest();
    createOrderRequest.setOrderQuantity(formValue.loads);
    createOrderRequest.setPurchaseOrderName(formValue.purchaseOrderName);
    createOrderRequest.setSalesOrderNumber(formValue.orderNumber);
    createOrderRequest.setIsAsapOrder(formValue.isAsapOrder);
    createOrderRequest.setDescription(formValue.description);
    createOrderRequest.setCostCenter(formValue.costCenter);
    createOrderRequest.setVendorContractId(formValue.vendorContractId);
    createOrderRequest.setContractTypeId(formValue.contractTypeId);
    createOrderRequest.setCostCenterId(formValue.costCenterId);
    createOrderRequest.setBusinessLineId(formValue.businessLine);
    if (formValue.scheduledDeliveryWindow && formValue.scheduledDeliveryWindow.length) {
        differenceInMinutes(formValue.scheduledDeliveryWindow[1], formValue.scheduledDeliveryWindow[0]);
        createOrderRequest.setDeliveryWindowStart(Math.round(formValue.scheduledDeliveryWindow[0].getTime() / 1000));
        createOrderRequest.setDeliveryWindowDurationMinutes(differenceInMinutes(formValue.scheduledDeliveryWindow[1], formValue.scheduledDeliveryWindow[0]));
    }
    if (formValue.trailer) {
        createOrderRequest.setTrailerId(formValue.trailer.id);
    }
    if (formValue.truck) {
        createOrderRequest.setTruckId(formValue.truck.id);
    }
    if (formValue.truckingVendor) {
        createOrderRequest.setTruckingVendorId(formValue.truckingVendor.id);
    }
    return createOrderRequest;
}
export function createTruckPickupTask(formValue) {
    var pickupTruckTask = new CreateTaskRequest();
    pickupTruckTask.setDescription(formValue.truckPickupLocation.directions);
    pickupTruckTask.setSiteId(formValue.truckPickupLocation.id);
    var pickupTruckSubTask = new CreateSubTaskRequest();
    pickupTruckSubTask.setTypeName('pickup-truck');
    pickupTruckTask.addCreateSubTaskRequest(pickupTruckSubTask);
    return pickupTruckTask;
}
export function createTruckDropoffTask(formValue) {
    var dropoffTruckTask = new CreateTaskRequest();
    dropoffTruckTask.setDescription(formValue.truckDropoffLocation.directions);
    dropoffTruckTask.setSiteId(formValue.truckDropoffLocation.id);
    var dropoffTruckSubTask = new CreateSubTaskRequest();
    dropoffTruckSubTask.setTypeName('dropoff-truck');
    dropoffTruckTask.addCreateSubTaskRequest(dropoffTruckSubTask);
    return dropoffTruckTask;
}
export function createTrailerPickupTask(formValue) {
    var pickupTrailerTask = new CreateTaskRequest();
    pickupTrailerTask.setDescription(formValue.trailerPickupLocation.directions);
    pickupTrailerTask.setSiteId(formValue.trailerPickupLocation.id);
    var pickupTrailerSubTask = new CreateSubTaskRequest();
    pickupTrailerSubTask.setTypeName('pickup-trailer');
    pickupTrailerTask.addCreateSubTaskRequest(pickupTrailerSubTask);
    return pickupTrailerTask;
}
export function createTrailerDropoffTask(formValue) {
    var dropoffTrailerTask = new CreateTaskRequest();
    dropoffTrailerTask.setDescription(formValue.trailerDropoffLocation.directions);
    dropoffTrailerTask.setSiteId(formValue.trailerDropoffLocation.id);
    var dropoffTrailerSubTask = new CreateSubTaskRequest();
    dropoffTrailerSubTask.setTypeName('dropoff-trailer');
    dropoffTrailerTask.addCreateSubTaskRequest(dropoffTrailerSubTask);
    return dropoffTrailerTask;
}
export function createPayloadPickupTask(formValue) {
    var pickupPayloadTask = new CreateTaskRequest();
    pickupPayloadTask.setDescription(formValue.pickupFrom.directions);
    pickupPayloadTask.setSiteId(formValue.pickupFrom.id);
    var pickupPayloadSubTask = new CreateSubTaskRequest();
    pickupPayloadSubTask.setOrderedQuantity(formValue.quantity);
    pickupPayloadSubTask.setPayloadId(formValue.payload.id);
    pickupPayloadSubTask.setTypeName('pickup-payload');
    pickupPayloadSubTask.setPurchaseOrderName(formValue.purchaseOrderName);
    pickupPayloadSubTask.setSalesOrderNumber(formValue.orderNumber);
    pickupPayloadSubTask.setMaintenanceOrderNumber(formValue.maintenanceOrderNumber);
    pickupPayloadTask.addCreateSubTaskRequest(pickupPayloadSubTask);
    return pickupPayloadTask;
}
export function createPayloadDropoffTask(formValue) {
    var dropoffPayloadTask = new CreateTaskRequest();
    dropoffPayloadTask.setDescription(formValue.dropoffSite.directions);
    dropoffPayloadTask.setSiteId(formValue.dropoffSite.id);
    var dropoffPayloadSubTask = new CreateSubTaskRequest();
    dropoffPayloadSubTask.setOrderedQuantity(formValue.quantity);
    dropoffPayloadSubTask.setPayloadId(formValue.payload.id);
    dropoffPayloadSubTask.setTypeName('dropoff-payload');
    dropoffPayloadSubTask.setPurchaseOrderName(formValue.purchaseOrderName);
    dropoffPayloadSubTask.setSalesOrderNumber(formValue.orderNumber);
    dropoffPayloadSubTask.setMaintenanceOrderNumber(formValue.maintenanceOrderNumber);
    dropoffPayloadTask.addCreateSubTaskRequest(dropoffPayloadSubTask);
    return dropoffPayloadTask;
}
function addRegularTasksToOrderRequest(createOrderRequest, formValue) {
    // Task setup
    if (formValue.truckPickupLocation && formValue.truck) {
        createOrderRequest.addTaskRequests(createTruckPickupTask(formValue));
    }
    if (formValue.trailerPickupLocation && formValue.trailer) {
        createOrderRequest.addTaskRequests(createTrailerPickupTask(formValue));
    }
    createOrderRequest.addTaskRequests(createPayloadPickupTask(formValue));
    createOrderRequest.addTaskRequests(createPayloadDropoffTask(formValue));
    if (formValue.trailerDropoffLocation && formValue.trailer) {
        createOrderRequest.addTaskRequests(createTrailerDropoffTask(formValue));
    }
    if (formValue.truckDropoffLocation && formValue.truck) {
        createOrderRequest.addTaskRequests(createTruckDropoffTask(formValue));
    }
}
function addCustomTasksToOrderRequest(request, customStops) {
    customStops.forEach(function (stop) {
        if (stop.stopType === TaskType.TASK_TYPE_PICKUP) {
            request.addTaskRequests(createPayloadPickupTask({
                maintenanceOrderNumber: stop.maintenanceOrderNumber,
                orderNumber: stop.salesOrderNumber,
                payload: stop.payload,
                pickupFrom: stop.location,
                purchaseOrderName: stop.purchaseOrderName,
                quantity: stop.quantity,
            }));
        }
        else if (stop.stopType === TaskType.TASK_TYPE_DROPOFF) {
            request.addTaskRequests(createPayloadDropoffTask({
                dropoffSite: stop.location,
                maintenanceOrderNumber: stop.maintenanceOrderNumber,
                orderNumber: stop.salesOrderNumber,
                payload: stop.payload,
                purchaseOrderName: stop.purchaseOrderName,
                quantity: stop.quantity,
            }));
        }
    });
}
export function flattenTasks(createOrderRequest) {
    var clone = createOrderRequest.clone();
    var flattenedTasks = clone.getTaskRequestsList().reduce(function (tasks, task) {
        // First task
        if (!tasks.length) {
            tasks.push(task);
        }
        else {
            var currentTask_1 = tasks[tasks.length - 1];
            // Same site and right next to each other so merge subTasks
            if (currentTask_1.getSiteId() === task.getSiteId()) {
                var subTasksToAdd = task.getCreateSubTaskRequestList();
                subTasksToAdd.forEach(function (subTask) {
                    currentTask_1.addCreateSubTaskRequest(subTask);
                });
                if (task.getIsBillable()) {
                    currentTask_1.setIsBillable(true);
                }
            }
            else {
                // They do not share the same site ID so this task is now the current task
                tasks.push(task);
            }
        }
        return tasks;
    }, []);
    flattenedTasks.forEach(function (task, index) {
        task.setSequence(index + 1);
    });
    clone.setTaskRequestsList(flattenedTasks);
    return clone;
}
export function makeLastDeliveryTaskBillable(createOrderRequest) {
    var clone = createOrderRequest.clone();
    var lastTaskWithPayloadDeliveryIndex = findLastIndex(clone.getTaskRequestsList(), function (task) {
        return task.getCreateSubTaskRequestList().some(function (subTask) { return subTask.getTypeName() === 'dropoff-payload'; });
    });
    if (lastTaskWithPayloadDeliveryIndex >= 0) {
        var lastTaskWithDelivery = clone
            .getTaskRequestsList()[lastTaskWithPayloadDeliveryIndex].clone();
        if (lastTaskWithDelivery) {
            lastTaskWithDelivery.setIsBillable(true);
            var requests = tslib_1.__spread(clone.getTaskRequestsList());
            requests[lastTaskWithPayloadDeliveryIndex] = lastTaskWithDelivery;
            clone.setTaskRequestsList(requests);
        }
    }
    return clone;
}
export function saveFormToLocalStorage(formValue, siteId, storageKey) {
    var _a;
    var savedSettings = {
        loads: formValue.loads,
        maintenanceOrderNumber: formValue.maintenanceOrderNumber,
        orderNumber: formValue.orderNumber,
        payload: formValue.payload,
        pickupFrom: formValue.pickupFrom,
        purchaseOrderName: formValue.purchaseOrderName,
        quantity: formValue.quantity,
        truckingVendor: formValue.truckingVendor,
    };
    var existingSavedSettings = JSON.parse(localStorage.getItem(storageKey)) || {};
    localStorage.setItem(storageKey, JSON.stringify(tslib_1.__assign({}, existingSavedSettings, (_a = {}, _a[siteId] = savedSettings, _a))));
}
export function getFormFromLocalStorage(siteId, storageKey) {
    var saved = JSON.parse(localStorage.getItem(storageKey));
    if (!saved || !saved[siteId]) {
        return {
            loads: null,
            maintenanceOrderNumber: null,
            orderNumber: null,
            payload: null,
            pickupFrom: null,
            purchaseOrderName: null,
            quantity: null,
            truckingVendor: null,
        };
    }
    return saved[siteId];
}
function findLastIndex(array, comparator) {
    for (var i = array.length - 1; i >= 0; i--) {
        if (comparator(array[i])) {
            return i;
        }
    }
    return -1;
}
export function addCustomTasksToBackhaulRequest(request, customStops) {
    customStops.forEach(function (stop) {
        if (stop.stopType === TaskType.TASK_TYPE_PICKUP) {
            request.addTaskRequests(createPayloadPickupTask({
                maintenanceOrderNumber: stop.maintenanceOrderNumber,
                orderNumber: stop.salesOrderNumber,
                payload: stop.payload,
                pickupFrom: stop.location,
                purchaseOrderName: stop.purchaseOrderName,
                quantity: stop.quantity,
            }));
        }
        else if (stop.stopType === TaskType.TASK_TYPE_DROPOFF) {
            request.addTaskRequests(createPayloadDropoffTask({
                dropoffSite: stop.location,
                maintenanceOrderNumber: stop.maintenanceOrderNumber,
                orderNumber: stop.salesOrderNumber,
                payload: stop.payload,
                purchaseOrderName: stop.purchaseOrderName,
                quantity: stop.quantity,
            }));
        }
    });
}
