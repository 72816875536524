/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-preferences.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-spinner";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/common";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../common/directives/automated-selector.directive";
import * as i10 from "../../common/header/header.component.ngfactory";
import * as i11 from "../../common/header/header.component";
import * as i12 from "./notification-preferences.component";
import * as i13 from "../../services/notifications.service";
var styles_NotificationPreferencesComponent = [i0.styles];
var RenderType_NotificationPreferencesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationPreferencesComponent, data: {} });
export { RenderType_NotificationPreferencesComponent as RenderType_NotificationPreferencesComponent };
function View_NotificationPreferencesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "clickable mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["email"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_NotificationPreferencesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["diameter", "24"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i4.View_MatSpinner_0, i4.RenderType_MatSpinner)), i1.ɵdid(1, 114688, null, 0, i5.MatSpinner, [i1.ElementRef, i6.Platform, [2, i7.DOCUMENT], [2, i8.ANIMATION_MODULE_TYPE], i5.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = "24"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_NotificationPreferencesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "notification"], ["ctAutomatedSelector", "notification__row"]], [[1, "data-aid", 0], [1, "data-aid-name", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i9.AutomatedSelectorDirective, [i1.ChangeDetectorRef], { ctAutomatedSelector: [0, "ctAutomatedSelector"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "name"], ["ctAutomatedSelector", "notification__name"]], [[1, "data-aid", 0], [1, "data-aid-name", 0]], null, null, null, null)), i1.ɵdid(3, 81920, null, 0, i9.AutomatedSelectorDirective, [i1.ChangeDetectorRef], { ctAutomatedSelector: [0, "ctAutomatedSelector"] }, null), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "span", [["class", "toggle"], ["ctAutomatedSelector", "notification__emailContainer"]], [[2, "enabled", null], [1, "data-aid", 0], [1, "data-aid-name", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updatePreference(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 81920, null, 0, i9.AutomatedSelectorDirective, [i1.ChangeDetectorRef], { ctAutomatedSelector: [0, "ctAutomatedSelector"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationPreferencesComponent_3)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationPreferencesComponent_4)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = "notification__row"; _ck(_v, 1, 0, currVal_2); var currVal_5 = "notification__name"; _ck(_v, 3, 0, currVal_5); var currVal_10 = "notification__emailContainer"; _ck(_v, 6, 0, currVal_10); var currVal_11 = (_v.context.$implicit.notificationType.globalEmailEnabled && !_v.context.$implicit.loading); _ck(_v, 8, 0, currVal_11); var currVal_12 = _v.context.$implicit.loading; _ck(_v, 10, 0, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).automatedTag; var currVal_1 = i1.ɵnov(_v, 1).automatedDataName; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 3).automatedTag; var currVal_4 = i1.ɵnov(_v, 3).automatedDataName; _ck(_v, 2, 0, currVal_3, currVal_4); var currVal_6 = _v.context.$implicit.notificationType.prettyPrintName; _ck(_v, 4, 0, currVal_6); var currVal_7 = _v.context.$implicit.userEmailEnabled; var currVal_8 = i1.ɵnov(_v, 6).automatedTag; var currVal_9 = i1.ɵnov(_v, 6).automatedDataName; _ck(_v, 5, 0, currVal_7, currVal_8, currVal_9); }); }
function View_NotificationPreferencesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "notification-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationPreferencesComponent_2)), i1.ɵdid(4, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit.preferences; var currVal_2 = _co.notificationTrackBy; _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
export function View_NotificationPreferencesComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "notification-preferences"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "ct-header", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onWindowScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_HeaderComponent_0, i10.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, [["header", 4]], 0, i11.HeaderComponent, [], { noSubheader: [0, "noSubheader"] }, null), (_l()(), i1.ɵeld(3, 0, null, 8, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Notification Preferences"])), (_l()(), i1.ɵeld(6, 0, null, 0, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "img", [["alt", "menu"], ["class", "menu-button"], ["ctAutomatedSelector", "menu__open"], ["src", "assets/icons/menu-24.svg"]], [[1, "data-aid", 0], [1, "data-aid-name", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).toggleNav() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 81920, null, 0, i9.AutomatedSelectorDirective, [i1.ChangeDetectorRef], { ctAutomatedSelector: [0, "ctAutomatedSelector"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "settings-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Notifications "])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NotificationPreferencesComponent_1)), i1.ɵdid(13, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 2, 0, currVal_0); var currVal_3 = "menu__open"; _ck(_v, 8, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.groups$)); _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 8).automatedTag; var currVal_2 = i1.ɵnov(_v, 8).automatedDataName; _ck(_v, 7, 0, currVal_1, currVal_2); }); }
export function View_NotificationPreferencesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sa-notification-preferences", [], null, null, null, View_NotificationPreferencesComponent_0, RenderType_NotificationPreferencesComponent)), i1.ɵdid(1, 114688, null, 0, i12.NotificationPreferencesComponent, [i13.NotificationsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationPreferencesComponentNgFactory = i1.ɵccf("sa-notification-preferences", i12.NotificationPreferencesComponent, View_NotificationPreferencesComponent_Host_0, {}, {}, []);
export { NotificationPreferencesComponentNgFactory as NotificationPreferencesComponentNgFactory };
