import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MarketplaceRfq } from '~proto/marketplace/rfq_pb';
import { MarketplaceRfqStatus } from '~proto/types/types_pb';
import { MarketplaceService } from '~services/marketplace.service';

@Component({
  selector: 'ct-marketplace-dashboard',
  styleUrls: ['./marketplace-dashboard.component.scss'],
  templateUrl: './marketplace-dashboard.component.html',
})
export class MarketplaceDashboardComponent implements OnInit, OnDestroy {
  constructor(private marketplaceService: MarketplaceService) {}

  public openRfq$$ = new BehaviorSubject<MarketplaceRfq.AsObject[]>(null);
  public scheduledRfq$$ = new BehaviorSubject<MarketplaceRfq.AsObject[]>(null);
  public awardedRfq$$ = new BehaviorSubject<MarketplaceRfq.AsObject[]>(null);
  public rfqTypes = MarketplaceRfqStatus;

  public ngOnInit() {
    this.marketplaceService.allRFQ$.subscribe((rfqs: MarketplaceRfq.AsObject[]) => {
      this.openRfq$$.next(
        (rfqs &&
          rfqs.filter(
            (rfq) =>
              rfq.status === MarketplaceRfqStatus.RFQ_STATUS_BIDDING_OPEN ||
              rfq.status === MarketplaceRfqStatus.RFQ_STATUS_BIDDING_CLOSED,
          )) ||
          null,
      );
      this.scheduledRfq$$.next(
        (rfqs && rfqs.filter((rfq) => rfq.status === MarketplaceRfqStatus.RFQ_STATUS_SCHEDULED)) || null,
      );
      this.awardedRfq$$.next(
        (rfqs && rfqs.filter((rfq) => rfq.status === MarketplaceRfqStatus.RFQ_STATUS_AWARDED)) || null,
      );
    });
  }
  public ngOnDestroy() {
    // this.marketplaceService.clearSelectedRfq();
  }
}
