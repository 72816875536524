import * as tslib_1 from "tslib";
import { BehaviorSubject, combineLatest } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { TrailersDetailsRequest, } from '~proto/trailer/trailer_api_pb';
import { TrailerAPI } from '~proto/trailer/trailer_api_pb_service';
import * as fromRouterConstants from '../app-routing.constants';
import * as i0 from "@angular/core";
import * as i1 from "./grpc.service";
import * as i2 from "./router-state.service";
var TrailerService = /** @class */ (function () {
    function TrailerService(grpc, routerState) {
        this.grpc = grpc;
        this.routerState = routerState;
        this.trailers$$ = new BehaviorSubject([]);
        this.networkActive$$ = new BehaviorSubject(false);
        this.getTrailers();
    }
    Object.defineProperty(TrailerService.prototype, "trailers$", {
        get: function () {
            return this.trailers$$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TrailerService.prototype, "networkActive$", {
        get: function () {
            return this.networkActive$$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TrailerService.prototype, "currentTrailer$", {
        get: function () {
            this.getTrailers();
            return combineLatest([
                this.routerState.listenForParamChange$(fromRouterConstants.TRAILER_ID),
                this.trailers$$,
            ]).pipe(map(function (_a) {
                var _b = tslib_1.__read(_a, 2), trailerId = _b[0], trailers = _b[1];
                return trailers.find(function (trailer) { return trailer.id === +trailerId; });
            }));
        },
        enumerable: true,
        configurable: true
    });
    TrailerService.prototype.getTrailers = function () {
        var _this = this;
        if (!this.trailers$$.value || !this.trailers$$.value.length) {
            this.networkActive$$.next(true);
        }
        var trailerReq = new TrailersDetailsRequest();
        this.grpc
            .invoke$(TrailerAPI.ListTrailersWithDetails, trailerReq)
            .pipe(finalize(function () {
            _this.networkActive$$.next(false);
        }))
            .subscribe(function (trailerResp) {
            _this.trailers$$.next(trailerResp.toObject().trailersList);
        });
    };
    TrailerService.prototype.addTrailer = function (createTrailerReq) {
        var _this = this;
        return this.grpc.invoke$(TrailerAPI.CreateTrailerWithModel, createTrailerReq).pipe(tap(function (_) {
            _this.getTrailers();
        }));
    };
    TrailerService.prototype.deleteTrailer = function (deleteTrailerReq) {
        var _this = this;
        return this.grpc.invoke$(TrailerAPI.DeleteTrailer, deleteTrailerReq).pipe(tap(function (_) {
            _this.getTrailers();
        }));
    };
    TrailerService.prototype.updateTrailer = function (updateTrailerReq) {
        var _this = this;
        return this.grpc.invoke$(TrailerAPI.UpdateTrailer, updateTrailerReq).pipe(tap(function (_) {
            _this.getTrailers();
        }));
    };
    TrailerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TrailerService_Factory() { return new TrailerService(i0.ɵɵinject(i1.GrpcService), i0.ɵɵinject(i2.RouterStateService)); }, token: TrailerService, providedIn: "root" });
    return TrailerService;
}());
export { TrailerService };
