/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_cybertron_types_types_pb = require('../../../proto/cybertron/types/types_pb.js');
var proto_cybertron_businessline_businessline_pb = require('../../../proto/cybertron/businessline/businessline_pb.js');
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
var proto_user_user_user_pb = require('../../../proto/user/user/user_pb.js');
goog.exportSymbol('proto.cbv1.ArchiveBusinessLineRequest', null, global);
goog.exportSymbol('proto.cbv1.ArchiveBusinessLineResponse', null, global);
goog.exportSymbol('proto.cbv1.BusinessLinesAttachedToUserRequest', null, global);
goog.exportSymbol('proto.cbv1.BusinessLinesAttachedToUserResponse', null, global);
goog.exportSymbol('proto.cbv1.CreateNewBusinessLineRequest', null, global);
goog.exportSymbol('proto.cbv1.CreateNewBusinessLineResponse', null, global);
goog.exportSymbol('proto.cbv1.ListBusinessLinesRequest', null, global);
goog.exportSymbol('proto.cbv1.ListBusinessLinesResponse', null, global);
goog.exportSymbol('proto.cbv1.UpdateNewBusinessLineRequest', null, global);
goog.exportSymbol('proto.cbv1.UpdateNewBusinessLineResponse', null, global);
goog.exportSymbol('proto.cbv1.UsersAttachedToBusinessLineRequest', null, global);
goog.exportSymbol('proto.cbv1.UsersAttachedToBusinessLineResponse', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.BusinessLinesAttachedToUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.BusinessLinesAttachedToUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.BusinessLinesAttachedToUserRequest.displayName = 'proto.cbv1.BusinessLinesAttachedToUserRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.BusinessLinesAttachedToUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.BusinessLinesAttachedToUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.BusinessLinesAttachedToUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BusinessLinesAttachedToUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.BusinessLinesAttachedToUserRequest}
 */
proto.cbv1.BusinessLinesAttachedToUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.BusinessLinesAttachedToUserRequest;
  return proto.cbv1.BusinessLinesAttachedToUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.BusinessLinesAttachedToUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.BusinessLinesAttachedToUserRequest}
 */
proto.cbv1.BusinessLinesAttachedToUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.BusinessLinesAttachedToUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.BusinessLinesAttachedToUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.BusinessLinesAttachedToUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BusinessLinesAttachedToUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.cbv1.BusinessLinesAttachedToUserRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.BusinessLinesAttachedToUserRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.BusinessLinesAttachedToUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.BusinessLinesAttachedToUserResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.BusinessLinesAttachedToUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.BusinessLinesAttachedToUserResponse.displayName = 'proto.cbv1.BusinessLinesAttachedToUserResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.BusinessLinesAttachedToUserResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.BusinessLinesAttachedToUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.BusinessLinesAttachedToUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.BusinessLinesAttachedToUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BusinessLinesAttachedToUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessLineList: jspb.Message.toObjectList(msg.getBusinessLineList(),
    proto_cybertron_businessline_businessline_pb.BusinessLine.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.BusinessLinesAttachedToUserResponse}
 */
proto.cbv1.BusinessLinesAttachedToUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.BusinessLinesAttachedToUserResponse;
  return proto.cbv1.BusinessLinesAttachedToUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.BusinessLinesAttachedToUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.BusinessLinesAttachedToUserResponse}
 */
proto.cbv1.BusinessLinesAttachedToUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_businessline_businessline_pb.BusinessLine;
      reader.readMessage(value,proto_cybertron_businessline_businessline_pb.BusinessLine.deserializeBinaryFromReader);
      msg.addBusinessLine(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.BusinessLinesAttachedToUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.BusinessLinesAttachedToUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.BusinessLinesAttachedToUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BusinessLinesAttachedToUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessLineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_businessline_businessline_pb.BusinessLine.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BusinessLine business_line = 1;
 * @return {!Array<!proto.cbv1.BusinessLine>}
 */
proto.cbv1.BusinessLinesAttachedToUserResponse.prototype.getBusinessLineList = function() {
  return /** @type{!Array<!proto.cbv1.BusinessLine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_businessline_businessline_pb.BusinessLine, 1));
};


/** @param {!Array<!proto.cbv1.BusinessLine>} value */
proto.cbv1.BusinessLinesAttachedToUserResponse.prototype.setBusinessLineList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.BusinessLine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.BusinessLine}
 */
proto.cbv1.BusinessLinesAttachedToUserResponse.prototype.addBusinessLine = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.BusinessLine, opt_index);
};


proto.cbv1.BusinessLinesAttachedToUserResponse.prototype.clearBusinessLineList = function() {
  this.setBusinessLineList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UsersAttachedToBusinessLineRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UsersAttachedToBusinessLineRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UsersAttachedToBusinessLineRequest.displayName = 'proto.cbv1.UsersAttachedToBusinessLineRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UsersAttachedToBusinessLineRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UsersAttachedToBusinessLineRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UsersAttachedToBusinessLineRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UsersAttachedToBusinessLineRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UsersAttachedToBusinessLineRequest}
 */
proto.cbv1.UsersAttachedToBusinessLineRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UsersAttachedToBusinessLineRequest;
  return proto.cbv1.UsersAttachedToBusinessLineRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UsersAttachedToBusinessLineRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UsersAttachedToBusinessLineRequest}
 */
proto.cbv1.UsersAttachedToBusinessLineRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UsersAttachedToBusinessLineRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UsersAttachedToBusinessLineRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UsersAttachedToBusinessLineRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UsersAttachedToBusinessLineRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.UsersAttachedToBusinessLineRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.UsersAttachedToBusinessLineRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UsersAttachedToBusinessLineResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.UsersAttachedToBusinessLineResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.UsersAttachedToBusinessLineResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UsersAttachedToBusinessLineResponse.displayName = 'proto.cbv1.UsersAttachedToBusinessLineResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.UsersAttachedToBusinessLineResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UsersAttachedToBusinessLineResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UsersAttachedToBusinessLineResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UsersAttachedToBusinessLineResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UsersAttachedToBusinessLineResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto_user_user_user_pb.UserV2.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UsersAttachedToBusinessLineResponse}
 */
proto.cbv1.UsersAttachedToBusinessLineResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UsersAttachedToBusinessLineResponse;
  return proto.cbv1.UsersAttachedToBusinessLineResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UsersAttachedToBusinessLineResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UsersAttachedToBusinessLineResponse}
 */
proto.cbv1.UsersAttachedToBusinessLineResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_user_user_user_pb.UserV2;
      reader.readMessage(value,proto_user_user_user_pb.UserV2.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UsersAttachedToBusinessLineResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UsersAttachedToBusinessLineResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UsersAttachedToBusinessLineResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UsersAttachedToBusinessLineResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_user_user_user_pb.UserV2.serializeBinaryToWriter
    );
  }
};


/**
 * repeated cbv2.UserV2 users = 1;
 * @return {!Array<!proto.cbv2.UserV2>}
 */
proto.cbv1.UsersAttachedToBusinessLineResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.cbv2.UserV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_user_user_user_pb.UserV2, 1));
};


/** @param {!Array<!proto.cbv2.UserV2>} value */
proto.cbv1.UsersAttachedToBusinessLineResponse.prototype.setUsersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv2.UserV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv2.UserV2}
 */
proto.cbv1.UsersAttachedToBusinessLineResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv2.UserV2, opt_index);
};


proto.cbv1.UsersAttachedToBusinessLineResponse.prototype.clearUsersList = function() {
  this.setUsersList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateNewBusinessLineRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.UpdateNewBusinessLineRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.UpdateNewBusinessLineRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateNewBusinessLineRequest.displayName = 'proto.cbv1.UpdateNewBusinessLineRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.UpdateNewBusinessLineRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateNewBusinessLineRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateNewBusinessLineRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateNewBusinessLineRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateNewBusinessLineRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userIdsList: jspb.Message.getRepeatedField(msg, 3),
    costCenterIdsList: jspb.Message.getRepeatedField(msg, 4)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateNewBusinessLineRequest}
 */
proto.cbv1.UpdateNewBusinessLineRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateNewBusinessLineRequest;
  return proto.cbv1.UpdateNewBusinessLineRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateNewBusinessLineRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateNewBusinessLineRequest}
 */
proto.cbv1.UpdateNewBusinessLineRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    case 4:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setCostCenterIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateNewBusinessLineRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateNewBusinessLineRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateNewBusinessLineRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateNewBusinessLineRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getCostCenterIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.UpdateNewBusinessLineRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.UpdateNewBusinessLineRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.UpdateNewBusinessLineRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.UpdateNewBusinessLineRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string user_ids = 3;
 * @return {!Array<string>}
 */
proto.cbv1.UpdateNewBusinessLineRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/** @param {!Array<string>} value */
proto.cbv1.UpdateNewBusinessLineRequest.prototype.setUserIdsList = function(value) {
  jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.UpdateNewBusinessLineRequest.prototype.addUserIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


proto.cbv1.UpdateNewBusinessLineRequest.prototype.clearUserIdsList = function() {
  this.setUserIdsList([]);
};


/**
 * repeated int32 cost_center_ids = 4;
 * @return {!Array<number>}
 */
proto.cbv1.UpdateNewBusinessLineRequest.prototype.getCostCenterIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/** @param {!Array<number>} value */
proto.cbv1.UpdateNewBusinessLineRequest.prototype.setCostCenterIdsList = function(value) {
  jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.UpdateNewBusinessLineRequest.prototype.addCostCenterIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


proto.cbv1.UpdateNewBusinessLineRequest.prototype.clearCostCenterIdsList = function() {
  this.setCostCenterIdsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateNewBusinessLineResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UpdateNewBusinessLineResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateNewBusinessLineResponse.displayName = 'proto.cbv1.UpdateNewBusinessLineResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateNewBusinessLineResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateNewBusinessLineResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateNewBusinessLineResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateNewBusinessLineResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessLine: (f = msg.getBusinessLine()) && proto_cybertron_businessline_businessline_pb.BusinessLine.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateNewBusinessLineResponse}
 */
proto.cbv1.UpdateNewBusinessLineResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateNewBusinessLineResponse;
  return proto.cbv1.UpdateNewBusinessLineResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateNewBusinessLineResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateNewBusinessLineResponse}
 */
proto.cbv1.UpdateNewBusinessLineResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_businessline_businessline_pb.BusinessLine;
      reader.readMessage(value,proto_cybertron_businessline_businessline_pb.BusinessLine.deserializeBinaryFromReader);
      msg.setBusinessLine(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateNewBusinessLineResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateNewBusinessLineResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateNewBusinessLineResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateNewBusinessLineResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessLine();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_businessline_businessline_pb.BusinessLine.serializeBinaryToWriter
    );
  }
};


/**
 * optional BusinessLine business_line = 1;
 * @return {?proto.cbv1.BusinessLine}
 */
proto.cbv1.UpdateNewBusinessLineResponse.prototype.getBusinessLine = function() {
  return /** @type{?proto.cbv1.BusinessLine} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_businessline_businessline_pb.BusinessLine, 1));
};


/** @param {?proto.cbv1.BusinessLine|undefined} value */
proto.cbv1.UpdateNewBusinessLineResponse.prototype.setBusinessLine = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.UpdateNewBusinessLineResponse.prototype.clearBusinessLine = function() {
  this.setBusinessLine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.UpdateNewBusinessLineResponse.prototype.hasBusinessLine = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateNewBusinessLineRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.CreateNewBusinessLineRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.CreateNewBusinessLineRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateNewBusinessLineRequest.displayName = 'proto.cbv1.CreateNewBusinessLineRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.CreateNewBusinessLineRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateNewBusinessLineRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateNewBusinessLineRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateNewBusinessLineRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateNewBusinessLineRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userIdsList: jspb.Message.getRepeatedField(msg, 2),
    costCenterIdsList: jspb.Message.getRepeatedField(msg, 3)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateNewBusinessLineRequest}
 */
proto.cbv1.CreateNewBusinessLineRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateNewBusinessLineRequest;
  return proto.cbv1.CreateNewBusinessLineRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateNewBusinessLineRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateNewBusinessLineRequest}
 */
proto.cbv1.CreateNewBusinessLineRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setCostCenterIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateNewBusinessLineRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateNewBusinessLineRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateNewBusinessLineRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateNewBusinessLineRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getCostCenterIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.cbv1.CreateNewBusinessLineRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.CreateNewBusinessLineRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string user_ids = 2;
 * @return {!Array<string>}
 */
proto.cbv1.CreateNewBusinessLineRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<string>} value */
proto.cbv1.CreateNewBusinessLineRequest.prototype.setUserIdsList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.CreateNewBusinessLineRequest.prototype.addUserIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.cbv1.CreateNewBusinessLineRequest.prototype.clearUserIdsList = function() {
  this.setUserIdsList([]);
};


/**
 * repeated int32 cost_center_ids = 3;
 * @return {!Array<number>}
 */
proto.cbv1.CreateNewBusinessLineRequest.prototype.getCostCenterIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/** @param {!Array<number>} value */
proto.cbv1.CreateNewBusinessLineRequest.prototype.setCostCenterIdsList = function(value) {
  jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.CreateNewBusinessLineRequest.prototype.addCostCenterIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


proto.cbv1.CreateNewBusinessLineRequest.prototype.clearCostCenterIdsList = function() {
  this.setCostCenterIdsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateNewBusinessLineResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateNewBusinessLineResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateNewBusinessLineResponse.displayName = 'proto.cbv1.CreateNewBusinessLineResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateNewBusinessLineResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateNewBusinessLineResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateNewBusinessLineResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateNewBusinessLineResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessLine: (f = msg.getBusinessLine()) && proto_cybertron_businessline_businessline_pb.BusinessLine.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateNewBusinessLineResponse}
 */
proto.cbv1.CreateNewBusinessLineResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateNewBusinessLineResponse;
  return proto.cbv1.CreateNewBusinessLineResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateNewBusinessLineResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateNewBusinessLineResponse}
 */
proto.cbv1.CreateNewBusinessLineResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_businessline_businessline_pb.BusinessLine;
      reader.readMessage(value,proto_cybertron_businessline_businessline_pb.BusinessLine.deserializeBinaryFromReader);
      msg.setBusinessLine(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateNewBusinessLineResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateNewBusinessLineResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateNewBusinessLineResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateNewBusinessLineResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessLine();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_businessline_businessline_pb.BusinessLine.serializeBinaryToWriter
    );
  }
};


/**
 * optional BusinessLine business_line = 1;
 * @return {?proto.cbv1.BusinessLine}
 */
proto.cbv1.CreateNewBusinessLineResponse.prototype.getBusinessLine = function() {
  return /** @type{?proto.cbv1.BusinessLine} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_businessline_businessline_pb.BusinessLine, 1));
};


/** @param {?proto.cbv1.BusinessLine|undefined} value */
proto.cbv1.CreateNewBusinessLineResponse.prototype.setBusinessLine = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.CreateNewBusinessLineResponse.prototype.clearBusinessLine = function() {
  this.setBusinessLine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CreateNewBusinessLineResponse.prototype.hasBusinessLine = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListBusinessLinesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ListBusinessLinesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListBusinessLinesRequest.displayName = 'proto.cbv1.ListBusinessLinesRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListBusinessLinesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListBusinessLinesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListBusinessLinesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListBusinessLinesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListBusinessLinesRequest}
 */
proto.cbv1.ListBusinessLinesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListBusinessLinesRequest;
  return proto.cbv1.ListBusinessLinesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListBusinessLinesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListBusinessLinesRequest}
 */
proto.cbv1.ListBusinessLinesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListBusinessLinesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListBusinessLinesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListBusinessLinesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListBusinessLinesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListBusinessLinesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListBusinessLinesResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListBusinessLinesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListBusinessLinesResponse.displayName = 'proto.cbv1.ListBusinessLinesResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListBusinessLinesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListBusinessLinesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListBusinessLinesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListBusinessLinesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListBusinessLinesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessLinesList: jspb.Message.toObjectList(msg.getBusinessLinesList(),
    proto_cybertron_businessline_businessline_pb.BusinessLine.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListBusinessLinesResponse}
 */
proto.cbv1.ListBusinessLinesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListBusinessLinesResponse;
  return proto.cbv1.ListBusinessLinesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListBusinessLinesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListBusinessLinesResponse}
 */
proto.cbv1.ListBusinessLinesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_businessline_businessline_pb.BusinessLine;
      reader.readMessage(value,proto_cybertron_businessline_businessline_pb.BusinessLine.deserializeBinaryFromReader);
      msg.addBusinessLines(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListBusinessLinesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListBusinessLinesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListBusinessLinesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListBusinessLinesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessLinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_businessline_businessline_pb.BusinessLine.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BusinessLine business_lines = 1;
 * @return {!Array<!proto.cbv1.BusinessLine>}
 */
proto.cbv1.ListBusinessLinesResponse.prototype.getBusinessLinesList = function() {
  return /** @type{!Array<!proto.cbv1.BusinessLine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_businessline_businessline_pb.BusinessLine, 1));
};


/** @param {!Array<!proto.cbv1.BusinessLine>} value */
proto.cbv1.ListBusinessLinesResponse.prototype.setBusinessLinesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.BusinessLine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.BusinessLine}
 */
proto.cbv1.ListBusinessLinesResponse.prototype.addBusinessLines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.BusinessLine, opt_index);
};


proto.cbv1.ListBusinessLinesResponse.prototype.clearBusinessLinesList = function() {
  this.setBusinessLinesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ArchiveBusinessLineRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ArchiveBusinessLineRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ArchiveBusinessLineRequest.displayName = 'proto.cbv1.ArchiveBusinessLineRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ArchiveBusinessLineRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ArchiveBusinessLineRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ArchiveBusinessLineRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ArchiveBusinessLineRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ArchiveBusinessLineRequest}
 */
proto.cbv1.ArchiveBusinessLineRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ArchiveBusinessLineRequest;
  return proto.cbv1.ArchiveBusinessLineRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ArchiveBusinessLineRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ArchiveBusinessLineRequest}
 */
proto.cbv1.ArchiveBusinessLineRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ArchiveBusinessLineRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ArchiveBusinessLineRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ArchiveBusinessLineRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ArchiveBusinessLineRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.ArchiveBusinessLineRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.ArchiveBusinessLineRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ArchiveBusinessLineResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ArchiveBusinessLineResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ArchiveBusinessLineResponse.displayName = 'proto.cbv1.ArchiveBusinessLineResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ArchiveBusinessLineResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ArchiveBusinessLineResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ArchiveBusinessLineResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ArchiveBusinessLineResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ArchiveBusinessLineResponse}
 */
proto.cbv1.ArchiveBusinessLineResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ArchiveBusinessLineResponse;
  return proto.cbv1.ArchiveBusinessLineResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ArchiveBusinessLineResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ArchiveBusinessLineResponse}
 */
proto.cbv1.ArchiveBusinessLineResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ArchiveBusinessLineResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ArchiveBusinessLineResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ArchiveBusinessLineResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ArchiveBusinessLineResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.ArchiveBusinessLineResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.ArchiveBusinessLineResponse.prototype.setStatus = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.cbv1.ArchiveBusinessLineResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.ArchiveBusinessLineResponse.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.cbv1);
