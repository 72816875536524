import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { endWith, map, takeWhile } from 'rxjs/operators';

export const longPressTimeInMs = 500;
const timerInterval = 30;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-long-escape',
  styleUrls: ['./long-escape.component.scss'],
  templateUrl: './long-escape.component.html',
})
export class LongEscapeComponent implements OnInit {
  public circumference = 2 * Math.PI * 12;
  public dashOffset$: Observable<number>;
  constructor() {}

  public ngOnInit() {
    this.dashOffset$ = interval(timerInterval).pipe(
      map((i) => this.circumference * ((i * timerInterval) / longPressTimeInMs)),
      takeWhile((partial) => partial < this.circumference),
      endWith(this.circumference),
    );
  }
}
