// package: cbv1
// file: proto/cybertron/order/order_api.proto

var proto_cybertron_order_order_api_pb = require("../../../proto/cybertron/order/order_api_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var OrderAPI = (function () {
  function OrderAPI() {}
  OrderAPI.serviceName = "cbv1.OrderAPI";
  return OrderAPI;
}());

OrderAPI.CreateOrder = {
  methodName: "CreateOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.CreateOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.CreateOrderResponse
};

OrderAPI.LMOUpdateOrder = {
  methodName: "LMOUpdateOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.LMOUpdateOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.LMOUpdateOrderResponse
};

OrderAPI.LMOUpdatePendingOrder = {
  methodName: "LMOUpdatePendingOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.LMOUpdatePendingOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.LMOUpdatePendingOrderResponse
};

OrderAPI.VendorUpdateOrder = {
  methodName: "VendorUpdateOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.VendorUpdateOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.VendorUpdateOrderResponse
};

OrderAPI.GetOrder = {
  methodName: "GetOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.GetOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.OrderResponse
};

OrderAPI.LMOCancelOrder = {
  methodName: "LMOCancelOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.LMOCancelOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.LMOCancelOrderResponse
};

OrderAPI.LMOReassignOrder = {
  methodName: "LMOReassignOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.LMOReassignOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.LMOReassignOrderResponse
};

OrderAPI.DriverGetOrder = {
  methodName: "DriverGetOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.DriverGetOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.DriverGetOrderResponse
};

OrderAPI.DriverRejectOrder = {
  methodName: "DriverRejectOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.DriverRejectOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.DriverRejectOrderResponse
};

OrderAPI.DriverAcceptOrder = {
  methodName: "DriverAcceptOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.DriverAcceptOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.DriverAcceptOrderResponse
};

OrderAPI.DriverCompleteOrder = {
  methodName: "DriverCompleteOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.DriverCompleteOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.DriverCompleteOrderResponse
};

OrderAPI.DriverStopWorkRelated = {
  methodName: "DriverStopWorkRelated",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.DriverStopRequest,
  responseType: proto_cybertron_order_order_api_pb.DriverStopResponse
};

OrderAPI.AutoCloseOrder = {
  methodName: "AutoCloseOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.AutoCloseOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.AutoCloseOrderResponse
};

OrderAPI.VendorDeclineOrder = {
  methodName: "VendorDeclineOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.VendorDeclineOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.VendorDeclineOrderResponse
};

OrderAPI.VendorDispatchOrder = {
  methodName: "VendorDispatchOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.VendorDispatchOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.VendorDispatchOrderResponse
};

OrderAPI.VendorUnassignOrder = {
  methodName: "VendorUnassignOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.VendorUnassignOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.VendorUnassignOrderResponse
};

OrderAPI.VendorUnassignDriverOrder = {
  methodName: "VendorUnassignDriverOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.VendorUnassignDriverOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.VendorUnassignDriverOrderResponse
};

OrderAPI.CompleteOrder = {
  methodName: "CompleteOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.CompleteOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.CompleteOrderResponse
};

OrderAPI.EventStream = {
  methodName: "EventStream",
  service: OrderAPI,
  requestStream: false,
  responseStream: true,
  requestType: proto_cybertron_order_order_api_pb.OrderEventStreamRequest,
  responseType: proto_cybertron_order_order_api_pb.OrderEventStreamResponse
};

OrderAPI.LMOListMappablePendingOrders = {
  methodName: "LMOListMappablePendingOrders",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.MappableOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.MappableOrderResponse
};

OrderAPI.LMOListMappableInProgressOrders = {
  methodName: "LMOListMappableInProgressOrders",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.MappableOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.MappableOrderResponse
};

OrderAPI.LMOListMappableCompletedOrders = {
  methodName: "LMOListMappableCompletedOrders",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.MappableOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.MappableOrderCompletedResponse
};

OrderAPI.VendorListMappablePendingOrders = {
  methodName: "VendorListMappablePendingOrders",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.MappableOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.MappableOrderResponse
};

OrderAPI.VendorListMappableInProgressOrders = {
  methodName: "VendorListMappableInProgressOrders",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.MappableOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.MappableOrderResponse
};

OrderAPI.VendorListMappableCompletedOrders = {
  methodName: "VendorListMappableCompletedOrders",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.MappableOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.MappableOrderCompletedResponse
};

OrderAPI.StreamMappableOrders = {
  methodName: "StreamMappableOrders",
  service: OrderAPI,
  requestStream: false,
  responseStream: true,
  requestType: proto_cybertron_order_order_api_pb.StreamMappableOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.StreamMappableOrderResponse
};

OrderAPI.UnassignTrailerOrder = {
  methodName: "UnassignTrailerOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.UnassignTrailerOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.UnassignTrailerOrderResponse
};

OrderAPI.UnassignTruckOrder = {
  methodName: "UnassignTruckOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.UnassignTruckOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.UnassignTruckOrderResponse
};

OrderAPI.PurchaseOrderSearch = {
  methodName: "PurchaseOrderSearch",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.PurchaseOrderSearchRequest,
  responseType: proto_cybertron_order_order_api_pb.PurchaseOrderSearchResponse
};

OrderAPI.SalesOrderSearch = {
  methodName: "SalesOrderSearch",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.SalesOrderSearchRequest,
  responseType: proto_cybertron_order_order_api_pb.SalesOrderSearchResponse
};

OrderAPI.MaintenanceOrderSearch = {
  methodName: "MaintenanceOrderSearch",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.MaintenanceOrderSearchRequest,
  responseType: proto_cybertron_order_order_api_pb.MaintenanceOrderSearchResponse
};

OrderAPI.GetSiteOrderEtas = {
  methodName: "GetSiteOrderEtas",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.GetSiteOrderETAsRequest,
  responseType: proto_cybertron_order_order_api_pb.GetSiteOrderETAsResponse
};

OrderAPI.AcceptOrderForDriver = {
  methodName: "AcceptOrderForDriver",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.AcceptOrderForDriverRequest,
  responseType: proto_cybertron_order_order_api_pb.AcceptOrderForDriverResponse
};

OrderAPI.ManuallyCompleteTask = {
  methodName: "ManuallyCompleteTask",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.ManuallyCompleteTaskRequest,
  responseType: proto_cybertron_order_order_api_pb.ManuallyCompleteTaskResponse
};

OrderAPI.SwapDriverOnOrder = {
  methodName: "SwapDriverOnOrder",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.SwapDriverOnOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.SwapDriverOnOrderResponse
};

OrderAPI.GetFleetManagerScheduleForDay = {
  methodName: "GetFleetManagerScheduleForDay",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.FleetManagerDriverRequest,
  responseType: proto_cybertron_order_order_api_pb.FleetManagerDriverResponse
};

OrderAPI.DeleteOrderAttachment = {
  methodName: "DeleteOrderAttachment",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.DeleteAttachmentRequest,
  responseType: proto_cybertron_order_order_api_pb.DeleteAttachmentResponse
};

OrderAPI.GetOrderAttachmentUrl = {
  methodName: "GetOrderAttachmentUrl",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.AttachmentUrlRequest,
  responseType: proto_cybertron_order_order_api_pb.AttachmentUrlResponse
};

OrderAPI.GetDetentionStats = {
  methodName: "GetDetentionStats",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.StatsRequest,
  responseType: proto_cybertron_order_order_api_pb.StatsResponse
};

OrderAPI.LMOBulkCancelOrders = {
  methodName: "LMOBulkCancelOrders",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.LMOBulkCancelOrderRequest,
  responseType: proto_cybertron_order_order_api_pb.LMOBulkCancelOrderResponse
};

OrderAPI.LMOBulkSetAsScheduledOrders = {
  methodName: "LMOBulkSetAsScheduledOrders",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.LMOBulkSetAsScheduledOrdersRequest,
  responseType: proto_cybertron_order_order_api_pb.LMOBulkSetAsScheduledOrdersResponse
};

OrderAPI.LMOBulkSetAsASAPOrders = {
  methodName: "LMOBulkSetAsASAPOrders",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.LMOBulkSetAsASAPOrdersRequest,
  responseType: proto_cybertron_order_order_api_pb.LMOBulkSetAsASAPOrdersResponse
};

OrderAPI.LMOBulkMoveScheduleForOrders = {
  methodName: "LMOBulkMoveScheduleForOrders",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.LMOBulkMoveScheduleForOrdersRequest,
  responseType: proto_cybertron_order_order_api_pb.LMOBulkMoveScheduleForOrdersResponse
};

OrderAPI.DriverCanUploadDocument = {
  methodName: "DriverCanUploadDocument",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.DriverCanUploadRequest,
  responseType: proto_cybertron_order_order_api_pb.DriverCanUploadResponse
};

OrderAPI.VendorListSlimCompletedOrders = {
  methodName: "VendorListSlimCompletedOrders",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.GetVendorMappableOrderCompletedSlimRequest,
  responseType: proto_cybertron_order_order_api_pb.GetVendorMappableOrderCompletedSlimResponse
};

OrderAPI.DriverUpdateLoadDetails = {
  methodName: "DriverUpdateLoadDetails",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.DriverUpdateLoadDetailsRequest,
  responseType: proto_cybertron_order_order_api_pb.DriverUpdateLoadDetailsResponse
};

OrderAPI.DispatcherCalendarOrderSummaries = {
  methodName: "DispatcherCalendarOrderSummaries",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.DispatcherCalendarOrderSummariesRequest,
  responseType: proto_cybertron_order_order_api_pb.DispatcherCalendarOrderSummariesResponse
};

OrderAPI.DispatcherUpdateTaskSite = {
  methodName: "DispatcherUpdateTaskSite",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.DispatcherUpdateTaskSiteSingle,
  responseType: proto_cybertron_order_order_api_pb.OrderResponse
};

OrderAPI.CreateAsset = {
  methodName: "CreateAsset",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.CreateAssetRequest,
  responseType: proto_cybertron_order_order_api_pb.CreateAssetResponse
};

OrderAPI.UpdateAsset = {
  methodName: "UpdateAsset",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.UpdateAssetRequest,
  responseType: proto_cybertron_order_order_api_pb.UpdateAssetResponse
};

OrderAPI.GetAssets = {
  methodName: "GetAssets",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.GetAssetsRequest,
  responseType: proto_cybertron_order_order_api_pb.GetAssetsResponse
};

OrderAPI.GetAssetTypes = {
  methodName: "GetAssetTypes",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.GetAssetTypesRequest,
  responseType: proto_cybertron_order_order_api_pb.GetAssetTypesResponse
};

OrderAPI.GetAssetGroups = {
  methodName: "GetAssetGroups",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.GetAssetGroupsRequest,
  responseType: proto_cybertron_order_order_api_pb.GetAssetGroupsResponse
};

OrderAPI.GetSubTaskTypes = {
  methodName: "GetSubTaskTypes",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.GetSubTaskTypesRequest,
  responseType: proto_cybertron_order_order_api_pb.GetSubTaskTypesResponse
};

OrderAPI.ListCostCenter = {
  methodName: "ListCostCenter",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.ListCostCenterRequest,
  responseType: proto_cybertron_order_order_api_pb.ListCostCenterResponse
};

OrderAPI.CreateCostCenter = {
  methodName: "CreateCostCenter",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.CreateCostCenterRequest,
  responseType: proto_cybertron_order_order_api_pb.CreateCostCenterResponse
};

OrderAPI.ArchiveCostCenter = {
  methodName: "ArchiveCostCenter",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.ArchiveCostCenterRequest,
  responseType: proto_cybertron_order_order_api_pb.ArchiveCostCenterResponse
};

OrderAPI.UpdateCostCenter = {
  methodName: "UpdateCostCenter",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.UpdateCostCenterRequest,
  responseType: proto_cybertron_order_order_api_pb.UpdateCostCenterResponse
};

OrderAPI.LoadHeatMap = {
  methodName: "LoadHeatMap",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.LoadHeatMapRequest,
  responseType: proto_cybertron_order_order_api_pb.LoadHeatMapResponse
};

OrderAPI.BackHaul = {
  methodName: "BackHaul",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.BackHaulRequest,
  responseType: proto_cybertron_order_order_api_pb.BackHaulResponse
};

OrderAPI.CanLoadBeBackHaul = {
  methodName: "CanLoadBeBackHaul",
  service: OrderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.CanLoadBeBackHaulRequest,
  responseType: proto_cybertron_order_order_api_pb.CanLoadBeBackHaulResponse
};

exports.OrderAPI = OrderAPI;

function OrderAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

OrderAPIClient.prototype.createOrder = function createOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.CreateOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.lMOUpdateOrder = function lMOUpdateOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.LMOUpdateOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.lMOUpdatePendingOrder = function lMOUpdatePendingOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.LMOUpdatePendingOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.vendorUpdateOrder = function vendorUpdateOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.VendorUpdateOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.getOrder = function getOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.GetOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.lMOCancelOrder = function lMOCancelOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.LMOCancelOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.lMOReassignOrder = function lMOReassignOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.LMOReassignOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.driverGetOrder = function driverGetOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.DriverGetOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.driverRejectOrder = function driverRejectOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.DriverRejectOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.driverAcceptOrder = function driverAcceptOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.DriverAcceptOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.driverCompleteOrder = function driverCompleteOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.DriverCompleteOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.driverStopWorkRelated = function driverStopWorkRelated(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.DriverStopWorkRelated, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.autoCloseOrder = function autoCloseOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.AutoCloseOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.vendorDeclineOrder = function vendorDeclineOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.VendorDeclineOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.vendorDispatchOrder = function vendorDispatchOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.VendorDispatchOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.vendorUnassignOrder = function vendorUnassignOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.VendorUnassignOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.vendorUnassignDriverOrder = function vendorUnassignDriverOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.VendorUnassignDriverOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.completeOrder = function completeOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.CompleteOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.eventStream = function eventStream(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(OrderAPI.EventStream, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.lMOListMappablePendingOrders = function lMOListMappablePendingOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.LMOListMappablePendingOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.lMOListMappableInProgressOrders = function lMOListMappableInProgressOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.LMOListMappableInProgressOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.lMOListMappableCompletedOrders = function lMOListMappableCompletedOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.LMOListMappableCompletedOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.vendorListMappablePendingOrders = function vendorListMappablePendingOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.VendorListMappablePendingOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.vendorListMappableInProgressOrders = function vendorListMappableInProgressOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.VendorListMappableInProgressOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.vendorListMappableCompletedOrders = function vendorListMappableCompletedOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.VendorListMappableCompletedOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.streamMappableOrders = function streamMappableOrders(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(OrderAPI.StreamMappableOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.unassignTrailerOrder = function unassignTrailerOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.UnassignTrailerOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.unassignTruckOrder = function unassignTruckOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.UnassignTruckOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.purchaseOrderSearch = function purchaseOrderSearch(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.PurchaseOrderSearch, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.salesOrderSearch = function salesOrderSearch(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.SalesOrderSearch, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.maintenanceOrderSearch = function maintenanceOrderSearch(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.MaintenanceOrderSearch, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.getSiteOrderEtas = function getSiteOrderEtas(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.GetSiteOrderEtas, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.acceptOrderForDriver = function acceptOrderForDriver(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.AcceptOrderForDriver, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.manuallyCompleteTask = function manuallyCompleteTask(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.ManuallyCompleteTask, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.swapDriverOnOrder = function swapDriverOnOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.SwapDriverOnOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.getFleetManagerScheduleForDay = function getFleetManagerScheduleForDay(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.GetFleetManagerScheduleForDay, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.deleteOrderAttachment = function deleteOrderAttachment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.DeleteOrderAttachment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.getOrderAttachmentUrl = function getOrderAttachmentUrl(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.GetOrderAttachmentUrl, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.getDetentionStats = function getDetentionStats(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.GetDetentionStats, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.lMOBulkCancelOrders = function lMOBulkCancelOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.LMOBulkCancelOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.lMOBulkSetAsScheduledOrders = function lMOBulkSetAsScheduledOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.LMOBulkSetAsScheduledOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.lMOBulkSetAsASAPOrders = function lMOBulkSetAsASAPOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.LMOBulkSetAsASAPOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.lMOBulkMoveScheduleForOrders = function lMOBulkMoveScheduleForOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.LMOBulkMoveScheduleForOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.driverCanUploadDocument = function driverCanUploadDocument(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.DriverCanUploadDocument, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.vendorListSlimCompletedOrders = function vendorListSlimCompletedOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.VendorListSlimCompletedOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.driverUpdateLoadDetails = function driverUpdateLoadDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.DriverUpdateLoadDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.dispatcherCalendarOrderSummaries = function dispatcherCalendarOrderSummaries(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.DispatcherCalendarOrderSummaries, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.dispatcherUpdateTaskSite = function dispatcherUpdateTaskSite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.DispatcherUpdateTaskSite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.createAsset = function createAsset(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.CreateAsset, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.updateAsset = function updateAsset(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.UpdateAsset, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.getAssets = function getAssets(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.GetAssets, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.getAssetTypes = function getAssetTypes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.GetAssetTypes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.getAssetGroups = function getAssetGroups(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.GetAssetGroups, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.getSubTaskTypes = function getSubTaskTypes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.GetSubTaskTypes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.listCostCenter = function listCostCenter(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.ListCostCenter, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.createCostCenter = function createCostCenter(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.CreateCostCenter, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.archiveCostCenter = function archiveCostCenter(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.ArchiveCostCenter, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.updateCostCenter = function updateCostCenter(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.UpdateCostCenter, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.loadHeatMap = function loadHeatMap(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.LoadHeatMap, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.backHaul = function backHaul(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.BackHaul, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderAPIClient.prototype.canLoadBeBackHaul = function canLoadBeBackHaul(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderAPI.CanLoadBeBackHaul, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.OrderAPIClient = OrderAPIClient;

var VendorAPI = (function () {
  function VendorAPI() {}
  VendorAPI.serviceName = "cbv1.VendorAPI";
  return VendorAPI;
}());

VendorAPI.CanUpdateOrderPickupTime = {
  methodName: "CanUpdateOrderPickupTime",
  service: VendorAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.CanUpdateOrderPickupTimeRequest,
  responseType: proto_cybertron_order_order_api_pb.CanUpdateOrderPickupTimeResponse
};

VendorAPI.UpdateOrderPickupTime = {
  methodName: "UpdateOrderPickupTime",
  service: VendorAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_order_order_api_pb.UpdateOrderPickupTimeRequest,
  responseType: proto_cybertron_order_order_api_pb.UpdateOrderPickupTimeResponse
};

exports.VendorAPI = VendorAPI;

function VendorAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

VendorAPIClient.prototype.canUpdateOrderPickupTime = function canUpdateOrderPickupTime(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VendorAPI.CanUpdateOrderPickupTime, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

VendorAPIClient.prototype.updateOrderPickupTime = function updateOrderPickupTime(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VendorAPI.UpdateOrderPickupTime, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.VendorAPIClient = VendorAPIClient;

