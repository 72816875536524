import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { HotkeyComponent, HotkeyComponentData } from '~common/hotkey/hotkey.component';

@Directive({
  selector: '[ctHotkey]',
})
export class HotkeyDirective {
  @Input() public ctHotkey: string;
  @Input() public routerLink: string | string[];
  @Input() public actionDescription: string;
  @Output() public hotkeyPressed = new EventEmitter();

  constructor(public router: Router, private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute) {}

  @HostListener('click', ['$event'])
  public onclick($event) {
    if (window.innerWidth > 1024) {
      const data: HotkeyComponentData = {
        hotkey: this.ctHotkey.toLocaleUpperCase(),
        taskDescription: this.actionDescription,
      };
      this.snackBar.openFromComponent(HotkeyComponent, {
        data: data,
        duration: 5000,
        panelClass: 'hotkey-snackbar',
      });
    }
  }

  @HostListener('document:keydown', ['$event'])
  public key(e: KeyboardEvent) {
    if (!this.ctHotkey || e.ctrlKey || e.metaKey) {
      return;
    }
    if ((e.target as HTMLElement).tagName !== 'BODY') {
      return;
    }
    if (e.key === this.ctHotkey) {
      e.preventDefault();
      if (this.routerLink) {
        if (typeof this.routerLink === 'string') {
          this.router.navigateByUrl(this.routerLink, { relativeTo: this.activatedRoute });
        } else {
          this.router.navigate(this.routerLink, { relativeTo: this.activatedRoute });
        }
        return;
      }

      this.hotkeyPressed.next(e);
    }
  }
}
