/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_geo_geo_pb = require('../../../proto/geo/geo_pb.js');
var proto_cybertron_marketplace_rfq_pb = require('../../../proto/cybertron/marketplace/rfq_pb.js');
var proto_cybertron_types_types_pb = require('../../../proto/cybertron/types/types_pb.js');
goog.exportSymbol('proto.cbv1.Bidder', null, global);
goog.exportSymbol('proto.cbv1.CalculateDistanceRequest', null, global);
goog.exportSymbol('proto.cbv1.CalculateDistanceResponse', null, global);
goog.exportSymbol('proto.cbv1.CanBidRequest', null, global);
goog.exportSymbol('proto.cbv1.CanBidResponse', null, global);
goog.exportSymbol('proto.cbv1.CanMarkNotInterestedRequest', null, global);
goog.exportSymbol('proto.cbv1.CanMarkNotInterestedResponse', null, global);
goog.exportSymbol('proto.cbv1.CanUpdateBidderInformationRequest', null, global);
goog.exportSymbol('proto.cbv1.CanUpdateBidderInformationResponse', null, global);
goog.exportSymbol('proto.cbv1.CreateRFQBidRequest', null, global);
goog.exportSymbol('proto.cbv1.CreateRFQBidResponse', null, global);
goog.exportSymbol('proto.cbv1.CreateRFQRequest', null, global);
goog.exportSymbol('proto.cbv1.CreateRFQResponse', null, global);
goog.exportSymbol('proto.cbv1.LoadRFQByIdRequest', null, global);
goog.exportSymbol('proto.cbv1.LoadRFQByIdResponse', null, global);
goog.exportSymbol('proto.cbv1.RfqFileUrlRequest', null, global);
goog.exportSymbol('proto.cbv1.RfqFileUrlResponse', null, global);
goog.exportSymbol('proto.cbv1.SearchBidderRequest', null, global);
goog.exportSymbol('proto.cbv1.SearchBidderResponse', null, global);
goog.exportSymbol('proto.cbv1.StreamerRequest', null, global);
goog.exportSymbol('proto.cbv1.StreamerResponse', null, global);
goog.exportSymbol('proto.cbv1.awardRfqRequest', null, global);
goog.exportSymbol('proto.cbv1.awardRfqResponse', null, global);
goog.exportSymbol('proto.cbv1.cancelRfqRequest', null, global);
goog.exportSymbol('proto.cbv1.cancelRfqResponse', null, global);
goog.exportSymbol('proto.cbv1.listRfqRequest', null, global);
goog.exportSymbol('proto.cbv1.listRfqResponse', null, global);
goog.exportSymbol('proto.cbv1.markNotInterestedRequest', null, global);
goog.exportSymbol('proto.cbv1.markNotInterestedResponse', null, global);
goog.exportSymbol('proto.cbv1.rfqSuggestionsRequest', null, global);
goog.exportSymbol('proto.cbv1.rfqSuggestionsResponse', null, global);
goog.exportSymbol('proto.cbv1.updateBidderInformationRequest', null, global);
goog.exportSymbol('proto.cbv1.updateBidderInformationResponse', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CalculateDistanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CalculateDistanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CalculateDistanceRequest.displayName = 'proto.cbv1.CalculateDistanceRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CalculateDistanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CalculateDistanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CalculateDistanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CalculateDistanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pickUp: (f = msg.getPickUp()) && proto_geo_geo_pb.Point.toObject(includeInstance, f),
    dropOff: (f = msg.getDropOff()) && proto_geo_geo_pb.Point.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CalculateDistanceRequest}
 */
proto.cbv1.CalculateDistanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CalculateDistanceRequest;
  return proto.cbv1.CalculateDistanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CalculateDistanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CalculateDistanceRequest}
 */
proto.cbv1.CalculateDistanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_geo_geo_pb.Point;
      reader.readMessage(value,proto_geo_geo_pb.Point.deserializeBinaryFromReader);
      msg.setPickUp(value);
      break;
    case 2:
      var value = new proto_geo_geo_pb.Point;
      reader.readMessage(value,proto_geo_geo_pb.Point.deserializeBinaryFromReader);
      msg.setDropOff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CalculateDistanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CalculateDistanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CalculateDistanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CalculateDistanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPickUp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_geo_geo_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getDropOff();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_geo_geo_pb.Point.serializeBinaryToWriter
    );
  }
};


/**
 * optional Point pick_up = 1;
 * @return {?proto.cbv1.Point}
 */
proto.cbv1.CalculateDistanceRequest.prototype.getPickUp = function() {
  return /** @type{?proto.cbv1.Point} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Point, 1));
};


/** @param {?proto.cbv1.Point|undefined} value */
proto.cbv1.CalculateDistanceRequest.prototype.setPickUp = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.CalculateDistanceRequest.prototype.clearPickUp = function() {
  this.setPickUp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CalculateDistanceRequest.prototype.hasPickUp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Point drop_off = 2;
 * @return {?proto.cbv1.Point}
 */
proto.cbv1.CalculateDistanceRequest.prototype.getDropOff = function() {
  return /** @type{?proto.cbv1.Point} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Point, 2));
};


/** @param {?proto.cbv1.Point|undefined} value */
proto.cbv1.CalculateDistanceRequest.prototype.setDropOff = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cbv1.CalculateDistanceRequest.prototype.clearDropOff = function() {
  this.setDropOff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CalculateDistanceRequest.prototype.hasDropOff = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CalculateDistanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CalculateDistanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CalculateDistanceResponse.displayName = 'proto.cbv1.CalculateDistanceResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CalculateDistanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CalculateDistanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CalculateDistanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CalculateDistanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    distance: jspb.Message.getFieldWithDefault(msg, 1, 0),
    reason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CalculateDistanceResponse}
 */
proto.cbv1.CalculateDistanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CalculateDistanceResponse;
  return proto.cbv1.CalculateDistanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CalculateDistanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CalculateDistanceResponse}
 */
proto.cbv1.CalculateDistanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDistance(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CalculateDistanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CalculateDistanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CalculateDistanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CalculateDistanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDistance();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 distance = 1;
 * @return {number}
 */
proto.cbv1.CalculateDistanceResponse.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.CalculateDistanceResponse.prototype.setDistance = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.cbv1.CalculateDistanceResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.CalculateDistanceResponse.prototype.setReason = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LoadRFQByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LoadRFQByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LoadRFQByIdRequest.displayName = 'proto.cbv1.LoadRFQByIdRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LoadRFQByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LoadRFQByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LoadRFQByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LoadRFQByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    rfqId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LoadRFQByIdRequest}
 */
proto.cbv1.LoadRFQByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LoadRFQByIdRequest;
  return proto.cbv1.LoadRFQByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LoadRFQByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LoadRFQByIdRequest}
 */
proto.cbv1.LoadRFQByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRfqId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LoadRFQByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LoadRFQByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LoadRFQByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LoadRFQByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRfqId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 rfq_id = 1;
 * @return {number}
 */
proto.cbv1.LoadRFQByIdRequest.prototype.getRfqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.LoadRFQByIdRequest.prototype.setRfqId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LoadRFQByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LoadRFQByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LoadRFQByIdResponse.displayName = 'proto.cbv1.LoadRFQByIdResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LoadRFQByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LoadRFQByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LoadRFQByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LoadRFQByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rfq: (f = msg.getRfq()) && proto_cybertron_marketplace_rfq_pb.MarketplaceRfq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LoadRFQByIdResponse}
 */
proto.cbv1.LoadRFQByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LoadRFQByIdResponse;
  return proto.cbv1.LoadRFQByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LoadRFQByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LoadRFQByIdResponse}
 */
proto.cbv1.LoadRFQByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_marketplace_rfq_pb.MarketplaceRfq;
      reader.readMessage(value,proto_cybertron_marketplace_rfq_pb.MarketplaceRfq.deserializeBinaryFromReader);
      msg.setRfq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LoadRFQByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LoadRFQByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LoadRFQByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LoadRFQByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRfq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_marketplace_rfq_pb.MarketplaceRfq.serializeBinaryToWriter
    );
  }
};


/**
 * optional MarketplaceRfq rfq = 1;
 * @return {?proto.cbv1.MarketplaceRfq}
 */
proto.cbv1.LoadRFQByIdResponse.prototype.getRfq = function() {
  return /** @type{?proto.cbv1.MarketplaceRfq} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_marketplace_rfq_pb.MarketplaceRfq, 1));
};


/** @param {?proto.cbv1.MarketplaceRfq|undefined} value */
proto.cbv1.LoadRFQByIdResponse.prototype.setRfq = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.LoadRFQByIdResponse.prototype.clearRfq = function() {
  this.setRfq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.LoadRFQByIdResponse.prototype.hasRfq = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.awardRfqRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.awardRfqRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.awardRfqRequest.displayName = 'proto.cbv1.awardRfqRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.awardRfqRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.awardRfqRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.awardRfqRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.awardRfqRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    rfqId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bidId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.awardRfqRequest}
 */
proto.cbv1.awardRfqRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.awardRfqRequest;
  return proto.cbv1.awardRfqRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.awardRfqRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.awardRfqRequest}
 */
proto.cbv1.awardRfqRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRfqId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBidId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.awardRfqRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.awardRfqRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.awardRfqRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.awardRfqRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRfqId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBidId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 rfq_id = 1;
 * @return {number}
 */
proto.cbv1.awardRfqRequest.prototype.getRfqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.awardRfqRequest.prototype.setRfqId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 bid_id = 2;
 * @return {number}
 */
proto.cbv1.awardRfqRequest.prototype.getBidId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.awardRfqRequest.prototype.setBidId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.awardRfqResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.awardRfqResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.awardRfqResponse.displayName = 'proto.cbv1.awardRfqResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.awardRfqResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.awardRfqResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.awardRfqResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.awardRfqResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    awarded: jspb.Message.getFieldWithDefault(msg, 1, false),
    reason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.awardRfqResponse}
 */
proto.cbv1.awardRfqResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.awardRfqResponse;
  return proto.cbv1.awardRfqResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.awardRfqResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.awardRfqResponse}
 */
proto.cbv1.awardRfqResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAwarded(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.awardRfqResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.awardRfqResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.awardRfqResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.awardRfqResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAwarded();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool awarded = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.awardRfqResponse.prototype.getAwarded = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.awardRfqResponse.prototype.setAwarded = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.cbv1.awardRfqResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.awardRfqResponse.prototype.setReason = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.listRfqRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.listRfqRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.listRfqRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.listRfqRequest.displayName = 'proto.cbv1.listRfqRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.listRfqRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.listRfqRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.listRfqRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.listRfqRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.listRfqRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusesList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.listRfqRequest}
 */
proto.cbv1.listRfqRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.listRfqRequest;
  return proto.cbv1.listRfqRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.listRfqRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.listRfqRequest}
 */
proto.cbv1.listRfqRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<!proto.cbv1.MarketplaceRfqStatus>} */ (reader.readPackedEnum());
      msg.setStatusesList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.listRfqRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.listRfqRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.listRfqRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.listRfqRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated MarketplaceRfqStatus statuses = 1;
 * @return {!Array<!proto.cbv1.MarketplaceRfqStatus>}
 */
proto.cbv1.listRfqRequest.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.cbv1.MarketplaceRfqStatus>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<!proto.cbv1.MarketplaceRfqStatus>} value */
proto.cbv1.listRfqRequest.prototype.setStatusesList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.cbv1.MarketplaceRfqStatus} value
 * @param {number=} opt_index
 */
proto.cbv1.listRfqRequest.prototype.addStatuses = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv1.listRfqRequest.prototype.clearStatusesList = function() {
  this.setStatusesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.listRfqResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.listRfqResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.listRfqResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.listRfqResponse.displayName = 'proto.cbv1.listRfqResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.listRfqResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.listRfqResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.listRfqResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.listRfqResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.listRfqResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rfqsList: jspb.Message.toObjectList(msg.getRfqsList(),
    proto_cybertron_marketplace_rfq_pb.MarketplaceRfq.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.listRfqResponse}
 */
proto.cbv1.listRfqResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.listRfqResponse;
  return proto.cbv1.listRfqResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.listRfqResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.listRfqResponse}
 */
proto.cbv1.listRfqResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_marketplace_rfq_pb.MarketplaceRfq;
      reader.readMessage(value,proto_cybertron_marketplace_rfq_pb.MarketplaceRfq.deserializeBinaryFromReader);
      msg.addRfqs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.listRfqResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.listRfqResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.listRfqResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.listRfqResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRfqsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_marketplace_rfq_pb.MarketplaceRfq.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MarketplaceRfq rfqs = 1;
 * @return {!Array<!proto.cbv1.MarketplaceRfq>}
 */
proto.cbv1.listRfqResponse.prototype.getRfqsList = function() {
  return /** @type{!Array<!proto.cbv1.MarketplaceRfq>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_marketplace_rfq_pb.MarketplaceRfq, 1));
};


/** @param {!Array<!proto.cbv1.MarketplaceRfq>} value */
proto.cbv1.listRfqResponse.prototype.setRfqsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.MarketplaceRfq=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.MarketplaceRfq}
 */
proto.cbv1.listRfqResponse.prototype.addRfqs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.MarketplaceRfq, opt_index);
};


proto.cbv1.listRfqResponse.prototype.clearRfqsList = function() {
  this.setRfqsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.cancelRfqRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.cancelRfqRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.cancelRfqRequest.displayName = 'proto.cbv1.cancelRfqRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.cancelRfqRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.cancelRfqRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.cancelRfqRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.cancelRfqRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    rfqId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.cancelRfqRequest}
 */
proto.cbv1.cancelRfqRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.cancelRfqRequest;
  return proto.cbv1.cancelRfqRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.cancelRfqRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.cancelRfqRequest}
 */
proto.cbv1.cancelRfqRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRfqId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.cancelRfqRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.cancelRfqRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.cancelRfqRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.cancelRfqRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRfqId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 rfq_id = 1;
 * @return {number}
 */
proto.cbv1.cancelRfqRequest.prototype.getRfqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.cancelRfqRequest.prototype.setRfqId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.cancelRfqResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.cancelRfqResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.cancelRfqResponse.displayName = 'proto.cbv1.cancelRfqResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.cancelRfqResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.cancelRfqResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.cancelRfqResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.cancelRfqResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cancelled: jspb.Message.getFieldWithDefault(msg, 1, false),
    reason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.cancelRfqResponse}
 */
proto.cbv1.cancelRfqResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.cancelRfqResponse;
  return proto.cbv1.cancelRfqResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.cancelRfqResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.cancelRfqResponse}
 */
proto.cbv1.cancelRfqResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCancelled(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.cancelRfqResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.cancelRfqResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.cancelRfqResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.cancelRfqResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCancelled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool cancelled = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.cancelRfqResponse.prototype.getCancelled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.cancelRfqResponse.prototype.setCancelled = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.cbv1.cancelRfqResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.cancelRfqResponse.prototype.setReason = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CanMarkNotInterestedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CanMarkNotInterestedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CanMarkNotInterestedRequest.displayName = 'proto.cbv1.CanMarkNotInterestedRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CanMarkNotInterestedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CanMarkNotInterestedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CanMarkNotInterestedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanMarkNotInterestedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rfqId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CanMarkNotInterestedRequest}
 */
proto.cbv1.CanMarkNotInterestedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CanMarkNotInterestedRequest;
  return proto.cbv1.CanMarkNotInterestedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CanMarkNotInterestedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CanMarkNotInterestedRequest}
 */
proto.cbv1.CanMarkNotInterestedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRfqId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CanMarkNotInterestedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CanMarkNotInterestedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CanMarkNotInterestedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanMarkNotInterestedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRfqId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.cbv1.CanMarkNotInterestedRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.CanMarkNotInterestedRequest.prototype.setToken = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 rfq_id = 2;
 * @return {number}
 */
proto.cbv1.CanMarkNotInterestedRequest.prototype.getRfqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.CanMarkNotInterestedRequest.prototype.setRfqId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CanMarkNotInterestedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CanMarkNotInterestedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CanMarkNotInterestedResponse.displayName = 'proto.cbv1.CanMarkNotInterestedResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CanMarkNotInterestedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CanMarkNotInterestedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CanMarkNotInterestedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanMarkNotInterestedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    canUpdate: jspb.Message.getFieldWithDefault(msg, 1, false),
    rfq: (f = msg.getRfq()) && proto_cybertron_marketplace_rfq_pb.MarketplaceRfq.toObject(includeInstance, f),
    reason: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CanMarkNotInterestedResponse}
 */
proto.cbv1.CanMarkNotInterestedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CanMarkNotInterestedResponse;
  return proto.cbv1.CanMarkNotInterestedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CanMarkNotInterestedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CanMarkNotInterestedResponse}
 */
proto.cbv1.CanMarkNotInterestedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanUpdate(value);
      break;
    case 2:
      var value = new proto_cybertron_marketplace_rfq_pb.MarketplaceRfq;
      reader.readMessage(value,proto_cybertron_marketplace_rfq_pb.MarketplaceRfq.deserializeBinaryFromReader);
      msg.setRfq(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CanMarkNotInterestedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CanMarkNotInterestedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CanMarkNotInterestedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanMarkNotInterestedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanUpdate();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getRfq();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_cybertron_marketplace_rfq_pb.MarketplaceRfq.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool can_update = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.CanMarkNotInterestedResponse.prototype.getCanUpdate = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.CanMarkNotInterestedResponse.prototype.setCanUpdate = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional MarketplaceRfq rfq = 2;
 * @return {?proto.cbv1.MarketplaceRfq}
 */
proto.cbv1.CanMarkNotInterestedResponse.prototype.getRfq = function() {
  return /** @type{?proto.cbv1.MarketplaceRfq} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_marketplace_rfq_pb.MarketplaceRfq, 2));
};


/** @param {?proto.cbv1.MarketplaceRfq|undefined} value */
proto.cbv1.CanMarkNotInterestedResponse.prototype.setRfq = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cbv1.CanMarkNotInterestedResponse.prototype.clearRfq = function() {
  this.setRfq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CanMarkNotInterestedResponse.prototype.hasRfq = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string reason = 3;
 * @return {string}
 */
proto.cbv1.CanMarkNotInterestedResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.CanMarkNotInterestedResponse.prototype.setReason = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.markNotInterestedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.markNotInterestedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.markNotInterestedRequest.displayName = 'proto.cbv1.markNotInterestedRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.markNotInterestedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.markNotInterestedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.markNotInterestedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.markNotInterestedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rfqId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.markNotInterestedRequest}
 */
proto.cbv1.markNotInterestedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.markNotInterestedRequest;
  return proto.cbv1.markNotInterestedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.markNotInterestedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.markNotInterestedRequest}
 */
proto.cbv1.markNotInterestedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRfqId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.markNotInterestedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.markNotInterestedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.markNotInterestedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.markNotInterestedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRfqId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.cbv1.markNotInterestedRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.markNotInterestedRequest.prototype.setToken = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 rfq_id = 2;
 * @return {number}
 */
proto.cbv1.markNotInterestedRequest.prototype.getRfqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.markNotInterestedRequest.prototype.setRfqId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.markNotInterestedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.markNotInterestedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.markNotInterestedResponse.displayName = 'proto.cbv1.markNotInterestedResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.markNotInterestedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.markNotInterestedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.markNotInterestedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.markNotInterestedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    updated: jspb.Message.getFieldWithDefault(msg, 1, false),
    reason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.markNotInterestedResponse}
 */
proto.cbv1.markNotInterestedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.markNotInterestedResponse;
  return proto.cbv1.markNotInterestedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.markNotInterestedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.markNotInterestedResponse}
 */
proto.cbv1.markNotInterestedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdated(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.markNotInterestedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.markNotInterestedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.markNotInterestedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.markNotInterestedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdated();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool updated = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.markNotInterestedResponse.prototype.getUpdated = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.markNotInterestedResponse.prototype.setUpdated = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.cbv1.markNotInterestedResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.markNotInterestedResponse.prototype.setReason = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CanUpdateBidderInformationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CanUpdateBidderInformationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CanUpdateBidderInformationRequest.displayName = 'proto.cbv1.CanUpdateBidderInformationRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CanUpdateBidderInformationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CanUpdateBidderInformationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CanUpdateBidderInformationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanUpdateBidderInformationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bidderId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CanUpdateBidderInformationRequest}
 */
proto.cbv1.CanUpdateBidderInformationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CanUpdateBidderInformationRequest;
  return proto.cbv1.CanUpdateBidderInformationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CanUpdateBidderInformationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CanUpdateBidderInformationRequest}
 */
proto.cbv1.CanUpdateBidderInformationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBidderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CanUpdateBidderInformationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CanUpdateBidderInformationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CanUpdateBidderInformationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanUpdateBidderInformationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBidderId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.cbv1.CanUpdateBidderInformationRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.CanUpdateBidderInformationRequest.prototype.setToken = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 bidder_id = 2;
 * @return {number}
 */
proto.cbv1.CanUpdateBidderInformationRequest.prototype.getBidderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.CanUpdateBidderInformationRequest.prototype.setBidderId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CanUpdateBidderInformationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CanUpdateBidderInformationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CanUpdateBidderInformationResponse.displayName = 'proto.cbv1.CanUpdateBidderInformationResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CanUpdateBidderInformationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CanUpdateBidderInformationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CanUpdateBidderInformationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanUpdateBidderInformationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    canUpdate: jspb.Message.getFieldWithDefault(msg, 1, false),
    bidder: (f = msg.getBidder()) && proto_cybertron_marketplace_rfq_pb.MarketplaceBidder.toObject(includeInstance, f),
    reason: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CanUpdateBidderInformationResponse}
 */
proto.cbv1.CanUpdateBidderInformationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CanUpdateBidderInformationResponse;
  return proto.cbv1.CanUpdateBidderInformationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CanUpdateBidderInformationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CanUpdateBidderInformationResponse}
 */
proto.cbv1.CanUpdateBidderInformationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanUpdate(value);
      break;
    case 2:
      var value = new proto_cybertron_marketplace_rfq_pb.MarketplaceBidder;
      reader.readMessage(value,proto_cybertron_marketplace_rfq_pb.MarketplaceBidder.deserializeBinaryFromReader);
      msg.setBidder(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CanUpdateBidderInformationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CanUpdateBidderInformationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CanUpdateBidderInformationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanUpdateBidderInformationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanUpdate();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBidder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_cybertron_marketplace_rfq_pb.MarketplaceBidder.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool can_update = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.CanUpdateBidderInformationResponse.prototype.getCanUpdate = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.CanUpdateBidderInformationResponse.prototype.setCanUpdate = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional MarketplaceBidder bidder = 2;
 * @return {?proto.cbv1.MarketplaceBidder}
 */
proto.cbv1.CanUpdateBidderInformationResponse.prototype.getBidder = function() {
  return /** @type{?proto.cbv1.MarketplaceBidder} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_marketplace_rfq_pb.MarketplaceBidder, 2));
};


/** @param {?proto.cbv1.MarketplaceBidder|undefined} value */
proto.cbv1.CanUpdateBidderInformationResponse.prototype.setBidder = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cbv1.CanUpdateBidderInformationResponse.prototype.clearBidder = function() {
  this.setBidder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CanUpdateBidderInformationResponse.prototype.hasBidder = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string reason = 3;
 * @return {string}
 */
proto.cbv1.CanUpdateBidderInformationResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.CanUpdateBidderInformationResponse.prototype.setReason = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.updateBidderInformationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.updateBidderInformationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.updateBidderInformationRequest.displayName = 'proto.cbv1.updateBidderInformationRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.updateBidderInformationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.updateBidderInformationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.updateBidderInformationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.updateBidderInformationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bidderId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    account: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.updateBidderInformationRequest}
 */
proto.cbv1.updateBidderInformationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.updateBidderInformationRequest;
  return proto.cbv1.updateBidderInformationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.updateBidderInformationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.updateBidderInformationRequest}
 */
proto.cbv1.updateBidderInformationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBidderId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.updateBidderInformationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.updateBidderInformationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.updateBidderInformationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.updateBidderInformationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBidderId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.cbv1.updateBidderInformationRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.updateBidderInformationRequest.prototype.setToken = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 bidder_id = 2;
 * @return {number}
 */
proto.cbv1.updateBidderInformationRequest.prototype.getBidderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.updateBidderInformationRequest.prototype.setBidderId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.cbv1.updateBidderInformationRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.updateBidderInformationRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account = 4;
 * @return {string}
 */
proto.cbv1.updateBidderInformationRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.updateBidderInformationRequest.prototype.setAccount = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.updateBidderInformationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.updateBidderInformationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.updateBidderInformationResponse.displayName = 'proto.cbv1.updateBidderInformationResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.updateBidderInformationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.updateBidderInformationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.updateBidderInformationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.updateBidderInformationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    updated: jspb.Message.getFieldWithDefault(msg, 1, false),
    reason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.updateBidderInformationResponse}
 */
proto.cbv1.updateBidderInformationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.updateBidderInformationResponse;
  return proto.cbv1.updateBidderInformationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.updateBidderInformationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.updateBidderInformationResponse}
 */
proto.cbv1.updateBidderInformationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdated(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.updateBidderInformationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.updateBidderInformationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.updateBidderInformationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.updateBidderInformationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdated();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool updated = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.updateBidderInformationResponse.prototype.getUpdated = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.updateBidderInformationResponse.prototype.setUpdated = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.cbv1.updateBidderInformationResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.updateBidderInformationResponse.prototype.setReason = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CanBidRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CanBidRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CanBidRequest.displayName = 'proto.cbv1.CanBidRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CanBidRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CanBidRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CanBidRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanBidRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rfqId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CanBidRequest}
 */
proto.cbv1.CanBidRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CanBidRequest;
  return proto.cbv1.CanBidRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CanBidRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CanBidRequest}
 */
proto.cbv1.CanBidRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRfqId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CanBidRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CanBidRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CanBidRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanBidRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRfqId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.cbv1.CanBidRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.CanBidRequest.prototype.setToken = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 rfq_id = 2;
 * @return {number}
 */
proto.cbv1.CanBidRequest.prototype.getRfqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.CanBidRequest.prototype.setRfqId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CanBidResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CanBidResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CanBidResponse.displayName = 'proto.cbv1.CanBidResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CanBidResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CanBidResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CanBidResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanBidResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    canBid: jspb.Message.getFieldWithDefault(msg, 1, false),
    rfq: (f = msg.getRfq()) && proto_cybertron_marketplace_rfq_pb.MarketplaceRfq.toObject(includeInstance, f),
    previousBid: (f = msg.getPreviousBid()) && proto_cybertron_marketplace_rfq_pb.MarketplaceBid.toObject(includeInstance, f),
    reason: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CanBidResponse}
 */
proto.cbv1.CanBidResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CanBidResponse;
  return proto.cbv1.CanBidResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CanBidResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CanBidResponse}
 */
proto.cbv1.CanBidResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanBid(value);
      break;
    case 2:
      var value = new proto_cybertron_marketplace_rfq_pb.MarketplaceRfq;
      reader.readMessage(value,proto_cybertron_marketplace_rfq_pb.MarketplaceRfq.deserializeBinaryFromReader);
      msg.setRfq(value);
      break;
    case 3:
      var value = new proto_cybertron_marketplace_rfq_pb.MarketplaceBid;
      reader.readMessage(value,proto_cybertron_marketplace_rfq_pb.MarketplaceBid.deserializeBinaryFromReader);
      msg.setPreviousBid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CanBidResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CanBidResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CanBidResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanBidResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanBid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getRfq();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_cybertron_marketplace_rfq_pb.MarketplaceRfq.serializeBinaryToWriter
    );
  }
  f = message.getPreviousBid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_cybertron_marketplace_rfq_pb.MarketplaceBid.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional bool can_bid = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.CanBidResponse.prototype.getCanBid = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.CanBidResponse.prototype.setCanBid = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional MarketplaceRfq rfq = 2;
 * @return {?proto.cbv1.MarketplaceRfq}
 */
proto.cbv1.CanBidResponse.prototype.getRfq = function() {
  return /** @type{?proto.cbv1.MarketplaceRfq} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_marketplace_rfq_pb.MarketplaceRfq, 2));
};


/** @param {?proto.cbv1.MarketplaceRfq|undefined} value */
proto.cbv1.CanBidResponse.prototype.setRfq = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cbv1.CanBidResponse.prototype.clearRfq = function() {
  this.setRfq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CanBidResponse.prototype.hasRfq = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MarketplaceBid previous_bid = 3;
 * @return {?proto.cbv1.MarketplaceBid}
 */
proto.cbv1.CanBidResponse.prototype.getPreviousBid = function() {
  return /** @type{?proto.cbv1.MarketplaceBid} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_marketplace_rfq_pb.MarketplaceBid, 3));
};


/** @param {?proto.cbv1.MarketplaceBid|undefined} value */
proto.cbv1.CanBidResponse.prototype.setPreviousBid = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv1.CanBidResponse.prototype.clearPreviousBid = function() {
  this.setPreviousBid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CanBidResponse.prototype.hasPreviousBid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string reason = 4;
 * @return {string}
 */
proto.cbv1.CanBidResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.CanBidResponse.prototype.setReason = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateRFQBidRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateRFQBidRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateRFQBidRequest.displayName = 'proto.cbv1.CreateRFQBidRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateRFQBidRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateRFQBidRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateRFQBidRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateRFQBidRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    rfqId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    siteGeofence: (f = msg.getSiteGeofence()) && proto_geo_geo_pb.Point.toObject(includeInstance, f),
    token: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateRFQBidRequest}
 */
proto.cbv1.CreateRFQBidRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateRFQBidRequest;
  return proto.cbv1.CreateRFQBidRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateRFQBidRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateRFQBidRequest}
 */
proto.cbv1.CreateRFQBidRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRfqId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 3:
      var value = new proto_geo_geo_pb.Point;
      reader.readMessage(value,proto_geo_geo_pb.Point.deserializeBinaryFromReader);
      msg.setSiteGeofence(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateRFQBidRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateRFQBidRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateRFQBidRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateRFQBidRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRfqId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSiteGeofence();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_geo_geo_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 rfq_id = 1;
 * @return {number}
 */
proto.cbv1.CreateRFQBidRequest.prototype.getRfqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.CreateRFQBidRequest.prototype.setRfqId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 amount = 2;
 * @return {number}
 */
proto.cbv1.CreateRFQBidRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.CreateRFQBidRequest.prototype.setAmount = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Point site_geofence = 3;
 * @return {?proto.cbv1.Point}
 */
proto.cbv1.CreateRFQBidRequest.prototype.getSiteGeofence = function() {
  return /** @type{?proto.cbv1.Point} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Point, 3));
};


/** @param {?proto.cbv1.Point|undefined} value */
proto.cbv1.CreateRFQBidRequest.prototype.setSiteGeofence = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv1.CreateRFQBidRequest.prototype.clearSiteGeofence = function() {
  this.setSiteGeofence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CreateRFQBidRequest.prototype.hasSiteGeofence = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string token = 4;
 * @return {string}
 */
proto.cbv1.CreateRFQBidRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.CreateRFQBidRequest.prototype.setToken = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateRFQBidResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateRFQBidResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateRFQBidResponse.displayName = 'proto.cbv1.CreateRFQBidResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateRFQBidResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateRFQBidResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateRFQBidResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateRFQBidResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    created: jspb.Message.getFieldWithDefault(msg, 1, false),
    reason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateRFQBidResponse}
 */
proto.cbv1.CreateRFQBidResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateRFQBidResponse;
  return proto.cbv1.CreateRFQBidResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateRFQBidResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateRFQBidResponse}
 */
proto.cbv1.CreateRFQBidResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreated(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateRFQBidResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateRFQBidResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateRFQBidResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateRFQBidResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreated();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool created = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.CreateRFQBidResponse.prototype.getCreated = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.CreateRFQBidResponse.prototype.setCreated = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.cbv1.CreateRFQBidResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.CreateRFQBidResponse.prototype.setReason = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SearchBidderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.SearchBidderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SearchBidderRequest.displayName = 'proto.cbv1.SearchBidderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SearchBidderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SearchBidderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SearchBidderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SearchBidderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SearchBidderRequest}
 */
proto.cbv1.SearchBidderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SearchBidderRequest;
  return proto.cbv1.SearchBidderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SearchBidderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SearchBidderRequest}
 */
proto.cbv1.SearchBidderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SearchBidderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SearchBidderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SearchBidderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SearchBidderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.cbv1.SearchBidderRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.SearchBidderRequest.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SearchBidderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.SearchBidderResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.SearchBidderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SearchBidderResponse.displayName = 'proto.cbv1.SearchBidderResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.SearchBidderResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SearchBidderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SearchBidderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SearchBidderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SearchBidderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    biddersList: jspb.Message.toObjectList(msg.getBiddersList(),
    proto_cybertron_marketplace_rfq_pb.MarketplaceBidder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SearchBidderResponse}
 */
proto.cbv1.SearchBidderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SearchBidderResponse;
  return proto.cbv1.SearchBidderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SearchBidderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SearchBidderResponse}
 */
proto.cbv1.SearchBidderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_marketplace_rfq_pb.MarketplaceBidder;
      reader.readMessage(value,proto_cybertron_marketplace_rfq_pb.MarketplaceBidder.deserializeBinaryFromReader);
      msg.addBidders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SearchBidderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SearchBidderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SearchBidderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SearchBidderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBiddersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_marketplace_rfq_pb.MarketplaceBidder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MarketplaceBidder bidders = 1;
 * @return {!Array<!proto.cbv1.MarketplaceBidder>}
 */
proto.cbv1.SearchBidderResponse.prototype.getBiddersList = function() {
  return /** @type{!Array<!proto.cbv1.MarketplaceBidder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_marketplace_rfq_pb.MarketplaceBidder, 1));
};


/** @param {!Array<!proto.cbv1.MarketplaceBidder>} value */
proto.cbv1.SearchBidderResponse.prototype.setBiddersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.MarketplaceBidder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.MarketplaceBidder}
 */
proto.cbv1.SearchBidderResponse.prototype.addBidders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.MarketplaceBidder, opt_index);
};


proto.cbv1.SearchBidderResponse.prototype.clearBiddersList = function() {
  this.setBiddersList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateRFQRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.CreateRFQRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.CreateRFQRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateRFQRequest.displayName = 'proto.cbv1.CreateRFQRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.CreateRFQRequest.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateRFQRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateRFQRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateRFQRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateRFQRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    payload: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    unit: jspb.Message.getFieldWithDefault(msg, 3, ""),
    biddingStartTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    biddingEndTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    location: (f = msg.getLocation()) && proto_geo_geo_pb.Point.toObject(includeInstance, f),
    logisticCost: jspb.Message.getFieldWithDefault(msg, 7, 0),
    jobStartTime: jspb.Message.getFieldWithDefault(msg, 8, 0),
    jobEndTime: jspb.Message.getFieldWithDefault(msg, 9, 0),
    maxPrice: jspb.Message.getFieldWithDefault(msg, 10, 0),
    biddersList: jspb.Message.toObjectList(msg.getBiddersList(),
    proto.cbv1.Bidder.toObject, includeInstance),
    fileIdsList: jspb.Message.getRepeatedField(msg, 12),
    messageForBidders: jspb.Message.getFieldWithDefault(msg, 13, ""),
    rfqType: jspb.Message.getFieldWithDefault(msg, 14, 0),
    freightTypeName: jspb.Message.getFieldWithDefault(msg, 15, ""),
    pickupLocation: (f = msg.getPickupLocation()) && proto_geo_geo_pb.Point.toObject(includeInstance, f),
    pickupTime: jspb.Message.getFieldWithDefault(msg, 17, 0),
    description: jspb.Message.getFieldWithDefault(msg, 18, ""),
    dropOffTime: jspb.Message.getFieldWithDefault(msg, 19, 0),
    minBidReduction: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateRFQRequest}
 */
proto.cbv1.CreateRFQRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateRFQRequest;
  return proto.cbv1.CreateRFQRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateRFQRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateRFQRequest}
 */
proto.cbv1.CreateRFQRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBiddingStartTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBiddingEndTime(value);
      break;
    case 6:
      var value = new proto_geo_geo_pb.Point;
      reader.readMessage(value,proto_geo_geo_pb.Point.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLogisticCost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobStartTime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobEndTime(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxPrice(value);
      break;
    case 11:
      var value = new proto.cbv1.Bidder;
      reader.readMessage(value,proto.cbv1.Bidder.deserializeBinaryFromReader);
      msg.addBidders(value);
      break;
    case 12:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setFileIdsList(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageForBidders(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRfqType(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setFreightTypeName(value);
      break;
    case 16:
      var value = new proto_geo_geo_pb.Point;
      reader.readMessage(value,proto_geo_geo_pb.Point.deserializeBinaryFromReader);
      msg.setPickupLocation(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPickupTime(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDropOffTime(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinBidReduction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateRFQRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateRFQRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateRFQRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateRFQRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBiddingStartTime();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBiddingEndTime();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_geo_geo_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getLogisticCost();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getJobStartTime();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getJobEndTime();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getMaxPrice();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getBiddersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.cbv1.Bidder.serializeBinaryToWriter
    );
  }
  f = message.getFileIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      12,
      f
    );
  }
  f = message.getMessageForBidders();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getRfqType();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getFreightTypeName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPickupLocation();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto_geo_geo_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getPickupTime();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getDropOffTime();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getMinBidReduction();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
};


/**
 * optional string payload = 1;
 * @return {string}
 */
proto.cbv1.CreateRFQRequest.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.CreateRFQRequest.prototype.setPayload = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 quantity = 2;
 * @return {number}
 */
proto.cbv1.CreateRFQRequest.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.CreateRFQRequest.prototype.setQuantity = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string unit = 3;
 * @return {string}
 */
proto.cbv1.CreateRFQRequest.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.CreateRFQRequest.prototype.setUnit = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 bidding_start_time = 4;
 * @return {number}
 */
proto.cbv1.CreateRFQRequest.prototype.getBiddingStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.CreateRFQRequest.prototype.setBiddingStartTime = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 bidding_end_time = 5;
 * @return {number}
 */
proto.cbv1.CreateRFQRequest.prototype.getBiddingEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.CreateRFQRequest.prototype.setBiddingEndTime = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional Point location = 6;
 * @return {?proto.cbv1.Point}
 */
proto.cbv1.CreateRFQRequest.prototype.getLocation = function() {
  return /** @type{?proto.cbv1.Point} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Point, 6));
};


/** @param {?proto.cbv1.Point|undefined} value */
proto.cbv1.CreateRFQRequest.prototype.setLocation = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


proto.cbv1.CreateRFQRequest.prototype.clearLocation = function() {
  this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CreateRFQRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 logistic_cost = 7;
 * @return {number}
 */
proto.cbv1.CreateRFQRequest.prototype.getLogisticCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.CreateRFQRequest.prototype.setLogisticCost = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 job_start_time = 8;
 * @return {number}
 */
proto.cbv1.CreateRFQRequest.prototype.getJobStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cbv1.CreateRFQRequest.prototype.setJobStartTime = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 job_end_time = 9;
 * @return {number}
 */
proto.cbv1.CreateRFQRequest.prototype.getJobEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cbv1.CreateRFQRequest.prototype.setJobEndTime = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 max_price = 10;
 * @return {number}
 */
proto.cbv1.CreateRFQRequest.prototype.getMaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cbv1.CreateRFQRequest.prototype.setMaxPrice = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated Bidder bidders = 11;
 * @return {!Array<!proto.cbv1.Bidder>}
 */
proto.cbv1.CreateRFQRequest.prototype.getBiddersList = function() {
  return /** @type{!Array<!proto.cbv1.Bidder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.Bidder, 11));
};


/** @param {!Array<!proto.cbv1.Bidder>} value */
proto.cbv1.CreateRFQRequest.prototype.setBiddersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.cbv1.Bidder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.Bidder}
 */
proto.cbv1.CreateRFQRequest.prototype.addBidders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.cbv1.Bidder, opt_index);
};


proto.cbv1.CreateRFQRequest.prototype.clearBiddersList = function() {
  this.setBiddersList([]);
};


/**
 * repeated int32 file_ids = 12;
 * @return {!Array<number>}
 */
proto.cbv1.CreateRFQRequest.prototype.getFileIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/** @param {!Array<number>} value */
proto.cbv1.CreateRFQRequest.prototype.setFileIdsList = function(value) {
  jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.CreateRFQRequest.prototype.addFileIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


proto.cbv1.CreateRFQRequest.prototype.clearFileIdsList = function() {
  this.setFileIdsList([]);
};


/**
 * optional string message_for_bidders = 13;
 * @return {string}
 */
proto.cbv1.CreateRFQRequest.prototype.getMessageForBidders = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.cbv1.CreateRFQRequest.prototype.setMessageForBidders = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 rfq_type = 14;
 * @return {number}
 */
proto.cbv1.CreateRFQRequest.prototype.getRfqType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.cbv1.CreateRFQRequest.prototype.setRfqType = function(value) {
  jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string freight_type_name = 15;
 * @return {string}
 */
proto.cbv1.CreateRFQRequest.prototype.getFreightTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.cbv1.CreateRFQRequest.prototype.setFreightTypeName = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional Point pickup_location = 16;
 * @return {?proto.cbv1.Point}
 */
proto.cbv1.CreateRFQRequest.prototype.getPickupLocation = function() {
  return /** @type{?proto.cbv1.Point} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Point, 16));
};


/** @param {?proto.cbv1.Point|undefined} value */
proto.cbv1.CreateRFQRequest.prototype.setPickupLocation = function(value) {
  jspb.Message.setWrapperField(this, 16, value);
};


proto.cbv1.CreateRFQRequest.prototype.clearPickupLocation = function() {
  this.setPickupLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CreateRFQRequest.prototype.hasPickupLocation = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional int32 pickup_time = 17;
 * @return {number}
 */
proto.cbv1.CreateRFQRequest.prototype.getPickupTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {number} value */
proto.cbv1.CreateRFQRequest.prototype.setPickupTime = function(value) {
  jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string description = 18;
 * @return {string}
 */
proto.cbv1.CreateRFQRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/** @param {string} value */
proto.cbv1.CreateRFQRequest.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional int32 drop_off_time = 19;
 * @return {number}
 */
proto.cbv1.CreateRFQRequest.prototype.getDropOffTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/** @param {number} value */
proto.cbv1.CreateRFQRequest.prototype.setDropOffTime = function(value) {
  jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 min_bid_reduction = 20;
 * @return {number}
 */
proto.cbv1.CreateRFQRequest.prototype.getMinBidReduction = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/** @param {number} value */
proto.cbv1.CreateRFQRequest.prototype.setMinBidReduction = function(value) {
  jspb.Message.setProto3IntField(this, 20, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.Bidder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.Bidder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.Bidder.displayName = 'proto.cbv1.Bidder';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.Bidder.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.Bidder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.Bidder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Bidder.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    account: jspb.Message.getFieldWithDefault(msg, 3, ""),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.Bidder}
 */
proto.cbv1.Bidder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.Bidder;
  return proto.cbv1.Bidder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.Bidder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.Bidder}
 */
proto.cbv1.Bidder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.Bidder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.Bidder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.Bidder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Bidder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.cbv1.Bidder.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.Bidder.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.Bidder.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.Bidder.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account = 3;
 * @return {string}
 */
proto.cbv1.Bidder.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.Bidder.prototype.setAccount = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.cbv1.Bidder.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.Bidder.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateRFQResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateRFQResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateRFQResponse.displayName = 'proto.cbv1.CreateRFQResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateRFQResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateRFQResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateRFQResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateRFQResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rfq: (f = msg.getRfq()) && proto_cybertron_marketplace_rfq_pb.MarketplaceRfq.toObject(includeInstance, f),
    reason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateRFQResponse}
 */
proto.cbv1.CreateRFQResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateRFQResponse;
  return proto.cbv1.CreateRFQResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateRFQResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateRFQResponse}
 */
proto.cbv1.CreateRFQResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_marketplace_rfq_pb.MarketplaceRfq;
      reader.readMessage(value,proto_cybertron_marketplace_rfq_pb.MarketplaceRfq.deserializeBinaryFromReader);
      msg.setRfq(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateRFQResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateRFQResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateRFQResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateRFQResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRfq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_marketplace_rfq_pb.MarketplaceRfq.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional MarketplaceRfq rfq = 1;
 * @return {?proto.cbv1.MarketplaceRfq}
 */
proto.cbv1.CreateRFQResponse.prototype.getRfq = function() {
  return /** @type{?proto.cbv1.MarketplaceRfq} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_marketplace_rfq_pb.MarketplaceRfq, 1));
};


/** @param {?proto.cbv1.MarketplaceRfq|undefined} value */
proto.cbv1.CreateRFQResponse.prototype.setRfq = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.CreateRFQResponse.prototype.clearRfq = function() {
  this.setRfq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CreateRFQResponse.prototype.hasRfq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.cbv1.CreateRFQResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.CreateRFQResponse.prototype.setReason = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.RfqFileUrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.RfqFileUrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.RfqFileUrlRequest.displayName = 'proto.cbv1.RfqFileUrlRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.RfqFileUrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.RfqFileUrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.RfqFileUrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.RfqFileUrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.RfqFileUrlRequest}
 */
proto.cbv1.RfqFileUrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.RfqFileUrlRequest;
  return proto.cbv1.RfqFileUrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.RfqFileUrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.RfqFileUrlRequest}
 */
proto.cbv1.RfqFileUrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFileId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.RfqFileUrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.RfqFileUrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.RfqFileUrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.RfqFileUrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 file_id = 1;
 * @return {number}
 */
proto.cbv1.RfqFileUrlRequest.prototype.getFileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.RfqFileUrlRequest.prototype.setFileId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.RfqFileUrlResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.RfqFileUrlResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.RfqFileUrlResponse.displayName = 'proto.cbv1.RfqFileUrlResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.RfqFileUrlResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.RfqFileUrlResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.RfqFileUrlResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.RfqFileUrlResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.RfqFileUrlResponse}
 */
proto.cbv1.RfqFileUrlResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.RfqFileUrlResponse;
  return proto.cbv1.RfqFileUrlResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.RfqFileUrlResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.RfqFileUrlResponse}
 */
proto.cbv1.RfqFileUrlResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.RfqFileUrlResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.RfqFileUrlResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.RfqFileUrlResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.RfqFileUrlResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.cbv1.RfqFileUrlResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.RfqFileUrlResponse.prototype.setUrl = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.StreamerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.StreamerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.StreamerResponse.displayName = 'proto.cbv1.StreamerResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.StreamerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.StreamerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.StreamerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StreamerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rfq: (f = msg.getRfq()) && proto_cybertron_marketplace_rfq_pb.MarketplaceRfq.toObject(includeInstance, f),
    reason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.StreamerResponse}
 */
proto.cbv1.StreamerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.StreamerResponse;
  return proto.cbv1.StreamerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.StreamerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.StreamerResponse}
 */
proto.cbv1.StreamerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_marketplace_rfq_pb.MarketplaceRfq;
      reader.readMessage(value,proto_cybertron_marketplace_rfq_pb.MarketplaceRfq.deserializeBinaryFromReader);
      msg.setRfq(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.StreamerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.StreamerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.StreamerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StreamerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRfq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_marketplace_rfq_pb.MarketplaceRfq.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional MarketplaceRfq rfq = 1;
 * @return {?proto.cbv1.MarketplaceRfq}
 */
proto.cbv1.StreamerResponse.prototype.getRfq = function() {
  return /** @type{?proto.cbv1.MarketplaceRfq} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_marketplace_rfq_pb.MarketplaceRfq, 1));
};


/** @param {?proto.cbv1.MarketplaceRfq|undefined} value */
proto.cbv1.StreamerResponse.prototype.setRfq = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.StreamerResponse.prototype.clearRfq = function() {
  this.setRfq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.StreamerResponse.prototype.hasRfq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.cbv1.StreamerResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.StreamerResponse.prototype.setReason = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.StreamerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.StreamerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.StreamerRequest.displayName = 'proto.cbv1.StreamerRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.StreamerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.StreamerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.StreamerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StreamerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.StreamerRequest}
 */
proto.cbv1.StreamerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.StreamerRequest;
  return proto.cbv1.StreamerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.StreamerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.StreamerRequest}
 */
proto.cbv1.StreamerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.StreamerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.StreamerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.StreamerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StreamerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.rfqSuggestionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.rfqSuggestionsResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.rfqSuggestionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.rfqSuggestionsResponse.displayName = 'proto.cbv1.rfqSuggestionsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.rfqSuggestionsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.rfqSuggestionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.rfqSuggestionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.rfqSuggestionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.rfqSuggestionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    productsList: jspb.Message.getRepeatedField(msg, 1),
    biddersList: jspb.Message.toObjectList(msg.getBiddersList(),
    proto_cybertron_marketplace_rfq_pb.MarketplaceBidder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.rfqSuggestionsResponse}
 */
proto.cbv1.rfqSuggestionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.rfqSuggestionsResponse;
  return proto.cbv1.rfqSuggestionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.rfqSuggestionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.rfqSuggestionsResponse}
 */
proto.cbv1.rfqSuggestionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addProducts(value);
      break;
    case 2:
      var value = new proto_cybertron_marketplace_rfq_pb.MarketplaceBidder;
      reader.readMessage(value,proto_cybertron_marketplace_rfq_pb.MarketplaceBidder.deserializeBinaryFromReader);
      msg.addBidders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.rfqSuggestionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.rfqSuggestionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.rfqSuggestionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.rfqSuggestionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getBiddersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_cybertron_marketplace_rfq_pb.MarketplaceBidder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string products = 1;
 * @return {!Array<string>}
 */
proto.cbv1.rfqSuggestionsResponse.prototype.getProductsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.cbv1.rfqSuggestionsResponse.prototype.setProductsList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.rfqSuggestionsResponse.prototype.addProducts = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv1.rfqSuggestionsResponse.prototype.clearProductsList = function() {
  this.setProductsList([]);
};


/**
 * repeated MarketplaceBidder bidders = 2;
 * @return {!Array<!proto.cbv1.MarketplaceBidder>}
 */
proto.cbv1.rfqSuggestionsResponse.prototype.getBiddersList = function() {
  return /** @type{!Array<!proto.cbv1.MarketplaceBidder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_marketplace_rfq_pb.MarketplaceBidder, 2));
};


/** @param {!Array<!proto.cbv1.MarketplaceBidder>} value */
proto.cbv1.rfqSuggestionsResponse.prototype.setBiddersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cbv1.MarketplaceBidder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.MarketplaceBidder}
 */
proto.cbv1.rfqSuggestionsResponse.prototype.addBidders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cbv1.MarketplaceBidder, opt_index);
};


proto.cbv1.rfqSuggestionsResponse.prototype.clearBiddersList = function() {
  this.setBiddersList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.rfqSuggestionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.rfqSuggestionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.rfqSuggestionsRequest.displayName = 'proto.cbv1.rfqSuggestionsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.rfqSuggestionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.rfqSuggestionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.rfqSuggestionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.rfqSuggestionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.rfqSuggestionsRequest}
 */
proto.cbv1.rfqSuggestionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.rfqSuggestionsRequest;
  return proto.cbv1.rfqSuggestionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.rfqSuggestionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.rfqSuggestionsRequest}
 */
proto.cbv1.rfqSuggestionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.rfqSuggestionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.rfqSuggestionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.rfqSuggestionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.rfqSuggestionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.cbv1);
