import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { CreateTrailerRequest } from '~proto/trailer/trailer_api_pb';
import { fuse } from '~utilities/fuse';
var searchOptions = {
    distance: 100,
    keys: ['name'],
    location: 0,
    maxPatternLength: 16,
    minMatchCharLength: 1,
    shouldSort: true,
    threshold: 0.1,
};
var CreateOrderTrailerSelectorComponent = /** @class */ (function () {
    function CreateOrderTrailerSelectorComponent(trailerService) {
        this.trailerService = trailerService;
        this.selected = new EventEmitter();
        this.invalid = false;
        this.textChanges$$ = new BehaviorSubject(null);
    }
    CreateOrderTrailerSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.trailers$ = combineLatest([
            this.trailerService.trailers$.pipe(map(fuse(searchOptions))),
            this.textChanges$$.pipe(debounceTime(100), startWith('')),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), trailers = _b[0], text = _b[1];
            if (!trailers) {
                return [];
            }
            if (!text || text === '') {
                _this.topResult = trailers.data.length ? trailers.data[0] : null;
                return trailers.data;
            }
            var searchResults = trailers.fuse.search(text);
            _this.topResult = searchResults.length ? searchResults[0] : null;
            return searchResults;
        }));
        this.showCreateTrailerButton$ = combineLatest([
            this.trailerService.trailers$,
            this.textChanges$$.pipe(startWith(null)),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), trailers = _b[0], text = _b[1];
            if (!text || !text.length) {
                return false;
            }
            return trailers.every(function (trailer) { return trailer.name !== text; });
        }));
    };
    CreateOrderTrailerSelectorComponent.prototype.inputTextChange = function (event) {
        this.textChanges$$.next(event.trim());
    };
    CreateOrderTrailerSelectorComponent.prototype.selectTrailer = function (trailer) {
        this.selected.emit({ trailer: trailer });
        this.smartDropdown.close();
    };
    CreateOrderTrailerSelectorComponent.prototype.addTrailer = function () {
        var _this = this;
        var name = this.textChanges$$.value;
        if (!name || name === '') {
            return;
        }
        var request = new CreateTrailerRequest();
        request.setName(name);
        this.trailerService.addTrailer$(request).subscribe(function (trailer) {
            _this.selectTrailer(trailer);
        });
    };
    CreateOrderTrailerSelectorComponent.prototype.enterKeyPressed = function () {
        if (this.topResult) {
            this.selectTrailer(this.topResult);
        }
        else {
            this.addTrailer();
        }
    };
    return CreateOrderTrailerSelectorComponent;
}());
export { CreateOrderTrailerSelectorComponent };
