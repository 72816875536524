import { AfterViewInit, ElementRef, OnInit } from '@angular/core';
var MapComponent = /** @class */ (function () {
    function MapComponent() {
        this.latitude = 39.7392;
        this.longitude = -104.9903;
    }
    Object.defineProperty(MapComponent.prototype, "mapLatitude", {
        set: function (value) {
            this.latitude = value;
            this.initMap();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MapComponent.prototype, "mapLongitude", {
        set: function (value) {
            this.longitude = value;
            this.initMap();
        },
        enumerable: true,
        configurable: true
    });
    MapComponent.prototype.ngOnInit = function () { };
    MapComponent.prototype.ngAfterViewInit = function () {
        this.initMap();
    };
    MapComponent.prototype.initMap = function () {
        if (this.map == null) {
            var center = {
                lat: this.latitude,
                lng: this.longitude,
            };
            var mapConfig = {
                center: center,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    position: 3,
                },
                mapTypeId: google.maps.MapTypeId.HYBRID,
                streetViewControl: false,
                zoom: 12,
                zoomControlOptions: {
                    position: 3,
                },
            };
            if (this.mapElement != null) {
                this.map = new google.maps.Map(this.mapElement.nativeElement, mapConfig);
                this.mineMarker = new google.maps.Marker({
                    animation: google.maps.Animation.DROP,
                    draggable: false,
                    map: this.map,
                    position: this.map.getCenter(),
                });
            }
        }
        else {
            this.map.setCenter({
                lat: +this.latitude,
                lng: +this.longitude,
            });
            this.mineMarker.setPosition(this.map.getCenter());
        }
    };
    return MapComponent;
}());
export { MapComponent };
