import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { alarmsPluralMapping } from '~plural/alarms';
import { loadPluralMapping } from '~plural/loads';
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-lmo-jobs-statistics',
  styleUrls: ['./lmo-jobs-statistics.component.scss'],
  templateUrl: './lmo-jobs-statistics.component.html',
})
export class LmoJobsStatisticsComponent implements OnInit {
  @Input() public pendingCount: number;
  @Input() public inboundCount: number;
  @Input() public outboundCount: number;
  @Input() public alarmCount = 0;
  public loadsPlural = loadPluralMapping;
  public alarmsPlural = alarmsPluralMapping;

  constructor() {}

  public ngOnInit() {}
}
