import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Stock } from '~proto/site/site_pb';
import { trackById } from '~utilities/trackById';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-job-detail-stock-card',
  styleUrls: ['./job-detail-stock-card.component.scss'],
  templateUrl: './job-detail-stock-card.component.html',
})
export class JobDetailStockCardComponent implements OnInit {
  public trackById = trackById;
  @Input() public stocks: Stock.AsObject[];
  @Input() public updatedAt: number;

  constructor() {}

  public ngOnInit() {}
}
