import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { SmartDropdownComponent } from '~common/smart-dropdown/smart-dropdown.component';
import { LMOSiteSummary, Site, SiteShort } from '~proto/site/site_pb';
import { crowFliesDistance } from '~utilities/distance';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { trackById } from '~utilities/trackById';
import { CreateJobComponent } from '../create-job/create-job.component';

interface SiteGroup {
  name: string;
  sitesList: LMOSiteSummary.AsObject[] | SiteShort.AsObject[];
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-create-order-site-selector',
  styleUrls: ['./create-order-site-selector.component.scss'],
  templateUrl: './create-order-site-selector.component.html',
})
export class CreateOrderSiteSelectorComponent implements OnInit {
  private withoutSiteIds$$ = new BehaviorSubject<number[]>([]);
  public trackById = trackById;
  private topResult: LMOSiteSummary.AsObject | SiteShort.AsObject;
  @Output() public selected = new EventEmitter<{
    pickupFrom: LMOSiteSummary.AsObject | SiteShort.AsObject | Site.AsObject;
  }>();
  @ViewChild('smartDropdown', { static: true }) private smartDropdown: SmartDropdownComponent;
  @Input() public currentSite: Site.AsObject;
  @Input() public site: Site.AsObject;
  @Input() public invalid = false;
  @Input() public tabIndex = '';
  @Input() public label = 'pickup from';
  @Input() public set withoutSiteIds(siteIds: number[]) {
    this.withoutSiteIds$$.next(siteIds || []);
  }
  @Input() public disabled = false;
  public searching$: Observable<boolean>;
  public textChanges$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public sites$: Observable<(LMOSiteSummary.AsObject | SiteShort.AsObject)[]>;
  public sitesWithHeaders$: Observable<SiteGroup[]>;
  public canAddNew$: Observable<boolean>;

  constructor(private sites: JobSitesService, private matDialog: MatDialog) {}

  public ngOnInit() {
    this.searching$ = this.sites.searching$;
    const searchResults$ = combineLatest([
      this.sites.sites$,
      this.textChanges$$.pipe(debounceTime(200)).pipe(
        switchMap((searchText) => {
          if (searchText && searchText.length >= 2) {
            return this.sites.searchForSite$(searchText);
          }
          return of([] as SiteShort.AsObject[]);
        }),
      ),
    ]).pipe(
      map(([sites, searchResults]) => {
        if (!sites) {
          return searchResults;
        }
        const siteMap = idArrayToRecord(sites);
        return searchResults.filter((site) => !site.archived && !siteMap[site.id]);
      }),
    );
    this.sitesWithHeaders$ = combineLatest([
      this.sites.sites$,
      this.withoutSiteIds$$,
      this.textChanges$$.pipe(
        debounceTime(100),
        startWith(''),
      ),
      searchResults$,
    ]).pipe(
      map(([sites, bannedSiteIds, text, searchResults]) => {
        if (!sites) {
          return [];
        }
        const sitesAfterFilteringSkippedSites = sites.filter((site) => !bannedSiteIds.includes(site.id));
        const pinned: LMOSiteSummary.AsObject[] = [];
        const recent: LMOSiteSummary.AsObject[] = [];
        sitesAfterFilteringSkippedSites.forEach((site) => {
          if (site.favorited) {
            pinned.push(site);
          } else {
            recent.push(site);
          }
        });
        const sitesWithHeaders = [
          {
            name: 'Pinned',
            sitesList: pinned,
          },
          {
            name: 'Recent',
            sitesList: recent,
          },
        ] as SiteGroup[];
        if (!text || text === '') {
          this.topResult = sitesAfterFilteringSkippedSites.length ? sitesAfterFilteringSkippedSites[0] : null;

          return sitesWithHeaders;
        }
        const asLowercase = text.toLocaleLowerCase();
        const cleanedItems = sitesWithHeaders.map((item) => {
          const filteredSites = (item.sitesList as (LMOSiteSummary.AsObject | SiteShort.AsObject)[]).filter((site) =>
            site.name.toLocaleLowerCase().includes(asLowercase),
          );
          return {
            ...item,
            sitesList: filteredSites,
          };
        });
        const mergedGroups = [
          ...cleanedItems,
          {
            name: 'Search Results',
            sitesList: searchResults,
          },
        ] as SiteGroup[];
        const filtered: SiteGroup[] = mergedGroups.filter((item) => item.sitesList.length > 0);
        if (filtered.length && filtered[0].sitesList.length) {
          this.topResult = filtered[0].sitesList[0];
        } else {
          this.topResult = null;
        }
        return filtered;
      }),
    );
    this.canAddNew$ = combineLatest([this.sitesWithHeaders$, this.textChanges$$.pipe(startWith(''))]).pipe(
      map(([currentSitesPayload, textChange]) => {
        if (!textChange || textChange === '') {
          return false;
        }
        const asLower = textChange.toLocaleLowerCase();
        return currentSitesPayload.every((group) =>
          group.sitesList.every((payload) => !payload.name.toLocaleLowerCase().includes(asLower)),
        );
      }),
    );
  }

  public togglePin(event: Event, siteID: number, isFavorited: boolean) {
    event.stopPropagation();
    this.sites.setFavorite(isFavorited, siteID);
  }

  public inputTextChange(event: string) {
    this.textChanges$$.next(event.trim());
  }

  // calculate as the crow flies distance
  public distance(lat1: number, lon1: number, lat2: number, lon2: number) {
    return crowFliesDistance(lat1, lon1, lat2, lon2);
  }

  public selectSite(pickupFrom: LMOSiteSummary.AsObject | SiteShort.AsObject | Site.AsObject) {
    this.selected.emit({ pickupFrom });
    this.smartDropdown.close();
  }

  public enterKeyPressed() {
    if (this.topResult) {
      this.selectSite(this.topResult);
    } else {
      // this.createNewSite();
    }
  }
  public addOrderSite() {
    const dialog = this.matDialog.open(CreateJobComponent, {
      minHeight: '100%',
      minWidth: '100%',
    });
    dialog.componentInstance.modalInstance = true;
    dialog.componentInstance.jobName = this.textChanges$$.value;
    dialog.afterClosed().subscribe((result: Site.AsObject) => {
      if (result) {
        this.selectSite(result);
      }
    });
  }
}
