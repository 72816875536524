import { formatNumber } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import {
  CanBidRequest,
  CanBidResponse,
  CanMarkNotInterestedRequest,
  CanMarkNotInterestedResponse,
  CreateRFQBidRequest,
  CreateRFQBidResponse,
  markNotInterestedRequest,
  markNotInterestedResponse,
} from '~proto/marketplace/rfq_api_pb';
import { MarketplaceRfq } from '~proto/marketplace/rfq_pb';
// import { ConstantsService } from '~services/constants.service';
import { MarketplaceService } from '~services/marketplace.service';
import { Point } from '../../../../proto/geo/geo_pb';
import { RfqLocationFormComponent } from '../rfq-location-form/rfq-location-form.component';

enum pageType {
  CALL_BID_TYPE = 'call',
  MARK_NOT_interested_TYPE = 'marknotinterested',
}

@Component({
  selector: 'ct-marketplace-email-bid',
  styleUrls: ['./marketplace-email-bid.component.scss'],
  templateUrl: './marketplace-email-bid.component.html',
})
export class MarketplaceEmailBidComponent implements OnInit {
  public bid: number;
  public pickupLocation: string;
  public rfqId: number;
  public token: string;
  public type: string;
  public isReBid = false;
  public placeholderString$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public networkActive$$ = new BehaviorSubject(false);
  public rfq$: BehaviorSubject<MarketplaceRfq.AsObject> = new BehaviorSubject<MarketplaceRfq.AsObject>(null);
  public distanceInMiles$ = new BehaviorSubject<number>(null);
  public counter: string;

  constructor(
    private router: ActivatedRoute,
    private marketplaceService: MarketplaceService,
    private matdialog: MatDialog,
    private snackbar: MatSnackBar,
  ) {}

  public ngOnInit() {
    this.rfqId = +this.router.snapshot.params['rfqId'];
    this.token = this.router.snapshot.params['token'];
    this.type = this.router.snapshot.params['type'].toLowerCase();
    this.checkType();
  }

  private checkType() {
    if (this.type === pageType.CALL_BID_TYPE) {
      this.checkIfCanBid();
    } else if (this.type === pageType.MARK_NOT_interested_TYPE) {
      this.checkIfCanMarkNotinterested();
    } else {
      this.setPlaceholderString('PAGE NOT FOUND');
    }
  }

  public checkAllFields() {
    if (this.isReBid) {
      return !this.bid;
    } else {
      return !this.bid && !this.pickupLocation;
    }
  }

  private checkIfCanBid() {
    const canBidRequest = new CanBidRequest();
    canBidRequest.setRfqId(this.rfqId);
    canBidRequest.setToken(this.token);
    this.marketplaceService.canBid(canBidRequest).subscribe(
      (bidResponse: CanBidResponse) => {
        if (bidResponse.getCanBid()) {
          this.rfq$.next(bidResponse.getRfq().toObject());
          this.countDown(this.rfq$);
          if (bidResponse.getPreviousBid()) {
            this.isReBid = true;
            this.bid = +(bidResponse.getPreviousBid().getAmount() / 100).toFixed(2);
          }
        } else {
          this.setPlaceholderString(bidResponse.getReason());
        }
      },
      (err) => {
        this.setPlaceholderString('RFQ DETAILS NOT FOUND');
      },
    );
  }

  private checkIfCanMarkNotinterested() {
    const canMarkNotInterestedRequest = new CanMarkNotInterestedRequest();
    canMarkNotInterestedRequest.setRfqId(this.rfqId);
    canMarkNotInterestedRequest.setToken(this.token);
    this.marketplaceService.canMarkNotinterested(canMarkNotInterestedRequest).subscribe(
      (markNotInterestedResp: CanMarkNotInterestedResponse) => {
        if (markNotInterestedResp.getCanUpdate()) {
          this.rfq$.next(markNotInterestedResp.getRfq().toObject());
        } else {
          this.setPlaceholderString(markNotInterestedResp.getReason());
        }
      },
      (err) => {
        this.setPlaceholderString('RFQ DETAILS NOT FOUND');
      },
    );
  }

  private setPlaceholderString(value: string) {
    this.placeholderString$.next(value);
  }

  public createBid() {
    if (this.canSubmitBid()) {
      this.networkActive$$.next(true);
      const createBidRequest = new CreateRFQBidRequest();
      createBidRequest.setAmount(this.bid * 100);
      createBidRequest.setRfqId(this.rfqId);
      createBidRequest.setToken(this.token);
      if (!this.isReBid && this.isProductRfq(this.rfq$.value)) {
        const point = new Point();
        const location = this.pickupLocation.split(',');
        point.setLat(+location[0]);
        point.setLon(+location[1]);
        createBidRequest.setSiteGeofence(point);
      }
      this.marketplaceService.createBid(createBidRequest).subscribe(
        (resp: CreateRFQBidResponse) => {
          if (resp.getCreated()) {
            this.setPlaceholderString('BID PLACED SUCCESSFULLY');
            this.networkActive$$.next(false);
          } else {
            this.showSnackbar(resp.getReason());
            this.networkActive$$.next(false);
          }
        },
        (err) => {
          this.showSnackbar('BID PLACING FAILED. PLEASE TRY AGAIN LATER');
          this.networkActive$$.next(false);
        },
      );
    } else {
      this.showSnackbar(
        this.isProductRfq(this.rfq$.value) ? 'Please enter valid bid and pickup location' : 'Please enter valid bid',
      );
    }
  }

  private canSubmitBid() {
    if (this.bid && this.bid > 0) {
      if (this.isProductRfq(this.rfq$.value) && !this.isReBid) {
        return !!this.pickupLocation;
      } else {
        return true;
      }
    }
    return false;
  }

  public async openMapLocation() {
    const mapDialog = this.matdialog.open(RfqLocationFormComponent);
    const locationData = await mapDialog.afterClosed().toPromise();
    this.pickupLocation = locationData;
  }

  public countDown(dataObj) {
    dataObj.subscribe((obj) => {
      if (obj) {
        if (new Date().getTime() / 1000 < obj.biddingStartTime) {
          const date = moment.unix(obj.biddingStartTime).format('MM/DD/YY, h:mm a');
          this.counter = `Bidding will begin at: ${date} `;
        } else if (new Date().getTime() / 1000 > obj.biddingEndTime) {
          const date = moment.unix(obj.biddingEndTime).format('MM/DD/YY, h:mm a');
          this.counter = `Bidding ended at: ${date}`;
        } else {
          setInterval(() => {
            const count = obj.biddingEndTime - new Date().getTime() / 1000;
            this.counter = this.secondsToDhms(count.toFixed());
          }, 1000);
        }
      }
    });
  }

  public secondsToDhms(seconds) {
    if (seconds > 0) {
      const d = Math.floor(seconds / (3600 * 24));
      const h = Math.floor((seconds % (3600 * 24)) / 3600);
      const m = Math.floor((seconds % 3600) / 60);
      const s = Math.floor(seconds % 60);

      const dDisplay = d > 0 ? d + (d === 1 ? ' day ' : ' days ') : '';
      const hDisplay = h + ':';
      const mDisplay = m + ':';
      const sDisplay = s + '';
      return (
        'Bidding ends in:  ' +
        dDisplay +
        hDisplay.padStart(3, '0') +
        mDisplay.padStart(3, '0') +
        sDisplay.padStart(2, '0')
      );
    } else {
      return 'Bidding Has Ended';
    }
  }

  // private getDistanceInMiles() {
  //   const distanceReq = new CalculateDistanceRequest();
  //   const pickupLoc = this.pickupLocation.split(',');
  //   const pickupPoint = new Point();
  //   pickupPoint.setLat(+pickupLoc[0]);
  //   pickupPoint.setLon(+pickupLoc[1]);
  //   distanceReq.setPickUp(pickupPoint);
  //   const dropOffPoint = new Point();
  //   const sub = this.rfq$.subscribe((rfq) => {
  //     dropOffPoint.setLat(rfq.well.lat);
  //     dropOffPoint.setLon(rfq.well.lon);
  //   });
  //   distanceReq.setDropOff(dropOffPoint);
  //   sub.unsubscribe();
  //   this.marketplaceService.getDistance(distanceReq).subscribe(
  //     (resp: CalculateDistanceResponse) => {
  //       if (!resp.getReason()) {
  //         this.distanceInMiles$.next(resp.getDistance());
  //       } else {
  //         this.showSnackbar(resp.getReason());
  //       }
  //     },
  //     (err) => {
  //       this.showSnackbar('Error getting distance');
  //     },
  //   );
  // }

  private showSnackbar(msg: string) {
    this.snackbar.open(msg, null, {
      duration: 2000,
      panelClass: ['snackbar-error'],
    });
  }

  public setMarkNotinterested() {
    this.networkActive$$.next(true);
    const markNotinterested = new markNotInterestedRequest();
    markNotinterested.setRfqId(this.rfqId);
    markNotinterested.setToken(this.token);
    this.marketplaceService.markNotInterested(markNotinterested).subscribe(
      (resp: markNotInterestedResponse) => {
        if (resp.getUpdated()) {
          this.setPlaceholderString('YOU HAVE BEEN MARKED NOT INTERESTED SUCCESSFULLY');
          this.networkActive$$.next(false);
        } else {
          this.showSnackbar(resp.getReason());
          this.networkActive$$.next(false);
        }
      },
      (err) => {
        this.showSnackbar('MARKING YOU NOT INTERESTED FAILED FOR SOME REASON. PLEASE TRY AGAIN LATER');
        this.networkActive$$.next(false);
      },
    );
  }

  public isFreightRfq(rfq) {
    return rfq.rfqType === 2;
  }

  public isProductRfq(rfq) {
    return rfq.rfqType === 3;
  }

  public isDeliveredProductRfq(rfq) {
    return rfq.rfqType === 4;
  }

  public getTotalCostLabel(rfq) {
    if (this.isFreightRfq(rfq)) {
      return `TOTAL COST ($) at ${rfq.distance} miles`;
    } else if (this.isProductRfq(rfq) || this.isDeliveredProductRfq(rfq)) {
      return `TOTAL COST ($) at ${formatNumber(rfq.quantity, 'en-US')} ${rfq.unit}`;
    }
  }

  public getTotalCost(rfq) {
    if (this.isFreightRfq(rfq)) {
      return (this.bid * rfq.distance).toFixed(2);
    } else if (this.isProductRfq(rfq) || this.isDeliveredProductRfq(rfq)) {
      return (this.bid * rfq.quantity).toFixed(2);
    }
  }

  public getMinReductionSuffix(rfq) {
    if (this.isFreightRfq(rfq)) {
      return `$ / MILE`;
    } else {
      return `$ / ${rfq.unit}`;
    }
  }
}
