/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stage-url-indicator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./stage-url-indicator.component";
var styles_StageUrlIndicatorComponent = [i0.styles];
var RenderType_StageUrlIndicatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StageUrlIndicatorComponent, data: {} });
export { RenderType_StageUrlIndicatorComponent as RenderType_StageUrlIndicatorComponent };
export function View_StageUrlIndicatorComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "environment-switcher"]], [[2, "is-prod", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Using ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isProd(); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.getEnvName(); _ck(_v, 1, 0, currVal_1); }); }
export function View_StageUrlIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ct-stage-url-indicator", [], null, null, null, View_StageUrlIndicatorComponent_0, RenderType_StageUrlIndicatorComponent)), i1.ɵdid(1, 114688, null, 0, i2.StageUrlIndicatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StageUrlIndicatorComponentNgFactory = i1.ɵccf("ct-stage-url-indicator", i2.StageUrlIndicatorComponent, View_StageUrlIndicatorComponent_Host_0, {}, {}, []);
export { StageUrlIndicatorComponentNgFactory as StageUrlIndicatorComponentNgFactory };
