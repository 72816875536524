import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Alarm } from '~proto/alarm/alarm_pb';
import { LmoSiteAlarmsService } from '../../services/lmo-site-alarms.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-lmo-site-alarms',
  styleUrls: ['./lmo-site-alarms.component.scss'],
  templateUrl: './lmo-site-alarms.component.html',
})
export class LmoSiteAlarmsComponent implements OnInit {
  public alarms$: Observable<Alarm.AsObject[]>;
  constructor(private alarmService: LmoSiteAlarmsService) {}

  public ngOnInit() {
    this.alarms$ = this.alarmService.alarms$;
  }

  public prettyAlarmName(alarm: Alarm.AsObject): string {
    switch (alarm.alarmType) {
      case 'driver_delay':
        return 'Driver Delayed';
      case 'driver_stop':
        return 'Driver Stopped';
      case 'all_vendors_declined':
        return 'All Vendors Declined';
      case 'no_vendors_responded':
        return 'No Vendors Responded';
      case 'up_for_grabs':
        return 'Up For Grabs';
      case 'order_created':
        return 'Order Created';
      case 'order_cancelled':
        return 'Order Cancelled';
      case 'close_out_load':
        return 'Close Out Load';
      case 'vendor_declined':
        return 'Vendor Declined';
      case 'dispatch_delayed':
        return 'Dispatch Delayed';
      default:
        return alarm.alarmType;
    }
  }

  public toRouterLink(url: string): string[] {
    if (!url.includes('hauli.shaleapps.com') && !url.startsWith('/')) {
      return null;
    }
    if (url.startsWith('/')) {
      return ['/', ...url.split('/')].filter((path) => path.length > 0);
    }
    const hauliUrl = 'hauli.shaleapps.com';
    const start = url.indexOf(hauliUrl) + hauliUrl.length;
    return ['/', ...url.substring(start).split('/')].filter((path) => path.length > 0);
  }

  public alarmImageName(alarm: Alarm.AsObject): string {
    switch (alarm.alarmType) {
      case 'driver_delay':
        return 'delay';
      case 'driver_stop':
        return 'generic';
      case 'all_vendors_declined':
        return 'generic';
      case 'no_vendors_responded':
        return 'generic';
      case 'up_for_grabs':
        return 'delay';
      case 'order_created':
        return 'generic';
      case 'order_cancelled':
        return 'generic';
      case 'close_out_load':
        return 'generic';
      case 'vendor_declined':
        return 'generic';
      default:
        return 'generic';
    }
  }

  public trackByCompoundKey(alarm: Alarm.AsObject): string {
    return alarm.compoundKey;
  }
}
