import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { PayloadService } from '~lmo/services/payload.service';
import { DriverCertification } from '~proto/drivercertification/driverCertification_pb';
import { CreatePayloadRequest } from '~proto/payload/payload_api_pb';
import { PayloadType, Unit } from '~proto/payload/payload_pb';
import { ConstantsService } from '~services/constants.service';
import { recordToArray } from '~utilities/recordToArray';
import { trackById } from '~utilities/trackById';
import { DriverCertificationService } from '../../../dispatcher/services/driver-certification.service';

interface FormValue {
  driverCertificationIds: number[];
  name: string;
  payloadTypeId: number;
  unitId: number;
}

@Component({
  selector: 'ct-lmo-payload-management-create',
  styleUrls: ['./lmo-payload-management-create.component.scss'],
  templateUrl: './lmo-payload-management-create.component.html',
})
export class LmoPayloadManagementCreateComponent implements OnInit {
  public networkActive$ = new BehaviorSubject(false);
  public formGroup: FormGroup;

  public payloadTypes: PayloadType.AsObject[];
  public units: Unit.AsObject[];
  public driverCertifications$: Observable<DriverCertification.AsObject[]>;

  public trackById = trackById;

  constructor(
    private fb: FormBuilder,
    private payloadService: PayloadService,
    private constantsService: ConstantsService,
    private driverCertificationService: DriverCertificationService,
    private router: Router,
    private snackbar: MatSnackBar,
  ) {}

  private formValue(): FormValue {
    return this.formGroup.value;
  }

  public ngOnInit() {
    this.formGroup = this.fb.group({
      driverCertificationIds: [[], []],
      name: [null, [Validators.required]],
      payloadTypeId: [null, [Validators.required]],
      unitId: [null, [Validators.required]],
    });
    this.payloadTypes = recordToArray(this.constantsService.payloadTypes).sort((a, b) => a.name.localeCompare(b.name));
    this.units = recordToArray(this.constantsService.units).sort((a, b) => a.name.localeCompare(b.name));
    this.driverCertifications$ = this.driverCertificationService.driverCertifications$;
  }

  public async addPayload() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    const formValue = this.formValue();

    this.networkActive$.next(true);

    const createPayloadRequest = new CreatePayloadRequest();
    createPayloadRequest.setName(formValue.name);
    createPayloadRequest.setPayloadTypeId(formValue.payloadTypeId);
    createPayloadRequest.setUnitId(formValue.unitId);
    createPayloadRequest.setDriverCertificationIdsList(formValue.driverCertificationIds);

    this.payloadService.createPayload$(createPayloadRequest).subscribe(() => {
      this.networkActive$.next(false);
      this.router.navigate(['/', 'lmo', 'payloads']);

      this.snackbar.open('Payload Created', null, {
        duration: 2000,
      });
    });
  }
}
