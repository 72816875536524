<div class="file-preview-wrap">
  <i class="material-icons close-btn" [mat-dialog-close]="false">close</i>
  <div class="file-wrap">
    <div class="download-buttons-wrap" *ngIf="!fileLoading">
      <div class="download-wrap" (click)="getPreviewDownloadLink()">
        <i class="material-icons">arrow_downward</i>
      </div>
      <div class="download-wrap" *ngIf="fileType === 'image'" (click)="rotateImage(-90)">
        <i class="material-icons">rotate_left</i>
      </div>
      <div class="download-wrap" *ngIf="fileType === 'image'" (click)="rotateImage(90)">
        <i class="material-icons">rotate_right</i>
      </div>
    </div>
    <div class="file-preview" *ngIf="previewFileUrl && !fileLoading">
      <img
        [src]="previewFileUrl"
        *ngIf="fileType === 'image'; else noPreview"
        [ngStyle]="{ transform: 'rotate(' + rotateDegree + 'deg)' }"
      />
      <ng-template #noPreview>
        <div class="preview-placeholder">
          <h3>This attachment cannot be previewed.</h3>
        </div>
      </ng-template>
    </div>
    <div class="loader-placeholder" *ngIf="fileLoading">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  </div>
</div>
