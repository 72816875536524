import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '~services/auth.service';
import { RoleService } from '~services/role.service';

@Component({
  selector: 'sa-update-password',
  styleUrls: ['./update-password.component.scss'],
  templateUrl: './update-password.component.html',
})
export class UpdatePasswordComponent implements OnInit {
  public updatePasswordForm: FormGroup;
  public password: string;
  public hide = true;
  public hide1 = true;

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public roleService: RoleService,
    private authService: AuthService,
  ) {}

  private formValue() {
    return this.updatePasswordForm.value;
  }

  public ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.updatePasswordForm = this.fb.group(
      {
        confirmPassword: ['', [Validators.required, this.passwordValidator('password', 'confirmPassword')]],
        password: ['', [Validators.required]],
      },
      {
        validator: [this.passwordValidator('password', 'confirmPassword')],
      },
    );
  }

  public updatePassword() {
    const formValue = this.formValue();
    if (this.updatePasswordForm.invalid) {
      return;
    }
    if (formValue.password === formValue.confirmPassword) {
      this.roleService.updatePassword$(formValue.password).subscribe(
        (response) => {
          this.snackBar.open('Password updated successfully', null, { duration: 2000 });
          this.logout();
        },
        (error) => {
          console.error(error);
          this.snackBar.open('Failed to update password', null, { duration: 2000, panelClass: ['snackbar-error'] });
        },
      );
    }
  }

  public passwordValidator(oldDateControlName: string, newDateControlName: string) {
    return (group: FormGroup) => {
      const oldDateControl = group.get(oldDateControlName);
      const newDateControl = group.get(newDateControlName);

      if (!oldDateControl || !newDateControl) {
        return;
      }

      return oldDateControl.value === newDateControl.value
        ? newDateControl.setErrors(null)
        : newDateControl.setErrors({ diff: true });
    };
  }

  public logout() {
    this.authService.logout();
  }
}
