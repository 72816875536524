import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-jobs-list-order-count',
  styleUrls: ['./jobs-list-order-count.component.scss'],
  templateUrl: './jobs-list-order-count.component.html',
})
export class JobsListOrderCountComponent implements OnInit {
  @Input() public pendingCount: number;
  @Input() public haulisBackupCount: number;
  @Input() public upForGrabsCount: number;
  @Input() public inProgressCount: number;
  @Input() public inboundCount: number;
  @Input() public outboundCount: number;
  @Input() public alarmCount: number;
  @Input() public onSiteCount: number;
  @Input() public siteId: number;

  constructor() {}

  public ngOnInit() {}
}
