import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';
import { TooltipPosition } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import { once } from 'remeda';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, take, tap } from 'rxjs/operators';
import { FuelSurchargeService } from 'src/app/billing/services/fuel-surcharge.service';
import { ConfirmSaveDialogueComponent } from '~common/confirm-save-dialogue/confirm-save-dialogue.component';
import { CreateMileageCostsRequest, EditVendorContractRequest } from '~proto/contracts/contracts_api_pb';
import { FuelSurchargeContract, VendorContract, VendorContractType } from '~proto/contracts/contracts_pb';
import { PayloadType } from '~proto/payload/payload_pb';
import { NullableTime, VendorContractTravelCostType, VendorContractTravelCostTypeMap } from '~proto/types/types_pb';
import { ConstantsService } from '~services/constants.service';
import { UnsavedDataComponent } from '~services/guard/has-unsaved-data.guard';
import { recordToArray } from '~utilities/recordToArray';
import { trackById } from '~utilities/trackById';
import { ContractsService } from '../../services/contracts.service';

interface MileageBracket {
  cost: number;
  maxMiles: number;
  minMiles: number;
}

interface FormValue {
  contractName: string;
  costPerHour: number;
  costPerMile: number;
  deadheadCost: number;
  deadheadFreeDistance: number;
  defaultContract: boolean;
  detentionHourly: number;
  detentionMax: number;
  freeTimeDropoff: number;
  freeTimePickup: number;
  lineHaul: VendorContractTravelCostTypeMap[keyof VendorContractTravelCostTypeMap];
  payloadTypes: PayloadType.AsObject[];
  referenceNumber: string;
  mileageBrackets: MileageBracket[];
  contractType: number;
  fuelSurcharge: boolean;
  isReturnBillable: boolean;
  minPrice: number;
  fileId: number;
  backHaulChargePercent: number;
  isBackHaul: boolean;
  expirationDate: string;
  effectiveDate: string;
}

const MAX_FILE_SIZE = 25 * 1024 * 1024;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-lmo-trucking-contracts-edit',
  styleUrls: ['./lmo-trucking-contracts-edit.component.scss'],
  templateUrl: './lmo-trucking-contracts-edit.component.html',
})
export class LmoTruckingContractsEditComponent implements OnInit, OnDestroy, UnsavedDataComponent {
  public trackById = trackById;
  public today = new Date();

  @ViewChild('newContractType', { static: true }) private newContractTypeFormModal: TemplateRef<any>;
  public newContractTypeId: FormControl;
  public newContractTypeForm: FormControl;
  public newContractEdiCode: FormControl;
  public newContractFuelMatrix: FormControl;
  public contractTypes: VendorContractType.AsObject[] = [];
  private newContractTypeFormDialog: MatDialogRef<any>;
  public fuelContractList$: Observable<FuelSurchargeContract.AsObject[]>;
  public position: TooltipPosition = 'below';
  public message = 'Require contract type with fuel surcharge';
  public backHaulMessage = 'Require back haul option as Yes';
  public effectiveDatMessage = 'Effective Date Already Choosen';
  public toolTipDisabled = false;
  public backHaulToolTipDisabled = false;
  public isEffectiveDateTooltipDisabled = false;

  public get mileageBrackets(): FormArray {
    return this.formGroup.get('mileageBrackets') as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    private constantsService: ConstantsService,
    private contractService: ContractsService,
    private router: Router,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private fuelSurchargeService: FuelSurchargeService,
  ) {}

  private formSubmitted = false;
  public VendorContractTravelCostType = VendorContractTravelCostType;
  public lineHaulOptions = [
    {
      name: 'Hourly',
      value: VendorContractTravelCostType.PER_HOUR,
    },
    {
      name: 'Fixed Cost Mileage Bracket',
      value: VendorContractTravelCostType.MILEAGE_BRACKET,
    },
    {
      name: 'Per Mile',
      value: VendorContractTravelCostType.PER_MILE,
    },
    {
      name: 'Fixed Cost Point to Point',
      value: VendorContractTravelCostType.FIXED_POINT,
    },
  ];
  private destroy$ = new Subject();
  public payloadTypes: PayloadType.AsObject[];
  public networkActive$$ = new BehaviorSubject(false);
  public formGroup: FormGroup;
  public contract$: Observable<VendorContract.AsObject>;
  public contract: FormValue;

  public uploadActive$$ = new BehaviorSubject<boolean>(false);

  @HostListener('window:popstate', ['$event'])
  public onPopState(event) {
    this.checkEditForm();
  }

  public ngOnInit() {
    this.today.setHours(0, 0, 0, 0);
    this.formGroup = this.fb.group({
      backHaulChargePercent: [0, [Validators.max(100), Validators.min(0)]],
      contractName: [null, [Validators.required]],
      contractType: [null],
      costPerHour: [null, [Validators.required]],
      costPerMile: [null, [Validators.required]],
      deadheadCost: [0, [Validators.required]],
      deadheadFreeDistance: [0, [Validators.required]],
      defaultContract: [false, [Validators.required]],
      detentionHourly: [0, [Validators.required]],
      detentionMax: [0, [Validators.required]],
      effectiveDate: [null],
      expirationDate: [null],
      fileId: [0, [Validators.required]],
      freeTimeDropoff: [0, [Validators.required]],
      freeTimePickup: [0, [Validators.required]],
      fuelSurcharge: this.fb.control({ value: false, disabled: true }, [Validators.required]),
      isBackHaul: [false, [Validators.required]],
      isReturnBillable: [false, [Validators.required]],
      lineHaul: ['Fixed Cost Mileage Bracket', [Validators.required]],
      minPrice: [0, [Validators.required]],
      payloadTypes: [null],
      referenceNumber: [null],
    });

    this.formGroup.addControl('mileageBrackets', this.fb.array([]));

    this.payloadTypes = recordToArray(this.constantsService.payloadTypes).sort((a, b) => a.name.localeCompare(b.name));

    const onlyListenOnce = once(() => this.listenToLineHaul());

    this.fuelContractList$ = this.fuelSurchargeService.fuelContracts$.pipe(
      map((fuelContracts) => fuelContracts.contractsList.filter((fuelContract) => fuelContract.id)),
    );

    this.contract$ = this.contractService.currentContract$.pipe(
      filter((contract) => !!contract),
      tap((contract) => {
        if (
          contract.contractType &&
          contract.contractType.fuelSurchargeContract &&
          contract.contractType.fuelSurchargeContract.contractTypesList &&
          contract.contractType.fuelSurchargeContract.contractTypesList.length > 0
        ) {
          this.formGroup.get('fuelSurcharge').enable();
          this.toolTipDisabled = true;
        }
        if (!contract.isBackHaulEnabled) {
          this.formGroup.get('backHaulChargePercent').disable();
          this.backHaulToolTipDisabled = false;
        } else {
          this.formGroup.get('backHaulChargePercent').enable();
          this.backHaulToolTipDisabled = true;
        }
        if (contract.isEffective) {
          this.formGroup.get('effectiveDate').disable();
          this.isEffectiveDateTooltipDisabled = false;
        } else {
          this.formGroup.get('effectiveDate').enable();
          this.isEffectiveDateTooltipDisabled = true;
        }
        this.formGroup.patchValue(
          {
            backHaulChargePercent: contract.backHaulChargePercent,
            contractName: contract.name,
            contractType: contract.contractType ? contract.contractType.id : -1,
            costPerHour: contract.perHourTravelCost,
            costPerMile: contract.perMileTravelCost,
            deadheadCost: contract.deadheadCostPerMile,
            deadheadFreeDistance: contract.deadheadFreeMileage,
            defaultContract: contract.isDefault,
            detentionHourly: contract.hourlyDetentionCost,
            detentionMax: contract.maxDetentionPerOrder,
            effectiveDate: contract.effectiveTime && contract.effectiveTime.valid ? contract.effectiveTime.time : null,
            expirationDate: contract.expirationTime.valid ? contract.expirationTime.time : null,
            fileId: contract.fileId,
            freeTimeDropoff: contract.dropoffFreeTime,
            freeTimePickup: contract.pickupFreeTime,
            fuelSurcharge: contract.fuelSurcharge,
            isBackHaul: contract.isBackHaulEnabled,
            isReturnBillable: contract.isReturnTripBillable,
            lineHaul: contract.travelCostType,
            minPrice: contract.minimumCharge,
            payloadTypes: contract.payloadtypesList,
            referenceNumber: contract.referenceNumber,
          },
          { emitEvent: false, onlySelf: true },
        );
        if (contract.fileId > 0) {
          this.contractService.loadFileDetails(contract.fileId);
        }
        this.mileageBrackets.clear();
        contract.mileageCostsList.forEach((item) => {
          const mileageBracket = this.fb.group({
            cost: [item.cost, [Validators.required]],
            maxMiles: [item.upperBoundMiles, [Validators.required]],
            minMiles: [item.lowerBoundMiles, [Validators.required]],
          });
          this.mileageBrackets.push(mileageBracket);
        });
        this.networkActive$$.next(false);
        onlyListenOnce();
        this.contract = this.formGroup.value;
      }),
    );

    this.newContractTypeId = this.fb.control(null, [Validators.required]);
    this.newContractTypeForm = this.fb.control(null, [Validators.required]);
    this.newContractEdiCode = this.fb.control(null, [Validators.required]);
    this.newContractFuelMatrix = this.fb.control(null);

    this.contractService.contractTypes$.subscribe((contractTypes) => {
      this.contractTypes = contractTypes;
    });
    this.setupFormDisableAndEnableListeners();
  }

  private setupFormDisableAndEnableListeners() {
    this.formGroup
      .get('contractType')
      .valueChanges.pipe(distinctUntilChanged())
      .pipe(debounceTime(1000))
      .subscribe((contractType) => {
        const fuelContract = this.contractTypes.find((type) => {
          return type.id === contractType;
        });
        if (!contractType || !(fuelContract && fuelContract.fuelSurchargeContract)) {
          this.formGroup.get('fuelSurcharge').setValue(false);
          this.formGroup.get('fuelSurcharge').disable();
          this.toolTipDisabled = false;
        } else {
          this.formGroup.get('fuelSurcharge').enable();
          this.toolTipDisabled = true;
        }
      });

    this.formGroup
      .get('isBackHaul')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((isBackHaul) => {
        this.formGroup.get('backHaulChargePercent').setValue(0);
        if (isBackHaul) {
          this.formGroup.get('backHaulChargePercent').enable();
          this.backHaulToolTipDisabled = true;
        } else {
          this.formGroup.get('backHaulChargePercent').disable();
          this.backHaulToolTipDisabled = false;
        }
      });
  }

  public openNewContractTypeForm() {
    this.newContractTypeFormDialog = this.matDialog.open(this.newContractTypeFormModal, {
      maxWidth: '500px;',
    });

    this.newContractTypeFormDialog.afterOpened().subscribe(() => {
      this.newContractTypeForm.reset();
      this.newContractEdiCode.reset();
      this.newContractTypeId.reset();
      this.newContractFuelMatrix.reset();
    });
  }

  public openEditContractTypeForm() {
    this.newContractTypeFormDialog = this.matDialog.open(this.newContractTypeFormModal, {
      maxWidth: '500px;',
    });
    this.newContractTypeFormDialog.afterOpened().subscribe(() => {
      const contractTypeId = this.formGroup.get('contractType').value;
      const contractType = this.contractTypes.find((type) => {
        return type.id === contractTypeId;
      });
      this.newContractTypeForm.setValue(contractType.name);
      this.newContractEdiCode.setValue(contractType.ediCode);
      this.newContractTypeId.setValue(contractTypeId);
      this.newContractFuelMatrix.setValue(
        contractType && contractType.fuelSurchargeContract && contractType.fuelSurchargeContract.id,
      );
    });
  }

  public createContractType() {
    const contractTypeName = this.newContractTypeForm.value;
    const contractEdiCode = this.newContractEdiCode.value;
    const contractFuelId = this.newContractFuelMatrix.value;

    if (!contractFuelId) {
      this.formGroup.get('fuelSurcharge').setValue(false);
      this.formGroup.get('fuelSurcharge').disable();
    } else {
      this.formGroup.get('fuelSurcharge').enable();
    }

    if (this.newContractTypeId.value > 0) {
      return this.editContractType();
    }

    this.contractService.createContractType$(contractTypeName, contractEdiCode, contractFuelId).subscribe(
      (contractType) => {
        this.formGroup.get('contractType').setValue(contractType.id);
        this.newContractTypeFormDialog.close();
        this.snackBar.open(`Contract type is created`, null, {
          duration: 2500,
        });
      },
      (err) => {
        this.snackBar.open('Something Went Wrong', null, {
          duration: 2500,
          panelClass: ['snackbar-error'],
        });
      },
    );
  }

  private editContractType() {
    const contractTypeId = this.newContractTypeId.value;
    const contractTypeName = this.newContractTypeForm.value;
    const contractEdiCode = this.newContractEdiCode.value;
    const contractFuelId = this.newContractFuelMatrix.value;

    this.contractService.editContractType$(contractTypeId, contractTypeName, contractEdiCode, contractFuelId).subscribe(
      (contractType) => {
        this.formGroup.get('contractType').setValue(contractType.id);
        this.newContractTypeFormDialog.close();
        this.snackBar.open(`Contract type is edited`, null, {
          duration: 2500,
        });
      },
      (err) => {
        this.snackBar.open('Something Went Wrong', null, {
          duration: 2500,
          panelClass: ['snackbar-error'],
        });
      },
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  public hasUnsavedData(): boolean {
    return this.formGroup.dirty && !this.formSubmitted;
  }

  public async editTruckingContract() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }
    // Stop listening we are going to submit!
    this.destroy$.next();
    this.networkActive$$.next(true);
    const request = this.editRequest();
    try {
      await this.contractService.editCurrentContract(request);
      this.snackBar.open(`Updating Trucking Contract Successful`, null, {
        duration: 2000,
      });
      this.formSubmitted = true;
      this.contractService.setSavedStatus('saved');
      this.router.navigate(['/', 'lmo', 'trucking-contracts']);
    } catch (error) {
      this.snackBar.open('Updating Trucking Contract Failed', null, {
        duration: 2000,
        panelClass: ['snackbar-error'],
      });
      this.contractService.setSavedStatus('nosaved');
    }
  }

  public addBracket() {
    const brackets = this.formGroup.get('mileageBrackets') as FormArray;
    const lastBracket = brackets.value.length && brackets.value[brackets.value.length - 1];
    brackets.push(
      this.fb.group({
        cost: [null, [Validators.required]],
        maxMiles: [null, [Validators.required]],
        minMiles: [lastBracket ? lastBracket.maxMiles + 1 : null, [Validators.required]],
      }),
    );
  }

  public removeBracket(id: number) {
    const brackets = this.formGroup.get('mileageBrackets') as FormArray;
    brackets.removeAt(id);
  }

  public archiveContract() {
    this.networkActive$$.next(true);
    this.contractService.toggleCurrentContractArchive();
  }

  public compareById<T extends { id: number }>(a: T, b: T): boolean {
    return a.id === b.id;
  }

  private listenToLineHaul() {
    this.formGroup
      .get('lineHaul')
      .valueChanges.pipe(startWith(this.formGroup.get('lineHaul').value))
      .subscribe((value: VendorContractTravelCostTypeMap[keyof VendorContractTravelCostTypeMap]) => {
        if (value === VendorContractTravelCostType.MILEAGE_BRACKET) {
          this.toggleMileageBracket('enable');
          this.toggleCostPerHour('disable');
          this.toggleCostPerMile('disable');
          this.toggleFileId('disable');
        } else if (value === VendorContractTravelCostType.PER_HOUR) {
          this.toggleCostPerHour('enable');
          this.toggleCostPerMile('disable');
          this.toggleMileageBracket('disable');
          this.toggleFileId('disable');
        } else if (value === VendorContractTravelCostType.PER_MILE) {
          this.toggleCostPerMile('enable');
          this.toggleCostPerHour('disable');
          this.toggleMileageBracket('disable');
          this.toggleFileId('disable');
        } else if (value === VendorContractTravelCostType.FIXED_POINT) {
          this.toggleCostPerMile('disable');
          this.toggleCostPerHour('disable');
          this.toggleMileageBracket('disable');
          this.toggleFileId('enable');
        }
      });
  }

  private toggleMileageBracket(action: 'enable' | 'disable') {
    this.formGroup.get('mileageBrackets')[action]();
  }

  private toggleCostPerHour(action: 'enable' | 'disable') {
    this.formGroup.get('costPerHour')[action]();
  }

  private toggleCostPerMile(action: 'enable' | 'disable') {
    this.formGroup.get('costPerMile')[action]();
  }

  private editRequest(): EditVendorContractRequest {
    const value: FormValue = this.formGroup.value;
    const rawValue: FormValue = this.formGroup.getRawValue();
    const request = new EditVendorContractRequest();
    const mask = new FieldMask();
    request.setName(value.contractName);
    mask.addPaths('name');
    request.setPayloadTypeIdsList(value.payloadTypes.map((type) => type.id));
    mask.addPaths('payloadIds');
    request.setTravelCostType(value.lineHaul);
    mask.addPaths('travelCostType');
    request.setIsDefault(value.defaultContract);
    mask.addPaths('isDefault');
    request.setReferenceNumber(value.referenceNumber);
    mask.addPaths('referenceNumber');
    request.setIsReturnTripBillable(value.isReturnBillable);
    mask.addPaths('isReturnTripBillable');
    request.setBackHaulChargePercent(value.backHaulChargePercent);
    mask.addPaths('backHaulChargePercent');
    request.setIsBackHaulEnabled(value.isBackHaul);
    mask.addPaths('isBackHaulEnabled');
    const expirationDate = new NullableTime();
    expirationDate.setValid(value.expirationDate !== null);
    expirationDate.setTime(new Date(value.expirationDate).toISOString());
    request.setExpirationTime(expirationDate);
    const effectiveDate = new NullableTime();
    if (!!rawValue.effectiveDate) {
      effectiveDate.setValid(rawValue.effectiveDate !== null);
      effectiveDate.setTime(new Date(rawValue.effectiveDate).toISOString());
    }
    request.setEffectiveTime(effectiveDate);
    mask.addPaths('expirationTime');
    mask.addPaths('effectiveTime');
    if (value.contractType > 0) {
      request.setContractTypeId(value.contractType);
      mask.addPaths('vendorContractTypeId');
    }

    if (value.lineHaul === VendorContractTravelCostType.MILEAGE_BRACKET) {
      request.setDeadheadCostPerMile(value.deadheadCost);
      mask.addPaths('deadheadCostPerMile');
      request.setDeadheadFreeMileage(value.deadheadFreeDistance);
      mask.addPaths('deadheadFreeMileage');
      request.setDropoffFreeTime(value.freeTimeDropoff);
      mask.addPaths('dropoffFreeTime');
      request.setHourlyDetentionCost(value.detentionHourly);
      mask.addPaths('hourlyDetentionCost');
      request.setMaxDetentionPerOrder(value.detentionMax);
      mask.addPaths('maxDetentionPerOrder');
      request.setPickupFreeTime(value.freeTimePickup);
      mask.addPaths('pickupFreeTime');
      request.setMileageCostRequestsList(
        value.mileageBrackets.map((bracket) => {
          const bracketRequest = new CreateMileageCostsRequest();
          bracketRequest.setCost(bracket.cost);
          bracketRequest.setLowerBoundMiles(bracket.minMiles);
          bracketRequest.setUpperBoundMiles(bracket.maxMiles);
          return bracketRequest;
        }),
      );
      mask.addPaths('mileageCostRequests');
    } else if (value.lineHaul === VendorContractTravelCostType.PER_HOUR) {
      request.setPerHourTravelCost(value.costPerHour);
      mask.addPaths('perHourTravelCost');
      request.setMinimumCharge(value.minPrice);
      mask.addPaths('minimumCharge');
    } else if (value.lineHaul === VendorContractTravelCostType.PER_MILE) {
      request.setPerMileTravelCost(value.costPerMile);
      mask.addPaths('perMileTravelCost');
      request.setMinimumCharge(value.minPrice);
      mask.addPaths('minimumCharge');
      request.setDropoffFreeTime(value.freeTimeDropoff);
      mask.addPaths('dropoffFreeTime');
      request.setHourlyDetentionCost(value.detentionHourly);
      mask.addPaths('hourlyDetentionCost');
      request.setMaxDetentionPerOrder(value.detentionMax);
      mask.addPaths('maxDetentionPerOrder');
      request.setPickupFreeTime(value.freeTimePickup);
      mask.addPaths('pickupFreeTime');
    } else if (value.lineHaul === VendorContractTravelCostType.FIXED_POINT) {
      request.setFixCostFileId(value.fileId);
      mask.addPaths('fixPointFileId');
    }
    request.setFuelSurcharge(value.fuelSurcharge);
    mask.addPaths('isFuelSurchargeApplies');
    request.setMask(mask);
    return request;
  }

  public checkEditForm() {
    if (this.checkForFormChanges()) {
      this.contractService.setSavedStatus('nosaved');
      const dialog = this.matDialog.open(ConfirmSaveDialogueComponent);
      dialog.afterClosed().subscribe((result) => {
        if (result === 'save') {
          this.editTruckingContract();
        } else if (result === 'nosave') {
          this.contractService.setSavedStatus('saved');
          this.router.navigateByUrl('/lmo/trucking-contracts/manage');
        }
      });
    } else {
      this.contractService.setSavedStatus('saved');
      this.router.navigateByUrl('/lmo/trucking-contracts/manage');
    }
  }

  private checkForFormChanges(): boolean {
    let result = false;
    const formGroup = this.formGroup.controls;
    const contractKeys = Object.keys(this.contract);
    for (let i = 0; i < contractKeys.length; i++) {
      if (
        contractKeys[i] === 'payloadTypes' &&
        this.comparePayloadTypes(this.contract[contractKeys[i]], formGroup[contractKeys[i]].value)
      ) {
        result = true;
        break;
      } else if (
        contractKeys[i] === 'mileageBrackets' &&
        this.compareMileageBrackets(this.contract[contractKeys[i]], formGroup[contractKeys[i]].value)
      ) {
        result = true;
        break;
      } else if (
        this.contract[contractKeys[i]] !== this.formGroup.controls[contractKeys[i]].value &&
        contractKeys[i] !== 'mileageBrackets' &&
        contractKeys[i] !== 'payloadTypes'
      ) {
        result = true;
        break;
      }
    }
    return result;
  }

  private compareMileageBrackets(
    contractMileageBrackets: MileageBracket[],
    formMileageBrackets: MileageBracket[],
  ): boolean {
    let result = false;
    if (contractMileageBrackets.length !== formMileageBrackets.length) {
      return true;
    }
    contractMileageBrackets.forEach((val, idx) => {
      if (
        val.cost !== formMileageBrackets[idx].cost ||
        val.maxMiles !== formMileageBrackets[idx].maxMiles ||
        val.minMiles !== formMileageBrackets[idx].minMiles
      ) {
        result = true;
      }
    });
    return result;
  }

  private comparePayloadTypes(contractPayloadTypes: PayloadType.AsObject[], formPayloadTypes: PayloadType.AsObject[]) {
    let count = 0;
    if (contractPayloadTypes.length !== formPayloadTypes.length) {
      return true;
    }
    contractPayloadTypes.forEach((val) => {
      formPayloadTypes.forEach((formVal) => {
        if (val.id === formVal.id) {
          count++;
        }
      });
    });
    return count !== formPayloadTypes.length;
  }

  public trackLineHaulOptions(
    _index: number,
    option: {
      name: string;
      value: VendorContractTravelCostTypeMap[keyof VendorContractTravelCostTypeMap];
    },
  ): VendorContractTravelCostTypeMap[keyof VendorContractTravelCostTypeMap] {
    return option.value;
  }

  public trackByMemoryReference(index: number, item: any): any {
    return item;
  }

  public closeTab() {
    this.newContractTypeFormDialog.close();
    this.newContractTypeFormDialog = null;
  }

  public showDetention(): boolean {
    return (
      this.formGroup.get('lineHaul').value === VendorContractTravelCostType.MILEAGE_BRACKET ||
      this.formGroup.get('lineHaul').value === VendorContractTravelCostType.PER_MILE
    );
  }

  public showDeadhead(): boolean {
    return this.formGroup.get('lineHaul').value === VendorContractTravelCostType.MILEAGE_BRACKET;
  }

  public showMileageBracket(): boolean {
    return this.formGroup.get('lineHaul').value === VendorContractTravelCostType.MILEAGE_BRACKET;
  }

  public showMinPrice(): boolean {
    return (
      this.formGroup.get('lineHaul').value === VendorContractTravelCostType.PER_HOUR ||
      this.formGroup.get('lineHaul').value === VendorContractTravelCostType.PER_MILE
    );
  }

  public showFileUpload(): boolean {
    return this.formGroup.get('lineHaul').value === VendorContractTravelCostType.FIXED_POINT;
  }

  private toggleFileId(action: 'enable' | 'disable') {
    this.formGroup.get('fileId')[action]();
  }

  public onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      const inputSelect = document.getElementsByName('file-name');
      inputSelect[0].innerHTML = file.name;
      if (file.size > MAX_FILE_SIZE) {
        this.snackBar.open('File Size cannot be greater than 25 MB', null, {
          duration: 2500,
        });
      } else {
        event.target.name === 'mileage-brackets' ? this.uploadMileageBracketsFile(file) : this.uploadP2PCostFile(file);
      }
    }
  }

  private uploadP2PCostFile(fileToUpload) {
    this.uploadActive$$.next(true);
    this.contractService.uploadP2PCostFile$(fileToUpload).subscribe(
      (response) => {
        this.uploadActive$$.next(false);
        if (response.status) {
          this.formGroup.get('fileId').setValue(response.fileId);
          this.snackBar.open('File uploaded successfully', null, {
            duration: 2000,
          });
        } else {
          this.snackBar.open(response.message, null, {
            duration: 20000,
          });
        }
      },
      (err) => {
        this.uploadActive$$.next(false);
        this.snackBar.open('Error: ' + err.Error.Message, null, {
          duration: 20000,
        });
      },
    );
  }

  public downloadP2PCostFile() {
    const fileId = this.formGroup.get('fileId').value;
    this.contractService
      .downloadP2PCostFile$(fileId)
      .pipe(take(1))
      .subscribe(
        (response) => {
          window.location.assign(response.toObject().url);
        },
        (err) => {
          this.snackBar.open('Error:' + err.toObject().url, null, {
            duration: 10000,
          });
        },
      );
  }

  private uploadMileageBracketsFile(fileToUpload) {
    this.uploadActive$$.next(true);
    this.contractService.uploadMileageBracketsFile$(fileToUpload).subscribe(
      (response) => {
        this.uploadActive$$.next(false);
        if (response.length > 0) {
          this.mileageBrackets.controls = [];
          response.forEach((item) => {
            const mileageBracket = this.fb.group({
              cost: [item.cost, [Validators.required]],
              maxMiles: [item.upperBoundMiles, [Validators.required]],
              minMiles: [item.lowerBoundMiles, [Validators.required]],
            });
            this.mileageBrackets.push(mileageBracket);
          });
          this.snackBar.open('File uploaded successfully', null, {
            duration: 2000,
          });
        } else {
          this.snackBar.open(response.message, null, {
            duration: 10000,
          });
        }
      },
      (err) => {
        this.uploadActive$$.next(false);
        this.snackBar.open('Error: ' + err.error, null, {
          duration: 10000,
        });
      },
    );
  }

  public clearExpirationDate() {
    this.formGroup.get('expirationDate').setValue(null);
  }
  public clearFffectiveDate() {
    this.formGroup.get('effectiveDate').setValue(null);
  }
}
