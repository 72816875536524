import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validator, Validators, } from '@angular/forms';
import { flatten } from 'remeda';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, take, takeUntil } from 'rxjs/operators';
import { TaskType } from '~proto/types/types_pb';
import { UserDetailsForIntercomRequest } from '~proto/user/user_api_pb';
import { UserAPI } from '~proto/user/user_api_pb_service';
import { moveItemInFormArray } from '~utilities/moveItemInFormArray';
var CreateOrderCustomStops2Component = /** @class */ (function () {
    function CreateOrderCustomStops2Component(fb, payloadService, grpcService) {
        this.fb = fb;
        this.payloadService = payloadService;
        this.grpcService = grpcService;
        this.destroy$ = new Subject();
        this.customStops = new FormArray([]);
        this.isPurchaseOrderRequired$$ = new BehaviorSubject(false);
        this.isSalesOrderRequired$$ = new BehaviorSubject(false);
        this.isMaintenanceOrderRequired$$ = new BehaviorSubject(false);
        this.stopTypes = [
            {
                name: 'Pickup Payload',
                value: TaskType.TASK_TYPE_PICKUP,
            },
            {
                name: 'Dropoff Payload',
                value: TaskType.TASK_TYPE_DROPOFF,
            },
        ];
        var fg = this.getNewFormLine();
        fg.get('stopType').setValue(TaskType.TASK_TYPE_PICKUP);
        this.customStops.push(fg);
    }
    CreateOrderCustomStops2Component.prototype.customStopsValue = function () {
        return this.customStops.value;
    };
    Object.defineProperty(CreateOrderCustomStops2Component.prototype, "invalid", {
        get: function () {
            return !!this.validationErrors;
        },
        enumerable: true,
        configurable: true
    });
    CreateOrderCustomStops2Component.prototype.ngOnInit = function () {
        this.loadRequiredFieldSettings();
        this.setupPayloadSummaries();
        this.setupValidationErrors();
        this.setupPayloads();
        this.setupPurchaseOrders();
        this.setupSalesOrderNumbers();
        this.setupMaintenanceOrderNumbers();
    };
    CreateOrderCustomStops2Component.prototype.setupPayloadSummaries = function () {
        var _this = this;
        this.payloadSummaries$ = this.customStops.valueChanges.pipe(map(function (stops) {
            var summariesMap = stops.reduce(function (acc, stop) {
                var keyValue = _this.isPurchaseOrderRequired$$.value || stop.purchaseOrderName ? stop.purchaseOrderName : 'key';
                if (stop.payload) {
                    var key = stop.payload.id + "-" + keyValue;
                    if (!acc[key]) {
                        acc[key] = {
                            droppedOff: 0,
                            key: key,
                            leftOver: 0,
                            payloadName: stop.payload.name,
                            pickedUp: 0,
                            purchaseOrderName: stop.purchaseOrderName ? stop.purchaseOrderName : '-',
                        };
                    }
                    if (stop.stopType === TaskType.TASK_TYPE_PICKUP) {
                        acc[key].pickedUp += stop.quantity;
                        acc[key].leftOver += stop.quantity;
                    }
                    else if (stop.stopType === TaskType.TASK_TYPE_DROPOFF) {
                        acc[key].droppedOff += stop.quantity;
                        acc[key].leftOver -= stop.quantity;
                    }
                }
                return acc;
            }, {});
            return Object.values(summariesMap);
        }));
    };
    CreateOrderCustomStops2Component.prototype.setupPayloads = function () {
        this.payloadGroups$ = combineLatest([
            this.payloadService.allPayloadGroups$,
            this.customStops.valueChanges.pipe(startWith([])),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), sitePayloads = _b[0], formValues = _b[1];
            var payloadsOnForm = formValues.filter(function (stop) { return stop.payload; }).map(function (stop) { return stop.payload; });
            var allPayloads = tslib_1.__spread(flatten(sitePayloads.map(function (payloadGroup) { return payloadGroup.payloadsList; })), payloadsOnForm);
            var uniquePayloads = allPayloads.reduce(function (acc, payload) {
                acc[payload.id] = payload;
                return acc;
            }, {});
            return [
                {
                    name: '',
                    payloadsList: Object.values(uniquePayloads),
                },
            ];
        }));
    };
    CreateOrderCustomStops2Component.prototype.setupPurchaseOrders = function () {
        this.purchaseOrderNames$ = this.customStops.valueChanges.pipe(map(function (formValues) {
            return formValues.filter(function (stop) { return stop.purchaseOrderName; }).map(function (stop) { return stop.purchaseOrderName; });
        }), shareReplay(1));
    };
    CreateOrderCustomStops2Component.prototype.setupSalesOrderNumbers = function () {
        this.salesOrderNumbers$ = this.customStops.valueChanges.pipe(map(function (formValues) {
            return formValues.filter(function (stop) { return stop.salesOrderNumber; }).map(function (stop) { return stop.salesOrderNumber; });
        }), shareReplay(1));
    };
    CreateOrderCustomStops2Component.prototype.setupMaintenanceOrderNumbers = function () {
        this.maintenanceOrderNumbers$ = this.customStops.valueChanges.pipe(map(function (formValues) {
            return formValues.filter(function (stop) { return stop.maintenanceOrderNumber; }).map(function (stop) { return stop.maintenanceOrderNumber; });
        }), shareReplay(1));
    };
    CreateOrderCustomStops2Component.prototype.loadRequiredFieldSettings = function () {
        var _this = this;
        var request = new UserDetailsForIntercomRequest();
        this.grpcService.invoke$(UserAPI.UserDetailsForIntercom, request).subscribe(function (response) {
            _this.isPurchaseOrderRequired$$.next(response.toObject().userDetails.isPurchaseOrderRequired);
            _this.isSalesOrderRequired$$.next(response.toObject().userDetails.isSalesOrderRequired);
            _this.isMaintenanceOrderRequired$$.next(response.toObject().userDetails.isMaintenanceOrderRequired);
        });
    };
    CreateOrderCustomStops2Component.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    };
    CreateOrderCustomStops2Component.prototype.writeValue = function (obj) {
        if (Array.isArray(obj) && obj.length === this.customStops.length) {
            this.customStops.setValue(obj);
        }
    };
    CreateOrderCustomStops2Component.prototype.registerOnChange = function (fn) {
        this.customStops.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(function (value) {
            fn(value);
        });
    };
    CreateOrderCustomStops2Component.prototype.registerOnTouched = function (fn) { };
    CreateOrderCustomStops2Component.prototype.setDisabledState = function (isDisabled) {
        isDisabled ? this.customStops.disable() : this.customStops.enable();
    };
    CreateOrderCustomStops2Component.prototype.validate = function () {
        return this.validationErrors;
    };
    CreateOrderCustomStops2Component.prototype.registerOnValidatorChange = function (fn) {
        this.customStops.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(function () {
            fn();
        });
    };
    CreateOrderCustomStops2Component.prototype.trackByControl = function (_index, control) {
        return control;
    };
    CreateOrderCustomStops2Component.prototype.trackByName = function (_index, record) {
        return record.name;
    };
    CreateOrderCustomStops2Component.prototype.trackByKey = function (_index, record) {
        return record.key;
    };
    CreateOrderCustomStops2Component.prototype.selectCustomOrderLineSite = function (index, event) {
        this.customStops
            .at(index)
            .get('location')
            .setValue(event.pickupFrom);
    };
    CreateOrderCustomStops2Component.prototype.selectCustomOrderLinePayload = function (index, event) {
        this.customStops
            .at(index)
            .get('payload')
            .setValue(event.payload);
        this.customStops
            .at(index)
            .get('quantity')
            .setValue(event.quantity || 0);
    };
    CreateOrderCustomStops2Component.prototype.selectCustomOrderPurchaseOrderName = function (index, event) {
        this.customStops
            .at(index)
            .get('purchaseOrderName')
            .setValue(event.purchaseOrderName);
    };
    CreateOrderCustomStops2Component.prototype.selectCustomSalesOrderNumber = function (index, event) {
        this.customStops
            .at(index)
            .get('salesOrderNumber')
            .setValue(event.orderNumber);
    };
    CreateOrderCustomStops2Component.prototype.selectCustomMaintenanceOrderNumber = function (index, event) {
        this.customStops
            .at(index)
            .get('maintenanceOrderNumber')
            .setValue(event.maintenanceOrderNumber);
    };
    CreateOrderCustomStops2Component.prototype.removeCustomOrderLink = function (index) {
        if (this.customStops.length > 1) {
            this.customStops.removeAt(index);
        }
    };
    CreateOrderCustomStops2Component.prototype.insertLineBefore = function (index) {
        var fg = this.getNewFormLine();
        fg.get('stopType').setValue(TaskType.TASK_TYPE_PICKUP);
        this.customStops.insert(index, fg);
    };
    CreateOrderCustomStops2Component.prototype.insertLineAfter = function (index) {
        var fg = this.getNewFormLine();
        fg.get('stopType').setValue(TaskType.TASK_TYPE_PICKUP);
        this.customStops.insert(index + 1, fg);
        var previousFg = this.customStops.at(index);
        if (previousFg) {
            var previousSalesOrderNumber = previousFg.get('salesOrderNumber').value;
            if (previousSalesOrderNumber) {
                fg.get('salesOrderNumber').setValue(previousSalesOrderNumber);
            }
        }
    };
    CreateOrderCustomStops2Component.prototype.drop = function (event) {
        moveItemInFormArray(this.customStops, event.previousIndex, event.currentIndex);
    };
    CreateOrderCustomStops2Component.prototype.getNewFormLine = function (site) {
        var _this = this;
        var fg = this.fb.group({
            location: [site, [Validators.required]],
            maintenanceOrderNumber: [null, this.isMaintenanceOrderRequired$$.value ? [Validators.required] : ''],
            payload: [null, [Validators.required]],
            purchaseOrderName: [null, this.isPurchaseOrderRequired$$.value ? [Validators.required] : ''],
            quantity: [null, [Validators.required]],
            salesOrderNumber: [null, this.isSalesOrderRequired$$.value ? [Validators.required] : ''],
            stopType: [null, [Validators.required]],
        });
        fg.get('stopType')
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe(function () {
            _this.prefillPayloadDropoffInfoIfPayloadDropoff(fg);
            if (_this.isSalesOrderRequired$$.value && fg.controls['stopType'].value === 1) {
                fg.controls['salesOrderNumber'].setValidators(Validators.required);
            }
            else {
                fg.controls['salesOrderNumber'].clearValidators();
            }
            if (_this.isMaintenanceOrderRequired$$.value && fg.controls['stopType'].value === 1) {
                fg.controls['maintenanceOrderNumber'].setValidators(Validators.required);
            }
            else {
                fg.controls['maintenanceOrderNumber'].clearValidators();
            }
        });
        fg.get('payload')
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe(function () {
            _this.prefillPayloadQuantityIfPayloadDropoff(fg);
        });
        return fg;
    };
    CreateOrderCustomStops2Component.prototype.setupValidationErrors = function () {
        var _this = this;
        combineLatest([
            this.payloadSummaries$,
            // The two below are just to trigger a recalculation
            this.customStops.valueChanges.pipe(startWith(null), map(function () { return _this.customStopsValue(); })),
            this.customStops.statusChanges,
        ])
            .pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 1), payloadSummaries = _b[0];
            var hasError = false;
            var validationErrors = {};
            if (_this.customStops.invalid && _this.customStops.errors) {
                hasError = true;
                validationErrors.customStops = tslib_1.__assign({}, _this.customStops.errors);
            }
            if (_this.customStops.controls.some(function (control) { return control.invalid; })) {
                hasError = true;
                validationErrors.customStopsLine = 'Please fill in all fields';
            }
            if (payloadSummaries.some(function (summary) { return summary.leftOver !== 0; })) {
                hasError = true;
                validationErrors.invalidTotals = 'All payloads must 0 out';
            }
            if (hasError) {
                return validationErrors;
            }
            return null;
        }), takeUntil(this.destroy$))
            .subscribe(function (errors) {
            _this.validationErrors = errors;
        });
    };
    CreateOrderCustomStops2Component.prototype.prefillPayloadDropoffInfoIfPayloadDropoff = function (formGroup) {
        if (formGroup.get('stopType').value !== TaskType.TASK_TYPE_DROPOFF) {
            return;
        }
        var stopsValue = this.customStopsValue();
        var payloadPickupStops = this.customStops.controls.filter(function (control) {
            var value = control.value;
            return value.stopType === TaskType.TASK_TYPE_PICKUP && !!value.payload;
        });
        var payloadDropoffStops = stopsValue.filter(function (v) { return v.stopType === TaskType.TASK_TYPE_DROPOFF; });
        var firstUnmatchedPickup = payloadPickupStops.find(function (pickupStop) {
            return payloadDropoffStops.every(function (dropoffStop) { return !dropoffStop.payload || dropoffStop.payload.id !== pickupStop.value.payload.id; });
        });
        if (!firstUnmatchedPickup) {
            return;
        }
        if (formGroup.get('payload').value === null) {
            formGroup.get('payload').setValue(firstUnmatchedPickup.get('payload').value);
        }
        if (formGroup.get('quantity').value === null) {
            formGroup.get('quantity').setValue(firstUnmatchedPickup.get('quantity').value);
        }
    };
    CreateOrderCustomStops2Component.prototype.prefillPayloadQuantityIfPayloadDropoff = function (formGroup) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var thisPayload, thisPurchaseOrderName, keyValue, key, payloadSummaries, thisPayloadSummary;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (formGroup.get('stopType').value !== TaskType.TASK_TYPE_DROPOFF) {
                            return [2 /*return*/];
                        }
                        thisPayload = formGroup.get('payload').value;
                        if (!thisPayload) {
                            return [2 /*return*/];
                        }
                        thisPurchaseOrderName = formGroup.get('purchaseOrderName').value;
                        keyValue = this.isPurchaseOrderRequired$$.value || thisPurchaseOrderName ? thisPurchaseOrderName : 'key';
                        key = thisPayload.id + "-" + keyValue;
                        return [4 /*yield*/, this.payloadSummaries$.pipe(take(1)).toPromise()];
                    case 1:
                        payloadSummaries = _a.sent();
                        thisPayloadSummary = payloadSummaries.find(function (summary) { return summary.key === key; });
                        if (thisPayloadSummary && thisPayloadSummary.leftOver > 0) {
                            formGroup.get('quantity').setValue(thisPayloadSummary.leftOver);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return CreateOrderCustomStops2Component;
}());
export { CreateOrderCustomStops2Component };
