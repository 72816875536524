import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, map, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { PayloadService } from 'src/app/lmo/services/payload.service';
import { SelectPayloadQuantityComponent } from '~common/select-payload-quantity/select-payload-quantity.component';
import { SelectPayloadTypeComponent } from '~common/select-payload-type/select-payload-type.component';
import { SelectPayloadUnitsComponent } from '~common/select-payload-units/select-payload-units.component';
import { SmartDropdownComponent } from '~common/smart-dropdown/smart-dropdown.component';
import { CreatePayloadRequest } from '~proto/payload/payload_api_pb';
import { Payload, PayloadGroup, PayloadType, Unit } from '~proto/payload/payload_pb';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { trackById } from '~utilities/trackById';
import { CreateOrderPayloadSelectorConfirmTrackingComponent } from '../create-order-payload-selector-confirm-tracking/create-order-payload-selector-confirm-tracking.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-create-order-payload-selector',
  styleUrls: ['./create-order-payload-selector.component.scss'],
  templateUrl: './create-order-payload-selector.component.html',
})
export class CreateOrderPayloadSelectorComponent implements OnInit {
  public pinnedAndRecentPayloads$$ = new BehaviorSubject<PayloadGroup.AsObject[]>([]);
  public searching$: Observable<boolean>;
  private topResult: Payload.AsObject;
  public currentSitesPayload$: Observable<PayloadGroup.AsObject[]>;
  @Output() public selected = new EventEmitter<{ payload: Payload.AsObject; quantity: number }>();
  @Input() public payload: Payload.AsObject;
  @Input() public invalid = false;
  @Input() public tab = '';
  @Input() public openAutomatically = false;
  @Input() public quantityPrompt = true;
  @Input() public allowPinning = false;
  @Input() public allowedPayloadTypes = [];
  @Input() public set payloadGroups(groups: PayloadGroup.AsObject[]) {
    this.pinnedAndRecentPayloads$$.next(toPinnedAndOtherGroups(groups));
  }
  @ViewChild('smartDropdown', { static: true }) private smartDropdown: SmartDropdownComponent;
  public trackById = trackById;
  public textChanges$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public canAddNew$: Observable<boolean>;

  constructor(private payloadService: PayloadService, private matDialog: MatDialog, private snackBar: MatSnackBar) {}

  public ngOnInit() {
    this.searching$ = this.payloadService.searching$;
    const searchResults$ = this.textChanges$$.pipe(
      debounceTime(200),
      switchMap((searchText) => {
        if (searchText && searchText.length >= 2) {
          return this.payloadService.searchPayloads$(searchText);
        }
        return of([] as Payload.AsObject[]);
      }),
      withLatestFrom(this.pinnedAndRecentPayloads$$),
      map(([searchResults, payloadGroups]) => {
        if (!payloadGroups) {
          return searchResults;
        }
        const payloads = payloadGroups.reduce((arr, group) => [...arr, ...group.payloadsList], []);
        const payloadMap = idArrayToRecord(payloads);
        return searchResults.filter((payload) => {
          let passed = true;
          if (this.allowedPayloadTypes.length > 0) {
            passed = passed && this.allowedPayloadTypes.includes(payload.type.id)
          }
          return passed && !payloadMap[payload.id]
        });
      }),
    );

    this.currentSitesPayload$ = combineLatest([
      this.pinnedAndRecentPayloads$$,
      this.textChanges$$.pipe(
        debounceTime(100),
        startWith(''),
      ),
      searchResults$,
    ]).pipe(
      map(([items, text, searchResults]) => {
        if (!items) {
          return [];
        }
        if (!text || text === '') {
          const filteredItems: PayloadGroup.AsObject[] = items.filter((item) => item.payloadsList.length > 0);
          this.topResult =
            filteredItems.length && filteredItems[0].payloadsList.length ? filteredItems[0].payloadsList[0] : null;
          return filteredItems;
        }
        const cleanedItems = items.map((item) => ({
          ...item,
          payloadsList: item.payloadsList.filter((entry) =>
            entry.name.toLocaleLowerCase().includes(text.toLocaleLowerCase()),
          ),
        }));
        const mergedGroups = [
          {
            name: 'search results',
            payloadsList: searchResults,
          },
          ...cleanedItems,
        ];
        const filtered: PayloadGroup.AsObject[] = mergedGroups.filter((item) => item.payloadsList.length > 0);
        if (filtered.length && filtered[0].payloadsList.length) {
          this.topResult = filtered[0].payloadsList[0];
        } else {
          this.topResult = null;
        }
        return filtered;
      }),
    );

    this.canAddNew$ = combineLatest([this.textChanges$$.pipe(startWith('')), this.pinnedAndRecentPayloads$$]).pipe(
      map(([textChange, groups]) => {
        if (!textChange || textChange === '') {
          return false;
        }
        const asLower = textChange.toLocaleLowerCase();
        return groups.every((group) =>
          group.payloadsList.every((payload) => payload.name.toLocaleLowerCase() !== asLower),
        );
      }),
    );
  }

  public inputTextChange(event: string) {
    this.textChanges$$.next(event.trim());
  }

  public togglePin(event: Event, payloadId: number, isFavorited: boolean) {
    event.stopPropagation();
    this.payloadService.updatePayloadFavorites(payloadId, isFavorited);
  }

  public selectPayload(payload: Payload.AsObject, isNew = false) {
    if (this.quantityPrompt) {
      const dialog = this.matDialog.open(SelectPayloadQuantityComponent);
      dialog.componentInstance.payload = payload;
      dialog.afterClosed().subscribe(async (quantity) => {
        if (isNew) {
          const trackPayloadOnSite = this.matDialog.open(CreateOrderPayloadSelectorConfirmTrackingComponent);
          trackPayloadOnSite.componentInstance.payload = payload;
          await trackPayloadOnSite.afterClosed().toPromise();

          this.showCreatedSnackbar(payload);
        }

        this.selected.emit({ payload, quantity });
        this.smartDropdown.close();
      });
    } else {
      if (isNew) {
        this.showCreatedSnackbar(payload);
      }
      this.selected.emit({ payload, quantity: 1 });
      this.smartDropdown.close();
    }
  }

  public async createNewPayload() {
    const createPayloadRequest = new CreatePayloadRequest();
    createPayloadRequest.setName(this.textChanges$$.value);

    const unitsDialog = this.matDialog.open(SelectPayloadUnitsComponent);
    unitsDialog.componentInstance.payloadName = createPayloadRequest.getName();
    const unit: Unit.AsObject = await unitsDialog.afterClosed().toPromise();
    if (!unit) {
      return;
    }

    createPayloadRequest.setUnitId(unit.id);

    const payloadTypeDialog = this.matDialog.open(SelectPayloadTypeComponent);
    payloadTypeDialog.componentInstance.payloadName = createPayloadRequest.getName();
    payloadTypeDialog.componentInstance.allowedPayloadTypes = this.allowedPayloadTypes;
    const payloadType: PayloadType.AsObject = await payloadTypeDialog.afterClosed().toPromise();
    if (!payloadType) {
      return;
    }
    createPayloadRequest.setPayloadTypeId(payloadType.id);

    this.payloadService.createPayload$(createPayloadRequest).subscribe(async (payload) => {
      if (!payload) {
        return;
      }

      this.selectPayload(payload, true);
    });
  }

  public enterKeyPressed() {
    if (this.topResult) {
      this.selectPayload(this.topResult);
    } else {
      this.createNewPayload();
    }
  }

  private showCreatedSnackbar(payload: Payload.AsObject) {
    const titleString = `${payload.name} has been created!`;
    const actionString = `Add Payload Details`;

    const snack = this.snackBar.open(titleString, actionString, { duration: 10000 });
    snack.onAction().subscribe(() => {
      const link = `/lmo/payloads/${this.payload.id}/edit`;
      window.open(link, '_blank');
    });
  }
}

function toPinnedAndOtherGroups(payloadGroups: PayloadGroup.AsObject[] = []): PayloadGroup.AsObject[] {
  if (payloadGroups === null || payloadGroups.length === 0) {
    return [];
  }
  const allPayloads = payloadGroups.reduce((arr: Payload.AsObject[], group) => [...arr, ...group.payloadsList], []);
  const pinned: Payload.AsObject[] = [];
  const recent: Payload.AsObject[] = [];
  allPayloads.forEach((payload) => {
    if (payload.favorited) {
      pinned.push(payload);
    } else {
      recent.push(payload);
    }
  });
  return [
    {
      name: 'Pinned',
      payloadsList: pinned,
    },
    {
      name: 'Recent',
      payloadsList: recent,
    },
  ] as PayloadGroup.AsObject[];
}
