import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ChoiceDialogComponent } from '~common/choice-dialog/choice-dialog.component';
import { AllowedAccountTypesDirective } from '~common/directives/allowed-account-types.directive';
import { OrderMapControlsComponent } from '~common/order-map-controls/order-map-controls.component';
import { PayloadsIconComponent } from '~common/payloads-icon/payloads-icon.component';
import { MaterialModule } from './../material-module/material.module';
import { BrowserCheckComponent } from './browser-check/browser-check.component';
import {
  ButtonComponent,
  CtButtonDirective,
  CtDangerButtonDirective,
  CtDarkButtonDirective,
  CtGrayButtonDirective,
  CtSecondaryButtonDirective,
} from './button/button.component';
import { ChatBotComponent } from './chat-bot/chat-bot.component';
import { ConfirmCancelComponent } from './confirm-cancel/confirm-cancel.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmSaveDialogueComponent } from './confirm-save-dialogue/confirm-save-dialogue.component';
import { CustomFieldComponent } from './custom-field/custom-field.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { AutomatedSelectorDirective } from './directives/automated-selector.directive';
import { FeatureFlagDirective } from './directives/feature-flag.directive';
import { HotkeyDirective } from './directives/hotkey.directive';
import { InputDirective } from './directives/input.directive';
import { LongEscapeDirective } from './directives/long-escape.directive';
import { CurrencyInputDirective } from './directives/money.directive';
import { NumberDirective } from './directives/number.directive';
import { PageTitleDirective } from './directives/page-title.directive';
import { SmartDropdownContainerDirective } from './directives/smart-dropdown-container.directive';
import { FilePreviewModalComponent } from './file-preview-modal/file-preview-modal.component';
import { HeaderComponent } from './header/header.component';
import { HotkeyComponent } from './hotkey/hotkey.component';
import { InputComponent } from './input/input.component';
import { LabeledButtonComponent } from './labeled-button/labeled-button.component';
import { LongEscapeTipComponent } from './long-escape-tip/long-escape-tip.component';
import { LongEscapeComponent } from './long-escape/long-escape.component';
import { MapComponent } from './map/map.component';
import { MiniModalComponent } from './mini-modal/mini-modal.component';
import { NetworkActiveButtonComponent } from './network-active-button/network-active-button.component';
import { NotificationPromptComponent } from './notification-prompt/notification-prompt.component';
import { OrdersMapComponent } from './orders-map/orders-map.component';
import { PayloadIconComponent } from './payload-icon/payload-icon.component';
import { pipes } from './pipes';
import { CountdownPipe } from './pipes/countdown.pipe';
import { FeatureFlagPipe } from './pipes/feature-flag.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { UniqueOrderPipe } from './pipes/unique-order.pipe';
import { SelectBusinessHoursComponent } from './select-business-hours/select-business-hours.component';
import { SelectDateRangeComponent } from './select-date-range/select-date-range.component';
import { SelectPayloadQuantityComponent } from './select-payload-quantity/select-payload-quantity.component';
import { SelectPayloadTypeComponent } from './select-payload-type/select-payload-type.component';
import { SelectPayloadUnitsComponent } from './select-payload-units/select-payload-units.component';
import { ShowLocationDialogComponent } from './show-location-dialog/show-location-dialog.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { SmartDropdownComponent } from './smart-dropdown/smart-dropdown.component';
import { StageUrlIndicatorComponent } from './stage-url-indicator/stage-url-indicator.component';
import { SubtaskComponent } from './subtask/subtask.component';
import { VersioningComponent } from './versioning/versioning.component';

@NgModule({
  declarations: [
    ButtonComponent,
    CtButtonDirective,
    CtDangerButtonDirective,
    CtSecondaryButtonDirective,
    CtDarkButtonDirective,
    CtGrayButtonDirective,
    HeaderComponent,
    SideNavComponent,
    InputDirective,
    LabeledButtonComponent,
    MiniModalComponent,
    SelectPayloadUnitsComponent,
    SmartDropdownComponent,
    SelectPayloadQuantityComponent,
    ...pipes,
    NumberDirective,
    InputComponent,
    NetworkActiveButtonComponent,
    SelectPayloadTypeComponent,
    SmartDropdownContainerDirective,
    OrdersMapComponent,
    OrderMapControlsComponent,
    ConfirmCancelComponent,
    FeatureFlagPipe,
    FeatureFlagDirective,
    VersioningComponent,
    BrowserCheckComponent,
    SelectDateRangeComponent,
    PageTitleDirective,
    AutoFocusDirective,
    AllowedAccountTypesDirective,
    HotkeyDirective,
    HotkeyComponent,
    LongEscapeDirective,
    LongEscapeComponent,
    LongEscapeTipComponent,
    NotificationPromptComponent,
    CurrencyInputDirective,
    ChatBotComponent,
    ConfirmSaveDialogueComponent,
    FilePreviewModalComponent,
    SafePipe,
    StageUrlIndicatorComponent,
    SafeHtmlPipe,
    SelectBusinessHoursComponent,
    CustomFieldComponent,
    UniqueOrderPipe,
    AutomatedSelectorDirective,
    PayloadIconComponent,
    PayloadsIconComponent,
    MapComponent,
    ShowLocationDialogComponent,
    ConfirmDialogComponent,
    SubtaskComponent,
    ChoiceDialogComponent,
  ],
  entryComponents: [
    SelectPayloadUnitsComponent,
    SelectPayloadQuantityComponent,
    SelectPayloadTypeComponent,
    SelectDateRangeComponent,
    ConfirmCancelComponent,
    HotkeyComponent,
    LongEscapeComponent,
    LongEscapeTipComponent,
    NotificationPromptComponent,
    ConfirmSaveDialogueComponent,
    FilePreviewModalComponent,
    SelectBusinessHoursComponent,
    ShowLocationDialogComponent,
    ConfirmDialogComponent,
    ChoiceDialogComponent,
  ],
  exports: [
    ButtonComponent,
    CtButtonDirective,
    CtDangerButtonDirective,
    CtSecondaryButtonDirective,
    CtDarkButtonDirective,
    CtGrayButtonDirective,
    HeaderComponent,
    SideNavComponent,
    InputDirective,
    LabeledButtonComponent,
    SelectPayloadUnitsComponent,
    SmartDropdownComponent,
    SelectPayloadQuantityComponent,
    NumberDirective,
    InputComponent,
    NetworkActiveButtonComponent,
    SelectPayloadTypeComponent,
    SmartDropdownContainerDirective,
    OrdersMapComponent,
    OrderMapControlsComponent,
    MiniModalComponent,
    FeatureFlagPipe,
    FeatureFlagDirective,
    VersioningComponent,
    BrowserCheckComponent,
    StageUrlIndicatorComponent,
    SelectDateRangeComponent,
    PageTitleDirective,
    AutoFocusDirective,
    AllowedAccountTypesDirective,
    HotkeyDirective,
    LongEscapeDirective,
    NotificationPromptComponent,
    CurrencyInputDirective,
    ...pipes,
    ChatBotComponent,
    CustomFieldComponent,
    UniqueOrderPipe,
    AutomatedSelectorDirective,
    PayloadIconComponent,
    MapComponent,
    PayloadsIconComponent,
    ShowLocationDialogComponent,
    SubtaskComponent,
    ChoiceDialogComponent,
  ],
  imports: [MaterialModule, CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
  providers: [CountdownPipe],
})
export class CommonComponentsModule {}
