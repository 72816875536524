import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-lmo-trucking-contracts',
  styleUrls: ['./lmo-trucking-contracts.component.scss'],
  templateUrl: './lmo-trucking-contracts.component.html',
})
export class LmoTruckingContractsComponent implements OnInit {
  constructor() {}

  public ngOnInit() {}
}
