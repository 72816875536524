import { BusinessLine } from '~proto/businessline/businessline_pb';
import { Driver } from '~proto/user/user_pb';
import { UserV2 } from './../proto/user/user/user_pb.d';

export function idArrayToRecord<T extends { id: number | string }>(tArray: T[]): Record<string, T> {
  if (!tArray) {
    return {};
  }
  return tArray.reduce((record: Record<string, T>, t: T) => {
    record[t.id] = t;
    return record;
  }, {});
}

export function uidArrayToRecord<T extends Driver.AsObject>(tArray: T[]): Record<string, T> {
  if (!tArray) {
    return {};
  }
  return tArray.reduce((record: Record<string, T>, t: T) => {
    record[t.user.firebaseUid] = t;
    return record;
  }, {});
}

export function userIdArrayToRecord<T extends UserV2.AsObject>(tArray: T[]): Record<string, T> {
  if (!tArray) {
    return {};
  }
  return tArray.reduce((record: Record<string, T>, t: T) => {
    record[t.authId] = t;
    return record;
  }, {});
}

export function businessLineIdArrayToRecord<T extends BusinessLine.AsObject>(tArray: T[]): Record<string, T> {
  if (!tArray) {
    return {};
  }
  return tArray.reduce((record: Record<string, T>, t: T) => {
    record[t.id] = t;
    return record;
  }, {});
}
