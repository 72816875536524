import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { fuse } from '~utilities/fuse';
import { trackById } from '~utilities/trackById';
var searchOptions = {
    distance: 100,
    keys: ['name'],
    location: 0,
    maxPatternLength: 16,
    minMatchCharLength: 1,
    shouldSort: true,
    threshold: 0.2,
};
var LmoPayloadManagementComponent = /** @class */ (function () {
    function LmoPayloadManagementComponent(payloadService) {
        this.payloadService = payloadService;
        this.payloadSearch = new FormControl();
        this.trackById = trackById;
    }
    LmoPayloadManagementComponent.prototype.ngOnInit = function () {
        this.payloads$ = combineLatest([
            this.payloadService.allPayloads$.pipe(map(fuse(searchOptions))),
            this.payloadSearch.valueChanges.pipe(startWith(''), debounceTime(200)),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), fused = _b[0], searchTerm = _b[1];
            var finalPayloads;
            if (!searchTerm || searchTerm === '') {
                finalPayloads = fused.data;
            }
            else {
                finalPayloads = fused.fuse.search(searchTerm);
            }
            return finalPayloads.sort(function (a, b) { return a.name.localeCompare(b.name); });
        }));
    };
    return LmoPayloadManagementComponent;
}());
export { LmoPayloadManagementComponent };
