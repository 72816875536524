import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '~environments/environment';

@Directive({
  selector: '[ctOnlyEnv]',
})
export class OnlyEnvDirective {
  private hasView = false;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  @Input() set ctOnlyEnv(environments: string[]) {
    const isEnv = environments.includes(environment.environment);
    if (isEnv && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!isEnv && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
