import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CanUpdateBidderInformationRequest, updateBidderInformationRequest, } from '~proto/marketplace/rfq_api_pb';
var MarketplaceEmailUpdateProfileComponent = /** @class */ (function () {
    function MarketplaceEmailUpdateProfileComponent(fb, route, marketplaceService) {
        this.fb = fb;
        this.route = route;
        this.marketplaceService = marketplaceService;
        this.networkActive$$ = new BehaviorSubject(false);
        this.placeholderString$ = new BehaviorSubject('');
    }
    MarketplaceEmailUpdateProfileComponent.prototype.ngOnInit = function () {
        this.bidderId = +this.route.snapshot.params['bidderId'];
        this.token = this.route.snapshot.params['token'];
        this.checkIfCanUpdate();
    };
    MarketplaceEmailUpdateProfileComponent.prototype.buildForm = function () {
        this.profileForm = this.fb.group({
            account: [this.bidder.account, [Validators.required]],
            email: [{ value: this.bidder.email, disabled: true }, [Validators.required]],
            name: [this.bidder.name, [Validators.required]],
        });
    };
    MarketplaceEmailUpdateProfileComponent.prototype.setPlaceholderString = function (value) {
        this.placeholderString$.next(value);
    };
    MarketplaceEmailUpdateProfileComponent.prototype.checkIfCanUpdate = function () {
        var _this = this;
        var canUpdateProfileRequest = new CanUpdateBidderInformationRequest();
        canUpdateProfileRequest.setBidderId(this.bidderId);
        canUpdateProfileRequest.setToken(this.token);
        this.marketplaceService.canUpdateProfile(canUpdateProfileRequest).subscribe(function (resp) {
            _this.bidder = tslib_1.__assign({}, resp.getBidder().toObject());
            _this.buildForm();
        }, function (err) {
            _this.setPlaceholderString('Bidder Not Found');
        });
    };
    MarketplaceEmailUpdateProfileComponent.prototype.updateBidder = function () {
        var _this = this;
        if (this.profileForm.invalid) {
            this.profileForm.markAllAsTouched();
        }
        else {
            var updateBidderRequest = new updateBidderInformationRequest();
            updateBidderRequest.setAccount(this.profileForm.controls['account'].value);
            updateBidderRequest.setBidderId(this.bidder.id);
            updateBidderRequest.setName(this.profileForm.controls['name'].value);
            updateBidderRequest.setToken(this.token);
            this.marketplaceService.updateBidderInformation(updateBidderRequest).subscribe(function (resp) {
                if (resp.getUpdated()) {
                    _this.setPlaceholderString('YOUR PROFILE DETAILS UPDATED SUCCESSFULLY');
                }
                else {
                    _this.setPlaceholderString('UPDATING YOUR PROFILE INFORMATION FAILED FOR SOME REASON. PLEASE TRY AGAIN LATER');
                }
                _this.networkActive$$.next(false);
            }, function (err) {
                _this.setPlaceholderString('UPDATING YOUR PROFILE INFORMATION FAILED FOR SOME REASON. PLEASE TRY AGAIN LATER');
                _this.networkActive$$.next(false);
            });
        }
    };
    return MarketplaceEmailUpdateProfileComponent;
}());
export { MarketplaceEmailUpdateProfileComponent };
