import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { RoleService } from '~services/role.service';

@Directive({
  selector: '[ctAllowedAccountRoles]',
})
export class AllowedAccountTypesDirective implements OnDestroy {
  private allowedAccountTypes$$: BehaviorSubject<Record<number, boolean>> = new BehaviorSubject({});
  private hasView = false;
  private readonly subscription: Subscription;

  @Input() set ctAllowedAccountRoles(accountTypes: number[]) {
    const allowedAccountTypes: Record<number, boolean> = {};
    accountTypes.forEach((accountType) => {
      allowedAccountTypes[accountType] = true;
    });
    this.allowedAccountTypes$$.next(allowedAccountTypes);
  }

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private roleService: RoleService,
  ) {
    this.subscription = this.allowedAccountTypes$$.subscribe((record) => {
      this.roleService.userRole$.subscribe((accountRoleId) => {
        if (!accountRoleId) {
          this.clear();
          return;
        }
        if (record[accountRoleId.valueOf()] === true) {
          this.show();
        } else {
          this.clear();
        }
      });
    });
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private clear() {
    if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  private show() {
    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
  }
}
