import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { filter, finalize, map, take } from 'rxjs/operators';
import { ConfirmCancelComponent } from '~common/confirm-cancel/confirm-cancel.component';
import { TaskStatus, TaskType } from '~proto/types/types_pb';
import { getPayloadDetailsFromOrder } from '~utilities/groupOrders';
import { trackById } from '~utilities/trackById';
import { OrderConfirmCompleteComponent } from '../order-confirm-complete/order-confirm-complete.component';
var OrderDetailInProgressComponent = /** @class */ (function () {
    function OrderDetailInProgressComponent(orderService, router, siteService, matDialog) {
        this.orderService = orderService;
        this.router = router;
        this.siteService = siteService;
        this.matDialog = matDialog;
        this.networkActive$ = new BehaviorSubject(false);
        this.taskType = TaskType;
        this.taskStatus = TaskStatus;
        this.trackById = trackById;
        this.driverCertifications = [];
    }
    OrderDetailInProgressComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentOrder$ = this.orderService.currentOrder$;
        this.payloadDetails$ = this.currentOrder$.pipe(map(getPayloadDetailsFromOrder));
        this.currentOrder$.pipe(filter(function (currentOrder) { return !!currentOrder; })).subscribe(function (currentOrder) {
            var inProgressTasks = currentOrder.tasksList.filter(function (task) { return task.status === TaskStatus.TASK_STATUS_IN_PROGRESS; });
            _this.driverCertifications = currentOrder.driverCertificationsList.map(function (cert) { return cert.name; });
            if (inProgressTasks.length) {
                _this.inProgressTask = inProgressTasks[0];
            }
            else {
                _this.inProgressTask = currentOrder.tasksList.filter(function (task) { return task.sequence === 1; })[0];
            }
            _this.inProgressTaskEta =
                _this.inProgressTask.stats && _this.inProgressTask.stats.driverEta
                    ? moment()
                        .add(_this.inProgressTask.stats.driverEta, 'minutes')
                        .unix() * 1000
                    : null;
        });
    };
    OrderDetailInProgressComponent.prototype.confirmCancel = function (orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cancelDialog, confirmed;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cancelDialog = this.matDialog.open(ConfirmCancelComponent);
                        cancelDialog.componentInstance.name = "#" + orderId;
                        return [4 /*yield*/, cancelDialog.afterClosed().toPromise()];
                    case 1:
                        confirmed = _a.sent();
                        if (!confirmed) {
                            return [2 /*return*/];
                        }
                        this.cancelOrder(orderId);
                        return [2 /*return*/];
                }
            });
        });
    };
    OrderDetailInProgressComponent.prototype.cancelOrder = function (orderId) {
        var _this = this;
        this.networkActive$.next(true);
        this.siteService.currentSite$.pipe(take(1)).subscribe(function (site) {
            _this.orderService
                .cancelInProgressOrder$(orderId)
                .pipe(finalize(function () { return _this.networkActive$.next(false); }))
                .subscribe(function () {
                _this.router.navigate(['/', 'lmo', 'jobs', site.id, 'orders', 'in-progress', 'list']);
            });
        });
    };
    OrderDetailInProgressComponent.prototype.taskClickEvent = function (isNotLastTask, taskId, orderId) {
        if (isNotLastTask) {
            this.manuallyCompleteTask(taskId);
        }
        else {
            this.confirmComplete(orderId);
        }
    };
    OrderDetailInProgressComponent.prototype.confirmComplete = function (orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var confirmDialog, confirmed;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        confirmDialog = this.matDialog.open(OrderConfirmCompleteComponent);
                        return [4 /*yield*/, confirmDialog.afterClosed().toPromise()];
                    case 1:
                        confirmed = _a.sent();
                        if (!confirmed) {
                            return [2 /*return*/];
                        }
                        this.completeOrder(orderId);
                        return [2 /*return*/];
                }
            });
        });
    };
    OrderDetailInProgressComponent.prototype.completeOrder = function (orderId) {
        var _this = this;
        this.networkActive$.next(true);
        this.siteService.currentSite$.pipe(take(1)).subscribe(function (site) {
            _this.orderService
                .completeOrder$(orderId)
                .pipe(finalize(function () { return _this.networkActive$.next(false); }))
                .subscribe(function () {
                _this.router.navigate(['/', 'lmo', 'jobs', site.id, 'orders', 'in-progress', 'list']);
            });
        });
    };
    OrderDetailInProgressComponent.prototype.manuallyCompleteTask = function (taskId) {
        var _this = this;
        this.networkActive$.next(true);
        this.orderService
            .manuallyCompleteTask$(taskId)
            .pipe(finalize(function () { return _this.networkActive$.next(false); }))
            .subscribe(function () { });
    };
    OrderDetailInProgressComponent.prototype.getTaskStatus = function (type, status) {
        switch (type) {
            case this.taskType.TASK_TYPE_PICKUP:
                if (status === this.taskStatus.TASK_STATUS_COMPLETED) {
                    return 'Picked Up';
                }
                return 'Pick Up';
            case this.taskType.TASK_TYPE_DROPOFF:
                if (status === this.taskStatus.TASK_STATUS_COMPLETED) {
                    return 'Dropped Off';
                }
                return 'Drop-Off';
            case this.taskType.TASK_TYPE_RETURN_MATERIALS:
                return 'Return Trailer';
            default:
                return '';
        }
    };
    OrderDetailInProgressComponent.prototype.getTaskEta = function (task) {
        var taskEta = task.stats && task.stats.driverEta
            ? moment()
                .add(task.stats.driverEta, 'minutes')
                .unix() * 1000
            : null;
        return taskEta;
    };
    return OrderDetailInProgressComponent;
}());
export { OrderDetailInProgressComponent };
