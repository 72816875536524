import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';
import { UpdateEmailUserV2Request } from '~proto/user/user_api_pb';
import { trackById } from '~utilities/trackById';
var LmoUserManagementEditComponent = /** @class */ (function () {
    function LmoUserManagementEditComponent(fb, userService, router, snackbar, routerState, businessLineService, roleService) {
        this.fb = fb;
        this.userService = userService;
        this.router = router;
        this.snackbar = snackbar;
        this.routerState = routerState;
        this.businessLineService = businessLineService;
        this.roleService = roleService;
        this.networkActive$ = new BehaviorSubject(false);
        this.trackById = trackById;
        this.isBusinessLineEnabled$$ = new BehaviorSubject(false);
        this.roles = {
            1: 'Admin',
            2: 'Default',
            4: 'View only',
        };
        this.setUserRoles = [
            { id: 1, name: 'Admin' },
            { id: 2, name: 'Default' },
            { id: 4, name: 'View Only' },
        ];
        this.setUserPermissions = {
            1: 'Can Approve Ticket',
            2: 'Can Approve Invoice',
            4: 'Can Create Cost Center',
            5: 'Can Modify Users',
        };
        this.userRoles = Object.values(this.roles);
        this.errorMessages = {
            hasRestrictedCharacter: 'only dash, dot, plus, underscore are allowed',
            lessThanTwoCharacter: 'minimum 2 character are required',
            nameRequired: '*required',
        };
    }
    Object.defineProperty(LmoUserManagementEditComponent.prototype, "nameInputValueCheck", {
        get: function () {
            var inputVal = this.formGroup.get('name').value || '';
            var inputLength = inputVal.length;
            var isRequired = inputLength === 0 ? this.errorMessages.nameRequired : '_';
            var errorMsg = inputLength > 0 && inputLength < 2 ? this.errorMessages.lessThanTwoCharacter : isRequired;
            return errorMsg;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LmoUserManagementEditComponent.prototype, "emailValueCheck", {
        get: function () {
            var inputVal = this.formGroup.get('email').value || '';
            var inputLength = inputVal.length;
            var isRequired = inputLength === 0 ? this.errorMessages.nameRequired : '_';
            return isRequired;
        },
        enumerable: true,
        configurable: true
    });
    LmoUserManagementEditComponent.prototype.formValue = function () {
        return this.formGroup.value;
    };
    LmoUserManagementEditComponent.prototype.ngOnInit = function () {
        this.setUser().then();
        this.backUrl$ = this.routerState.backUrlOr$(['../../']);
        this.formGroup = this.fb.group({
            businessLine: [[]],
            email: ['', [Validators.required]],
            name: ['', [Validators.required]],
            permission: [[]],
            uRole: [[]],
        });
        this.checkIfBusinessLineEnabled();
        if (this.isBusinessLineEnabled$$.value) {
            this.businessLines$ = this.businessLineService.businessLines$;
        }
        this.userPermissions$ = this.userService.userPermissions$;
    };
    LmoUserManagementEditComponent.prototype.setUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.userService.currentUser$
                                .pipe(filter(function (user) { return !!user; }), take(1))
                                .toPromise()];
                    case 1:
                        _a.user = _b.sent();
                        if (this.isBusinessLineEnabled$$.value) {
                            this.businessLineService
                                .businessLinesAttachedToUser$(this.user.authId)
                                .pipe(filter(function (businessLine) { return !!businessLine; }))
                                .subscribe(function (businessLine) {
                                _this.formGroup.patchValue({
                                    businessLine: _this.getBusinessLinesAttachedToUserIds(businessLine),
                                });
                            });
                        }
                        this.formGroup.patchValue({
                            email: this.user.email,
                            name: this.user.name,
                            permission: this.user.permissionsList,
                            uRole: this.getUserRole(this.user.role),
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    LmoUserManagementEditComponent.prototype.getUserRole = function (role) {
        var userRoleObject = this.setUserRoles.filter(function (obj) {
            return obj.id === role;
        });
        return userRoleObject[0].id;
    };
    LmoUserManagementEditComponent.prototype.getBusinessLinesAttachedToUserIds = function (businessLines) {
        return businessLines.map(function (businessLine) { return businessLine.id; });
    };
    LmoUserManagementEditComponent.prototype.updateUser = function () {
        var _this = this;
        this.formGroup.markAllAsTouched();
        if (this.formGroup.invalid) {
            return;
        }
        var formValue = this.formValue();
        this.networkActive$.next(true);
        this.updateUserDetails$(formValue)
            .pipe(finalize(function () {
            _this.networkActive$.next(false);
        }))
            .subscribe(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var backUrl;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.snackbar.open(formValue.name + " updated!", null, {
                            duration: 2000,
                        });
                        return [4 /*yield*/, this.backUrl$.pipe(take(1)).toPromise()];
                    case 1:
                        backUrl = _a.sent();
                        return [4 /*yield*/, this.router.navigate(backUrl)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    LmoUserManagementEditComponent.prototype.checkIfBusinessLineEnabled = function () {
        var _this = this;
        this.roleService.isBusinessLineEnabled$.subscribe(function (isBusinessLineEnabled) {
            _this.isBusinessLineEnabled$$.next(isBusinessLineEnabled);
        });
    };
    LmoUserManagementEditComponent.prototype.updateUserDetails$ = function (formValue) {
        if (formValue.uRole !== 1) {
            formValue.permission = [];
        }
        var updateEmailUserV2Request = new UpdateEmailUserV2Request();
        updateEmailUserV2Request.setName(formValue.name);
        updateEmailUserV2Request.setEmail(formValue.email.toLowerCase());
        updateEmailUserV2Request.setRole(formValue.uRole);
        updateEmailUserV2Request.setAuthId(this.user.authId);
        updateEmailUserV2Request.setBusinesslineidsList(formValue.businessLine);
        updateEmailUserV2Request.setPermissionsList(formValue.permission);
        return this.userService.updateUserDetails$(updateEmailUserV2Request);
    };
    return LmoUserManagementEditComponent;
}());
export { LmoUserManagementEditComponent };
