import { ElementRef, Renderer2 } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { distinctUntilChanged, pluck, startWith, tap } from 'rxjs/operators';
var onlyNumbers = /[^0-9.]/g;
var NumberDirective = /** @class */ (function () {
    function NumberDirective(_renderer, _elementRef) {
        var _this = this;
        this._renderer = _renderer;
        this._elementRef = _elementRef;
        this.text$$ = new BehaviorSubject(null);
        this.type = _elementRef.nativeElement.type;
        if (this.type !== 'text') {
            console.warn('number directive text input');
        }
        fromEvent(this._elementRef.nativeElement, 'input')
            .pipe(tap(function (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();
        }), pluck('target', 'value'), startWith(''), distinctUntilChanged())
            .subscribe(function (value) {
            var inputOnlyNumberString = value.replace(onlyNumbers, '');
            var inputOnlyNumber = inputOnlyNumberString.endsWith('.')
                ? inputOnlyNumberString
                : parseFloat(inputOnlyNumberString) || 0;
            _this.writeValue(inputOnlyNumber);
        });
    }
    Object.defineProperty(NumberDirective.prototype, "text$", {
        get: function () {
            return this.text$$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    NumberDirective.prototype.writeValue = function (input) {
        if (typeof input === 'string') {
            if (this.type === 'text') {
                var parsedWithoutDecimal = parseInt(input.substring(0, input.length - 1), 10);
                var formattedField = parsedWithoutDecimal.toLocaleString() + ".";
                this._renderer.setProperty(this._elementRef.nativeElement, 'value', formattedField);
                this.text$$.next(formattedField);
            }
            else {
                this._renderer.setProperty(this._elementRef.nativeElement, 'value', input);
                this.text$$.next("" + input);
            }
        }
        else if (!isNaN(input) && input !== null) {
            if (this.type === 'text') {
                var formattedField = input.toLocaleString('lookup', { maximumFractionDigits: 20 });
                this._renderer.setProperty(this._elementRef.nativeElement, 'value', formattedField);
                this.text$$.next(formattedField);
            }
            else {
                this._renderer.setProperty(this._elementRef.nativeElement, 'value', input);
                this.text$$.next("" + input);
            }
        }
        else {
            this.text$$.next(null);
            this._renderer.setProperty(this._elementRef.nativeElement, 'value', null);
        }
        if (this.onChange) {
            if (typeof input === 'string') {
                this.onChange(null);
            }
            else {
                this.onChange(input);
            }
        }
    };
    NumberDirective.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    NumberDirective.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    NumberDirective.prototype.setDisabledState = function (isDisabled) {
        this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
    };
    return NumberDirective;
}());
export { NumberDirective };
