import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { OrdersService } from 'src/app/lmo/services/orders.service';
import { GroupedOrders, PayloadDetails } from 'src/app/models/type-with-order.model';
import { orderPluralMapping } from '~plural/orders';
import { Site } from '~proto/site/site_pb';
import { OrderStatus, TaskStatus } from '~proto/types/types_pb';
import { convertMappableOrderToOrderCard, groupOrdersByTaskType } from '~utilities/groupOrders';
import { trackById } from '~utilities/trackById';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-orders-in-progress-view',
  styleUrls: ['./orders-in-progress-view.component.scss'],
  templateUrl: './orders-in-progress-view.component.html',
})
export class OrdersInProgressViewComponent implements OnInit {
  public ordersPlural = orderPluralMapping;
  public mappableOrders$: Observable<GroupedOrders<string>[]>;
  public currentSite$: Observable<Site.AsObject>;
  public trackById = trackById;
  public taskDelDate = new Date();

  constructor(private ordersService: OrdersService, private siteService: JobSitesService) {}

  public ngOnInit() {
    this.mappableOrders$ = this.ordersService.mappableInProgressOrders$.pipe(
      map((orders) => {
        const dispatchedOrders = orders.filter((order) => order.status === OrderStatus.ORDER_STATUS_DISPATCHED);
        const inProgressOrders = groupOrdersByTaskType(orders, TaskStatus.TASK_STATUS_IN_PROGRESS)
          .map((group) => ({
            groupHeader: group.groupHeader,
            orderCards: group.orderCards.filter((order) => order.status === OrderStatus.ORDER_STATUS_DRIVER_ACCEPTED),
          }))
          .filter((group) => group.orderCards.length > 0);
        return [
          {
            groupHeader: 'Sent to Driver',
            orderCards: dispatchedOrders.map((order) => convertMappableOrderToOrderCard(order)),
          },
          ...inProgressOrders,
        ].filter((group) => group.orderCards.length);
      }),
    );
    this.currentSite$ = this.siteService.currentSite$;
  }

  public trackByPayloadDetails(_: number, details: PayloadDetails) {
    return details.payloadName;
  }
}
