<div class="select-payload-type">
  <ct-mini-modal title="Select Payload Type" subTitle="How should we classify {{ payloadName }}?">
    <main>
      <div class="content">
        <div class="input-wrapper">
          <mat-select #payloadTypeSelect [formControl]="fc" ctAutomatedSelector="payload_type__type_button">
            <mat-select-trigger>
              <ng-container *ngIf="payloadTypeSelect.value && payloadTypeSelect.value.id">
                <span class="payload-type-name">{{ payloadTypeSelect.value.name }}</span>
              </ng-container>
            </mat-select-trigger>
            <mat-option *ngFor="let type of payloadTypes; trackBy: trackById" [value]="type"
              ><span class="payload-type-name" ctAutomatedSelector="{{ 'payload_type__name_' + type.name }}">{{
                type.name
              }}</span>
            </mat-option>
          </mat-select>
          <label>Payload Type</label>
        </div>
      </div>
    </main>
    <footer>
      <div class="content">
        <button class="full-button" ct-dark-button (click)="selected()" ctAutomatedSelector="payload_type__save">
          save
        </button>
      </div>
    </footer>
  </ct-mini-modal>
</div>
