/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-save-dialogue.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../button/button.component.ngfactory";
import * as i3 from "../button/button.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../mini-modal/mini-modal.component.ngfactory";
import * as i6 from "../mini-modal/mini-modal.component";
import * as i7 from "../directives/allowed-account-types.directive";
import * as i8 from "../../services/role.service";
import * as i9 from "./confirm-save-dialogue.component";
var styles_ConfirmSaveDialogueComponent = [i0.styles];
var RenderType_ConfirmSaveDialogueComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmSaveDialogueComponent, data: {} });
export { RenderType_ConfirmSaveDialogueComponent as RenderType_ConfirmSaveDialogueComponent };
function View_ConfirmSaveDialogueComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "cancel"], ["ct-danger-button", ""]], [[2, "ct-button", null], [2, "button-danger", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(2, 49152, null, 0, i3.ButtonComponent, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(3, 16384, null, 0, i3.CtDangerButtonDirective, [], null, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["class", "save"], ["ct-dark-button", ""]], [[1, "aria-label", 0], [1, "type", 0], [2, "ct-button", null], [2, "dark-button", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).dialogRef.close(i1.ɵnov(_v, 6).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(6, 606208, null, 0, i4.MatDialogClose, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵdid(7, 49152, null, 0, i3.ButtonComponent, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(8, 16384, null, 0, i3.CtDarkButtonDirective, [], null, null), (_l()(), i1.ɵted(-1, 0, ["Save"]))], function (_ck, _v) { var currVal_6 = "save"; _ck(_v, 6, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).ctButton; var currVal_1 = i1.ɵnov(_v, 3).secondary; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 6).ariaLabel || null); var currVal_3 = i1.ɵnov(_v, 6).type; var currVal_4 = i1.ɵnov(_v, 8).ctButton; var currVal_5 = i1.ɵnov(_v, 8).secondary; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_ConfirmSaveDialogueComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "main-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "ct-mini-modal", [["title", "Do you want to save the changes to the contract?"]], null, null, null, i5.View_MiniModalComponent_0, i5.RenderType_MiniModalComponent)), i1.ɵdid(2, 114688, null, 0, i6.MiniModalComponent, [], { title: [0, "title"] }, null), (_l()(), i1.ɵeld(3, 0, null, 1, 9, "main", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "button-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["class", "nosave"], ["ct-button", ""]], [[1, "aria-label", 0], [1, "type", 0], [2, "ct-button", null], [2, "button", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).dialogRef.close(i1.ɵnov(_v, 6).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(6, 606208, null, 0, i4.MatDialogClose, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵdid(7, 49152, null, 0, i3.ButtonComponent, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(8, 16384, null, 0, i3.CtButtonDirective, [], null, null), (_l()(), i1.ɵted(-1, 0, ["Don't Save"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ConfirmSaveDialogueComponent_1)), i1.ɵdid(11, 147456, null, 0, i7.AllowedAccountTypesDirective, [i1.ViewContainerRef, i1.TemplateRef, i8.RoleService], { ctAllowedAccountRoles: [0, "ctAllowedAccountRoles"] }, null), i1.ɵpad(12, 2)], function (_ck, _v) { var currVal_0 = "Do you want to save the changes to the contract?"; _ck(_v, 2, 0, currVal_0); var currVal_5 = "nosave"; _ck(_v, 6, 0, currVal_5); var currVal_6 = _ck(_v, 12, 0, 1, 2); _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 6).ariaLabel || null); var currVal_2 = i1.ɵnov(_v, 6).type; var currVal_3 = i1.ɵnov(_v, 8).ctButton; var currVal_4 = i1.ɵnov(_v, 8).secondary; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_ConfirmSaveDialogueComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ct-confirm-save-dialogue", [], null, null, null, View_ConfirmSaveDialogueComponent_0, RenderType_ConfirmSaveDialogueComponent)), i1.ɵdid(1, 114688, null, 0, i9.ConfirmSaveDialogueComponent, [i4.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmSaveDialogueComponentNgFactory = i1.ɵccf("ct-confirm-save-dialogue", i9.ConfirmSaveDialogueComponent, View_ConfirmSaveDialogueComponent_Host_0, {}, {}, []);
export { ConfirmSaveDialogueComponentNgFactory as ConfirmSaveDialogueComponentNgFactory };
