import { FormArray } from '@angular/forms';

/** Clamps a number between zero and a maximum. */
function clamp(value: number, max: number): number {
  return Math.max(0, Math.min(max, value));
}

/**
 * Moves an item in a FormArray to another position.
 * @param formArray FormArray instance in which to move the item.
 * @param fromIndex Starting index of the item.
 * @param toIndex Index to which he item should be moved.
 */
export function moveItemInFormArray(formArray: FormArray, fromIndex: number, toIndex: number): void {
  const from = clamp(fromIndex, formArray.length - 1);
  const to = clamp(toIndex, formArray.length - 1);

  if (from === to) {
    return;
  }

  // const moveForward = to > from;
  const movedControl = formArray.at(from);
  formArray.removeAt(from);
  formArray.insert(to, movedControl);
}
