/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mini-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./mini-modal.component";
var styles_MiniModalComponent = [i0.styles];
var RenderType_MiniModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MiniModalComponent, data: {} });
export { RenderType_MiniModalComponent as RenderType_MiniModalComponent };
function View_MiniModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_MiniModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "sub-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subTitle; _ck(_v, 1, 0, currVal_0); }); }
export function View_MiniModalComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "mini-modal"]], [[2, "grow", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "header", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MiniModalComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MiniModalComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "main", [], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(8, 0, null, null, 1, "footer", [], null, null, null, null, null)), i1.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.subTitle; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.grow; _ck(_v, 0, 0, currVal_0); }); }
export function View_MiniModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ct-mini-modal", [], null, null, null, View_MiniModalComponent_0, RenderType_MiniModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.MiniModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MiniModalComponentNgFactory = i1.ɵccf("ct-mini-modal", i3.MiniModalComponent, View_MiniModalComponent_Host_0, { title: "title", subTitle: "subTitle", grow: "grow" }, {}, ["*", "*"]);
export { MiniModalComponentNgFactory as MiniModalComponentNgFactory };
