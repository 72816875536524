import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, race, Subject, timer } from 'rxjs';
import { exhaustMap, mapTo, take, takeUntil } from 'rxjs/operators';
import { LongEscapeTipComponent } from '~common/long-escape-tip/long-escape-tip.component';
import { LongEscapeComponent, longPressTimeInMs } from '~common/long-escape/long-escape.component';
var LongEscapeDirective = /** @class */ (function () {
    function LongEscapeDirective(snackBar, router, activatedRoute) {
        this.snackBar = snackBar;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.longPressed = new EventEmitter();
        this.keyDown$$ = new Subject();
        this.keyUp$$ = new Subject();
        this.destroys$$ = new Subject();
    }
    LongEscapeDirective.prototype.ngOnInit = function () {
        var _this = this;
        var snackBar;
        this.keyDown$$
            .asObservable()
            .pipe(exhaustMap(function () {
            snackBar = _this.snackBar.openFromComponent(LongEscapeComponent, {
                panelClass: 'hotkey-snackbar',
            });
            return combineLatest([
                race(_this.keyUp$$.pipe(mapTo(false)), timer(longPressTimeInMs).pipe(take(1), mapTo(true))).pipe(take(1)),
                _this.keyUp$$.asObservable(),
            ]).pipe(take(1));
        }), takeUntil(this.destroys$$))
            .subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 1), isLongPress = _b[0];
            if (snackBar) {
                snackBar.dismiss();
            }
            if (isLongPress) {
                if (_this.routerLink) {
                    if (typeof _this.routerLink === 'string') {
                        _this.router.navigateByUrl(_this.routerLink, { relativeTo: _this.activatedRoute });
                    }
                    else {
                        _this.router.navigate(_this.routerLink, { relativeTo: _this.activatedRoute });
                    }
                    return;
                }
                _this.longPressed.next();
            }
        });
    };
    LongEscapeDirective.prototype.ngOnDestroy = function () {
        this.destroys$$.next();
        this.destroys$$.unsubscribe();
    };
    LongEscapeDirective.prototype.click = function ($event) {
        if (window.innerWidth > 1024) {
            this.snackBar.openFromComponent(LongEscapeTipComponent, {
                duration: 5000,
                panelClass: 'hotkey-snackbar',
            });
        }
    };
    LongEscapeDirective.prototype.keyDown = function (event) {
        this.keyDown$$.next(event);
    };
    LongEscapeDirective.prototype.keyUp = function (event) {
        this.keyUp$$.next(event);
    };
    return LongEscapeDirective;
}());
export { LongEscapeDirective };
