import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { CreateTruckRequest } from '~proto/truck/truck_api_pb';
import { fuse } from '~utilities/fuse';
var searchOptions = {
    distance: 100,
    keys: ['name'],
    location: 0,
    maxPatternLength: 16,
    minMatchCharLength: 1,
    shouldSort: true,
    threshold: 0.1,
};
var CreateOrderTruckSelectorComponent = /** @class */ (function () {
    function CreateOrderTruckSelectorComponent(truckService) {
        this.truckService = truckService;
        this.selected = new EventEmitter();
        this.invalid = false;
        this.textChanges$$ = new BehaviorSubject(null);
    }
    CreateOrderTruckSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.trucks$ = combineLatest([
            this.truckService.trucks$.pipe(map(fuse(searchOptions))),
            this.textChanges$$.pipe(debounceTime(100), startWith('')),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), trucks = _b[0], text = _b[1];
            if (!trucks) {
                return [];
            }
            if (!text || text === '') {
                _this.topResult = trucks.data.length ? trucks.data[0] : null;
                return trucks.data;
            }
            var searchResults = trucks.fuse.search(text);
            _this.topResult = searchResults.length ? searchResults[0] : null;
            return searchResults;
        }));
        this.showCreateTruckButton$ = combineLatest([
            this.truckService.trucks$,
            this.textChanges$$.pipe(startWith(null)),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), trucks = _b[0], text = _b[1];
            if (!text || !text.length) {
                return false;
            }
            return trucks.every(function (truck) { return truck.name !== text; });
        }));
    };
    CreateOrderTruckSelectorComponent.prototype.inputTextChange = function (event) {
        this.textChanges$$.next(event.trim());
    };
    CreateOrderTruckSelectorComponent.prototype.selectTruck = function (truck) {
        this.selected.emit({ truck: truck });
        this.smartDropdown.close();
    };
    CreateOrderTruckSelectorComponent.prototype.addTruck = function () {
        var _this = this;
        var name = this.textChanges$$.value;
        if (!name || name === '') {
            return;
        }
        var request = new CreateTruckRequest();
        request.setName(name);
        this.truckService.addTruck$(request).subscribe(function (truck) {
            _this.selectTruck(truck);
        });
    };
    CreateOrderTruckSelectorComponent.prototype.enterKeyPressed = function () {
        if (this.topResult) {
            this.selectTruck(this.topResult);
        }
        else {
            this.addTruck();
        }
    };
    return CreateOrderTruckSelectorComponent;
}());
export { CreateOrderTruckSelectorComponent };
