import { BehaviorSubject } from 'rxjs';
import { filter, map, share } from 'rxjs/operators';
import { environment } from '~environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
var ConfigValuesService = /** @class */ (function () {
    function ConfigValuesService(db) {
        this.db = db;
        this.configs$$ = new BehaviorSubject(null);
        this.subscribeToConfigVals();
    }
    Object.defineProperty(ConfigValuesService.prototype, "configs$", {
        get: function () {
            return this.configs$$.asObservable().pipe(filter(function (configs) { return !!configs; }), map(function (configs) { return Object.assign({}, configs); }), share());
        },
        enumerable: true,
        configurable: true
    });
    ConfigValuesService.prototype.subscribeToConfigVals = function () {
        var _this = this;
        this.db
            .doc("hauli-config-values/" + environment.environment)
            .valueChanges()
            .subscribe(function (configs) {
            if (configs) {
                _this.configs$$.next(configs);
            }
            else {
                _this.configs$$.next({});
            }
        }, function (error) {
            _this.configs$$.next({});
        });
    };
    ConfigValuesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigValuesService_Factory() { return new ConfigValuesService(i0.ɵɵinject(i1.AngularFirestore)); }, token: ConfigValuesService, providedIn: "root" });
    return ConfigValuesService;
}());
export { ConfigValuesService };
