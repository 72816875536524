import * as tslib_1 from "tslib";
import { HttpHeaders } from '@angular/common/http';
import { grpc } from '@improbable-eng/grpc-web';
import * as moment from 'moment';
import { NgxLoggerLevel } from 'ngx-logger';
import { Observable, race, Subject, timer } from 'rxjs';
import { filter, mapTo, switchMap, take, throttleTime } from 'rxjs/operators';
import { environment } from '~environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/material/snack-bar";
import * as i3 from "ngx-logger";
import * as i4 from "./versioning.service";
import * as i5 from "@angular/router";
var lastInvokeTime = {};
var GRPCError = /** @class */ (function (_super) {
    tslib_1.__extends(GRPCError, _super);
    function GRPCError(code, message, trailers) {
        var _this = _super.call(this, message) || this;
        _this.code = code;
        _this.message = message;
        _this.trailers = trailers;
        return _this;
    }
    return GRPCError;
}(Error));
export { GRPCError };
var GrpcService = /** @class */ (function () {
    function GrpcService(authService, matSnackBar, logger, versionService, router) {
        var _this = this;
        this.authService = authService;
        this.matSnackBar = matSnackBar;
        this.logger = logger;
        this.versionService = versionService;
        this.router = router;
        this.errorDisplay$$ = new Subject();
        this.authService.jwt$.subscribe(function (jwt) {
            _this.logger.setCustomHttpHeaders(new HttpHeaders({ authorization: "bearer " + jwt }));
        });
        if (sessionStorage.getItem('SA_LOG_ON') || localStorage.getItem('SA_LOG_ON')) {
            this.logger.updateConfig({ level: NgxLoggerLevel.DEBUG });
        }
        this.errorDisplay$$.pipe(throttleTime(3000)).subscribe(function (errorMessage) {
            _this.matSnackBar.open(errorMessage, null, { panelClass: 'snackbar-error', duration: 2000 });
        });
    }
    GrpcService.prototype.invoke$ = function (method, request, backgroundRequest) {
        var _this = this;
        if (backgroundRequest === void 0) { backgroundRequest = false; }
        this.logger.log("\u2B06 Request: " + method.methodName, request.toObject());
        this.warnAboutLastInvokeTimeIfNeeded(method);
        return race(this.authService.jwt$.pipe(filter(function (jwt) { return !!jwt; })), timer(12000).pipe(mapTo(null))).pipe(take(1), switchMap(function (jwt) {
            var metadata = {};
            if (jwt) {
                metadata.Authorization = "Bearer " + jwt;
            }
            return new Observable(function (observer) {
                grpc.invoke(method, {
                    host: environment.api,
                    metadata: metadata,
                    onEnd: function (code, msg, trailers) {
                        if (code !== grpc.Code.OK) {
                            if (code === grpc.Code.Unauthenticated) {
                                _this.authService.logout();
                            }
                            else {
                                var info = void 0;
                                try {
                                    info = JSON.parse(msg).info;
                                    _this.logger.error({
                                        clientVersion: _this.versionService.currentVersion,
                                        errorCode: code,
                                        errorMessage: info.join(' '),
                                        methodName: method.methodName,
                                        requestPayload: JSON.stringify(request.toObject()),
                                    });
                                }
                                catch (_err) {
                                    _this.logger.error({
                                        clientVersion: _this.versionService.currentVersion,
                                        errorCode: code,
                                        errorMessage: msg,
                                        methodName: method.methodName,
                                        requestPayload: JSON.stringify(request.toObject()),
                                    });
                                }
                                // permission denied to access resource, redirect to home screen
                                if (code === grpc.Code.PermissionDenied) {
                                    _this.router.navigate(['/']);
                                }
                                if (!backgroundRequest) {
                                    _this.errorDisplay$$.next(info ? info.join(' ') : 'Something Went Wrong');
                                }
                            }
                            observer.error(new GRPCError(code, msg, trailers));
                        }
                        observer.complete();
                    },
                    onMessage: function (message) {
                        _this.logger.log("Response \u2B07: " + method.methodName, message.toObject());
                        observer.next(message);
                    },
                    request: request,
                });
            });
        }));
    };
    GrpcService.prototype.invokeWithoutLogin$ = function (method, request) {
        var _this = this;
        return new Observable(function (observer) {
            _this.logger.log(method.methodName + " ok");
            var metadata = {};
            metadata.Authorization = "Bearer alsdhjakjshdashdjhsajhdkjahskjhsajhd";
            grpc.invoke(method, {
                host: environment.api,
                metadata: metadata,
                onEnd: function (code, msg, trailers) {
                    if (code === grpc.Code.OK) {
                        _this.logger.log(method.methodName + " ok");
                    }
                    if (code !== grpc.Code.OK) {
                        var info = void 0;
                        try {
                            info = JSON.parse(msg).info;
                            _this.logger.error({
                                clientVersion: _this.versionService.currentVersion,
                                errorCode: code,
                                errorMessage: info.join(' '),
                                methodName: method.methodName,
                                requestPayload: request.toObject(),
                            });
                        }
                        catch (_err) {
                            _this.logger.error({
                                clientVersion: _this.versionService.currentVersion,
                                errorCode: code,
                                errorMessage: msg,
                                methodName: method.methodName,
                                requestPayload: JSON.stringify(request.toObject()),
                            });
                        }
                        _this.errorDisplay$$.next(info ? info.join(' ') : 'Something Went Wrong');
                        observer.error(new GRPCError(code, msg, trailers));
                    }
                    observer.complete();
                },
                onMessage: function (message) {
                    _this.logger.log("" + method.methodName, message.toObject());
                    observer.next(message);
                },
                request: request,
            });
        });
    };
    GrpcService.prototype.warnAboutLastInvokeTimeIfNeeded = function (rpcCall) {
        var rpcName = rpcCall.methodName;
        var now = moment();
        var lastInvoked = lastInvokeTime[rpcName];
        if (!lastInvoked) {
            lastInvokeTime[rpcName] = now;
        }
        else {
            var diffInMs = now.diff(lastInvoked, 'milliseconds');
            if (diffInMs < 1000) {
                this.logger.debug("Recently invoked " + rpcName + " (last " + diffInMs + "ms), this might need some throttling");
            }
            lastInvokeTime[rpcName] = now;
        }
    };
    GrpcService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GrpcService_Factory() { return new GrpcService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.MatSnackBar), i0.ɵɵinject(i3.NGXLogger), i0.ɵɵinject(i4.VersioningService), i0.ɵɵinject(i5.Router)); }, token: GrpcService, providedIn: "root" });
    return GrpcService;
}());
export { GrpcService };
