import { OnInit } from '@angular/core';
import { MatButton } from '@angular/material';
import { ButtonComponent } from '~common/button/button.component';
var NetworkActiveButtonComponent = /** @class */ (function () {
    function NetworkActiveButtonComponent() {
        this._active = false;
    }
    Object.defineProperty(NetworkActiveButtonComponent.prototype, "active", {
        get: function () {
            return this._active;
        },
        set: function (active) {
            this._active = active;
            this.toggleButtonDisabled();
        },
        enumerable: true,
        configurable: true
    });
    NetworkActiveButtonComponent.prototype.ngOnInit = function () { };
    NetworkActiveButtonComponent.prototype.toggleButtonDisabled = function () {
        if (this.button) {
            if (this.active) {
                this.button.disabled = true;
            }
            else {
                this.button.disabled = false;
            }
        }
        if (this.ctButton) {
            if (this.active) {
                this.ctButton.disabled = true;
            }
            else {
                this.ctButton.disabled = false;
            }
        }
    };
    return NetworkActiveButtonComponent;
}());
export { NetworkActiveButtonComponent };
