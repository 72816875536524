import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { OrdersService } from 'src/app/lmo/services/orders.service';
import { OrderCard, PayloadDetails } from 'src/app/models/type-with-order.model';
import { orderPluralMapping } from '~plural/orders';
import { MappableOrder } from '~proto/order/order_pb';
import { OrderStatus } from '~proto/types/types_pb';
import { fuse } from '~utilities/fuse';
import { convertMappableOrderToOrderCardForLastSequence } from '~utilities/groupOrders';
import { trackById } from '~utilities/trackById';

interface HeaderItem {
  id: string;
  status: number;
  isHeaderItem: true;
  length: number;
}
const searchOptions: Fuse.FuseOptions<any> = {
  distance: 100,
  keys: ['id', 'truckName', 'driver.user.name', 'trailerName', 'taskSummariesList.payload.name', 'vendorName', 'displayId'],
  location: 0,
  maxPatternLength: 16,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.2,
};

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-orders-completed-view',
  styleUrls: ['./orders-completed-view.component.scss'],
  templateUrl: './orders-completed-view.component.html',
})
export class OrdersCompletedViewComponent implements OnInit {
  public ordersPlural = orderPluralMapping;
  public completedOrders$: Observable<MappableOrder.AsObject[]>;
  public trackById = trackById;
  public orderStatus = OrderStatus;
  public completedOrdersSearch: FormControl = new FormControl(this.ordersService.searchTerm);
  public totalCompletedElements: number;
  public completedOrdersEndIncrement = 25;
  public completedOrdersEndIndex$$: BehaviorSubject<number> = new BehaviorSubject<number>(
    this.completedOrdersEndIncrement,
  );

  get completedOrdersEndIndex$(): Observable<number> {
    return this.completedOrdersEndIndex$$.asObservable();
  }
  public virtualScrolls$: Observable<Array<HeaderItem | MappableOrder.AsObject>>;

  constructor(private ordersService: OrdersService) {}

  public ngOnInit() {
    this.completedOrders$ = combineLatest([
      this.ordersService.mappableCompletedOrders$.pipe(map(fuse<MappableOrder.AsObject>(searchOptions))),
      this.completedOrdersSearch.valueChanges.pipe(
        debounceTime(200),
        startWith(null as string),
      ),
    ]).pipe(
      map(([fused, searchTerm]) => {
        if (!searchTerm || searchTerm === '') {
          return fused.data;
        }
        return fused.fuse.search(searchTerm);
      }),
    );
    this.virtualScrolls$ = combineLatest([
      this.completedOrders$,
      this.completedOrdersEndIndex$.pipe(distinctUntilChanged()),
    ]).pipe(
      map(([completed, endIndex]) => {
        const completedHeader: HeaderItem = {
          id: 'completedHeader',
          isHeaderItem: true,
          length: 0,
          status: 0,
        };
        const cancelledHeader: HeaderItem = {
          id: 'cancelledHeader',
          isHeaderItem: true,
          length: 0,
          status: 0,
        };
        const completedOrders: OrderCard[] = [];
        const cancelledOrders: OrderCard[] = [];
        completed.forEach((complete) => {
          if (complete.status === OrderStatus.ORDER_STATUS_COMPLETED) {
            completedHeader.status = complete.status;
            completedOrders.push(convertMappableOrderToOrderCardForLastSequence(complete));
          } else if (complete.status === OrderStatus.ORDER_STATUS_CANCELLED) {
            cancelledHeader.status = complete.status;
            cancelledOrders.push(convertMappableOrderToOrderCardForLastSequence(complete));
          }
        });
        this.totalCompletedElements = cancelledOrders.length + completedOrders.length;
        const arr: any = [
          { ...completedHeader, length: completedOrders.length },
          ...completedOrders,
          { ...cancelledHeader, length: cancelledOrders.length },
          ...cancelledOrders,
        ];
        return arr.slice(0, Math.min(endIndex, arr.length));
      }),
    );
  }
  public onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      const currentCompletedOrdersEndIndex = this.completedOrdersEndIndex$$.value;
      if (currentCompletedOrdersEndIndex < this.totalCompletedElements + 2) {
        this.completedOrdersEndIndex$$.next(currentCompletedOrdersEndIndex + this.completedOrdersEndIncrement);
      }
    }
  }

  public trackByPayloadDetails(_: number, details: PayloadDetails) {
    return details.payloadName;
  }
}
