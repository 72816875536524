import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material';

export interface HotkeyComponentData {
  hotkey: string;
  taskDescription: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-hotkey',
  styleUrls: ['./hotkey.component.scss'],
  templateUrl: './hotkey.component.html',
})
export class HotkeyComponent implements OnInit {
  public taskDescription: string;
  public hotkey: string;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: HotkeyComponentData) {
    this.taskDescription = data.taskDescription;
    this.hotkey = data.hotkey;
  }

  public ngOnInit() {}
}
