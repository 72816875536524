import { AfterViewInit, Component, ElementRef, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ct-rfq-location-form',
  styleUrls: ['./rfq-location-form.component.scss'],
  templateUrl: './rfq-location-form.component.html',
})
export class RfqLocationFormComponent implements OnInit, AfterViewInit {
  @ViewChild('map', { static: false }) private mapElement: ElementRef;
  public map: google.maps.Map;
  public mineMarker: google.maps.Marker;
  public latitude = 39.7392;
  public longitude = -104.9903;
  public invalidLocation$$ = new BehaviorSubject<boolean>(false);

  constructor(@Optional() private matDialogRef: MatDialogRef<RfqLocationFormComponent>) {}

  public ngOnInit() {}

  public ngAfterViewInit(): void {
    this.initMap();
  }

  private initMap() {
    if (this.map == null) {
      const center = {
        lat: this.latitude,
        lng: this.longitude,
      };
      const mapConfig = {
        center: center,
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: 3,
        },
        mapTypeId: google.maps.MapTypeId.HYBRID,
        streetViewControl: false,
        zoom: 12,
        zoomControlOptions: {
          position: 3,
        },
      };

      if (this.mapElement != null) {
        this.map = new google.maps.Map(this.mapElement.nativeElement, mapConfig);
        this.mineMarker = new google.maps.Marker({
          animation: google.maps.Animation.DROP,
          draggable: true,
          map: this.map,
          position: this.map.getCenter(),
        });
        google.maps.event.addListener(this.mineMarker, 'position_changed', this.handleMineMarkerMove.bind(this));
      }
    }
  }

  public centerMarkerOnMap() {
    const currentMapCenter = this.map.getCenter();
    this.mineMarker.setPosition({
      lat: currentMapCenter.lat(),
      lng: currentMapCenter.lng(),
    });
  }

  public centerMapOnMarker() {
    const currentMarkerPosition = this.mineMarker.getPosition();
    this.map.setCenter(currentMarkerPosition);
  }

  private handleMineMarkerMove() {
    const newPosition = this.mineMarker.getPosition();
    this.latitude = newPosition.lat();
    this.longitude = newPosition.lng();
  }

  public latLongChange() {
    if (+this.latitude <= 90 && +this.latitude >= -90 && +this.longitude <= 180 && +this.longitude >= -180) {
      this.mineMarker.setPosition({
        lat: +this.latitude,
        lng: +this.longitude,
      });
      this.centerMapOnMarker();
      this.invalidLocation$$.next(false);
    } else {
      this.invalidLocation$$.next(true);
    }
  }

  public submit() {
    this.matDialogRef.close(`${this.latitude}, ${this.longitude}`);
  }
}
