import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { PayloadService } from 'src/app/lmo/services/payload.service';
import { SelectPayloadTypeComponent } from '~common/select-payload-type/select-payload-type.component';
import { SelectPayloadUnitsComponent } from '~common/select-payload-units/select-payload-units.component';
import { SmartDropdownComponent } from '~common/smart-dropdown/smart-dropdown.component';
import { CreatePayloadRequest } from '~proto/payload/payload_api_pb';
import { Payload, PayloadGroup, PayloadType, Unit } from '~proto/payload/payload_pb';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { trackById } from '~utilities/trackById';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-order-details-edit-payload',
  styleUrls: ['./order-details-edit-payload.component.scss'],
  templateUrl: './order-details-edit-payload.component.html',
})
export class OrderDetailsEditPayloadComponent implements OnInit {
  @Output() public selected = new EventEmitter<{ payload: Payload.AsObject }>();
  @Input() public payload: Payload.AsObject;
  @ViewChild(SmartDropdownComponent, { static: true }) private smartDropdown: SmartDropdownComponent;
  public payloads$: Observable<PayloadGroup.AsObject[]>;
  public trackById = trackById;
  public textChanges$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public searching$: Observable<boolean>;
  private topResult: Payload.AsObject;

  constructor(private payloadService: PayloadService, private matDialog: MatDialog) {}

  public ngOnInit() {
    this.searching$ = this.payloadService.searching$;
    const searchResults$ = combineLatest([
      this.payloadService.currentSitePayloads$,
      this.textChanges$$.pipe(debounceTime(200)).pipe(
        switchMap((searchText) => {
          if (searchText && searchText.length >= 2) {
            return this.payloadService.searchPayloads$(searchText);
          }
          return of([] as Payload.AsObject[]);
        }),
      ),
    ]).pipe(
      map(([payloadGroups, searchResults]) => {
        if (!payloadGroups) {
          return searchResults;
        }
        const payloads = payloadGroups.reduce((arr, group) => [...arr, ...group.payloadsList], []);
        const payloadMap = idArrayToRecord(payloads);
        return searchResults.filter((payload) => !payloadMap[payload.id]);
      }),
    );
    this.payloads$ = combineLatest([
      this.payloadService.currentSitePayloads$.pipe(
        map((payloads) => {
          const allPayloads = payloads.reduce((arr: Payload.AsObject[], group) => [...arr, ...group.payloadsList], []);
          const pinned: Payload.AsObject[] = [];
          const recent: Payload.AsObject[] = [];
          allPayloads.forEach((payload) => {
            if (payload.favorited) {
              pinned.push(payload);
            } else {
              recent.push(payload);
            }
          });
          return [
            {
              name: 'Pinned',
              payloadsList: pinned,
            },
            {
              name: 'Recent',
              payloadsList: recent,
            },
          ] as PayloadGroup.AsObject[];
        }),
      ),
      this.textChanges$$.pipe(
        debounceTime(100),
        startWith(''),
      ),
      searchResults$,
    ]).pipe(
      map(([items, text, searchResults]) => {
        if (!items) {
          return [];
        }
        if (!text || text === '') {
          const filteredItems: PayloadGroup.AsObject[] = items.filter((item) => item.payloadsList.length > 0);
          this.topResult =
            filteredItems.length && filteredItems[0].payloadsList.length ? filteredItems[0].payloadsList[0] : null;
          return filteredItems;
        }
        const cleanedItems = items.map((item) => ({
          ...item,
          payloadsList: item.payloadsList.filter((entry) =>
            entry.name.toLocaleLowerCase().includes(text.toLocaleLowerCase()),
          ),
        }));
        const mergedGroups = [
          {
            name: 'search results',
            payloadsList: searchResults,
          },
          ...cleanedItems,
        ];
        const filtered: PayloadGroup.AsObject[] = mergedGroups.filter((item) => item.payloadsList.length > 0);
        if (filtered.length && filtered[0].payloadsList.length) {
          this.topResult = filtered[0].payloadsList[0];
        } else {
          this.topResult = null;
        }
        return filtered;
      }),
    );
  }

  public inputTextChange(event: string) {
    this.textChanges$$.next(event.trim());
  }

  public togglePin(event: Event, payloadId: number, isFavorited: boolean) {
    event.stopPropagation();
    this.payloadService.updatePayloadFavorites(payloadId, isFavorited);
  }

  public selectPayload(payload: Payload.AsObject, isNew = false) {
    this.selected.emit({ payload });
    this.smartDropdown.close();
  }

  public async createNewPayload() {
    const createPayloadRequest = new CreatePayloadRequest();
    createPayloadRequest.setName(this.textChanges$$.value);

    const unitsDialog = this.matDialog.open(SelectPayloadUnitsComponent);
    unitsDialog.componentInstance.payloadName = createPayloadRequest.getName();
    const unit: Unit.AsObject = await unitsDialog.afterClosed().toPromise();
    if (!unit) {
      return;
    }

    createPayloadRequest.setUnitId(unit.id);

    const payloadTypeDialog = this.matDialog.open(SelectPayloadTypeComponent);
    payloadTypeDialog.componentInstance.payloadName = createPayloadRequest.getName();
    const payloadType: PayloadType.AsObject = await payloadTypeDialog.afterClosed().toPromise();
    if (!payloadType) {
      return;
    }
    createPayloadRequest.setPayloadTypeId(payloadType.id);

    this.payloadService.createPayload$(createPayloadRequest).subscribe(async (payload) => {
      if (!payload) {
        return;
      }

      this.selectPayload(payload, true);
    });
  }

  public enterKeyPressed() {
    if (this.topResult) {
      this.selectPayload(this.topResult);
    } else {
      this.createNewPayload();
    }
  }
}
