import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, finalize, map, startWith, take } from 'rxjs/operators';
import { LMOUpdatePendingOrderRequest } from '~proto/order/order_api_pb';
import { OrderType } from '~proto/types/types_pb';
import { UserDetailsForIntercomRequest } from '~proto/user/user_api_pb';
import { UserAPI } from '~proto/user/user_api_pb_service';
import { trackById } from '~utilities/trackById';
var LmoEditPendingOrderComponent = /** @class */ (function () {
    function LmoEditPendingOrderComponent(orderService, fb, snackBar, costCenterService, contractService, grpcService, roleService) {
        this.orderService = orderService;
        this.fb = fb;
        this.snackBar = snackBar;
        this.costCenterService = costCenterService;
        this.contractService = contractService;
        this.grpcService = grpcService;
        this.roleService = roleService;
        this.networkActive$ = new BehaviorSubject(false);
        this.orderTypes = OrderType;
        this.truckingContracts$$ = new BehaviorSubject([]);
        this.trackById = trackById;
        this.contractType$$ = new BehaviorSubject(-1);
        this.isCostCenterRequired$$ = new BehaviorSubject(false);
        this.isPurchaseOrderRequired$$ = new BehaviorSubject(false);
        this.isSalesOrderRequired$$ = new BehaviorSubject(false);
        this.isBusinessLineCostCenterRelationEnabled$$ = new BehaviorSubject(false);
        this.businessLineLinked$$ = new BehaviorSubject(null);
        this.isPOValid = 'true';
        this.isSOValid = 'true';
    }
    LmoEditPendingOrderComponent.prototype.formValue = function () {
        return this.formGroup.value;
    };
    LmoEditPendingOrderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadRequiredFieldSettings();
        this.costCenters$ = this.costCenterService.costCenters$;
        this.currentOrder$ = this.orderService.currentOrder$;
        this.formGroup = this.fb.group({
            bolNumber: null,
            contractTypeId: null,
            costCenter: null,
            costCenterId: [null, this.isCostCenterRequired$$.value ? [Validators.required] : ''],
            description: null,
            isAsapOrder: null,
            payload: null,
            pickupFrom: null,
            purchaseOrderName: null,
            quantity: null,
            salesOrderNumber: null,
            scheduledDeliveryWindow: null,
            trailer: null,
            truck: null,
            truckingVendor: null,
            vendorContractId: null,
        });
        this.currentOrder$
            .pipe(filter(function (order) { return !!order; }), take(1))
            .subscribe(function (order) {
            if (!order.tasksList || order.tasksList.length === 0) {
                return;
            }
            _this.businessLineLinked$$.next(order.businessLine);
            var pickupTask = order.tasksList[0];
            if (_this.isPurchaseOrderRequired$$.value && order.costCenter === '') {
                _this.isCCValid = -1;
            }
            if (order && order.type !== OrderType.ORDER_TYPE_CUSTOM) {
                _this.isPurchaseOrderRequired$$.value
                    ? _this.formGroup.controls['purchaseOrderName'].setValidators(Validators.required)
                    : _this.formGroup.controls['purchaseOrderName'].clearValidators();
                _this.isSalesOrderRequired$$.value
                    ? _this.formGroup.controls['salesOrderNumber'].setValidators(Validators.required)
                    : _this.formGroup.controls['salesOrderNumber'].clearValidators();
            }
            _this.costCenterObj = order.costCenterObj;
            _this.formGroup.patchValue({
                bolNumber: pickupTask.billing ? pickupTask.billing.bolNumber : null,
                contractTypeId: order.contractType ? order.contractType.id : null,
                costCenter: order.costCenterObj ? order.costCenterObj.name : '',
                costCenterId: order.costCenterObj ? order.costCenterObj.id : -1,
                description: order.description,
                isAsapOrder: order.isAsap,
                payload: pickupTask.payload,
                pickupFrom: pickupTask.site,
                purchaseOrderName: pickupTask.billing ? pickupTask.billing.purchaseOrderName : null,
                quantity: pickupTask.actualQuantity > Number.MIN_SAFE_INTEGER
                    ? pickupTask.actualQuantity
                    : pickupTask.orderedQuantity > Number.MIN_SAFE_INTEGER
                        ? pickupTask.orderedQuantity
                        : 0,
                salesOrderNumber: order.salesOrderNumber,
                scheduledDeliveryWindow: order.deliveryWindowStart
                    ? [
                        moment(order.deliveryWindowStart * 1000).toDate(),
                        moment(order.deliveryWindowStart * 1000)
                            .add(order.deliveryWindowDurationMinutes, 'minutes')
                            .toDate(),
                    ]
                    : null,
                trailer: order.trailer,
                truck: order.truck,
                truckingVendor: order.truckingVendor,
                vendorContractId: order.vendorContract ? order.vendorContract.id : null,
            });
            _this.listenToAsap();
            _this.listenToPayload();
            _this.setupPayloads();
            _this.listenContractTypeChanges(order);
            _this.listenContractChange(order);
            _this.listenCostCenterChanges();
            _this.contractType$$.next(order.contractType ? order.contractType.id : -1);
            _this.checkIsBusinessLineCostCenterRelationEnabled();
        });
    };
    LmoEditPendingOrderComponent.prototype.listenContractTypeChanges = function (order) {
        var _this = this;
        this.contractTypes$ = this.contractService.contractTypes$;
        this.formGroup.get('contractTypeId').valueChanges.subscribe(function (value) {
            _this.contractType$$.next(value);
            _this.formGroup.get('truckingVendor').setValue(null);
            _this.formGroup.get('vendorContractId').setValue(null);
        });
    };
    LmoEditPendingOrderComponent.prototype.listenContractChange = function (order) {
        var _this = this;
        combineLatest([
            this.formGroup
                .get('truckingVendor')
                .valueChanges.pipe(startWith(order.truckingVendor ? order.truckingVendor : null)),
            this.formGroup
                .get('contractTypeId')
                .valueChanges.pipe(startWith(order.contractType ? order.contractType.id : -1)),
            this.contractService.nonExpiredContracts$,
        ]).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 3), vendor = _b[0], contractTypeId = _b[1], contracts = _b[2];
            if (vendor) {
                _this.truckingContracts$$.next(contracts.filter(function (contract) {
                    return contract.vendor.id === vendor.id &&
                        contract.isEffective &&
                        (contractTypeId <= 0 || (contract.contractType && contract.contractType.id === contractTypeId));
                }));
            }
            else {
                _this.truckingContracts$$.next([]);
            }
        });
    };
    LmoEditPendingOrderComponent.prototype.listenCostCenterChanges = function () {
        var _this = this;
        this.formGroup.get('costCenterId').valueChanges.subscribe(function (value) {
            _this.isCCValid = value;
        });
    };
    LmoEditPendingOrderComponent.prototype.setupPayloads = function () {
        this.payloads$ = combineLatest([
            this.currentOrder$,
            this.formGroup.get('payload').valueChanges.pipe(startWith(this.formGroup.get('payload').value)),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), order = _b[0], formPayload = _b[1];
            if (order && order.type === OrderType.ORDER_TYPE_CUSTOM) {
                return Object.values(order.tasksList.reduce(function (acc, task) {
                    task.subTasksList.forEach(function (subtask) {
                        if (subtask.payload) {
                            acc[subtask.payload.id] = subtask.payload;
                        }
                    });
                    return acc;
                }, {}));
            }
            else if (formPayload) {
                return [formPayload];
            }
            else {
                return [];
            }
        }));
    };
    LmoEditPendingOrderComponent.prototype.selected = function (event) {
        this.formGroup.patchValue(event);
    };
    LmoEditPendingOrderComponent.prototype.isCustomOrder = function (order) {
        return order.type === OrderType.ORDER_TYPE_CUSTOM;
    };
    LmoEditPendingOrderComponent.prototype.updateOrder = function (currentOrder) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var value, request, mask, order, isCustomOrder, start, end, windowDuration;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        value = this.formValue();
                        this.requiredFieldsCheck(value, currentOrder);
                        if (!this.isCCValid || this.isCCValid === -1 || !this.isPOValid || !this.isSOValid) {
                            this.snackBar.open("\n        " + (this.isCostCenterRequired$$.value && (!this.isCCValid || this.isCCValid === -1) ? 'Cost Center, ' : '') + "\n        " + (this.isPurchaseOrderRequired$$.value && !this.isPOValid ? 'PO Number, ' : '') + "\n        " + (this.isSalesOrderRequired$$.value && !this.isSOValid ? 'Sales Order Number' : '') + "\n        fields are mandatory", null, {
                                duration: 3000,
                            });
                            return [2 /*return*/];
                        }
                        if (this.formGroup.invalid) {
                            return [2 /*return*/];
                        }
                        this.networkActive$.next(true);
                        request = new LMOUpdatePendingOrderRequest();
                        mask = new FieldMask();
                        return [4 /*yield*/, this.currentOrder$.pipe(take(1)).toPromise()];
                    case 1:
                        order = _a.sent();
                        isCustomOrder = this.isCustomOrder(order);
                        request.setIsAsapOrder(value.isAsapOrder);
                        if (!isCustomOrder) {
                            request.setPurchaseOrderName(value.purchaseOrderName);
                            request.setSalesOrderNumber(value.salesOrderNumber);
                            request.setBolNumber(value.bolNumber);
                        }
                        request.setDescription(value.description);
                        request.setTruckingVendorId(value.truckingVendor ? value.truckingVendor.id : 0);
                        mask.addPaths('isAsap');
                        request.setCostCenter(value.costCenter);
                        if (!isCustomOrder) {
                            mask.addPaths('purchaseOrderName');
                            mask.addPaths('salesOrderNumber');
                            mask.addPaths('bolNumber');
                        }
                        mask.addPaths('description');
                        mask.addPaths('truckingVendorId');
                        mask.addPaths('lmoCostCenterId');
                        mask.addPaths('vendorContractTypeId');
                        mask.addPaths('vendorContractId');
                        mask.addPaths('vendorContractJson');
                        if (order.contractType == null || order.contractType.id !== value.contractTypeId) {
                            request.setContractTypeId(value.contractTypeId);
                        }
                        request.setCostCenterId(value.costCenterId);
                        if (order.vendorContract == null || order.vendorContract.id !== value.vendorContractId) {
                            request.setVendorContractId(value.vendorContractId);
                        }
                        if (value.scheduledDeliveryWindow && value.scheduledDeliveryWindow.length) {
                            start = moment(value.scheduledDeliveryWindow[0]);
                            end = moment(value.scheduledDeliveryWindow[1]);
                            windowDuration = moment.duration(end.diff(start)).asMinutes();
                            request.setDeliveryWindowStart(start.unix());
                            request.setDeliveryWindowDurationMinutes(windowDuration);
                            mask.addPaths('deliveryWindowStart');
                            mask.addPaths('deliveryWindowDurationMinutes');
                        }
                        // These cannot be edited on a custom order
                        if (order.type !== OrderType.ORDER_TYPE_CUSTOM) {
                            if (value.payload) {
                                request.setPayloadId(value.payload.id);
                                mask.addPaths('payloadId');
                            }
                            if (value.quantity !== null) {
                                request.setOrderedQuantity(value.quantity);
                                mask.addPaths('orderedQuantity');
                            }
                            if (value.pickupFrom) {
                                request.setSiteId(value.pickupFrom.id);
                                mask.addPaths('siteId');
                            }
                            if (value.trailer) {
                                request.setTrailerId(value.trailer.id);
                                mask.addPaths('trailerId');
                            }
                            if (value.truck) {
                                request.setTruckId(value.truck.id);
                                mask.addPaths('truckId');
                            }
                        }
                        request.setMask(mask);
                        this.orderService
                            .updatePendingOrder$(request)
                            .pipe(finalize(function () { return _this.networkActive$.next(false); }))
                            .subscribe(function () {
                            _this.snackBar.open('Order Updated', null, { duration: 2000 });
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    LmoEditPendingOrderComponent.prototype.requiredFieldsCheck = function (formValue, order) {
        this.isCCValid = this.isCostCenterRequired$$.value ? formValue.costCenterId : 'true';
        if (order && order.type !== OrderType.ORDER_TYPE_CUSTOM) {
            this.isPOValid = this.isPurchaseOrderRequired$$.value ? formValue.purchaseOrderName : 'true';
            this.isSOValid = this.isSalesOrderRequired$$.value ? formValue.salesOrderNumber : 'true';
        }
    };
    LmoEditPendingOrderComponent.prototype.listenToAsap = function () {
        var _this = this;
        this.formGroup
            .get('isAsapOrder')
            .valueChanges.pipe(startWith(this.formGroup.get('isAsapOrder').value))
            .subscribe(function (value) {
            if (value) {
                _this.formGroup.get('scheduledDeliveryWindow').disable();
                _this.formGroup.get('scheduledDeliveryWindow').reset();
            }
            else {
                _this.formGroup.get('scheduledDeliveryWindow').enable();
            }
        });
    };
    LmoEditPendingOrderComponent.prototype.listenToPayload = function () {
        var _this = this;
        this.formGroup.get('payload').valueChanges.subscribe(function (value) {
            if (value) {
                _this.formGroup.get('truckingVendor').reset();
            }
        });
    };
    LmoEditPendingOrderComponent.prototype.checkIsBusinessLineCostCenterRelationEnabled = function () {
        var _this = this;
        this.roleService.isBusinessLineCostCenterRelationEnabled$.subscribe(function (isBLCCRelationEnabled) {
            _this.isBusinessLineCostCenterRelationEnabled$$.next(isBLCCRelationEnabled);
            if (isBLCCRelationEnabled) {
                _this.costCenterService.loadCostCenters(_this.businessLineLinked$$.value);
            }
        });
    };
    LmoEditPendingOrderComponent.prototype.loadRequiredFieldSettings = function () {
        var _this = this;
        var request = new UserDetailsForIntercomRequest();
        this.grpcService.invoke$(UserAPI.UserDetailsForIntercom, request).subscribe(function (response) {
            _this.isCostCenterRequired$$.next(response.toObject().userDetails.isCostCenterRequired);
            _this.isPurchaseOrderRequired$$.next(response.toObject().userDetails.isPurchaseOrderRequired);
            _this.isSalesOrderRequired$$.next(response.toObject().userDetails.isSalesOrderRequired);
        });
    };
    return LmoEditPendingOrderComponent;
}());
export { LmoEditPendingOrderComponent };
