<div class="login" ctPageTitle="Login">
  <div class="content">
    <div class="body">
      <div class="logo">
        <img class="shale-logo" src="../../../assets/icons/shale-logo.svg" />
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="login()">
        <p class="header">Login to ShaleApps HAULi<sup style="font-size: .5em; font-weight: 600;">TM</sup></p>
        <ct-input label="email">
          <input formControlName="email" ctInput type="email" ctAutomatedSelector="login__email" />
        </ct-input>

        <ct-input label="password">
          <input formControlName="password" ctInput type="password" ctAutomatedSelector="login__password" />
        </ct-input>
        <div>
          <a mat-button (click)="openSSOEmail()">Login via SSO</a>
        </div>

        <div class="environment-picker" *ctOnlyEnv="['dev', 'stage']">
          <button type="button" [class.is-active]="isDev()" (click)="useDev()">Dev</button>
          <button type="button" [class.is-active]="isStage()" (click)="useStage()">Stage</button>
          <button type="button" [class.is-active]="isProd()" (click)="useProd()" class="prod">Prod</button>
        </div>

        <div class="login-actions">
          <button type="submit" ct-button ctAutomatedSelector="login__sign-in-button">Sign In</button>
          <a
            [routerLink]="['/', 'reset-password']"
            [queryParams]="{ email: loginForm.get('email').value }"
            ctAutomatedSelector="login__forgot-password-button"
            >Forgot your password?</a
          >
        </div>

        <div class="login-conditions">
          By clicking Sign In, you agree to our
          <a href="http://privacy.shaleapps.com" target="_blank">Terms and Conditions</a>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #ssoEmail>
  <form (ngSubmit)="ssoLogin()" class="new-po-form">
    <mat-form-field class="form-field">
      <input #newContractName matInput placeholder="Email" [formControl]="ssoEmailForm" autocomplete="off" />
    </mat-form-field>
    <br />
    <button
      class="submit-btn"
      type="submit"
      color="primary"
      mat-raised-button
      [disabled]="ssoEmailForm.invalid || running"
    >
      Login Via SSO
    </button>
    <button class="cancel-btn" type="button" mat-raised-button (click)="closeTab()">
      Cancel
    </button>
  </form>
</ng-template>
