import { OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { trackById } from '~utilities/trackById';
var LmoTruckingContractsManageComponent = /** @class */ (function () {
    function LmoTruckingContractsManageComponent(contractService) {
        this.contractService = contractService;
        this.trackById = trackById;
    }
    LmoTruckingContractsManageComponent.prototype.ngOnInit = function () {
        this.contracts$ = this.contractService.allContracts$.pipe(map(function (contracts) { return contracts.map(convertProtoContractToListItem); }));
    };
    return LmoTruckingContractsManageComponent;
}());
export { LmoTruckingContractsManageComponent };
function convertProtoContractToListItem(contract) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    return {
        contractName: contract.name,
        contractType: contract.contractType ? contract.contractType.name : '-',
        createdBy: contract.createdBy && contract.createdBy.name ? contract.createdBy.name : '-',
        createdDate: contract.createdUnix * 1000,
        expirationDate: contract.expirationTime.valid ? contract.expirationTime.time : null,
        id: contract.id,
        isDefault: false,
        isExpired: contract.isExpired,
        payloadNames: contract.payloadtypesList.map(function (type) { return type.name; }).join(', '),
        vendorName: contract.vendor ? contract.vendor.name : 'Not implemented yet',
        zipcodesnotmappedList: contract.zipcodesnotmappedList,
    };
}
