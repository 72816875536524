import { Directive, ElementRef, Input, OnDestroy, OnInit, Optional, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { pluck, startWith, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[ctInput]',
})
export class InputDirective implements OnInit, OnDestroy {
  private destroy$$ = new Subject();
  private text$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  @Input() public enableAutocomplete = false;

  public get text$(): Observable<string> {
    return this.text$$.asObservable();
  }

  constructor(private renderer: Renderer2, private el: ElementRef, @Optional() private control: NgControl) {
    this.text$.pipe(takeUntil(this.destroy$$)).subscribe((value) => {
      if (
        !value ||
        (typeof value === 'string' && value.trim() === '' && this.el.nativeElement.classList.contains('not-empty'))
      ) {
        this.renderer.removeClass(this.el.nativeElement, 'not-empty');
      } else if (!this.el.nativeElement.classList.contains('not-empty')) {
        this.renderer.addClass(this.el.nativeElement, 'not-empty');
      }
    });
  }

  public ngOnInit() {
    if (this.enableAutocomplete === false) {
      this.renderer.setProperty(this.el.nativeElement, 'autocomplete', 'on');
    }
    if (this.control && this.control.valueChanges) {
      this.control.valueChanges
        .pipe(startWith(this.control.value))
        .subscribe((change: string) => this.text$$.next(change));
    } else {
      fromEvent(this.el.nativeElement, 'input')
        .pipe(
          tap((event: Event) => {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();
          }),
          pluck('target', 'value'),
          takeUntil(this.destroy$$),
        )
        .subscribe((value: string) => {
          this.text$$.next(value);
        });
    }
  }

  public ngOnDestroy() {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }
}
