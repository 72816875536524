import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sa-choice-dialog',
  styleUrls: ['./choice-dialog.component.scss'],
  templateUrl: './choice-dialog.component.html',
})
export class ChoiceDialogComponent implements OnInit {
  public data: any;

  constructor(public dialogRef: MatDialogRef<ChoiceDialogComponent>, @Inject(MAT_DIALOG_DATA) public _data: any) {
    this.data = _data;
  }

  public ngOnInit() {}

  public cancelAction() {
    this.dialogRef.close(false);
  }

  public continueAction() {
    this.dialogRef.close(true);
  }
}
