/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_cybertron_account_account_pb = require('../../../proto/cybertron/account/account_pb.js');
var proto_cybertron_payload_payload_pb = require('../../../proto/cybertron/payload/payload_pb.js');
var proto_cybertron_businessline_businessline_pb = require('../../../proto/cybertron/businessline/businessline_pb.js');
var proto_cybertron_types_types_pb = require('../../../proto/cybertron/types/types_pb.js');
var proto_cybertron_drivercertification_driverCertification_pb = require('../../../proto/cybertron/drivercertification/driverCertification_pb.js');
var proto_geo_geo_pb = require('../../../proto/geo/geo_pb.js');
goog.exportSymbol('proto.cbv1.Analytics', null, global);
goog.exportSymbol('proto.cbv1.Claims', null, global);
goog.exportSymbol('proto.cbv1.Driver', null, global);
goog.exportSymbol('proto.cbv1.DriverStatus', null, global);
goog.exportSymbol('proto.cbv1.LastWaypoint', null, global);
goog.exportSymbol('proto.cbv1.Session', null, global);
goog.exportSymbol('proto.cbv1.User', null, global);
goog.exportSymbol('proto.cbv1.UserAccountRole', null, global);
goog.exportSymbol('proto.cbv1.UserDetailsForIntercom', null, global);
goog.exportSymbol('proto.cbv1.Waypoint', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.Waypoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.Waypoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.Waypoint.displayName = 'proto.cbv1.Waypoint';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.Waypoint.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.Waypoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.Waypoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Waypoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firebaseUid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    coordinates: (f = msg.getCoordinates()) && proto_geo_geo_pb.Point.toObject(includeInstance, f),
    accuracy: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    speed: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    clientCreatedUnix: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.Waypoint}
 */
proto.cbv1.Waypoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.Waypoint;
  return proto.cbv1.Waypoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.Waypoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.Waypoint}
 */
proto.cbv1.Waypoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebaseUid(value);
      break;
    case 3:
      var value = new proto_geo_geo_pb.Point;
      reader.readMessage(value,proto_geo_geo_pb.Point.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAccuracy(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpeed(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setClientCreatedUnix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.Waypoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.Waypoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.Waypoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Waypoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirebaseUid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_geo_geo_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getAccuracy();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getClientCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.Waypoint.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.Waypoint.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string firebase_uid = 2;
 * @return {string}
 */
proto.cbv1.Waypoint.prototype.getFirebaseUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.Waypoint.prototype.setFirebaseUid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Point coordinates = 3;
 * @return {?proto.cbv1.Point}
 */
proto.cbv1.Waypoint.prototype.getCoordinates = function() {
  return /** @type{?proto.cbv1.Point} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Point, 3));
};


/** @param {?proto.cbv1.Point|undefined} value */
proto.cbv1.Waypoint.prototype.setCoordinates = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv1.Waypoint.prototype.clearCoordinates = function() {
  this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Waypoint.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float accuracy = 4;
 * @return {number}
 */
proto.cbv1.Waypoint.prototype.getAccuracy = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.cbv1.Waypoint.prototype.setAccuracy = function(value) {
  jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float speed = 5;
 * @return {number}
 */
proto.cbv1.Waypoint.prototype.getSpeed = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.cbv1.Waypoint.prototype.setSpeed = function(value) {
  jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 client_created_unix = 6;
 * @return {number}
 */
proto.cbv1.Waypoint.prototype.getClientCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.Waypoint.prototype.setClientCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.User.displayName = 'proto.cbv1.User';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.User.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    firebaseUid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    claims: (f = msg.getClaims()) && proto.cbv1.Claims.toObject(includeInstance, f),
    disabled: jspb.Message.getFieldWithDefault(msg, 6, false),
    pin: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.User}
 */
proto.cbv1.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.User;
  return proto.cbv1.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.User}
 */
proto.cbv1.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebaseUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 5:
      var value = new proto.cbv1.Claims;
      reader.readMessage(value,proto.cbv1.Claims.deserializeBinaryFromReader);
      msg.setClaims(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirebaseUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClaims();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.cbv1.Claims.serializeBinaryToWriter
    );
  }
  f = message.getDisabled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPin();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string firebase_uid = 1;
 * @return {string}
 */
proto.cbv1.User.prototype.getFirebaseUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.User.prototype.setFirebaseUid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.User.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.cbv1.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.User.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone = 4;
 * @return {string}
 */
proto.cbv1.User.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.User.prototype.setPhone = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Claims claims = 5;
 * @return {?proto.cbv1.Claims}
 */
proto.cbv1.User.prototype.getClaims = function() {
  return /** @type{?proto.cbv1.Claims} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.Claims, 5));
};


/** @param {?proto.cbv1.Claims|undefined} value */
proto.cbv1.User.prototype.setClaims = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.cbv1.User.prototype.clearClaims = function() {
  this.setClaims(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.User.prototype.hasClaims = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool disabled = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.User.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.cbv1.User.prototype.setDisabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string pin = 7;
 * @return {string}
 */
proto.cbv1.User.prototype.getPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cbv1.User.prototype.setPin = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UserAccountRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.UserAccountRole.repeatedFields_, null);
};
goog.inherits(proto.cbv1.UserAccountRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UserAccountRole.displayName = 'proto.cbv1.UserAccountRole';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.UserAccountRole.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UserAccountRole.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UserAccountRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UserAccountRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UserAccountRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolesList: jspb.Message.getRepeatedField(msg, 1),
    account: (f = msg.getAccount()) && proto_cybertron_account_account_pb.Account.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UserAccountRole}
 */
proto.cbv1.UserAccountRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UserAccountRole;
  return proto.cbv1.UserAccountRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UserAccountRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UserAccountRole}
 */
proto.cbv1.UserAccountRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<!proto.cbv1.Role>} */ (reader.readPackedEnum());
      msg.setRolesList(value);
      break;
    case 2:
      var value = new proto_cybertron_account_account_pb.Account;
      reader.readMessage(value,proto_cybertron_account_account_pb.Account.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UserAccountRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UserAccountRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UserAccountRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UserAccountRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_cybertron_account_account_pb.Account.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Role roles = 1;
 * @return {!Array<!proto.cbv1.Role>}
 */
proto.cbv1.UserAccountRole.prototype.getRolesList = function() {
  return /** @type {!Array<!proto.cbv1.Role>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<!proto.cbv1.Role>} value */
proto.cbv1.UserAccountRole.prototype.setRolesList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.cbv1.Role} value
 * @param {number=} opt_index
 */
proto.cbv1.UserAccountRole.prototype.addRoles = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv1.UserAccountRole.prototype.clearRolesList = function() {
  this.setRolesList([]);
};


/**
 * optional Account account = 2;
 * @return {?proto.cbv1.Account}
 */
proto.cbv1.UserAccountRole.prototype.getAccount = function() {
  return /** @type{?proto.cbv1.Account} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_account_account_pb.Account, 2));
};


/** @param {?proto.cbv1.Account|undefined} value */
proto.cbv1.UserAccountRole.prototype.setAccount = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cbv1.UserAccountRole.prototype.clearAccount = function() {
  this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.UserAccountRole.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.Claims = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.Claims.repeatedFields_, null);
};
goog.inherits(proto.cbv1.Claims, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.Claims.displayName = 'proto.cbv1.Claims';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.Claims.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.Claims.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.Claims.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.Claims} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Claims.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountSession: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    authsList: jspb.Message.toObjectList(msg.getAuthsList(),
    proto.cbv1.UserAccountRole.toObject, includeInstance),
    scopesList: jspb.Message.getRepeatedField(msg, 4),
    accountType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    role: jspb.Message.getFieldWithDefault(msg, 6, 0),
    analytics: (f = msg.getAnalytics()) && proto.cbv1.Analytics.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 8, ""),
    ssoEnabled: jspb.Message.getFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.Claims}
 */
proto.cbv1.Claims.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.Claims;
  return proto.cbv1.Claims.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.Claims} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.Claims}
 */
proto.cbv1.Claims.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountSession(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 3:
      var value = new proto.cbv1.UserAccountRole;
      reader.readMessage(value,proto.cbv1.UserAccountRole.deserializeBinaryFromReader);
      msg.addAuths(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addScopes(value);
      break;
    case 5:
      var value = /** @type {!proto.cbv1.AccountType} */ (reader.readEnum());
      msg.setAccountType(value);
      break;
    case 6:
      var value = /** @type {!proto.cbv1.Role} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 7:
      var value = new proto.cbv1.Analytics;
      reader.readMessage(value,proto.cbv1.Analytics.deserializeBinaryFromReader);
      msg.setAnalytics(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSsoEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.Claims.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.Claims.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.Claims} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Claims.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountSession();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuthsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.cbv1.UserAccountRole.serializeBinaryToWriter
    );
  }
  f = message.getScopesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getAccountType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAnalytics();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.cbv1.Analytics.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSsoEnabled();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional int32 account_session = 1;
 * @return {number}
 */
proto.cbv1.Claims.prototype.getAccountSession = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.Claims.prototype.setAccountSession = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string account_name = 2;
 * @return {string}
 */
proto.cbv1.Claims.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.Claims.prototype.setAccountName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated UserAccountRole auths = 3;
 * @return {!Array<!proto.cbv1.UserAccountRole>}
 */
proto.cbv1.Claims.prototype.getAuthsList = function() {
  return /** @type{!Array<!proto.cbv1.UserAccountRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.UserAccountRole, 3));
};


/** @param {!Array<!proto.cbv1.UserAccountRole>} value */
proto.cbv1.Claims.prototype.setAuthsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.cbv1.UserAccountRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.UserAccountRole}
 */
proto.cbv1.Claims.prototype.addAuths = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.cbv1.UserAccountRole, opt_index);
};


proto.cbv1.Claims.prototype.clearAuthsList = function() {
  this.setAuthsList([]);
};


/**
 * repeated string scopes = 4;
 * @return {!Array<string>}
 */
proto.cbv1.Claims.prototype.getScopesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/** @param {!Array<string>} value */
proto.cbv1.Claims.prototype.setScopesList = function(value) {
  jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.Claims.prototype.addScopes = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


proto.cbv1.Claims.prototype.clearScopesList = function() {
  this.setScopesList([]);
};


/**
 * optional AccountType account_type = 5;
 * @return {!proto.cbv1.AccountType}
 */
proto.cbv1.Claims.prototype.getAccountType = function() {
  return /** @type {!proto.cbv1.AccountType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {!proto.cbv1.AccountType} value */
proto.cbv1.Claims.prototype.setAccountType = function(value) {
  jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional Role role = 6;
 * @return {!proto.cbv1.Role}
 */
proto.cbv1.Claims.prototype.getRole = function() {
  return /** @type {!proto.cbv1.Role} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.cbv1.Role} value */
proto.cbv1.Claims.prototype.setRole = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional Analytics analytics = 7;
 * @return {?proto.cbv1.Analytics}
 */
proto.cbv1.Claims.prototype.getAnalytics = function() {
  return /** @type{?proto.cbv1.Analytics} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.Analytics, 7));
};


/** @param {?proto.cbv1.Analytics|undefined} value */
proto.cbv1.Claims.prototype.setAnalytics = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


proto.cbv1.Claims.prototype.clearAnalytics = function() {
  this.setAnalytics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Claims.prototype.hasAnalytics = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string name = 8;
 * @return {string}
 */
proto.cbv1.Claims.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.cbv1.Claims.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool sso_enabled = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.Claims.prototype.getSsoEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.cbv1.Claims.prototype.setSsoEnabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.Driver = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.Driver.repeatedFields_, null);
};
goog.inherits(proto.cbv1.Driver, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.Driver.displayName = 'proto.cbv1.Driver';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.Driver.repeatedFields_ = [8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.Driver.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.Driver.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.Driver} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Driver.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.cbv1.User.toObject(includeInstance, f),
    driverStatus: (f = msg.getDriverStatus()) && proto.cbv1.DriverStatus.toObject(includeInstance, f),
    lastWaypoint: (f = msg.getLastWaypoint()) && proto.cbv1.LastWaypoint.toObject(includeInstance, f),
    isAssigned: jspb.Message.getFieldWithDefault(msg, 5, false),
    hosRemainingInMinutes: jspb.Message.getFieldWithDefault(msg, 6, 0),
    isOnSandiRunboard: jspb.Message.getFieldWithDefault(msg, 7, false),
    certificationsList: jspb.Message.toObjectList(msg.getCertificationsList(),
    proto_cybertron_drivercertification_driverCertification_pb.DriverCertification.toObject, includeInstance),
    allowedPayloadTypesList: jspb.Message.toObjectList(msg.getAllowedPayloadTypesList(),
    proto_cybertron_payload_payload_pb.PayloadType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.Driver}
 */
proto.cbv1.Driver.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.Driver;
  return proto.cbv1.Driver.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.Driver} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.Driver}
 */
proto.cbv1.Driver.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cbv1.User;
      reader.readMessage(value,proto.cbv1.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = new proto.cbv1.DriverStatus;
      reader.readMessage(value,proto.cbv1.DriverStatus.deserializeBinaryFromReader);
      msg.setDriverStatus(value);
      break;
    case 4:
      var value = new proto.cbv1.LastWaypoint;
      reader.readMessage(value,proto.cbv1.LastWaypoint.deserializeBinaryFromReader);
      msg.setLastWaypoint(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAssigned(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHosRemainingInMinutes(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOnSandiRunboard(value);
      break;
    case 8:
      var value = new proto_cybertron_drivercertification_driverCertification_pb.DriverCertification;
      reader.readMessage(value,proto_cybertron_drivercertification_driverCertification_pb.DriverCertification.deserializeBinaryFromReader);
      msg.addCertifications(value);
      break;
    case 9:
      var value = new proto_cybertron_payload_payload_pb.PayloadType;
      reader.readMessage(value,proto_cybertron_payload_payload_pb.PayloadType.deserializeBinaryFromReader);
      msg.addAllowedPayloadTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.Driver.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.Driver.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.Driver} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Driver.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cbv1.User.serializeBinaryToWriter
    );
  }
  f = message.getDriverStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cbv1.DriverStatus.serializeBinaryToWriter
    );
  }
  f = message.getLastWaypoint();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.cbv1.LastWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getIsAssigned();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getHosRemainingInMinutes();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getIsOnSandiRunboard();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getCertificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto_cybertron_drivercertification_driverCertification_pb.DriverCertification.serializeBinaryToWriter
    );
  }
  f = message.getAllowedPayloadTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto_cybertron_payload_payload_pb.PayloadType.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.cbv1.User}
 */
proto.cbv1.Driver.prototype.getUser = function() {
  return /** @type{?proto.cbv1.User} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.User, 1));
};


/** @param {?proto.cbv1.User|undefined} value */
proto.cbv1.Driver.prototype.setUser = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.Driver.prototype.clearUser = function() {
  this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Driver.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DriverStatus driver_status = 3;
 * @return {?proto.cbv1.DriverStatus}
 */
proto.cbv1.Driver.prototype.getDriverStatus = function() {
  return /** @type{?proto.cbv1.DriverStatus} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.DriverStatus, 3));
};


/** @param {?proto.cbv1.DriverStatus|undefined} value */
proto.cbv1.Driver.prototype.setDriverStatus = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv1.Driver.prototype.clearDriverStatus = function() {
  this.setDriverStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Driver.prototype.hasDriverStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LastWaypoint last_waypoint = 4;
 * @return {?proto.cbv1.LastWaypoint}
 */
proto.cbv1.Driver.prototype.getLastWaypoint = function() {
  return /** @type{?proto.cbv1.LastWaypoint} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.LastWaypoint, 4));
};


/** @param {?proto.cbv1.LastWaypoint|undefined} value */
proto.cbv1.Driver.prototype.setLastWaypoint = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.cbv1.Driver.prototype.clearLastWaypoint = function() {
  this.setLastWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Driver.prototype.hasLastWaypoint = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool is_assigned = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.Driver.prototype.getIsAssigned = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.cbv1.Driver.prototype.setIsAssigned = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int64 hos_remaining_in_minutes = 6;
 * @return {number}
 */
proto.cbv1.Driver.prototype.getHosRemainingInMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.Driver.prototype.setHosRemainingInMinutes = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool is_on_sandi_runboard = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.Driver.prototype.getIsOnSandiRunboard = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.cbv1.Driver.prototype.setIsOnSandiRunboard = function(value) {
  jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated DriverCertification certifications = 8;
 * @return {!Array<!proto.cbv1.DriverCertification>}
 */
proto.cbv1.Driver.prototype.getCertificationsList = function() {
  return /** @type{!Array<!proto.cbv1.DriverCertification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_drivercertification_driverCertification_pb.DriverCertification, 8));
};


/** @param {!Array<!proto.cbv1.DriverCertification>} value */
proto.cbv1.Driver.prototype.setCertificationsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.cbv1.DriverCertification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.DriverCertification}
 */
proto.cbv1.Driver.prototype.addCertifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.cbv1.DriverCertification, opt_index);
};


proto.cbv1.Driver.prototype.clearCertificationsList = function() {
  this.setCertificationsList([]);
};


/**
 * repeated PayloadType allowed_payload_types = 9;
 * @return {!Array<!proto.cbv1.PayloadType>}
 */
proto.cbv1.Driver.prototype.getAllowedPayloadTypesList = function() {
  return /** @type{!Array<!proto.cbv1.PayloadType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_payload_payload_pb.PayloadType, 9));
};


/** @param {!Array<!proto.cbv1.PayloadType>} value */
proto.cbv1.Driver.prototype.setAllowedPayloadTypesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.cbv1.PayloadType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.PayloadType}
 */
proto.cbv1.Driver.prototype.addAllowedPayloadTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.cbv1.PayloadType, opt_index);
};


proto.cbv1.Driver.prototype.clearAllowedPayloadTypesList = function() {
  this.setAllowedPayloadTypesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DriverStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DriverStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DriverStatus.displayName = 'proto.cbv1.DriverStatus';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DriverStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DriverStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DriverStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    driverStatusType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isException: jspb.Message.getFieldWithDefault(msg, 2, false),
    shortTitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    exceptionTitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    exceptionDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    exceptionUrl: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DriverStatus}
 */
proto.cbv1.DriverStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DriverStatus;
  return proto.cbv1.DriverStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DriverStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DriverStatus}
 */
proto.cbv1.DriverStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cbv1.DriverStatusType} */ (reader.readEnum());
      msg.setDriverStatusType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsException(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExceptionTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExceptionDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExceptionUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DriverStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DriverStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DriverStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriverStatusType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getIsException();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getShortTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExceptionTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExceptionDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExceptionUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional DriverStatusType driver_status_type = 1;
 * @return {!proto.cbv1.DriverStatusType}
 */
proto.cbv1.DriverStatus.prototype.getDriverStatusType = function() {
  return /** @type {!proto.cbv1.DriverStatusType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.cbv1.DriverStatusType} value */
proto.cbv1.DriverStatus.prototype.setDriverStatusType = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool is_exception = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.DriverStatus.prototype.getIsException = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.DriverStatus.prototype.setIsException = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string short_title = 3;
 * @return {string}
 */
proto.cbv1.DriverStatus.prototype.getShortTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.DriverStatus.prototype.setShortTitle = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string exception_title = 4;
 * @return {string}
 */
proto.cbv1.DriverStatus.prototype.getExceptionTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.DriverStatus.prototype.setExceptionTitle = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string exception_description = 5;
 * @return {string}
 */
proto.cbv1.DriverStatus.prototype.getExceptionDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.DriverStatus.prototype.setExceptionDescription = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string exception_url = 6;
 * @return {string}
 */
proto.cbv1.DriverStatus.prototype.getExceptionUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cbv1.DriverStatus.prototype.setExceptionUrl = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LastWaypoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LastWaypoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LastWaypoint.displayName = 'proto.cbv1.LastWaypoint';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LastWaypoint.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LastWaypoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LastWaypoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LastWaypoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    coordinates: (f = msg.getCoordinates()) && proto_geo_geo_pb.Point.toObject(includeInstance, f),
    clientCreatedUnix: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createdUnix: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LastWaypoint}
 */
proto.cbv1.LastWaypoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LastWaypoint;
  return proto.cbv1.LastWaypoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LastWaypoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LastWaypoint}
 */
proto.cbv1.LastWaypoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_geo_geo_pb.Point;
      reader.readMessage(value,proto_geo_geo_pb.Point.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setClientCreatedUnix(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedUnix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LastWaypoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LastWaypoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LastWaypoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LastWaypoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_geo_geo_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getClientCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional Point coordinates = 1;
 * @return {?proto.cbv1.Point}
 */
proto.cbv1.LastWaypoint.prototype.getCoordinates = function() {
  return /** @type{?proto.cbv1.Point} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Point, 1));
};


/** @param {?proto.cbv1.Point|undefined} value */
proto.cbv1.LastWaypoint.prototype.setCoordinates = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.LastWaypoint.prototype.clearCoordinates = function() {
  this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.LastWaypoint.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 client_created_unix = 2;
 * @return {number}
 */
proto.cbv1.LastWaypoint.prototype.getClientCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.LastWaypoint.prototype.setClientCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 created_unix = 3;
 * @return {number}
 */
proto.cbv1.LastWaypoint.prototype.getCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.LastWaypoint.prototype.setCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UserDetailsForIntercom = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.UserDetailsForIntercom.repeatedFields_, null);
};
goog.inherits(proto.cbv1.UserDetailsForIntercom, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UserDetailsForIntercom.displayName = 'proto.cbv1.UserDetailsForIntercom';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.UserDetailsForIntercom.repeatedFields_ = [9,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UserDetailsForIntercom.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UserDetailsForIntercom.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UserDetailsForIntercom} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UserDetailsForIntercom.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    companyType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userCreateTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    currentSessionStartTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    role: jspb.Message.getFieldWithDefault(msg, 6, 0),
    isFirstTimeLogin: jspb.Message.getFieldWithDefault(msg, 7, false),
    isBusinessLineEnabled: jspb.Message.getFieldWithDefault(msg, 8, false),
    businessLinesList: jspb.Message.toObjectList(msg.getBusinessLinesList(),
    proto_cybertron_businessline_businessline_pb.BusinessLine.toObject, includeInstance),
    isSsoUser: jspb.Message.getFieldWithDefault(msg, 10, false),
    isLmoSettingsEnable: jspb.Message.getFieldWithDefault(msg, 11, false),
    isPurchaseOrderRequired: jspb.Message.getFieldWithDefault(msg, 12, false),
    isCostCenterRequired: jspb.Message.getFieldWithDefault(msg, 13, false),
    isSalesOrderRequired: jspb.Message.getFieldWithDefault(msg, 14, false),
    isBusinessLineCostCenterRelationEnabled: jspb.Message.getFieldWithDefault(msg, 15, false),
    userActionsList: jspb.Message.getRepeatedField(msg, 16),
    isSalesOrderApiEnabled: jspb.Message.getFieldWithDefault(msg, 17, false),
    isMaintenanceOrderRequired: jspb.Message.getFieldWithDefault(msg, 18, false),
    isMaintenanceOrderApiEnabled: jspb.Message.getFieldWithDefault(msg, 19, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UserDetailsForIntercom}
 */
proto.cbv1.UserDetailsForIntercom.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UserDetailsForIntercom;
  return proto.cbv1.UserDetailsForIntercom.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UserDetailsForIntercom} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UserDetailsForIntercom}
 */
proto.cbv1.UserDetailsForIntercom.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserCreateTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentSessionStartTime(value);
      break;
    case 6:
      var value = /** @type {!proto.cbv1.Role} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFirstTimeLogin(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBusinessLineEnabled(value);
      break;
    case 9:
      var value = new proto_cybertron_businessline_businessline_pb.BusinessLine;
      reader.readMessage(value,proto_cybertron_businessline_businessline_pb.BusinessLine.deserializeBinaryFromReader);
      msg.addBusinessLines(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSsoUser(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLmoSettingsEnable(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPurchaseOrderRequired(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCostCenterRequired(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSalesOrderRequired(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBusinessLineCostCenterRelationEnabled(value);
      break;
    case 16:
      var value = /** @type {!Array<!proto.cbv1.UsersActionType>} */ (reader.readPackedEnum());
      msg.setUserActionsList(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSalesOrderApiEnabled(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMaintenanceOrderRequired(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMaintenanceOrderApiEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UserDetailsForIntercom.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UserDetailsForIntercom.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UserDetailsForIntercom} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UserDetailsForIntercom.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompanyType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserCreateTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCurrentSessionStartTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getIsFirstTimeLogin();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsBusinessLineEnabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getBusinessLinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto_cybertron_businessline_businessline_pb.BusinessLine.serializeBinaryToWriter
    );
  }
  f = message.getIsSsoUser();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsLmoSettingsEnable();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getIsPurchaseOrderRequired();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getIsCostCenterRequired();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getIsSalesOrderRequired();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIsBusinessLineCostCenterRelationEnabled();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getUserActionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      16,
      f
    );
  }
  f = message.getIsSalesOrderApiEnabled();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getIsMaintenanceOrderRequired();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getIsMaintenanceOrderApiEnabled();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
};


/**
 * optional int64 company_id = 1;
 * @return {number}
 */
proto.cbv1.UserDetailsForIntercom.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.UserDetailsForIntercom.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string company_name = 2;
 * @return {string}
 */
proto.cbv1.UserDetailsForIntercom.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.UserDetailsForIntercom.prototype.setCompanyName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string company_type = 3;
 * @return {string}
 */
proto.cbv1.UserDetailsForIntercom.prototype.getCompanyType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.UserDetailsForIntercom.prototype.setCompanyType = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 user_create_time = 4;
 * @return {number}
 */
proto.cbv1.UserDetailsForIntercom.prototype.getUserCreateTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.UserDetailsForIntercom.prototype.setUserCreateTime = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 current_session_start_time = 5;
 * @return {number}
 */
proto.cbv1.UserDetailsForIntercom.prototype.getCurrentSessionStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.UserDetailsForIntercom.prototype.setCurrentSessionStartTime = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional Role role = 6;
 * @return {!proto.cbv1.Role}
 */
proto.cbv1.UserDetailsForIntercom.prototype.getRole = function() {
  return /** @type {!proto.cbv1.Role} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.cbv1.Role} value */
proto.cbv1.UserDetailsForIntercom.prototype.setRole = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool is_first_time_login = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.UserDetailsForIntercom.prototype.getIsFirstTimeLogin = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.cbv1.UserDetailsForIntercom.prototype.setIsFirstTimeLogin = function(value) {
  jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_business_line_enabled = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.UserDetailsForIntercom.prototype.getIsBusinessLineEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.cbv1.UserDetailsForIntercom.prototype.setIsBusinessLineEnabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * repeated BusinessLine business_lines = 9;
 * @return {!Array<!proto.cbv1.BusinessLine>}
 */
proto.cbv1.UserDetailsForIntercom.prototype.getBusinessLinesList = function() {
  return /** @type{!Array<!proto.cbv1.BusinessLine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_businessline_businessline_pb.BusinessLine, 9));
};


/** @param {!Array<!proto.cbv1.BusinessLine>} value */
proto.cbv1.UserDetailsForIntercom.prototype.setBusinessLinesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.cbv1.BusinessLine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.BusinessLine}
 */
proto.cbv1.UserDetailsForIntercom.prototype.addBusinessLines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.cbv1.BusinessLine, opt_index);
};


proto.cbv1.UserDetailsForIntercom.prototype.clearBusinessLinesList = function() {
  this.setBusinessLinesList([]);
};


/**
 * optional bool is_sso_user = 10;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.UserDetailsForIntercom.prototype.getIsSsoUser = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 10, false));
};


/** @param {boolean} value */
proto.cbv1.UserDetailsForIntercom.prototype.setIsSsoUser = function(value) {
  jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool is_lmo_settings_enable = 11;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.UserDetailsForIntercom.prototype.getIsLmoSettingsEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 11, false));
};


/** @param {boolean} value */
proto.cbv1.UserDetailsForIntercom.prototype.setIsLmoSettingsEnable = function(value) {
  jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool is_purchase_order_required = 12;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.UserDetailsForIntercom.prototype.getIsPurchaseOrderRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 12, false));
};


/** @param {boolean} value */
proto.cbv1.UserDetailsForIntercom.prototype.setIsPurchaseOrderRequired = function(value) {
  jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool is_cost_center_required = 13;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.UserDetailsForIntercom.prototype.getIsCostCenterRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 13, false));
};


/** @param {boolean} value */
proto.cbv1.UserDetailsForIntercom.prototype.setIsCostCenterRequired = function(value) {
  jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool is_sales_order_required = 14;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.UserDetailsForIntercom.prototype.getIsSalesOrderRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 14, false));
};


/** @param {boolean} value */
proto.cbv1.UserDetailsForIntercom.prototype.setIsSalesOrderRequired = function(value) {
  jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool is_business_line_cost_center_relation_enabled = 15;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.UserDetailsForIntercom.prototype.getIsBusinessLineCostCenterRelationEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 15, false));
};


/** @param {boolean} value */
proto.cbv1.UserDetailsForIntercom.prototype.setIsBusinessLineCostCenterRelationEnabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * repeated UsersActionType user_actions = 16;
 * @return {!Array<!proto.cbv1.UsersActionType>}
 */
proto.cbv1.UserDetailsForIntercom.prototype.getUserActionsList = function() {
  return /** @type {!Array<!proto.cbv1.UsersActionType>} */ (jspb.Message.getRepeatedField(this, 16));
};


/** @param {!Array<!proto.cbv1.UsersActionType>} value */
proto.cbv1.UserDetailsForIntercom.prototype.setUserActionsList = function(value) {
  jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {!proto.cbv1.UsersActionType} value
 * @param {number=} opt_index
 */
proto.cbv1.UserDetailsForIntercom.prototype.addUserActions = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


proto.cbv1.UserDetailsForIntercom.prototype.clearUserActionsList = function() {
  this.setUserActionsList([]);
};


/**
 * optional bool is_sales_order_api_enabled = 17;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.UserDetailsForIntercom.prototype.getIsSalesOrderApiEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 17, false));
};


/** @param {boolean} value */
proto.cbv1.UserDetailsForIntercom.prototype.setIsSalesOrderApiEnabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool is_maintenance_order_required = 18;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.UserDetailsForIntercom.prototype.getIsMaintenanceOrderRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 18, false));
};


/** @param {boolean} value */
proto.cbv1.UserDetailsForIntercom.prototype.setIsMaintenanceOrderRequired = function(value) {
  jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool is_maintenance_order_api_enabled = 19;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.UserDetailsForIntercom.prototype.getIsMaintenanceOrderApiEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 19, false));
};


/** @param {boolean} value */
proto.cbv1.UserDetailsForIntercom.prototype.setIsMaintenanceOrderApiEnabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 19, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.Analytics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.Analytics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.Analytics.displayName = 'proto.cbv1.Analytics';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.Analytics.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.Analytics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.Analytics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Analytics.toObject = function(includeInstance, msg) {
  var f, obj = {
    segment: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isBuyer: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.Analytics}
 */
proto.cbv1.Analytics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.Analytics;
  return proto.cbv1.Analytics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.Analytics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.Analytics}
 */
proto.cbv1.Analytics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSegment(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBuyer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.Analytics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.Analytics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.Analytics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Analytics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSegment();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getIsBuyer();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 segment = 1;
 * @return {number}
 */
proto.cbv1.Analytics.prototype.getSegment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.Analytics.prototype.setSegment = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool is_buyer = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.Analytics.prototype.getIsBuyer = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.Analytics.prototype.setIsBuyer = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.Session = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.Session, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.Session.displayName = 'proto.cbv1.Session';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.Session.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.Session.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.Session} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Session.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.Session}
 */
proto.cbv1.Session.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.Session;
  return proto.cbv1.Session.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.Session} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.Session}
 */
proto.cbv1.Session.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.Session.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.Session.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.Session} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Session.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.cbv1);
