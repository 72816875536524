import { Component, OnInit } from '@angular/core';
import * as LogRocket from 'logrocket';
import { filter, take } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { IntercomService } from '~services/intercom.service';
import { fadeAnimation } from './router-animations';
import { AuthService } from './services/auth.service';
import { RouterStateService } from './services/router-state.service';

@Component({
  animations: [fadeAnimation],
  selector: 'ct-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public title = 'HAULi';

  constructor(
    private _routerState: RouterStateService,
    public authService: AuthService,
    public intercom: IntercomService,
  ) {
    this.enableLogRocket();
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  public isE2eTesting() {
    return JSON.parse(localStorage.getItem('e2e')) === true;
  }

  private enableLogRocket() {
    if (window.location.hostname === 'localhost') {
      return;
    }
    const logRocketOptions = {};
    if (environment.production) {
      LogRocket.init('stkslu/hauli-prod', logRocketOptions);
    }

    this.authService.isLoggedIn$
      .pipe(
        filter((t) => t),
        take(1),
      )
      .subscribe(async (isLoggedIn) => {
        if (isLoggedIn) {
          const accountName = await this.authService.accountName$.pipe(take(1)).toPromise();
          const user = this.authService.user;
          LogRocket.identify(user.uid, {
            accountName: accountName,
            email: user.email,
            name: user.displayName,
            phone: user.phoneNumber,
          });
        }
      });
  }

  public ngOnInit(): void {}
}
