import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '~environments/environment';
import { VERSION } from '~environments/version';

Sentry.init({
  dsn: 'https://1104847c833540429588e24fd7d73b7c@sentry.io/2445057',
  environment: environment.environment,
  release: `${VERSION.version}.${VERSION.hash}`,
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  public handleError(error) {
    // if ((error.originalError && error.originalError.stack) || error.stack) {
    //   Sentry.captureException(error.originalError || error);
    // } else {
    //   Sentry.captureMessage(error.message || error);
    // }
    // // If we want to prompt the user
    // // const eventId = Sentry.captureException(error.originalError || error);
    // // Sentry.showReportDialog({ eventId });
    console.error(error);
  }
}
