import { OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
var DashboardCardComponent = /** @class */ (function () {
    function DashboardCardComponent() {
        this.isLoading = false;
        this.showVisibilityToggle = false;
        this.visible$$ = new BehaviorSubject(true);
        this.visible$ = this.visible$$.asObservable();
    }
    Object.defineProperty(DashboardCardComponent.prototype, "localStorageKeyWithPrefix", {
        get: function () {
            if (this.localStorageKey) {
                return "card-start-visible:" + this.localStorageKey;
            }
        },
        enumerable: true,
        configurable: true
    });
    DashboardCardComponent.prototype.ngOnInit = function () {
        if (this.localStorageKeyWithPrefix) {
            var storageSetting = localStorage.getItem(this.localStorageKeyWithPrefix);
            var visible = false;
            // No setting from the user, default to visible
            if (storageSetting === null) {
                visible = true;
            }
            else {
                // The user has an existing setting,  use that
                visible = storageSetting === 'true';
            }
            this.visible$$.next(visible);
        }
    };
    DashboardCardComponent.prototype.toggleVisiblity = function () {
        var newState = !this.visible$$.value;
        this.visible$$.next(newState);
        if (this.localStorageKeyWithPrefix) {
            localStorage.setItem(this.localStorageKeyWithPrefix, "" + newState);
        }
    };
    return DashboardCardComponent;
}());
export { DashboardCardComponent };
