import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'ct-confirm-cancel',
  styleUrls: ['./confirm-cancel.component.scss'],
  templateUrl: './confirm-cancel.component.html',
})
export class ConfirmCancelComponent implements OnInit {
  @Input() public name = '';
  @Output() public cancelConfirmed = new EventEmitter();

  constructor(private matDialogRef: MatDialogRef<ConfirmCancelComponent>) {}

  public ngOnInit() {}

  public cancel() {
    this.cancelConfirmed.emit(false);
    this.matDialogRef.close(false);
  }

  public cancelConfirm() {
    this.cancelConfirmed.emit(true);
    this.matDialogRef.close(true);
  }
}
