import * as moment from 'moment';
import { MappableOrder, Order, Task, TaskSummary } from '~proto/order/order_pb';
import { OrderStatus, OrderStatusMap, TaskType } from '~proto/types/types_pb';

export function orderSiteName(order: Order.AsObject): string {
  if (!order) {
    return null;
  }
  const task = getLastDropoffTask(order);
  return task.site.name;
}

export function getLastDropoffTask(order: Order.AsObject): Task.AsObject {
  let task: Task.AsObject;
  const dropOffs = order.tasksList.filter((tasks) => tasks.type === TaskType.TASK_TYPE_DROPOFF);
  if (dropOffs.length) {
    dropOffs.sort((a, b) => a.sequence - b.sequence);
    task = dropOffs[dropOffs.length - 1];
  }
  if (!task) {
    task = order.tasksList[0];
  }
  return task;
}

export function isPending(order: Order.AsObject | MappableOrder.AsObject): boolean {
  return !isInProgress(order) && !isCompleted(order);
}

export function isInProgress(order: Order.AsObject | MappableOrder.AsObject): boolean {
  const inProgressStatuses: OrderStatusMap[keyof OrderStatusMap][] = [
    OrderStatus.ORDER_STATUS_DISPATCHED,
    OrderStatus.ORDER_STATUS_DRIVER_ACCEPTED,
  ];
  return inProgressStatuses.includes(order.status);
}

export function isCompleted(order: Order.AsObject | MappableOrder.AsObject): boolean {
  const completedStatuses: OrderStatusMap[keyof OrderStatusMap][] = [
    OrderStatus.ORDER_STATUS_CANCELLED,
    OrderStatus.ORDER_STATUS_COMPLETED,
    OrderStatus.ORDER_STATUS_DECLINED,
  ];
  return completedStatuses.includes(order.status);
}
export function getStatus(order: Order.AsObject): 'pending' | 'in-progress' | 'completed' {
  return isCompleted(order) ? 'completed' : isInProgress(order) ? 'in-progress' : 'pending';
}

export function getInProgressPickupTask(order: Order.AsObject): Task.AsObject {
  let task: Task.AsObject;
  const pickUps = order.tasksList.filter((tasks) => tasks.type === TaskType.TASK_TYPE_PICKUP);
  if (pickUps.length) {
    pickUps.sort((a, b) => a.sequence - b.sequence);
    task = pickUps[pickUps.length - 1];
  }
  if (!task) {
    task = order.tasksList[0];
  }
  return task;
}

export function getBillableTask(order: Order.AsObject): Task.AsObject {
  let task = order.tasksList.find((tasks) => tasks.isBillable);
  if (!task) {
    task = order.tasksList[0];
  }
  return task;
}

export function orderIsToday(scheduledUnix: number): boolean {
  const today = moment();
  return moment.unix(scheduledUnix).isSame(today, 'day');
}

export function getClosedSiteForTask(order: Order.AsObject): Task.AsObject {
  const closedSite = order.tasksList.find((task) => task.site && task.site.expectedClosed);
  if (!closedSite) {
    return null;
  }
  return closedSite;
}

export function getClosedSiteForTaskForMappableOrder(order: MappableOrder.AsObject): TaskSummary.AsObject {
  const closedSite = order.taskSummariesList.find((task) => task.site && task.site.expectedClosed);
  if (!closedSite) {
    return null;
  }
  return closedSite;
}
