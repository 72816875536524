import { Component, Input, OnInit } from '@angular/core';
import { CalendarDateFormatter, CalendarUtils, DAYS_OF_WEEK } from 'angular-calendar';
import { GetMonthViewArgs, MonthView } from 'calendar-utils';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { assignedOrderPluralMapping } from '~plural/assigned-orders';
import { orderPluralMapping } from '~plural/orders';
import { LMOCalendarStats } from '~proto/site/site_pb';
import { VisibilityService } from '../../services/visibility.service';
import { CustomDateFormatter } from './custom-date-formatter.provider';

// weekStartsOn option is ignored when using moment, as it needs to be configured globally for the moment locale
moment.updateLocale('en', {
  week: {
    dow: DAYS_OF_WEEK.MONDAY,
    doy: 0,
  },
});

// utility to start calendar at current date, instead of 1st of current month
export class MyCalendarUtils extends CalendarUtils {
  public getMonthView(args: GetMonthViewArgs): MonthView {
    args.viewStart = args.viewDate;
    args.viewEnd = moment(args.viewDate)
      .add(4, 'weeks')
      .toDate();
    return super.getMonthView(args);
  }
}

@Component({
  providers: [
    {
      provide: CalendarUtils,
      useClass: MyCalendarUtils,
    },
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
  selector: 'ct-lmo-calendar',
  styleUrls: ['./lmo-calendar.component.scss'],
  templateUrl: './lmo-calendar.component.html',
})
export class LmoCalendarComponent implements OnInit {
  @Input() public localStorageKey: string;
  public view = 'month';
  public viewDate: Date = new Date();
  public calendarStats$: Observable<LMOCalendarStats.AsObject[]>;
  public calendarStatsMap$: Observable<Record<string, LMOCalendarStats.AsObject>>;
  public orderPlural = orderPluralMapping;
  public assignedOrderPlural = assignedOrderPluralMapping;
  public innerWidth: any;
  // mobile/tablet view uses this visible value to toggle calendar visibility - mobile view should collapse vertically
  private visible$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public visible$: Observable<boolean> = this.visible$$.asObservable();
  // desktop view uses this calendarHidden value from visibilityService to toggle calendar visibility - desktop view should collapse sideways
  public calendarHidden$: Observable<boolean>;
  private get localStorageKeyWithPrefix(): string {
    if (this.localStorageKey) {
      return `card-start-visible:${this.localStorageKey}`;
    }
  }

  constructor(private siteService: JobSitesService, private visibilityService: VisibilityService) {}

  public ngOnInit() {
    this.innerWidth = window.innerWidth;

    this.calendarStats$ = this.siteService.siteCalendarStats$;
    this.calendarHidden$ = this.visibilityService.calendarhidden$;
    if (this.localStorageKeyWithPrefix) {
      const storageSetting = localStorage.getItem(this.localStorageKeyWithPrefix);
      let visible = false;
      // No setting from the user, default to visible
      if (storageSetting === null) {
        visible = true;
      } else {
        // The user has an existing setting,  use that
        visible = storageSetting === 'true';
      }
      this.visible$$.next(visible);
      if (visible === false) {
        this.visibilityService.toggleCalendarVisibility();
      }
    }

    this.calendarStatsMap$ = this.siteService.siteCalendarStats$.pipe(
      map((stats) => {
        return stats.reduce((acc, stat) => {
          acc[`${stat.year}${stat.month}${stat.date}`] = stat;
          return acc;
        }, {});
      }),
    );
  }

  public getStat$(date: string): Observable<LMOCalendarStats.AsObject> {
    const m = moment(date);
    return this.calendarStatsMap$.pipe(map((stats) => stats && stats[`${m.year()}${m.month() + 1}${m.date()}`]));
  }

  public toggleMobileVisiblity() {
    const newState = !this.visible$$.value;
    this.visible$$.next(newState);
    this.visibilityService.toggleCalendarVisibility();
    if (this.localStorageKeyWithPrefix) {
      localStorage.setItem(this.localStorageKeyWithPrefix, `${newState}`);
    }
  }

  public toggleVisibility(calendarHidden: boolean) {
    this.visible$$.next(calendarHidden);
    if (this.localStorageKeyWithPrefix) {
      localStorage.setItem(this.localStorageKeyWithPrefix, `${calendarHidden}`);
    }
    this.visibilityService.toggleCalendarVisibility();
  }

  public getDateUrl(dateString: string): string[] {
    const date = moment(dateString);
    return ['/', 'lmo', 'jobs', 'calendar', `${date.year()}`, `${date.month() + 1}`, `${date.date()}`];
  }
}
