import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { UserDetailsForIntercomRequest, UserDetailsForIntercomResponse } from '~proto/user/user_api_pb';
import { UserAPI } from '~proto/user/user_api_pb_service';
import { AuthService } from '~services/auth.service';
import { GrpcService } from '~services/grpc.service';
import { RouterStateService } from '~services/router-state.service';

declare const Intercom: any;

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor(
    private routerState: RouterStateService,
    private authService: AuthService,
    private grpcService: GrpcService,
  ) {
    this.listenForLoggedIn();
    this.listenToRouterStateChanges();
  }

  public show() {
    if (hasIntercom()) {
      Intercom('show');
    }
  }

  public showMessages() {
    if (hasIntercom()) {
      Intercom('showMessages');
    }
  }

  public showNewMessage() {
    if (hasIntercom()) {
      Intercom('showNewMessage');
    }
  }

  public setIsUsingNewBillingPortal(isUsing: boolean) {
    if (hasIntercom()) {
      Intercom('update', { using_new_billing: isUsing });
    }
  }

  private async boot() {
    const request = new UserDetailsForIntercomRequest();
    const intercomDetails = await (this.grpcService.invoke$(UserAPI.UserDetailsForIntercom, request) as Observable<
      UserDetailsForIntercomResponse
    >).toPromise();
    const user = this.authService.getUserFromCache();
    if (user && user.id && hasIntercom()) {
      const intercomSettings = {
        app_id: environment.environment === 'prod' ? 'bijgcgew' : 'v2sebl2g',
        company: null as any,
        created_at: new Date(intercomDetails.getUserDetails().getUserCreateTime()).getTime() / 1000,
        email: user.email,
        name: user.name,
        product: 'HAULi',
        user_id: user.id,
      };
      intercomSettings.company = {
        id: intercomDetails.getUserDetails().getCompanyId(),
        name: intercomDetails.getUserDetails().getCompanyName(),
        type: intercomDetails.getUserDetails().getCompanyType(),
      };
      Intercom('boot', intercomSettings);
    }
  }

  private shutdown() {
    if (hasIntercom()) {
      Intercom('shutdown');
    }
  }

  private listenToRouterStateChanges() {
    this.routerState.routerState$
      .pipe(
        map((state) => state.url),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        if (hasIntercom()) {
          Intercom('update');
        }
      });
  }

  private listenForLoggedIn() {
    this.authService.isLoggedIn$.pipe(distinctUntilChanged()).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.boot();
      } else {
        this.shutdown();
      }
    });
  }
}

function hasIntercom() {
  return typeof Intercom !== 'undefined';
}
