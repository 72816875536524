import * as tslib_1 from "tslib";
import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, map, tap, throttleTime } from 'rxjs/operators';
import { NotificationUserPreferencesRequest, } from '~proto/notification/notification_api_pb';
import { NotificationAPI } from '~proto/notification/notification_api_pb_service';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import * as i0 from "@angular/core";
import * as i1 from "./grpc.service";
import * as i2 from "@angular/material/snack-bar";
var NotificationsService = /** @class */ (function () {
    function NotificationsService(grpcService, snackBar) {
        var _this = this;
        this.grpcService = grpcService;
        this.snackBar = snackBar;
        this.preferencesThrottle$$ = new Subject();
        this.preferences$$ = new BehaviorSubject({});
        this.preferencesThrottle$$.pipe(throttleTime(100)).subscribe(function () { return _this._loadPreferences(); });
        this.loadPreferences();
    }
    Object.defineProperty(NotificationsService.prototype, "preferences$", {
        get: function () {
            this.loadPreferences();
            return this.preferences$$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NotificationsService.prototype, "groups$", {
        get: function () {
            this.loadPreferences();
            return this.preferences$$.asObservable().pipe(map(function (prefs) { return convertPrefsToGroups(prefs); }));
        },
        enumerable: true,
        configurable: true
    });
    NotificationsService.prototype.updatePreferences$ = function (request, silent) {
        var _this = this;
        if (silent === void 0) { silent = false; }
        var current = tslib_1.__assign({}, this.preferences$$.value);
        request.toObject().updatesList.forEach(function (r) {
            current[r.id] = tslib_1.__assign({}, current[r.id], { loading: true });
        });
        this.preferences$$.next(current);
        return this.grpcService.invoke$(NotificationAPI.UpdatePreference, request).pipe(map(function (response) { return response.toObject().preferencesList; }), tap(function (preferenceList) {
            var asLoading = preferenceList.map(function (pref) { return (tslib_1.__assign({}, pref, { loading: false })); });
            _this.preferences$$.next(idArrayToRecord(asLoading));
        }), tap(function () {
            if (!silent) {
                _this.snackBar.open('Preferences Updated', null, { duration: 5000 });
            }
        }), catchError(function () {
            request.toObject().updatesList.forEach(function (r) {
                current[r.id] = tslib_1.__assign({}, current[r.id], { loading: false });
            });
            return of(null);
        }));
    };
    NotificationsService.prototype.loadPreferences = function () {
        this.preferencesThrottle$$.next();
    };
    NotificationsService.prototype._loadPreferences = function () {
        var _this = this;
        var request = new NotificationUserPreferencesRequest();
        this.grpcService
            .invoke$(NotificationAPI.ListPreferences, request)
            .subscribe(function (prefs) {
            var asLoading = prefs
                .toObject()
                .preferencesList.map(function (pref) { return (tslib_1.__assign({}, pref, { loading: false })); });
            _this.preferences$$.next(idArrayToRecord(asLoading));
        });
    };
    NotificationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationsService_Factory() { return new NotificationsService(i0.ɵɵinject(i1.GrpcService), i0.ɵɵinject(i2.MatSnackBar)); }, token: NotificationsService, providedIn: "root" });
    return NotificationsService;
}());
export { NotificationsService };
function convertPrefsToGroups(prefs) {
    var groupsMap = {};
    Object.values(prefs).forEach(function (pref) {
        var groupName = pref.notificationType.notificationGroup.name;
        if (!groupsMap[groupName]) {
            groupsMap[groupName] = {
                name: groupName,
                preferences: [],
            };
        }
        groupsMap[groupName].preferences.push(pref);
    });
    var groups = Object.values(groupsMap);
    groups.forEach(function (group) {
        return group.preferences.sort(function (a, b) { return a.notificationType.name.localeCompare(b.notificationType.name); });
    });
    groups.sort(function (a, b) { return a.name.localeCompare(b.name); });
    return groups;
}
