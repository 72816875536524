import { ElementRef, Renderer2, } from '@angular/core';
var CtButtonDirective = /** @class */ (function () {
    function CtButtonDirective() {
        this.ctButton = true;
        this.secondary = true;
    }
    return CtButtonDirective;
}());
export { CtButtonDirective };
var CtSecondaryButtonDirective = /** @class */ (function () {
    function CtSecondaryButtonDirective() {
        this.ctButton = true;
        this.secondary = true;
    }
    return CtSecondaryButtonDirective;
}());
export { CtSecondaryButtonDirective };
var CtGrayButtonDirective = /** @class */ (function () {
    function CtGrayButtonDirective() {
        this.ctButton = true;
        this.secondary = true;
    }
    return CtGrayButtonDirective;
}());
export { CtGrayButtonDirective };
var CtDarkButtonDirective = /** @class */ (function () {
    function CtDarkButtonDirective() {
        this.ctButton = true;
        this.secondary = true;
    }
    return CtDarkButtonDirective;
}());
export { CtDarkButtonDirective };
var CtDangerButtonDirective = /** @class */ (function () {
    function CtDangerButtonDirective() {
        this.ctButton = true;
        this.secondary = true;
    }
    return CtDangerButtonDirective;
}());
export { CtDangerButtonDirective };
var ButtonComponent = /** @class */ (function () {
    function ButtonComponent(el, renderer) {
        this.el = el;
        this.renderer = renderer;
    }
    Object.defineProperty(ButtonComponent.prototype, "disabled", {
        set: function (disabled) {
            this.renderer.setProperty(this.el.nativeElement, 'disabled', disabled);
        },
        enumerable: true,
        configurable: true
    });
    return ButtonComponent;
}());
export { ButtonComponent };
