import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContractsService } from '~lmo/services/contracts.service';
import { VendorContract, VendorContractType } from '~proto/contracts/contracts_pb';
import { trackById } from '~utilities/trackById';

interface ContractTypeWithContractCount extends VendorContractType.AsObject {
  numberOfContracts: number;
}

function getNumberOfContracts(contracts: VendorContract.AsObject[], type: VendorContractType.AsObject): number {
  return contracts.filter((contract) => {
    if (contract.contractType) {
      return contract.contractType.id === type.id;
    }
    return false;
  }).length;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-lmo-trucking-contract-types',
  styleUrls: ['./lmo-trucking-contracts-type.component.scss'],
  templateUrl: './lmo-trucking-contracts-type.component.html',
})
export class LmoTruckingContractsTypeComponent implements OnInit {
  public trackById = trackById;
  public contractTypes$: Observable<ContractTypeWithContractCount[]>;
  public fuelUploadCategoryMap = {
    1: 'Per Mile Fixed',
    2: 'Percentage Hike',
  };

  constructor(private contractService: ContractsService) {}

  public ngOnInit() {
    this.contractTypes$ = combineLatest([
      this.contractService.nonExpiredContracts$,
      this.contractService.contractTypes$,
    ]).pipe(
      map(([contracts, contractTypes]) => {
        return contractTypes.map((type) => {
          return {
            ...type,
            numberOfContracts: getNumberOfContracts(contracts, type),
          } as ContractTypeWithContractCount;
        });
      }),
    );
  }

  public archiveContract(contract: VendorContractType.AsObject) {
    this.contractService.archiveVendorContract(contract);
  }
}
