import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { FilePreviewModalComponent } from '~common/file-preview-modal/file-preview-modal.component';
import { OrderAttachment } from '~proto/order/order_pb';
import { AttachmentCategory } from '~proto/types/types_pb';
import { trackById } from '~utilities/trackById';
var MAX_FILE_SIZE = 24 * 1024 * 1024;
var OrderDetailAttachmentComponent = /** @class */ (function () {
    function OrderDetailAttachmentComponent(snackbar, fileUploadService, orderService, dialog, cdr) {
        this.snackbar = snackbar;
        this.fileUploadService = fileUploadService;
        this.orderService = orderService;
        this.dialog = dialog;
        this.cdr = cdr;
        this.trackById = trackById;
        this.bolAttachments = [];
        this.truckTicketAttachments = [];
        this.otherAttachments = [];
        this.uploading = '';
        this.allowedFileTypes = ['pdf', 'svg', 'png', 'jpg', 'xls', 'xlsx', 'csv', 'doc', 'docx', 'ppt'];
    }
    OrderDetailAttachmentComponent.prototype.ngOnInit = function () {
        this.separateFiles();
    };
    OrderDetailAttachmentComponent.prototype.separateFiles = function () {
        var _this = this;
        this.bolAttachments = [];
        this.truckTicketAttachments = [];
        this.otherAttachments = [];
        this.attachmentsList.forEach(function (file) {
            _this.updateFile(file);
        });
    };
    OrderDetailAttachmentComponent.prototype.updateFile = function (file) {
        switch (file.category) {
            case AttachmentCategory.ATTACHMENT_CATEGORY_BOL_IMAGE:
                this.bolAttachments.push(file);
                break;
            case AttachmentCategory.ATTACHMENT_CATEGORY_TICKET_IMAGE:
                this.truckTicketAttachments.push(file);
                break;
            case AttachmentCategory.ATTACHMENT_CATEGORY_OTHER_IMAGE:
                this.otherAttachments.push(file);
                break;
        }
    };
    OrderDetailAttachmentComponent.prototype.onFileChange = function (event, category) {
        if (event.target.files && event.target.files.length) {
            var _a = tslib_1.__read(event.target.files, 1), file = _a[0];
            if (file.size > MAX_FILE_SIZE) {
                this.snackbar.open('File Size cannot be greater than 25 MB', null, {
                    duration: 2500,
                });
            }
            else {
                this.fileToUpload = file;
                this.uploadFile(category);
            }
        }
    };
    OrderDetailAttachmentComponent.prototype.deleteFile = function (fileId, event) {
        var _this = this;
        event.stopPropagation();
        this.orderService.deleteAttachment$(fileId).subscribe(function (resp) {
            _this.attachmentsList = tslib_1.__spread(resp);
            _this.separateFiles();
            _this.snackbar.open('File Successfully Deleted', null, {
                duration: 2000,
            });
            _this.cdr.detectChanges();
        }, function (err) {
            _this.snackbar.open('File Delete Failed', null, {
                duration: 2000,
                panelClass: ['snackbar-error'],
            });
            _this.cdr.detectChanges();
        });
    };
    OrderDetailAttachmentComponent.prototype.previewFile = function (file) {
        this.dialog.open(FilePreviewModalComponent, {
            data: file,
            height: '75vh',
            width: '75vw',
        });
    };
    OrderDetailAttachmentComponent.prototype.uploadFile = function (category) {
        var _this = this;
        this.uploading = category;
        this.fileUploadService.uploadFile$(this.fileToUpload, this.orderId, category).subscribe(function (result) {
            _this.uploading = '';
            var newImage = new OrderAttachment();
            newImage.setId(result.id);
            newImage.setOrderId(result.order_id);
            newImage.setFileName(result.file_name);
            newImage.setCanDelete(result.can_delete);
            newImage.setUploadedUnix(result.uploaded_unix);
            newImage.setCategory(result.category);
            newImage.setUploadedBy(result.uploaded_by);
            _this.updateFile(newImage.toObject());
            _this.snackbar.open('File uploaded Successfully', null, {
                duration: 5000,
            });
            _this.cdr.detectChanges();
        }, function (err) {
            _this.uploading = '';
            _this.snackbar.open('File uploaded failed', null, {
                duration: 5000,
            });
            _this.cdr.detectChanges();
        });
    };
    OrderDetailAttachmentComponent.prototype.getFileIcon = function (fileName) {
        var filePathArray = fileName.toLowerCase().split('/');
        var filename = filePathArray[filePathArray.length - 1];
        var fileNameArray = filename.split('.');
        var extension = fileNameArray[fileNameArray.length - 1];
        if (this.allowedFileTypes.includes(extension)) {
            if (extension === 'xlsx' || extension === 'ppt') {
                extension = 'xls';
            }
            if (extension === 'docx') {
                extension = 'doc';
            }
            return "assets/icons/file-types/" + extension + ".svg";
        }
        else {
            return "assets/icons/file-types/other.png";
        }
    };
    return OrderDetailAttachmentComponent;
}());
export { OrderDetailAttachmentComponent };
