<div class="confirm-cancel">
  <ct-mini-modal [title]="data.title">
    <footer>
      <div class="content">
        <button ct-button (click)="cancel()">{{ data.declineButtonText }}</button>
        <button ct-danger-button (click)="cancelConfirm()">{{ data.acceptButtonText }}</button>
      </div>
    </footer>
  </ct-mini-modal>
</div>
