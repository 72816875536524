import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { UpdatePayloadRequest } from '~proto/payload/payload_api_pb';
import { recordToArray } from '~utilities/recordToArray';
import { trackById } from '~utilities/trackById';
var LmoPayloadManagementEditComponent = /** @class */ (function () {
    function LmoPayloadManagementEditComponent(fb, payloadService, constantsService, driverCertificationService, router, snackbar) {
        this.fb = fb;
        this.payloadService = payloadService;
        this.constantsService = constantsService;
        this.driverCertificationService = driverCertificationService;
        this.router = router;
        this.snackbar = snackbar;
        this.networkActive$ = new BehaviorSubject(false);
        this.trackById = trackById;
    }
    LmoPayloadManagementEditComponent.prototype.formValue = function () {
        return this.formGroup.value;
    };
    LmoPayloadManagementEditComponent.prototype.ngOnInit = function () {
        this.setPayload().then();
        this.payloadTypes = recordToArray(this.constantsService.payloadTypes).sort(function (a, b) { return a.name.localeCompare(b.name); });
        this.units = recordToArray(this.constantsService.units).sort(function (a, b) { return a.name.localeCompare(b.name); });
        this.driverCertifications$ = this.driverCertificationService.driverCertifications$;
        this.formGroup = this.fb.group({
            driverCertificationIds: [[]],
            name: ['', [Validators.required]],
            payloadTypeId: ['', [Validators.required]],
            unitId: ['', [Validators.required]],
        });
    };
    LmoPayloadManagementEditComponent.prototype.setPayload = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.payloadService.currentPayload$
                                .pipe(filter(function (payload) { return !!payload; }), take(1))
                                .toPromise()];
                    case 1:
                        _a.payload = _b.sent();
                        this.formGroup.patchValue({
                            driverCertificationIds: this.payload.driverCertificationsList.map(function (cert) { return cert.id; }),
                            name: this.payload.name,
                            payloadTypeId: this.payload.type.id,
                            unitId: this.payload.unit.id,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    LmoPayloadManagementEditComponent.prototype.updatePayload = function () {
        var _this = this;
        this.formGroup.markAllAsTouched();
        if (this.formGroup.invalid) {
            return;
        }
        var formValue = this.formValue();
        this.networkActive$.next(true);
        var updatePayloadRequest = new UpdatePayloadRequest();
        updatePayloadRequest.setId(this.payload.id);
        updatePayloadRequest.setName(formValue.name);
        updatePayloadRequest.setPayloadTypeId(formValue.payloadTypeId);
        updatePayloadRequest.setUnitId(formValue.unitId);
        updatePayloadRequest.setDriverCertificationIdsList(formValue.driverCertificationIds);
        var mask = new FieldMask();
        mask.setPathsList(['name', 'payloadTypeId', 'unitId']);
        updatePayloadRequest.setMask(mask);
        this.payloadService.updatePayload$(updatePayloadRequest).subscribe(function () {
            _this.networkActive$.next(false);
            _this.router.navigate(['/', 'lmo', 'payloads']).then();
            _this.snackbar.open('Payload Updated', null, {
                duration: 2000,
            });
        });
    };
    return LmoPayloadManagementEditComponent;
}());
export { LmoPayloadManagementEditComponent };
