import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AccountTypeMap } from '~proto/types/types_pb';
import { AuthService } from '~services/auth.service';
import { RoleService } from '~services/role.service';

@Injectable({
  providedIn: 'root',
})
export class IsAccountTypeGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private roleService: RoleService) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!next.data || !next.data.accountTypes) {
      console.error('Route data did not include accountTypes');
      return false;
    }
    return this.authService.isAccountType$(next.data.accountTypes as AccountTypeMap[keyof AccountTypeMap]).pipe(
      tap((isAccountType) => {
        if (!isAccountType) {
          console.error('Not account type:', next.data.accountTypes);
          setTimeout(() => this.router.navigate(['/', 'login']), 100);
        }
        this.checkFirstTimeLogin();
      }),
    );
  }

  private checkFirstTimeLogin() {
    this.roleService.isFirstTimeUser$.subscribe((isFirstTime) => {
      if (isFirstTime) {
        this.router.navigate(['/', 'update-password']).then();
      }
    });
  }
}
