import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';
import { BusinessLineService } from '~lmo/services/business-line.service';
import { BusinessLine } from '~proto/businessline/businessline_pb';
import { UpdateEmailUserV2Request, UpdateUserV2Response } from '~proto/user/user_api_pb';
import { RoleService } from '~services/role.service';
import { RouterStateService } from '~services/router-state.service';
import { trackById } from '~utilities/trackById';
import { UserV2 } from '../../../../proto/user/user/user_pb';
import { UsersService } from '../../services/users.service';
import { UserPermissionsMap } from './../../../../proto/cybertron/types/types_pb.d';

interface FormValue {
  name: string;
  email: string;
  uRole: 1 | 2 | 3 | 4;
  businessLine: Array<number>;
  permission: Array<0 | 1 | 2>;
}

interface UserRole {
  id: number;
  name: string;
}

@Component({
  selector: 'ct-lmo-user-management-edit',
  styleUrls: ['./lmo-user-management-edit.component.scss'],
  templateUrl: './lmo-user-management-edit.component.html',
})
export class LmoUserManagementEditComponent implements OnInit {
  public networkActive$ = new BehaviorSubject(false);
  public formGroup: FormGroup;
  public user: UserV2.AsObject;
  public trackById = trackById;
  public backUrl$: Observable<string[]>;
  public businessLines$: Observable<BusinessLine.AsObject[]>;
  public businessLinesAttachedToUser: BusinessLine.AsObject[];
  public isBusinessLineEnabled$$ = new BehaviorSubject<Boolean>(false);
  public userPermissions$: Observable<UserPermissionsMap[keyof UserPermissionsMap][]>;

  public roles = {
    1: 'Admin',
    2: 'Default',
    4: 'View only',
  };
  public setUserRoles: UserRole[] = [
    { id: 1, name: 'Admin' },
    { id: 2, name: 'Default' },
    { id: 4, name: 'View Only' },
  ];

  public setUserPermissions = {
    1: 'Can Approve Ticket',
    2: 'Can Approve Invoice',
    4: 'Can Create Cost Center',
    5: 'Can Modify Users',
  };

  public userRoles = Object.values(this.roles);

  public errorMessages = {
    hasRestrictedCharacter: 'only dash, dot, plus, underscore are allowed',
    lessThanTwoCharacter: 'minimum 2 character are required',
    nameRequired: '*required',
  };

  public get nameInputValueCheck() {
    const inputVal = this.formGroup.get('name').value || '';
    const inputLength = inputVal.length;
    const isRequired = inputLength === 0 ? this.errorMessages.nameRequired : '_';
    const errorMsg = inputLength > 0 && inputLength < 2 ? this.errorMessages.lessThanTwoCharacter : isRequired;
    return errorMsg;
  }

  public get emailValueCheck() {
    const inputVal = this.formGroup.get('email').value || '';
    const inputLength = inputVal.length;
    const isRequired = inputLength === 0 ? this.errorMessages.nameRequired : '_';
    return isRequired;
  }

  constructor(
    private fb: FormBuilder,
    private userService: UsersService,
    private router: Router,
    private snackbar: MatSnackBar,
    private routerState: RouterStateService,
    private businessLineService: BusinessLineService,
    private roleService: RoleService,
  ) {}

  private formValue(): FormValue {
    return this.formGroup.value;
  }

  public ngOnInit() {
    this.setUser().then();

    this.backUrl$ = this.routerState.backUrlOr$(['../../']);

    this.formGroup = this.fb.group({
      businessLine: [[]],
      email: ['', [Validators.required]],
      name: ['', [Validators.required]],
      permission: [[]],
      uRole: [[]],
    });
    this.checkIfBusinessLineEnabled();
    if (this.isBusinessLineEnabled$$.value) {
      this.businessLines$ = this.businessLineService.businessLines$;
    }
    this.userPermissions$ = this.userService.userPermissions$;
  }

  public async setUser() {
    this.user = await this.userService.currentUser$
      .pipe(
        filter((user) => !!user),
        take(1),
      )
      .toPromise();

    if (this.isBusinessLineEnabled$$.value) {
      this.businessLineService
        .businessLinesAttachedToUser$(this.user.authId)
        .pipe(filter((businessLine) => !!businessLine))
        .subscribe((businessLine) => {
          this.formGroup.patchValue({
            businessLine: this.getBusinessLinesAttachedToUserIds(businessLine),
          });
        });
    }

    this.formGroup.patchValue({
      email: this.user.email,
      name: this.user.name,
      permission: this.user.permissionsList,
      uRole: this.getUserRole(this.user.role),
    });
  }

  public getUserRole(role: number): number {
    const userRoleObject = this.setUserRoles.filter((obj) => {
      return obj.id === role;
    });
    return userRoleObject[0].id;
  }

  public getBusinessLinesAttachedToUserIds(businessLines: BusinessLine.AsObject[]): Array<number> {
    return businessLines.map((businessLine) => businessLine.id);
  }

  public updateUser() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    const formValue = this.formValue();

    this.networkActive$.next(true);
    this.updateUserDetails$(formValue)
      .pipe(
        finalize(() => {
          this.networkActive$.next(false);
        }),
      )
      .subscribe(async () => {
        this.snackbar.open(`${formValue.name} updated!`, null, {
          duration: 2000,
        });

        const backUrl = await this.backUrl$.pipe(take(1)).toPromise();
        await this.router.navigate(backUrl);
      });
  }

  private checkIfBusinessLineEnabled() {
    this.roleService.isBusinessLineEnabled$.subscribe((isBusinessLineEnabled) => {
      this.isBusinessLineEnabled$$.next(isBusinessLineEnabled);
    });
  }

  private updateUserDetails$(formValue: FormValue): Observable<UpdateUserV2Response.AsObject> {
    if (formValue.uRole !== 1) {
      formValue.permission = [];
    }
    const updateEmailUserV2Request = new UpdateEmailUserV2Request();
    updateEmailUserV2Request.setName(formValue.name);
    updateEmailUserV2Request.setEmail(formValue.email.toLowerCase());
    updateEmailUserV2Request.setRole(formValue.uRole);
    updateEmailUserV2Request.setAuthId(this.user.authId);
    updateEmailUserV2Request.setBusinesslineidsList(formValue.businessLine);
    updateEmailUserV2Request.setPermissionsList(formValue.permission);

    return this.userService.updateUserDetails$(updateEmailUserV2Request);
  }
}
