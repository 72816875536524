import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, filter, finalize, map, startWith } from 'rxjs/operators';
import { ConfirmDialogComponent } from '~common/confirm-dialog/confirm-dialog.component';
var LmoBusinessLineManagementComponent = /** @class */ (function () {
    function LmoBusinessLineManagementComponent(businessLineService, dialog, snackBar, router, roleService) {
        this.businessLineService = businessLineService;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.router = router;
        this.roleService = roleService;
        this.businessLineSearch = new FormControl();
        this.networkActive$$ = new BehaviorSubject(false);
        this.displayedColumns = ['id', 'name', 'user_count', 'cost_center_count', 'actions'];
        this.isBusinessLineCostCenterRelationEnabled$$ = new BehaviorSubject(false);
    }
    LmoBusinessLineManagementComponent.prototype.ngOnInit = function () {
        this.businessLines$ = combineLatest([
            this.businessLineService.businessLines$,
            this.businessLineSearch.valueChanges.pipe(startWith(''), debounceTime(200)),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), businessLines = _b[0], searchTerm = _b[1];
            if (!searchTerm || searchTerm === '') {
                return businessLines;
            }
            return businessLines.filter(function (businessLine) {
                return businessLine.name.toLowerCase().includes(searchTerm.toLowerCase());
            });
        }), map(function (businessLines) {
            return new MatTableDataSource(businessLines);
        }));
        this.checkIsBusinessLineCostCenterRelationEnabled();
    };
    LmoBusinessLineManagementComponent.prototype.trackByBLId = function (_index, businessLine) {
        return businessLine.id;
    };
    LmoBusinessLineManagementComponent.prototype.editBusinessLine = function (row) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.router.navigate(['/lmo/', 'business-lines', row.id, 'edit'])];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LmoBusinessLineManagementComponent.prototype.checkIsBusinessLineCostCenterRelationEnabled = function () {
        var _this = this;
        this.roleService.isBusinessLineCostCenterRelationEnabled$.subscribe(function (isBLCCRelationEnabled) {
            _this.isBusinessLineCostCenterRelationEnabled$$.next(isBLCCRelationEnabled);
        });
    };
    LmoBusinessLineManagementComponent.prototype.archiveBusinessLine = function (businessLine, event) {
        var _this = this;
        event.stopPropagation();
        if (!businessLine.canbearchived) {
            this.snackBar.open('Business Line can not be archived as users are attached to it', null, {
                duration: 2000,
            });
            return;
        }
        var dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                acceptButtonText: 'Archive',
                declineButtonText: 'Cancel',
                title: "Are you sure you want to archive " + businessLine.name + " ?",
            },
        });
        dialogRef
            .afterClosed()
            .pipe(filter(function (data) { return !!data; }))
            .subscribe(function (result) {
            var id = businessLine.id;
            if (!id) {
                return;
            }
            _this.networkActive$$.next(true);
            _this.businessLineService
                .archiveBusinessLine$(id)
                .pipe(finalize(function () {
                _this.networkActive$$.next(false);
            }))
                .subscribe(function (response) {
                _this.snackBar.open('Business Line archived successfully', null, {
                    duration: 2000,
                });
            }, function (err) {
                // todo: check error message
                var error = err.error;
                _this.snackBar.open('Error:' + (error.Message ? error.Message : error), null, {
                    duration: 5000,
                });
            });
        });
    };
    return LmoBusinessLineManagementComponent;
}());
export { LmoBusinessLineManagementComponent };
