import * as moment from 'moment';
import { pick, sort } from 'remeda';
import { GroupedOrders, OrderCard, PayloadDetails } from 'src/app/models/type-with-order.model';
import {
  DispatcherCalendarOrderSummary,
  MappableOrder,
  MappableOrderCompleted,
  TaskSummary,
} from '~proto/order/order_pb';
import { Payload } from '~proto/payload/payload_pb';
import { IdName, OrderStatusMap, TaskStatus, TaskStatusMap, TaskType, TaskTypeMap } from '~proto/types/types_pb';
import { getClosedSiteForTaskForMappableOrder, orderIsToday } from '~utilities/orderHelpers';
import { getFirstAndLastTask, getFirstTask, getLastTask } from './getFirstTask';

const taskTypePrettyNameMap: Record<TaskTypeMap[keyof TaskTypeMap], string> = {
  [TaskType.TASK_TYPE_PICKUP]: 'En Route to Pickup',
  [TaskType.TASK_TYPE_DROPOFF]: 'En Route to Drop Off',
  [TaskType.TASK_TYPE_INVALID]: '',
  [TaskType.TASK_TYPE_RETURN_MATERIALS]: 'Return Trailer',
  [TaskType.TASK_TYPE_OTHER]: 'Other',
  [TaskType.TASK_TYPE_AD_HOC]: 'Ad Hoc',
  [TaskType.TASK_TYPE_ASSET_PICKUP]: 'Asset Pickup',
};

export function groupOrdersByTaskType<T>(
  orders: MappableOrder.AsObject[],
  taskStatus: TaskStatusMap[keyof TaskStatusMap],
): GroupedOrders<string>[] {
  const typeMap: Partial<Record<string, GroupedOrders<string>>> = {};
  orders.forEach((order) => {
    let foundTask = false;
    order.taskSummariesList.forEach((taskSummary) => {
      if (taskSummary.status === taskStatus) {
        foundTask = true;
        if (!typeMap[taskSummary.type]) {
          typeMap[taskSummary.type] = {
            groupHeader: taskTypePrettyNameMap[taskSummary.type],
            orderCards: [],
          };
        }
        const asOrderCard = convertMappableOrderToOrderCard(order);
        if (asOrderCard) {
          typeMap[taskSummary.type].orderCards.push(asOrderCard);
        }
      }
    });
    if (!foundTask) {
      const firstTask = getFirstTask(order.taskSummariesList);
      if (!typeMap[firstTask.type]) {
        typeMap[firstTask.type] = {
          groupHeader: taskTypePrettyNameMap[firstTask.type],
          orderCards: [],
        };
      }
      const asOrderCard = convertMappableOrderToOrderCard(order);
      if (asOrderCard) {
        typeMap[firstTask.type].orderCards.push(asOrderCard);
      }
    }
  });
  const groupedOrders = Object.values(typeMap)
    .filter((group) => group.orderCards.length > 0)
    .sort((a, b) => a.groupHeader.localeCompare(b.groupHeader));

  return groupedOrders;
}

export function groupOrdersByBillingTaskPayloadName<T>(orders: MappableOrder.AsObject[]): GroupedOrders<string>[] {
  const nameMap: Record<string, GroupedOrders<string>> = {};
  orders.forEach((order) => {
    if (!order.taskSummariesList || !order.taskSummariesList.length) {
      return;
    }

    let billableTasks = order.taskSummariesList.filter((task) => task.isBillable && task.payload);
    if (billableTasks.length === 0) {
      billableTasks = order.taskSummariesList.filter((task) => task.payload);
    }
    billableTasks.sort((a, b) => a.sequence - b.sequence);
    const currentTask = billableTasks[0];
    let payloadName = currentTask && currentTask.payload && currentTask.payload.name;
    if (!payloadName) {
      payloadName = 'No Payload';
    }
    if (!nameMap[payloadName]) {
      nameMap[payloadName] = {
        groupHeader: payloadName,
        orderCards: [],
      };
    }
    const asOrderCard = convertMappableOrderToOrderCard(order);
    if (asOrderCard) {
      nameMap[payloadName].orderCards.push(asOrderCard);
    }
  });
  const groupedOrders = Object.values(nameMap)
    .filter((group) => group.orderCards.length > 0)
    .sort((a, b) => a.groupHeader.localeCompare(b.groupHeader));
  return groupedOrders;
}

export function groupOrdersByOrderStatus<T>(
  orders: MappableOrder.AsObject[],
): GroupedOrders<OrderStatusMap[keyof OrderStatusMap]>[] {
  const statusMap: Partial<
    Record<OrderStatusMap[keyof OrderStatusMap], GroupedOrders<OrderStatusMap[keyof OrderStatusMap]>>
  > = {};
  orders.forEach((order) => {
    const orderStatus = order.status;

    // if (!orderStatus) {
    //   return;
    // }

    if (!statusMap[orderStatus]) {
      statusMap[orderStatus] = {
        groupHeader: orderStatus,
        orderCards: [],
      };
    }

    const asOrderCard = convertMappableOrderToOrderCardForLastSequence(order);
    if (asOrderCard) {
      statusMap[orderStatus].orderCards.push(asOrderCard);
    }
  });

  const groupedOrders = Object.values(statusMap)
    .filter((group) => group.orderCards.length > 0)
    .sort((a, b) => a.groupHeader - b.groupHeader);
  return groupedOrders;
}

export function convertMappableOrderToOrderCardForLastSequence(
  order: MappableOrder.AsObject | MappableOrderCompleted.AsObject,
): OrderCard {
  if (!order.taskSummariesList || !order.taskSummariesList.length) {
    return null;
  }
  const firstSequenceTask = getFirstTask(order.taskSummariesList);
  const lastSequenceTask = getLastTask(order.taskSummariesList);

  return {
    arrivedTime: lastSequenceTask.stats ? lastSequenceTask.stats.arrivalUnix : 0,
    currentTaskStatus: lastSequenceTask.status,
    displayId: order.displayId,
    driverName: order.driver && order.driver.user ? order.driver.user.name : '',
    eta:
      lastSequenceTask.stats && lastSequenceTask.stats.driverEta
        ? moment()
            .add(lastSequenceTask.stats.driverEta, 'minutes')
            .unix() * 1000
        : null,
    exceptionTitle: order.driver && order.driver.driverStatus && order.driver.driverStatus.exceptionTitle,
    exitTime: lastSequenceTask.stats ? lastSequenceTask.stats.exitUnix : 0,
    haulisPickVendorName: null,
    id: order.id,
    isAsap: order.isAsap,
    isAvailableToDispatch: (order as MappableOrder.AsObject).isAvailableToDispatch,
    isException: order.driver && order.driver.driverStatus && order.driver.driverStatus.isException,
    lastWaypointClientCreationUnix:
      order.driver && order.driver.lastWaypoint ? order.driver.lastWaypoint.clientCreatedUnix : 0,
    missingData: (order as MappableOrderCompleted.AsObject).missingDataList,
    nextSiteName: lastSequenceTask && lastSequenceTask.site ? lastSequenceTask.site.name : '',
    payloadDetails: getPayloadDetailsFromOrderSummary(order),
    saUniqueId: order.saUniqueId,
    scheduledUnix: lastSequenceTask.scheduledUnix,
    siteSequence: sort(order.taskSummariesList, sortTasks).map((task) => pick(task.site, ['id', 'name'])),
    siteSequenceName: getSiteSequenceName(firstSequenceTask, lastSequenceTask, order.taskSummariesList.length),
    status: order.status,
    statusUnix: order.statusUnix,
    tasks: order.taskSummariesList,
    trailerName: order.trailerName,
    truckName: order.truckName,
    upForGrabsStatus: (order as MappableOrder.AsObject).isUpForGrabs || null,
    upForGrabsUnix: (order as MappableOrder.AsObject).upForGrabsUnix * 1000 || 0,
    updatedUnix: order.updatedUnix,
    vendorName: order.vendorName,
  };
}

const activeTaskStatuses: TaskStatusMap[keyof TaskStatusMap][] = [
  TaskStatus.TASK_STATUS_IN_PROGRESS,
  TaskStatus.TASK_STATUS_QUEUED,
];

export function convertMappableOrderToOrderCard(
  order: MappableOrder.AsObject | MappableOrderCompleted.AsObject,
): OrderCard {
  if (!order.taskSummariesList || !order.taskSummariesList.length) {
    return null;
  }

  const [firstSequenceTask, lastSequenceTask] = getFirstAndLastTask(order.taskSummariesList);

  let firstTaskWithPayload =
    sort(order.taskSummariesList, (a, b) => a.sequence - b.sequence).find(
      (task) => task.status === TaskStatus.TASK_STATUS_IN_PROGRESS,
    ) || order.taskSummariesList.find((task) => task.sequence === 1);
  if (!firstTaskWithPayload.payload) {
    const allPayloadTasks = order.taskSummariesList.filter(
      (task) => task.payload && activeTaskStatuses.includes(task.status),
    );
    allPayloadTasks.sort((a, b) => a.sequence - b.sequence);
    if (allPayloadTasks.length) {
      firstTaskWithPayload = allPayloadTasks[0];
    }
  }

  return {
    arrivedTime: firstTaskWithPayload.stats ? firstTaskWithPayload.stats.arrivalUnix : 0,
    currentTaskStatus: firstTaskWithPayload.status,
    displayId: order.displayId,
    driverName: order.driver && order.driver.user ? order.driver.user.name : '',
    eta:
      firstTaskWithPayload.stats && firstTaskWithPayload.stats.driverEta
        ? moment()
            .add(firstTaskWithPayload.stats.driverEta, 'minutes')
            .unix() * 1000
        : null,
    exceptionTitle: order.driver && order.driver.driverStatus && order.driver.driverStatus.exceptionTitle,
    exitTime: firstTaskWithPayload.stats ? firstTaskWithPayload.stats.exitUnix : 0,
    fleetManagerDriverName: (order as MappableOrder.AsObject).fleetManagerPlan
      ? (order as MappableOrder.AsObject).fleetManagerPlan.driverName
      : '',
    fleetManagerDriverValid: (order as MappableOrder.AsObject).fleetManagerPlan
      ? (order as MappableOrder.AsObject).fleetManagerPlan.driverValid
      : false,
    haulisPickVendorName: null,
    id: order.id,
    isAsap: order.isAsap,
    isAvailableToDispatch: (order as MappableOrder.AsObject).isAvailableToDispatch,
    isException: order.driver && order.driver.driverStatus && order.driver.driverStatus.isException,
    isScheduledToday:
      (order as MappableOrder.AsObject).fleetManagerPlan &&
      (order as MappableOrder.AsObject).fleetManagerPlan.plannedStartUnix
        ? orderIsToday((order as MappableOrder.AsObject).fleetManagerPlan.plannedStartUnix)
        : orderIsToday(firstTaskWithPayload.scheduledUnix),
    lastWaypointClientCreationUnix:
      order.driver && order.driver.lastWaypoint ? order.driver.lastWaypoint.clientCreatedUnix : 0,
    minutesDelayed: (order as MappableOrder.AsObject).minutesStats
      ? (order as MappableOrder.AsObject).minutesStats.minutesDelayed
      : 0,
    minutesLate: (order as MappableOrder.AsObject).minutesStats
      ? (order as MappableOrder.AsObject).minutesStats.minutesLate
      : 0,
    minutesValid: (order as MappableOrder.AsObject).minutesStats
      ? (order as MappableOrder.AsObject).minutesStats.minutesValid
      : false,
    missingData: (order as MappableOrderCompleted.AsObject).missingDataList,
    nextSiteName: firstTaskWithPayload.site ? firstTaskWithPayload.site.name : null,
    payloadDetails: getPayloadDetailsFromOrderSummary(order),
    plannedStartUnix:
      (order as MappableOrder.AsObject).fleetManagerPlan &&
      (order as MappableOrder.AsObject).fleetManagerPlan.plannedStartUnix
        ? (order as MappableOrder.AsObject).fleetManagerPlan.plannedStartUnix
        : 0,
    saUniqueId: order.saUniqueId,
    scheduledUnix: firstTaskWithPayload.scheduledUnix,
    siteClosed: getClosedSiteForTaskForMappableOrder(order as MappableOrder.AsObject),
    siteSequence: sort(order.taskSummariesList, sortTasks).map((task) => pick(task.site, ['id', 'name'])),
    siteSequenceName: getSiteSequenceName(firstSequenceTask, lastSequenceTask, order.taskSummariesList.length),
    status: order.status,
    statusUnix: order.statusUnix,
    tasks: order.taskSummariesList,
    trailerName: order.trailerName,
    truckName: order.truckName,
    upForGrabsStatus: (order as MappableOrder.AsObject).isUpForGrabs || null,
    upForGrabsUnix: (order as MappableOrder.AsObject).upForGrabsUnix * 1000 || 0,
    updatedUnix: order.updatedUnix,
    vendorName: order.vendorName,
  };
}

interface HasTasksSummaries {
  taskSummariesList: TaskSummary.AsObject[];
}

export function getPayloadDetailsFromOrderSummary(order: HasTasksSummaries): PayloadDetails[] {
  return getPayloadDetailsFromOrder({ tasksList: order.taskSummariesList });
}

export function getPayloadDetailsFromCalendarOrder(
  order: Pick<DispatcherCalendarOrderSummary.AsObject, 'taskSummariesList'>,
): PayloadDetails[] {
  const fakeOrder: HasTasks = {
    tasksList: order.taskSummariesList.map((taskSummary) => ({
      subTasksList: taskSummary.subTasksList.map((subTaskSummary) => {
        const payload: Payload.AsObject = {
          createdUnix: 0,
          driverCertificationsList: [],
          favorited: false,
          frequentlyUsedQuantity: 0,
          id: subTaskSummary.payloadId,
          lastUsedQuantity: 0,
          name: subTaskSummary.payloadName,
          type: {
            id: subTaskSummary.payloadTypeId,
            name: subTaskSummary.payloadTypeName,
          },
          unit: {
            abbreviation: subTaskSummary.unitAbbreviation,
            id: -1,
            name: '',
          },
          updatedUnix: 0,
        };
        const subtaskType: IdName.AsObject = {
          id: -1,
          name: subTaskSummary.subTaskTypeName,
        };
        return {
          actualQuantity: subTaskSummary.actualQuantity,
          orderedQuantity: subTaskSummary.orderedQuantity,
          payload,
          type: subtaskType,
        };
      }),
    })),
  };
  return getPayloadDetailsFromOrder(fakeOrder);
}

interface HasTasks {
  tasksList: Array<{
    subTasksList: Array<{
      payload?: Payload.AsObject;
      type?: IdName.AsObject;
      actualQuantity: number;
      orderedQuantity: number;
    }>;
  }>;
}

export function getPayloadDetailsFromOrder(order: HasTasks): PayloadDetails[] {
  return Object.values(
    order.tasksList.reduce(
      (acc, task) => {
        task.subTasksList.forEach((subTask) => {
          if (subTask.type && (subTask.type.name === 'pickup-payload' || subTask.type.name === 'pickup')) {
            if (subTask.payload) {
              if (subTask.payload && !acc[subTask.payload.id]) {
                acc[subTask.payload.id] = {
                  orderedQuantity: actualOrOrderedQuantity(subTask),
                  payloadName: subTask.payload.name,
                  payloadType: subTask.payload.type.name,
                  payloadTypeId: subTask.payload.type.id,
                  payloadUnits: subTask.payload.unit.abbreviation,
                };
              } else {
                acc[subTask.payload.id].orderedQuantity += actualOrOrderedQuantity(subTask);
              }
            }
          }
        });
        return acc;
      },
      {} as Record<number, PayloadDetails>,
    ),
  );
}

export function actualOrOrderedQuantity(subtask: { actualQuantity: number; orderedQuantity: number }): number {
  if (subtask.actualQuantity > Number.MIN_SAFE_INTEGER) {
    return subtask.actualQuantity;
  }
  return subtask.orderedQuantity;
}

function sortTasks(a: TaskSummary.AsObject, b: TaskSummary.AsObject): number {
  return a.sequence - b.sequence;
}

function getSiteSequenceName(
  firstTask: TaskSummary.AsObject,
  lastTask: TaskSummary.AsObject,
  taskCount: number,
): string {
  if (!firstTask.site || !lastTask.site) {
    return '';
  }
  if (taskCount <= 2) {
    return `${firstTask.site.name} → ${lastTask.site.name}`;
  }
  return `${firstTask.site.name} → +${taskCount - 2} more → ${lastTask.site.name}`;
}
