import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[ctAutoFocus]',
})
export class AutoFocusDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  public ngAfterViewInit() {
    if (this.elementRef.nativeElement.focus) {
      this.elementRef.nativeElement.focus();
    }
  }
}
