import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { environment } from '~environments/environment';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-stage-url-indicator',
  styleUrls: ['./stage-url-indicator.component.scss'],
  templateUrl: './stage-url-indicator.component.html',
})
export class StageUrlIndicatorComponent implements OnInit {
  public environment = environment;
  constructor() {}

  public ngOnInit() {}

  public isProd(): boolean {
    return environment.api === 'https://prime.shaleapps.com';
  }

  public getEnvName(): string {
    switch (environment.api) {
      case 'https://prime.shaleapps.com':
        return 'Prod';
      case 'https://stage.prime.shaleapps.com':
        return 'Stage';
      case 'https://dev.prime.shaleapps.com':
        return 'Dev';
      default:
        return '????';
    }
  }
}
