<div class="side-nav" *ngIf="visible" [@slideInOut]>
  <div class="header">
    <span class="logo">
      <img src="../../../favicon.ico" alt="logo" />
      HAULi<sup style="font-size: .5em;font-weight: 600;">TM</sup>
    </span>
    <span class="close" (click)="close()">
      <i class="material-icons">clear</i>
    </span>
  </div>
  <ul class="top-nav nav-list font-medium no-padding side-nav__top">
    <div class="group" *ngIf="authService.isAdmin$() | async">
      <li>
        <a [routerLink]="['/', 'shaleapps']">Admin</a>
      </li>
    </div>

    <div class="group" *ngIf="!(authService.isAdmin$() | async)">
      <div class="group-header"></div>
      <li *ngIf="authService.isTruckingVendor$() | async">
        <a [routerLink]="['/', 'dispatcher', 'map']" routerLinkActive="true" ctAutomatedSelector="menu__jobs">
          Jobs
        </a>
      </li>

      <li *ngIf="authService.isLMO$() | async">
        <a [routerLink]="['/', 'lmo', 'jobs']" routerLinkActive="true" ctAutomatedSelector="menu__jobs">
          Jobs
        </a>
      </li>

      <ng-container *ctAllowedAccountRoles="[1]">
        <li *ngIf="authService.isLMO$() | async">
          <ng-container *ngIf="userEmail$ | async as userEmail">
            <a
              [routerLink]="['/', 'lmo', 'marketplace', 'dashboard']"
              routerLinkActive="true"
              ctAutomatedSelector="menu__jobs"
            >
              Marketplace
            </a>
          </ng-container>
        </li>
      </ng-container>

      <ng-container *ctAllowedAccountRoles="[1]">
        <li>
          <a
            [routerLink]="['/', 'billing', 'list', 'not_billed']"
            routerLinkActive="true"
            ctAutomatedSelector="menu__fuel-surcharges"
            >Billing</a
          >
        </li>
      </ng-container>
    </div>

    <div class="group" *ngIf="!(authService.isAdmin$() | async)">
      <div class="group-header">Setup</div>

      <ng-container *ctAllowedAccountRoles="[1]">
        <li *ngIf="authService.isLMO$() | async">
          <a [routerLink]="['/', 'lmo', 'payloads']" routerLinkActive="true">Payloads</a>
        </li>
      </ng-container>

      <li>
        <a [routerLink]="['/', 'trailers']" routerLinkActive="true">Trailers</a>
      </li>

      <ng-container *ctAllowedAccountRoles="[1]">
        <ng-container *ngIf="authService.isLMO$() | async; else dispatcherView">
          <li>
            <a [routerLink]="['/', 'lmo', 'users']" routerLinkActive="true">Users</a>
          </li>
        </ng-container>
        <ng-template #dispatcherView>
          <li>
            <a [routerLink]="['/', 'dispatcher', 'users']" routerLinkActive="true">Users</a>
          </li>
        </ng-template>
      </ng-container>

      <ng-container *ctAllowedAccountRoles="[1]">
        <ng-container *ngIf="authService.isLMO$() | async">
          <ng-container *ngIf="isBusinessLineEnabled$$ | async">
            <li>
              <a [routerLink]="['/', 'lmo', 'business-lines']" routerLinkActive="true">Business Lines</a>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>

      <li *ngIf="authService.isTruckingVendor$() | async">
        <a [routerLink]="['/', 'dispatcher', 'drivers']" routerLinkActive="true">Drivers</a>
      </li>

      <ng-container *ctAllowedAccountRoles="[1]">
        <ng-container *ngIf="authService.isLMO$() | async">
          <li>
            <a
              [routerLink]="['/', 'lmo', 'trucking-contracts']"
              routerLinkActive="true"
              ctAutomatedSelector="menu__trucking-contracts"
              >Trucking Contracts</a
            >
          </li>
        </ng-container>
      </ng-container>

      <ng-container *ctAllowedAccountRoles="[1]">
        <li *ngIf="authService.isLMO$() | async">
          <a
            [routerLink]="['/', 'billing', 'setting', 'details']"
            routerLinkActive="true"
            ctAutomatedSelector="menu__fuel-surcharges"
            >Billing Configuration</a
          >
        </li>
      </ng-container>

      <li>
        <a
          [routerLink]="['/', 'notification-preferences']"
          routerLinkActive="true"
          ctAutomatedSelector="menu__notification_preferences"
          >Notification Preferences</a
        >
      </li>
    </div>

    <div class="group" *ngIf="!(authService.isAdmin$() | async)">
      <div class="group-header">Support</div>

      <li *ngIf="authService.isTruckingVendor$() | async">
        <a
          [routerLink]="['/', 'dispatcher', 'training-videos']"
          routerLinkActive="true"
          ctAutomatedSelector="menu__training_videos"
          >Training Videos</a
        >
      </li>

      <ng-container *ctAllowedAccountRoles="[1, 2]">
        <li *ngIf="authService.isLMO$() | async">
          <a
            [routerLink]="['/', 'lmo', 'training-videos']"
            routerLinkActive="true"
            ctAutomatedSelector="menu__training_videos"
            >Training Videos</a
          >
        </li>
      </ng-container>

      <li *ngIf="!(authService.isAdmin$() | async)">
        <a (click)="openIntercom()">ShaleApps Support </a>
      </li>

    </div>
  </ul>

  <ul class="bottom-nav nav-list font-medium support-nav">
    <ng-container *ngIf="authService.isLMO$() | async">
      <li class="bottom-nav-title info-text">Check Out Our Other Apps</li>
      <li class="app-list-item">
        <img src="../../../assets/icons/sandi-wave.svg" alt="sandi" class="app-logo" />
        <div>
          <div>
            SANDi<sup style="font-size: .5em;font-weight: 600;">TM</sup> <span class="info-text"> for Completions</span>
          </div>
          <div class="info-text">Available on <a class="platforms" (click)="goToSandi()">iOS</a></div>
        </div>
      </li>
    </ng-container>

    <ng-container *ngIf="authService.isTruckingVendor$() | async">
      <li class="bottom-nav-title info-text">Download our App for Drivers</li>
      <li class="app-list-item">
        <img src="../../../assets/icons/gohawk.svg" alt="gohawk" class="app-logo" />
        <div>
          <div>GoHawk<sup style="font-size: .5em;font-weight: 600;">TM</sup></div>
          <div class="info-text">
            Available on <a class="platforms" (click)="goToGohawkIOS()">iOS</a> and
            <a (click)="goToGohawkAndroid()" class="platforms">Android</a>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>

  <div class="nav-list font-medium">
    <div class="user">
      <div class="user-info font-medium">
        <div class="user-email">
          {{ userEmail$ | async }}
        </div>
        <div class="user-company">{{ name$ | async }}</div>
        <ng-container *ngIf="isSsoUser$$ | async">
          <ng-container *ngIf="ssoLoginUrl$ | async as ssoLoginUrl">
            <div class="user-account-switch">
              <a (click)="ssoLogin()">SSO Switch Account</a>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <img
        class="logout"
        src="../../../assets/icons/signout.svg"
        alt="signout"
        (click)="logout()"
        ctAutomatedSelector="menu__sign_out"
      />
    </div>
  </div>
</div>

<div class="silkscreen" (click)="close()" *ngIf="visible"></div>
