import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { LMOSiteSummary } from '~proto/site/site_pb';
import { getSumOfProperty } from '~utilities/getSumOfProperty';
import { trackById } from '~utilities/trackById';

@Component({
  selector: 'ct-lmo-currently-active-jobs',
  styleUrls: ['./lmo-currently-active-jobs.component.scss'],
  templateUrl: './lmo-currently-active-jobs.component.html',
})
export class LmoCurrentlyActiveJobsComponent implements OnInit {
  public trackById = trackById;
  public currentlyActiveJobs$: Observable<LMOSiteSummary.AsObject[]>;
  public pendingCount$: Observable<number>;
  public needsAttentionCount$: Observable<number>;
  public inboundCount$: Observable<number>;
  public outboundCount$: Observable<number>;
  public onSiteCount$: Observable<number>;
  public alarmCount$: Observable<number>;

  constructor(private siteService: JobSitesService) {}

  public ngOnInit() {
    this.currentlyActiveJobs$ = this.siteService.currentlyActiveSites$.pipe(filter((sites) => !!sites));
    this.pendingCount$ = this.currentlyActiveJobs$.pipe(map((sites) => getSumOfProperty(sites, 'pendingCount')));
    this.inboundCount$ = this.currentlyActiveJobs$.pipe(map((sites) => getSumOfProperty(sites, 'inboundCount')));
    this.outboundCount$ = this.currentlyActiveJobs$.pipe(map((sites) => getSumOfProperty(sites, 'outboundCount')));
    this.needsAttentionCount$ = this.currentlyActiveJobs$.pipe(
      map((sites) => getSumOfProperty(sites, 'needsAttentionCount')),
    );
    this.onSiteCount$ = this.currentlyActiveJobs$.pipe(map((sites) => getSumOfProperty(sites, 'onSiteCount')));
    this.alarmCount$ = this.currentlyActiveJobs$.pipe(map((sites) => getSumOfProperty(sites, 'alarmCount')));
  }
}
