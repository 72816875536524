<div class="header">
  <ng-container>
    <div class="main-header">
      <span class="menu-button">
        <ng-content select="[mainMenuButton]"></ng-content>
      </span>
      <span class="title">
        <ng-template [ngTemplateOutlet]="titleRef"></ng-template>
      </span>
      <span class="shale-logo">
        <img src="assets/icons/shale-logo-small.svg" alt="logo" />
      </span>
      <span class="actions">
        <ng-content select="[headerActionsButtons]"></ng-content>
      </span>
    </div>
    <div class="sub-header" *ngIf="!noSubheader">
      <div [ngClass]="showSearch ? 'content-with-search' : 'content'">
        <ng-content select="[subHeaderContent]"></ng-content>
      </div>
      <div class="search" *ngIf="showSearch">
        <ng-content select="[subHeaderSearch]"></ng-content>
      </div>
      <div class="actions">
        <span class="pipe"></span>
        <ng-content select="[subHeaderActions]"></ng-content>
      </div>
    </div>
  </ng-container>

  <div class="scroll-header" *ngIf="showScrollHeader" [@slideInOut]>
    <span class="menu-button">
      <ng-content select="[scrollMenuButton]"></ng-content>
    </span>
    <span class="title" class="hide-handheld">
      <ng-template [ngTemplateOutlet]="titleRef"></ng-template>
    </span>
    <div class="content">
      <ng-content select="[scrollContent]"></ng-content>
    </div>
    <div class="actions">
      <span class="pipe"></span>
      <ng-content select="[scrollActions]">&nbsp;</ng-content>
    </div>
  </div>
</div>
<ct-side-nav [visible]="displayNav$ | async" (onMenuClose)="toggleNav()"></ct-side-nav>

<ng-template #titleRef>
  <span class="title" *ngIf="titleString">
    {{ titleString }}
  </span>
  <ng-content *ngIf="!titleString" select="[title]"></ng-content>
</ng-template>
