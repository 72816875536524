import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, race, Subject, timer } from 'rxjs';
import { exhaustMap, mapTo, take, takeUntil } from 'rxjs/operators';
import { LongEscapeTipComponent } from '~common/long-escape-tip/long-escape-tip.component';
import { LongEscapeComponent, longPressTimeInMs } from '~common/long-escape/long-escape.component';

@Directive({
  selector: '[ctLongEscape]',
})
export class LongEscapeDirective implements OnInit, OnDestroy {
  @Input() public routerLink: string | string[];
  @Output() public longPressed: EventEmitter<MouseEvent> = new EventEmitter();
  public keyDown$$ = new Subject();
  public keyUp$$ = new Subject();
  private destroys$$ = new Subject();

  constructor(private snackBar: MatSnackBar, private router: Router, private activatedRoute: ActivatedRoute) {}

  public ngOnInit() {
    let snackBar: MatSnackBarRef<any>;
    this.keyDown$$
      .asObservable()
      .pipe(
        exhaustMap(() => {
          snackBar = this.snackBar.openFromComponent(LongEscapeComponent, {
            panelClass: 'hotkey-snackbar',
          });
          return combineLatest([
            race(
              this.keyUp$$.pipe(mapTo(false)),
              timer(longPressTimeInMs).pipe(
                take(1),
                mapTo(true),
              ),
            ).pipe(take(1)),
            this.keyUp$$.asObservable(),
          ]).pipe(take(1));
        }),
        takeUntil(this.destroys$$),
      )
      .subscribe(([isLongPress]) => {
        if (snackBar) {
          snackBar.dismiss();
        }
        if (isLongPress) {
          if (this.routerLink) {
            if (typeof this.routerLink === 'string') {
              this.router.navigateByUrl(this.routerLink, { relativeTo: this.activatedRoute });
            } else {
              this.router.navigate(this.routerLink, { relativeTo: this.activatedRoute });
            }
            return;
          }
          this.longPressed.next();
        }
      });
  }

  public ngOnDestroy() {
    this.destroys$$.next();
    this.destroys$$.unsubscribe();
  }

  @HostListener('click', ['$event'])
  public click($event) {
    if (window.innerWidth > 1024) {
      this.snackBar.openFromComponent(LongEscapeTipComponent, {
        duration: 5000,
        panelClass: 'hotkey-snackbar',
      });
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  public keyDown(event: KeyboardEvent) {
    this.keyDown$$.next(event);
  }

  @HostListener('document:keyup.escape', ['$event'])
  public keyUp(event: KeyboardEvent) {
    this.keyUp$$.next(event);
  }
}
