import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, filter, finalize, map, startWith, switchMap } from 'rxjs/operators';
import { SelectPayloadTypeComponent } from '~common/select-payload-type/select-payload-type.component';
import { SelectPayloadUnitsComponent } from '~common/select-payload-units/select-payload-units.component';
import { CreatePayloadRequest } from '~proto/payload/payload_api_pb';
import { AddStockRequest } from '~proto/site/site_api_pb';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { trackById } from '~utilities/trackById';
var StockAddComponent = /** @class */ (function () {
    function StockAddComponent(site, fb, payloadService, matDialog, router, activatedRoute) {
        this.site = site;
        this.fb = fb;
        this.payloadService = payloadService;
        this.matDialog = matDialog;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.trackById = trackById;
        this.textChanges$$ = new BehaviorSubject(null);
        this.formTouched = false;
        this.networkActive$ = new BehaviorSubject(false);
    }
    StockAddComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.siteName$ = this.site.currentSite$.pipe(filter(function (site) { return !!site; }), map(function (site) { return site.name; }));
        this.formGroup = this.fb.group({
            maxQuantity: [1, [Validators.required, Validators.min(1)]],
            payload: [null, Validators.required],
            quantity: [0, [Validators.required, this.quantityValidator('quantity', 'maxQuantity')]],
        }, {
            validator: [this.quantityValidator('quantity', 'maxQuantity')],
        });
        this.payloadsGroups$ = combineLatest([this.payloadService.currentSitePayloads$, this.site.currentSite$]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), payloadGroups = _b[0], site = _b[1];
            var sitePayloads = site.stockReferencesList.reduce(function (record, stock) {
                var _a;
                return tslib_1.__assign({}, record, (_a = {}, _a[stock.payload.id] = stock.payload, _a));
            }, {});
            var pinned = [];
            var unpinned = [];
            var filteredGroups = payloadGroups.map(function (group) {
                group.payloadsList.forEach(function (payload) {
                    if (payload.favorited) {
                        pinned.push(payload);
                    }
                    else {
                        unpinned.push(payload);
                    }
                });
                return tslib_1.__assign({}, group, { payloadsList: unpinned.filter(function (payload) { return !sitePayloads[payload.id]; }) });
            });
            if (pinned.length) {
                filteredGroups.unshift({
                    name: 'Pinned',
                    payloadsList: pinned.filter(function (payload) { return !sitePayloads[payload.id]; }),
                });
            }
            var sitePayloadsAsArray = Object.values(sitePayloads);
            if (sitePayloadsAsArray.length) {
                filteredGroups.push({
                    disabled: true,
                    name: 'Already On Site',
                    payloadsList: sitePayloadsAsArray,
                });
            }
            return filteredGroups;
        }));
        this.searching$ = this.payloadService.searching$;
        var searchResults$ = combineLatest([
            this.payloadsGroups$,
            this.textChanges$$.pipe(debounceTime(200)).pipe(switchMap(function (searchText) {
                if (searchText && searchText.length >= 2) {
                    return _this.payloadService.searchPayloads$(searchText);
                }
                return of([]);
            })),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), payloadGroups = _b[0], searchResults = _b[1];
            if (!payloadGroups) {
                return searchResults;
            }
            var payloads = payloadGroups.reduce(function (arr, group) { return tslib_1.__spread(arr, group.payloadsList); }, []);
            var payloadMap = idArrayToRecord(payloads);
            return searchResults.filter(function (payload) { return !payloadMap[payload.id]; });
        }));
        this.filteredPayloadGroups$ = combineLatest([
            this.payloadsGroups$,
            this.textChanges$$.pipe(startWith('')),
            searchResults$,
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 3), groups = _b[0], text = _b[1], searchResults = _b[2];
            if (!groups && !searchResults) {
                return [];
            }
            var cleanedItems = groups.map(function (item) { return (tslib_1.__assign({}, item, { payloadsList: item.payloadsList.filter(function (entry) {
                    return entry.name.toLocaleLowerCase().includes((text || '').toLocaleLowerCase());
                }) })); });
            var mergedGroups = tslib_1.__spread([
                {
                    name: 'search results',
                    payloadsList: searchResults,
                }
            ], cleanedItems);
            return mergedGroups.filter(function (item) { return item.payloadsList.length > 0; });
        }));
    };
    StockAddComponent.prototype.inputTextChange = function (event) {
        this.textChanges$$.next(event.trim());
    };
    StockAddComponent.prototype.togglePin = function (event, payloadId, isFavorited) {
        event.stopPropagation();
        this.payloadService.updatePayloadFavorites(payloadId, isFavorited);
    };
    StockAddComponent.prototype.selectPayload = function (payload, isDisabled) {
        if (isDisabled) {
            return;
        }
        this.formGroup.patchValue({ payload: payload });
        this.smartDropdown.close();
    };
    StockAddComponent.prototype.createNewPayload = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var createPayloadRequest, unitsDialog, unit, payloadTypeDialog, payloadType;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        createPayloadRequest = new CreatePayloadRequest();
                        createPayloadRequest.setName(this.textChanges$$.value);
                        unitsDialog = this.matDialog.open(SelectPayloadUnitsComponent);
                        unitsDialog.componentInstance.payloadName = createPayloadRequest.getName();
                        return [4 /*yield*/, unitsDialog.afterClosed().toPromise()];
                    case 1:
                        unit = _a.sent();
                        if (!unit) {
                            return [2 /*return*/];
                        }
                        createPayloadRequest.setUnitId(unit.id);
                        payloadTypeDialog = this.matDialog.open(SelectPayloadTypeComponent);
                        payloadTypeDialog.componentInstance.payloadName = createPayloadRequest.getName();
                        return [4 /*yield*/, payloadTypeDialog.afterClosed().toPromise()];
                    case 2:
                        payloadType = _a.sent();
                        if (!payloadType) {
                            return [2 /*return*/];
                        }
                        createPayloadRequest.setPayloadTypeId(payloadType.id);
                        this.payloadService.createPayload$(createPayloadRequest).subscribe(function (payload) {
                            if (payload) {
                                _this.selectPayload(payload, false);
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    StockAddComponent.prototype.addPayloadToSite = function () {
        var _this = this;
        if (this.formGroup.invalid) {
            return;
        }
        this.networkActive$.next(true);
        var value = this.formGroup.value;
        var request = new AddStockRequest();
        request.setPayloadId(value.payload.id);
        request.setMaxQuantity(value.maxQuantity);
        request.setQuantity(value.quantity);
        this.site
            .addStockToSite$(request)
            .pipe(finalize(function () { return _this.networkActive$.next(false); }))
            .subscribe(function () {
            _this.router.navigate(['../'], { relativeTo: _this.activatedRoute });
        });
    };
    StockAddComponent.prototype.quantityValidator = function (quantity, maxQuantity) {
        return function (group) {
            var quantityControl = group.get(quantity);
            var maxQuantityControl = group.get(maxQuantity);
            if (!quantityControl || !maxQuantityControl) {
                return;
            }
            return quantityControl.value <= maxQuantityControl.value
                ? quantityControl.setErrors(null)
                : quantityControl.setErrors({ diff: true });
        };
    };
    return StockAddComponent;
}());
export { StockAddComponent };
