import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FixedPointCost } from '~proto/contracts/contracts_pb';
import { ContractsService, FileSortParams } from '../../services/contracts.service';

const TABLE_DATA: FixedPointCost.AsObject[] = [];

@Component({
  selector: 'ct-lmo-trucking-contracts-point-to-point',
  styleUrls: ['./lmo-trucking-contracts-point-to-point-list.component.scss'],
  templateUrl: './lmo-trucking-contracts-point-to-point-list.component.html',
})
export class LmoTruckingContractsPointToPointListComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) public sort: MatSort;

  public searchField = new FormControl();

  public uploadedDataColumns: string[] = [
    'originCity',
    'originState',
    'originZip',
    'destinationCity',
    'destinationState',
    'destinationZip',
    'cost',
  ];

  public loading$: Observable<boolean>;

  public uploadDataResponse = new MatTableDataSource(TABLE_DATA);

  public sortParams: FileSortParams = {
    fileId: -1,
    key: 'originCity',
    limit: 100,
    page: 1,
    searchBy: '',
    sortDirection: 'asc',
  };

  public totalCostPoints = 0;

  constructor(private contractService: ContractsService) {}

  public ngOnInit() {
    this.loading$ = this.contractService.loadingPointToPointFileData$;

    this.contractService.pointToPointFileDataSort$.subscribe((value) => {
      console.log('sort params are', value);
      this.sortParams = value;
    });
    this.contractService.totalPointToPointFileData$.subscribe((totalOrders) => {
      console.log('total orders are', totalOrders);
      this.totalCostPoints = +totalOrders;
    });

    this.uploadDataResponse.sort = this.sort;

    this.contractService.pointToPointFileData$.subscribe((data) => {
      this.uploadDataResponse.data = data;
    });

    this.searchField.valueChanges.pipe(debounceTime(500)).subscribe((filter) => {
      this.contractService.search(filter);
    });
  }

  public onMatSortChange(event) {
    this.contractService.sortData(event);
  }

  public switchPage($event) {
    this.contractService.loadPage($event.pageIndex + 1);
  }
}
