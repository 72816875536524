import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CreatePayloadRequest } from '~proto/payload/payload_api_pb';
import { recordToArray } from '~utilities/recordToArray';
import { trackById } from '~utilities/trackById';
var LmoPayloadManagementCreateComponent = /** @class */ (function () {
    function LmoPayloadManagementCreateComponent(fb, payloadService, constantsService, driverCertificationService, router, snackbar) {
        this.fb = fb;
        this.payloadService = payloadService;
        this.constantsService = constantsService;
        this.driverCertificationService = driverCertificationService;
        this.router = router;
        this.snackbar = snackbar;
        this.networkActive$ = new BehaviorSubject(false);
        this.trackById = trackById;
    }
    LmoPayloadManagementCreateComponent.prototype.formValue = function () {
        return this.formGroup.value;
    };
    LmoPayloadManagementCreateComponent.prototype.ngOnInit = function () {
        this.formGroup = this.fb.group({
            driverCertificationIds: [[], []],
            name: [null, [Validators.required]],
            payloadTypeId: [null, [Validators.required]],
            unitId: [null, [Validators.required]],
        });
        this.payloadTypes = recordToArray(this.constantsService.payloadTypes).sort(function (a, b) { return a.name.localeCompare(b.name); });
        this.units = recordToArray(this.constantsService.units).sort(function (a, b) { return a.name.localeCompare(b.name); });
        this.driverCertifications$ = this.driverCertificationService.driverCertifications$;
    };
    LmoPayloadManagementCreateComponent.prototype.addPayload = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formValue, createPayloadRequest;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.formGroup.markAllAsTouched();
                if (this.formGroup.invalid) {
                    return [2 /*return*/];
                }
                formValue = this.formValue();
                this.networkActive$.next(true);
                createPayloadRequest = new CreatePayloadRequest();
                createPayloadRequest.setName(formValue.name);
                createPayloadRequest.setPayloadTypeId(formValue.payloadTypeId);
                createPayloadRequest.setUnitId(formValue.unitId);
                createPayloadRequest.setDriverCertificationIdsList(formValue.driverCertificationIds);
                this.payloadService.createPayload$(createPayloadRequest).subscribe(function () {
                    _this.networkActive$.next(false);
                    _this.router.navigate(['/', 'lmo', 'payloads']);
                    _this.snackbar.open('Payload Created', null, {
                        duration: 2000,
                    });
                });
                return [2 /*return*/];
            });
        });
    };
    return LmoPayloadManagementCreateComponent;
}());
export { LmoPayloadManagementCreateComponent };
