import { OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
var AllowedAccountTypesDirective = /** @class */ (function () {
    function AllowedAccountTypesDirective(viewContainer, templateRef, roleService) {
        var _this = this;
        this.viewContainer = viewContainer;
        this.templateRef = templateRef;
        this.roleService = roleService;
        this.allowedAccountTypes$$ = new BehaviorSubject({});
        this.hasView = false;
        this.subscription = this.allowedAccountTypes$$.subscribe(function (record) {
            _this.roleService.userRole$.subscribe(function (accountRoleId) {
                if (!accountRoleId) {
                    _this.clear();
                    return;
                }
                if (record[accountRoleId.valueOf()] === true) {
                    _this.show();
                }
                else {
                    _this.clear();
                }
            });
        });
    }
    Object.defineProperty(AllowedAccountTypesDirective.prototype, "ctAllowedAccountRoles", {
        set: function (accountTypes) {
            var allowedAccountTypes = {};
            accountTypes.forEach(function (accountType) {
                allowedAccountTypes[accountType] = true;
            });
            this.allowedAccountTypes$$.next(allowedAccountTypes);
        },
        enumerable: true,
        configurable: true
    });
    AllowedAccountTypesDirective.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    AllowedAccountTypesDirective.prototype.clear = function () {
        if (this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    };
    AllowedAccountTypesDirective.prototype.show = function () {
        if (!this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        }
    };
    return AllowedAccountTypesDirective;
}());
export { AllowedAccountTypesDirective };
