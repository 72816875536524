import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';
import { ConfirmDialogComponent } from '~common/confirm-dialog/confirm-dialog.component';
import { DeleteTrailersRequest, DeleteTrailersResponse } from '~proto/trailer/trailer_api_pb';
import { TrailerDetails } from '~proto/trailer/trailer_pb';
import { ConstantsService } from '~services/constants.service';
import { TrailerService } from '~services/trailer.service';
import { fuse } from '~utilities/fuse';

const searchOptions: Fuse.FuseOptions<any> = {
  distance: 100,
  keys: ['name', 'model.name'],
  location: 0,
  maxPatternLength: 16,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.2,
};

@Component({
  selector: 'ct-trailer-management',
  styleUrls: ['./trailer-management.component.scss'],
  templateUrl: './trailer-management.component.html',
})
export class TrailerManagementComponent implements OnInit {
  public trailers$: Observable<MatTableDataSource<TrailerDetails.AsObject>>;
  public networkActive$: Observable<boolean>;
  public trailerSearch: FormControl = new FormControl();
  public displayedColumns = ['name', 'model', 'location', 'lastUsed', 'service', 'actions'];

  constructor(
    private trailerService: TrailerService,
    private router: Router,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private constantsService: ConstantsService,
  ) {}

  public ngOnInit() {
    this.networkActive$ = this.trailerService.networkActive$;
    this.trailers$ = combineLatest([
      this.trailerService.trailers$
        .pipe(
          map((trailers) => this.constantsService.sortDataValues(trailers)),
          map(fuse<TrailerDetails.AsObject>(searchOptions))
        ),
      this.trailerSearch.valueChanges.pipe(
        startWith(''),
        debounceTime(200),
      ),
    ]).pipe(
      map(([fused, searchTerm]) => {
        if (!searchTerm || searchTerm === '') {
          return fused.data;
        }
        return fused.fuse.search(searchTerm);
      }),
      map((trailers) => {
        return new MatTableDataSource(trailers);
      }),
    );
  }

  public trackById(_index: number, trailer: any) {
    return trailer.id;
  }

  public deleteTrailer(trailer: TrailerDetails.AsObject, event) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        acceptButtonText: 'Delete',
        declineButtonText: 'Cancel',
        title: `Are you sure you want to delete ${trailer.name} ?`,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(filter((data) => !!data))
      .subscribe((result) => {
        if (result) {
          const deleteTrailerReq = new DeleteTrailersRequest();
          deleteTrailerReq.setId(trailer.id);
          this.trailerService.deleteTrailer(deleteTrailerReq).subscribe((trailers: DeleteTrailersResponse) => {
            this.snackbar.open(trailers.toObject().status, null, {
              duration: 2000,
            });
          });
        }
      });
  }

  public async openTrailer(row) {
    await this.router.navigate(['/', 'trailers', row.id, 'edit']);
  }
}
