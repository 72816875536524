import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { trackById } from '~utilities/trackById';
var CreateOrderPurchaseOrderSelectorComponent = /** @class */ (function () {
    function CreateOrderPurchaseOrderSelectorComponent(recentService) {
        this.recentService = recentService;
        this.additionalRecentPOs$$ = new BehaviorSubject([]);
        this.selected = new EventEmitter();
        this.invalid = false;
        this.textChanges$$ = new BehaviorSubject(null);
        this.trackById = trackById;
    }
    Object.defineProperty(CreateOrderPurchaseOrderSelectorComponent.prototype, "additionalRecentPOs", {
        set: function (POs) {
            if (POs) {
                this.additionalRecentPOs$$.next(POs);
            }
        },
        enumerable: true,
        configurable: true
    });
    CreateOrderPurchaseOrderSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.purchaseOrderNames$ = combineLatest([
            this.recentService.purchaseOrders$,
            this.textChanges$$,
            this.additionalRecentPOs$$,
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 3), purchaseOrders = _b[0], text = _b[1], additionalRecentPOs = _b[2];
            if (!purchaseOrders) {
                return [];
            }
            var asArray = [
                {
                    name: 'search results',
                    purchaseOrderNames: purchaseOrders.searchResults,
                },
                {
                    name: 'recently used',
                    purchaseOrderNames: Array.from(new Set(tslib_1.__spread((purchaseOrders.recentlyUsedList || []), additionalRecentPOs))),
                },
                {
                    name: 'frequently used',
                    purchaseOrderNames: purchaseOrders.frequentlyUsedList,
                },
            ];
            if (!text || text === '') {
                return asArray.filter(function (group) { return group.purchaseOrderNames && group.purchaseOrderNames.length > 0; });
            }
            return asArray
                .map(function (group) { return ({
                name: group.name,
                purchaseOrderNames: (group.purchaseOrderNames || []).filter(function (s) { return s.includes(text); }),
            }); })
                .filter(function (group) { return group.purchaseOrderNames.length > 0; });
        }));
        this.showCreatePOButton$ = combineLatest([
            this.recentService.purchaseOrders$,
            this.textChanges$$.pipe(startWith(null)),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), purchaseOrder = _b[0], text = _b[1];
            if (!text || !text.length) {
                return false;
            }
            return ((purchaseOrder.frequentlyUsedList || []).every(function (po) { return po !== text; }) &&
                (purchaseOrder.recentlyUsedList || []).every(function (po) { return po !== text; }) &&
                (purchaseOrder.searchResults || []).every(function (po) { return po !== text; }));
        }));
        this.textChanges$$.pipe(debounceTime(250)).subscribe(function (text) {
            _this.recentService.searchPurchaseOrders(text);
        });
    };
    CreateOrderPurchaseOrderSelectorComponent.prototype.selectPurchaseOrder = function (purchaseOrderName) {
        this.selected.emit({ purchaseOrderName: purchaseOrderName });
        this.smartDropdown.close();
    };
    CreateOrderPurchaseOrderSelectorComponent.prototype.addPurchaseOrderName = function () {
        if (this.textChanges$$.value && this.textChanges$$.value !== '') {
            this.selectPurchaseOrder(this.textChanges$$.value);
        }
    };
    CreateOrderPurchaseOrderSelectorComponent.prototype.inputTextChange = function (event) {
        this.textChanges$$.next(event.trim());
    };
    CreateOrderPurchaseOrderSelectorComponent.prototype.enterKeyPressed = function () {
        if (this.topResult) {
            this.selectPurchaseOrder(this.topResult);
        }
        else {
            this.addPurchaseOrderName();
        }
    };
    CreateOrderPurchaseOrderSelectorComponent.prototype.trackByName = function (_number, name) {
        return name;
    };
    return CreateOrderPurchaseOrderSelectorComponent;
}());
export { CreateOrderPurchaseOrderSelectorComponent };
