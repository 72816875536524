import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { trackById } from '~utilities/trackById';
var CreateOrderSalesOrderNumberSelectorComponent = /** @class */ (function () {
    function CreateOrderSalesOrderNumberSelectorComponent(recentService, roleService) {
        this.recentService = recentService;
        this.roleService = roleService;
        this.additionalRecentSalesOrderNumbers$$ = new BehaviorSubject([]);
        this.selected = new EventEmitter();
        this.invalid = false;
        this.textChanges$$ = new BehaviorSubject(null);
        this.isSalesOrderApiEnabled$$ = new BehaviorSubject(false);
        this.trackById = trackById;
    }
    Object.defineProperty(CreateOrderSalesOrderNumberSelectorComponent.prototype, "additionalRecentSalesOrderNumbers", {
        set: function (SOs) {
            if (SOs) {
                this.additionalRecentSalesOrderNumbers$$.next(SOs);
            }
        },
        enumerable: true,
        configurable: true
    });
    CreateOrderSalesOrderNumberSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.recentService.loadSalesOrder();
        this.salesOrderNumbers$ = combineLatest([
            this.recentService.salesOrders$,
            this.textChanges$$,
            this.additionalRecentSalesOrderNumbers$$,
            this.roleService.isSalesOrderApiEnabled$,
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 4), salesOrders = _b[0], text = _b[1], additionalRecentSalesOrderNumbers = _b[2], isSalesOrderApiEnabled = _b[3];
            if (!salesOrders) {
                return [];
            }
            var recent = isSalesOrderApiEnabled
                ? []
                : tslib_1.__spread((salesOrders.recentlyUsedList || []), additionalRecentSalesOrderNumbers);
            var frequent = isSalesOrderApiEnabled ? [] : tslib_1.__spread((salesOrders.frequentlyUsedList || []));
            var asArray = [
                {
                    name: 'search results',
                    salesOrderNumbers: salesOrders.searchResults,
                },
                {
                    name: 'recently used',
                    salesOrderNumbers: Array.from(new Set(recent)),
                },
                {
                    name: 'frequently used',
                    salesOrderNumbers: frequent,
                },
            ];
            if (!text || text === '') {
                return asArray.filter(function (group) { return group.salesOrderNumbers && group.salesOrderNumbers.length > 0; });
            }
            return asArray
                .map(function (group) { return ({
                name: group.name,
                salesOrderNumbers: (group.salesOrderNumbers || []).filter(function (s) { return s.includes(text); }),
            }); })
                .filter(function (group) { return group.salesOrderNumbers.length > 0; });
        }));
        this.showCreateSOButton$ = combineLatest([
            this.recentService.salesOrders$,
            this.textChanges$$.pipe(startWith(null)),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), salesOrders = _b[0], text = _b[1];
            if (!text || !text.length) {
                return false;
            }
            if (!salesOrders) {
                return true;
            }
            return ((salesOrders.searchResults || []).every(function (so) { return so !== text; }) &&
                (salesOrders.frequentlyUsedList || []).every(function (so) { return so !== text; }) &&
                (salesOrders.recentlyUsedList || []).every(function (so) { return so !== text; }));
        }));
        this.textChanges$$.pipe(debounceTime(250)).subscribe(function (text) {
            _this.recentService.searchSalesOrders(text);
        });
        this.checkIsSalesOrderApiEnabled();
    };
    CreateOrderSalesOrderNumberSelectorComponent.prototype.selectSalesOrderNumber = function (orderNumber) {
        this.selected.emit({ orderNumber: orderNumber });
        this.smartDropdown.close();
    };
    CreateOrderSalesOrderNumberSelectorComponent.prototype.addSalesOrderNumber = function () {
        if (this.textChanges$$.value && this.textChanges$$.value !== '') {
            this.selectSalesOrderNumber(this.textChanges$$.value);
        }
    };
    CreateOrderSalesOrderNumberSelectorComponent.prototype.inputTextChange = function (event) {
        this.textChanges$$.next(event.trim());
    };
    CreateOrderSalesOrderNumberSelectorComponent.prototype.enterKeyPressed = function () {
        if (!this.isSalesOrderApiEnabled$$.value) {
            if (this.topResult) {
                this.selectSalesOrderNumber(this.topResult);
            }
            else {
                this.addSalesOrderNumber();
            }
        }
    };
    CreateOrderSalesOrderNumberSelectorComponent.prototype.trackByName = function (_number, name) {
        return name;
    };
    CreateOrderSalesOrderNumberSelectorComponent.prototype.checkIsSalesOrderApiEnabled = function () {
        var _this = this;
        this.roleService.isSalesOrderApiEnabled$.subscribe(function (isSalesOrderApiEnabled) {
            _this.isSalesOrderApiEnabled$$.next(isSalesOrderApiEnabled);
        });
    };
    return CreateOrderSalesOrderNumberSelectorComponent;
}());
export { CreateOrderSalesOrderNumberSelectorComponent };
