import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { flatten } from 'remeda';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, take, takeUntil } from 'rxjs/operators';
import { PayloadService } from '~lmo/services/payload.service';
import { CustomStop } from '~lmo/utilities/create-order';
import { Payload, PayloadGroup } from '~proto/payload/payload_pb';
import { Site } from '~proto/site/site_pb';
import { TaskType, TaskTypeMap } from '~proto/types/types_pb';
import { UserDetailsForIntercomRequest, UserDetailsForIntercomResponse } from '~proto/user/user_api_pb';
import { UserAPI } from '~proto/user/user_api_pb_service';
import { GrpcService } from '~services/grpc.service';
import { moveItemInFormArray } from '~utilities/moveItemInFormArray';

export interface PayloadSummary {
  key: string; // payloadId-purchaseOrderName
  payloadName: string;
  purchaseOrderName?: string;
  pickedUp: number;
  droppedOff: number;
  leftOver: number;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CreateOrderCustomStops2Component),
    },
    {
      multi: true,
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CreateOrderCustomStops2Component),
    },
  ],
  selector: 'ct-create-order-custom-stops2',
  styleUrls: ['./create-order-custom-stops2.component.scss'],
  templateUrl: './create-order-custom-stops2.component.html',
})
export class CreateOrderCustomStops2Component implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  public validationErrors: ValidationErrors;
  private destroy$ = new Subject();
  public customStops = new FormArray([]);
  public payloadSummaries$: Observable<PayloadSummary[]>;
  public payloadGroups$: Observable<PayloadGroup.AsObject[]>;
  public purchaseOrderNames$: Observable<string[]>;
  public salesOrderNumbers$: Observable<string[]>;
  public isPurchaseOrderRequired$$ = new BehaviorSubject<Boolean>(false);
  public isSalesOrderRequired$$ = new BehaviorSubject<Boolean>(false);
  public isMaintenanceOrderRequired$$ = new BehaviorSubject<Boolean>(false);
  public maintenanceOrderNumbers$: Observable<string[]>;

  public stopTypes: { name: string; value: TaskTypeMap[keyof TaskTypeMap] }[] = [
    {
      name: 'Pickup Payload',
      value: TaskType.TASK_TYPE_PICKUP,
    },
    {
      name: 'Dropoff Payload',
      value: TaskType.TASK_TYPE_DROPOFF,
    },
  ];

  private customStopsValue(): CustomStop[] {
    return this.customStops.value;
  }

  public get invalid(): boolean {
    return !!this.validationErrors;
  }

  constructor(private fb: FormBuilder, private payloadService: PayloadService, private grpcService: GrpcService) {
    const fg = this.getNewFormLine();
    fg.get('stopType').setValue(TaskType.TASK_TYPE_PICKUP);
    this.customStops.push(fg);
  }

  public ngOnInit() {
    this.loadRequiredFieldSettings();
    this.setupPayloadSummaries();
    this.setupValidationErrors();
    this.setupPayloads();
    this.setupPurchaseOrders();
    this.setupSalesOrderNumbers();
    this.setupMaintenanceOrderNumbers();
  }

  private setupPayloadSummaries() {
    this.payloadSummaries$ = this.customStops.valueChanges.pipe(
      map((stops: CustomStop[]) => {
        const summariesMap = stops.reduce(
          (acc, stop) => {
            const keyValue =
              this.isPurchaseOrderRequired$$.value || stop.purchaseOrderName ? stop.purchaseOrderName : 'key';
            if (stop.payload) {
              const key = `${stop.payload.id}-${keyValue}`;

              if (!acc[key]) {
                acc[key] = {
                  droppedOff: 0,
                  key: key,
                  leftOver: 0,
                  payloadName: stop.payload.name,
                  pickedUp: 0,
                  purchaseOrderName: stop.purchaseOrderName ? stop.purchaseOrderName : '-',
                };
              }
              if (stop.stopType === TaskType.TASK_TYPE_PICKUP) {
                acc[key].pickedUp += stop.quantity;
                acc[key].leftOver += stop.quantity;
              } else if (stop.stopType === TaskType.TASK_TYPE_DROPOFF) {
                acc[key].droppedOff += stop.quantity;
                acc[key].leftOver -= stop.quantity;
              }
            }
            return acc;
          },
          {} as Record<number, PayloadSummary>,
        );
        return Object.values(summariesMap);
      }),
    );
  }

  private setupPayloads() {
    this.payloadGroups$ = combineLatest([
      this.payloadService.allPayloadGroups$,
      this.customStops.valueChanges.pipe(startWith([] as CustomStop[])),
    ]).pipe(
      map(([sitePayloads, formValues]: [PayloadGroup.AsObject[], CustomStop[]]) => {
        const payloadsOnForm = formValues.filter((stop) => stop.payload).map((stop) => stop.payload);
        const allPayloads = [
          ...flatten(sitePayloads.map((payloadGroup) => payloadGroup.payloadsList)),
          ...payloadsOnForm,
        ];
        const uniquePayloads = allPayloads.reduce(
          (acc, payload) => {
            acc[payload.id] = payload;
            return acc;
          },
          {} as Record<number, Payload.AsObject>,
        );
        return [
          {
            name: '',
            payloadsList: Object.values(uniquePayloads),
          },
        ];
      }),
    );
  }

  private setupPurchaseOrders() {
    this.purchaseOrderNames$ = this.customStops.valueChanges.pipe(
      map((formValues: CustomStop[]) => {
        return formValues.filter((stop) => stop.purchaseOrderName).map((stop) => stop.purchaseOrderName);
      }),
      shareReplay(1),
    );
  }

  private setupSalesOrderNumbers() {
    this.salesOrderNumbers$ = this.customStops.valueChanges.pipe(
      map((formValues: CustomStop[]) => {
        return formValues.filter((stop) => stop.salesOrderNumber).map((stop) => stop.salesOrderNumber);
      }),
      shareReplay(1),
    );
  }

  private setupMaintenanceOrderNumbers() {
    this.maintenanceOrderNumbers$ = this.customStops.valueChanges.pipe(
      map((formValues: CustomStop[]) => {
        return formValues.filter((stop) => stop.maintenanceOrderNumber).map((stop) => stop.maintenanceOrderNumber);
      }),
      shareReplay(1),
    );
  }

  private loadRequiredFieldSettings() {
    const request = new UserDetailsForIntercomRequest();
    (this.grpcService.invoke$(UserAPI.UserDetailsForIntercom, request) as Observable<
      UserDetailsForIntercomResponse
    >).subscribe((response) => {
      this.isPurchaseOrderRequired$$.next(response.toObject().userDetails.isPurchaseOrderRequired);
      this.isSalesOrderRequired$$.next(response.toObject().userDetails.isSalesOrderRequired);
      this.isMaintenanceOrderRequired$$.next(response.toObject().userDetails.isMaintenanceOrderRequired);
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public writeValue(obj: any): void {
    if (Array.isArray(obj) && obj.length === this.customStops.length) {
      this.customStops.setValue(obj);
    }
  }

  public registerOnChange(fn: any): void {
    this.customStops.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      fn(value);
    });
  }

  public registerOnTouched(fn: any): void {}

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.customStops.disable() : this.customStops.enable();
  }

  public validate(): ValidationErrors {
    return this.validationErrors;
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this.customStops.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      fn();
    });
  }

  public trackByControl(_index: number, control: FormControl) {
    return control;
  }

  public trackByName(_index: number, record: { name: string }) {
    return record.name;
  }

  public trackByKey(_index: number, record: { key: string }) {
    return record.key;
  }

  public selectCustomOrderLineSite(index: number, event: { pickupFrom: Site.AsObject }) {
    this.customStops
      .at(index)
      .get('location')
      .setValue(event.pickupFrom);
  }

  public selectCustomOrderLinePayload(index: number, event: { payload: Payload.AsObject; quantity: number }) {
    this.customStops
      .at(index)
      .get('payload')
      .setValue(event.payload);

    this.customStops
      .at(index)
      .get('quantity')
      .setValue(event.quantity || 0);
  }

  public selectCustomOrderPurchaseOrderName(index: number, event: { purchaseOrderName: string }) {
    this.customStops
      .at(index)
      .get('purchaseOrderName')
      .setValue(event.purchaseOrderName);
  }

  public selectCustomSalesOrderNumber(index: number, event: { orderNumber: string }) {
    this.customStops
      .at(index)
      .get('salesOrderNumber')
      .setValue(event.orderNumber);
  }

  public selectCustomMaintenanceOrderNumber(index: number, event: { maintenanceOrderNumber: string }) {
    this.customStops
      .at(index)
      .get('maintenanceOrderNumber')
      .setValue(event.maintenanceOrderNumber);
  }

  public removeCustomOrderLink(index: number) {
    if (this.customStops.length > 1) {
      this.customStops.removeAt(index);
    }
  }

  public insertLineBefore(index: number) {
    const fg = this.getNewFormLine();
    fg.get('stopType').setValue(TaskType.TASK_TYPE_PICKUP);
    this.customStops.insert(index, fg);
  }

  public insertLineAfter(index: number) {
    const fg = this.getNewFormLine();
    fg.get('stopType').setValue(TaskType.TASK_TYPE_PICKUP);
    this.customStops.insert(index + 1, fg);
    const previousFg = this.customStops.at(index);
    if (previousFg) {
      const previousSalesOrderNumber = previousFg.get('salesOrderNumber').value;
      if (previousSalesOrderNumber) {
        fg.get('salesOrderNumber').setValue(previousSalesOrderNumber);
      }
    }
  }

  public drop(event: CdkDragDrop<FormGroup[]>) {
    moveItemInFormArray(this.customStops, event.previousIndex, event.currentIndex);
  }

  private getNewFormLine(site?: Site.AsObject): FormGroup {
    const fg = this.fb.group({
      location: [site, [Validators.required]],
      maintenanceOrderNumber: [null, this.isMaintenanceOrderRequired$$.value ? [Validators.required] : ''],
      payload: [null, [Validators.required]],
      purchaseOrderName: [null, this.isPurchaseOrderRequired$$.value ? [Validators.required] : ''],
      quantity: [null, [Validators.required]],
      salesOrderNumber: [null, this.isSalesOrderRequired$$.value ? [Validators.required] : ''],
      stopType: [null, [Validators.required]],
    });

    fg.get('stopType')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(() => {
        this.prefillPayloadDropoffInfoIfPayloadDropoff(fg);
        if (this.isSalesOrderRequired$$.value && fg.controls['stopType'].value === 1) {
          fg.controls['salesOrderNumber'].setValidators(Validators.required);
        } else {
          fg.controls['salesOrderNumber'].clearValidators();
        }
        if (this.isMaintenanceOrderRequired$$.value && fg.controls['stopType'].value === 1) {
          fg.controls['maintenanceOrderNumber'].setValidators(Validators.required);
        } else {
          fg.controls['maintenanceOrderNumber'].clearValidators();
        }
      });

    fg.get('payload')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(() => {
        this.prefillPayloadQuantityIfPayloadDropoff(fg);
      });
    return fg;
  }

  private setupValidationErrors() {
    combineLatest([
      this.payloadSummaries$,
      // The two below are just to trigger a recalculation
      this.customStops.valueChanges.pipe(
        startWith(null as any),
        map(() => this.customStopsValue()),
      ),
      this.customStops.statusChanges,
    ])
      .pipe(
        map(([payloadSummaries]) => {
          let hasError = false;
          const validationErrors: {
            [key: string]: any;
          } = {};
          if (this.customStops.invalid && this.customStops.errors) {
            hasError = true;
            validationErrors.customStops = {
              ...this.customStops.errors,
            };
          }
          if (this.customStops.controls.some((control) => control.invalid)) {
            hasError = true;
            validationErrors.customStopsLine = 'Please fill in all fields';
          }
          if (payloadSummaries.some((summary) => summary.leftOver !== 0)) {
            hasError = true;
            validationErrors.invalidTotals = 'All payloads must 0 out';
          }
          if (hasError) {
            return validationErrors;
          }
          return null;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((errors) => {
        this.validationErrors = errors;
      });
  }

  private prefillPayloadDropoffInfoIfPayloadDropoff(formGroup: FormGroup) {
    if (formGroup.get('stopType').value !== TaskType.TASK_TYPE_DROPOFF) {
      return;
    }

    const stopsValue = this.customStopsValue();
    const payloadPickupStops = this.customStops.controls.filter((control) => {
      const value: CustomStop = control.value;
      return value.stopType === TaskType.TASK_TYPE_PICKUP && !!value.payload;
    });
    const payloadDropoffStops = stopsValue.filter((v) => v.stopType === TaskType.TASK_TYPE_DROPOFF);
    const firstUnmatchedPickup = payloadPickupStops.find((pickupStop) =>
      payloadDropoffStops.every(
        (dropoffStop) => !dropoffStop.payload || dropoffStop.payload.id !== pickupStop.value.payload.id,
      ),
    );

    if (!firstUnmatchedPickup) {
      return;
    }

    if (formGroup.get('payload').value === null) {
      formGroup.get('payload').setValue(firstUnmatchedPickup.get('payload').value);
    }

    if (formGroup.get('quantity').value === null) {
      formGroup.get('quantity').setValue(firstUnmatchedPickup.get('quantity').value);
    }
  }

  private async prefillPayloadQuantityIfPayloadDropoff(formGroup: FormGroup) {
    if (formGroup.get('stopType').value !== TaskType.TASK_TYPE_DROPOFF) {
      return;
    }
    const thisPayload = formGroup.get('payload').value as Payload.AsObject;
    if (!thisPayload) {
      return;
    }

    const thisPurchaseOrderName = formGroup.get('purchaseOrderName').value as string;

    const keyValue = this.isPurchaseOrderRequired$$.value || thisPurchaseOrderName ? thisPurchaseOrderName : 'key';

    const key = `${thisPayload.id}-${keyValue}`;

    const payloadSummaries = await this.payloadSummaries$.pipe(take(1)).toPromise();
    const thisPayloadSummary = payloadSummaries.find((summary) => summary.key === key);
    if (thisPayloadSummary && thisPayloadSummary.leftOver > 0) {
      formGroup.get('quantity').setValue(thisPayloadSummary.leftOver);
    }
  }
}
