var _a;
import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { pick, sort } from 'remeda';
import { TaskStatus, TaskType } from '~proto/types/types_pb';
import { getClosedSiteForTaskForMappableOrder, orderIsToday } from '~utilities/orderHelpers';
import { getFirstAndLastTask, getFirstTask, getLastTask } from './getFirstTask';
var taskTypePrettyNameMap = (_a = {},
    _a[TaskType.TASK_TYPE_PICKUP] = 'En Route to Pickup',
    _a[TaskType.TASK_TYPE_DROPOFF] = 'En Route to Drop Off',
    _a[TaskType.TASK_TYPE_INVALID] = '',
    _a[TaskType.TASK_TYPE_RETURN_MATERIALS] = 'Return Trailer',
    _a[TaskType.TASK_TYPE_OTHER] = 'Other',
    _a[TaskType.TASK_TYPE_AD_HOC] = 'Ad Hoc',
    _a[TaskType.TASK_TYPE_ASSET_PICKUP] = 'Asset Pickup',
    _a);
export function groupOrdersByTaskType(orders, taskStatus) {
    var typeMap = {};
    orders.forEach(function (order) {
        var foundTask = false;
        order.taskSummariesList.forEach(function (taskSummary) {
            if (taskSummary.status === taskStatus) {
                foundTask = true;
                if (!typeMap[taskSummary.type]) {
                    typeMap[taskSummary.type] = {
                        groupHeader: taskTypePrettyNameMap[taskSummary.type],
                        orderCards: [],
                    };
                }
                var asOrderCard = convertMappableOrderToOrderCard(order);
                if (asOrderCard) {
                    typeMap[taskSummary.type].orderCards.push(asOrderCard);
                }
            }
        });
        if (!foundTask) {
            var firstTask = getFirstTask(order.taskSummariesList);
            if (!typeMap[firstTask.type]) {
                typeMap[firstTask.type] = {
                    groupHeader: taskTypePrettyNameMap[firstTask.type],
                    orderCards: [],
                };
            }
            var asOrderCard = convertMappableOrderToOrderCard(order);
            if (asOrderCard) {
                typeMap[firstTask.type].orderCards.push(asOrderCard);
            }
        }
    });
    var groupedOrders = Object.values(typeMap)
        .filter(function (group) { return group.orderCards.length > 0; })
        .sort(function (a, b) { return a.groupHeader.localeCompare(b.groupHeader); });
    return groupedOrders;
}
export function groupOrdersByBillingTaskPayloadName(orders) {
    var nameMap = {};
    orders.forEach(function (order) {
        if (!order.taskSummariesList || !order.taskSummariesList.length) {
            return;
        }
        var billableTasks = order.taskSummariesList.filter(function (task) { return task.isBillable && task.payload; });
        if (billableTasks.length === 0) {
            billableTasks = order.taskSummariesList.filter(function (task) { return task.payload; });
        }
        billableTasks.sort(function (a, b) { return a.sequence - b.sequence; });
        var currentTask = billableTasks[0];
        var payloadName = currentTask && currentTask.payload && currentTask.payload.name;
        if (!payloadName) {
            payloadName = 'No Payload';
        }
        if (!nameMap[payloadName]) {
            nameMap[payloadName] = {
                groupHeader: payloadName,
                orderCards: [],
            };
        }
        var asOrderCard = convertMappableOrderToOrderCard(order);
        if (asOrderCard) {
            nameMap[payloadName].orderCards.push(asOrderCard);
        }
    });
    var groupedOrders = Object.values(nameMap)
        .filter(function (group) { return group.orderCards.length > 0; })
        .sort(function (a, b) { return a.groupHeader.localeCompare(b.groupHeader); });
    return groupedOrders;
}
export function groupOrdersByOrderStatus(orders) {
    var statusMap = {};
    orders.forEach(function (order) {
        var orderStatus = order.status;
        // if (!orderStatus) {
        //   return;
        // }
        if (!statusMap[orderStatus]) {
            statusMap[orderStatus] = {
                groupHeader: orderStatus,
                orderCards: [],
            };
        }
        var asOrderCard = convertMappableOrderToOrderCardForLastSequence(order);
        if (asOrderCard) {
            statusMap[orderStatus].orderCards.push(asOrderCard);
        }
    });
    var groupedOrders = Object.values(statusMap)
        .filter(function (group) { return group.orderCards.length > 0; })
        .sort(function (a, b) { return a.groupHeader - b.groupHeader; });
    return groupedOrders;
}
export function convertMappableOrderToOrderCardForLastSequence(order) {
    if (!order.taskSummariesList || !order.taskSummariesList.length) {
        return null;
    }
    var firstSequenceTask = getFirstTask(order.taskSummariesList);
    var lastSequenceTask = getLastTask(order.taskSummariesList);
    return {
        arrivedTime: lastSequenceTask.stats ? lastSequenceTask.stats.arrivalUnix : 0,
        currentTaskStatus: lastSequenceTask.status,
        displayId: order.displayId,
        driverName: order.driver && order.driver.user ? order.driver.user.name : '',
        eta: lastSequenceTask.stats && lastSequenceTask.stats.driverEta
            ? moment()
                .add(lastSequenceTask.stats.driverEta, 'minutes')
                .unix() * 1000
            : null,
        exceptionTitle: order.driver && order.driver.driverStatus && order.driver.driverStatus.exceptionTitle,
        exitTime: lastSequenceTask.stats ? lastSequenceTask.stats.exitUnix : 0,
        haulisPickVendorName: null,
        id: order.id,
        isAsap: order.isAsap,
        isAvailableToDispatch: order.isAvailableToDispatch,
        isException: order.driver && order.driver.driverStatus && order.driver.driverStatus.isException,
        lastWaypointClientCreationUnix: order.driver && order.driver.lastWaypoint ? order.driver.lastWaypoint.clientCreatedUnix : 0,
        missingData: order.missingDataList,
        nextSiteName: lastSequenceTask && lastSequenceTask.site ? lastSequenceTask.site.name : '',
        payloadDetails: getPayloadDetailsFromOrderSummary(order),
        saUniqueId: order.saUniqueId,
        scheduledUnix: lastSequenceTask.scheduledUnix,
        siteSequence: sort(order.taskSummariesList, sortTasks).map(function (task) { return pick(task.site, ['id', 'name']); }),
        siteSequenceName: getSiteSequenceName(firstSequenceTask, lastSequenceTask, order.taskSummariesList.length),
        status: order.status,
        statusUnix: order.statusUnix,
        tasks: order.taskSummariesList,
        trailerName: order.trailerName,
        truckName: order.truckName,
        upForGrabsStatus: order.isUpForGrabs || null,
        upForGrabsUnix: order.upForGrabsUnix * 1000 || 0,
        updatedUnix: order.updatedUnix,
        vendorName: order.vendorName,
    };
}
var activeTaskStatuses = [
    TaskStatus.TASK_STATUS_IN_PROGRESS,
    TaskStatus.TASK_STATUS_QUEUED,
];
export function convertMappableOrderToOrderCard(order) {
    if (!order.taskSummariesList || !order.taskSummariesList.length) {
        return null;
    }
    var _a = tslib_1.__read(getFirstAndLastTask(order.taskSummariesList), 2), firstSequenceTask = _a[0], lastSequenceTask = _a[1];
    var firstTaskWithPayload = sort(order.taskSummariesList, function (a, b) { return a.sequence - b.sequence; }).find(function (task) { return task.status === TaskStatus.TASK_STATUS_IN_PROGRESS; }) || order.taskSummariesList.find(function (task) { return task.sequence === 1; });
    if (!firstTaskWithPayload.payload) {
        var allPayloadTasks = order.taskSummariesList.filter(function (task) { return task.payload && activeTaskStatuses.includes(task.status); });
        allPayloadTasks.sort(function (a, b) { return a.sequence - b.sequence; });
        if (allPayloadTasks.length) {
            firstTaskWithPayload = allPayloadTasks[0];
        }
    }
    return {
        arrivedTime: firstTaskWithPayload.stats ? firstTaskWithPayload.stats.arrivalUnix : 0,
        currentTaskStatus: firstTaskWithPayload.status,
        displayId: order.displayId,
        driverName: order.driver && order.driver.user ? order.driver.user.name : '',
        eta: firstTaskWithPayload.stats && firstTaskWithPayload.stats.driverEta
            ? moment()
                .add(firstTaskWithPayload.stats.driverEta, 'minutes')
                .unix() * 1000
            : null,
        exceptionTitle: order.driver && order.driver.driverStatus && order.driver.driverStatus.exceptionTitle,
        exitTime: firstTaskWithPayload.stats ? firstTaskWithPayload.stats.exitUnix : 0,
        fleetManagerDriverName: order.fleetManagerPlan
            ? order.fleetManagerPlan.driverName
            : '',
        fleetManagerDriverValid: order.fleetManagerPlan
            ? order.fleetManagerPlan.driverValid
            : false,
        haulisPickVendorName: null,
        id: order.id,
        isAsap: order.isAsap,
        isAvailableToDispatch: order.isAvailableToDispatch,
        isException: order.driver && order.driver.driverStatus && order.driver.driverStatus.isException,
        isScheduledToday: order.fleetManagerPlan &&
            order.fleetManagerPlan.plannedStartUnix
            ? orderIsToday(order.fleetManagerPlan.plannedStartUnix)
            : orderIsToday(firstTaskWithPayload.scheduledUnix),
        lastWaypointClientCreationUnix: order.driver && order.driver.lastWaypoint ? order.driver.lastWaypoint.clientCreatedUnix : 0,
        minutesDelayed: order.minutesStats
            ? order.minutesStats.minutesDelayed
            : 0,
        minutesLate: order.minutesStats
            ? order.minutesStats.minutesLate
            : 0,
        minutesValid: order.minutesStats
            ? order.minutesStats.minutesValid
            : false,
        missingData: order.missingDataList,
        nextSiteName: firstTaskWithPayload.site ? firstTaskWithPayload.site.name : null,
        payloadDetails: getPayloadDetailsFromOrderSummary(order),
        plannedStartUnix: order.fleetManagerPlan &&
            order.fleetManagerPlan.plannedStartUnix
            ? order.fleetManagerPlan.plannedStartUnix
            : 0,
        saUniqueId: order.saUniqueId,
        scheduledUnix: firstTaskWithPayload.scheduledUnix,
        siteClosed: getClosedSiteForTaskForMappableOrder(order),
        siteSequence: sort(order.taskSummariesList, sortTasks).map(function (task) { return pick(task.site, ['id', 'name']); }),
        siteSequenceName: getSiteSequenceName(firstSequenceTask, lastSequenceTask, order.taskSummariesList.length),
        status: order.status,
        statusUnix: order.statusUnix,
        tasks: order.taskSummariesList,
        trailerName: order.trailerName,
        truckName: order.truckName,
        upForGrabsStatus: order.isUpForGrabs || null,
        upForGrabsUnix: order.upForGrabsUnix * 1000 || 0,
        updatedUnix: order.updatedUnix,
        vendorName: order.vendorName,
    };
}
export function getPayloadDetailsFromOrderSummary(order) {
    return getPayloadDetailsFromOrder({ tasksList: order.taskSummariesList });
}
export function getPayloadDetailsFromCalendarOrder(order) {
    var fakeOrder = {
        tasksList: order.taskSummariesList.map(function (taskSummary) { return ({
            subTasksList: taskSummary.subTasksList.map(function (subTaskSummary) {
                var payload = {
                    createdUnix: 0,
                    driverCertificationsList: [],
                    favorited: false,
                    frequentlyUsedQuantity: 0,
                    id: subTaskSummary.payloadId,
                    lastUsedQuantity: 0,
                    name: subTaskSummary.payloadName,
                    type: {
                        id: subTaskSummary.payloadTypeId,
                        name: subTaskSummary.payloadTypeName,
                    },
                    unit: {
                        abbreviation: subTaskSummary.unitAbbreviation,
                        id: -1,
                        name: '',
                    },
                    updatedUnix: 0,
                };
                var subtaskType = {
                    id: -1,
                    name: subTaskSummary.subTaskTypeName,
                };
                return {
                    actualQuantity: subTaskSummary.actualQuantity,
                    orderedQuantity: subTaskSummary.orderedQuantity,
                    payload: payload,
                    type: subtaskType,
                };
            }),
        }); }),
    };
    return getPayloadDetailsFromOrder(fakeOrder);
}
export function getPayloadDetailsFromOrder(order) {
    return Object.values(order.tasksList.reduce(function (acc, task) {
        task.subTasksList.forEach(function (subTask) {
            if (subTask.type && (subTask.type.name === 'pickup-payload' || subTask.type.name === 'pickup')) {
                if (subTask.payload) {
                    if (subTask.payload && !acc[subTask.payload.id]) {
                        acc[subTask.payload.id] = {
                            orderedQuantity: actualOrOrderedQuantity(subTask),
                            payloadName: subTask.payload.name,
                            payloadType: subTask.payload.type.name,
                            payloadTypeId: subTask.payload.type.id,
                            payloadUnits: subTask.payload.unit.abbreviation,
                        };
                    }
                    else {
                        acc[subTask.payload.id].orderedQuantity += actualOrOrderedQuantity(subTask);
                    }
                }
            }
        });
        return acc;
    }, {}));
}
export function actualOrOrderedQuantity(subtask) {
    if (subtask.actualQuantity > Number.MIN_SAFE_INTEGER) {
        return subtask.actualQuantity;
    }
    return subtask.orderedQuantity;
}
function sortTasks(a, b) {
    return a.sequence - b.sequence;
}
function getSiteSequenceName(firstTask, lastTask, taskCount) {
    if (!firstTask.site || !lastTask.site) {
        return '';
    }
    if (taskCount <= 2) {
        return firstTask.site.name + " \u2192 " + lastTask.site.name;
    }
    return firstTask.site.name + " \u2192 +" + (taskCount - 2) + " more \u2192 " + lastTask.site.name;
}
