import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { sort } from 'remeda';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { UpdateCustomFieldSettingsRequest } from '~proto/fields/fields_api_pb';
import { CustomFieldSetting } from '~proto/fields/fields_pb';
import { moveItemInFormArray } from '~utilities/moveItemInFormArray';
import { customFieldsTypes, LmoCustomOrderFieldsService } from '../../services/lmo-custom-order-fields.service';

interface FormArrayEntry {
  key: string;
  required: boolean;
  type: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-lmo-custom-fields-orders',
  styleUrls: ['./lmo-custom-fields-orders.component.scss'],
  templateUrl: './lmo-custom-fields-orders.component.html',
})
export class LmoCustomFieldsOrdersComponent implements OnInit {
  public networkActive$ = new BehaviorSubject(false);
  public formArr = new FormArray([]);
  public customFieldsTypes = customFieldsTypes;
  constructor(
    private customFieldOrderService: LmoCustomOrderFieldsService,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
  ) {}

  public ngOnInit() {
    this.customFieldOrderService.fields$
      .pipe(
        distinctUntilChanged(),
        tap((fields) => {
          this.networkActive$.next(false);
          this.formArr.clear();
          const sortedFields = sort(fields, (a, b) => a.position - b.position);
          sortedFields.forEach((field) => {
            this.formArr.push(makeFormGroup(field));
          });
          this.cd.markForCheck();
        }),
      )
      .subscribe();
  }

  public addField() {
    const emptyFormGroup = makeFormGroup({});
    this.formArr.push(emptyFormGroup);
  }

  public removeField(index: number) {
    this.formArr.removeAt(index);
  }

  public drop(event: CdkDragDrop<FormGroup[]>) {
    moveItemInFormArray(this.formArr, event.previousIndex, event.currentIndex);
  }

  public save() {
    if (this.formArr.invalid) {
      this.snackBar.open('Invalid', null, { duration: 2000, panelClass: 'snackbar-error' });
      return;
    }
    this.networkActive$.next(true);
    const request = new UpdateCustomFieldSettingsRequest();
    const values: FormArrayEntry[] = this.formArr.value;
    values.forEach((value, index) => {
      const field = new CustomFieldSetting();
      field.setKey(value.key);
      field.setPosition(index + 1);
      field.setRequired(value.required);
      field.setType(value.type);
      request.addCustomFieldSettings(field);
    });

    this.customFieldOrderService.updateFields(request);
  }

  public trackByIndex(index: number) {
    return index;
  }
}

function makeFormGroup(field: Partial<CustomFieldSetting.AsObject>): FormGroup {
  return new FormGroup({
    key: new FormControl(field.key || null, [Validators.required]),
    required: new FormControl(field.required || false, [Validators.required]),
    type: new FormControl(field.type || customFieldsTypes[0], [Validators.required]),
  });
}
