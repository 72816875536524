import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validator, ValidatorFn, Validators, } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, take, takeUntil } from 'rxjs/operators';
import { TaskType } from '~proto/types/types_pb';
import { moveItemInFormArray } from '~utilities/moveItemInFormArray';
function noSameSiteNextToEachOther() {
    return function (formArray) {
        var hasSameSiteNextToEachOther = formArray.controls.some(function (control, index) {
            // Nothing to compare to
            if (index === 0) {
                return false;
            }
            var value = control.value;
            var previousValue = formArray.controls[index - 1].value;
            return value.location && previousValue.location && value.location.id === previousValue.location.id;
        });
        return hasSameSiteNextToEachOther
            ? { sameNextSite: 'Cannot create two tasks with the same site next to each other' }
            : null;
    };
}
var CreateOrderCustomStopsComponent = /** @class */ (function () {
    function CreateOrderCustomStopsComponent(siteService, fb, cd, payloadService) {
        this.siteService = siteService;
        this.fb = fb;
        this.cd = cd;
        this.payloadService = payloadService;
        this.destroy$ = new Subject();
        this.customStops = new FormArray([]);
        this.taskTypes = TaskType;
        this.stopTypes = [
            {
                name: 'Pickup Payload',
                value: TaskType.TASK_TYPE_PICKUP,
            },
            {
                name: 'Dropoff Payload',
                value: TaskType.TASK_TYPE_DROPOFF,
            },
            {
                name: 'Pickup Trailer',
                value: TaskType.TASK_TYPE_ASSET_PICKUP,
            },
            {
                name: 'Dropoff Trailer',
                value: TaskType.TASK_TYPE_RETURN_MATERIALS,
            },
            {
                name: 'Ad-Hoc (No Payload)',
                value: TaskType.TASK_TYPE_AD_HOC,
            },
        ];
        this.customStops.setValidators([noSameSiteNextToEachOther()]);
        this.addCurrentSiteLineItem();
    }
    CreateOrderCustomStopsComponent.prototype.customStopsValue = function () {
        return this.customStops.value;
    };
    CreateOrderCustomStopsComponent.prototype.ngOnInit = function () {
        this.currentSite$ = this.siteService.currentSite$;
        this.currentSitePayloads$ = this.payloadService.currentSitePayloads$;
        this.setupValidationErrors();
    };
    CreateOrderCustomStopsComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    };
    CreateOrderCustomStopsComponent.prototype.writeValue = function (obj) {
        if (Array.isArray(obj) && obj.length === this.customStops.length) {
            this.customStops.setValue(obj);
        }
    };
    CreateOrderCustomStopsComponent.prototype.registerOnChange = function (fn) {
        this.customStops.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(function (value) {
            fn(value);
        });
    };
    CreateOrderCustomStopsComponent.prototype.registerOnTouched = function (fn) { };
    CreateOrderCustomStopsComponent.prototype.setDisabledState = function (isDisabled) {
        isDisabled ? this.customStops.disable() : this.customStops.enable();
    };
    CreateOrderCustomStopsComponent.prototype.validate = function () {
        return this.validationErrors;
    };
    CreateOrderCustomStopsComponent.prototype.registerOnValidatorChange = function (fn) {
        this.customStops.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(function () {
            fn();
        });
    };
    CreateOrderCustomStopsComponent.prototype.trackByControl = function (_index, control) {
        return control;
    };
    CreateOrderCustomStopsComponent.prototype.trackByName = function (_index, record) {
        return record.name;
    };
    CreateOrderCustomStopsComponent.prototype.selectCustomOrderLineSite = function (index, event) {
        this.customStops
            .at(index)
            .get('location')
            .setValue(event.pickupFrom);
    };
    CreateOrderCustomStopsComponent.prototype.selectCustomOrderLinePayload = function (index, event) {
        this.customStops
            .at(index)
            .get('payload')
            .setValue(event.payload);
        this.customStops
            .at(index)
            .get('quantity')
            .setValue(event.quantity || 0);
    };
    CreateOrderCustomStopsComponent.prototype.selectCustomOrderLineTrailer = function (index, event) {
        this.customStops
            .at(index)
            .get('assetNumber')
            .setValue(event.trailer);
    };
    CreateOrderCustomStopsComponent.prototype.removeCustomOrderLink = function (index) {
        this.customStops.removeAt(index);
    };
    CreateOrderCustomStopsComponent.prototype.addCustomOrderLine = function () {
        var fg = this.getNewFormLine();
        fg.get('stopType').setValue(TaskType.TASK_TYPE_PICKUP);
        this.customStops.push(fg);
    };
    CreateOrderCustomStopsComponent.prototype.insertLineBefore = function (index) {
        var fg = this.getNewFormLine();
        fg.get('stopType').setValue(TaskType.TASK_TYPE_PICKUP);
        this.customStops.insert(index, fg);
    };
    CreateOrderCustomStopsComponent.prototype.insertLineAfter = function (index) {
        if (index + 1 >= this.customStops.length) {
            this.addCustomOrderLine();
        }
        else {
            var fg = this.getNewFormLine();
            fg.get('stopType').setValue(TaskType.TASK_TYPE_PICKUP);
            this.customStops.insert(index + 1, fg);
        }
    };
    CreateOrderCustomStopsComponent.prototype.drop = function (event) {
        moveItemInFormArray(this.customStops, event.previousIndex, event.currentIndex);
    };
    CreateOrderCustomStopsComponent.prototype.addCurrentSiteLineItem = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var site, fg;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.siteService.currentSite$
                            .pipe(filter(function (s) { return !!s; }), take(1))
                            .toPromise()];
                    case 1:
                        site = _a.sent();
                        fg = this.getNewFormLine(site, true);
                        fg.get('stopType').setValue(TaskType.TASK_TYPE_DROPOFF);
                        this.customStops.push(fg);
                        this.cd.markForCheck();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateOrderCustomStopsComponent.prototype.getNewFormLine = function (site, currentSite) {
        var _this = this;
        if (currentSite === void 0) { currentSite = false; }
        var fg = this.fb.group({
            assetNumber: [null, [Validators.required]],
            location: [site, [Validators.required]],
            payload: [null, [Validators.required]],
            quantity: [null, [Validators.required]],
            stopType: [null, [Validators.required]],
        });
        if (currentSite) {
            fg.addControl('_isCurrentSite', this.fb.control(true));
        }
        fg.get('stopType')
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe(function () {
            switchCustomOrderLineTo(fg);
            _this.prefillTrailerDropoffInfoIfTrailerDropoff(fg);
            _this.prefillPayloadDropoffInfoIfPayloadDropoff(fg);
        });
        return fg;
    };
    CreateOrderCustomStopsComponent.prototype.setupValidationErrors = function () {
        var _this = this;
        combineLatest([
            this.customStops.valueChanges.pipe(startWith(null), map(function () { return _this.customStopsValue(); })),
            this.customStops.statusChanges,
        ])
            .pipe(map(function () {
            var hasError = false;
            var validationErrors = {};
            if (_this.customStops.invalid && _this.customStops.errors) {
                hasError = true;
                validationErrors.customStops = tslib_1.__assign({}, _this.customStops.errors);
            }
            _this.customStops.controls.some(function (control) {
                if (control.invalid) {
                    hasError = true;
                    validationErrors.customStopsLine = 'Please fill in all fields';
                    return true;
                }
            });
            if (hasError) {
                return validationErrors;
            }
            return null;
        }), takeUntil(this.destroy$))
            .subscribe(function (errors) {
            _this.validationErrors = errors;
        });
    };
    CreateOrderCustomStopsComponent.prototype.prefillTrailerDropoffInfoIfTrailerDropoff = function (formGroup) {
        if (formGroup.get('stopType').value !== TaskType.TASK_TYPE_RETURN_MATERIALS) {
            return;
        }
        var pickupInfo = this.customStops.controls.find(function (control) { return control.get('stopType').value === TaskType.TASK_TYPE_ASSET_PICKUP; });
        if (!pickupInfo) {
            return;
        }
        if (formGroup.get('location').value === null) {
            formGroup.get('location').setValue(pickupInfo.get('location').value);
        }
        if (formGroup.get('assetNumber').value === null) {
            formGroup.get('assetNumber').setValue(pickupInfo.get('assetNumber').value);
        }
    };
    CreateOrderCustomStopsComponent.prototype.prefillPayloadDropoffInfoIfPayloadDropoff = function (formGroup) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var currentSite, stopsValue, payloadPickupStops, payloadDropoffStops, firstUnmatchedPickup;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (formGroup.get('stopType').value !== TaskType.TASK_TYPE_DROPOFF) {
                            return [2 /*return*/];
                        }
                        if (!(formGroup.get('location').value === null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.siteService.currentSite$.pipe(take(1)).toPromise()];
                    case 1:
                        currentSite = _a.sent();
                        formGroup.get('location').setValue(currentSite);
                        _a.label = 2;
                    case 2:
                        stopsValue = this.customStopsValue();
                        payloadPickupStops = this.customStops.controls.filter(function (control) {
                            var value = control.value;
                            return value.stopType === TaskType.TASK_TYPE_PICKUP && !!value.payload;
                        });
                        payloadDropoffStops = stopsValue.filter(function (v) { return v.stopType === TaskType.TASK_TYPE_DROPOFF; });
                        firstUnmatchedPickup = payloadPickupStops.find(function (pickupStop) {
                            return payloadDropoffStops.every(function (dropoffStop) { return !dropoffStop.payload || dropoffStop.payload.id !== pickupStop.value.payload.id; });
                        });
                        if (!firstUnmatchedPickup) {
                            return [2 /*return*/];
                        }
                        if (formGroup.get('payload').value === null) {
                            formGroup.get('payload').setValue(firstUnmatchedPickup.get('payload').value);
                        }
                        if (formGroup.get('quantity').value === null) {
                            formGroup.get('quantity').setValue(firstUnmatchedPickup.get('quantity').value);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return CreateOrderCustomStopsComponent;
}());
export { CreateOrderCustomStopsComponent };
function switchCustomOrderLineTo(formGroup) {
    var stopType = formGroup.get('stopType').value;
    switch (stopType) {
        case TaskType.TASK_TYPE_AD_HOC:
            formGroup.get('assetNumber').disable();
            formGroup.get('payload').disable();
            formGroup.get('quantity').disable();
            break;
        case TaskType.TASK_TYPE_ASSET_PICKUP:
        case TaskType.TASK_TYPE_RETURN_MATERIALS:
            formGroup.get('assetNumber').enable();
            formGroup.get('payload').disable();
            formGroup.get('quantity').disable();
            break;
        default:
            formGroup.get('assetNumber').disable();
            formGroup.get('payload').enable();
            formGroup.get('quantity').enable();
    }
}
