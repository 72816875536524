import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import * as moment from 'moment';
import { OwlDateTimeComponent } from 'ng-pick-datetime';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, finalize, map, startWith, take } from 'rxjs/operators';
import { OrdersService } from 'src/app/lmo/services/orders.service';
import { ContractsService } from '~lmo/services/contracts.service';
import { BusinessLine } from '~proto/businessline/businessline_pb';
import { VendorContract, VendorContractType } from '~proto/contracts/contracts_pb';
import { LMOUpdatePendingOrderRequest } from '~proto/order/order_api_pb';
import { CostCenter, Order } from '~proto/order/order_pb';
import { Payload } from '~proto/payload/payload_pb';
import { Site } from '~proto/site/site_pb';
import { Trailer } from '~proto/trailer/trailer_pb';
import { Truck } from '~proto/truck/truck_pb';
import { IdName, OrderType } from '~proto/types/types_pb';
import { UserDetailsForIntercomRequest, UserDetailsForIntercomResponse } from '~proto/user/user_api_pb';
import { UserAPI } from '~proto/user/user_api_pb_service';
import { GrpcService } from '~services/grpc.service';
import { RoleService } from '~services/role.service';
import { trackById } from '~utilities/trackById';
import { CostCenterService } from '../../../billing/services/cost-center.service';

interface FormValue {
  scheduledDeliveryWindow: [Date, Date];
  isAsapOrder: boolean;
  trailer: Trailer.AsObject;
  truck: Truck.AsObject;
  salesOrderNumber: string;
  payload: Payload.AsObject;
  quantity: number;
  pickupFrom: Site.AsObject;
  truckingVendor: IdName.AsObject;
  purchaseOrderName: string;
  bolNumber: string;
  description: string;
  costCenter: string;
  costCenterId: number;
  vendorContractId: number;
  contractTypeId: number;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-lmo-edit-pending-order',
  styleUrls: ['./lmo-edit-pending-order.component.scss'],
  templateUrl: './lmo-edit-pending-order.component.html',
})
export class LmoEditPendingOrderComponent implements OnInit {
  public payloads$: Observable<Payload.AsObject[]>;
  public currentOrder$: Observable<Order.AsObject>;
  public networkActive$ = new BehaviorSubject(false);
  public formGroup: FormGroup;
  public orderTypes = OrderType;

  public truckingContracts$$ = new BehaviorSubject<VendorContract.AsObject[]>([]);
  public trackById = trackById;

  public contractTypes$: Observable<VendorContractType.AsObject[]>;
  public contractType$$ = new BehaviorSubject<number>(-1);
  public costCenters$: Observable<CostCenter.AsObject[]>;

  public isCostCenterRequired$$ = new BehaviorSubject<Boolean>(false);
  public isPurchaseOrderRequired$$ = new BehaviorSubject<Boolean>(false);
  public isSalesOrderRequired$$ = new BehaviorSubject<Boolean>(false);
  public isBusinessLineCostCenterRelationEnabled$$ = new BehaviorSubject<Boolean>(false);
  public businessLineLinked$$: BehaviorSubject<BusinessLine.AsObject> = new BehaviorSubject(null);
  public isCCValid: any;
  private isPOValid = 'true';
  private isSOValid = 'true';
  public costCenterObj: CostCenter.AsObject;

  /* tslint:disable:no-unused-variable */
  @ViewChild('dt', { static: false }) private dateTime: OwlDateTimeComponent<any>;

  constructor(
    private orderService: OrdersService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private costCenterService: CostCenterService,
    private contractService: ContractsService,
    private grpcService: GrpcService,
    private roleService: RoleService,
  ) {}

  private formValue(): FormValue {
    return this.formGroup.value as FormValue;
  }

  public ngOnInit() {
    this.loadRequiredFieldSettings();
    this.costCenters$ = this.costCenterService.costCenters$;
    this.currentOrder$ = this.orderService.currentOrder$;
    this.formGroup = this.fb.group({
      bolNumber: null,
      contractTypeId: null,
      costCenter: null,
      costCenterId: [null, this.isCostCenterRequired$$.value ? [Validators.required] : ''],
      description: null,
      isAsapOrder: null,
      payload: null,
      pickupFrom: null,
      purchaseOrderName: null,
      quantity: null,
      salesOrderNumber: null,
      scheduledDeliveryWindow: null,
      trailer: null,
      truck: null,
      truckingVendor: null,
      vendorContractId: null,
    });

    this.currentOrder$
      .pipe(
        filter((order) => !!order),
        take(1),
      )
      .subscribe((order) => {
        if (!order.tasksList || order.tasksList.length === 0) {
          return;
        }
        this.businessLineLinked$$.next(order.businessLine);
        const pickupTask = order.tasksList[0];
        if (this.isPurchaseOrderRequired$$.value && order.costCenter === '') {
          this.isCCValid = -1;
        }
        if (order && order.type !== OrderType.ORDER_TYPE_CUSTOM) {
          this.isPurchaseOrderRequired$$.value
            ? this.formGroup.controls['purchaseOrderName'].setValidators(Validators.required)
            : this.formGroup.controls['purchaseOrderName'].clearValidators();
          this.isSalesOrderRequired$$.value
            ? this.formGroup.controls['salesOrderNumber'].setValidators(Validators.required)
            : this.formGroup.controls['salesOrderNumber'].clearValidators();
        }
        this.costCenterObj = order.costCenterObj;
        this.formGroup.patchValue({
          bolNumber: pickupTask.billing ? pickupTask.billing.bolNumber : null,
          contractTypeId: order.contractType ? order.contractType.id : null,
          costCenter: order.costCenterObj ? order.costCenterObj.name : '',
          costCenterId: order.costCenterObj ? order.costCenterObj.id : -1,
          description: order.description,
          isAsapOrder: order.isAsap,
          payload: pickupTask.payload,
          pickupFrom: pickupTask.site,
          purchaseOrderName: pickupTask.billing ? pickupTask.billing.purchaseOrderName : null,
          quantity:
            pickupTask.actualQuantity > Number.MIN_SAFE_INTEGER
              ? pickupTask.actualQuantity
              : pickupTask.orderedQuantity > Number.MIN_SAFE_INTEGER
              ? pickupTask.orderedQuantity
              : 0,
          salesOrderNumber: order.salesOrderNumber,
          scheduledDeliveryWindow: order.deliveryWindowStart
            ? [
                moment(order.deliveryWindowStart * 1000).toDate(),
                moment(order.deliveryWindowStart * 1000)
                  .add(order.deliveryWindowDurationMinutes, 'minutes')
                  .toDate(),
              ]
            : null,
          trailer: order.trailer,
          truck: order.truck,
          truckingVendor: order.truckingVendor,
          vendorContractId: order.vendorContract ? order.vendorContract.id : null,
        });

        this.listenToAsap();
        this.listenToPayload();
        this.setupPayloads();

        this.listenContractTypeChanges(order);
        this.listenContractChange(order);
        this.listenCostCenterChanges();

        this.contractType$$.next(order.contractType ? order.contractType.id : -1);
        this.checkIsBusinessLineCostCenterRelationEnabled();
      });
  }

  private listenContractTypeChanges(order: Order.AsObject) {
    this.contractTypes$ = this.contractService.contractTypes$;

    this.formGroup.get('contractTypeId').valueChanges.subscribe((value) => {
      this.contractType$$.next(value);
      this.formGroup.get('truckingVendor').setValue(null);
      this.formGroup.get('vendorContractId').setValue(null);
    });
  }

  private listenContractChange(order: Order.AsObject) {
    combineLatest([
      this.formGroup
        .get('truckingVendor')
        .valueChanges.pipe(startWith(order.truckingVendor ? order.truckingVendor : null)),
      this.formGroup
        .get('contractTypeId')
        .valueChanges.pipe(startWith(order.contractType ? order.contractType.id : -1)),
      this.contractService.nonExpiredContracts$,
    ]).subscribe(([vendor, contractTypeId, contracts]) => {
      if (vendor) {
        this.truckingContracts$$.next(
          contracts.filter(
            (contract) =>
              contract.vendor.id === vendor.id &&
              contract.isEffective &&
              (contractTypeId <= 0 || (contract.contractType && contract.contractType.id === contractTypeId)),
          ),
        );
      } else {
        this.truckingContracts$$.next([]);
      }
    });
  }

  private listenCostCenterChanges() {
    this.formGroup.get('costCenterId').valueChanges.subscribe((value) => {
      this.isCCValid = value;
    });
  }

  private setupPayloads() {
    this.payloads$ = combineLatest([
      this.currentOrder$,
      this.formGroup.get('payload').valueChanges.pipe(startWith(this.formGroup.get('payload').value)),
    ]).pipe(
      map(([order, formPayload]: [Order.AsObject, Payload.AsObject]) => {
        if (order && order.type === OrderType.ORDER_TYPE_CUSTOM) {
          return Object.values(
            order.tasksList.reduce(
              (acc, task) => {
                task.subTasksList.forEach((subtask) => {
                  if (subtask.payload) {
                    acc[subtask.payload.id] = subtask.payload;
                  }
                });
                return acc;
              },
              {} as Record<number, Payload.AsObject>,
            ),
          );
        } else if (formPayload) {
          return [formPayload];
        } else {
          return [];
        }
      }),
    );
  }

  public selected(event) {
    this.formGroup.patchValue(event);
  }

  public isCustomOrder(order: Order.AsObject): Boolean {
    return order.type === OrderType.ORDER_TYPE_CUSTOM;
  }

  public async updateOrder(currentOrder: Order.AsObject) {
    const value = this.formValue();
    this.requiredFieldsCheck(value, currentOrder);

    if (!this.isCCValid || this.isCCValid === -1 || !this.isPOValid || !this.isSOValid) {
      this.snackBar.open(
        `
        ${this.isCostCenterRequired$$.value && (!this.isCCValid || this.isCCValid === -1) ? 'Cost Center, ' : ''}
        ${this.isPurchaseOrderRequired$$.value && !this.isPOValid ? 'PO Number, ' : ''}
        ${this.isSalesOrderRequired$$.value && !this.isSOValid ? 'Sales Order Number' : ''}
        fields are mandatory`,
        null,
        {
          duration: 3000,
        },
      );
      return;
    }

    if (this.formGroup.invalid) {
      return;
    }

    this.networkActive$.next(true);
    const request = new LMOUpdatePendingOrderRequest();
    const mask = new FieldMask();
    const order = await this.currentOrder$.pipe(take(1)).toPromise();
    const isCustomOrder = this.isCustomOrder(order);
    request.setIsAsapOrder(value.isAsapOrder);
    if (!isCustomOrder) {
      request.setPurchaseOrderName(value.purchaseOrderName);
      request.setSalesOrderNumber(value.salesOrderNumber);
      request.setBolNumber(value.bolNumber);
    }
    request.setDescription(value.description);
    request.setTruckingVendorId(value.truckingVendor ? value.truckingVendor.id : 0);
    mask.addPaths('isAsap');
    request.setCostCenter(value.costCenter);
    if (!isCustomOrder) {
      mask.addPaths('purchaseOrderName');
      mask.addPaths('salesOrderNumber');
      mask.addPaths('bolNumber');
    }
    mask.addPaths('description');
    mask.addPaths('truckingVendorId');
    mask.addPaths('lmoCostCenterId');

    mask.addPaths('vendorContractTypeId');
    mask.addPaths('vendorContractId');
    mask.addPaths('vendorContractJson');
    if (order.contractType == null || order.contractType.id !== value.contractTypeId) {
      request.setContractTypeId(value.contractTypeId);
    }
    request.setCostCenterId(value.costCenterId);

    if (order.vendorContract == null || order.vendorContract.id !== value.vendorContractId) {
      request.setVendorContractId(value.vendorContractId);
    }

    if (value.scheduledDeliveryWindow && value.scheduledDeliveryWindow.length) {
      const start = moment(value.scheduledDeliveryWindow[0]);
      const end = moment(value.scheduledDeliveryWindow[1]);
      const windowDuration = moment.duration(end.diff(start)).asMinutes();
      request.setDeliveryWindowStart(start.unix());
      request.setDeliveryWindowDurationMinutes(windowDuration);
      mask.addPaths('deliveryWindowStart');
      mask.addPaths('deliveryWindowDurationMinutes');
    }
    // These cannot be edited on a custom order
    if (order.type !== OrderType.ORDER_TYPE_CUSTOM) {
      if (value.payload) {
        request.setPayloadId(value.payload.id);
        mask.addPaths('payloadId');
      }
      if (value.quantity !== null) {
        request.setOrderedQuantity(value.quantity);
        mask.addPaths('orderedQuantity');
      }
      if (value.pickupFrom) {
        request.setSiteId(value.pickupFrom.id);
        mask.addPaths('siteId');
      }
      if (value.trailer) {
        request.setTrailerId(value.trailer.id);
        mask.addPaths('trailerId');
      }
      if (value.truck) {
        request.setTruckId(value.truck.id);
        mask.addPaths('truckId');
      }
    }
    request.setMask(mask);
    this.orderService
      .updatePendingOrder$(request)
      .pipe(finalize(() => this.networkActive$.next(false)))
      .subscribe(() => {
        this.snackBar.open('Order Updated', null, { duration: 2000 });
      });
  }

  private requiredFieldsCheck(formValue: FormValue, order: Order.AsObject) {
    this.isCCValid = this.isCostCenterRequired$$.value ? formValue.costCenterId : 'true';

    if (order && order.type !== OrderType.ORDER_TYPE_CUSTOM) {
      this.isPOValid = this.isPurchaseOrderRequired$$.value ? formValue.purchaseOrderName : 'true';
      this.isSOValid = this.isSalesOrderRequired$$.value ? formValue.salesOrderNumber : 'true';
    }
  }

  private listenToAsap() {
    this.formGroup
      .get('isAsapOrder')
      .valueChanges.pipe(startWith(this.formGroup.get('isAsapOrder').value))
      .subscribe((value: boolean) => {
        if (value) {
          this.formGroup.get('scheduledDeliveryWindow').disable();
          this.formGroup.get('scheduledDeliveryWindow').reset();
        } else {
          this.formGroup.get('scheduledDeliveryWindow').enable();
        }
      });
  }

  private listenToPayload() {
    this.formGroup.get('payload').valueChanges.subscribe((value: boolean) => {
      if (value) {
        this.formGroup.get('truckingVendor').reset();
      }
    });
  }

  private checkIsBusinessLineCostCenterRelationEnabled() {
    this.roleService.isBusinessLineCostCenterRelationEnabled$.subscribe((isBLCCRelationEnabled: Boolean) => {
      this.isBusinessLineCostCenterRelationEnabled$$.next(isBLCCRelationEnabled);
      if (isBLCCRelationEnabled) {
        this.costCenterService.loadCostCenters(this.businessLineLinked$$.value);
      }
    });
  }

  private loadRequiredFieldSettings() {
    const request = new UserDetailsForIntercomRequest();
    (this.grpcService.invoke$(UserAPI.UserDetailsForIntercom, request) as Observable<
      UserDetailsForIntercomResponse
    >).subscribe((response) => {
      this.isCostCenterRequired$$.next(response.toObject().userDetails.isCostCenterRequired);
      this.isPurchaseOrderRequired$$.next(response.toObject().userDetails.isPurchaseOrderRequired);
      this.isSalesOrderRequired$$.next(response.toObject().userDetails.isSalesOrderRequired);
    });
  }
}
