// package: cbv1
// file: proto/cybertron/googledialog/dialog.proto

var proto_cybertron_googledialog_dialog_pb = require("../../../proto/cybertron/googledialog/dialog_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var DialogAPI = (function () {
  function DialogAPI() {}
  DialogAPI.serviceName = "cbv1.DialogAPI";
  return DialogAPI;
}());

DialogAPI.clientMessage = {
  methodName: "clientMessage",
  service: DialogAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_googledialog_dialog_pb.DialogStreamerRequest,
  responseType: proto_cybertron_googledialog_dialog_pb.RequestAccepted
};

DialogAPI.clientMessageNoStreaming = {
  methodName: "clientMessageNoStreaming",
  service: DialogAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_googledialog_dialog_pb.DialogStreamerRequest,
  responseType: proto_cybertron_googledialog_dialog_pb.DialogStreamerResponse
};

DialogAPI.listen = {
  methodName: "listen",
  service: DialogAPI,
  requestStream: false,
  responseStream: true,
  requestType: proto_cybertron_googledialog_dialog_pb.DialogStreamerRequest,
  responseType: proto_cybertron_googledialog_dialog_pb.DialogStreamerResponse
};

exports.DialogAPI = DialogAPI;

function DialogAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

DialogAPIClient.prototype.clientMessage = function clientMessage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DialogAPI.clientMessage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DialogAPIClient.prototype.clientMessageNoStreaming = function clientMessageNoStreaming(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DialogAPI.clientMessageNoStreaming, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DialogAPIClient.prototype.listen = function listen(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(DialogAPI.listen, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.DialogAPIClient = DialogAPIClient;

