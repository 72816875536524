import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { AuthService } from './../../services/auth.service';

interface FormValue {
  email: string;
  password: string;
}

@Component({
  selector: 'ct-login-page',
  styleUrls: ['./login-page.component.scss'],
  templateUrl: './login-page.component.html',
})
export class LoginPageComponent implements OnInit {
  // public ssoLoginUrl$: Observable<string>;
  public loginForm: FormGroup;
  public currentJwt$: Observable<string>;
  public activeNetworkRequest$ = new BehaviorSubject<boolean>(false);

  @ViewChild('ssoEmail', { static: true }) private ssoEmailFormModal: TemplateRef<any>;
  private ssoEmailFormDialog: MatDialogRef<any>;
  public ssoEmailForm = new FormControl(null, [Validators.required, Validators.email]);
  public running = false;

  public get formValue(): FormValue {
    return this.loginForm.value;
  }

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    // private configValues: ConfigValuesService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
  ) {
    this.authService.idToken$.pipe(filter((token) => !!token)).subscribe((token) => {
      if (token) {
        this.authService.redirectIfLoggedIn();
      }
    });
    // this.ssoLoginUrl$ = this.configValues.configs$.pipe(map((configs) => configs.sso_login_url));
  }

  public ngOnInit() {
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
    });
  }

  public login() {
    if (this.loginForm.invalid) {
      return;
    }
    const formValue = this.formValue;
    this.authService.login(formValue.email, formValue.password);
  }

  public isDev(): boolean {
    return environment.api === 'https://dev.prime.shaleapps.com';
  }

  public isStage(): boolean {
    return environment.api === 'https://stage.prime.shaleapps.com';
  }

  public isProd(): boolean {
    return environment.api === 'https://prime.shaleapps.com';
  }

  public useDev(): void {
    this.useEnv('https://dev.prime.shaleapps.com');
  }

  public useStage(): void {
    this.useEnv('https://stage.prime.shaleapps.com');
  }

  public useProd(): void {
    this.useEnv('https://prime.shaleapps.com');
  }

  private useEnv(api: string) {
    environment.api = api;
  }

  public openSSOEmail() {
    const email = this.authService.getSSOEmailFromCache();
    this.ssoEmailForm.setValue(email);
    this.ssoEmailFormDialog = this.matDialog.open(this.ssoEmailFormModal, {
      width: '250px;',
    });
  }

  public ssoLogin() {
    this.running = true;
    this.authService.ssoURL$(this.ssoEmailForm.value).subscribe(
      (url) => {
        this.running = false;
        this.cacheSSOEmail();
        window.open(url, '_self');
        this.closeTab();
      },
      (error) => {
        this.running = false;
        this.snackBar.open(error.error, null, {
          duration: 5000,
          panelClass: ['snackbar-error'],
        });
      },
    );
  }

  public cacheSSOEmail() {
    this.authService.storeSSOEmailInCache({
      email: this.ssoEmailForm.value,
    });
  }

  public closeTab() {
    this.ssoEmailFormDialog.close();
    this.ssoEmailFormDialog = null;
  }
}
