/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-prompt.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../button/button.component.ngfactory";
import * as i3 from "../button/button.component";
import * as i4 from "../directives/automated-selector.directive";
import * as i5 from "./notification-prompt.component";
import * as i6 from "@angular/material/dialog";
var styles_NotificationPromptComponent = [i0.styles];
var RenderType_NotificationPromptComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationPromptComponent, data: {} });
export { RenderType_NotificationPromptComponent as RenderType_NotificationPromptComponent };
export function View_NotificationPromptComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "notification-prompt"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "details"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "button", [["ct-dark-button", ""]], [[2, "ct-button", null], [2, "dark-button", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(7, 49152, null, 0, i3.ButtonComponent, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(8, 16384, null, 0, i3.CtDarkButtonDirective, [], null, null), (_l()(), i1.ɵted(9, 0, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "button", [["ct-gray-button", ""], ["ctAutomatedSelector", "notification__no-thanks"]], [[2, "ct-button", null], [2, "button-gray", null], [1, "data-aid", 0], [1, "data-aid-name", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(11, 49152, null, 0, i3.ButtonComponent, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(12, 16384, null, 0, i3.CtGrayButtonDirective, [], null, null), i1.ɵdid(13, 81920, null, 0, i4.AutomatedSelectorDirective, [i1.ChangeDetectorRef], { ctAutomatedSelector: [0, "ctAutomatedSelector"] }, null), (_l()(), i1.ɵted(-1, 0, ["no thanks"]))], function (_ck, _v) { var currVal_9 = "notification__no-thanks"; _ck(_v, 13, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerText; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.detailsText; _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 8).ctButton; var currVal_3 = i1.ɵnov(_v, 8).secondary; _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = _co.actionText; _ck(_v, 9, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 12).ctButton; var currVal_6 = i1.ɵnov(_v, 12).secondary; var currVal_7 = i1.ɵnov(_v, 13).automatedTag; var currVal_8 = i1.ɵnov(_v, 13).automatedDataName; _ck(_v, 10, 0, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_NotificationPromptComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ct-notification-prompt", [], null, null, null, View_NotificationPromptComponent_0, RenderType_NotificationPromptComponent)), i1.ɵdid(1, 114688, null, 0, i5.NotificationPromptComponent, [[2, i6.MatDialogRef]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationPromptComponentNgFactory = i1.ɵccf("ct-notification-prompt", i5.NotificationPromptComponent, View_NotificationPromptComponent_Host_0, { headerText: "headerText", detailsText: "detailsText", actionText: "actionText" }, { selected: "selected" }, []);
export { NotificationPromptComponentNgFactory as NotificationPromptComponentNgFactory };
