import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var LmoSiteAlarmsComponent = /** @class */ (function () {
    function LmoSiteAlarmsComponent(alarmService) {
        this.alarmService = alarmService;
    }
    LmoSiteAlarmsComponent.prototype.ngOnInit = function () {
        this.alarms$ = this.alarmService.alarms$;
    };
    LmoSiteAlarmsComponent.prototype.prettyAlarmName = function (alarm) {
        switch (alarm.alarmType) {
            case 'driver_delay':
                return 'Driver Delayed';
            case 'driver_stop':
                return 'Driver Stopped';
            case 'all_vendors_declined':
                return 'All Vendors Declined';
            case 'no_vendors_responded':
                return 'No Vendors Responded';
            case 'up_for_grabs':
                return 'Up For Grabs';
            case 'order_created':
                return 'Order Created';
            case 'order_cancelled':
                return 'Order Cancelled';
            case 'close_out_load':
                return 'Close Out Load';
            case 'vendor_declined':
                return 'Vendor Declined';
            case 'dispatch_delayed':
                return 'Dispatch Delayed';
            default:
                return alarm.alarmType;
        }
    };
    LmoSiteAlarmsComponent.prototype.toRouterLink = function (url) {
        if (!url.includes('hauli.shaleapps.com') && !url.startsWith('/')) {
            return null;
        }
        if (url.startsWith('/')) {
            return tslib_1.__spread(['/'], url.split('/')).filter(function (path) { return path.length > 0; });
        }
        var hauliUrl = 'hauli.shaleapps.com';
        var start = url.indexOf(hauliUrl) + hauliUrl.length;
        return tslib_1.__spread(['/'], url.substring(start).split('/')).filter(function (path) { return path.length > 0; });
    };
    LmoSiteAlarmsComponent.prototype.alarmImageName = function (alarm) {
        switch (alarm.alarmType) {
            case 'driver_delay':
                return 'delay';
            case 'driver_stop':
                return 'generic';
            case 'all_vendors_declined':
                return 'generic';
            case 'no_vendors_responded':
                return 'generic';
            case 'up_for_grabs':
                return 'delay';
            case 'order_created':
                return 'generic';
            case 'order_cancelled':
                return 'generic';
            case 'close_out_load':
                return 'generic';
            case 'vendor_declined':
                return 'generic';
            default:
                return 'generic';
        }
    };
    LmoSiteAlarmsComponent.prototype.trackByCompoundKey = function (alarm) {
        return alarm.compoundKey;
    };
    return LmoSiteAlarmsComponent;
}());
export { LmoSiteAlarmsComponent };
