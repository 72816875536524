import { Circle, Point } from 'src/proto/geo/geo_pb';

export function createCircle(lat: number, lon: number, radius: number): Circle {
  const circle = new Circle();
  const point = new Point();
  point.setLat(lat);
  point.setLon(lon);
  circle.setRadius(radius * 1609);
  circle.setCenter(point);
  return circle;
}
