import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { interval, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import('moment-precise-range-plugin');

const countdownObservables: Record<string, Observable<string>> = {};

@Pipe({
  name: 'countdown',
})
export class CountdownPipe implements PipeTransform {
  // tslint:disable-next-line
  public transform(dateAsString: string | number): Observable<string> {
    if (!countdownObservables[dateAsString]) {
      countdownObservables[dateAsString] = getCountDown$(dateAsString);
    }
    return countdownObservables[dateAsString];
  }
}

interface PreciseDiff {
  days: number;
  firstDateWasLater: boolean;
  hours: number;
  minutes: number;
  months: number;
  seconds: number;
  years: number;
}
const dateOrder: (keyof PreciseDiff)[] = ['years', 'months', 'days', 'hours', 'minutes', 'seconds'];
const substitutes: Partial<Record<keyof PreciseDiff, string>> = { months: 'mo' };

function getCountDown$(dateAsString: string | number): Observable<string> {
  const asMoment = moment(dateAsString);
  if (asMoment.isValid) {
    return interval(200).pipe(
      map(() => {
        const preciseDiff: PreciseDiff = (moment as any).preciseDiff(moment(), asMoment, true);
        if (preciseDiff.firstDateWasLater) {
          return '-';
        }
        const result = [];
        let dateOrderIndex = 0;
        while (dateOrderIndex < dateOrder.length && result.length < 2) {
          const key = dateOrder[dateOrderIndex];
          if (preciseDiff[key] > 0 || result.length === 1) {
            const keyName = substitutes[key] ? substitutes[key] : key.substring(0, 1).toLocaleLowerCase();
            result.push(`${preciseDiff[key]}${keyName}`);
          }
          dateOrderIndex++;
        }
        if (result.length) {
          return `${result.join(' ')}`;
        }
        return '-';
      }),
    );
  }
  return of('-');
}
