import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Params, Router } from '@angular/router';
import { equals } from 'remeda';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

const maximumEntriesToStoreInHistory = 25;

export interface RouterState {
  params: Params;
  queryParams: Params;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class RouterStateService {
  private backUrl$$ = new BehaviorSubject<string[]>(null);
  private routerState$$ = new BehaviorSubject<RouterState>(null);
  private history$$ = new BehaviorSubject<string[][]>([]);

  public get routerState$() {
    return this.routerState$$.asObservable().pipe(filter((params) => !!params));
  }

  public get backUrl$(): Observable<string[]> {
    return this.backUrl$$.asObservable();
  }

  public get history$(): Observable<string[][]> {
    return this.history$$.asObservable();
  }

  public listenForParamChange$(paramName: string): Observable<string> {
    return this.routerState$.pipe(
      map((state) => state.params[paramName]),
      distinctUntilChanged(),
    );
  }

  public listenForQueryChange$(queryParamName: string): Observable<string> {
    return this.routerState$.pipe(
      map((state) => state.queryParams[queryParamName]),
      distinctUntilChanged(),
    );
  }

  constructor(private router: Router) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const snapshot = this.router.routerState.snapshot;
      let { url } = snapshot;
      url = url.split('?')[0];
      const { queryParams } = snapshot.root;
      if (this.routerState$$.value) {
        const withoutParams = this.routerState$$.value.url.split('?')[0];
        if (!equals(url, withoutParams)) {
          const oldUrl = ['/', ...withoutParams.split('/').filter((segment) => segment !== '')];
          this.storeHistory(oldUrl);
        }
      }

      let state: ActivatedRouteSnapshot = snapshot.root;
      let params = {};
      while (state.firstChild) {
        params = { ...params, ...state.params };
        state = state.firstChild;
      }
      params = { ...params, ...state.params };
      const routerState = {
        params,
        queryParams,
        url,
      };
      this.routerState$$.next(routerState);
    });
  }

  public backUrlOr$(alternateRoute: string[]): Observable<string[]> {
    return this.backUrl$.pipe(
      map((route) => {
        if (route && route.length) {
          return route;
        }
        return alternateRoute;
      }),
    );
  }

  private storeHistory(url: string[]) {
    this.backUrl$$.next(url);
    const value = this.history$$.value;
    if (!value) {
      this.history$$.next([url]);
    } else if (value.length < maximumEntriesToStoreInHistory) {
      this.history$$.next([url, ...value]);
    } else {
      this.history$$.next([url, ...value.slice(0, maximumEntriesToStoreInHistory - 1)]);
    }
  }
}
