import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-versioning',
  styleUrls: ['./versioning.component.scss'],
  templateUrl: './versioning.component.html',
})
export class VersioningComponent implements OnInit {
  @HostBinding('class') public classes = 'version-warning';
  @HostBinding('class.wrong-version') public isWrongVersion = false;

  constructor(private swUpdate: SwUpdate, private cd: ChangeDetectorRef) {}

  public ngOnInit() {
    this.swUpdate.available.pipe().subscribe(() => {
      this.isWrongVersion = true;
      this.cd.markForCheck();
    });
  }
}
