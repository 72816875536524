import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../auth.service";
import * as i2 from "@angular/router";
import * as i3 from "../role.service";
var IsAccountTypeGuard = /** @class */ (function () {
    function IsAccountTypeGuard(authService, router, roleService) {
        this.authService = authService;
        this.router = router;
        this.roleService = roleService;
    }
    IsAccountTypeGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        if (!next.data || !next.data.accountTypes) {
            console.error('Route data did not include accountTypes');
            return false;
        }
        return this.authService.isAccountType$(next.data.accountTypes).pipe(tap(function (isAccountType) {
            if (!isAccountType) {
                console.error('Not account type:', next.data.accountTypes);
                setTimeout(function () { return _this.router.navigate(['/', 'login']); }, 100);
            }
            _this.checkFirstTimeLogin();
        }));
    };
    IsAccountTypeGuard.prototype.checkFirstTimeLogin = function () {
        var _this = this;
        this.roleService.isFirstTimeUser$.subscribe(function (isFirstTime) {
            if (isFirstTime) {
                _this.router.navigate(['/', 'update-password']).then();
            }
        });
    };
    IsAccountTypeGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IsAccountTypeGuard_Factory() { return new IsAccountTypeGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.RoleService)); }, token: IsAccountTypeGuard, providedIn: "root" });
    return IsAccountTypeGuard;
}());
export { IsAccountTypeGuard };
