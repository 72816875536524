import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-order-confirm-complete',
  styleUrls: ['./order-confirm-complete.component.scss'],
  templateUrl: './order-confirm-complete.component.html',
})
export class OrderConfirmCompleteComponent implements OnInit {
  @Output() public completeConfirmed = new EventEmitter();

  constructor(private matDialogRef: MatDialogRef<OrderConfirmCompleteComponent>) {}

  public ngOnInit() {}

  public cancel() {
    this.completeConfirmed.emit(false);
    this.matDialogRef.close(false);
  }

  public complete() {
    this.completeConfirmed.emit(true);
    this.matDialogRef.close(true);
  }
}
