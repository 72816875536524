import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OrdersService } from 'src/app/lmo/services/orders.service';
import { PayloadDetails } from 'src/app/models/type-with-order.model';
import { Order, Task } from '~proto/order/order_pb';
import { TaskType } from '~proto/types/types_pb';
import { OrderStatus } from '~proto/types/types_pb';
import { getPayloadDetailsFromOrder } from '~utilities/groupOrders';
import { trackById } from '~utilities/trackById';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-order-detail-completed',
  styleUrls: ['./order-detail-completed.component.scss'],
  templateUrl: './order-detail-completed.component.html',
})
export class OrderDetailCompletedComponent implements OnInit {
  public currentOrder$: Observable<Order.AsObject>;
  public payloadDetails$: Observable<PayloadDetails[]>;
  public lastTask: Task.AsObject;
  public taskType = TaskType;
  public orderStatus = OrderStatus;
  public trackById = trackById;

  constructor(private orderService: OrdersService) {}

  public ngOnInit() {
    this.currentOrder$ = this.orderService.currentOrder$.pipe(filter((currentOrder) => !!currentOrder));
    this.payloadDetails$ = this.currentOrder$.pipe(map(getPayloadDetailsFromOrder));
    this.currentOrder$.subscribe((order) => {
      this.lastTask = order.tasksList[order.tasksList.length - 1];
      if (this.lastTask.type === TaskType.TASK_TYPE_RETURN_MATERIALS) {
        this.lastTask = order.tasksList[0];
      }
    });
  }
}
