import { ListPayloadTypesRequest, ListUnitsRequest, } from '~proto/payload/payload_api_pb';
import { PayloadAPI } from '~proto/payload/payload_api_pb_service';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import * as i0 from "@angular/core";
import * as i1 from "./grpc.service";
var PAYLOAD_TYPE_KEY = 'payloadTypes_CONSTANTS';
var UNITS_KEY = 'units_CONSTANTS';
var ConstantsService = /** @class */ (function () {
    function ConstantsService(grpcService) {
        this.grpcService = grpcService;
        var storagePayloadTypes = localStorage.getItem(PAYLOAD_TYPE_KEY);
        if (storagePayloadTypes) {
            this._payloadTypes = JSON.parse(storagePayloadTypes);
        }
        var storageUnits = localStorage.getItem(UNITS_KEY);
        if (storageUnits) {
            this._units = JSON.parse(storageUnits);
        }
        this.loadPayloadTypes();
        this.loadUnits();
    }
    Object.defineProperty(ConstantsService.prototype, "payloadTypes", {
        get: function () {
            return this._payloadTypes;
        },
        set: function (types) {
            localStorage.setItem(PAYLOAD_TYPE_KEY, JSON.stringify(types));
            this._payloadTypes = types;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConstantsService.prototype, "units", {
        get: function () {
            return this._units;
        },
        set: function (types) {
            localStorage.setItem(UNITS_KEY, JSON.stringify(types));
            this._units = types;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConstantsService.prototype, "unitsArray", {
        get: function () {
            return Object.values(this._units);
        },
        enumerable: true,
        configurable: true
    });
    ConstantsService.prototype.loadPayloadTypes = function () {
        var _this = this;
        var request = new ListPayloadTypesRequest();
        this.grpcService.invoke$(PayloadAPI.ListPayloadTypes, request).subscribe(function (response) {
            _this.payloadTypes = idArrayToRecord(response.toObject().payloadTypesList);
        });
    };
    ConstantsService.prototype.loadUnits = function () {
        var _this = this;
        var request = new ListUnitsRequest();
        this.grpcService.invoke$(PayloadAPI.ListUnits, request).subscribe(function (response) {
            _this.units = idArrayToRecord(response.toObject().unitsList);
        });
    };
    ConstantsService.prototype.getAbbreviationFromUnit = function (unit) {
        if (this.unitsArray.length) {
            var tempUnit = this.unitsArray.find(function (selectedUnit) { return selectedUnit.name === unit; });
            if (tempUnit) {
                return tempUnit.abbreviation;
            }
        }
        return 'unit';
    };
    ConstantsService.prototype.sortDataValues = function (dataValues) {
        return dataValues.sort(function (valA, valB) {
            valA = valA.name.toLowerCase();
            valB = valB.name.toLowerCase();
            return valA > valB ? 1 : valA < valB ? -1 : 0;
        });
    };
    ConstantsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConstantsService_Factory() { return new ConstantsService(i0.ɵɵinject(i1.GrpcService)); }, token: ConstantsService, providedIn: "root" });
    return ConstantsService;
}());
export { ConstantsService };
