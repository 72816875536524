import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { CalendarDayViewComponent, CalendarUtils } from 'angular-calendar';
var EVENT_WIDTH = 200;
var DayViewSchedulerCalendarUtils = /** @class */ (function (_super) {
    tslib_1.__extends(DayViewSchedulerCalendarUtils, _super);
    function DayViewSchedulerCalendarUtils() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DayViewSchedulerCalendarUtils.prototype.getDayView = function (args) {
        var view = tslib_1.__assign({}, _super.prototype.getDayView.call(this, args), { drivers: [] });
        view.events.forEach(function (_a) {
            var event = _a.event;
            if (!view.drivers.includes(event.meta.driverId)) {
                view.drivers.push(event.meta.driverId);
            }
        });
        // sort the users by their names
        view.drivers.sort(function (driver1, driver2) { return driver1 - driver2; });
        view.events = view.events.map(function (dayViewEvent) {
            var index = view.drivers.indexOf(dayViewEvent.event.meta.driverId);
            dayViewEvent.left = index * EVENT_WIDTH; // change the column of the event
            dayViewEvent.width = EVENT_WIDTH;
            return dayViewEvent;
        });
        view.width = view.drivers.length * EVENT_WIDTH;
        return view;
    };
    return DayViewSchedulerCalendarUtils;
}(CalendarUtils));
export { DayViewSchedulerCalendarUtils };
var LmoOrdersCalendarDayViewCalendarComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LmoOrdersCalendarDayViewCalendarComponent, _super);
    function LmoOrdersCalendarDayViewCalendarComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return LmoOrdersCalendarDayViewCalendarComponent;
}(CalendarDayViewComponent));
export { LmoOrdersCalendarDayViewCalendarComponent };
