import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('500ms ease-in', style({ transform: 'translateY(0%)' })),
      ]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  public displayNav$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() public showScrollHeader = false;
  @Input() public noScrollHeader = false;
  @Input() public noSubheader = false;
  @Input() public showSearch = false;
  @Input() public titleString: string;

  constructor() {}

  public ngOnInit() {}

  @HostListener('window:scroll', [])
  public onWindowScroll() {
    if (!this.noScrollHeader) {
      if (window.pageYOffset >= 68) {
        this.showScrollHeader = true;
      } else if (window.pageYOffset < 68) {
        this.showScrollHeader = false;
      }
    }
  }

  public toggleNav() {
    this.displayNav$.next(!this.displayNav$.value);
  }
}
