import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { SelectBusinessHoursComponent } from '~common/select-business-hours/select-business-hours.component';
import { LmoDistrictService } from '~lmo/services/lmo-district.service';
import { DriverCertification } from '~proto/drivercertification/driverCertification_pb';
import { CreateSiteRequest } from '~proto/site/site_api_pb';
import { District, Site, SiteAvailableDuration } from '~proto/site/site_pb';
import { createCircle } from '~utilities/createCircle';
import { trackById } from '~utilities/trackById';
import { DriverCertificationService } from '../../../dispatcher/services/driver-certification.service';

interface FormValue {
  address: string;
  addToPinnedJobs: boolean;
  businessHours: string;
  name: string;
  type: string;
  latitude: number;
  longitude: number;
  radius: number;
  direction: string;
  districtId: number | null;
  driverCertificationIds: number[];
}

// const metersInAYard = 0.914;
const metersInAMile = 1609;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-create-job',
  styleUrls: ['./create-job.component.scss'],
  templateUrl: './create-job.component.html',
})
export class CreateJobComponent implements OnInit, AfterViewInit {
  @ViewChild('map', { static: false }) private mapElement: ElementRef;
  public networkActive$$ = new BehaviorSubject<boolean>(false);
  public driverCertifications$: Observable<DriverCertification.AsObject[]>;
  public lmoDistricts$: Observable<District.AsObject[]>;
  public trackById = trackById;
  public map: google.maps.Map;
  public createForm: FormGroup;
  public geofence: google.maps.Circle;
  public mineMarker: google.maps.Marker;
  public businessHours: SiteAvailableDuration[] = [];
  public modalInstance = false;
  public jobName: string;
  public weekMap = {
    0: 'MONDAY',
    1: 'TUESDAY',
    2: 'WEDNESDAY',
    3: 'THURSDAY',
    4: 'FRIDAY',
    5: 'SATURDAY',
    6: 'SUNDAY',
  };

  public get radius(): FormControl {
    return this.createForm.get('radius') as FormControl;
  }

  public get latitude(): FormControl {
    return this.createForm.get('latitude') as FormControl;
  }

  public get longitude(): FormControl {
    return this.createForm.get('longitude') as FormControl;
  }

  public get formValue(): FormValue {
    return this.createForm.value;
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private jobSitesService: JobSitesService,
    private driverCertificationService: DriverCertificationService,
    private lmoDistrictService: LmoDistrictService,
    private dialog: MatDialog,
    @Optional() private matDialogRef: MatDialogRef<CreateJobComponent>,
    private matSnackbar: MatSnackBar,
  ) {}

  public ngOnInit() {
    this.driverCertifications$ = this.driverCertificationService.driverCertifications$;
    this.lmoDistricts$ = this.lmoDistrictService.lmoDistricts$;

    this.createForm = this.fb.group({
      addToPinnedJobs: [false, []],
      address: ['', []],
      businessHours: ['', []],
      direction: ['', []],
      districtId: [null, []],
      driverCertificationIds: [[]],
      latitude: [39.7392, [Validators.required]],
      longitude: [-104.9903, [Validators.required]],
      name: [this.jobName, [Validators.required]],
      radius: [1, [Validators.required, Validators.min(0.02)]],
      type: ['well', [Validators.required]],
    });

    this.radius.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe(this.handleRadiusInputChanges.bind(this));
  }

  public ngAfterViewInit(): void {
    this.initMap();
  }

  public editBusinessHours() {
    const dialogRef = this.dialog.open(SelectBusinessHoursComponent, {
      data: this.businessHours,
    });
    dialogRef
      .afterClosed()
      .pipe(filter((value) => !!value && value.length))
      .subscribe((value: SiteAvailableDuration[]) => {
        this.businessHours = [...value];
        let hoursValue = '';
        value.forEach((day) => {
          hoursValue += `${hoursValue !== '' ? ', ' : ''}${
            this.weekMap[day.getDay() - 1]
          }(${day.getOpeningTime()} - ${day.getClosingTime()})`;
        });
        this.createForm.controls['businessHours'].setValue(hoursValue);
      });
  }

  private initMap() {
    if (this.map == null) {
      const center = {
        lat: this.latitude.value,
        lng: this.longitude.value,
      };
      const mapConfig = {
        center: center,
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: 3,
        },
        mapTypeId: google.maps.MapTypeId.HYBRID,
        streetViewControl: false,
        // styles: <any>LightTheme,
        zoom: 12,
        zoomControlOptions: {
          position: 3,
        },
      };

      if (this.mapElement != null) {
        this.map = new google.maps.Map(this.mapElement.nativeElement, mapConfig);
        this.mineMarker = new google.maps.Marker({
          animation: google.maps.Animation.DROP,
          draggable: true,
          map: this.map,
          position: this.map.getCenter(),
        });
        this.geofence = new google.maps.Circle({
          center: this.map.getCenter(),
          editable: true,
          fillColor: '#00FF00',
          fillOpacity: 0.35,
          map: this.map,
          radius: this.radius.value * metersInAMile,
          strokeColor: '#00FF00',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          zIndex: 10,
        });
        this.geofence.bindTo('center', this.mineMarker, 'position');
        google.maps.event.addListener(this.mineMarker, 'position_changed', this.handleMineMarkerMove.bind(this));
        google.maps.event.addListener(this.geofence, 'radius_changed', this.handleGeofenceRadiusChange.bind(this));
      }
    }
  }

  public createJob() {
    this.createForm.markAllAsTouched();
    if (this.createForm.invalid) {
      return;
    }
    const formValue = this.formValue;
    if (formValue.name.replace(/\s/g, "") === "") {
      this.matSnackbar.open('Site name cannot be empty', null, { duration: 2000 });
      return;
    }
    this.networkActive$$.next(true);
    const createSiteRequest = new CreateSiteRequest();
    const circle = createCircle(formValue.latitude, formValue.longitude, formValue.radius);
    createSiteRequest.setGeofence(circle);
    createSiteRequest.setName(formValue.name);
    createSiteRequest.setDirections('');
    createSiteRequest.setSiteType(formValue.type);
    createSiteRequest.setDirections(formValue.direction);
    createSiteRequest.setFavorited(formValue.addToPinnedJobs);
    createSiteRequest.setAddress(formValue.address);
    createSiteRequest.setDriverCertificationIdsList(formValue.driverCertificationIds);
    if (formValue.districtId) {
      createSiteRequest.setDistrictId(formValue.districtId);
    }
    if (this.createForm.get('type').value !== 'well') {
      createSiteRequest.setAvailabledurationsList(this.businessHours);
    }
    this.jobSitesService
      .createSite$(createSiteRequest)
      .pipe(
        finalize(() => {
          this.networkActive$$.next(false);
        }),
      )
      .subscribe((site: Site.AsObject) => {
        if (this.modalInstance) {
          this.matDialogRef.close(site);
        } else {
          this.router.navigate(['/', 'lmo', 'jobs', site.id]);
        }
      });
  }

  public centerMarkerOnMap() {
    const currentMapCenter = this.map.getCenter();
    this.mineMarker.setPosition({
      lat: currentMapCenter.lat(),
      lng: currentMapCenter.lng(),
    });
  }

  public centerMapOnMarker() {
    const currentMarkerPosition = this.mineMarker.getPosition();
    this.map.setCenter(currentMarkerPosition);
  }

  public handleLatitudeInputChange() {
    const latitude = this.formValue.latitude;
    const currentPosition = this.mineMarker.getPosition();
    this.mineMarker.setPosition({ lat: +latitude, lng: currentPosition.lng() });
    this.centerMapOnMarker();
  }

  public handleLongitudeInputChange() {
    const longitude = this.formValue.longitude;
    const currentPosition = this.mineMarker.getPosition();
    this.mineMarker.setPosition({ lng: +longitude, lat: currentPosition.lat() });
    this.centerMapOnMarker();
  }

  private handleMineMarkerMove() {
    const newPosition = this.mineMarker.getPosition();
    this.latitude.setValue(newPosition.lat(), { emitEvent: false });
    this.longitude.setValue(newPosition.lng(), { emitEvent: false });
  }

  private handleGeofenceRadiusChange() {
    const newRadius = this.geofence.getRadius() / metersInAMile;
    if (newRadius <= 10) {
      this.radius.setValue(parseFloat(newRadius.toFixed(2)), { emitEvent: false });
    } else {
      this.geofence.setRadius(10 * metersInAMile);
    }
  }

  private handleRadiusInputChanges(radius: string) {
    this.geofence.setRadius(+radius * metersInAMile);
  }
}
