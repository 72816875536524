import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Stock } from '~proto/site/site_pb';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-stock-amount-percentage',
  styleUrls: ['./stock-amount-percentage.component.scss'],
  templateUrl: './stock-amount-percentage.component.html',
})
export class StockAmountPercentageComponent implements OnInit {
  public decimalPercent: number;
  @Input() public set stock(stock: Stock.AsObject) {
    if (!stock || !stock.maxQuantity || !stock.quantity) {
      this.decimalPercent = 0;
    } else {
      this.decimalPercent = stock.quantity / stock.maxQuantity;
    }
  }

  constructor() {}

  public ngOnInit() {}

  public getFillHeight(): string {
    return `${Math.min(this.decimalPercent * 100, 100)}%`;
  }
}
