import { sort } from 'remeda';
import { Payload } from '~proto/payload/payload_pb';

interface HasSequence {
  sequence: number;
}

interface HasPayload extends HasSequence {
  payload?: Payload.AsObject;
  payloadTypeId?: number;
}

export function getFirstTask<T extends HasSequence>(tasks: T[]): T {
  const sortedTasks = getSortedTasks(tasks);
  if (!sortedTasks.length) {
    return null;
  }
  return sortedTasks[0];
}

export function getLastTask<T extends HasSequence>(tasks: T[]): T {
  const sortedTasks = getSortedTasks(tasks);
  if (!sortedTasks.length) {
    return null;
  }
  return sortedTasks[sortedTasks.length - 1];
}

export function getFirstAndLastTask<T extends HasSequence>(tasks: T[]): [T, T] {
  const sortedTasks = getSortedTasks(tasks);
  if (!sortedTasks.length) {
    return [null, null];
  }
  return [sortedTasks[0], sortedTasks[sortedTasks.length - 1]];
}

export function getFirstTaskWithPayload<T extends HasPayload>(tasks: T[]): T {
  const sortedTasks = getSortedTasks(tasks);
  const onlyPayloadTasks = sortedTasks.filter((t) => t.payload || (t.payloadTypeId && t.payloadTypeId > 0));
  if (!onlyPayloadTasks.length) {
    return null;
  }
  return onlyPayloadTasks[0];
}

function getSortedTasks<T extends HasSequence>(tasks: T[]): T[] {
  return sort(tasks, bySequence) as T[];
}

function bySequence(a: HasSequence, b: HasSequence): number {
  return a.sequence - b.sequence;
}
