<ct-header #header [noSubheader]="true">
  <ng-container title class="title">
    <span>Trailer Management</span>
  </ng-container>
  <ng-container mainMenuButton class="menu-items">
    <img class="menu-button" src="assets/icons/menu-24.svg" alt="menu" (click)="header.toggleNav()" />
  </ng-container>
  <ng-container headerActionsButtons *ctAllowedAccountRoles="[1, 2]">
    <a [routerLink]="['./', 'add']">
      <img src="assets/icons/truck.svg" alt="create new trailer" />
      <span class="hide-handheld">Add Trailer</span>
    </a>
  </ng-container>
</ct-header>
<div class="trailer-management">
  <div class="settings-container">
    <div class="heading">
      Trailers
    </div>
    <div class="search-trailers">
      <ct-input label="Search trailers">
        <input ctInput autocomplete="off" [formControl]="trailerSearch" />
      </ct-input>
    </div>

    <ng-container *ngIf="!(networkActive$ | async); else spinner">
      <ng-container *ngIf="trailers$ | async as trailers">
        <ng-container *ngIf="trailers.data.length; else noTrailers">
          <div class="table-wrap">
            <table mat-table [dataSource]="trailers" class="mat-elevation-z8">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
              </ng-container>

              <ng-container matColumnDef="model">
                <th mat-header-cell *matHeaderCellDef>Model</th>
                <td mat-cell *matCellDef="let element">{{ element.model?.name || '--' }}</td>
              </ng-container>

              <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef>Current Location</th>
                <td mat-cell *matCellDef="let element">{{ element.currentLocation }}</td>
              </ng-container>

              <ng-container matColumnDef="lastUsed">
                <th mat-header-cell *matHeaderCellDef>Last Used</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.lastUsedUnix ? (element.lastUsedUnix * 1000 | date: 'short') : '--' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="service">
                <th mat-header-cell *matHeaderCellDef>In Service</th>
                <td mat-cell *matCellDef="let element">{{ element.inService ? 'Yes' : 'No' }}</td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" (click)="deleteTrailer(element, $event)">
                  <ng-container *ctAllowedAccountRoles="[1, 2]">
                    <i class="material-icons">delete</i>
                  </ng-container>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="openTrailer(row)"></tr>
            </table>
          </div>
        </ng-container>
        <ng-template #noTrailers>
          <h3 class="placeholder-text">No Trailers Found.</h3>
        </ng-template>
      </ng-container>
    </ng-container>
    <ng-template #spinner>
      <mat-spinner [diameter]="75"></mat-spinner>
    </ng-template>
  </div>
</div>
