/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_cybertron_contracts_contracts_pb = require('../../../proto/cybertron/contracts/contracts_pb.js');
var proto_cybertron_types_types_pb = require('../../../proto/cybertron/types/types_pb.js');
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.exportSymbol('proto.cbv1.ArchiveFuelSurchargeContractRequest', null, global);
goog.exportSymbol('proto.cbv1.ArchiveFuelSurchargeContractResponse', null, global);
goog.exportSymbol('proto.cbv1.CreateContractTypeRequest', null, global);
goog.exportSymbol('proto.cbv1.CreateContractTypeResponse', null, global);
goog.exportSymbol('proto.cbv1.CreateFuelSurchargeContractsRequest', null, global);
goog.exportSymbol('proto.cbv1.CreateFuelSurchargeContractsResponse', null, global);
goog.exportSymbol('proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest', null, global);
goog.exportSymbol('proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse', null, global);
goog.exportSymbol('proto.cbv1.CreateMileageCostsRequest', null, global);
goog.exportSymbol('proto.cbv1.CreateVendorContractRequest', null, global);
goog.exportSymbol('proto.cbv1.CreateVendorContractResponse', null, global);
goog.exportSymbol('proto.cbv1.DownloadFuelCostFileRequest', null, global);
goog.exportSymbol('proto.cbv1.DownloadFuelCostFileResponse', null, global);
goog.exportSymbol('proto.cbv1.DownloadP2PFileRequest', null, global);
goog.exportSymbol('proto.cbv1.DownloadP2PFileResponse', null, global);
goog.exportSymbol('proto.cbv1.EditContractTypeRequest', null, global);
goog.exportSymbol('proto.cbv1.EditContractTypeResponse', null, global);
goog.exportSymbol('proto.cbv1.EditVendorContractRequest', null, global);
goog.exportSymbol('proto.cbv1.EditVendorContractResponse', null, global);
goog.exportSymbol('proto.cbv1.GetLMOVendorPayloadTypePercentageRequest', null, global);
goog.exportSymbol('proto.cbv1.GetLMOVendorPayloadTypePercentageResponse', null, global);
goog.exportSymbol('proto.cbv1.GetVendorContractRequest', null, global);
goog.exportSymbol('proto.cbv1.GetVendorContractResponse', null, global);
goog.exportSymbol('proto.cbv1.ListContractTypeRequest', null, global);
goog.exportSymbol('proto.cbv1.ListContractTypeResponse', null, global);
goog.exportSymbol('proto.cbv1.ListFixPointCostsRequest', null, global);
goog.exportSymbol('proto.cbv1.ListFixPointCostsResponse', null, global);
goog.exportSymbol('proto.cbv1.ListFuelSurchargeBracketsRequest', null, global);
goog.exportSymbol('proto.cbv1.ListFuelSurchargeBracketsResponse', null, global);
goog.exportSymbol('proto.cbv1.ListFuelSurchargeContractsRequest', null, global);
goog.exportSymbol('proto.cbv1.ListFuelSurchargeContractsResponse', null, global);
goog.exportSymbol('proto.cbv1.ListVendorContractsRequest', null, global);
goog.exportSymbol('proto.cbv1.ListVendorContractsResponse', null, global);
goog.exportSymbol('proto.cbv1.ToggleArchiveVendorContractRequest', null, global);
goog.exportSymbol('proto.cbv1.ToggleArchiveVendorContractResponse', null, global);
goog.exportSymbol('proto.cbv1.ToggleArchiveVendorContractTypeRequest', null, global);
goog.exportSymbol('proto.cbv1.ToggleArchiveVendorContractTypeResponse', null, global);
goog.exportSymbol('proto.cbv1.UpdateFuelSurchargeContractRequest', null, global);
goog.exportSymbol('proto.cbv1.UpdateFuelSurchargeContractResponse', null, global);
goog.exportSymbol('proto.cbv1.VendorPayloadTypePercentage', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateFuelSurchargeContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.UpdateFuelSurchargeContractRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.UpdateFuelSurchargeContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateFuelSurchargeContractRequest.displayName = 'proto.cbv1.UpdateFuelSurchargeContractRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.UpdateFuelSurchargeContractRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateFuelSurchargeContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateFuelSurchargeContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateFuelSurchargeContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateFuelSurchargeContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vendorContractTypeIdsList: jspb.Message.getRepeatedField(msg, 3)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateFuelSurchargeContractRequest}
 */
proto.cbv1.UpdateFuelSurchargeContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateFuelSurchargeContractRequest;
  return proto.cbv1.UpdateFuelSurchargeContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateFuelSurchargeContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateFuelSurchargeContractRequest}
 */
proto.cbv1.UpdateFuelSurchargeContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setVendorContractTypeIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateFuelSurchargeContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateFuelSurchargeContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateFuelSurchargeContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateFuelSurchargeContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVendorContractTypeIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.UpdateFuelSurchargeContractRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.UpdateFuelSurchargeContractRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.UpdateFuelSurchargeContractRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.UpdateFuelSurchargeContractRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 vendor_contract_type_ids = 3;
 * @return {!Array<number>}
 */
proto.cbv1.UpdateFuelSurchargeContractRequest.prototype.getVendorContractTypeIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/** @param {!Array<number>} value */
proto.cbv1.UpdateFuelSurchargeContractRequest.prototype.setVendorContractTypeIdsList = function(value) {
  jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.UpdateFuelSurchargeContractRequest.prototype.addVendorContractTypeIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


proto.cbv1.UpdateFuelSurchargeContractRequest.prototype.clearVendorContractTypeIdsList = function() {
  this.setVendorContractTypeIdsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateFuelSurchargeContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UpdateFuelSurchargeContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateFuelSurchargeContractResponse.displayName = 'proto.cbv1.UpdateFuelSurchargeContractResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateFuelSurchargeContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateFuelSurchargeContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateFuelSurchargeContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateFuelSurchargeContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contract: (f = msg.getContract()) && proto_cybertron_contracts_contracts_pb.FuelSurchargeContract.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateFuelSurchargeContractResponse}
 */
proto.cbv1.UpdateFuelSurchargeContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateFuelSurchargeContractResponse;
  return proto.cbv1.UpdateFuelSurchargeContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateFuelSurchargeContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateFuelSurchargeContractResponse}
 */
proto.cbv1.UpdateFuelSurchargeContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_contracts_contracts_pb.FuelSurchargeContract;
      reader.readMessage(value,proto_cybertron_contracts_contracts_pb.FuelSurchargeContract.deserializeBinaryFromReader);
      msg.setContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateFuelSurchargeContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateFuelSurchargeContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateFuelSurchargeContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateFuelSurchargeContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContract();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_contracts_contracts_pb.FuelSurchargeContract.serializeBinaryToWriter
    );
  }
};


/**
 * optional FuelSurchargeContract contract = 1;
 * @return {?proto.cbv1.FuelSurchargeContract}
 */
proto.cbv1.UpdateFuelSurchargeContractResponse.prototype.getContract = function() {
  return /** @type{?proto.cbv1.FuelSurchargeContract} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_contracts_contracts_pb.FuelSurchargeContract, 1));
};


/** @param {?proto.cbv1.FuelSurchargeContract|undefined} value */
proto.cbv1.UpdateFuelSurchargeContractResponse.prototype.setContract = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.UpdateFuelSurchargeContractResponse.prototype.clearContract = function() {
  this.setContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.UpdateFuelSurchargeContractResponse.prototype.hasContract = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateFuelSurchargeContractsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateFuelSurchargeContractsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateFuelSurchargeContractsRequest.displayName = 'proto.cbv1.CreateFuelSurchargeContractsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateFuelSurchargeContractsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateFuelSurchargeContractsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateFuelSurchargeContractsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateFuelSurchargeContractsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateFuelSurchargeContractsRequest}
 */
proto.cbv1.CreateFuelSurchargeContractsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateFuelSurchargeContractsRequest;
  return proto.cbv1.CreateFuelSurchargeContractsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateFuelSurchargeContractsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateFuelSurchargeContractsRequest}
 */
proto.cbv1.CreateFuelSurchargeContractsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateFuelSurchargeContractsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateFuelSurchargeContractsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateFuelSurchargeContractsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateFuelSurchargeContractsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.cbv1.CreateFuelSurchargeContractsRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.CreateFuelSurchargeContractsRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fileId = 2;
 * @return {string}
 */
proto.cbv1.CreateFuelSurchargeContractsRequest.prototype.getFileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.CreateFuelSurchargeContractsRequest.prototype.setFileid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateFuelSurchargeContractsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateFuelSurchargeContractsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateFuelSurchargeContractsResponse.displayName = 'proto.cbv1.CreateFuelSurchargeContractsResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateFuelSurchargeContractsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateFuelSurchargeContractsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateFuelSurchargeContractsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateFuelSurchargeContractsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contract: (f = msg.getContract()) && proto_cybertron_contracts_contracts_pb.FuelSurchargeContract.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateFuelSurchargeContractsResponse}
 */
proto.cbv1.CreateFuelSurchargeContractsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateFuelSurchargeContractsResponse;
  return proto.cbv1.CreateFuelSurchargeContractsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateFuelSurchargeContractsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateFuelSurchargeContractsResponse}
 */
proto.cbv1.CreateFuelSurchargeContractsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_contracts_contracts_pb.FuelSurchargeContract;
      reader.readMessage(value,proto_cybertron_contracts_contracts_pb.FuelSurchargeContract.deserializeBinaryFromReader);
      msg.setContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateFuelSurchargeContractsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateFuelSurchargeContractsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateFuelSurchargeContractsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateFuelSurchargeContractsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContract();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_contracts_contracts_pb.FuelSurchargeContract.serializeBinaryToWriter
    );
  }
};


/**
 * optional FuelSurchargeContract contract = 1;
 * @return {?proto.cbv1.FuelSurchargeContract}
 */
proto.cbv1.CreateFuelSurchargeContractsResponse.prototype.getContract = function() {
  return /** @type{?proto.cbv1.FuelSurchargeContract} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_contracts_contracts_pb.FuelSurchargeContract, 1));
};


/** @param {?proto.cbv1.FuelSurchargeContract|undefined} value */
proto.cbv1.CreateFuelSurchargeContractsResponse.prototype.setContract = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.CreateFuelSurchargeContractsResponse.prototype.clearContract = function() {
  this.setContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CreateFuelSurchargeContractsResponse.prototype.hasContract = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListFuelSurchargeContractsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ListFuelSurchargeContractsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListFuelSurchargeContractsRequest.displayName = 'proto.cbv1.ListFuelSurchargeContractsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListFuelSurchargeContractsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListFuelSurchargeContractsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListFuelSurchargeContractsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListFuelSurchargeContractsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListFuelSurchargeContractsRequest}
 */
proto.cbv1.ListFuelSurchargeContractsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListFuelSurchargeContractsRequest;
  return proto.cbv1.ListFuelSurchargeContractsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListFuelSurchargeContractsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListFuelSurchargeContractsRequest}
 */
proto.cbv1.ListFuelSurchargeContractsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListFuelSurchargeContractsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListFuelSurchargeContractsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListFuelSurchargeContractsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListFuelSurchargeContractsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListFuelSurchargeContractsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListFuelSurchargeContractsResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListFuelSurchargeContractsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListFuelSurchargeContractsResponse.displayName = 'proto.cbv1.ListFuelSurchargeContractsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListFuelSurchargeContractsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListFuelSurchargeContractsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListFuelSurchargeContractsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListFuelSurchargeContractsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListFuelSurchargeContractsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractsList: jspb.Message.toObjectList(msg.getContractsList(),
    proto_cybertron_contracts_contracts_pb.FuelSurchargeContract.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListFuelSurchargeContractsResponse}
 */
proto.cbv1.ListFuelSurchargeContractsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListFuelSurchargeContractsResponse;
  return proto.cbv1.ListFuelSurchargeContractsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListFuelSurchargeContractsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListFuelSurchargeContractsResponse}
 */
proto.cbv1.ListFuelSurchargeContractsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_contracts_contracts_pb.FuelSurchargeContract;
      reader.readMessage(value,proto_cybertron_contracts_contracts_pb.FuelSurchargeContract.deserializeBinaryFromReader);
      msg.addContracts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListFuelSurchargeContractsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListFuelSurchargeContractsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListFuelSurchargeContractsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListFuelSurchargeContractsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_contracts_contracts_pb.FuelSurchargeContract.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FuelSurchargeContract contracts = 1;
 * @return {!Array<!proto.cbv1.FuelSurchargeContract>}
 */
proto.cbv1.ListFuelSurchargeContractsResponse.prototype.getContractsList = function() {
  return /** @type{!Array<!proto.cbv1.FuelSurchargeContract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_contracts_contracts_pb.FuelSurchargeContract, 1));
};


/** @param {!Array<!proto.cbv1.FuelSurchargeContract>} value */
proto.cbv1.ListFuelSurchargeContractsResponse.prototype.setContractsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.FuelSurchargeContract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.FuelSurchargeContract}
 */
proto.cbv1.ListFuelSurchargeContractsResponse.prototype.addContracts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.FuelSurchargeContract, opt_index);
};


proto.cbv1.ListFuelSurchargeContractsResponse.prototype.clearContractsList = function() {
  this.setContractsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ArchiveFuelSurchargeContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ArchiveFuelSurchargeContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ArchiveFuelSurchargeContractRequest.displayName = 'proto.cbv1.ArchiveFuelSurchargeContractRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ArchiveFuelSurchargeContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ArchiveFuelSurchargeContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ArchiveFuelSurchargeContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ArchiveFuelSurchargeContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ArchiveFuelSurchargeContractRequest}
 */
proto.cbv1.ArchiveFuelSurchargeContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ArchiveFuelSurchargeContractRequest;
  return proto.cbv1.ArchiveFuelSurchargeContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ArchiveFuelSurchargeContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ArchiveFuelSurchargeContractRequest}
 */
proto.cbv1.ArchiveFuelSurchargeContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContractid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ArchiveFuelSurchargeContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ArchiveFuelSurchargeContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ArchiveFuelSurchargeContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ArchiveFuelSurchargeContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 contractId = 1;
 * @return {number}
 */
proto.cbv1.ArchiveFuelSurchargeContractRequest.prototype.getContractid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.ArchiveFuelSurchargeContractRequest.prototype.setContractid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ArchiveFuelSurchargeContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ArchiveFuelSurchargeContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ArchiveFuelSurchargeContractResponse.displayName = 'proto.cbv1.ArchiveFuelSurchargeContractResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ArchiveFuelSurchargeContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ArchiveFuelSurchargeContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ArchiveFuelSurchargeContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ArchiveFuelSurchargeContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ArchiveFuelSurchargeContractResponse}
 */
proto.cbv1.ArchiveFuelSurchargeContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ArchiveFuelSurchargeContractResponse;
  return proto.cbv1.ArchiveFuelSurchargeContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ArchiveFuelSurchargeContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ArchiveFuelSurchargeContractResponse}
 */
proto.cbv1.ArchiveFuelSurchargeContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ArchiveFuelSurchargeContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ArchiveFuelSurchargeContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ArchiveFuelSurchargeContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ArchiveFuelSurchargeContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.ArchiveFuelSurchargeContractResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.ArchiveFuelSurchargeContractResponse.prototype.setSuccess = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListFuelSurchargeBracketsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ListFuelSurchargeBracketsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListFuelSurchargeBracketsRequest.displayName = 'proto.cbv1.ListFuelSurchargeBracketsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListFuelSurchargeBracketsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListFuelSurchargeBracketsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListFuelSurchargeBracketsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListFuelSurchargeBracketsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fuelmatrixcontractid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListFuelSurchargeBracketsRequest}
 */
proto.cbv1.ListFuelSurchargeBracketsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListFuelSurchargeBracketsRequest;
  return proto.cbv1.ListFuelSurchargeBracketsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListFuelSurchargeBracketsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListFuelSurchargeBracketsRequest}
 */
proto.cbv1.ListFuelSurchargeBracketsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFuelmatrixcontractid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListFuelSurchargeBracketsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListFuelSurchargeBracketsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListFuelSurchargeBracketsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListFuelSurchargeBracketsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFuelmatrixcontractid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 fuelMatrixContractId = 1;
 * @return {number}
 */
proto.cbv1.ListFuelSurchargeBracketsRequest.prototype.getFuelmatrixcontractid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.ListFuelSurchargeBracketsRequest.prototype.setFuelmatrixcontractid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListFuelSurchargeBracketsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListFuelSurchargeBracketsResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListFuelSurchargeBracketsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListFuelSurchargeBracketsResponse.displayName = 'proto.cbv1.ListFuelSurchargeBracketsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListFuelSurchargeBracketsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListFuelSurchargeBracketsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListFuelSurchargeBracketsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListFuelSurchargeBracketsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListFuelSurchargeBracketsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fuelBracketsList: jspb.Message.toObjectList(msg.getFuelBracketsList(),
    proto_cybertron_contracts_contracts_pb.FuelSurchargeBrackets.toObject, includeInstance),
    fuelChargeType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListFuelSurchargeBracketsResponse}
 */
proto.cbv1.ListFuelSurchargeBracketsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListFuelSurchargeBracketsResponse;
  return proto.cbv1.ListFuelSurchargeBracketsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListFuelSurchargeBracketsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListFuelSurchargeBracketsResponse}
 */
proto.cbv1.ListFuelSurchargeBracketsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_contracts_contracts_pb.FuelSurchargeBrackets;
      reader.readMessage(value,proto_cybertron_contracts_contracts_pb.FuelSurchargeBrackets.deserializeBinaryFromReader);
      msg.addFuelBrackets(value);
      break;
    case 2:
      var value = /** @type {!proto.cbv1.FuelChargeType} */ (reader.readEnum());
      msg.setFuelChargeType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListFuelSurchargeBracketsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListFuelSurchargeBracketsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListFuelSurchargeBracketsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListFuelSurchargeBracketsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFuelBracketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_contracts_contracts_pb.FuelSurchargeBrackets.serializeBinaryToWriter
    );
  }
  f = message.getFuelChargeType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated FuelSurchargeBrackets fuel_brackets = 1;
 * @return {!Array<!proto.cbv1.FuelSurchargeBrackets>}
 */
proto.cbv1.ListFuelSurchargeBracketsResponse.prototype.getFuelBracketsList = function() {
  return /** @type{!Array<!proto.cbv1.FuelSurchargeBrackets>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_contracts_contracts_pb.FuelSurchargeBrackets, 1));
};


/** @param {!Array<!proto.cbv1.FuelSurchargeBrackets>} value */
proto.cbv1.ListFuelSurchargeBracketsResponse.prototype.setFuelBracketsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.FuelSurchargeBrackets=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.FuelSurchargeBrackets}
 */
proto.cbv1.ListFuelSurchargeBracketsResponse.prototype.addFuelBrackets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.FuelSurchargeBrackets, opt_index);
};


proto.cbv1.ListFuelSurchargeBracketsResponse.prototype.clearFuelBracketsList = function() {
  this.setFuelBracketsList([]);
};


/**
 * optional FuelChargeType fuel_charge_type = 2;
 * @return {!proto.cbv1.FuelChargeType}
 */
proto.cbv1.ListFuelSurchargeBracketsResponse.prototype.getFuelChargeType = function() {
  return /** @type {!proto.cbv1.FuelChargeType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.cbv1.FuelChargeType} value */
proto.cbv1.ListFuelSurchargeBracketsResponse.prototype.setFuelChargeType = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListFixPointCostsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ListFixPointCostsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListFixPointCostsRequest.displayName = 'proto.cbv1.ListFixPointCostsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListFixPointCostsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListFixPointCostsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListFixPointCostsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListFixPointCostsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    searchField: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orderBy: jspb.Message.getFieldWithDefault(msg, 3, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListFixPointCostsRequest}
 */
proto.cbv1.ListFixPointCostsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListFixPointCostsRequest;
  return proto.cbv1.ListFixPointCostsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListFixPointCostsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListFixPointCostsRequest}
 */
proto.cbv1.ListFixPointCostsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFileId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchField(value);
      break;
    case 3:
      var value = /** @type {!proto.cbv1.PointToPointCostOrderBy} */ (reader.readEnum());
      msg.setOrderBy(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListFixPointCostsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListFixPointCostsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListFixPointCostsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListFixPointCostsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSearchField();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrderBy();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional int64 file_id = 1;
 * @return {number}
 */
proto.cbv1.ListFixPointCostsRequest.prototype.getFileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.ListFixPointCostsRequest.prototype.setFileId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string search_field = 2;
 * @return {string}
 */
proto.cbv1.ListFixPointCostsRequest.prototype.getSearchField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.ListFixPointCostsRequest.prototype.setSearchField = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PointToPointCostOrderBy order_by = 3;
 * @return {!proto.cbv1.PointToPointCostOrderBy}
 */
proto.cbv1.ListFixPointCostsRequest.prototype.getOrderBy = function() {
  return /** @type {!proto.cbv1.PointToPointCostOrderBy} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.cbv1.PointToPointCostOrderBy} value */
proto.cbv1.ListFixPointCostsRequest.prototype.setOrderBy = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 limit = 5;
 * @return {number}
 */
proto.cbv1.ListFixPointCostsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.ListFixPointCostsRequest.prototype.setLimit = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 offset = 6;
 * @return {number}
 */
proto.cbv1.ListFixPointCostsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.ListFixPointCostsRequest.prototype.setOffset = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListFixPointCostsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListFixPointCostsResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListFixPointCostsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListFixPointCostsResponse.displayName = 'proto.cbv1.ListFixPointCostsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListFixPointCostsResponse.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListFixPointCostsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListFixPointCostsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListFixPointCostsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListFixPointCostsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fixedPointCostsList: jspb.Message.toObjectList(msg.getFixedPointCostsList(),
    proto_cybertron_contracts_contracts_pb.FixedPointCost.toObject, includeInstance),
    totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    zipCodesNotMappedList: jspb.Message.getRepeatedField(msg, 3)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListFixPointCostsResponse}
 */
proto.cbv1.ListFixPointCostsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListFixPointCostsResponse;
  return proto.cbv1.ListFixPointCostsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListFixPointCostsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListFixPointCostsResponse}
 */
proto.cbv1.ListFixPointCostsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_contracts_contracts_pb.FixedPointCost;
      reader.readMessage(value,proto_cybertron_contracts_contracts_pb.FixedPointCost.deserializeBinaryFromReader);
      msg.addFixedPointCosts(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addZipCodesNotMapped(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListFixPointCostsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListFixPointCostsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListFixPointCostsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListFixPointCostsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFixedPointCostsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_contracts_contracts_pb.FixedPointCost.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getZipCodesNotMappedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * repeated FixedPointCost fixed_point_costs = 1;
 * @return {!Array<!proto.cbv1.FixedPointCost>}
 */
proto.cbv1.ListFixPointCostsResponse.prototype.getFixedPointCostsList = function() {
  return /** @type{!Array<!proto.cbv1.FixedPointCost>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_contracts_contracts_pb.FixedPointCost, 1));
};


/** @param {!Array<!proto.cbv1.FixedPointCost>} value */
proto.cbv1.ListFixPointCostsResponse.prototype.setFixedPointCostsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.FixedPointCost=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.FixedPointCost}
 */
proto.cbv1.ListFixPointCostsResponse.prototype.addFixedPointCosts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.FixedPointCost, opt_index);
};


proto.cbv1.ListFixPointCostsResponse.prototype.clearFixedPointCostsList = function() {
  this.setFixedPointCostsList([]);
};


/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.cbv1.ListFixPointCostsResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.ListFixPointCostsResponse.prototype.setTotalCount = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string zip_codes_not_mapped = 3;
 * @return {!Array<string>}
 */
proto.cbv1.ListFixPointCostsResponse.prototype.getZipCodesNotMappedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/** @param {!Array<string>} value */
proto.cbv1.ListFixPointCostsResponse.prototype.setZipCodesNotMappedList = function(value) {
  jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.ListFixPointCostsResponse.prototype.addZipCodesNotMapped = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


proto.cbv1.ListFixPointCostsResponse.prototype.clearZipCodesNotMappedList = function() {
  this.setZipCodesNotMappedList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateVendorContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.CreateVendorContractRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.CreateVendorContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateVendorContractRequest.displayName = 'proto.cbv1.CreateVendorContractRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.CreateVendorContractRequest.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateVendorContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateVendorContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateVendorContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateVendorContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vendorId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hourlyDetentionCost: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maxDetentionPerOrder: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pickupFreeTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dropoffFreeTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    deadheadFreeMileage: jspb.Message.getFieldWithDefault(msg, 7, 0),
    deadheadCostPerMile: jspb.Message.getFieldWithDefault(msg, 8, 0),
    travelCostType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    perHourTravelCost: jspb.Message.getFieldWithDefault(msg, 10, 0),
    mileageCostRequestsList: jspb.Message.toObjectList(msg.getMileageCostRequestsList(),
    proto.cbv1.CreateMileageCostsRequest.toObject, includeInstance),
    payloadTypeIdsList: jspb.Message.getRepeatedField(msg, 12),
    referenceNumber: jspb.Message.getFieldWithDefault(msg, 13, ""),
    isDefault: jspb.Message.getFieldWithDefault(msg, 14, false),
    perMileTravelCost: jspb.Message.getFieldWithDefault(msg, 15, 0),
    contractTypeId: jspb.Message.getFieldWithDefault(msg, 16, 0),
    fuelSurcharge: jspb.Message.getFieldWithDefault(msg, 17, false),
    minimumCharge: jspb.Message.getFieldWithDefault(msg, 18, 0),
    fixCostFileId: jspb.Message.getFieldWithDefault(msg, 19, 0),
    isReturnTripBillable: jspb.Message.getFieldWithDefault(msg, 20, false),
    isBackHaulEnabled: jspb.Message.getFieldWithDefault(msg, 21, false),
    backHaulChargePercent: jspb.Message.getFieldWithDefault(msg, 22, 0),
    expirationTime: (f = msg.getExpirationTime()) && proto_cybertron_types_types_pb.NullableTime.toObject(includeInstance, f),
    effectiveTime: (f = msg.getEffectiveTime()) && proto_cybertron_types_types_pb.NullableTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateVendorContractRequest}
 */
proto.cbv1.CreateVendorContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateVendorContractRequest;
  return proto.cbv1.CreateVendorContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateVendorContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateVendorContractRequest}
 */
proto.cbv1.CreateVendorContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVendorId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHourlyDetentionCost(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxDetentionPerOrder(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickupFreeTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDropoffFreeTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeadheadFreeMileage(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeadheadCostPerMile(value);
      break;
    case 9:
      var value = /** @type {!proto.cbv1.VendorContractTravelCostType} */ (reader.readEnum());
      msg.setTravelCostType(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPerHourTravelCost(value);
      break;
    case 11:
      var value = new proto.cbv1.CreateMileageCostsRequest;
      reader.readMessage(value,proto.cbv1.CreateMileageCostsRequest.deserializeBinaryFromReader);
      msg.addMileageCostRequests(value);
      break;
    case 12:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setPayloadTypeIdsList(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceNumber(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDefault(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPerMileTravelCost(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContractTypeId(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFuelSurcharge(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinimumCharge(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFixCostFileId(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReturnTripBillable(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBackHaulEnabled(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBackHaulChargePercent(value);
      break;
    case 23:
      var value = new proto_cybertron_types_types_pb.NullableTime;
      reader.readMessage(value,proto_cybertron_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setExpirationTime(value);
      break;
    case 24:
      var value = new proto_cybertron_types_types_pb.NullableTime;
      reader.readMessage(value,proto_cybertron_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setEffectiveTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateVendorContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateVendorContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateVendorContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateVendorContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVendorId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHourlyDetentionCost();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMaxDetentionPerOrder();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPickupFreeTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getDropoffFreeTime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getDeadheadFreeMileage();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getDeadheadCostPerMile();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getTravelCostType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getPerHourTravelCost();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getMileageCostRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.cbv1.CreateMileageCostsRequest.serializeBinaryToWriter
    );
  }
  f = message.getPayloadTypeIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      12,
      f
    );
  }
  f = message.getReferenceNumber();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getIsDefault();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getPerMileTravelCost();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getContractTypeId();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getFuelSurcharge();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getMinimumCharge();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getFixCostFileId();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getIsReturnTripBillable();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getIsBackHaulEnabled();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getBackHaulChargePercent();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getExpirationTime();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto_cybertron_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getEffectiveTime();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto_cybertron_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 vendor_id = 1;
 * @return {number}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getVendorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.CreateVendorContractRequest.prototype.setVendorId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.CreateVendorContractRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 hourly_detention_cost = 3;
 * @return {number}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getHourlyDetentionCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.CreateVendorContractRequest.prototype.setHourlyDetentionCost = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 max_detention_per_order = 4;
 * @return {number}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getMaxDetentionPerOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.CreateVendorContractRequest.prototype.setMaxDetentionPerOrder = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 pickup_free_time = 5;
 * @return {number}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getPickupFreeTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.CreateVendorContractRequest.prototype.setPickupFreeTime = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 dropoff_free_time = 6;
 * @return {number}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getDropoffFreeTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.CreateVendorContractRequest.prototype.setDropoffFreeTime = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 deadhead_free_mileage = 7;
 * @return {number}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getDeadheadFreeMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.CreateVendorContractRequest.prototype.setDeadheadFreeMileage = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 deadhead_cost_per_mile = 8;
 * @return {number}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getDeadheadCostPerMile = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cbv1.CreateVendorContractRequest.prototype.setDeadheadCostPerMile = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional VendorContractTravelCostType travel_cost_type = 9;
 * @return {!proto.cbv1.VendorContractTravelCostType}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getTravelCostType = function() {
  return /** @type {!proto.cbv1.VendorContractTravelCostType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {!proto.cbv1.VendorContractTravelCostType} value */
proto.cbv1.CreateVendorContractRequest.prototype.setTravelCostType = function(value) {
  jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional int64 per_hour_travel_cost = 10;
 * @return {number}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getPerHourTravelCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cbv1.CreateVendorContractRequest.prototype.setPerHourTravelCost = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated CreateMileageCostsRequest mileage_cost_requests = 11;
 * @return {!Array<!proto.cbv1.CreateMileageCostsRequest>}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getMileageCostRequestsList = function() {
  return /** @type{!Array<!proto.cbv1.CreateMileageCostsRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.CreateMileageCostsRequest, 11));
};


/** @param {!Array<!proto.cbv1.CreateMileageCostsRequest>} value */
proto.cbv1.CreateVendorContractRequest.prototype.setMileageCostRequestsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.cbv1.CreateMileageCostsRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.CreateMileageCostsRequest}
 */
proto.cbv1.CreateVendorContractRequest.prototype.addMileageCostRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.cbv1.CreateMileageCostsRequest, opt_index);
};


proto.cbv1.CreateVendorContractRequest.prototype.clearMileageCostRequestsList = function() {
  this.setMileageCostRequestsList([]);
};


/**
 * repeated int64 payload_type_ids = 12;
 * @return {!Array<number>}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getPayloadTypeIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/** @param {!Array<number>} value */
proto.cbv1.CreateVendorContractRequest.prototype.setPayloadTypeIdsList = function(value) {
  jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.CreateVendorContractRequest.prototype.addPayloadTypeIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


proto.cbv1.CreateVendorContractRequest.prototype.clearPayloadTypeIdsList = function() {
  this.setPayloadTypeIdsList([]);
};


/**
 * optional string reference_number = 13;
 * @return {string}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getReferenceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.cbv1.CreateVendorContractRequest.prototype.setReferenceNumber = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool is_default = 14;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getIsDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 14, false));
};


/** @param {boolean} value */
proto.cbv1.CreateVendorContractRequest.prototype.setIsDefault = function(value) {
  jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional int64 per_mile_travel_cost = 15;
 * @return {number}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getPerMileTravelCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.cbv1.CreateVendorContractRequest.prototype.setPerMileTravelCost = function(value) {
  jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 contract_type_id = 16;
 * @return {number}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getContractTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.cbv1.CreateVendorContractRequest.prototype.setContractTypeId = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional bool fuel_surcharge = 17;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getFuelSurcharge = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 17, false));
};


/** @param {boolean} value */
proto.cbv1.CreateVendorContractRequest.prototype.setFuelSurcharge = function(value) {
  jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional int64 minimum_charge = 18;
 * @return {number}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getMinimumCharge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/** @param {number} value */
proto.cbv1.CreateVendorContractRequest.prototype.setMinimumCharge = function(value) {
  jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 fix_cost_file_id = 19;
 * @return {number}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getFixCostFileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/** @param {number} value */
proto.cbv1.CreateVendorContractRequest.prototype.setFixCostFileId = function(value) {
  jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional bool is_return_trip_billable = 20;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getIsReturnTripBillable = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 20, false));
};


/** @param {boolean} value */
proto.cbv1.CreateVendorContractRequest.prototype.setIsReturnTripBillable = function(value) {
  jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool is_back_haul_enabled = 21;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getIsBackHaulEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 21, false));
};


/** @param {boolean} value */
proto.cbv1.CreateVendorContractRequest.prototype.setIsBackHaulEnabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional int32 back_haul_charge_percent = 22;
 * @return {number}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getBackHaulChargePercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/** @param {number} value */
proto.cbv1.CreateVendorContractRequest.prototype.setBackHaulChargePercent = function(value) {
  jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional NullableTime expiration_time = 23;
 * @return {?proto.cbv1.NullableTime}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getExpirationTime = function() {
  return /** @type{?proto.cbv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_types_types_pb.NullableTime, 23));
};


/** @param {?proto.cbv1.NullableTime|undefined} value */
proto.cbv1.CreateVendorContractRequest.prototype.setExpirationTime = function(value) {
  jspb.Message.setWrapperField(this, 23, value);
};


proto.cbv1.CreateVendorContractRequest.prototype.clearExpirationTime = function() {
  this.setExpirationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CreateVendorContractRequest.prototype.hasExpirationTime = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional NullableTime effective_time = 24;
 * @return {?proto.cbv1.NullableTime}
 */
proto.cbv1.CreateVendorContractRequest.prototype.getEffectiveTime = function() {
  return /** @type{?proto.cbv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_types_types_pb.NullableTime, 24));
};


/** @param {?proto.cbv1.NullableTime|undefined} value */
proto.cbv1.CreateVendorContractRequest.prototype.setEffectiveTime = function(value) {
  jspb.Message.setWrapperField(this, 24, value);
};


proto.cbv1.CreateVendorContractRequest.prototype.clearEffectiveTime = function() {
  this.setEffectiveTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CreateVendorContractRequest.prototype.hasEffectiveTime = function() {
  return jspb.Message.getField(this, 24) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateMileageCostsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateMileageCostsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateMileageCostsRequest.displayName = 'proto.cbv1.CreateMileageCostsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateMileageCostsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateMileageCostsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateMileageCostsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateMileageCostsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lowerBoundMiles: jspb.Message.getFieldWithDefault(msg, 1, 0),
    upperBoundMiles: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cost: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateMileageCostsRequest}
 */
proto.cbv1.CreateMileageCostsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateMileageCostsRequest;
  return proto.cbv1.CreateMileageCostsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateMileageCostsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateMileageCostsRequest}
 */
proto.cbv1.CreateMileageCostsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLowerBoundMiles(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpperBoundMiles(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateMileageCostsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateMileageCostsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateMileageCostsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateMileageCostsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLowerBoundMiles();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUpperBoundMiles();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCost();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 lower_bound_miles = 1;
 * @return {number}
 */
proto.cbv1.CreateMileageCostsRequest.prototype.getLowerBoundMiles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.CreateMileageCostsRequest.prototype.setLowerBoundMiles = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 upper_bound_miles = 2;
 * @return {number}
 */
proto.cbv1.CreateMileageCostsRequest.prototype.getUpperBoundMiles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.CreateMileageCostsRequest.prototype.setUpperBoundMiles = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 cost = 3;
 * @return {number}
 */
proto.cbv1.CreateMileageCostsRequest.prototype.getCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.CreateMileageCostsRequest.prototype.setCost = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateVendorContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateVendorContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateVendorContractResponse.displayName = 'proto.cbv1.CreateVendorContractResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateVendorContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateVendorContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateVendorContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateVendorContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vendorContract: (f = msg.getVendorContract()) && proto_cybertron_contracts_contracts_pb.VendorContract.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateVendorContractResponse}
 */
proto.cbv1.CreateVendorContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateVendorContractResponse;
  return proto.cbv1.CreateVendorContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateVendorContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateVendorContractResponse}
 */
proto.cbv1.CreateVendorContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_contracts_contracts_pb.VendorContract;
      reader.readMessage(value,proto_cybertron_contracts_contracts_pb.VendorContract.deserializeBinaryFromReader);
      msg.setVendorContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateVendorContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateVendorContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateVendorContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateVendorContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVendorContract();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_contracts_contracts_pb.VendorContract.serializeBinaryToWriter
    );
  }
};


/**
 * optional VendorContract vendor_contract = 1;
 * @return {?proto.cbv1.VendorContract}
 */
proto.cbv1.CreateVendorContractResponse.prototype.getVendorContract = function() {
  return /** @type{?proto.cbv1.VendorContract} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_contracts_contracts_pb.VendorContract, 1));
};


/** @param {?proto.cbv1.VendorContract|undefined} value */
proto.cbv1.CreateVendorContractResponse.prototype.setVendorContract = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.CreateVendorContractResponse.prototype.clearVendorContract = function() {
  this.setVendorContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CreateVendorContractResponse.prototype.hasVendorContract = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.EditVendorContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.EditVendorContractRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.EditVendorContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.EditVendorContractRequest.displayName = 'proto.cbv1.EditVendorContractRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.EditVendorContractRequest.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.EditVendorContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.EditVendorContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.EditVendorContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.EditVendorContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hourlyDetentionCost: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maxDetentionPerOrder: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pickupFreeTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dropoffFreeTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    deadheadFreeMileage: jspb.Message.getFieldWithDefault(msg, 7, 0),
    deadheadCostPerMile: jspb.Message.getFieldWithDefault(msg, 8, 0),
    travelCostType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    perHourTravelCost: jspb.Message.getFieldWithDefault(msg, 10, 0),
    payloadTypeIdsList: jspb.Message.getRepeatedField(msg, 11),
    mileageCostRequestsList: jspb.Message.toObjectList(msg.getMileageCostRequestsList(),
    proto.cbv1.CreateMileageCostsRequest.toObject, includeInstance),
    referenceNumber: jspb.Message.getFieldWithDefault(msg, 13, ""),
    isDefault: jspb.Message.getFieldWithDefault(msg, 14, false),
    mask: (f = msg.getMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f),
    perMileTravelCost: jspb.Message.getFieldWithDefault(msg, 16, 0),
    contractTypeId: jspb.Message.getFieldWithDefault(msg, 17, 0),
    fuelSurcharge: jspb.Message.getFieldWithDefault(msg, 18, false),
    minimumCharge: jspb.Message.getFieldWithDefault(msg, 19, 0),
    fixCostFileId: jspb.Message.getFieldWithDefault(msg, 20, 0),
    isReturnTripBillable: jspb.Message.getFieldWithDefault(msg, 21, false),
    isBackHaulEnabled: jspb.Message.getFieldWithDefault(msg, 22, false),
    backHaulChargePercent: jspb.Message.getFieldWithDefault(msg, 23, 0),
    expirationTime: (f = msg.getExpirationTime()) && proto_cybertron_types_types_pb.NullableTime.toObject(includeInstance, f),
    effectiveTime: (f = msg.getEffectiveTime()) && proto_cybertron_types_types_pb.NullableTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.EditVendorContractRequest}
 */
proto.cbv1.EditVendorContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.EditVendorContractRequest;
  return proto.cbv1.EditVendorContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.EditVendorContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.EditVendorContractRequest}
 */
proto.cbv1.EditVendorContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHourlyDetentionCost(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxDetentionPerOrder(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickupFreeTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDropoffFreeTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeadheadFreeMileage(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeadheadCostPerMile(value);
      break;
    case 9:
      var value = /** @type {!proto.cbv1.VendorContractTravelCostType} */ (reader.readEnum());
      msg.setTravelCostType(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPerHourTravelCost(value);
      break;
    case 11:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setPayloadTypeIdsList(value);
      break;
    case 12:
      var value = new proto.cbv1.CreateMileageCostsRequest;
      reader.readMessage(value,proto.cbv1.CreateMileageCostsRequest.deserializeBinaryFromReader);
      msg.addMileageCostRequests(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceNumber(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDefault(value);
      break;
    case 15:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setMask(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPerMileTravelCost(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContractTypeId(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFuelSurcharge(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinimumCharge(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFixCostFileId(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReturnTripBillable(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBackHaulEnabled(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBackHaulChargePercent(value);
      break;
    case 24:
      var value = new proto_cybertron_types_types_pb.NullableTime;
      reader.readMessage(value,proto_cybertron_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setExpirationTime(value);
      break;
    case 25:
      var value = new proto_cybertron_types_types_pb.NullableTime;
      reader.readMessage(value,proto_cybertron_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setEffectiveTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.EditVendorContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.EditVendorContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.EditVendorContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.EditVendorContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHourlyDetentionCost();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMaxDetentionPerOrder();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPickupFreeTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getDropoffFreeTime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getDeadheadFreeMileage();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getDeadheadCostPerMile();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getTravelCostType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getPerHourTravelCost();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getPayloadTypeIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      11,
      f
    );
  }
  f = message.getMileageCostRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.cbv1.CreateMileageCostsRequest.serializeBinaryToWriter
    );
  }
  f = message.getReferenceNumber();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getIsDefault();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getMask();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
  f = message.getPerMileTravelCost();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getContractTypeId();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getFuelSurcharge();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getMinimumCharge();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getFixCostFileId();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getIsReturnTripBillable();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getIsBackHaulEnabled();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getBackHaulChargePercent();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getExpirationTime();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto_cybertron_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getEffectiveTime();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto_cybertron_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.EditVendorContractRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.EditVendorContractRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.EditVendorContractRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.EditVendorContractRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 hourly_detention_cost = 3;
 * @return {number}
 */
proto.cbv1.EditVendorContractRequest.prototype.getHourlyDetentionCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.EditVendorContractRequest.prototype.setHourlyDetentionCost = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 max_detention_per_order = 4;
 * @return {number}
 */
proto.cbv1.EditVendorContractRequest.prototype.getMaxDetentionPerOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.EditVendorContractRequest.prototype.setMaxDetentionPerOrder = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 pickup_free_time = 5;
 * @return {number}
 */
proto.cbv1.EditVendorContractRequest.prototype.getPickupFreeTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.EditVendorContractRequest.prototype.setPickupFreeTime = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 dropoff_free_time = 6;
 * @return {number}
 */
proto.cbv1.EditVendorContractRequest.prototype.getDropoffFreeTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.EditVendorContractRequest.prototype.setDropoffFreeTime = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 deadhead_free_mileage = 7;
 * @return {number}
 */
proto.cbv1.EditVendorContractRequest.prototype.getDeadheadFreeMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.EditVendorContractRequest.prototype.setDeadheadFreeMileage = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 deadhead_cost_per_mile = 8;
 * @return {number}
 */
proto.cbv1.EditVendorContractRequest.prototype.getDeadheadCostPerMile = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cbv1.EditVendorContractRequest.prototype.setDeadheadCostPerMile = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional VendorContractTravelCostType travel_cost_type = 9;
 * @return {!proto.cbv1.VendorContractTravelCostType}
 */
proto.cbv1.EditVendorContractRequest.prototype.getTravelCostType = function() {
  return /** @type {!proto.cbv1.VendorContractTravelCostType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {!proto.cbv1.VendorContractTravelCostType} value */
proto.cbv1.EditVendorContractRequest.prototype.setTravelCostType = function(value) {
  jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional int64 per_hour_travel_cost = 10;
 * @return {number}
 */
proto.cbv1.EditVendorContractRequest.prototype.getPerHourTravelCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cbv1.EditVendorContractRequest.prototype.setPerHourTravelCost = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated int64 payload_type_ids = 11;
 * @return {!Array<number>}
 */
proto.cbv1.EditVendorContractRequest.prototype.getPayloadTypeIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/** @param {!Array<number>} value */
proto.cbv1.EditVendorContractRequest.prototype.setPayloadTypeIdsList = function(value) {
  jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.EditVendorContractRequest.prototype.addPayloadTypeIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


proto.cbv1.EditVendorContractRequest.prototype.clearPayloadTypeIdsList = function() {
  this.setPayloadTypeIdsList([]);
};


/**
 * repeated CreateMileageCostsRequest mileage_cost_requests = 12;
 * @return {!Array<!proto.cbv1.CreateMileageCostsRequest>}
 */
proto.cbv1.EditVendorContractRequest.prototype.getMileageCostRequestsList = function() {
  return /** @type{!Array<!proto.cbv1.CreateMileageCostsRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.CreateMileageCostsRequest, 12));
};


/** @param {!Array<!proto.cbv1.CreateMileageCostsRequest>} value */
proto.cbv1.EditVendorContractRequest.prototype.setMileageCostRequestsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.cbv1.CreateMileageCostsRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.CreateMileageCostsRequest}
 */
proto.cbv1.EditVendorContractRequest.prototype.addMileageCostRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.cbv1.CreateMileageCostsRequest, opt_index);
};


proto.cbv1.EditVendorContractRequest.prototype.clearMileageCostRequestsList = function() {
  this.setMileageCostRequestsList([]);
};


/**
 * optional string reference_number = 13;
 * @return {string}
 */
proto.cbv1.EditVendorContractRequest.prototype.getReferenceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.cbv1.EditVendorContractRequest.prototype.setReferenceNumber = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool is_default = 14;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.EditVendorContractRequest.prototype.getIsDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 14, false));
};


/** @param {boolean} value */
proto.cbv1.EditVendorContractRequest.prototype.setIsDefault = function(value) {
  jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional google.protobuf.FieldMask mask = 15;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.cbv1.EditVendorContractRequest.prototype.getMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 15));
};


/** @param {?proto.google.protobuf.FieldMask|undefined} value */
proto.cbv1.EditVendorContractRequest.prototype.setMask = function(value) {
  jspb.Message.setWrapperField(this, 15, value);
};


proto.cbv1.EditVendorContractRequest.prototype.clearMask = function() {
  this.setMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.EditVendorContractRequest.prototype.hasMask = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional int64 per_mile_travel_cost = 16;
 * @return {number}
 */
proto.cbv1.EditVendorContractRequest.prototype.getPerMileTravelCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.cbv1.EditVendorContractRequest.prototype.setPerMileTravelCost = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 contract_type_id = 17;
 * @return {number}
 */
proto.cbv1.EditVendorContractRequest.prototype.getContractTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {number} value */
proto.cbv1.EditVendorContractRequest.prototype.setContractTypeId = function(value) {
  jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional bool fuel_surcharge = 18;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.EditVendorContractRequest.prototype.getFuelSurcharge = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 18, false));
};


/** @param {boolean} value */
proto.cbv1.EditVendorContractRequest.prototype.setFuelSurcharge = function(value) {
  jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional int64 minimum_charge = 19;
 * @return {number}
 */
proto.cbv1.EditVendorContractRequest.prototype.getMinimumCharge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/** @param {number} value */
proto.cbv1.EditVendorContractRequest.prototype.setMinimumCharge = function(value) {
  jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 fix_cost_file_id = 20;
 * @return {number}
 */
proto.cbv1.EditVendorContractRequest.prototype.getFixCostFileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/** @param {number} value */
proto.cbv1.EditVendorContractRequest.prototype.setFixCostFileId = function(value) {
  jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional bool is_return_trip_billable = 21;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.EditVendorContractRequest.prototype.getIsReturnTripBillable = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 21, false));
};


/** @param {boolean} value */
proto.cbv1.EditVendorContractRequest.prototype.setIsReturnTripBillable = function(value) {
  jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool is_back_haul_enabled = 22;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.EditVendorContractRequest.prototype.getIsBackHaulEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 22, false));
};


/** @param {boolean} value */
proto.cbv1.EditVendorContractRequest.prototype.setIsBackHaulEnabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional int32 back_haul_charge_percent = 23;
 * @return {number}
 */
proto.cbv1.EditVendorContractRequest.prototype.getBackHaulChargePercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/** @param {number} value */
proto.cbv1.EditVendorContractRequest.prototype.setBackHaulChargePercent = function(value) {
  jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional NullableTime expiration_time = 24;
 * @return {?proto.cbv1.NullableTime}
 */
proto.cbv1.EditVendorContractRequest.prototype.getExpirationTime = function() {
  return /** @type{?proto.cbv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_types_types_pb.NullableTime, 24));
};


/** @param {?proto.cbv1.NullableTime|undefined} value */
proto.cbv1.EditVendorContractRequest.prototype.setExpirationTime = function(value) {
  jspb.Message.setWrapperField(this, 24, value);
};


proto.cbv1.EditVendorContractRequest.prototype.clearExpirationTime = function() {
  this.setExpirationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.EditVendorContractRequest.prototype.hasExpirationTime = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional NullableTime effective_time = 25;
 * @return {?proto.cbv1.NullableTime}
 */
proto.cbv1.EditVendorContractRequest.prototype.getEffectiveTime = function() {
  return /** @type{?proto.cbv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_types_types_pb.NullableTime, 25));
};


/** @param {?proto.cbv1.NullableTime|undefined} value */
proto.cbv1.EditVendorContractRequest.prototype.setEffectiveTime = function(value) {
  jspb.Message.setWrapperField(this, 25, value);
};


proto.cbv1.EditVendorContractRequest.prototype.clearEffectiveTime = function() {
  this.setEffectiveTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.EditVendorContractRequest.prototype.hasEffectiveTime = function() {
  return jspb.Message.getField(this, 25) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.EditVendorContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.EditVendorContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.EditVendorContractResponse.displayName = 'proto.cbv1.EditVendorContractResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.EditVendorContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.EditVendorContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.EditVendorContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.EditVendorContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vendorContract: (f = msg.getVendorContract()) && proto_cybertron_contracts_contracts_pb.VendorContract.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.EditVendorContractResponse}
 */
proto.cbv1.EditVendorContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.EditVendorContractResponse;
  return proto.cbv1.EditVendorContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.EditVendorContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.EditVendorContractResponse}
 */
proto.cbv1.EditVendorContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_contracts_contracts_pb.VendorContract;
      reader.readMessage(value,proto_cybertron_contracts_contracts_pb.VendorContract.deserializeBinaryFromReader);
      msg.setVendorContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.EditVendorContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.EditVendorContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.EditVendorContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.EditVendorContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVendorContract();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_contracts_contracts_pb.VendorContract.serializeBinaryToWriter
    );
  }
};


/**
 * optional VendorContract vendor_contract = 1;
 * @return {?proto.cbv1.VendorContract}
 */
proto.cbv1.EditVendorContractResponse.prototype.getVendorContract = function() {
  return /** @type{?proto.cbv1.VendorContract} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_contracts_contracts_pb.VendorContract, 1));
};


/** @param {?proto.cbv1.VendorContract|undefined} value */
proto.cbv1.EditVendorContractResponse.prototype.setVendorContract = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.EditVendorContractResponse.prototype.clearVendorContract = function() {
  this.setVendorContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.EditVendorContractResponse.prototype.hasVendorContract = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetVendorContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetVendorContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetVendorContractRequest.displayName = 'proto.cbv1.GetVendorContractRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetVendorContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetVendorContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetVendorContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetVendorContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetVendorContractRequest}
 */
proto.cbv1.GetVendorContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetVendorContractRequest;
  return proto.cbv1.GetVendorContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetVendorContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetVendorContractRequest}
 */
proto.cbv1.GetVendorContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetVendorContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetVendorContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetVendorContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetVendorContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.GetVendorContractRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.GetVendorContractRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetVendorContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetVendorContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetVendorContractResponse.displayName = 'proto.cbv1.GetVendorContractResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetVendorContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetVendorContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetVendorContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetVendorContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vendorContract: (f = msg.getVendorContract()) && proto_cybertron_contracts_contracts_pb.VendorContract.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetVendorContractResponse}
 */
proto.cbv1.GetVendorContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetVendorContractResponse;
  return proto.cbv1.GetVendorContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetVendorContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetVendorContractResponse}
 */
proto.cbv1.GetVendorContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_contracts_contracts_pb.VendorContract;
      reader.readMessage(value,proto_cybertron_contracts_contracts_pb.VendorContract.deserializeBinaryFromReader);
      msg.setVendorContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetVendorContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetVendorContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetVendorContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetVendorContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVendorContract();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_contracts_contracts_pb.VendorContract.serializeBinaryToWriter
    );
  }
};


/**
 * optional VendorContract vendor_contract = 1;
 * @return {?proto.cbv1.VendorContract}
 */
proto.cbv1.GetVendorContractResponse.prototype.getVendorContract = function() {
  return /** @type{?proto.cbv1.VendorContract} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_contracts_contracts_pb.VendorContract, 1));
};


/** @param {?proto.cbv1.VendorContract|undefined} value */
proto.cbv1.GetVendorContractResponse.prototype.setVendorContract = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.GetVendorContractResponse.prototype.clearVendorContract = function() {
  this.setVendorContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.GetVendorContractResponse.prototype.hasVendorContract = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ToggleArchiveVendorContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ToggleArchiveVendorContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ToggleArchiveVendorContractRequest.displayName = 'proto.cbv1.ToggleArchiveVendorContractRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ToggleArchiveVendorContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ToggleArchiveVendorContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ToggleArchiveVendorContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ToggleArchiveVendorContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    archive: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ToggleArchiveVendorContractRequest}
 */
proto.cbv1.ToggleArchiveVendorContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ToggleArchiveVendorContractRequest;
  return proto.cbv1.ToggleArchiveVendorContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ToggleArchiveVendorContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ToggleArchiveVendorContractRequest}
 */
proto.cbv1.ToggleArchiveVendorContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ToggleArchiveVendorContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ToggleArchiveVendorContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ToggleArchiveVendorContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ToggleArchiveVendorContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getArchive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.ToggleArchiveVendorContractRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.ToggleArchiveVendorContractRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool archive = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.ToggleArchiveVendorContractRequest.prototype.getArchive = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.ToggleArchiveVendorContractRequest.prototype.setArchive = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ToggleArchiveVendorContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ToggleArchiveVendorContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ToggleArchiveVendorContractResponse.displayName = 'proto.cbv1.ToggleArchiveVendorContractResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ToggleArchiveVendorContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ToggleArchiveVendorContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ToggleArchiveVendorContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ToggleArchiveVendorContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vendorContract: (f = msg.getVendorContract()) && proto_cybertron_contracts_contracts_pb.VendorContract.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ToggleArchiveVendorContractResponse}
 */
proto.cbv1.ToggleArchiveVendorContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ToggleArchiveVendorContractResponse;
  return proto.cbv1.ToggleArchiveVendorContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ToggleArchiveVendorContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ToggleArchiveVendorContractResponse}
 */
proto.cbv1.ToggleArchiveVendorContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_contracts_contracts_pb.VendorContract;
      reader.readMessage(value,proto_cybertron_contracts_contracts_pb.VendorContract.deserializeBinaryFromReader);
      msg.setVendorContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ToggleArchiveVendorContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ToggleArchiveVendorContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ToggleArchiveVendorContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ToggleArchiveVendorContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVendorContract();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_contracts_contracts_pb.VendorContract.serializeBinaryToWriter
    );
  }
};


/**
 * optional VendorContract vendor_contract = 1;
 * @return {?proto.cbv1.VendorContract}
 */
proto.cbv1.ToggleArchiveVendorContractResponse.prototype.getVendorContract = function() {
  return /** @type{?proto.cbv1.VendorContract} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_contracts_contracts_pb.VendorContract, 1));
};


/** @param {?proto.cbv1.VendorContract|undefined} value */
proto.cbv1.ToggleArchiveVendorContractResponse.prototype.setVendorContract = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.ToggleArchiveVendorContractResponse.prototype.clearVendorContract = function() {
  this.setVendorContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.ToggleArchiveVendorContractResponse.prototype.hasVendorContract = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListVendorContractsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ListVendorContractsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListVendorContractsRequest.displayName = 'proto.cbv1.ListVendorContractsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListVendorContractsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListVendorContractsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListVendorContractsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListVendorContractsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListVendorContractsRequest}
 */
proto.cbv1.ListVendorContractsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListVendorContractsRequest;
  return proto.cbv1.ListVendorContractsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListVendorContractsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListVendorContractsRequest}
 */
proto.cbv1.ListVendorContractsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListVendorContractsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListVendorContractsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListVendorContractsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListVendorContractsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListVendorContractsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListVendorContractsResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListVendorContractsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListVendorContractsResponse.displayName = 'proto.cbv1.ListVendorContractsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListVendorContractsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListVendorContractsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListVendorContractsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListVendorContractsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListVendorContractsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vendorContractsList: jspb.Message.toObjectList(msg.getVendorContractsList(),
    proto_cybertron_contracts_contracts_pb.VendorContract.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListVendorContractsResponse}
 */
proto.cbv1.ListVendorContractsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListVendorContractsResponse;
  return proto.cbv1.ListVendorContractsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListVendorContractsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListVendorContractsResponse}
 */
proto.cbv1.ListVendorContractsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_contracts_contracts_pb.VendorContract;
      reader.readMessage(value,proto_cybertron_contracts_contracts_pb.VendorContract.deserializeBinaryFromReader);
      msg.addVendorContracts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListVendorContractsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListVendorContractsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListVendorContractsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListVendorContractsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVendorContractsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_contracts_contracts_pb.VendorContract.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VendorContract vendor_contracts = 1;
 * @return {!Array<!proto.cbv1.VendorContract>}
 */
proto.cbv1.ListVendorContractsResponse.prototype.getVendorContractsList = function() {
  return /** @type{!Array<!proto.cbv1.VendorContract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_contracts_contracts_pb.VendorContract, 1));
};


/** @param {!Array<!proto.cbv1.VendorContract>} value */
proto.cbv1.ListVendorContractsResponse.prototype.setVendorContractsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.VendorContract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.VendorContract}
 */
proto.cbv1.ListVendorContractsResponse.prototype.addVendorContracts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.VendorContract, opt_index);
};


proto.cbv1.ListVendorContractsResponse.prototype.clearVendorContractsList = function() {
  this.setVendorContractsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest.displayName = 'proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vendorPayloadTypePercentagesList: jspb.Message.toObjectList(msg.getVendorPayloadTypePercentagesList(),
    proto.cbv1.VendorPayloadTypePercentage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest}
 */
proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest;
  return proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest}
 */
proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cbv1.VendorPayloadTypePercentage;
      reader.readMessage(value,proto.cbv1.VendorPayloadTypePercentage.deserializeBinaryFromReader);
      msg.addVendorPayloadTypePercentages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVendorPayloadTypePercentagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cbv1.VendorPayloadTypePercentage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VendorPayloadTypePercentage vendor_payload_type_percentages = 1;
 * @return {!Array<!proto.cbv1.VendorPayloadTypePercentage>}
 */
proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest.prototype.getVendorPayloadTypePercentagesList = function() {
  return /** @type{!Array<!proto.cbv1.VendorPayloadTypePercentage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.VendorPayloadTypePercentage, 1));
};


/** @param {!Array<!proto.cbv1.VendorPayloadTypePercentage>} value */
proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest.prototype.setVendorPayloadTypePercentagesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.VendorPayloadTypePercentage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.VendorPayloadTypePercentage}
 */
proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest.prototype.addVendorPayloadTypePercentages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.VendorPayloadTypePercentage, opt_index);
};


proto.cbv1.CreateLMOVendorPayloadTypePercentageRequest.prototype.clearVendorPayloadTypePercentagesList = function() {
  this.setVendorPayloadTypePercentagesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorPayloadTypePercentage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.VendorPayloadTypePercentage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorPayloadTypePercentage.displayName = 'proto.cbv1.VendorPayloadTypePercentage';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorPayloadTypePercentage.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorPayloadTypePercentage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorPayloadTypePercentage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorPayloadTypePercentage.toObject = function(includeInstance, msg) {
  var f, obj = {
    vendorId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    payloadTypeId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    percentage: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorPayloadTypePercentage}
 */
proto.cbv1.VendorPayloadTypePercentage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorPayloadTypePercentage;
  return proto.cbv1.VendorPayloadTypePercentage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorPayloadTypePercentage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorPayloadTypePercentage}
 */
proto.cbv1.VendorPayloadTypePercentage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVendorId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPayloadTypeId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorPayloadTypePercentage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorPayloadTypePercentage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorPayloadTypePercentage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorPayloadTypePercentage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVendorId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPayloadTypeId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPercentage();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 vendor_id = 1;
 * @return {number}
 */
proto.cbv1.VendorPayloadTypePercentage.prototype.getVendorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.VendorPayloadTypePercentage.prototype.setVendorId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 payload_type_id = 2;
 * @return {number}
 */
proto.cbv1.VendorPayloadTypePercentage.prototype.getPayloadTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.VendorPayloadTypePercentage.prototype.setPayloadTypeId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 percentage = 3;
 * @return {number}
 */
proto.cbv1.VendorPayloadTypePercentage.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.VendorPayloadTypePercentage.prototype.setPercentage = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse.displayName = 'proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse}
 */
proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse;
  return proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse}
 */
proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateLMOVendorPayloadTypePercentageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetLMOVendorPayloadTypePercentageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetLMOVendorPayloadTypePercentageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetLMOVendorPayloadTypePercentageRequest.displayName = 'proto.cbv1.GetLMOVendorPayloadTypePercentageRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetLMOVendorPayloadTypePercentageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetLMOVendorPayloadTypePercentageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetLMOVendorPayloadTypePercentageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetLMOVendorPayloadTypePercentageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetLMOVendorPayloadTypePercentageRequest}
 */
proto.cbv1.GetLMOVendorPayloadTypePercentageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetLMOVendorPayloadTypePercentageRequest;
  return proto.cbv1.GetLMOVendorPayloadTypePercentageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetLMOVendorPayloadTypePercentageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetLMOVendorPayloadTypePercentageRequest}
 */
proto.cbv1.GetLMOVendorPayloadTypePercentageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetLMOVendorPayloadTypePercentageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetLMOVendorPayloadTypePercentageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetLMOVendorPayloadTypePercentageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetLMOVendorPayloadTypePercentageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetLMOVendorPayloadTypePercentageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.GetLMOVendorPayloadTypePercentageResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.GetLMOVendorPayloadTypePercentageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetLMOVendorPayloadTypePercentageResponse.displayName = 'proto.cbv1.GetLMOVendorPayloadTypePercentageResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.GetLMOVendorPayloadTypePercentageResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetLMOVendorPayloadTypePercentageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetLMOVendorPayloadTypePercentageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetLMOVendorPayloadTypePercentageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetLMOVendorPayloadTypePercentageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    payloadPercentagesList: jspb.Message.toObjectList(msg.getPayloadPercentagesList(),
    proto_cybertron_contracts_contracts_pb.LMOVendorPayloadTypePercentage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetLMOVendorPayloadTypePercentageResponse}
 */
proto.cbv1.GetLMOVendorPayloadTypePercentageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetLMOVendorPayloadTypePercentageResponse;
  return proto.cbv1.GetLMOVendorPayloadTypePercentageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetLMOVendorPayloadTypePercentageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetLMOVendorPayloadTypePercentageResponse}
 */
proto.cbv1.GetLMOVendorPayloadTypePercentageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_contracts_contracts_pb.LMOVendorPayloadTypePercentage;
      reader.readMessage(value,proto_cybertron_contracts_contracts_pb.LMOVendorPayloadTypePercentage.deserializeBinaryFromReader);
      msg.addPayloadPercentages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetLMOVendorPayloadTypePercentageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetLMOVendorPayloadTypePercentageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetLMOVendorPayloadTypePercentageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetLMOVendorPayloadTypePercentageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayloadPercentagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_contracts_contracts_pb.LMOVendorPayloadTypePercentage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LMOVendorPayloadTypePercentage payload_percentages = 1;
 * @return {!Array<!proto.cbv1.LMOVendorPayloadTypePercentage>}
 */
proto.cbv1.GetLMOVendorPayloadTypePercentageResponse.prototype.getPayloadPercentagesList = function() {
  return /** @type{!Array<!proto.cbv1.LMOVendorPayloadTypePercentage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_contracts_contracts_pb.LMOVendorPayloadTypePercentage, 1));
};


/** @param {!Array<!proto.cbv1.LMOVendorPayloadTypePercentage>} value */
proto.cbv1.GetLMOVendorPayloadTypePercentageResponse.prototype.setPayloadPercentagesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.LMOVendorPayloadTypePercentage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.LMOVendorPayloadTypePercentage}
 */
proto.cbv1.GetLMOVendorPayloadTypePercentageResponse.prototype.addPayloadPercentages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.LMOVendorPayloadTypePercentage, opt_index);
};


proto.cbv1.GetLMOVendorPayloadTypePercentageResponse.prototype.clearPayloadPercentagesList = function() {
  this.setPayloadPercentagesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateContractTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateContractTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateContractTypeRequest.displayName = 'proto.cbv1.CreateContractTypeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateContractTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateContractTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateContractTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateContractTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ediCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fuelChargeContractId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateContractTypeRequest}
 */
proto.cbv1.CreateContractTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateContractTypeRequest;
  return proto.cbv1.CreateContractTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateContractTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateContractTypeRequest}
 */
proto.cbv1.CreateContractTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdiCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFuelChargeContractId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateContractTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateContractTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateContractTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateContractTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEdiCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFuelChargeContractId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.cbv1.CreateContractTypeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.CreateContractTypeRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string edi_code = 2;
 * @return {string}
 */
proto.cbv1.CreateContractTypeRequest.prototype.getEdiCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.CreateContractTypeRequest.prototype.setEdiCode = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 fuel_charge_contract_id = 3;
 * @return {number}
 */
proto.cbv1.CreateContractTypeRequest.prototype.getFuelChargeContractId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.CreateContractTypeRequest.prototype.setFuelChargeContractId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateContractTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateContractTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateContractTypeResponse.displayName = 'proto.cbv1.CreateContractTypeResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateContractTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateContractTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateContractTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateContractTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contracttype: (f = msg.getContracttype()) && proto_cybertron_contracts_contracts_pb.VendorContractType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateContractTypeResponse}
 */
proto.cbv1.CreateContractTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateContractTypeResponse;
  return proto.cbv1.CreateContractTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateContractTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateContractTypeResponse}
 */
proto.cbv1.CreateContractTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_contracts_contracts_pb.VendorContractType;
      reader.readMessage(value,proto_cybertron_contracts_contracts_pb.VendorContractType.deserializeBinaryFromReader);
      msg.setContracttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateContractTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateContractTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateContractTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateContractTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContracttype();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_contracts_contracts_pb.VendorContractType.serializeBinaryToWriter
    );
  }
};


/**
 * optional VendorContractType contractType = 1;
 * @return {?proto.cbv1.VendorContractType}
 */
proto.cbv1.CreateContractTypeResponse.prototype.getContracttype = function() {
  return /** @type{?proto.cbv1.VendorContractType} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_contracts_contracts_pb.VendorContractType, 1));
};


/** @param {?proto.cbv1.VendorContractType|undefined} value */
proto.cbv1.CreateContractTypeResponse.prototype.setContracttype = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.CreateContractTypeResponse.prototype.clearContracttype = function() {
  this.setContracttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CreateContractTypeResponse.prototype.hasContracttype = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.EditContractTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.EditContractTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.EditContractTypeRequest.displayName = 'proto.cbv1.EditContractTypeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.EditContractTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.EditContractTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.EditContractTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.EditContractTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ediCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fuelChargeContractId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.EditContractTypeRequest}
 */
proto.cbv1.EditContractTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.EditContractTypeRequest;
  return proto.cbv1.EditContractTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.EditContractTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.EditContractTypeRequest}
 */
proto.cbv1.EditContractTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdiCode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFuelChargeContractId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.EditContractTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.EditContractTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.EditContractTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.EditContractTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEdiCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFuelChargeContractId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.EditContractTypeRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.EditContractTypeRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.EditContractTypeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.EditContractTypeRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string edi_code = 3;
 * @return {string}
 */
proto.cbv1.EditContractTypeRequest.prototype.getEdiCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.EditContractTypeRequest.prototype.setEdiCode = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 fuel_charge_contract_id = 4;
 * @return {number}
 */
proto.cbv1.EditContractTypeRequest.prototype.getFuelChargeContractId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.EditContractTypeRequest.prototype.setFuelChargeContractId = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.EditContractTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.EditContractTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.EditContractTypeResponse.displayName = 'proto.cbv1.EditContractTypeResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.EditContractTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.EditContractTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.EditContractTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.EditContractTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contracttype: (f = msg.getContracttype()) && proto_cybertron_contracts_contracts_pb.VendorContractType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.EditContractTypeResponse}
 */
proto.cbv1.EditContractTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.EditContractTypeResponse;
  return proto.cbv1.EditContractTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.EditContractTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.EditContractTypeResponse}
 */
proto.cbv1.EditContractTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_contracts_contracts_pb.VendorContractType;
      reader.readMessage(value,proto_cybertron_contracts_contracts_pb.VendorContractType.deserializeBinaryFromReader);
      msg.setContracttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.EditContractTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.EditContractTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.EditContractTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.EditContractTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContracttype();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_contracts_contracts_pb.VendorContractType.serializeBinaryToWriter
    );
  }
};


/**
 * optional VendorContractType contractType = 1;
 * @return {?proto.cbv1.VendorContractType}
 */
proto.cbv1.EditContractTypeResponse.prototype.getContracttype = function() {
  return /** @type{?proto.cbv1.VendorContractType} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_contracts_contracts_pb.VendorContractType, 1));
};


/** @param {?proto.cbv1.VendorContractType|undefined} value */
proto.cbv1.EditContractTypeResponse.prototype.setContracttype = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.EditContractTypeResponse.prototype.clearContracttype = function() {
  this.setContracttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.EditContractTypeResponse.prototype.hasContracttype = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListContractTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ListContractTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListContractTypeRequest.displayName = 'proto.cbv1.ListContractTypeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListContractTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListContractTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListContractTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListContractTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListContractTypeRequest}
 */
proto.cbv1.ListContractTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListContractTypeRequest;
  return proto.cbv1.ListContractTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListContractTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListContractTypeRequest}
 */
proto.cbv1.ListContractTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListContractTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListContractTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListContractTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListContractTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListContractTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListContractTypeResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListContractTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListContractTypeResponse.displayName = 'proto.cbv1.ListContractTypeResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListContractTypeResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListContractTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListContractTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListContractTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListContractTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contracttypesList: jspb.Message.toObjectList(msg.getContracttypesList(),
    proto_cybertron_contracts_contracts_pb.VendorContractType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListContractTypeResponse}
 */
proto.cbv1.ListContractTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListContractTypeResponse;
  return proto.cbv1.ListContractTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListContractTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListContractTypeResponse}
 */
proto.cbv1.ListContractTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_contracts_contracts_pb.VendorContractType;
      reader.readMessage(value,proto_cybertron_contracts_contracts_pb.VendorContractType.deserializeBinaryFromReader);
      msg.addContracttypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListContractTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListContractTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListContractTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListContractTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContracttypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_contracts_contracts_pb.VendorContractType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VendorContractType contractTypes = 1;
 * @return {!Array<!proto.cbv1.VendorContractType>}
 */
proto.cbv1.ListContractTypeResponse.prototype.getContracttypesList = function() {
  return /** @type{!Array<!proto.cbv1.VendorContractType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_contracts_contracts_pb.VendorContractType, 1));
};


/** @param {!Array<!proto.cbv1.VendorContractType>} value */
proto.cbv1.ListContractTypeResponse.prototype.setContracttypesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.VendorContractType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.VendorContractType}
 */
proto.cbv1.ListContractTypeResponse.prototype.addContracttypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.VendorContractType, opt_index);
};


proto.cbv1.ListContractTypeResponse.prototype.clearContracttypesList = function() {
  this.setContracttypesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ToggleArchiveVendorContractTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ToggleArchiveVendorContractTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ToggleArchiveVendorContractTypeRequest.displayName = 'proto.cbv1.ToggleArchiveVendorContractTypeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ToggleArchiveVendorContractTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ToggleArchiveVendorContractTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ToggleArchiveVendorContractTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ToggleArchiveVendorContractTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    archive: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ToggleArchiveVendorContractTypeRequest}
 */
proto.cbv1.ToggleArchiveVendorContractTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ToggleArchiveVendorContractTypeRequest;
  return proto.cbv1.ToggleArchiveVendorContractTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ToggleArchiveVendorContractTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ToggleArchiveVendorContractTypeRequest}
 */
proto.cbv1.ToggleArchiveVendorContractTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ToggleArchiveVendorContractTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ToggleArchiveVendorContractTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ToggleArchiveVendorContractTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ToggleArchiveVendorContractTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getArchive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.ToggleArchiveVendorContractTypeRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.ToggleArchiveVendorContractTypeRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool archive = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.ToggleArchiveVendorContractTypeRequest.prototype.getArchive = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.ToggleArchiveVendorContractTypeRequest.prototype.setArchive = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ToggleArchiveVendorContractTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ToggleArchiveVendorContractTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ToggleArchiveVendorContractTypeResponse.displayName = 'proto.cbv1.ToggleArchiveVendorContractTypeResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ToggleArchiveVendorContractTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ToggleArchiveVendorContractTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ToggleArchiveVendorContractTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ToggleArchiveVendorContractTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contracttype: (f = msg.getContracttype()) && proto_cybertron_contracts_contracts_pb.VendorContractType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ToggleArchiveVendorContractTypeResponse}
 */
proto.cbv1.ToggleArchiveVendorContractTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ToggleArchiveVendorContractTypeResponse;
  return proto.cbv1.ToggleArchiveVendorContractTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ToggleArchiveVendorContractTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ToggleArchiveVendorContractTypeResponse}
 */
proto.cbv1.ToggleArchiveVendorContractTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_contracts_contracts_pb.VendorContractType;
      reader.readMessage(value,proto_cybertron_contracts_contracts_pb.VendorContractType.deserializeBinaryFromReader);
      msg.setContracttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ToggleArchiveVendorContractTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ToggleArchiveVendorContractTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ToggleArchiveVendorContractTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ToggleArchiveVendorContractTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContracttype();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_contracts_contracts_pb.VendorContractType.serializeBinaryToWriter
    );
  }
};


/**
 * optional VendorContractType contractType = 1;
 * @return {?proto.cbv1.VendorContractType}
 */
proto.cbv1.ToggleArchiveVendorContractTypeResponse.prototype.getContracttype = function() {
  return /** @type{?proto.cbv1.VendorContractType} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_contracts_contracts_pb.VendorContractType, 1));
};


/** @param {?proto.cbv1.VendorContractType|undefined} value */
proto.cbv1.ToggleArchiveVendorContractTypeResponse.prototype.setContracttype = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.ToggleArchiveVendorContractTypeResponse.prototype.clearContracttype = function() {
  this.setContracttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.ToggleArchiveVendorContractTypeResponse.prototype.hasContracttype = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DownloadP2PFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DownloadP2PFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DownloadP2PFileRequest.displayName = 'proto.cbv1.DownloadP2PFileRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DownloadP2PFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DownloadP2PFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DownloadP2PFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DownloadP2PFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DownloadP2PFileRequest}
 */
proto.cbv1.DownloadP2PFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DownloadP2PFileRequest;
  return proto.cbv1.DownloadP2PFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DownloadP2PFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DownloadP2PFileRequest}
 */
proto.cbv1.DownloadP2PFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFileId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DownloadP2PFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DownloadP2PFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DownloadP2PFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DownloadP2PFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 file_id = 1;
 * @return {number}
 */
proto.cbv1.DownloadP2PFileRequest.prototype.getFileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.DownloadP2PFileRequest.prototype.setFileId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DownloadP2PFileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DownloadP2PFileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DownloadP2PFileResponse.displayName = 'proto.cbv1.DownloadP2PFileResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DownloadP2PFileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DownloadP2PFileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DownloadP2PFileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DownloadP2PFileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, false),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DownloadP2PFileResponse}
 */
proto.cbv1.DownloadP2PFileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DownloadP2PFileResponse;
  return proto.cbv1.DownloadP2PFileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DownloadP2PFileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DownloadP2PFileResponse}
 */
proto.cbv1.DownloadP2PFileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DownloadP2PFileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DownloadP2PFileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DownloadP2PFileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DownloadP2PFileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.cbv1.DownloadP2PFileResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.DownloadP2PFileResponse.prototype.setUrl = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool status = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.DownloadP2PFileResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.DownloadP2PFileResponse.prototype.setStatus = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.cbv1.DownloadP2PFileResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.DownloadP2PFileResponse.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DownloadFuelCostFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DownloadFuelCostFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DownloadFuelCostFileRequest.displayName = 'proto.cbv1.DownloadFuelCostFileRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DownloadFuelCostFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DownloadFuelCostFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DownloadFuelCostFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DownloadFuelCostFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DownloadFuelCostFileRequest}
 */
proto.cbv1.DownloadFuelCostFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DownloadFuelCostFileRequest;
  return proto.cbv1.DownloadFuelCostFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DownloadFuelCostFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DownloadFuelCostFileRequest}
 */
proto.cbv1.DownloadFuelCostFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DownloadFuelCostFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DownloadFuelCostFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DownloadFuelCostFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DownloadFuelCostFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DownloadFuelCostFileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DownloadFuelCostFileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DownloadFuelCostFileResponse.displayName = 'proto.cbv1.DownloadFuelCostFileResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DownloadFuelCostFileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DownloadFuelCostFileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DownloadFuelCostFileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DownloadFuelCostFileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, false),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DownloadFuelCostFileResponse}
 */
proto.cbv1.DownloadFuelCostFileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DownloadFuelCostFileResponse;
  return proto.cbv1.DownloadFuelCostFileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DownloadFuelCostFileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DownloadFuelCostFileResponse}
 */
proto.cbv1.DownloadFuelCostFileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DownloadFuelCostFileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DownloadFuelCostFileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DownloadFuelCostFileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DownloadFuelCostFileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.cbv1.DownloadFuelCostFileResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.DownloadFuelCostFileResponse.prototype.setUrl = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool status = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.DownloadFuelCostFileResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.DownloadFuelCostFileResponse.prototype.setStatus = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.cbv1.DownloadFuelCostFileResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.DownloadFuelCostFileResponse.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


goog.object.extend(exports, proto.cbv1);
