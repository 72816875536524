import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { SelectPayloadTypeComponent } from '~common/select-payload-type/select-payload-type.component';
import { SelectPayloadUnitsComponent } from '~common/select-payload-units/select-payload-units.component';
import { SmartDropdownComponent } from '~common/smart-dropdown/smart-dropdown.component';
import { CreatePayloadRequest } from '~proto/payload/payload_api_pb';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { trackById } from '~utilities/trackById';
var OrderDetailsEditPayloadComponent = /** @class */ (function () {
    function OrderDetailsEditPayloadComponent(payloadService, matDialog) {
        this.payloadService = payloadService;
        this.matDialog = matDialog;
        this.selected = new EventEmitter();
        this.trackById = trackById;
        this.textChanges$$ = new BehaviorSubject(null);
    }
    OrderDetailsEditPayloadComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searching$ = this.payloadService.searching$;
        var searchResults$ = combineLatest([
            this.payloadService.currentSitePayloads$,
            this.textChanges$$.pipe(debounceTime(200)).pipe(switchMap(function (searchText) {
                if (searchText && searchText.length >= 2) {
                    return _this.payloadService.searchPayloads$(searchText);
                }
                return of([]);
            })),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), payloadGroups = _b[0], searchResults = _b[1];
            if (!payloadGroups) {
                return searchResults;
            }
            var payloads = payloadGroups.reduce(function (arr, group) { return tslib_1.__spread(arr, group.payloadsList); }, []);
            var payloadMap = idArrayToRecord(payloads);
            return searchResults.filter(function (payload) { return !payloadMap[payload.id]; });
        }));
        this.payloads$ = combineLatest([
            this.payloadService.currentSitePayloads$.pipe(map(function (payloads) {
                var allPayloads = payloads.reduce(function (arr, group) { return tslib_1.__spread(arr, group.payloadsList); }, []);
                var pinned = [];
                var recent = [];
                allPayloads.forEach(function (payload) {
                    if (payload.favorited) {
                        pinned.push(payload);
                    }
                    else {
                        recent.push(payload);
                    }
                });
                return [
                    {
                        name: 'Pinned',
                        payloadsList: pinned,
                    },
                    {
                        name: 'Recent',
                        payloadsList: recent,
                    },
                ];
            })),
            this.textChanges$$.pipe(debounceTime(100), startWith('')),
            searchResults$,
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 3), items = _b[0], text = _b[1], searchResults = _b[2];
            if (!items) {
                return [];
            }
            if (!text || text === '') {
                var filteredItems = items.filter(function (item) { return item.payloadsList.length > 0; });
                _this.topResult =
                    filteredItems.length && filteredItems[0].payloadsList.length ? filteredItems[0].payloadsList[0] : null;
                return filteredItems;
            }
            var cleanedItems = items.map(function (item) { return (tslib_1.__assign({}, item, { payloadsList: item.payloadsList.filter(function (entry) {
                    return entry.name.toLocaleLowerCase().includes(text.toLocaleLowerCase());
                }) })); });
            var mergedGroups = tslib_1.__spread([
                {
                    name: 'search results',
                    payloadsList: searchResults,
                }
            ], cleanedItems);
            var filtered = mergedGroups.filter(function (item) { return item.payloadsList.length > 0; });
            if (filtered.length && filtered[0].payloadsList.length) {
                _this.topResult = filtered[0].payloadsList[0];
            }
            else {
                _this.topResult = null;
            }
            return filtered;
        }));
    };
    OrderDetailsEditPayloadComponent.prototype.inputTextChange = function (event) {
        this.textChanges$$.next(event.trim());
    };
    OrderDetailsEditPayloadComponent.prototype.togglePin = function (event, payloadId, isFavorited) {
        event.stopPropagation();
        this.payloadService.updatePayloadFavorites(payloadId, isFavorited);
    };
    OrderDetailsEditPayloadComponent.prototype.selectPayload = function (payload, isNew) {
        if (isNew === void 0) { isNew = false; }
        this.selected.emit({ payload: payload });
        this.smartDropdown.close();
    };
    OrderDetailsEditPayloadComponent.prototype.createNewPayload = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var createPayloadRequest, unitsDialog, unit, payloadTypeDialog, payloadType;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        createPayloadRequest = new CreatePayloadRequest();
                        createPayloadRequest.setName(this.textChanges$$.value);
                        unitsDialog = this.matDialog.open(SelectPayloadUnitsComponent);
                        unitsDialog.componentInstance.payloadName = createPayloadRequest.getName();
                        return [4 /*yield*/, unitsDialog.afterClosed().toPromise()];
                    case 1:
                        unit = _a.sent();
                        if (!unit) {
                            return [2 /*return*/];
                        }
                        createPayloadRequest.setUnitId(unit.id);
                        payloadTypeDialog = this.matDialog.open(SelectPayloadTypeComponent);
                        payloadTypeDialog.componentInstance.payloadName = createPayloadRequest.getName();
                        return [4 /*yield*/, payloadTypeDialog.afterClosed().toPromise()];
                    case 2:
                        payloadType = _a.sent();
                        if (!payloadType) {
                            return [2 /*return*/];
                        }
                        createPayloadRequest.setPayloadTypeId(payloadType.id);
                        this.payloadService.createPayload$(createPayloadRequest).subscribe(function (payload) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                if (!payload) {
                                    return [2 /*return*/];
                                }
                                this.selectPayload(payload, true);
                                return [2 /*return*/];
                            });
                        }); });
                        return [2 /*return*/];
                }
            });
        });
    };
    OrderDetailsEditPayloadComponent.prototype.enterKeyPressed = function () {
        if (this.topResult) {
            this.selectPayload(this.topResult);
        }
        else {
            this.createNewPayload();
        }
    };
    return OrderDetailsEditPayloadComponent;
}());
export { OrderDetailsEditPayloadComponent };
