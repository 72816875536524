<div class="sa-flex-password-container">
  <div class="update-password">
    <img class="shale-logo" src="/assets/icons/shale-logo.svg" />
    <div class="back-wrap">
      <i class="material-icons">arrow_back</i><a [routerLink]="['/', 'login']">Back to login</a>
    </div>
    <div class="form-wrap">
      <form 
        (ngSubmit)="updatePassword()"
        [formGroup]="updatePasswordForm"
      >
        <h2>Update Password</h2>
        <mat-form-field>
          <input 
            type="password" 
            matInput 
            [formControl]="updatePasswordForm.controls['password']" 
            minlength="6"
            placeholder="new password"
            [type]="hide ? 'password':'text'" />
            <i class="material-icons" matSuffix (click)="hide = !hide">
              {{ hide ? 'visibility' : 'visibility_off' }}
            </i>
          <mat-error *ngIf="updatePasswordForm.controls['password'].hasError('required')">
            Password is required
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input 
            type="password" 
            matInput 
            [formControl]="updatePasswordForm.controls['confirmPassword']"
            minlength="6"
            placeholder="confirm password" 
            [type]="hide1 ? 'password':'text'" />
            <i class="material-icons" matSuffix (click)="hide1 = !hide1">
              {{ hide1 ? 'visibility' : 'visibility_off' }}
            </i>
          <mat-error *ngIf="updatePasswordForm.controls['confirmPassword'].hasError('required')">
            Password is required
          </mat-error>
          <mat-error
            *ngIf="
            updatePasswordForm.controls['confirmPassword'].hasError('diff') &&
              !updatePasswordForm.controls['confirmPassword'].hasError('required')
            "
          >
            Both passwords are not same.
          </mat-error>
        </mat-form-field>
        <div class="button-wrap">
          <button mat-raised-button color="primary">Update Password</button>
        </div>
      </form>
    </div>
  </div>
</div>
