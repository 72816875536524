import { Injectable } from '@angular/core';
import {
  ListPayloadTypesRequest,
  ListPayloadTypesResponse,
  ListUnitsRequest,
  ListUnitsResponse,
} from '~proto/payload/payload_api_pb';
import { PayloadAPI } from '~proto/payload/payload_api_pb_service';
import { PayloadType, Unit } from '~proto/payload/payload_pb';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { GrpcService } from './grpc.service';

const PAYLOAD_TYPE_KEY = 'payloadTypes_CONSTANTS';
const UNITS_KEY = 'units_CONSTANTS';

@Injectable({
  providedIn: 'root',
})
export class ConstantsService {
  private _payloadTypes: Record<string, PayloadType.AsObject>;
  private _units: Record<string, Unit.AsObject>;

  public set payloadTypes(types: Record<string, PayloadType.AsObject>) {
    localStorage.setItem(PAYLOAD_TYPE_KEY, JSON.stringify(types));
    this._payloadTypes = types;
  }
  public get payloadTypes(): Record<string, PayloadType.AsObject> {
    return this._payloadTypes;
  }

  public set units(types: Record<string, Unit.AsObject>) {
    localStorage.setItem(UNITS_KEY, JSON.stringify(types));
    this._units = types;
  }
  public get units(): Record<string, Unit.AsObject> {
    return this._units;
  }

  public get unitsArray(): Unit.AsObject[] {
    return Object.values(this._units);
  }

  constructor(private grpcService: GrpcService) {
    const storagePayloadTypes = localStorage.getItem(PAYLOAD_TYPE_KEY);
    if (storagePayloadTypes) {
      this._payloadTypes = JSON.parse(storagePayloadTypes);
    }
    const storageUnits = localStorage.getItem(UNITS_KEY);
    if (storageUnits) {
      this._units = JSON.parse(storageUnits);
    }
    this.loadPayloadTypes();
    this.loadUnits();
  }

  private loadPayloadTypes() {
    const request = new ListPayloadTypesRequest();
    this.grpcService.invoke$(PayloadAPI.ListPayloadTypes, request).subscribe((response: ListPayloadTypesResponse) => {
      this.payloadTypes = idArrayToRecord(response.toObject().payloadTypesList);
    });
  }

  private loadUnits() {
    const request = new ListUnitsRequest();
    this.grpcService.invoke$(PayloadAPI.ListUnits, request).subscribe((response: ListUnitsResponse) => {
      this.units = idArrayToRecord(response.toObject().unitsList);
    });
  }

  public getAbbreviationFromUnit(unit: string) {
    if (this.unitsArray.length) {
      const tempUnit = this.unitsArray.find((selectedUnit) => selectedUnit.name === unit);
      if (tempUnit) {
        return tempUnit.abbreviation;
      }
    }
    return 'unit';
  }

  public sortDataValues(dataValues: any) {
    return dataValues.sort((valA: any, valB: any) => {
      valA = valA.name.toLowerCase();
      valB = valB.name.toLowerCase();
      return valA > valB ? 1 : valA < valB ? -1 : 0;
    });
  }
}
