import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { SmartDropdownComponent } from '~common/smart-dropdown/smart-dropdown.component';
import { trackById } from '~utilities/trackById';
import { CreateOrderRecentsService } from '../../services/create-order-recents.service';

interface GroupedPurchaseOrderNames {
  name: string;
  purchaseOrderNames: string[];
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-create-order-purchase-order-selector',
  styleUrls: ['./create-order-purchase-order-selector.component.scss'],
  templateUrl: './create-order-purchase-order-selector.component.html',
})
export class CreateOrderPurchaseOrderSelectorComponent implements OnInit {
  private topResult: string;
  private additionalRecentPOs$$ = new BehaviorSubject<string[]>([]);
  @Output() public selected = new EventEmitter<{ purchaseOrderName: string }>();
  @ViewChild('smartDropdown', { static: true }) private smartDropdown: SmartDropdownComponent;
  @Input() public purchaseOrderName: string;
  @Input() public invalid = false;
  @Input() public set additionalRecentPOs(POs: string[]) {
    if (POs) {
      this.additionalRecentPOs$$.next(POs);
    }
  }
  public textChanges$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public purchaseOrderNames$: Observable<GroupedPurchaseOrderNames[]>;
  public showCreatePOButton$: Observable<boolean>;
  public trackById = trackById;

  constructor(private recentService: CreateOrderRecentsService) {}

  public ngOnInit() {
    this.purchaseOrderNames$ = combineLatest([
      this.recentService.purchaseOrders$,
      this.textChanges$$,
      this.additionalRecentPOs$$,
    ]).pipe(
      map(([purchaseOrders, text, additionalRecentPOs]) => {
        if (!purchaseOrders) {
          return [];
        }
        const asArray = [
          {
            name: 'search results',
            purchaseOrderNames: purchaseOrders.searchResults,
          },
          {
            name: 'recently used',
            purchaseOrderNames: Array.from(
              new Set([...(purchaseOrders.recentlyUsedList || []), ...additionalRecentPOs]),
            ),
          },
          {
            name: 'frequently used',
            purchaseOrderNames: purchaseOrders.frequentlyUsedList,
          },
        ];
        if (!text || text === '') {
          return asArray.filter((group) => group.purchaseOrderNames && group.purchaseOrderNames.length > 0);
        }
        return asArray
          .map((group) => ({
            name: group.name,
            purchaseOrderNames: (group.purchaseOrderNames || []).filter((s) => s.includes(text)),
          }))
          .filter((group) => group.purchaseOrderNames.length > 0);
      }),
    );
    this.showCreatePOButton$ = combineLatest([
      this.recentService.purchaseOrders$,
      this.textChanges$$.pipe(startWith(null as string)),
    ]).pipe(
      map(([purchaseOrder, text]) => {
        if (!text || !text.length) {
          return false;
        }

        return (
          (purchaseOrder.frequentlyUsedList || []).every((po) => po !== text) &&
          (purchaseOrder.recentlyUsedList || []).every((po) => po !== text) &&
          (purchaseOrder.searchResults || []).every((po) => po !== text)
        );
      }),
    );

    this.textChanges$$.pipe(debounceTime(250)).subscribe((text: string) => {
      this.recentService.searchPurchaseOrders(text);
    });
  }

  public selectPurchaseOrder(purchaseOrderName: string) {
    this.selected.emit({ purchaseOrderName });
    this.smartDropdown.close();
  }

  public addPurchaseOrderName() {
    if (this.textChanges$$.value && this.textChanges$$.value !== '') {
      this.selectPurchaseOrder(this.textChanges$$.value);
    }
  }

  public inputTextChange(event: string) {
    this.textChanges$$.next(event.trim());
  }

  public enterKeyPressed() {
    if (this.topResult) {
      this.selectPurchaseOrder(this.topResult);
    } else {
      this.addPurchaseOrderName();
    }
  }

  public trackByName(_number: number, name: string): string {
    return name;
  }
}
