import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, finalize, map, startWith } from 'rxjs/operators';
import { ConfirmDialogComponent } from '~common/confirm-dialog/confirm-dialog.component';
import { BusinessLineService } from '~lmo/services/business-line.service';
import { BusinessLine } from '~proto/businessline/businessline_pb';
import { RoleService } from '~services/role.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-lmo-business-line-management',
  styleUrls: ['./lmo-business-line-management.component.scss'],
  templateUrl: './lmo-business-line-management.component.html',
})
export class LmoBusinessLineManagementComponent implements OnInit {
  public businessLines$: Observable<MatTableDataSource<BusinessLine.AsObject>>;
  public businessLineSearch: FormControl = new FormControl();
  public networkActive$$ = new BehaviorSubject<boolean>(false);
  public displayedColumns = ['id', 'name', 'user_count', 'cost_center_count', 'actions'];
  public isBusinessLineCostCenterRelationEnabled$$ = new BehaviorSubject<Boolean>(false);

  constructor(
    private businessLineService: BusinessLineService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private roleService: RoleService,
  ) {}

  public ngOnInit() {
    this.businessLines$ = combineLatest([
      this.businessLineService.businessLines$,
      this.businessLineSearch.valueChanges.pipe(
        startWith(''),
        debounceTime(200),
      ),
    ]).pipe(
      map(([businessLines, searchTerm]) => {
        if (!searchTerm || searchTerm === '') {
          return businessLines;
        }
        return businessLines.filter((businessLine) =>
          businessLine.name.toLowerCase().includes(searchTerm.toLowerCase()),
        );
      }),
      map((businessLines) => {
        return new MatTableDataSource(businessLines);
      }),
    );
    this.checkIsBusinessLineCostCenterRelationEnabled();
  }

  public trackByBLId(_index: number, businessLine: BusinessLine.AsObject) {
    return businessLine.id;
  }

  public async editBusinessLine(row) {
    await this.router.navigate(['/lmo/', 'business-lines', row.id, 'edit']);
  }

  private checkIsBusinessLineCostCenterRelationEnabled() {
    this.roleService.isBusinessLineCostCenterRelationEnabled$.subscribe((isBLCCRelationEnabled: Boolean) => {
      this.isBusinessLineCostCenterRelationEnabled$$.next(isBLCCRelationEnabled);
    });
  }

  public archiveBusinessLine(businessLine: BusinessLine.AsObject, event) {
    event.stopPropagation();
    if (!businessLine.canbearchived) {
      this.snackBar.open('Business Line can not be archived as users are attached to it', null, {
        duration: 2000,
      });
      return
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        acceptButtonText: 'Archive',
        declineButtonText: 'Cancel',
        title: `Are you sure you want to archive ${businessLine.name} ?`,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(filter((data) => !!data))
      .subscribe((result) => {
        const id = businessLine.id;
        if (!id) {
          return;
        }
        this.networkActive$$.next(true);
        this.businessLineService
          .archiveBusinessLine$(id)
          .pipe(
            finalize(() => {
              this.networkActive$$.next(false);
            }),
          )
          .subscribe(
            (response) => {
              this.snackBar.open('Business Line archived successfully', null, {
                duration: 2000,
              });
            },
            (err) => {
              // todo: check error message
              const error = err.error;
              this.snackBar.open('Error:' + (error.Message ? error.Message : error), null, {
                duration: 5000,
              });
            },
          );
      });
  }
}
