import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-smart-search-dropdown',
  styleUrls: ['./smart-search-dropdown.component.scss'],
  templateUrl: './smart-search-dropdown.component.html',
})
export class SmartSearchDropdownComponent implements OnInit, AfterViewInit {
  private isTransformed = false;
  private stopTransition$ = new Subject();
  public formControl = new FormControl();
  public isActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @ViewChild('overlay', { static: true }) private overlay: ElementRef;
  @ViewChild('input', { static: true }) private input: ElementRef;
  @Input() public label = '';
  @Input() public selectionTemplate: TemplateRef<any>;
  @Input() public emptyTextTemplate: TemplateRef<any>;
  @Input() public items: any[];
  @Input() public emptyText = 'No Results';
  @Input() public triggerText = '';
  @Input() public invalid = false;
  @Input() public showCloseButton = true;
  @Input() public startActive = false;
  @Input() public tab = '';
  @Input() public isSearching$: Observable<boolean>;
  @Input() public subHeaderSearch = false;
  @Output() public textChange = new EventEmitter();
  @Output() public enterKey = new EventEmitter();

  constructor(private cd: ChangeDetectorRef) {}

  public ngOnInit() {
    this.formControl.valueChanges.subscribe((value) => {
      this.textChange.next(value);
    });
  }

  public ngAfterViewInit(): void {
    if (this.startActive) {
      timer(200)
        .pipe(take(1))
        .subscribe(() => {
          this.focused();
        });
    }
  }

  public focused() {
    if (this.isTransformed) {
      return;
    }
    this.isTransformed = true;
    this.isActive$.next(true);
    this.cd.detectChanges();
    this.input.nativeElement.focus();
  }

  public close() {
    this.isTransformed = false;
    this.stopTransition$.next(null);
    this.input.nativeElement.parentElement.setAttribute('style', '');
    this.overlay.nativeElement.setAttribute('style', ``);
    this.formControl.setValue('');
    this.items = null;
    this.isActive$.next(false);
    this.input.nativeElement.blur();
  }

  public onEnterKey($event: KeyboardEvent) {
    $event.preventDefault();
    this.enterKey.next(null);
  }

  @HostListener('document:keydown.escape', ['$event'])
  public onEscapeHandler(event: KeyboardEvent) {
    if (this.isTransformed) {
      this.close();
    }
  }

  public trackByAny(index: number, obj: { id?: number; name?: string }): string | number {
    return obj.id ? obj.id : obj.name ? obj.name : index;
  }
}
