import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { ChatbotService, Message } from 'src/app/chatbot.service';

@Component({
  selector: 'ct-chat-bot',
  styleUrls: ['./chat-bot.component.scss'],
  templateUrl: './chat-bot.component.html',
})
export class ChatBotComponent implements OnInit {
  @ViewChild('chatBotInput', { static: false }) public input: ElementRef<HTMLInputElement>;
  @ViewChild('scroller', { static: false }) private messages: ElementRef;
  public chat$ = new Observable<Message[]>();
  public formControl = new FormControl();
  public hide = true;
  public onRight$: Observable<boolean>;

  constructor(private chatbot: ChatbotService) {}

  public ngOnInit() {
    this.chat$ = this.chatbot.chat$.pipe(
      distinctUntilChanged(),
      tap(async () => {
        await new Promise((resolve) => {
          setTimeout(resolve, 200);
        });
        this.scrollToBottom();
      }),
    );
    this.onRight$ = this.chatbot.onRight$;
  }

  public toggleHide() {
    this.hide = !this.hide;
    if (!this.hide && this.input && this.input.nativeElement && this.input.nativeElement.focus) {
      this.input.nativeElement.focus();
    }
  }

  public sendMessage() {
    this.chatbot.sendMessage(this.formControl.value);
    this.formControl.setValue('');
  }

  private scrollToBottom(): void {
    try {
      this.messages.nativeElement.scrollTop = this.messages.nativeElement.scrollHeight;
    } catch (err) {}
  }

  @HostListener('document:keydown.escape', ['$event'])
  public escapeKey(event: KeyboardEvent) {
    if (
      (event.target as HTMLElement).tagName !== 'BODY' &&
      (event.target as HTMLElement) !== this.input.nativeElement
    ) {
      return;
    }
    if (!this.hide) {
      this.hide = true;
      if (this.input.nativeElement) {
        this.input.nativeElement.blur();
      }
    }
  }

  public trackBy(_index: number, message: Message) {
    return message.message;
  }
}
