import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { filter, finalize, take, tap } from 'rxjs/operators';
import { StockQuantityRequest, UpdateStocksQuantityRequest } from '~proto/site/site_api_pb';
var StockUpdateComponent = /** @class */ (function () {
    function StockUpdateComponent(siteService, router, activatedRoute) {
        this.siteService = siteService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.networkActive$ = new BehaviorSubject(false);
        this.formArray = new FormArray([]);
    }
    StockUpdateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentSite$ = this.siteService.currentSite$.pipe(filter(function (site) { return !!site; }), take(1), tap(function (site) {
            var stocks = tslib_1.__spread(site.stockReferencesList);
            stocks.sort(function (a, b) { return a.payload.name.toLocaleLowerCase().localeCompare(b.payload.name.toLocaleLowerCase()); });
            stocks.forEach(function (stock) {
                var group = new FormGroup({
                    id: new FormControl(stock.id),
                    payload: new FormControl(stock.payload),
                    quantity: new FormControl(stock.quantity),
                });
                _this.formArray.push(group);
            });
        }));
    };
    StockUpdateComponent.prototype.updateCurrentStocks = function () {
        var _this = this;
        if (this.formArray.invalid) {
            return;
        }
        this.networkActive$.next(true);
        var value = this.formArray.value;
        var request = new UpdateStocksQuantityRequest();
        value.forEach(function (formValue) {
            var update = new StockQuantityRequest();
            update.setStockId(formValue.id);
            update.setQuantity(formValue.quantity);
            request.addStockQuantityRequests(update);
        });
        this.siteService
            .updateStockQuanities$(request)
            .pipe(finalize(function () { return _this.networkActive$.next(false); }))
            .subscribe(function () {
            _this.router.navigate(['../'], { relativeTo: _this.activatedRoute });
        });
    };
    StockUpdateComponent.prototype.trackByIndex = function (index) {
        return index;
    };
    return StockUpdateComponent;
}());
export { StockUpdateComponent };
