import { Component, Injectable, OnInit } from '@angular/core';
import { CalendarDayViewComponent, CalendarUtils } from 'angular-calendar';
import { DayView, GetDayViewArgs } from 'calendar-utils';

const EVENT_WIDTH = 200;

// extend the interface to add the array of users
interface DayViewScheduler extends DayView {
  drivers: number[];
}

@Injectable()
export class DayViewSchedulerCalendarUtils extends CalendarUtils {
  public getDayView(args: GetDayViewArgs): DayViewScheduler {
    const view: DayViewScheduler = {
      ...super.getDayView(args),
      drivers: [],
    };
    view.events.forEach(({ event }) => {
      if (!view.drivers.includes(event.meta.driverId)) {
        view.drivers.push(event.meta.driverId);
      }
    });
    // sort the users by their names
    view.drivers.sort((driver1, driver2) => driver1 - driver2);
    view.events = view.events.map((dayViewEvent) => {
      const index = view.drivers.indexOf(dayViewEvent.event.meta.driverId);
      dayViewEvent.left = index * EVENT_WIDTH; // change the column of the event
      dayViewEvent.width = EVENT_WIDTH;
      return dayViewEvent;
    });
    view.width = view.drivers.length * EVENT_WIDTH;
    return view;
  }
}

@Component({
  providers: [
    {
      provide: CalendarUtils,
      useClass: DayViewSchedulerCalendarUtils,
    },
  ],
  selector: 'ct-lmo-orders-calendar-day-view-calendar',
  styleUrls: ['./lmo-orders-calendar-day-view-calendar.component.scss'],
  templateUrl: './lmo-orders-calendar-day-view-calendar.component.html',
})
export class LmoOrdersCalendarDayViewCalendarComponent extends CalendarDayViewComponent implements OnInit {
  public view: DayViewScheduler;
}
