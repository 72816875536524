import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CalendarEvent } from 'calendar-utils';
import { flatten, sort } from 'remeda';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrdersService } from 'src/app/lmo/services/orders.service';
import { FleetManagerDriver, FleetManagerDriverScheduleOrder } from '~proto/order/order_pb';
import { RouterStateService } from '~services/router-state.service';
import { getFirstTaskWithPayload } from '~utilities/getFirstTask';
import * as fromRouterConstants from '../../../app-routing.constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-lmo-orders-calendar-day-view',
  styleUrls: ['./lmo-orders-calendar-day-view.component.scss'],
  templateUrl: './lmo-orders-calendar-day-view.component.html',
})
export class LmoOrdersCalendarDayViewComponent implements OnInit {
  public viewDate: Date;
  public events$: Observable<CalendarEvent[]>;
  constructor(private orderService: OrdersService, private routerState: RouterStateService) {}

  public ngOnInit() {
    this.events$ = this.orderService.scheduledDrivers$.pipe(map((drivers) => convertDriversToEvents(drivers)));

    combineLatest([
      this.routerState.listenForParamChange$(fromRouterConstants.YEAR),
      this.routerState.listenForParamChange$(fromRouterConstants.MONTH),
      this.routerState.listenForParamChange$(fromRouterConstants.DAY),
    ]).subscribe(([year, month, date]) => {
      this.viewDate = new Date(+year, +month - 1, +date);
    });
  }
}

const colors: string[] = [
  '#6d6683',
  '#cd3e2a',
  '#8a43d5',
  '#497e34',
  '#512993',
  '#886e2f',
  '#bf38a0',
  '#3a4828',
  '#6068c0',
  '#974d34',
  '#3d265f',
  '#c4385f',
  '#497671',
  '#924578',
  '#2b2939',
  '#5b272b',
];

function convertDriversToEvents(fakeDrivers: FleetManagerDriver.AsObject[]): CalendarEvent[] {
  return flatten(fakeDrivers.map(convertDriverToEvent));
}

function convertDriverToEvent(fakeDriver: FleetManagerDriver.AsObject): CalendarEvent[] {
  return sort(fakeDriver.ordersList, sortOrders).map((order, index) => {
    const firstTask = getFirstTaskWithPayload(order.taskSummariesList);
    const billingTask = order.taskSummariesList.find((task) => task.isBillable);
    return {
      color: {
        primary: colors[firstTask.payload ? firstTask.payload.type.id % 16 : 0],
        secondary: colors[firstTask.payload ? firstTask.payload.type.id % 16 : 0],
      },
      end: new Date(order.endUnix * 1000),
      meta: {
        driverId: fakeDriver.tempId,
        endSiteName: billingTask.site.name,
        orderNumber: order.id,
        startSiteName: firstTask.site.name,
      },
      start: new Date(order.startUnix * 1000),
      title: firstTask.payload.name || 'No Payload',
    };
  });
}

function sortOrders(a: FleetManagerDriverScheduleOrder.AsObject, b: FleetManagerDriverScheduleOrder.AsObject): number {
  return a.startUnix - b.startUnix;
}
