import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';
import { UpdateTrailersRequest } from '~proto/trailer/trailer_api_pb';
import { TrailerDetails } from '~proto/trailer/trailer_pb';
import { TrailerService } from '~services/trailer.service';
import { trackById } from '~utilities/trackById';

interface FormValue {
  id: number;
  model: string;
  name: string;
}

@Component({
  selector: 'ct-edit-trailer',
  styleUrls: ['./edit-trailer.component.scss'],
  templateUrl: './edit-trailer.component.html',
})
export class EditTrailerComponent implements OnInit {
  public networkActive$ = new BehaviorSubject(false);
  public formGroup: FormGroup;
  public trackById = trackById;

  constructor(private trailerService: TrailerService, private fb: FormBuilder, private router: Router) {}

  private formValue(): FormValue {
    return this.formGroup.value;
  }

  public ngOnInit() {
    this.trailerService.currentTrailer$
      .pipe(
        filter(Boolean),
        take(1),
      )
      .subscribe((trailer: TrailerDetails.AsObject) => {
        this.formGroup = this.fb.group({
          id: [trailer.id, [Validators.required]],
          model: [trailer.model ? trailer.model.name : '', [Validators.required]],
          name: [trailer.name, [Validators.required]],
        });
      });
  }

  public editTrailer() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    this.networkActive$.next(true);
    const formValue = this.formValue();
    const editTrailerReq = new UpdateTrailersRequest();
    editTrailerReq.setId(formValue.id);
    editTrailerReq.setName(formValue.name);
    editTrailerReq.setModelName(formValue.model);
    this.trailerService
      .updateTrailer(editTrailerReq)
      .pipe(
        finalize(() => {
          this.networkActive$.next(false);
        }),
      )
      .subscribe((trailers) => {
        this.router.navigate(['/', 'trailers']);
      });
  }
}
