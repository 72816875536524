import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/browser';
import * as firebase from 'firebase/app';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, race, timer } from 'rxjs';
import { filter, map, mapTo, share, take, tap } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { AccountType, AccountTypeMap, RoleMap } from '~proto/types/types_pb';
import { Claims } from '~proto/user/user_pb';
import { User } from '../models/user.model';
import { LocalStorageService } from './local-storage.service';

export interface ClaimsWithFirebaseStuff extends Claims.AsObject {
  auth: {
    roles: number[];
    account: {
      id: number;
      name: string;
      type: number;
    };
  }[];
  iss: string;
  aud: string;
  auth_time: number;
  user_id: string;
  sub: string;
  iat: number;
  exp: number;
  email: string;
  email_verified: boolean;
  firebase: {
    identities: {
      email: string[];
    };
    sign_in_provider: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _accountName$ = this.afAuth.idTokenResult.pipe(
    map((token) => {
      return token.claims.account_session_name;
    }),
  );
  private _isShaleAppsUser$ = this.afAuth.idTokenResult.pipe(
    map((token) => token && token.claims && token.claims.email && token.claims.email.includes('@shaleapps.com')),
  );
  private isLMOOnce$ = this.isAccountType$(AccountType.ACCOUNT_TYPE_LMO);
  private isTruckingVendorOnce$ = this.isAccountType$(AccountType.ACCOUNT_TYPE_TRUCKING_VENDOR);
  private isAdminOnce$ = this.isAccountType$(AccountType.ACCOUNT_TYPE_SHALEAPPS);
  private _user: firebase.User;
  private isLoggedIn$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private afAuth: AngularFireAuth,
    private localStorage: LocalStorageService,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private logger: NGXLogger,
    private http: HttpClient,
  ) {
    this.afAuth.authState.subscribe((user) => {
      this._user = user;
      // may have to add user contract from local storage
      if (user) {
        return user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult && idTokenResult.token && idTokenResult.token !== '') {
            this.sentryIdentify();
            this.logger.log(idTokenResult);
          } else {
            console.error('this idTokenResult did not have a token', idTokenResult);
          }
        });
      }
    });
  }

  get authenticated(): boolean {
    return this._user !== null && this._user !== undefined;
  }

  get user(): firebase.User {
    return this._user;
  }

  public get idToken$() {
    return this.afAuth.idTokenResult;
  }

  public get jwt$(): Observable<string> {
    return this.afAuth.idToken;
  }

  public get isLoggedIn$(): Observable<boolean> {
    return this.isLoggedIn$$.asObservable().pipe(share());
  }

  public get userEmail$(): Observable<string> {
    return this.afAuth.idTokenResult.pipe(
      filter((token) => !!token),
      map((token) => token.claims.email),
    );
  }

  public get accountName$(): Observable<string> {
    return this._accountName$;
  }

  public login(username: string, password: string) {
    console.log('Begin Login:', username);
    const isE2ETesting = JSON.parse(localStorage.getItem('e2e')) === true;
    this.afAuth.auth
      .setPersistence(isE2ETesting ? firebase.auth.Auth.Persistence.NONE : firebase.auth.Auth.Persistence.LOCAL)
      .then(() => this.afAuth.auth.signInWithEmailAndPassword(username, password))
      .then((fbUser) => {
        console.log('Login Successful', username);
        this.isLoggedIn$$.next(true);
        this.storeUserInCache({
          email: fbUser.user.email,
          id: fbUser.user.uid,
          name: fbUser.user.displayName,
        });
      })
      .catch((error) => {
        console.error('Error logging in', error);
        this.matSnackBar.open('Invalid username or password', null, { panelClass: 'snackbar-error', duration: 2000 });
      });
  }

  public loginWithCustomToken(token: string): Promise<void> {
    return this.afAuth.auth
      .signInWithCustomToken(token)
      .then((fbUser) => {
        this.isLoggedIn$$.next(true);
        this.storeUserInCache({
          email: fbUser.user.email,
          id: fbUser.user.uid,
          name: fbUser.user.displayName,
        });
      })
      .catch((error) => {
        this.matSnackBar.open('Invalid username or password', null, { panelClass: 'snackbar-error', duration: 2000 });
      });
  }

  public ssoURL$(email: string): Observable<string> {
    return (this.http.post(environment.api + '/sso_url', { email: email }) as Observable<string>).pipe(
      tap(console.log),
    );
  }

  public logout(): void {
    this.afAuth.auth.signOut();
    this.removeUserFromCache();
    this.isLoggedIn$$.next(false);
    this.router.navigateByUrl('/login');
    Sentry.setUser(null);
  }

  public async redirectIfLoggedIn(): Promise<void> {
    const idToken = await this.afAuth.idTokenResult.pipe(take(1)).toPromise();
    if (!idToken) {
      return;
    }
    const expirationTime = new Date(idToken.expirationTime);
    if (expirationTime < new Date()) {
      return;
    }

    if (isAccountType(AccountType.ACCOUNT_TYPE_TRUCKING_VENDOR, idToken.claims.account_session_type)) {
      this.router.navigate(['/', 'dispatcher']);
    } else if (isAccountType(AccountType.ACCOUNT_TYPE_LMO, idToken.claims.account_session_type)) {
      this.router.navigate(['/', 'lmo']);
    } else if (isAccountType(AccountType.ACCOUNT_TYPE_SHALEAPPS, idToken.claims.account_session_type)) {
      this.router.navigate(['/', 'shaleapps']);
    } else {
      this.logger.error('Unknown account type', idToken);
      this.logout();
    }
  }

  public getUserFromCache(): Partial<User> {
    return this.localStorage.getItem('user');
  }

  public removeUserFromCache(): void {
    this.localStorage.removeItem('user');
  }

  public storeSSOEmailInCache(user: Partial<User>): void {
    this.localStorage.setItem('ssoEmail', user.email);
  }

  public getSSOEmailFromCache(): User {
    return this.localStorage.getItem('ssoEmail');
  }

  public async hasAccountRole(accountType: RoleMap[keyof RoleMap] | RoleMap[keyof RoleMap][]): Promise<boolean> {
    const idToken = await this.afAuth.idTokenResult.pipe(take(1)).toPromise();
    if (!idToken) {
      return false;
    }
    if (!idToken.claims.account_session) {
      return false;
    }
    return hasAccountRole(accountType, idToken.claims.role);
  }

  public isLMO$(): Observable<boolean> {
    return this.isLMOOnce$;
  }

  public isTruckingVendor$(): Observable<boolean> {
    return this.isTruckingVendorOnce$;
  }

  public isAdmin$(): Observable<boolean> {
    return this.isAdminOnce$;
  }

  public isAccountType$(
    accountType: AccountTypeMap[keyof AccountTypeMap] | AccountTypeMap[keyof AccountTypeMap][],
  ): Observable<boolean> {
    // return of(true);
    return this.afAuth.idTokenResult.pipe(
      map((idToken) => {
        if (!idToken) {
          return false;
        }
        if (!idToken.claims.account_session) {
          console.error('No claims on id token', idToken);
          return false;
        }
        return isAccountType(accountType, idToken.claims.account_session_type);
      }),
    );
  }

  public isShaleAppsUser$(): Observable<boolean> {
    return this._isShaleAppsUser$;
  }

  private async sentryIdentify() {
    const user: Sentry.User = {};
    const afUser = await this.afAuth.authState.pipe(take(1)).toPromise();
    const idTokenResult = await afUser.getIdTokenResult();
    const claims = idTokenResult.claims as ClaimsWithFirebaseStuff;
    user.email = claims.email;
    user.username = claims.name;
    user.id = claims.user_id;
    Sentry.setUser(user);
  }

  private storeUserInCache(user: Partial<User>): void {
    this.localStorage.setItem('user', user);
  }

  public async getAccountTypePrettyName(): Promise<'lmo' | 'trucking-vendor' | null> {
    return race<firebase.auth.IdTokenResult, firebase.auth.IdTokenResult>(
      this.afAuth.idTokenResult.pipe(filter((token) => !!token)),
      timer(1000).pipe(mapTo(null)),
    )
      .pipe(
        take(1),
        map((idToken) => {
          if (!idToken) {
            return null;
          }
          if (!idToken.claims.account_session) {
            return null;
          }
          if (idToken.claims.account_session_type === AccountType.ACCOUNT_TYPE_LMO) {
            return 'lmo';
          } else if (idToken.claims.account_session_type === AccountType.ACCOUNT_TYPE_TRUCKING_VENDOR) {
            return 'trucking-vendor';
          }
          return null;
        }),
      )
      .toPromise();
  }
}

function hasAccountRole(
  allowedRoles: RoleMap[keyof RoleMap] | RoleMap[keyof RoleMap][],
  userRoles: RoleMap[keyof RoleMap][],
): boolean {
  if (!userRoles || !Array.isArray(userRoles) || userRoles.length === 0) {
    return false;
  }
  if (Array.isArray(allowedRoles)) {
    return allowedRoles.some((role) => userRoles.includes(role));
  } else {
    return userRoles.includes(allowedRoles);
  }
}

function isAccountType(
  allowedAccountTypes: AccountTypeMap[keyof AccountTypeMap] | AccountTypeMap[keyof AccountTypeMap][],
  accountType: AccountTypeMap[keyof AccountTypeMap],
): boolean {
  if (!accountType) {
    return false;
  }
  if (Array.isArray(allowedAccountTypes)) {
    return allowedAccountTypes.some((type) => type === accountType);
  } else {
    return allowedAccountTypes === accountType;
  }
}
