import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, finalize, map, take } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { OrdersService } from 'src/app/lmo/services/orders.service';
import { PayloadDetails } from 'src/app/models/type-with-order.model';
import { ConfirmCancelComponent } from '~common/confirm-cancel/confirm-cancel.component';
import { Order, Task } from '~proto/order/order_pb';
import { TaskStatus, TaskType } from '~proto/types/types_pb';
import { getPayloadDetailsFromOrder } from '~utilities/groupOrders';
import { trackById } from '~utilities/trackById';
import { OrderConfirmCompleteComponent } from '../order-confirm-complete/order-confirm-complete.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-order-detail-in-progress',
  styleUrls: ['./order-detail-in-progress.component.scss'],
  templateUrl: './order-detail-in-progress.component.html',
})
export class OrderDetailInProgressComponent implements OnInit {
  public currentOrder$: Observable<Order.AsObject>;
  public inProgressTask: Task.AsObject;
  public payloadDetails$: Observable<PayloadDetails[]>;
  public networkActive$ = new BehaviorSubject<boolean>(false);
  public taskType = TaskType;
  public inProgressTaskEta: number;
  public taskStatus = TaskStatus;
  public trackById = trackById;

  public driverCertifications = [];

  constructor(
    private orderService: OrdersService,
    private router: Router,
    private siteService: JobSitesService,
    private matDialog: MatDialog,
  ) {}

  public ngOnInit() {
    this.currentOrder$ = this.orderService.currentOrder$;
    this.payloadDetails$ = this.currentOrder$.pipe(map(getPayloadDetailsFromOrder));
    this.currentOrder$.pipe(filter((currentOrder) => !!currentOrder)).subscribe((currentOrder) => {
      const inProgressTasks = currentOrder.tasksList.filter(
        (task) => task.status === TaskStatus.TASK_STATUS_IN_PROGRESS,
      );

      this.driverCertifications = currentOrder.driverCertificationsList.map((cert) => cert.name);

      if (inProgressTasks.length) {
        this.inProgressTask = inProgressTasks[0];
      } else {
        this.inProgressTask = currentOrder.tasksList.filter((task) => task.sequence === 1)[0];
      }
      this.inProgressTaskEta =
        this.inProgressTask.stats && this.inProgressTask.stats.driverEta
          ? moment()
              .add(this.inProgressTask.stats.driverEta, 'minutes')
              .unix() * 1000
          : null;
    });
  }

  public async confirmCancel(orderId: number) {
    const cancelDialog = this.matDialog.open(ConfirmCancelComponent);
    cancelDialog.componentInstance.name = `#${orderId}`;

    const confirmed: boolean = await cancelDialog.afterClosed().toPromise();
    if (!confirmed) {
      return;
    }
    this.cancelOrder(orderId);
  }

  public cancelOrder(orderId: number) {
    this.networkActive$.next(true);

    this.siteService.currentSite$.pipe(take(1)).subscribe((site) => {
      this.orderService
        .cancelInProgressOrder$(orderId)
        .pipe(finalize(() => this.networkActive$.next(false)))
        .subscribe(() => {
          this.router.navigate(['/', 'lmo', 'jobs', site.id, 'orders', 'in-progress', 'list']);
        });
    });
  }

  public taskClickEvent(isNotLastTask: boolean, taskId: number, orderId: number) {
    if (isNotLastTask) {
      this.manuallyCompleteTask(taskId);
    } else {
      this.confirmComplete(orderId);
    }
  }

  public async confirmComplete(orderId: number) {
    const confirmDialog = this.matDialog.open(OrderConfirmCompleteComponent);

    const confirmed: boolean = await confirmDialog.afterClosed().toPromise();
    if (!confirmed) {
      return;
    }
    this.completeOrder(orderId);
  }

  public completeOrder(orderId: number) {
    this.networkActive$.next(true);

    this.siteService.currentSite$.pipe(take(1)).subscribe((site) => {
      this.orderService
        .completeOrder$(orderId)
        .pipe(finalize(() => this.networkActive$.next(false)))
        .subscribe(() => {
          this.router.navigate(['/', 'lmo', 'jobs', site.id, 'orders', 'in-progress', 'list']);
        });
    });
  }

  public manuallyCompleteTask(taskId: number) {
    this.networkActive$.next(true);
    this.orderService
      .manuallyCompleteTask$(taskId)
      .pipe(finalize(() => this.networkActive$.next(false)))
      .subscribe(() => {});
  }

  public getTaskStatus(type: number, status: number) {
    switch (type) {
      case this.taskType.TASK_TYPE_PICKUP:
        if (status === this.taskStatus.TASK_STATUS_COMPLETED) {
          return 'Picked Up';
        }
        return 'Pick Up';
      case this.taskType.TASK_TYPE_DROPOFF:
        if (status === this.taskStatus.TASK_STATUS_COMPLETED) {
          return 'Dropped Off';
        }
        return 'Drop-Off';
      case this.taskType.TASK_TYPE_RETURN_MATERIALS:
        return 'Return Trailer';
      default:
        return '';
    }
  }

  public getTaskEta(task: Task.AsObject) {
    const taskEta =
      task.stats && task.stats.driverEta
        ? moment()
            .add(task.stats.driverEta, 'minutes')
            .unix() * 1000
        : null;
    return taskEta;
  }
}
