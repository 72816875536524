import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { differenceInHours } from 'date-fns';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, finalize, map, startWith } from 'rxjs/operators';
import { createCustomOrderRequestFactory } from '~lmo/utilities/create-order';
import { OrderType } from '~proto/types/types_pb';
import { UserDetailsForIntercomRequest } from '~proto/user/user_api_pb';
import { UserAPI } from '~proto/user/user_api_pb_service';
import { trackById } from '~utilities/trackById';
import { CreateOrderCustomStops2Component } from '../create-order-custom-stops2/create-order-custom-stops2.component';
var CreateOrderCustomTypeWithShipperAssetsComponent = /** @class */ (function () {
    function CreateOrderCustomTypeWithShipperAssetsComponent(orderService, fb, router, recentService, snackBar, contractService, 
    // Leave this here so we make sure all the constants are loaded
    _constantsService, // tslint-ignore-line
    roleService, grpcService) {
        this.orderService = orderService;
        this.fb = fb;
        this.router = router;
        this.recentService = recentService;
        this.snackBar = snackBar;
        this.contractService = contractService;
        this._constantsService = _constantsService;
        this.roleService = roleService;
        this.grpcService = grpcService;
        this.customStops = new FormControl();
        this.networkActive$ = new BehaviorSubject(false);
        this.truckingContracts = [];
        this.trackById = trackById;
        this.businessLinesList$$ = new BehaviorSubject(null);
        this.isBusinessLineEnabled$$ = new BehaviorSubject(false);
        this.isCostCenterRequired$$ = new BehaviorSubject(false);
        this.isPurchaseOrderRequired$$ = new BehaviorSubject(false);
        this.isSalesOrderRequired$$ = new BehaviorSubject(false);
        this.isMaintenanceOrderRequired$$ = new BehaviorSubject(false);
        this.isBusinessLineCostCenterRelationEnabled$$ = new BehaviorSubject(false);
    }
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getStats();
        this.loadRequiredFieldSettings();
        this.formGroup = this.fb.group({
            businessLine: [null],
            contractTypeId: [null],
            costCenter: [null, this.isCostCenterRequired$$.value ? [Validators.required] : ''],
            costCenterId: [null],
            description: [null, []],
            isAsapOrder: [null, Validators.required],
            loads: [null, Validators.compose([Validators.required, Validators.min(1), Validators.max(50)])],
            scheduledDeliveryWindow: [null, [Validators.required]],
            trailer: [null, [Validators.required]],
            trailerDropoffLocation: [null, [Validators.required]],
            trailerPickupLocation: [null, [Validators.required]],
            truckingVendor: [null],
            vendorContractId: [null],
        });
        this.recentService.loadSalesOrder();
        this.listenToAsap();
        this.listenToWarnings();
        this.listenToTrailer();
        this.setupPayloads();
        this.listenContractTypeChanges();
        this.listenContractChange();
        this.checkIfBusinessLineEnabled();
        this.checkIsBusinessLineCostCenterRelationEnabled();
        this.formGroup.get('businessLine').valueChanges.subscribe(function () {
            _this.formGroup.get('costCenter').setValue(null);
            _this.formGroup.get('costCenterId').setValue(null);
        });
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.listenContractTypeChanges = function () {
        var _this = this;
        this.contractType$ = this.formGroup.get('contractTypeId').valueChanges;
        this.contractTypes$ = this.contractService.contractTypes$;
        combineLatest([
            this.formGroup.get('contractTypeId').valueChanges,
            this.contractService.nonExpiredContracts$,
        ]).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), contractTypeId = _b[0], contracts = _b[1];
            var contractTypeContracts = contracts.filter(function (contract) {
                if (contract.contractType) {
                    return +contractTypeId <= 0 || contract.contractType.id === +contractTypeId;
                }
                return +contractTypeId <= 0;
            });
            var vendor = _this.formGroup.get('truckingVendor').value;
            if (vendor) {
                var vendorContract = contractTypeContracts.find(function (contract) { return contract.vendor.id === vendor.id; });
                if (!(vendorContract && vendorContract.id > 0)) {
                    _this.formGroup.get('truckingVendor').setValue(null);
                }
            }
        });
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.listenContractChange = function () {
        var _this = this;
        combineLatest([
            this.formGroup.get('truckingVendor').valueChanges,
            this.formGroup.get('contractTypeId').valueChanges.pipe(startWith(-1)),
            this.contractService.nonExpiredContracts$,
        ]).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 3), vendor = _b[0], contractTypeId = _b[1], contracts = _b[2];
            var contractTypeContracts = contracts.filter(function (contract) {
                if (contract.contractType) {
                    return +contractTypeId <= 0 || contract.contractType.id === +contractTypeId;
                }
                return +contractTypeId <= 0;
            });
            var contractId = _this.formGroup.get('vendorContractId').value;
            if (contractId) {
                var contractById = contractTypeContracts.find(function (contract) { return contract.id === +contractId; });
                if (!(contractById && contractById.id > 0)) {
                    _this.formGroup.get('vendorContractId').setValue(null);
                }
            }
            if (vendor) {
                _this.truckingContracts = contracts.filter(function (contract) {
                    return contract.vendor.id === vendor.id &&
                        contract.isEffective &&
                        (contractTypeId <= 0 || (contract.contractType && contract.contractType.id === contractTypeId));
                });
            }
        });
        combineLatest([
            this.formGroup.get('vendorContractId').valueChanges,
            this.contractService.nonExpiredContracts$,
        ]).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), contractId = _b[0], contracts = _b[1];
            if (contractId) {
                var contractById = contracts.find(function (contract) { return contract.id === +contractId; });
                _this.formGroup.get('contractTypeId').setValue(contractById.contractType ? contractById.contractType.id : -1);
            }
        });
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.selected = function (event) {
        if (event.pickupFrom && event.pickupFrom.direction) {
            event.pickupFrom.directions = event.pickupFrom.direction;
        }
        this.formGroup.patchValue(event);
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.create = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formValue, customStopsValue, createOrderRequest, payloadSummary;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                formValue = this.formValue();
                customStopsValue = this.customStopsValue();
                this.requiredFieldsCheck(formValue, customStopsValue);
                if (!this.isCCValid || !this.isPOValid || !this.isSOValid || !this.isMOValid) {
                    this.snackBar.open("\n        " + (this.isCostCenterRequired$$.value && !formValue.costCenter ? 'Cost Center, ' : '') + "\n        " + (this.isPurchaseOrderRequired$$.value && !customStopsValue[0].purchaseOrderName ? 'Purchase Order Name, ' : '') + "\n        " + (!this.isMOValid ? 'Maintenance Order Number, ' : '') + "\n        " + (!this.isSOValid ? 'Sales Order Number' : '') + "\n        fields are mandatory", null, {
                        duration: 3000,
                    });
                    return [2 /*return*/];
                }
                createOrderRequest = createCustomOrderRequestFactory(formValue, customStopsValue);
                payloadSummary = this.customStopsComp.validationErrors;
                if (payloadSummary && payloadSummary.invalidTotals) {
                    this.snackBar.open(payloadSummary.invalidTotals, null, {
                        duration: 3000,
                    });
                    return [2 /*return*/];
                }
                this.formGroup.markAllAsTouched();
                if (this.formGroup.invalid) {
                    return [2 /*return*/];
                }
                if (!this.customStopsComp.customStops.valid) {
                    return [2 /*return*/];
                }
                this.networkActive$.next(true);
                createOrderRequest.setType(OrderType.ORDER_TYPE_CUSTOM);
                this.orderService
                    .createOrder$(createOrderRequest)
                    .pipe(finalize(function () {
                    _this.networkActive$.next(false);
                }))
                    .subscribe(function (order) {
                    _this.snackBar.open('Load successfully scheduled!', null, {
                        duration: 3000,
                    });
                    _this.router.navigate([
                        '/',
                        'lmo',
                        'jobs',
                        createOrderRequest.getTaskRequestsList()[createOrderRequest.getTaskRequestsList().length - 1].getSiteId(),
                        'orders',
                        'pending',
                        order.orderId,
                    ]);
                });
                return [2 /*return*/];
            });
        });
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.requiredFieldsCheck = function (formValue, customStopsValue) {
        this.isCCValid = this.isCostCenterRequired$$.value ? formValue.costCenter : 'true';
        this.isPOValid = this.isPurchaseOrderRequired$$.value ? customStopsValue[0].purchaseOrderName : 'true';
        this.isSOValid = this.isSalesOrderRequired$$.value ? this.checkDropOffSO(customStopsValue) : true;
        this.isMOValid = this.isMaintenanceOrderRequired$$.value ? this.checkDropOffMO(customStopsValue) : true;
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.checkDropOffSO = function (customStopsValue) {
        return (customStopsValue &&
            customStopsValue.map(function (customStop) {
                if (customStop.stopType === 1 && customStop.salesOrderNumber === null) {
                    return false;
                }
                return true;
            }));
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.checkDropOffMO = function (customStopsValue) {
        return (customStopsValue &&
            customStopsValue.map(function (customStop) {
                if (customStop.stopType === 1 && customStop.maintenanceOrderNumber === null) {
                    return false;
                }
                return true;
            }));
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.selectedTrailerPickupLocation = function (event) {
        this.formGroup.patchValue({ trailerPickupLocation: event.pickupFrom });
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.selectedTrailerDropoffLocation = function (event) {
        this.formGroup.patchValue({ trailerDropoffLocation: event.pickupFrom });
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.formValue = function () {
        return this.formGroup.value;
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.customStopsValue = function () {
        return this.customStops.value;
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.getStats = function () {
        var _this = this;
        this.orderService.getStats$().subscribe(function (stats) {
            _this.stats = stats;
        });
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.listenToAsap = function () {
        var _this = this;
        this.formGroup
            .get('isAsapOrder')
            .valueChanges.pipe(startWith(this.formGroup.get('isAsapOrder').value))
            .subscribe(function (value) {
            if (value) {
                _this.formGroup.get('scheduledDeliveryWindow').disable();
                _this.formGroup.get('scheduledDeliveryWindow').reset();
            }
            else {
                _this.formGroup.get('scheduledDeliveryWindow').enable();
                if (_this.formGroup.get('loads').value && _this.formGroup.get('loads').value > 1) {
                }
            }
        });
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.setupPayloads = function () {
        this.payloads$ = this.customStops.valueChanges.pipe(startWith([]), map(function (stops) { return stops.filter(function (stop) { return stop.payload; }).map(function (stop) { return stop.payload; }); }));
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.listenToWarnings = function () {
        this.showDetentionWarning$ = this.formGroup.valueChanges.pipe(map(function (formValue) {
            if (formValue.isAsapOrder &&
                formValue.scheduledDeliveryWindow &&
                formValue.scheduledDeliveryWindow.length > 1) {
                if (differenceInHours(formValue.scheduledDeliveryWindow[1], formValue.scheduledDeliveryWindow[0]) < 3) {
                    return true;
                }
            }
            return false;
        }));
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.listenToTrailer = function () {
        var _this = this;
        this.formGroup
            .get('trailer')
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe(function (trailer) {
            if (trailer) {
                _this.formGroup.get('trailerPickupLocation').setValue(trailer.site);
            }
        });
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.businessLinesList = function () {
        var _this = this;
        this.roleService.businessLinesList$.subscribe(function (businessLines) {
            _this.businessLinesList$$.next(businessLines);
            if (businessLines.length === 1) {
                _this.formGroup.controls['businessLine'].setValue(businessLines[0].id);
            }
        });
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.checkIfBusinessLineEnabled = function () {
        var _this = this;
        this.roleService.isBusinessLineEnabled$.subscribe(function (isBusinessLineEnabled) {
            _this.isBusinessLineEnabled$$.next(isBusinessLineEnabled);
            if (isBusinessLineEnabled) {
                _this.businessLinesList();
            }
        });
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.checkIsBusinessLineCostCenterRelationEnabled = function () {
        var _this = this;
        this.roleService.isBusinessLineCostCenterRelationEnabled$.subscribe(function (isBLCCRelationEnabled) {
            _this.isBusinessLineCostCenterRelationEnabled$$.next(isBLCCRelationEnabled);
        });
    };
    CreateOrderCustomTypeWithShipperAssetsComponent.prototype.loadRequiredFieldSettings = function () {
        var _this = this;
        var request = new UserDetailsForIntercomRequest();
        this.grpcService.invoke$(UserAPI.UserDetailsForIntercom, request).subscribe(function (response) {
            _this.isCostCenterRequired$$.next(response.toObject().userDetails.isCostCenterRequired);
            _this.isPurchaseOrderRequired$$.next(response.toObject().userDetails.isPurchaseOrderRequired);
            _this.isSalesOrderRequired$$.next(response.toObject().userDetails.isSalesOrderRequired);
            _this.isMaintenanceOrderRequired$$.next(response.toObject().userDetails.isMaintenanceOrderRequired);
        });
    };
    return CreateOrderCustomTypeWithShipperAssetsComponent;
}());
export { CreateOrderCustomTypeWithShipperAssetsComponent };
