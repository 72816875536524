import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { sort } from 'remeda';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, startWith, take, tap } from 'rxjs/operators';
import { CustomField } from '~proto/fields/fields_pb';
import { CreateOrderRequest, CreateTaskRequest } from '~proto/order/order_api_pb';
import { OrderType, TaskType } from '~proto/types/types_pb';
import { CreateOrderCustomStopsComponent, } from '../create-order-custom-stops/create-order-custom-stops.component';
var CREATE_ORDER_SAVED_SETTINGS = 'CREATE_ORDER_SAVED_SETTINGS';
var CreateOrderComponent = /** @class */ (function () {
    function CreateOrderComponent(orderService, site, fb, router, recentService, snackBar, customFieldsService, payloadService, 
    // Leave this here so we make sure all the constants are loaded
    _constantsService) {
        this.orderService = orderService;
        this.site = site;
        this.fb = fb;
        this.router = router;
        this.recentService = recentService;
        this.snackBar = snackBar;
        this.customFieldsService = customFieldsService;
        this.payloadService = payloadService;
        this._constantsService = _constantsService;
        this.customStops = new FormControl();
        this.requiredCustomFields = new FormArray([]);
        this.optionalCustomFields = new FormArray([]);
        this.networkActive$ = new BehaviorSubject(false);
        this.orderTypes = OrderType;
        this.staggerDurationOptions = [
            {
                minutes: 1,
                name: 'Minutes',
            },
            {
                minutes: 60,
                name: 'Hours',
            },
            {
                minutes: 1440,
                name: 'Days',
            },
        ];
    }
    CreateOrderComponent.prototype.formValue = function () {
        return this.formGroup.value;
    };
    CreateOrderComponent.prototype.customStopsValue = function () {
        return this.customStops.value;
    };
    CreateOrderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentSite$ = this.site.currentSite$;
        this.currentSitePayloads$ = this.payloadService.currentSitePayloads$;
        this.getStats();
        this.siteName$ = this.currentSite$.pipe(filter(function (site) { return !!site; }), map(function (site) { return site.name; }));
        this.withoutSiteIds$ = this.currentSite$.pipe(map(function (site) { return (site ? [site.id] : []); }));
        this.getFormFromLocalStorage().then(function (lastPayloadChoices) {
            _this.formGroup = _this.fb.group({
                confirmationRequired: [false, Validators.required],
                description: [null, []],
                isAsapOrder: [null, Validators.required],
                loads: [null, [Validators.required, Validators.min(1), Validators.max(50)]],
                orderNumber: [lastPayloadChoices.orderNumber],
                payload: [lastPayloadChoices.payload, Validators.required],
                pickupFrom: [lastPayloadChoices.pickupFrom, Validators.required],
                purchaseOrderName: [lastPayloadChoices.purchaseOrderName],
                quantity: [lastPayloadChoices.quantity, Validators.required],
                scheduledDeliveryWindow: [null, [Validators.required]],
                staggerDuration: [null],
                staggerDurationUnit: [_this.staggerDurationOptions[0]],
                trailer: [null],
                trailerLocation: [null],
                truck: [null],
                truckingVendor: [lastPayloadChoices.truckingVendor],
            });
            _this.recentService.loadSalesOrder();
            if (_this.formGroup.get('payload').value) {
                _this.recentService.loadPurchaseOrders(_this.formGroup.get('payload').value.id);
            }
            _this.formGroup.get('loads').setValue(lastPayloadChoices.loads);
            _this.listenToAsap();
            _this.listenToNumberOfLoads();
            _this.listenToTrailer();
            _this.setupPayloads();
        });
        this.customFieldsService.fields$
            .pipe(distinctUntilChanged(), tap(function (fields) {
            _this.requiredCustomFields.clear();
            _this.optionalCustomFields.clear();
            var sortedFields = sort(fields, function (a, b) { return a.position - b.position; });
            sortedFields.forEach(function (field) {
                if (field.required) {
                    _this.requiredCustomFields.push(makeCustomFieldFormGroup(field));
                }
                else {
                    _this.optionalCustomFields.push(makeCustomFieldFormGroup(field));
                }
            });
        }))
            .subscribe();
    };
    CreateOrderComponent.prototype.getStats = function () {
        var _this = this;
        this.orderService.getStats$().subscribe(function (stats) {
            _this.stats = stats;
        });
    };
    CreateOrderComponent.prototype.selected = function (event) {
        if (event.pickupFrom && event.pickupFrom.direction) {
            event.pickupFrom.directions = event.pickupFrom.direction;
        }
        this.formGroup.patchValue(event);
        if (event.payload) {
            this.recentService.loadPurchaseOrders(event.payload.id);
        }
    };
    CreateOrderComponent.prototype.selectedTrailerLocation = function (event) {
        this.formGroup.patchValue({ trailerLocation: event.pickupFrom });
    };
    CreateOrderComponent.prototype.selectOrderType = function (orderType) {
        this.orderType = orderType;
        if (this.orderType === OrderType.ORDER_TYPE_CUSTOM) {
            if (this.formGroup) {
                this.formGroup.get('pickupFrom').disable();
                this.formGroup.get('quantity').disable();
                this.formGroup.get('payload').disable();
            }
        }
    };
    CreateOrderComponent.prototype.createBaseOrderRequest = function () {
        var formValue = this.formValue();
        var createOrderRequest = new CreateOrderRequest();
        createOrderRequest.setConfirmationRequired(formValue.confirmationRequired);
        createOrderRequest.setOrderQuantity(formValue.loads);
        createOrderRequest.setPurchaseOrderName(formValue.purchaseOrderName);
        createOrderRequest.setSalesOrderNumber(formValue.orderNumber);
        createOrderRequest.setIsAsapOrder(formValue.isAsapOrder);
        createOrderRequest.setDescription(formValue.description);
        createOrderRequest.setType(this.orderType);
        if (formValue.scheduledDeliveryWindow && formValue.scheduledDeliveryWindow.length) {
            var start = moment(formValue.scheduledDeliveryWindow[0]);
            var end = moment(formValue.scheduledDeliveryWindow[1]);
            var windowDuration = moment.duration(end.diff(start)).asMinutes();
            createOrderRequest.setDeliveryWindowStart(start.unix());
            createOrderRequest.setDeliveryWindowDurationMinutes(windowDuration);
        }
        if (formValue.trailer) {
            createOrderRequest.setTrailerId(formValue.trailer.id);
        }
        if (formValue.trailerLocation) {
            createOrderRequest.setTrailerSiteId(formValue.trailerLocation.id);
        }
        if (formValue.truck) {
            createOrderRequest.setTruckId(formValue.truck.id);
        }
        if (formValue.staggerDuration) {
            createOrderRequest.setStaggerMinutes(formValue.staggerDuration * (formValue.staggerDurationUnit.minutes || 1));
        }
        if (formValue.truckingVendor) {
            createOrderRequest.setTruckingVendorId(formValue.truckingVendor.id);
        }
        return createOrderRequest;
    };
    CreateOrderComponent.prototype.addDropOffTasksToOrderRequest = function (site, request) {
        var formValue = this.formValue();
        var pickupTask = new CreateTaskRequest();
        pickupTask.setDescription(formValue.pickupFrom.directions);
        pickupTask.setOrderedQuantity(formValue.quantity);
        pickupTask.setSiteId(formValue.pickupFrom.id);
        pickupTask.setTaskType(TaskType.TASK_TYPE_PICKUP);
        pickupTask.setPayloadId(formValue.payload.id);
        pickupTask.setSequence(1);
        var dropOffTask = new CreateTaskRequest();
        dropOffTask.setDescription(site.directions);
        dropOffTask.setOrderedQuantity(formValue.quantity);
        dropOffTask.setTaskType(TaskType.TASK_TYPE_DROPOFF);
        dropOffTask.setSiteId(site.id);
        dropOffTask.setIsBillable(true);
        dropOffTask.setPayloadId(formValue.payload.id);
        dropOffTask.setSequence(2);
        if (this.orderType === this.orderTypes.ORDER_TYPE_DELIVERY_AND_RETURN) {
            var returnTask = new CreateTaskRequest();
            returnTask.setDescription(formValue.pickupFrom.directions);
            returnTask.setTaskType(TaskType.TASK_TYPE_RETURN_MATERIALS);
            returnTask.setSiteId(formValue.pickupFrom.id);
            returnTask.setSequence(3);
            request.setTaskRequestsList([pickupTask, dropOffTask, returnTask]);
        }
        else {
            request.setTaskRequestsList([pickupTask, dropOffTask]);
        }
    };
    CreateOrderComponent.prototype.addPickupTasksToOrderRequest = function (site, request) {
        var formValue = this.formValue();
        var pickupTask = new CreateTaskRequest();
        pickupTask.setDescription(site.directions);
        pickupTask.setOrderedQuantity(formValue.quantity);
        pickupTask.setSiteId(site.id);
        pickupTask.setIsBillable(true);
        pickupTask.setTaskType(TaskType.TASK_TYPE_PICKUP);
        pickupTask.setPayloadId(formValue.payload.id);
        pickupTask.setSequence(1);
        var dropOffTask = new CreateTaskRequest();
        dropOffTask.setDescription(formValue.pickupFrom.directions);
        dropOffTask.setOrderedQuantity(formValue.quantity);
        dropOffTask.setSiteId(formValue.pickupFrom.id);
        dropOffTask.setTaskType(TaskType.TASK_TYPE_DROPOFF);
        dropOffTask.setPayloadId(formValue.payload.id);
        dropOffTask.setSequence(2);
        request.setTaskRequestsList([pickupTask, dropOffTask]);
    };
    CreateOrderComponent.prototype.addCustomTasksToOrderRequest = function (currentSite, request) {
        var customStops = this.customStopsValue();
        var tasks = [];
        customStops.forEach(function (stop, index) {
            var task = new CreateTaskRequest();
            task.setDescription(stop.location.directions);
            task.setSiteId(stop.location.id);
            task.setTaskType(stop.stopType);
            if (currentSite.id === stop.location.id) {
                task.setIsBillable(true);
            }
            if (stop.payload) {
                task.setPayloadId(stop.payload.id);
            }
            if (stop.quantity) {
                task.setOrderedQuantity(stop.quantity);
            }
            task.setSequence(index + 1);
            tasks.push(task);
        });
        request.setTaskRequestsList(tasks);
    };
    CreateOrderComponent.prototype.addCustomFields = function (request) {
        var customFields = tslib_1.__spread(this.requiredCustomFields.value, this.optionalCustomFields.value).map(convertCustomFieldsFormGroupsToCustomFields);
        request.setCustomFieldsList(customFields);
    };
    CreateOrderComponent.prototype.create = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var site, formValue, createOrderRequest;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.formGroup.markAllAsTouched();
                        if (this.formGroup.invalid || (this.customStopsComp && this.customStopsComp.validate())) {
                            return [2 /*return*/];
                        }
                        this.networkActive$.next(true);
                        return [4 /*yield*/, this.currentSite$.pipe(take(1)).toPromise()];
                    case 1:
                        site = _a.sent();
                        formValue = this.formValue();
                        saveFormToLocalStorage(formValue, site.id);
                        createOrderRequest = this.createBaseOrderRequest();
                        if (this.orderType === this.orderTypes.ORDER_TYPE_DELIVERY ||
                            this.orderType === this.orderTypes.ORDER_TYPE_DELIVERY_AND_RETURN) {
                            this.addDropOffTasksToOrderRequest(site, createOrderRequest);
                        }
                        else if (this.orderType === this.orderTypes.ORDER_TYPE_PICKUP) {
                            this.addPickupTasksToOrderRequest(site, createOrderRequest);
                        }
                        else if (this.orderType === this.orderTypes.ORDER_TYPE_CUSTOM) {
                            this.addCustomTasksToOrderRequest(site, createOrderRequest);
                        }
                        this.orderService
                            .createOrder$(createOrderRequest)
                            .pipe(finalize(function () {
                            _this.networkActive$.next(false);
                        }))
                            .subscribe(function (order) {
                            _this.snackBar.open('Load successfully scheduled!', null, {
                                duration: 3000,
                            });
                            _this.router.navigate(['/', 'lmo', 'jobs', site.id, 'orders', 'pending', order.orderId]);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateOrderComponent.prototype.getFormFromLocalStorage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var siteId, saved;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.currentSite$
                            .pipe(take(1))
                            .toPromise()
                            .then(function (site) {
                            siteId = site.id;
                        })];
                    case 1:
                        _a.sent();
                        saved = JSON.parse(localStorage.getItem(CREATE_ORDER_SAVED_SETTINGS));
                        if (!saved) {
                            return [2 /*return*/, {
                                    loads: null,
                                    orderNumber: null,
                                    payload: null,
                                    pickupFrom: null,
                                    purchaseOrderName: null,
                                    quantity: null,
                                    truckingVendor: null,
                                }];
                        }
                        return [2 /*return*/, saved];
                }
            });
        });
    };
    CreateOrderComponent.prototype.listenToAsap = function () {
        var _this = this;
        this.formGroup
            .get('isAsapOrder')
            .valueChanges.pipe(startWith(this.formGroup.get('isAsapOrder').value))
            .subscribe(function (value) {
            if (value) {
                _this.formGroup.get('scheduledDeliveryWindow').disable();
                _this.formGroup.get('scheduledDeliveryWindow').reset();
                _this.formGroup.get('staggerDuration').disable();
                _this.formGroup.get('staggerDuration').reset();
                _this.formGroup.get('staggerDurationUnit').disable();
            }
            else {
                _this.formGroup.get('scheduledDeliveryWindow').enable();
                if (_this.formGroup.get('loads').value && _this.formGroup.get('loads').value > 1) {
                    _this.formGroup.get('staggerDuration').enable();
                    _this.formGroup.get('staggerDurationUnit').enable();
                }
            }
        });
    };
    CreateOrderComponent.prototype.listenToNumberOfLoads = function () {
        var _this = this;
        this.formGroup
            .get('loads')
            .valueChanges.pipe(startWith(this.formGroup.get('loads').value))
            .subscribe(function (value) {
            if (value && value > 1 && !_this.formGroup.get('isAsapOrder').value) {
                _this.formGroup.get('staggerDuration').enable();
                _this.formGroup.get('staggerDurationUnit').enable();
            }
            else {
                _this.formGroup.get('staggerDuration').disable();
                _this.formGroup.get('staggerDurationUnit').disable();
            }
        });
    };
    CreateOrderComponent.prototype.listenToTrailer = function () {
        var _this = this;
        this.customStops.valueChanges.subscribe(function () {
            var value = _this.customStopsValue();
            if (!value) {
                return;
            }
            var trailerStops = value.filter(function (stop) { return !!stop.assetNumber; });
            if (trailerStops.length) {
                _this.formGroup.get('trailer').setValue(trailerStops[0].assetNumber);
            }
        });
        this.formGroup
            .get('trailer')
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe(function (trailer) {
            if (trailer) {
                // Update custom stops
                var value = _this.customStopsValue();
                if (value) {
                    var newValue = value.map(function (stop) {
                        if (stop.assetNumber) {
                            return tslib_1.__assign({}, stop, { assetNumber: trailer });
                        }
                        return stop;
                    });
                    _this.customStops.setValue(newValue);
                }
                // Update trailer location
                _this.formGroup.get('trailerLocation').setValue(trailer.site);
            }
        });
    };
    CreateOrderComponent.prototype.setupPayloads = function () {
        var _this = this;
        this.payloads$ = combineLatest([
            this.customStops.valueChanges.pipe(startWith(null), map(function () { return _this.customStopsValue(); }), map(function (stops) { return (stops || []).filter(function (stop) { return stop.payload; }).map(function (stop) { return stop.payload; }); })),
            this.formGroup.get('payload').valueChanges.pipe(startWith(this.formGroup.get('payload').value)),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), payloads = _b[0], payload = _b[1];
            return tslib_1.__spread(payloads, [payload]).filter(function (p) { return !!p; });
        }));
    };
    CreateOrderComponent.prototype.checkDetentionWarning = function () {
        if (!this.formGroup.get('isAsapOrder').value) {
            var start = moment(this.formGroup.get('scheduledDeliveryWindow').value && this.formGroup.get('scheduledDeliveryWindow').value[0]);
            var end = moment(this.formGroup.get('scheduledDeliveryWindow').value && this.formGroup.get('scheduledDeliveryWindow').value[1]);
            var windowDuration = moment.duration(end.diff(start)).asHours();
            return windowDuration < 3;
        }
        else {
            return false;
        }
    };
    CreateOrderComponent.prototype.trackByIndex = function (index) {
        return index;
    };
    CreateOrderComponent.prototype.trackByName = function (_index, record) {
        return record.name;
    };
    return CreateOrderComponent;
}());
export { CreateOrderComponent };
function saveFormToLocalStorage(formValue, siteId) {
    var _a;
    var savedSettings = {
        loads: formValue.loads,
        orderNumber: formValue.orderNumber,
        payload: formValue.payload,
        pickupFrom: formValue.pickupFrom,
        purchaseOrderName: formValue.purchaseOrderName,
        quantity: formValue.quantity,
        truckingVendor: formValue.truckingVendor,
    };
    var existingSavedSettings = JSON.parse(localStorage.getItem(CREATE_ORDER_SAVED_SETTINGS)) || {};
    localStorage.setItem(CREATE_ORDER_SAVED_SETTINGS, JSON.stringify(tslib_1.__assign({}, existingSavedSettings, (_a = {}, _a[siteId] = savedSettings, _a))));
}
function makeCustomFieldFormGroup(field) {
    var validators = field.required ? [Validators.required] : [];
    return new FormGroup({
        meta: new FormControl(field),
        value: new FormControl(null, validators),
    });
}
function convertCustomFieldsFormGroupsToCustomFields(group) {
    var _others = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        _others[_i - 1] = arguments[_i];
    }
    var customField = new CustomField();
    customField.setKey(group.meta.key);
    customField.setPosition(group.meta.position);
    customField.setType(group.meta.type);
    if (group.value === null) {
        customField.setValue(null);
    }
    else {
        customField.setValue(typeof group.value === 'string' ? group.value : group.value.toString());
    }
    return customField;
}
