import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { trackById } from '~utilities/trackById';
function getNumberOfContracts(contracts, type) {
    return contracts.filter(function (contract) {
        if (contract.contractType) {
            return contract.contractType.id === type.id;
        }
        return false;
    }).length;
}
var LmoTruckingContractsTypeComponent = /** @class */ (function () {
    function LmoTruckingContractsTypeComponent(contractService) {
        this.contractService = contractService;
        this.trackById = trackById;
        this.fuelUploadCategoryMap = {
            1: 'Per Mile Fixed',
            2: 'Percentage Hike',
        };
    }
    LmoTruckingContractsTypeComponent.prototype.ngOnInit = function () {
        this.contractTypes$ = combineLatest([
            this.contractService.nonExpiredContracts$,
            this.contractService.contractTypes$,
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), contracts = _b[0], contractTypes = _b[1];
            return contractTypes.map(function (type) {
                return tslib_1.__assign({}, type, { numberOfContracts: getNumberOfContracts(contracts, type) });
            });
        }));
    };
    LmoTruckingContractsTypeComponent.prototype.archiveContract = function (contract) {
        this.contractService.archiveVendorContract(contract);
    };
    return LmoTruckingContractsTypeComponent;
}());
export { LmoTruckingContractsTypeComponent };
