import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { OrdersService } from 'src/app/lmo/services/orders.service';
import { OrderStatus, OrderStatusMap } from '~proto/types/types_pb';

const pendingStatuses: Partial<OrderStatusMap[keyof OrderStatusMap]>[] = [
  OrderStatus.ORDER_STATUS_CONFIRMATION_NEEDED,
  OrderStatus.ORDER_STATUS_DECLINED,
  OrderStatus.ORDER_STATUS_DRIVER_REJECTED,
  OrderStatus.ORDER_STATUS_PENDING,
];

const completedStatuses: Partial<OrderStatusMap[keyof OrderStatusMap]>[] = [
  OrderStatus.ORDER_STATUS_COMPLETED,
  OrderStatus.ORDER_STATUS_CANCELLED,
];

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-lmo-order-permalink',
  styleUrls: ['./lmo-order-permalink.component.scss'],
  templateUrl: './lmo-order-permalink.component.html',
})
export class LmoOrderPermalinkComponent implements OnInit {
  constructor(private router: Router, private orderService: OrdersService) {}

  public ngOnInit() {
    this.orderService.currentOrder$
      .pipe(
        filter((order) => !!order),
        take(1),
      )
      .subscribe((order) => {
        let currentState = 'in-progress';
        if (pendingStatuses.includes(order.status)) {
          currentState = 'pending';
        } else if (completedStatuses.includes(order.status)) {
          currentState = 'completed';
        }
        const billingTask = order.tasksList.find((task) => task.isBillable);
        const urlInfo = ['/', 'lmo', 'jobs', billingTask.site.id, 'orders', currentState, order.id];
        this.router.navigate(urlInfo, { replaceUrl: true });
      });
  }
}
