import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { JobSitesService } from '~lmo/services/job-sites.service';
import { Site } from '~proto/site/site_pb';
import { trackById } from '~utilities/trackById';

@Component({
  selector: 'ct-certification-card',
  styleUrls: ['./certification-card.component.scss'],
  templateUrl: './certification-card.component.html',
})
export class CertificationCardComponent implements OnInit {
  public currentSite$: Observable<Site.AsObject>;
  public trackById = trackById;

  constructor(private siteService: JobSitesService) {}

  public ngOnInit() {
    this.currentSite$ = this.siteService.currentSite$.pipe(filter((site) => !!site));
  }
}
