import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
var CreateOrderTruckingVendorSelectorComponent = /** @class */ (function () {
    function CreateOrderTruckingVendorSelectorComponent(contractService) {
        this.contractService = contractService;
        this._payloads$ = new BehaviorSubject([]);
        this._contractType$ = new BehaviorSubject(-1);
        this.selected = new EventEmitter();
        this.invalid = false;
        this.disabled = false;
        this.textChanges$$ = new BehaviorSubject(null);
    }
    Object.defineProperty(CreateOrderTruckingVendorSelectorComponent.prototype, "payloads", {
        set: function (payloads) {
            this._payloads$.next(payloads);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateOrderTruckingVendorSelectorComponent.prototype, "contractType", {
        set: function (contractType) {
            this._contractType$.next(contractType);
        },
        enumerable: true,
        configurable: true
    });
    CreateOrderTruckingVendorSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        var uniqueVendorsByPayload$ = combineLatest([
            this.contractService.nonExpiredContracts$,
            this._payloads$.pipe(startWith(this._payloads$.value)),
            this._contractType$,
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 3), contracts = _b[0], payloads = _b[1], contractType = _b[2];
            if (!payloads || !payloads.length) {
                return [];
            }
            var unarchived = contracts.filter(function (contract) { return !contract.archived; });
            var uniqueVendors = unarchived.reduce(function (acc, contract) {
                var _a;
                var payloadIDsSupportedByContract = idArrayToRecord(contract.payloadtypesList);
                if ((contractType <= 0 || (contract.contractType && contract.contractType.id === contractType)) &&
                    payloads.every(function (payload) { return payloadIDsSupportedByContract[payload.type.id]; })) {
                    return tslib_1.__assign({}, acc, (_a = {}, _a[contract.vendor.id] = contract.vendor, _a));
                }
                return acc;
            }, {});
            return Object.values(uniqueVendors).sort(function (a, b) {
                return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
            });
        }));
        this.truckingVendors$ = combineLatest([
            uniqueVendorsByPayload$,
            this.textChanges$$.pipe(debounceTime(100), startWith('')),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), contracts = _b[0], text = _b[1];
            if (!contracts) {
                return [];
            }
            if (!text || text === '') {
                _this.topResult = contracts.length ? contracts[0] : null;
                return contracts;
            }
            var asLowercase = text.toLocaleLowerCase();
            var filtered = contracts.filter(function (account) { return account.name.toLocaleLowerCase().includes(asLowercase); });
            _this.topResult = filtered.length ? filtered[0] : null;
            return filtered;
        }));
    };
    CreateOrderTruckingVendorSelectorComponent.prototype.inputTextChange = function (event) {
        this.textChanges$$.next(event.trim());
    };
    CreateOrderTruckingVendorSelectorComponent.prototype.selectVendor = function (truckingVendor) {
        this.selected.emit({ truckingVendor: truckingVendor });
        this.smartDropdown.close();
    };
    CreateOrderTruckingVendorSelectorComponent.prototype.enterKeyPressed = function () {
        if (this.topResult) {
            this.selectVendor(this.topResult);
        }
    };
    return CreateOrderTruckingVendorSelectorComponent;
}());
export { CreateOrderTruckingVendorSelectorComponent };
