import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { SmartDropdownComponent } from '~common/smart-dropdown/smart-dropdown.component';
import { CreateTrailerRequest } from '~proto/trailer/trailer_api_pb';
import { Trailer } from '~proto/trailer/trailer_pb';
import { fuse } from '~utilities/fuse';
import { TruckAndTrailerService } from '../../services/truck-and-trailer.service';

const searchOptions: Fuse.FuseOptions<Trailer.AsObject> = {
  distance: 100,
  keys: ['name'],
  location: 0,
  maxPatternLength: 16,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.1,
};

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-create-order-trailer-selector',
  styleUrls: ['./create-order-trailer-selector.component.scss'],
  templateUrl: './create-order-trailer-selector.component.html',
})
export class CreateOrderTrailerSelectorComponent implements OnInit {
  private topResult: Trailer.AsObject;
  @Output() public selected = new EventEmitter<{ trailer: Trailer.AsObject }>();
  @ViewChild('smartDropdown', { static: true }) private smartDropdown: SmartDropdownComponent;
  @Input() public trailer: Trailer.AsObject;
  @Input() public invalid = false;
  public showCreateTrailerButton$: Observable<boolean>;
  public textChanges$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public trailers$: Observable<Trailer.AsObject[]>;
  constructor(private trailerService: TruckAndTrailerService) {}

  public ngOnInit() {
    this.trailers$ = combineLatest([
      this.trailerService.trailers$.pipe(map(fuse(searchOptions))),
      this.textChanges$$.pipe(
        debounceTime(100),
        startWith(''),
      ),
    ]).pipe(
      map(([trailers, text]) => {
        if (!trailers) {
          return [];
        }
        if (!text || text === '') {
          this.topResult = trailers.data.length ? trailers.data[0] : null;
          return trailers.data;
        }
        const searchResults = trailers.fuse.search(text);
        this.topResult = searchResults.length ? searchResults[0] : null;
        return searchResults;
      }),
    );

    this.showCreateTrailerButton$ = combineLatest([
      this.trailerService.trailers$,
      this.textChanges$$.pipe(startWith(null as string)),
    ]).pipe(
      map(([trailers, text]) => {
        if (!text || !text.length) {
          return false;
        }

        return trailers.every((trailer) => trailer.name !== text);
      }),
    );
  }

  public inputTextChange(event: string) {
    this.textChanges$$.next(event.trim());
  }

  public selectTrailer(trailer: Trailer.AsObject) {
    this.selected.emit({ trailer });
    this.smartDropdown.close();
  }

  public addTrailer() {
    const name = this.textChanges$$.value;
    if (!name || name === '') {
      return;
    }
    const request = new CreateTrailerRequest();
    request.setName(name);
    this.trailerService.addTrailer$(request).subscribe((trailer) => {
      this.selectTrailer(trailer);
    });
  }

  public enterKeyPressed() {
    if (this.topResult) {
      this.selectTrailer(this.topResult);
    } else {
      this.addTrailer();
    }
  }
}
