/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_cybertron_payload_payload_pb = require('../../../proto/cybertron/payload/payload_pb.js');
var proto_cybertron_billingsettings_settings_pb = require('../../../proto/cybertron/billingsettings/settings_pb.js');
var proto_cybertron_types_types_pb = require('../../../proto/cybertron/types/types_pb.js');
goog.exportSymbol('proto.cbv1.FixedPointCost', null, global);
goog.exportSymbol('proto.cbv1.FuelSurchargeBrackets', null, global);
goog.exportSymbol('proto.cbv1.FuelSurchargeContract', null, global);
goog.exportSymbol('proto.cbv1.LMOVendorPayloadTypePercentage', null, global);
goog.exportSymbol('proto.cbv1.MileageCost', null, global);
goog.exportSymbol('proto.cbv1.VendorContract', null, global);
goog.exportSymbol('proto.cbv1.VendorContractType', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorContractType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.VendorContractType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorContractType.displayName = 'proto.cbv1.VendorContractType';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorContractType.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorContractType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorContractType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorContractType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    ediCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fuelSurchargeContract: (f = msg.getFuelSurchargeContract()) && proto.cbv1.FuelSurchargeContract.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorContractType}
 */
proto.cbv1.VendorContractType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorContractType;
  return proto.cbv1.VendorContractType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorContractType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorContractType}
 */
proto.cbv1.VendorContractType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOwnerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdiCode(value);
      break;
    case 5:
      var value = new proto.cbv1.FuelSurchargeContract;
      reader.readMessage(value,proto.cbv1.FuelSurchargeContract.deserializeBinaryFromReader);
      msg.setFuelSurchargeContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorContractType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorContractType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorContractType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorContractType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEdiCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFuelSurchargeContract();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.cbv1.FuelSurchargeContract.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.VendorContractType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.VendorContractType.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.VendorContractType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.VendorContractType.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 owner_id = 3;
 * @return {number}
 */
proto.cbv1.VendorContractType.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.VendorContractType.prototype.setOwnerId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string edi_code = 4;
 * @return {string}
 */
proto.cbv1.VendorContractType.prototype.getEdiCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.VendorContractType.prototype.setEdiCode = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional FuelSurchargeContract fuel_surcharge_contract = 5;
 * @return {?proto.cbv1.FuelSurchargeContract}
 */
proto.cbv1.VendorContractType.prototype.getFuelSurchargeContract = function() {
  return /** @type{?proto.cbv1.FuelSurchargeContract} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.FuelSurchargeContract, 5));
};


/** @param {?proto.cbv1.FuelSurchargeContract|undefined} value */
proto.cbv1.VendorContractType.prototype.setFuelSurchargeContract = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.cbv1.VendorContractType.prototype.clearFuelSurchargeContract = function() {
  this.setFuelSurchargeContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.VendorContractType.prototype.hasFuelSurchargeContract = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorContract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.VendorContract.repeatedFields_, null);
};
goog.inherits(proto.cbv1.VendorContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorContract.displayName = 'proto.cbv1.VendorContract';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.VendorContract.repeatedFields_ = [11,14,25,29];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorContract.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorContract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorContract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorContract.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lmo: (f = msg.getLmo()) && proto_cybertron_types_types_pb.IdName.toObject(includeInstance, f),
    vendor: (f = msg.getVendor()) && proto_cybertron_types_types_pb.IdName.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    hourlyDetentionCost: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxDetentionPerOrder: jspb.Message.getFieldWithDefault(msg, 6, 0),
    pickupFreeTime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    dropoffFreeTime: jspb.Message.getFieldWithDefault(msg, 8, 0),
    deadheadFreeMileage: jspb.Message.getFieldWithDefault(msg, 9, 0),
    deadheadCostPerMile: jspb.Message.getFieldWithDefault(msg, 10, 0),
    mileageCostsList: jspb.Message.toObjectList(msg.getMileageCostsList(),
    proto.cbv1.MileageCost.toObject, includeInstance),
    travelCostType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    perHourTravelCost: jspb.Message.getFieldWithDefault(msg, 13, 0),
    payloadtypesList: jspb.Message.toObjectList(msg.getPayloadtypesList(),
    proto_cybertron_payload_payload_pb.PayloadType.toObject, includeInstance),
    archived: jspb.Message.getFieldWithDefault(msg, 15, false),
    createdUnix: jspb.Message.getFieldWithDefault(msg, 16, 0),
    updatedUnix: jspb.Message.getFieldWithDefault(msg, 17, 0),
    referenceNumber: jspb.Message.getFieldWithDefault(msg, 18, ""),
    isDefault: jspb.Message.getFieldWithDefault(msg, 19, false),
    perMileTravelCost: jspb.Message.getFieldWithDefault(msg, 20, 0),
    contractType: (f = msg.getContractType()) && proto.cbv1.VendorContractType.toObject(includeInstance, f),
    fuelSurcharge: jspb.Message.getFieldWithDefault(msg, 22, false),
    minimumCharge: jspb.Message.getFieldWithDefault(msg, 23, 0),
    fileId: jspb.Message.getFieldWithDefault(msg, 24, 0),
    poinToPointLocsList: jspb.Message.toObjectList(msg.getPoinToPointLocsList(),
    proto.cbv1.FixedPointCost.toObject, includeInstance),
    isReturnTripBillable: jspb.Message.getFieldWithDefault(msg, 26, false),
    isBackHaulEnabled: jspb.Message.getFieldWithDefault(msg, 27, false),
    backHaulChargePercent: jspb.Message.getFieldWithDefault(msg, 28, 0),
    zipcodesnotmappedList: jspb.Message.getRepeatedField(msg, 29),
    expirationTime: (f = msg.getExpirationTime()) && proto_cybertron_types_types_pb.NullableTime.toObject(includeInstance, f),
    isExpired: jspb.Message.getFieldWithDefault(msg, 31, false),
    effectiveTime: (f = msg.getEffectiveTime()) && proto_cybertron_types_types_pb.NullableTime.toObject(includeInstance, f),
    isEffective: jspb.Message.getFieldWithDefault(msg, 33, false),
    createdBy: (f = msg.getCreatedBy()) && proto_cybertron_billingsettings_settings_pb.UserDetails.toObject(includeInstance, f),
    lastEditedBy: (f = msg.getLastEditedBy()) && proto_cybertron_billingsettings_settings_pb.UserDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorContract}
 */
proto.cbv1.VendorContract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorContract;
  return proto.cbv1.VendorContract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorContract}
 */
proto.cbv1.VendorContract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_cybertron_types_types_pb.IdName;
      reader.readMessage(value,proto_cybertron_types_types_pb.IdName.deserializeBinaryFromReader);
      msg.setLmo(value);
      break;
    case 3:
      var value = new proto_cybertron_types_types_pb.IdName;
      reader.readMessage(value,proto_cybertron_types_types_pb.IdName.deserializeBinaryFromReader);
      msg.setVendor(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHourlyDetentionCost(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxDetentionPerOrder(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickupFreeTime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDropoffFreeTime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeadheadFreeMileage(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeadheadCostPerMile(value);
      break;
    case 11:
      var value = new proto.cbv1.MileageCost;
      reader.readMessage(value,proto.cbv1.MileageCost.deserializeBinaryFromReader);
      msg.addMileageCosts(value);
      break;
    case 12:
      var value = /** @type {!proto.cbv1.VendorContractTravelCostType} */ (reader.readEnum());
      msg.setTravelCostType(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPerHourTravelCost(value);
      break;
    case 14:
      var value = new proto_cybertron_payload_payload_pb.PayloadType;
      reader.readMessage(value,proto_cybertron_payload_payload_pb.PayloadType.deserializeBinaryFromReader);
      msg.addPayloadtypes(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedUnix(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedUnix(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceNumber(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDefault(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPerMileTravelCost(value);
      break;
    case 21:
      var value = new proto.cbv1.VendorContractType;
      reader.readMessage(value,proto.cbv1.VendorContractType.deserializeBinaryFromReader);
      msg.setContractType(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFuelSurcharge(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinimumCharge(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFileId(value);
      break;
    case 25:
      var value = new proto.cbv1.FixedPointCost;
      reader.readMessage(value,proto.cbv1.FixedPointCost.deserializeBinaryFromReader);
      msg.addPoinToPointLocs(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReturnTripBillable(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBackHaulEnabled(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBackHaulChargePercent(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.addZipcodesnotmapped(value);
      break;
    case 30:
      var value = new proto_cybertron_types_types_pb.NullableTime;
      reader.readMessage(value,proto_cybertron_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setExpirationTime(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExpired(value);
      break;
    case 32:
      var value = new proto_cybertron_types_types_pb.NullableTime;
      reader.readMessage(value,proto_cybertron_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setEffectiveTime(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEffective(value);
      break;
    case 34:
      var value = new proto_cybertron_billingsettings_settings_pb.UserDetails;
      reader.readMessage(value,proto_cybertron_billingsettings_settings_pb.UserDetails.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    case 35:
      var value = new proto_cybertron_billingsettings_settings_pb.UserDetails;
      reader.readMessage(value,proto_cybertron_billingsettings_settings_pb.UserDetails.deserializeBinaryFromReader);
      msg.setLastEditedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorContract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorContract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorContract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLmo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_cybertron_types_types_pb.IdName.serializeBinaryToWriter
    );
  }
  f = message.getVendor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_cybertron_types_types_pb.IdName.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHourlyDetentionCost();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getMaxDetentionPerOrder();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPickupFreeTime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getDropoffFreeTime();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getDeadheadFreeMileage();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getDeadheadCostPerMile();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getMileageCostsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.cbv1.MileageCost.serializeBinaryToWriter
    );
  }
  f = message.getTravelCostType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getPerHourTravelCost();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getPayloadtypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto_cybertron_payload_payload_pb.PayloadType.serializeBinaryToWriter
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getCreatedUnix();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getUpdatedUnix();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getReferenceNumber();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getIsDefault();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getPerMileTravelCost();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getContractType();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.cbv1.VendorContractType.serializeBinaryToWriter
    );
  }
  f = message.getFuelSurcharge();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getMinimumCharge();
  if (f !== 0) {
    writer.writeInt64(
      23,
      f
    );
  }
  f = message.getFileId();
  if (f !== 0) {
    writer.writeInt64(
      24,
      f
    );
  }
  f = message.getPoinToPointLocsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.cbv1.FixedPointCost.serializeBinaryToWriter
    );
  }
  f = message.getIsReturnTripBillable();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getIsBackHaulEnabled();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getBackHaulChargePercent();
  if (f !== 0) {
    writer.writeInt32(
      28,
      f
    );
  }
  f = message.getZipcodesnotmappedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      29,
      f
    );
  }
  f = message.getExpirationTime();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto_cybertron_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getIsExpired();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getEffectiveTime();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto_cybertron_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getIsEffective();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto_cybertron_billingsettings_settings_pb.UserDetails.serializeBinaryToWriter
    );
  }
  f = message.getLastEditedBy();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto_cybertron_billingsettings_settings_pb.UserDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.VendorContract.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.VendorContract.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional IdName lmo = 2;
 * @return {?proto.cbv1.IdName}
 */
proto.cbv1.VendorContract.prototype.getLmo = function() {
  return /** @type{?proto.cbv1.IdName} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_types_types_pb.IdName, 2));
};


/** @param {?proto.cbv1.IdName|undefined} value */
proto.cbv1.VendorContract.prototype.setLmo = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cbv1.VendorContract.prototype.clearLmo = function() {
  this.setLmo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.VendorContract.prototype.hasLmo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional IdName vendor = 3;
 * @return {?proto.cbv1.IdName}
 */
proto.cbv1.VendorContract.prototype.getVendor = function() {
  return /** @type{?proto.cbv1.IdName} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_types_types_pb.IdName, 3));
};


/** @param {?proto.cbv1.IdName|undefined} value */
proto.cbv1.VendorContract.prototype.setVendor = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv1.VendorContract.prototype.clearVendor = function() {
  this.setVendor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.VendorContract.prototype.hasVendor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.cbv1.VendorContract.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.VendorContract.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 hourly_detention_cost = 5;
 * @return {number}
 */
proto.cbv1.VendorContract.prototype.getHourlyDetentionCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.VendorContract.prototype.setHourlyDetentionCost = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 max_detention_per_order = 6;
 * @return {number}
 */
proto.cbv1.VendorContract.prototype.getMaxDetentionPerOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.VendorContract.prototype.setMaxDetentionPerOrder = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 pickup_free_time = 7;
 * @return {number}
 */
proto.cbv1.VendorContract.prototype.getPickupFreeTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.VendorContract.prototype.setPickupFreeTime = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 dropoff_free_time = 8;
 * @return {number}
 */
proto.cbv1.VendorContract.prototype.getDropoffFreeTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cbv1.VendorContract.prototype.setDropoffFreeTime = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 deadhead_free_mileage = 9;
 * @return {number}
 */
proto.cbv1.VendorContract.prototype.getDeadheadFreeMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cbv1.VendorContract.prototype.setDeadheadFreeMileage = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 deadhead_cost_per_mile = 10;
 * @return {number}
 */
proto.cbv1.VendorContract.prototype.getDeadheadCostPerMile = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cbv1.VendorContract.prototype.setDeadheadCostPerMile = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated MileageCost mileage_costs = 11;
 * @return {!Array<!proto.cbv1.MileageCost>}
 */
proto.cbv1.VendorContract.prototype.getMileageCostsList = function() {
  return /** @type{!Array<!proto.cbv1.MileageCost>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.MileageCost, 11));
};


/** @param {!Array<!proto.cbv1.MileageCost>} value */
proto.cbv1.VendorContract.prototype.setMileageCostsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.cbv1.MileageCost=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.MileageCost}
 */
proto.cbv1.VendorContract.prototype.addMileageCosts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.cbv1.MileageCost, opt_index);
};


proto.cbv1.VendorContract.prototype.clearMileageCostsList = function() {
  this.setMileageCostsList([]);
};


/**
 * optional VendorContractTravelCostType travel_cost_type = 12;
 * @return {!proto.cbv1.VendorContractTravelCostType}
 */
proto.cbv1.VendorContract.prototype.getTravelCostType = function() {
  return /** @type {!proto.cbv1.VendorContractTravelCostType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {!proto.cbv1.VendorContractTravelCostType} value */
proto.cbv1.VendorContract.prototype.setTravelCostType = function(value) {
  jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional int64 per_hour_travel_cost = 13;
 * @return {number}
 */
proto.cbv1.VendorContract.prototype.getPerHourTravelCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.cbv1.VendorContract.prototype.setPerHourTravelCost = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * repeated PayloadType payloadTypes = 14;
 * @return {!Array<!proto.cbv1.PayloadType>}
 */
proto.cbv1.VendorContract.prototype.getPayloadtypesList = function() {
  return /** @type{!Array<!proto.cbv1.PayloadType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_payload_payload_pb.PayloadType, 14));
};


/** @param {!Array<!proto.cbv1.PayloadType>} value */
proto.cbv1.VendorContract.prototype.setPayloadtypesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.cbv1.PayloadType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.PayloadType}
 */
proto.cbv1.VendorContract.prototype.addPayloadtypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.cbv1.PayloadType, opt_index);
};


proto.cbv1.VendorContract.prototype.clearPayloadtypesList = function() {
  this.setPayloadtypesList([]);
};


/**
 * optional bool archived = 15;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.VendorContract.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 15, false));
};


/** @param {boolean} value */
proto.cbv1.VendorContract.prototype.setArchived = function(value) {
  jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional int64 created_unix = 16;
 * @return {number}
 */
proto.cbv1.VendorContract.prototype.getCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.cbv1.VendorContract.prototype.setCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 updated_unix = 17;
 * @return {number}
 */
proto.cbv1.VendorContract.prototype.getUpdatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {number} value */
proto.cbv1.VendorContract.prototype.setUpdatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string reference_number = 18;
 * @return {string}
 */
proto.cbv1.VendorContract.prototype.getReferenceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/** @param {string} value */
proto.cbv1.VendorContract.prototype.setReferenceNumber = function(value) {
  jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional bool is_default = 19;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.VendorContract.prototype.getIsDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 19, false));
};


/** @param {boolean} value */
proto.cbv1.VendorContract.prototype.setIsDefault = function(value) {
  jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional int64 per_mile_travel_cost = 20;
 * @return {number}
 */
proto.cbv1.VendorContract.prototype.getPerMileTravelCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/** @param {number} value */
proto.cbv1.VendorContract.prototype.setPerMileTravelCost = function(value) {
  jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional VendorContractType contract_type = 21;
 * @return {?proto.cbv1.VendorContractType}
 */
proto.cbv1.VendorContract.prototype.getContractType = function() {
  return /** @type{?proto.cbv1.VendorContractType} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.VendorContractType, 21));
};


/** @param {?proto.cbv1.VendorContractType|undefined} value */
proto.cbv1.VendorContract.prototype.setContractType = function(value) {
  jspb.Message.setWrapperField(this, 21, value);
};


proto.cbv1.VendorContract.prototype.clearContractType = function() {
  this.setContractType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.VendorContract.prototype.hasContractType = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional bool fuel_surcharge = 22;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.VendorContract.prototype.getFuelSurcharge = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 22, false));
};


/** @param {boolean} value */
proto.cbv1.VendorContract.prototype.setFuelSurcharge = function(value) {
  jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional int64 minimum_charge = 23;
 * @return {number}
 */
proto.cbv1.VendorContract.prototype.getMinimumCharge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/** @param {number} value */
proto.cbv1.VendorContract.prototype.setMinimumCharge = function(value) {
  jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int64 file_id = 24;
 * @return {number}
 */
proto.cbv1.VendorContract.prototype.getFileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/** @param {number} value */
proto.cbv1.VendorContract.prototype.setFileId = function(value) {
  jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * repeated FixedPointCost poin_to_point_locs = 25;
 * @return {!Array<!proto.cbv1.FixedPointCost>}
 */
proto.cbv1.VendorContract.prototype.getPoinToPointLocsList = function() {
  return /** @type{!Array<!proto.cbv1.FixedPointCost>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.FixedPointCost, 25));
};


/** @param {!Array<!proto.cbv1.FixedPointCost>} value */
proto.cbv1.VendorContract.prototype.setPoinToPointLocsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.cbv1.FixedPointCost=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.FixedPointCost}
 */
proto.cbv1.VendorContract.prototype.addPoinToPointLocs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.cbv1.FixedPointCost, opt_index);
};


proto.cbv1.VendorContract.prototype.clearPoinToPointLocsList = function() {
  this.setPoinToPointLocsList([]);
};


/**
 * optional bool is_return_trip_billable = 26;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.VendorContract.prototype.getIsReturnTripBillable = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 26, false));
};


/** @param {boolean} value */
proto.cbv1.VendorContract.prototype.setIsReturnTripBillable = function(value) {
  jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional bool is_back_haul_enabled = 27;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.VendorContract.prototype.getIsBackHaulEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 27, false));
};


/** @param {boolean} value */
proto.cbv1.VendorContract.prototype.setIsBackHaulEnabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional int32 back_haul_charge_percent = 28;
 * @return {number}
 */
proto.cbv1.VendorContract.prototype.getBackHaulChargePercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/** @param {number} value */
proto.cbv1.VendorContract.prototype.setBackHaulChargePercent = function(value) {
  jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * repeated string ZipCodesNotMapped = 29;
 * @return {!Array<string>}
 */
proto.cbv1.VendorContract.prototype.getZipcodesnotmappedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 29));
};


/** @param {!Array<string>} value */
proto.cbv1.VendorContract.prototype.setZipcodesnotmappedList = function(value) {
  jspb.Message.setField(this, 29, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.VendorContract.prototype.addZipcodesnotmapped = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 29, value, opt_index);
};


proto.cbv1.VendorContract.prototype.clearZipcodesnotmappedList = function() {
  this.setZipcodesnotmappedList([]);
};


/**
 * optional NullableTime expiration_time = 30;
 * @return {?proto.cbv1.NullableTime}
 */
proto.cbv1.VendorContract.prototype.getExpirationTime = function() {
  return /** @type{?proto.cbv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_types_types_pb.NullableTime, 30));
};


/** @param {?proto.cbv1.NullableTime|undefined} value */
proto.cbv1.VendorContract.prototype.setExpirationTime = function(value) {
  jspb.Message.setWrapperField(this, 30, value);
};


proto.cbv1.VendorContract.prototype.clearExpirationTime = function() {
  this.setExpirationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.VendorContract.prototype.hasExpirationTime = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional bool is_expired = 31;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.VendorContract.prototype.getIsExpired = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 31, false));
};


/** @param {boolean} value */
proto.cbv1.VendorContract.prototype.setIsExpired = function(value) {
  jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional NullableTime effective_time = 32;
 * @return {?proto.cbv1.NullableTime}
 */
proto.cbv1.VendorContract.prototype.getEffectiveTime = function() {
  return /** @type{?proto.cbv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_types_types_pb.NullableTime, 32));
};


/** @param {?proto.cbv1.NullableTime|undefined} value */
proto.cbv1.VendorContract.prototype.setEffectiveTime = function(value) {
  jspb.Message.setWrapperField(this, 32, value);
};


proto.cbv1.VendorContract.prototype.clearEffectiveTime = function() {
  this.setEffectiveTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.VendorContract.prototype.hasEffectiveTime = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional bool is_effective = 33;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.VendorContract.prototype.getIsEffective = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 33, false));
};


/** @param {boolean} value */
proto.cbv1.VendorContract.prototype.setIsEffective = function(value) {
  jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * optional UserDetails created_by = 34;
 * @return {?proto.cbv1.UserDetails}
 */
proto.cbv1.VendorContract.prototype.getCreatedBy = function() {
  return /** @type{?proto.cbv1.UserDetails} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billingsettings_settings_pb.UserDetails, 34));
};


/** @param {?proto.cbv1.UserDetails|undefined} value */
proto.cbv1.VendorContract.prototype.setCreatedBy = function(value) {
  jspb.Message.setWrapperField(this, 34, value);
};


proto.cbv1.VendorContract.prototype.clearCreatedBy = function() {
  this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.VendorContract.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional UserDetails last_edited_by = 35;
 * @return {?proto.cbv1.UserDetails}
 */
proto.cbv1.VendorContract.prototype.getLastEditedBy = function() {
  return /** @type{?proto.cbv1.UserDetails} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billingsettings_settings_pb.UserDetails, 35));
};


/** @param {?proto.cbv1.UserDetails|undefined} value */
proto.cbv1.VendorContract.prototype.setLastEditedBy = function(value) {
  jspb.Message.setWrapperField(this, 35, value);
};


proto.cbv1.VendorContract.prototype.clearLastEditedBy = function() {
  this.setLastEditedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.VendorContract.prototype.hasLastEditedBy = function() {
  return jspb.Message.getField(this, 35) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.MileageCost = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.MileageCost, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.MileageCost.displayName = 'proto.cbv1.MileageCost';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.MileageCost.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.MileageCost.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.MileageCost} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MileageCost.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lowerBoundMiles: jspb.Message.getFieldWithDefault(msg, 2, 0),
    upperBoundMiles: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cost: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.MileageCost}
 */
proto.cbv1.MileageCost.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.MileageCost;
  return proto.cbv1.MileageCost.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.MileageCost} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.MileageCost}
 */
proto.cbv1.MileageCost.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLowerBoundMiles(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpperBoundMiles(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.MileageCost.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.MileageCost.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.MileageCost} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MileageCost.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLowerBoundMiles();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUpperBoundMiles();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCost();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.MileageCost.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.MileageCost.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 lower_bound_miles = 2;
 * @return {number}
 */
proto.cbv1.MileageCost.prototype.getLowerBoundMiles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.MileageCost.prototype.setLowerBoundMiles = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 upper_bound_miles = 3;
 * @return {number}
 */
proto.cbv1.MileageCost.prototype.getUpperBoundMiles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.MileageCost.prototype.setUpperBoundMiles = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cost = 4;
 * @return {number}
 */
proto.cbv1.MileageCost.prototype.getCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.MileageCost.prototype.setCost = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.FuelSurchargeBrackets = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.FuelSurchargeBrackets, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.FuelSurchargeBrackets.displayName = 'proto.cbv1.FuelSurchargeBrackets';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.FuelSurchargeBrackets.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.FuelSurchargeBrackets.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.FuelSurchargeBrackets} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FuelSurchargeBrackets.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lowerBound: jspb.Message.getFieldWithDefault(msg, 2, 0),
    upperBound: jspb.Message.getFieldWithDefault(msg, 3, 0),
    value: +jspb.Message.getFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.FuelSurchargeBrackets}
 */
proto.cbv1.FuelSurchargeBrackets.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.FuelSurchargeBrackets;
  return proto.cbv1.FuelSurchargeBrackets.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.FuelSurchargeBrackets} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.FuelSurchargeBrackets}
 */
proto.cbv1.FuelSurchargeBrackets.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLowerBound(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpperBound(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.FuelSurchargeBrackets.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.FuelSurchargeBrackets.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.FuelSurchargeBrackets} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FuelSurchargeBrackets.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLowerBound();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUpperBound();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.FuelSurchargeBrackets.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.FuelSurchargeBrackets.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 lower_bound = 2;
 * @return {number}
 */
proto.cbv1.FuelSurchargeBrackets.prototype.getLowerBound = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.FuelSurchargeBrackets.prototype.setLowerBound = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 upper_bound = 3;
 * @return {number}
 */
proto.cbv1.FuelSurchargeBrackets.prototype.getUpperBound = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.FuelSurchargeBrackets.prototype.setUpperBound = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional float value = 4;
 * @return {number}
 */
proto.cbv1.FuelSurchargeBrackets.prototype.getValue = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.cbv1.FuelSurchargeBrackets.prototype.setValue = function(value) {
  jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.FixedPointCost = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.FixedPointCost, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.FixedPointCost.displayName = 'proto.cbv1.FixedPointCost';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.FixedPointCost.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.FixedPointCost.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.FixedPointCost} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FixedPointCost.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    originCity: jspb.Message.getFieldWithDefault(msg, 2, ""),
    originState: jspb.Message.getFieldWithDefault(msg, 3, ""),
    originZip: jspb.Message.getFieldWithDefault(msg, 4, ""),
    destinationCity: jspb.Message.getFieldWithDefault(msg, 5, ""),
    destinationState: jspb.Message.getFieldWithDefault(msg, 6, ""),
    destinationZip: jspb.Message.getFieldWithDefault(msg, 7, ""),
    cost: jspb.Message.getFieldWithDefault(msg, 8, 0),
    isBackHaul: jspb.Message.getFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.FixedPointCost}
 */
proto.cbv1.FixedPointCost.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.FixedPointCost;
  return proto.cbv1.FixedPointCost.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.FixedPointCost} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.FixedPointCost}
 */
proto.cbv1.FixedPointCost.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginCity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginState(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginZip(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationState(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationZip(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCost(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBackHaul(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.FixedPointCost.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.FixedPointCost.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.FixedPointCost} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FixedPointCost.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOriginCity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOriginState();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOriginZip();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDestinationCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDestinationState();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDestinationZip();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCost();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getIsBackHaul();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.FixedPointCost.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.FixedPointCost.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string origin_city = 2;
 * @return {string}
 */
proto.cbv1.FixedPointCost.prototype.getOriginCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.FixedPointCost.prototype.setOriginCity = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string origin_state = 3;
 * @return {string}
 */
proto.cbv1.FixedPointCost.prototype.getOriginState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.FixedPointCost.prototype.setOriginState = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string origin_zip = 4;
 * @return {string}
 */
proto.cbv1.FixedPointCost.prototype.getOriginZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.FixedPointCost.prototype.setOriginZip = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string destination_city = 5;
 * @return {string}
 */
proto.cbv1.FixedPointCost.prototype.getDestinationCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.FixedPointCost.prototype.setDestinationCity = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string destination_state = 6;
 * @return {string}
 */
proto.cbv1.FixedPointCost.prototype.getDestinationState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cbv1.FixedPointCost.prototype.setDestinationState = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string destination_zip = 7;
 * @return {string}
 */
proto.cbv1.FixedPointCost.prototype.getDestinationZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cbv1.FixedPointCost.prototype.setDestinationZip = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 cost = 8;
 * @return {number}
 */
proto.cbv1.FixedPointCost.prototype.getCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cbv1.FixedPointCost.prototype.setCost = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool is_back_haul = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.FixedPointCost.prototype.getIsBackHaul = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.cbv1.FixedPointCost.prototype.setIsBackHaul = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOVendorPayloadTypePercentage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOVendorPayloadTypePercentage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOVendorPayloadTypePercentage.displayName = 'proto.cbv1.LMOVendorPayloadTypePercentage';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOVendorPayloadTypePercentage.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOVendorPayloadTypePercentage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOVendorPayloadTypePercentage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOVendorPayloadTypePercentage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lmo: (f = msg.getLmo()) && proto_cybertron_types_types_pb.IdName.toObject(includeInstance, f),
    vendor: (f = msg.getVendor()) && proto_cybertron_types_types_pb.IdName.toObject(includeInstance, f),
    payloadType: (f = msg.getPayloadType()) && proto_cybertron_payload_payload_pb.PayloadType.toObject(includeInstance, f),
    percentage: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOVendorPayloadTypePercentage}
 */
proto.cbv1.LMOVendorPayloadTypePercentage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOVendorPayloadTypePercentage;
  return proto.cbv1.LMOVendorPayloadTypePercentage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOVendorPayloadTypePercentage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOVendorPayloadTypePercentage}
 */
proto.cbv1.LMOVendorPayloadTypePercentage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_cybertron_types_types_pb.IdName;
      reader.readMessage(value,proto_cybertron_types_types_pb.IdName.deserializeBinaryFromReader);
      msg.setLmo(value);
      break;
    case 3:
      var value = new proto_cybertron_types_types_pb.IdName;
      reader.readMessage(value,proto_cybertron_types_types_pb.IdName.deserializeBinaryFromReader);
      msg.setVendor(value);
      break;
    case 4:
      var value = new proto_cybertron_payload_payload_pb.PayloadType;
      reader.readMessage(value,proto_cybertron_payload_payload_pb.PayloadType.deserializeBinaryFromReader);
      msg.setPayloadType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOVendorPayloadTypePercentage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOVendorPayloadTypePercentage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOVendorPayloadTypePercentage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOVendorPayloadTypePercentage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLmo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_cybertron_types_types_pb.IdName.serializeBinaryToWriter
    );
  }
  f = message.getVendor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_cybertron_types_types_pb.IdName.serializeBinaryToWriter
    );
  }
  f = message.getPayloadType();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_cybertron_payload_payload_pb.PayloadType.serializeBinaryToWriter
    );
  }
  f = message.getPercentage();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.LMOVendorPayloadTypePercentage.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.LMOVendorPayloadTypePercentage.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional IdName lmo = 2;
 * @return {?proto.cbv1.IdName}
 */
proto.cbv1.LMOVendorPayloadTypePercentage.prototype.getLmo = function() {
  return /** @type{?proto.cbv1.IdName} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_types_types_pb.IdName, 2));
};


/** @param {?proto.cbv1.IdName|undefined} value */
proto.cbv1.LMOVendorPayloadTypePercentage.prototype.setLmo = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cbv1.LMOVendorPayloadTypePercentage.prototype.clearLmo = function() {
  this.setLmo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.LMOVendorPayloadTypePercentage.prototype.hasLmo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional IdName vendor = 3;
 * @return {?proto.cbv1.IdName}
 */
proto.cbv1.LMOVendorPayloadTypePercentage.prototype.getVendor = function() {
  return /** @type{?proto.cbv1.IdName} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_types_types_pb.IdName, 3));
};


/** @param {?proto.cbv1.IdName|undefined} value */
proto.cbv1.LMOVendorPayloadTypePercentage.prototype.setVendor = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv1.LMOVendorPayloadTypePercentage.prototype.clearVendor = function() {
  this.setVendor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.LMOVendorPayloadTypePercentage.prototype.hasVendor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PayloadType payload_type = 4;
 * @return {?proto.cbv1.PayloadType}
 */
proto.cbv1.LMOVendorPayloadTypePercentage.prototype.getPayloadType = function() {
  return /** @type{?proto.cbv1.PayloadType} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_payload_payload_pb.PayloadType, 4));
};


/** @param {?proto.cbv1.PayloadType|undefined} value */
proto.cbv1.LMOVendorPayloadTypePercentage.prototype.setPayloadType = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.cbv1.LMOVendorPayloadTypePercentage.prototype.clearPayloadType = function() {
  this.setPayloadType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.LMOVendorPayloadTypePercentage.prototype.hasPayloadType = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 percentage = 5;
 * @return {number}
 */
proto.cbv1.LMOVendorPayloadTypePercentage.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.LMOVendorPayloadTypePercentage.prototype.setPercentage = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.FuelSurchargeContract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.FuelSurchargeContract.repeatedFields_, null);
};
goog.inherits(proto.cbv1.FuelSurchargeContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.FuelSurchargeContract.displayName = 'proto.cbv1.FuelSurchargeContract';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.FuelSurchargeContract.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.FuelSurchargeContract.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.FuelSurchargeContract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.FuelSurchargeContract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FuelSurchargeContract.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fuelChargeType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fileId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    contractTypesList: jspb.Message.toObjectList(msg.getContractTypesList(),
    proto.cbv1.VendorContractType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.FuelSurchargeContract}
 */
proto.cbv1.FuelSurchargeContract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.FuelSurchargeContract;
  return proto.cbv1.FuelSurchargeContract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.FuelSurchargeContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.FuelSurchargeContract}
 */
proto.cbv1.FuelSurchargeContract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.cbv1.FuelChargeType} */ (reader.readEnum());
      msg.setFuelChargeType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFileId(value);
      break;
    case 5:
      var value = new proto.cbv1.VendorContractType;
      reader.readMessage(value,proto.cbv1.VendorContractType.deserializeBinaryFromReader);
      msg.addContractTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.FuelSurchargeContract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.FuelSurchargeContract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.FuelSurchargeContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FuelSurchargeContract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFuelChargeType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFileId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getContractTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.cbv1.VendorContractType.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.FuelSurchargeContract.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.FuelSurchargeContract.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.FuelSurchargeContract.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.FuelSurchargeContract.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional FuelChargeType fuel_charge_type = 3;
 * @return {!proto.cbv1.FuelChargeType}
 */
proto.cbv1.FuelSurchargeContract.prototype.getFuelChargeType = function() {
  return /** @type {!proto.cbv1.FuelChargeType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.cbv1.FuelChargeType} value */
proto.cbv1.FuelSurchargeContract.prototype.setFuelChargeType = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 file_id = 4;
 * @return {number}
 */
proto.cbv1.FuelSurchargeContract.prototype.getFileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.FuelSurchargeContract.prototype.setFileId = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated VendorContractType contract_types = 5;
 * @return {!Array<!proto.cbv1.VendorContractType>}
 */
proto.cbv1.FuelSurchargeContract.prototype.getContractTypesList = function() {
  return /** @type{!Array<!proto.cbv1.VendorContractType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.VendorContractType, 5));
};


/** @param {!Array<!proto.cbv1.VendorContractType>} value */
proto.cbv1.FuelSurchargeContract.prototype.setContractTypesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.cbv1.VendorContractType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.VendorContractType}
 */
proto.cbv1.FuelSurchargeContract.prototype.addContractTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.cbv1.VendorContractType, opt_index);
};


proto.cbv1.FuelSurchargeContract.prototype.clearContractTypesList = function() {
  this.setContractTypesList([]);
};


goog.object.extend(exports, proto.cbv1);
