import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ct-lmo-training-videos',
  styleUrls: ['./lmo-training-videos.component.scss'],
  templateUrl: './lmo-training-videos.component.html',
})
export class LmoTrainingVideosComponent implements OnInit {
  constructor() {}

  public ngOnInit() {}
}
