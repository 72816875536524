import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormArray, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';
import { createPayloadDropoffTask, createPayloadPickupTask, createTrailerDropoffTask, createTrailerPickupTask, createTruckDropoffTask, createTruckPickupTask, flattenTasks, } from '~lmo/utilities/create-order';
import { CreateOrderRequest } from '~proto/order/order_api_pb';
import { OrderType } from '~proto/types/types_pb';
import { UserDetailsForIntercomRequest } from '~proto/user/user_api_pb';
import { UserAPI } from '~proto/user/user_api_pb_service';
import { trackById } from '~utilities/trackById';
var CREATE_REPEATED_ORDER_SAVED_SETTINGS = 'CREATE_REPEATED_ORDER_SAVED_SETTINGS';
var CreateRepeatedOrderTrailerAndTruckComponent = /** @class */ (function () {
    function CreateRepeatedOrderTrailerAndTruckComponent(orderService, fb, recentService, snackBar, payloadService, router, contractService, roleService, grpcService) {
        this.orderService = orderService;
        this.fb = fb;
        this.recentService = recentService;
        this.snackBar = snackBar;
        this.payloadService = payloadService;
        this.router = router;
        this.contractService = contractService;
        this.roleService = roleService;
        this.grpcService = grpcService;
        this.networkActive$$ = new BehaviorSubject(false);
        this.orderTaskGroups = new FormArray([]);
        this.truckingContracts = [];
        this.trackById = trackById;
        this.businessLinesList$$ = new BehaviorSubject(null);
        this.isBusinessLineEnabled$$ = new BehaviorSubject(false);
        this.isCostCenterRequired$$ = new BehaviorSubject(false);
        this.isPurchaseOrderRequired$$ = new BehaviorSubject(false);
        this.isSalesOrderRequired$$ = new BehaviorSubject(false);
        this.isMaintenanceOrderRequired$$ = new BehaviorSubject(false);
        this.isBusinessLineCostCenterRelationEnabled$$ = new BehaviorSubject(false);
    }
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.formValue = function () {
        return this.formGroup.value;
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.orderTaskGroupsValue = function () {
        return this.orderTaskGroups.value;
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.payloadGroups$ = this.payloadService.allPayloadGroups$;
        this.insertLineBefore(0);
        this.loadRequiredFieldSettings();
        this.getFormFromLocalStorage().then(function (lastPayloadChoices) {
            _this.formGroup = _this.fb.group({
                contractTypeId: [null],
                costCenter: [null, _this.isCostCenterRequired$$.value ? [Validators.required] : ''],
                costCenterId: [null],
                description: [null, []],
                truckingVendor: [lastPayloadChoices.truckingVendor],
                vendorContractId: [null],
            });
            _this.recentService.loadSalesOrder();
            _this.setupPayloads();
            _this.listenContractChange(lastPayloadChoices.truckingVendor);
            _this.listenContractTypeChanges();
            _this.checkIfBusinessLineEnabled();
            _this.checkIsBusinessLineCostCenterRelationEnabled();
            _this.orderTaskGroups
                .at(0)
                .get('businessLine')
                .valueChanges.subscribe(function () {
                _this.formGroup.get('costCenter').setValue(null);
                _this.formGroup.get('costCenterId').setValue(null);
            });
        });
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.listenContractTypeChanges = function () {
        var _this = this;
        this.contractType$ = this.formGroup.get('contractTypeId').valueChanges;
        this.contractTypes$ = this.contractService.contractTypes$;
        combineLatest([
            this.formGroup.get('contractTypeId').valueChanges,
            this.contractService.nonExpiredContracts$,
        ]).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), contractTypeId = _b[0], contracts = _b[1];
            var contractTypeContracts = contracts.filter(function (contract) {
                if (contract.contractType) {
                    return +contractTypeId <= 0 || contract.contractType.id === +contractTypeId;
                }
                return +contractTypeId <= 0;
            });
            var vendor = _this.formGroup.get('truckingVendor').value;
            if (vendor) {
                var vendorContract = contractTypeContracts.find(function (contract) { return contract.vendor.id === vendor.id; });
                if (!(vendorContract && vendorContract.id > 0)) {
                    _this.formGroup.get('truckingVendor').setValue(null);
                }
            }
        });
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.listenContractChange = function (initialVendor) {
        var _this = this;
        combineLatest([
            this.formGroup.get('truckingVendor').valueChanges.pipe(startWith(initialVendor)),
            this.formGroup.get('contractTypeId').valueChanges.pipe(startWith(-1)),
            this.contractService.nonExpiredContracts$,
        ]).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 3), vendor = _b[0], contractTypeId = _b[1], contracts = _b[2];
            var contractTypeContracts = contracts.filter(function (contract) {
                if (contract.contractType) {
                    return +contractTypeId <= 0 || contract.contractType.id === +contractTypeId;
                }
                return +contractTypeId <= 0;
            });
            var contractId = _this.formGroup.get('vendorContractId').value;
            if (contractId) {
                var contractById = contractTypeContracts.find(function (contract) { return contract.id === +contractId; });
                if (!(contractById && contractById.id > 0)) {
                    _this.formGroup.get('vendorContractId').setValue(null);
                }
            }
            if (vendor) {
                _this.truckingContracts = contracts.filter(function (contract) {
                    return contract.vendor.id === vendor.id &&
                        contract.isEffective &&
                        (contractTypeId <= 0 || (contract.contractType && contract.contractType.id === contractTypeId));
                });
            }
        });
        combineLatest([
            this.formGroup.get('vendorContractId').valueChanges,
            this.contractService.nonExpiredContracts$,
        ]).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), contractId = _b[0], contracts = _b[1];
            if (contractId) {
                var contractById = contracts.find(function (contract) { return contract.id === +contractId; });
                _this.formGroup.get('contractTypeId').setValue(contractById.contractType ? contractById.contractType.id : -1);
            }
        });
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.getStats = function () {
        var _this = this;
        this.orderService.getStats$().subscribe(function (stats) {
            _this.stats = stats;
        });
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.selectOrderTaskGroupLinePickupLocation = function (index, event) {
        this.orderTaskGroups
            .at(index)
            .get('pickupLocation')
            .setValue(event.pickupFrom);
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.selectOrderTaskGroupLineDropoffLocation = function (index, event) {
        this.orderTaskGroups
            .at(index)
            .get('dropoffLocation')
            .setValue(event.pickupFrom);
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.selectOrderTaskGroupLinePayload = function (index, event) {
        this.orderTaskGroups
            .at(index)
            .get('payload')
            .setValue(event.payload);
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.selectOrderTaskGroupLineTruck = function (index, event) {
        this.orderTaskGroups
            .at(index)
            .get('truck')
            .setValue(event.truck);
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.selectOrderTaskGroupLineTrailer = function (index, event) {
        this.orderTaskGroups
            .at(index)
            .get('trailer')
            .setValue(event.trailer);
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.selectCustomOrderPurchaseOrderName = function (index, event) {
        this.orderTaskGroups
            .at(index)
            .get('purchaseOrderName')
            .setValue(event.purchaseOrderName);
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.selectCustomSalesOrderNumber = function (index, event) {
        this.orderTaskGroups
            .at(index)
            .get('orderNumber')
            .setValue(event.orderNumber);
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.selectCustomMaintenanceOrderNumber = function (index, event) {
        this.orderTaskGroups
            .at(index)
            .get('maintenanceOrderNumber')
            .setValue(event.maintenanceOrderNumber);
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.removeOrderTaskGroupLink = function (index) {
        this.orderTaskGroups.removeAt(index);
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.insertLineBefore = function (index) {
        var fg = this.getNewFormLine();
        var currentLine = this.orderTaskGroups.at(index);
        if (currentLine) {
            fg.patchValue({
                businessLine: currentLine.get('businessLine').value,
                dropoffLocation: currentLine.get('dropoffLocation').value,
                isAsapOrder: currentLine.get('isAsapOrder').value,
                maintenanceOrderNumber: currentLine.get('maintenanceOrderNumber').value,
                orderNumber: currentLine.get('orderNumber').value,
                payload: currentLine.get('payload').value,
                pickupLocation: currentLine.get('pickupLocation').value,
                purchaseOrderName: currentLine.get('purchaseOrderName').value,
                scheduledDeliveryWindow: currentLine.get('scheduledDeliveryWindow').value,
            });
        }
        this.orderTaskGroups.insert(index, fg);
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.insertLineAfter = function (index) {
        var fg = this.getNewFormLine();
        var currentLine = this.orderTaskGroups.at(index);
        if (currentLine) {
            fg.patchValue({
                businessLine: currentLine.get('businessLine').value,
                dropoffLocation: currentLine.get('dropoffLocation').value,
                isAsapOrder: currentLine.get('isAsapOrder').value,
                maintenanceOrderNumber: currentLine.get('maintenanceOrderNumber').value,
                orderNumber: currentLine.get('orderNumber').value,
                payload: currentLine.get('payload').value,
                pickupLocation: currentLine.get('pickupLocation').value,
                purchaseOrderName: currentLine.get('purchaseOrderName').value,
                scheduledDeliveryWindow: currentLine.get('scheduledDeliveryWindow').value,
            });
        }
        if (index + 1 >= this.orderTaskGroups.length) {
            this.orderTaskGroups.push(fg);
        }
        else {
            this.orderTaskGroups.insert(index + 1, fg);
        }
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.swapLocations = function (index, _event) {
        var currentLine = this.orderTaskGroups.at(index);
        if (currentLine) {
            currentLine.patchValue({
                dropoffLocation: currentLine.get('pickupLocation').value,
                pickupLocation: currentLine.get('dropoffLocation').value,
            });
        }
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.getNewFormLine = function () {
        var fg = this.fb.group({
            businessLine: [null],
            dropoffLocation: [null, [Validators.required]],
            isAsapOrder: [null, Validators.required],
            maintenanceOrderNumber: [null, this.isMaintenanceOrderRequired$$.value ? [Validators.required] : ''],
            orderNumber: [null, this.isSalesOrderRequired$$.value ? [Validators.required] : ''],
            payload: [null, [Validators.required]],
            pickupLocation: [null, [Validators.required]],
            purchaseOrderName: [null, this.isPurchaseOrderRequired$$.value ? [Validators.required] : ''],
            scheduledDeliveryWindow: [null, [Validators.required]],
            trailer: [null, [Validators.required]],
            truck: [null, [Validators.required]],
        });
        this.listenToAsap(fg);
        return fg;
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.selected = function (event) {
        if (event.pickupFrom && event.pickupFrom.direction) {
            event.pickupFrom.directions = event.pickupFrom.direction;
        }
        this.formGroup.patchValue(event);
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.trackByControl = function (_index, control) {
        return control;
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.create = function ($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formValue, orderTaskGroups, requests, dropOff;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.formGroup.markAllAsTouched();
                        this.orderTaskGroups.markAllAsTouched();
                        if (this.formGroup.invalid || this.orderTaskGroups.invalid) {
                            return [2 /*return*/];
                        }
                        formValue = this.formValue();
                        orderTaskGroups = this.orderTaskGroupsValue();
                        this.requiredFieldsCheck(formValue, orderTaskGroups);
                        if (!this.isCCValid || !this.isPOValid || !this.isSOValid || !this.isMOValid) {
                            this.snackBar.open('Please fill all mandatory fields', null, {
                                duration: 3000,
                            });
                            return [2 /*return*/];
                        }
                        saveFormToLocalStorage(formValue);
                        requests = orderTaskGroups.map(function (taskGroup) {
                            return _this.createOrderFromTaskGroup(formValue, taskGroup);
                        });
                        this.networkActive$$.next(true);
                        return [4 /*yield*/, this.orderService
                                .createMultipleOrders$(requests)
                                .pipe(finalize(function () { return _this.networkActive$$.next(false); }))
                                .toPromise()];
                    case 1:
                        _a.sent();
                        this.snackBar.open('Loads successfully scheduled!', null, {
                            duration: 3000,
                        });
                        dropOff = requests[requests.length - 1].getTaskRequestsList()[1];
                        if (!dropOff) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.router.navigate(['/', 'lmo', 'jobs', dropOff.getSiteId(), 'orders', 'pending'])];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.router.navigate(['../'])];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.createOrderFromTaskGroup = function (formValue, taskGroup) {
        var createOrderRequest = new CreateOrderRequest();
        createOrderRequest.setOrderQuantity(1);
        createOrderRequest.setIsAsapOrder(taskGroup.isAsapOrder);
        createOrderRequest.setDescription(formValue.description);
        createOrderRequest.setType(OrderType.ORDER_TYPE_DELIVERY);
        createOrderRequest.setTruckId(taskGroup.truck.id);
        createOrderRequest.setTrailerId(taskGroup.trailer.id);
        createOrderRequest.setCostCenter(formValue.costCenter);
        createOrderRequest.setCostCenterId(formValue.costCenterId);
        createOrderRequest.setVendorContractId(formValue.vendorContractId);
        createOrderRequest.setContractTypeId(formValue.contractTypeId);
        createOrderRequest.setBusinessLineId(taskGroup.businessLine);
        if (taskGroup.scheduledDeliveryWindow && taskGroup.scheduledDeliveryWindow.length) {
            var start = moment(taskGroup.scheduledDeliveryWindow[0]);
            var end = moment(taskGroup.scheduledDeliveryWindow[1]);
            var windowDuration = moment.duration(end.diff(start)).asMinutes();
            createOrderRequest.setDeliveryWindowStart(start.unix());
            createOrderRequest.setDeliveryWindowDurationMinutes(windowDuration);
        }
        if (formValue.truckingVendor) {
            createOrderRequest.setTruckingVendorId(formValue.truckingVendor.id);
        }
        var pickupLocation = taskGroup.pickupLocation, dropoffLocation = taskGroup.dropoffLocation, payload = taskGroup.payload, purchaseOrderName = taskGroup.purchaseOrderName, orderNumber = taskGroup.orderNumber, maintenanceOrderNumber = taskGroup.maintenanceOrderNumber;
        var pickupTruckTask = createTruckPickupTask({ truckPickupLocation: pickupLocation });
        var pickupTrailerTask = createTrailerPickupTask({ trailerPickupLocation: pickupLocation });
        var pickupPayloadTask = createPayloadPickupTask({
            maintenanceOrderNumber: maintenanceOrderNumber,
            orderNumber: orderNumber,
            payload: payload,
            pickupFrom: pickupLocation,
            purchaseOrderName: purchaseOrderName,
            quantity: 1,
        });
        var dropoffPayloadTask = createPayloadDropoffTask({
            dropoffSite: dropoffLocation,
            maintenanceOrderNumber: maintenanceOrderNumber,
            orderNumber: orderNumber,
            payload: payload,
            purchaseOrderName: purchaseOrderName,
            quantity: 1,
        });
        dropoffPayloadTask.setIsBillable(true);
        var dropoffTrailerTask = createTrailerDropoffTask({ trailerDropoffLocation: dropoffLocation });
        var dropoffTruckTask = createTruckDropoffTask({ truckDropoffLocation: dropoffLocation });
        createOrderRequest.setTaskRequestsList([
            pickupTruckTask,
            pickupTrailerTask,
            pickupPayloadTask,
            dropoffPayloadTask,
            dropoffTrailerTask,
            dropoffTruckTask,
        ]);
        return flattenTasks(createOrderRequest);
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.requiredFieldsCheck = function (formValue, customStopsValue) {
        this.isCCValid = this.isCostCenterRequired$$.value ? formValue.costCenter : 'true';
        this.isPOValid = this.isPurchaseOrderRequired$$.value ? customStopsValue[0].purchaseOrderName : 'true';
        this.isSOValid = this.isSalesOrderRequired$$.value ? customStopsValue[0].orderNumber : 'true';
        this.isMOValid = this.isMaintenanceOrderRequired$$.value ? customStopsValue[0].maintenanceOrderNumber : 'true';
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.getFormFromLocalStorage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var saved;
            return tslib_1.__generator(this, function (_a) {
                saved = JSON.parse(localStorage.getItem(CREATE_REPEATED_ORDER_SAVED_SETTINGS));
                if (!saved) {
                    return [2 /*return*/, {
                            truckingVendor: null,
                        }];
                }
                return [2 /*return*/, saved];
            });
        });
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.listenToAsap = function (formGroup) {
        formGroup
            .get('isAsapOrder')
            .valueChanges.pipe(startWith(formGroup.get('isAsapOrder').value))
            .subscribe(function (value) {
            if (value) {
                formGroup.get('scheduledDeliveryWindow').disable();
                formGroup.get('scheduledDeliveryWindow').reset();
            }
            else {
                formGroup.get('scheduledDeliveryWindow').enable();
            }
        });
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.checkDetentionWarning = function (isAsapOrder, scheduleWindow) {
        if (isAsapOrder) {
            return false;
        }
        var start = moment(scheduleWindow && scheduleWindow[0]);
        var end = moment(scheduleWindow && scheduleWindow[1]);
        var windowDuration = moment.duration(end.diff(start)).asHours();
        return windowDuration < 3;
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.trackByIndex = function (index) {
        return index;
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.trackByName = function (_index, record) {
        return record.name;
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.setupPayloads = function () {
        var _this = this;
        this.payloads$ = this.orderTaskGroups.valueChanges.pipe(map(function () { return _this.orderTaskGroupsValue(); }), map(function (value) { return value.filter(function (line) { return line.payload; }).map(function (line) { return line.payload; }); }));
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.businessLinesList = function () {
        var _this = this;
        this.roleService.businessLinesList$.subscribe(function (businessLines) {
            _this.businessLinesList$$.next(businessLines);
            if (businessLines.length === 1) {
                _this.orderTaskGroups
                    .at(0)
                    .get('businessLine')
                    .setValue(businessLines[0].id);
            }
        });
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.checkIfBusinessLineEnabled = function () {
        var _this = this;
        this.roleService.isBusinessLineEnabled$.subscribe(function (isBusinessLineEnabled) {
            _this.isBusinessLineEnabled$$.next(isBusinessLineEnabled);
            if (isBusinessLineEnabled) {
                _this.businessLinesList();
            }
        });
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.checkIsBusinessLineCostCenterRelationEnabled = function () {
        var _this = this;
        this.roleService.isBusinessLineCostCenterRelationEnabled$.subscribe(function (isBLCCRelationEnabled) {
            _this.isBusinessLineCostCenterRelationEnabled$$.next(isBLCCRelationEnabled);
        });
    };
    CreateRepeatedOrderTrailerAndTruckComponent.prototype.loadRequiredFieldSettings = function () {
        var _this = this;
        var request = new UserDetailsForIntercomRequest();
        this.grpcService.invoke$(UserAPI.UserDetailsForIntercom, request).subscribe(function (response) {
            _this.isCostCenterRequired$$.next(response.toObject().userDetails.isCostCenterRequired);
            _this.isPurchaseOrderRequired$$.next(response.toObject().userDetails.isPurchaseOrderRequired);
            _this.isSalesOrderRequired$$.next(response.toObject().userDetails.isSalesOrderRequired);
            _this.isMaintenanceOrderRequired$$.next(response.toObject().userDetails.isMaintenanceOrderRequired);
        });
    };
    return CreateRepeatedOrderTrailerAndTruckComponent;
}());
export { CreateRepeatedOrderTrailerAndTruckComponent };
function saveFormToLocalStorage(formValue) {
    var savedSettings = {
        truckingVendor: formValue.truckingVendor,
    };
    localStorage.setItem(CREATE_REPEATED_ORDER_SAVED_SETTINGS, JSON.stringify(savedSettings));
}
