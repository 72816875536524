/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_cybertron_types_types_pb = require('../../../proto/cybertron/types/types_pb.js');
var proto_cybertron_site_site_pb = require('../../../proto/cybertron/site/site_pb.js');
var proto_cybertron_alarm_alarm_pb = require('../../../proto/cybertron/alarm/alarm_pb.js');
var proto_cybertron_payload_payload_pb = require('../../../proto/cybertron/payload/payload_pb.js');
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
var proto_geo_geo_pb = require('../../../proto/geo/geo_pb.js');
var proto_cybertron_drivercertification_driverCertification_pb = require('../../../proto/cybertron/drivercertification/driverCertification_pb.js');
goog.exportSymbol('proto.cbv1.AddStockRequest', null, global);
goog.exportSymbol('proto.cbv1.AddStockResponse', null, global);
goog.exportSymbol('proto.cbv1.CreateSiteRequest', null, global);
goog.exportSymbol('proto.cbv1.CreateSiteResponse', null, global);
goog.exportSymbol('proto.cbv1.DeleteSiteRequest', null, global);
goog.exportSymbol('proto.cbv1.DeleteSiteResponse', null, global);
goog.exportSymbol('proto.cbv1.DeleteStockRequest', null, global);
goog.exportSymbol('proto.cbv1.DeleteStockResponse', null, global);
goog.exportSymbol('proto.cbv1.FavoriteSiteRequest', null, global);
goog.exportSymbol('proto.cbv1.FavoriteSiteResponse', null, global);
goog.exportSymbol('proto.cbv1.GetSiteRequest', null, global);
goog.exportSymbol('proto.cbv1.GetSiteResponse', null, global);
goog.exportSymbol('proto.cbv1.GetVendorSiteRequest', null, global);
goog.exportSymbol('proto.cbv1.GetVendorSiteResponse', null, global);
goog.exportSymbol('proto.cbv1.LMOListDistrictsRequest', null, global);
goog.exportSymbol('proto.cbv1.LMOListDistrictsResponse', null, global);
goog.exportSymbol('proto.cbv1.LMOListSitesRequest', null, global);
goog.exportSymbol('proto.cbv1.LMOListSitesResponse', null, global);
goog.exportSymbol('proto.cbv1.LMOPurchaseOrderShortcutsRequest', null, global);
goog.exportSymbol('proto.cbv1.LMOPurchaseOrderShortcutsResponse', null, global);
goog.exportSymbol('proto.cbv1.LMOSalesOrderNumberShortcutsRequest', null, global);
goog.exportSymbol('proto.cbv1.LMOSalesOrderNumberShortcutsResponse', null, global);
goog.exportSymbol('proto.cbv1.LMOSearchSitesRequest', null, global);
goog.exportSymbol('proto.cbv1.LMOSearchSitesResponse', null, global);
goog.exportSymbol('proto.cbv1.LMOSiteActivityFeedRequest', null, global);
goog.exportSymbol('proto.cbv1.LMOSiteActivityFeedResponse', null, global);
goog.exportSymbol('proto.cbv1.LMOSitesCalendarRequest', null, global);
goog.exportSymbol('proto.cbv1.LMOSitesCalendarResponse', null, global);
goog.exportSymbol('proto.cbv1.LMOSitesStatisticsRequest', null, global);
goog.exportSymbol('proto.cbv1.LMOSitesStatisticsResponse', null, global);
goog.exportSymbol('proto.cbv1.PutSiteRequest', null, global);
goog.exportSymbol('proto.cbv1.ReportSiteAvailabilityRequest', null, global);
goog.exportSymbol('proto.cbv1.ReportSiteAvailabilityResponse', null, global);
goog.exportSymbol('proto.cbv1.StockQuantityRequest', null, global);
goog.exportSymbol('proto.cbv1.StockUpdate', null, global);
goog.exportSymbol('proto.cbv1.StreamAccountSiteEventsRequest', null, global);
goog.exportSymbol('proto.cbv1.StreamAccountSiteEventsResponse', null, global);
goog.exportSymbol('proto.cbv1.UpdateSiteRequest', null, global);
goog.exportSymbol('proto.cbv1.UpdateSiteResponse', null, global);
goog.exportSymbol('proto.cbv1.UpdateStockRequest', null, global);
goog.exportSymbol('proto.cbv1.UpdateStockResponse', null, global);
goog.exportSymbol('proto.cbv1.UpdateStocksQuantityRequest', null, global);
goog.exportSymbol('proto.cbv1.UpdateStocksQuantityResponse', null, global);
goog.exportSymbol('proto.cbv1.VendorListArchivedSitesRequest', null, global);
goog.exportSymbol('proto.cbv1.VendorListArchivedSitesResponse', null, global);
goog.exportSymbol('proto.cbv1.VendorListSitesRequest', null, global);
goog.exportSymbol('proto.cbv1.VendorListSitesResponse', null, global);
goog.exportSymbol('proto.cbv1.VendorPossibleSiteForTask', null, global);
goog.exportSymbol('proto.cbv1.VendorSearchPossibleSitesForTaskRequest', null, global);
goog.exportSymbol('proto.cbv1.VendorSearchPossibleSitesForTaskResponse', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.CreateSiteRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.CreateSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateSiteRequest.displayName = 'proto.cbv1.CreateSiteRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.CreateSiteRequest.repeatedFields_ = [10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    geofence: (f = msg.getGeofence()) && proto_geo_geo_pb.Circle.toObject(includeInstance, f),
    directions: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    availability: (f = msg.getAvailability()) && proto_cybertron_site_site_pb.Availability.toObject(includeInstance, f),
    favorited: jspb.Message.getFieldWithDefault(msg, 8, false),
    address: jspb.Message.getFieldWithDefault(msg, 9, ""),
    availabledurationsList: jspb.Message.toObjectList(msg.getAvailabledurationsList(),
    proto_cybertron_site_site_pb.SiteAvailableDuration.toObject, includeInstance),
    driverCertificationIdsList: jspb.Message.getRepeatedField(msg, 11),
    districtId: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateSiteRequest}
 */
proto.cbv1.CreateSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateSiteRequest;
  return proto.cbv1.CreateSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateSiteRequest}
 */
proto.cbv1.CreateSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteType(value);
      break;
    case 3:
      var value = new proto_geo_geo_pb.Circle;
      reader.readMessage(value,proto_geo_geo_pb.Circle.deserializeBinaryFromReader);
      msg.setGeofence(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirections(value);
      break;
    case 5:
      var value = /** @type {!proto.cbv1.SiteStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = new proto_cybertron_site_site_pb.Availability;
      reader.readMessage(value,proto_cybertron_site_site_pb.Availability.deserializeBinaryFromReader);
      msg.setAvailability(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorited(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 10:
      var value = new proto_cybertron_site_site_pb.SiteAvailableDuration;
      reader.readMessage(value,proto_cybertron_site_site_pb.SiteAvailableDuration.deserializeBinaryFromReader);
      msg.addAvailabledurations(value);
      break;
    case 11:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setDriverCertificationIdsList(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDistrictId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGeofence();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_geo_geo_pb.Circle.serializeBinaryToWriter
    );
  }
  f = message.getDirections();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAvailability();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_cybertron_site_site_pb.Availability.serializeBinaryToWriter
    );
  }
  f = message.getFavorited();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAvailabledurationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto_cybertron_site_site_pb.SiteAvailableDuration.serializeBinaryToWriter
    );
  }
  f = message.getDriverCertificationIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      11,
      f
    );
  }
  f = message.getDistrictId();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.cbv1.CreateSiteRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.CreateSiteRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string site_type = 2;
 * @return {string}
 */
proto.cbv1.CreateSiteRequest.prototype.getSiteType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.CreateSiteRequest.prototype.setSiteType = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Circle geofence = 3;
 * @return {?proto.cbv1.Circle}
 */
proto.cbv1.CreateSiteRequest.prototype.getGeofence = function() {
  return /** @type{?proto.cbv1.Circle} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Circle, 3));
};


/** @param {?proto.cbv1.Circle|undefined} value */
proto.cbv1.CreateSiteRequest.prototype.setGeofence = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv1.CreateSiteRequest.prototype.clearGeofence = function() {
  this.setGeofence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CreateSiteRequest.prototype.hasGeofence = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string directions = 4;
 * @return {string}
 */
proto.cbv1.CreateSiteRequest.prototype.getDirections = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.CreateSiteRequest.prototype.setDirections = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional SiteStatus status = 5;
 * @return {!proto.cbv1.SiteStatus}
 */
proto.cbv1.CreateSiteRequest.prototype.getStatus = function() {
  return /** @type {!proto.cbv1.SiteStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {!proto.cbv1.SiteStatus} value */
proto.cbv1.CreateSiteRequest.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional Availability availability = 6;
 * @return {?proto.cbv1.Availability}
 */
proto.cbv1.CreateSiteRequest.prototype.getAvailability = function() {
  return /** @type{?proto.cbv1.Availability} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_site_site_pb.Availability, 6));
};


/** @param {?proto.cbv1.Availability|undefined} value */
proto.cbv1.CreateSiteRequest.prototype.setAvailability = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


proto.cbv1.CreateSiteRequest.prototype.clearAvailability = function() {
  this.setAvailability(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CreateSiteRequest.prototype.hasAvailability = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool favorited = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.CreateSiteRequest.prototype.getFavorited = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.cbv1.CreateSiteRequest.prototype.setFavorited = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string address = 9;
 * @return {string}
 */
proto.cbv1.CreateSiteRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.cbv1.CreateSiteRequest.prototype.setAddress = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated SiteAvailableDuration AvailableDurations = 10;
 * @return {!Array<!proto.cbv1.SiteAvailableDuration>}
 */
proto.cbv1.CreateSiteRequest.prototype.getAvailabledurationsList = function() {
  return /** @type{!Array<!proto.cbv1.SiteAvailableDuration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_site_site_pb.SiteAvailableDuration, 10));
};


/** @param {!Array<!proto.cbv1.SiteAvailableDuration>} value */
proto.cbv1.CreateSiteRequest.prototype.setAvailabledurationsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.cbv1.SiteAvailableDuration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.SiteAvailableDuration}
 */
proto.cbv1.CreateSiteRequest.prototype.addAvailabledurations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.cbv1.SiteAvailableDuration, opt_index);
};


proto.cbv1.CreateSiteRequest.prototype.clearAvailabledurationsList = function() {
  this.setAvailabledurationsList([]);
};


/**
 * repeated int32 driver_certification_ids = 11;
 * @return {!Array<number>}
 */
proto.cbv1.CreateSiteRequest.prototype.getDriverCertificationIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/** @param {!Array<number>} value */
proto.cbv1.CreateSiteRequest.prototype.setDriverCertificationIdsList = function(value) {
  jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.CreateSiteRequest.prototype.addDriverCertificationIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


proto.cbv1.CreateSiteRequest.prototype.clearDriverCertificationIdsList = function() {
  this.setDriverCertificationIdsList([]);
};


/**
 * optional int64 district_id = 12;
 * @return {number}
 */
proto.cbv1.CreateSiteRequest.prototype.getDistrictId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.cbv1.CreateSiteRequest.prototype.setDistrictId = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateSiteResponse.displayName = 'proto.cbv1.CreateSiteResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    site: (f = msg.getSite()) && proto_cybertron_site_site_pb.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateSiteResponse}
 */
proto.cbv1.CreateSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateSiteResponse;
  return proto.cbv1.CreateSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateSiteResponse}
 */
proto.cbv1.CreateSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_site_site_pb.Site;
      reader.readMessage(value,proto_cybertron_site_site_pb.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_site_site_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional Site site = 1;
 * @return {?proto.cbv1.Site}
 */
proto.cbv1.CreateSiteResponse.prototype.getSite = function() {
  return /** @type{?proto.cbv1.Site} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_site_site_pb.Site, 1));
};


/** @param {?proto.cbv1.Site|undefined} value */
proto.cbv1.CreateSiteResponse.prototype.setSite = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.CreateSiteResponse.prototype.clearSite = function() {
  this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CreateSiteResponse.prototype.hasSite = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOListDistrictsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOListDistrictsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOListDistrictsRequest.displayName = 'proto.cbv1.LMOListDistrictsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOListDistrictsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOListDistrictsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOListDistrictsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOListDistrictsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOListDistrictsRequest}
 */
proto.cbv1.LMOListDistrictsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOListDistrictsRequest;
  return proto.cbv1.LMOListDistrictsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOListDistrictsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOListDistrictsRequest}
 */
proto.cbv1.LMOListDistrictsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOListDistrictsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOListDistrictsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOListDistrictsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOListDistrictsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOListDistrictsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.LMOListDistrictsResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.LMOListDistrictsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOListDistrictsResponse.displayName = 'proto.cbv1.LMOListDistrictsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.LMOListDistrictsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOListDistrictsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOListDistrictsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOListDistrictsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOListDistrictsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    districtsList: jspb.Message.toObjectList(msg.getDistrictsList(),
    proto_cybertron_site_site_pb.District.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOListDistrictsResponse}
 */
proto.cbv1.LMOListDistrictsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOListDistrictsResponse;
  return proto.cbv1.LMOListDistrictsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOListDistrictsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOListDistrictsResponse}
 */
proto.cbv1.LMOListDistrictsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_site_site_pb.District;
      reader.readMessage(value,proto_cybertron_site_site_pb.District.deserializeBinaryFromReader);
      msg.addDistricts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOListDistrictsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOListDistrictsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOListDistrictsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOListDistrictsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDistrictsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_site_site_pb.District.serializeBinaryToWriter
    );
  }
};


/**
 * repeated District districts = 1;
 * @return {!Array<!proto.cbv1.District>}
 */
proto.cbv1.LMOListDistrictsResponse.prototype.getDistrictsList = function() {
  return /** @type{!Array<!proto.cbv1.District>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_site_site_pb.District, 1));
};


/** @param {!Array<!proto.cbv1.District>} value */
proto.cbv1.LMOListDistrictsResponse.prototype.setDistrictsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.District=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.District}
 */
proto.cbv1.LMOListDistrictsResponse.prototype.addDistricts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.District, opt_index);
};


proto.cbv1.LMOListDistrictsResponse.prototype.clearDistrictsList = function() {
  this.setDistrictsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetSiteRequest.displayName = 'proto.cbv1.GetSiteRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetSiteRequest}
 */
proto.cbv1.GetSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetSiteRequest;
  return proto.cbv1.GetSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetSiteRequest}
 */
proto.cbv1.GetSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.GetSiteRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.GetSiteRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetSiteResponse.displayName = 'proto.cbv1.GetSiteResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    site: (f = msg.getSite()) && proto_cybertron_site_site_pb.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetSiteResponse}
 */
proto.cbv1.GetSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetSiteResponse;
  return proto.cbv1.GetSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetSiteResponse}
 */
proto.cbv1.GetSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_site_site_pb.Site;
      reader.readMessage(value,proto_cybertron_site_site_pb.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_site_site_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional Site site = 1;
 * @return {?proto.cbv1.Site}
 */
proto.cbv1.GetSiteResponse.prototype.getSite = function() {
  return /** @type{?proto.cbv1.Site} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_site_site_pb.Site, 1));
};


/** @param {?proto.cbv1.Site|undefined} value */
proto.cbv1.GetSiteResponse.prototype.setSite = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.GetSiteResponse.prototype.clearSite = function() {
  this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.GetSiteResponse.prototype.hasSite = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DeleteSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DeleteSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DeleteSiteRequest.displayName = 'proto.cbv1.DeleteSiteRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DeleteSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DeleteSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DeleteSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DeleteSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DeleteSiteRequest}
 */
proto.cbv1.DeleteSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DeleteSiteRequest;
  return proto.cbv1.DeleteSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DeleteSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DeleteSiteRequest}
 */
proto.cbv1.DeleteSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DeleteSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DeleteSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DeleteSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DeleteSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.DeleteSiteRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.DeleteSiteRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DeleteSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DeleteSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DeleteSiteResponse.displayName = 'proto.cbv1.DeleteSiteResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DeleteSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DeleteSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DeleteSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DeleteSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DeleteSiteResponse}
 */
proto.cbv1.DeleteSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DeleteSiteResponse;
  return proto.cbv1.DeleteSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DeleteSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DeleteSiteResponse}
 */
proto.cbv1.DeleteSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DeleteSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DeleteSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DeleteSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DeleteSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.UpdateSiteRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.UpdateSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateSiteRequest.displayName = 'proto.cbv1.UpdateSiteRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.UpdateSiteRequest.repeatedFields_ = [8,14,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    salesOrderNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    geofence: (f = msg.getGeofence()) && proto_geo_geo_pb.Circle.toObject(includeInstance, f),
    directions: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    availability: (f = msg.getAvailability()) && proto_cybertron_site_site_pb.Availability.toObject(includeInstance, f),
    stockReferencesList: jspb.Message.toObjectList(msg.getStockReferencesList(),
    proto_cybertron_site_site_pb.Stock.toObject, includeInstance),
    favorited: jspb.Message.getFieldWithDefault(msg, 9, false),
    archived: jspb.Message.getFieldWithDefault(msg, 10, false),
    mask: (f = msg.getMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f),
    siteType: jspb.Message.getFieldWithDefault(msg, 12, ""),
    address: jspb.Message.getFieldWithDefault(msg, 13, ""),
    availabledurationsList: jspb.Message.toObjectList(msg.getAvailabledurationsList(),
    proto_cybertron_site_site_pb.SiteAvailableDuration.toObject, includeInstance),
    driverCertificationIdsList: jspb.Message.getRepeatedField(msg, 15),
    districtId: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateSiteRequest}
 */
proto.cbv1.UpdateSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateSiteRequest;
  return proto.cbv1.UpdateSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateSiteRequest}
 */
proto.cbv1.UpdateSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalesOrderNumber(value);
      break;
    case 4:
      var value = new proto_geo_geo_pb.Circle;
      reader.readMessage(value,proto_geo_geo_pb.Circle.deserializeBinaryFromReader);
      msg.setGeofence(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirections(value);
      break;
    case 6:
      var value = /** @type {!proto.cbv1.SiteStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = new proto_cybertron_site_site_pb.Availability;
      reader.readMessage(value,proto_cybertron_site_site_pb.Availability.deserializeBinaryFromReader);
      msg.setAvailability(value);
      break;
    case 8:
      var value = new proto_cybertron_site_site_pb.Stock;
      reader.readMessage(value,proto_cybertron_site_site_pb.Stock.deserializeBinaryFromReader);
      msg.addStockReferences(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorited(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 11:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setMask(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteType(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 14:
      var value = new proto_cybertron_site_site_pb.SiteAvailableDuration;
      reader.readMessage(value,proto_cybertron_site_site_pb.SiteAvailableDuration.deserializeBinaryFromReader);
      msg.addAvailabledurations(value);
      break;
    case 15:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setDriverCertificationIdsList(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDistrictId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSalesOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGeofence();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_geo_geo_pb.Circle.serializeBinaryToWriter
    );
  }
  f = message.getDirections();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAvailability();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_cybertron_site_site_pb.Availability.serializeBinaryToWriter
    );
  }
  f = message.getStockReferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto_cybertron_site_site_pb.Stock.serializeBinaryToWriter
    );
  }
  f = message.getFavorited();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getMask();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
  f = message.getSiteType();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAvailabledurationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto_cybertron_site_site_pb.SiteAvailableDuration.serializeBinaryToWriter
    );
  }
  f = message.getDriverCertificationIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      15,
      f
    );
  }
  f = message.getDistrictId();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.UpdateSiteRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.UpdateSiteRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.UpdateSiteRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.UpdateSiteRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sales_order_number = 3;
 * @return {string}
 */
proto.cbv1.UpdateSiteRequest.prototype.getSalesOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.UpdateSiteRequest.prototype.setSalesOrderNumber = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Circle geofence = 4;
 * @return {?proto.cbv1.Circle}
 */
proto.cbv1.UpdateSiteRequest.prototype.getGeofence = function() {
  return /** @type{?proto.cbv1.Circle} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Circle, 4));
};


/** @param {?proto.cbv1.Circle|undefined} value */
proto.cbv1.UpdateSiteRequest.prototype.setGeofence = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.cbv1.UpdateSiteRequest.prototype.clearGeofence = function() {
  this.setGeofence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.UpdateSiteRequest.prototype.hasGeofence = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string directions = 5;
 * @return {string}
 */
proto.cbv1.UpdateSiteRequest.prototype.getDirections = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.UpdateSiteRequest.prototype.setDirections = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional SiteStatus status = 6;
 * @return {!proto.cbv1.SiteStatus}
 */
proto.cbv1.UpdateSiteRequest.prototype.getStatus = function() {
  return /** @type {!proto.cbv1.SiteStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.cbv1.SiteStatus} value */
proto.cbv1.UpdateSiteRequest.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional Availability availability = 7;
 * @return {?proto.cbv1.Availability}
 */
proto.cbv1.UpdateSiteRequest.prototype.getAvailability = function() {
  return /** @type{?proto.cbv1.Availability} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_site_site_pb.Availability, 7));
};


/** @param {?proto.cbv1.Availability|undefined} value */
proto.cbv1.UpdateSiteRequest.prototype.setAvailability = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


proto.cbv1.UpdateSiteRequest.prototype.clearAvailability = function() {
  this.setAvailability(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.UpdateSiteRequest.prototype.hasAvailability = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated Stock stock_references = 8;
 * @return {!Array<!proto.cbv1.Stock>}
 */
proto.cbv1.UpdateSiteRequest.prototype.getStockReferencesList = function() {
  return /** @type{!Array<!proto.cbv1.Stock>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_site_site_pb.Stock, 8));
};


/** @param {!Array<!proto.cbv1.Stock>} value */
proto.cbv1.UpdateSiteRequest.prototype.setStockReferencesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.cbv1.Stock=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.Stock}
 */
proto.cbv1.UpdateSiteRequest.prototype.addStockReferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.cbv1.Stock, opt_index);
};


proto.cbv1.UpdateSiteRequest.prototype.clearStockReferencesList = function() {
  this.setStockReferencesList([]);
};


/**
 * optional bool favorited = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.UpdateSiteRequest.prototype.getFavorited = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.cbv1.UpdateSiteRequest.prototype.setFavorited = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool archived = 10;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.UpdateSiteRequest.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 10, false));
};


/** @param {boolean} value */
proto.cbv1.UpdateSiteRequest.prototype.setArchived = function(value) {
  jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional google.protobuf.FieldMask mask = 11;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.cbv1.UpdateSiteRequest.prototype.getMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 11));
};


/** @param {?proto.google.protobuf.FieldMask|undefined} value */
proto.cbv1.UpdateSiteRequest.prototype.setMask = function(value) {
  jspb.Message.setWrapperField(this, 11, value);
};


proto.cbv1.UpdateSiteRequest.prototype.clearMask = function() {
  this.setMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.UpdateSiteRequest.prototype.hasMask = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string site_type = 12;
 * @return {string}
 */
proto.cbv1.UpdateSiteRequest.prototype.getSiteType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.cbv1.UpdateSiteRequest.prototype.setSiteType = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string address = 13;
 * @return {string}
 */
proto.cbv1.UpdateSiteRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.cbv1.UpdateSiteRequest.prototype.setAddress = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated SiteAvailableDuration AvailableDurations = 14;
 * @return {!Array<!proto.cbv1.SiteAvailableDuration>}
 */
proto.cbv1.UpdateSiteRequest.prototype.getAvailabledurationsList = function() {
  return /** @type{!Array<!proto.cbv1.SiteAvailableDuration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_site_site_pb.SiteAvailableDuration, 14));
};


/** @param {!Array<!proto.cbv1.SiteAvailableDuration>} value */
proto.cbv1.UpdateSiteRequest.prototype.setAvailabledurationsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.cbv1.SiteAvailableDuration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.SiteAvailableDuration}
 */
proto.cbv1.UpdateSiteRequest.prototype.addAvailabledurations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.cbv1.SiteAvailableDuration, opt_index);
};


proto.cbv1.UpdateSiteRequest.prototype.clearAvailabledurationsList = function() {
  this.setAvailabledurationsList([]);
};


/**
 * repeated int32 driver_certification_ids = 15;
 * @return {!Array<number>}
 */
proto.cbv1.UpdateSiteRequest.prototype.getDriverCertificationIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 15));
};


/** @param {!Array<number>} value */
proto.cbv1.UpdateSiteRequest.prototype.setDriverCertificationIdsList = function(value) {
  jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.UpdateSiteRequest.prototype.addDriverCertificationIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


proto.cbv1.UpdateSiteRequest.prototype.clearDriverCertificationIdsList = function() {
  this.setDriverCertificationIdsList([]);
};


/**
 * optional int64 district_id = 16;
 * @return {number}
 */
proto.cbv1.UpdateSiteRequest.prototype.getDistrictId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.cbv1.UpdateSiteRequest.prototype.setDistrictId = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UpdateSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateSiteResponse.displayName = 'proto.cbv1.UpdateSiteResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    site: (f = msg.getSite()) && proto_cybertron_site_site_pb.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateSiteResponse}
 */
proto.cbv1.UpdateSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateSiteResponse;
  return proto.cbv1.UpdateSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateSiteResponse}
 */
proto.cbv1.UpdateSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_site_site_pb.Site;
      reader.readMessage(value,proto_cybertron_site_site_pb.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_site_site_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional Site site = 1;
 * @return {?proto.cbv1.Site}
 */
proto.cbv1.UpdateSiteResponse.prototype.getSite = function() {
  return /** @type{?proto.cbv1.Site} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_site_site_pb.Site, 1));
};


/** @param {?proto.cbv1.Site|undefined} value */
proto.cbv1.UpdateSiteResponse.prototype.setSite = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.UpdateSiteResponse.prototype.clearSite = function() {
  this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.UpdateSiteResponse.prototype.hasSite = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.AddStockRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.AddStockRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.AddStockRequest.displayName = 'proto.cbv1.AddStockRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.AddStockRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.AddStockRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.AddStockRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AddStockRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    payloadId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    quantity: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    maxQuantity: +jspb.Message.getFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.AddStockRequest}
 */
proto.cbv1.AddStockRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.AddStockRequest;
  return proto.cbv1.AddStockRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.AddStockRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.AddStockRequest}
 */
proto.cbv1.AddStockRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPayloadId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.AddStockRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.AddStockRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.AddStockRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AddStockRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPayloadId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getMaxQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional int32 site_id = 1;
 * @return {number}
 */
proto.cbv1.AddStockRequest.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.AddStockRequest.prototype.setSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 payload_id = 2;
 * @return {number}
 */
proto.cbv1.AddStockRequest.prototype.getPayloadId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.AddStockRequest.prototype.setPayloadId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double quantity = 3;
 * @return {number}
 */
proto.cbv1.AddStockRequest.prototype.getQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cbv1.AddStockRequest.prototype.setQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double max_quantity = 4;
 * @return {number}
 */
proto.cbv1.AddStockRequest.prototype.getMaxQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.cbv1.AddStockRequest.prototype.setMaxQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.AddStockResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.AddStockResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.AddStockResponse.displayName = 'proto.cbv1.AddStockResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.AddStockResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.AddStockResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.AddStockResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AddStockResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    site: (f = msg.getSite()) && proto_cybertron_site_site_pb.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.AddStockResponse}
 */
proto.cbv1.AddStockResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.AddStockResponse;
  return proto.cbv1.AddStockResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.AddStockResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.AddStockResponse}
 */
proto.cbv1.AddStockResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_site_site_pb.Site;
      reader.readMessage(value,proto_cybertron_site_site_pb.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.AddStockResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.AddStockResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.AddStockResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AddStockResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_site_site_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional Site site = 1;
 * @return {?proto.cbv1.Site}
 */
proto.cbv1.AddStockResponse.prototype.getSite = function() {
  return /** @type{?proto.cbv1.Site} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_site_site_pb.Site, 1));
};


/** @param {?proto.cbv1.Site|undefined} value */
proto.cbv1.AddStockResponse.prototype.setSite = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.AddStockResponse.prototype.clearSite = function() {
  this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.AddStockResponse.prototype.hasSite = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateStockRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.UpdateStockRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.UpdateStockRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateStockRequest.displayName = 'proto.cbv1.UpdateStockRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.UpdateStockRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateStockRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateStockRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateStockRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateStockRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stockUpdatesList: jspb.Message.toObjectList(msg.getStockUpdatesList(),
    proto.cbv1.StockUpdate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateStockRequest}
 */
proto.cbv1.UpdateStockRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateStockRequest;
  return proto.cbv1.UpdateStockRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateStockRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateStockRequest}
 */
proto.cbv1.UpdateStockRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cbv1.StockUpdate;
      reader.readMessage(value,proto.cbv1.StockUpdate.deserializeBinaryFromReader);
      msg.addStockUpdates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateStockRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateStockRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateStockRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateStockRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStockUpdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cbv1.StockUpdate.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StockUpdate stock_updates = 1;
 * @return {!Array<!proto.cbv1.StockUpdate>}
 */
proto.cbv1.UpdateStockRequest.prototype.getStockUpdatesList = function() {
  return /** @type{!Array<!proto.cbv1.StockUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.StockUpdate, 1));
};


/** @param {!Array<!proto.cbv1.StockUpdate>} value */
proto.cbv1.UpdateStockRequest.prototype.setStockUpdatesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.StockUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.StockUpdate}
 */
proto.cbv1.UpdateStockRequest.prototype.addStockUpdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.StockUpdate, opt_index);
};


proto.cbv1.UpdateStockRequest.prototype.clearStockUpdatesList = function() {
  this.setStockUpdatesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateStockResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UpdateStockResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateStockResponse.displayName = 'proto.cbv1.UpdateStockResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateStockResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateStockResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateStockResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateStockResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateStockResponse}
 */
proto.cbv1.UpdateStockResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateStockResponse;
  return proto.cbv1.UpdateStockResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateStockResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateStockResponse}
 */
proto.cbv1.UpdateStockResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateStockResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateStockResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateStockResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateStockResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DeleteStockRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DeleteStockRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DeleteStockRequest.displayName = 'proto.cbv1.DeleteStockRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DeleteStockRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DeleteStockRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DeleteStockRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DeleteStockRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stockId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DeleteStockRequest}
 */
proto.cbv1.DeleteStockRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DeleteStockRequest;
  return proto.cbv1.DeleteStockRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DeleteStockRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DeleteStockRequest}
 */
proto.cbv1.DeleteStockRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStockId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DeleteStockRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DeleteStockRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DeleteStockRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DeleteStockRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStockId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 stock_id = 1;
 * @return {number}
 */
proto.cbv1.DeleteStockRequest.prototype.getStockId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.DeleteStockRequest.prototype.setStockId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DeleteStockResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DeleteStockResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DeleteStockResponse.displayName = 'proto.cbv1.DeleteStockResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DeleteStockResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DeleteStockResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DeleteStockResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DeleteStockResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DeleteStockResponse}
 */
proto.cbv1.DeleteStockResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DeleteStockResponse;
  return proto.cbv1.DeleteStockResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DeleteStockResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DeleteStockResponse}
 */
proto.cbv1.DeleteStockResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DeleteStockResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DeleteStockResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DeleteStockResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DeleteStockResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.StockUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.StockUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.StockUpdate.displayName = 'proto.cbv1.StockUpdate';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.StockUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.StockUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.StockUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StockUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    stockId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quantity: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    maxQuantity: +jspb.Message.getFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.StockUpdate}
 */
proto.cbv1.StockUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.StockUpdate;
  return proto.cbv1.StockUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.StockUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.StockUpdate}
 */
proto.cbv1.StockUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStockId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.StockUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.StockUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.StockUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StockUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStockId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getMaxQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int32 stock_id = 1;
 * @return {number}
 */
proto.cbv1.StockUpdate.prototype.getStockId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.StockUpdate.prototype.setStockId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double quantity = 2;
 * @return {number}
 */
proto.cbv1.StockUpdate.prototype.getQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.cbv1.StockUpdate.prototype.setQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double max_quantity = 3;
 * @return {number}
 */
proto.cbv1.StockUpdate.prototype.getMaxQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cbv1.StockUpdate.prototype.setMaxQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.PutSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.PutSiteRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.PutSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.PutSiteRequest.displayName = 'proto.cbv1.PutSiteRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.PutSiteRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.PutSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.PutSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.PutSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.PutSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    geofence: (f = msg.getGeofence()) && proto_geo_geo_pb.Circle.toObject(includeInstance, f),
    directions: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    stockReferencesList: jspb.Message.toObjectList(msg.getStockReferencesList(),
    proto_cybertron_site_site_pb.Stock.toObject, includeInstance),
    availability: (f = msg.getAvailability()) && proto_cybertron_site_site_pb.Availability.toObject(includeInstance, f),
    siteType: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.PutSiteRequest}
 */
proto.cbv1.PutSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.PutSiteRequest;
  return proto.cbv1.PutSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.PutSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.PutSiteRequest}
 */
proto.cbv1.PutSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto_geo_geo_pb.Circle;
      reader.readMessage(value,proto_geo_geo_pb.Circle.deserializeBinaryFromReader);
      msg.setGeofence(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirections(value);
      break;
    case 6:
      var value = /** @type {!proto.cbv1.SiteStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = new proto_cybertron_site_site_pb.Stock;
      reader.readMessage(value,proto_cybertron_site_site_pb.Stock.deserializeBinaryFromReader);
      msg.addStockReferences(value);
      break;
    case 8:
      var value = new proto_cybertron_site_site_pb.Availability;
      reader.readMessage(value,proto_cybertron_site_site_pb.Availability.deserializeBinaryFromReader);
      msg.setAvailability(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.PutSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.PutSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.PutSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.PutSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGeofence();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_geo_geo_pb.Circle.serializeBinaryToWriter
    );
  }
  f = message.getDirections();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getStockReferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto_cybertron_site_site_pb.Stock.serializeBinaryToWriter
    );
  }
  f = message.getAvailability();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_cybertron_site_site_pb.Availability.serializeBinaryToWriter
    );
  }
  f = message.getSiteType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.PutSiteRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.PutSiteRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.PutSiteRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.PutSiteRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Circle geofence = 4;
 * @return {?proto.cbv1.Circle}
 */
proto.cbv1.PutSiteRequest.prototype.getGeofence = function() {
  return /** @type{?proto.cbv1.Circle} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Circle, 4));
};


/** @param {?proto.cbv1.Circle|undefined} value */
proto.cbv1.PutSiteRequest.prototype.setGeofence = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.cbv1.PutSiteRequest.prototype.clearGeofence = function() {
  this.setGeofence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.PutSiteRequest.prototype.hasGeofence = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string directions = 5;
 * @return {string}
 */
proto.cbv1.PutSiteRequest.prototype.getDirections = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.PutSiteRequest.prototype.setDirections = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional SiteStatus status = 6;
 * @return {!proto.cbv1.SiteStatus}
 */
proto.cbv1.PutSiteRequest.prototype.getStatus = function() {
  return /** @type {!proto.cbv1.SiteStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.cbv1.SiteStatus} value */
proto.cbv1.PutSiteRequest.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated Stock stock_references = 7;
 * @return {!Array<!proto.cbv1.Stock>}
 */
proto.cbv1.PutSiteRequest.prototype.getStockReferencesList = function() {
  return /** @type{!Array<!proto.cbv1.Stock>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_site_site_pb.Stock, 7));
};


/** @param {!Array<!proto.cbv1.Stock>} value */
proto.cbv1.PutSiteRequest.prototype.setStockReferencesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.cbv1.Stock=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.Stock}
 */
proto.cbv1.PutSiteRequest.prototype.addStockReferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.cbv1.Stock, opt_index);
};


proto.cbv1.PutSiteRequest.prototype.clearStockReferencesList = function() {
  this.setStockReferencesList([]);
};


/**
 * optional Availability availability = 8;
 * @return {?proto.cbv1.Availability}
 */
proto.cbv1.PutSiteRequest.prototype.getAvailability = function() {
  return /** @type{?proto.cbv1.Availability} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_site_site_pb.Availability, 8));
};


/** @param {?proto.cbv1.Availability|undefined} value */
proto.cbv1.PutSiteRequest.prototype.setAvailability = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.cbv1.PutSiteRequest.prototype.clearAvailability = function() {
  this.setAvailability(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.PutSiteRequest.prototype.hasAvailability = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string site_type = 9;
 * @return {string}
 */
proto.cbv1.PutSiteRequest.prototype.getSiteType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.cbv1.PutSiteRequest.prototype.setSiteType = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOListSitesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOListSitesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOListSitesRequest.displayName = 'proto.cbv1.LMOListSitesRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOListSitesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOListSitesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOListSitesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOListSitesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageToken: jspb.Message.getFieldWithDefault(msg, 1, 0),
    maxResults: jspb.Message.getFieldWithDefault(msg, 2, 0),
    orderBy: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOListSitesRequest}
 */
proto.cbv1.LMOListSitesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOListSitesRequest;
  return proto.cbv1.LMOListSitesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOListSitesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOListSitesRequest}
 */
proto.cbv1.LMOListSitesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxResults(value);
      break;
    case 3:
      var value = /** @type {!proto.cbv1.SiteOrderBy} */ (reader.readEnum());
      msg.setOrderBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOListSitesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOListSitesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOListSitesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOListSitesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageToken();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMaxResults();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOrderBy();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int32 page_token = 1;
 * @return {number}
 */
proto.cbv1.LMOListSitesRequest.prototype.getPageToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.LMOListSitesRequest.prototype.setPageToken = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 max_results = 2;
 * @return {number}
 */
proto.cbv1.LMOListSitesRequest.prototype.getMaxResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.LMOListSitesRequest.prototype.setMaxResults = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional SiteOrderBy order_by = 3;
 * @return {!proto.cbv1.SiteOrderBy}
 */
proto.cbv1.LMOListSitesRequest.prototype.getOrderBy = function() {
  return /** @type {!proto.cbv1.SiteOrderBy} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.cbv1.SiteOrderBy} value */
proto.cbv1.LMOListSitesRequest.prototype.setOrderBy = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOListSitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.LMOListSitesResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.LMOListSitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOListSitesResponse.displayName = 'proto.cbv1.LMOListSitesResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.LMOListSitesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOListSitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOListSitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOListSitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOListSitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto_cybertron_site_site_pb.LMOSiteSummary.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastToken: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOListSitesResponse}
 */
proto.cbv1.LMOListSitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOListSitesResponse;
  return proto.cbv1.LMOListSitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOListSitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOListSitesResponse}
 */
proto.cbv1.LMOListSitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_site_site_pb.LMOSiteSummary;
      reader.readMessage(value,proto_cybertron_site_site_pb.LMOSiteSummary.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOListSitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOListSitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOListSitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOListSitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_site_site_pb.LMOSiteSummary.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLastToken();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated LMOSiteSummary sites = 1;
 * @return {!Array<!proto.cbv1.LMOSiteSummary>}
 */
proto.cbv1.LMOListSitesResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.cbv1.LMOSiteSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_site_site_pb.LMOSiteSummary, 1));
};


/** @param {!Array<!proto.cbv1.LMOSiteSummary>} value */
proto.cbv1.LMOListSitesResponse.prototype.setSitesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.LMOSiteSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.LMOSiteSummary}
 */
proto.cbv1.LMOListSitesResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.LMOSiteSummary, opt_index);
};


proto.cbv1.LMOListSitesResponse.prototype.clearSitesList = function() {
  this.setSitesList([]);
};


/**
 * optional int32 next_page_token = 2;
 * @return {number}
 */
proto.cbv1.LMOListSitesResponse.prototype.getNextPageToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.LMOListSitesResponse.prototype.setNextPageToken = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 last_token = 3;
 * @return {number}
 */
proto.cbv1.LMOListSitesResponse.prototype.getLastToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.LMOListSitesResponse.prototype.setLastToken = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOSearchSitesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOSearchSitesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOSearchSitesRequest.displayName = 'proto.cbv1.LMOSearchSitesRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOSearchSitesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOSearchSitesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOSearchSitesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSearchSitesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOSearchSitesRequest}
 */
proto.cbv1.LMOSearchSitesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOSearchSitesRequest;
  return proto.cbv1.LMOSearchSitesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOSearchSitesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOSearchSitesRequest}
 */
proto.cbv1.LMOSearchSitesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOSearchSitesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOSearchSitesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOSearchSitesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSearchSitesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string site_name = 1;
 * @return {string}
 */
proto.cbv1.LMOSearchSitesRequest.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.LMOSearchSitesRequest.prototype.setSiteName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOSearchSitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.LMOSearchSitesResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.LMOSearchSitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOSearchSitesResponse.displayName = 'proto.cbv1.LMOSearchSitesResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.LMOSearchSitesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOSearchSitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOSearchSitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOSearchSitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSearchSitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto_cybertron_site_site_pb.SiteShort.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOSearchSitesResponse}
 */
proto.cbv1.LMOSearchSitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOSearchSitesResponse;
  return proto.cbv1.LMOSearchSitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOSearchSitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOSearchSitesResponse}
 */
proto.cbv1.LMOSearchSitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_site_site_pb.SiteShort;
      reader.readMessage(value,proto_cybertron_site_site_pb.SiteShort.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOSearchSitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOSearchSitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOSearchSitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSearchSitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_site_site_pb.SiteShort.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SiteShort sites = 1;
 * @return {!Array<!proto.cbv1.SiteShort>}
 */
proto.cbv1.LMOSearchSitesResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.cbv1.SiteShort>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_site_site_pb.SiteShort, 1));
};


/** @param {!Array<!proto.cbv1.SiteShort>} value */
proto.cbv1.LMOSearchSitesResponse.prototype.setSitesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.SiteShort=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.SiteShort}
 */
proto.cbv1.LMOSearchSitesResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.SiteShort, opt_index);
};


proto.cbv1.LMOSearchSitesResponse.prototype.clearSitesList = function() {
  this.setSitesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorListSitesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.VendorListSitesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorListSitesRequest.displayName = 'proto.cbv1.VendorListSitesRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorListSitesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorListSitesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorListSitesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorListSitesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageToken: jspb.Message.getFieldWithDefault(msg, 1, 0),
    maxResults: jspb.Message.getFieldWithDefault(msg, 2, 0),
    orderBy: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorListSitesRequest}
 */
proto.cbv1.VendorListSitesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorListSitesRequest;
  return proto.cbv1.VendorListSitesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorListSitesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorListSitesRequest}
 */
proto.cbv1.VendorListSitesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxResults(value);
      break;
    case 3:
      var value = /** @type {!proto.cbv1.SiteOrderBy} */ (reader.readEnum());
      msg.setOrderBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorListSitesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorListSitesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorListSitesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorListSitesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageToken();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMaxResults();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOrderBy();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int32 page_token = 1;
 * @return {number}
 */
proto.cbv1.VendorListSitesRequest.prototype.getPageToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.VendorListSitesRequest.prototype.setPageToken = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 max_results = 2;
 * @return {number}
 */
proto.cbv1.VendorListSitesRequest.prototype.getMaxResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.VendorListSitesRequest.prototype.setMaxResults = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional SiteOrderBy order_by = 3;
 * @return {!proto.cbv1.SiteOrderBy}
 */
proto.cbv1.VendorListSitesRequest.prototype.getOrderBy = function() {
  return /** @type {!proto.cbv1.SiteOrderBy} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.cbv1.SiteOrderBy} value */
proto.cbv1.VendorListSitesRequest.prototype.setOrderBy = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorListSitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.VendorListSitesResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.VendorListSitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorListSitesResponse.displayName = 'proto.cbv1.VendorListSitesResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.VendorListSitesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorListSitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorListSitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorListSitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorListSitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto_cybertron_site_site_pb.VendorSiteSummary.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastToken: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorListSitesResponse}
 */
proto.cbv1.VendorListSitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorListSitesResponse;
  return proto.cbv1.VendorListSitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorListSitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorListSitesResponse}
 */
proto.cbv1.VendorListSitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_site_site_pb.VendorSiteSummary;
      reader.readMessage(value,proto_cybertron_site_site_pb.VendorSiteSummary.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorListSitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorListSitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorListSitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorListSitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_site_site_pb.VendorSiteSummary.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLastToken();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated VendorSiteSummary sites = 1;
 * @return {!Array<!proto.cbv1.VendorSiteSummary>}
 */
proto.cbv1.VendorListSitesResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.cbv1.VendorSiteSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_site_site_pb.VendorSiteSummary, 1));
};


/** @param {!Array<!proto.cbv1.VendorSiteSummary>} value */
proto.cbv1.VendorListSitesResponse.prototype.setSitesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.VendorSiteSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.VendorSiteSummary}
 */
proto.cbv1.VendorListSitesResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.VendorSiteSummary, opt_index);
};


proto.cbv1.VendorListSitesResponse.prototype.clearSitesList = function() {
  this.setSitesList([]);
};


/**
 * optional int32 next_page_token = 2;
 * @return {number}
 */
proto.cbv1.VendorListSitesResponse.prototype.getNextPageToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.VendorListSitesResponse.prototype.setNextPageToken = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 last_token = 3;
 * @return {number}
 */
proto.cbv1.VendorListSitesResponse.prototype.getLastToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.VendorListSitesResponse.prototype.setLastToken = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorListArchivedSitesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.VendorListArchivedSitesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorListArchivedSitesRequest.displayName = 'proto.cbv1.VendorListArchivedSitesRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorListArchivedSitesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorListArchivedSitesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorListArchivedSitesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorListArchivedSitesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageToken: jspb.Message.getFieldWithDefault(msg, 1, 0),
    maxResults: jspb.Message.getFieldWithDefault(msg, 2, 0),
    orderBy: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorListArchivedSitesRequest}
 */
proto.cbv1.VendorListArchivedSitesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorListArchivedSitesRequest;
  return proto.cbv1.VendorListArchivedSitesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorListArchivedSitesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorListArchivedSitesRequest}
 */
proto.cbv1.VendorListArchivedSitesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxResults(value);
      break;
    case 3:
      var value = /** @type {!proto.cbv1.SiteOrderBy} */ (reader.readEnum());
      msg.setOrderBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorListArchivedSitesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorListArchivedSitesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorListArchivedSitesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorListArchivedSitesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageToken();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMaxResults();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOrderBy();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int32 page_token = 1;
 * @return {number}
 */
proto.cbv1.VendorListArchivedSitesRequest.prototype.getPageToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.VendorListArchivedSitesRequest.prototype.setPageToken = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 max_results = 2;
 * @return {number}
 */
proto.cbv1.VendorListArchivedSitesRequest.prototype.getMaxResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.VendorListArchivedSitesRequest.prototype.setMaxResults = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional SiteOrderBy order_by = 3;
 * @return {!proto.cbv1.SiteOrderBy}
 */
proto.cbv1.VendorListArchivedSitesRequest.prototype.getOrderBy = function() {
  return /** @type {!proto.cbv1.SiteOrderBy} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.cbv1.SiteOrderBy} value */
proto.cbv1.VendorListArchivedSitesRequest.prototype.setOrderBy = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorListArchivedSitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.VendorListArchivedSitesResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.VendorListArchivedSitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorListArchivedSitesResponse.displayName = 'proto.cbv1.VendorListArchivedSitesResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.VendorListArchivedSitesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorListArchivedSitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorListArchivedSitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorListArchivedSitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorListArchivedSitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto_cybertron_site_site_pb.VendorSiteSummary.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastToken: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorListArchivedSitesResponse}
 */
proto.cbv1.VendorListArchivedSitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorListArchivedSitesResponse;
  return proto.cbv1.VendorListArchivedSitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorListArchivedSitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorListArchivedSitesResponse}
 */
proto.cbv1.VendorListArchivedSitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_site_site_pb.VendorSiteSummary;
      reader.readMessage(value,proto_cybertron_site_site_pb.VendorSiteSummary.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorListArchivedSitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorListArchivedSitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorListArchivedSitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorListArchivedSitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_site_site_pb.VendorSiteSummary.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLastToken();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated VendorSiteSummary sites = 1;
 * @return {!Array<!proto.cbv1.VendorSiteSummary>}
 */
proto.cbv1.VendorListArchivedSitesResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.cbv1.VendorSiteSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_site_site_pb.VendorSiteSummary, 1));
};


/** @param {!Array<!proto.cbv1.VendorSiteSummary>} value */
proto.cbv1.VendorListArchivedSitesResponse.prototype.setSitesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.VendorSiteSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.VendorSiteSummary}
 */
proto.cbv1.VendorListArchivedSitesResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.VendorSiteSummary, opt_index);
};


proto.cbv1.VendorListArchivedSitesResponse.prototype.clearSitesList = function() {
  this.setSitesList([]);
};


/**
 * optional int32 next_page_token = 2;
 * @return {number}
 */
proto.cbv1.VendorListArchivedSitesResponse.prototype.getNextPageToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.VendorListArchivedSitesResponse.prototype.setNextPageToken = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 last_token = 3;
 * @return {number}
 */
proto.cbv1.VendorListArchivedSitesResponse.prototype.getLastToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.VendorListArchivedSitesResponse.prototype.setLastToken = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.FavoriteSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.FavoriteSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.FavoriteSiteRequest.displayName = 'proto.cbv1.FavoriteSiteRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.FavoriteSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.FavoriteSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.FavoriteSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FavoriteSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    favorited: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.FavoriteSiteRequest}
 */
proto.cbv1.FavoriteSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.FavoriteSiteRequest;
  return proto.cbv1.FavoriteSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.FavoriteSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.FavoriteSiteRequest}
 */
proto.cbv1.FavoriteSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorited(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.FavoriteSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.FavoriteSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.FavoriteSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FavoriteSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFavorited();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int32 site_id = 1;
 * @return {number}
 */
proto.cbv1.FavoriteSiteRequest.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.FavoriteSiteRequest.prototype.setSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool favorited = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.FavoriteSiteRequest.prototype.getFavorited = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.FavoriteSiteRequest.prototype.setFavorited = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.FavoriteSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.FavoriteSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.FavoriteSiteResponse.displayName = 'proto.cbv1.FavoriteSiteResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.FavoriteSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.FavoriteSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.FavoriteSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FavoriteSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    favorited: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.FavoriteSiteResponse}
 */
proto.cbv1.FavoriteSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.FavoriteSiteResponse;
  return proto.cbv1.FavoriteSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.FavoriteSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.FavoriteSiteResponse}
 */
proto.cbv1.FavoriteSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorited(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.FavoriteSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.FavoriteSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.FavoriteSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FavoriteSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFavorited();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int32 site_id = 1;
 * @return {number}
 */
proto.cbv1.FavoriteSiteResponse.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.FavoriteSiteResponse.prototype.setSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool favorited = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.FavoriteSiteResponse.prototype.getFavorited = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.FavoriteSiteResponse.prototype.setFavorited = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateStocksQuantityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.UpdateStocksQuantityRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.UpdateStocksQuantityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateStocksQuantityRequest.displayName = 'proto.cbv1.UpdateStocksQuantityRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.UpdateStocksQuantityRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateStocksQuantityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateStocksQuantityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateStocksQuantityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateStocksQuantityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stockQuantityRequestsList: jspb.Message.toObjectList(msg.getStockQuantityRequestsList(),
    proto.cbv1.StockQuantityRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateStocksQuantityRequest}
 */
proto.cbv1.UpdateStocksQuantityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateStocksQuantityRequest;
  return proto.cbv1.UpdateStocksQuantityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateStocksQuantityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateStocksQuantityRequest}
 */
proto.cbv1.UpdateStocksQuantityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cbv1.StockQuantityRequest;
      reader.readMessage(value,proto.cbv1.StockQuantityRequest.deserializeBinaryFromReader);
      msg.addStockQuantityRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateStocksQuantityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateStocksQuantityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateStocksQuantityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateStocksQuantityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStockQuantityRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cbv1.StockQuantityRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StockQuantityRequest stock_quantity_requests = 1;
 * @return {!Array<!proto.cbv1.StockQuantityRequest>}
 */
proto.cbv1.UpdateStocksQuantityRequest.prototype.getStockQuantityRequestsList = function() {
  return /** @type{!Array<!proto.cbv1.StockQuantityRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.StockQuantityRequest, 1));
};


/** @param {!Array<!proto.cbv1.StockQuantityRequest>} value */
proto.cbv1.UpdateStocksQuantityRequest.prototype.setStockQuantityRequestsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.StockQuantityRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.StockQuantityRequest}
 */
proto.cbv1.UpdateStocksQuantityRequest.prototype.addStockQuantityRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.StockQuantityRequest, opt_index);
};


proto.cbv1.UpdateStocksQuantityRequest.prototype.clearStockQuantityRequestsList = function() {
  this.setStockQuantityRequestsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.StockQuantityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.StockQuantityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.StockQuantityRequest.displayName = 'proto.cbv1.StockQuantityRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.StockQuantityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.StockQuantityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.StockQuantityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StockQuantityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stockId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quantity: +jspb.Message.getFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.StockQuantityRequest}
 */
proto.cbv1.StockQuantityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.StockQuantityRequest;
  return proto.cbv1.StockQuantityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.StockQuantityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.StockQuantityRequest}
 */
proto.cbv1.StockQuantityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStockId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.StockQuantityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.StockQuantityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.StockQuantityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StockQuantityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStockId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int32 stock_id = 1;
 * @return {number}
 */
proto.cbv1.StockQuantityRequest.prototype.getStockId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.StockQuantityRequest.prototype.setStockId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double quantity = 2;
 * @return {number}
 */
proto.cbv1.StockQuantityRequest.prototype.getQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.cbv1.StockQuantityRequest.prototype.setQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateStocksQuantityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UpdateStocksQuantityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateStocksQuantityResponse.displayName = 'proto.cbv1.UpdateStocksQuantityResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateStocksQuantityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateStocksQuantityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateStocksQuantityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateStocksQuantityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateStocksQuantityResponse}
 */
proto.cbv1.UpdateStocksQuantityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateStocksQuantityResponse;
  return proto.cbv1.UpdateStocksQuantityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateStocksQuantityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateStocksQuantityResponse}
 */
proto.cbv1.UpdateStocksQuantityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateStocksQuantityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateStocksQuantityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateStocksQuantityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateStocksQuantityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ReportSiteAvailabilityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ReportSiteAvailabilityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ReportSiteAvailabilityRequest.displayName = 'proto.cbv1.ReportSiteAvailabilityRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ReportSiteAvailabilityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ReportSiteAvailabilityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ReportSiteAvailabilityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ReportSiteAvailabilityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    available: jspb.Message.getFieldWithDefault(msg, 3, false),
    etaSeconds: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ReportSiteAvailabilityRequest}
 */
proto.cbv1.ReportSiteAvailabilityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ReportSiteAvailabilityRequest;
  return proto.cbv1.ReportSiteAvailabilityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ReportSiteAvailabilityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ReportSiteAvailabilityRequest}
 */
proto.cbv1.ReportSiteAvailabilityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAvailable(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEtaSeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ReportSiteAvailabilityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ReportSiteAvailabilityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ReportSiteAvailabilityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ReportSiteAvailabilityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAvailable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getEtaSeconds();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 site_id = 1;
 * @return {number}
 */
proto.cbv1.ReportSiteAvailabilityRequest.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.ReportSiteAvailabilityRequest.prototype.setSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.cbv1.ReportSiteAvailabilityRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.ReportSiteAvailabilityRequest.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool available = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.ReportSiteAvailabilityRequest.prototype.getAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.cbv1.ReportSiteAvailabilityRequest.prototype.setAvailable = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 eta_seconds = 4;
 * @return {number}
 */
proto.cbv1.ReportSiteAvailabilityRequest.prototype.getEtaSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.ReportSiteAvailabilityRequest.prototype.setEtaSeconds = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ReportSiteAvailabilityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ReportSiteAvailabilityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ReportSiteAvailabilityResponse.displayName = 'proto.cbv1.ReportSiteAvailabilityResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ReportSiteAvailabilityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ReportSiteAvailabilityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ReportSiteAvailabilityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ReportSiteAvailabilityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    site: (f = msg.getSite()) && proto_cybertron_site_site_pb.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ReportSiteAvailabilityResponse}
 */
proto.cbv1.ReportSiteAvailabilityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ReportSiteAvailabilityResponse;
  return proto.cbv1.ReportSiteAvailabilityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ReportSiteAvailabilityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ReportSiteAvailabilityResponse}
 */
proto.cbv1.ReportSiteAvailabilityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_site_site_pb.Site;
      reader.readMessage(value,proto_cybertron_site_site_pb.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ReportSiteAvailabilityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ReportSiteAvailabilityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ReportSiteAvailabilityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ReportSiteAvailabilityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_site_site_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional Site site = 1;
 * @return {?proto.cbv1.Site}
 */
proto.cbv1.ReportSiteAvailabilityResponse.prototype.getSite = function() {
  return /** @type{?proto.cbv1.Site} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_site_site_pb.Site, 1));
};


/** @param {?proto.cbv1.Site|undefined} value */
proto.cbv1.ReportSiteAvailabilityResponse.prototype.setSite = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.ReportSiteAvailabilityResponse.prototype.clearSite = function() {
  this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.ReportSiteAvailabilityResponse.prototype.hasSite = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.StreamAccountSiteEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.StreamAccountSiteEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.StreamAccountSiteEventsRequest.displayName = 'proto.cbv1.StreamAccountSiteEventsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.StreamAccountSiteEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.StreamAccountSiteEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.StreamAccountSiteEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StreamAccountSiteEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.StreamAccountSiteEventsRequest}
 */
proto.cbv1.StreamAccountSiteEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.StreamAccountSiteEventsRequest;
  return proto.cbv1.StreamAccountSiteEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.StreamAccountSiteEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.StreamAccountSiteEventsRequest}
 */
proto.cbv1.StreamAccountSiteEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.StreamAccountSiteEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.StreamAccountSiteEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.StreamAccountSiteEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StreamAccountSiteEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.StreamAccountSiteEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.StreamAccountSiteEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.StreamAccountSiteEventsResponse.displayName = 'proto.cbv1.StreamAccountSiteEventsResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.StreamAccountSiteEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.StreamAccountSiteEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.StreamAccountSiteEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StreamAccountSiteEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteEvent: (f = msg.getSiteEvent()) && proto_cybertron_site_site_pb.SiteEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.StreamAccountSiteEventsResponse}
 */
proto.cbv1.StreamAccountSiteEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.StreamAccountSiteEventsResponse;
  return proto.cbv1.StreamAccountSiteEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.StreamAccountSiteEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.StreamAccountSiteEventsResponse}
 */
proto.cbv1.StreamAccountSiteEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_site_site_pb.SiteEvent;
      reader.readMessage(value,proto_cybertron_site_site_pb.SiteEvent.deserializeBinaryFromReader);
      msg.setSiteEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.StreamAccountSiteEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.StreamAccountSiteEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.StreamAccountSiteEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StreamAccountSiteEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_site_site_pb.SiteEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional SiteEvent site_event = 1;
 * @return {?proto.cbv1.SiteEvent}
 */
proto.cbv1.StreamAccountSiteEventsResponse.prototype.getSiteEvent = function() {
  return /** @type{?proto.cbv1.SiteEvent} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_site_site_pb.SiteEvent, 1));
};


/** @param {?proto.cbv1.SiteEvent|undefined} value */
proto.cbv1.StreamAccountSiteEventsResponse.prototype.setSiteEvent = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.StreamAccountSiteEventsResponse.prototype.clearSiteEvent = function() {
  this.setSiteEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.StreamAccountSiteEventsResponse.prototype.hasSiteEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOSiteActivityFeedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOSiteActivityFeedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOSiteActivityFeedRequest.displayName = 'proto.cbv1.LMOSiteActivityFeedRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOSiteActivityFeedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOSiteActivityFeedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOSiteActivityFeedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSiteActivityFeedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOSiteActivityFeedRequest}
 */
proto.cbv1.LMOSiteActivityFeedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOSiteActivityFeedRequest;
  return proto.cbv1.LMOSiteActivityFeedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOSiteActivityFeedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOSiteActivityFeedRequest}
 */
proto.cbv1.LMOSiteActivityFeedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOSiteActivityFeedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOSiteActivityFeedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOSiteActivityFeedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSiteActivityFeedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.LMOSiteActivityFeedRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.LMOSiteActivityFeedRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOSiteActivityFeedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.LMOSiteActivityFeedResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.LMOSiteActivityFeedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOSiteActivityFeedResponse.displayName = 'proto.cbv1.LMOSiteActivityFeedResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.LMOSiteActivityFeedResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOSiteActivityFeedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOSiteActivityFeedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOSiteActivityFeedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSiteActivityFeedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alarmsList: jspb.Message.toObjectList(msg.getAlarmsList(),
    proto_cybertron_alarm_alarm_pb.Alarm.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOSiteActivityFeedResponse}
 */
proto.cbv1.LMOSiteActivityFeedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOSiteActivityFeedResponse;
  return proto.cbv1.LMOSiteActivityFeedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOSiteActivityFeedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOSiteActivityFeedResponse}
 */
proto.cbv1.LMOSiteActivityFeedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_alarm_alarm_pb.Alarm;
      reader.readMessage(value,proto_cybertron_alarm_alarm_pb.Alarm.deserializeBinaryFromReader);
      msg.addAlarms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOSiteActivityFeedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOSiteActivityFeedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOSiteActivityFeedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSiteActivityFeedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlarmsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_alarm_alarm_pb.Alarm.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Alarm alarms = 1;
 * @return {!Array<!proto.cbv1.Alarm>}
 */
proto.cbv1.LMOSiteActivityFeedResponse.prototype.getAlarmsList = function() {
  return /** @type{!Array<!proto.cbv1.Alarm>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_alarm_alarm_pb.Alarm, 1));
};


/** @param {!Array<!proto.cbv1.Alarm>} value */
proto.cbv1.LMOSiteActivityFeedResponse.prototype.setAlarmsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.Alarm=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.Alarm}
 */
proto.cbv1.LMOSiteActivityFeedResponse.prototype.addAlarms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.Alarm, opt_index);
};


proto.cbv1.LMOSiteActivityFeedResponse.prototype.clearAlarmsList = function() {
  this.setAlarmsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOSalesOrderNumberShortcutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOSalesOrderNumberShortcutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOSalesOrderNumberShortcutsRequest.displayName = 'proto.cbv1.LMOSalesOrderNumberShortcutsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOSalesOrderNumberShortcutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOSalesOrderNumberShortcutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOSalesOrderNumberShortcutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSalesOrderNumberShortcutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOSalesOrderNumberShortcutsRequest}
 */
proto.cbv1.LMOSalesOrderNumberShortcutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOSalesOrderNumberShortcutsRequest;
  return proto.cbv1.LMOSalesOrderNumberShortcutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOSalesOrderNumberShortcutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOSalesOrderNumberShortcutsRequest}
 */
proto.cbv1.LMOSalesOrderNumberShortcutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOSalesOrderNumberShortcutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOSalesOrderNumberShortcutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOSalesOrderNumberShortcutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSalesOrderNumberShortcutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 site_id = 1;
 * @return {number}
 */
proto.cbv1.LMOSalesOrderNumberShortcutsRequest.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.LMOSalesOrderNumberShortcutsRequest.prototype.setSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOSalesOrderNumberShortcutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.LMOSalesOrderNumberShortcutsResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.LMOSalesOrderNumberShortcutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOSalesOrderNumberShortcutsResponse.displayName = 'proto.cbv1.LMOSalesOrderNumberShortcutsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.LMOSalesOrderNumberShortcutsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOSalesOrderNumberShortcutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOSalesOrderNumberShortcutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOSalesOrderNumberShortcutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSalesOrderNumberShortcutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recentlyUsedList: jspb.Message.getRepeatedField(msg, 1),
    frequentlyUsedList: jspb.Message.getRepeatedField(msg, 2)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOSalesOrderNumberShortcutsResponse}
 */
proto.cbv1.LMOSalesOrderNumberShortcutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOSalesOrderNumberShortcutsResponse;
  return proto.cbv1.LMOSalesOrderNumberShortcutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOSalesOrderNumberShortcutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOSalesOrderNumberShortcutsResponse}
 */
proto.cbv1.LMOSalesOrderNumberShortcutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addRecentlyUsed(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFrequentlyUsed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOSalesOrderNumberShortcutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOSalesOrderNumberShortcutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOSalesOrderNumberShortcutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSalesOrderNumberShortcutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecentlyUsedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getFrequentlyUsedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string recently_used = 1;
 * @return {!Array<string>}
 */
proto.cbv1.LMOSalesOrderNumberShortcutsResponse.prototype.getRecentlyUsedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.cbv1.LMOSalesOrderNumberShortcutsResponse.prototype.setRecentlyUsedList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.LMOSalesOrderNumberShortcutsResponse.prototype.addRecentlyUsed = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv1.LMOSalesOrderNumberShortcutsResponse.prototype.clearRecentlyUsedList = function() {
  this.setRecentlyUsedList([]);
};


/**
 * repeated string frequently_used = 2;
 * @return {!Array<string>}
 */
proto.cbv1.LMOSalesOrderNumberShortcutsResponse.prototype.getFrequentlyUsedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<string>} value */
proto.cbv1.LMOSalesOrderNumberShortcutsResponse.prototype.setFrequentlyUsedList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.LMOSalesOrderNumberShortcutsResponse.prototype.addFrequentlyUsed = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.cbv1.LMOSalesOrderNumberShortcutsResponse.prototype.clearFrequentlyUsedList = function() {
  this.setFrequentlyUsedList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOPurchaseOrderShortcutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOPurchaseOrderShortcutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOPurchaseOrderShortcutsRequest.displayName = 'proto.cbv1.LMOPurchaseOrderShortcutsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOPurchaseOrderShortcutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOPurchaseOrderShortcutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOPurchaseOrderShortcutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOPurchaseOrderShortcutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    payloadId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOPurchaseOrderShortcutsRequest}
 */
proto.cbv1.LMOPurchaseOrderShortcutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOPurchaseOrderShortcutsRequest;
  return proto.cbv1.LMOPurchaseOrderShortcutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOPurchaseOrderShortcutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOPurchaseOrderShortcutsRequest}
 */
proto.cbv1.LMOPurchaseOrderShortcutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPayloadId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOPurchaseOrderShortcutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOPurchaseOrderShortcutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOPurchaseOrderShortcutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOPurchaseOrderShortcutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPayloadId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 site_id = 1;
 * @return {number}
 */
proto.cbv1.LMOPurchaseOrderShortcutsRequest.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.LMOPurchaseOrderShortcutsRequest.prototype.setSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 payload_id = 2;
 * @return {number}
 */
proto.cbv1.LMOPurchaseOrderShortcutsRequest.prototype.getPayloadId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.LMOPurchaseOrderShortcutsRequest.prototype.setPayloadId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOPurchaseOrderShortcutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.LMOPurchaseOrderShortcutsResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.LMOPurchaseOrderShortcutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOPurchaseOrderShortcutsResponse.displayName = 'proto.cbv1.LMOPurchaseOrderShortcutsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.LMOPurchaseOrderShortcutsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOPurchaseOrderShortcutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOPurchaseOrderShortcutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOPurchaseOrderShortcutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOPurchaseOrderShortcutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recentlyUsedList: jspb.Message.getRepeatedField(msg, 1),
    frequentlyUsedList: jspb.Message.getRepeatedField(msg, 2)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOPurchaseOrderShortcutsResponse}
 */
proto.cbv1.LMOPurchaseOrderShortcutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOPurchaseOrderShortcutsResponse;
  return proto.cbv1.LMOPurchaseOrderShortcutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOPurchaseOrderShortcutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOPurchaseOrderShortcutsResponse}
 */
proto.cbv1.LMOPurchaseOrderShortcutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addRecentlyUsed(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFrequentlyUsed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOPurchaseOrderShortcutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOPurchaseOrderShortcutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOPurchaseOrderShortcutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOPurchaseOrderShortcutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecentlyUsedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getFrequentlyUsedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string recently_used = 1;
 * @return {!Array<string>}
 */
proto.cbv1.LMOPurchaseOrderShortcutsResponse.prototype.getRecentlyUsedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.cbv1.LMOPurchaseOrderShortcutsResponse.prototype.setRecentlyUsedList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.LMOPurchaseOrderShortcutsResponse.prototype.addRecentlyUsed = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv1.LMOPurchaseOrderShortcutsResponse.prototype.clearRecentlyUsedList = function() {
  this.setRecentlyUsedList([]);
};


/**
 * repeated string frequently_used = 2;
 * @return {!Array<string>}
 */
proto.cbv1.LMOPurchaseOrderShortcutsResponse.prototype.getFrequentlyUsedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<string>} value */
proto.cbv1.LMOPurchaseOrderShortcutsResponse.prototype.setFrequentlyUsedList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.LMOPurchaseOrderShortcutsResponse.prototype.addFrequentlyUsed = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.cbv1.LMOPurchaseOrderShortcutsResponse.prototype.clearFrequentlyUsedList = function() {
  this.setFrequentlyUsedList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOSitesStatisticsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOSitesStatisticsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOSitesStatisticsRequest.displayName = 'proto.cbv1.LMOSitesStatisticsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOSitesStatisticsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOSitesStatisticsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOSitesStatisticsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSitesStatisticsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOSitesStatisticsRequest}
 */
proto.cbv1.LMOSitesStatisticsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOSitesStatisticsRequest;
  return proto.cbv1.LMOSitesStatisticsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOSitesStatisticsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOSitesStatisticsRequest}
 */
proto.cbv1.LMOSitesStatisticsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOSitesStatisticsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOSitesStatisticsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOSitesStatisticsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSitesStatisticsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOSitesStatisticsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOSitesStatisticsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOSitesStatisticsResponse.displayName = 'proto.cbv1.LMOSitesStatisticsResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOSitesStatisticsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOSitesStatisticsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOSitesStatisticsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSitesStatisticsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pendingCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    inProgressCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    needsAttentionCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOSitesStatisticsResponse}
 */
proto.cbv1.LMOSitesStatisticsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOSitesStatisticsResponse;
  return proto.cbv1.LMOSitesStatisticsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOSitesStatisticsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOSitesStatisticsResponse}
 */
proto.cbv1.LMOSitesStatisticsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPendingCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInProgressCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNeedsAttentionCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOSitesStatisticsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOSitesStatisticsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOSitesStatisticsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSitesStatisticsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPendingCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getInProgressCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNeedsAttentionCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 pending_count = 1;
 * @return {number}
 */
proto.cbv1.LMOSitesStatisticsResponse.prototype.getPendingCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.LMOSitesStatisticsResponse.prototype.setPendingCount = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 in_progress_count = 2;
 * @return {number}
 */
proto.cbv1.LMOSitesStatisticsResponse.prototype.getInProgressCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.LMOSitesStatisticsResponse.prototype.setInProgressCount = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 needs_attention_count = 3;
 * @return {number}
 */
proto.cbv1.LMOSitesStatisticsResponse.prototype.getNeedsAttentionCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.LMOSitesStatisticsResponse.prototype.setNeedsAttentionCount = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOSitesCalendarRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOSitesCalendarRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOSitesCalendarRequest.displayName = 'proto.cbv1.LMOSitesCalendarRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOSitesCalendarRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOSitesCalendarRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOSitesCalendarRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSitesCalendarRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOSitesCalendarRequest}
 */
proto.cbv1.LMOSitesCalendarRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOSitesCalendarRequest;
  return proto.cbv1.LMOSitesCalendarRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOSitesCalendarRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOSitesCalendarRequest}
 */
proto.cbv1.LMOSitesCalendarRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOSitesCalendarRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOSitesCalendarRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOSitesCalendarRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSitesCalendarRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOSitesCalendarResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.LMOSitesCalendarResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.LMOSitesCalendarResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOSitesCalendarResponse.displayName = 'proto.cbv1.LMOSitesCalendarResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.LMOSitesCalendarResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOSitesCalendarResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOSitesCalendarResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOSitesCalendarResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSitesCalendarResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    calendarStatsList: jspb.Message.toObjectList(msg.getCalendarStatsList(),
    proto_cybertron_site_site_pb.LMOCalendarStats.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOSitesCalendarResponse}
 */
proto.cbv1.LMOSitesCalendarResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOSitesCalendarResponse;
  return proto.cbv1.LMOSitesCalendarResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOSitesCalendarResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOSitesCalendarResponse}
 */
proto.cbv1.LMOSitesCalendarResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_site_site_pb.LMOCalendarStats;
      reader.readMessage(value,proto_cybertron_site_site_pb.LMOCalendarStats.deserializeBinaryFromReader);
      msg.addCalendarStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOSitesCalendarResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOSitesCalendarResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOSitesCalendarResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSitesCalendarResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCalendarStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_site_site_pb.LMOCalendarStats.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LMOCalendarStats calendar_stats = 1;
 * @return {!Array<!proto.cbv1.LMOCalendarStats>}
 */
proto.cbv1.LMOSitesCalendarResponse.prototype.getCalendarStatsList = function() {
  return /** @type{!Array<!proto.cbv1.LMOCalendarStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_site_site_pb.LMOCalendarStats, 1));
};


/** @param {!Array<!proto.cbv1.LMOCalendarStats>} value */
proto.cbv1.LMOSitesCalendarResponse.prototype.setCalendarStatsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.LMOCalendarStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.LMOCalendarStats}
 */
proto.cbv1.LMOSitesCalendarResponse.prototype.addCalendarStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.LMOCalendarStats, opt_index);
};


proto.cbv1.LMOSitesCalendarResponse.prototype.clearCalendarStatsList = function() {
  this.setCalendarStatsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetVendorSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetVendorSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetVendorSiteRequest.displayName = 'proto.cbv1.GetVendorSiteRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetVendorSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetVendorSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetVendorSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetVendorSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetVendorSiteRequest}
 */
proto.cbv1.GetVendorSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetVendorSiteRequest;
  return proto.cbv1.GetVendorSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetVendorSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetVendorSiteRequest}
 */
proto.cbv1.GetVendorSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetVendorSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetVendorSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetVendorSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetVendorSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.GetVendorSiteRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.GetVendorSiteRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetVendorSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetVendorSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetVendorSiteResponse.displayName = 'proto.cbv1.GetVendorSiteResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetVendorSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetVendorSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetVendorSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetVendorSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    site: (f = msg.getSite()) && proto_cybertron_site_site_pb.VendorSite.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetVendorSiteResponse}
 */
proto.cbv1.GetVendorSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetVendorSiteResponse;
  return proto.cbv1.GetVendorSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetVendorSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetVendorSiteResponse}
 */
proto.cbv1.GetVendorSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_site_site_pb.VendorSite;
      reader.readMessage(value,proto_cybertron_site_site_pb.VendorSite.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetVendorSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetVendorSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetVendorSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetVendorSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_site_site_pb.VendorSite.serializeBinaryToWriter
    );
  }
};


/**
 * optional VendorSite site = 1;
 * @return {?proto.cbv1.VendorSite}
 */
proto.cbv1.GetVendorSiteResponse.prototype.getSite = function() {
  return /** @type{?proto.cbv1.VendorSite} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_site_site_pb.VendorSite, 1));
};


/** @param {?proto.cbv1.VendorSite|undefined} value */
proto.cbv1.GetVendorSiteResponse.prototype.setSite = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.GetVendorSiteResponse.prototype.clearSite = function() {
  this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.GetVendorSiteResponse.prototype.hasSite = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorSearchPossibleSitesForTaskRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.VendorSearchPossibleSitesForTaskRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorSearchPossibleSitesForTaskRequest.displayName = 'proto.cbv1.VendorSearchPossibleSitesForTaskRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorSearchPossibleSitesForTaskRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorSearchPossibleSitesForTaskRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorSearchPossibleSitesForTaskRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorSearchPossibleSitesForTaskRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    searchTerm: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorSearchPossibleSitesForTaskRequest}
 */
proto.cbv1.VendorSearchPossibleSitesForTaskRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorSearchPossibleSitesForTaskRequest;
  return proto.cbv1.VendorSearchPossibleSitesForTaskRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorSearchPossibleSitesForTaskRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorSearchPossibleSitesForTaskRequest}
 */
proto.cbv1.VendorSearchPossibleSitesForTaskRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchTerm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorSearchPossibleSitesForTaskRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorSearchPossibleSitesForTaskRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorSearchPossibleSitesForTaskRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorSearchPossibleSitesForTaskRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSearchTerm();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 task_id = 1;
 * @return {number}
 */
proto.cbv1.VendorSearchPossibleSitesForTaskRequest.prototype.getTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.VendorSearchPossibleSitesForTaskRequest.prototype.setTaskId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string search_term = 2;
 * @return {string}
 */
proto.cbv1.VendorSearchPossibleSitesForTaskRequest.prototype.getSearchTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.VendorSearchPossibleSitesForTaskRequest.prototype.setSearchTerm = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorPossibleSiteForTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.VendorPossibleSiteForTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorPossibleSiteForTask.displayName = 'proto.cbv1.VendorPossibleSiteForTask';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorPossibleSiteForTask.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorPossibleSiteForTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorPossibleSiteForTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorPossibleSiteForTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorPossibleSiteForTask}
 */
proto.cbv1.VendorPossibleSiteForTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorPossibleSiteForTask;
  return proto.cbv1.VendorPossibleSiteForTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorPossibleSiteForTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorPossibleSiteForTask}
 */
proto.cbv1.VendorPossibleSiteForTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorPossibleSiteForTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorPossibleSiteForTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorPossibleSiteForTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorPossibleSiteForTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.VendorPossibleSiteForTask.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.VendorPossibleSiteForTask.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.VendorPossibleSiteForTask.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.VendorPossibleSiteForTask.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorSearchPossibleSitesForTaskResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.VendorSearchPossibleSitesForTaskResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.VendorSearchPossibleSitesForTaskResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorSearchPossibleSitesForTaskResponse.displayName = 'proto.cbv1.VendorSearchPossibleSitesForTaskResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.VendorSearchPossibleSitesForTaskResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorSearchPossibleSitesForTaskResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorSearchPossibleSitesForTaskResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorSearchPossibleSitesForTaskResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorSearchPossibleSitesForTaskResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto.cbv1.VendorPossibleSiteForTask.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorSearchPossibleSitesForTaskResponse}
 */
proto.cbv1.VendorSearchPossibleSitesForTaskResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorSearchPossibleSitesForTaskResponse;
  return proto.cbv1.VendorSearchPossibleSitesForTaskResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorSearchPossibleSitesForTaskResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorSearchPossibleSitesForTaskResponse}
 */
proto.cbv1.VendorSearchPossibleSitesForTaskResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cbv1.VendorPossibleSiteForTask;
      reader.readMessage(value,proto.cbv1.VendorPossibleSiteForTask.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorSearchPossibleSitesForTaskResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorSearchPossibleSitesForTaskResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorSearchPossibleSitesForTaskResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorSearchPossibleSitesForTaskResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cbv1.VendorPossibleSiteForTask.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VendorPossibleSiteForTask sites = 1;
 * @return {!Array<!proto.cbv1.VendorPossibleSiteForTask>}
 */
proto.cbv1.VendorSearchPossibleSitesForTaskResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.cbv1.VendorPossibleSiteForTask>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.VendorPossibleSiteForTask, 1));
};


/** @param {!Array<!proto.cbv1.VendorPossibleSiteForTask>} value */
proto.cbv1.VendorSearchPossibleSitesForTaskResponse.prototype.setSitesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.VendorPossibleSiteForTask=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.VendorPossibleSiteForTask}
 */
proto.cbv1.VendorSearchPossibleSitesForTaskResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.VendorPossibleSiteForTask, opt_index);
};


proto.cbv1.VendorSearchPossibleSitesForTaskResponse.prototype.clearSitesList = function() {
  this.setSitesList([]);
};


goog.object.extend(exports, proto.cbv1);
