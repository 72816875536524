import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

export interface ConfirmEvent {
  reassign: boolean;
  complete: boolean;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-assigned-asset-actions',
  styleUrls: ['./assigned-asset-actions.component.scss'],
  templateUrl: './assigned-asset-actions.component.html',
})
export class AssignedAssetActionsComponent implements OnInit {
  @Input() public title: string;

  constructor(private matDialogRef: MatDialogRef<AssignedAssetActionsComponent>) {}

  public ngOnInit() {}

  public cancel() {
    this.matDialogRef.close({
      complete: false,
      reassign: false,
    });
  }

  public reassign() {
    this.matDialogRef.close({
      complete: false,
      reassign: true,
    });
  }

  public complete() {
    this.matDialogRef.close({
      complete: true,
      reassign: true,
    });
  }
}
