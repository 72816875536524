/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hotkey.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./hotkey.component";
import * as i4 from "@angular/material/snack-bar";
var styles_HotkeyComponent = [i0.styles];
var RenderType_HotkeyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HotkeyComponent, data: {} });
export { RenderType_HotkeyComponent as RenderType_HotkeyComponent };
function View_HotkeyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Next time, hit "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "key"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(4, null, [" to ", "! "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hotkey; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.taskDescription; _ck(_v, 4, 0, currVal_1); }); }
function View_HotkeyComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Next time, hit "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "key"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["! "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hotkey; _ck(_v, 3, 0, currVal_0); }); }
export function View_HotkeyComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "hotkey"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HotkeyComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HotkeyComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.taskDescription; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.taskDescription; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_HotkeyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ct-hotkey", [], null, null, null, View_HotkeyComponent_0, RenderType_HotkeyComponent)), i1.ɵdid(1, 114688, null, 0, i3.HotkeyComponent, [i4.MAT_SNACK_BAR_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HotkeyComponentNgFactory = i1.ɵccf("ct-hotkey", i3.HotkeyComponent, View_HotkeyComponent_Host_0, {}, {}, []);
export { HotkeyComponentNgFactory as HotkeyComponentNgFactory };
