import { BehaviorSubject } from 'rxjs';
import { filter, map, share, tap } from 'rxjs/operators';
import { ResetPasswordOnFirstTimeLoginRequest, UserDetailsForIntercomRequest, } from '~proto/user/user_api_pb';
import { TriggerUserActionRequest } from '~proto/user/user_api_pb';
import { UserAPI } from '~proto/user/user_api_pb_service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "./grpc.service";
var RoleService = /** @class */ (function () {
    function RoleService(authService, grpcService) {
        var _this = this;
        this.authService = authService;
        this.grpcService = grpcService;
        this.userRole$$ = new BehaviorSubject(null);
        this.isFirstTimeUser$$ = new BehaviorSubject(null);
        this.isBusinessLineEnabled$$ = new BehaviorSubject(null);
        //  private businessLinesList$$: BehaviorSubject<UserDetailsForIntercom<BusinessLine.AsObject>> = new BehaviorSubject<UserDetailsForIntercom<BusinessLine.AsObject>>(null);
        this.businessLinesList$$ = new BehaviorSubject([]);
        this.isSsoUser$$ = new BehaviorSubject(null);
        this.isBusinessLineCostCenterRelationEnabled$$ = new BehaviorSubject(false);
        this.userActionsList$$ = new BehaviorSubject([]);
        this.isSalesOrderApiEnabled$$ = new BehaviorSubject(false);
        this.isMaintenanceOrderApiEnabled$$ = new BehaviorSubject(false);
        this.authService.isLoggedIn$.subscribe(function () {
            _this.loadUserRole();
        });
    }
    Object.defineProperty(RoleService.prototype, "userRole$", {
        get: function () {
            return this.userRole$$.asObservable().pipe(filter(function (value) { return value !== null; }), share());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoleService.prototype, "isFirstTimeUser$", {
        get: function () {
            return this.isFirstTimeUser$$.asObservable().pipe(filter(function (value) { return value !== null; }), share());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoleService.prototype, "isBusinessLineEnabled$", {
        get: function () {
            return this.isBusinessLineEnabled$$.asObservable().pipe(filter(function (value) { return value !== null; }), share());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoleService.prototype, "businessLinesList$", {
        get: function () {
            return this.businessLinesList$$.asObservable().pipe(filter(function (value) { return value !== null; }), map(function (values) { return values && values.map(function (value) { return value.toObject(); }); }), share());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoleService.prototype, "isSsoUser$", {
        get: function () {
            return this.isSsoUser$$.asObservable().pipe(filter(function (value) { return value !== null; }), share());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoleService.prototype, "isBusinessLineCostCenterRelationEnabled$", {
        get: function () {
            return this.isBusinessLineCostCenterRelationEnabled$$.asObservable().pipe(filter(function (value) { return value !== null; }), share());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoleService.prototype, "userActionsList$", {
        get: function () {
            return this.userActionsList$$.asObservable().pipe(filter(function (value) { return value !== null; }), map(function (values) { return values && values.map(function (value) { return value; }); }), share());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoleService.prototype, "isSalesOrderApiEnabled$", {
        get: function () {
            return this.isSalesOrderApiEnabled$$.asObservable().pipe(filter(function (value) { return value !== null; }), share());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoleService.prototype, "isMaintenanceOrderApiEnabled$", {
        get: function () {
            return this.isMaintenanceOrderApiEnabled$$.asObservable().pipe(filter(function (value) { return value !== null; }), share());
        },
        enumerable: true,
        configurable: true
    });
    RoleService.prototype.loadUserRole = function () {
        var _this = this;
        var request = new UserDetailsForIntercomRequest();
        this.grpcService.invoke$(UserAPI.UserDetailsForIntercom, request).subscribe(function (response) {
            _this.userRole$$.next(response.toObject().userDetails.role);
            _this.isFirstTimeUser$$.next(response.toObject().userDetails.isFirstTimeLogin);
            _this.isBusinessLineEnabled$$.next(response.toObject().userDetails.isBusinessLineEnabled);
            _this.businessLinesList$$.next(response.getUserDetails().getBusinessLinesList());
            _this.isSsoUser$$.next(response.toObject().userDetails.isSsoUser);
            _this.isBusinessLineCostCenterRelationEnabled$$.next(response.toObject().userDetails.isBusinessLineCostCenterRelationEnabled);
            _this.userActionsList$$.next(response.toObject().userDetails.userActionsList);
            _this.isSalesOrderApiEnabled$$.next(response.toObject().userDetails.isSalesOrderApiEnabled);
            _this.isMaintenanceOrderApiEnabled$$.next(response.toObject().userDetails.isMaintenanceOrderApiEnabled);
        });
    };
    RoleService.prototype.updatePassword$ = function (password) {
        var _this = this;
        var user = this.authService.getUserFromCache();
        var request = new ResetPasswordOnFirstTimeLoginRequest();
        request.setEmail(user.email);
        request.setPassword(password);
        return this.grpcService.invoke$(UserAPI.ResetPasswordOnFirstTimeLogin, request).pipe(tap(function () { return _this.loadUserRole(); }));
    };
    RoleService.prototype.updateUserActions$ = function () {
        var request = new TriggerUserActionRequest();
        request.setActionType(1);
        return this.grpcService
            .invoke$(UserAPI.TriggerUserAction, request)
            .pipe(map(function (response) { return response.toObject().status; }));
    };
    RoleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoleService_Factory() { return new RoleService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.GrpcService)); }, token: RoleService, providedIn: "root" });
    return RoleService;
}());
export { RoleService };
