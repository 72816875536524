import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../role.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/material/snack-bar";
var IsAdminRoleTypeGuard = /** @class */ (function () {
    function IsAdminRoleTypeGuard(userRole, router, snackBar) {
        this.userRole = userRole;
        this.router = router;
        this.snackBar = snackBar;
    }
    IsAdminRoleTypeGuard.prototype.canActivate = function () {
        var _this = this;
        return this.userRole.userRole$.pipe(map(function (role) {
            if (role === 1) {
                return true;
            }
            else {
                _this.snackBar.open('User not authorized to access the page', null, {
                    duration: 2000,
                });
                setTimeout(function () { return _this.router.navigate(['/', 'login']); }, 100);
            }
        }));
    };
    IsAdminRoleTypeGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IsAdminRoleTypeGuard_Factory() { return new IsAdminRoleTypeGuard(i0.ɵɵinject(i1.RoleService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.MatSnackBar)); }, token: IsAdminRoleTypeGuard, providedIn: "root" });
    return IsAdminRoleTypeGuard;
}());
export { IsAdminRoleTypeGuard };
