/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./long-escape-tip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./long-escape-tip.component";
var styles_LongEscapeTipComponent = [i0.styles];
var RenderType_LongEscapeTipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LongEscapeTipComponent, data: {} });
export { RenderType_LongEscapeTipComponent as RenderType_LongEscapeTipComponent };
export function View_LongEscapeTipComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "hotkey"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Next time, try holding "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "key"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ESC"])), (_l()(), i1.ɵted(-1, null, ["!"]))], null, null); }
export function View_LongEscapeTipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ct-long-escape-tip", [], null, null, null, View_LongEscapeTipComponent_0, RenderType_LongEscapeTipComponent)), i1.ɵdid(1, 114688, null, 0, i2.LongEscapeTipComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LongEscapeTipComponentNgFactory = i1.ɵccf("ct-long-escape-tip", i2.LongEscapeTipComponent, View_LongEscapeTipComponent_Host_0, {}, {}, []);
export { LongEscapeTipComponentNgFactory as LongEscapeTipComponentNgFactory };
