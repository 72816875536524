/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_user_account_account_pb = require('../../../proto/user/account/account_pb.js');
var proto_geo_geo_pb = require('../../../proto/geo/geo_pb.js');
var proto_cybertron_types_types_pb = require('../../../proto/cybertron/types/types_pb.js');
goog.exportSymbol('proto.cbv2.Analytics', null, global);
goog.exportSymbol('proto.cbv2.Claims', null, global);
goog.exportSymbol('proto.cbv2.Driver', null, global);
goog.exportSymbol('proto.cbv2.DriverStatus', null, global);
goog.exportSymbol('proto.cbv2.DriverStatusTypeV2', null, global);
goog.exportSymbol('proto.cbv2.LastWayPoint', null, global);
goog.exportSymbol('proto.cbv2.RoleV2', null, global);
goog.exportSymbol('proto.cbv2.Segment', null, global);
goog.exportSymbol('proto.cbv2.User', null, global);
goog.exportSymbol('proto.cbv2.UserAccountRoleV2', null, global);
goog.exportSymbol('proto.cbv2.UserV2', null, global);
goog.exportSymbol('proto.cbv2.Waypoint', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv2.Waypoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv2.Waypoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv2.Waypoint.displayName = 'proto.cbv2.Waypoint';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv2.Waypoint.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv2.Waypoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv2.Waypoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv2.Waypoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    authId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    coordinates: (f = msg.getCoordinates()) && proto_geo_geo_pb.Point.toObject(includeInstance, f),
    accuracy: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    speed: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    clientCreatedUnix: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv2.Waypoint}
 */
proto.cbv2.Waypoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv2.Waypoint;
  return proto.cbv2.Waypoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv2.Waypoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv2.Waypoint}
 */
proto.cbv2.Waypoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthId(value);
      break;
    case 3:
      var value = new proto_geo_geo_pb.Point;
      reader.readMessage(value,proto_geo_geo_pb.Point.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAccuracy(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpeed(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setClientCreatedUnix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv2.Waypoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv2.Waypoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv2.Waypoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv2.Waypoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAuthId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_geo_geo_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getAccuracy();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getClientCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv2.Waypoint.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv2.Waypoint.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string auth_id = 2;
 * @return {string}
 */
proto.cbv2.Waypoint.prototype.getAuthId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv2.Waypoint.prototype.setAuthId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional cbv1.Point coordinates = 3;
 * @return {?proto.cbv1.Point}
 */
proto.cbv2.Waypoint.prototype.getCoordinates = function() {
  return /** @type{?proto.cbv1.Point} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Point, 3));
};


/** @param {?proto.cbv1.Point|undefined} value */
proto.cbv2.Waypoint.prototype.setCoordinates = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv2.Waypoint.prototype.clearCoordinates = function() {
  this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv2.Waypoint.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float accuracy = 4;
 * @return {number}
 */
proto.cbv2.Waypoint.prototype.getAccuracy = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.cbv2.Waypoint.prototype.setAccuracy = function(value) {
  jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float speed = 5;
 * @return {number}
 */
proto.cbv2.Waypoint.prototype.getSpeed = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.cbv2.Waypoint.prototype.setSpeed = function(value) {
  jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 client_created_unix = 6;
 * @return {number}
 */
proto.cbv2.Waypoint.prototype.getClientCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv2.Waypoint.prototype.setClientCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv2.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv2.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv2.User.displayName = 'proto.cbv2.User';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv2.User.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv2.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv2.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv2.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    authId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    claims: (f = msg.getClaims()) && proto.cbv2.Claims.toObject(includeInstance, f),
    disabled: jspb.Message.getFieldWithDefault(msg, 6, false),
    pin: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv2.User}
 */
proto.cbv2.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv2.User;
  return proto.cbv2.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv2.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv2.User}
 */
proto.cbv2.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 5:
      var value = new proto.cbv2.Claims;
      reader.readMessage(value,proto.cbv2.Claims.deserializeBinaryFromReader);
      msg.setClaims(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv2.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv2.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv2.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv2.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClaims();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.cbv2.Claims.serializeBinaryToWriter
    );
  }
  f = message.getDisabled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPin();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string auth_id = 1;
 * @return {string}
 */
proto.cbv2.User.prototype.getAuthId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv2.User.prototype.setAuthId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv2.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv2.User.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.cbv2.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv2.User.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone = 4;
 * @return {string}
 */
proto.cbv2.User.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv2.User.prototype.setPhone = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Claims claims = 5;
 * @return {?proto.cbv2.Claims}
 */
proto.cbv2.User.prototype.getClaims = function() {
  return /** @type{?proto.cbv2.Claims} */ (
    jspb.Message.getWrapperField(this, proto.cbv2.Claims, 5));
};


/** @param {?proto.cbv2.Claims|undefined} value */
proto.cbv2.User.prototype.setClaims = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.cbv2.User.prototype.clearClaims = function() {
  this.setClaims(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv2.User.prototype.hasClaims = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool disabled = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv2.User.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.cbv2.User.prototype.setDisabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string pin = 7;
 * @return {string}
 */
proto.cbv2.User.prototype.getPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cbv2.User.prototype.setPin = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv2.UserV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv2.UserV2.repeatedFields_, null);
};
goog.inherits(proto.cbv2.UserV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv2.UserV2.displayName = 'proto.cbv2.UserV2';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv2.UserV2.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv2.UserV2.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv2.UserV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv2.UserV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv2.UserV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    authId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    account: (f = msg.getAccount()) && proto_user_account_account_pb.Account.toObject(includeInstance, f),
    disabled: jspb.Message.getFieldWithDefault(msg, 6, false),
    pin: jspb.Message.getFieldWithDefault(msg, 7, ""),
    ssoEnabled: jspb.Message.getFieldWithDefault(msg, 8, false),
    role: jspb.Message.getFieldWithDefault(msg, 9, 0),
    permissionsList: jspb.Message.getRepeatedField(msg, 10)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv2.UserV2}
 */
proto.cbv2.UserV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv2.UserV2;
  return proto.cbv2.UserV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv2.UserV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv2.UserV2}
 */
proto.cbv2.UserV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 5:
      var value = new proto_user_account_account_pb.Account;
      reader.readMessage(value,proto_user_account_account_pb.Account.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPin(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSsoEnabled(value);
      break;
    case 9:
      var value = /** @type {!proto.cbv2.RoleV2} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 10:
      var value = /** @type {!Array<!proto.cbv1.UserPermissions>} */ (reader.readPackedEnum());
      msg.setPermissionsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv2.UserV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv2.UserV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv2.UserV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv2.UserV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_user_account_account_pb.Account.serializeBinaryToWriter
    );
  }
  f = message.getDisabled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPin();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSsoEnabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      10,
      f
    );
  }
};


/**
 * optional string auth_id = 1;
 * @return {string}
 */
proto.cbv2.UserV2.prototype.getAuthId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv2.UserV2.prototype.setAuthId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv2.UserV2.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv2.UserV2.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.cbv2.UserV2.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv2.UserV2.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone = 4;
 * @return {string}
 */
proto.cbv2.UserV2.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv2.UserV2.prototype.setPhone = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Account account = 5;
 * @return {?proto.cbv2.Account}
 */
proto.cbv2.UserV2.prototype.getAccount = function() {
  return /** @type{?proto.cbv2.Account} */ (
    jspb.Message.getWrapperField(this, proto_user_account_account_pb.Account, 5));
};


/** @param {?proto.cbv2.Account|undefined} value */
proto.cbv2.UserV2.prototype.setAccount = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.cbv2.UserV2.prototype.clearAccount = function() {
  this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv2.UserV2.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool disabled = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv2.UserV2.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.cbv2.UserV2.prototype.setDisabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string pin = 7;
 * @return {string}
 */
proto.cbv2.UserV2.prototype.getPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cbv2.UserV2.prototype.setPin = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool sso_enabled = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv2.UserV2.prototype.getSsoEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.cbv2.UserV2.prototype.setSsoEnabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional RoleV2 role = 9;
 * @return {!proto.cbv2.RoleV2}
 */
proto.cbv2.UserV2.prototype.getRole = function() {
  return /** @type {!proto.cbv2.RoleV2} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {!proto.cbv2.RoleV2} value */
proto.cbv2.UserV2.prototype.setRole = function(value) {
  jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * repeated cbv1.UserPermissions permissions = 10;
 * @return {!Array<!proto.cbv1.UserPermissions>}
 */
proto.cbv2.UserV2.prototype.getPermissionsList = function() {
  return /** @type {!Array<!proto.cbv1.UserPermissions>} */ (jspb.Message.getRepeatedField(this, 10));
};


/** @param {!Array<!proto.cbv1.UserPermissions>} value */
proto.cbv2.UserV2.prototype.setPermissionsList = function(value) {
  jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.cbv1.UserPermissions} value
 * @param {number=} opt_index
 */
proto.cbv2.UserV2.prototype.addPermissions = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


proto.cbv2.UserV2.prototype.clearPermissionsList = function() {
  this.setPermissionsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv2.UserAccountRoleV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv2.UserAccountRoleV2.repeatedFields_, null);
};
goog.inherits(proto.cbv2.UserAccountRoleV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv2.UserAccountRoleV2.displayName = 'proto.cbv2.UserAccountRoleV2';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv2.UserAccountRoleV2.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv2.UserAccountRoleV2.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv2.UserAccountRoleV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv2.UserAccountRoleV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv2.UserAccountRoleV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolesList: jspb.Message.getRepeatedField(msg, 1),
    account: (f = msg.getAccount()) && proto_user_account_account_pb.Account.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv2.UserAccountRoleV2}
 */
proto.cbv2.UserAccountRoleV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv2.UserAccountRoleV2;
  return proto.cbv2.UserAccountRoleV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv2.UserAccountRoleV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv2.UserAccountRoleV2}
 */
proto.cbv2.UserAccountRoleV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<!proto.cbv2.RoleV2>} */ (reader.readPackedEnum());
      msg.setRolesList(value);
      break;
    case 2:
      var value = new proto_user_account_account_pb.Account;
      reader.readMessage(value,proto_user_account_account_pb.Account.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv2.UserAccountRoleV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv2.UserAccountRoleV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv2.UserAccountRoleV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv2.UserAccountRoleV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_user_account_account_pb.Account.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RoleV2 roles = 1;
 * @return {!Array<!proto.cbv2.RoleV2>}
 */
proto.cbv2.UserAccountRoleV2.prototype.getRolesList = function() {
  return /** @type {!Array<!proto.cbv2.RoleV2>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<!proto.cbv2.RoleV2>} value */
proto.cbv2.UserAccountRoleV2.prototype.setRolesList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.cbv2.RoleV2} value
 * @param {number=} opt_index
 */
proto.cbv2.UserAccountRoleV2.prototype.addRoles = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv2.UserAccountRoleV2.prototype.clearRolesList = function() {
  this.setRolesList([]);
};


/**
 * optional Account account = 2;
 * @return {?proto.cbv2.Account}
 */
proto.cbv2.UserAccountRoleV2.prototype.getAccount = function() {
  return /** @type{?proto.cbv2.Account} */ (
    jspb.Message.getWrapperField(this, proto_user_account_account_pb.Account, 2));
};


/** @param {?proto.cbv2.Account|undefined} value */
proto.cbv2.UserAccountRoleV2.prototype.setAccount = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cbv2.UserAccountRoleV2.prototype.clearAccount = function() {
  this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv2.UserAccountRoleV2.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv2.Claims = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv2.Claims.repeatedFields_, null);
};
goog.inherits(proto.cbv2.Claims, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv2.Claims.displayName = 'proto.cbv2.Claims';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv2.Claims.repeatedFields_ = [4,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv2.Claims.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv2.Claims.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv2.Claims} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv2.Claims.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountSession: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    scopesList: jspb.Message.getRepeatedField(msg, 4),
    role: jspb.Message.getFieldWithDefault(msg, 6, 0),
    analytics: (f = msg.getAnalytics()) && proto.cbv2.Analytics.toObject(includeInstance, f),
    authsList: jspb.Message.toObjectList(msg.getAuthsList(),
    proto.cbv2.UserAccountRoleV2.toObject, includeInstance),
    ssoEnabled: jspb.Message.getFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv2.Claims}
 */
proto.cbv2.Claims.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv2.Claims;
  return proto.cbv2.Claims.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv2.Claims} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv2.Claims}
 */
proto.cbv2.Claims.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountSession(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 3:
      var value = /** @type {!proto.cbv2.AccountTypeV2} */ (reader.readEnum());
      msg.setAccountType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addScopes(value);
      break;
    case 6:
      var value = /** @type {!proto.cbv2.RoleV2} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 7:
      var value = new proto.cbv2.Analytics;
      reader.readMessage(value,proto.cbv2.Analytics.deserializeBinaryFromReader);
      msg.setAnalytics(value);
      break;
    case 8:
      var value = new proto.cbv2.UserAccountRoleV2;
      reader.readMessage(value,proto.cbv2.UserAccountRoleV2.deserializeBinaryFromReader);
      msg.addAuths(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSsoEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv2.Claims.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv2.Claims.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv2.Claims} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv2.Claims.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountSession();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getScopesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAnalytics();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.cbv2.Analytics.serializeBinaryToWriter
    );
  }
  f = message.getAuthsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.cbv2.UserAccountRoleV2.serializeBinaryToWriter
    );
  }
  f = message.getSsoEnabled();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional int32 account_session = 1;
 * @return {number}
 */
proto.cbv2.Claims.prototype.getAccountSession = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv2.Claims.prototype.setAccountSession = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string account_name = 2;
 * @return {string}
 */
proto.cbv2.Claims.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv2.Claims.prototype.setAccountName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AccountTypeV2 account_type = 3;
 * @return {!proto.cbv2.AccountTypeV2}
 */
proto.cbv2.Claims.prototype.getAccountType = function() {
  return /** @type {!proto.cbv2.AccountTypeV2} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.cbv2.AccountTypeV2} value */
proto.cbv2.Claims.prototype.setAccountType = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated string scopes = 4;
 * @return {!Array<string>}
 */
proto.cbv2.Claims.prototype.getScopesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/** @param {!Array<string>} value */
proto.cbv2.Claims.prototype.setScopesList = function(value) {
  jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv2.Claims.prototype.addScopes = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


proto.cbv2.Claims.prototype.clearScopesList = function() {
  this.setScopesList([]);
};


/**
 * optional RoleV2 role = 6;
 * @return {!proto.cbv2.RoleV2}
 */
proto.cbv2.Claims.prototype.getRole = function() {
  return /** @type {!proto.cbv2.RoleV2} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.cbv2.RoleV2} value */
proto.cbv2.Claims.prototype.setRole = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional Analytics analytics = 7;
 * @return {?proto.cbv2.Analytics}
 */
proto.cbv2.Claims.prototype.getAnalytics = function() {
  return /** @type{?proto.cbv2.Analytics} */ (
    jspb.Message.getWrapperField(this, proto.cbv2.Analytics, 7));
};


/** @param {?proto.cbv2.Analytics|undefined} value */
proto.cbv2.Claims.prototype.setAnalytics = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


proto.cbv2.Claims.prototype.clearAnalytics = function() {
  this.setAnalytics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv2.Claims.prototype.hasAnalytics = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated UserAccountRoleV2 auths = 8;
 * @return {!Array<!proto.cbv2.UserAccountRoleV2>}
 */
proto.cbv2.Claims.prototype.getAuthsList = function() {
  return /** @type{!Array<!proto.cbv2.UserAccountRoleV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv2.UserAccountRoleV2, 8));
};


/** @param {!Array<!proto.cbv2.UserAccountRoleV2>} value */
proto.cbv2.Claims.prototype.setAuthsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.cbv2.UserAccountRoleV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv2.UserAccountRoleV2}
 */
proto.cbv2.Claims.prototype.addAuths = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.cbv2.UserAccountRoleV2, opt_index);
};


proto.cbv2.Claims.prototype.clearAuthsList = function() {
  this.setAuthsList([]);
};


/**
 * optional bool sso_enabled = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv2.Claims.prototype.getSsoEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.cbv2.Claims.prototype.setSsoEnabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv2.Driver = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv2.Driver, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv2.Driver.displayName = 'proto.cbv2.Driver';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv2.Driver.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv2.Driver.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv2.Driver} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv2.Driver.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.cbv2.User.toObject(includeInstance, f),
    driverStatus: (f = msg.getDriverStatus()) && proto.cbv2.DriverStatus.toObject(includeInstance, f),
    lastWaypoint: (f = msg.getLastWaypoint()) && proto.cbv2.LastWayPoint.toObject(includeInstance, f),
    isAssigned: jspb.Message.getFieldWithDefault(msg, 5, false),
    hosRemainingInMinutes: jspb.Message.getFieldWithDefault(msg, 6, 0),
    fcmtoken: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv2.Driver}
 */
proto.cbv2.Driver.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv2.Driver;
  return proto.cbv2.Driver.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv2.Driver} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv2.Driver}
 */
proto.cbv2.Driver.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cbv2.User;
      reader.readMessage(value,proto.cbv2.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = new proto.cbv2.DriverStatus;
      reader.readMessage(value,proto.cbv2.DriverStatus.deserializeBinaryFromReader);
      msg.setDriverStatus(value);
      break;
    case 4:
      var value = new proto.cbv2.LastWayPoint;
      reader.readMessage(value,proto.cbv2.LastWayPoint.deserializeBinaryFromReader);
      msg.setLastWaypoint(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAssigned(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHosRemainingInMinutes(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFcmtoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv2.Driver.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv2.Driver.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv2.Driver} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv2.Driver.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cbv2.User.serializeBinaryToWriter
    );
  }
  f = message.getDriverStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cbv2.DriverStatus.serializeBinaryToWriter
    );
  }
  f = message.getLastWaypoint();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.cbv2.LastWayPoint.serializeBinaryToWriter
    );
  }
  f = message.getIsAssigned();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getHosRemainingInMinutes();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getFcmtoken();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.cbv2.User}
 */
proto.cbv2.Driver.prototype.getUser = function() {
  return /** @type{?proto.cbv2.User} */ (
    jspb.Message.getWrapperField(this, proto.cbv2.User, 1));
};


/** @param {?proto.cbv2.User|undefined} value */
proto.cbv2.Driver.prototype.setUser = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv2.Driver.prototype.clearUser = function() {
  this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv2.Driver.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DriverStatus driver_status = 3;
 * @return {?proto.cbv2.DriverStatus}
 */
proto.cbv2.Driver.prototype.getDriverStatus = function() {
  return /** @type{?proto.cbv2.DriverStatus} */ (
    jspb.Message.getWrapperField(this, proto.cbv2.DriverStatus, 3));
};


/** @param {?proto.cbv2.DriverStatus|undefined} value */
proto.cbv2.Driver.prototype.setDriverStatus = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv2.Driver.prototype.clearDriverStatus = function() {
  this.setDriverStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv2.Driver.prototype.hasDriverStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LastWayPoint last_waypoint = 4;
 * @return {?proto.cbv2.LastWayPoint}
 */
proto.cbv2.Driver.prototype.getLastWaypoint = function() {
  return /** @type{?proto.cbv2.LastWayPoint} */ (
    jspb.Message.getWrapperField(this, proto.cbv2.LastWayPoint, 4));
};


/** @param {?proto.cbv2.LastWayPoint|undefined} value */
proto.cbv2.Driver.prototype.setLastWaypoint = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.cbv2.Driver.prototype.clearLastWaypoint = function() {
  this.setLastWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv2.Driver.prototype.hasLastWaypoint = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool is_assigned = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv2.Driver.prototype.getIsAssigned = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.cbv2.Driver.prototype.setIsAssigned = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int64 hos_remaining_in_minutes = 6;
 * @return {number}
 */
proto.cbv2.Driver.prototype.getHosRemainingInMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv2.Driver.prototype.setHosRemainingInMinutes = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string FCMToken = 7;
 * @return {string}
 */
proto.cbv2.Driver.prototype.getFcmtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cbv2.Driver.prototype.setFcmtoken = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv2.DriverStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv2.DriverStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv2.DriverStatus.displayName = 'proto.cbv2.DriverStatus';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv2.DriverStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv2.DriverStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv2.DriverStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv2.DriverStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    driverStatusType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isException: jspb.Message.getFieldWithDefault(msg, 2, false),
    shortTitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    exceptionTitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    exceptionDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    exceptionUrl: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv2.DriverStatus}
 */
proto.cbv2.DriverStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv2.DriverStatus;
  return proto.cbv2.DriverStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv2.DriverStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv2.DriverStatus}
 */
proto.cbv2.DriverStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cbv2.DriverStatusTypeV2} */ (reader.readEnum());
      msg.setDriverStatusType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsException(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExceptionTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExceptionDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExceptionUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv2.DriverStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv2.DriverStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv2.DriverStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv2.DriverStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriverStatusType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getIsException();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getShortTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExceptionTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExceptionDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExceptionUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional DriverStatusTypeV2 driver_status_type = 1;
 * @return {!proto.cbv2.DriverStatusTypeV2}
 */
proto.cbv2.DriverStatus.prototype.getDriverStatusType = function() {
  return /** @type {!proto.cbv2.DriverStatusTypeV2} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.cbv2.DriverStatusTypeV2} value */
proto.cbv2.DriverStatus.prototype.setDriverStatusType = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool is_exception = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv2.DriverStatus.prototype.getIsException = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv2.DriverStatus.prototype.setIsException = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string short_title = 3;
 * @return {string}
 */
proto.cbv2.DriverStatus.prototype.getShortTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv2.DriverStatus.prototype.setShortTitle = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string exception_title = 4;
 * @return {string}
 */
proto.cbv2.DriverStatus.prototype.getExceptionTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv2.DriverStatus.prototype.setExceptionTitle = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string exception_description = 5;
 * @return {string}
 */
proto.cbv2.DriverStatus.prototype.getExceptionDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv2.DriverStatus.prototype.setExceptionDescription = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string exception_url = 6;
 * @return {string}
 */
proto.cbv2.DriverStatus.prototype.getExceptionUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cbv2.DriverStatus.prototype.setExceptionUrl = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv2.LastWayPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv2.LastWayPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv2.LastWayPoint.displayName = 'proto.cbv2.LastWayPoint';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv2.LastWayPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv2.LastWayPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv2.LastWayPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv2.LastWayPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    coordinates: (f = msg.getCoordinates()) && proto_geo_geo_pb.Point.toObject(includeInstance, f),
    clientCreatedUnix: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createdUnix: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv2.LastWayPoint}
 */
proto.cbv2.LastWayPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv2.LastWayPoint;
  return proto.cbv2.LastWayPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv2.LastWayPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv2.LastWayPoint}
 */
proto.cbv2.LastWayPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_geo_geo_pb.Point;
      reader.readMessage(value,proto_geo_geo_pb.Point.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setClientCreatedUnix(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedUnix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv2.LastWayPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv2.LastWayPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv2.LastWayPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv2.LastWayPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_geo_geo_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getClientCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional cbv1.Point coordinates = 1;
 * @return {?proto.cbv1.Point}
 */
proto.cbv2.LastWayPoint.prototype.getCoordinates = function() {
  return /** @type{?proto.cbv1.Point} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Point, 1));
};


/** @param {?proto.cbv1.Point|undefined} value */
proto.cbv2.LastWayPoint.prototype.setCoordinates = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv2.LastWayPoint.prototype.clearCoordinates = function() {
  this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv2.LastWayPoint.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 client_created_unix = 2;
 * @return {number}
 */
proto.cbv2.LastWayPoint.prototype.getClientCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv2.LastWayPoint.prototype.setClientCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 created_unix = 3;
 * @return {number}
 */
proto.cbv2.LastWayPoint.prototype.getCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv2.LastWayPoint.prototype.setCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv2.Analytics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv2.Analytics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv2.Analytics.displayName = 'proto.cbv2.Analytics';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv2.Analytics.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv2.Analytics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv2.Analytics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv2.Analytics.toObject = function(includeInstance, msg) {
  var f, obj = {
    segment: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isBuyer: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv2.Analytics}
 */
proto.cbv2.Analytics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv2.Analytics;
  return proto.cbv2.Analytics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv2.Analytics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv2.Analytics}
 */
proto.cbv2.Analytics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cbv2.Segment} */ (reader.readEnum());
      msg.setSegment(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBuyer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv2.Analytics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv2.Analytics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv2.Analytics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv2.Analytics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSegment();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getIsBuyer();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Segment segment = 1;
 * @return {!proto.cbv2.Segment}
 */
proto.cbv2.Analytics.prototype.getSegment = function() {
  return /** @type {!proto.cbv2.Segment} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.cbv2.Segment} value */
proto.cbv2.Analytics.prototype.setSegment = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool is_buyer = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv2.Analytics.prototype.getIsBuyer = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv2.Analytics.prototype.setIsBuyer = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.cbv2.RoleV2 = {
  ROLE_INVALID: 0,
  ROLE_ADMIN: 1,
  ROLE_DEFAULT: 2,
  ROLE_DRIVER: 3,
  ROLE_VIEW_ONLY: 4,
  ROLE_CREW_LEADER: 7
};

/**
 * @enum {number}
 */
proto.cbv2.DriverStatusTypeV2 = {
  DRIVER_STATUS_TYPE_INVALID: 0,
  DRIVER_STATUS_TYPE_OFFLINE: 1,
  DRIVER_STATUS_TYPE_ONLINE: 2,
  DRIVER_STATUS_TYPE_ACTION_NEEDED: 3,
  DRIVER_STATUS_TYPE_UNREACHABLE: 4
};

/**
 * @enum {number}
 */
proto.cbv2.Segment = {
  INVALID_SEGMENT: 0,
  FIELD: 1,
  PROCUREMENT: 2,
  LOGISTICS: 3,
  FINANCE: 4,
  MANAGEMENT: 5
};

goog.object.extend(exports, proto.cbv2);
