import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import { once } from 'remeda';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, take } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { SelectBusinessHoursComponent } from '~common/select-business-hours/select-business-hours.component';
import { LmoDistrictService } from '~lmo/services/lmo-district.service';
import { DriverCertification } from '~proto/drivercertification/driverCertification_pb';
import { UpdateSiteRequest } from '~proto/site/site_api_pb';
import { District, Site, SiteAvailableDuration } from '~proto/site/site_pb';
import { createCircle } from '~utilities/createCircle';
import { trackById } from '~utilities/trackById';
import { DriverCertificationService } from '../../../dispatcher/services/driver-certification.service';

interface FormValue {
  address: string;
  businessHours: string;
  name: string;
  directions: string;
  districtId: number | null;
  latitude: number;
  longitude: number;
  radius: number;
  type: string;
  driverCertificationIds: number[];
}

const metersInAMile = 1609;

@Component({
  selector: 'ct-lmo-edit-job',
  styleUrls: ['./lmo-edit-job.component.scss'],
  templateUrl: './lmo-edit-job.component.html',
})
export class LmoEditJobComponent implements OnInit, AfterViewInit {
  @ViewChild('map', { static: false }) private mapElement: ElementRef;
  public site$: Observable<Site.AsObject>;
  public networkActive$$ = new BehaviorSubject<boolean>(false);
  public driverCertifications$: Observable<DriverCertification.AsObject[]>;
  public lmoDistricts$: Observable<District.AsObject[]>;
  public trackById = trackById;
  public map: google.maps.Map;
  public editForm: FormGroup;
  public geofence: google.maps.Circle;
  public mineMarker: google.maps.Marker;
  public businessHours: SiteAvailableDuration[] = [];
  public weekMap = {
    0: 'MONDAY',
    1: 'TUESDAY',
    2: 'WEDNESDAY',
    3: 'THURSDAY',
    4: 'FRIDAY',
    5: 'SATURDAY',
    6: 'SUNDAY',
  };

  public get radius(): FormControl {
    return this.editForm.get('radius') as FormControl;
  }

  public get latitude(): FormControl {
    return this.editForm.get('latitude') as FormControl;
  }

  public get longitude(): FormControl {
    return this.editForm.get('longitude') as FormControl;
  }

  public get formValue(): FormValue {
    return this.editForm.value;
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private jobSitesService: JobSitesService,
    private driverCertificationService: DriverCertificationService,
    private lmoDistrictService: LmoDistrictService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {}

  public ngOnInit() {
    this.site$ = this.jobSitesService.currentSite$;
    this.driverCertifications$ = this.driverCertificationService.driverCertifications$;
    this.lmoDistricts$ = this.lmoDistrictService.lmoDistricts$;

    this.editForm = this.fb.group({
      address: ['', []],
      businessHours: ['', []],
      directions: ['', []],
      districtId: [null, []],
      driverCertificationIds: [[]],
      latitude: [null, [Validators.required]],
      longitude: [null, [Validators.required]],
      name: [null, [Validators.required]],
      radius: [null, [Validators.required, Validators.min(0.02)]],
      type: ['well', [Validators.required]],
    });
    const initMapOnce = once(() => this.initMap());

    this.site$
      .pipe(
        filter((site) => !!site),
        take(1),
      )
      .subscribe((site) => {
        this.editForm.patchValue({
          address: site.address,
          directions: site.directions,
          districtId: site.district ? site.district.id : null,
          driverCertificationIds: site.driverCertificationsList.map((cert) => cert.id),
          latitude: site.geofence.center.lat,
          longitude: site.geofence.center.lon,
          name: site.name,
          radius: Math.round((site.geofence.radius / 1609) * 100) / 100,
          type: site.siteType,
        });
        initMapOnce();
        this.setBusinessHourString(site.availabledurationsList);
        this.radius.valueChanges
          .pipe(
            debounceTime(500),
            distinctUntilChanged(),
          )
          .subscribe(this.handleRadiusInputChanges.bind(this));
      });
  }

  public ngAfterViewInit(): void {}

  private setBusinessHourString(days) {
    const openDays = [];
    let hoursValue = '';
    days.forEach((dayValue) => {
      const availableDuration = new SiteAvailableDuration();
      availableDuration.setDay(dayValue.day); // Proto Starts from Invalid as 0, Monday as 1 and so on...
      availableDuration.setOpeningTime(dayValue.openingTime);
      availableDuration.setClosingTime(dayValue.closingTime);
      openDays.push(availableDuration);
      hoursValue += `${hoursValue !== '' ? ', ' : ''}${this.weekMap[dayValue.day - 1]}(${dayValue.openingTime} - ${
        dayValue.closingTime
      })`;
    });
    this.businessHours = [...openDays];
    this.editForm.controls['businessHours'].setValue(hoursValue);
  }

  private async initMap() {
    for (let i = 0; i < 10 && !this.mapElement; i++) {
      await new Promise((resolve) => setTimeout(resolve, 200));
    }
    if (!this.mapElement) {
      console.log('Failed to find map element');
      return;
    }
    const center = {
      lat: this.latitude.value,
      lng: this.longitude.value,
    };
    const mapConfig = {
      center: center,
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: 3,
      },
      mapTypeId: google.maps.MapTypeId.HYBRID,
      streetViewControl: false,
      zoom: 12,
      zoomControlOptions: {
        position: 3,
      },
    };

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapConfig);
    this.mineMarker = new google.maps.Marker({
      animation: google.maps.Animation.DROP,
      draggable: true,
      map: this.map,
      position: this.map.getCenter(),
    });
    this.geofence = new google.maps.Circle({
      center: this.map.getCenter(),
      editable: true,
      fillColor: '#00FF00',
      fillOpacity: 0.35,
      map: this.map,
      radius: this.radius.value * metersInAMile,
      strokeColor: '#00FF00',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      zIndex: 10,
    });
    this.geofence.bindTo('center', this.mineMarker, 'position');
    google.maps.event.addListener(this.mineMarker, 'position_changed', this.handleMineMarkerMove.bind(this));
    google.maps.event.addListener(this.geofence, 'radius_changed', this.handleGeofenceRadiusChange.bind(this));
  }

  public editBusinessHours() {
    const dialogRef = this.dialog.open(SelectBusinessHoursComponent, {
      data: this.businessHours,
    });
    dialogRef
      .afterClosed()
      .pipe(filter((value) => !!value && value.length))
      .subscribe((value: SiteAvailableDuration[]) => {
        this.businessHours = [...value];
        let hoursValue = '';
        value.forEach((day) => {
          hoursValue += `${hoursValue !== '' ? ', ' : ''}${
            this.weekMap[day.getDay() - 1]
          }(${day.getOpeningTime()} - ${day.getClosingTime()})`;
        });
        this.editForm.controls['businessHours'].setValue(hoursValue);
      });
  }

  public updateJob() {
    this.editForm.markAllAsTouched();
    if (this.editForm.invalid) {
      return;
    }
    this.networkActive$$.next(true);
    const formValue = this.formValue;
    const request = new UpdateSiteRequest();
    const circle = createCircle(formValue.latitude, formValue.longitude, formValue.radius);
    request.setName(formValue.name);
    request.setAddress(formValue.address);
    request.setGeofence(circle);
    request.setSiteType(formValue.type);
    request.setDirections(formValue.directions);
    request.setDriverCertificationIdsList(formValue.driverCertificationIds);
    if (this.editForm.get('type').value !== 'well') {
      request.setAvailabledurationsList(this.businessHours);
    }

    const fieldMask = new FieldMask();
    fieldMask.addPaths('name');
    fieldMask.addPaths('location');
    fieldMask.addPaths('address');
    fieldMask.addPaths('radius');
    fieldMask.addPaths('siteTypeId');
    fieldMask.addPaths('directions');
    fieldMask.addPaths('availableDurations');
    if (formValue.districtId) {
      request.setDistrictId(formValue.districtId);
      fieldMask.addPaths('lmoDistrictId');
    }

    request.setMask(fieldMask);
    this.jobSitesService
      .updateSite$(request)
      .pipe(
        finalize(() => {
          this.networkActive$$.next(false);
        }),
      )
      .subscribe((site: Site.AsObject) => {
        this.snackBar.open(`${site.name} Updated`, null, { duration: 5000 });
        this.router.navigate(['/', 'lmo', 'jobs', site.id]);
      });
  }

  public centerMarkerOnMap() {
    const currentMapCenter = this.map.getCenter();
    this.mineMarker.setPosition({
      lat: currentMapCenter.lat(),
      lng: currentMapCenter.lng(),
    });
  }

  public centerMapOnMarker() {
    const currentMarkerPosition = this.mineMarker.getPosition();
    this.map.setCenter(currentMarkerPosition);
  }

  public handleLatitudeInputChange() {
    const latitude = this.formValue.latitude;
    const currentPosition = this.mineMarker.getPosition();
    this.mineMarker.setPosition({ lat: +latitude, lng: currentPosition.lng() });
    this.centerMapOnMarker();
  }

  public handleLongitudeInputChange() {
    const longitude = this.formValue.longitude;
    const currentPosition = this.mineMarker.getPosition();
    this.mineMarker.setPosition({ lng: +longitude, lat: currentPosition.lat() });
    this.centerMapOnMarker();
  }

  private handleMineMarkerMove() {
    const newPosition = this.mineMarker.getPosition();
    this.latitude.setValue(newPosition.lat(), { emitEvent: false });
    this.longitude.setValue(newPosition.lng(), { emitEvent: false });
  }

  private handleGeofenceRadiusChange() {
    const newRadius = this.geofence.getRadius() / metersInAMile;
    if (newRadius <= 10) {
      this.radius.setValue(Math.round(newRadius * 100) / 100);
    } else {
      this.geofence.setRadius(10 * metersInAMile);
    }
  }

  private handleRadiusInputChanges(radius: string) {
    this.geofence.setRadius(+radius * metersInAMile);
  }
}
