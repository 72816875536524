import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { CalendarUtils, DAYS_OF_WEEK } from 'angular-calendar';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { assignedOrderPluralMapping } from '~plural/assigned-orders';
import { orderPluralMapping } from '~plural/orders';
// weekStartsOn option is ignored when using moment, as it needs to be configured globally for the moment locale
moment.updateLocale('en', {
    week: {
        dow: DAYS_OF_WEEK.MONDAY,
        doy: 0,
    },
});
// utility to start calendar at current date, instead of 1st of current month
var MyCalendarUtils = /** @class */ (function (_super) {
    tslib_1.__extends(MyCalendarUtils, _super);
    function MyCalendarUtils() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MyCalendarUtils.prototype.getMonthView = function (args) {
        args.viewStart = args.viewDate;
        args.viewEnd = moment(args.viewDate)
            .add(4, 'weeks')
            .toDate();
        return _super.prototype.getMonthView.call(this, args);
    };
    return MyCalendarUtils;
}(CalendarUtils));
export { MyCalendarUtils };
var LmoCalendarComponent = /** @class */ (function () {
    function LmoCalendarComponent(siteService, visibilityService) {
        this.siteService = siteService;
        this.visibilityService = visibilityService;
        this.view = 'month';
        this.viewDate = new Date();
        this.orderPlural = orderPluralMapping;
        this.assignedOrderPlural = assignedOrderPluralMapping;
        // mobile/tablet view uses this visible value to toggle calendar visibility - mobile view should collapse vertically
        this.visible$$ = new BehaviorSubject(true);
        this.visible$ = this.visible$$.asObservable();
    }
    Object.defineProperty(LmoCalendarComponent.prototype, "localStorageKeyWithPrefix", {
        get: function () {
            if (this.localStorageKey) {
                return "card-start-visible:" + this.localStorageKey;
            }
        },
        enumerable: true,
        configurable: true
    });
    LmoCalendarComponent.prototype.ngOnInit = function () {
        this.innerWidth = window.innerWidth;
        this.calendarStats$ = this.siteService.siteCalendarStats$;
        this.calendarHidden$ = this.visibilityService.calendarhidden$;
        if (this.localStorageKeyWithPrefix) {
            var storageSetting = localStorage.getItem(this.localStorageKeyWithPrefix);
            var visible = false;
            // No setting from the user, default to visible
            if (storageSetting === null) {
                visible = true;
            }
            else {
                // The user has an existing setting,  use that
                visible = storageSetting === 'true';
            }
            this.visible$$.next(visible);
            if (visible === false) {
                this.visibilityService.toggleCalendarVisibility();
            }
        }
        this.calendarStatsMap$ = this.siteService.siteCalendarStats$.pipe(map(function (stats) {
            return stats.reduce(function (acc, stat) {
                acc["" + stat.year + stat.month + stat.date] = stat;
                return acc;
            }, {});
        }));
    };
    LmoCalendarComponent.prototype.getStat$ = function (date) {
        var m = moment(date);
        return this.calendarStatsMap$.pipe(map(function (stats) { return stats && stats["" + m.year() + (m.month() + 1) + m.date()]; }));
    };
    LmoCalendarComponent.prototype.toggleMobileVisiblity = function () {
        var newState = !this.visible$$.value;
        this.visible$$.next(newState);
        this.visibilityService.toggleCalendarVisibility();
        if (this.localStorageKeyWithPrefix) {
            localStorage.setItem(this.localStorageKeyWithPrefix, "" + newState);
        }
    };
    LmoCalendarComponent.prototype.toggleVisibility = function (calendarHidden) {
        this.visible$$.next(calendarHidden);
        if (this.localStorageKeyWithPrefix) {
            localStorage.setItem(this.localStorageKeyWithPrefix, "" + calendarHidden);
        }
        this.visibilityService.toggleCalendarVisibility();
    };
    LmoCalendarComponent.prototype.getDateUrl = function (dateString) {
        var date = moment(dateString);
        return ['/', 'lmo', 'jobs', 'calendar', "" + date.year(), "" + (date.month() + 1), "" + date.date()];
    };
    return LmoCalendarComponent;
}());
export { LmoCalendarComponent };
