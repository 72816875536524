import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { differenceInHours } from 'date-fns';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, startWith, take } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { OrdersService } from 'src/app/lmo/services/orders.service';
import { ContractsService } from '~lmo/services/contracts.service';
import { PayloadService } from '~lmo/services/payload.service';
import {
  CreateOrderFormValue,
  createOrderRequestFactory,
  getFormFromLocalStorage,
  saveFormToLocalStorage,
} from '~lmo/utilities/create-order';
import { BusinessLine } from '~proto/businessline/businessline_pb';
import { VendorContract, VendorContractType } from '~proto/contracts/contracts_pb';
import { DetentionStats } from '~proto/order/order_pb';
import { Payload, PayloadGroup } from '~proto/payload/payload_pb';
import { Site } from '~proto/site/site_pb';
import { Trailer } from '~proto/trailer/trailer_pb';
import { IdName } from '~proto/types/types_pb';
import { UserDetailsForIntercomRequest, UserDetailsForIntercomResponse } from '~proto/user/user_api_pb';
import { UserAPI } from '~proto/user/user_api_pb_service';
import { ConstantsService } from '~services/constants.service';
import { GrpcService } from '~services/grpc.service';
import { RoleService } from '~services/role.service';
import { trackById } from '~utilities/trackById';
import { CreateOrderRecentsService } from '../../services/create-order-recents.service';

const CREATE_ORDER_SAVED_SETTINGS = 'CREATE_ORDER_PAYLOAD_AND_TRAILER_SAVED_SETTINGS';

export type PayloadAndTrailerFormValue = Pick<
  CreateOrderFormValue,
  | 'description'
  | 'dropoffSite'
  | 'isAsapOrder'
  | 'loads'
  | 'orderNumber'
  | 'payload'
  | 'pickupFrom'
  | 'purchaseOrderName'
  | 'quantity'
  | 'scheduledDeliveryWindow'
  | 'trailer'
  | 'trailerDropoffLocation'
  | 'trailerPickupLocation'
  | 'truckingVendor'
  | 'costCenter'
  | 'vendorContractId'
  | 'contractTypeId'
  | 'costCenterId'
  | 'businessLine'
  | 'maintenanceOrderNumber'
>;

@Component({
  selector: 'ct-create-order-payload-and-trailer',
  styleUrls: ['../create-order/create-order.component.scss'],
  templateUrl: './create-order-payload-and-trailer.component.html',
})
export class CreateOrderPayloadAndTrailerComponent implements OnInit {
  public currentSite$: Observable<Site.AsObject>;
  public currentSitePayloads$: Observable<PayloadGroup.AsObject[]>;
  public withoutSiteIds$: Observable<number[]>;
  public showDetentionWarning$: Observable<boolean>;
  public networkActive$ = new BehaviorSubject<boolean>(false);
  public formGroup: FormGroup;
  public siteName$: Observable<string>;
  public stats: DetentionStats.AsObject;
  public payloads$: Observable<Payload.AsObject[]>;

  public truckingContracts: VendorContract.AsObject[] = [];
  public trackById = trackById;

  public contractTypes$: Observable<VendorContractType.AsObject[]>;
  public contractType$: Observable<VendorContractType.AsObject>;
  public businessLinesList$$ = new BehaviorSubject<BusinessLine.AsObject[]>(null);
  public isBusinessLineEnabled$$ = new BehaviorSubject<Boolean>(false);
  public isCostCenterRequired$$ = new BehaviorSubject<Boolean>(false);
  public isPurchaseOrderRequired$$ = new BehaviorSubject<Boolean>(false);
  public isSalesOrderRequired$$ = new BehaviorSubject<Boolean>(false);
  public isMaintenanceOrderRequired$$ = new BehaviorSubject<Boolean>(false);
  public isBusinessLineCostCenterRelationEnabled$$ = new BehaviorSubject<Boolean>(false);
  private isCCValid: string;
  private isPOValid: string;
  private isSOValid: string;
  private isMOValid: string;

  constructor(
    private orderService: OrdersService,
    private site: JobSitesService,
    private fb: FormBuilder,
    private router: Router,
    private recentService: CreateOrderRecentsService,
    private snackBar: MatSnackBar,
    private payloadService: PayloadService,
    private contractService: ContractsService,
    // Leave this here so we make sure all the constants are loaded
    private _constantsService: ConstantsService, // tslint-ignore-line
    private roleService: RoleService,
    private grpcService: GrpcService,
  ) {}

  private formValue(): CreateOrderFormValue {
    return this.formGroup.value as CreateOrderFormValue;
  }

  public ngOnInit() {
    this.currentSite$ = this.site.currentSite$;
    this.currentSitePayloads$ = this.payloadService.currentSitePayloads$;
    this.getStats();
    this.siteName$ = this.currentSite$.pipe(
      filter((site) => !!site),
      map((site) => site.name),
    );
    this.withoutSiteIds$ = this.currentSite$.pipe(map((site) => (site ? [site.id] : [])));
    this.currentSite$
      .pipe(take(1))
      .toPromise()
      .then((site) => {
        this.loadRequiredFieldSettings();
        const lastPayloadChoices = getFormFromLocalStorage(site.id, CREATE_ORDER_SAVED_SETTINGS);
        this.formGroup = this.fb.group({
          businessLine: [null],
          contractTypeId: [null],
          costCenter: [null, this.isCostCenterRequired$$.value ? [Validators.required] : ''],
          costCenterId: [null, []],
          description: [null, []],
          isAsapOrder: [null, Validators.required],
          loads: [1, [Validators.required, Validators.min(1), Validators.max(50)]],
          maintenanceOrderNumber: [
            lastPayloadChoices.maintenanceOrderNumber,
            this.isMaintenanceOrderRequired$$.value ? [Validators.required] : '',
          ],
          orderNumber: [lastPayloadChoices.orderNumber, this.isSalesOrderRequired$$.value ? [Validators.required] : ''],
          payload: [lastPayloadChoices.payload, Validators.required],
          pickupFrom: [lastPayloadChoices.pickupFrom, Validators.required],
          purchaseOrderName: [
            lastPayloadChoices.purchaseOrderName,
            this.isPurchaseOrderRequired$$.value ? [Validators.required] : '',
          ],
          quantity: [lastPayloadChoices.quantity, Validators.required],
          scheduledDeliveryWindow: [null, [Validators.required]],
          trailer: [null],
          trailerDropoffLocation: [site, [Validators.required]],
          trailerPickupLocation: [lastPayloadChoices.pickupFrom, [Validators.required]],
          truckingVendor: [lastPayloadChoices.truckingVendor],
          vendorContractId: [null],
        });

        this.recentService.loadSalesOrder();
        if (this.formGroup.get('payload').value) {
          this.recentService.loadPurchaseOrders(this.formGroup.get('payload').value.id);
        }

        this.listenToAsap();
        this.listenToWarnings();
        this.setupPayloads();
        this.listenToPickupLocationChange();
        this.listenToTrailer();

        this.listenContractChange(lastPayloadChoices.truckingVendor);
        this.listenContractTypeChanges();
        this.checkIfBusinessLineEnabled();
        this.checkIsBusinessLineCostCenterRelationEnabled();

        this.formGroup.get('businessLine').valueChanges.subscribe(() => {
          this.formGroup.get('costCenter').setValue(null);
          this.formGroup.get('costCenterId').setValue(null);
        });
      });
  }

  private listenContractTypeChanges() {
    this.contractType$ = this.formGroup.get('contractTypeId').valueChanges;
    this.contractTypes$ = this.contractService.contractTypes$;

    combineLatest([
      this.formGroup.get('contractTypeId').valueChanges,
      this.contractService.nonExpiredContracts$,
    ]).subscribe(([contractTypeId, contracts]) => {
      const contractTypeContracts = contracts.filter((contract) => {
        if (contract.contractType) {
          return +contractTypeId <= 0 || contract.contractType.id === +contractTypeId;
        }
        return +contractTypeId <= 0;
      });
      const vendor = this.formGroup.get('truckingVendor').value;
      if (vendor) {
        const vendorContract = contractTypeContracts.find((contract) => contract.vendor.id === vendor.id);
        if (!(vendorContract && vendorContract.id > 0)) {
          this.formGroup.get('truckingVendor').setValue(null);
        }
      }
    });
  }

  private listenContractChange(initialVendor: IdName.AsObject) {
    combineLatest([
      this.formGroup.get('truckingVendor').valueChanges.pipe(startWith(initialVendor)),
      this.formGroup.get('contractTypeId').valueChanges.pipe(startWith(-1)),
      this.contractService.nonExpiredContracts$,
    ]).subscribe(([vendor, contractTypeId, contracts]) => {
      const contractTypeContracts = contracts.filter((contract) => {
        if (contract.contractType) {
          return +contractTypeId <= 0 || contract.contractType.id === +contractTypeId;
        }
        return +contractTypeId <= 0;
      });
      const contractId = this.formGroup.get('vendorContractId').value;
      if (contractId) {
        const contractById = contractTypeContracts.find((contract) => contract.id === +contractId);
        if (!(contractById && contractById.id > 0)) {
          this.formGroup.get('vendorContractId').setValue(null);
        }
      }
      if (vendor) {
        this.truckingContracts = contracts.filter(
          (contract) =>
            contract.vendor.id === vendor.id &&
            (contractTypeId <= 0 || (contract.contractType && contract.contractType.id === contractTypeId)),
        );
      }
    });

    combineLatest([
      this.formGroup.get('vendorContractId').valueChanges,
      this.contractService.nonExpiredContracts$,
    ]).subscribe(([contractId, contracts]) => {
      if (contractId) {
        const contractById = contracts.find((contract) => contract.id === +contractId);
        this.formGroup.get('contractTypeId').setValue(contractById.contractType ? contractById.contractType.id : -1);
      }
    });
  }

  public getStats() {
    this.orderService.getStats$().subscribe((stats: DetentionStats.AsObject) => {
      this.stats = stats;
    });
  }

  public selected(event: any) {
    if (event.pickupFrom && event.pickupFrom.direction) {
      event.pickupFrom.directions = event.pickupFrom.direction;
    }
    this.formGroup.patchValue(event);
    if (event.payload) {
      this.recentService.loadPurchaseOrders(event.payload.id);
    }
  }

  public selectedTrailerPickupLocation(event: { pickupFrom: Site.AsObject }) {
    this.formGroup.patchValue({ trailerPickupLocation: event.pickupFrom });
  }

  public selectedTrailerDropoffLocation(event: { pickupFrom: Site.AsObject }) {
    this.formGroup.patchValue({ trailerDropoffLocation: event.pickupFrom });
  }

  public async create($event) {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    this.networkActive$.next(true);
    const site = await this.currentSite$.pipe(take(1)).toPromise();
    const formValue: PayloadAndTrailerFormValue = this.formValue();
    formValue.dropoffSite = site;
    saveFormToLocalStorage(formValue, site.id, CREATE_ORDER_SAVED_SETTINGS);
    const createOrderRequest = createOrderRequestFactory(formValue);

    this.requiredFieldsCheck(formValue);

    if (!this.isCCValid || !this.isPOValid || !this.isSOValid || !this.isMOValid) {
      this.snackBar.open('Please fill all mandatory fields', null, {
        duration: 3000,
      });
      this.networkActive$.next(false);
      return;
    }

    this.orderService
      .createOrder$(createOrderRequest)
      .pipe(
        finalize(() => {
          this.networkActive$.next(false);
        }),
      )
      .subscribe((order) => {
        this.snackBar.open('Load successfully scheduled!', null, {
          duration: 3000,
        });
        this.router.navigate(['/', 'lmo', 'jobs', site.id, 'orders', 'pending', order.orderId]);
      });
  }

  private requiredFieldsCheck(formValue: PayloadAndTrailerFormValue) {
    this.isCCValid = this.isCostCenterRequired$$.value ? formValue.costCenter : 'true';
    this.isPOValid = this.isPurchaseOrderRequired$$.value ? formValue.purchaseOrderName : 'true';
    this.isSOValid = this.isSalesOrderRequired$$.value ? formValue.orderNumber : 'true';
    this.isMOValid = this.isMaintenanceOrderRequired$$.value ? formValue.orderNumber : 'true';
  }

  private listenToAsap() {
    this.formGroup
      .get('isAsapOrder')
      .valueChanges.pipe(startWith(this.formGroup.get('isAsapOrder').value))
      .subscribe((value: boolean) => {
        if (value) {
          this.formGroup.get('scheduledDeliveryWindow').disable();
          this.formGroup.get('scheduledDeliveryWindow').reset();
        } else {
          this.formGroup.get('scheduledDeliveryWindow').enable();
          if (this.formGroup.get('loads').value && this.formGroup.get('loads').value > 1) {
          }
        }
      });
  }

  private listenToPickupLocationChange() {
    this.formGroup.get('pickupFrom').valueChanges.subscribe((pickupFrom) => {
      const trailerPickup = this.formGroup.get('trailerPickupLocation');
      if (!trailerPickup.value) {
        trailerPickup.setValue(pickupFrom);
      }
    });
  }

  private setupPayloads() {
    this.payloads$ = this.formGroup
      .get('payload')
      .valueChanges.pipe(startWith(this.formGroup.get('payload').value))
      .pipe(map((payload) => (payload ? [payload] : [])));
  }

  private businessLinesList() {
    this.roleService.businessLinesList$.subscribe((businessLines: BusinessLine.AsObject[]) => {
      this.businessLinesList$$.next(businessLines);
      if (businessLines.length === 1) {
        this.formGroup.controls['businessLine'].setValue(businessLines[0].id);
      }
    });
  }

  private checkIfBusinessLineEnabled() {
    this.roleService.isBusinessLineEnabled$.subscribe((isBusinessLineEnabled) => {
      this.isBusinessLineEnabled$$.next(isBusinessLineEnabled);
      if (isBusinessLineEnabled) {
        this.businessLinesList();
      }
    });
  }

  private checkIsBusinessLineCostCenterRelationEnabled() {
    this.roleService.isBusinessLineCostCenterRelationEnabled$.subscribe((isBLCCRelationEnabled: Boolean) => {
      this.isBusinessLineCostCenterRelationEnabled$$.next(isBLCCRelationEnabled);
    });
  }

  private listenToWarnings() {
    this.showDetentionWarning$ = this.formGroup.valueChanges.pipe(
      map((formValue: CreateOrderFormValue) => {
        if (
          !formValue.isAsapOrder &&
          formValue.scheduledDeliveryWindow &&
          formValue.scheduledDeliveryWindow.length > 1
        ) {
          if (differenceInHours(formValue.scheduledDeliveryWindow[1], formValue.scheduledDeliveryWindow[0]) < 3) {
            return true;
          }
        }
        return false;
      }),
    );
  }

  private listenToTrailer() {
    this.formGroup
      .get('trailer')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((trailer: Trailer.AsObject) => {
        if (trailer) {
          this.formGroup.get('trailerPickupLocation').setValue(trailer.site);
        }
      });
  }

  private loadRequiredFieldSettings() {
    const request = new UserDetailsForIntercomRequest();
    (this.grpcService.invoke$(UserAPI.UserDetailsForIntercom, request) as Observable<
      UserDetailsForIntercomResponse
    >).subscribe((response) => {
      this.isCostCenterRequired$$.next(response.toObject().userDetails.isCostCenterRequired);
      this.isPurchaseOrderRequired$$.next(response.toObject().userDetails.isPurchaseOrderRequired);
      this.isSalesOrderRequired$$.next(response.toObject().userDetails.isSalesOrderRequired);
      this.isMaintenanceOrderRequired$$.next(response.toObject().userDetails.isMaintenanceOrderRequired);
    });
  }

  public trackByIndex(index: number) {
    return index;
  }

  public trackByName(_index: number, record: { name: string }) {
    return record.name;
  }
}
