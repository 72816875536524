/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./choice-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./choice-dialog.component";
var styles_ChoiceDialogComponent = [i0.styles];
var RenderType_ChoiceDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChoiceDialogComponent, data: {} });
export { RenderType_ChoiceDialogComponent as RenderType_ChoiceDialogComponent };
export function View_ChoiceDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["mat-raised-button", ""], ["saAutomatedSelector", "choice-dialog__cancel-action"], ["tabindex", "-1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(9, 0, [" ", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "button", [["cdkFocusInitial", ""], ["color", "error"], ["mat-dialog-close", "true"], ["mat-raised-button", ""], ["saAutomatedSelector", "choice-dialog__continue-action"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).dialogRef.close(i1.ɵnov(_v, 12).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.continueAction() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(12, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(13, 0, [" ", " "]))], function (_ck, _v) { var currVal_9 = "error"; _ck(_v, 11, 0, currVal_9); var currVal_10 = "true"; _ck(_v, 12, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.context; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.data.desc; _ck(_v, 4, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 8).disabled || null); var currVal_3 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = _co.data.button[0]; _ck(_v, 9, 0, currVal_4); var currVal_5 = (i1.ɵnov(_v, 11).disabled || null); var currVal_6 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); var currVal_7 = (i1.ɵnov(_v, 12).ariaLabel || null); var currVal_8 = i1.ɵnov(_v, 12).type; _ck(_v, 10, 0, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_11 = _co.data.button[1]; _ck(_v, 13, 0, currVal_11); }); }
export function View_ChoiceDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sa-choice-dialog", [], null, null, null, View_ChoiceDialogComponent_0, RenderType_ChoiceDialogComponent)), i1.ɵdid(1, 114688, null, 0, i7.ChoiceDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChoiceDialogComponentNgFactory = i1.ɵccf("sa-choice-dialog", i7.ChoiceDialogComponent, View_ChoiceDialogComponent_Host_0, {}, {}, []);
export { ChoiceDialogComponentNgFactory as ChoiceDialogComponentNgFactory };
