import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, tap, throttleTime } from 'rxjs/operators';
import {
  NotificationUserPreferencesRequest,
  NotificationUserPreferencesResponse,
  UpdatePreferenceRequests,
} from '~proto/notification/notification_api_pb';
import { NotificationAPI } from '~proto/notification/notification_api_pb_service';
import { NotificationUserPreference } from '~proto/notification/notification_pb';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { GrpcService } from './grpc.service';

export interface UserPrefWithLoading extends NotificationUserPreference.AsObject {
  loading: boolean;
}

export interface NotificationGroupWithPrefs {
  name: string;
  preferences: UserPrefWithLoading[];
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private preferencesThrottle$$ = new Subject();
  private preferences$$ = new BehaviorSubject<Record<string, UserPrefWithLoading>>({});

  public get preferences$(): Observable<Record<string, UserPrefWithLoading>> {
    this.loadPreferences();
    return this.preferences$$.asObservable();
  }

  public get groups$() {
    this.loadPreferences();
    return this.preferences$$.asObservable().pipe(map((prefs) => convertPrefsToGroups(prefs)));
  }

  constructor(private grpcService: GrpcService, private snackBar: MatSnackBar) {
    this.preferencesThrottle$$.pipe(throttleTime(100)).subscribe(() => this._loadPreferences());
    this.loadPreferences();
  }

  public updatePreferences$(
    request: UpdatePreferenceRequests,
    silent = false,
  ): Observable<NotificationUserPreference.AsObject[]> {
    const current = { ...this.preferences$$.value };
    request.toObject().updatesList.forEach((r) => {
      current[r.id] = {
        ...current[r.id],
        loading: true,
      };
    });
    this.preferences$$.next(current);
    return this.grpcService.invoke$(NotificationAPI.UpdatePreference, request).pipe(
      map((response: NotificationUserPreferencesResponse) => response.toObject().preferencesList),
      tap((preferenceList) => {
        const asLoading: UserPrefWithLoading[] = preferenceList.map((pref) => ({ ...pref, loading: false }));
        this.preferences$$.next(idArrayToRecord(asLoading));
      }),
      tap(() => {
        if (!silent) {
          this.snackBar.open('Preferences Updated', null, { duration: 5000 });
        }
      }),
      catchError(() => {
        request.toObject().updatesList.forEach((r) => {
          current[r.id] = {
            ...current[r.id],
            loading: false,
          };
        });
        return of(null);
      }),
    );
  }

  private loadPreferences() {
    this.preferencesThrottle$$.next();
  }

  private _loadPreferences() {
    const request = new NotificationUserPreferencesRequest();
    this.grpcService
      .invoke$(NotificationAPI.ListPreferences, request)
      .subscribe((prefs: NotificationUserPreferencesResponse) => {
        const asLoading: UserPrefWithLoading[] = prefs
          .toObject()
          .preferencesList.map((pref) => ({ ...pref, loading: false }));
        this.preferences$$.next(idArrayToRecord(asLoading));
      });
  }

}

function convertPrefsToGroups(prefs: Record<string, UserPrefWithLoading>): NotificationGroupWithPrefs[] {
  const groupsMap: Record<string, NotificationGroupWithPrefs> = {};
  Object.values(prefs).forEach((pref) => {
    const groupName = pref.notificationType.notificationGroup.name;
    if (!groupsMap[groupName]) {
      groupsMap[groupName] = {
        name: groupName,
        preferences: [],
      };
    }
    groupsMap[groupName].preferences.push(pref);
  });
  const groups = Object.values(groupsMap);
  groups.forEach((group) =>
    group.preferences.sort((a, b) => a.notificationType.name.localeCompare(b.notificationType.name)),
  );
  groups.sort((a, b) => a.name.localeCompare(b.name));
  return groups;
}
