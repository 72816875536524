import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

export interface LatLng {
  lat: number;
  lng: number;
  title: string;
}

@Component({
  selector: 'ct-show-location-dialog',
  styleUrls: ['./show-location-dialog.component.scss'],
  templateUrl: './show-location-dialog.component.html',
})
export class ShowLocationDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: LatLng) {}

  public ngOnInit() {}
}
