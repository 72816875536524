let canvas: HTMLCanvasElement;

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export function getInputTextWidth(text) {
  // re-use canvas object for better performance
  canvas = canvas || (canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = '16px Averta-Regular';
  const metrics = context.measureText(text);
  return metrics.width;
}
