<div class="show-location">
  <header>
    <a class="close" mat-dialog-close>
      <i class="material-icons">
        close
      </i>
    </a>
    <h3 class="title">{{ data.title }}</h3>
  </header>
  <main>
    <div class="detail-row site-row">
      <div class="lat-lng">
        <ct-input staticLabel label="LATITUDE">
          <input [value]="data.lat" readonly />
        </ct-input>
        <ct-input staticLabel label="LONGITUDE">
          <input [value]="data.lng" readonly />
        </ct-input>
      </div>
      <ct-map [mapLatitude]="data.lat" [mapLongitude]="data.lng"></ct-map>
    </div>
  </main>
</div>
