/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./browser-check.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./browser-check.component";
import * as i4 from "ngx-device-detector";
var styles_BrowserCheckComponent = [i0.styles];
var RenderType_BrowserCheckComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BrowserCheckComponent, data: {} });
export { RenderType_BrowserCheckComponent as RenderType_BrowserCheckComponent };
function View_BrowserCheckComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "browser-warning-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" For the best performance, please use Google Chrome to access HAULi. Internet Explorer is not supported."])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Click here to download Google Chrome: "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["href", "https://www.google.com/chrome/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" https://www.google.com/chrome/ "]))], null, null); }
export function View_BrowserCheckComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BrowserCheckComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.deviceService.browser === "IE"); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BrowserCheckComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ct-browser", [], [[8, "className", 0], [2, "wrong-browser", null]], null, null, View_BrowserCheckComponent_0, RenderType_BrowserCheckComponent)), i1.ɵdid(1, 114688, null, 0, i3.BrowserCheckComponent, [i4.DeviceDetectorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; var currVal_1 = i1.ɵnov(_v, 1).isIE; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var BrowserCheckComponentNgFactory = i1.ɵccf("ct-browser", i3.BrowserCheckComponent, View_BrowserCheckComponent_Host_0, {}, {}, []);
export { BrowserCheckComponentNgFactory as BrowserCheckComponentNgFactory };
