import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { sort } from 'remeda';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, startWith, take } from 'rxjs/operators';
import { CreateMileageCostsRequest, CreateVendorContractRequest } from '~proto/contracts/contracts_api_pb';
import { NullableTime, VendorContractTravelCostType } from '~proto/types/types_pb';
import { recordToArray } from '~utilities/recordToArray';
import { trackById } from '~utilities/trackById';
var MAX_FILE_SIZE = 25 * 1024 * 1024;
var LmoTruckingContractsCreationComponent = /** @class */ (function () {
    function LmoTruckingContractsCreationComponent(fb, constantsService, accountService, contractService, router, matDialog, snackBar, fuelSurchargeService) {
        this.fb = fb;
        this.constantsService = constantsService;
        this.accountService = accountService;
        this.contractService = contractService;
        this.router = router;
        this.matDialog = matDialog;
        this.snackBar = snackBar;
        this.fuelSurchargeService = fuelSurchargeService;
        this.trackById = trackById;
        this.today = new Date();
        this.contractTypes = [];
        this.position = 'below';
        this.message = 'Require contract type with fuel surcharge';
        this.toolTipDisabled = false;
        this.backHaulMessage = 'Require back haul option as Yes';
        this.backHaulToolTipDisabled = false;
        this.VendorContractTravelCostType = VendorContractTravelCostType;
        this.lineHaulOptions = [
            {
                name: 'Fixed Cost Mileage Bracket',
                value: VendorContractTravelCostType.MILEAGE_BRACKET,
            },
            {
                name: 'Fixed Cost - Point to Point',
                value: VendorContractTravelCostType.FIXED_POINT,
            },
            {
                name: 'Hourly',
                value: VendorContractTravelCostType.PER_HOUR,
            },
            {
                name: 'Per Mile',
                value: VendorContractTravelCostType.PER_MILE,
            },
        ];
        this.truckVendorSearch = new FormControl();
        this.networkActive$$ = new BehaviorSubject(false);
        this.uploadActive$$ = new BehaviorSubject(false);
    }
    Object.defineProperty(LmoTruckingContractsCreationComponent.prototype, "mileageBrackets", {
        get: function () {
            return this.formGroup.get('mileageBrackets');
        },
        enumerable: true,
        configurable: true
    });
    LmoTruckingContractsCreationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.today.setHours(0, 0, 0, 0);
        this.formGroup = this.fb.group({
            backHaulChargePercent: [{ value: 0, disabled: true }, [Validators.max(100), Validators.min(0)]],
            contractName: [null, [Validators.required]],
            contractType: [null],
            costPerHour: [null, [Validators.required]],
            costPerMile: [null, [Validators.required]],
            deadheadCost: [0, [Validators.required]],
            deadheadFreeDistance: [0, [Validators.required]],
            defaultContract: [false, [Validators.required]],
            detentionHourly: [0, [Validators.required]],
            detentionMax: [0, [Validators.required]],
            effectiveDate: [new Date()],
            expirationDate: [null],
            fileId: [null, [Validators.required]],
            freeTimeDropoff: [0, [Validators.required]],
            freeTimePickup: [0, [Validators.required]],
            fuelSurcharge: this.fb.control({ value: false, disabled: true }, [Validators.required]),
            isBackHaul: [false, [Validators.required]],
            isReturnBillable: [false, [Validators.required]],
            lineHaul: [VendorContractTravelCostType.PER_HOUR, [Validators.required]],
            minPrice: [0, [Validators.required]],
            payloadTypes: [null],
            referenceNumber: [null],
            truckingVendor: [null, [Validators.required]],
        });
        this.fuelContractList$ = this.fuelSurchargeService.fuelContracts$.pipe(map(function (fuelContracts) { return fuelContracts.contractsList.filter(function (fuelContract) { return fuelContract.id; }); }));
        var mileageBracket = this.fb.group({
            cost: [null, [Validators.required]],
            maxMiles: [null, [Validators.required]],
            minMiles: [null, [Validators.required]],
        });
        this.formGroup.addControl('mileageBrackets', this.fb.array([mileageBracket]));
        this.newContractTypeId = this.fb.control(null, [Validators.required]);
        this.newContractTypeForm = this.fb.control(null, [Validators.required]);
        this.newContractEdiCode = this.fb.control(null, [Validators.required]);
        this.newContractFuelMatrix = this.fb.control(null);
        this.payloadTypes = recordToArray(this.constantsService.payloadTypes).sort(function (a, b) { return a.name.localeCompare(b.name); });
        this.truckingVendors$ = combineLatest([
            this.accountService.allVendors$.pipe(map(function (vendors) { return sort(vendors, function (a, b) { return a.name.localeCompare(b.name); }); })),
            this.truckVendorSearch.valueChanges.pipe(startWith(this.truckVendorSearch.value)),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), vendors = _b[0], searchTerm = _b[1];
            if (!searchTerm || searchTerm === '') {
                return vendors;
            }
            var asLower = searchTerm.toLocaleLowerCase();
            return vendors.filter(function (vendor) { return vendor.name.toLocaleLowerCase().includes(asLower); });
        }));
        this.listenToLineHaul();
        this.contractService.contractTypes$.subscribe(function (contractTypes) {
            _this.contractTypes = contractTypes;
        });
        this.setupFormDisableAndEnableListeners();
    };
    LmoTruckingContractsCreationComponent.prototype.setupFormDisableAndEnableListeners = function () {
        var _this = this;
        this.formGroup
            .get('contractType')
            .valueChanges.pipe(distinctUntilChanged())
            .pipe(debounceTime(1000))
            .subscribe(function (contractType) {
            var fuelContract = _this.contractTypes.find(function (type) {
                return type.id === contractType;
            });
            if (!contractType || !(fuelContract && fuelContract.fuelSurchargeContract)) {
                _this.formGroup.get('fuelSurcharge').setValue(false);
                _this.formGroup.get('fuelSurcharge').disable();
                _this.toolTipDisabled = false;
            }
            else {
                _this.formGroup.get('fuelSurcharge').enable();
                _this.toolTipDisabled = true;
            }
        });
        this.formGroup
            .get('isBackHaul')
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe(function (isBackHaul) {
            _this.formGroup.get('backHaulChargePercent').setValue(0);
            if (isBackHaul) {
                _this.formGroup.get('backHaulChargePercent').enable();
                _this.backHaulToolTipDisabled = true;
            }
            else {
                _this.formGroup.get('backHaulChargePercent').disable();
                _this.backHaulToolTipDisabled = false;
            }
        });
    };
    LmoTruckingContractsCreationComponent.prototype.openNewContractTypeForm = function () {
        var _this = this;
        this.newContractTypeFormDialog = this.matDialog.open(this.newContractTypeFormModal, {
            maxWidth: '500px;',
        });
        this.newContractTypeFormDialog.afterOpened().subscribe(function () {
            _this.newContractTypeForm.reset();
            _this.newContractEdiCode.reset();
            _this.newContractTypeId.reset();
            _this.newContractFuelMatrix.reset();
        });
    };
    LmoTruckingContractsCreationComponent.prototype.openEditContractTypeForm = function () {
        var _this = this;
        this.newContractTypeFormDialog = this.matDialog.open(this.newContractTypeFormModal, {
            maxWidth: '500px;',
        });
        this.newContractTypeFormDialog.afterOpened().subscribe(function () {
            var contractTypeId = _this.formGroup.get('contractType').value;
            var contractType = _this.contractTypes.find(function (type) {
                return type.id === contractTypeId;
            });
            _this.newContractTypeForm.setValue(contractType && contractType.name);
            _this.newContractEdiCode.setValue(contractType && contractType.ediCode);
            _this.newContractTypeId.setValue(contractTypeId);
            _this.newContractFuelMatrix.setValue(contractType && contractType.fuelSurchargeContract && contractType.fuelSurchargeContract.id);
        });
    };
    LmoTruckingContractsCreationComponent.prototype.createContractType = function () {
        var _this = this;
        var contractTypeName = this.newContractTypeForm.value;
        var contractEdiCode = this.newContractEdiCode.value;
        var contractFuelId = this.newContractFuelMatrix.value;
        if (!contractFuelId) {
            this.formGroup.get('fuelSurcharge').setValue(false);
            this.formGroup.get('fuelSurcharge').disable();
        }
        else {
            this.formGroup.get('fuelSurcharge').enable();
        }
        if (this.newContractTypeId.value > 0) {
            return this.editContractType();
        }
        this.contractService.createContractType$(contractTypeName, contractEdiCode, contractFuelId).subscribe(function (contractType) {
            _this.formGroup.get('contractType').setValue(contractType.id);
            _this.newContractTypeFormDialog.close();
            _this.snackBar.open("Contract type is created", null, {
                duration: 2500,
            });
        }, function (err) {
            _this.snackBar.open('Something Went Wrong', null, {
                duration: 2500,
                panelClass: ['snackbar-error'],
            });
        });
    };
    LmoTruckingContractsCreationComponent.prototype.editContractType = function () {
        var _this = this;
        var contractTypeId = this.newContractTypeId.value;
        var contractTypeName = this.newContractTypeForm.value;
        var contractEdiCode = this.newContractEdiCode.value;
        var contractFuelId = this.newContractFuelMatrix.value;
        this.contractService.editContractType$(contractTypeId, contractTypeName, contractEdiCode, contractFuelId).subscribe(function (contractType) {
            _this.formGroup.get('contractType').setValue(contractType.id);
            _this.newContractTypeFormDialog.close();
            _this.snackBar.open("Contract type is edited", null, {
                duration: 2500,
            });
        }, function (err) {
            _this.snackBar.open('Something Went Wrong', null, {
                duration: 2500,
                panelClass: ['snackbar-error'],
            });
        });
    };
    LmoTruckingContractsCreationComponent.prototype.hasUnsavedData = function () {
        return false;
        // return this.formGroup.dirty && !this.formSubmitted;
    };
    LmoTruckingContractsCreationComponent.prototype.createTruckingContract = function () {
        var _this = this;
        this.formGroup.markAllAsTouched();
        if (this.formGroup.invalid) {
            return;
        }
        this.networkActive$$.next(true);
        var request = this.createRequest();
        this.contractService
            .createContract$(request)
            .pipe(finalize(function () { return _this.networkActive$$.next(false); }))
            .subscribe(function () {
            _this.router.navigate(['/', 'lmo', 'trucking-contracts']);
        });
    };
    LmoTruckingContractsCreationComponent.prototype.addBracket = function ($event) {
        if ($event) {
            $event.preventDefault();
        }
        var brackets = this.formGroup.get('mileageBrackets');
        var lastBracket = brackets.value.length && brackets.value[brackets.value.length - 1];
        brackets.push(this.fb.group({
            cost: [null, [Validators.required]],
            maxMiles: [null, [Validators.required]],
            minMiles: [lastBracket ? lastBracket.maxMiles + 1 : null, [Validators.required]],
        }));
    };
    LmoTruckingContractsCreationComponent.prototype.removeBracket = function (id) {
        var brackets = this.formGroup.get('mileageBrackets');
        brackets.removeAt(id);
    };
    LmoTruckingContractsCreationComponent.prototype.listenToLineHaul = function () {
        var _this = this;
        this.formGroup
            .get('lineHaul')
            .valueChanges.pipe(startWith(this.formGroup.get('lineHaul').value))
            .subscribe(function (value) {
            if (value === VendorContractTravelCostType.MILEAGE_BRACKET) {
                _this.toggleMileageBracket('enable');
                _this.toggleCostPerHour('disable');
                _this.toggleCostPerMile('disable');
                _this.toggleFileId('disable');
            }
            else if (value === VendorContractTravelCostType.PER_HOUR) {
                _this.toggleCostPerHour('enable');
                _this.toggleCostPerMile('disable');
                _this.toggleMileageBracket('disable');
                _this.toggleFileId('disable');
            }
            else if (value === VendorContractTravelCostType.PER_MILE) {
                _this.toggleCostPerMile('enable');
                _this.toggleCostPerHour('disable');
                _this.toggleMileageBracket('disable');
                _this.toggleFileId('disable');
            }
            else if (value === VendorContractTravelCostType.FIXED_POINT) {
                _this.toggleCostPerMile('disable');
                _this.toggleCostPerHour('disable');
                _this.toggleMileageBracket('disable');
                _this.toggleFileId('enable');
            }
        });
    };
    LmoTruckingContractsCreationComponent.prototype.toggleMileageBracket = function (action) {
        this.formGroup.get('mileageBrackets')[action]();
    };
    LmoTruckingContractsCreationComponent.prototype.toggleFileId = function (action) {
        this.formGroup.get('fileId')[action]();
    };
    LmoTruckingContractsCreationComponent.prototype.toggleCostPerHour = function (action) {
        this.formGroup.get('costPerHour')[action]();
    };
    LmoTruckingContractsCreationComponent.prototype.toggleCostPerMile = function (action) {
        this.formGroup.get('costPerMile')[action]();
    };
    LmoTruckingContractsCreationComponent.prototype.createRequest = function () {
        var value = this.formGroup.value;
        var request = new CreateVendorContractRequest();
        request.setName(value.contractName);
        request.setPayloadTypeIdsList(value.payloadTypes.map(function (type) { return type.id; }));
        request.setVendorId(value.truckingVendor.id);
        request.setTravelCostType(value.lineHaul);
        request.setReferenceNumber(value.referenceNumber);
        request.setIsDefault(value.defaultContract);
        request.setContractTypeId(value.contractType);
        request.setFuelSurcharge(value.fuelSurcharge);
        request.setBackHaulChargePercent(value.backHaulChargePercent);
        request.setIsBackHaulEnabled(value.isBackHaul);
        var expirationDate = new NullableTime();
        expirationDate.setValid(value.expirationDate !== null);
        expirationDate.setTime(new Date(value.expirationDate).toISOString());
        request.setExpirationTime(expirationDate);
        var effectiveDate = new NullableTime();
        var date = value.effectiveDate ? new Date(value.effectiveDate).toISOString() : new Date().toISOString();
        effectiveDate.setValid(value.effectiveDate !== null);
        effectiveDate.setTime(date);
        request.setEffectiveTime(effectiveDate);
        if (value.lineHaul === VendorContractTravelCostType.MILEAGE_BRACKET) {
            request.setDeadheadCostPerMile(value.deadheadCost);
            request.setDeadheadFreeMileage(value.deadheadFreeDistance);
            request.setDropoffFreeTime(value.freeTimeDropoff);
            request.setHourlyDetentionCost(value.detentionHourly);
            request.setMaxDetentionPerOrder(value.detentionMax);
            request.setPickupFreeTime(value.freeTimePickup);
            request.setIsReturnTripBillable(value.isReturnBillable);
            request.setMileageCostRequestsList(value.mileageBrackets.map(function (bracket) {
                var bracketRequest = new CreateMileageCostsRequest();
                bracketRequest.setCost(bracket.cost);
                bracketRequest.setLowerBoundMiles(bracket.minMiles);
                bracketRequest.setUpperBoundMiles(bracket.maxMiles);
                return bracketRequest;
            }));
        }
        else if (value.lineHaul === VendorContractTravelCostType.PER_HOUR) {
            request.setPerHourTravelCost(value.costPerHour);
            request.setMinimumCharge(value.minPrice);
            request.setIsReturnTripBillable(value.isReturnBillable);
        }
        else if (value.lineHaul === VendorContractTravelCostType.PER_MILE) {
            request.setPerMileTravelCost(value.costPerMile);
            request.setDropoffFreeTime(value.freeTimeDropoff);
            request.setHourlyDetentionCost(value.detentionHourly);
            request.setMaxDetentionPerOrder(value.detentionMax);
            request.setPickupFreeTime(value.freeTimePickup);
            request.setMinimumCharge(value.minPrice);
            request.setIsReturnTripBillable(value.isReturnBillable);
        }
        else if (value.lineHaul === VendorContractTravelCostType.FIXED_POINT) {
            request.setFixCostFileId(value.fileId);
            request.setDropoffFreeTime(value.freeTimeDropoff);
            request.setHourlyDetentionCost(value.detentionHourly);
            request.setMaxDetentionPerOrder(value.detentionMax);
            request.setPickupFreeTime(value.freeTimePickup);
            request.setMinimumCharge(value.minPrice);
        }
        return request;
    };
    LmoTruckingContractsCreationComponent.prototype.unloadNotification = function ($event) {
        if (this.hasUnsavedData()) {
            $event.returnValue = true;
        }
    };
    LmoTruckingContractsCreationComponent.prototype.trackByName = function (_index, item) {
        return item.name;
    };
    LmoTruckingContractsCreationComponent.prototype.trackByIndex = function (index) {
        return index;
    };
    LmoTruckingContractsCreationComponent.prototype.closeTab = function () {
        this.newContractTypeFormDialog.close();
        this.newContractTypeFormDialog = null;
    };
    LmoTruckingContractsCreationComponent.prototype.showDetention = function () {
        return (this.formGroup.get('lineHaul').value === VendorContractTravelCostType.MILEAGE_BRACKET ||
            this.formGroup.get('lineHaul').value === VendorContractTravelCostType.PER_MILE);
    };
    LmoTruckingContractsCreationComponent.prototype.showDeadhead = function () {
        return this.formGroup.get('lineHaul').value === VendorContractTravelCostType.MILEAGE_BRACKET;
    };
    LmoTruckingContractsCreationComponent.prototype.showMileageBracket = function () {
        return this.formGroup.get('lineHaul').value === VendorContractTravelCostType.MILEAGE_BRACKET;
    };
    LmoTruckingContractsCreationComponent.prototype.showMinPrice = function () {
        return (this.formGroup.get('lineHaul').value === VendorContractTravelCostType.PER_HOUR ||
            this.formGroup.get('lineHaul').value === VendorContractTravelCostType.PER_MILE);
    };
    LmoTruckingContractsCreationComponent.prototype.showFileUpload = function () {
        return this.formGroup.get('lineHaul').value === VendorContractTravelCostType.FIXED_POINT;
    };
    LmoTruckingContractsCreationComponent.prototype.onFileChange = function (event) {
        if (event.target.files && event.target.files.length) {
            var _a = tslib_1.__read(event.target.files, 1), file = _a[0];
            var inputSelect = document.getElementsByName('file-name');
            inputSelect[0].innerHTML = file.name;
            if (file.size > MAX_FILE_SIZE) {
                this.snackBar.open('File Size cannot be greater than 25 MB', null, {
                    duration: 2500,
                });
            }
            else {
                event.target.name === 'mileage-brackets' ? this.uploadMileageBracketsFile(file) : this.uploadP2PCostFile(file);
            }
        }
    };
    LmoTruckingContractsCreationComponent.prototype.uploadP2PCostFile = function (fileToUpload) {
        var _this = this;
        this.uploadActive$$.next(true);
        this.contractService.uploadP2PCostFile$(fileToUpload).subscribe(function (response) {
            _this.uploadActive$$.next(false);
            if (response.status) {
                _this.formGroup.get('fileId').setValue(response.fileId);
                _this.snackBar.open('File uploaded successfully', null, {
                    duration: 2000,
                });
            }
            else {
                _this.snackBar.open(response.message, null, {
                    duration: 2000,
                });
            }
        }, function (err) {
            _this.uploadActive$$.next(false);
            _this.snackBar.open('Error: ' + err.Error.Message, null, {
                duration: 20000,
            });
        });
    };
    LmoTruckingContractsCreationComponent.prototype.downloadP2PCostFile = function () {
        var _this = this;
        var fileId = this.formGroup.get('fileId').value;
        this.contractService
            .downloadP2PCostFile$(fileId)
            .pipe(take(1))
            .subscribe(function (response) {
            window.location.assign(response.toObject().url);
        }, function (err) {
            _this.snackBar.open('Error:' + err.toObject().url, null, {
                duration: 10000,
            });
        });
    };
    LmoTruckingContractsCreationComponent.prototype.uploadMileageBracketsFile = function (fileToUpload) {
        var _this = this;
        this.uploadActive$$.next(true);
        this.contractService.uploadMileageBracketsFile$(fileToUpload).subscribe(function (response) {
            _this.uploadActive$$.next(false);
            if (response.length > 0) {
                _this.mileageBrackets.controls = [];
                response.forEach(function (item) {
                    var mileageBracket = _this.fb.group({
                        cost: [item.cost, [Validators.required]],
                        maxMiles: [item.upperBoundMiles, [Validators.required]],
                        minMiles: [item.lowerBoundMiles, [Validators.required]],
                    });
                    _this.mileageBrackets.push(mileageBracket);
                });
                _this.snackBar.open('File uploaded successfully', null, {
                    duration: 2000,
                });
            }
            else {
                _this.snackBar.open(response.message, null, {
                    duration: 20000,
                });
            }
        }, function (err) {
            _this.uploadActive$$.next(false);
            _this.snackBar.open('Error: ' + err.error, null, {
                duration: 10000,
            });
        });
    };
    LmoTruckingContractsCreationComponent.prototype.clearExpirationDate = function () {
        this.formGroup.get('expirationDate').setValue(null);
    };
    LmoTruckingContractsCreationComponent.prototype.clearEffectiveDate = function () {
        this.formGroup.get('effectiveDate').setValue(null);
    };
    return LmoTruckingContractsCreationComponent;
}());
export { LmoTruckingContractsCreationComponent };
