import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-notification-prompt',
  styleUrls: ['./notification-prompt.component.scss'],
  templateUrl: './notification-prompt.component.html',
})
export class NotificationPromptComponent implements OnInit {
  @Input() public headerText = '';
  @Input() public detailsText = '';
  @Input() public actionText = '';
  @Output() public selected = new EventEmitter();

  constructor(@Optional() private dialogRef: MatDialogRef<NotificationPromptComponent>) {}

  public ngOnInit() {}

  public select(selected: boolean) {
    this.selected.next(selected);
    if (this.dialogRef) {
      this.dialogRef.close(selected);
    }
  }
}
