import { TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '~environments/environment';
var OnlyEnvDirective = /** @class */ (function () {
    function OnlyEnvDirective(templateRef, viewContainer) {
        this.templateRef = templateRef;
        this.viewContainer = viewContainer;
        this.hasView = false;
    }
    Object.defineProperty(OnlyEnvDirective.prototype, "ctOnlyEnv", {
        set: function (environments) {
            var isEnv = environments.includes(environment.environment);
            if (isEnv && !this.hasView) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.hasView = true;
            }
            else if (!isEnv && this.hasView) {
                this.viewContainer.clear();
                this.hasView = false;
            }
        },
        enumerable: true,
        configurable: true
    });
    return OnlyEnvDirective;
}());
export { OnlyEnvDirective };
