import { OnInit, TemplateRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '~environments/environment';
var LoginPageComponent = /** @class */ (function () {
    function LoginPageComponent(fb, authService, 
    // private configValues: ConfigValuesService,
    snackBar, matDialog) {
        var _this = this;
        this.fb = fb;
        this.authService = authService;
        this.snackBar = snackBar;
        this.matDialog = matDialog;
        this.activeNetworkRequest$ = new BehaviorSubject(false);
        this.ssoEmailForm = new FormControl(null, [Validators.required, Validators.email]);
        this.running = false;
        this.authService.idToken$.pipe(filter(function (token) { return !!token; })).subscribe(function (token) {
            if (token) {
                _this.authService.redirectIfLoggedIn();
            }
        });
        // this.ssoLoginUrl$ = this.configValues.configs$.pipe(map((configs) => configs.sso_login_url));
    }
    Object.defineProperty(LoginPageComponent.prototype, "formValue", {
        get: function () {
            return this.loginForm.value;
        },
        enumerable: true,
        configurable: true
    });
    LoginPageComponent.prototype.ngOnInit = function () {
        this.loginForm = this.fb.group({
            email: [null, [Validators.required, Validators.email]],
            password: [null, [Validators.required]],
        });
    };
    LoginPageComponent.prototype.login = function () {
        if (this.loginForm.invalid) {
            return;
        }
        var formValue = this.formValue;
        this.authService.login(formValue.email, formValue.password);
    };
    LoginPageComponent.prototype.isDev = function () {
        return environment.api === 'https://dev.prime.shaleapps.com';
    };
    LoginPageComponent.prototype.isStage = function () {
        return environment.api === 'https://stage.prime.shaleapps.com';
    };
    LoginPageComponent.prototype.isProd = function () {
        return environment.api === 'https://prime.shaleapps.com';
    };
    LoginPageComponent.prototype.useDev = function () {
        this.useEnv('https://dev.prime.shaleapps.com');
    };
    LoginPageComponent.prototype.useStage = function () {
        this.useEnv('https://stage.prime.shaleapps.com');
    };
    LoginPageComponent.prototype.useProd = function () {
        this.useEnv('https://prime.shaleapps.com');
    };
    LoginPageComponent.prototype.useEnv = function (api) {
        environment.api = api;
    };
    LoginPageComponent.prototype.openSSOEmail = function () {
        var email = this.authService.getSSOEmailFromCache();
        this.ssoEmailForm.setValue(email);
        this.ssoEmailFormDialog = this.matDialog.open(this.ssoEmailFormModal, {
            width: '250px;',
        });
    };
    LoginPageComponent.prototype.ssoLogin = function () {
        var _this = this;
        this.running = true;
        this.authService.ssoURL$(this.ssoEmailForm.value).subscribe(function (url) {
            _this.running = false;
            _this.cacheSSOEmail();
            window.open(url, '_self');
            _this.closeTab();
        }, function (error) {
            _this.running = false;
            _this.snackBar.open(error.error, null, {
                duration: 5000,
                panelClass: ['snackbar-error'],
            });
        });
    };
    LoginPageComponent.prototype.cacheSSOEmail = function () {
        this.authService.storeSSOEmailInCache({
            email: this.ssoEmailForm.value,
        });
    };
    LoginPageComponent.prototype.closeTab = function () {
        this.ssoEmailFormDialog.close();
        this.ssoEmailFormDialog = null;
    };
    return LoginPageComponent;
}());
export { LoginPageComponent };
