// package: cbv1
// file: proto/cybertron/notification/notification_api.proto

var proto_cybertron_notification_notification_api_pb = require("../../../proto/cybertron/notification/notification_api_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var NotificationAPI = (function () {
  function NotificationAPI() {}
  NotificationAPI.serviceName = "cbv1.NotificationAPI";
  return NotificationAPI;
}());

NotificationAPI.SetNewUserPreferences = {
  methodName: "SetNewUserPreferences",
  service: NotificationAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_notification_notification_api_pb.SetNewUserPreferencesRequests,
  responseType: proto_cybertron_notification_notification_api_pb.SetNewUserPreferencesResponse
};

NotificationAPI.UpdatePreference = {
  methodName: "UpdatePreference",
  service: NotificationAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_notification_notification_api_pb.UpdatePreferenceRequests,
  responseType: proto_cybertron_notification_notification_api_pb.NotificationUserPreferencesResponse
};

NotificationAPI.ListPreferences = {
  methodName: "ListPreferences",
  service: NotificationAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_notification_notification_api_pb.NotificationUserPreferencesRequest,
  responseType: proto_cybertron_notification_notification_api_pb.NotificationUserPreferencesResponse
};

exports.NotificationAPI = NotificationAPI;

function NotificationAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

NotificationAPIClient.prototype.setNewUserPreferences = function setNewUserPreferences(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NotificationAPI.SetNewUserPreferences, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NotificationAPIClient.prototype.updatePreference = function updatePreference(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NotificationAPI.UpdatePreference, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NotificationAPIClient.prototype.listPreferences = function listPreferences(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NotificationAPI.ListPreferences, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.NotificationAPIClient = NotificationAPIClient;

