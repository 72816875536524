import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SiteAvailableDuration } from '~proto/site/site_pb';

@Component({
  selector: 'ct-select-business-hours',
  styleUrls: ['./select-business-hours.component.scss'],
  templateUrl: './select-business-hours.component.html',
})
export class SelectBusinessHoursComponent implements OnInit {
  private businessHours: SiteAvailableDuration[];
  public weekForm: FormGroup;
  public allowedTimes = [
    '12:00 AM',
    '12:30 AM',
    '1:00 AM',
    '1:30 AM',
    '2:00 AM',
    '2:30 AM',
    '3:00 AM',
    '3:30 AM',
    '4:00 AM',
    '4:30 AM',
    '5:00 AM',
    '5:30 AM',
    '6:00 AM',
    '6:30 AM',
    '7:00 AM',
    '7:30 AM',
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM',
  ];
  public durationHours = [];
  public weekMap = {
    0: 'MONDAY',
    1: 'TUESDAY',
    2: 'WEDNESDAY',
    3: 'THURSDAY',
    4: 'FRIDAY',
    5: 'SATURDAY',
    6: 'SUNDAY',
  };

  public get daysArray() {
    return this.weekForm.get('days') as FormArray;
  }

  constructor(
    @Optional() private matDialogRef: MatDialogRef<SelectBusinessHoursComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SiteAvailableDuration[],
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
  ) {
    this.businessHours = data;
    for (let i = 0; i < 48; i++) {
      this.durationHours.push({
        time: this.allowedTimes[i],
        weight: i,
      });
    }
  }

  public ngOnInit() {
    this.weekForm = this.fb.group({
      days: this.fb.array(this.buildForm()),
    });
  }

  public trackDurationHours(_index: number, dh: { time: string; weight: number }): any {
    return dh.time;
  }

  public trackByIndex(index: number, control: FormControl): any {
    return index;
  }

  private buildForm() {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const existingDay = this.checkForExistingDay(i);
      days.push(
        this.fb.group({
          closingTime: [existingDay ? this.setTime(existingDay.closingTime) : null, []],
          day: [!!existingDay, []],
          openingTime: [existingDay ? this.setTime(existingDay.openingTime) : null, []],
        }),
      );
    }
    return days;
  }

  private setTime(time) {
    const index = this.allowedTimes.findIndex((allowedTime) => allowedTime === time);
    return {
      time: time,
      weight: index,
    };
  }

  private checkForExistingDay(index: number): SiteAvailableDuration.AsObject {
    if (this.businessHours.length) {
      const existingDay = this.businessHours.find((businessDay) => businessDay.getDay() === index + 1); // Proto Starts from Invalid as 0, Monday as 1 and so on...
      return (existingDay && existingDay.toObject()) || null;
    }
    return null;
  }

  public cancel() {
    this.matDialogRef.close(null);
  }

  public checkWeekValues() {
    let invalidReason = '';
    for (let i = 0; i < this.daysArray.controls.length; i++) {
      if (this.daysArray.controls[i].value.day) {
        if (!this.daysArray.controls[i].value.openingTime || !this.daysArray.controls[i].value.closingTime) {
          invalidReason = 'Please fill in all the fields';
          break;
        } else if (
          this.daysArray.controls[i].value.openingTime.weight >= this.daysArray.controls[i].value.closingTime.weight
        ) {
          invalidReason = 'Opening time cannot be greater than closing time';
          break;
        }
      }
    }
    return invalidReason;
  }

  public compareByTime<T extends { time: string; weight: number }>(a: T, b: T): boolean {
    return a.time === b.time;
  }

  public continue() {
    if (this.checkWeekValues() === '') {
      const openDays = [];
      this.daysArray.value.forEach((dayValue, i) => {
        if (dayValue && dayValue.day) {
          const availableDuration = new SiteAvailableDuration();
          availableDuration.setDay(i + 1);
          availableDuration.setOpeningTime(dayValue.openingTime.time);
          availableDuration.setClosingTime(dayValue.closingTime.time);
          openDays.push(availableDuration);
        }
      });
      this.matDialogRef.close(openDays);
    } else {
      this.snackbar.open(this.checkWeekValues(), null, {
        duration: 2500,
        panelClass: ['snackbar-error'],
      });
    }
  }
}
