import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { SmartDropdownComponent } from '~common/smart-dropdown/smart-dropdown.component';
import { Account } from '~proto/account/account_pb';
import { Payload } from '~proto/payload/payload_pb';
import { IdName } from '~proto/types/types_pb';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { ContractsService } from '../../services/contracts.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-create-order-trucking-vendor-selector',
  styleUrls: ['./create-order-trucking-vendor-selector.component.scss'],
  templateUrl: './create-order-trucking-vendor-selector.component.html',
})
export class CreateOrderTruckingVendorSelectorComponent implements OnInit {
  private topResult: IdName.AsObject;
  private _payloads$ = new BehaviorSubject<Payload.AsObject[]>([]);
  private _contractType$ = new BehaviorSubject<number>(-1);
  @Output() public selected = new EventEmitter<{ truckingVendor: IdName.AsObject }>();
  @ViewChild('smartDropdown', { static: false }) private smartDropdown: SmartDropdownComponent;
  @Input() public truckingVendor: Account.AsObject;
  @Input() public invalid = false;
  @Input() public disabled = false;

  @Input()
  public set payloads(payloads: Payload.AsObject[]) {
    this._payloads$.next(payloads);
  }

  @Input()
  public set contractType(contractType: number) {
    this._contractType$.next(contractType);
  }

  public textChanges$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public truckingVendors$: Observable<IdName.AsObject[]>;

  constructor(private contractService: ContractsService) {}

  public ngOnInit() {
    const uniqueVendorsByPayload$ = combineLatest([
      this.contractService.nonExpiredContracts$,
      this._payloads$.pipe(startWith(this._payloads$.value)),
      this._contractType$,
    ]).pipe(
      map(([contracts, payloads, contractType]) => {
        if (!payloads || !payloads.length) {
          return [];
        }
        const unarchived = contracts.filter((contract) => !contract.archived);
        const uniqueVendors: Record<string, IdName.AsObject> = unarchived.reduce((acc, contract) => {
          const payloadIDsSupportedByContract = idArrayToRecord(contract.payloadtypesList);
          if (
            (contractType <= 0 || (contract.contractType && contract.contractType.id === contractType)) &&
            payloads.every((payload) => payloadIDsSupportedByContract[payload.type.id])
          ) {
            return { ...acc, [contract.vendor.id]: contract.vendor };
          }
          return acc;
        }, {});
        return Object.values(uniqueVendors).sort((a, b) =>
          a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()),
        );
      }),
    );
    this.truckingVendors$ = combineLatest([
      uniqueVendorsByPayload$,
      this.textChanges$$.pipe(
        debounceTime(100),
        startWith(''),
      ),
    ]).pipe(
      map(([contracts, text]) => {
        if (!contracts) {
          return [];
        }
        if (!text || text === '') {
          this.topResult = contracts.length ? contracts[0] : null;
          return contracts;
        }
        const asLowercase = text.toLocaleLowerCase();
        const filtered = contracts.filter((account) => account.name.toLocaleLowerCase().includes(asLowercase));
        this.topResult = filtered.length ? filtered[0] : null;
        return filtered;
      }),
    );
  }

  public inputTextChange(event: string) {
    this.textChanges$$.next(event.trim());
  }

  public selectVendor(truckingVendor: IdName.AsObject) {
    this.selected.emit({ truckingVendor });
    this.smartDropdown.close();
  }

  public enterKeyPressed() {
    if (this.topResult) {
      this.selectVendor(this.topResult);
    }
  }
}
