import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UpdatePreferenceRequest, UpdatePreferenceRequests } from '~proto/notification/notification_api_pb';
import { NotificationUserPreference } from '~proto/notification/notification_pb';
import { NotificationGroupWithPrefs, NotificationsService } from '~services/notifications.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sa-notification-preferences',
  styleUrls: ['./notification-preferences.component.scss'],
  templateUrl: './notification-preferences.component.html',
})
export class NotificationPreferencesComponent implements OnInit {
  public groups$: Observable<NotificationGroupWithPrefs[]>;
  constructor(public notificationService: NotificationsService) {}

  public ngOnInit() {
    this.groups$ = this.notificationService.groups$;
  }

  public notificationTrackBy(_index, notification: NotificationUserPreference.AsObject) {
    return notification.id;
  }

  public updatePreference(pref: NotificationUserPreference.AsObject) {
    const request = new UpdatePreferenceRequests();
    const update = new UpdatePreferenceRequest();
    update.setId(pref.id);
    update.setUserEmailEnabled(!pref.userEmailEnabled);
    request.addUpdates(update);
    this.notificationService.updatePreferences$(request).subscribe();
  }
}
