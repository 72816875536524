import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogStreamerRequest, DialogStreamerResponse } from '~proto/googledialog/dialog_pb';
import { DialogAPI } from '~proto/googledialog/dialog_pb_service';
import { GrpcService } from '~services/grpc.service';

export interface Message extends DialogStreamerResponse.AsObject {
  sender: 'bot' | 'client';
}

@Injectable({
  providedIn: 'root',
})
export class ChatbotService {
  private onRight$$ = new BehaviorSubject<boolean>(true);
  private chat$$ = new BehaviorSubject<Message[]>([]);

  public get chat$(): Observable<Message[]> {
    return this.chat$$.asObservable();
  }

  public get onRight$(): Observable<boolean> {
    return this.onRight$$.asObservable();
  }

  constructor(private grpcService: GrpcService) {}

  public sendMessage(message: string) {
    const request = new DialogStreamerRequest();
    this.chat$$.next([
      ...this.chat$$.value,
      { localUrl: '', localUrlText: '', message, statusUnix: '', orderStatus: '', sender: 'client' },
    ]);
    request.setMessage(message);
    request.setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    return this.grpcService
      .invoke$(DialogAPI.clientMessageNoStreaming, request)
      .pipe(map((response: DialogStreamerResponse) => response.toObject()))
      .subscribe((responseMessage) => {
        this.chat$$.next([...this.chat$$.value, { ...responseMessage, sender: 'bot' }]);
      });
  }

  public onRight(isOnRight: boolean) {
    this.onRight$$.next(isOnRight);
  }
}
