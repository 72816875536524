/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_cybertron_account_account_pb = require('../../../proto/cybertron/account/account_pb.js');
var proto_cybertron_user_user_pb = require('../../../proto/cybertron/user/user_pb.js');
var proto_cybertron_payload_payload_pb = require('../../../proto/cybertron/payload/payload_pb.js');
var proto_cybertron_types_types_pb = require('../../../proto/cybertron/types/types_pb.js');
var proto_cybertron_drivercertification_driverCertification_pb = require('../../../proto/cybertron/drivercertification/driverCertification_pb.js');
var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
var proto_geo_geo_pb = require('../../../proto/geo/geo_pb.js');
goog.exportSymbol('proto.cbv1.Availability', null, global);
goog.exportSymbol('proto.cbv1.District', null, global);
goog.exportSymbol('proto.cbv1.LMOCalendarStats', null, global);
goog.exportSymbol('proto.cbv1.LMOSiteSummary', null, global);
goog.exportSymbol('proto.cbv1.Site', null, global);
goog.exportSymbol('proto.cbv1.SiteAvailableDuration', null, global);
goog.exportSymbol('proto.cbv1.SiteEvent', null, global);
goog.exportSymbol('proto.cbv1.SiteShort', null, global);
goog.exportSymbol('proto.cbv1.Stock', null, global);
goog.exportSymbol('proto.cbv1.VendorSite', null, global);
goog.exportSymbol('proto.cbv1.VendorSiteSummary', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.Site = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.Site.repeatedFields_, null);
};
goog.inherits(proto.cbv1.Site, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.Site.displayName = 'proto.cbv1.Site';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.Site.repeatedFields_ = [12,24,25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.Site.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.Site.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.Site} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Site.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    siteType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    geofence: (f = msg.getGeofence()) && proto_geo_geo_pb.Circle.toObject(includeInstance, f),
    directions: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    availability: (f = msg.getAvailability()) && proto.cbv1.Availability.toObject(includeInstance, f),
    pendingCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    inProgressCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    completedCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    needsAtnCount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    stockReferencesList: jspb.Message.toObjectList(msg.getStockReferencesList(),
    proto.cbv1.Stock.toObject, includeInstance),
    account: (f = msg.getAccount()) && proto_cybertron_account_account_pb.Account.toObject(includeInstance, f),
    favorited: jspb.Message.getFieldWithDefault(msg, 14, false),
    archived: jspb.Message.getFieldWithDefault(msg, 15, false),
    createdUnix: jspb.Message.getFieldWithDefault(msg, 16, 0),
    updatedUnix: jspb.Message.getFieldWithDefault(msg, 17, 0),
    upForGrabsCount: jspb.Message.getFieldWithDefault(msg, 18, 0),
    haulisBackupCount: jspb.Message.getFieldWithDefault(msg, 19, 0),
    inboundCount: jspb.Message.getFieldWithDefault(msg, 20, 0),
    outboundCount: jspb.Message.getFieldWithDefault(msg, 21, 0),
    onLocationCount: jspb.Message.getFieldWithDefault(msg, 22, 0),
    address: jspb.Message.getFieldWithDefault(msg, 23, ""),
    availabledurationsList: jspb.Message.toObjectList(msg.getAvailabledurationsList(),
    proto.cbv1.SiteAvailableDuration.toObject, includeInstance),
    driverCertificationsList: jspb.Message.toObjectList(msg.getDriverCertificationsList(),
    proto_cybertron_drivercertification_driverCertification_pb.DriverCertification.toObject, includeInstance),
    district: (f = msg.getDistrict()) && proto.cbv1.District.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.Site}
 */
proto.cbv1.Site.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.Site;
  return proto.cbv1.Site.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.Site} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.Site}
 */
proto.cbv1.Site.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteType(value);
      break;
    case 4:
      var value = new proto_geo_geo_pb.Circle;
      reader.readMessage(value,proto_geo_geo_pb.Circle.deserializeBinaryFromReader);
      msg.setGeofence(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirections(value);
      break;
    case 6:
      var value = /** @type {!proto.cbv1.SiteStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = new proto.cbv1.Availability;
      reader.readMessage(value,proto.cbv1.Availability.deserializeBinaryFromReader);
      msg.setAvailability(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPendingCount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInProgressCount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompletedCount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNeedsAtnCount(value);
      break;
    case 12:
      var value = new proto.cbv1.Stock;
      reader.readMessage(value,proto.cbv1.Stock.deserializeBinaryFromReader);
      msg.addStockReferences(value);
      break;
    case 13:
      var value = new proto_cybertron_account_account_pb.Account;
      reader.readMessage(value,proto_cybertron_account_account_pb.Account.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorited(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedUnix(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdatedUnix(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpForGrabsCount(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHaulisBackupCount(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInboundCount(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOutboundCount(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOnLocationCount(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 24:
      var value = new proto.cbv1.SiteAvailableDuration;
      reader.readMessage(value,proto.cbv1.SiteAvailableDuration.deserializeBinaryFromReader);
      msg.addAvailabledurations(value);
      break;
    case 25:
      var value = new proto_cybertron_drivercertification_driverCertification_pb.DriverCertification;
      reader.readMessage(value,proto_cybertron_drivercertification_driverCertification_pb.DriverCertification.deserializeBinaryFromReader);
      msg.addDriverCertifications(value);
      break;
    case 26:
      var value = new proto.cbv1.District;
      reader.readMessage(value,proto.cbv1.District.deserializeBinaryFromReader);
      msg.setDistrict(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.Site.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.Site.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.Site} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Site.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSiteType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGeofence();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_geo_geo_pb.Circle.serializeBinaryToWriter
    );
  }
  f = message.getDirections();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAvailability();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.cbv1.Availability.serializeBinaryToWriter
    );
  }
  f = message.getPendingCount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getInProgressCount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getCompletedCount();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getNeedsAtnCount();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getStockReferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.cbv1.Stock.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_cybertron_account_account_pb.Account.serializeBinaryToWriter
    );
  }
  f = message.getFavorited();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getUpdatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getUpForGrabsCount();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getHaulisBackupCount();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getInboundCount();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getOutboundCount();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
  f = message.getOnLocationCount();
  if (f !== 0) {
    writer.writeInt64(
      22,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getAvailabledurationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      24,
      f,
      proto.cbv1.SiteAvailableDuration.serializeBinaryToWriter
    );
  }
  f = message.getDriverCertificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto_cybertron_drivercertification_driverCertification_pb.DriverCertification.serializeBinaryToWriter
    );
  }
  f = message.getDistrict();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.cbv1.District.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.Site.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.Site.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.Site.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.Site.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string site_type = 3;
 * @return {string}
 */
proto.cbv1.Site.prototype.getSiteType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.Site.prototype.setSiteType = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Circle geofence = 4;
 * @return {?proto.cbv1.Circle}
 */
proto.cbv1.Site.prototype.getGeofence = function() {
  return /** @type{?proto.cbv1.Circle} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Circle, 4));
};


/** @param {?proto.cbv1.Circle|undefined} value */
proto.cbv1.Site.prototype.setGeofence = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.cbv1.Site.prototype.clearGeofence = function() {
  this.setGeofence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Site.prototype.hasGeofence = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string directions = 5;
 * @return {string}
 */
proto.cbv1.Site.prototype.getDirections = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.Site.prototype.setDirections = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional SiteStatus status = 6;
 * @return {!proto.cbv1.SiteStatus}
 */
proto.cbv1.Site.prototype.getStatus = function() {
  return /** @type {!proto.cbv1.SiteStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.cbv1.SiteStatus} value */
proto.cbv1.Site.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional Availability availability = 7;
 * @return {?proto.cbv1.Availability}
 */
proto.cbv1.Site.prototype.getAvailability = function() {
  return /** @type{?proto.cbv1.Availability} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.Availability, 7));
};


/** @param {?proto.cbv1.Availability|undefined} value */
proto.cbv1.Site.prototype.setAvailability = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


proto.cbv1.Site.prototype.clearAvailability = function() {
  this.setAvailability(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Site.prototype.hasAvailability = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 pending_count = 8;
 * @return {number}
 */
proto.cbv1.Site.prototype.getPendingCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cbv1.Site.prototype.setPendingCount = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 in_progress_count = 9;
 * @return {number}
 */
proto.cbv1.Site.prototype.getInProgressCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cbv1.Site.prototype.setInProgressCount = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 completed_count = 10;
 * @return {number}
 */
proto.cbv1.Site.prototype.getCompletedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cbv1.Site.prototype.setCompletedCount = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 needs_atn_count = 11;
 * @return {number}
 */
proto.cbv1.Site.prototype.getNeedsAtnCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.cbv1.Site.prototype.setNeedsAtnCount = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated Stock stock_references = 12;
 * @return {!Array<!proto.cbv1.Stock>}
 */
proto.cbv1.Site.prototype.getStockReferencesList = function() {
  return /** @type{!Array<!proto.cbv1.Stock>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.Stock, 12));
};


/** @param {!Array<!proto.cbv1.Stock>} value */
proto.cbv1.Site.prototype.setStockReferencesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.cbv1.Stock=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.Stock}
 */
proto.cbv1.Site.prototype.addStockReferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.cbv1.Stock, opt_index);
};


proto.cbv1.Site.prototype.clearStockReferencesList = function() {
  this.setStockReferencesList([]);
};


/**
 * optional Account account = 13;
 * @return {?proto.cbv1.Account}
 */
proto.cbv1.Site.prototype.getAccount = function() {
  return /** @type{?proto.cbv1.Account} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_account_account_pb.Account, 13));
};


/** @param {?proto.cbv1.Account|undefined} value */
proto.cbv1.Site.prototype.setAccount = function(value) {
  jspb.Message.setWrapperField(this, 13, value);
};


proto.cbv1.Site.prototype.clearAccount = function() {
  this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Site.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool favorited = 14;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.Site.prototype.getFavorited = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 14, false));
};


/** @param {boolean} value */
proto.cbv1.Site.prototype.setFavorited = function(value) {
  jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool archived = 15;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.Site.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 15, false));
};


/** @param {boolean} value */
proto.cbv1.Site.prototype.setArchived = function(value) {
  jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional int32 created_unix = 16;
 * @return {number}
 */
proto.cbv1.Site.prototype.getCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.cbv1.Site.prototype.setCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 updated_unix = 17;
 * @return {number}
 */
proto.cbv1.Site.prototype.getUpdatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {number} value */
proto.cbv1.Site.prototype.setUpdatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int64 up_for_grabs_count = 18;
 * @return {number}
 */
proto.cbv1.Site.prototype.getUpForGrabsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/** @param {number} value */
proto.cbv1.Site.prototype.setUpForGrabsCount = function(value) {
  jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 haulis_backup_count = 19;
 * @return {number}
 */
proto.cbv1.Site.prototype.getHaulisBackupCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/** @param {number} value */
proto.cbv1.Site.prototype.setHaulisBackupCount = function(value) {
  jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 inbound_count = 20;
 * @return {number}
 */
proto.cbv1.Site.prototype.getInboundCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/** @param {number} value */
proto.cbv1.Site.prototype.setInboundCount = function(value) {
  jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int64 outbound_count = 21;
 * @return {number}
 */
proto.cbv1.Site.prototype.getOutboundCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/** @param {number} value */
proto.cbv1.Site.prototype.setOutboundCount = function(value) {
  jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int64 on_location_count = 22;
 * @return {number}
 */
proto.cbv1.Site.prototype.getOnLocationCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/** @param {number} value */
proto.cbv1.Site.prototype.setOnLocationCount = function(value) {
  jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional string address = 23;
 * @return {string}
 */
proto.cbv1.Site.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/** @param {string} value */
proto.cbv1.Site.prototype.setAddress = function(value) {
  jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * repeated SiteAvailableDuration AvailableDurations = 24;
 * @return {!Array<!proto.cbv1.SiteAvailableDuration>}
 */
proto.cbv1.Site.prototype.getAvailabledurationsList = function() {
  return /** @type{!Array<!proto.cbv1.SiteAvailableDuration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.SiteAvailableDuration, 24));
};


/** @param {!Array<!proto.cbv1.SiteAvailableDuration>} value */
proto.cbv1.Site.prototype.setAvailabledurationsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 24, value);
};


/**
 * @param {!proto.cbv1.SiteAvailableDuration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.SiteAvailableDuration}
 */
proto.cbv1.Site.prototype.addAvailabledurations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, proto.cbv1.SiteAvailableDuration, opt_index);
};


proto.cbv1.Site.prototype.clearAvailabledurationsList = function() {
  this.setAvailabledurationsList([]);
};


/**
 * repeated DriverCertification driver_certifications = 25;
 * @return {!Array<!proto.cbv1.DriverCertification>}
 */
proto.cbv1.Site.prototype.getDriverCertificationsList = function() {
  return /** @type{!Array<!proto.cbv1.DriverCertification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_drivercertification_driverCertification_pb.DriverCertification, 25));
};


/** @param {!Array<!proto.cbv1.DriverCertification>} value */
proto.cbv1.Site.prototype.setDriverCertificationsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.cbv1.DriverCertification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.DriverCertification}
 */
proto.cbv1.Site.prototype.addDriverCertifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.cbv1.DriverCertification, opt_index);
};


proto.cbv1.Site.prototype.clearDriverCertificationsList = function() {
  this.setDriverCertificationsList([]);
};


/**
 * optional District district = 26;
 * @return {?proto.cbv1.District}
 */
proto.cbv1.Site.prototype.getDistrict = function() {
  return /** @type{?proto.cbv1.District} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.District, 26));
};


/** @param {?proto.cbv1.District|undefined} value */
proto.cbv1.Site.prototype.setDistrict = function(value) {
  jspb.Message.setWrapperField(this, 26, value);
};


proto.cbv1.Site.prototype.clearDistrict = function() {
  this.setDistrict(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Site.prototype.hasDistrict = function() {
  return jspb.Message.getField(this, 26) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SiteAvailableDuration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.SiteAvailableDuration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SiteAvailableDuration.displayName = 'proto.cbv1.SiteAvailableDuration';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SiteAvailableDuration.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SiteAvailableDuration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SiteAvailableDuration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SiteAvailableDuration.toObject = function(includeInstance, msg) {
  var f, obj = {
    day: jspb.Message.getFieldWithDefault(msg, 1, 0),
    openingTime: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closingTime: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SiteAvailableDuration}
 */
proto.cbv1.SiteAvailableDuration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SiteAvailableDuration;
  return proto.cbv1.SiteAvailableDuration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SiteAvailableDuration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SiteAvailableDuration}
 */
proto.cbv1.SiteAvailableDuration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cbv1.WeekDay} */ (reader.readEnum());
      msg.setDay(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpeningTime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosingTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SiteAvailableDuration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SiteAvailableDuration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SiteAvailableDuration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SiteAvailableDuration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDay();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOpeningTime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClosingTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional WeekDay day = 1;
 * @return {!proto.cbv1.WeekDay}
 */
proto.cbv1.SiteAvailableDuration.prototype.getDay = function() {
  return /** @type {!proto.cbv1.WeekDay} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.cbv1.WeekDay} value */
proto.cbv1.SiteAvailableDuration.prototype.setDay = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string opening_time = 2;
 * @return {string}
 */
proto.cbv1.SiteAvailableDuration.prototype.getOpeningTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.SiteAvailableDuration.prototype.setOpeningTime = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closing_time = 3;
 * @return {string}
 */
proto.cbv1.SiteAvailableDuration.prototype.getClosingTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.SiteAvailableDuration.prototype.setClosingTime = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOSiteSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.LMOSiteSummary.repeatedFields_, null);
};
goog.inherits(proto.cbv1.LMOSiteSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOSiteSummary.displayName = 'proto.cbv1.LMOSiteSummary';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.LMOSiteSummary.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOSiteSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOSiteSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOSiteSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSiteSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pendingCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    inboundCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    outboundCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    favorited: jspb.Message.getFieldWithDefault(msg, 6, false),
    stockReferencesList: jspb.Message.toObjectList(msg.getStockReferencesList(),
    proto.cbv1.Stock.toObject, includeInstance),
    availability: (f = msg.getAvailability()) && proto.cbv1.Availability.toObject(includeInstance, f),
    needsAttentionCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    siteStatus: jspb.Message.getFieldWithDefault(msg, 10, 0),
    inProgressCount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    geofence: (f = msg.getGeofence()) && proto_geo_geo_pb.Circle.toObject(includeInstance, f),
    siteType: jspb.Message.getFieldWithDefault(msg, 13, ""),
    direction: jspb.Message.getFieldWithDefault(msg, 14, ""),
    upForGrabsCount: jspb.Message.getFieldWithDefault(msg, 15, 0),
    haulisBackupCount: jspb.Message.getFieldWithDefault(msg, 16, 0),
    onSiteCount: jspb.Message.getFieldWithDefault(msg, 17, 0),
    alarmCount: jspb.Message.getFieldWithDefault(msg, 18, 0),
    currentlyActive: jspb.Message.getFieldWithDefault(msg, 19, false),
    district: (f = msg.getDistrict()) && proto.cbv1.District.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOSiteSummary}
 */
proto.cbv1.LMOSiteSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOSiteSummary;
  return proto.cbv1.LMOSiteSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOSiteSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOSiteSummary}
 */
proto.cbv1.LMOSiteSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPendingCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInboundCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutboundCount(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorited(value);
      break;
    case 7:
      var value = new proto.cbv1.Stock;
      reader.readMessage(value,proto.cbv1.Stock.deserializeBinaryFromReader);
      msg.addStockReferences(value);
      break;
    case 8:
      var value = new proto.cbv1.Availability;
      reader.readMessage(value,proto.cbv1.Availability.deserializeBinaryFromReader);
      msg.setAvailability(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNeedsAttentionCount(value);
      break;
    case 10:
      var value = /** @type {!proto.cbv1.SiteStatus} */ (reader.readEnum());
      msg.setSiteStatus(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInProgressCount(value);
      break;
    case 12:
      var value = new proto_geo_geo_pb.Circle;
      reader.readMessage(value,proto_geo_geo_pb.Circle.deserializeBinaryFromReader);
      msg.setGeofence(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirection(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpForGrabsCount(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHaulisBackupCount(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOnSiteCount(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAlarmCount(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCurrentlyActive(value);
      break;
    case 20:
      var value = new proto.cbv1.District;
      reader.readMessage(value,proto.cbv1.District.deserializeBinaryFromReader);
      msg.setDistrict(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOSiteSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOSiteSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOSiteSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOSiteSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPendingCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getInboundCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOutboundCount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getFavorited();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getStockReferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.cbv1.Stock.serializeBinaryToWriter
    );
  }
  f = message.getAvailability();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.cbv1.Availability.serializeBinaryToWriter
    );
  }
  f = message.getNeedsAttentionCount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getSiteStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getInProgressCount();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getGeofence();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_geo_geo_pb.Circle.serializeBinaryToWriter
    );
  }
  f = message.getSiteType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDirection();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUpForGrabsCount();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getHaulisBackupCount();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getOnSiteCount();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getAlarmCount();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getCurrentlyActive();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getDistrict();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.cbv1.District.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.LMOSiteSummary.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.LMOSiteSummary.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.LMOSiteSummary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.LMOSiteSummary.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 pending_count = 3;
 * @return {number}
 */
proto.cbv1.LMOSiteSummary.prototype.getPendingCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.LMOSiteSummary.prototype.setPendingCount = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 inbound_count = 4;
 * @return {number}
 */
proto.cbv1.LMOSiteSummary.prototype.getInboundCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.LMOSiteSummary.prototype.setInboundCount = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 outbound_count = 5;
 * @return {number}
 */
proto.cbv1.LMOSiteSummary.prototype.getOutboundCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.LMOSiteSummary.prototype.setOutboundCount = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool favorited = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.LMOSiteSummary.prototype.getFavorited = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.cbv1.LMOSiteSummary.prototype.setFavorited = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated Stock stock_references = 7;
 * @return {!Array<!proto.cbv1.Stock>}
 */
proto.cbv1.LMOSiteSummary.prototype.getStockReferencesList = function() {
  return /** @type{!Array<!proto.cbv1.Stock>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.Stock, 7));
};


/** @param {!Array<!proto.cbv1.Stock>} value */
proto.cbv1.LMOSiteSummary.prototype.setStockReferencesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.cbv1.Stock=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.Stock}
 */
proto.cbv1.LMOSiteSummary.prototype.addStockReferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.cbv1.Stock, opt_index);
};


proto.cbv1.LMOSiteSummary.prototype.clearStockReferencesList = function() {
  this.setStockReferencesList([]);
};


/**
 * optional Availability availability = 8;
 * @return {?proto.cbv1.Availability}
 */
proto.cbv1.LMOSiteSummary.prototype.getAvailability = function() {
  return /** @type{?proto.cbv1.Availability} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.Availability, 8));
};


/** @param {?proto.cbv1.Availability|undefined} value */
proto.cbv1.LMOSiteSummary.prototype.setAvailability = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.cbv1.LMOSiteSummary.prototype.clearAvailability = function() {
  this.setAvailability(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.LMOSiteSummary.prototype.hasAvailability = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 needs_attention_count = 9;
 * @return {number}
 */
proto.cbv1.LMOSiteSummary.prototype.getNeedsAttentionCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cbv1.LMOSiteSummary.prototype.setNeedsAttentionCount = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional SiteStatus site_status = 10;
 * @return {!proto.cbv1.SiteStatus}
 */
proto.cbv1.LMOSiteSummary.prototype.getSiteStatus = function() {
  return /** @type {!proto.cbv1.SiteStatus} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {!proto.cbv1.SiteStatus} value */
proto.cbv1.LMOSiteSummary.prototype.setSiteStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional int32 in_progress_count = 11;
 * @return {number}
 */
proto.cbv1.LMOSiteSummary.prototype.getInProgressCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.cbv1.LMOSiteSummary.prototype.setInProgressCount = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional Circle geofence = 12;
 * @return {?proto.cbv1.Circle}
 */
proto.cbv1.LMOSiteSummary.prototype.getGeofence = function() {
  return /** @type{?proto.cbv1.Circle} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Circle, 12));
};


/** @param {?proto.cbv1.Circle|undefined} value */
proto.cbv1.LMOSiteSummary.prototype.setGeofence = function(value) {
  jspb.Message.setWrapperField(this, 12, value);
};


proto.cbv1.LMOSiteSummary.prototype.clearGeofence = function() {
  this.setGeofence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.LMOSiteSummary.prototype.hasGeofence = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string site_type = 13;
 * @return {string}
 */
proto.cbv1.LMOSiteSummary.prototype.getSiteType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.cbv1.LMOSiteSummary.prototype.setSiteType = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string direction = 14;
 * @return {string}
 */
proto.cbv1.LMOSiteSummary.prototype.getDirection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.cbv1.LMOSiteSummary.prototype.setDirection = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int64 up_for_grabs_count = 15;
 * @return {number}
 */
proto.cbv1.LMOSiteSummary.prototype.getUpForGrabsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.cbv1.LMOSiteSummary.prototype.setUpForGrabsCount = function(value) {
  jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 haulis_backup_count = 16;
 * @return {number}
 */
proto.cbv1.LMOSiteSummary.prototype.getHaulisBackupCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.cbv1.LMOSiteSummary.prototype.setHaulisBackupCount = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 on_site_count = 17;
 * @return {number}
 */
proto.cbv1.LMOSiteSummary.prototype.getOnSiteCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {number} value */
proto.cbv1.LMOSiteSummary.prototype.setOnSiteCount = function(value) {
  jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int64 alarm_count = 18;
 * @return {number}
 */
proto.cbv1.LMOSiteSummary.prototype.getAlarmCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/** @param {number} value */
proto.cbv1.LMOSiteSummary.prototype.setAlarmCount = function(value) {
  jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional bool currently_active = 19;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.LMOSiteSummary.prototype.getCurrentlyActive = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 19, false));
};


/** @param {boolean} value */
proto.cbv1.LMOSiteSummary.prototype.setCurrentlyActive = function(value) {
  jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional District district = 20;
 * @return {?proto.cbv1.District}
 */
proto.cbv1.LMOSiteSummary.prototype.getDistrict = function() {
  return /** @type{?proto.cbv1.District} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.District, 20));
};


/** @param {?proto.cbv1.District|undefined} value */
proto.cbv1.LMOSiteSummary.prototype.setDistrict = function(value) {
  jspb.Message.setWrapperField(this, 20, value);
};


proto.cbv1.LMOSiteSummary.prototype.clearDistrict = function() {
  this.setDistrict(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.LMOSiteSummary.prototype.hasDistrict = function() {
  return jspb.Message.getField(this, 20) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorSiteSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.VendorSiteSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorSiteSummary.displayName = 'proto.cbv1.VendorSiteSummary';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorSiteSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorSiteSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorSiteSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorSiteSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pendingCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    inProgressCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    needsAttentionCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    availability: (f = msg.getAvailability()) && proto.cbv1.Availability.toObject(includeInstance, f),
    favorited: jspb.Message.getFieldWithDefault(msg, 7, false),
    siteType: jspb.Message.getFieldWithDefault(msg, 8, ""),
    archived: jspb.Message.getFieldWithDefault(msg, 9, false),
    completedCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    upForGrabsCount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    haulisBackupCount: jspb.Message.getFieldWithDefault(msg, 12, 0),
    geofence: (f = msg.getGeofence()) && proto_geo_geo_pb.Circle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorSiteSummary}
 */
proto.cbv1.VendorSiteSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorSiteSummary;
  return proto.cbv1.VendorSiteSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorSiteSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorSiteSummary}
 */
proto.cbv1.VendorSiteSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPendingCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInProgressCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNeedsAttentionCount(value);
      break;
    case 6:
      var value = new proto.cbv1.Availability;
      reader.readMessage(value,proto.cbv1.Availability.deserializeBinaryFromReader);
      msg.setAvailability(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorited(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteType(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompletedCount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpForGrabsCount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHaulisBackupCount(value);
      break;
    case 13:
      var value = new proto_geo_geo_pb.Circle;
      reader.readMessage(value,proto_geo_geo_pb.Circle.deserializeBinaryFromReader);
      msg.setGeofence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorSiteSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorSiteSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorSiteSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorSiteSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPendingCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getInProgressCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getNeedsAttentionCount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getAvailability();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.cbv1.Availability.serializeBinaryToWriter
    );
  }
  f = message.getFavorited();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getSiteType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getCompletedCount();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getUpForGrabsCount();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getHaulisBackupCount();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getGeofence();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_geo_geo_pb.Circle.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.VendorSiteSummary.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.VendorSiteSummary.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.VendorSiteSummary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.VendorSiteSummary.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 pending_count = 3;
 * @return {number}
 */
proto.cbv1.VendorSiteSummary.prototype.getPendingCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.VendorSiteSummary.prototype.setPendingCount = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 in_progress_count = 4;
 * @return {number}
 */
proto.cbv1.VendorSiteSummary.prototype.getInProgressCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.VendorSiteSummary.prototype.setInProgressCount = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 needs_attention_count = 5;
 * @return {number}
 */
proto.cbv1.VendorSiteSummary.prototype.getNeedsAttentionCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.VendorSiteSummary.prototype.setNeedsAttentionCount = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional Availability availability = 6;
 * @return {?proto.cbv1.Availability}
 */
proto.cbv1.VendorSiteSummary.prototype.getAvailability = function() {
  return /** @type{?proto.cbv1.Availability} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.Availability, 6));
};


/** @param {?proto.cbv1.Availability|undefined} value */
proto.cbv1.VendorSiteSummary.prototype.setAvailability = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


proto.cbv1.VendorSiteSummary.prototype.clearAvailability = function() {
  this.setAvailability(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.VendorSiteSummary.prototype.hasAvailability = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool favorited = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.VendorSiteSummary.prototype.getFavorited = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.cbv1.VendorSiteSummary.prototype.setFavorited = function(value) {
  jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string site_type = 8;
 * @return {string}
 */
proto.cbv1.VendorSiteSummary.prototype.getSiteType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.cbv1.VendorSiteSummary.prototype.setSiteType = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool archived = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.VendorSiteSummary.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.cbv1.VendorSiteSummary.prototype.setArchived = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int32 completed_count = 10;
 * @return {number}
 */
proto.cbv1.VendorSiteSummary.prototype.getCompletedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cbv1.VendorSiteSummary.prototype.setCompletedCount = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 up_for_grabs_count = 11;
 * @return {number}
 */
proto.cbv1.VendorSiteSummary.prototype.getUpForGrabsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.cbv1.VendorSiteSummary.prototype.setUpForGrabsCount = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 haulis_backup_count = 12;
 * @return {number}
 */
proto.cbv1.VendorSiteSummary.prototype.getHaulisBackupCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.cbv1.VendorSiteSummary.prototype.setHaulisBackupCount = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional Circle geofence = 13;
 * @return {?proto.cbv1.Circle}
 */
proto.cbv1.VendorSiteSummary.prototype.getGeofence = function() {
  return /** @type{?proto.cbv1.Circle} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Circle, 13));
};


/** @param {?proto.cbv1.Circle|undefined} value */
proto.cbv1.VendorSiteSummary.prototype.setGeofence = function(value) {
  jspb.Message.setWrapperField(this, 13, value);
};


proto.cbv1.VendorSiteSummary.prototype.clearGeofence = function() {
  this.setGeofence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.VendorSiteSummary.prototype.hasGeofence = function() {
  return jspb.Message.getField(this, 13) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SiteShort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.SiteShort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SiteShort.displayName = 'proto.cbv1.SiteShort';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SiteShort.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SiteShort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SiteShort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SiteShort.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    geofence: (f = msg.getGeofence()) && proto_geo_geo_pb.Circle.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    favorited: jspb.Message.getFieldWithDefault(msg, 5, false),
    archived: jspb.Message.getFieldWithDefault(msg, 6, false),
    lastOrderedUnix: jspb.Message.getFieldWithDefault(msg, 7, 0),
    siteStatus: jspb.Message.getFieldWithDefault(msg, 8, 0),
    siteType: jspb.Message.getFieldWithDefault(msg, 9, ""),
    direction: jspb.Message.getFieldWithDefault(msg, 10, ""),
    expectedClosed: jspb.Message.getFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SiteShort}
 */
proto.cbv1.SiteShort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SiteShort;
  return proto.cbv1.SiteShort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SiteShort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SiteShort}
 */
proto.cbv1.SiteShort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto_geo_geo_pb.Circle;
      reader.readMessage(value,proto_geo_geo_pb.Circle.deserializeBinaryFromReader);
      msg.setGeofence(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorited(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastOrderedUnix(value);
      break;
    case 8:
      var value = /** @type {!proto.cbv1.SiteStatus} */ (reader.readEnum());
      msg.setSiteStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirection(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExpectedClosed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SiteShort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SiteShort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SiteShort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SiteShort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGeofence();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_geo_geo_pb.Circle.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFavorited();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getLastOrderedUnix();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getSiteStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getSiteType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDirection();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getExpectedClosed();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.SiteShort.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.SiteShort.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.SiteShort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.SiteShort.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Circle geofence = 3;
 * @return {?proto.cbv1.Circle}
 */
proto.cbv1.SiteShort.prototype.getGeofence = function() {
  return /** @type{?proto.cbv1.Circle} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Circle, 3));
};


/** @param {?proto.cbv1.Circle|undefined} value */
proto.cbv1.SiteShort.prototype.setGeofence = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv1.SiteShort.prototype.clearGeofence = function() {
  this.setGeofence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.SiteShort.prototype.hasGeofence = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.cbv1.SiteShort.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.SiteShort.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool favorited = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.SiteShort.prototype.getFavorited = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.cbv1.SiteShort.prototype.setFavorited = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool archived = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.SiteShort.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.cbv1.SiteShort.prototype.setArchived = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int32 last_ordered_unix = 7;
 * @return {number}
 */
proto.cbv1.SiteShort.prototype.getLastOrderedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.SiteShort.prototype.setLastOrderedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional SiteStatus site_status = 8;
 * @return {!proto.cbv1.SiteStatus}
 */
proto.cbv1.SiteShort.prototype.getSiteStatus = function() {
  return /** @type {!proto.cbv1.SiteStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {!proto.cbv1.SiteStatus} value */
proto.cbv1.SiteShort.prototype.setSiteStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string site_type = 9;
 * @return {string}
 */
proto.cbv1.SiteShort.prototype.getSiteType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.cbv1.SiteShort.prototype.setSiteType = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string direction = 10;
 * @return {string}
 */
proto.cbv1.SiteShort.prototype.getDirection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.cbv1.SiteShort.prototype.setDirection = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool expected_closed = 11;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.SiteShort.prototype.getExpectedClosed = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 11, false));
};


/** @param {boolean} value */
proto.cbv1.SiteShort.prototype.setExpectedClosed = function(value) {
  jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.Availability = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.Availability, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.Availability.displayName = 'proto.cbv1.Availability';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.Availability.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.Availability.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.Availability} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Availability.toObject = function(includeInstance, msg) {
  var f, obj = {
    available: jspb.Message.getFieldWithDefault(msg, 1, 0),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    etaUnix: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.Availability}
 */
proto.cbv1.Availability.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.Availability;
  return proto.cbv1.Availability.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.Availability} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.Availability}
 */
proto.cbv1.Availability.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cbv1.SiteAvailability} */ (reader.readEnum());
      msg.setAvailable(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEtaUnix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.Availability.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.Availability.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.Availability} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Availability.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailable();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEtaUnix();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional SiteAvailability available = 1;
 * @return {!proto.cbv1.SiteAvailability}
 */
proto.cbv1.Availability.prototype.getAvailable = function() {
  return /** @type {!proto.cbv1.SiteAvailability} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.cbv1.SiteAvailability} value */
proto.cbv1.Availability.prototype.setAvailable = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.cbv1.Availability.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.Availability.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 eta_unix = 3;
 * @return {number}
 */
proto.cbv1.Availability.prototype.getEtaUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.Availability.prototype.setEtaUnix = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.Stock = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.Stock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.Stock.displayName = 'proto.cbv1.Stock';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.Stock.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.Stock.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.Stock} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Stock.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    payload: (f = msg.getPayload()) && proto_cybertron_payload_payload_pb.Payload.toObject(includeInstance, f),
    quantity: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    maxQuantity: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    createdUnix: jspb.Message.getFieldWithDefault(msg, 5, 0),
    updatedUnix: jspb.Message.getFieldWithDefault(msg, 6, 0),
    ghostQuantity: +jspb.Message.getFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.Stock}
 */
proto.cbv1.Stock.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.Stock;
  return proto.cbv1.Stock.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.Stock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.Stock}
 */
proto.cbv1.Stock.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_cybertron_payload_payload_pb.Payload;
      reader.readMessage(value,proto_cybertron_payload_payload_pb.Payload.deserializeBinaryFromReader);
      msg.setPayload(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxQuantity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedUnix(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdatedUnix(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGhostQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.Stock.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.Stock.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.Stock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Stock.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPayload();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_cybertron_payload_payload_pb.Payload.serializeBinaryToWriter
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getMaxQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getUpdatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getGhostQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.Stock.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.Stock.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Payload payload = 2;
 * @return {?proto.cbv1.Payload}
 */
proto.cbv1.Stock.prototype.getPayload = function() {
  return /** @type{?proto.cbv1.Payload} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_payload_payload_pb.Payload, 2));
};


/** @param {?proto.cbv1.Payload|undefined} value */
proto.cbv1.Stock.prototype.setPayload = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cbv1.Stock.prototype.clearPayload = function() {
  this.setPayload(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Stock.prototype.hasPayload = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double quantity = 3;
 * @return {number}
 */
proto.cbv1.Stock.prototype.getQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cbv1.Stock.prototype.setQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double max_quantity = 4;
 * @return {number}
 */
proto.cbv1.Stock.prototype.getMaxQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.cbv1.Stock.prototype.setMaxQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 created_unix = 5;
 * @return {number}
 */
proto.cbv1.Stock.prototype.getCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.Stock.prototype.setCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 updated_unix = 6;
 * @return {number}
 */
proto.cbv1.Stock.prototype.getUpdatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.Stock.prototype.setUpdatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional double ghost_quantity = 7;
 * @return {number}
 */
proto.cbv1.Stock.prototype.getGhostQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.cbv1.Stock.prototype.setGhostQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SiteEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.SiteEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SiteEvent.displayName = 'proto.cbv1.SiteEvent';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SiteEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SiteEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SiteEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SiteEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: jspb.Message.getFieldWithDefault(msg, 1, 0),
    site: (f = msg.getSite()) && proto.cbv1.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SiteEvent}
 */
proto.cbv1.SiteEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SiteEvent;
  return proto.cbv1.SiteEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SiteEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SiteEvent}
 */
proto.cbv1.SiteEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cbv1.EventType} */ (reader.readEnum());
      msg.setEvent(value);
      break;
    case 2:
      var value = new proto.cbv1.Site;
      reader.readMessage(value,proto.cbv1.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SiteEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SiteEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SiteEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SiteEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.cbv1.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventType event = 1;
 * @return {!proto.cbv1.EventType}
 */
proto.cbv1.SiteEvent.prototype.getEvent = function() {
  return /** @type {!proto.cbv1.EventType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.cbv1.EventType} value */
proto.cbv1.SiteEvent.prototype.setEvent = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Site site = 2;
 * @return {?proto.cbv1.Site}
 */
proto.cbv1.SiteEvent.prototype.getSite = function() {
  return /** @type{?proto.cbv1.Site} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.Site, 2));
};


/** @param {?proto.cbv1.Site|undefined} value */
proto.cbv1.SiteEvent.prototype.setSite = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cbv1.SiteEvent.prototype.clearSite = function() {
  this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.SiteEvent.prototype.hasSite = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOCalendarStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOCalendarStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOCalendarStats.displayName = 'proto.cbv1.LMOCalendarStats';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOCalendarStats.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOCalendarStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOCalendarStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOCalendarStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateUnix: jspb.Message.getFieldWithDefault(msg, 1, 0),
    driverCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    orderCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    assignedOrderCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    year: jspb.Message.getFieldWithDefault(msg, 5, 0),
    month: jspb.Message.getFieldWithDefault(msg, 6, 0),
    date: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOCalendarStats}
 */
proto.cbv1.LMOCalendarStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOCalendarStats;
  return proto.cbv1.LMOCalendarStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOCalendarStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOCalendarStats}
 */
proto.cbv1.LMOCalendarStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateUnix(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDriverCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssignedOrderCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setYear(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonth(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOCalendarStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOCalendarStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOCalendarStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOCalendarStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateUnix();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDriverCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOrderCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAssignedOrderCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional int64 date_unix = 1;
 * @return {number}
 */
proto.cbv1.LMOCalendarStats.prototype.getDateUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.LMOCalendarStats.prototype.setDateUnix = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 driver_count = 2;
 * @return {number}
 */
proto.cbv1.LMOCalendarStats.prototype.getDriverCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.LMOCalendarStats.prototype.setDriverCount = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 order_count = 3;
 * @return {number}
 */
proto.cbv1.LMOCalendarStats.prototype.getOrderCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.LMOCalendarStats.prototype.setOrderCount = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 assigned_order_count = 4;
 * @return {number}
 */
proto.cbv1.LMOCalendarStats.prototype.getAssignedOrderCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.LMOCalendarStats.prototype.setAssignedOrderCount = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 year = 5;
 * @return {number}
 */
proto.cbv1.LMOCalendarStats.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.LMOCalendarStats.prototype.setYear = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 month = 6;
 * @return {number}
 */
proto.cbv1.LMOCalendarStats.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.LMOCalendarStats.prototype.setMonth = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 date = 7;
 * @return {number}
 */
proto.cbv1.LMOCalendarStats.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.LMOCalendarStats.prototype.setDate = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorSite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.VendorSite.repeatedFields_, null);
};
goog.inherits(proto.cbv1.VendorSite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorSite.displayName = 'proto.cbv1.VendorSite';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.VendorSite.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorSite.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorSite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorSite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorSite.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    siteType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    geofence: (f = msg.getGeofence()) && proto_geo_geo_pb.Circle.toObject(includeInstance, f),
    directions: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    availability: (f = msg.getAvailability()) && proto.cbv1.Availability.toObject(includeInstance, f),
    pendingCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    inProgressCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    completedCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    favorited: jspb.Message.getFieldWithDefault(msg, 11, false),
    archived: jspb.Message.getFieldWithDefault(msg, 12, false),
    address: jspb.Message.getFieldWithDefault(msg, 13, ""),
    availabledurationsList: jspb.Message.toObjectList(msg.getAvailabledurationsList(),
    proto.cbv1.SiteAvailableDuration.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorSite}
 */
proto.cbv1.VendorSite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorSite;
  return proto.cbv1.VendorSite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorSite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorSite}
 */
proto.cbv1.VendorSite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteType(value);
      break;
    case 4:
      var value = new proto_geo_geo_pb.Circle;
      reader.readMessage(value,proto_geo_geo_pb.Circle.deserializeBinaryFromReader);
      msg.setGeofence(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirections(value);
      break;
    case 6:
      var value = /** @type {!proto.cbv1.SiteStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = new proto.cbv1.Availability;
      reader.readMessage(value,proto.cbv1.Availability.deserializeBinaryFromReader);
      msg.setAvailability(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPendingCount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInProgressCount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompletedCount(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorited(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 14:
      var value = new proto.cbv1.SiteAvailableDuration;
      reader.readMessage(value,proto.cbv1.SiteAvailableDuration.deserializeBinaryFromReader);
      msg.addAvailabledurations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorSite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorSite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorSite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorSite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSiteType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGeofence();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_geo_geo_pb.Circle.serializeBinaryToWriter
    );
  }
  f = message.getDirections();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAvailability();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.cbv1.Availability.serializeBinaryToWriter
    );
  }
  f = message.getPendingCount();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getInProgressCount();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getCompletedCount();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getFavorited();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAvailabledurationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.cbv1.SiteAvailableDuration.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.VendorSite.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.VendorSite.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.VendorSite.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.VendorSite.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string site_type = 3;
 * @return {string}
 */
proto.cbv1.VendorSite.prototype.getSiteType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.VendorSite.prototype.setSiteType = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Circle geofence = 4;
 * @return {?proto.cbv1.Circle}
 */
proto.cbv1.VendorSite.prototype.getGeofence = function() {
  return /** @type{?proto.cbv1.Circle} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Circle, 4));
};


/** @param {?proto.cbv1.Circle|undefined} value */
proto.cbv1.VendorSite.prototype.setGeofence = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.cbv1.VendorSite.prototype.clearGeofence = function() {
  this.setGeofence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.VendorSite.prototype.hasGeofence = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string directions = 5;
 * @return {string}
 */
proto.cbv1.VendorSite.prototype.getDirections = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.VendorSite.prototype.setDirections = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional SiteStatus status = 6;
 * @return {!proto.cbv1.SiteStatus}
 */
proto.cbv1.VendorSite.prototype.getStatus = function() {
  return /** @type {!proto.cbv1.SiteStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.cbv1.SiteStatus} value */
proto.cbv1.VendorSite.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional Availability availability = 7;
 * @return {?proto.cbv1.Availability}
 */
proto.cbv1.VendorSite.prototype.getAvailability = function() {
  return /** @type{?proto.cbv1.Availability} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.Availability, 7));
};


/** @param {?proto.cbv1.Availability|undefined} value */
proto.cbv1.VendorSite.prototype.setAvailability = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


proto.cbv1.VendorSite.prototype.clearAvailability = function() {
  this.setAvailability(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.VendorSite.prototype.hasAvailability = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 pending_count = 8;
 * @return {number}
 */
proto.cbv1.VendorSite.prototype.getPendingCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cbv1.VendorSite.prototype.setPendingCount = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 in_progress_count = 9;
 * @return {number}
 */
proto.cbv1.VendorSite.prototype.getInProgressCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cbv1.VendorSite.prototype.setInProgressCount = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 completed_count = 10;
 * @return {number}
 */
proto.cbv1.VendorSite.prototype.getCompletedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cbv1.VendorSite.prototype.setCompletedCount = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool favorited = 11;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.VendorSite.prototype.getFavorited = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 11, false));
};


/** @param {boolean} value */
proto.cbv1.VendorSite.prototype.setFavorited = function(value) {
  jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool archived = 12;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.VendorSite.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 12, false));
};


/** @param {boolean} value */
proto.cbv1.VendorSite.prototype.setArchived = function(value) {
  jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string address = 13;
 * @return {string}
 */
proto.cbv1.VendorSite.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.cbv1.VendorSite.prototype.setAddress = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated SiteAvailableDuration AvailableDurations = 14;
 * @return {!Array<!proto.cbv1.SiteAvailableDuration>}
 */
proto.cbv1.VendorSite.prototype.getAvailabledurationsList = function() {
  return /** @type{!Array<!proto.cbv1.SiteAvailableDuration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.SiteAvailableDuration, 14));
};


/** @param {!Array<!proto.cbv1.SiteAvailableDuration>} value */
proto.cbv1.VendorSite.prototype.setAvailabledurationsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.cbv1.SiteAvailableDuration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.SiteAvailableDuration}
 */
proto.cbv1.VendorSite.prototype.addAvailabledurations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.cbv1.SiteAvailableDuration, opt_index);
};


proto.cbv1.VendorSite.prototype.clearAvailabledurationsList = function() {
  this.setAvailabledurationsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.District = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.District, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.District.displayName = 'proto.cbv1.District';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.District.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.District.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.District} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.District.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lmoId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.District}
 */
proto.cbv1.District.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.District;
  return proto.cbv1.District.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.District} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.District}
 */
proto.cbv1.District.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLmoId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.District.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.District.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.District} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.District.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLmoId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.District.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.District.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.District.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.District.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 lmo_id = 3;
 * @return {number}
 */
proto.cbv1.District.prototype.getLmoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.District.prototype.setLmoId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


goog.object.extend(exports, proto.cbv1);
