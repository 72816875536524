/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./network-active-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./network-active-button.component";
var styles_NetworkActiveButtonComponent = [i0.styles];
var RenderType_NetworkActiveButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NetworkActiveButtonComponent, data: { "animation": [{ type: 7, name: "fadeInOut", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "200ms ease-in" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "200ms ease-in" }], options: null }], options: {} }] } });
export { RenderType_NetworkActiveButtonComponent as RenderType_NetworkActiveButtonComponent };
function View_NetworkActiveButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "overlay"]], [[24, "@fadeInOut", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["diameter", "30"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(2, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_4 = "30"; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_2 = i1.ɵnov(_v, 2).diameter; var currVal_3 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_NetworkActiveButtonComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "network-active-button"]], [[2, "active", null]], null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NetworkActiveButtonComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.active; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.active; _ck(_v, 0, 0, currVal_0); }); }
export function View_NetworkActiveButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ct-network-active-button", [], null, null, null, View_NetworkActiveButtonComponent_0, RenderType_NetworkActiveButtonComponent)), i1.ɵdid(1, 114688, null, 2, i7.NetworkActiveButtonComponent, [], null, null), i1.ɵqud(603979776, 1, { button: 0 }), i1.ɵqud(603979776, 2, { ctButton: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NetworkActiveButtonComponentNgFactory = i1.ɵccf("ct-network-active-button", i7.NetworkActiveButtonComponent, View_NetworkActiveButtonComponent_Host_0, { active: "active" }, {}, ["*"]);
export { NetworkActiveButtonComponentNgFactory as NetworkActiveButtonComponentNgFactory };
