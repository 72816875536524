import { CurrencyPipe } from '@angular/common';
import { Directive, ElementRef, forwardRef, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, pluck, startWith, tap } from 'rxjs/operators';

const onlyNumbers = /[^0-9.]/g;

@Directive({
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberDirective),
    },
    CurrencyPipe,
  ],
  selector: '[ctNumber]',
})
export class NumberDirective implements ControlValueAccessor {
  private text$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public type: string;
  public onChange: Function;
  public onTouched: Function;
  public value: number;

  public get text$(): Observable<string> {
    return this.text$$.asObservable();
  }

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {
    this.type = (_elementRef.nativeElement as HTMLInputElement).type;
    if (this.type !== 'text') {
      console.warn('number directive text input');
    }
    fromEvent(this._elementRef.nativeElement, 'input')
      .pipe(
        tap((event: Event) => {
          event.preventDefault();
          event.stopImmediatePropagation();
          event.stopPropagation();
        }),
        pluck('target', 'value'),
        startWith(''),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        const inputOnlyNumberString = (value as string).replace(onlyNumbers, '');
        const inputOnlyNumber = inputOnlyNumberString.endsWith('.')
          ? inputOnlyNumberString
          : parseFloat(inputOnlyNumberString) || 0;
        this.writeValue(inputOnlyNumber);
      });
  }

  public writeValue(input: number | string): void {
    if (typeof input === 'string') {
      if (this.type === 'text') {
        const parsedWithoutDecimal = parseInt(input.substring(0, input.length - 1), 10);
        const formattedField = `${parsedWithoutDecimal.toLocaleString()}.`;
        this._renderer.setProperty(this._elementRef.nativeElement, 'value', formattedField);
        this.text$$.next(formattedField);
      } else {
        this._renderer.setProperty(this._elementRef.nativeElement, 'value', input);
        this.text$$.next(`${input}`);
      }
    } else if (!isNaN(input) && input !== null) {
      if (this.type === 'text') {
        const formattedField = input.toLocaleString('lookup', { maximumFractionDigits: 20 });
        this._renderer.setProperty(this._elementRef.nativeElement, 'value', formattedField);
        this.text$$.next(formattedField);
      } else {
        this._renderer.setProperty(this._elementRef.nativeElement, 'value', input);
        this.text$$.next(`${input}`);
      }
    } else {
      this.text$$.next(null);
      this._renderer.setProperty(this._elementRef.nativeElement, 'value', null);
    }
    if (this.onChange) {
      if (typeof input === 'string') {
        this.onChange(null);
      } else {
        this.onChange(input);
      }
    }
  }
  public registerOnChange(fn: Function): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }
}
