export const JOB_ID = `job_id`;
export const ORDER_ID = `order_id`;
export const DRIVER_ID = `driver_id`;
export const TRAILER_ID = `trailer_id`;
export const PAYLOAD_ID = 'payload_id';
export const STOCK_ID = `stock_id`;
export const CONTRACT_ID = `contract_id`;
export const YEAR = 'year';
export const MONTH = 'month';
export const DAY = 'day';
export const LOG_CLUSTER = `logistics_cluster`;
export const USER_ID = `user_id`;
export const BUSINESS_LINE =  `business_line`;
