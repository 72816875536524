import * as tslib_1 from "tslib";
import { formatNumber } from '@angular/common';
import { OnInit } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { CanBidRequest, CanMarkNotInterestedRequest, CreateRFQBidRequest, markNotInterestedRequest, } from '~proto/marketplace/rfq_api_pb';
import { Point } from '../../../../proto/geo/geo_pb';
import { RfqLocationFormComponent } from '../rfq-location-form/rfq-location-form.component';
var pageType;
(function (pageType) {
    pageType["CALL_BID_TYPE"] = "call";
    pageType["MARK_NOT_interested_TYPE"] = "marknotinterested";
})(pageType || (pageType = {}));
var MarketplaceEmailBidComponent = /** @class */ (function () {
    function MarketplaceEmailBidComponent(router, marketplaceService, matdialog, snackbar) {
        this.router = router;
        this.marketplaceService = marketplaceService;
        this.matdialog = matdialog;
        this.snackbar = snackbar;
        this.isReBid = false;
        this.placeholderString$ = new BehaviorSubject('');
        this.networkActive$$ = new BehaviorSubject(false);
        this.rfq$ = new BehaviorSubject(null);
        this.distanceInMiles$ = new BehaviorSubject(null);
    }
    MarketplaceEmailBidComponent.prototype.ngOnInit = function () {
        this.rfqId = +this.router.snapshot.params['rfqId'];
        this.token = this.router.snapshot.params['token'];
        this.type = this.router.snapshot.params['type'].toLowerCase();
        this.checkType();
    };
    MarketplaceEmailBidComponent.prototype.checkType = function () {
        if (this.type === pageType.CALL_BID_TYPE) {
            this.checkIfCanBid();
        }
        else if (this.type === pageType.MARK_NOT_interested_TYPE) {
            this.checkIfCanMarkNotinterested();
        }
        else {
            this.setPlaceholderString('PAGE NOT FOUND');
        }
    };
    MarketplaceEmailBidComponent.prototype.checkAllFields = function () {
        if (this.isReBid) {
            return !this.bid;
        }
        else {
            return !this.bid && !this.pickupLocation;
        }
    };
    MarketplaceEmailBidComponent.prototype.checkIfCanBid = function () {
        var _this = this;
        var canBidRequest = new CanBidRequest();
        canBidRequest.setRfqId(this.rfqId);
        canBidRequest.setToken(this.token);
        this.marketplaceService.canBid(canBidRequest).subscribe(function (bidResponse) {
            if (bidResponse.getCanBid()) {
                _this.rfq$.next(bidResponse.getRfq().toObject());
                _this.countDown(_this.rfq$);
                if (bidResponse.getPreviousBid()) {
                    _this.isReBid = true;
                    _this.bid = +(bidResponse.getPreviousBid().getAmount() / 100).toFixed(2);
                }
            }
            else {
                _this.setPlaceholderString(bidResponse.getReason());
            }
        }, function (err) {
            _this.setPlaceholderString('RFQ DETAILS NOT FOUND');
        });
    };
    MarketplaceEmailBidComponent.prototype.checkIfCanMarkNotinterested = function () {
        var _this = this;
        var canMarkNotInterestedRequest = new CanMarkNotInterestedRequest();
        canMarkNotInterestedRequest.setRfqId(this.rfqId);
        canMarkNotInterestedRequest.setToken(this.token);
        this.marketplaceService.canMarkNotinterested(canMarkNotInterestedRequest).subscribe(function (markNotInterestedResp) {
            if (markNotInterestedResp.getCanUpdate()) {
                _this.rfq$.next(markNotInterestedResp.getRfq().toObject());
            }
            else {
                _this.setPlaceholderString(markNotInterestedResp.getReason());
            }
        }, function (err) {
            _this.setPlaceholderString('RFQ DETAILS NOT FOUND');
        });
    };
    MarketplaceEmailBidComponent.prototype.setPlaceholderString = function (value) {
        this.placeholderString$.next(value);
    };
    MarketplaceEmailBidComponent.prototype.createBid = function () {
        var _this = this;
        if (this.canSubmitBid()) {
            this.networkActive$$.next(true);
            var createBidRequest = new CreateRFQBidRequest();
            createBidRequest.setAmount(this.bid * 100);
            createBidRequest.setRfqId(this.rfqId);
            createBidRequest.setToken(this.token);
            if (!this.isReBid && this.isProductRfq(this.rfq$.value)) {
                var point = new Point();
                var location_1 = this.pickupLocation.split(',');
                point.setLat(+location_1[0]);
                point.setLon(+location_1[1]);
                createBidRequest.setSiteGeofence(point);
            }
            this.marketplaceService.createBid(createBidRequest).subscribe(function (resp) {
                if (resp.getCreated()) {
                    _this.setPlaceholderString('BID PLACED SUCCESSFULLY');
                    _this.networkActive$$.next(false);
                }
                else {
                    _this.showSnackbar(resp.getReason());
                    _this.networkActive$$.next(false);
                }
            }, function (err) {
                _this.showSnackbar('BID PLACING FAILED. PLEASE TRY AGAIN LATER');
                _this.networkActive$$.next(false);
            });
        }
        else {
            this.showSnackbar(this.isProductRfq(this.rfq$.value) ? 'Please enter valid bid and pickup location' : 'Please enter valid bid');
        }
    };
    MarketplaceEmailBidComponent.prototype.canSubmitBid = function () {
        if (this.bid && this.bid > 0) {
            if (this.isProductRfq(this.rfq$.value) && !this.isReBid) {
                return !!this.pickupLocation;
            }
            else {
                return true;
            }
        }
        return false;
    };
    MarketplaceEmailBidComponent.prototype.openMapLocation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var mapDialog, locationData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        mapDialog = this.matdialog.open(RfqLocationFormComponent);
                        return [4 /*yield*/, mapDialog.afterClosed().toPromise()];
                    case 1:
                        locationData = _a.sent();
                        this.pickupLocation = locationData;
                        return [2 /*return*/];
                }
            });
        });
    };
    MarketplaceEmailBidComponent.prototype.countDown = function (dataObj) {
        var _this = this;
        dataObj.subscribe(function (obj) {
            if (obj) {
                if (new Date().getTime() / 1000 < obj.biddingStartTime) {
                    var date = moment.unix(obj.biddingStartTime).format('MM/DD/YY, h:mm a');
                    _this.counter = "Bidding will begin at: " + date + " ";
                }
                else if (new Date().getTime() / 1000 > obj.biddingEndTime) {
                    var date = moment.unix(obj.biddingEndTime).format('MM/DD/YY, h:mm a');
                    _this.counter = "Bidding ended at: " + date;
                }
                else {
                    setInterval(function () {
                        var count = obj.biddingEndTime - new Date().getTime() / 1000;
                        _this.counter = _this.secondsToDhms(count.toFixed());
                    }, 1000);
                }
            }
        });
    };
    MarketplaceEmailBidComponent.prototype.secondsToDhms = function (seconds) {
        if (seconds > 0) {
            var d = Math.floor(seconds / (3600 * 24));
            var h = Math.floor((seconds % (3600 * 24)) / 3600);
            var m = Math.floor((seconds % 3600) / 60);
            var s = Math.floor(seconds % 60);
            var dDisplay = d > 0 ? d + (d === 1 ? ' day ' : ' days ') : '';
            var hDisplay = h + ':';
            var mDisplay = m + ':';
            var sDisplay = s + '';
            return ('Bidding ends in:  ' +
                dDisplay +
                hDisplay.padStart(3, '0') +
                mDisplay.padStart(3, '0') +
                sDisplay.padStart(2, '0'));
        }
        else {
            return 'Bidding Has Ended';
        }
    };
    // private getDistanceInMiles() {
    //   const distanceReq = new CalculateDistanceRequest();
    //   const pickupLoc = this.pickupLocation.split(',');
    //   const pickupPoint = new Point();
    //   pickupPoint.setLat(+pickupLoc[0]);
    //   pickupPoint.setLon(+pickupLoc[1]);
    //   distanceReq.setPickUp(pickupPoint);
    //   const dropOffPoint = new Point();
    //   const sub = this.rfq$.subscribe((rfq) => {
    //     dropOffPoint.setLat(rfq.well.lat);
    //     dropOffPoint.setLon(rfq.well.lon);
    //   });
    //   distanceReq.setDropOff(dropOffPoint);
    //   sub.unsubscribe();
    //   this.marketplaceService.getDistance(distanceReq).subscribe(
    //     (resp: CalculateDistanceResponse) => {
    //       if (!resp.getReason()) {
    //         this.distanceInMiles$.next(resp.getDistance());
    //       } else {
    //         this.showSnackbar(resp.getReason());
    //       }
    //     },
    //     (err) => {
    //       this.showSnackbar('Error getting distance');
    //     },
    //   );
    // }
    MarketplaceEmailBidComponent.prototype.showSnackbar = function (msg) {
        this.snackbar.open(msg, null, {
            duration: 2000,
            panelClass: ['snackbar-error'],
        });
    };
    MarketplaceEmailBidComponent.prototype.setMarkNotinterested = function () {
        var _this = this;
        this.networkActive$$.next(true);
        var markNotinterested = new markNotInterestedRequest();
        markNotinterested.setRfqId(this.rfqId);
        markNotinterested.setToken(this.token);
        this.marketplaceService.markNotInterested(markNotinterested).subscribe(function (resp) {
            if (resp.getUpdated()) {
                _this.setPlaceholderString('YOU HAVE BEEN MARKED NOT INTERESTED SUCCESSFULLY');
                _this.networkActive$$.next(false);
            }
            else {
                _this.showSnackbar(resp.getReason());
                _this.networkActive$$.next(false);
            }
        }, function (err) {
            _this.showSnackbar('MARKING YOU NOT INTERESTED FAILED FOR SOME REASON. PLEASE TRY AGAIN LATER');
            _this.networkActive$$.next(false);
        });
    };
    MarketplaceEmailBidComponent.prototype.isFreightRfq = function (rfq) {
        return rfq.rfqType === 2;
    };
    MarketplaceEmailBidComponent.prototype.isProductRfq = function (rfq) {
        return rfq.rfqType === 3;
    };
    MarketplaceEmailBidComponent.prototype.isDeliveredProductRfq = function (rfq) {
        return rfq.rfqType === 4;
    };
    MarketplaceEmailBidComponent.prototype.getTotalCostLabel = function (rfq) {
        if (this.isFreightRfq(rfq)) {
            return "TOTAL COST ($) at " + rfq.distance + " miles";
        }
        else if (this.isProductRfq(rfq) || this.isDeliveredProductRfq(rfq)) {
            return "TOTAL COST ($) at " + formatNumber(rfq.quantity, 'en-US') + " " + rfq.unit;
        }
    };
    MarketplaceEmailBidComponent.prototype.getTotalCost = function (rfq) {
        if (this.isFreightRfq(rfq)) {
            return (this.bid * rfq.distance).toFixed(2);
        }
        else if (this.isProductRfq(rfq) || this.isDeliveredProductRfq(rfq)) {
            return (this.bid * rfq.quantity).toFixed(2);
        }
    };
    MarketplaceEmailBidComponent.prototype.getMinReductionSuffix = function (rfq) {
        if (this.isFreightRfq(rfq)) {
            return "$ / MILE";
        }
        else {
            return "$ / " + rfq.unit;
        }
    };
    return MarketplaceEmailBidComponent;
}());
export { MarketplaceEmailBidComponent };
