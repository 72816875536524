<div class="select-payload-quantity" *ngIf="payload">
  <ct-mini-modal title="Quantity" [subTitle]="payload.name">
    <main>
      <div class="content">
        <div class="choice">
          <ct-labeled-button
            *ngIf="payload.lastUsedQuantity"
            label="Last Used"
            text="{{ payload.lastUsedQuantity }} ({{ payload.unit.abbreviation }})"
            (click)="selected(payload.lastUsedQuantity)"
            ctAutomatedSelector="payload_quantity__last_used"
          ></ct-labeled-button>
        </div>
        <div class="choice">
          <ct-labeled-button
            *ngIf="payload.frequentlyUsedQuantity"
            label="Frequently Used"
            text="{{ payload.frequentlyUsedQuantity }} ({{ payload.unit.abbreviation }})"
            (click)="selected(payload.frequentlyUsedQuantity)"
            ctAutomatedSelector="payload_quantity__frequently_used"
          ></ct-labeled-button>
        </div>
        <div class="choice">
          <ct-labeled-button
            *ngIf="!(customAmountStarted$ | async)"
            label="Other"
            text="Enter a Quantity ({{ payload.unit.abbreviation }})"
            (click)="customAmountStart()"
            automatedSelector="payload_quantity__custom_amount_button"
          ></ct-labeled-button>
          <ng-container *ngIf="customAmountStarted$ | async">
            <ct-input staticLabel label="Custom Amount" [suffix]="payload.unit.abbreviation">
              <input
                ctNumber
                [formControl]="fc"
                #customQuantity
                ctAutoFocus
                (keyup.enter)="customAmountSelected()"
                ctAutomatedSelector="payload_quantity__custom_amount_input"
              />
            </ct-input>
            <button
              ct-dark-button
              type="button"
              class="full-button"
              (click)="customAmountSelected()"
              ctAutomatedSelector="payload_quantity__save_quanity"
            >
              SAVE
            </button>
          </ng-container>
        </div>
      </div>
    </main>
  </ct-mini-modal>
</div>
