import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, filter, finalize, map, shareReplay, startWith, take } from 'rxjs/operators';
import { ConfirmDialogComponent } from '~common/confirm-dialog/confirm-dialog.component';
var MAX_FILE_SIZE = 25 * 1024 * 1024;
var LmoUserManagementComponent = /** @class */ (function () {
    function LmoUserManagementComponent(userService, snackBar, router, dialog, roleService) {
        this.userService = userService;
        this.snackBar = snackBar;
        this.router = router;
        this.dialog = dialog;
        this.roleService = roleService;
        this.userSearch = new FormControl();
        this.networkActive$$ = new BehaviorSubject(false);
        this.displayedColumns = ['name', 'email', 'role', 'actions'];
        this.isBusinessLineEnabled$$ = new BehaviorSubject(false);
        this.setUserRoles = {
            1: 'Admin',
            2: 'Default',
            4: 'View Only',
        };
        this.canCrudUsers$ = this.userService.canCrudUsers$.pipe(filter(function (c) { return c.initialized; }), map(function (c) { return c.canCrud; }), shareReplay(1));
    }
    LmoUserManagementComponent.prototype.ngOnInit = function () {
        this.users$ = combineLatest([
            this.userService.users$,
            this.userSearch.valueChanges.pipe(startWith(''), debounceTime(200)),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), users = _b[0], searchTerm = _b[1];
            if (!searchTerm || searchTerm === '') {
                return users;
            }
            return users.filter(function (user) {
                return user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    user.email.toLowerCase().includes(searchTerm.toLowerCase());
            });
        }), map(function (users) {
            return new MatTableDataSource(users);
        }));
        this.checkIfBusinessLineEnabled();
    };
    LmoUserManagementComponent.prototype.trackByAuthId = function (_index, user) {
        return user.authId;
    };
    LmoUserManagementComponent.prototype.editUser = function (row) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var canCrud;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.canCrudUsers$.pipe(take(1)).toPromise()];
                    case 1:
                        canCrud = _a.sent();
                        if (!canCrud) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.router.navigate(['/lmo/', 'users', row.authId, 'edit'])];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LmoUserManagementComponent.prototype.removeUser = function (user, event) {
        var _this = this;
        event.stopPropagation();
        var dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                acceptButtonText: 'Delete',
                declineButtonText: 'Cancel',
                title: "Are you sure you want to delete " + user.name + " ?",
            },
        });
        dialogRef
            .afterClosed()
            .pipe(filter(function (data) { return !!data; }))
            .subscribe(function (result) {
            var authId = user.authId;
            if (!authId) {
                return;
            }
            _this.networkActive$$.next(true);
            _this.userService
                .unlinkUser$(authId)
                .pipe(finalize(function () {
                _this.networkActive$$.next(false);
            }))
                .subscribe(function (response) {
                _this.snackBar.open('User remove successfully', null, {
                    duration: 2000,
                });
            }, function (err) {
                // todo: check error message
                var error = err.error;
                _this.snackBar.open('Error:' + (error.Message ? error.Message : error), null, {
                    duration: 5000,
                });
            });
        });
    };
    LmoUserManagementComponent.prototype.onFileChange = function (event) {
        if (event.target.files && event.target.files.length) {
            var _a = tslib_1.__read(event.target.files, 1), file = _a[0];
            if (file.size > MAX_FILE_SIZE) {
                this.snackBar.open('File Size cannot be greater than 25 MB', null, {
                    duration: 2500,
                });
            }
            else {
                this.uploadFile(file);
            }
        }
    };
    LmoUserManagementComponent.prototype.uploadFile = function (fileToUpload) {
        var _this = this;
        this.networkActive$$.next(true);
        this.userService.userBulkUploadFile$(fileToUpload).subscribe(function (response) {
            _this.networkActive$$.next(false);
            _this.snackBar.open('File uploaded successfully', null, {
                duration: 2000,
            });
            _this.uploadDialog.close();
        }, function (err) {
            // todo: check error message
            _this.networkActive$$.next(false);
            var error = err.error;
            _this.snackBar.open('Error:' + (error.Message ? error.Message : error), null, {
                duration: 5000,
            });
            _this.uploadDialog.close();
        });
    };
    LmoUserManagementComponent.prototype.userFileAction = function () {
        this.uploadDialog = this.dialog.open(this.userTemplateActionModal, {
            maxWidth: '500px;',
        });
    };
    LmoUserManagementComponent.prototype.checkIfBusinessLineEnabled = function () {
        var _this = this;
        this.roleService.isBusinessLineEnabled$.subscribe(function (isBusinessLineEnabled) {
            _this.isBusinessLineEnabled$$.next(isBusinessLineEnabled);
        });
    };
    LmoUserManagementComponent.prototype.closeModal = function () {
        var _this = this;
        setTimeout(function () { return _this.uploadDialog.close(); }, 1000);
    };
    return LmoUserManagementComponent;
}());
export { LmoUserManagementComponent };
