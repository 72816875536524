import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { trackById } from '~utilities/trackById';
import { RfqLocationFormComponent } from '../rfq-location-form/rfq-location-form.component';
var FREIGHT_RFQ_TYPE = 'freight';
var PRODUCT_RFQ_TYPE = 'product';
var DELIVERED_PRODUCT_RFQ_TYPE = 'deliveredProduct';
var RfqItemFormComponent = /** @class */ (function () {
    function RfqItemFormComponent(fb, rfqFormService, marketplaceService, router, route, snackBar, matdialog, constants, snackbar) {
        this.fb = fb;
        this.rfqFormService = rfqFormService;
        this.marketplaceService = marketplaceService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.matdialog = matdialog;
        this.constants = constants;
        this.snackbar = snackbar;
        this.trackById = trackById;
        this.networkActive$$ = new BehaviorSubject(false);
        this.fileUploading$$ = new BehaviorSubject(false);
        this.rfqStartingTime$ = new BehaviorSubject('');
        this.rfqType$ = new BehaviorSubject('');
        this.rfqDuration$ = new BehaviorSubject('');
        this.destroy$ = new Subject();
        this.fileToUpload = [];
        this.productsList = [];
        this.productSuggestions$ = new BehaviorSubject(this.productsList);
    }
    Object.defineProperty(RfqItemFormComponent.prototype, "rfqUnit", {
        get: function () {
            return this.rfqItemForm.get('unit').value;
        },
        enumerable: true,
        configurable: true
    });
    RfqItemFormComponent.prototype.ngOnInit = function () {
        this.rfqType$.next(this.route.parent.snapshot.params.type);
        this.rfqFormService.setRfqType(this.rfqType$.value);
        this.buildForm();
        this.getSuggestions();
        this.units = Object.values(this.constants.units);
        this.units.sort(function (a, b) { return a.name.localeCompare(b.name); });
    };
    RfqItemFormComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(null);
        this.destroy$.unsubscribe();
    };
    RfqItemFormComponent.prototype.getSuggestions = function () {
        var _this = this;
        this.marketplaceService.rfqSuggestions$
            .pipe(map(function (suggestions) { return (suggestions && suggestions.productsList) || []; }), takeUntil(this.destroy$))
            .subscribe(function (productsList) {
            _this.productsList = tslib_1.__spread(productsList);
            _this.productSuggestions$.next(_this.productsList);
        });
    };
    RfqItemFormComponent.prototype.buildForm = function () {
        var _this = this;
        this.rfqItemForm = this.fb.group({
            attachments: [[], []],
            description: ['', []],
            minBidReduction: [null, []],
            quantity: [0, [Validators.required]],
            rfqDuration: [null, [Validators.required]],
            unit: [null, [Validators.required]],
        });
        if (this.isFreightRfq()) {
            this.rfqItemForm.addControl('freightType', new FormControl('', Validators.required));
            this.rfqItemForm.addControl('pickupSite', new FormControl('', Validators.required));
            this.rfqItemForm.addControl('pickupTime', new FormControl('', Validators.required));
            this.rfqItemForm.addControl('dropoffSite', new FormControl('', Validators.required));
            this.rfqItemForm.addControl('dropoffTime', new FormControl('', Validators.required));
        }
        else if (this.isProductRfq() || this.isDeliveredProductRfq()) {
            this.rfqItemForm.addControl('jobDuration', new FormControl('', Validators.required));
            this.rfqItemForm.addControl('maxPrice', new FormControl(null));
            this.rfqItemForm.addControl('payload', new FormControl('', Validators.required));
            if (this.isDeliveredProductRfq()) {
                this.rfqItemForm.addControl('site', new FormControl('', Validators.required));
            }
            if (this.isProductRfq()) {
                this.rfqItemForm.addControl('demandCenter', new FormControl(''));
            }
            this.setPayloadListener();
        }
        this.rfqItemForm
            .get('rfqDuration')
            .valueChanges.pipe(filter(function (value) { return !!value; }), takeUntil(this.destroy$))
            .subscribe(function (value) {
            _this.rfqStartingTime$.next(moment(value[0]).format('hh:mma'));
            _this.rfqDuration$.next(moment(value[1]).diff(moment(value[0]), 'hours') + "hrs");
        });
    };
    RfqItemFormComponent.prototype.setPayloadListener = function () {
        var _this = this;
        this.rfqItemForm
            .get('payload')
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe(function (value) {
            if (value) {
                _this.productSuggestions$.next(_this.productsList.filter(function (product) { return product.includes(value); }));
            }
            else {
                _this.productSuggestions$.next(_this.productsList);
            }
        });
    };
    RfqItemFormComponent.prototype.isFreightRfq = function () {
        return this.rfqType$.value === FREIGHT_RFQ_TYPE;
    };
    RfqItemFormComponent.prototype.isProductRfq = function () {
        return this.rfqType$.value === PRODUCT_RFQ_TYPE;
    };
    RfqItemFormComponent.prototype.isDeliveredProductRfq = function () {
        return this.rfqType$.value === DELIVERED_PRODUCT_RFQ_TYPE;
    };
    RfqItemFormComponent.prototype.moveToStepTwo = function () {
        this.networkActive$$.next(true);
        if (this.rfqItemForm.invalid) {
            this.rfqItemForm.markAllAsTouched();
            this.networkActive$$.next(false);
            this.snackBar.open('Please fill in all the fields', null, { duration: 2000 });
        }
        else {
            var files = this.rfqItemForm.controls['attachments'].value.map(function (file) { return file.id; });
            this.rfqItemForm.controls['attachments'].setValue(files);
            this.rfqFormService.saveFormData(this.rfqItemForm.value, 'ITEM_DATA');
            this.router.navigate(['/', 'lmo', 'marketplace', 'schedule-rfq', this.rfqType$.value, '2']);
            this.networkActive$$.next(false);
        }
    };
    RfqItemFormComponent.prototype.openDemandMapLocation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var mapDialog, locationData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        mapDialog = this.matdialog.open(RfqLocationFormComponent);
                        return [4 /*yield*/, mapDialog.afterClosed().toPromise()];
                    case 1:
                        locationData = _a.sent();
                        this.rfqItemForm.controls['demandCenter'].setValue(locationData);
                        return [2 /*return*/];
                }
            });
        });
    };
    RfqItemFormComponent.prototype.openMapLocation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var mapDialog, locationData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        mapDialog = this.matdialog.open(RfqLocationFormComponent);
                        return [4 /*yield*/, mapDialog.afterClosed().toPromise()];
                    case 1:
                        locationData = _a.sent();
                        this.rfqItemForm.controls['site'].setValue(locationData);
                        return [2 /*return*/];
                }
            });
        });
    };
    RfqItemFormComponent.prototype.openPickupMap = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var mapDialog, locationData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        mapDialog = this.matdialog.open(RfqLocationFormComponent);
                        return [4 /*yield*/, mapDialog.afterClosed().toPromise()];
                    case 1:
                        locationData = _a.sent();
                        this.rfqItemForm.controls['pickupSite'].setValue(locationData);
                        return [2 /*return*/];
                }
            });
        });
    };
    RfqItemFormComponent.prototype.openDropoffMap = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var mapDialog, locationData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        mapDialog = this.matdialog.open(RfqLocationFormComponent);
                        return [4 /*yield*/, mapDialog.afterClosed().toPromise()];
                    case 1:
                        locationData = _a.sent();
                        this.rfqItemForm.controls['dropoffSite'].setValue(locationData);
                        return [2 /*return*/];
                }
            });
        });
    };
    RfqItemFormComponent.prototype.onFileChange = function (event) {
        if (event.target.files && event.target.files.length) {
            this.fileToUpload = tslib_1.__spread(event.target.files);
            this.uploadFile();
        }
    };
    RfqItemFormComponent.prototype.removeFile = function (selectedFile) {
        var files = this.rfqItemForm.controls['attachments'].value.filter(function (file) {
            return file.id !== selectedFile.id;
        });
        this.rfqItemForm.controls['attachments'].setValue(files);
    };
    RfqItemFormComponent.prototype.uploadFile = function () {
        var _this = this;
        this.fileToUpload.forEach(function (file) {
            _this.fileUploading$$.next(true);
            _this.rfqFormService.uploadFile$(file).subscribe(function (result) {
                var files = _this.rfqItemForm.controls['attachments'].value;
                files.push(result);
                _this.rfqItemForm.controls['attachments'].setValue(files);
                _this.fileUploading$$.next(false);
                _this.snackbar.open(file.name + " uploaded Successfully", null, {
                    duration: 2000,
                });
            }, function (err) {
                _this.fileUploading$$.next(false);
                _this.snackbar.open(file.name + " upload failed", null, {
                    duration: 2000,
                });
            });
        });
    };
    RfqItemFormComponent.prototype.getMinReductionSuffix = function () {
        if (this.isFreightRfq()) {
            return "$ / MILE";
        }
        else {
            return "$ / " + (this.rfqUnit ? this.rfqUnit.abbreviation : 'unit');
        }
    };
    return RfqItemFormComponent;
}());
export { RfqItemFormComponent };
