import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, finalize, take, tap } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { Payload } from '~proto/payload/payload_pb';
import { StockQuantityRequest, UpdateStocksQuantityRequest } from '~proto/site/site_api_pb';
import { Site } from '~proto/site/site_pb';

interface FormValue {
  id: number;
  payload: Payload.AsObject;
  quantity: number;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-stock-update',
  styleUrls: ['./stock-update.component.scss'],
  templateUrl: './stock-update.component.html',
})
export class StockUpdateComponent implements OnInit {
  public networkActive$ = new BehaviorSubject<boolean>(false);
  public currentSite$: Observable<Site.AsObject>;
  public formArray = new FormArray([]);
  constructor(private siteService: JobSitesService, private router: Router, private activatedRoute: ActivatedRoute) {}

  public ngOnInit() {
    this.currentSite$ = this.siteService.currentSite$.pipe(
      filter((site) => !!site),
      take(1),
      tap((site) => {
        const stocks = [...site.stockReferencesList];
        stocks.sort((a, b) => a.payload.name.toLocaleLowerCase().localeCompare(b.payload.name.toLocaleLowerCase()));
        stocks.forEach((stock) => {
          const group = new FormGroup({
            id: new FormControl(stock.id),
            payload: new FormControl(stock.payload),
            quantity: new FormControl(stock.quantity),
          });
          this.formArray.push(group);
        });
      }),
    );
  }

  public updateCurrentStocks() {
    if (this.formArray.invalid) {
      return;
    }
    this.networkActive$.next(true);
    const value = this.formArray.value as FormValue[];
    const request = new UpdateStocksQuantityRequest();
    value.forEach((formValue) => {
      const update = new StockQuantityRequest();
      update.setStockId(formValue.id);
      update.setQuantity(formValue.quantity);
      request.addStockQuantityRequests(update);
    });
    this.siteService
      .updateStockQuanities$(request)
      .pipe(finalize(() => this.networkActive$.next(false)))
      .subscribe(() => {
        this.router.navigate(['../'], { relativeTo: this.activatedRoute });
      });
  }

  public trackByIndex(index: number) {
    return index;
  }
}
