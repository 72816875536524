import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { Site } from '~proto/site/site_pb';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-load-status-card',
  styleUrls: ['./load-status-card.component.scss'],
  templateUrl: './load-status-card.component.html',
})
export class LoadStatusCardComponent implements OnInit {
  public site$: Observable<Site.AsObject>;

  constructor(private jobsService: JobSitesService) {}

  public ngOnInit() {
    this.site$ = this.jobsService.currentSite$.pipe(filter((site) => !!site));
  }
}
