import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
var suffix = "HAULi\u2122";
var TitleService = /** @class */ (function () {
    function TitleService(title) {
        this.title = title;
    }
    TitleService.prototype.setTitle = function (title) {
        if (title && title !== '') {
            this.title.setTitle(title + " - " + suffix);
        }
        else {
            this.title.setTitle(suffix);
        }
    };
    TitleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TitleService_Factory() { return new TitleService(i0.ɵɵinject(i1.Title)); }, token: TitleService, providedIn: "root" });
    return TitleService;
}());
export { TitleService };
