import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, finalize, map, take } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { ReportSiteAvailabilityRequest } from '~proto/site/site_api_pb';
import { SiteAvailability } from '~proto/types/types_pb';

interface FormValue {
  availability: boolean;
  description: string;
  eta: number;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-job-report-issue',
  styleUrls: ['./job-report-issue.component.scss'],
  templateUrl: './job-report-issue.component.html',
})
export class JobReportIssueComponent implements OnInit {
  public networkActive$ = new BehaviorSubject<boolean>(false);
  public formGroup: FormGroup;
  public siteName$: Observable<string>;

  constructor(private siteService: JobSitesService, private fb: FormBuilder, private router: Router) {}

  private formValue(): FormValue {
    return this.formGroup.value;
  }

  public ngOnInit() {
    this.siteName$ = this.siteService.currentSite$.pipe(
      filter((site) => !!site),
      map((site) => site.name),
    );

    this.formGroup = this.fb.group({
      availability: [false, [Validators.required]],
      description: [null, [Validators.required]],
      eta: [null, [Validators.required]],
    });

    this.listenToReportFormChanges();

    this.siteService.currentSite$
      .pipe(
        filter((site) => !!site),
        take(1),
      )
      .subscribe((currentSite) => {
        if (currentSite.availability.available === SiteAvailability.SITE_AVAILABILITY_AVAILABLE) {
          this.formGroup.get('availability').setValue(false);
        } else {
          this.formGroup.get('availability').setValue(true);
        }
      });
  }

  public submitIssue() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    this.networkActive$.next(true);
    this.siteService.currentSite$.pipe(take(1)).subscribe((site) => {
      const formValue = this.formValue();
      const reportIssueRequest = new ReportSiteAvailabilityRequest();
      reportIssueRequest.setSiteId(site.id);
      reportIssueRequest.setDescription(formValue.description);
      reportIssueRequest.setAvailable(formValue.availability);
      const hoursToSeconds = formValue.eta * 3600; // 1 hour = 3600 seconds
      reportIssueRequest.setEtaSeconds(hoursToSeconds || 0);

      this.siteService
        .reportIssue$(reportIssueRequest)
        .pipe(
          finalize(() => {
            this.networkActive$.next(false);
          }),
        )
        .subscribe(() => {
          this.router.navigate(['/', 'lmo', 'jobs', site.id]);
        });
    });
  }

  private listenToReportFormChanges() {
    this.formGroup.get('availability').valueChanges.subscribe((available) => {
      if (available) {
        const description = this.formGroup.get('description');
        description.disable();
        description.clearValidators();
        const eta = this.formGroup.get('eta');
        eta.disable();
        eta.clearValidators();
      } else {
        const description = this.formGroup.get('description');
        description.enable();
        description.setValidators(Validators.required);
        const eta = this.formGroup.get('eta');
        eta.enable();
        eta.setValidators(Validators.required);
      }
    });
  }
}
