import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, TemplateRef, } from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import { once } from 'remeda';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, take, tap } from 'rxjs/operators';
import { ConfirmSaveDialogueComponent } from '~common/confirm-save-dialogue/confirm-save-dialogue.component';
import { CreateMileageCostsRequest, EditVendorContractRequest } from '~proto/contracts/contracts_api_pb';
import { NullableTime, VendorContractTravelCostType } from '~proto/types/types_pb';
import { recordToArray } from '~utilities/recordToArray';
import { trackById } from '~utilities/trackById';
var MAX_FILE_SIZE = 25 * 1024 * 1024;
var LmoTruckingContractsEditComponent = /** @class */ (function () {
    function LmoTruckingContractsEditComponent(fb, constantsService, contractService, router, matDialog, snackBar, fuelSurchargeService) {
        this.fb = fb;
        this.constantsService = constantsService;
        this.contractService = contractService;
        this.router = router;
        this.matDialog = matDialog;
        this.snackBar = snackBar;
        this.fuelSurchargeService = fuelSurchargeService;
        this.trackById = trackById;
        this.today = new Date();
        this.contractTypes = [];
        this.position = 'below';
        this.message = 'Require contract type with fuel surcharge';
        this.backHaulMessage = 'Require back haul option as Yes';
        this.effectiveDatMessage = 'Effective Date Already Choosen';
        this.toolTipDisabled = false;
        this.backHaulToolTipDisabled = false;
        this.isEffectiveDateTooltipDisabled = false;
        this.formSubmitted = false;
        this.VendorContractTravelCostType = VendorContractTravelCostType;
        this.lineHaulOptions = [
            {
                name: 'Hourly',
                value: VendorContractTravelCostType.PER_HOUR,
            },
            {
                name: 'Fixed Cost Mileage Bracket',
                value: VendorContractTravelCostType.MILEAGE_BRACKET,
            },
            {
                name: 'Per Mile',
                value: VendorContractTravelCostType.PER_MILE,
            },
            {
                name: 'Fixed Cost Point to Point',
                value: VendorContractTravelCostType.FIXED_POINT,
            },
        ];
        this.destroy$ = new Subject();
        this.networkActive$$ = new BehaviorSubject(false);
        this.uploadActive$$ = new BehaviorSubject(false);
    }
    Object.defineProperty(LmoTruckingContractsEditComponent.prototype, "mileageBrackets", {
        get: function () {
            return this.formGroup.get('mileageBrackets');
        },
        enumerable: true,
        configurable: true
    });
    LmoTruckingContractsEditComponent.prototype.onPopState = function (event) {
        this.checkEditForm();
    };
    LmoTruckingContractsEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.today.setHours(0, 0, 0, 0);
        this.formGroup = this.fb.group({
            backHaulChargePercent: [0, [Validators.max(100), Validators.min(0)]],
            contractName: [null, [Validators.required]],
            contractType: [null],
            costPerHour: [null, [Validators.required]],
            costPerMile: [null, [Validators.required]],
            deadheadCost: [0, [Validators.required]],
            deadheadFreeDistance: [0, [Validators.required]],
            defaultContract: [false, [Validators.required]],
            detentionHourly: [0, [Validators.required]],
            detentionMax: [0, [Validators.required]],
            effectiveDate: [null],
            expirationDate: [null],
            fileId: [0, [Validators.required]],
            freeTimeDropoff: [0, [Validators.required]],
            freeTimePickup: [0, [Validators.required]],
            fuelSurcharge: this.fb.control({ value: false, disabled: true }, [Validators.required]),
            isBackHaul: [false, [Validators.required]],
            isReturnBillable: [false, [Validators.required]],
            lineHaul: ['Fixed Cost Mileage Bracket', [Validators.required]],
            minPrice: [0, [Validators.required]],
            payloadTypes: [null],
            referenceNumber: [null],
        });
        this.formGroup.addControl('mileageBrackets', this.fb.array([]));
        this.payloadTypes = recordToArray(this.constantsService.payloadTypes).sort(function (a, b) { return a.name.localeCompare(b.name); });
        var onlyListenOnce = once(function () { return _this.listenToLineHaul(); });
        this.fuelContractList$ = this.fuelSurchargeService.fuelContracts$.pipe(map(function (fuelContracts) { return fuelContracts.contractsList.filter(function (fuelContract) { return fuelContract.id; }); }));
        this.contract$ = this.contractService.currentContract$.pipe(filter(function (contract) { return !!contract; }), tap(function (contract) {
            if (contract.contractType &&
                contract.contractType.fuelSurchargeContract &&
                contract.contractType.fuelSurchargeContract.contractTypesList &&
                contract.contractType.fuelSurchargeContract.contractTypesList.length > 0) {
                _this.formGroup.get('fuelSurcharge').enable();
                _this.toolTipDisabled = true;
            }
            if (!contract.isBackHaulEnabled) {
                _this.formGroup.get('backHaulChargePercent').disable();
                _this.backHaulToolTipDisabled = false;
            }
            else {
                _this.formGroup.get('backHaulChargePercent').enable();
                _this.backHaulToolTipDisabled = true;
            }
            if (contract.isEffective) {
                _this.formGroup.get('effectiveDate').disable();
                _this.isEffectiveDateTooltipDisabled = false;
            }
            else {
                _this.formGroup.get('effectiveDate').enable();
                _this.isEffectiveDateTooltipDisabled = true;
            }
            _this.formGroup.patchValue({
                backHaulChargePercent: contract.backHaulChargePercent,
                contractName: contract.name,
                contractType: contract.contractType ? contract.contractType.id : -1,
                costPerHour: contract.perHourTravelCost,
                costPerMile: contract.perMileTravelCost,
                deadheadCost: contract.deadheadCostPerMile,
                deadheadFreeDistance: contract.deadheadFreeMileage,
                defaultContract: contract.isDefault,
                detentionHourly: contract.hourlyDetentionCost,
                detentionMax: contract.maxDetentionPerOrder,
                effectiveDate: contract.effectiveTime && contract.effectiveTime.valid ? contract.effectiveTime.time : null,
                expirationDate: contract.expirationTime.valid ? contract.expirationTime.time : null,
                fileId: contract.fileId,
                freeTimeDropoff: contract.dropoffFreeTime,
                freeTimePickup: contract.pickupFreeTime,
                fuelSurcharge: contract.fuelSurcharge,
                isBackHaul: contract.isBackHaulEnabled,
                isReturnBillable: contract.isReturnTripBillable,
                lineHaul: contract.travelCostType,
                minPrice: contract.minimumCharge,
                payloadTypes: contract.payloadtypesList,
                referenceNumber: contract.referenceNumber,
            }, { emitEvent: false, onlySelf: true });
            if (contract.fileId > 0) {
                _this.contractService.loadFileDetails(contract.fileId);
            }
            _this.mileageBrackets.clear();
            contract.mileageCostsList.forEach(function (item) {
                var mileageBracket = _this.fb.group({
                    cost: [item.cost, [Validators.required]],
                    maxMiles: [item.upperBoundMiles, [Validators.required]],
                    minMiles: [item.lowerBoundMiles, [Validators.required]],
                });
                _this.mileageBrackets.push(mileageBracket);
            });
            _this.networkActive$$.next(false);
            onlyListenOnce();
            _this.contract = _this.formGroup.value;
        }));
        this.newContractTypeId = this.fb.control(null, [Validators.required]);
        this.newContractTypeForm = this.fb.control(null, [Validators.required]);
        this.newContractEdiCode = this.fb.control(null, [Validators.required]);
        this.newContractFuelMatrix = this.fb.control(null);
        this.contractService.contractTypes$.subscribe(function (contractTypes) {
            _this.contractTypes = contractTypes;
        });
        this.setupFormDisableAndEnableListeners();
    };
    LmoTruckingContractsEditComponent.prototype.setupFormDisableAndEnableListeners = function () {
        var _this = this;
        this.formGroup
            .get('contractType')
            .valueChanges.pipe(distinctUntilChanged())
            .pipe(debounceTime(1000))
            .subscribe(function (contractType) {
            var fuelContract = _this.contractTypes.find(function (type) {
                return type.id === contractType;
            });
            if (!contractType || !(fuelContract && fuelContract.fuelSurchargeContract)) {
                _this.formGroup.get('fuelSurcharge').setValue(false);
                _this.formGroup.get('fuelSurcharge').disable();
                _this.toolTipDisabled = false;
            }
            else {
                _this.formGroup.get('fuelSurcharge').enable();
                _this.toolTipDisabled = true;
            }
        });
        this.formGroup
            .get('isBackHaul')
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe(function (isBackHaul) {
            _this.formGroup.get('backHaulChargePercent').setValue(0);
            if (isBackHaul) {
                _this.formGroup.get('backHaulChargePercent').enable();
                _this.backHaulToolTipDisabled = true;
            }
            else {
                _this.formGroup.get('backHaulChargePercent').disable();
                _this.backHaulToolTipDisabled = false;
            }
        });
    };
    LmoTruckingContractsEditComponent.prototype.openNewContractTypeForm = function () {
        var _this = this;
        this.newContractTypeFormDialog = this.matDialog.open(this.newContractTypeFormModal, {
            maxWidth: '500px;',
        });
        this.newContractTypeFormDialog.afterOpened().subscribe(function () {
            _this.newContractTypeForm.reset();
            _this.newContractEdiCode.reset();
            _this.newContractTypeId.reset();
            _this.newContractFuelMatrix.reset();
        });
    };
    LmoTruckingContractsEditComponent.prototype.openEditContractTypeForm = function () {
        var _this = this;
        this.newContractTypeFormDialog = this.matDialog.open(this.newContractTypeFormModal, {
            maxWidth: '500px;',
        });
        this.newContractTypeFormDialog.afterOpened().subscribe(function () {
            var contractTypeId = _this.formGroup.get('contractType').value;
            var contractType = _this.contractTypes.find(function (type) {
                return type.id === contractTypeId;
            });
            _this.newContractTypeForm.setValue(contractType.name);
            _this.newContractEdiCode.setValue(contractType.ediCode);
            _this.newContractTypeId.setValue(contractTypeId);
            _this.newContractFuelMatrix.setValue(contractType && contractType.fuelSurchargeContract && contractType.fuelSurchargeContract.id);
        });
    };
    LmoTruckingContractsEditComponent.prototype.createContractType = function () {
        var _this = this;
        var contractTypeName = this.newContractTypeForm.value;
        var contractEdiCode = this.newContractEdiCode.value;
        var contractFuelId = this.newContractFuelMatrix.value;
        if (!contractFuelId) {
            this.formGroup.get('fuelSurcharge').setValue(false);
            this.formGroup.get('fuelSurcharge').disable();
        }
        else {
            this.formGroup.get('fuelSurcharge').enable();
        }
        if (this.newContractTypeId.value > 0) {
            return this.editContractType();
        }
        this.contractService.createContractType$(contractTypeName, contractEdiCode, contractFuelId).subscribe(function (contractType) {
            _this.formGroup.get('contractType').setValue(contractType.id);
            _this.newContractTypeFormDialog.close();
            _this.snackBar.open("Contract type is created", null, {
                duration: 2500,
            });
        }, function (err) {
            _this.snackBar.open('Something Went Wrong', null, {
                duration: 2500,
                panelClass: ['snackbar-error'],
            });
        });
    };
    LmoTruckingContractsEditComponent.prototype.editContractType = function () {
        var _this = this;
        var contractTypeId = this.newContractTypeId.value;
        var contractTypeName = this.newContractTypeForm.value;
        var contractEdiCode = this.newContractEdiCode.value;
        var contractFuelId = this.newContractFuelMatrix.value;
        this.contractService.editContractType$(contractTypeId, contractTypeName, contractEdiCode, contractFuelId).subscribe(function (contractType) {
            _this.formGroup.get('contractType').setValue(contractType.id);
            _this.newContractTypeFormDialog.close();
            _this.snackBar.open("Contract type is edited", null, {
                duration: 2500,
            });
        }, function (err) {
            _this.snackBar.open('Something Went Wrong', null, {
                duration: 2500,
                panelClass: ['snackbar-error'],
            });
        });
    };
    LmoTruckingContractsEditComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(null);
        this.destroy$.unsubscribe();
    };
    LmoTruckingContractsEditComponent.prototype.hasUnsavedData = function () {
        return this.formGroup.dirty && !this.formSubmitted;
    };
    LmoTruckingContractsEditComponent.prototype.editTruckingContract = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.formGroup.markAllAsTouched();
                        if (this.formGroup.invalid) {
                            return [2 /*return*/];
                        }
                        // Stop listening we are going to submit!
                        this.destroy$.next();
                        this.networkActive$$.next(true);
                        request = this.editRequest();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.contractService.editCurrentContract(request)];
                    case 2:
                        _a.sent();
                        this.snackBar.open("Updating Trucking Contract Successful", null, {
                            duration: 2000,
                        });
                        this.formSubmitted = true;
                        this.contractService.setSavedStatus('saved');
                        this.router.navigate(['/', 'lmo', 'trucking-contracts']);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        this.snackBar.open('Updating Trucking Contract Failed', null, {
                            duration: 2000,
                            panelClass: ['snackbar-error'],
                        });
                        this.contractService.setSavedStatus('nosaved');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    LmoTruckingContractsEditComponent.prototype.addBracket = function () {
        var brackets = this.formGroup.get('mileageBrackets');
        var lastBracket = brackets.value.length && brackets.value[brackets.value.length - 1];
        brackets.push(this.fb.group({
            cost: [null, [Validators.required]],
            maxMiles: [null, [Validators.required]],
            minMiles: [lastBracket ? lastBracket.maxMiles + 1 : null, [Validators.required]],
        }));
    };
    LmoTruckingContractsEditComponent.prototype.removeBracket = function (id) {
        var brackets = this.formGroup.get('mileageBrackets');
        brackets.removeAt(id);
    };
    LmoTruckingContractsEditComponent.prototype.archiveContract = function () {
        this.networkActive$$.next(true);
        this.contractService.toggleCurrentContractArchive();
    };
    LmoTruckingContractsEditComponent.prototype.compareById = function (a, b) {
        return a.id === b.id;
    };
    LmoTruckingContractsEditComponent.prototype.listenToLineHaul = function () {
        var _this = this;
        this.formGroup
            .get('lineHaul')
            .valueChanges.pipe(startWith(this.formGroup.get('lineHaul').value))
            .subscribe(function (value) {
            if (value === VendorContractTravelCostType.MILEAGE_BRACKET) {
                _this.toggleMileageBracket('enable');
                _this.toggleCostPerHour('disable');
                _this.toggleCostPerMile('disable');
                _this.toggleFileId('disable');
            }
            else if (value === VendorContractTravelCostType.PER_HOUR) {
                _this.toggleCostPerHour('enable');
                _this.toggleCostPerMile('disable');
                _this.toggleMileageBracket('disable');
                _this.toggleFileId('disable');
            }
            else if (value === VendorContractTravelCostType.PER_MILE) {
                _this.toggleCostPerMile('enable');
                _this.toggleCostPerHour('disable');
                _this.toggleMileageBracket('disable');
                _this.toggleFileId('disable');
            }
            else if (value === VendorContractTravelCostType.FIXED_POINT) {
                _this.toggleCostPerMile('disable');
                _this.toggleCostPerHour('disable');
                _this.toggleMileageBracket('disable');
                _this.toggleFileId('enable');
            }
        });
    };
    LmoTruckingContractsEditComponent.prototype.toggleMileageBracket = function (action) {
        this.formGroup.get('mileageBrackets')[action]();
    };
    LmoTruckingContractsEditComponent.prototype.toggleCostPerHour = function (action) {
        this.formGroup.get('costPerHour')[action]();
    };
    LmoTruckingContractsEditComponent.prototype.toggleCostPerMile = function (action) {
        this.formGroup.get('costPerMile')[action]();
    };
    LmoTruckingContractsEditComponent.prototype.editRequest = function () {
        var value = this.formGroup.value;
        var rawValue = this.formGroup.getRawValue();
        var request = new EditVendorContractRequest();
        var mask = new FieldMask();
        request.setName(value.contractName);
        mask.addPaths('name');
        request.setPayloadTypeIdsList(value.payloadTypes.map(function (type) { return type.id; }));
        mask.addPaths('payloadIds');
        request.setTravelCostType(value.lineHaul);
        mask.addPaths('travelCostType');
        request.setIsDefault(value.defaultContract);
        mask.addPaths('isDefault');
        request.setReferenceNumber(value.referenceNumber);
        mask.addPaths('referenceNumber');
        request.setIsReturnTripBillable(value.isReturnBillable);
        mask.addPaths('isReturnTripBillable');
        request.setBackHaulChargePercent(value.backHaulChargePercent);
        mask.addPaths('backHaulChargePercent');
        request.setIsBackHaulEnabled(value.isBackHaul);
        mask.addPaths('isBackHaulEnabled');
        var expirationDate = new NullableTime();
        expirationDate.setValid(value.expirationDate !== null);
        expirationDate.setTime(new Date(value.expirationDate).toISOString());
        request.setExpirationTime(expirationDate);
        var effectiveDate = new NullableTime();
        if (!!rawValue.effectiveDate) {
            effectiveDate.setValid(rawValue.effectiveDate !== null);
            effectiveDate.setTime(new Date(rawValue.effectiveDate).toISOString());
        }
        request.setEffectiveTime(effectiveDate);
        mask.addPaths('expirationTime');
        mask.addPaths('effectiveTime');
        if (value.contractType > 0) {
            request.setContractTypeId(value.contractType);
            mask.addPaths('vendorContractTypeId');
        }
        if (value.lineHaul === VendorContractTravelCostType.MILEAGE_BRACKET) {
            request.setDeadheadCostPerMile(value.deadheadCost);
            mask.addPaths('deadheadCostPerMile');
            request.setDeadheadFreeMileage(value.deadheadFreeDistance);
            mask.addPaths('deadheadFreeMileage');
            request.setDropoffFreeTime(value.freeTimeDropoff);
            mask.addPaths('dropoffFreeTime');
            request.setHourlyDetentionCost(value.detentionHourly);
            mask.addPaths('hourlyDetentionCost');
            request.setMaxDetentionPerOrder(value.detentionMax);
            mask.addPaths('maxDetentionPerOrder');
            request.setPickupFreeTime(value.freeTimePickup);
            mask.addPaths('pickupFreeTime');
            request.setMileageCostRequestsList(value.mileageBrackets.map(function (bracket) {
                var bracketRequest = new CreateMileageCostsRequest();
                bracketRequest.setCost(bracket.cost);
                bracketRequest.setLowerBoundMiles(bracket.minMiles);
                bracketRequest.setUpperBoundMiles(bracket.maxMiles);
                return bracketRequest;
            }));
            mask.addPaths('mileageCostRequests');
        }
        else if (value.lineHaul === VendorContractTravelCostType.PER_HOUR) {
            request.setPerHourTravelCost(value.costPerHour);
            mask.addPaths('perHourTravelCost');
            request.setMinimumCharge(value.minPrice);
            mask.addPaths('minimumCharge');
        }
        else if (value.lineHaul === VendorContractTravelCostType.PER_MILE) {
            request.setPerMileTravelCost(value.costPerMile);
            mask.addPaths('perMileTravelCost');
            request.setMinimumCharge(value.minPrice);
            mask.addPaths('minimumCharge');
            request.setDropoffFreeTime(value.freeTimeDropoff);
            mask.addPaths('dropoffFreeTime');
            request.setHourlyDetentionCost(value.detentionHourly);
            mask.addPaths('hourlyDetentionCost');
            request.setMaxDetentionPerOrder(value.detentionMax);
            mask.addPaths('maxDetentionPerOrder');
            request.setPickupFreeTime(value.freeTimePickup);
            mask.addPaths('pickupFreeTime');
        }
        else if (value.lineHaul === VendorContractTravelCostType.FIXED_POINT) {
            request.setFixCostFileId(value.fileId);
            mask.addPaths('fixPointFileId');
        }
        request.setFuelSurcharge(value.fuelSurcharge);
        mask.addPaths('isFuelSurchargeApplies');
        request.setMask(mask);
        return request;
    };
    LmoTruckingContractsEditComponent.prototype.checkEditForm = function () {
        var _this = this;
        if (this.checkForFormChanges()) {
            this.contractService.setSavedStatus('nosaved');
            var dialog = this.matDialog.open(ConfirmSaveDialogueComponent);
            dialog.afterClosed().subscribe(function (result) {
                if (result === 'save') {
                    _this.editTruckingContract();
                }
                else if (result === 'nosave') {
                    _this.contractService.setSavedStatus('saved');
                    _this.router.navigateByUrl('/lmo/trucking-contracts/manage');
                }
            });
        }
        else {
            this.contractService.setSavedStatus('saved');
            this.router.navigateByUrl('/lmo/trucking-contracts/manage');
        }
    };
    LmoTruckingContractsEditComponent.prototype.checkForFormChanges = function () {
        var result = false;
        var formGroup = this.formGroup.controls;
        var contractKeys = Object.keys(this.contract);
        for (var i = 0; i < contractKeys.length; i++) {
            if (contractKeys[i] === 'payloadTypes' &&
                this.comparePayloadTypes(this.contract[contractKeys[i]], formGroup[contractKeys[i]].value)) {
                result = true;
                break;
            }
            else if (contractKeys[i] === 'mileageBrackets' &&
                this.compareMileageBrackets(this.contract[contractKeys[i]], formGroup[contractKeys[i]].value)) {
                result = true;
                break;
            }
            else if (this.contract[contractKeys[i]] !== this.formGroup.controls[contractKeys[i]].value &&
                contractKeys[i] !== 'mileageBrackets' &&
                contractKeys[i] !== 'payloadTypes') {
                result = true;
                break;
            }
        }
        return result;
    };
    LmoTruckingContractsEditComponent.prototype.compareMileageBrackets = function (contractMileageBrackets, formMileageBrackets) {
        var result = false;
        if (contractMileageBrackets.length !== formMileageBrackets.length) {
            return true;
        }
        contractMileageBrackets.forEach(function (val, idx) {
            if (val.cost !== formMileageBrackets[idx].cost ||
                val.maxMiles !== formMileageBrackets[idx].maxMiles ||
                val.minMiles !== formMileageBrackets[idx].minMiles) {
                result = true;
            }
        });
        return result;
    };
    LmoTruckingContractsEditComponent.prototype.comparePayloadTypes = function (contractPayloadTypes, formPayloadTypes) {
        var count = 0;
        if (contractPayloadTypes.length !== formPayloadTypes.length) {
            return true;
        }
        contractPayloadTypes.forEach(function (val) {
            formPayloadTypes.forEach(function (formVal) {
                if (val.id === formVal.id) {
                    count++;
                }
            });
        });
        return count !== formPayloadTypes.length;
    };
    LmoTruckingContractsEditComponent.prototype.trackLineHaulOptions = function (_index, option) {
        return option.value;
    };
    LmoTruckingContractsEditComponent.prototype.trackByMemoryReference = function (index, item) {
        return item;
    };
    LmoTruckingContractsEditComponent.prototype.closeTab = function () {
        this.newContractTypeFormDialog.close();
        this.newContractTypeFormDialog = null;
    };
    LmoTruckingContractsEditComponent.prototype.showDetention = function () {
        return (this.formGroup.get('lineHaul').value === VendorContractTravelCostType.MILEAGE_BRACKET ||
            this.formGroup.get('lineHaul').value === VendorContractTravelCostType.PER_MILE);
    };
    LmoTruckingContractsEditComponent.prototype.showDeadhead = function () {
        return this.formGroup.get('lineHaul').value === VendorContractTravelCostType.MILEAGE_BRACKET;
    };
    LmoTruckingContractsEditComponent.prototype.showMileageBracket = function () {
        return this.formGroup.get('lineHaul').value === VendorContractTravelCostType.MILEAGE_BRACKET;
    };
    LmoTruckingContractsEditComponent.prototype.showMinPrice = function () {
        return (this.formGroup.get('lineHaul').value === VendorContractTravelCostType.PER_HOUR ||
            this.formGroup.get('lineHaul').value === VendorContractTravelCostType.PER_MILE);
    };
    LmoTruckingContractsEditComponent.prototype.showFileUpload = function () {
        return this.formGroup.get('lineHaul').value === VendorContractTravelCostType.FIXED_POINT;
    };
    LmoTruckingContractsEditComponent.prototype.toggleFileId = function (action) {
        this.formGroup.get('fileId')[action]();
    };
    LmoTruckingContractsEditComponent.prototype.onFileChange = function (event) {
        if (event.target.files && event.target.files.length) {
            var _a = tslib_1.__read(event.target.files, 1), file = _a[0];
            var inputSelect = document.getElementsByName('file-name');
            inputSelect[0].innerHTML = file.name;
            if (file.size > MAX_FILE_SIZE) {
                this.snackBar.open('File Size cannot be greater than 25 MB', null, {
                    duration: 2500,
                });
            }
            else {
                event.target.name === 'mileage-brackets' ? this.uploadMileageBracketsFile(file) : this.uploadP2PCostFile(file);
            }
        }
    };
    LmoTruckingContractsEditComponent.prototype.uploadP2PCostFile = function (fileToUpload) {
        var _this = this;
        this.uploadActive$$.next(true);
        this.contractService.uploadP2PCostFile$(fileToUpload).subscribe(function (response) {
            _this.uploadActive$$.next(false);
            if (response.status) {
                _this.formGroup.get('fileId').setValue(response.fileId);
                _this.snackBar.open('File uploaded successfully', null, {
                    duration: 2000,
                });
            }
            else {
                _this.snackBar.open(response.message, null, {
                    duration: 20000,
                });
            }
        }, function (err) {
            _this.uploadActive$$.next(false);
            _this.snackBar.open('Error: ' + err.Error.Message, null, {
                duration: 20000,
            });
        });
    };
    LmoTruckingContractsEditComponent.prototype.downloadP2PCostFile = function () {
        var _this = this;
        var fileId = this.formGroup.get('fileId').value;
        this.contractService
            .downloadP2PCostFile$(fileId)
            .pipe(take(1))
            .subscribe(function (response) {
            window.location.assign(response.toObject().url);
        }, function (err) {
            _this.snackBar.open('Error:' + err.toObject().url, null, {
                duration: 10000,
            });
        });
    };
    LmoTruckingContractsEditComponent.prototype.uploadMileageBracketsFile = function (fileToUpload) {
        var _this = this;
        this.uploadActive$$.next(true);
        this.contractService.uploadMileageBracketsFile$(fileToUpload).subscribe(function (response) {
            _this.uploadActive$$.next(false);
            if (response.length > 0) {
                _this.mileageBrackets.controls = [];
                response.forEach(function (item) {
                    var mileageBracket = _this.fb.group({
                        cost: [item.cost, [Validators.required]],
                        maxMiles: [item.upperBoundMiles, [Validators.required]],
                        minMiles: [item.lowerBoundMiles, [Validators.required]],
                    });
                    _this.mileageBrackets.push(mileageBracket);
                });
                _this.snackBar.open('File uploaded successfully', null, {
                    duration: 2000,
                });
            }
            else {
                _this.snackBar.open(response.message, null, {
                    duration: 10000,
                });
            }
        }, function (err) {
            _this.uploadActive$$.next(false);
            _this.snackBar.open('Error: ' + err.error, null, {
                duration: 10000,
            });
        });
    };
    LmoTruckingContractsEditComponent.prototype.clearExpirationDate = function () {
        this.formGroup.get('expirationDate').setValue(null);
    };
    LmoTruckingContractsEditComponent.prototype.clearFffectiveDate = function () {
        this.formGroup.get('effectiveDate').setValue(null);
    };
    return LmoTruckingContractsEditComponent;
}());
export { LmoTruckingContractsEditComponent };
