import * as tslib_1 from "tslib";
import { distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { UserDetailsForIntercomRequest } from '~proto/user/user_api_pb';
import { UserAPI } from '~proto/user/user_api_pb_service';
import * as i0 from "@angular/core";
import * as i1 from "./router-state.service";
import * as i2 from "./auth.service";
import * as i3 from "./grpc.service";
var IntercomService = /** @class */ (function () {
    function IntercomService(routerState, authService, grpcService) {
        this.routerState = routerState;
        this.authService = authService;
        this.grpcService = grpcService;
        this.listenForLoggedIn();
        this.listenToRouterStateChanges();
    }
    IntercomService.prototype.show = function () {
        if (hasIntercom()) {
            Intercom('show');
        }
    };
    IntercomService.prototype.showMessages = function () {
        if (hasIntercom()) {
            Intercom('showMessages');
        }
    };
    IntercomService.prototype.showNewMessage = function () {
        if (hasIntercom()) {
            Intercom('showNewMessage');
        }
    };
    IntercomService.prototype.setIsUsingNewBillingPortal = function (isUsing) {
        if (hasIntercom()) {
            Intercom('update', { using_new_billing: isUsing });
        }
    };
    IntercomService.prototype.boot = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, intercomDetails, user, intercomSettings;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = new UserDetailsForIntercomRequest();
                        return [4 /*yield*/, this.grpcService.invoke$(UserAPI.UserDetailsForIntercom, request).toPromise()];
                    case 1:
                        intercomDetails = _a.sent();
                        user = this.authService.getUserFromCache();
                        if (user && user.id && hasIntercom()) {
                            intercomSettings = {
                                app_id: environment.environment === 'prod' ? 'bijgcgew' : 'v2sebl2g',
                                company: null,
                                created_at: new Date(intercomDetails.getUserDetails().getUserCreateTime()).getTime() / 1000,
                                email: user.email,
                                name: user.name,
                                product: 'HAULi',
                                user_id: user.id,
                            };
                            intercomSettings.company = {
                                id: intercomDetails.getUserDetails().getCompanyId(),
                                name: intercomDetails.getUserDetails().getCompanyName(),
                                type: intercomDetails.getUserDetails().getCompanyType(),
                            };
                            Intercom('boot', intercomSettings);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    IntercomService.prototype.shutdown = function () {
        if (hasIntercom()) {
            Intercom('shutdown');
        }
    };
    IntercomService.prototype.listenToRouterStateChanges = function () {
        this.routerState.routerState$
            .pipe(map(function (state) { return state.url; }), distinctUntilChanged())
            .subscribe(function () {
            if (hasIntercom()) {
                Intercom('update');
            }
        });
    };
    IntercomService.prototype.listenForLoggedIn = function () {
        var _this = this;
        this.authService.isLoggedIn$.pipe(distinctUntilChanged()).subscribe(function (isLoggedIn) {
            if (isLoggedIn) {
                _this.boot();
            }
            else {
                _this.shutdown();
            }
        });
    };
    IntercomService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IntercomService_Factory() { return new IntercomService(i0.ɵɵinject(i1.RouterStateService), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.GrpcService)); }, token: IntercomService, providedIn: "root" });
    return IntercomService;
}());
export { IntercomService };
function hasIntercom() {
    return typeof Intercom !== 'undefined';
}
