import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { SiteOrderETAs } from '~proto/order/order_pb';

interface ChartValue {
  name: number;
  value: number;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-lmo-loads-arriving-card',
  styleUrls: ['./lmo-loads-arriving-card.component.scss'],
  templateUrl: './lmo-loads-arriving-card.component.html',
})
export class LmoLoadsArrivingCardComponent implements OnInit {
  public chartOrders$: Observable<ChartValue[]>;
  public innerWidth: any;

  // options for chart
  public showXAxis = true;
  public showYAxis = false;
  public showLegend = false;
  public showXAxisLabel = false;
  public showYAxisLabel = false;
  public showDataLabel = false;
  public showGridLines = true;
  public noBarWhenZero = false;
  public tooltipDisabled = false;
  public customColors = [];

  constructor(private jobsService: JobSitesService) {}

  public ngOnInit() {
    this.chartOrders$ = this.jobsService.siteOrderEtas$.pipe(
      filter((etas) => !!etas),
      map((etas) => this.filterOrdersByHour(etas)),
    );

    this.innerWidth = window.innerWidth;
    // hide tooltip and show static data label on mobile devices - looks much better
    if (this.innerWidth < 767) {
      this.showDataLabel = true;
      this.tooltipDisabled = true;
    }
  }

  private filterOrdersByHour(etas: SiteOrderETAs.AsObject) {
    const jsonForChart = [];
    // get next 12 hours, initialize value to 0 arriving orders
    for (let i = 1; i <= 12; i++) {
      jsonForChart.push({
        name: moment()
          .add(i, 'hours')
          .format('h A'),
        value: 0,
      });
    }

    // etas.orderEtasMap is a map of orderIDs -> unix arrival times
    etas.orderEtasMap.forEach((eta: [number, number]) => {
      // find right hour value for current task based on eta - unix time
      const hoursDuration = moment(eta[1] * 1000).diff(moment(), 'hours');
      if (hoursDuration < 12) {
        jsonForChart[hoursDuration].value++;
      }
    });

    // generate custom colors for chart based on order value
    jsonForChart.forEach((result) => {
      switch (result.value) {
        case 1:
          this.customColors.push({
            name: result.name,
            value: 'rgba(45, 45, 46, 0.25)',
          });
          break;
        case 2:
          this.customColors.push({
            name: result.name,
            value: 'rgba(45, 45, 46, 0.4)',
          });
          break;
        case 3:
          this.customColors.push({
            name: result.name,
            value: 'rgba(45, 45, 46, 0.6)',
          });
          break;
        case 4:
          this.customColors.push({
            name: result.name,
            value: 'rgba(45, 45, 46, 0.8)',
          });
          break;
        case 5:
          this.customColors.push({
            name: result.name,
            value: 'rgba(45, 45, 46, 0.9)',
          });
          break;
        default:
          this.customColors.push({
            name: result.name,
            value: '#2D2D2E',
          });
      }
    });

    return jsonForChart;
  }
}
