import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Bidder } from '~proto/marketplace/rfq_api_pb';

@Component({
  selector: 'ct-rfq-add-bidder-dialog',
  styleUrls: ['./rfq-add-bidder-dialog.component.scss'],
  templateUrl: './rfq-add-bidder-dialog.component.html',
})
export class RfqAddBidderDialogComponent implements OnInit {
  public addVendorForm: FormGroup;
  constructor(
    private matDialogRef: MatDialogRef<RfqAddBidderDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {}

  public ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.addVendorForm = this.fb.group({
      account: [null, [Validators.required]],
      email: [null, [Validators.required]],
      id: [-1, []],
      name: [null, [Validators.required]],
    });

    if (this.data.includes('@')) {
      this.addVendorForm.controls['email'].setValue(this.data);
    } else {
      this.addVendorForm.controls['name'].setValue(this.data);
    }
  }

  public addVendor() {
    if (this.addVendorForm.invalid) {
      this.addVendorForm.markAllAsTouched();
    } else {
      const bidder = new Bidder();
      bidder.setAccount(this.addVendorForm.controls['account'].value);
      bidder.setEmail(this.addVendorForm.controls['email'].value);
      bidder.setId(this.addVendorForm.controls['id'].value);
      bidder.setName(this.addVendorForm.controls['name'].value);
      this.matDialogRef.close(bidder);
    }
  }
}
