import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { SmartDropdownComponent } from '~common/smart-dropdown/smart-dropdown.component';
import { CreateTruckRequest } from '~proto/truck/truck_api_pb';
import { Truck } from '~proto/truck/truck_pb';
import { fuse } from '~utilities/fuse';
import { TruckAndTrailerService } from '../../services/truck-and-trailer.service';

const searchOptions: Fuse.FuseOptions<Truck.AsObject> = {
  distance: 100,
  keys: ['name'],
  location: 0,
  maxPatternLength: 16,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.1,
};

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-create-order-truck-selector',
  styleUrls: ['./create-order-truck-selector.component.scss'],
  templateUrl: './create-order-truck-selector.component.html',
})
export class CreateOrderTruckSelectorComponent implements OnInit {
  private topResult: Truck.AsObject;
  @Output() public selected = new EventEmitter<{ truck: Truck.AsObject }>();
  @ViewChild('smartDropdown', { static: true }) private smartDropdown: SmartDropdownComponent;
  @Input() public truck: Truck.AsObject;
  @Input() public invalid = false;
  public showCreateTruckButton$: Observable<boolean>;
  public textChanges$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public trucks$: Observable<Truck.AsObject[]>;
  constructor(private truckService: TruckAndTrailerService) {}

  public ngOnInit() {
    this.trucks$ = combineLatest([
      this.truckService.trucks$.pipe(map(fuse(searchOptions))),
      this.textChanges$$.pipe(
        debounceTime(100),
        startWith(''),
      ),
    ]).pipe(
      map(([trucks, text]) => {
        if (!trucks) {
          return [];
        }
        if (!text || text === '') {
          this.topResult = trucks.data.length ? trucks.data[0] : null;
          return trucks.data;
        }
        const searchResults = trucks.fuse.search(text);
        this.topResult = searchResults.length ? searchResults[0] : null;
        return searchResults;
      }),
    );

    this.showCreateTruckButton$ = combineLatest([
      this.truckService.trucks$,
      this.textChanges$$.pipe(startWith(null as string)),
    ]).pipe(
      map(([trucks, text]) => {
        if (!text || !text.length) {
          return false;
        }

        return trucks.every((truck) => truck.name !== text);
      }),
    );
  }

  public inputTextChange(event: string) {
    this.textChanges$$.next(event.trim());
  }

  public selectTruck(truck: Truck.AsObject) {
    this.selected.emit({ truck });
    this.smartDropdown.close();
  }

  public addTruck() {
    const name = this.textChanges$$.value;
    if (!name || name === '') {
      return;
    }
    const request = new CreateTruckRequest();
    request.setName(name);
    this.truckService.addTruck$(request).subscribe((truck) => {
      this.selectTruck(truck);
    });
  }

  public enterKeyPressed() {
    if (this.topResult) {
      this.selectTruck(this.topResult);
    } else {
      this.addTruck();
    }
  }
}
