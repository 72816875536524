import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SiteAvailableDuration } from '~proto/site/site_pb';
var SelectBusinessHoursComponent = /** @class */ (function () {
    function SelectBusinessHoursComponent(matDialogRef, data, fb, snackbar) {
        this.matDialogRef = matDialogRef;
        this.data = data;
        this.fb = fb;
        this.snackbar = snackbar;
        this.allowedTimes = [
            '12:00 AM',
            '12:30 AM',
            '1:00 AM',
            '1:30 AM',
            '2:00 AM',
            '2:30 AM',
            '3:00 AM',
            '3:30 AM',
            '4:00 AM',
            '4:30 AM',
            '5:00 AM',
            '5:30 AM',
            '6:00 AM',
            '6:30 AM',
            '7:00 AM',
            '7:30 AM',
            '8:00 AM',
            '8:30 AM',
            '9:00 AM',
            '9:30 AM',
            '10:00 AM',
            '10:30 AM',
            '11:00 AM',
            '11:30 AM',
            '12:00 PM',
            '12:30 PM',
            '1:00 PM',
            '1:30 PM',
            '2:00 PM',
            '2:30 PM',
            '3:00 PM',
            '3:30 PM',
            '4:00 PM',
            '4:30 PM',
            '5:00 PM',
            '5:30 PM',
            '6:00 PM',
            '6:30 PM',
            '7:00 PM',
            '7:30 PM',
            '8:00 PM',
            '8:30 PM',
            '9:00 PM',
            '9:30 PM',
            '10:00 PM',
            '10:30 PM',
            '11:00 PM',
            '11:30 PM',
        ];
        this.durationHours = [];
        this.weekMap = {
            0: 'MONDAY',
            1: 'TUESDAY',
            2: 'WEDNESDAY',
            3: 'THURSDAY',
            4: 'FRIDAY',
            5: 'SATURDAY',
            6: 'SUNDAY',
        };
        this.businessHours = data;
        for (var i = 0; i < 48; i++) {
            this.durationHours.push({
                time: this.allowedTimes[i],
                weight: i,
            });
        }
    }
    Object.defineProperty(SelectBusinessHoursComponent.prototype, "daysArray", {
        get: function () {
            return this.weekForm.get('days');
        },
        enumerable: true,
        configurable: true
    });
    SelectBusinessHoursComponent.prototype.ngOnInit = function () {
        this.weekForm = this.fb.group({
            days: this.fb.array(this.buildForm()),
        });
    };
    SelectBusinessHoursComponent.prototype.trackDurationHours = function (_index, dh) {
        return dh.time;
    };
    SelectBusinessHoursComponent.prototype.trackByIndex = function (index, control) {
        return index;
    };
    SelectBusinessHoursComponent.prototype.buildForm = function () {
        var days = [];
        for (var i = 0; i < 7; i++) {
            var existingDay = this.checkForExistingDay(i);
            days.push(this.fb.group({
                closingTime: [existingDay ? this.setTime(existingDay.closingTime) : null, []],
                day: [!!existingDay, []],
                openingTime: [existingDay ? this.setTime(existingDay.openingTime) : null, []],
            }));
        }
        return days;
    };
    SelectBusinessHoursComponent.prototype.setTime = function (time) {
        var index = this.allowedTimes.findIndex(function (allowedTime) { return allowedTime === time; });
        return {
            time: time,
            weight: index,
        };
    };
    SelectBusinessHoursComponent.prototype.checkForExistingDay = function (index) {
        if (this.businessHours.length) {
            var existingDay = this.businessHours.find(function (businessDay) { return businessDay.getDay() === index + 1; }); // Proto Starts from Invalid as 0, Monday as 1 and so on...
            return (existingDay && existingDay.toObject()) || null;
        }
        return null;
    };
    SelectBusinessHoursComponent.prototype.cancel = function () {
        this.matDialogRef.close(null);
    };
    SelectBusinessHoursComponent.prototype.checkWeekValues = function () {
        var invalidReason = '';
        for (var i = 0; i < this.daysArray.controls.length; i++) {
            if (this.daysArray.controls[i].value.day) {
                if (!this.daysArray.controls[i].value.openingTime || !this.daysArray.controls[i].value.closingTime) {
                    invalidReason = 'Please fill in all the fields';
                    break;
                }
                else if (this.daysArray.controls[i].value.openingTime.weight >= this.daysArray.controls[i].value.closingTime.weight) {
                    invalidReason = 'Opening time cannot be greater than closing time';
                    break;
                }
            }
        }
        return invalidReason;
    };
    SelectBusinessHoursComponent.prototype.compareByTime = function (a, b) {
        return a.time === b.time;
    };
    SelectBusinessHoursComponent.prototype.continue = function () {
        if (this.checkWeekValues() === '') {
            var openDays_1 = [];
            this.daysArray.value.forEach(function (dayValue, i) {
                if (dayValue && dayValue.day) {
                    var availableDuration = new SiteAvailableDuration();
                    availableDuration.setDay(i + 1);
                    availableDuration.setOpeningTime(dayValue.openingTime.time);
                    availableDuration.setClosingTime(dayValue.closingTime.time);
                    openDays_1.push(availableDuration);
                }
            });
            this.matDialogRef.close(openDays_1);
        }
        else {
            this.snackbar.open(this.checkWeekValues(), null, {
                duration: 2500,
                panelClass: ['snackbar-error'],
            });
        }
    };
    return SelectBusinessHoursComponent;
}());
export { SelectBusinessHoursComponent };
