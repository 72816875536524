import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
var SigninWithCustomTokenComponent = /** @class */ (function () {
    function SigninWithCustomTokenComponent(authService, route, snackbar) {
        var _this = this;
        this.authService = authService;
        this.route = route;
        this.snackbar = snackbar;
        this.working$ = new BehaviorSubject(true);
        this.authService.idToken$.pipe(filter(function (token) { return !!token; })).subscribe(function (token) {
            if (token) {
                _this.authService.redirectIfLoggedIn();
            }
        });
    }
    SigninWithCustomTokenComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        token = this.route.snapshot.paramMap.get('jwt');
                        if (!token) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.authService.loginWithCustomToken(token)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        this.snackbar.open(error_1.message, null, {
                            duration: 4000,
                            panelClass: 'snackbar-error',
                        });
                        this.working$.next(false);
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        this.snackbar.open('Invalid Token, please login again', null, {
                            duration: 4000,
                            panelClass: 'snackbar-error',
                        });
                        this.working$.next(false);
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    return SigninWithCustomTokenComponent;
}());
export { SigninWithCustomTokenComponent };
