// package: cbv1
// file: proto/cybertron/user/user_api.proto

var proto_cybertron_user_user_api_pb = require("../../../proto/cybertron/user/user_api_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var UserAPI = (function () {
  function UserAPI() {}
  UserAPI.serviceName = "cbv1.UserAPI";
  return UserAPI;
}());

UserAPI.CreateDriver = {
  methodName: "CreateDriver",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.CreateDriverRequest,
  responseType: proto_cybertron_user_user_api_pb.CreateDriverResponse
};

UserAPI.UnlinkDriverAndTruckingVendor = {
  methodName: "UnlinkDriverAndTruckingVendor",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.UnlinkDriverAndTruckingVendorRequest,
  responseType: proto_cybertron_user_user_api_pb.UnlinkDriverAndTruckingVendorResponse
};

UserAPI.CreateEmailUser = {
  methodName: "CreateEmailUser",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.CreateEmailUserRequest,
  responseType: proto_cybertron_user_user_api_pb.CreateEmailUserResponse
};

UserAPI.BatchWaypoint = {
  methodName: "BatchWaypoint",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.CreateWaypointsRequest,
  responseType: proto_cybertron_user_user_api_pb.CreateWaypointsResponse
};

UserAPI.TransferWaypoint = {
  methodName: "TransferWaypoint",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.TransferWaypointRequest,
  responseType: proto_cybertron_user_user_api_pb.TransferWaypointResponse
};

UserAPI.ListUsers = {
  methodName: "ListUsers",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.ListUsersRequest,
  responseType: proto_cybertron_user_user_api_pb.ListUsersResponse
};

UserAPI.ListDrivers = {
  methodName: "ListDrivers",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.ListDriversRequest,
  responseType: proto_cybertron_user_user_api_pb.ListDriversResponse
};

UserAPI.SearchDrivers = {
  methodName: "SearchDrivers",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.SearchDriversRequest,
  responseType: proto_cybertron_user_user_api_pb.SearchDriversResponse
};

UserAPI.UpdateEmailUser = {
  methodName: "UpdateEmailUser",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.UpdateEmailUserRequest,
  responseType: proto_cybertron_user_user_api_pb.UpdateEmailUserResponse
};

UserAPI.UpdateUserClaims = {
  methodName: "UpdateUserClaims",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.UpdateUserClaimsRequest,
  responseType: proto_cybertron_user_user_api_pb.UpdateUserResponse
};

UserAPI.MigrateClaims = {
  methodName: "MigrateClaims",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.MigrateClaimsRequest,
  responseType: proto_cybertron_user_user_api_pb.MigrateClaimsResponse
};

UserAPI.UpdateDriverDetails = {
  methodName: "UpdateDriverDetails",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.UpdateDriverDetailsRequest,
  responseType: proto_cybertron_user_user_api_pb.UpdateDriverDetailsResponse
};

UserAPI.UserDetailsForIntercom = {
  methodName: "UserDetailsForIntercom",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.UserDetailsForIntercomRequest,
  responseType: proto_cybertron_user_user_api_pb.UserDetailsForIntercomResponse
};

UserAPI.GetUserHOS = {
  methodName: "GetUserHOS",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.GetUserHOSRequest,
  responseType: proto_cybertron_user_user_api_pb.GetUserHOSResponse
};

UserAPI.UpdateHOS = {
  methodName: "UpdateHOS",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.UpdateHOSRequest,
  responseType: proto_cybertron_user_user_api_pb.UpdateHOSResponse
};

UserAPI.CreateEmailUserV2 = {
  methodName: "CreateEmailUserV2",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.CreateEmailUserV2Request,
  responseType: proto_cybertron_user_user_api_pb.CreateUserV2Response
};

UserAPI.UpdateEmailUserV2 = {
  methodName: "UpdateEmailUserV2",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.UpdateEmailUserV2Request,
  responseType: proto_cybertron_user_user_api_pb.UpdateUserV2Response
};

UserAPI.UpdateEmailUserPermissions = {
  methodName: "UpdateEmailUserPermissions",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.UpdateEmailUserPermissionsRequest,
  responseType: proto_cybertron_user_user_api_pb.UpdateEmailUserPermissionsResponse
};

UserAPI.ListUsersV2 = {
  methodName: "ListUsersV2",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.ListUsersV2Request,
  responseType: proto_cybertron_user_user_api_pb.ListUsersV2Response
};

UserAPI.DeleteUser = {
  methodName: "DeleteUser",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.DeleteUserRequest,
  responseType: proto_cybertron_user_user_api_pb.DeleteUserResponse
};

UserAPI.DeleteHauliUser = {
  methodName: "DeleteHauliUser",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.DeleteUserRequest,
  responseType: proto_cybertron_user_user_api_pb.DeleteUserResponse
};

UserAPI.ResetPasswordOnFirstTimeLogin = {
  methodName: "ResetPasswordOnFirstTimeLogin",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.ResetPasswordOnFirstTimeLoginRequest,
  responseType: proto_cybertron_user_user_api_pb.ResetPasswordOnFirstTimeLoginResponse
};

UserAPI.TriggerUserAction = {
  methodName: "TriggerUserAction",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.TriggerUserActionRequest,
  responseType: proto_cybertron_user_user_api_pb.TriggerUserActionResponse
};

UserAPI.CanCrudUser = {
  methodName: "CanCrudUser",
  service: UserAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_user_user_api_pb.CanCrudUserRequest,
  responseType: proto_cybertron_user_user_api_pb.CanCrudUserResponse
};

exports.UserAPI = UserAPI;

function UserAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

UserAPIClient.prototype.createDriver = function createDriver(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.CreateDriver, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.unlinkDriverAndTruckingVendor = function unlinkDriverAndTruckingVendor(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.UnlinkDriverAndTruckingVendor, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.createEmailUser = function createEmailUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.CreateEmailUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.batchWaypoint = function batchWaypoint(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.BatchWaypoint, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.transferWaypoint = function transferWaypoint(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.TransferWaypoint, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.listUsers = function listUsers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.ListUsers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.listDrivers = function listDrivers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.ListDrivers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.searchDrivers = function searchDrivers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.SearchDrivers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.updateEmailUser = function updateEmailUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.UpdateEmailUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.updateUserClaims = function updateUserClaims(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.UpdateUserClaims, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.migrateClaims = function migrateClaims(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.MigrateClaims, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.updateDriverDetails = function updateDriverDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.UpdateDriverDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.userDetailsForIntercom = function userDetailsForIntercom(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.UserDetailsForIntercom, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.getUserHOS = function getUserHOS(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.GetUserHOS, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.updateHOS = function updateHOS(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.UpdateHOS, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.createEmailUserV2 = function createEmailUserV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.CreateEmailUserV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.updateEmailUserV2 = function updateEmailUserV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.UpdateEmailUserV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.updateEmailUserPermissions = function updateEmailUserPermissions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.UpdateEmailUserPermissions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.listUsersV2 = function listUsersV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.ListUsersV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.deleteUser = function deleteUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.DeleteUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.deleteHauliUser = function deleteHauliUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.DeleteHauliUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.resetPasswordOnFirstTimeLogin = function resetPasswordOnFirstTimeLogin(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.ResetPasswordOnFirstTimeLogin, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.triggerUserAction = function triggerUserAction(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.TriggerUserAction, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserAPIClient.prototype.canCrudUser = function canCrudUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserAPI.CanCrudUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.UserAPIClient = UserAPIClient;

