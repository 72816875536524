import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TooltipPosition } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { sort } from 'remeda';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, startWith, take } from 'rxjs/operators';
import { FuelSurchargeService } from 'src/app/billing/services/fuel-surcharge.service';
import { AccountsService } from 'src/app/lmo/services/accounts.service';
import { Account } from '~proto/account/account_pb';
import { CreateMileageCostsRequest, CreateVendorContractRequest } from '~proto/contracts/contracts_api_pb';
import { FuelSurchargeContract } from '~proto/contracts/contracts_pb';
import { PayloadType } from '~proto/payload/payload_pb';
import { NullableTime, VendorContractTravelCostType, VendorContractTravelCostTypeMap } from '~proto/types/types_pb';
import { ConstantsService } from '~services/constants.service';
import { UnsavedDataComponent } from '~services/guard/has-unsaved-data.guard';
import { recordToArray } from '~utilities/recordToArray';
import { trackById } from '~utilities/trackById';
import { ContractsService } from '../../services/contracts.service';

interface MileageBracket {
  cost: number;
  maxMiles: number;
  minMiles: number;
}

interface FormValue {
  contractName: string;
  costPerHour: number;
  costPerMile: number;
  deadheadCost: number;
  deadheadFreeDistance: number;
  defaultContract: boolean;
  detentionHourly: number;
  detentionMax: number;
  freeTimeDropoff: number;
  freeTimePickup: number;
  lineHaul: VendorContractTravelCostTypeMap[keyof VendorContractTravelCostTypeMap];
  payloadTypes: PayloadType.AsObject[];
  referenceNumber: string;
  truckingVendor: Account.AsObject;
  mileageBrackets: MileageBracket[];
  contractType: number;
  fuelSurcharge: boolean;
  isReturnBillable: boolean;
  minPrice: number;
  fileId: number;
  backHaulChargePercent: number;
  isBackHaul: boolean;
  expirationDate: string;
  effectiveDate: string;
}

const MAX_FILE_SIZE = 25 * 1024 * 1024;

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-lmo-trucking-contracts-creation',
  styleUrls: ['./lmo-trucking-contracts-creation.component.scss'],
  templateUrl: './lmo-trucking-contracts-creation.component.html',
})
export class LmoTruckingContractsCreationComponent implements OnInit, UnsavedDataComponent {
  public trackById = trackById;
  public today = new Date();

  @ViewChild('newContractType', { static: true }) private newContractTypeFormModal: TemplateRef<any>;
  public newContractTypeId: FormControl;
  public newContractTypeForm: FormControl;
  public newContractEdiCode: FormControl;
  public newContractFuelMatrix: FormControl;
  public contractTypes = [];
  private newContractTypeFormDialog: MatDialogRef<any>;
  public fuelContractList$: Observable<FuelSurchargeContract.AsObject[]>;
  public position: TooltipPosition = 'below';
  public message = 'Require contract type with fuel surcharge';
  public toolTipDisabled = false;
  public backHaulMessage = 'Require back haul option as Yes';
  public backHaulToolTipDisabled = false;

  public VendorContractTravelCostType = VendorContractTravelCostType;
  public lineHaulOptions = [
    {
      name: 'Fixed Cost Mileage Bracket',
      value: VendorContractTravelCostType.MILEAGE_BRACKET,
    },
    {
      name: 'Fixed Cost - Point to Point',
      value: VendorContractTravelCostType.FIXED_POINT,
    },
    {
      name: 'Hourly',
      value: VendorContractTravelCostType.PER_HOUR,
    },
    {
      name: 'Per Mile',
      value: VendorContractTravelCostType.PER_MILE,
    },
  ];
  public truckVendorSearch = new FormControl();
  public payloadTypes: PayloadType.AsObject[];
  public truckingVendors$: Observable<Account.AsObject[]>;
  public networkActive$$ = new BehaviorSubject(false);
  public formGroup: FormGroup;
  public uploadActive$$ = new BehaviorSubject<boolean>(false);

  public get mileageBrackets(): FormArray {
    return this.formGroup.get('mileageBrackets') as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    private constantsService: ConstantsService,
    private accountService: AccountsService,
    private contractService: ContractsService,
    private router: Router,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private fuelSurchargeService: FuelSurchargeService,
  ) {}

  public ngOnInit() {
    this.today.setHours(0, 0, 0, 0);
    this.formGroup = this.fb.group({
      backHaulChargePercent: [{ value: 0, disabled: true }, [Validators.max(100), Validators.min(0)]],
      contractName: [null, [Validators.required]],
      contractType: [null],
      costPerHour: [null, [Validators.required]],
      costPerMile: [null, [Validators.required]],
      deadheadCost: [0, [Validators.required]],
      deadheadFreeDistance: [0, [Validators.required]],
      defaultContract: [false, [Validators.required]],
      detentionHourly: [0, [Validators.required]],
      detentionMax: [0, [Validators.required]],
      effectiveDate: [new Date()],
      expirationDate: [null],
      fileId: [null, [Validators.required]],
      freeTimeDropoff: [0, [Validators.required]],
      freeTimePickup: [0, [Validators.required]],
      fuelSurcharge: this.fb.control({ value: false, disabled: true }, [Validators.required]),
      isBackHaul: [false, [Validators.required]],
      isReturnBillable: [false, [Validators.required]],
      lineHaul: [VendorContractTravelCostType.PER_HOUR, [Validators.required]],
      minPrice: [0, [Validators.required]],
      payloadTypes: [null],
      referenceNumber: [null],
      truckingVendor: [null, [Validators.required]],
    });

    this.fuelContractList$ = this.fuelSurchargeService.fuelContracts$.pipe(
      map((fuelContracts) => fuelContracts.contractsList.filter((fuelContract) => fuelContract.id)),
    );

    const mileageBracket = this.fb.group({
      cost: [null, [Validators.required]],
      maxMiles: [null, [Validators.required]],
      minMiles: [null, [Validators.required]],
    });

    this.formGroup.addControl('mileageBrackets', this.fb.array([mileageBracket]));

    this.newContractTypeId = this.fb.control(null, [Validators.required]);
    this.newContractTypeForm = this.fb.control(null, [Validators.required]);
    this.newContractEdiCode = this.fb.control(null, [Validators.required]);
    this.newContractFuelMatrix = this.fb.control(null);

    this.payloadTypes = recordToArray(this.constantsService.payloadTypes).sort((a, b) => a.name.localeCompare(b.name));

    this.truckingVendors$ = combineLatest([
      this.accountService.allVendors$.pipe(map((vendors) => sort(vendors, (a, b) => a.name.localeCompare(b.name)))),
      this.truckVendorSearch.valueChanges.pipe(startWith(this.truckVendorSearch.value)),
    ]).pipe(
      map(([vendors, searchTerm]) => {
        if (!searchTerm || searchTerm === '') {
          return vendors;
        }
        const asLower = searchTerm.toLocaleLowerCase();
        return vendors.filter((vendor) => vendor.name.toLocaleLowerCase().includes(asLower));
      }),
    );

    this.listenToLineHaul();

    this.contractService.contractTypes$.subscribe((contractTypes) => {
      this.contractTypes = contractTypes;
    });
    this.setupFormDisableAndEnableListeners();
  }

  private setupFormDisableAndEnableListeners() {
    this.formGroup
      .get('contractType')
      .valueChanges.pipe(distinctUntilChanged())
      .pipe(debounceTime(1000))
      .subscribe((contractType) => {
        const fuelContract = this.contractTypes.find((type) => {
          return type.id === contractType;
        });
        if (!contractType || !(fuelContract && fuelContract.fuelSurchargeContract)) {
          this.formGroup.get('fuelSurcharge').setValue(false);
          this.formGroup.get('fuelSurcharge').disable();
          this.toolTipDisabled = false;
        } else {
          this.formGroup.get('fuelSurcharge').enable();
          this.toolTipDisabled = true;
        }
      });

    this.formGroup
      .get('isBackHaul')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((isBackHaul) => {
        this.formGroup.get('backHaulChargePercent').setValue(0);
        if (isBackHaul) {
          this.formGroup.get('backHaulChargePercent').enable();
          this.backHaulToolTipDisabled = true;
        } else {
          this.formGroup.get('backHaulChargePercent').disable();
          this.backHaulToolTipDisabled = false;
        }
      });
  }

  public openNewContractTypeForm() {
    this.newContractTypeFormDialog = this.matDialog.open(this.newContractTypeFormModal, {
      maxWidth: '500px;',
    });
    this.newContractTypeFormDialog.afterOpened().subscribe(() => {
      this.newContractTypeForm.reset();
      this.newContractEdiCode.reset();
      this.newContractTypeId.reset();
      this.newContractFuelMatrix.reset();
    });
  }

  public openEditContractTypeForm() {
    this.newContractTypeFormDialog = this.matDialog.open(this.newContractTypeFormModal, {
      maxWidth: '500px;',
    });
    this.newContractTypeFormDialog.afterOpened().subscribe(() => {
      const contractTypeId = this.formGroup.get('contractType').value;
      const contractType = this.contractTypes.find((type) => {
        return type.id === contractTypeId;
      });
      this.newContractTypeForm.setValue(contractType && contractType.name);
      this.newContractEdiCode.setValue(contractType && contractType.ediCode);
      this.newContractTypeId.setValue(contractTypeId);
      this.newContractFuelMatrix.setValue(
        contractType && contractType.fuelSurchargeContract && contractType.fuelSurchargeContract.id,
      );
    });
  }

  public createContractType() {
    const contractTypeName = this.newContractTypeForm.value;
    const contractEdiCode = this.newContractEdiCode.value;
    const contractFuelId = this.newContractFuelMatrix.value;

    if (!contractFuelId) {
      this.formGroup.get('fuelSurcharge').setValue(false);
      this.formGroup.get('fuelSurcharge').disable();
    } else {
      this.formGroup.get('fuelSurcharge').enable();
    }

    if (this.newContractTypeId.value > 0) {
      return this.editContractType();
    }

    this.contractService.createContractType$(contractTypeName, contractEdiCode, contractFuelId).subscribe(
      (contractType) => {
        this.formGroup.get('contractType').setValue(contractType.id);
        this.newContractTypeFormDialog.close();
        this.snackBar.open(`Contract type is created`, null, {
          duration: 2500,
        });
      },
      (err) => {
        this.snackBar.open('Something Went Wrong', null, {
          duration: 2500,
          panelClass: ['snackbar-error'],
        });
      },
    );
  }

  private editContractType() {
    const contractTypeId = this.newContractTypeId.value;
    const contractTypeName = this.newContractTypeForm.value;
    const contractEdiCode = this.newContractEdiCode.value;
    const contractFuelId = this.newContractFuelMatrix.value;

    this.contractService.editContractType$(contractTypeId, contractTypeName, contractEdiCode, contractFuelId).subscribe(
      (contractType) => {
        this.formGroup.get('contractType').setValue(contractType.id);
        this.newContractTypeFormDialog.close();
        this.snackBar.open(`Contract type is edited`, null, {
          duration: 2500,
        });
      },
      (err) => {
        this.snackBar.open('Something Went Wrong', null, {
          duration: 2500,
          panelClass: ['snackbar-error'],
        });
      },
    );
  }

  public hasUnsavedData(): boolean {
    return false;
    // return this.formGroup.dirty && !this.formSubmitted;
  }

  public createTruckingContract() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }
    this.networkActive$$.next(true);
    const request = this.createRequest();
    this.contractService
      .createContract$(request)
      .pipe(finalize(() => this.networkActive$$.next(false)))
      .subscribe(() => {
        this.router.navigate(['/', 'lmo', 'trucking-contracts']);
      });
  }

  public addBracket($event?: KeyboardEvent) {
    if ($event) {
      $event.preventDefault();
    }
    const brackets = this.formGroup.get('mileageBrackets') as FormArray;
    const lastBracket = brackets.value.length && brackets.value[brackets.value.length - 1];
    brackets.push(
      this.fb.group({
        cost: [null, [Validators.required]],
        maxMiles: [null, [Validators.required]],
        minMiles: [lastBracket ? lastBracket.maxMiles + 1 : null, [Validators.required]],
      }),
    );
  }

  public removeBracket(id: number) {
    const brackets = this.formGroup.get('mileageBrackets') as FormArray;
    brackets.removeAt(id);
  }

  private listenToLineHaul() {
    this.formGroup
      .get('lineHaul')
      .valueChanges.pipe(startWith(this.formGroup.get('lineHaul').value))
      .subscribe((value: VendorContractTravelCostTypeMap[keyof VendorContractTravelCostTypeMap]) => {
        if (value === VendorContractTravelCostType.MILEAGE_BRACKET) {
          this.toggleMileageBracket('enable');
          this.toggleCostPerHour('disable');
          this.toggleCostPerMile('disable');
          this.toggleFileId('disable');
        } else if (value === VendorContractTravelCostType.PER_HOUR) {
          this.toggleCostPerHour('enable');
          this.toggleCostPerMile('disable');
          this.toggleMileageBracket('disable');
          this.toggleFileId('disable');
        } else if (value === VendorContractTravelCostType.PER_MILE) {
          this.toggleCostPerMile('enable');
          this.toggleCostPerHour('disable');
          this.toggleMileageBracket('disable');
          this.toggleFileId('disable');
        } else if (value === VendorContractTravelCostType.FIXED_POINT) {
          this.toggleCostPerMile('disable');
          this.toggleCostPerHour('disable');
          this.toggleMileageBracket('disable');
          this.toggleFileId('enable');
        }
      });
  }

  private toggleMileageBracket(action: 'enable' | 'disable') {
    this.formGroup.get('mileageBrackets')[action]();
  }

  private toggleFileId(action: 'enable' | 'disable') {
    this.formGroup.get('fileId')[action]();
  }

  private toggleCostPerHour(action: 'enable' | 'disable') {
    this.formGroup.get('costPerHour')[action]();
  }

  private toggleCostPerMile(action: 'enable' | 'disable') {
    this.formGroup.get('costPerMile')[action]();
  }

  private createRequest(): CreateVendorContractRequest {
    const value: FormValue = this.formGroup.value;
    const request = new CreateVendorContractRequest();
    request.setName(value.contractName);
    request.setPayloadTypeIdsList(value.payloadTypes.map((type) => type.id));
    request.setVendorId(value.truckingVendor.id);
    request.setTravelCostType(value.lineHaul);
    request.setReferenceNumber(value.referenceNumber);
    request.setIsDefault(value.defaultContract);
    request.setContractTypeId(value.contractType);
    request.setFuelSurcharge(value.fuelSurcharge);
    request.setBackHaulChargePercent(value.backHaulChargePercent);
    request.setIsBackHaulEnabled(value.isBackHaul);
    const expirationDate = new NullableTime();
    expirationDate.setValid(value.expirationDate !== null);
    expirationDate.setTime(new Date(value.expirationDate).toISOString());
    request.setExpirationTime(expirationDate);
    const effectiveDate = new NullableTime();
    const date = value.effectiveDate ? new Date(value.effectiveDate).toISOString() : new Date().toISOString();
    effectiveDate.setValid(value.effectiveDate !== null);
    effectiveDate.setTime(date);
    request.setEffectiveTime(effectiveDate);
    if (value.lineHaul === VendorContractTravelCostType.MILEAGE_BRACKET) {
      request.setDeadheadCostPerMile(value.deadheadCost);
      request.setDeadheadFreeMileage(value.deadheadFreeDistance);
      request.setDropoffFreeTime(value.freeTimeDropoff);
      request.setHourlyDetentionCost(value.detentionHourly);
      request.setMaxDetentionPerOrder(value.detentionMax);
      request.setPickupFreeTime(value.freeTimePickup);
      request.setIsReturnTripBillable(value.isReturnBillable);
      request.setMileageCostRequestsList(
        value.mileageBrackets.map((bracket) => {
          const bracketRequest = new CreateMileageCostsRequest();
          bracketRequest.setCost(bracket.cost);
          bracketRequest.setLowerBoundMiles(bracket.minMiles);
          bracketRequest.setUpperBoundMiles(bracket.maxMiles);
          return bracketRequest;
        }),
      );
    } else if (value.lineHaul === VendorContractTravelCostType.PER_HOUR) {
      request.setPerHourTravelCost(value.costPerHour);
      request.setMinimumCharge(value.minPrice);
      request.setIsReturnTripBillable(value.isReturnBillable);
    } else if (value.lineHaul === VendorContractTravelCostType.PER_MILE) {
      request.setPerMileTravelCost(value.costPerMile);
      request.setDropoffFreeTime(value.freeTimeDropoff);
      request.setHourlyDetentionCost(value.detentionHourly);
      request.setMaxDetentionPerOrder(value.detentionMax);
      request.setPickupFreeTime(value.freeTimePickup);
      request.setMinimumCharge(value.minPrice);
      request.setIsReturnTripBillable(value.isReturnBillable);
    } else if (value.lineHaul === VendorContractTravelCostType.FIXED_POINT) {
      request.setFixCostFileId(value.fileId);
      request.setDropoffFreeTime(value.freeTimeDropoff);
      request.setHourlyDetentionCost(value.detentionHourly);
      request.setMaxDetentionPerOrder(value.detentionMax);
      request.setPickupFreeTime(value.freeTimePickup);
      request.setMinimumCharge(value.minPrice);
    }
    return request;
  }

  @HostListener('window:beforeunload', ['$event'])
  public unloadNotification($event: any) {
    if (this.hasUnsavedData()) {
      $event.returnValue = true;
    }
  }

  public trackByName<T extends { name: string }>(_index: number, item: T) {
    return item.name;
  }

  public trackByIndex(index: number) {
    return index;
  }

  public closeTab() {
    this.newContractTypeFormDialog.close();
    this.newContractTypeFormDialog = null;
  }

  public showDetention(): boolean {
    return (
      this.formGroup.get('lineHaul').value === VendorContractTravelCostType.MILEAGE_BRACKET ||
      this.formGroup.get('lineHaul').value === VendorContractTravelCostType.PER_MILE
    );
  }

  public showDeadhead(): boolean {
    return this.formGroup.get('lineHaul').value === VendorContractTravelCostType.MILEAGE_BRACKET;
  }

  public showMileageBracket(): boolean {
    return this.formGroup.get('lineHaul').value === VendorContractTravelCostType.MILEAGE_BRACKET;
  }

  public showMinPrice(): boolean {
    return (
      this.formGroup.get('lineHaul').value === VendorContractTravelCostType.PER_HOUR ||
      this.formGroup.get('lineHaul').value === VendorContractTravelCostType.PER_MILE
    );
  }

  public showFileUpload(): boolean {
    return this.formGroup.get('lineHaul').value === VendorContractTravelCostType.FIXED_POINT;
  }

  public onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      const inputSelect = document.getElementsByName('file-name');
      inputSelect[0].innerHTML = file.name;
      if (file.size > MAX_FILE_SIZE) {
        this.snackBar.open('File Size cannot be greater than 25 MB', null, {
          duration: 2500,
        });
      } else {
        event.target.name === 'mileage-brackets' ? this.uploadMileageBracketsFile(file) : this.uploadP2PCostFile(file);
      }
    }
  }

  private uploadP2PCostFile(fileToUpload) {
    this.uploadActive$$.next(true);
    this.contractService.uploadP2PCostFile$(fileToUpload).subscribe(
      (response) => {
        this.uploadActive$$.next(false);
        if (response.status) {
          this.formGroup.get('fileId').setValue(response.fileId);
          this.snackBar.open('File uploaded successfully', null, {
            duration: 2000,
          });
        } else {
          this.snackBar.open(response.message, null, {
            duration: 2000,
          });
        }
      },
      (err) => {
        this.uploadActive$$.next(false);
        this.snackBar.open('Error: ' + err.Error.Message, null, {
          duration: 20000,
        });
      },
    );
  }

  public downloadP2PCostFile() {
    const fileId = this.formGroup.get('fileId').value;
    this.contractService
      .downloadP2PCostFile$(fileId)
      .pipe(take(1))
      .subscribe(
        (response) => {
          window.location.assign(response.toObject().url);
        },
        (err) => {
          this.snackBar.open('Error:' + err.toObject().url, null, {
            duration: 10000,
          });
        },
      );
  }

  private uploadMileageBracketsFile(fileToUpload) {
    this.uploadActive$$.next(true);
    this.contractService.uploadMileageBracketsFile$(fileToUpload).subscribe(
      (response) => {
        this.uploadActive$$.next(false);
        if (response.length > 0) {
          this.mileageBrackets.controls = [];
          response.forEach((item) => {
            const mileageBracket = this.fb.group({
              cost: [item.cost, [Validators.required]],
              maxMiles: [item.upperBoundMiles, [Validators.required]],
              minMiles: [item.lowerBoundMiles, [Validators.required]],
            });
            this.mileageBrackets.push(mileageBracket);
          });
          this.snackBar.open('File uploaded successfully', null, {
            duration: 2000,
          });
        } else {
          this.snackBar.open(response.message, null, {
            duration: 20000,
          });
        }
      },
      (err) => {
        this.uploadActive$$.next(false);
        this.snackBar.open('Error: ' + err.error, null, {
          duration: 10000,
        });
      },
    );
  }

  public clearExpirationDate() {
    this.formGroup.get('expirationDate').setValue(null);
  }
  public clearEffectiveDate() {
    this.formGroup.get('effectiveDate').setValue(null);
  }
}
