import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { Payload } from '~proto/payload/payload_pb';
import { AddStockRequest } from '~proto/site/site_api_pb';
import { Site } from '~proto/site/site_pb';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-create-order-payload-selector-confirm-tracking',
  styleUrls: ['./create-order-payload-selector-confirm-tracking.component.scss'],
  templateUrl: './create-order-payload-selector-confirm-tracking.component.html',
})
export class CreateOrderPayloadSelectorConfirmTrackingComponent implements OnInit {
  @Input() public payload: Payload.AsObject;
  public currentSite$: Observable<Site.AsObject>;
  public fg = new FormGroup({
    maxQuantity: new FormControl(0, [Validators.required]),
    quantity: new FormControl(0, [Validators.required]),
  });
  public wantsToTrack = new FormControl(false);

  constructor(
    private siteService: JobSitesService,
    public matDialogRef: MatDialogRef<CreateOrderPayloadSelectorConfirmTrackingComponent>,
  ) {}

  public ngOnInit() {
    this.currentSite$ = this.siteService.currentSite$;
  }

  public trackPayload() {
    if (this.fg.invalid) {
      return;
    }
    const request = new AddStockRequest();
    request.setMaxQuantity(this.fg.value.maxQuantity);
    request.setPayloadId(this.payload.id);
    request.setQuantity(this.fg.value.quantity);

    this.siteService.addStockToSite$(request).subscribe(() => {
      this.matDialogRef.close();
    });
  }

  public nevermind() {
    this.matDialogRef.close();
  }
}
