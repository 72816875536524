import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrdersService } from 'src/app/lmo/services/orders.service';
import { FilePreviewModalComponent } from '~common/file-preview-modal/file-preview-modal.component';
import { OrderAttachment } from '~proto/order/order_pb';
import { AttachmentCategory } from '~proto/types/types_pb';
import { FileUploadService } from '~services/file-upload.service';
import { trackById } from '~utilities/trackById';

const MAX_FILE_SIZE = 24 * 1024 * 1024;

@Component({
  selector: 'ct-order-detail-attachment',
  styleUrls: ['./order-detail-attachment.component.scss'],
  templateUrl: './order-detail-attachment.component.html',
})
export class OrderDetailAttachmentComponent implements OnInit {
  @Input() public attachmentsList: OrderAttachment.AsObject[];
  @Input() public orderId: number;
  public trackById = trackById;
  public bolAttachments: OrderAttachment.AsObject[] = [];
  public truckTicketAttachments: OrderAttachment.AsObject[] = [];
  public otherAttachments: OrderAttachment.AsObject[] = [];
  private fileToUpload: File;
  public uploading = '';
  private allowedFileTypes = ['pdf', 'svg', 'png', 'jpg', 'xls', 'xlsx', 'csv', 'doc', 'docx', 'ppt'];

  constructor(
    private snackbar: MatSnackBar,
    private fileUploadService: FileUploadService,
    private orderService: OrdersService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit() {
    this.separateFiles();
  }

  public separateFiles() {
    this.bolAttachments = [];
    this.truckTicketAttachments = [];
    this.otherAttachments = [];
    this.attachmentsList.forEach((file: OrderAttachment.AsObject) => {
      this.updateFile(file);
    });
  }

  private updateFile(file: OrderAttachment.AsObject) {
    switch (file.category) {
      case AttachmentCategory.ATTACHMENT_CATEGORY_BOL_IMAGE:
        this.bolAttachments.push(file);
        break;
      case AttachmentCategory.ATTACHMENT_CATEGORY_TICKET_IMAGE:
        this.truckTicketAttachments.push(file);
        break;
      case AttachmentCategory.ATTACHMENT_CATEGORY_OTHER_IMAGE:
        this.otherAttachments.push(file);
        break;
    }
  }

  public onFileChange(event, category: string) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      if (file.size > MAX_FILE_SIZE) {
        this.snackbar.open('File Size cannot be greater than 25 MB', null, {
          duration: 2500,
        });
      } else {
        this.fileToUpload = file;
        this.uploadFile(category);
      }
    }
  }

  public deleteFile(fileId: number, event) {
    event.stopPropagation();
    this.orderService.deleteAttachment$(fileId).subscribe(
      (resp) => {
        this.attachmentsList = [...resp];
        this.separateFiles();
        this.snackbar.open('File Successfully Deleted', null, {
          duration: 2000,
        });
        this.cdr.detectChanges();
      },
      (err) => {
        this.snackbar.open('File Delete Failed', null, {
          duration: 2000,
          panelClass: ['snackbar-error'],
        });
        this.cdr.detectChanges();
      },
    );
  }

  public previewFile(file: OrderAttachment.AsObject) {
    this.dialog.open(FilePreviewModalComponent, {
      data: file,
      height: '75vh',
      width: '75vw',
    });
  }

  public uploadFile(category: string) {
    this.uploading = category;
    this.fileUploadService.uploadFile$(this.fileToUpload, this.orderId, category).subscribe(
      (result) => {
        this.uploading = '';
        const newImage = new OrderAttachment();
        newImage.setId(result.id);
        newImage.setOrderId(result.order_id);
        newImage.setFileName(result.file_name);
        newImage.setCanDelete(result.can_delete);
        newImage.setUploadedUnix(result.uploaded_unix);
        newImage.setCategory(result.category);
        newImage.setUploadedBy(result.uploaded_by);
        this.updateFile(newImage.toObject());
        this.snackbar.open('File uploaded Successfully', null, {
          duration: 5000,
        });
        this.cdr.detectChanges();
      },
      (err) => {
        this.uploading = '';
        this.snackbar.open('File uploaded failed', null, {
          duration: 5000,
        });
        this.cdr.detectChanges();
      },
    );
  }

  public getFileIcon(fileName) {
    const filePathArray = fileName.toLowerCase().split('/');
    const filename = filePathArray[filePathArray.length - 1];
    const fileNameArray = filename.split('.');
    let extension = fileNameArray[fileNameArray.length - 1];
    if (this.allowedFileTypes.includes(extension)) {
      if (extension === 'xlsx' || extension === 'ppt') {
        extension = 'xls';
      }
      if (extension === 'docx') {
        extension = 'doc';
      }
      return `assets/icons/file-types/${extension}.svg`;
    } else {
      return `assets/icons/file-types/other.png`;
    }
  }
}
