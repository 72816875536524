<div class="trailer-edit">
  <div class="trailer-edit-header">
    <a class="close" [routerLink]="['../../']" ctLongEscape>
      <i class="material-icons">
        close
      </i>
    </a>
    <span class="modal-title">
      <h1>Edit trailer</h1>
    </span>
  </div>

  <form [formGroup]="formGroup" (ngSubmit)="editTrailer()" *ngIf="formGroup; else spinner">
    <div class="inputs">
      <ct-input label="Name" staticLabel class="span-6">
        <input ctInput formControlName="name" />
      </ct-input>
      <ct-input label="Model" staticLabel class="span-6">
        <input ctInput formControlName="model" />
      </ct-input>
    </div>
    <ng-container *ctAllowedAccountRoles="[1, 2]">
      <div class="submit-button">
        <ct-network-active-button [active]="networkActive$ | async">
          <button ct-dark-button class="full-button" type="submit">
            Edit trailer
          </button>
        </ct-network-active-button>
      </div>
    </ng-container>
  </form>
  <ng-template #spinner>
    <mat-spinner [diameter]="75"></mat-spinner>
  </ng-template>
</div>
