import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CreateEmailUserV2Request } from '~proto/user/user_api_pb';
import { trackById } from '~utilities/trackById';
var LmoUserManagementCreateComponent = /** @class */ (function () {
    function LmoUserManagementCreateComponent(fb, userService, router, snackbar, businessLineService, roleService) {
        this.fb = fb;
        this.userService = userService;
        this.router = router;
        this.snackbar = snackbar;
        this.businessLineService = businessLineService;
        this.roleService = roleService;
        this.networkActive$ = new BehaviorSubject(false);
        this.trackById = trackById;
        this.hide = true;
        this.isBusinessLineEnabled$$ = new BehaviorSubject(false);
        this.setUserRoles = [
            { id: 1, name: 'Admin' },
            { id: 2, name: 'Default' },
            { id: 4, name: 'View Only' },
        ];
        this.setUserPermissions = {
            1: 'Can Approve Ticket',
            2: 'Can Approve Invoice',
            4: 'Can Create Cost Center',
        };
        this.errorMessages = {
            hasRestrictedCharacter: 'only dash, dot, plus, underscore are allowed',
            lessThanTwoCharacter: 'minimum 2 character are required',
            minPasswordLength: 'minimum 6 characters are required',
            nameRequired: '*required',
        };
    }
    Object.defineProperty(LmoUserManagementCreateComponent.prototype, "nameInputValueCheck", {
        get: function () {
            var inputVal = this.formGroup.get('name').value || '';
            var inputLength = inputVal.length;
            var isRequired = inputLength === 0 ? this.errorMessages.nameRequired : '_';
            var errorMsg = inputLength > 0 && inputLength < 2 ? this.errorMessages.lessThanTwoCharacter : isRequired;
            return errorMsg;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LmoUserManagementCreateComponent.prototype, "emailValueCheck", {
        get: function () {
            var inputVal = this.formGroup.get('email').value || '';
            var inputLength = inputVal.length;
            var isRequired = inputLength === 0 ? this.errorMessages.nameRequired : '_';
            return isRequired;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LmoUserManagementCreateComponent.prototype, "passwordValueCheck", {
        get: function () {
            var inputVal = this.formGroup.get('password').value || '';
            var inputLength = inputVal.length;
            var isRequired = inputLength === 0 ? this.errorMessages.nameRequired : '_';
            var errorMsg = inputLength > 0 && inputLength < 6 ? this.errorMessages.minPasswordLength : isRequired;
            return errorMsg;
        },
        enumerable: true,
        configurable: true
    });
    LmoUserManagementCreateComponent.prototype.formValue = function () {
        return this.formGroup.value;
    };
    LmoUserManagementCreateComponent.prototype.ngOnInit = function () {
        this.formGroup = this.fb.group({
            businessLine: [''],
            email: ['', [Validators.required]],
            name: ['', [Validators.required]],
            password: ['', [Validators.required]],
            permission: [[]],
            role: [[]],
        });
        this.checkIfBusinessLineEnabled();
        if (this.isBusinessLineEnabled$$.value) {
            this.businessLines$ = this.businessLineService.businessLines$;
        }
        this.userPermissions$ = this.userService.userPermissions$;
    };
    LmoUserManagementCreateComponent.prototype.addUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formValue;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.formGroup.markAllAsTouched();
                if (this.formGroup.invalid) {
                    return [2 /*return*/];
                }
                formValue = this.formValue();
                this.networkActive$.next(true);
                this.createUser$(formValue)
                    .pipe(finalize(function () {
                    _this.networkActive$.next(false);
                }))
                    .subscribe(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.snackbar.open(formValue.name + " created!", null, {
                                    duration: 2000,
                                });
                                return [4 /*yield*/, this.router.navigate(['/', 'lmo', 'users'])];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    LmoUserManagementCreateComponent.prototype.checkIfBusinessLineEnabled = function () {
        var _this = this;
        this.roleService.isBusinessLineEnabled$.subscribe(function (isBusinessLineEnabled) {
            _this.isBusinessLineEnabled$$.next(isBusinessLineEnabled);
        });
    };
    LmoUserManagementCreateComponent.prototype.createUser$ = function (formValue) {
        if (formValue.role !== 1) {
            formValue.permission = [];
        }
        var createUserRequest = new CreateEmailUserV2Request();
        createUserRequest.setName(formValue.name);
        createUserRequest.setEmail(formValue.email.toLowerCase());
        createUserRequest.setPassword(formValue.password);
        createUserRequest.setRole(formValue.role);
        createUserRequest.setBusinesslineidsList(formValue.businessLine);
        createUserRequest.setPermissionsList(formValue.permission);
        return this.userService.createUser$(createUserRequest);
    };
    return LmoUserManagementCreateComponent;
}());
export { LmoUserManagementCreateComponent };
