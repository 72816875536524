import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, filter, map, startWith, take } from 'rxjs/operators';
import { SmartDropdownComponent } from '~common/smart-dropdown/smart-dropdown.component';
import { CreateTruckRequest } from '~proto/truck/truck_api_pb';
import { fuse } from '~utilities/fuse';
import { trackById } from '~utilities/trackById';
import { AssignedAssetActionsComponent, } from '../assigned-asset-actions/assigned-asset-actions.component';
var searchOptions = {
    distance: 100,
    keys: ['name'],
    location: 0,
    maxPatternLength: 16,
    minMatchCharLength: 1,
    shouldSort: true,
    threshold: 0.1,
};
var OrderDetailsEditTrailerSelectorComponent = /** @class */ (function () {
    function OrderDetailsEditTrailerSelectorComponent(trailerService, orderService, matDialog) {
        this.trailerService = trailerService;
        this.orderService = orderService;
        this.matDialog = matDialog;
        this.selected = new EventEmitter();
        this.invalid = false;
        this.trackById = trackById;
        this.textChanges$$ = new BehaviorSubject(null);
    }
    OrderDetailsEditTrailerSelectorComponent.prototype.ngOnInit = function () {
        this.trailers$ = combineLatest([
            this.trailerService.trailers$.pipe(map(fuse(searchOptions))),
            this.textChanges$$.pipe(debounceTime(100), startWith('')),
            this.orderService.currentOrder$,
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), fused = _b[0], text = _b[1];
            if (!text || text === '') {
                return fused.data;
            }
            return fused.fuse.search(text);
        }));
    };
    OrderDetailsEditTrailerSelectorComponent.prototype.addTrailer = function () {
        var _this = this;
        var name = this.textChanges$$.value;
        if (!name || name === '') {
            return;
        }
        var request = new CreateTruckRequest();
        request.setName(name);
        this.trailerService.addTrailer$(request).subscribe(function (truck) {
            _this.select(truck);
        });
    };
    OrderDetailsEditTrailerSelectorComponent.prototype.select = function (trailer) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (trailer.isAssigned) {
                    this.orderService.currentOrder$
                        .pipe(filter(function (order) { return !!order; }), take(1))
                        .subscribe(function (currentOrder) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var reassignDialog, confirm_1;
                        var _this = this;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(currentOrder.trailer.id !== trailer.id)) return [3 /*break*/, 2];
                                    reassignDialog = this.matDialog.open(AssignedAssetActionsComponent);
                                    reassignDialog.componentInstance.title = "Trailer " + trailer.name + " Is Already In Use";
                                    return [4 /*yield*/, reassignDialog.afterClosed().toPromise()];
                                case 1:
                                    confirm_1 = _a.sent();
                                    if (!confirm_1.reassign) {
                                        // close without selecting trailer
                                        this.smartDropdown.close();
                                        return [2 /*return*/];
                                    }
                                    // unassign trailer, return to form with trailer selected
                                    this.trailerService.unassignTrailer$(trailer.id, confirm_1.complete).subscribe(function () {
                                        _this.selected.emit({ trailer: trailer });
                                        _this.smartDropdown.close();
                                        return;
                                    });
                                    return [3 /*break*/, 3];
                                case 2:
                                    // ALready on this order
                                    this.selected.emit({ trailer: trailer });
                                    this.smartDropdown.close();
                                    _a.label = 3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); });
                }
                else {
                    // trailer not in use, can select and close dropdown
                    this.selected.emit({ trailer: trailer });
                    this.smartDropdown.close();
                }
                return [2 /*return*/];
            });
        });
    };
    OrderDetailsEditTrailerSelectorComponent.prototype.inputTextChange = function (event) {
        this.textChanges$$.next(event.trim());
    };
    return OrderDetailsEditTrailerSelectorComponent;
}());
export { OrderDetailsEditTrailerSelectorComponent };
