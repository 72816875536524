import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { orderPluralMapping } from '~plural/orders';
import { OrderStatus, TaskStatus } from '~proto/types/types_pb';
import { convertMappableOrderToOrderCard, groupOrdersByTaskType } from '~utilities/groupOrders';
import { trackById } from '~utilities/trackById';
var OrdersInProgressViewComponent = /** @class */ (function () {
    function OrdersInProgressViewComponent(ordersService, siteService) {
        this.ordersService = ordersService;
        this.siteService = siteService;
        this.ordersPlural = orderPluralMapping;
        this.trackById = trackById;
        this.taskDelDate = new Date();
    }
    OrdersInProgressViewComponent.prototype.ngOnInit = function () {
        this.mappableOrders$ = this.ordersService.mappableInProgressOrders$.pipe(map(function (orders) {
            var dispatchedOrders = orders.filter(function (order) { return order.status === OrderStatus.ORDER_STATUS_DISPATCHED; });
            var inProgressOrders = groupOrdersByTaskType(orders, TaskStatus.TASK_STATUS_IN_PROGRESS)
                .map(function (group) { return ({
                groupHeader: group.groupHeader,
                orderCards: group.orderCards.filter(function (order) { return order.status === OrderStatus.ORDER_STATUS_DRIVER_ACCEPTED; }),
            }); })
                .filter(function (group) { return group.orderCards.length > 0; });
            return tslib_1.__spread([
                {
                    groupHeader: 'Sent to Driver',
                    orderCards: dispatchedOrders.map(function (order) { return convertMappableOrderToOrderCard(order); }),
                }
            ], inProgressOrders).filter(function (group) { return group.orderCards.length; });
        }));
        this.currentSite$ = this.siteService.currentSite$;
    };
    OrdersInProgressViewComponent.prototype.trackByPayloadDetails = function (_, details) {
        return details.payloadName;
    };
    return OrdersInProgressViewComponent;
}());
export { OrdersInProgressViewComponent };
