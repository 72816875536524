import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { SmartDropdownComponent } from '~common/smart-dropdown/smart-dropdown.component';
import { RoleService } from '~services/role.service';
import { trackById } from '~utilities/trackById';
import { CreateOrderRecentsService } from '../../services/create-order-recents.service';

interface GroupedMaintenanceOrderNumber {
  name: string;
  maintenanceOrderNumbers: string[];
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-create-order-maintenance-order-number-selector',
  styleUrls: ['./create-order-maintenance-order-number-selector.component.scss'],
  templateUrl: './create-order-maintenance-order-number-selector.component.html',
})
export class CreateOrderMaintenanceOrderNumberSelectorComponent implements OnInit {
  private topResult: string;
  private additionalRecentMaintenanceOrderNumbers$$ = new BehaviorSubject<string[]>([]);
  @Output() public selected = new EventEmitter<{ maintenanceOrderNumber: string }>();
  @ViewChild('smartDropdown', { static: true }) private smartDropdown: SmartDropdownComponent;
  @Input() public maintenanceOrderNumber: string;
  @Input() public invalid = false;

  @Input()
  public set additionalRecentMaintenanceOrderNumbers(SOs: string[]) {
    if (SOs) {
      this.additionalRecentMaintenanceOrderNumbers$$.next(SOs);
    }
  }

  public textChanges$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public isMaintenanceOrderApiEnabled$$ = new BehaviorSubject<Boolean>(false);
  public maintenanceOrderNumbers$: Observable<GroupedMaintenanceOrderNumber[]>;
  public showCreateMOButton$: Observable<boolean>;
  public trackById = trackById;

  constructor(private recentService: CreateOrderRecentsService, private roleService: RoleService) {}

  public ngOnInit() {
    this.maintenanceOrderNumbers$ = combineLatest([
      this.recentService.maintenanceOrders$,
      this.textChanges$$,
      this.additionalRecentMaintenanceOrderNumbers$$,
    ]).pipe(
      map(([maintenanceOrders, text, additionalRecentMaintenanceOrderNumbers]) => {
        if (!maintenanceOrders) {
          return [];
        }
        const asArray = [
          {
            maintenanceOrderNumbers: maintenanceOrders.searchResults,
            name: 'search results',
          },
          {
            maintenanceOrderNumbers: Array.from(
              new Set([...(maintenanceOrders.recentlyUsedList || []), ...additionalRecentMaintenanceOrderNumbers]),
            ),
            name: 'recently used',
          },
          {
            maintenanceOrderNumbers: maintenanceOrders.frequentlyUsedList,
            name: 'frequently used',
          },
        ];
        if (!text || text === '') {
          return asArray.filter((group) => group.maintenanceOrderNumbers && group.maintenanceOrderNumbers.length > 0);
        }
        return asArray
          .map((group) => ({
            maintenanceOrderNumbers: (group.maintenanceOrderNumbers || []).filter((s) => s.includes(text)),
            name: group.name,
          }))
          .filter((group) => group.maintenanceOrderNumbers.length > 0);
      }),
    );

    this.showCreateMOButton$ = combineLatest([
      this.recentService.maintenanceOrders$,
      this.textChanges$$.pipe(startWith(null as string)),
    ]).pipe(
      map(([maintenanceOrders, text]) => {
        if (!text || !text.length) {
          return false;
        }

        if (!maintenanceOrders) {
          return true;
        }

        return (
          (maintenanceOrders.searchResults || []).every((so) => so !== text) &&
          (maintenanceOrders.frequentlyUsedList || []).every((so) => so !== text) &&
          (maintenanceOrders.recentlyUsedList || []).every((so) => so !== text)
        );
      }),
    );

    this.textChanges$$.pipe(debounceTime(250)).subscribe((text: string) => {
      this.recentService.searchMaintenanceOrders(text);
    });

    this.checkIsMaintenanceOrderApiEnabled();
  }

  public selectMaintenanceOrderNumber(maintenanceOrderNumber: string) {
    this.selected.emit({ maintenanceOrderNumber });
    this.smartDropdown.close();
  }

  public addMaintenanceOrderNumber() {
    if (this.textChanges$$.value && this.textChanges$$.value !== '') {
      this.selectMaintenanceOrderNumber(this.textChanges$$.value);
    }
  }

  public inputTextChange(event: string) {
    this.textChanges$$.next(event.trim());
  }

  public enterKeyPressed() {
    if (!this.isMaintenanceOrderApiEnabled$$.value) {
      if (this.topResult) {
        this.selectMaintenanceOrderNumber(this.topResult);
      } else {
        this.addMaintenanceOrderNumber();
      }
    }
  }

  public trackByName(_number: number, name: string): string {
    return name;
  }

  private checkIsMaintenanceOrderApiEnabled() {
    this.roleService.isMaintenanceOrderApiEnabled$.subscribe((isSalesOrderApiEnabled) => {
      this.isMaintenanceOrderApiEnabled$$.next(isSalesOrderApiEnabled);
    });
  }
}
