<div class="select-date-range">
  <ct-mini-modal title="Select Date Range">
    <main>
      <div class="content">
        <owl-date-time-inline
          [(ngModel)]="selectedDates"
          [pickerType]="'calendar'"
          [selectMode]="'range'"
        ></owl-date-time-inline>
      </div>
    </main>
    <footer>
      <div class="content">
        <button ct-dark-button class="full-button" (click)="selected()">{{ buttonText }}</button>
      </div>
    </footer>
  </ct-mini-modal>
</div>
