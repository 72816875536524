import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetCustomFieldSettingsRequest, } from '~proto/fields/fields_api_pb';
import { FieldsAPI } from '~proto/fields/fields_api_pb_service';
import { CanLoadBeBackHaulRequest } from '~proto/order/order_api_pb';
import { OrderAPI } from '~proto/order/order_api_pb_service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/grpc.service";
import * as i2 from "@angular/material/snack-bar";
var customFieldResource = 'orders';
export var customFieldsTypes = ['string', 'number', 'boolean'];
var LmoCustomOrderFieldsService = /** @class */ (function () {
    function LmoCustomOrderFieldsService(grpcService, snackBar) {
        this.grpcService = grpcService;
        this.snackBar = snackBar;
        this.fields$$ = new BehaviorSubject([]);
    }
    Object.defineProperty(LmoCustomOrderFieldsService.prototype, "fields$", {
        get: function () {
            this.loadCustomFields();
            return this.fields$$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    LmoCustomOrderFieldsService.prototype.createField = function (request) {
        var _this = this;
        request.setCustomFieldResource(customFieldResource);
        this.grpcService
            .invoke$(FieldsAPI.CreateCustomFieldSetting, request)
            .subscribe(function (response) {
            _this.fields$$.next(response.toObject().customFieldSettingsList);
        });
    };
    LmoCustomOrderFieldsService.prototype.updateFields = function (request) {
        var _this = this;
        request.setCustomFieldResource(customFieldResource);
        this.grpcService
            .invoke$(FieldsAPI.UpdateCustomFieldSettings, request)
            .subscribe(function (response) {
            _this.fields$$.next(response.toObject().customFieldSettingsList);
            _this.snackBar.open('Custom Fields Updated', null, { duration: 2000 });
        });
    };
    LmoCustomOrderFieldsService.prototype.loadCustomFields = function () {
        var _this = this;
        var request = new GetCustomFieldSettingsRequest();
        request.setCustomFieldResource(customFieldResource);
        this.grpcService
            .invoke$(FieldsAPI.GetCustomFieldSettings, request)
            .subscribe(function (response) {
            _this.fields$$.next(response.toObject().customFieldSettingsList);
        });
    };
    LmoCustomOrderFieldsService.prototype.canLoadBeBackHaulRequest$ = function (orderId) {
        var request = new CanLoadBeBackHaulRequest();
        request.setLoadId(orderId);
        return this.grpcService.invoke$(OrderAPI.CanLoadBeBackHaul, request).pipe(map(function (response) { return response.toObject(); }));
    };
    LmoCustomOrderFieldsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LmoCustomOrderFieldsService_Factory() { return new LmoCustomOrderFieldsService(i0.ɵɵinject(i1.GrpcService), i0.ɵɵinject(i2.MatSnackBar)); }, token: LmoCustomOrderFieldsService, providedIn: "root" });
    return LmoCustomOrderFieldsService;
}());
export { LmoCustomOrderFieldsService };
