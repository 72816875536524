import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { orderPluralMapping } from '~plural/orders';
import { OrderStatus } from '~proto/types/types_pb';
import { fuse } from '~utilities/fuse';
import { convertMappableOrderToOrderCardForLastSequence } from '~utilities/groupOrders';
import { trackById } from '~utilities/trackById';
var searchOptions = {
    distance: 100,
    keys: ['id', 'truckName', 'driver.user.name', 'trailerName', 'taskSummariesList.payload.name', 'vendorName', 'displayId'],
    location: 0,
    maxPatternLength: 16,
    minMatchCharLength: 1,
    shouldSort: true,
    threshold: 0.2,
};
var OrdersCompletedViewComponent = /** @class */ (function () {
    function OrdersCompletedViewComponent(ordersService) {
        this.ordersService = ordersService;
        this.ordersPlural = orderPluralMapping;
        this.trackById = trackById;
        this.orderStatus = OrderStatus;
        this.completedOrdersSearch = new FormControl(this.ordersService.searchTerm);
        this.completedOrdersEndIncrement = 25;
        this.completedOrdersEndIndex$$ = new BehaviorSubject(this.completedOrdersEndIncrement);
    }
    Object.defineProperty(OrdersCompletedViewComponent.prototype, "completedOrdersEndIndex$", {
        get: function () {
            return this.completedOrdersEndIndex$$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    OrdersCompletedViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.completedOrders$ = combineLatest([
            this.ordersService.mappableCompletedOrders$.pipe(map(fuse(searchOptions))),
            this.completedOrdersSearch.valueChanges.pipe(debounceTime(200), startWith(null)),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), fused = _b[0], searchTerm = _b[1];
            if (!searchTerm || searchTerm === '') {
                return fused.data;
            }
            return fused.fuse.search(searchTerm);
        }));
        this.virtualScrolls$ = combineLatest([
            this.completedOrders$,
            this.completedOrdersEndIndex$.pipe(distinctUntilChanged()),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), completed = _b[0], endIndex = _b[1];
            var completedHeader = {
                id: 'completedHeader',
                isHeaderItem: true,
                length: 0,
                status: 0,
            };
            var cancelledHeader = {
                id: 'cancelledHeader',
                isHeaderItem: true,
                length: 0,
                status: 0,
            };
            var completedOrders = [];
            var cancelledOrders = [];
            completed.forEach(function (complete) {
                if (complete.status === OrderStatus.ORDER_STATUS_COMPLETED) {
                    completedHeader.status = complete.status;
                    completedOrders.push(convertMappableOrderToOrderCardForLastSequence(complete));
                }
                else if (complete.status === OrderStatus.ORDER_STATUS_CANCELLED) {
                    cancelledHeader.status = complete.status;
                    cancelledOrders.push(convertMappableOrderToOrderCardForLastSequence(complete));
                }
            });
            _this.totalCompletedElements = cancelledOrders.length + completedOrders.length;
            var arr = tslib_1.__spread([
                tslib_1.__assign({}, completedHeader, { length: completedOrders.length })
            ], completedOrders, [
                tslib_1.__assign({}, cancelledHeader, { length: cancelledOrders.length })
            ], cancelledOrders);
            return arr.slice(0, Math.min(endIndex, arr.length));
        }));
    };
    OrdersCompletedViewComponent.prototype.onScroll = function (event) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            var currentCompletedOrdersEndIndex = this.completedOrdersEndIndex$$.value;
            if (currentCompletedOrdersEndIndex < this.totalCompletedElements + 2) {
                this.completedOrdersEndIndex$$.next(currentCompletedOrdersEndIndex + this.completedOrdersEndIncrement);
            }
        }
    };
    OrdersCompletedViewComponent.prototype.trackByPayloadDetails = function (_, details) {
        return details.payloadName;
    };
    return OrdersCompletedViewComponent;
}());
export { OrdersCompletedViewComponent };
