// package: cbv1
// file: proto/cybertron/payload/payload_api.proto

var proto_cybertron_payload_payload_api_pb = require("../../../proto/cybertron/payload/payload_api_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var PayloadAPI = (function () {
  function PayloadAPI() {}
  PayloadAPI.serviceName = "cbv1.PayloadAPI";
  return PayloadAPI;
}());

PayloadAPI.CreatePayload = {
  methodName: "CreatePayload",
  service: PayloadAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_payload_payload_api_pb.CreatePayloadRequest,
  responseType: proto_cybertron_payload_payload_api_pb.CreatePayloadResponse
};

PayloadAPI.DeletePayload = {
  methodName: "DeletePayload",
  service: PayloadAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_payload_payload_api_pb.DeletePayloadRequest,
  responseType: proto_cybertron_payload_payload_api_pb.DeletePayloadResponse
};

PayloadAPI.UpdatePayload = {
  methodName: "UpdatePayload",
  service: PayloadAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_payload_payload_api_pb.UpdatePayloadRequest,
  responseType: proto_cybertron_payload_payload_api_pb.UpdatePayloadResponse
};

PayloadAPI.GetPayload = {
  methodName: "GetPayload",
  service: PayloadAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_payload_payload_api_pb.GetPayloadRequest,
  responseType: proto_cybertron_payload_payload_api_pb.GetPayloadResponse
};

PayloadAPI.ListAllPayloads = {
  methodName: "ListAllPayloads",
  service: PayloadAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_payload_payload_api_pb.ListAllPayloadsRequest,
  responseType: proto_cybertron_payload_payload_api_pb.ListAllPayloadsResponse
};

PayloadAPI.ListRecentPayloads = {
  methodName: "ListRecentPayloads",
  service: PayloadAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_payload_payload_api_pb.ListRecentPayloadsRequest,
  responseType: proto_cybertron_payload_payload_api_pb.ListRecentPayloadsResponse
};

PayloadAPI.ListPayloads = {
  methodName: "ListPayloads",
  service: PayloadAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_payload_payload_api_pb.ListPayloadsRequest,
  responseType: proto_cybertron_payload_payload_api_pb.ListPayloadsResponse
};

PayloadAPI.ListPayloadTypes = {
  methodName: "ListPayloadTypes",
  service: PayloadAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_payload_payload_api_pb.ListPayloadTypesRequest,
  responseType: proto_cybertron_payload_payload_api_pb.ListPayloadTypesResponse
};

PayloadAPI.ListPayloadBundles = {
  methodName: "ListPayloadBundles",
  service: PayloadAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_payload_payload_api_pb.ListPayloadBundlesRequest,
  responseType: proto_cybertron_payload_payload_api_pb.ListPayloadBundlesResponse
};

PayloadAPI.ListUnits = {
  methodName: "ListUnits",
  service: PayloadAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_payload_payload_api_pb.ListUnitsRequest,
  responseType: proto_cybertron_payload_payload_api_pb.ListUnitsResponse
};

PayloadAPI.FavoritePayload = {
  methodName: "FavoritePayload",
  service: PayloadAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_payload_payload_api_pb.FavoritePayloadRequest,
  responseType: proto_cybertron_payload_payload_api_pb.FavoritePayloadResponse
};

PayloadAPI.SearchPayloads = {
  methodName: "SearchPayloads",
  service: PayloadAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_payload_payload_api_pb.SearchPayloadsRequest,
  responseType: proto_cybertron_payload_payload_api_pb.SearchPayloadsResponse
};

exports.PayloadAPI = PayloadAPI;

function PayloadAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

PayloadAPIClient.prototype.createPayload = function createPayload(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PayloadAPI.CreatePayload, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PayloadAPIClient.prototype.deletePayload = function deletePayload(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PayloadAPI.DeletePayload, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PayloadAPIClient.prototype.updatePayload = function updatePayload(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PayloadAPI.UpdatePayload, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PayloadAPIClient.prototype.getPayload = function getPayload(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PayloadAPI.GetPayload, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PayloadAPIClient.prototype.listAllPayloads = function listAllPayloads(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PayloadAPI.ListAllPayloads, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PayloadAPIClient.prototype.listRecentPayloads = function listRecentPayloads(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PayloadAPI.ListRecentPayloads, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PayloadAPIClient.prototype.listPayloads = function listPayloads(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PayloadAPI.ListPayloads, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PayloadAPIClient.prototype.listPayloadTypes = function listPayloadTypes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PayloadAPI.ListPayloadTypes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PayloadAPIClient.prototype.listPayloadBundles = function listPayloadBundles(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PayloadAPI.ListPayloadBundles, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PayloadAPIClient.prototype.listUnits = function listUnits(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PayloadAPI.ListUnits, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PayloadAPIClient.prototype.favoritePayload = function favoritePayload(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PayloadAPI.FavoritePayload, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PayloadAPIClient.prototype.searchPayloads = function searchPayloads(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PayloadAPI.SearchPayloads, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.PayloadAPIClient = PayloadAPIClient;

