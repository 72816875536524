import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { Site } from '~proto/site/site_pb';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-location-status-card',
  styleUrls: ['./location-status-card.component.scss'],
  templateUrl: './location-status-card.component.html',
})
export class LocationStatusCardComponent implements OnInit {
  public currentSite$: Observable<Site.AsObject>;
  public siteId$: Observable<number>;

  constructor(private siteService: JobSitesService) {}

  public ngOnInit() {
    this.currentSite$ = this.siteService.currentSite$.pipe(filter((site) => !!site));
    this.siteId$ = this.currentSite$.pipe(map((site) => site.id));
  }
}
