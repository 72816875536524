import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
var ChatBotComponent = /** @class */ (function () {
    function ChatBotComponent(chatbot) {
        this.chatbot = chatbot;
        this.chat$ = new Observable();
        this.formControl = new FormControl();
        this.hide = true;
    }
    ChatBotComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.chat$ = this.chatbot.chat$.pipe(distinctUntilChanged(), tap(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve) {
                            setTimeout(resolve, 200);
                        })];
                    case 1:
                        _a.sent();
                        this.scrollToBottom();
                        return [2 /*return*/];
                }
            });
        }); }));
        this.onRight$ = this.chatbot.onRight$;
    };
    ChatBotComponent.prototype.toggleHide = function () {
        this.hide = !this.hide;
        if (!this.hide && this.input && this.input.nativeElement && this.input.nativeElement.focus) {
            this.input.nativeElement.focus();
        }
    };
    ChatBotComponent.prototype.sendMessage = function () {
        this.chatbot.sendMessage(this.formControl.value);
        this.formControl.setValue('');
    };
    ChatBotComponent.prototype.scrollToBottom = function () {
        try {
            this.messages.nativeElement.scrollTop = this.messages.nativeElement.scrollHeight;
        }
        catch (err) { }
    };
    ChatBotComponent.prototype.escapeKey = function (event) {
        if (event.target.tagName !== 'BODY' &&
            event.target !== this.input.nativeElement) {
            return;
        }
        if (!this.hide) {
            this.hide = true;
            if (this.input.nativeElement) {
                this.input.nativeElement.blur();
            }
        }
    };
    ChatBotComponent.prototype.trackBy = function (_index, message) {
        return message.message;
    };
    return ChatBotComponent;
}());
export { ChatBotComponent };
