import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, filter, finalize, map, startWith, switchMap } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { PayloadService } from 'src/app/lmo/services/payload.service';
import { SelectPayloadTypeComponent } from '~common/select-payload-type/select-payload-type.component';
import { SelectPayloadUnitsComponent } from '~common/select-payload-units/select-payload-units.component';
import { SmartDropdownComponent } from '~common/smart-dropdown/smart-dropdown.component';
import { CreatePayloadRequest } from '~proto/payload/payload_api_pb';
import { Payload, PayloadGroup, Unit } from '~proto/payload/payload_pb';
import { AddStockRequest } from '~proto/site/site_api_pb';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { trackById } from '~utilities/trackById';

export interface DisabledPayloadGroup extends PayloadGroup.AsObject {
  disabled: true;
}

interface Form {
  payload: Payload.AsObject;
  maxQuantity: number;
  quantity: number;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-stock-add',
  styleUrls: ['./stock-add.component.scss'],
  templateUrl: './stock-add.component.html',
})
export class StockAddComponent implements OnInit {
  @ViewChild('smartDropdown', { static: true }) private smartDropdown: SmartDropdownComponent;
  public searching$: Observable<boolean>;
  public trackById = trackById;
  public textChanges$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public nonSitePayloads$: Observable<PayloadGroup.AsObject[]>;
  public formTouched = false;
  public networkActive$ = new BehaviorSubject<boolean>(false);
  public formGroup: FormGroup;
  public siteName$: Observable<string>;
  public filteredPayloadGroups$: Observable<(PayloadGroup.AsObject | DisabledPayloadGroup)[]>;
  private payloadsGroups$: Observable<(PayloadGroup.AsObject | DisabledPayloadGroup)[]>;

  constructor(
    private site: JobSitesService,
    private fb: FormBuilder,
    private payloadService: PayloadService,
    private matDialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  public ngOnInit() {
    this.siteName$ = this.site.currentSite$.pipe(
      filter((site) => !!site),
      map((site) => site.name),
    );

    this.formGroup = this.fb.group(
      {
        maxQuantity: [1, [Validators.required, Validators.min(1)]],
        payload: [null, Validators.required],
        quantity: [0, [Validators.required, this.quantityValidator('quantity', 'maxQuantity')]],
      },
      {
        validator: [this.quantityValidator('quantity', 'maxQuantity')],
      },
    );

    this.payloadsGroups$ = combineLatest([this.payloadService.currentSitePayloads$, this.site.currentSite$]).pipe(
      map(([payloadGroups, site]) => {
        const sitePayloads: Record<string, Payload.AsObject> = site.stockReferencesList.reduce((record, stock) => {
          return {
            ...record,
            [stock.payload.id]: stock.payload,
          };
        }, {});
        const pinned: Payload.AsObject[] = [];
        const unpinned: Payload.AsObject[] = [];
        const filteredGroups: (PayloadGroup.AsObject | DisabledPayloadGroup)[] = payloadGroups.map((group) => {
          group.payloadsList.forEach((payload) => {
            if (payload.favorited) {
              pinned.push(payload);
            } else {
              unpinned.push(payload);
            }
          });
          return {
            ...group,
            payloadsList: unpinned.filter((payload) => !sitePayloads[payload.id]),
          };
        });
        if (pinned.length) {
          filteredGroups.unshift({
            name: 'Pinned',
            payloadsList: pinned.filter((payload) => !sitePayloads[payload.id]),
          });
        }
        const sitePayloadsAsArray = Object.values(sitePayloads);
        if (sitePayloadsAsArray.length) {
          filteredGroups.push({
            disabled: true,
            name: 'Already On Site',
            payloadsList: sitePayloadsAsArray,
          });
        }
        return filteredGroups;
      }),
    );

    this.searching$ = this.payloadService.searching$;

    const searchResults$ = combineLatest([
      this.payloadsGroups$,
      this.textChanges$$.pipe(debounceTime(200)).pipe(
        switchMap((searchText) => {
          if (searchText && searchText.length >= 2) {
            return this.payloadService.searchPayloads$(searchText);
          }
          return of([] as Payload.AsObject[]);
        }),
      ),
    ]).pipe(
      map(([payloadGroups, searchResults]) => {
        if (!payloadGroups) {
          return searchResults;
        }
        const payloads = payloadGroups.reduce((arr, group) => [...arr, ...group.payloadsList], []);
        const payloadMap = idArrayToRecord(payloads);
        return searchResults.filter((payload) => !payloadMap[payload.id]);
      }),
    );

    this.filteredPayloadGroups$ = combineLatest([
      this.payloadsGroups$,
      this.textChanges$$.pipe(startWith('')),
      searchResults$,
    ]).pipe(
      map(([groups, text, searchResults]) => {
        if (!groups && !searchResults) {
          return [];
        }

        const cleanedItems = groups.map((item) => ({
          ...item,
          payloadsList: item.payloadsList.filter((entry) =>
            entry.name.toLocaleLowerCase().includes((text || '').toLocaleLowerCase()),
          ),
        }));

        const mergedGroups = [
          {
            name: 'search results',
            payloadsList: searchResults,
          },
          ...cleanedItems,
        ];

        return mergedGroups.filter((item) => item.payloadsList.length > 0);
      }),
    );
  }

  public inputTextChange(event: string) {
    this.textChanges$$.next(event.trim());
  }

  public togglePin(event: Event, payloadId: number, isFavorited: boolean) {
    event.stopPropagation();
    this.payloadService.updatePayloadFavorites(payloadId, isFavorited);
  }

  public selectPayload(payload: Payload.AsObject, isDisabled: boolean) {
    if (isDisabled) {
      return;
    }
    this.formGroup.patchValue({ payload });
    this.smartDropdown.close();
  }

  public async createNewPayload() {
    const createPayloadRequest = new CreatePayloadRequest();
    createPayloadRequest.setName(this.textChanges$$.value);

    const unitsDialog = this.matDialog.open(SelectPayloadUnitsComponent);
    unitsDialog.componentInstance.payloadName = createPayloadRequest.getName();
    const unit: Unit.AsObject = await unitsDialog.afterClosed().toPromise();
    if (!unit) {
      return;
    }
    createPayloadRequest.setUnitId(unit.id);

    const payloadTypeDialog = this.matDialog.open(SelectPayloadTypeComponent);
    payloadTypeDialog.componentInstance.payloadName = createPayloadRequest.getName();
    const payloadType: Payload.AsObject = await payloadTypeDialog.afterClosed().toPromise();
    if (!payloadType) {
      return;
    }
    createPayloadRequest.setPayloadTypeId(payloadType.id);

    this.payloadService.createPayload$(createPayloadRequest).subscribe((payload) => {
      if (payload) {
        this.selectPayload(payload, false);
      }
    });
  }

  public addPayloadToSite() {
    if (this.formGroup.invalid) {
      return;
    }
    this.networkActive$.next(true);
    const value = this.formGroup.value as Form;
    const request = new AddStockRequest();
    request.setPayloadId(value.payload.id);
    request.setMaxQuantity(value.maxQuantity);
    request.setQuantity(value.quantity);
    this.site
      .addStockToSite$(request)
      .pipe(finalize(() => this.networkActive$.next(false)))
      .subscribe(() => {
        this.router.navigate(['../'], { relativeTo: this.activatedRoute });
      });
  }

  public quantityValidator(quantity: string, maxQuantity: string) {
    return (group: FormGroup) => {
      const quantityControl = group.get(quantity);
      const maxQuantityControl = group.get(maxQuantity);

      if (!quantityControl || !maxQuantityControl) {
        return;
      }

      return quantityControl.value <= maxQuantityControl.value
        ? quantityControl.setErrors(null)
        : quantityControl.setErrors({ diff: true });
    };
  }
}
