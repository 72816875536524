import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-browser',
  styleUrls: ['./browser-check.component.scss'],
  templateUrl: './browser-check.component.html',
})
export class BrowserCheckComponent implements OnInit {
  @HostBinding('class') public classes = 'browser-warning';
  @HostBinding('class.wrong-browser') public isIE = false;

  constructor(
    public deviceService: DeviceDetectorService,
  ) {}
  public ngOnInit() {
    if ( this.deviceService.browser === 'IE' ) {
      this.isIE = true;
    }
  }
}
