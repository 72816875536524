/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../mini-modal/mini-modal.component.ngfactory";
import * as i3 from "../mini-modal/mini-modal.component";
import * as i4 from "../button/button.component.ngfactory";
import * as i5 from "../button/button.component";
import * as i6 from "./confirm-dialog.component";
import * as i7 from "@angular/material/dialog";
var styles_ConfirmDialogComponent = [i0.styles];
var RenderType_ConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmDialogComponent, data: {} });
export { RenderType_ConfirmDialogComponent as RenderType_ConfirmDialogComponent };
export function View_ConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "confirm-cancel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "ct-mini-modal", [], null, null, null, i2.View_MiniModalComponent_0, i2.RenderType_MiniModalComponent)), i1.ɵdid(2, 114688, null, 0, i3.MiniModalComponent, [], { title: [0, "title"] }, null), (_l()(), i1.ɵeld(3, 0, null, 1, 9, "footer", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["ct-button", ""]], [[2, "ct-button", null], [2, "button", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ButtonComponent_0, i4.RenderType_ButtonComponent)), i1.ɵdid(6, 49152, null, 0, i5.ButtonComponent, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(7, 16384, null, 0, i5.CtButtonDirective, [], null, null), (_l()(), i1.ɵted(8, 0, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["ct-danger-button", ""]], [[2, "ct-button", null], [2, "button-danger", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelConfirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ButtonComponent_0, i4.RenderType_ButtonComponent)), i1.ɵdid(10, 49152, null, 0, i5.ButtonComponent, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(11, 16384, null, 0, i5.CtDangerButtonDirective, [], null, null), (_l()(), i1.ɵted(12, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.title; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v, 7).ctButton; var currVal_2 = i1.ɵnov(_v, 7).secondary; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = _co.data.declineButtonText; _ck(_v, 8, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 11).ctButton; var currVal_5 = i1.ɵnov(_v, 11).secondary; _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_6 = _co.data.acceptButtonText; _ck(_v, 12, 0, currVal_6); }); }
export function View_ConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ct-confirm-dialog", [], null, null, null, View_ConfirmDialogComponent_0, RenderType_ConfirmDialogComponent)), i1.ɵdid(1, 114688, null, 0, i6.ConfirmDialogComponent, [i7.MatDialogRef, i7.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmDialogComponentNgFactory = i1.ɵccf("ct-confirm-dialog", i6.ConfirmDialogComponent, View_ConfirmDialogComponent_Host_0, {}, {}, []);
export { ConfirmDialogComponentNgFactory as ConfirmDialogComponentNgFactory };
