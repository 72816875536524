/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_cybertron_types_types_pb = require('../../../proto/cybertron/types/types_pb.js');
goog.exportSymbol('proto.cbv1.Alarm', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.Alarm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.Alarm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.Alarm.displayName = 'proto.cbv1.Alarm';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.Alarm.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.Alarm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.Alarm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Alarm.toObject = function(includeInstance, msg) {
  var f, obj = {
    compoundKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    alarmType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    actorId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    actor: jspb.Message.getFieldWithDefault(msg, 5, ""),
    actorUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    verb: jspb.Message.getFieldWithDefault(msg, 7, ""),
    object: jspb.Message.getFieldWithDefault(msg, 8, ""),
    objectId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    objectUrl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    siteId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    siteUrl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    additionalInfo: jspb.Message.getFieldWithDefault(msg, 13, ""),
    webhookUrl: jspb.Message.getFieldWithDefault(msg, 14, ""),
    expiresUnix: jspb.Message.getFieldWithDefault(msg, 15, 0),
    createdUnix: jspb.Message.getFieldWithDefault(msg, 16, 0),
    updatedUnix: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.Alarm}
 */
proto.cbv1.Alarm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.Alarm;
  return proto.cbv1.Alarm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.Alarm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.Alarm}
 */
proto.cbv1.Alarm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompoundKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlarmType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActorId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActor(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setActorUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerb(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setObject(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectUrl(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteUrl(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdditionalInfo(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebhookUrl(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpiresUnix(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedUnix(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdatedUnix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.Alarm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.Alarm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.Alarm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Alarm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompoundKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAlarmType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActorId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActorUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getVerb();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getObject();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getObjectId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getObjectUrl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getSiteUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAdditionalInfo();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getWebhookUrl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getExpiresUnix();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getUpdatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
};


/**
 * optional string compound_key = 2;
 * @return {string}
 */
proto.cbv1.Alarm.prototype.getCompoundKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.Alarm.prototype.setCompoundKey = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string alarm_type = 3;
 * @return {string}
 */
proto.cbv1.Alarm.prototype.getAlarmType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.Alarm.prototype.setAlarmType = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string actor_id = 4;
 * @return {string}
 */
proto.cbv1.Alarm.prototype.getActorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.Alarm.prototype.setActorId = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string actor = 5;
 * @return {string}
 */
proto.cbv1.Alarm.prototype.getActor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.Alarm.prototype.setActor = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string actor_url = 6;
 * @return {string}
 */
proto.cbv1.Alarm.prototype.getActorUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cbv1.Alarm.prototype.setActorUrl = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string verb = 7;
 * @return {string}
 */
proto.cbv1.Alarm.prototype.getVerb = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cbv1.Alarm.prototype.setVerb = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string object = 8;
 * @return {string}
 */
proto.cbv1.Alarm.prototype.getObject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.cbv1.Alarm.prototype.setObject = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string object_id = 9;
 * @return {string}
 */
proto.cbv1.Alarm.prototype.getObjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.cbv1.Alarm.prototype.setObjectId = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string object_url = 10;
 * @return {string}
 */
proto.cbv1.Alarm.prototype.getObjectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.cbv1.Alarm.prototype.setObjectUrl = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 site_id = 11;
 * @return {number}
 */
proto.cbv1.Alarm.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.cbv1.Alarm.prototype.setSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string site_url = 12;
 * @return {string}
 */
proto.cbv1.Alarm.prototype.getSiteUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.cbv1.Alarm.prototype.setSiteUrl = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string additional_info = 13;
 * @return {string}
 */
proto.cbv1.Alarm.prototype.getAdditionalInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.cbv1.Alarm.prototype.setAdditionalInfo = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string webhook_url = 14;
 * @return {string}
 */
proto.cbv1.Alarm.prototype.getWebhookUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.cbv1.Alarm.prototype.setWebhookUrl = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int32 expires_unix = 15;
 * @return {number}
 */
proto.cbv1.Alarm.prototype.getExpiresUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.cbv1.Alarm.prototype.setExpiresUnix = function(value) {
  jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 created_unix = 16;
 * @return {number}
 */
proto.cbv1.Alarm.prototype.getCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.cbv1.Alarm.prototype.setCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 updated_unix = 17;
 * @return {number}
 */
proto.cbv1.Alarm.prototype.getUpdatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {number} value */
proto.cbv1.Alarm.prototype.setUpdatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 17, value);
};


goog.object.extend(exports, proto.cbv1);
