import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { SmartDropdownComponent } from '~common/smart-dropdown/smart-dropdown.component';
import { RoleService } from '~services/role.service';
import { trackById } from '~utilities/trackById';
import { CreateOrderRecentsService } from '../../services/create-order-recents.service';

interface GroupedSalesOrderNumber {
  name: string;
  salesOrderNumbers: string[];
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-create-order-sales-order-number-selector',
  styleUrls: ['./create-order-sales-order-number-selector.component.scss'],
  templateUrl: './create-order-sales-order-number-selector.component.html',
})
export class CreateOrderSalesOrderNumberSelectorComponent implements OnInit {
  private topResult: string;
  private additionalRecentSalesOrderNumbers$$ = new BehaviorSubject<string[]>([]);
  @Output() public selected = new EventEmitter<{ orderNumber: string }>();
  @ViewChild('smartDropdown', { static: true }) private smartDropdown: SmartDropdownComponent;
  @Input() public salesOrderNumber: string;
  @Input() public invalid = false;

  @Input()
  public set additionalRecentSalesOrderNumbers(SOs: string[]) {
    if (SOs) {
      this.additionalRecentSalesOrderNumbers$$.next(SOs);
    }
  }

  public textChanges$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public isSalesOrderApiEnabled$$ = new BehaviorSubject<Boolean>(false);
  public salesOrderNumbers$: Observable<GroupedSalesOrderNumber[]>;
  public showCreateSOButton$: Observable<boolean>;
  public trackById = trackById;

  constructor(private recentService: CreateOrderRecentsService, private roleService: RoleService) {}

  public ngOnInit() {
    this.recentService.loadSalesOrder();
    this.salesOrderNumbers$ = combineLatest([
      this.recentService.salesOrders$,
      this.textChanges$$,
      this.additionalRecentSalesOrderNumbers$$,
      this.roleService.isSalesOrderApiEnabled$,
    ]).pipe(
      map(([salesOrders, text, additionalRecentSalesOrderNumbers, isSalesOrderApiEnabled]) => {
        if (!salesOrders) {
          return [];
        }
        const recent = isSalesOrderApiEnabled
          ? []
          : [...(salesOrders.recentlyUsedList || []), ...additionalRecentSalesOrderNumbers];
        const frequent = isSalesOrderApiEnabled ? [] : [...(salesOrders.frequentlyUsedList || [])];
        const asArray = [
          {
            name: 'search results',
            salesOrderNumbers: salesOrders.searchResults,
          },
          {
            name: 'recently used',
            salesOrderNumbers: Array.from(new Set(recent)),
          },
          {
            name: 'frequently used',
            salesOrderNumbers: frequent,
          },
        ];
        if (!text || text === '') {
          return asArray.filter((group) => group.salesOrderNumbers && group.salesOrderNumbers.length > 0);
        }
        return asArray
          .map((group) => ({
            name: group.name,
            salesOrderNumbers: (group.salesOrderNumbers || []).filter((s) => s.includes(text)),
          }))
          .filter((group) => group.salesOrderNumbers.length > 0);
      }),
    );

    this.showCreateSOButton$ = combineLatest([
      this.recentService.salesOrders$,
      this.textChanges$$.pipe(startWith(null as string)),
    ]).pipe(
      map(([salesOrders, text]) => {
        if (!text || !text.length) {
          return false;
        }

        if (!salesOrders) {
          return true;
        }

        return (
          (salesOrders.searchResults || []).every((so) => so !== text) &&
          (salesOrders.frequentlyUsedList || []).every((so) => so !== text) &&
          (salesOrders.recentlyUsedList || []).every((so) => so !== text)
        );
      }),
    );

    this.textChanges$$.pipe(debounceTime(250)).subscribe((text: string) => {
      this.recentService.searchSalesOrders(text);
    });

    this.checkIsSalesOrderApiEnabled();
  }

  public selectSalesOrderNumber(orderNumber: string) {
    this.selected.emit({ orderNumber });
    this.smartDropdown.close();
  }

  public addSalesOrderNumber() {
    if (this.textChanges$$.value && this.textChanges$$.value !== '') {
      this.selectSalesOrderNumber(this.textChanges$$.value);
    }
  }

  public inputTextChange(event: string) {
    this.textChanges$$.next(event.trim());
  }

  public enterKeyPressed() {
    if (!this.isSalesOrderApiEnabled$$.value) {
      if (this.topResult) {
        this.selectSalesOrderNumber(this.topResult);
      } else {
        this.addSalesOrderNumber();
      }
    }
  }

  public trackByName(_number: number, name: string): string {
    return name;
  }

  private checkIsSalesOrderApiEnabled() {
    this.roleService.isSalesOrderApiEnabled$.subscribe((isSalesOrderApiEnabled) => {
      this.isSalesOrderApiEnabled$$.next(isSalesOrderApiEnabled);
    });
  }
}
