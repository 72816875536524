import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, take, takeUntil } from 'rxjs/operators';
import { JobSitesService } from '~lmo/services/job-sites.service';
import { PayloadService } from '~lmo/services/payload.service';
import { Payload, PayloadGroup } from '~proto/payload/payload_pb';
import { Site } from '~proto/site/site_pb';
import { Trailer } from '~proto/trailer/trailer_pb';
import { TaskType, TaskTypeMap } from '~proto/types/types_pb';
import { moveItemInFormArray } from '~utilities/moveItemInFormArray';

export interface CustomStop {
  assetNumber: Trailer.AsObject;
  location: Site.AsObject;
  payload: Payload.AsObject;
  quantity: number;
  stopType: TaskTypeMap[keyof TaskTypeMap];
}

function noSameSiteNextToEachOther(): ValidatorFn {
  return (formArray: FormArray): { [key: string]: any } | null => {
    const hasSameSiteNextToEachOther = formArray.controls.some((control, index) => {
      // Nothing to compare to
      if (index === 0) {
        return false;
      }
      const value: CustomStop = control.value;
      const previousValue: CustomStop = formArray.controls[index - 1].value;
      return value.location && previousValue.location && value.location.id === previousValue.location.id;
    });
    return hasSameSiteNextToEachOther
      ? { sameNextSite: 'Cannot create two tasks with the same site next to each other' }
      : null;
  };
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CreateOrderCustomStopsComponent),
    },
    {
      multi: true,
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CreateOrderCustomStopsComponent),
    },
  ],
  selector: 'ct-create-order-custom-stops',
  styleUrls: ['./create-order-custom-stops.component.scss'],
  templateUrl: './create-order-custom-stops.component.html',
})
export class CreateOrderCustomStopsComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  private validationErrors: ValidationErrors;
  private destroy$ = new Subject();
  public customStops = new FormArray([]);
  public currentSite$: Observable<Site.AsObject>;
  public currentSitePayloads$: Observable<PayloadGroup.AsObject[]>;
  public taskTypes = TaskType;

  public stopTypes: { name: string; value: TaskTypeMap[keyof TaskTypeMap] }[] = [
    {
      name: 'Pickup Payload',
      value: TaskType.TASK_TYPE_PICKUP,
    },
    {
      name: 'Dropoff Payload',
      value: TaskType.TASK_TYPE_DROPOFF,
    },
    {
      name: 'Pickup Trailer',
      value: TaskType.TASK_TYPE_ASSET_PICKUP,
    },
    {
      name: 'Dropoff Trailer',
      value: TaskType.TASK_TYPE_RETURN_MATERIALS,
    },
    {
      name: 'Ad-Hoc (No Payload)',
      value: TaskType.TASK_TYPE_AD_HOC,
    },
  ];

  private customStopsValue(): CustomStop[] {
    return this.customStops.value;
  }

  constructor(
    private siteService: JobSitesService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private payloadService: PayloadService,
  ) {
    this.customStops.setValidators([noSameSiteNextToEachOther()]);
    this.addCurrentSiteLineItem();
  }

  public ngOnInit() {
    this.currentSite$ = this.siteService.currentSite$;
    this.currentSitePayloads$ = this.payloadService.currentSitePayloads$;
    this.setupValidationErrors();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public writeValue(obj: any): void {
    if (Array.isArray(obj) && obj.length === this.customStops.length) {
      this.customStops.setValue(obj);
    }
  }

  public registerOnChange(fn: any): void {
    this.customStops.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      fn(value);
    });
  }

  public registerOnTouched(fn: any): void {}

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.customStops.disable() : this.customStops.enable();
  }

  public validate(): ValidationErrors {
    return this.validationErrors;
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this.customStops.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      fn();
    });
  }

  public trackByControl(_index: number, control: FormControl) {
    return control;
  }

  public trackByName(_index: number, record: { name: string }) {
    return record.name;
  }

  public selectCustomOrderLineSite(index: number, event: { pickupFrom: Site.AsObject }) {
    this.customStops
      .at(index)
      .get('location')
      .setValue(event.pickupFrom);
  }

  public selectCustomOrderLinePayload(index: number, event: { payload: Payload.AsObject; quantity: number }) {
    this.customStops
      .at(index)
      .get('payload')
      .setValue(event.payload);

    this.customStops
      .at(index)
      .get('quantity')
      .setValue(event.quantity || 0);
  }

  public selectCustomOrderLineTrailer(index: number, event: { trailer: Trailer.AsObject }) {
    this.customStops
      .at(index)
      .get('assetNumber')
      .setValue(event.trailer);
  }

  public removeCustomOrderLink(index: number) {
    this.customStops.removeAt(index);
  }

  public addCustomOrderLine() {
    const fg = this.getNewFormLine();
    fg.get('stopType').setValue(TaskType.TASK_TYPE_PICKUP);
    this.customStops.push(fg);
  }

  public insertLineBefore(index: number) {
    const fg = this.getNewFormLine();
    fg.get('stopType').setValue(TaskType.TASK_TYPE_PICKUP);
    this.customStops.insert(index, fg);
  }

  public insertLineAfter(index: number) {
    if (index + 1 >= this.customStops.length) {
      this.addCustomOrderLine();
    } else {
      const fg = this.getNewFormLine();
      fg.get('stopType').setValue(TaskType.TASK_TYPE_PICKUP);
      this.customStops.insert(index + 1, fg);
    }
  }

  public drop(event: CdkDragDrop<FormGroup[]>) {
    moveItemInFormArray(this.customStops, event.previousIndex, event.currentIndex);
  }

  private async addCurrentSiteLineItem() {
    const site = await this.siteService.currentSite$
      .pipe(
        filter((s) => !!s),
        take(1),
      )
      .toPromise();
    const fg = this.getNewFormLine(site, true);
    fg.get('stopType').setValue(TaskType.TASK_TYPE_DROPOFF);
    this.customStops.push(fg);
    this.cd.markForCheck();
  }

  private getNewFormLine(site?: Site.AsObject, currentSite = false): FormGroup {
    const fg = this.fb.group({
      assetNumber: [null, [Validators.required]],
      location: [site, [Validators.required]],
      payload: [null, [Validators.required]],
      quantity: [null, [Validators.required]],
      stopType: [null, [Validators.required]],
    });

    if (currentSite) {
      fg.addControl('_isCurrentSite', this.fb.control(true));
    }

    fg.get('stopType')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(() => {
        switchCustomOrderLineTo(fg);
        this.prefillTrailerDropoffInfoIfTrailerDropoff(fg);
        this.prefillPayloadDropoffInfoIfPayloadDropoff(fg);
      });

    return fg;
  }

  private setupValidationErrors() {
    combineLatest([
      this.customStops.valueChanges.pipe(
        startWith(null as any),
        map(() => this.customStopsValue()),
      ),
      this.customStops.statusChanges,
    ])
      .pipe(
        map(() => {
          let hasError = false;
          const validationErrors: {
            [key: string]: any;
          } = {};
          if (this.customStops.invalid && this.customStops.errors) {
            hasError = true;
            validationErrors.customStops = {
              ...this.customStops.errors,
            };
          }
          this.customStops.controls.some((control) => {
            if (control.invalid) {
              hasError = true;
              validationErrors.customStopsLine = 'Please fill in all fields';
              return true;
            }
          });
          if (hasError) {
            return validationErrors;
          }
          return null;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((errors) => {
        this.validationErrors = errors;
      });
  }

  private prefillTrailerDropoffInfoIfTrailerDropoff(formGroup: FormGroup) {
    if (formGroup.get('stopType').value !== TaskType.TASK_TYPE_RETURN_MATERIALS) {
      return;
    }
    const pickupInfo = this.customStops.controls.find(
      (control) => control.get('stopType').value === TaskType.TASK_TYPE_ASSET_PICKUP,
    );
    if (!pickupInfo) {
      return;
    }
    if (formGroup.get('location').value === null) {
      formGroup.get('location').setValue(pickupInfo.get('location').value);
    }

    if (formGroup.get('assetNumber').value === null) {
      formGroup.get('assetNumber').setValue(pickupInfo.get('assetNumber').value);
    }
  }

  private async prefillPayloadDropoffInfoIfPayloadDropoff(formGroup: FormGroup) {
    if (formGroup.get('stopType').value !== TaskType.TASK_TYPE_DROPOFF) {
      return;
    }

    if (formGroup.get('location').value === null) {
      const currentSite = await this.siteService.currentSite$.pipe(take(1)).toPromise();
      formGroup.get('location').setValue(currentSite);
    }

    const stopsValue = this.customStopsValue();
    const payloadPickupStops = this.customStops.controls.filter((control) => {
      const value: CustomStop = control.value;
      return value.stopType === TaskType.TASK_TYPE_PICKUP && !!value.payload;
    });
    const payloadDropoffStops = stopsValue.filter((v) => v.stopType === TaskType.TASK_TYPE_DROPOFF);
    const firstUnmatchedPickup = payloadPickupStops.find((pickupStop) =>
      payloadDropoffStops.every(
        (dropoffStop) => !dropoffStop.payload || dropoffStop.payload.id !== pickupStop.value.payload.id,
      ),
    );

    if (!firstUnmatchedPickup) {
      return;
    }

    if (formGroup.get('payload').value === null) {
      formGroup.get('payload').setValue(firstUnmatchedPickup.get('payload').value);
    }

    if (formGroup.get('quantity').value === null) {
      formGroup.get('quantity').setValue(firstUnmatchedPickup.get('quantity').value);
    }
  }
}

function switchCustomOrderLineTo(formGroup: FormGroup) {
  const stopType = formGroup.get('stopType').value;
  switch (stopType) {
    case TaskType.TASK_TYPE_AD_HOC:
      formGroup.get('assetNumber').disable();
      formGroup.get('payload').disable();
      formGroup.get('quantity').disable();
      break;
    case TaskType.TASK_TYPE_ASSET_PICKUP:
    case TaskType.TASK_TYPE_RETURN_MATERIALS:
      formGroup.get('assetNumber').enable();
      formGroup.get('payload').disable();
      formGroup.get('quantity').disable();
      break;
    default:
      formGroup.get('assetNumber').disable();
      formGroup.get('payload').enable();
      formGroup.get('quantity').enable();
  }
}
