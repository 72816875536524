import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, finalize, map, take, tap } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { PayloadService } from 'src/app/lmo/services/payload.service';
import { SelectPayloadTypeComponent } from '~common/select-payload-type/select-payload-type.component';
import { SelectPayloadUnitsComponent } from '~common/select-payload-units/select-payload-units.component';
import { SmartDropdownComponent } from '~common/smart-dropdown/smart-dropdown.component';
import { CreatePayloadRequest } from '~proto/payload/payload_api_pb';
import { Payload, PayloadGroup, PayloadType, Unit } from '~proto/payload/payload_pb';
import { DeleteStockRequest, StockUpdate, UpdateStockRequest } from '~proto/site/site_api_pb';
import { Stock } from '~proto/site/site_pb';
import { trackById } from '~utilities/trackById';

export interface DisabledPayloadGroup extends PayloadGroup.AsObject {
  disabled: true;
}

interface Form {
  payload: Payload.AsObject;
  maxQuantity: number;
  quantity: number;
  stockId: number;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-stock-edit',
  styleUrls: ['./stock-edit.component.scss'],
  templateUrl: './stock-edit.component.html',
})
export class StockEditComponent implements OnInit {
  @ViewChild('smartDropdown', { static: true }) private smartDropdown: SmartDropdownComponent;
  @ViewChild('confirmRemove', { static: true }) private confirmRemove: TemplateRef<any>;
  public trackById = trackById;
  public textChanges$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public nonSitePayloads$: Observable<PayloadGroup.AsObject[]>;
  public formTouched = false;
  public networkActive$ = new BehaviorSubject<boolean>(false);
  public formGroup: FormGroup;
  public siteName$: Observable<string>;
  public currentStock$: Observable<Stock.AsObject>;
  public filteredPayloadGroups$: Observable<(PayloadGroup.AsObject | DisabledPayloadGroup)[]>;
  private payloadsGroups$: Observable<(PayloadGroup.AsObject | DisabledPayloadGroup)[]>;
  private confirmRemoveDialog: MatDialogRef<TemplateRef<any>>;

  constructor(
    private site: JobSitesService,
    private fb: FormBuilder,
    private payloadService: PayloadService,
    private matDialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  public ngOnInit() {
    this.formGroup = this.fb.group({
      maxQuantity: [null, [Validators.required, Validators.min(1)]],
      payload: [null, Validators.required],
      quantity: [null, Validators.required],
      stockId: [null],
    });
    this.siteName$ = this.site.currentSite$.pipe(
      filter((site) => !!site),
      map((site) => site.name),
    );

    this.currentStock$ = this.site.currentSiteStock$.pipe(
      filter((stock) => !!stock),
      take(1),
      tap((stock) => {
        this.formGroup.setValue({
          maxQuantity: stock.maxQuantity,
          payload: stock.payload,
          quantity: stock.quantity,
          stockId: stock.id,
        });
      }),
    );

    this.payloadsGroups$ = combineLatest([
      this.payloadService.currentSitePayloads$,
      this.site.currentSite$,
      this.currentStock$,
    ]).pipe(
      map(([payloadGroups, site, currentStock]) => {
        const sitePayloads: Record<string, Payload.AsObject> = site.stockReferencesList.reduce((record, stock) => {
          return {
            ...record,
            [stock.payload.id]: stock.payload,
          };
        }, {});
        const filteredGroups: (PayloadGroup.AsObject | DisabledPayloadGroup)[] = [
          {
            name: 'Current Payload',
            payloadsList: [currentStock.payload],
          },
        ];
        payloadGroups.forEach((group) => {
          filteredGroups.push({
            ...group,
            payloadsList: group.payloadsList.filter((payload) => !sitePayloads[payload.id]),
          });
        });
        const sitePayloadsAsArray = Object.values(sitePayloads);
        if (sitePayloadsAsArray.length) {
          filteredGroups.push({
            disabled: true,
            name: 'Already On Site',
            payloadsList: sitePayloadsAsArray,
          });
        }
        return filteredGroups;
      }),
    );

    this.filteredPayloadGroups$ = combineLatest([this.payloadsGroups$, this.textChanges$$]).pipe(
      map(([groups, text]) => {
        if (!groups) {
          return [];
        }
        if (!text || text === '') {
          return groups;
        }
        const cleanedItems = groups.map((item) => ({
          ...item,
          payloadsList: item.payloadsList.filter((entry) =>
            entry.name.toLocaleLowerCase().includes(text.toLocaleLowerCase()),
          ),
        }));
        return cleanedItems.filter((item) => item.payloadsList.length > 0);
      }),
    );
  }

  public inputTextChange(event: string) {
    this.textChanges$$.next(event.trim());
  }

  public togglePin(event: Event, payloadId: number, isFavorited: boolean) {
    event.stopPropagation();
    this.payloadService.updatePayloadFavorites(payloadId, isFavorited);
  }

  public selectPayload(payload: Payload.AsObject, isDisabled: boolean) {
    if (isDisabled) {
      return;
    }
    this.formGroup.patchValue({ payload });
    this.smartDropdown.close();
  }

  public async createNewPayload() {
    const createPayloadRequest = new CreatePayloadRequest();
    createPayloadRequest.setName(this.textChanges$$.value);

    const unitsDialog = this.matDialog.open(SelectPayloadUnitsComponent);
    unitsDialog.componentInstance.payloadName = createPayloadRequest.getName();
    const unit: Unit.AsObject = await unitsDialog.afterClosed().toPromise();
    if (!unit) {
      return;
    }
    createPayloadRequest.setUnitId(unit.id);

    const payloadTypeDialog = this.matDialog.open(SelectPayloadTypeComponent);
    payloadTypeDialog.componentInstance.payloadName = createPayloadRequest.getName();
    const payloadType: PayloadType.AsObject = await payloadTypeDialog.afterClosed().toPromise();
    if (!payloadType) {
      return;
    }
    createPayloadRequest.setPayloadTypeId(payloadType.id);

    this.payloadService.createPayload$(createPayloadRequest).subscribe((payload) => {
      if (payload) {
        this.selectPayload(payload, false);
      }
    });
  }

  public updateStock() {
    if (this.formGroup.invalid) {
      return;
    }
    this.networkActive$.next(true);
    const value = this.formGroup.value as Form;
    const request = new UpdateStockRequest();
    const stockUpdate = new StockUpdate();
    stockUpdate.setMaxQuantity(value.maxQuantity);
    stockUpdate.setQuantity(value.quantity);
    stockUpdate.setStockId(value.stockId);
    request.addStockUpdates(stockUpdate);
    this.site
      .updateSiteStock$(request)
      .pipe(finalize(() => this.networkActive$.next(false)))
      .subscribe(() => {
        this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
      });
  }

  public removeStockPrompt() {
    this.confirmRemoveDialog = this.matDialog.open(this.confirmRemove);
  }

  public removeStock() {
    const request = new DeleteStockRequest();
    const value = this.formGroup.value as Form;
    request.setStockId(value.stockId);
    this.networkActive$.next(true);
    this.site
      .removeSiteStock$(request)
      .pipe(finalize(() => this.networkActive$.next(false)))
      .subscribe(() => {
        if (this.confirmRemoveDialog) {
          this.confirmRemoveDialog.close();
        }
        this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
      });
  }
}
