import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountType } from '~proto/types/types_pb';
import { IsAccountTypeGuard } from '~services/guards/is-account-type.guard';
import { IsAdminRoleTypeGuard } from '~services/guards/is-admin-role-type.guard';
import * as fromRouterConstants from './app-routing.constants';
import * as fromContainers from './containers';

const routes: Routes = [
  {
    canActivate: [IsAccountTypeGuard],
    data: { accountTypes: [AccountType.ACCOUNT_TYPE_TRUCKING_VENDOR] },
    loadChildren: 'src/app/dispatcher/dispatcher.module#DispatcherModule',
    path: 'dispatcher',
  },
  {
    canActivate: [IsAccountTypeGuard, IsAdminRoleTypeGuard],
    data: { accountTypes: [AccountType.ACCOUNT_TYPE_TRUCKING_VENDOR, AccountType.ACCOUNT_TYPE_LMO] },
    loadChildren: 'src/app/billing/billing.module#BillingModule',
    path: 'billing',
  },
  {
    data: { accountTypes: [AccountType.ACCOUNT_TYPE_LMO] },
    loadChildren: 'src/app/lmo/lmo.module#LmoModule',
    path: 'lmo',
  },
  {
    canActivate: [IsAccountTypeGuard],
    component: fromContainers.TrailerManagementComponent,
    data: { accountTypes: [AccountType.ACCOUNT_TYPE_TRUCKING_VENDOR, AccountType.ACCOUNT_TYPE_LMO] },
    path: 'trailers',
  },
  {
    canActivate: [IsAccountTypeGuard],
    component: fromContainers.AddTrailerComponent,
    data: { accountTypes: [AccountType.ACCOUNT_TYPE_TRUCKING_VENDOR, AccountType.ACCOUNT_TYPE_LMO] },
    path: 'trailers/add',
  },
  {
    canActivate: [IsAccountTypeGuard],
    component: fromContainers.EditTrailerComponent,
    data: { accountTypes: [AccountType.ACCOUNT_TYPE_TRUCKING_VENDOR, AccountType.ACCOUNT_TYPE_LMO] },
    path: `trailers/:${fromRouterConstants.TRAILER_ID}/edit`,
  },
  {
    component: fromContainers.NotificationPreferencesComponent,
    path: 'notification-preferences',
  },
  {
    component: fromContainers.SigninWithCustomTokenComponent,
    path: 'sso/:jwt',
  },
  {
    component: fromContainers.ResetPasswordComponent,
    path: 'reset-password',
  },
  {
    component: fromContainers.LoginPageComponent,
    path: 'login',
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    component: fromContainers.UpdatePasswordComponent,
    path: 'update-password',
  },
  { path: 'shaleapps', loadChildren: () => import('./shaleapps/shaleapps.module').then((m) => m.ShaleappsModule) },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
})
export class AppRoutingModule {}
