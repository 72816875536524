import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';
import { ConfirmDialogComponent } from '~common/confirm-dialog/confirm-dialog.component';
import { DeleteTrailersRequest } from '~proto/trailer/trailer_api_pb';
import { fuse } from '~utilities/fuse';
var searchOptions = {
    distance: 100,
    keys: ['name', 'model.name'],
    location: 0,
    maxPatternLength: 16,
    minMatchCharLength: 1,
    shouldSort: true,
    threshold: 0.2,
};
var TrailerManagementComponent = /** @class */ (function () {
    function TrailerManagementComponent(trailerService, router, snackbar, dialog, constantsService) {
        this.trailerService = trailerService;
        this.router = router;
        this.snackbar = snackbar;
        this.dialog = dialog;
        this.constantsService = constantsService;
        this.trailerSearch = new FormControl();
        this.displayedColumns = ['name', 'model', 'location', 'lastUsed', 'service', 'actions'];
    }
    TrailerManagementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.networkActive$ = this.trailerService.networkActive$;
        this.trailers$ = combineLatest([
            this.trailerService.trailers$
                .pipe(map(function (trailers) { return _this.constantsService.sortDataValues(trailers); }), map(fuse(searchOptions))),
            this.trailerSearch.valueChanges.pipe(startWith(''), debounceTime(200)),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), fused = _b[0], searchTerm = _b[1];
            if (!searchTerm || searchTerm === '') {
                return fused.data;
            }
            return fused.fuse.search(searchTerm);
        }), map(function (trailers) {
            return new MatTableDataSource(trailers);
        }));
    };
    TrailerManagementComponent.prototype.trackById = function (_index, trailer) {
        return trailer.id;
    };
    TrailerManagementComponent.prototype.deleteTrailer = function (trailer, event) {
        var _this = this;
        event.stopPropagation();
        var dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                acceptButtonText: 'Delete',
                declineButtonText: 'Cancel',
                title: "Are you sure you want to delete " + trailer.name + " ?",
            },
        });
        dialogRef
            .afterClosed()
            .pipe(filter(function (data) { return !!data; }))
            .subscribe(function (result) {
            if (result) {
                var deleteTrailerReq = new DeleteTrailersRequest();
                deleteTrailerReq.setId(trailer.id);
                _this.trailerService.deleteTrailer(deleteTrailerReq).subscribe(function (trailers) {
                    _this.snackbar.open(trailers.toObject().status, null, {
                        duration: 2000,
                    });
                });
            }
        });
    };
    TrailerManagementComponent.prototype.openTrailer = function (row) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.router.navigate(['/', 'trailers', row.id, 'edit'])];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return TrailerManagementComponent;
}());
export { TrailerManagementComponent };
