import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { Payload } from '~proto/payload/payload_pb';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-select-payload-quantity',
  styleUrls: ['./select-payload-quantity.component.scss'],
  templateUrl: './select-payload-quantity.component.html',
})
export class SelectPayloadQuantityComponent implements OnInit, AfterViewInit {
  public fc = new FormControl(null, { validators: Validators.required });
  @Input() public payload: Payload.AsObject;
  @Output() public selectedQuantity = new EventEmitter<number>();
  @ViewChildren('customQuantity', { read: ElementRef }) public customQuanityInput: QueryList<
    ElementRef<HTMLInputElement>
  >;
  public customAmountStarted$ = new BehaviorSubject<boolean>(false);

  constructor(@Optional() private matDialogRef: MatDialogRef<SelectPayloadQuantityComponent>) {}

  public ngOnInit() {}

  public ngAfterViewInit() {
    this.customQuanityInput.changes.subscribe((inputs: QueryList<ElementRef<HTMLInputElement>>) => {
      if (inputs && inputs.length) {
        inputs.first.nativeElement.focus();
      }
    });
  }

  public customAmountStart() {
    this.customAmountStarted$.next(true);
  }

  public customAmountSelected() {
    if (this.fc.invalid) {
      return;
    }
    this.selected(this.fc.value);
  }

  public selected(amount: number) {
    this.selectedQuantity.emit(amount);
    if (this.matDialogRef) {
      this.matDialogRef.close(amount);
    }
  }
}
