/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_cybertron_payload_payload_pb = require('../../../proto/cybertron/payload/payload_pb.js');
var proto_cybertron_types_types_pb = require('../../../proto/cybertron/types/types_pb.js');
goog.exportSymbol('proto.cbv1.LMOBillingSetting', null, global);
goog.exportSymbol('proto.cbv1.LMOChargeType', null, global);
goog.exportSymbol('proto.cbv1.LMOUserPermission', null, global);
goog.exportSymbol('proto.cbv1.UserDetails', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOBillingSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.LMOBillingSetting.repeatedFields_, null);
};
goog.inherits(proto.cbv1.LMOBillingSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOBillingSetting.displayName = 'proto.cbv1.LMOBillingSetting';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.LMOBillingSetting.repeatedFields_ = [10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOBillingSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOBillingSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOBillingSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOBillingSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lmoId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deadheadThreshold: jspb.Message.getFieldWithDefault(msg, 3, 0),
    linehaulThreshold: jspb.Message.getFieldWithDefault(msg, 4, 0),
    noDetentionAutoApprove: jspb.Message.getFieldWithDefault(msg, 5, false),
    aboveLinehaulThreshold: jspb.Message.getFieldWithDefault(msg, 6, 0),
    useShaleappInvoiceNumber: jspb.Message.getFieldWithDefault(msg, 7, false),
    canUnapproveOrders: jspb.Message.getFieldWithDefault(msg, 8, false),
    singleOrderPerInvoice: jspb.Message.getFieldWithDefault(msg, 9, false),
    canApproveTicketList: jspb.Message.getRepeatedField(msg, 10),
    canApproveInvoiceList: jspb.Message.getRepeatedField(msg, 11),
    fastTrackWithoutImage: jspb.Message.getFieldWithDefault(msg, 12, false),
    expectedLineHaulThreshold: jspb.Message.getFieldWithDefault(msg, 13, 0),
    isDetentionFastTracked: jspb.Message.getFieldWithDefault(msg, 14, false),
    isExpectedLineHaulThresholdEnabled: jspb.Message.getFieldWithDefault(msg, 15, false),
    isPurchaseOrderRequired: jspb.Message.getFieldWithDefault(msg, 16, false),
    isCostCenterRequired: jspb.Message.getFieldWithDefault(msg, 17, false),
    isSalesOrderRequired: jspb.Message.getFieldWithDefault(msg, 18, false),
    canOrderAutoApproved: jspb.Message.getFieldWithDefault(msg, 19, false),
    salesOrderApiEnabled: jspb.Message.getFieldWithDefault(msg, 20, false),
    isMaintenanceOrderNumberRequired: jspb.Message.getFieldWithDefault(msg, 21, false),
    maintenanceOrderApiEnabled: jspb.Message.getFieldWithDefault(msg, 22, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOBillingSetting}
 */
proto.cbv1.LMOBillingSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOBillingSetting;
  return proto.cbv1.LMOBillingSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOBillingSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOBillingSetting}
 */
proto.cbv1.LMOBillingSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLmoId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeadheadThreshold(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLinehaulThreshold(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoDetentionAutoApprove(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAboveLinehaulThreshold(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseShaleappInvoiceNumber(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanUnapproveOrders(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSingleOrderPerInvoice(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addCanApproveTicket(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addCanApproveInvoice(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFastTrackWithoutImage(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpectedLineHaulThreshold(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDetentionFastTracked(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExpectedLineHaulThresholdEnabled(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPurchaseOrderRequired(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCostCenterRequired(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSalesOrderRequired(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanOrderAutoApproved(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSalesOrderApiEnabled(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMaintenanceOrderNumberRequired(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMaintenanceOrderApiEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOBillingSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOBillingSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOBillingSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOBillingSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLmoId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDeadheadThreshold();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLinehaulThreshold();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNoDetentionAutoApprove();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getAboveLinehaulThreshold();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getUseShaleappInvoiceNumber();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getCanUnapproveOrders();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getSingleOrderPerInvoice();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getCanApproveTicketList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getCanApproveInvoiceList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getFastTrackWithoutImage();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getExpectedLineHaulThreshold();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getIsDetentionFastTracked();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIsExpectedLineHaulThresholdEnabled();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getIsPurchaseOrderRequired();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getIsCostCenterRequired();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getIsSalesOrderRequired();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getCanOrderAutoApproved();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getSalesOrderApiEnabled();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getIsMaintenanceOrderNumberRequired();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getMaintenanceOrderApiEnabled();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.LMOBillingSetting.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.LMOBillingSetting.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 lmo_id = 2;
 * @return {number}
 */
proto.cbv1.LMOBillingSetting.prototype.getLmoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.LMOBillingSetting.prototype.setLmoId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 deadhead_threshold = 3;
 * @return {number}
 */
proto.cbv1.LMOBillingSetting.prototype.getDeadheadThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.LMOBillingSetting.prototype.setDeadheadThreshold = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 linehaul_threshold = 4;
 * @return {number}
 */
proto.cbv1.LMOBillingSetting.prototype.getLinehaulThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.LMOBillingSetting.prototype.setLinehaulThreshold = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool no_detention_auto_approve = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.LMOBillingSetting.prototype.getNoDetentionAutoApprove = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.cbv1.LMOBillingSetting.prototype.setNoDetentionAutoApprove = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int64 above_linehaul_threshold = 6;
 * @return {number}
 */
proto.cbv1.LMOBillingSetting.prototype.getAboveLinehaulThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.LMOBillingSetting.prototype.setAboveLinehaulThreshold = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool use_shaleapp_invoice_number = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.LMOBillingSetting.prototype.getUseShaleappInvoiceNumber = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.cbv1.LMOBillingSetting.prototype.setUseShaleappInvoiceNumber = function(value) {
  jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool can_unapprove_orders = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.LMOBillingSetting.prototype.getCanUnapproveOrders = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.cbv1.LMOBillingSetting.prototype.setCanUnapproveOrders = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool single_order_per_invoice = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.LMOBillingSetting.prototype.getSingleOrderPerInvoice = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.cbv1.LMOBillingSetting.prototype.setSingleOrderPerInvoice = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * repeated string can_approve_ticket = 10;
 * @return {!Array<string>}
 */
proto.cbv1.LMOBillingSetting.prototype.getCanApproveTicketList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/** @param {!Array<string>} value */
proto.cbv1.LMOBillingSetting.prototype.setCanApproveTicketList = function(value) {
  jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.LMOBillingSetting.prototype.addCanApproveTicket = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


proto.cbv1.LMOBillingSetting.prototype.clearCanApproveTicketList = function() {
  this.setCanApproveTicketList([]);
};


/**
 * repeated string can_approve_invoice = 11;
 * @return {!Array<string>}
 */
proto.cbv1.LMOBillingSetting.prototype.getCanApproveInvoiceList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/** @param {!Array<string>} value */
proto.cbv1.LMOBillingSetting.prototype.setCanApproveInvoiceList = function(value) {
  jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.LMOBillingSetting.prototype.addCanApproveInvoice = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


proto.cbv1.LMOBillingSetting.prototype.clearCanApproveInvoiceList = function() {
  this.setCanApproveInvoiceList([]);
};


/**
 * optional bool fast_track_without_image = 12;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.LMOBillingSetting.prototype.getFastTrackWithoutImage = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 12, false));
};


/** @param {boolean} value */
proto.cbv1.LMOBillingSetting.prototype.setFastTrackWithoutImage = function(value) {
  jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional int32 expected_line_haul_threshold = 13;
 * @return {number}
 */
proto.cbv1.LMOBillingSetting.prototype.getExpectedLineHaulThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.cbv1.LMOBillingSetting.prototype.setExpectedLineHaulThreshold = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional bool is_detention_fast_tracked = 14;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.LMOBillingSetting.prototype.getIsDetentionFastTracked = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 14, false));
};


/** @param {boolean} value */
proto.cbv1.LMOBillingSetting.prototype.setIsDetentionFastTracked = function(value) {
  jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool is_expected_line_haul_threshold_enabled = 15;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.LMOBillingSetting.prototype.getIsExpectedLineHaulThresholdEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 15, false));
};


/** @param {boolean} value */
proto.cbv1.LMOBillingSetting.prototype.setIsExpectedLineHaulThresholdEnabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool is_purchase_order_required = 16;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.LMOBillingSetting.prototype.getIsPurchaseOrderRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 16, false));
};


/** @param {boolean} value */
proto.cbv1.LMOBillingSetting.prototype.setIsPurchaseOrderRequired = function(value) {
  jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool is_cost_center_required = 17;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.LMOBillingSetting.prototype.getIsCostCenterRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 17, false));
};


/** @param {boolean} value */
proto.cbv1.LMOBillingSetting.prototype.setIsCostCenterRequired = function(value) {
  jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool is_sales_order_required = 18;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.LMOBillingSetting.prototype.getIsSalesOrderRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 18, false));
};


/** @param {boolean} value */
proto.cbv1.LMOBillingSetting.prototype.setIsSalesOrderRequired = function(value) {
  jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool can_order_auto_approved = 19;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.LMOBillingSetting.prototype.getCanOrderAutoApproved = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 19, false));
};


/** @param {boolean} value */
proto.cbv1.LMOBillingSetting.prototype.setCanOrderAutoApproved = function(value) {
  jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool sales_order_api_enabled = 20;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.LMOBillingSetting.prototype.getSalesOrderApiEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 20, false));
};


/** @param {boolean} value */
proto.cbv1.LMOBillingSetting.prototype.setSalesOrderApiEnabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool is_maintenance_order_number_required = 21;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.LMOBillingSetting.prototype.getIsMaintenanceOrderNumberRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 21, false));
};


/** @param {boolean} value */
proto.cbv1.LMOBillingSetting.prototype.setIsMaintenanceOrderNumberRequired = function(value) {
  jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool maintenance_order_api_enabled = 22;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.LMOBillingSetting.prototype.getMaintenanceOrderApiEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 22, false));
};


/** @param {boolean} value */
proto.cbv1.LMOBillingSetting.prototype.setMaintenanceOrderApiEnabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 22, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOUserPermission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.LMOUserPermission.repeatedFields_, null);
};
goog.inherits(proto.cbv1.LMOUserPermission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOUserPermission.displayName = 'proto.cbv1.LMOUserPermission';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.LMOUserPermission.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOUserPermission.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOUserPermission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOUserPermission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOUserPermission.toObject = function(includeInstance, msg) {
  var f, obj = {
    permission: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userIdsList: jspb.Message.getRepeatedField(msg, 2)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOUserPermission}
 */
proto.cbv1.LMOUserPermission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOUserPermission;
  return proto.cbv1.LMOUserPermission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOUserPermission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOUserPermission}
 */
proto.cbv1.LMOUserPermission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cbv1.UserPermissions} */ (reader.readEnum());
      msg.setPermission(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOUserPermission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOUserPermission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOUserPermission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOUserPermission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermission();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional UserPermissions permission = 1;
 * @return {!proto.cbv1.UserPermissions}
 */
proto.cbv1.LMOUserPermission.prototype.getPermission = function() {
  return /** @type {!proto.cbv1.UserPermissions} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.cbv1.UserPermissions} value */
proto.cbv1.LMOUserPermission.prototype.setPermission = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated string user_ids = 2;
 * @return {!Array<string>}
 */
proto.cbv1.LMOUserPermission.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<string>} value */
proto.cbv1.LMOUserPermission.prototype.setUserIdsList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.LMOUserPermission.prototype.addUserIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.cbv1.LMOUserPermission.prototype.clearUserIdsList = function() {
  this.setUserIdsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOChargeType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOChargeType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOChargeType.displayName = 'proto.cbv1.LMOChargeType';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOChargeType.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOChargeType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOChargeType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOChargeType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lmoId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isDefaultForType: jspb.Message.getFieldWithDefault(msg, 4, false),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    abbrev: jspb.Message.getFieldWithDefault(msg, 6, ""),
    ediCode: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOChargeType}
 */
proto.cbv1.LMOChargeType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOChargeType;
  return proto.cbv1.LMOChargeType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOChargeType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOChargeType}
 */
proto.cbv1.LMOChargeType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLmoId(value);
      break;
    case 3:
      var value = /** @type {!proto.cbv1.ChargeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDefaultForType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbbrev(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdiCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOChargeType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOChargeType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOChargeType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOChargeType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLmoId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getIsDefaultForType();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAbbrev();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEdiCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.LMOChargeType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.LMOChargeType.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 lmo_id = 2;
 * @return {number}
 */
proto.cbv1.LMOChargeType.prototype.getLmoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.LMOChargeType.prototype.setLmoId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ChargeType type = 3;
 * @return {!proto.cbv1.ChargeType}
 */
proto.cbv1.LMOChargeType.prototype.getType = function() {
  return /** @type {!proto.cbv1.ChargeType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.cbv1.ChargeType} value */
proto.cbv1.LMOChargeType.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool is_default_for_type = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.LMOChargeType.prototype.getIsDefaultForType = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.cbv1.LMOChargeType.prototype.setIsDefaultForType = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.cbv1.LMOChargeType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.LMOChargeType.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string abbrev = 6;
 * @return {string}
 */
proto.cbv1.LMOChargeType.prototype.getAbbrev = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cbv1.LMOChargeType.prototype.setAbbrev = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string edi_code = 7;
 * @return {string}
 */
proto.cbv1.LMOChargeType.prototype.getEdiCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cbv1.LMOChargeType.prototype.setEdiCode = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UserDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UserDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UserDetails.displayName = 'proto.cbv1.UserDetails';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UserDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UserDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UserDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UserDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UserDetails}
 */
proto.cbv1.UserDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UserDetails;
  return proto.cbv1.UserDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UserDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UserDetails}
 */
proto.cbv1.UserDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UserDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UserDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UserDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UserDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.cbv1.UserDetails.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.UserDetails.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.UserDetails.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.UserDetails.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.cbv1.UserDetails.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.UserDetails.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


goog.object.extend(exports, proto.cbv1);
