/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./button.component";
var styles_ButtonComponent = [i0.styles];
var RenderType_ButtonComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ButtonComponent, data: {} });
export { RenderType_ButtonComponent as RenderType_ButtonComponent };
export function View_ButtonComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_ButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["ct-button", ""]], null, null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i1.ɵdid(1, 49152, null, 0, i2.ButtonComponent, [i1.ElementRef, i1.Renderer2], null, null)], null, null); }
var ButtonComponentNgFactory = i1.ɵccf("button[ct-button],button[ct-secondary-button],button[ct-danger-button],button[ct-dark-button],button[ct-gray-button],a[ct-button],a[ct-secondary-button],a[ct-danger-button],a[ct-dark-button]a[ct-gray-button]", i2.ButtonComponent, View_ButtonComponent_Host_0, { disabled: "disabled" }, {}, ["*"]);
export { ButtonComponentNgFactory as ButtonComponentNgFactory };
