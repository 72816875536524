/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./labeled-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../directives/automated-selector.directive";
import * as i3 from "@angular/common";
import * as i4 from "./labeled-button.component";
var styles_LabeledButtonComponent = [i0.styles];
var RenderType_LabeledButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LabeledButtonComponent, data: {} });
export { RenderType_LabeledButtonComponent as RenderType_LabeledButtonComponent };
function View_LabeledButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
function View_LabeledButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }); }
export function View_LabeledButtonComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "labeled-button"]], [[1, "data-aid", 0], [1, "data-aid-name", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.click.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 81920, null, 0, i2.AutomatedSelectorDirective, [i1.ChangeDetectorRef], { ctAutomatedSelector: [0, "ctAutomatedSelector"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LabeledButtonComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LabeledButtonComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.automatedSelector; _ck(_v, 1, 0, currVal_2); var currVal_3 = _co.label; _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.text; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).automatedTag; var currVal_1 = i1.ɵnov(_v, 1).automatedDataName; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_LabeledButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ct-labeled-button", [], null, null, null, View_LabeledButtonComponent_0, RenderType_LabeledButtonComponent)), i1.ɵdid(1, 114688, null, 0, i4.LabeledButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LabeledButtonComponentNgFactory = i1.ɵccf("ct-labeled-button", i4.LabeledButtonComponent, View_LabeledButtonComponent_Host_0, { automatedSelector: "automatedSelector", label: "label", text: "text" }, { click: "click" }, []);
export { LabeledButtonComponentNgFactory as LabeledButtonComponentNgFactory };
