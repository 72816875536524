import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, map, startWith, take } from 'rxjs/operators';
import { OrdersService } from 'src/app/lmo/services/orders.service';
import { SmartDropdownComponent } from '~common/smart-dropdown/smart-dropdown.component';
import { Trailer } from '~proto/trailer/trailer_pb';
import { CreateTruckRequest } from '~proto/truck/truck_api_pb';
import { Truck } from '~proto/truck/truck_pb';
import { fuse } from '~utilities/fuse';
import { trackById } from '~utilities/trackById';
import { TruckAndTrailerService } from '../../services/truck-and-trailer.service';
import {
  AssignedAssetActionsComponent,
  ConfirmEvent,
} from '../assigned-asset-actions/assigned-asset-actions.component';

const searchOptions: Fuse.FuseOptions<Trailer.AsObject> = {
  distance: 100,
  keys: ['name'],
  location: 0,
  maxPatternLength: 16,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.1,
};

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-order-details-edit-trailer-selector',
  styleUrls: ['./order-details-edit-trailer-selector.component.scss'],
  templateUrl: './order-details-edit-trailer-selector.component.html',
})
export class OrderDetailsEditTrailerSelectorComponent implements OnInit {
  @Output() public selected = new EventEmitter<{ trailer: Truck.AsObject }>();
  @Input() public trailer: Truck.AsObject;
  @Input() public invalid = false;
  @ViewChild(SmartDropdownComponent, { static: true }) private smartDropdown: SmartDropdownComponent;
  public trailers$: Observable<Truck.AsObject[]>;
  public trackById = trackById;
  public textChanges$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private trailerService: TruckAndTrailerService,
    private orderService: OrdersService,
    private matDialog: MatDialog,
  ) {}

  public ngOnInit() {
    this.trailers$ = combineLatest([
      this.trailerService.trailers$.pipe(map(fuse<Trailer.AsObject>(searchOptions))),
      this.textChanges$$.pipe(
        debounceTime(100),
        startWith(''),
      ),
      this.orderService.currentOrder$,
    ]).pipe(
      map(([fused, text]) => {
        if (!text || text === '') {
          return fused.data;
        }
        return fused.fuse.search(text);
      }),
    );
  }

  public addTrailer() {
    const name = this.textChanges$$.value;
    if (!name || name === '') {
      return;
    }
    const request = new CreateTruckRequest();
    request.setName(name);
    this.trailerService.addTrailer$(request).subscribe((truck) => {
      this.select(truck);
    });
  }

  public async select(trailer: Trailer.AsObject) {
    if (trailer.isAssigned) {
      this.orderService.currentOrder$
        .pipe(
          filter((order) => !!order),
          take(1),
        )
        .subscribe(async (currentOrder) => {
          if (currentOrder.trailer.id !== trailer.id) {
            // trailer is in use, prompt reassign action
            const reassignDialog = this.matDialog.open(AssignedAssetActionsComponent);
            reassignDialog.componentInstance.title = `Trailer ${trailer.name} Is Already In Use`;

            const confirm: ConfirmEvent = await reassignDialog.afterClosed().toPromise();
            if (!confirm.reassign) {
              // close without selecting trailer
              this.smartDropdown.close();
              return;
            }

            // unassign trailer, return to form with trailer selected
            this.trailerService.unassignTrailer$(trailer.id, confirm.complete).subscribe(() => {
              this.selected.emit({ trailer });
              this.smartDropdown.close();
              return;
            });
          } else {
            // ALready on this order
            this.selected.emit({ trailer });
            this.smartDropdown.close();
          }
        });
    } else {
      // trailer not in use, can select and close dropdown
      this.selected.emit({ trailer });
      this.smartDropdown.close();
    }
  }

  public inputTextChange(event: string) {
    this.textChanges$$.next(event.trim());
  }
}
