import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateCustomFieldSettingRequest,
  CreateCustomFieldSettingResponse,
  GetCustomFieldSettingsRequest,
  GetCustomFieldSettingsResponse,
  UpdateCustomFieldSettingsRequest,
  UpdateCustomFieldSettingsResponse,
} from '~proto/fields/fields_api_pb';
import { FieldsAPI } from '~proto/fields/fields_api_pb_service';
import { CustomFieldSetting } from '~proto/fields/fields_pb';
import { CanLoadBeBackHaulRequest, CanLoadBeBackHaulResponse } from '~proto/order/order_api_pb';
import { OrderAPI } from '~proto/order/order_api_pb_service';
import { GrpcService } from '~services/grpc.service';

const customFieldResource = 'orders';

export const customFieldsTypes = ['string', 'number', 'boolean'];

@Injectable({
  providedIn: 'root',
})
export class LmoCustomOrderFieldsService {
  private fields$$ = new BehaviorSubject<CustomFieldSetting.AsObject[]>([]);

  public get fields$(): Observable<CustomFieldSetting.AsObject[]> {
    this.loadCustomFields();
    return this.fields$$.asObservable();
  }

  constructor(private grpcService: GrpcService, private snackBar: MatSnackBar) {}

  public createField(request: CreateCustomFieldSettingRequest) {
    request.setCustomFieldResource(customFieldResource);
    this.grpcService
      .invoke$(FieldsAPI.CreateCustomFieldSetting, request)
      .subscribe((response: CreateCustomFieldSettingResponse) => {
        this.fields$$.next(response.toObject().customFieldSettingsList);
      });
  }

  public updateFields(request: UpdateCustomFieldSettingsRequest) {
    request.setCustomFieldResource(customFieldResource);
    this.grpcService
      .invoke$(FieldsAPI.UpdateCustomFieldSettings, request)
      .subscribe((response: UpdateCustomFieldSettingsResponse) => {
        this.fields$$.next(response.toObject().customFieldSettingsList);
        this.snackBar.open('Custom Fields Updated', null, { duration: 2000 });
      });
  }

  private loadCustomFields() {
    const request = new GetCustomFieldSettingsRequest();
    request.setCustomFieldResource(customFieldResource);
    this.grpcService
      .invoke$(FieldsAPI.GetCustomFieldSettings, request)
      .subscribe((response: GetCustomFieldSettingsResponse) => {
        this.fields$$.next(response.toObject().customFieldSettingsList);
      });
  }

  public canLoadBeBackHaulRequest$(orderId: number): Observable<CanLoadBeBackHaulResponse.AsObject> {
    const request = new CanLoadBeBackHaulRequest();
    request.setLoadId(orderId);
    return (this.grpcService.invoke$(OrderAPI.CanLoadBeBackHaul, request) as Observable<
      CanLoadBeBackHaulResponse
    >).pipe(map((response) => response.toObject()));
  }
}
