import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import * as LogRocket from 'logrocket';
import { filter, take } from 'rxjs/operators';
import { environment } from '~environments/environment';
var AppComponent = /** @class */ (function () {
    function AppComponent(_routerState, authService, intercom) {
        this._routerState = _routerState;
        this.authService = authService;
        this.intercom = intercom;
        this.title = 'HAULi';
        this.enableLogRocket();
    }
    AppComponent.prototype.getRouterOutletState = function (outlet) {
        return outlet.isActivated ? outlet.activatedRoute : '';
    };
    AppComponent.prototype.isE2eTesting = function () {
        return JSON.parse(localStorage.getItem('e2e')) === true;
    };
    AppComponent.prototype.enableLogRocket = function () {
        var _this = this;
        if (window.location.hostname === 'localhost') {
            return;
        }
        var logRocketOptions = {};
        if (environment.production) {
            LogRocket.init('stkslu/hauli-prod', logRocketOptions);
        }
        this.authService.isLoggedIn$
            .pipe(filter(function (t) { return t; }), take(1))
            .subscribe(function (isLoggedIn) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var accountName, user;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isLoggedIn) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.authService.accountName$.pipe(take(1)).toPromise()];
                    case 1:
                        accountName = _a.sent();
                        user = this.authService.user;
                        LogRocket.identify(user.uid, {
                            accountName: accountName,
                            email: user.email,
                            name: user.displayName,
                            phone: user.phoneNumber,
                        });
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    };
    AppComponent.prototype.ngOnInit = function () { };
    return AppComponent;
}());
export { AppComponent };
