import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { Bidder, SearchBidderRequest, SearchBidderResponse } from '~proto/marketplace/rfq_api_pb';
import { MarketplaceBidder } from '~proto/marketplace/rfq_pb';
import { MarketplaceService } from '~services/marketplace.service';
import { trackById } from '~utilities/trackById';
import { RfqFormService, RfqVendorData } from '../../services/rfq-form.service';
import { RfqAddBidderDialogComponent } from '../rfq-add-bidder-dialog/rfq-add-bidder-dialog.component';

@Component({
  selector: 'ct-rfq-vendor-form',
  styleUrls: ['./rfq-vendor-form.component.scss'],
  templateUrl: './rfq-vendor-form.component.html',
})
export class RfqVendorFormComponent implements OnInit {
  public trackById = trackById;
  public rfqVendorForm: FormGroup;
  public networkActive$$ = new BehaviorSubject<boolean>(false);

  public selectable = true;
  public suggestedVendors: MarketplaceBidder.AsObject[] = [];
  public vendorSuggestions: MarketplaceBidder.AsObject[] = [];
  public selectedVendors: Bidder[] = [];
  public rfqType$ = new BehaviorSubject<string>('');
  constructor(
    private fb: FormBuilder,
    private marketplaceService: MarketplaceService,
    private matDialog: MatDialog,
    private rfqFormService: RfqFormService,
    private router: Router,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
  ) {}

  private get vendorName() {
    return this.rfqVendorForm.get('vendor').value;
  }

  public ngOnInit() {
    this.getSuggestions();
    this.rfqType$.next(this.route.parent.snapshot.params.type);
    this.rfqFormService.setRfqType(this.rfqType$.value);
    if (this.rfqFormService.isFirstFormNotFilled()) {
      this.router.navigate(['', 'lmo', 'marketplace', 'schedule-rfq', this.rfqType$.value, '1']);
    } else {
      this.buildForm();
    }
  }

  private getSuggestions() {
    this.marketplaceService.rfqSuggestions$
      .pipe(map((suggestions) => (suggestions && suggestions.biddersList) || []))
      .subscribe((biddersList) => {
        this.suggestedVendors = [...biddersList];
        this.vendorSuggestions = [...biddersList];
      });
  }

  private buildForm() {
    this.rfqVendorForm = this.fb.group({
      // message: [null, []],
      vendor: [null, [Validators.required]],
    });

    this.rfqVendorForm.controls['vendor'].valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe((value: string) => {
        if (value) {
          this.filterSuggestedVendor();
          const searchBidderRequest = new SearchBidderRequest();
          searchBidderRequest.setEmail(value.toLowerCase());
          this.marketplaceService.searchVendor(searchBidderRequest).subscribe((vendors: SearchBidderResponse) => {
            this.suggestedVendors = [...vendors.toObject().biddersList];
            this.filterSuggestedVendor();
          });
        } else {
          this.suggestedVendors = [...this.vendorSuggestions];
          this.filterSuggestedVendor();
        }
      });
  }

  public remove(selectedVendor: any): void {
    const index = this.selectedVendors.indexOf(selectedVendor);

    if (index >= 0) {
      this.selectedVendors.splice(index, 1);
      this.filterSuggestedVendor();
    }
  }

  public showAddVendor() {
    return (
      this.vendorName &&
      !this.selectedVendors.find(
        (vendor: Bidder) =>
          vendor.getName().toLowerCase() === this.vendorName.toLowerCase() ||
          vendor.getEmail().toLowerCase() === this.vendorName.toLowerCase(),
      ) &&
      !this.suggestedVendors.find(
        (vendor) =>
          vendor.name.toLowerCase() === this.vendorName.toLowerCase() ||
          vendor.email.toLowerCase() === this.vendorName.toLowerCase(),
      )
    );
  }

  public async addVendor() {
    const addVendorDialogRef = this.matDialog.open(RfqAddBidderDialogComponent, {
      data: this.vendorName,
    });
    addVendorDialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((vendorData: Bidder) => {
        this.setSelectedVendor(vendorData);
      });
  }

  public addExistingVendor(selectedvendor: MarketplaceBidder.AsObject) {
    const vendor = new Bidder();
    vendor.setId(selectedvendor.id);
    vendor.setName(selectedvendor.name);
    vendor.setEmail(selectedvendor.email);
    vendor.setAccount(selectedvendor.account);
    this.setSelectedVendor(vendor);
  }

  private setSelectedVendor(vendor: Bidder) {
    this.selectedVendors.push(vendor);
    this.filterSuggestedVendor();
    this.rfqVendorForm.controls['vendor'].setValue('');
  }

  private filterSuggestedVendor() {
    this.suggestedVendors = this.suggestedVendors.filter((vendor) => {
      return !this.selectedVendors.find((selectedVendor) => selectedVendor.getId() === vendor.id);
    });
  }

  public createRFQ() {
    if (this.selectedVendors.length) {
      const rfqVendorRequest: RfqVendorData = {
        vendors: this.selectedVendors,
      };
      this.rfqFormService.saveFormData(rfqVendorRequest, 'VENDOR_DATA');
    } else {
      this.snackbar.open('Please select a valid vendor', null, {
        duration: 2000,
        panelClass: ['snackbar-error'],
      });
    }
  }

  public trackByGetId(_index: number, bidder: Bidder): number {
    return bidder.getId();
  }
}
