import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import * as R from 'remeda';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { Site, Stock } from '~proto/site/site_pb';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-job-detail-dashboard',
  styleUrls: ['./job-detail-dashboard.component.scss'],
  templateUrl: './job-detail-dashboard.component.html',
})
export class JobDetailDashboardComponent implements OnInit {
  public sortedStocks$: Observable<Stock.AsObject[]>;
  public site$: Observable<Site.AsObject>;
  public stockUpdatedTime$: Observable<number>;

  constructor(private siteService: JobSitesService) {}

  public ngOnInit() {
    this.site$ = this.siteService.currentSite$.pipe(filter((site) => !!site));
    this.stockUpdatedTime$ = this.site$.pipe(
      map((site) => site.stockReferencesList.reduce((prev, current) => Math.max(prev, current.updatedUnix), 0)),
    );
    this.sortedStocks$ = this.site$.pipe(
      map((site) =>
        R.sort(site.stockReferencesList, (a, b) =>
          a.payload.name.toLocaleLowerCase().localeCompare(b.payload.name.toLocaleLowerCase()),
        ),
      ),
    );
  }
}
