import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  CreateLMOVendorPayloadTypePercentageRequest,
  VendorPayloadTypePercentage,
} from '~proto/contracts/contracts_api_pb';
import { VendorContract } from '~proto/contracts/contracts_pb';
import { PayloadType } from '~proto/payload/payload_pb';
import { ConstantsService } from '~services/constants.service';
import { trackById } from '~utilities/trackById';
import { ContractsService } from '../../services/contracts.service';
interface VendorSummary {
  id: number;
  name: string;
  percentage: FormControl;
}

interface PayloadTypeGroup {
  id: number;
  name: string;
  vendors: VendorSummary[];
  percentTotal: number;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-lmo-trucking-contracts-percentages',
  styleUrls: ['./lmo-trucking-contracts-percentages.component.scss'],
  templateUrl: './lmo-trucking-contracts-percentages.component.html',
})
export class LmoTruckingContractsPercentagesComponent implements OnInit {
  public trackById = trackById;
  public networkActive$ = new BehaviorSubject<boolean>(false);
  public formArray = new FormArray([]);
  public payloadTypeGroups: PayloadTypeGroup[] = [];

  constructor(
    private contractsService: ContractsService,
    private constantsService: ConstantsService,
    private cd: ChangeDetectorRef,
    private snackbar: MatSnackBar,
  ) {}

  public ngOnInit() {
    combineLatest([this.contractsService.nonExpiredContracts$, this.contractsService.percentages$])
      .pipe(
        filter(([contracts, _percentages]) => !!contracts && contracts.length > 0),
        map(([contracts, percentages]) => {
          return groupByPayloadType(contracts, percentages, this.constantsService.payloadTypes);
        }),
      )
      .subscribe((groups) => {
        this.networkActive$.next(false);
        this.payloadTypeGroups = groups;
        this.cd.markForCheck();
      });
  }

  public hasPercentOver100() {
    return this.payloadTypeGroups.some((g) => g.percentTotal > 100);
  }

  public async updatePercents() {
    const updates = new CreateLMOVendorPayloadTypePercentageRequest();
    // Check that none of the percentages are above 100
    if (this.hasPercentOver100()) {
      this.snackbar.open(
        'The total allocation of some of payloads is over 100%. Please adjust distribution to not exceed 100%.',
        null,
        {
          duration: 4000,
          panelClass: 'snackbar-error',
        },
      );
      return;
    }

    this.payloadTypeGroups.forEach((g) => {
      g.vendors.forEach((vendor) => {
        const percentUpdate = new VendorPayloadTypePercentage();
        percentUpdate.setPayloadTypeId(g.id);
        percentUpdate.setPercentage(+vendor.percentage.value);
        percentUpdate.setVendorId(vendor.id);
        updates.addVendorPayloadTypePercentages(percentUpdate);
      });
    });
    this.networkActive$.next(true);
    this.contractsService.updatePercentages(updates);
  }
}

function groupByPayloadType(
  contracts: VendorContract.AsObject[],
  percentages: Record<number, Record<number, number>>,
  payloadTypes: Record<string, PayloadType.AsObject>,
): PayloadTypeGroup[] {
  const groups: Record<number, Record<string, VendorSummary>> = {};
  contracts.forEach((contract) => {
    contract.payloadtypesList.forEach((payloadType) => {
      const currentPercentage = (percentages[payloadType.id] && percentages[payloadType.id][contract.vendor.id]) || 0;
      groups[payloadType.id] = {
        ...(groups[payloadType.id] || {}),
        [contract.vendor.id]: {
          id: contract.vendor.id,
          name: contract.vendor.name,
          percentage: new FormControl(currentPercentage, [Validators.min(0), Validators.max(100)]),
        },
      };
    });
  });
  return convertPayloadTypeRecordToPayloadTypeGroups(groups, payloadTypes);
}

function convertPayloadTypeRecordToPayloadTypeGroups(
  grouped: Record<number, Record<string, VendorSummary>>,
  payloadTypes: Record<string, PayloadType.AsObject>,
): PayloadTypeGroup[] {
  const returner: PayloadTypeGroup[] = [];
  Object.entries(grouped)
    .map(([key, value]) => ({ key, value }))
    .forEach(({ key, value }) => {
      const payloadType = payloadTypes[key];
      if (!payloadType) {
        return;
      }
      const payloadGroup = {
        ...payloadType,
        percentTotal: 0,
        vendors: Object.values(value).sort(sortVendors),
      };

      payloadGroup.vendors.forEach((vendor) => {
        vendor.percentage.valueChanges.subscribe(() => {
          payloadGroup.percentTotal = payloadGroup.vendors.reduce((acc, cur) => acc + +cur.percentage.value, 0);
        });
      });

      payloadGroup.percentTotal = payloadGroup.vendors.reduce((acc, cur) => acc + cur.percentage.value, 0);
      returner.push(payloadGroup);
    });
  return returner.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
}

function sortVendors(a: VendorSummary, b: VendorSummary): number {
  return a.percentage.value - b.percentage.value !== 0
    ? a.percentage.value - b.percentage.value
    : a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
}
