import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { SmartDropdownComponent } from '~common/smart-dropdown/smart-dropdown.component';
import { CostCenter } from '~proto/order/order_pb';
import { ConstantsService } from '~services/constants.service';
import { trackById } from '~utilities/trackById';
import { CreateOrderRecentsService } from '../../services/create-order-recents.service';
import { CostCenterService } from './../../../billing/services/cost-center.service';

interface GroupedCostCenter {
  name: string;
  costCenters: CostCenter.AsObject[];
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-create-order-cost-center-selector',
  styleUrls: ['./create-order-cost-center-selector.component.scss'],
  templateUrl: './create-order-cost-center-selector.component.html',
})
export class CreateOrderCostCenterSelectorComponent implements OnInit {
  // private topResult: string;
  public trackById = trackById;
  private additionalRecentCCs$$ = new BehaviorSubject<string[]>([]);
  @Output() public selected = new EventEmitter<{ costCenter: string; costCenterId: number }>();
  @ViewChild('smartDropdown', { static: true }) private smartDropdown: SmartDropdownComponent;
  @ViewChild('newCostCenter', { static: true }) private newCostCenterFormModal: TemplateRef<any>;
  @Input() public costCenterName: string;
  @Input() public invalid = false;
  @Input() public isDisabled: boolean;

  @Input() set businessLineId(businessLineId: number) {
    this.businessLine = businessLineId;
    this.textChanges$$.next('');
  }

  public textChanges$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public costCenterName$: Observable<GroupedCostCenter[]>;
  public showCreateCCButton$: Observable<boolean>;
  private newCostCenterFormDialog: MatDialogRef<any>;
  public newCostCenterForm = new FormControl();
  public newCostCenterId = new FormControl();
  public businessLine: number;

  constructor(
    private recentService: CreateOrderRecentsService,
    private matDialog: MatDialog,
    private costCenterService: CostCenterService,
    private snackBar: MatSnackBar,
    private constantsService: ConstantsService,
  ) {}

  public ngOnInit() {
    this.costCenterName$ = combineLatest([
      this.recentService.costCenters$.pipe(map((costCenters) => this.constantsService.sortDataValues(costCenters))),
      this.textChanges$$.pipe(startWith('')),
      this.additionalRecentCCs$$,
    ]).pipe(
      map(([costCenters, text, additionalRecentCCs]) => {
        if (!costCenters) {
          return [];
        }
        const asArray = [
          {
            costCenters: costCenters,
            name: 'search results',
          },
        ];
        if (!text || text === '') {
          return asArray.filter((group) => group.costCenters && group.costCenters.length > 0);
        }
        return asArray
          .map((group) => ({
            costCenters: (group.costCenters || []).filter((s) => s.name.toLowerCase().includes(text.toLowerCase())),
            name: group.name,
          }))
          .filter((group) => group.costCenters.length > 0);
      }),
    );
    this.showCreateCCButton$ = combineLatest([
      this.recentService.costCenters$,
      this.textChanges$$.pipe(startWith(null as string)),
    ]).pipe(
      map(([costCenterNames, text]) => {
        if (!text || !text.length) {
          return false;
        }

        return (costCenterNames || []).every((po) => po.name !== text);
      }),
    );

    this.textChanges$$.pipe(debounceTime(250)).subscribe((text: string) => {
      this.recentService.searchCostCenters(text, this.businessLine);
    });
  }

  public selectCostCenterName(costCenter: CostCenter.AsObject) {
    if (costCenter) {
      this.selected.emit({ costCenter: costCenter.name, costCenterId: costCenter.id });
    } else {
      this.selected.emit({ costCenter: '', costCenterId: -1 });
    }
    this.smartDropdown.close();
  }

  public addCostCenterName() {
    this.newCostCenterFormDialog = this.matDialog.open(this.newCostCenterFormModal, {
      maxWidth: '500px;',
    });
    this.newCostCenterFormDialog.afterOpened().subscribe(() => {
      this.newCostCenterForm.setValue(this.textChanges$$.value || '');
      this.newCostCenterId.setValue(0);
    });
  }

  public inputTextChange(event: string) {
    this.textChanges$$.next(event.trim());
  }

  public createCostCenter() {
    const costCenterName = this.newCostCenterForm.value;
    this.costCenterService.createCostCenter$(costCenterName, []).subscribe(
      (costCenter) => {
        this.closeTab();
        this.snackBar.open(`Cost Center has been created`, null, {
          duration: 2500,
        });
      },
      (err) => {
        this.snackBar.open('Something Went Wrong', null, {
          duration: 2500,
          panelClass: ['snackbar-error'],
        });
      },
    );
  }

  public closeTab() {
    this.newCostCenterFormDialog.close();
    this.newCostCenterFormDialog = null;
  }
}
