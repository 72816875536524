import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InputDirective } from '~common/directives/input.directive';
import { NumberDirective } from '~common/directives/number.directive';
import { getInputTextWidth } from '~utilities/getTextWidth';

const space = 24;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-input',
  styleUrls: ['./input.component.scss'],
  templateUrl: './input.component.html',
})
export class InputComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$$ = new Subject();
  @Input() public suffix: string;
  @Input() public staticLabel = false;
  @Input() public label: string;
  @Input() public rows: number;
  @ContentChild(NumberDirective, { static: false }) private ctNumber: NumberDirective;
  @ContentChild(InputDirective, { static: false }) private ctInput: InputDirective;
  @ViewChild('suffixRef', { static: false }) private suffixRef: ElementRef<HTMLDivElement>;

  constructor(private renderer: Renderer2) {}

  public ngOnInit() {}

  public ngAfterViewInit() {
    if (this.ctNumber) {
      this.ctNumber.text$.pipe(takeUntil(this.destroy$$)).subscribe((text) => {
        if (this.suffixRef) {
          if (text && text.length > 0) {
            const width = getInputTextWidth(text);
            this.renderer.setAttribute(
              this.suffixRef.nativeElement,
              'style',
              `display: block; left: ${width + space}px`,
            );
          } else {
            this.renderer.setAttribute(this.suffixRef.nativeElement, 'style', ``);
          }
        }
      });
    }

    if (this.ctInput) {
      this.ctInput.text$.pipe(takeUntil(this.destroy$$)).subscribe((text) => {
        if (!text || text.length === 0) {
          text = ' ';
        }
        if (this.suffixRef) {
          const width = getInputTextWidth(text);
          this.renderer.setAttribute(this.suffixRef.nativeElement, 'style', `display: block; left: ${width + space}px`);
        } else {
          this.renderer.setAttribute(this.suffixRef.nativeElement, 'style', ``);
        }
      });
    }
  }

  public ngOnDestroy() {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }
}
