import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, finalize, map, shareReplay, startWith, take } from 'rxjs/operators';
import { ConfirmDialogComponent } from '~common/confirm-dialog/confirm-dialog.component';
import { RoleService } from '~services/role.service';
import { UserV2 } from '../../../../proto/user/user/user_pb';
import { UsersService } from '../../services/users.service';

const MAX_FILE_SIZE = 25 * 1024 * 1024;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-lmo-user-management',
  styleUrls: ['./lmo-user-management.component.scss'],
  templateUrl: './lmo-user-management.component.html',
})
export class LmoUserManagementComponent implements OnInit {
  public users$: Observable<MatTableDataSource<UserV2.AsObject>>;
  public userSearch: FormControl = new FormControl();
  public networkActive$$ = new BehaviorSubject<boolean>(false);
  public displayedColumns = ['name', 'email', 'role', 'actions'];
  public uploadDialog;
  public isBusinessLineEnabled$$ = new BehaviorSubject<Boolean>(false);
  public canCrudUsers$: Observable<boolean>;

  public setUserRoles = {
    1: 'Admin',
    2: 'Default',
    4: 'View Only',
  };

  @ViewChild('userTemplateAction', { static: true })
  private userTemplateActionModal: TemplateRef<any>;

  constructor(
    public userService: UsersService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private roleService: RoleService,
  ) {
    this.canCrudUsers$ = this.userService.canCrudUsers$.pipe(
      filter((c) => c.initialized),
      map((c) => c.canCrud),
      shareReplay(1),
    );
  }

  public ngOnInit() {
    this.users$ = combineLatest([
      this.userService.users$,
      this.userSearch.valueChanges.pipe(
        startWith(''),
        debounceTime(200),
      ),
    ]).pipe(
      map(([users, searchTerm]) => {
        if (!searchTerm || searchTerm === '') {
          return users;
        }
        return users.filter(
          (user) =>
            user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase()),
        );
      }),
      map((users) => {
        return new MatTableDataSource(users);
      }),
    );
    this.checkIfBusinessLineEnabled();
  }

  public trackByAuthId(_index: number, user: UserV2.AsObject) {
    return user.authId;
  }

  public async editUser(row) {
    const canCrud = await this.canCrudUsers$.pipe(take(1)).toPromise();
    if (!canCrud) {
      return;
    }
    await this.router.navigate(['/lmo/', 'users', row.authId, 'edit']);
  }

  public removeUser(user: UserV2.AsObject, event) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        acceptButtonText: 'Delete',
        declineButtonText: 'Cancel',
        title: `Are you sure you want to delete ${user.name} ?`,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(filter((data) => !!data))
      .subscribe((result) => {
        const authId = user.authId;
        if (!authId) {
          return;
        }
        this.networkActive$$.next(true);
        this.userService
          .unlinkUser$(authId)
          .pipe(
            finalize(() => {
              this.networkActive$$.next(false);
            }),
          )
          .subscribe(
            (response) => {
              this.snackBar.open('User remove successfully', null, {
                duration: 2000,
              });
            },
            (err) => {
              // todo: check error message
              const error = err.error;
              this.snackBar.open('Error:' + (error.Message ? error.Message : error), null, {
                duration: 5000,
              });
            },
          );
      });
  }

  public onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      if (file.size > MAX_FILE_SIZE) {
        this.snackBar.open('File Size cannot be greater than 25 MB', null, {
          duration: 2500,
        });
      } else {
        this.uploadFile(file);
      }
    }
  }

  private uploadFile(fileToUpload) {
    this.networkActive$$.next(true);
    this.userService.userBulkUploadFile$(fileToUpload).subscribe(
      (response) => {
        this.networkActive$$.next(false);
        this.snackBar.open('File uploaded successfully', null, {
          duration: 2000,
        });
        this.uploadDialog.close();
      },
      (err) => {
        // todo: check error message
        this.networkActive$$.next(false);
        const error = err.error;
        this.snackBar.open('Error:' + (error.Message ? error.Message : error), null, {
          duration: 5000,
        });
        this.uploadDialog.close();
      },
    );
  }

  public userFileAction() {
    this.uploadDialog = this.dialog.open(this.userTemplateActionModal, {
      maxWidth: '500px;',
    });
  }

  private checkIfBusinessLineEnabled() {
    this.roleService.isBusinessLineEnabled$.subscribe((isBusinessLineEnabled) => {
      this.isBusinessLineEnabled$$.next(isBusinessLineEnabled);
    });
  }

  public closeModal() {
    setTimeout(() => this.uploadDialog.close(), 1000);
  }
}
