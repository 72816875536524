import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '~environments/environment';

const errorMessages = {
  'auth/too-many-requests': 'Too many requests, please wait a few minutes and try again',
  'auth/user-not-found': 'We were unable to find your account, please check the spelling of your email and try again.',
};

@Component({
  selector: 'ct-reset-password',
  styleUrls: ['./reset-password.component.scss'],
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
  public matcher: ErrorStateMatcher;
  public resetForm: FormGroup;
  public status: 'awaitingUserInput' | 'submitting' | 'success' | 'error' = 'error';
  public errorMessage: string = null;

  constructor(private fb: FormBuilder, private activatedRoute: ActivatedRoute, private fireAuth: AngularFireAuth) {}

  public ngOnInit() {
    const email = this.activatedRoute.snapshot.queryParamMap.get('email');
    this.resetForm = this.fb.group({ email: [email ? email : '', [Validators.email, Validators.required]] });
  }

  public sendResetEmail() {
    if (this.resetForm.valid) {
      this.status = 'submitting';
      this.fireAuth.auth
        .sendPasswordResetEmail(this.resetForm.get('email').value, { url: getContinueUrl() })
        .then(() => (this.status = 'success'))
        .catch((error) => {
          this.status = 'error';
          this.errorMessage = errorMessages[error.code] || 'Something went wrong, please contact ShaleApps support';
        });
    }
  }
}

function getContinueUrl() {
  if (window.location.hostname === 'localhost') {
    return `http://localhost:${window.location.port}/sandi`;
  }
  switch (environment.environment) {
    case 'dev':
      return 'https://dev.sand.shaleapps.com/sandi';
    case 'stage':
      return 'https://stage.sand.shaleapps.com/sandi';
    default:
      return 'https://sand.shaleapps.com/sandi';
  }
}
