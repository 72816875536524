/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_geo_geo_pb = require('../../../proto/geo/geo_pb.js');
var proto_cybertron_account_account_pb = require('../../../proto/cybertron/account/account_pb.js');
var proto_cybertron_user_user_pb = require('../../../proto/cybertron/user/user_pb.js');
var proto_cybertron_types_types_pb = require('../../../proto/cybertron/types/types_pb.js');
goog.exportSymbol('proto.cbv1.MarketplacRfqFile', null, global);
goog.exportSymbol('proto.cbv1.MarketplaceBid', null, global);
goog.exportSymbol('proto.cbv1.MarketplaceBidder', null, global);
goog.exportSymbol('proto.cbv1.MarketplaceRfq', null, global);
goog.exportSymbol('proto.cbv1.MarketplaceRfqBidder', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.MarketplaceRfq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.MarketplaceRfq.repeatedFields_, null);
};
goog.inherits(proto.cbv1.MarketplaceRfq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.MarketplaceRfq.displayName = 'proto.cbv1.MarketplaceRfq';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.MarketplaceRfq.repeatedFields_ = [5,6,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.MarketplaceRfq.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.MarketplaceRfq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.MarketplaceRfq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MarketplaceRfq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    well: (f = msg.getWell()) && proto_geo_geo_pb.Point.toObject(includeInstance, f),
    payload: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lmo: (f = msg.getLmo()) && proto_cybertron_account_account_pb.Account.toObject(includeInstance, f),
    biddersList: jspb.Message.toObjectList(msg.getBiddersList(),
    proto.cbv1.MarketplaceRfqBidder.toObject, includeInstance),
    bidsList: jspb.Message.toObjectList(msg.getBidsList(),
    proto.cbv1.MarketplaceBid.toObject, includeInstance),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createdUnix: jspb.Message.getFieldWithDefault(msg, 8, 0),
    updatedUnix: jspb.Message.getFieldWithDefault(msg, 9, 0),
    status: jspb.Message.getFieldWithDefault(msg, 10, 0),
    logisticCost: jspb.Message.getFieldWithDefault(msg, 11, 0),
    jobStartTime: jspb.Message.getFieldWithDefault(msg, 12, 0),
    jobEndTime: jspb.Message.getFieldWithDefault(msg, 13, 0),
    maxPrice: jspb.Message.getFieldWithDefault(msg, 14, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 15, 0),
    biddingStartTime: jspb.Message.getFieldWithDefault(msg, 16, 0),
    biddingEndTime: jspb.Message.getFieldWithDefault(msg, 17, 0),
    unit: jspb.Message.getFieldWithDefault(msg, 18, ""),
    filesList: jspb.Message.toObjectList(msg.getFilesList(),
    proto.cbv1.MarketplacRfqFile.toObject, includeInstance),
    user: (f = msg.getUser()) && proto_cybertron_user_user_pb.User.toObject(includeInstance, f),
    awardedBidId: jspb.Message.getFieldWithDefault(msg, 21, 0),
    rfqType: jspb.Message.getFieldWithDefault(msg, 22, 0),
    freightTypeName: jspb.Message.getFieldWithDefault(msg, 23, ""),
    pickupLocation: (f = msg.getPickupLocation()) && proto_geo_geo_pb.Point.toObject(includeInstance, f),
    pickupTime: jspb.Message.getFieldWithDefault(msg, 25, 0),
    description: jspb.Message.getFieldWithDefault(msg, 26, ""),
    dropOffTime: jspb.Message.getFieldWithDefault(msg, 27, 0),
    distance: jspb.Message.getFieldWithDefault(msg, 28, 0),
    minBidReduction: jspb.Message.getFieldWithDefault(msg, 29, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.MarketplaceRfq}
 */
proto.cbv1.MarketplaceRfq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.MarketplaceRfq;
  return proto.cbv1.MarketplaceRfq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.MarketplaceRfq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.MarketplaceRfq}
 */
proto.cbv1.MarketplaceRfq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_geo_geo_pb.Point;
      reader.readMessage(value,proto_geo_geo_pb.Point.deserializeBinaryFromReader);
      msg.setWell(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    case 4:
      var value = new proto_cybertron_account_account_pb.Account;
      reader.readMessage(value,proto_cybertron_account_account_pb.Account.deserializeBinaryFromReader);
      msg.setLmo(value);
      break;
    case 5:
      var value = new proto.cbv1.MarketplaceRfqBidder;
      reader.readMessage(value,proto.cbv1.MarketplaceRfqBidder.deserializeBinaryFromReader);
      msg.addBidders(value);
      break;
    case 6:
      var value = new proto.cbv1.MarketplaceBid;
      reader.readMessage(value,proto.cbv1.MarketplaceBid.deserializeBinaryFromReader);
      msg.addBids(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedUnix(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdatedUnix(value);
      break;
    case 10:
      var value = /** @type {!proto.cbv1.MarketplaceRfqStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLogisticCost(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobStartTime(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobEndTime(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxPrice(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBiddingStartTime(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBiddingEndTime(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 19:
      var value = new proto.cbv1.MarketplacRfqFile;
      reader.readMessage(value,proto.cbv1.MarketplacRfqFile.deserializeBinaryFromReader);
      msg.addFiles(value);
      break;
    case 20:
      var value = new proto_cybertron_user_user_pb.User;
      reader.readMessage(value,proto_cybertron_user_user_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAwardedBidId(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRfqType(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setFreightTypeName(value);
      break;
    case 24:
      var value = new proto_geo_geo_pb.Point;
      reader.readMessage(value,proto_geo_geo_pb.Point.deserializeBinaryFromReader);
      msg.setPickupLocation(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPickupTime(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDropOffTime(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDistance(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinBidReduction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.MarketplaceRfq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.MarketplaceRfq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.MarketplaceRfq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MarketplaceRfq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getWell();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_geo_geo_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLmo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_cybertron_account_account_pb.Account.serializeBinaryToWriter
    );
  }
  f = message.getBiddersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.cbv1.MarketplaceRfqBidder.serializeBinaryToWriter
    );
  }
  f = message.getBidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.cbv1.MarketplaceBid.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getUpdatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getLogisticCost();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getJobStartTime();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getJobEndTime();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getMaxPrice();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getBiddingStartTime();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getBiddingEndTime();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.cbv1.MarketplacRfqFile.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto_cybertron_user_user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getAwardedBidId();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getRfqType();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getFreightTypeName();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getPickupLocation();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto_geo_geo_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getPickupTime();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getDropOffTime();
  if (f !== 0) {
    writer.writeInt32(
      27,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0) {
    writer.writeInt32(
      28,
      f
    );
  }
  f = message.getMinBidReduction();
  if (f !== 0) {
    writer.writeInt32(
      29,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.MarketplaceRfq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceRfq.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Point well = 2;
 * @return {?proto.cbv1.Point}
 */
proto.cbv1.MarketplaceRfq.prototype.getWell = function() {
  return /** @type{?proto.cbv1.Point} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Point, 2));
};


/** @param {?proto.cbv1.Point|undefined} value */
proto.cbv1.MarketplaceRfq.prototype.setWell = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cbv1.MarketplaceRfq.prototype.clearWell = function() {
  this.setWell(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.MarketplaceRfq.prototype.hasWell = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string payload = 3;
 * @return {string}
 */
proto.cbv1.MarketplaceRfq.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.MarketplaceRfq.prototype.setPayload = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Account lmo = 4;
 * @return {?proto.cbv1.Account}
 */
proto.cbv1.MarketplaceRfq.prototype.getLmo = function() {
  return /** @type{?proto.cbv1.Account} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_account_account_pb.Account, 4));
};


/** @param {?proto.cbv1.Account|undefined} value */
proto.cbv1.MarketplaceRfq.prototype.setLmo = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.cbv1.MarketplaceRfq.prototype.clearLmo = function() {
  this.setLmo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.MarketplaceRfq.prototype.hasLmo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated MarketplaceRfqBidder bidders = 5;
 * @return {!Array<!proto.cbv1.MarketplaceRfqBidder>}
 */
proto.cbv1.MarketplaceRfq.prototype.getBiddersList = function() {
  return /** @type{!Array<!proto.cbv1.MarketplaceRfqBidder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.MarketplaceRfqBidder, 5));
};


/** @param {!Array<!proto.cbv1.MarketplaceRfqBidder>} value */
proto.cbv1.MarketplaceRfq.prototype.setBiddersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.cbv1.MarketplaceRfqBidder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.MarketplaceRfqBidder}
 */
proto.cbv1.MarketplaceRfq.prototype.addBidders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.cbv1.MarketplaceRfqBidder, opt_index);
};


proto.cbv1.MarketplaceRfq.prototype.clearBiddersList = function() {
  this.setBiddersList([]);
};


/**
 * repeated MarketplaceBid bids = 6;
 * @return {!Array<!proto.cbv1.MarketplaceBid>}
 */
proto.cbv1.MarketplaceRfq.prototype.getBidsList = function() {
  return /** @type{!Array<!proto.cbv1.MarketplaceBid>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.MarketplaceBid, 6));
};


/** @param {!Array<!proto.cbv1.MarketplaceBid>} value */
proto.cbv1.MarketplaceRfq.prototype.setBidsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.cbv1.MarketplaceBid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.MarketplaceBid}
 */
proto.cbv1.MarketplaceRfq.prototype.addBids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.cbv1.MarketplaceBid, opt_index);
};


proto.cbv1.MarketplaceRfq.prototype.clearBidsList = function() {
  this.setBidsList([]);
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.cbv1.MarketplaceRfq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cbv1.MarketplaceRfq.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 created_unix = 8;
 * @return {number}
 */
proto.cbv1.MarketplaceRfq.prototype.getCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceRfq.prototype.setCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 updated_unix = 9;
 * @return {number}
 */
proto.cbv1.MarketplaceRfq.prototype.getUpdatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceRfq.prototype.setUpdatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional MarketplaceRfqStatus status = 10;
 * @return {!proto.cbv1.MarketplaceRfqStatus}
 */
proto.cbv1.MarketplaceRfq.prototype.getStatus = function() {
  return /** @type {!proto.cbv1.MarketplaceRfqStatus} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {!proto.cbv1.MarketplaceRfqStatus} value */
proto.cbv1.MarketplaceRfq.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional int32 logistic_cost = 11;
 * @return {number}
 */
proto.cbv1.MarketplaceRfq.prototype.getLogisticCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceRfq.prototype.setLogisticCost = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 job_start_time = 12;
 * @return {number}
 */
proto.cbv1.MarketplaceRfq.prototype.getJobStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceRfq.prototype.setJobStartTime = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 job_end_time = 13;
 * @return {number}
 */
proto.cbv1.MarketplaceRfq.prototype.getJobEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceRfq.prototype.setJobEndTime = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 max_price = 14;
 * @return {number}
 */
proto.cbv1.MarketplaceRfq.prototype.getMaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceRfq.prototype.setMaxPrice = function(value) {
  jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 quantity = 15;
 * @return {number}
 */
proto.cbv1.MarketplaceRfq.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceRfq.prototype.setQuantity = function(value) {
  jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 bidding_start_time = 16;
 * @return {number}
 */
proto.cbv1.MarketplaceRfq.prototype.getBiddingStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceRfq.prototype.setBiddingStartTime = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 bidding_end_time = 17;
 * @return {number}
 */
proto.cbv1.MarketplaceRfq.prototype.getBiddingEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceRfq.prototype.setBiddingEndTime = function(value) {
  jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string unit = 18;
 * @return {string}
 */
proto.cbv1.MarketplaceRfq.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/** @param {string} value */
proto.cbv1.MarketplaceRfq.prototype.setUnit = function(value) {
  jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * repeated MarketplacRfqFile files = 19;
 * @return {!Array<!proto.cbv1.MarketplacRfqFile>}
 */
proto.cbv1.MarketplaceRfq.prototype.getFilesList = function() {
  return /** @type{!Array<!proto.cbv1.MarketplacRfqFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.MarketplacRfqFile, 19));
};


/** @param {!Array<!proto.cbv1.MarketplacRfqFile>} value */
proto.cbv1.MarketplaceRfq.prototype.setFilesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.cbv1.MarketplacRfqFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.MarketplacRfqFile}
 */
proto.cbv1.MarketplaceRfq.prototype.addFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.cbv1.MarketplacRfqFile, opt_index);
};


proto.cbv1.MarketplaceRfq.prototype.clearFilesList = function() {
  this.setFilesList([]);
};


/**
 * optional User user = 20;
 * @return {?proto.cbv1.User}
 */
proto.cbv1.MarketplaceRfq.prototype.getUser = function() {
  return /** @type{?proto.cbv1.User} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_user_user_pb.User, 20));
};


/** @param {?proto.cbv1.User|undefined} value */
proto.cbv1.MarketplaceRfq.prototype.setUser = function(value) {
  jspb.Message.setWrapperField(this, 20, value);
};


proto.cbv1.MarketplaceRfq.prototype.clearUser = function() {
  this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.MarketplaceRfq.prototype.hasUser = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional int32 awarded_bid_id = 21;
 * @return {number}
 */
proto.cbv1.MarketplaceRfq.prototype.getAwardedBidId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceRfq.prototype.setAwardedBidId = function(value) {
  jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int32 rfq_type = 22;
 * @return {number}
 */
proto.cbv1.MarketplaceRfq.prototype.getRfqType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceRfq.prototype.setRfqType = function(value) {
  jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional string freight_type_name = 23;
 * @return {string}
 */
proto.cbv1.MarketplaceRfq.prototype.getFreightTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/** @param {string} value */
proto.cbv1.MarketplaceRfq.prototype.setFreightTypeName = function(value) {
  jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional Point pickup_location = 24;
 * @return {?proto.cbv1.Point}
 */
proto.cbv1.MarketplaceRfq.prototype.getPickupLocation = function() {
  return /** @type{?proto.cbv1.Point} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Point, 24));
};


/** @param {?proto.cbv1.Point|undefined} value */
proto.cbv1.MarketplaceRfq.prototype.setPickupLocation = function(value) {
  jspb.Message.setWrapperField(this, 24, value);
};


proto.cbv1.MarketplaceRfq.prototype.clearPickupLocation = function() {
  this.setPickupLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.MarketplaceRfq.prototype.hasPickupLocation = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional int32 pickup_time = 25;
 * @return {number}
 */
proto.cbv1.MarketplaceRfq.prototype.getPickupTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceRfq.prototype.setPickupTime = function(value) {
  jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional string description = 26;
 * @return {string}
 */
proto.cbv1.MarketplaceRfq.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/** @param {string} value */
proto.cbv1.MarketplaceRfq.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional int32 drop_off_time = 27;
 * @return {number}
 */
proto.cbv1.MarketplaceRfq.prototype.getDropOffTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceRfq.prototype.setDropOffTime = function(value) {
  jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional int32 distance = 28;
 * @return {number}
 */
proto.cbv1.MarketplaceRfq.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceRfq.prototype.setDistance = function(value) {
  jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional int32 min_bid_reduction = 29;
 * @return {number}
 */
proto.cbv1.MarketplaceRfq.prototype.getMinBidReduction = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceRfq.prototype.setMinBidReduction = function(value) {
  jspb.Message.setProto3IntField(this, 29, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.MarketplaceBid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.MarketplaceBid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.MarketplaceBid.displayName = 'proto.cbv1.MarketplaceBid';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.MarketplaceBid.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.MarketplaceBid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.MarketplaceBid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MarketplaceBid.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    mine: (f = msg.getMine()) && proto_geo_geo_pb.Point.toObject(includeInstance, f),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    bidder: (f = msg.getBidder()) && proto.cbv1.MarketplaceBidder.toObject(includeInstance, f),
    createdUnix: jspb.Message.getFieldWithDefault(msg, 5, 0),
    updatedUnix: jspb.Message.getFieldWithDefault(msg, 6, 0),
    distanceFromDropOff: jspb.Message.getFieldWithDefault(msg, 7, 0),
    clawAmount: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.MarketplaceBid}
 */
proto.cbv1.MarketplaceBid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.MarketplaceBid;
  return proto.cbv1.MarketplaceBid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.MarketplaceBid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.MarketplaceBid}
 */
proto.cbv1.MarketplaceBid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_geo_geo_pb.Point;
      reader.readMessage(value,proto_geo_geo_pb.Point.deserializeBinaryFromReader);
      msg.setMine(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 4:
      var value = new proto.cbv1.MarketplaceBidder;
      reader.readMessage(value,proto.cbv1.MarketplaceBidder.deserializeBinaryFromReader);
      msg.setBidder(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedUnix(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdatedUnix(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDistanceFromDropOff(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setClawAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.MarketplaceBid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.MarketplaceBid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.MarketplaceBid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MarketplaceBid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMine();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_geo_geo_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getBidder();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.cbv1.MarketplaceBidder.serializeBinaryToWriter
    );
  }
  f = message.getCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getUpdatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getDistanceFromDropOff();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getClawAmount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.MarketplaceBid.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceBid.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Point mine = 2;
 * @return {?proto.cbv1.Point}
 */
proto.cbv1.MarketplaceBid.prototype.getMine = function() {
  return /** @type{?proto.cbv1.Point} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Point, 2));
};


/** @param {?proto.cbv1.Point|undefined} value */
proto.cbv1.MarketplaceBid.prototype.setMine = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cbv1.MarketplaceBid.prototype.clearMine = function() {
  this.setMine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.MarketplaceBid.prototype.hasMine = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 amount = 3;
 * @return {number}
 */
proto.cbv1.MarketplaceBid.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceBid.prototype.setAmount = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional MarketplaceBidder bidder = 4;
 * @return {?proto.cbv1.MarketplaceBidder}
 */
proto.cbv1.MarketplaceBid.prototype.getBidder = function() {
  return /** @type{?proto.cbv1.MarketplaceBidder} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.MarketplaceBidder, 4));
};


/** @param {?proto.cbv1.MarketplaceBidder|undefined} value */
proto.cbv1.MarketplaceBid.prototype.setBidder = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.cbv1.MarketplaceBid.prototype.clearBidder = function() {
  this.setBidder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.MarketplaceBid.prototype.hasBidder = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 created_unix = 5;
 * @return {number}
 */
proto.cbv1.MarketplaceBid.prototype.getCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceBid.prototype.setCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 updated_unix = 6;
 * @return {number}
 */
proto.cbv1.MarketplaceBid.prototype.getUpdatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceBid.prototype.setUpdatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 distance_from_drop_off = 7;
 * @return {number}
 */
proto.cbv1.MarketplaceBid.prototype.getDistanceFromDropOff = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceBid.prototype.setDistanceFromDropOff = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 claw_amount = 8;
 * @return {number}
 */
proto.cbv1.MarketplaceBid.prototype.getClawAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceBid.prototype.setClawAmount = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.MarketplaceBidder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.MarketplaceBidder.repeatedFields_, null);
};
goog.inherits(proto.cbv1.MarketplaceBidder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.MarketplaceBidder.displayName = 'proto.cbv1.MarketplaceBidder';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.MarketplaceBidder.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.MarketplaceBidder.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.MarketplaceBidder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.MarketplaceBidder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MarketplaceBidder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    account: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bidsList: jspb.Message.toObjectList(msg.getBidsList(),
    proto.cbv1.MarketplaceBid.toObject, includeInstance),
    createdUnix: jspb.Message.getFieldWithDefault(msg, 6, 0),
    updatedUnix: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.MarketplaceBidder}
 */
proto.cbv1.MarketplaceBidder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.MarketplaceBidder;
  return proto.cbv1.MarketplaceBidder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.MarketplaceBidder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.MarketplaceBidder}
 */
proto.cbv1.MarketplaceBidder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 5:
      var value = new proto.cbv1.MarketplaceBid;
      reader.readMessage(value,proto.cbv1.MarketplaceBid.deserializeBinaryFromReader);
      msg.addBids(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedUnix(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdatedUnix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.MarketplaceBidder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.MarketplaceBidder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.MarketplaceBidder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MarketplaceBidder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.cbv1.MarketplaceBid.serializeBinaryToWriter
    );
  }
  f = message.getCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getUpdatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.MarketplaceBidder.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceBidder.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.MarketplaceBidder.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.MarketplaceBidder.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.cbv1.MarketplaceBidder.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.MarketplaceBidder.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account = 4;
 * @return {string}
 */
proto.cbv1.MarketplaceBidder.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.MarketplaceBidder.prototype.setAccount = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated MarketplaceBid bids = 5;
 * @return {!Array<!proto.cbv1.MarketplaceBid>}
 */
proto.cbv1.MarketplaceBidder.prototype.getBidsList = function() {
  return /** @type{!Array<!proto.cbv1.MarketplaceBid>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.MarketplaceBid, 5));
};


/** @param {!Array<!proto.cbv1.MarketplaceBid>} value */
proto.cbv1.MarketplaceBidder.prototype.setBidsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.cbv1.MarketplaceBid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.MarketplaceBid}
 */
proto.cbv1.MarketplaceBidder.prototype.addBids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.cbv1.MarketplaceBid, opt_index);
};


proto.cbv1.MarketplaceBidder.prototype.clearBidsList = function() {
  this.setBidsList([]);
};


/**
 * optional int32 created_unix = 6;
 * @return {number}
 */
proto.cbv1.MarketplaceBidder.prototype.getCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceBidder.prototype.setCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 updated_unix = 7;
 * @return {number}
 */
proto.cbv1.MarketplaceBidder.prototype.getUpdatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.MarketplaceBidder.prototype.setUpdatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.MarketplaceRfqBidder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.MarketplaceRfqBidder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.MarketplaceRfqBidder.displayName = 'proto.cbv1.MarketplaceRfqBidder';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.MarketplaceRfqBidder.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.MarketplaceRfqBidder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.MarketplaceRfqBidder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MarketplaceRfqBidder.toObject = function(includeInstance, msg) {
  var f, obj = {
    bidder: (f = msg.getBidder()) && proto.cbv1.MarketplaceBidder.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.MarketplaceRfqBidder}
 */
proto.cbv1.MarketplaceRfqBidder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.MarketplaceRfqBidder;
  return proto.cbv1.MarketplaceRfqBidder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.MarketplaceRfqBidder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.MarketplaceRfqBidder}
 */
proto.cbv1.MarketplaceRfqBidder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cbv1.MarketplaceBidder;
      reader.readMessage(value,proto.cbv1.MarketplaceBidder.deserializeBinaryFromReader);
      msg.setBidder(value);
      break;
    case 2:
      var value = /** @type {!proto.cbv1.MarketplaceRfqBidderStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.MarketplaceRfqBidder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.MarketplaceRfqBidder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.MarketplaceRfqBidder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MarketplaceRfqBidder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBidder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cbv1.MarketplaceBidder.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional MarketplaceBidder bidder = 1;
 * @return {?proto.cbv1.MarketplaceBidder}
 */
proto.cbv1.MarketplaceRfqBidder.prototype.getBidder = function() {
  return /** @type{?proto.cbv1.MarketplaceBidder} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.MarketplaceBidder, 1));
};


/** @param {?proto.cbv1.MarketplaceBidder|undefined} value */
proto.cbv1.MarketplaceRfqBidder.prototype.setBidder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.MarketplaceRfqBidder.prototype.clearBidder = function() {
  this.setBidder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.MarketplaceRfqBidder.prototype.hasBidder = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MarketplaceRfqBidderStatus status = 2;
 * @return {!proto.cbv1.MarketplaceRfqBidderStatus}
 */
proto.cbv1.MarketplaceRfqBidder.prototype.getStatus = function() {
  return /** @type {!proto.cbv1.MarketplaceRfqBidderStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.cbv1.MarketplaceRfqBidderStatus} value */
proto.cbv1.MarketplaceRfqBidder.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.MarketplacRfqFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.MarketplacRfqFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.MarketplacRfqFile.displayName = 'proto.cbv1.MarketplacRfqFile';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.MarketplacRfqFile.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.MarketplacRfqFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.MarketplacRfqFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MarketplacRfqFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.MarketplacRfqFile}
 */
proto.cbv1.MarketplacRfqFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.MarketplacRfqFile;
  return proto.cbv1.MarketplacRfqFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.MarketplacRfqFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.MarketplacRfqFile}
 */
proto.cbv1.MarketplacRfqFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.MarketplacRfqFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.MarketplacRfqFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.MarketplacRfqFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MarketplacRfqFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.MarketplacRfqFile.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.MarketplacRfqFile.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.MarketplacRfqFile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.MarketplacRfqFile.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.cbv1);
