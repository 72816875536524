import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { sort } from 'remeda';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, startWith, take, tap } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { OrdersService } from 'src/app/lmo/services/orders.service';
import { PayloadService } from '~lmo/services/payload.service';
import { CustomField, CustomFieldSetting } from '~proto/fields/fields_pb';
import { CreateOrderRequest, CreateTaskRequest } from '~proto/order/order_api_pb';
import { DetentionStats } from '~proto/order/order_pb';
import { Payload, PayloadGroup } from '~proto/payload/payload_pb';
import { Site } from '~proto/site/site_pb';
import { Trailer } from '~proto/trailer/trailer_pb';
import { Truck } from '~proto/truck/truck_pb';
import { IdName, OrderType, OrderTypeMap, TaskType } from '~proto/types/types_pb';
import { ConstantsService } from '~services/constants.service';
import { CreateOrderRecentsService } from '../../services/create-order-recents.service';
import { LmoCustomOrderFieldsService } from '../../services/lmo-custom-order-fields.service';
import {
  CreateOrderCustomStopsComponent,
  CustomStop,
} from '../create-order-custom-stops/create-order-custom-stops.component';

const CREATE_ORDER_SAVED_SETTINGS = 'CREATE_ORDER_SAVED_SETTINGS';

interface LastPayloadChoices {
  payload: Payload.AsObject;
  pickupFrom: Site.AsObject;
  quantity: number;
  truckingVendor: IdName.AsObject;
  loads: number;
  purchaseOrderName: string;
  orderNumber: string;
}

interface FormValue {
  confirmationRequired: boolean;
  description: string;
  loads: number;
  orderNumber: string;
  payload: Payload.AsObject;
  pickupFrom: Site.AsObject;
  purchaseOrderName: string;
  quantity: number;
  scheduledDeliveryWindow: [Date, Date];
  isAsapOrder: boolean;
  staggerDurationUnit: StaggerDurationUnit;
  staggerDuration: number;
  trailer: Trailer.AsObject;
  trailerLocation: Site.AsObject;
  truck: Truck.AsObject;
  truckingVendor: IdName.AsObject;
}

interface StaggerDurationUnit {
  name: string;
  minutes: number;
}

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-create-order',
  styleUrls: ['./create-order.component.scss'],
  templateUrl: './create-order.component.html',
})
export class CreateOrderComponent implements OnInit {
  public currentSite$: Observable<Site.AsObject>;
  public currentSitePayloads$: Observable<PayloadGroup.AsObject[]>;
  public withoutSiteIds$: Observable<number[]>;
  @ViewChild(CreateOrderCustomStopsComponent, { static: false })
  public customStopsComp: CreateOrderCustomStopsComponent;
  public customStops = new FormControl();
  public requiredCustomFields = new FormArray([]);
  public optionalCustomFields = new FormArray([]);
  public networkActive$ = new BehaviorSubject<boolean>(false);
  public formGroup: FormGroup;
  public siteName$: Observable<string>;
  public orderType: OrderTypeMap[keyof OrderTypeMap];
  public orderTypes = OrderType;
  public stats: DetentionStats.AsObject;
  public staggerDurationOptions: StaggerDurationUnit[] = [
    {
      minutes: 1,
      name: 'Minutes',
    },
    {
      minutes: 60,
      name: 'Hours',
    },
    {
      minutes: 1440,
      name: 'Days',
    },
  ];
  public payloads$: Observable<Payload.AsObject[]>;

  constructor(
    private orderService: OrdersService,
    private site: JobSitesService,
    private fb: FormBuilder,
    private router: Router,
    private recentService: CreateOrderRecentsService,
    private snackBar: MatSnackBar,
    private customFieldsService: LmoCustomOrderFieldsService,
    private payloadService: PayloadService,
    // Leave this here so we make sure all the constants are loaded
    private _constantsService: ConstantsService, // tslint-ignore-line
  ) {}

  private formValue(): FormValue {
    return this.formGroup.value as FormValue;
  }

  private customStopsValue(): CustomStop[] {
    return this.customStops.value;
  }

  public ngOnInit() {
    this.currentSite$ = this.site.currentSite$;
    this.currentSitePayloads$ = this.payloadService.currentSitePayloads$;
    this.getStats();
    this.siteName$ = this.currentSite$.pipe(
      filter((site) => !!site),
      map((site) => site.name),
    );
    this.withoutSiteIds$ = this.currentSite$.pipe(map((site) => (site ? [site.id] : [])));

    this.getFormFromLocalStorage().then((lastPayloadChoices) => {
      this.formGroup = this.fb.group({
        confirmationRequired: [false, Validators.required],
        description: [null, []],
        isAsapOrder: [null, Validators.required],
        loads: [null, [Validators.required, Validators.min(1), Validators.max(50)]],
        orderNumber: [lastPayloadChoices.orderNumber],
        payload: [lastPayloadChoices.payload, Validators.required],
        pickupFrom: [lastPayloadChoices.pickupFrom, Validators.required],
        purchaseOrderName: [lastPayloadChoices.purchaseOrderName],
        quantity: [lastPayloadChoices.quantity, Validators.required],
        scheduledDeliveryWindow: [null, [Validators.required]],
        staggerDuration: [null],
        staggerDurationUnit: [this.staggerDurationOptions[0]],
        trailer: [null],
        trailerLocation: [null],
        truck: [null],
        truckingVendor: [lastPayloadChoices.truckingVendor],
      });

      this.recentService.loadSalesOrder();
      if (this.formGroup.get('payload').value) {
        this.recentService.loadPurchaseOrders(this.formGroup.get('payload').value.id);
      }

      this.formGroup.get('loads').setValue(lastPayloadChoices.loads);

      this.listenToAsap();
      this.listenToNumberOfLoads();
      this.listenToTrailer();
      this.setupPayloads();
    });

    this.customFieldsService.fields$
      .pipe(
        distinctUntilChanged(),
        tap((fields) => {
          this.requiredCustomFields.clear();
          this.optionalCustomFields.clear();
          const sortedFields = sort(fields, (a, b) => a.position - b.position);

          sortedFields.forEach((field) => {
            if (field.required) {
              this.requiredCustomFields.push(makeCustomFieldFormGroup(field));
            } else {
              this.optionalCustomFields.push(makeCustomFieldFormGroup(field));
            }
          });
        }),
      )
      .subscribe();
  }

  public getStats() {
    this.orderService.getStats$().subscribe((stats: DetentionStats.AsObject) => {
      this.stats = stats;
    });
  }

  public selected(event: any) {
    if (event.pickupFrom && event.pickupFrom.direction) {
      event.pickupFrom.directions = event.pickupFrom.direction;
    }
    this.formGroup.patchValue(event);
    if (event.payload) {
      this.recentService.loadPurchaseOrders(event.payload.id);
    }
  }

  public selectedTrailerLocation(event: { pickupFrom: Site.AsObject }) {
    this.formGroup.patchValue({ trailerLocation: event.pickupFrom });
  }

  public selectOrderType(orderType: OrderTypeMap[keyof OrderTypeMap]) {
    this.orderType = orderType;
    if (this.orderType === OrderType.ORDER_TYPE_CUSTOM) {
      if (this.formGroup) {
        this.formGroup.get('pickupFrom').disable();
        this.formGroup.get('quantity').disable();
        this.formGroup.get('payload').disable();
      }
    }
  }

  private createBaseOrderRequest(): CreateOrderRequest {
    const formValue = this.formValue();
    const createOrderRequest = new CreateOrderRequest();
    createOrderRequest.setConfirmationRequired(formValue.confirmationRequired);
    createOrderRequest.setOrderQuantity(formValue.loads);
    createOrderRequest.setPurchaseOrderName(formValue.purchaseOrderName);
    createOrderRequest.setSalesOrderNumber(formValue.orderNumber);
    createOrderRequest.setIsAsapOrder(formValue.isAsapOrder);
    createOrderRequest.setDescription(formValue.description);
    createOrderRequest.setType(this.orderType as OrderTypeMap[keyof OrderTypeMap]);
    if (formValue.scheduledDeliveryWindow && formValue.scheduledDeliveryWindow.length) {
      const start = moment(formValue.scheduledDeliveryWindow[0]);
      const end = moment(formValue.scheduledDeliveryWindow[1]);
      const windowDuration = moment.duration(end.diff(start)).asMinutes();
      createOrderRequest.setDeliveryWindowStart(start.unix());
      createOrderRequest.setDeliveryWindowDurationMinutes(windowDuration);
    }
    if (formValue.trailer) {
      createOrderRequest.setTrailerId(formValue.trailer.id);
    }
    if (formValue.trailerLocation) {
      createOrderRequest.setTrailerSiteId(formValue.trailerLocation.id);
    }
    if (formValue.truck) {
      createOrderRequest.setTruckId(formValue.truck.id);
    }
    if (formValue.staggerDuration) {
      createOrderRequest.setStaggerMinutes(formValue.staggerDuration * (formValue.staggerDurationUnit.minutes || 1));
    }
    if (formValue.truckingVendor) {
      createOrderRequest.setTruckingVendorId(formValue.truckingVendor.id);
    }
    return createOrderRequest;
  }

  private addDropOffTasksToOrderRequest(site: Site.AsObject, request: CreateOrderRequest): void {
    const formValue = this.formValue();
    const pickupTask = new CreateTaskRequest();
    pickupTask.setDescription(formValue.pickupFrom.directions);
    pickupTask.setOrderedQuantity(formValue.quantity);
    pickupTask.setSiteId(formValue.pickupFrom.id);
    pickupTask.setTaskType(TaskType.TASK_TYPE_PICKUP);
    pickupTask.setPayloadId(formValue.payload.id);
    pickupTask.setSequence(1);

    const dropOffTask = new CreateTaskRequest();
    dropOffTask.setDescription(site.directions);
    dropOffTask.setOrderedQuantity(formValue.quantity);
    dropOffTask.setTaskType(TaskType.TASK_TYPE_DROPOFF);
    dropOffTask.setSiteId(site.id);
    dropOffTask.setIsBillable(true);
    dropOffTask.setPayloadId(formValue.payload.id);
    dropOffTask.setSequence(2);

    if (this.orderType === this.orderTypes.ORDER_TYPE_DELIVERY_AND_RETURN) {
      const returnTask = new CreateTaskRequest();
      returnTask.setDescription(formValue.pickupFrom.directions);
      returnTask.setTaskType(TaskType.TASK_TYPE_RETURN_MATERIALS);
      returnTask.setSiteId(formValue.pickupFrom.id);
      returnTask.setSequence(3);
      request.setTaskRequestsList([pickupTask, dropOffTask, returnTask]);
    } else {
      request.setTaskRequestsList([pickupTask, dropOffTask]);
    }
  }

  private addPickupTasksToOrderRequest(site: Site.AsObject, request: CreateOrderRequest): void {
    const formValue = this.formValue();
    const pickupTask = new CreateTaskRequest();
    pickupTask.setDescription(site.directions);
    pickupTask.setOrderedQuantity(formValue.quantity);
    pickupTask.setSiteId(site.id);
    pickupTask.setIsBillable(true);
    pickupTask.setTaskType(TaskType.TASK_TYPE_PICKUP);
    pickupTask.setPayloadId(formValue.payload.id);
    pickupTask.setSequence(1);

    const dropOffTask = new CreateTaskRequest();
    dropOffTask.setDescription(formValue.pickupFrom.directions);
    dropOffTask.setOrderedQuantity(formValue.quantity);
    dropOffTask.setSiteId(formValue.pickupFrom.id);
    dropOffTask.setTaskType(TaskType.TASK_TYPE_DROPOFF);
    dropOffTask.setPayloadId(formValue.payload.id);
    dropOffTask.setSequence(2);

    request.setTaskRequestsList([pickupTask, dropOffTask]);
  }

  private addCustomTasksToOrderRequest(currentSite: Site.AsObject, request: CreateOrderRequest): void {
    const customStops = this.customStopsValue();
    const tasks: CreateTaskRequest[] = [];
    customStops.forEach((stop, index) => {
      const task = new CreateTaskRequest();
      task.setDescription(stop.location.directions);
      task.setSiteId(stop.location.id);
      task.setTaskType(stop.stopType);
      if (currentSite.id === stop.location.id) {
        task.setIsBillable(true);
      }
      if (stop.payload) {
        task.setPayloadId(stop.payload.id);
      }
      if (stop.quantity) {
        task.setOrderedQuantity(stop.quantity);
      }
      task.setSequence(index + 1);
      tasks.push(task);
    });

    request.setTaskRequestsList(tasks);
  }

  public addCustomFields(request: CreateOrderRequest): void {
    const customFields = [...this.requiredCustomFields.value, ...this.optionalCustomFields.value].map(
      convertCustomFieldsFormGroupsToCustomFields,
    );
    request.setCustomFieldsList(customFields);
  }

  public async create() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid || (this.customStopsComp && this.customStopsComp.validate())) {
      return;
    }

    this.networkActive$.next(true);
    const site = await this.currentSite$.pipe(take(1)).toPromise();
    const formValue = this.formValue();
    saveFormToLocalStorage(formValue, site.id);
    const createOrderRequest = this.createBaseOrderRequest();
    if (
      this.orderType === this.orderTypes.ORDER_TYPE_DELIVERY ||
      this.orderType === this.orderTypes.ORDER_TYPE_DELIVERY_AND_RETURN
    ) {
      this.addDropOffTasksToOrderRequest(site, createOrderRequest);
    } else if (this.orderType === this.orderTypes.ORDER_TYPE_PICKUP) {
      this.addPickupTasksToOrderRequest(site, createOrderRequest);
    } else if (this.orderType === this.orderTypes.ORDER_TYPE_CUSTOM) {
      this.addCustomTasksToOrderRequest(site, createOrderRequest);
    }

    this.orderService
      .createOrder$(createOrderRequest)
      .pipe(
        finalize(() => {
          this.networkActive$.next(false);
        }),
      )
      .subscribe((order) => {
        this.snackBar.open('Load successfully scheduled!', null, {
          duration: 3000,
        });
        this.router.navigate(['/', 'lmo', 'jobs', site.id, 'orders', 'pending', order.orderId]);
      });
  }

  public async getFormFromLocalStorage(): Promise<LastPayloadChoices> {
    let siteId: number;
    await this.currentSite$
      .pipe(take(1))
      .toPromise()
      .then((site: Site.AsObject) => {
        siteId = site.id;
      });
    const saved = JSON.parse(localStorage.getItem(CREATE_ORDER_SAVED_SETTINGS)) as LastPayloadChoices;
    if (!saved) {
      return {
        loads: null,
        orderNumber: null,
        payload: null,
        pickupFrom: null,
        purchaseOrderName: null,
        quantity: null,
        truckingVendor: null,
      };
    }
    return saved;
  }

  private listenToAsap() {
    this.formGroup
      .get('isAsapOrder')
      .valueChanges.pipe(startWith(this.formGroup.get('isAsapOrder').value))
      .subscribe((value: boolean) => {
        if (value) {
          this.formGroup.get('scheduledDeliveryWindow').disable();
          this.formGroup.get('scheduledDeliveryWindow').reset();
          this.formGroup.get('staggerDuration').disable();
          this.formGroup.get('staggerDuration').reset();
          this.formGroup.get('staggerDurationUnit').disable();
        } else {
          this.formGroup.get('scheduledDeliveryWindow').enable();
          if (this.formGroup.get('loads').value && this.formGroup.get('loads').value > 1) {
            this.formGroup.get('staggerDuration').enable();
            this.formGroup.get('staggerDurationUnit').enable();
          }
        }
      });
  }

  private listenToNumberOfLoads() {
    this.formGroup
      .get('loads')
      .valueChanges.pipe(startWith(this.formGroup.get('loads').value))
      .subscribe((value: number) => {
        if (value && value > 1 && !this.formGroup.get('isAsapOrder').value) {
          this.formGroup.get('staggerDuration').enable();
          this.formGroup.get('staggerDurationUnit').enable();
        } else {
          this.formGroup.get('staggerDuration').disable();
          this.formGroup.get('staggerDurationUnit').disable();
        }
      });
  }

  private listenToTrailer() {
    this.customStops.valueChanges.subscribe(() => {
      const value = this.customStopsValue();
      if (!value) {
        return;
      }
      const trailerStops = value.filter((stop) => !!stop.assetNumber);
      if (trailerStops.length) {
        this.formGroup.get('trailer').setValue(trailerStops[0].assetNumber);
      }
    });

    this.formGroup
      .get('trailer')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((trailer: Trailer.AsObject) => {
        if (trailer) {
          // Update custom stops
          const value = this.customStopsValue();
          if (value) {
            const newValue = value.map((stop) => {
              if (stop.assetNumber) {
                return {
                  ...stop,
                  assetNumber: trailer,
                };
              }
              return stop;
            });
            this.customStops.setValue(newValue);
          }
          // Update trailer location
          this.formGroup.get('trailerLocation').setValue(trailer.site);
        }
      });
  }

  private setupPayloads() {
    this.payloads$ = combineLatest([
      this.customStops.valueChanges.pipe(
        startWith(null as any),
        map(() => this.customStopsValue()),
        map((stops) => (stops || []).filter((stop) => stop.payload).map((stop) => stop.payload)),
      ),
      this.formGroup.get('payload').valueChanges.pipe(startWith(this.formGroup.get('payload').value)),
    ]).pipe(map(([payloads, payload]) => [...payloads, payload].filter((p) => !!p)));
  }

  public checkDetentionWarning() {
    if (!this.formGroup.get('isAsapOrder').value) {
      const start = moment(
        this.formGroup.get('scheduledDeliveryWindow').value && this.formGroup.get('scheduledDeliveryWindow').value[0],
      );
      const end = moment(
        this.formGroup.get('scheduledDeliveryWindow').value && this.formGroup.get('scheduledDeliveryWindow').value[1],
      );
      const windowDuration = moment.duration(end.diff(start)).asHours();
      return windowDuration < 3;
    } else {
      return false;
    }
  }

  public trackByIndex(index: number) {
    return index;
  }

  public trackByName(_index: number, record: { name: string }) {
    return record.name;
  }
}

function saveFormToLocalStorage(formValue: FormValue, siteId: number) {
  const savedSettings: LastPayloadChoices = {
    loads: formValue.loads,
    orderNumber: formValue.orderNumber,
    payload: formValue.payload,
    pickupFrom: formValue.pickupFrom,
    purchaseOrderName: formValue.purchaseOrderName,
    quantity: formValue.quantity,
    truckingVendor: formValue.truckingVendor,
  };
  const existingSavedSettings = JSON.parse(localStorage.getItem(CREATE_ORDER_SAVED_SETTINGS)) || {};
  localStorage.setItem(
    CREATE_ORDER_SAVED_SETTINGS,
    JSON.stringify({
      ...existingSavedSettings,
      [siteId]: savedSettings,
    }),
  );
}

function makeCustomFieldFormGroup(field: CustomFieldSetting.AsObject): FormGroup {
  const validators = field.required ? [Validators.required] : [];
  return new FormGroup({
    meta: new FormControl(field),
    value: new FormControl(null, validators),
  });
}

interface CustomFieldFormGroup {
  meta: CustomFieldSetting.AsObject;
  value: string | number | boolean;
}

function convertCustomFieldsFormGroupsToCustomFields(group: CustomFieldFormGroup, ..._others: any): CustomField {
  const customField = new CustomField();
  customField.setKey(group.meta.key);
  customField.setPosition(group.meta.position);
  customField.setType(group.meta.type);
  if (group.value === null) {
    customField.setValue(null);
  } else {
    customField.setValue(typeof group.value === 'string' ? group.value : group.value.toString());
  }
  return customField;
}
