import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Unit } from '~proto/payload/payload_pb';
import { ConstantsService } from '~services/constants.service';
import { MarketplaceService } from '~services/marketplace.service';
import { trackById } from '~utilities/trackById';
import { RfqFormService } from '../../services/rfq-form.service';
import { RfqLocationFormComponent } from '../rfq-location-form/rfq-location-form.component';

const FREIGHT_RFQ_TYPE = 'freight';
const PRODUCT_RFQ_TYPE = 'product';
const DELIVERED_PRODUCT_RFQ_TYPE = 'deliveredProduct';

@Component({
  selector: 'ct-rfq-item-form',
  styleUrls: ['./rfq-item-form.component.scss'],
  templateUrl: './rfq-item-form.component.html',
})
export class RfqItemFormComponent implements OnInit, OnDestroy {
  public trackById = trackById;
  public rfqItemForm: FormGroup;
  public networkActive$$ = new BehaviorSubject<boolean>(false);
  public fileUploading$$ = new BehaviorSubject<boolean>(false);
  public rfqStartingTime$ = new BehaviorSubject<string>('');
  public rfqType$ = new BehaviorSubject<string>('');
  public rfqDuration$ = new BehaviorSubject<string>('');
  public destroy$ = new Subject();
  public units: Unit.AsObject[];
  public fileToUpload: File[] = [];
  public productsList = [];
  public productSuggestions$ = new BehaviorSubject(this.productsList);

  constructor(
    private fb: FormBuilder,
    private rfqFormService: RfqFormService,
    private marketplaceService: MarketplaceService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private matdialog: MatDialog,
    private constants: ConstantsService,
    private snackbar: MatSnackBar,
  ) {}

  public get rfqUnit() {
    return this.rfqItemForm.get('unit').value;
  }

  public ngOnInit() {
    this.rfqType$.next(this.route.parent.snapshot.params.type);
    this.rfqFormService.setRfqType(this.rfqType$.value);
    this.buildForm();
    this.getSuggestions();
    this.units = Object.values(this.constants.units);
    this.units.sort((a, b) => a.name.localeCompare(b.name));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  private getSuggestions() {
    this.marketplaceService.rfqSuggestions$
      .pipe(
        map((suggestions) => (suggestions && suggestions.productsList) || []),
        takeUntil(this.destroy$),
      )
      .subscribe((productsList) => {
        this.productsList = [...productsList];
        this.productSuggestions$.next(this.productsList);
      });
  }

  private buildForm() {
    this.rfqItemForm = this.fb.group({
      attachments: [[], []],
      description: ['', []],
      minBidReduction: [null, []],
      quantity: [0, [Validators.required]],
      rfqDuration: [null, [Validators.required]],
      unit: [null, [Validators.required]],
    });

    if (this.isFreightRfq()) {
      this.rfqItemForm.addControl('freightType', new FormControl('', Validators.required));
      this.rfqItemForm.addControl('pickupSite', new FormControl('', Validators.required));
      this.rfqItemForm.addControl('pickupTime', new FormControl('', Validators.required));
      this.rfqItemForm.addControl('dropoffSite', new FormControl('', Validators.required));
      this.rfqItemForm.addControl('dropoffTime', new FormControl('', Validators.required));
    } else if (this.isProductRfq() || this.isDeliveredProductRfq()) {
      this.rfqItemForm.addControl('jobDuration', new FormControl('', Validators.required));
      this.rfqItemForm.addControl('maxPrice', new FormControl(null));
      this.rfqItemForm.addControl('payload', new FormControl('', Validators.required));
      if (this.isDeliveredProductRfq()) {
        this.rfqItemForm.addControl('site', new FormControl('', Validators.required));
      }
      if (this.isProductRfq()) {
        this.rfqItemForm.addControl('demandCenter', new FormControl(''));
      }
      this.setPayloadListener();
    }

    this.rfqItemForm
      .get('rfqDuration')
      .valueChanges.pipe(
        filter((value) => !!value),
        takeUntil(this.destroy$),
      )
      .subscribe((value) => {
        this.rfqStartingTime$.next(moment(value[0]).format('hh:mma'));
        this.rfqDuration$.next(`${moment(value[1]).diff(moment(value[0]), 'hours')}hrs`);
      });
  }

  private setPayloadListener() {
    this.rfqItemForm
      .get('payload')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value) {
          this.productSuggestions$.next(this.productsList.filter((product) => product.includes(value)));
        } else {
          this.productSuggestions$.next(this.productsList);
        }
      });
  }

  public isFreightRfq() {
    return this.rfqType$.value === FREIGHT_RFQ_TYPE;
  }

  public isProductRfq() {
    return this.rfqType$.value === PRODUCT_RFQ_TYPE;
  }

  public isDeliveredProductRfq() {
    return this.rfqType$.value === DELIVERED_PRODUCT_RFQ_TYPE;
  }

  public moveToStepTwo() {
    this.networkActive$$.next(true);
    if (this.rfqItemForm.invalid) {
      this.rfqItemForm.markAllAsTouched();
      this.networkActive$$.next(false);
      this.snackBar.open('Please fill in all the fields', null, { duration: 2000 });
    } else {
      const files = this.rfqItemForm.controls['attachments'].value.map((file) => file.id);
      this.rfqItemForm.controls['attachments'].setValue(files);
      this.rfqFormService.saveFormData(this.rfqItemForm.value, 'ITEM_DATA');
      this.router.navigate(['/', 'lmo', 'marketplace', 'schedule-rfq', this.rfqType$.value, '2']);
      this.networkActive$$.next(false);
    }
  }

  public async openDemandMapLocation() {
    const mapDialog = this.matdialog.open(RfqLocationFormComponent);
    const locationData = await mapDialog.afterClosed().toPromise();
    this.rfqItemForm.controls['demandCenter'].setValue(locationData);
  }

  public async openMapLocation() {
    const mapDialog = this.matdialog.open(RfqLocationFormComponent);
    const locationData = await mapDialog.afterClosed().toPromise();
    this.rfqItemForm.controls['site'].setValue(locationData);
  }

  public async openPickupMap() {
    const mapDialog = this.matdialog.open(RfqLocationFormComponent);
    const locationData = await mapDialog.afterClosed().toPromise();
    this.rfqItemForm.controls['pickupSite'].setValue(locationData);
  }

  public async openDropoffMap() {
    const mapDialog = this.matdialog.open(RfqLocationFormComponent);
    const locationData = await mapDialog.afterClosed().toPromise();
    this.rfqItemForm.controls['dropoffSite'].setValue(locationData);
  }

  public onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      this.fileToUpload = [...event.target.files];
      this.uploadFile();
    }
  }

  public removeFile(selectedFile) {
    const files = this.rfqItemForm.controls['attachments'].value.filter((file) => {
      return file.id !== selectedFile.id;
    });
    this.rfqItemForm.controls['attachments'].setValue(files);
  }

  private uploadFile() {
    this.fileToUpload.forEach((file) => {
      this.fileUploading$$.next(true);
      this.rfqFormService.uploadFile$(file).subscribe(
        (result) => {
          const files = this.rfqItemForm.controls['attachments'].value;
          files.push(result);
          this.rfqItemForm.controls['attachments'].setValue(files);
          this.fileUploading$$.next(false);
          this.snackbar.open(`${file.name} uploaded Successfully`, null, {
            duration: 2000,
          });
        },
        (err) => {
          this.fileUploading$$.next(false);
          this.snackbar.open(`${file.name} upload failed`, null, {
            duration: 2000,
          });
        },
      );
    });
  }

  public getMinReductionSuffix() {
    if (this.isFreightRfq()) {
      return `$ / MILE`;
    } else {
      return `$ / ${this.rfqUnit ? this.rfqUnit.abbreviation : 'unit'}`;
    }
  }
}
