import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { flatten } from 'remeda/dist/es';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import { PayloadSummary } from '~lmo/components/create-order-custom-stops2/create-order-custom-stops2.component';
import { LmoCustomOrderFieldsService } from '~lmo/services/lmo-custom-order-fields.service';
import { OrdersService } from '~lmo/services/orders.service';
import { PayloadService } from '~lmo/services/payload.service';
import { addCustomTasksToBackhaulRequest, CustomStop } from '~lmo/utilities/create-order';
import { BackHaulRequest } from '~proto/order/order_api_pb';
import { Order } from '~proto/order/order_pb';
import { Payload, PayloadGroup } from '~proto/payload/payload_pb';
import { Site } from '~proto/site/site_pb';
import { OrderType, TaskStatus, TaskType, TaskTypeMap } from '~proto/types/types_pb';
import { UserDetailsForIntercomRequest, UserDetailsForIntercomResponse } from '~proto/user/user_api_pb';
import { UserAPI } from '~proto/user/user_api_pb_service';
import { GrpcService } from '~services/grpc.service';
import { moveItemInFormArray } from '~utilities/moveItemInFormArray';
import { trackById } from '~utilities/trackById';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-add-tasks',
  styleUrls: ['./add-tasks.component.scss'],
  templateUrl: './add-tasks.component.html',
})
export class AddTasksComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  public validationErrors: ValidationErrors;
  public payloads$: Observable<Payload.AsObject[]>;
  public payloadGroups$: Observable<PayloadGroup.AsObject[]>;
  public formGroup: FormGroup;
  public networkActive$ = new BehaviorSubject<boolean>(false);
  public currentOrder$: Observable<Order.AsObject>;
  public currentOrder: Order.AsObject;
  public trackById = trackById;
  public customStops: FormArray[] = [];
  public isPurchaseOrderRequired$$ = new BehaviorSubject<Boolean>(false);
  public isSalesOrderRequired$$ = new BehaviorSubject<Boolean>(false);
  public purchaseOrderNames$: Observable<string[]>;
  public salesOrderNumbers$: Observable<string[]>;
  public allowedPayloadTypes$$ = new BehaviorSubject<number[]>([]);
  public canLoadBeBackHaul$$ = new BehaviorSubject<boolean>(false);
  private currentOrderId: number;
  public payloadSummaries$: Observable<PayloadSummary[]>;
  private isPOValid: string;
  private isSOValid: boolean;
  public customStops$$ = new BehaviorSubject<CustomStop[]>([]);
  public customStopsStatusChanges$$ = new BehaviorSubject<string[]>([]);
  public taskStatus = TaskStatus;
  public backHaulMessage = 'BackHaul can be Yes if the added tasks are in sequence.';
  public backHaulToolTipDisabled = true;

  public stopTypes: { name: string; value: TaskTypeMap[keyof TaskTypeMap] }[] = [
    {
      name: 'Pickup Payload',
      value: TaskType.TASK_TYPE_PICKUP,
    },
    {
      name: 'Dropoff Payload',
      value: TaskType.TASK_TYPE_DROPOFF,
    },
  ];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private customFieldOrderService: LmoCustomOrderFieldsService,
    private activatedRoute: ActivatedRoute,
    private orderService: OrdersService,
    private payloadService: PayloadService,
    private grpcService: GrpcService,
  ) {
  }

  public ngOnInit() {
    this.currentOrder$ = this.orderService.currentOrder$;
    this.loadRequiredFieldSettings();
    this.setupCustomStops();
    this.setupPayloadSummaries();
    this.setupValidationErrors();
    this.setupPayloads();
    this.setupPurchaseOrders();
    this.setupSalesOrderNumbers();
    this.formGroup = this.fb.group({
      isBackHaul: [false],
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public addNewCustomStop(taskIndex, taskSequence) {
    const fg = this.getNewFormLine(taskSequence, taskIndex);
    fg.get('stopType').setValue(TaskType.TASK_TYPE_PICKUP);
    this.customStops[taskIndex].push(fg);
    this.customStops.forEach((stops, index) => {
      if (index > taskIndex) {
        if (stops && stops.length > 0) {
          for (const stop of stops.value) {
            stop.sequence = stop.sequence + 1;
          }
        }
      }
    });
    this.checkTasksContinuity();
  }

  public removeCustomStop(taskIndex: number) {
    this.customStops[taskIndex].removeAt(this.customStops[taskIndex].length - 1);
    this.customStops.forEach((stops, index) => {
      if (index > taskIndex) {
        if (stops && stops.length > 0) {
          for (const stop of stops.value) {
            stop.sequence = stop.sequence - 1;
          }
        }
      }
    });
    this.checkTasksContinuity();
  }

  private checkTasksContinuity() {
    let previousStopSequence = -1;
    if (this.customStopsValue().length > 1) {
      for (const stop of this.customStopsValue()) {
        if (previousStopSequence === -1 || previousStopSequence === stop.sequence - 1) {
          previousStopSequence = stop.sequence;
        } else {
          this.disbaleBackHaulDropdown();
          break;
        }
        this.enableBackHaulDropdown();
      }
    } else {
      this.enableBackHaulDropdown();
    }
  }

  private disbaleBackHaulDropdown() {
    this.backHaulToolTipDisabled = false;
    this.formGroup.get('isBackHaul').setValue(false);
    this.formGroup.get('isBackHaul').disable();
  }

  private enableBackHaulDropdown() {
    this.backHaulToolTipDisabled = true;
    this.formGroup.get('isBackHaul').enable();
  }

  private stopChanged() {
    const stops: CustomStop[] = [];
    this.customStops.forEach((value) => {
      stops.push(...value.value);
    });
    this.customStops$$.next(stops);
  }

  private stopChangedStatus() {
    const stops: string[] = [];
    this.customStops.forEach((value) => {
      stops.push(...value.status);
    });
    this.customStopsStatusChanges$$.next(stops);
  }

  private setupCustomStops() {
    this.currentOrder$.pipe(filter((order) => order !== null)).subscribe((order: Order.AsObject) => {
      if (order) {
        this.currentOrderId = order.id;
        this.customFieldOrderService.canLoadBeBackHaulRequest$(order.id).subscribe(res => {
          this.canLoadBeBackHaul$$.next(res.canBeBackHaul);
          this.allowedPayloadTypes$$.next(res.allowedPayloadTypeIdsList);
        });
        order.tasksList.forEach((task) => {
          const formArray = new FormArray([]);
          formArray.valueChanges.subscribe(() => this.stopChanged());
          formArray.statusChanges.subscribe(() => this.stopChangedStatus());
          this.customStops.push(formArray);
        });
      }
    });
  }

  private customStopsValue(): CustomStop[] {
    const customStops: CustomStop[] = [];
    this.customStops.forEach((stop) => {
      customStops.push(...stop.value);
    });
    return customStops;
  }

  private getPreviousCustomStopsValue(taskIndex: number): CustomStop[] {
    const customStops: CustomStop[] = [];
    let i = 0;
    while (i <= taskIndex) {
      customStops.push(...this.customStops[i].value);
      i++;
    }
    return customStops;
  }

  private loadRequiredFieldSettings() {
    const request = new UserDetailsForIntercomRequest();
    (this.grpcService.invoke$(UserAPI.UserDetailsForIntercom, request) as Observable<UserDetailsForIntercomResponse>).subscribe((response) => {
      this.isPurchaseOrderRequired$$.next(response.toObject().userDetails.isPurchaseOrderRequired);
      this.isSalesOrderRequired$$.next(response.toObject().userDetails.isSalesOrderRequired);
    });
  }

  private requiredFieldsCheck(customStopsValue: CustomStop[]) {
    this.isPOValid = this.isPurchaseOrderRequired$$.value ? customStopsValue[0].purchaseOrderName : 'true';
    this.isSOValid = this.isSalesOrderRequired$$.value ? this.checkDropOffSO(customStopsValue) : true;
  }

  private checkDropOffSO(customStopsValue) {
    return (
      customStopsValue &&
      customStopsValue.map((customStop) => {
        if (customStop.stopType === 1 && customStop.salesOrderNumber === null) {
          return false;
        }
        return true;
      })
    );
  }

  public create() {
    const customStopsValue = this.customStopsValue();
    this.requiredFieldsCheck(customStopsValue);

    if (!this.isPOValid || !this.isSOValid) {
      this.snackBar.open(
        `
        ${this.isPurchaseOrderRequired$$.value && !customStopsValue[0].purchaseOrderName ? 'Purchase Order Name, ' : ''}
        ${this.isSalesOrderRequired$$.value && !this.isSOValid ? 'Sales Order Number' : ''}
        fields are mandatory`,
        null,
        {
          duration: 3000,
        },
      );
      return;
    }

    const payloadSummary = this.validationErrors;

    if (payloadSummary && payloadSummary.invalidTotals) {
      this.snackBar.open(payloadSummary.invalidTotals, null, {
        duration: 3000,
      });
      return;
    }

    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    for (const customStop of this.customStops) {
      if (!customStop.valid) {
        return;
      }
    }

    this.networkActive$.next(true);
    const backhaulRequest = new BackHaulRequest();
    addCustomTasksToBackhaulRequest(backhaulRequest, customStopsValue);
    backhaulRequest.getTaskRequestsList().map((value, index) => {
      value.setSequence(customStopsValue[index].sequence);
      value.setIsBackHaul(this.formGroup.get('isBackHaul').value);
    });
    backhaulRequest.setLoadId(this.currentOrderId);
    this.orderService
      .createBackHaul$(backhaulRequest)
      .pipe(
        finalize(() => {
          this.networkActive$.next(false);
        }),
      )
      .subscribe(() => {
        this.snackBar.open('Tasks successfully created!', null, {
          duration: 3000,
        });
        this.router.navigate(['..'], { relativeTo: this.activatedRoute });
      });
  }

  public isCustomOrder(order: Order.AsObject): Boolean {
    return order != null && order.type === OrderType.ORDER_TYPE_CUSTOM;
  }

  public drop(taskIndex: number, event: CdkDragDrop<FormGroup[]>) {
    moveItemInFormArray(this.customStops[taskIndex], event.previousIndex, event.currentIndex);
  }

  public trackByControl(_index: number, control: FormControl) {
    return control;
  }

  public trackByName(_index: number, record: { name: string }) {
    return record.name;
  }

  public trackByKey(_index: number, record: { key: string }) {
    return record.key;
  }

  public selectCustomOrderLineSite(taskIndex: number, index: number, event: { pickupFrom: Site.AsObject }) {
    this.customStops[taskIndex]
      .at(index)
      .get('location')
      .setValue(event.pickupFrom);
  }

  private setupValidationErrors() {
    combineLatest([
      this.payloadSummaries$,
      this.customStops$$.asObservable().pipe(
        startWith(null as any),
        map(() => this.customStopsValue()),
      ),
      this.customStopsStatusChanges$$.asObservable(),
    ])
      .pipe(
        map(([payloadSummaries]) => {
          let hasError = false;
          const validationErrors: {
            [key: string]: any;
          } = {};
          this.customStops.forEach((stops) => {
            if (stops.invalid && stops.errors) {
              hasError = true;
              validationErrors.customStops = {
                ...stops.errors,
              };
            }
          });
          this.customStops.forEach((stops) => {
            if (stops.invalid && stops.errors) {
              hasError = true;
              validationErrors.customStops = {
                ...stops.errors,
              };
            }
            if (stops.invalid && stops.errors) {
              hasError = true;
              validationErrors.customStops = {
                ...stops.errors,
              };
            }
            if (stops.controls.some((control) => control.invalid)) {
              hasError = true;
              validationErrors.customStopsLine = 'Please fill in all fields';
            }
          });
          if (payloadSummaries.some((summary) => summary.leftOver !== 0)) {
            hasError = true;
            validationErrors.invalidTotals = 'All payloads must 0 out';
          }
          if (hasError) {
            return validationErrors;
          }
          return null;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((errors) => {
        this.validationErrors = errors;
      });
  }

  public get invalid(): boolean {
    return !!this.validationErrors;
  }

  public validate(): ValidationErrors {
    return this.validationErrors;
  }

  public selectCustomOrderLinePayload(
    taskIndex: number,
    index: number,
    event: { payload: Payload.AsObject; quantity: number },
  ) {
    this.customStops[taskIndex]
      .at(index)
      .get('payload')
      .setValue(event.payload);

    this.customStops[taskIndex]
      .at(index)
      .get('quantity')
      .setValue(event.quantity || 0);
  }

  public selectCustomOrderPurchaseOrderName(taskIndex: number, index: number, event: { purchaseOrderName: string }) {
    this.customStops[taskIndex]
      .at(index)
      .get('purchaseOrderName')
      .setValue(event.purchaseOrderName);
  }

  public selectCustomSalesOrderNumber(taskIndex: number, index: number, event: { orderNumber: string }) {
    this.customStops[taskIndex]
      .at(index)
      .get('salesOrderNumber')
      .setValue(event.orderNumber);
  }

  private setupPayloadSummaries() {
    this.payloadSummaries$ = this.customStops$$.asObservable().pipe(
      map((stops: CustomStop[]) => {
        const summariesMap = stops.reduce(
          (acc, stop) => {
            const keyValue = this.isPurchaseOrderRequired$$.value ? stop.purchaseOrderName : stop.quantity;
            if (stop.payload && keyValue) {
              const key = `${stop.payload.id}-${keyValue}`;

              if (!acc[key]) {
                acc[key] = {
                  droppedOff: 0,
                  key: key,
                  leftOver: 0,
                  payloadName: stop.payload.name,
                  pickedUp: 0,
                  purchaseOrderName: stop.purchaseOrderName ? stop.purchaseOrderName : '-',
                };
              }
              if (stop.stopType === TaskType.TASK_TYPE_PICKUP) {
                acc[key].pickedUp += stop.quantity;
                acc[key].leftOver += stop.quantity;
              } else if (stop.stopType === TaskType.TASK_TYPE_DROPOFF) {
                acc[key].droppedOff += stop.quantity;
                acc[key].leftOver -= stop.quantity;
              }
            }
            return acc;
          },
          {} as Record<number, PayloadSummary>,
        );
        return Object.values(summariesMap);
      }),
    );
  }

  private setupPayloads() {
    this.payloadGroups$ = combineLatest([
      this.payloadService.allPayloadGroups$,
      this.allowedPayloadTypes$$.asObservable(),
      this.customStops$$.asObservable().pipe(startWith([] as CustomStop[])),
    ]).pipe(
      map((
        [sitePayloads, allowedPayloadTypes, formValues]: [PayloadGroup.AsObject[], number[], CustomStop[]],
      ) => {
        const payloadsOnForm = formValues.filter((stop) => stop.payload).map((stop) => stop.payload);
        const allPayloads = [
          ...flatten(sitePayloads.map((payloadGroup) => payloadGroup.payloadsList)),
          ...payloadsOnForm,
        ];
        const filteredPayloads = allPayloads.filter((payload: Payload.AsObject) => {
          return allowedPayloadTypes.includes(payload.type.id);
        });
        const uniquePayloads = filteredPayloads.reduce(
          (acc, payload) => {
            acc[payload.id] = payload;
            return acc;
          },
          {} as Record<number, Payload.AsObject>,
        );
        return [
          {
            name: '',
            payloadsList: Object.values(uniquePayloads),
          },
        ];
      }),
    );
  }

  private getNewFormLine(taskSequence: number, taskIndex: number, site?: Site.AsObject): FormGroup {
    const stopSequence = taskSequence + 1 + this.getPreviousCustomStopsValue(taskIndex).length;
    const fg = this.fb.group({
      location: [site, [Validators.required]],
      payload: [null, [Validators.required]],
      purchaseOrderName: [null, this.isPurchaseOrderRequired$$.value ? [Validators.required] : ''],
      quantity: [null, [Validators.required]],
      salesOrderNumber: [null, this.isSalesOrderRequired$$.value ? [Validators.required] : ''],
      sequence: [stopSequence],
      stopType: [null, [Validators.required]],
    });

    fg.get('stopType')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(() => {
        if (this.isSalesOrderRequired$$.value && fg.controls['stopType'].value === 1) {
          fg.controls['salesOrderNumber'].setValidators(Validators.required);
        } else {
          fg.controls['salesOrderNumber'].clearValidators();
        }
      });
    return fg;
  }

  private setupPurchaseOrders() {
    this.purchaseOrderNames$ = this.customStops$$.asObservable().pipe(
      map((formValues: CustomStop[]) => {
        return formValues.filter((stop) => stop.purchaseOrderName).map((stop) => stop.purchaseOrderName);
      }),
      shareReplay(1),
    );
  }

  private setupSalesOrderNumbers() {
    this.salesOrderNumbers$ = this.customStops$$.asObservable().pipe(
      map((formValues: CustomStop[]) => {
        return formValues.filter((stop) => stop.salesOrderNumber).map((stop) => stop.salesOrderNumber);
      }),
      shareReplay(1),
    );
  }
}
