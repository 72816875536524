import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';

@Directive({
  selector: 'button[ct-button], a[ct-button]',
})
export class CtButtonDirective {
  @HostBinding('class.ct-button') public ctButton = true;
  @HostBinding('class.button') public secondary = true;
}

@Directive({
  selector: 'button[ct-secondary-button]',
})
export class CtSecondaryButtonDirective {
  @HostBinding('class.ct-button') public ctButton = true;
  @HostBinding('class.button-secondary') public secondary = true;
}

@Directive({
  selector: 'button[ct-gray-button], a[ct-gray-button]',
})
export class CtGrayButtonDirective {
  @HostBinding('class.ct-button') public ctButton = true;
  @HostBinding('class.button-gray') public secondary = true;
}

@Directive({
  selector: 'button[ct-dark-button]',
})
export class CtDarkButtonDirective {
  @HostBinding('class.ct-button') public ctButton = true;
  @HostBinding('class.dark-button') public secondary = true;
}

@Directive({
  selector: 'button[ct-danger-button]',
})
export class CtDangerButtonDirective {
  @HostBinding('class.ct-button') public ctButton = true;
  @HostBinding('class.button-danger') public secondary = true;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector:
    'button[ct-button],button[ct-secondary-button],button[ct-danger-button],button[ct-dark-button],button[ct-gray-button],a[ct-button],a[ct-secondary-button],a[ct-danger-button],a[ct-dark-button]a[ct-gray-button]',
  styleUrls: ['./button.component.scss'],
  templateUrl: './button.component.html',
})
export class ButtonComponent {
  @Input() public set disabled(disabled: boolean) {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', disabled);
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}
}
