import { sort } from 'remeda';
import { Circle } from 'src/proto/geo/geo_pb';
import { MappableOrder, Order, Task, TaskSummary } from '~proto/order/order_pb';
import { OrderStatus, OrderStatusMap, TaskStatus, TaskType } from '~proto/types/types_pb';

export function orderBackLink(order: Order.AsObject): string[] {
  if (!order) {
    return [];
  }
  const task = getBillableTask(order);

  return ['/', 'dispatcher', 'map', `${task.site.id}`, getStatus(order)];
}

export function orderSiteName(order: Order.AsObject): string {
  if (!order) {
    return null;
  }
  const task = getBillableTask(order);
  return task.site.name;
}

export function orderSiteGeofence(order: Order.AsObject): Circle.AsObject {
  if (!order) {
    return null;
  }
  const task = getBillableTask(order);
  return task.site.geofence;
}

export function getLastDropoffTask(order: Order.AsObject): Task.AsObject {
  let task: Task.AsObject;
  const dropOffs = order.tasksList.filter((tasks) => tasks.type === TaskType.TASK_TYPE_DROPOFF);
  if (dropOffs.length) {
    dropOffs.sort((a, b) => a.sequence - b.sequence);
    task = dropOffs[dropOffs.length - 1];
  }
  if (!task) {
    task = order.tasksList[0];
  }
  return task;
}

export function isPending(order: Order.AsObject): boolean {
  return !isInProgress(order) && !isCompleted(order);
}

export function isInProgress(order: Order.AsObject): boolean {
  const inProgressStatuses: OrderStatusMap[keyof OrderStatusMap][] = [
    OrderStatus.ORDER_STATUS_DISPATCHED,
    OrderStatus.ORDER_STATUS_DRIVER_ACCEPTED,
  ];
  return inProgressStatuses.includes(order.status);
}

export function isCompleted(order: Order.AsObject): boolean {
  const completedStatuses: OrderStatusMap[keyof OrderStatusMap][] = [
    OrderStatus.ORDER_STATUS_CANCELLED,
    OrderStatus.ORDER_STATUS_COMPLETED,
  ];
  return completedStatuses.includes(order.status);
}
export function getStatus(order: Order.AsObject): 'pending' | 'in-progress' | 'completed' {
  return isCompleted(order) ? 'completed' : isInProgress(order) ? 'in-progress' : 'pending';
}

export function getInProgressOrFirstTask(order: Order.AsObject): Task.AsObject {
  if (!order) {
    return;
  }
  const sortedTasks = sort(order.tasksList, (a, b) => a.sequence - b.sequence);
  for (let i = 0; i < sortedTasks.length; i++) {
    if (sortedTasks[i].status === TaskStatus.TASK_STATUS_IN_PROGRESS) {
      return sortedTasks[i];
    }
  }
  return sortedTasks[0];
}

export function getBillableTask(order: Order.AsObject): Task.AsObject {
  let task = order.tasksList.find((tasks) => tasks.isBillable);
  if (!task) {
    task = order.tasksList.find((t) => t.sequence === 1);
    if (!task) {
      return getLastDropoffTask(order);
    }
  }
  return task;
}

export function getBillableTaskForMappableOrder(order: MappableOrder.AsObject): TaskSummary.AsObject {
  let task = order.taskSummariesList.find((tasks) => tasks.isBillable);
  if (!task) {
    task = order.taskSummariesList[0];
  }
  return task;
}

export function getInProgressTask(order: Order.AsObject): Task.AsObject {
  const inProgressTasks = order.tasksList.filter((task) => task.status === TaskStatus.TASK_STATUS_IN_PROGRESS);
  let inProgressTask: Task.AsObject;
  if (inProgressTasks.length) {
    inProgressTask = inProgressTasks[0];
  } else {
    inProgressTask = order.tasksList.filter((task) => task.sequence === 1)[0];
  }
  return inProgressTask;
}

export function getInProgressTaskForMappableOrder(order: MappableOrder.AsObject): TaskSummary.AsObject {
  const inProgressTasks = order.taskSummariesList.filter((task) => task.status === TaskStatus.TASK_STATUS_IN_PROGRESS);
  let inProgressTask: TaskSummary.AsObject;
  if (inProgressTasks.length) {
    inProgressTask = inProgressTasks[0];
  } else {
    inProgressTask = order.taskSummariesList.filter((task) => task.sequence === 1)[0];
  }
  return inProgressTask;
}
