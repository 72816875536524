import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';
import { OrdersService } from 'src/app/lmo/services/orders.service';
import { ContractsService } from '~lmo/services/contracts.service';
import { CreateOrderRecentsService } from '~lmo/services/create-order-recents.service';
import { PayloadService } from '~lmo/services/payload.service';
import {
  createPayloadDropoffTask,
  createPayloadPickupTask,
  createTrailerDropoffTask,
  createTrailerPickupTask,
  createTruckDropoffTask,
  createTruckPickupTask,
  flattenTasks,
} from '~lmo/utilities/create-order';
import { BusinessLine } from '~proto/businessline/businessline_pb';
import { VendorContract, VendorContractType } from '~proto/contracts/contracts_pb';
import { CreateOrderRequest } from '~proto/order/order_api_pb';
import { DetentionStats } from '~proto/order/order_pb';
import { Payload, PayloadGroup } from '~proto/payload/payload_pb';
import { Site } from '~proto/site/site_pb';
import { Trailer } from '~proto/trailer/trailer_pb';
import { Truck } from '~proto/truck/truck_pb';
import { IdName, OrderType } from '~proto/types/types_pb';
import { UserDetailsForIntercomRequest, UserDetailsForIntercomResponse } from '~proto/user/user_api_pb';
import { UserAPI } from '~proto/user/user_api_pb_service';
import { GrpcService } from '~services/grpc.service';
import { RoleService } from '~services/role.service';
import { trackById } from '~utilities/trackById';

const CREATE_REPEATED_ORDER_SAVED_SETTINGS = 'CREATE_REPEATED_ORDER_SAVED_SETTINGS';

export interface OrderTaskGroup {
  businessLine: number;
  pickupLocation: Site.AsObject;
  isAsapOrder: boolean;
  dropoffLocation: Site.AsObject;
  payload: Payload.AsObject;
  scheduledDeliveryWindow: [Date, Date];
  truck: Truck.AsObject;
  trailer: Trailer.AsObject;
  purchaseOrderName: string;
  orderNumber: string;
  maintenanceOrderNumber: string;
}

interface LastPayloadChoices {
  truckingVendor: IdName.AsObject;
}

interface FormValue {
  costCenter: string;
  costCenterId: number;
  description: string;
  truckingVendor: IdName.AsObject;
  vendorContractId: number;
  contractTypeId: number;
}
@Component({
  selector: 'ct-create-repeated-order-trailer-and-truck',
  styleUrls: ['./create-repeated-order-trailer-and-truck.component.scss'],
  templateUrl: './create-repeated-order-trailer-and-truck.component.html',
})
export class CreateRepeatedOrderTrailerAndTruckComponent implements OnInit {
  public payloadGroups$: Observable<PayloadGroup.AsObject[]>;
  public networkActive$$ = new BehaviorSubject<boolean>(false);
  public orderTaskGroups = new FormArray([]);
  public formGroup: FormGroup;
  public stats: DetentionStats.AsObject;
  public payloads$: Observable<Payload.AsObject[]>;

  public truckingContracts: VendorContract.AsObject[] = [];
  public trackById = trackById;

  public contractTypes$: Observable<VendorContractType.AsObject[]>;
  public contractType$: Observable<VendorContractType.AsObject>;
  public businessLinesList$$ = new BehaviorSubject<BusinessLine.AsObject[]>(null);
  public isBusinessLineEnabled$$ = new BehaviorSubject<Boolean>(false);
  public isCostCenterRequired$$ = new BehaviorSubject<Boolean>(false);
  public isPurchaseOrderRequired$$ = new BehaviorSubject<Boolean>(false);
  public isSalesOrderRequired$$ = new BehaviorSubject<Boolean>(false);
  public isMaintenanceOrderRequired$$ = new BehaviorSubject<Boolean>(false);
  public isBusinessLineCostCenterRelationEnabled$$ = new BehaviorSubject<Boolean>(false);
  private isCCValid: string;
  private isPOValid: string;
  private isSOValid: string;
  private isMOValid: string;

  constructor(
    private orderService: OrdersService,
    private fb: FormBuilder,
    private recentService: CreateOrderRecentsService,
    private snackBar: MatSnackBar,
    private payloadService: PayloadService,
    private router: Router,
    private contractService: ContractsService,
    private roleService: RoleService,
    private grpcService: GrpcService,
  ) {}

  private formValue(): FormValue {
    return this.formGroup.value as FormValue;
  }

  private orderTaskGroupsValue(): OrderTaskGroup[] {
    return this.orderTaskGroups.value;
  }

  public ngOnInit() {
    this.payloadGroups$ = this.payloadService.allPayloadGroups$;
    this.insertLineBefore(0);
    this.loadRequiredFieldSettings();

    this.getFormFromLocalStorage().then((lastPayloadChoices) => {
      this.formGroup = this.fb.group({
        contractTypeId: [null],
        costCenter: [null, this.isCostCenterRequired$$.value ? [Validators.required] : ''],
        costCenterId: [null],
        description: [null, []],
        truckingVendor: [lastPayloadChoices.truckingVendor],
        vendorContractId: [null],
      });

      this.recentService.loadSalesOrder();
      this.setupPayloads();

      this.listenContractChange(lastPayloadChoices.truckingVendor);
      this.listenContractTypeChanges();
      this.checkIfBusinessLineEnabled();
      this.checkIsBusinessLineCostCenterRelationEnabled();

      this.orderTaskGroups
        .at(0)
        .get('businessLine')
        .valueChanges.subscribe(() => {
          this.formGroup.get('costCenter').setValue(null);
          this.formGroup.get('costCenterId').setValue(null);
        });
    });
  }

  private listenContractTypeChanges() {
    this.contractType$ = this.formGroup.get('contractTypeId').valueChanges;
    this.contractTypes$ = this.contractService.contractTypes$;

    combineLatest([
      this.formGroup.get('contractTypeId').valueChanges,
      this.contractService.nonExpiredContracts$,
    ]).subscribe(([contractTypeId, contracts]) => {
      const contractTypeContracts = contracts.filter((contract) => {
        if (contract.contractType) {
          return +contractTypeId <= 0 || contract.contractType.id === +contractTypeId;
        }
        return +contractTypeId <= 0;
      });
      const vendor = this.formGroup.get('truckingVendor').value;
      if (vendor) {
        const vendorContract = contractTypeContracts.find((contract) => contract.vendor.id === vendor.id);
        if (!(vendorContract && vendorContract.id > 0)) {
          this.formGroup.get('truckingVendor').setValue(null);
        }
      }
    });
  }

  private listenContractChange(initialVendor: IdName.AsObject) {
    combineLatest([
      this.formGroup.get('truckingVendor').valueChanges.pipe(startWith(initialVendor)),
      this.formGroup.get('contractTypeId').valueChanges.pipe(startWith(-1)),
      this.contractService.nonExpiredContracts$,
    ]).subscribe(([vendor, contractTypeId, contracts]) => {
      const contractTypeContracts = contracts.filter((contract) => {
        if (contract.contractType) {
          return +contractTypeId <= 0 || contract.contractType.id === +contractTypeId;
        }
        return +contractTypeId <= 0;
      });
      const contractId = this.formGroup.get('vendorContractId').value;
      if (contractId) {
        const contractById = contractTypeContracts.find((contract) => contract.id === +contractId);
        if (!(contractById && contractById.id > 0)) {
          this.formGroup.get('vendorContractId').setValue(null);
        }
      }
      if (vendor) {
        this.truckingContracts = contracts.filter(
          (contract) =>
            contract.vendor.id === vendor.id &&
            contract.isEffective &&
            (contractTypeId <= 0 || (contract.contractType && contract.contractType.id === contractTypeId)),
        );
      }
    });

    combineLatest([
      this.formGroup.get('vendorContractId').valueChanges,
      this.contractService.nonExpiredContracts$,
    ]).subscribe(([contractId, contracts]) => {
      if (contractId) {
        const contractById = contracts.find((contract) => contract.id === +contractId);
        this.formGroup.get('contractTypeId').setValue(contractById.contractType ? contractById.contractType.id : -1);
      }
    });
  }

  public getStats() {
    this.orderService.getStats$().subscribe((stats: DetentionStats.AsObject) => {
      this.stats = stats;
    });
  }

  public selectOrderTaskGroupLinePickupLocation(index: number, event: { pickupFrom: Site.AsObject }) {
    this.orderTaskGroups
      .at(index)
      .get('pickupLocation')
      .setValue(event.pickupFrom);
  }

  public selectOrderTaskGroupLineDropoffLocation(index: number, event: { pickupFrom: Site.AsObject }) {
    this.orderTaskGroups
      .at(index)
      .get('dropoffLocation')
      .setValue(event.pickupFrom);
  }

  public selectOrderTaskGroupLinePayload(index: number, event: { payload: Payload.AsObject }) {
    this.orderTaskGroups
      .at(index)
      .get('payload')
      .setValue(event.payload);
  }

  public selectOrderTaskGroupLineTruck(index: number, event: { truck: Truck.AsObject }) {
    this.orderTaskGroups
      .at(index)
      .get('truck')
      .setValue(event.truck);
  }

  public selectOrderTaskGroupLineTrailer(index: number, event: { trailer: Trailer.AsObject }) {
    this.orderTaskGroups
      .at(index)
      .get('trailer')
      .setValue(event.trailer);
  }

  public selectCustomOrderPurchaseOrderName(index: number, event: { purchaseOrderName: string }) {
    this.orderTaskGroups
      .at(index)
      .get('purchaseOrderName')
      .setValue(event.purchaseOrderName);
  }

  public selectCustomSalesOrderNumber(index: number, event: { orderNumber: string }) {
    this.orderTaskGroups
      .at(index)
      .get('orderNumber')
      .setValue(event.orderNumber);
  }

  public selectCustomMaintenanceOrderNumber(index: number, event: { maintenanceOrderNumber: string }) {
    this.orderTaskGroups
      .at(index)
      .get('maintenanceOrderNumber')
      .setValue(event.maintenanceOrderNumber);
  }

  public removeOrderTaskGroupLink(index: number) {
    this.orderTaskGroups.removeAt(index);
  }

  public insertLineBefore(index: number) {
    const fg = this.getNewFormLine();
    const currentLine = this.orderTaskGroups.at(index);
    if (currentLine) {
      fg.patchValue({
        businessLine: currentLine.get('businessLine').value,
        dropoffLocation: currentLine.get('dropoffLocation').value,
        isAsapOrder: currentLine.get('isAsapOrder').value,
        maintenanceOrderNumber: currentLine.get('maintenanceOrderNumber').value,
        orderNumber: currentLine.get('orderNumber').value,
        payload: currentLine.get('payload').value,
        pickupLocation: currentLine.get('pickupLocation').value,
        purchaseOrderName: currentLine.get('purchaseOrderName').value,
        scheduledDeliveryWindow: currentLine.get('scheduledDeliveryWindow').value,
      });
    }
    this.orderTaskGroups.insert(index, fg);
  }

  public insertLineAfter(index: number) {
    const fg = this.getNewFormLine();
    const currentLine = this.orderTaskGroups.at(index);
    if (currentLine) {
      fg.patchValue({
        businessLine: currentLine.get('businessLine').value,
        dropoffLocation: currentLine.get('dropoffLocation').value,
        isAsapOrder: currentLine.get('isAsapOrder').value,
        maintenanceOrderNumber: currentLine.get('maintenanceOrderNumber').value,
        orderNumber: currentLine.get('orderNumber').value,
        payload: currentLine.get('payload').value,
        pickupLocation: currentLine.get('pickupLocation').value,
        purchaseOrderName: currentLine.get('purchaseOrderName').value,
        scheduledDeliveryWindow: currentLine.get('scheduledDeliveryWindow').value,
      });
    }
    if (index + 1 >= this.orderTaskGroups.length) {
      this.orderTaskGroups.push(fg);
    } else {
      this.orderTaskGroups.insert(index + 1, fg);
    }
  }

  public swapLocations(index: number, _event: MouseEvent) {
    const currentLine = this.orderTaskGroups.at(index);
    if (currentLine) {
      currentLine.patchValue({
        dropoffLocation: currentLine.get('pickupLocation').value,
        pickupLocation: currentLine.get('dropoffLocation').value,
      });
    }
  }

  private getNewFormLine(): FormGroup {
    const fg = this.fb.group({
      businessLine: [null],
      dropoffLocation: [null, [Validators.required]],
      isAsapOrder: [null, Validators.required],
      maintenanceOrderNumber: [null, this.isMaintenanceOrderRequired$$.value ? [Validators.required] : ''],
      orderNumber: [null, this.isSalesOrderRequired$$.value ? [Validators.required] : ''],
      payload: [null, [Validators.required]],
      pickupLocation: [null, [Validators.required]],
      purchaseOrderName: [null, this.isPurchaseOrderRequired$$.value ? [Validators.required] : ''],
      scheduledDeliveryWindow: [null, [Validators.required]],
      trailer: [null, [Validators.required]],
      truck: [null, [Validators.required]],
    });
    this.listenToAsap(fg);

    return fg;
  }

  public selected(event: any) {
    if (event.pickupFrom && event.pickupFrom.direction) {
      event.pickupFrom.directions = event.pickupFrom.direction;
    }
    this.formGroup.patchValue(event);
  }

  public trackByControl(_index: number, control: FormControl) {
    return control;
  }

  public async create($event) {
    this.formGroup.markAllAsTouched();
    this.orderTaskGroups.markAllAsTouched();

    if (this.formGroup.invalid || this.orderTaskGroups.invalid) {
      return;
    }

    const formValue = this.formValue();
    const orderTaskGroups = this.orderTaskGroupsValue();

    this.requiredFieldsCheck(formValue, orderTaskGroups);

    if (!this.isCCValid || !this.isPOValid || !this.isSOValid || !this.isMOValid) {
      this.snackBar.open('Please fill all mandatory fields', null, {
        duration: 3000,
      });
      return;
    }

    saveFormToLocalStorage(formValue);
    const requests: CreateOrderRequest[] = orderTaskGroups.map((taskGroup) =>
      this.createOrderFromTaskGroup(formValue, taskGroup),
    );

    this.networkActive$$.next(true);
    await this.orderService
      .createMultipleOrders$(requests)
      .pipe(finalize(() => this.networkActive$$.next(false)))
      .toPromise();

    this.snackBar.open('Loads successfully scheduled!', null, {
      duration: 3000,
    });

    // get the last request, and the dropoff stop
    const dropOff = requests[requests.length - 1].getTaskRequestsList()[1];
    if (dropOff) {
      await this.router.navigate(['/', 'lmo', 'jobs', dropOff.getSiteId(), 'orders', 'pending']);
    } else {
      await this.router.navigate(['../']);
    }
  }

  private createOrderFromTaskGroup(formValue: FormValue, taskGroup: OrderTaskGroup): CreateOrderRequest {
    const createOrderRequest = new CreateOrderRequest();
    createOrderRequest.setOrderQuantity(1);
    createOrderRequest.setIsAsapOrder(taskGroup.isAsapOrder);
    createOrderRequest.setDescription(formValue.description);
    createOrderRequest.setType(OrderType.ORDER_TYPE_DELIVERY);
    createOrderRequest.setTruckId(taskGroup.truck.id);
    createOrderRequest.setTrailerId(taskGroup.trailer.id);
    createOrderRequest.setCostCenter(formValue.costCenter);
    createOrderRequest.setCostCenterId(formValue.costCenterId);
    createOrderRequest.setVendorContractId(formValue.vendorContractId);
    createOrderRequest.setContractTypeId(formValue.contractTypeId);
    createOrderRequest.setBusinessLineId(taskGroup.businessLine);

    if (taskGroup.scheduledDeliveryWindow && taskGroup.scheduledDeliveryWindow.length) {
      const start = moment(taskGroup.scheduledDeliveryWindow[0]);
      const end = moment(taskGroup.scheduledDeliveryWindow[1]);
      const windowDuration = moment.duration(end.diff(start)).asMinutes();
      createOrderRequest.setDeliveryWindowStart(start.unix());
      createOrderRequest.setDeliveryWindowDurationMinutes(windowDuration);
    }

    if (formValue.truckingVendor) {
      createOrderRequest.setTruckingVendorId(formValue.truckingVendor.id);
    }
    const {
      pickupLocation,
      dropoffLocation,
      payload,
      purchaseOrderName,
      orderNumber,
      maintenanceOrderNumber,
    } = taskGroup;

    const pickupTruckTask = createTruckPickupTask({ truckPickupLocation: pickupLocation });
    const pickupTrailerTask = createTrailerPickupTask({ trailerPickupLocation: pickupLocation });
    const pickupPayloadTask = createPayloadPickupTask({
      maintenanceOrderNumber: maintenanceOrderNumber,
      orderNumber: orderNumber,
      payload: payload,
      pickupFrom: pickupLocation,
      purchaseOrderName: purchaseOrderName,
      quantity: 1,
    });
    const dropoffPayloadTask = createPayloadDropoffTask({
      dropoffSite: dropoffLocation,
      maintenanceOrderNumber: maintenanceOrderNumber,
      orderNumber: orderNumber,
      payload,
      purchaseOrderName: purchaseOrderName,
      quantity: 1,
    });
    dropoffPayloadTask.setIsBillable(true);
    const dropoffTrailerTask = createTrailerDropoffTask({ trailerDropoffLocation: dropoffLocation });
    const dropoffTruckTask = createTruckDropoffTask({ truckDropoffLocation: dropoffLocation });

    createOrderRequest.setTaskRequestsList([
      pickupTruckTask,
      pickupTrailerTask,
      pickupPayloadTask,
      dropoffPayloadTask,
      dropoffTrailerTask,
      dropoffTruckTask,
    ]);

    return flattenTasks(createOrderRequest);
  }

  private requiredFieldsCheck(formValue: FormValue, customStopsValue: OrderTaskGroup[]) {
    this.isCCValid = this.isCostCenterRequired$$.value ? formValue.costCenter : 'true';
    this.isPOValid = this.isPurchaseOrderRequired$$.value ? customStopsValue[0].purchaseOrderName : 'true';
    this.isSOValid = this.isSalesOrderRequired$$.value ? customStopsValue[0].orderNumber : 'true';
    this.isMOValid = this.isMaintenanceOrderRequired$$.value ? customStopsValue[0].maintenanceOrderNumber : 'true';
  }

  public async getFormFromLocalStorage(): Promise<LastPayloadChoices> {
    const saved = JSON.parse(localStorage.getItem(CREATE_REPEATED_ORDER_SAVED_SETTINGS)) as LastPayloadChoices;
    if (!saved) {
      return {
        truckingVendor: null,
      };
    }
    return saved;
  }

  private listenToAsap(formGroup: FormGroup) {
    formGroup
      .get('isAsapOrder')
      .valueChanges.pipe(startWith(formGroup.get('isAsapOrder').value))
      .subscribe((value: boolean) => {
        if (value) {
          formGroup.get('scheduledDeliveryWindow').disable();
          formGroup.get('scheduledDeliveryWindow').reset();
        } else {
          formGroup.get('scheduledDeliveryWindow').enable();
        }
      });
  }

  public checkDetentionWarning(isAsapOrder: boolean, scheduleWindow: moment.MomentInput) {
    if (isAsapOrder) {
      return false;
    }

    const start = moment(scheduleWindow && scheduleWindow[0]);
    const end = moment(scheduleWindow && scheduleWindow[1]);
    const windowDuration = moment.duration(end.diff(start)).asHours();
    return windowDuration < 3;
  }

  public trackByIndex(index: number) {
    return index;
  }

  public trackByName(_index: number, record: { name: string }) {
    return record.name;
  }

  private setupPayloads() {
    this.payloads$ = this.orderTaskGroups.valueChanges.pipe(
      map(() => this.orderTaskGroupsValue()),
      map((value) => value.filter((line) => line.payload).map((line) => line.payload)),
    );
  }

  private businessLinesList() {
    this.roleService.businessLinesList$.subscribe((businessLines: BusinessLine.AsObject[]) => {
      this.businessLinesList$$.next(businessLines);
      if (businessLines.length === 1) {
        this.orderTaskGroups
          .at(0)
          .get('businessLine')
          .setValue(businessLines[0].id);
      }
    });
  }

  private checkIfBusinessLineEnabled() {
    this.roleService.isBusinessLineEnabled$.subscribe((isBusinessLineEnabled) => {
      this.isBusinessLineEnabled$$.next(isBusinessLineEnabled);
      if (isBusinessLineEnabled) {
        this.businessLinesList();
      }
    });
  }

  private checkIsBusinessLineCostCenterRelationEnabled() {
    this.roleService.isBusinessLineCostCenterRelationEnabled$.subscribe((isBLCCRelationEnabled: Boolean) => {
      this.isBusinessLineCostCenterRelationEnabled$$.next(isBLCCRelationEnabled);
    });
  }

  private loadRequiredFieldSettings() {
    const request = new UserDetailsForIntercomRequest();
    (this.grpcService.invoke$(UserAPI.UserDetailsForIntercom, request) as Observable<
      UserDetailsForIntercomResponse
    >).subscribe((response) => {
      this.isCostCenterRequired$$.next(response.toObject().userDetails.isCostCenterRequired);
      this.isPurchaseOrderRequired$$.next(response.toObject().userDetails.isPurchaseOrderRequired);
      this.isSalesOrderRequired$$.next(response.toObject().userDetails.isSalesOrderRequired);
      this.isMaintenanceOrderRequired$$.next(response.toObject().userDetails.isMaintenanceOrderRequired);
    });
  }
}

function saveFormToLocalStorage(formValue: FormValue) {
  const savedSettings: LastPayloadChoices = {
    truckingVendor: formValue.truckingVendor,
  };
  localStorage.setItem(CREATE_REPEATED_ORDER_SAVED_SETTINGS, JSON.stringify(savedSettings));
}
