import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-mini-modal',
  styleUrls: ['./mini-modal.component.scss'],
  templateUrl: './mini-modal.component.html',
})
export class MiniModalComponent implements OnInit {
  @Input() public title: string;
  @Input() public subTitle: string;
  @Input() public grow = false;

  constructor() {}

  public ngOnInit() {}
}
