import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-lmo-marketplace-invite',
  styleUrls: ['./lmo-marketplace-invite.component.scss'],
  templateUrl: './lmo-marketplace-invite.component.html',
})
export class LmoMarketplaceInviteComponent implements OnInit {
  @Input() public userEmail: string;
  public title = '🎁 Interested in HAULi Marketplace? Request an invite!';
  constructor() {}

  public ngOnInit() {}

  public openGoogleInviteForm() {
    (<any>window).open(
      `https://docs.google.com/forms/d/e/1FAIpQLSfryjMR2jIHWO3M6VBu2MDXvkiy_3sLxgVZGscmt0uW3VRVgw/viewform?usp=pp_url&entry.845512455=${this.userEmail}`,
    );
  }
}
