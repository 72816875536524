import { Component, Input, OnInit } from '@angular/core';
import { MarketplaceRfq } from '~proto/marketplace/rfq_pb';
import { MarketplaceRfqStatus } from '~proto/types/types_pb';
import { ConstantsService } from '~services/constants.service';
import { trackById } from '~utilities/trackById';

@Component({
  selector: 'ct-rfq-listing',
  styleUrls: ['./rfq-listing.component.scss'],
  templateUrl: './rfq-listing.component.html',
})
export class RfqListingComponent implements OnInit {
  public trackById = trackById;
  @Input() public rfqList: MarketplaceRfq.AsObject[] = null;
  @Input() public rfqType: number;

  public rfqHeading: string;
  public rfqStatusTypes = MarketplaceRfqStatus;
  constructor(public constantService: ConstantsService) {}

  public ngOnInit() {
    this.setRfqHeading();
  }

  private setRfqHeading() {
    switch (this.rfqType) {
      case this.rfqStatusTypes.RFQ_STATUS_BIDDING_OPEN:
        this.rfqHeading = 'OPEN';
        break;
      case this.rfqStatusTypes.RFQ_STATUS_SCHEDULED:
        this.rfqHeading = 'SCHEDULED';
        break;
      case this.rfqStatusTypes.RFQ_STATUS_CANCELLED:
        this.rfqHeading = 'CANCELLED';
        break;
      case this.rfqStatusTypes.RFQ_STATUS_AWARDED:
        this.rfqHeading = 'AWARDED';
        break;
    }
  }

  public getRfqType(rfq) {
    switch (rfq.rfqType) {
      case 2:
        return 'FREIGHT';
      case 3:
        return 'PRODUCT';
      case 4:
        return 'DELIVERED PRODUCT';
      default:
        return '--';
    }
  }

  public findAwardedBid(selectedRfq: MarketplaceRfq.AsObject) {
    const awardedBid = selectedRfq.bidsList.find((bids) => bids.id === selectedRfq.awardedBidId);
    return `${awardedBid.bidder.name} (${awardedBid.bidder.email})` || '--';
  }
}
