import * as tslib_1 from "tslib";
import { NavigationEnd } from '@angular/router';
import { equals } from 'remeda';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var maximumEntriesToStoreInHistory = 25;
var RouterStateService = /** @class */ (function () {
    function RouterStateService(router) {
        var _this = this;
        this.router = router;
        this.backUrl$$ = new BehaviorSubject(null);
        this.routerState$$ = new BehaviorSubject(null);
        this.history$$ = new BehaviorSubject([]);
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function () {
            var snapshot = _this.router.routerState.snapshot;
            var url = snapshot.url;
            url = url.split('?')[0];
            var queryParams = snapshot.root.queryParams;
            if (_this.routerState$$.value) {
                var withoutParams = _this.routerState$$.value.url.split('?')[0];
                if (!equals(url, withoutParams)) {
                    var oldUrl = tslib_1.__spread(['/'], withoutParams.split('/').filter(function (segment) { return segment !== ''; }));
                    _this.storeHistory(oldUrl);
                }
            }
            var state = snapshot.root;
            var params = {};
            while (state.firstChild) {
                params = tslib_1.__assign({}, params, state.params);
                state = state.firstChild;
            }
            params = tslib_1.__assign({}, params, state.params);
            var routerState = {
                params: params,
                queryParams: queryParams,
                url: url,
            };
            _this.routerState$$.next(routerState);
        });
    }
    Object.defineProperty(RouterStateService.prototype, "routerState$", {
        get: function () {
            return this.routerState$$.asObservable().pipe(filter(function (params) { return !!params; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RouterStateService.prototype, "backUrl$", {
        get: function () {
            return this.backUrl$$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RouterStateService.prototype, "history$", {
        get: function () {
            return this.history$$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    RouterStateService.prototype.listenForParamChange$ = function (paramName) {
        return this.routerState$.pipe(map(function (state) { return state.params[paramName]; }), distinctUntilChanged());
    };
    RouterStateService.prototype.listenForQueryChange$ = function (queryParamName) {
        return this.routerState$.pipe(map(function (state) { return state.queryParams[queryParamName]; }), distinctUntilChanged());
    };
    RouterStateService.prototype.backUrlOr$ = function (alternateRoute) {
        return this.backUrl$.pipe(map(function (route) {
            if (route && route.length) {
                return route;
            }
            return alternateRoute;
        }));
    };
    RouterStateService.prototype.storeHistory = function (url) {
        this.backUrl$$.next(url);
        var value = this.history$$.value;
        if (!value) {
            this.history$$.next([url]);
        }
        else if (value.length < maximumEntriesToStoreInHistory) {
            this.history$$.next(tslib_1.__spread([url], value));
        }
        else {
            this.history$$.next(tslib_1.__spread([url], value.slice(0, maximumEntriesToStoreInHistory - 1)));
        }
    };
    RouterStateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RouterStateService_Factory() { return new RouterStateService(i0.ɵɵinject(i1.Router)); }, token: RouterStateService, providedIn: "root" });
    return RouterStateService;
}());
export { RouterStateService };
