import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AttachmentUrlRequest, AttachmentUrlResponse } from '~proto/order/order_api_pb';
import { OrderAPI } from '~proto/order/order_api_pb_service';
import { OrderAttachment } from '~proto/order/order_pb';
import { GrpcService } from '~services/grpc.service';

const imageFileTypes = ['svg', 'png', 'jpg', 'png'];

@Component({
  selector: 'ct-file-preview-modal',
  styleUrls: ['./file-preview-modal.component.scss'],
  templateUrl: './file-preview-modal.component.html',
})
export class FilePreviewModalComponent implements OnInit {
  public fileType: 'image' | 'pdf' | 'other';
  public selectedFile: OrderAttachment.AsObject;
  public fileLoading = true;
  public rotateDegree = 0;
  public previewFileUrl: string;
  private fileExtension: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: OrderAttachment.AsObject, private grpc: GrpcService) {
    this.selectedFile = data;
  }

  public ngOnInit() {
    const filePathArray = this.selectedFile.fileName.toLowerCase().split('/');
    const filename = filePathArray[filePathArray.length - 1];
    const fileNameArray = filename.split('.');
    const extension = fileNameArray[fileNameArray.length - 1];
    if (imageFileTypes.includes(extension)) {
      this.fileType = 'image';
    } else if (extension === 'pdf') {
      this.fileType = 'pdf';
    } else {
      this.fileType = 'other';
    }
    this.fileExtension = fileNameArray[fileNameArray.length - 1];
    this.getFileDownloadLink$(this.selectedFile.id).subscribe((resp: AttachmentUrlResponse) => {
      const fileUrl = resp.toObject().url;
      if (this.fileType === 'image' || this.fileType === 'pdf') {
        this.previewFileUrl = fileUrl;
      } else {
        this.previewFileUrl = `https://view.officeapps.live.com/op/view.aspx?src=${fileUrl}`;
      }
      this.fileLoading = false;
    });
  }

  public getPreviewDownloadLink() {
    if (this.previewFileUrl) {
      window.open(this.previewFileUrl, '_blank');
    }
  }

  public getPreviewLink() {
    return this.previewFileUrl.split('src=')[1];
  }

  public rotateImage(deg) {
    if (this.rotateDegree === 0 && deg === -90) {
      this.rotateDegree = 270;
    } else if (this.rotateDegree === 360 && deg === 90) {
      this.rotateDegree = 0;
    } else {
      this.rotateDegree += deg;
    }
  }

  public checkFileUrl() {
    return (
      this.previewFileUrl.startsWith('https://view.officeapps.live.com/op/view.aspx?src=') ||
      this.fileExtension === 'pdf'
    );
  }

  private getFileDownloadLink$(fileId: number): Observable<AttachmentUrlResponse> {
    const orderAttachmentUrl = new AttachmentUrlRequest();
    orderAttachmentUrl.setId(fileId);
    return this.grpc.invoke$(OrderAPI.GetOrderAttachmentUrl, orderAttachmentUrl);
  }
}
