import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-create-non-site-specific-orders',
  styleUrls: ['./create-non-site-specific-orders.component.scss'],
  templateUrl: './create-non-site-specific-orders.component.html',
})
export class CreateNonSiteSpecificOrdersComponent implements OnInit {
  constructor() {}

  public ngOnInit() {}
}
