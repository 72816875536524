import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CreateLMOVendorPayloadTypePercentageRequest, VendorPayloadTypePercentage, } from '~proto/contracts/contracts_api_pb';
import { trackById } from '~utilities/trackById';
var LmoTruckingContractsPercentagesComponent = /** @class */ (function () {
    function LmoTruckingContractsPercentagesComponent(contractsService, constantsService, cd, snackbar) {
        this.contractsService = contractsService;
        this.constantsService = constantsService;
        this.cd = cd;
        this.snackbar = snackbar;
        this.trackById = trackById;
        this.networkActive$ = new BehaviorSubject(false);
        this.formArray = new FormArray([]);
        this.payloadTypeGroups = [];
    }
    LmoTruckingContractsPercentagesComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest([this.contractsService.nonExpiredContracts$, this.contractsService.percentages$])
            .pipe(filter(function (_a) {
            var _b = tslib_1.__read(_a, 2), contracts = _b[0], _percentages = _b[1];
            return !!contracts && contracts.length > 0;
        }), map(function (_a) {
            var _b = tslib_1.__read(_a, 2), contracts = _b[0], percentages = _b[1];
            return groupByPayloadType(contracts, percentages, _this.constantsService.payloadTypes);
        }))
            .subscribe(function (groups) {
            _this.networkActive$.next(false);
            _this.payloadTypeGroups = groups;
            _this.cd.markForCheck();
        });
    };
    LmoTruckingContractsPercentagesComponent.prototype.hasPercentOver100 = function () {
        return this.payloadTypeGroups.some(function (g) { return g.percentTotal > 100; });
    };
    LmoTruckingContractsPercentagesComponent.prototype.updatePercents = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var updates;
            return tslib_1.__generator(this, function (_a) {
                updates = new CreateLMOVendorPayloadTypePercentageRequest();
                // Check that none of the percentages are above 100
                if (this.hasPercentOver100()) {
                    this.snackbar.open('The total allocation of some of payloads is over 100%. Please adjust distribution to not exceed 100%.', null, {
                        duration: 4000,
                        panelClass: 'snackbar-error',
                    });
                    return [2 /*return*/];
                }
                this.payloadTypeGroups.forEach(function (g) {
                    g.vendors.forEach(function (vendor) {
                        var percentUpdate = new VendorPayloadTypePercentage();
                        percentUpdate.setPayloadTypeId(g.id);
                        percentUpdate.setPercentage(+vendor.percentage.value);
                        percentUpdate.setVendorId(vendor.id);
                        updates.addVendorPayloadTypePercentages(percentUpdate);
                    });
                });
                this.networkActive$.next(true);
                this.contractsService.updatePercentages(updates);
                return [2 /*return*/];
            });
        });
    };
    return LmoTruckingContractsPercentagesComponent;
}());
export { LmoTruckingContractsPercentagesComponent };
function groupByPayloadType(contracts, percentages, payloadTypes) {
    var groups = {};
    contracts.forEach(function (contract) {
        contract.payloadtypesList.forEach(function (payloadType) {
            var _a;
            var currentPercentage = (percentages[payloadType.id] && percentages[payloadType.id][contract.vendor.id]) || 0;
            groups[payloadType.id] = tslib_1.__assign({}, (groups[payloadType.id] || {}), (_a = {}, _a[contract.vendor.id] = {
                id: contract.vendor.id,
                name: contract.vendor.name,
                percentage: new FormControl(currentPercentage, [Validators.min(0), Validators.max(100)]),
            }, _a));
        });
    });
    return convertPayloadTypeRecordToPayloadTypeGroups(groups, payloadTypes);
}
function convertPayloadTypeRecordToPayloadTypeGroups(grouped, payloadTypes) {
    var returner = [];
    Object.entries(grouped)
        .map(function (_a) {
        var _b = tslib_1.__read(_a, 2), key = _b[0], value = _b[1];
        return ({ key: key, value: value });
    })
        .forEach(function (_a) {
        var key = _a.key, value = _a.value;
        var payloadType = payloadTypes[key];
        if (!payloadType) {
            return;
        }
        var payloadGroup = tslib_1.__assign({}, payloadType, { percentTotal: 0, vendors: Object.values(value).sort(sortVendors) });
        payloadGroup.vendors.forEach(function (vendor) {
            vendor.percentage.valueChanges.subscribe(function () {
                payloadGroup.percentTotal = payloadGroup.vendors.reduce(function (acc, cur) { return acc + +cur.percentage.value; }, 0);
            });
        });
        payloadGroup.percentTotal = payloadGroup.vendors.reduce(function (acc, cur) { return acc + cur.percentage.value; }, 0);
        returner.push(payloadGroup);
    });
    return returner.sort(function (a, b) { return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()); });
}
function sortVendors(a, b) {
    return a.percentage.value - b.percentage.value !== 0
        ? a.percentage.value - b.percentage.value
        : a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
}
