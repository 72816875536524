<div class="trailer-create">
  <div class="trailer-create-header">
    <a class="close" [routerLink]="['../']" ctLongEscape>
      <i class="material-icons">
        close
      </i>
    </a>
    <span class="modal-title">
      <h1>Add trailer</h1>
    </span>
  </div>

  <form [formGroup]="formGroup" (ngSubmit)="addTrailer()">
    <div class="inputs">
      <ct-input label="Name" staticLabel class="span-6">
        <input ctInput formControlName="name" />
      </ct-input>
      <ct-input label="Model" staticLabel class="span-6">
        <input ctInput formControlName="model" />
      </ct-input>
    </div>
    <div class="submit-button">
      <ct-network-active-button [active]="networkActive$ | async">
        <button ct-dark-button class="full-button" type="submit">
          Add trailer
        </button>
      </ct-network-active-button>
    </div>
  </form>
</div>
