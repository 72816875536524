import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap } from 'rxjs/operators';
import { crowFliesDistance } from '~utilities/distance';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { trackById } from '~utilities/trackById';
var OrderDetailsEditSiteSelectorComponent = /** @class */ (function () {
    function OrderDetailsEditSiteSelectorComponent(sites) {
        this.sites = sites;
        this.trackById = trackById;
        this.selected = new EventEmitter();
        this.textChanges$$ = new BehaviorSubject(null);
    }
    OrderDetailsEditSiteSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searching$ = this.sites.searching$;
        this.siteGeofence$ = this.sites.currentSite$.pipe(filter(function (site) { return !!site; }), map(function (site) { return site.geofence; }));
        var searchResults$ = combineLatest([
            this.sites.sites$,
            this.textChanges$$.pipe(debounceTime(200)).pipe(switchMap(function (searchText) {
                if (searchText && searchText.length >= 2) {
                    return _this.sites.searchForSite$(searchText);
                }
                return of([]);
            })),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), sites = _b[0], searchResults = _b[1];
            if (!sites) {
                return searchResults;
            }
            var siteMap = idArrayToRecord(sites);
            return searchResults.filter(function (site) { return !site.archived && !siteMap[site.id]; });
        }));
        this.sitesWithHeaders$ = combineLatest([
            this.sites.sites$,
            this.sites.currentSite$,
            this.textChanges$$.pipe(debounceTime(100), startWith('')),
            searchResults$,
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 4), sites = _b[0], currentSite = _b[1], text = _b[2], searchResults = _b[3];
            if (!sites) {
                return [];
            }
            if (!currentSite) {
                return [];
            }
            var withoutCurrentSite = sites.filter(function (site) { return site.id !== currentSite.id; });
            var pinned = [];
            var recent = [];
            withoutCurrentSite.forEach(function (site) {
                if (site.favorited) {
                    pinned.push(site);
                }
                else {
                    recent.push(site);
                }
            });
            var sitesWithHeaders = [
                {
                    name: 'Pinned',
                    sitesList: pinned,
                },
                {
                    name: 'Recent',
                    sitesList: recent,
                },
            ];
            if (!text || text === '') {
                _this.topResult = withoutCurrentSite.length ? withoutCurrentSite[0] : null;
                return sitesWithHeaders;
            }
            var asLowercase = text.toLocaleLowerCase();
            var cleanedItems = sitesWithHeaders.map(function (item) {
                var filteredSites = item.sitesList.filter(function (site) {
                    return site.name.toLocaleLowerCase().includes(asLowercase);
                });
                return tslib_1.__assign({}, item, { sitesList: filteredSites });
            });
            var mergedGroups = tslib_1.__spread(cleanedItems, [
                {
                    name: 'Search Results',
                    sitesList: searchResults,
                },
            ]);
            var filtered = mergedGroups.filter(function (item) { return item.sitesList.length > 0; });
            if (filtered.length && filtered[0].sitesList.length) {
                _this.topResult = filtered[0].sitesList[0];
            }
            else {
                _this.topResult = null;
            }
            return filtered;
        }));
    };
    OrderDetailsEditSiteSelectorComponent.prototype.togglePin = function (event, siteID, isFavorited) {
        event.stopPropagation();
        this.sites.setFavorite(isFavorited, siteID);
    };
    OrderDetailsEditSiteSelectorComponent.prototype.inputTextChange = function (event) {
        this.textChanges$$.next(event.trim());
    };
    // calculate as the crow flies distance
    OrderDetailsEditSiteSelectorComponent.prototype.distance = function (lat1, lon1, lat2, lon2) {
        return crowFliesDistance(lat1, lon1, lat2, lon2);
    };
    OrderDetailsEditSiteSelectorComponent.prototype.selectSite = function (pickupFrom) {
        this.selected.emit({ pickupFrom: pickupFrom });
        this.smartDropdown.close();
    };
    OrderDetailsEditSiteSelectorComponent.prototype.enterKeyPressed = function () {
        if (this.topResult) {
            this.selectSite(this.topResult);
        }
        else {
            // this.createNewSite();
        }
    };
    return OrderDetailsEditSiteSelectorComponent;
}());
export { OrderDetailsEditSiteSelectorComponent };
