import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, finalize, map, startWith, takeUntil } from 'rxjs/operators';
import { CreateNewBusinessLineRequest } from '~proto/businessline/businessline_api_pb';
import { trackById } from '~utilities/trackById';
var LmoBusinessLineManagementCreateComponent = /** @class */ (function () {
    function LmoBusinessLineManagementCreateComponent(fb, businessLineService, router, snackbar, userService, costCenterService, roleService) {
        this.fb = fb;
        this.businessLineService = businessLineService;
        this.router = router;
        this.snackbar = snackbar;
        this.userService = userService;
        this.costCenterService = costCenterService;
        this.roleService = roleService;
        this.networkActive$ = new BehaviorSubject(false);
        this.hide = true;
        this.trackById = trackById;
        this.userSearch = new FormControl();
        this.costCenterSearch = new FormControl();
        this.destroy$$ = new Subject();
        this.isBusinessLineCostCenterRelationEnabled$$ = new BehaviorSubject(false);
        this.errorMessages = {
            lessThanTwoCharacter: 'minimum 2 character are required',
            minPasswordLength: 'minimum 6 characters are required',
            nameRequired: '*required',
        };
    }
    Object.defineProperty(LmoBusinessLineManagementCreateComponent.prototype, "nameInputValueCheck", {
        get: function () {
            var inputVal = this.formGroup.get('name').value || '';
            var inputLength = inputVal.length;
            var isRequired = inputLength === 0 ? this.errorMessages.nameRequired : '_';
            var errorMsg = inputLength > 0 && inputLength < 2 ? this.errorMessages.lessThanTwoCharacter : isRequired;
            return errorMsg;
        },
        enumerable: true,
        configurable: true
    });
    LmoBusinessLineManagementCreateComponent.prototype.formValue = function () {
        return this.formGroup.value;
    };
    LmoBusinessLineManagementCreateComponent.prototype.ngOnInit = function () {
        this.formGroup = this.fb.group({
            costCenter: [''],
            name: ['', [Validators.required]],
            user: [''],
        });
        this.usersList$ = combineLatest([
            this.userService.users$.pipe(takeUntil(this.destroy$$)),
            this.userSearch.valueChanges.pipe(takeUntil(this.destroy$$), debounceTime(500), startWith(''))
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), users = _b[0], filterText = _b[1];
            return users.filter(function (user) { return user.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0; });
        }));
        this.costCentersList$ = combineLatest([
            this.costCenterService.costCenters$.pipe(takeUntil(this.destroy$$)),
            this.costCenterSearch.valueChanges.pipe(takeUntil(this.destroy$$), debounceTime(500), startWith(''))
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), costCenters = _b[0], filterText = _b[1];
            return costCenters.filter(function (costCenter) { return costCenter.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0; });
        }));
        this.checkIsBusinessLineCostCenterRelationEnabled();
    };
    LmoBusinessLineManagementCreateComponent.prototype.addBusinessLine = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formValue;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.formGroup.markAllAsTouched();
                if (this.formGroup.invalid) {
                    return [2 /*return*/];
                }
                formValue = this.formValue();
                this.networkActive$.next(true);
                this.createBusinessLine$(formValue)
                    .pipe(finalize(function () {
                    _this.networkActive$.next(false);
                }))
                    .subscribe(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.snackbar.open(formValue.name + " created!", null, {
                                    duration: 2000,
                                });
                                return [4 /*yield*/, this.router.navigate(['/', 'lmo', 'business-lines'])];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    LmoBusinessLineManagementCreateComponent.prototype.checkIsBusinessLineCostCenterRelationEnabled = function () {
        var _this = this;
        this.roleService.isBusinessLineCostCenterRelationEnabled$.subscribe(function (isBLCCRelationEnabled) {
            _this.isBusinessLineCostCenterRelationEnabled$$.next(isBLCCRelationEnabled);
        });
    };
    LmoBusinessLineManagementCreateComponent.prototype.createBusinessLine$ = function (formValue) {
        var createBusinessLineRequest = new CreateNewBusinessLineRequest();
        createBusinessLineRequest.setName(formValue.name);
        createBusinessLineRequest.setUserIdsList(formValue.user);
        if (this.isBusinessLineCostCenterRelationEnabled$$.value) {
            createBusinessLineRequest.setCostCenterIdsList(formValue.costCenter);
        }
        return this.businessLineService.createBusinessLine$(createBusinessLineRequest);
    };
    return LmoBusinessLineManagementCreateComponent;
}());
export { LmoBusinessLineManagementCreateComponent };
