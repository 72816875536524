import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, finalize, map, take, tap } from 'rxjs/operators';
import { SelectPayloadTypeComponent } from '~common/select-payload-type/select-payload-type.component';
import { SelectPayloadUnitsComponent } from '~common/select-payload-units/select-payload-units.component';
import { CreatePayloadRequest } from '~proto/payload/payload_api_pb';
import { DeleteStockRequest, StockUpdate, UpdateStockRequest } from '~proto/site/site_api_pb';
import { trackById } from '~utilities/trackById';
var StockEditComponent = /** @class */ (function () {
    function StockEditComponent(site, fb, payloadService, matDialog, router, activatedRoute) {
        this.site = site;
        this.fb = fb;
        this.payloadService = payloadService;
        this.matDialog = matDialog;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.trackById = trackById;
        this.textChanges$$ = new BehaviorSubject(null);
        this.formTouched = false;
        this.networkActive$ = new BehaviorSubject(false);
    }
    StockEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formGroup = this.fb.group({
            maxQuantity: [null, [Validators.required, Validators.min(1)]],
            payload: [null, Validators.required],
            quantity: [null, Validators.required],
            stockId: [null],
        });
        this.siteName$ = this.site.currentSite$.pipe(filter(function (site) { return !!site; }), map(function (site) { return site.name; }));
        this.currentStock$ = this.site.currentSiteStock$.pipe(filter(function (stock) { return !!stock; }), take(1), tap(function (stock) {
            _this.formGroup.setValue({
                maxQuantity: stock.maxQuantity,
                payload: stock.payload,
                quantity: stock.quantity,
                stockId: stock.id,
            });
        }));
        this.payloadsGroups$ = combineLatest([
            this.payloadService.currentSitePayloads$,
            this.site.currentSite$,
            this.currentStock$,
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 3), payloadGroups = _b[0], site = _b[1], currentStock = _b[2];
            var sitePayloads = site.stockReferencesList.reduce(function (record, stock) {
                var _a;
                return tslib_1.__assign({}, record, (_a = {}, _a[stock.payload.id] = stock.payload, _a));
            }, {});
            var filteredGroups = [
                {
                    name: 'Current Payload',
                    payloadsList: [currentStock.payload],
                },
            ];
            payloadGroups.forEach(function (group) {
                filteredGroups.push(tslib_1.__assign({}, group, { payloadsList: group.payloadsList.filter(function (payload) { return !sitePayloads[payload.id]; }) }));
            });
            var sitePayloadsAsArray = Object.values(sitePayloads);
            if (sitePayloadsAsArray.length) {
                filteredGroups.push({
                    disabled: true,
                    name: 'Already On Site',
                    payloadsList: sitePayloadsAsArray,
                });
            }
            return filteredGroups;
        }));
        this.filteredPayloadGroups$ = combineLatest([this.payloadsGroups$, this.textChanges$$]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), groups = _b[0], text = _b[1];
            if (!groups) {
                return [];
            }
            if (!text || text === '') {
                return groups;
            }
            var cleanedItems = groups.map(function (item) { return (tslib_1.__assign({}, item, { payloadsList: item.payloadsList.filter(function (entry) {
                    return entry.name.toLocaleLowerCase().includes(text.toLocaleLowerCase());
                }) })); });
            return cleanedItems.filter(function (item) { return item.payloadsList.length > 0; });
        }));
    };
    StockEditComponent.prototype.inputTextChange = function (event) {
        this.textChanges$$.next(event.trim());
    };
    StockEditComponent.prototype.togglePin = function (event, payloadId, isFavorited) {
        event.stopPropagation();
        this.payloadService.updatePayloadFavorites(payloadId, isFavorited);
    };
    StockEditComponent.prototype.selectPayload = function (payload, isDisabled) {
        if (isDisabled) {
            return;
        }
        this.formGroup.patchValue({ payload: payload });
        this.smartDropdown.close();
    };
    StockEditComponent.prototype.createNewPayload = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var createPayloadRequest, unitsDialog, unit, payloadTypeDialog, payloadType;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        createPayloadRequest = new CreatePayloadRequest();
                        createPayloadRequest.setName(this.textChanges$$.value);
                        unitsDialog = this.matDialog.open(SelectPayloadUnitsComponent);
                        unitsDialog.componentInstance.payloadName = createPayloadRequest.getName();
                        return [4 /*yield*/, unitsDialog.afterClosed().toPromise()];
                    case 1:
                        unit = _a.sent();
                        if (!unit) {
                            return [2 /*return*/];
                        }
                        createPayloadRequest.setUnitId(unit.id);
                        payloadTypeDialog = this.matDialog.open(SelectPayloadTypeComponent);
                        payloadTypeDialog.componentInstance.payloadName = createPayloadRequest.getName();
                        return [4 /*yield*/, payloadTypeDialog.afterClosed().toPromise()];
                    case 2:
                        payloadType = _a.sent();
                        if (!payloadType) {
                            return [2 /*return*/];
                        }
                        createPayloadRequest.setPayloadTypeId(payloadType.id);
                        this.payloadService.createPayload$(createPayloadRequest).subscribe(function (payload) {
                            if (payload) {
                                _this.selectPayload(payload, false);
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    StockEditComponent.prototype.updateStock = function () {
        var _this = this;
        if (this.formGroup.invalid) {
            return;
        }
        this.networkActive$.next(true);
        var value = this.formGroup.value;
        var request = new UpdateStockRequest();
        var stockUpdate = new StockUpdate();
        stockUpdate.setMaxQuantity(value.maxQuantity);
        stockUpdate.setQuantity(value.quantity);
        stockUpdate.setStockId(value.stockId);
        request.addStockUpdates(stockUpdate);
        this.site
            .updateSiteStock$(request)
            .pipe(finalize(function () { return _this.networkActive$.next(false); }))
            .subscribe(function () {
            _this.router.navigate(['../../'], { relativeTo: _this.activatedRoute });
        });
    };
    StockEditComponent.prototype.removeStockPrompt = function () {
        this.confirmRemoveDialog = this.matDialog.open(this.confirmRemove);
    };
    StockEditComponent.prototype.removeStock = function () {
        var _this = this;
        var request = new DeleteStockRequest();
        var value = this.formGroup.value;
        request.setStockId(value.stockId);
        this.networkActive$.next(true);
        this.site
            .removeSiteStock$(request)
            .pipe(finalize(function () { return _this.networkActive$.next(false); }))
            .subscribe(function () {
            if (_this.confirmRemoveDialog) {
                _this.confirmRemoveDialog.close();
            }
            _this.router.navigate(['../../'], { relativeTo: _this.activatedRoute });
        });
    };
    return StockEditComponent;
}());
export { StockEditComponent };
