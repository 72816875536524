/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat-bot.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../directives/hotkey.directive";
import * as i3 from "@angular/router";
import * as i4 from "@angular/material/snack-bar";
import * as i5 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i6 from "@angular/material/icon";
import * as i7 from "@angular/common";
import * as i8 from "../pipes/safe-html.pipe";
import * as i9 from "@angular/platform-browser";
import * as i10 from "@angular/forms";
import * as i11 from "../button/button.component.ngfactory";
import * as i12 from "../button/button.component";
import * as i13 from "./chat-bot.component";
import * as i14 from "../../chatbot.service";
var styles_ChatBotComponent = [i0.styles];
var RenderType_ChatBotComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChatBotComponent, data: {} });
export { RenderType_ChatBotComponent as RenderType_ChatBotComponent };
function View_ChatBotComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "heading"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleHide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["HAULi\u2122\u00A0"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "emoji"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\uD83E\uDD16"]))], null, null); }
function View_ChatBotComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "heading"], ["ctHotkey", "h"]], null, [[null, "click"], [null, "hotkeyPressed"], ["document", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onclick($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).key($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.toggleHide() !== false);
        ad = (pd_2 && ad);
    } if (("hotkeyPressed" === en)) {
        var pd_3 = (_co.toggleHide() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.HotkeyDirective, [i3.Router, i4.MatSnackBar, i3.ActivatedRoute], { ctHotkey: [0, "ctHotkey"] }, { hotkeyPressed: "hotkeyPressed" }), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null], [2, i6.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["chat"]))], function (_ck, _v) { var currVal_0 = "h"; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 3).inline; var currVal_2 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_1, currVal_2); }); }
function View_ChatBotComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i7.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.parent.parent.context.$implicit.localUrl; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.parent.context.$implicit.localUrlText; _ck(_v, 2, 0, currVal_3); }); }
function View_ChatBotComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(2, 2), i1.ɵppd(3, 2), i1.ɵppd(4, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBotComponent_5)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = (_v.parent.context.$implicit.localUrl && _v.parent.context.$implicit.localUrl.length); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), ((((((((("Load " + _v.parent.context.$implicit.message.slice(_v.parent.context.$implicit.message.indexOf(" "), _v.parent.context.$implicit.message.lastIndexOf("w"))) + " was ") + _v.parent.context.$implicit.orderStatus) + " on ") + i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 1), (_v.parent.context.$implicit.statusUnix * 1000), "MM/dd/yy"))) + " at ") + i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 1), (_v.parent.context.$implicit.statusUnix * 1000), "h:mmaa"))) + " ") + _v.parent.context.$implicit.message.split(" ").pop()))); _ck(_v, 1, 0, currVal_0); }); }
function View_ChatBotComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i7.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.parent.parent.context.$implicit.localUrl; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.parent.context.$implicit.localUrlText; _ck(_v, 2, 0, currVal_3); }); }
function View_ChatBotComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBotComponent_7)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = (_v.parent.context.$implicit.localUrl && _v.parent.context.$implicit.localUrl.length); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.message)); _ck(_v, 0, 0, currVal_0); }); }
function View_ChatBotComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBotComponent_4)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["messageDontHaveStatusUnix", 2]], null, 0, null, View_ChatBotComponent_6))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.statusUnix; var currVal_2 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = ("message " + _v.context.$implicit.sender); _ck(_v, 0, 0, currVal_0); }); }
export function View_ChatBotComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.SafeHtmlPipe, [i9.DomSanitizer]), i1.ɵpid(0, i7.DatePipe, [i1.LOCALE_ID]), i1.ɵqud(671088640, 1, { input: 0 }), i1.ɵqud(671088640, 2, { messages: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 21, "div", [["class", "chat-bot-container"]], [[2, "on-left", null], [2, "active", null]], null, null, null, null)), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 19, "div", [["class", "chat-bot-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBotComponent_1)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBotComponent_2)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, [[2, 0], ["scroller", 1]], null, 3, "div", [["class", "message-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ChatBotComponent_3)), i1.ɵdid(13, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 10, "div", [["class", "input-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, [[1, 0], ["chatBotInput", 1]], null, 5, "input", [["placeholder", "Enter Message"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keydown.enter"], [null, "keydown.escape"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 17).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 17)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 17)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.enter" === en)) {
        var pd_4 = (_co.sendMessage() !== false);
        ad = (pd_4 && ad);
    } if (("keydown.escape" === en)) {
        var pd_5 = (_co.escapeKey($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 16384, null, 0, i10.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i10.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i10.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i10.DefaultValueAccessor]), i1.ɵdid(19, 540672, null, 0, i10.FormControlDirective, [[8, null], [8, null], [6, i10.NG_VALUE_ACCESSOR], [2, i10.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i10.NgControl, null, [i10.FormControlDirective]), i1.ɵdid(21, 16384, null, 0, i10.NgControlStatus, [[4, i10.NgControl]], null, null), (_l()(), i1.ɵeld(22, 0, null, null, 3, "button", [["ct-button", ""]], [[2, "ct-button", null], [2, "button", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendMessage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_ButtonComponent_0, i11.RenderType_ButtonComponent)), i1.ɵdid(23, 49152, null, 0, i12.ButtonComponent, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(24, 16384, null, 0, i12.CtButtonDirective, [], null, null), (_l()(), i1.ɵted(-1, 0, ["Send"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.hide; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.hide; _ck(_v, 10, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.chat$)); var currVal_5 = _co.trackBy; _ck(_v, 13, 0, currVal_4, currVal_5); var currVal_13 = _co.formControl; _ck(_v, 19, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.onRight$)); var currVal_1 = !_co.hide; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_6 = i1.ɵnov(_v, 21).ngClassUntouched; var currVal_7 = i1.ɵnov(_v, 21).ngClassTouched; var currVal_8 = i1.ɵnov(_v, 21).ngClassPristine; var currVal_9 = i1.ɵnov(_v, 21).ngClassDirty; var currVal_10 = i1.ɵnov(_v, 21).ngClassValid; var currVal_11 = i1.ɵnov(_v, 21).ngClassInvalid; var currVal_12 = i1.ɵnov(_v, 21).ngClassPending; _ck(_v, 16, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_14 = i1.ɵnov(_v, 24).ctButton; var currVal_15 = i1.ɵnov(_v, 24).secondary; _ck(_v, 22, 0, currVal_14, currVal_15); }); }
export function View_ChatBotComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ct-chat-bot", [], null, [["document", "keydown.escape"]], function (_v, en, $event) { var ad = true; if (("document:keydown.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).escapeKey($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ChatBotComponent_0, RenderType_ChatBotComponent)), i1.ɵdid(1, 114688, null, 0, i13.ChatBotComponent, [i14.ChatbotService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChatBotComponentNgFactory = i1.ɵccf("ct-chat-bot", i13.ChatBotComponent, View_ChatBotComponent_Host_0, {}, {}, []);
export { ChatBotComponentNgFactory as ChatBotComponentNgFactory };
