<div class="mini-modal" [class.grow]="grow">
  <header>
    <div class="title" *ngIf="title">{{ title }}</div>
    <div class="sub-title" *ngIf="subTitle">{{ subTitle }}</div>
  </header>
  <main>
    <ng-content></ng-content>
  </main>
  <footer>
    <ng-content></ng-content>
  </footer>
</div>
