import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import { flatten, omit, sort } from 'remeda';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';
import { getBillableTask } from 'src/app/dispatcher/utilities/orderHelpers';
import { OrdersService } from 'src/app/lmo/services/orders.service';
import { ContractsService } from '~lmo/services/contracts.service';
import { BusinessLine } from '~proto/businessline/businessline_pb';
import { VendorContract } from '~proto/contracts/contracts_pb';
import { LMOUpdateOrderRequest, SubTaskBillingUpdate } from '~proto/order/order_api_pb';
import { CostCenter, Order } from '~proto/order/order_pb';
import { Trailer } from '~proto/trailer/trailer_pb';
import { Truck } from '~proto/truck/truck_pb';
import { BillingStatus, NullableDouble, NullableString, OrderStatus, OrderType } from '~proto/types/types_pb';
import { UserDetailsForIntercomRequest, UserDetailsForIntercomResponse } from '~proto/user/user_api_pb';
import { UserAPI } from '~proto/user/user_api_pb_service';
import { GrpcService } from '~services/grpc.service';
import { RoleService } from '~services/role.service';
import { trackById } from '~utilities/trackById';
import { CostCenterService } from '../../../billing/services/cost-center.service';

interface FormValue {
  actualQuantity: number;
  ticketNumber: string;
  description: string;
  purchaseOrderName: string;
  trailer: Trailer.AsObject;
  truck: Truck.AsObject;
  costCenter: string;
  costCenterId: number;
  vendorContractId: number;
}

interface SubtaskSpecificStuff {
  actualQuantity: FormControl;
  bol?: FormControl;
  orderedQuantity: number;
  payloadName: string;
  payloadUnitAbbrev: string;
  subtaskId: number;
  type: 'pickup' | 'dropoff';
}

interface SubtaskSpecificStuffList {
  subtaskSpecificStuffs: SubtaskSpecificStuff[];
  hiddenInUi?: boolean;
  siteName: string;
}

@Component({
  selector: 'ct-lmo-edit-order',
  styleUrls: ['./lmo-edit-order.component.scss'],
  templateUrl: './lmo-edit-order.component.html',
})
export class LmoEditOrderComponent implements OnInit {
  public currentOrder$: Observable<Order.AsObject>;
  public formGroup: FormGroup;
  public siteBillingInfo: SubtaskSpecificStuffList[];
  public networkActive$ = new BehaviorSubject(false);
  public orderStatus = OrderStatus;
  public billingStatus = BillingStatus;

  public truckingContracts: VendorContract.AsObject[] = [];
  public trackById = trackById;
  public costCenters$: Observable<CostCenter.AsObject[]>;
  public isCostCenterRequired$$ = new BehaviorSubject<Boolean>(false);
  public isPurchaseOrderRequired$$ = new BehaviorSubject<Boolean>(false);
  public isBusinessLineCostCenterRelationEnabled$$ = new BehaviorSubject<Boolean>(false);
  public businessLineLinked$$: BehaviorSubject<BusinessLine.AsObject> = new BehaviorSubject(null);
  public isCCValid: any;
  private isPOValid = 'true';
  public costCenterObj: CostCenter.AsObject;

  constructor(
    private orderService: OrdersService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private contractService: ContractsService,
    private costCenterService: CostCenterService,
    private grpcService: GrpcService,
    private roleService: RoleService,
  ) {}

  public ngOnInit() {
    this.loadRequiredFieldSettings();
    this.costCenters$ = this.costCenterService.costCenters$;
    this.currentOrder$ = this.orderService.currentOrder$;
    this.formGroup = this.fb.group({
      costCenterId: [null, this.isCostCenterRequired$$.value ? [Validators.required] : ''],
      description: null,
      purchaseOrderName: null,
      ticketNumber: null,
      trailer: null,
      truck: null,
      vendorContractId: null,
    });
    this.currentOrder$
      .pipe(
        filter((order) => !!order),
        take(1),
      )
      .subscribe((order) => {
        const billableTask = getBillableTask(order);
        if (!billableTask) {
          return;
        }

        this.businessLineLinked$$.next(order.businessLine);
        if (this.isPurchaseOrderRequired$$.value && order.costCenter === '') {
          this.isCCValid = -1;
        }
        if (order && order.type !== OrderType.ORDER_TYPE_CUSTOM) {
          this.isPurchaseOrderRequired$$.value
            ? this.formGroup.controls['purchaseOrderName'].setValidators(Validators.required)
            : this.formGroup.controls['purchaseOrderName'].clearValidators();
        }
        this.costCenterObj = order.costCenterObj;
        this.formGroup.patchValue({
          bolNumber: billableTask.billing ? billableTask.billing.bolNumber : null,
          costCenterId: order.costCenterObj ? order.costCenterObj.id : -1,
          description: order.description,
          purchaseOrderName: billableTask.billing ? billableTask.billing.purchaseOrderName : null,
          ticketNumber: order.ticketNumber,
          trailer: order.trailer,
          truck: order.truck,
          vendorContractId: order.vendorContract ? order.vendorContract.id : null,
        });

        this.siteBillingInfo = getQuantitiesAsFormGroup(order);

        this.contractService.nonExpiredContracts$.subscribe((contracts) => {
          this.truckingContracts = contracts.filter(
            (contract) =>
              contract.vendor.id === order.truckingVendor.id &&
              (order.contractType == null ||
                (contract.contractType && order.contractType.id === contract.contractType.id)),
          );
        });
        this.listenCostCenterChanges();
        this.checkIsBusinessLineCostCenterRelationEnabled();
      });
  }

  private listenCostCenterChanges() {
    this.formGroup.get('costCenterId').valueChanges.subscribe((value) => {
      this.isCCValid = value;
    });
  }

  public selected(event) {
    this.formGroup.patchValue(event);
  }

  public isCustomOrder(order: Order.AsObject): Boolean {
    return order.type === OrderType.ORDER_TYPE_CUSTOM;
  }

  public async updateOrder(currentOrder: Order.AsObject) {
    const value = this.formGroup.value as FormValue;
    this.requiredFieldsCheck(value, currentOrder);

    if (!this.isCCValid || this.isCCValid === -1 || !this.isPOValid) {
      this.snackBar.open(
        `
        ${this.isCostCenterRequired$$.value && (!this.isCCValid || this.isCCValid === -1) ? 'Cost Center, ' : ''}
        ${this.isPurchaseOrderRequired$$.value && !this.isPOValid ? 'PO Number ' : ''}
        fields are mandatory`,
        null,
        {
          duration: 3000,
        },
      );
      return;
    }

    if (this.formGroup.invalid) {
      return;
    }

    const order = await this.currentOrder$.pipe(take(1)).toPromise();
    const isCustomOrder = this.isCustomOrder(order);

    this.networkActive$.next(true);
    const request = new LMOUpdateOrderRequest();
    if (!isCustomOrder) {
      request.setPurchaseOrderName(value.purchaseOrderName);
    }
    request.setTicketNumber(value.ticketNumber);
    const mask = new FieldMask();
    mask.addPaths('ticketNumber');
    if (!isCustomOrder) {
      mask.addPaths('purchaseOrderName');
    }
    mask.addPaths('description');
    if (value.truck) {
      request.setTruckId(value.truck.id);
      mask.addPaths('truckId');
    }
    if (value.trailer) {
      request.setTrailerId(value.trailer.id);
      mask.addPaths('trailerId');
    }
    request.setDescription(value.description);
    mask.addPaths('lmoCostCenterId');
    console.log('cost center id is,', value.costCenterId);
    request.setCostCenterId(value.costCenterId);

    if (
      order.vendorContract.id !== value.vendorContractId &&
      order.status !== this.orderStatus.ORDER_STATUS_COMPLETED
    ) {
      mask.addPaths('vendorContractId');
      mask.addPaths('vendorContractJson');
      request.setVendorContractId(value.vendorContractId);
    }

    request.setMask(mask);

    // Include the actual load weights and BOL on subtasks
    const siteActualQuantities = this.siteBillingInfo || [];
    const subtaskInputs = flatten(siteActualQuantities.map((site) => site.subtaskSpecificStuffs));
    const subtaskActualQuantityUpdates = subtaskInputs.map((subtaskInput) => {
      const update = new SubTaskBillingUpdate();
      update.setSubTaskId(subtaskInput.subtaskId);

      const actualQuantity = new NullableDouble();
      actualQuantity.setValid(subtaskInput.actualQuantity.value !== null);
      actualQuantity.setValue(subtaskInput.actualQuantity.value);
      update.setActualQuantity(actualQuantity);
      if (subtaskInput.bol && subtaskInput.type === 'dropoff') {
        const bol = new NullableString();
        bol.setValid(subtaskInput.bol.value !== null);
        bol.setValue(subtaskInput.bol.value);
        update.setBolNumber(bol);
      }
      return update;
    });
    request.setSubtaskBillingUpdateList(flatten(subtaskActualQuantityUpdates));

    this.orderService
      .updateOrder$(request)
      .pipe(finalize(() => this.networkActive$.next(false)))
      .subscribe(() => {
        this.snackBar.open('Order Updated', null, { duration: 2000 });
      });
  }

  private requiredFieldsCheck(formValue: FormValue, order: Order.AsObject) {
    this.isCCValid = this.isCostCenterRequired$$.value ? formValue.costCenterId : 'true';
    if (order && order.type !== OrderType.ORDER_TYPE_CUSTOM) {
      this.isPOValid = this.isPurchaseOrderRequired$$.value ? formValue.purchaseOrderName : 'true';
    }
  }

  public trackBySiteName(_: number, actualValueList: SubtaskSpecificStuffList) {
    return actualValueList.siteName;
  }

  public trackByPayloadName(_: number, actualQuantities: SubtaskSpecificStuff) {
    return actualQuantities.payloadName;
  }

  private checkIsBusinessLineCostCenterRelationEnabled() {
    this.roleService.isBusinessLineCostCenterRelationEnabled$.subscribe((isBLCCRelationEnabled: Boolean) => {
      this.isBusinessLineCostCenterRelationEnabled$$.next(isBLCCRelationEnabled);
      if (isBLCCRelationEnabled) {
        this.costCenterService.loadCostCenters(this.businessLineLinked$$.value);
      }
    });
  }

  private loadRequiredFieldSettings() {
    const request = new UserDetailsForIntercomRequest();
    (this.grpcService.invoke$(UserAPI.UserDetailsForIntercom, request) as Observable<
      UserDetailsForIntercomResponse
    >).subscribe((response) => {
      this.isCostCenterRequired$$.next(response.toObject().userDetails.isCostCenterRequired);
      this.isPurchaseOrderRequired$$.next(response.toObject().userDetails.isPurchaseOrderRequired);
    });
  }
}

const payloadPickupTypes = {
  pickup: true,
  'pickup-payload': true,
};

const payloadDropoffTypes = {
  dropoff: true,
  'dropoff-payload': true,
};

const payloadSubTaskTypes = {
  ...payloadPickupTypes,
  ...payloadDropoffTypes,
};

function getQuantitiesAsFormGroup(order: Order.AsObject): SubtaskSpecificStuffList[] {
  if (order.type === OrderType.ORDER_TYPE_CUSTOM) {
    return getQuantitiesAsFormGroupForCustomOrder(order);
  }
  return getQuantitiesAsFormGroupForRegularOrder(order);
}

function getQuantitiesAsFormGroupForCustomOrder(order: Order.AsObject): SubtaskSpecificStuffList[] {
  const sortedTasks = sort(order.tasksList, (a, b) => a.sequence - b.sequence);
  return sortedTasks
    .map((task) => {
      const payloadSubTasks = task.subTasksList.filter((subtask) => payloadSubTaskTypes[subtask.type.name]);
      const subtaskSpecificStuffList: SubtaskSpecificStuffList = {
        siteName: task.site.name,
        subtaskSpecificStuffs: payloadSubTasks.map((subTask) => {
          const billingInfo: SubtaskSpecificStuff = {
            actualQuantity: new FormControl(
              subTask.actualQuantity > Number.MIN_SAFE_INTEGER ? subTask.actualQuantity : null,
            ),
            orderedQuantity: subTask.orderedQuantity,
            payloadName: subTask.payload.name,
            payloadUnitAbbrev: subTask.payload.unit.abbreviation,
            subtaskId: subTask.id,
            type: getType(subTask.type.name),
          };
          if (billingInfo.type === 'dropoff') {
            billingInfo.bol = new FormControl(subTask.billing ? subTask.billing.bolNumber : null);
          }
          return billingInfo;
        }),
      };
      return subtaskSpecificStuffList;
    })
    .filter((actualQuantity) => actualQuantity.subtaskSpecificStuffs.length);
}

function getQuantitiesAsFormGroupForRegularOrder(order: Order.AsObject): SubtaskSpecificStuffList[] {
  const sortedTasks = sort(order.tasksList, (a, b) => a.sequence - b.sequence);
  const dropOffTasks = sortedTasks
    .map((task) => {
      const payloadSubTasks = task.subTasksList.filter((subtask) => payloadDropoffTypes[subtask.type.name]);
      const actualValueList: SubtaskSpecificStuffList = {
        siteName: task.site.name,
        subtaskSpecificStuffs: payloadSubTasks.map((subTask) => {
          const billingInfo: SubtaskSpecificStuff = {
            actualQuantity: new FormControl(
              subTask.actualQuantity > Number.MIN_SAFE_INTEGER ? subTask.actualQuantity : null,
            ),
            orderedQuantity: subTask.orderedQuantity,
            payloadName: subTask.payload.name,
            payloadUnitAbbrev: subTask.payload.unit.abbreviation,
            subtaskId: subTask.id,
            type: getType(subTask.type.name),
          };
          if (billingInfo.type === 'dropoff') {
            billingInfo.bol = new FormControl(subTask.billing ? subTask.billing.bolNumber : null);
          }
          return billingInfo;
        }),
      };
      return actualValueList;
    })
    .filter((actualQuantity) => actualQuantity.subtaskSpecificStuffs.length);

  // There should only be one task since this is not a custom order, but being safe
  if (dropOffTasks.length !== 1 || dropOffTasks[0].subtaskSpecificStuffs.length !== 1) {
    return [];
  }
  const pickupSubtask = flatten(sortedTasks.map((task) => task.subTasksList)).find(
    (subTask) => payloadPickupTypes[subTask.type.name],
  );
  if (pickupSubtask) {
    dropOffTasks.push({
      hiddenInUi: true,
      siteName: null, // don't care, won't show
      subtaskSpecificStuffs: [omit(dropOffTasks[0].subtaskSpecificStuffs[0], ['bol'])],
    });
    dropOffTasks[1].subtaskSpecificStuffs[0].subtaskId = pickupSubtask.id;
  }
  return dropOffTasks;
}

function getType(type: string): 'dropoff' | 'pickup' {
  if (payloadPickupTypes[type]) {
    return 'pickup';
  }
  if (payloadDropoffTypes[type]) {
    return 'dropoff';
  }
  return null;
}
