/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_cybertron_order_order_pb = require('../../../proto/cybertron/order/order_pb.js');
var proto_cybertron_user_user_pb = require('../../../proto/cybertron/user/user_pb.js');
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
var proto_cybertron_types_types_pb = require('../../../proto/cybertron/types/types_pb.js');
var proto_cybertron_fields_fields_pb = require('../../../proto/cybertron/fields/fields_pb.js');
var proto_cybertron_payload_payload_pb = require('../../../proto/cybertron/payload/payload_pb.js');
goog.exportSymbol('proto.cbv1.AcceptOrderForDriverRequest', null, global);
goog.exportSymbol('proto.cbv1.AcceptOrderForDriverResponse', null, global);
goog.exportSymbol('proto.cbv1.ArchiveCostCenterRequest', null, global);
goog.exportSymbol('proto.cbv1.ArchiveCostCenterResponse', null, global);
goog.exportSymbol('proto.cbv1.AttachmentUrlRequest', null, global);
goog.exportSymbol('proto.cbv1.AttachmentUrlResponse', null, global);
goog.exportSymbol('proto.cbv1.AutoCloseOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.AutoCloseOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.BackHaulRequest', null, global);
goog.exportSymbol('proto.cbv1.BackHaulResponse', null, global);
goog.exportSymbol('proto.cbv1.CanLoadBeBackHaulRequest', null, global);
goog.exportSymbol('proto.cbv1.CanLoadBeBackHaulResponse', null, global);
goog.exportSymbol('proto.cbv1.CanUpdateOrderPickupTimeRequest', null, global);
goog.exportSymbol('proto.cbv1.CanUpdateOrderPickupTimeResponse', null, global);
goog.exportSymbol('proto.cbv1.CompleteOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.CompleteOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.CreateAssetRequest', null, global);
goog.exportSymbol('proto.cbv1.CreateAssetResponse', null, global);
goog.exportSymbol('proto.cbv1.CreateCostCenterRequest', null, global);
goog.exportSymbol('proto.cbv1.CreateCostCenterResponse', null, global);
goog.exportSymbol('proto.cbv1.CreateOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.CreateOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.CreateSubTaskRequest', null, global);
goog.exportSymbol('proto.cbv1.CreateTaskRequest', null, global);
goog.exportSymbol('proto.cbv1.DeleteAttachmentRequest', null, global);
goog.exportSymbol('proto.cbv1.DeleteAttachmentResponse', null, global);
goog.exportSymbol('proto.cbv1.DispatcherCalendarOrderSummariesRequest', null, global);
goog.exportSymbol('proto.cbv1.DispatcherCalendarOrderSummariesResponse', null, global);
goog.exportSymbol('proto.cbv1.DispatcherUpdateTaskSiteSingle', null, global);
goog.exportSymbol('proto.cbv1.DriverAcceptOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.DriverAcceptOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.DriverCanUploadRequest', null, global);
goog.exportSymbol('proto.cbv1.DriverCanUploadResponse', null, global);
goog.exportSymbol('proto.cbv1.DriverCompleteOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.DriverCompleteOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.DriverGetOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.DriverGetOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.DriverRejectOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.DriverRejectOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.DriverStopRequest', null, global);
goog.exportSymbol('proto.cbv1.DriverStopResponse', null, global);
goog.exportSymbol('proto.cbv1.DriverUpdateLoadDetailsRequest', null, global);
goog.exportSymbol('proto.cbv1.DriverUpdateLoadDetailsResponse', null, global);
goog.exportSymbol('proto.cbv1.FleetManagerDriverRequest', null, global);
goog.exportSymbol('proto.cbv1.FleetManagerDriverResponse', null, global);
goog.exportSymbol('proto.cbv1.GetAssetGroupsRequest', null, global);
goog.exportSymbol('proto.cbv1.GetAssetGroupsResponse', null, global);
goog.exportSymbol('proto.cbv1.GetAssetTypesRequest', null, global);
goog.exportSymbol('proto.cbv1.GetAssetTypesResponse', null, global);
goog.exportSymbol('proto.cbv1.GetAssetsRequest', null, global);
goog.exportSymbol('proto.cbv1.GetAssetsResponse', null, global);
goog.exportSymbol('proto.cbv1.GetOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.GetSiteOrderETAsRequest', null, global);
goog.exportSymbol('proto.cbv1.GetSiteOrderETAsResponse', null, global);
goog.exportSymbol('proto.cbv1.GetSubTaskTypesRequest', null, global);
goog.exportSymbol('proto.cbv1.GetSubTaskTypesResponse', null, global);
goog.exportSymbol('proto.cbv1.GetVendorMappableOrderCompletedSlimRequest', null, global);
goog.exportSymbol('proto.cbv1.GetVendorMappableOrderCompletedSlimResponse', null, global);
goog.exportSymbol('proto.cbv1.LMOBulkCancelOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.LMOBulkCancelOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.LMOBulkMoveScheduleForOrdersRequest', null, global);
goog.exportSymbol('proto.cbv1.LMOBulkMoveScheduleForOrdersResponse', null, global);
goog.exportSymbol('proto.cbv1.LMOBulkSetAsASAPOrdersRequest', null, global);
goog.exportSymbol('proto.cbv1.LMOBulkSetAsASAPOrdersResponse', null, global);
goog.exportSymbol('proto.cbv1.LMOBulkSetAsScheduledOrdersRequest', null, global);
goog.exportSymbol('proto.cbv1.LMOBulkSetAsScheduledOrdersResponse', null, global);
goog.exportSymbol('proto.cbv1.LMOCancelOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.LMOCancelOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.LMOReassignOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.LMOReassignOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.LMOUpdateOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.LMOUpdateOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.LMOUpdatePendingOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.LMOUpdatePendingOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.ListCostCenterRequest', null, global);
goog.exportSymbol('proto.cbv1.ListCostCenterResponse', null, global);
goog.exportSymbol('proto.cbv1.LoadHeatMapRequest', null, global);
goog.exportSymbol('proto.cbv1.LoadHeatMapResponse', null, global);
goog.exportSymbol('proto.cbv1.MaintenanceOrderSearchRequest', null, global);
goog.exportSymbol('proto.cbv1.MaintenanceOrderSearchResponse', null, global);
goog.exportSymbol('proto.cbv1.ManuallyCompleteTaskRequest', null, global);
goog.exportSymbol('proto.cbv1.ManuallyCompleteTaskResponse', null, global);
goog.exportSymbol('proto.cbv1.MappableOrderCompletedResponse', null, global);
goog.exportSymbol('proto.cbv1.MappableOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.MappableOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.MappableOrderStreamRequest', null, global);
goog.exportSymbol('proto.cbv1.MappableOrderStreamResponse', null, global);
goog.exportSymbol('proto.cbv1.OrderEventStreamRequest', null, global);
goog.exportSymbol('proto.cbv1.OrderEventStreamResponse', null, global);
goog.exportSymbol('proto.cbv1.OrderResponse', null, global);
goog.exportSymbol('proto.cbv1.PurchaseOrderSearchRequest', null, global);
goog.exportSymbol('proto.cbv1.PurchaseOrderSearchResponse', null, global);
goog.exportSymbol('proto.cbv1.SalesOrderSearchRequest', null, global);
goog.exportSymbol('proto.cbv1.SalesOrderSearchResponse', null, global);
goog.exportSymbol('proto.cbv1.StatsRequest', null, global);
goog.exportSymbol('proto.cbv1.StatsResponse', null, global);
goog.exportSymbol('proto.cbv1.StreamMappableOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.StreamMappableOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.SubTaskActualQuantityUpdate', null, global);
goog.exportSymbol('proto.cbv1.SubTaskBillingUpdate', null, global);
goog.exportSymbol('proto.cbv1.SwapDriverOnOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.SwapDriverOnOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.TasksListCanBeBackHaul', null, global);
goog.exportSymbol('proto.cbv1.UnassignTrailerOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.UnassignTrailerOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.UnassignTruckOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.UnassignTruckOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.UpdateAssetRequest', null, global);
goog.exportSymbol('proto.cbv1.UpdateAssetResponse', null, global);
goog.exportSymbol('proto.cbv1.UpdateCostCenterRequest', null, global);
goog.exportSymbol('proto.cbv1.UpdateCostCenterResponse', null, global);
goog.exportSymbol('proto.cbv1.UpdateOrderPickupTimeRequest', null, global);
goog.exportSymbol('proto.cbv1.UpdateOrderPickupTimeResponse', null, global);
goog.exportSymbol('proto.cbv1.VendorDeclineOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.VendorDeclineOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.VendorDispatchOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.VendorDispatchOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.VendorUnassignDriverOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.VendorUnassignDriverOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.VendorUnassignOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.VendorUnassignOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.VendorUpdateOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.VendorUpdateOrderResponse', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CanLoadBeBackHaulRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CanLoadBeBackHaulRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CanLoadBeBackHaulRequest.displayName = 'proto.cbv1.CanLoadBeBackHaulRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CanLoadBeBackHaulRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CanLoadBeBackHaulRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CanLoadBeBackHaulRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanLoadBeBackHaulRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    loadId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CanLoadBeBackHaulRequest}
 */
proto.cbv1.CanLoadBeBackHaulRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CanLoadBeBackHaulRequest;
  return proto.cbv1.CanLoadBeBackHaulRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CanLoadBeBackHaulRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CanLoadBeBackHaulRequest}
 */
proto.cbv1.CanLoadBeBackHaulRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLoadId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CanLoadBeBackHaulRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CanLoadBeBackHaulRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CanLoadBeBackHaulRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanLoadBeBackHaulRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoadId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 load_id = 1;
 * @return {number}
 */
proto.cbv1.CanLoadBeBackHaulRequest.prototype.getLoadId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.CanLoadBeBackHaulRequest.prototype.setLoadId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CanLoadBeBackHaulResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.CanLoadBeBackHaulResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.CanLoadBeBackHaulResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CanLoadBeBackHaulResponse.displayName = 'proto.cbv1.CanLoadBeBackHaulResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.CanLoadBeBackHaulResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CanLoadBeBackHaulResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CanLoadBeBackHaulResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CanLoadBeBackHaulResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanLoadBeBackHaulResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    canBeBackHaul: jspb.Message.getFieldWithDefault(msg, 1, false),
    allowedPayloadTypeIdsList: jspb.Message.getRepeatedField(msg, 2)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CanLoadBeBackHaulResponse}
 */
proto.cbv1.CanLoadBeBackHaulResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CanLoadBeBackHaulResponse;
  return proto.cbv1.CanLoadBeBackHaulResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CanLoadBeBackHaulResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CanLoadBeBackHaulResponse}
 */
proto.cbv1.CanLoadBeBackHaulResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanBeBackHaul(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setAllowedPayloadTypeIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CanLoadBeBackHaulResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CanLoadBeBackHaulResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CanLoadBeBackHaulResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanLoadBeBackHaulResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanBeBackHaul();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAllowedPayloadTypeIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * optional bool can_be_back_haul = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.CanLoadBeBackHaulResponse.prototype.getCanBeBackHaul = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.CanLoadBeBackHaulResponse.prototype.setCanBeBackHaul = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated int32 allowed_payload_type_ids = 2;
 * @return {!Array<number>}
 */
proto.cbv1.CanLoadBeBackHaulResponse.prototype.getAllowedPayloadTypeIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<number>} value */
proto.cbv1.CanLoadBeBackHaulResponse.prototype.setAllowedPayloadTypeIdsList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.CanLoadBeBackHaulResponse.prototype.addAllowedPayloadTypeIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.cbv1.CanLoadBeBackHaulResponse.prototype.clearAllowedPayloadTypeIdsList = function() {
  this.setAllowedPayloadTypeIdsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.TasksListCanBeBackHaul = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.TasksListCanBeBackHaul, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.TasksListCanBeBackHaul.displayName = 'proto.cbv1.TasksListCanBeBackHaul';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.TasksListCanBeBackHaul.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.TasksListCanBeBackHaul.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.TasksListCanBeBackHaul} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.TasksListCanBeBackHaul.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    siteId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    siteName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    taskSequence: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.TasksListCanBeBackHaul}
 */
proto.cbv1.TasksListCanBeBackHaul.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.TasksListCanBeBackHaul;
  return proto.cbv1.TasksListCanBeBackHaul.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.TasksListCanBeBackHaul} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.TasksListCanBeBackHaul}
 */
proto.cbv1.TasksListCanBeBackHaul.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskSequence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.TasksListCanBeBackHaul.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.TasksListCanBeBackHaul.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.TasksListCanBeBackHaul} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.TasksListCanBeBackHaul.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTaskSequence();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 task_id = 1;
 * @return {number}
 */
proto.cbv1.TasksListCanBeBackHaul.prototype.getTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.TasksListCanBeBackHaul.prototype.setTaskId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 site_id = 2;
 * @return {number}
 */
proto.cbv1.TasksListCanBeBackHaul.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.TasksListCanBeBackHaul.prototype.setSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string site_name = 3;
 * @return {string}
 */
proto.cbv1.TasksListCanBeBackHaul.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.TasksListCanBeBackHaul.prototype.setSiteName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 task_sequence = 4;
 * @return {number}
 */
proto.cbv1.TasksListCanBeBackHaul.prototype.getTaskSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.TasksListCanBeBackHaul.prototype.setTaskSequence = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.BackHaulRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.BackHaulRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.BackHaulRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.BackHaulRequest.displayName = 'proto.cbv1.BackHaulRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.BackHaulRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.BackHaulRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.BackHaulRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.BackHaulRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BackHaulRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    loadId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstTaskSeqeunceNumberToBeAdded: jspb.Message.getFieldWithDefault(msg, 2, 0),
    taskRequestsList: jspb.Message.toObjectList(msg.getTaskRequestsList(),
    proto.cbv1.CreateTaskRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.BackHaulRequest}
 */
proto.cbv1.BackHaulRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.BackHaulRequest;
  return proto.cbv1.BackHaulRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.BackHaulRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.BackHaulRequest}
 */
proto.cbv1.BackHaulRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLoadId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFirstTaskSeqeunceNumberToBeAdded(value);
      break;
    case 3:
      var value = new proto.cbv1.CreateTaskRequest;
      reader.readMessage(value,proto.cbv1.CreateTaskRequest.deserializeBinaryFromReader);
      msg.addTaskRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.BackHaulRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.BackHaulRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.BackHaulRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BackHaulRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoadId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstTaskSeqeunceNumberToBeAdded();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTaskRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.cbv1.CreateTaskRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 load_id = 1;
 * @return {number}
 */
proto.cbv1.BackHaulRequest.prototype.getLoadId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.BackHaulRequest.prototype.setLoadId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 first_task_seqeunce_number_to_be_added = 2;
 * @return {number}
 */
proto.cbv1.BackHaulRequest.prototype.getFirstTaskSeqeunceNumberToBeAdded = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.BackHaulRequest.prototype.setFirstTaskSeqeunceNumberToBeAdded = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated CreateTaskRequest task_requests = 3;
 * @return {!Array<!proto.cbv1.CreateTaskRequest>}
 */
proto.cbv1.BackHaulRequest.prototype.getTaskRequestsList = function() {
  return /** @type{!Array<!proto.cbv1.CreateTaskRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.CreateTaskRequest, 3));
};


/** @param {!Array<!proto.cbv1.CreateTaskRequest>} value */
proto.cbv1.BackHaulRequest.prototype.setTaskRequestsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.cbv1.CreateTaskRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.CreateTaskRequest}
 */
proto.cbv1.BackHaulRequest.prototype.addTaskRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.cbv1.CreateTaskRequest, opt_index);
};


proto.cbv1.BackHaulRequest.prototype.clearTaskRequestsList = function() {
  this.setTaskRequestsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.BackHaulResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.BackHaulResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.BackHaulResponse.displayName = 'proto.cbv1.BackHaulResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.BackHaulResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.BackHaulResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.BackHaulResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BackHaulResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto_cybertron_order_order_pb.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.BackHaulResponse}
 */
proto.cbv1.BackHaulResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.BackHaulResponse;
  return proto.cbv1.BackHaulResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.BackHaulResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.BackHaulResponse}
 */
proto.cbv1.BackHaulResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.Order;
      reader.readMessage(value,proto_cybertron_order_order_pb.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.BackHaulResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.BackHaulResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.BackHaulResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BackHaulResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.cbv1.Order}
 */
proto.cbv1.BackHaulResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.Order} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.Order, 1));
};


/** @param {?proto.cbv1.Order|undefined} value */
proto.cbv1.BackHaulResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.BackHaulResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.BackHaulResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LoadHeatMapRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LoadHeatMapRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LoadHeatMapRequest.displayName = 'proto.cbv1.LoadHeatMapRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LoadHeatMapRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LoadHeatMapRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LoadHeatMapRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LoadHeatMapRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    loadId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LoadHeatMapRequest}
 */
proto.cbv1.LoadHeatMapRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LoadHeatMapRequest;
  return proto.cbv1.LoadHeatMapRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LoadHeatMapRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LoadHeatMapRequest}
 */
proto.cbv1.LoadHeatMapRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLoadId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LoadHeatMapRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LoadHeatMapRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LoadHeatMapRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LoadHeatMapRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoadId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 load_id = 1;
 * @return {number}
 */
proto.cbv1.LoadHeatMapRequest.prototype.getLoadId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.LoadHeatMapRequest.prototype.setLoadId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LoadHeatMapResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.LoadHeatMapResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.LoadHeatMapResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LoadHeatMapResponse.displayName = 'proto.cbv1.LoadHeatMapResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.LoadHeatMapResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LoadHeatMapResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LoadHeatMapResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LoadHeatMapResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LoadHeatMapResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    waypointsList: jspb.Message.toObjectList(msg.getWaypointsList(),
    proto_cybertron_user_user_pb.Waypoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LoadHeatMapResponse}
 */
proto.cbv1.LoadHeatMapResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LoadHeatMapResponse;
  return proto.cbv1.LoadHeatMapResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LoadHeatMapResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LoadHeatMapResponse}
 */
proto.cbv1.LoadHeatMapResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_user_user_pb.Waypoint;
      reader.readMessage(value,proto_cybertron_user_user_pb.Waypoint.deserializeBinaryFromReader);
      msg.addWaypoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LoadHeatMapResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LoadHeatMapResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LoadHeatMapResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LoadHeatMapResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWaypointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_user_user_pb.Waypoint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Waypoint waypoints = 1;
 * @return {!Array<!proto.cbv1.Waypoint>}
 */
proto.cbv1.LoadHeatMapResponse.prototype.getWaypointsList = function() {
  return /** @type{!Array<!proto.cbv1.Waypoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_user_user_pb.Waypoint, 1));
};


/** @param {!Array<!proto.cbv1.Waypoint>} value */
proto.cbv1.LoadHeatMapResponse.prototype.setWaypointsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.Waypoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.Waypoint}
 */
proto.cbv1.LoadHeatMapResponse.prototype.addWaypoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.Waypoint, opt_index);
};


proto.cbv1.LoadHeatMapResponse.prototype.clearWaypointsList = function() {
  this.setWaypointsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CanUpdateOrderPickupTimeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CanUpdateOrderPickupTimeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CanUpdateOrderPickupTimeRequest.displayName = 'proto.cbv1.CanUpdateOrderPickupTimeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CanUpdateOrderPickupTimeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CanUpdateOrderPickupTimeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CanUpdateOrderPickupTimeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanUpdateOrderPickupTimeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CanUpdateOrderPickupTimeRequest}
 */
proto.cbv1.CanUpdateOrderPickupTimeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CanUpdateOrderPickupTimeRequest;
  return proto.cbv1.CanUpdateOrderPickupTimeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CanUpdateOrderPickupTimeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CanUpdateOrderPickupTimeRequest}
 */
proto.cbv1.CanUpdateOrderPickupTimeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CanUpdateOrderPickupTimeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CanUpdateOrderPickupTimeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CanUpdateOrderPickupTimeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanUpdateOrderPickupTimeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.cbv1.CanUpdateOrderPickupTimeRequest.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.CanUpdateOrderPickupTimeRequest.prototype.setKey = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 order_id = 2;
 * @return {number}
 */
proto.cbv1.CanUpdateOrderPickupTimeRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.CanUpdateOrderPickupTimeRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CanUpdateOrderPickupTimeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CanUpdateOrderPickupTimeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CanUpdateOrderPickupTimeResponse.displayName = 'proto.cbv1.CanUpdateOrderPickupTimeResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CanUpdateOrderPickupTimeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CanUpdateOrderPickupTimeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CanUpdateOrderPickupTimeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanUpdateOrderPickupTimeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto_cybertron_order_order_pb.Order.toObject(includeInstance, f),
    scheduledPickupStartUnix: jspb.Message.getFieldWithDefault(msg, 2, 0),
    scheduledPickupEndUnix: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CanUpdateOrderPickupTimeResponse}
 */
proto.cbv1.CanUpdateOrderPickupTimeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CanUpdateOrderPickupTimeResponse;
  return proto.cbv1.CanUpdateOrderPickupTimeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CanUpdateOrderPickupTimeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CanUpdateOrderPickupTimeResponse}
 */
proto.cbv1.CanUpdateOrderPickupTimeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.Order;
      reader.readMessage(value,proto_cybertron_order_order_pb.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScheduledPickupStartUnix(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScheduledPickupEndUnix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CanUpdateOrderPickupTimeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CanUpdateOrderPickupTimeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CanUpdateOrderPickupTimeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CanUpdateOrderPickupTimeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.Order.serializeBinaryToWriter
    );
  }
  f = message.getScheduledPickupStartUnix();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getScheduledPickupEndUnix();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.cbv1.Order}
 */
proto.cbv1.CanUpdateOrderPickupTimeResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.Order} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.Order, 1));
};


/** @param {?proto.cbv1.Order|undefined} value */
proto.cbv1.CanUpdateOrderPickupTimeResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.CanUpdateOrderPickupTimeResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CanUpdateOrderPickupTimeResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 scheduled_pickup_start_unix = 2;
 * @return {number}
 */
proto.cbv1.CanUpdateOrderPickupTimeResponse.prototype.getScheduledPickupStartUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.CanUpdateOrderPickupTimeResponse.prototype.setScheduledPickupStartUnix = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 scheduled_pickup_end_unix = 3;
 * @return {number}
 */
proto.cbv1.CanUpdateOrderPickupTimeResponse.prototype.getScheduledPickupEndUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.CanUpdateOrderPickupTimeResponse.prototype.setScheduledPickupEndUnix = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateOrderPickupTimeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UpdateOrderPickupTimeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateOrderPickupTimeRequest.displayName = 'proto.cbv1.UpdateOrderPickupTimeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateOrderPickupTimeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateOrderPickupTimeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateOrderPickupTimeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateOrderPickupTimeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    scheduledPickupUnix: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateOrderPickupTimeRequest}
 */
proto.cbv1.UpdateOrderPickupTimeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateOrderPickupTimeRequest;
  return proto.cbv1.UpdateOrderPickupTimeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateOrderPickupTimeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateOrderPickupTimeRequest}
 */
proto.cbv1.UpdateOrderPickupTimeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScheduledPickupUnix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateOrderPickupTimeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateOrderPickupTimeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateOrderPickupTimeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateOrderPickupTimeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getScheduledPickupUnix();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.cbv1.UpdateOrderPickupTimeRequest.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.UpdateOrderPickupTimeRequest.prototype.setKey = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 order_id = 2;
 * @return {number}
 */
proto.cbv1.UpdateOrderPickupTimeRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.UpdateOrderPickupTimeRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 scheduled_pickup_unix = 3;
 * @return {number}
 */
proto.cbv1.UpdateOrderPickupTimeRequest.prototype.getScheduledPickupUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.UpdateOrderPickupTimeRequest.prototype.setScheduledPickupUnix = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateOrderPickupTimeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UpdateOrderPickupTimeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateOrderPickupTimeResponse.displayName = 'proto.cbv1.UpdateOrderPickupTimeResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateOrderPickupTimeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateOrderPickupTimeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateOrderPickupTimeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateOrderPickupTimeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorReason: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateOrderPickupTimeResponse}
 */
proto.cbv1.UpdateOrderPickupTimeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateOrderPickupTimeResponse;
  return proto.cbv1.UpdateOrderPickupTimeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateOrderPickupTimeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateOrderPickupTimeResponse}
 */
proto.cbv1.UpdateOrderPickupTimeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorReason(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateOrderPickupTimeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateOrderPickupTimeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateOrderPickupTimeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateOrderPickupTimeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorReason();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string error_reason = 1;
 * @return {string}
 */
proto.cbv1.UpdateOrderPickupTimeResponse.prototype.getErrorReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.UpdateOrderPickupTimeResponse.prototype.setErrorReason = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_message = 2;
 * @return {string}
 */
proto.cbv1.UpdateOrderPickupTimeResponse.prototype.getDisplayMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.UpdateOrderPickupTimeResponse.prototype.setDisplayMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.CreateOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.CreateOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateOrderRequest.displayName = 'proto.cbv1.CreateOrderRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.CreateOrderRequest.repeatedFields_ = [4,14,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    truckingVendorId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    confirmationRequired: jspb.Message.getFieldWithDefault(msg, 3, false),
    taskRequestsList: jspb.Message.toObjectList(msg.getTaskRequestsList(),
    proto.cbv1.CreateTaskRequest.toObject, includeInstance),
    orderQuantity: jspb.Message.getFieldWithDefault(msg, 5, 0),
    purchaseOrderName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    trailerId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    trailerSiteId: jspb.Message.getFieldWithDefault(msg, 19, 0),
    salesOrderNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    scheduledUnix: jspb.Message.getFieldWithDefault(msg, 9, 0),
    staggerMinutes: jspb.Message.getFieldWithDefault(msg, 10, 0),
    deliveryWindowStart: jspb.Message.getFieldWithDefault(msg, 11, 0),
    deliveryWindowDurationMinutes: jspb.Message.getFieldWithDefault(msg, 12, 0),
    isAsapOrder: jspb.Message.getFieldWithDefault(msg, 13, false),
    customFieldsList: jspb.Message.toObjectList(msg.getCustomFieldsList(),
    proto_cybertron_fields_fields_pb.CustomField.toObject, includeInstance),
    schedulePickupTime: jspb.Message.getFieldWithDefault(msg, 15, false),
    loaderEmailsList: jspb.Message.getRepeatedField(msg, 16),
    type: jspb.Message.getFieldWithDefault(msg, 17, 0),
    truckId: jspb.Message.getFieldWithDefault(msg, 18, 0),
    costCenter: jspb.Message.getFieldWithDefault(msg, 20, ""),
    vendorContractId: jspb.Message.getFieldWithDefault(msg, 21, 0),
    contractTypeId: jspb.Message.getFieldWithDefault(msg, 22, 0),
    costCenterId: jspb.Message.getFieldWithDefault(msg, 23, 0),
    businessLineId: jspb.Message.getFieldWithDefault(msg, 24, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateOrderRequest}
 */
proto.cbv1.CreateOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateOrderRequest;
  return proto.cbv1.CreateOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateOrderRequest}
 */
proto.cbv1.CreateOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTruckingVendorId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfirmationRequired(value);
      break;
    case 4:
      var value = new proto.cbv1.CreateTaskRequest;
      reader.readMessage(value,proto.cbv1.CreateTaskRequest.deserializeBinaryFromReader);
      msg.addTaskRequests(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderQuantity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurchaseOrderName(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTrailerId(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTrailerSiteId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalesOrderNumber(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScheduledUnix(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStaggerMinutes(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeliveryWindowStart(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeliveryWindowDurationMinutes(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAsapOrder(value);
      break;
    case 14:
      var value = new proto_cybertron_fields_fields_pb.CustomField;
      reader.readMessage(value,proto_cybertron_fields_fields_pb.CustomField.deserializeBinaryFromReader);
      msg.addCustomFields(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSchedulePickupTime(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.addLoaderEmails(value);
      break;
    case 17:
      var value = /** @type {!proto.cbv1.OrderType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTruckId(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostCenter(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVendorContractId(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContractTypeId(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCostCenterId(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBusinessLineId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTruckingVendorId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConfirmationRequired();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getTaskRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cbv1.CreateTaskRequest.serializeBinaryToWriter
    );
  }
  f = message.getOrderQuantity();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPurchaseOrderName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTrailerId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getTrailerSiteId();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getSalesOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getScheduledUnix();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getStaggerMinutes();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getDeliveryWindowStart();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getDeliveryWindowDurationMinutes();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getIsAsapOrder();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getCustomFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto_cybertron_fields_fields_pb.CustomField.serializeBinaryToWriter
    );
  }
  f = message.getSchedulePickupTime();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getLoaderEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      16,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getTruckId();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getCostCenter();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getVendorContractId();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
  f = message.getContractTypeId();
  if (f !== 0) {
    writer.writeInt64(
      22,
      f
    );
  }
  f = message.getCostCenterId();
  if (f !== 0) {
    writer.writeInt64(
      23,
      f
    );
  }
  f = message.getBusinessLineId();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
};


/**
 * optional int64 trucking_vendor_id = 1;
 * @return {number}
 */
proto.cbv1.CreateOrderRequest.prototype.getTruckingVendorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.CreateOrderRequest.prototype.setTruckingVendorId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.cbv1.CreateOrderRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.CreateOrderRequest.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool confirmation_required = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.CreateOrderRequest.prototype.getConfirmationRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.cbv1.CreateOrderRequest.prototype.setConfirmationRequired = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated CreateTaskRequest task_requests = 4;
 * @return {!Array<!proto.cbv1.CreateTaskRequest>}
 */
proto.cbv1.CreateOrderRequest.prototype.getTaskRequestsList = function() {
  return /** @type{!Array<!proto.cbv1.CreateTaskRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.CreateTaskRequest, 4));
};


/** @param {!Array<!proto.cbv1.CreateTaskRequest>} value */
proto.cbv1.CreateOrderRequest.prototype.setTaskRequestsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cbv1.CreateTaskRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.CreateTaskRequest}
 */
proto.cbv1.CreateOrderRequest.prototype.addTaskRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cbv1.CreateTaskRequest, opt_index);
};


proto.cbv1.CreateOrderRequest.prototype.clearTaskRequestsList = function() {
  this.setTaskRequestsList([]);
};


/**
 * optional int64 order_quantity = 5;
 * @return {number}
 */
proto.cbv1.CreateOrderRequest.prototype.getOrderQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.CreateOrderRequest.prototype.setOrderQuantity = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string purchase_order_name = 6;
 * @return {string}
 */
proto.cbv1.CreateOrderRequest.prototype.getPurchaseOrderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cbv1.CreateOrderRequest.prototype.setPurchaseOrderName = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 trailer_id = 7;
 * @return {number}
 */
proto.cbv1.CreateOrderRequest.prototype.getTrailerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.CreateOrderRequest.prototype.setTrailerId = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 trailer_site_id = 19;
 * @return {number}
 */
proto.cbv1.CreateOrderRequest.prototype.getTrailerSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/** @param {number} value */
proto.cbv1.CreateOrderRequest.prototype.setTrailerSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional string sales_order_number = 8;
 * @return {string}
 */
proto.cbv1.CreateOrderRequest.prototype.getSalesOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.cbv1.CreateOrderRequest.prototype.setSalesOrderNumber = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 scheduled_unix = 9;
 * @return {number}
 */
proto.cbv1.CreateOrderRequest.prototype.getScheduledUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cbv1.CreateOrderRequest.prototype.setScheduledUnix = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 stagger_minutes = 10;
 * @return {number}
 */
proto.cbv1.CreateOrderRequest.prototype.getStaggerMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cbv1.CreateOrderRequest.prototype.setStaggerMinutes = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 delivery_window_start = 11;
 * @return {number}
 */
proto.cbv1.CreateOrderRequest.prototype.getDeliveryWindowStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.cbv1.CreateOrderRequest.prototype.setDeliveryWindowStart = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 delivery_window_duration_minutes = 12;
 * @return {number}
 */
proto.cbv1.CreateOrderRequest.prototype.getDeliveryWindowDurationMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.cbv1.CreateOrderRequest.prototype.setDeliveryWindowDurationMinutes = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool is_asap_order = 13;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.CreateOrderRequest.prototype.getIsAsapOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 13, false));
};


/** @param {boolean} value */
proto.cbv1.CreateOrderRequest.prototype.setIsAsapOrder = function(value) {
  jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * repeated CustomField custom_fields = 14;
 * @return {!Array<!proto.cbv1.CustomField>}
 */
proto.cbv1.CreateOrderRequest.prototype.getCustomFieldsList = function() {
  return /** @type{!Array<!proto.cbv1.CustomField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_fields_fields_pb.CustomField, 14));
};


/** @param {!Array<!proto.cbv1.CustomField>} value */
proto.cbv1.CreateOrderRequest.prototype.setCustomFieldsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.cbv1.CustomField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.CustomField}
 */
proto.cbv1.CreateOrderRequest.prototype.addCustomFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.cbv1.CustomField, opt_index);
};


proto.cbv1.CreateOrderRequest.prototype.clearCustomFieldsList = function() {
  this.setCustomFieldsList([]);
};


/**
 * optional bool schedule_pickup_time = 15;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.CreateOrderRequest.prototype.getSchedulePickupTime = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 15, false));
};


/** @param {boolean} value */
proto.cbv1.CreateOrderRequest.prototype.setSchedulePickupTime = function(value) {
  jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * repeated string loader_emails = 16;
 * @return {!Array<string>}
 */
proto.cbv1.CreateOrderRequest.prototype.getLoaderEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
};


/** @param {!Array<string>} value */
proto.cbv1.CreateOrderRequest.prototype.setLoaderEmailsList = function(value) {
  jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.CreateOrderRequest.prototype.addLoaderEmails = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


proto.cbv1.CreateOrderRequest.prototype.clearLoaderEmailsList = function() {
  this.setLoaderEmailsList([]);
};


/**
 * optional OrderType type = 17;
 * @return {!proto.cbv1.OrderType}
 */
proto.cbv1.CreateOrderRequest.prototype.getType = function() {
  return /** @type {!proto.cbv1.OrderType} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {!proto.cbv1.OrderType} value */
proto.cbv1.CreateOrderRequest.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional int64 truck_id = 18;
 * @return {number}
 */
proto.cbv1.CreateOrderRequest.prototype.getTruckId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/** @param {number} value */
proto.cbv1.CreateOrderRequest.prototype.setTruckId = function(value) {
  jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string cost_center = 20;
 * @return {string}
 */
proto.cbv1.CreateOrderRequest.prototype.getCostCenter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/** @param {string} value */
proto.cbv1.CreateOrderRequest.prototype.setCostCenter = function(value) {
  jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional int64 vendor_contract_id = 21;
 * @return {number}
 */
proto.cbv1.CreateOrderRequest.prototype.getVendorContractId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/** @param {number} value */
proto.cbv1.CreateOrderRequest.prototype.setVendorContractId = function(value) {
  jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int64 contract_type_id = 22;
 * @return {number}
 */
proto.cbv1.CreateOrderRequest.prototype.getContractTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/** @param {number} value */
proto.cbv1.CreateOrderRequest.prototype.setContractTypeId = function(value) {
  jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int64 cost_center_id = 23;
 * @return {number}
 */
proto.cbv1.CreateOrderRequest.prototype.getCostCenterId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/** @param {number} value */
proto.cbv1.CreateOrderRequest.prototype.setCostCenterId = function(value) {
  jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int32 business_line_id = 24;
 * @return {number}
 */
proto.cbv1.CreateOrderRequest.prototype.getBusinessLineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/** @param {number} value */
proto.cbv1.CreateOrderRequest.prototype.setBusinessLineId = function(value) {
  jspb.Message.setProto3IntField(this, 24, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateTaskRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.CreateTaskRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.CreateTaskRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateTaskRequest.displayName = 'proto.cbv1.CreateTaskRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.CreateTaskRequest.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateTaskRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateTaskRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateTaskRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateTaskRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    taskType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    payloadId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    orderedQuantity: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    scheduledUnix: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sequence: jspb.Message.getFieldWithDefault(msg, 7, 0),
    isBillable: jspb.Message.getFieldWithDefault(msg, 8, false),
    createSubTaskRequestList: jspb.Message.toObjectList(msg.getCreateSubTaskRequestList(),
    proto.cbv1.CreateSubTaskRequest.toObject, includeInstance),
    isBackHaul: jspb.Message.getFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateTaskRequest}
 */
proto.cbv1.CreateTaskRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateTaskRequest;
  return proto.cbv1.CreateTaskRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateTaskRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateTaskRequest}
 */
proto.cbv1.CreateTaskRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {!proto.cbv1.TaskType} */ (reader.readEnum());
      msg.setTaskType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPayloadId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrderedQuantity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScheduledUnix(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSequence(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBillable(value);
      break;
    case 9:
      var value = new proto.cbv1.CreateSubTaskRequest;
      reader.readMessage(value,proto.cbv1.CreateSubTaskRequest.deserializeBinaryFromReader);
      msg.addCreateSubTaskRequest(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBackHaul(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateTaskRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateTaskRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateTaskRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateTaskRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTaskType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPayloadId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOrderedQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScheduledUnix();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getSequence();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getIsBillable();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getCreateSubTaskRequestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.cbv1.CreateSubTaskRequest.serializeBinaryToWriter
    );
  }
  f = message.getIsBackHaul();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional int64 site_id = 1;
 * @return {number}
 */
proto.cbv1.CreateTaskRequest.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.CreateTaskRequest.prototype.setSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional TaskType task_type = 2;
 * @return {!proto.cbv1.TaskType}
 */
proto.cbv1.CreateTaskRequest.prototype.getTaskType = function() {
  return /** @type {!proto.cbv1.TaskType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.cbv1.TaskType} value */
proto.cbv1.CreateTaskRequest.prototype.setTaskType = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 payload_id = 3;
 * @return {number}
 */
proto.cbv1.CreateTaskRequest.prototype.getPayloadId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.CreateTaskRequest.prototype.setPayloadId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double ordered_quantity = 4;
 * @return {number}
 */
proto.cbv1.CreateTaskRequest.prototype.getOrderedQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.cbv1.CreateTaskRequest.prototype.setOrderedQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.cbv1.CreateTaskRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.CreateTaskRequest.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 scheduled_unix = 6;
 * @return {number}
 */
proto.cbv1.CreateTaskRequest.prototype.getScheduledUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.CreateTaskRequest.prototype.setScheduledUnix = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 sequence = 7;
 * @return {number}
 */
proto.cbv1.CreateTaskRequest.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.CreateTaskRequest.prototype.setSequence = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool is_billable = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.CreateTaskRequest.prototype.getIsBillable = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.cbv1.CreateTaskRequest.prototype.setIsBillable = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * repeated CreateSubTaskRequest create_sub_task_request = 9;
 * @return {!Array<!proto.cbv1.CreateSubTaskRequest>}
 */
proto.cbv1.CreateTaskRequest.prototype.getCreateSubTaskRequestList = function() {
  return /** @type{!Array<!proto.cbv1.CreateSubTaskRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.CreateSubTaskRequest, 9));
};


/** @param {!Array<!proto.cbv1.CreateSubTaskRequest>} value */
proto.cbv1.CreateTaskRequest.prototype.setCreateSubTaskRequestList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.cbv1.CreateSubTaskRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.CreateSubTaskRequest}
 */
proto.cbv1.CreateTaskRequest.prototype.addCreateSubTaskRequest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.cbv1.CreateSubTaskRequest, opt_index);
};


proto.cbv1.CreateTaskRequest.prototype.clearCreateSubTaskRequestList = function() {
  this.setCreateSubTaskRequestList([]);
};


/**
 * optional bool is_back_haul = 10;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.CreateTaskRequest.prototype.getIsBackHaul = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 10, false));
};


/** @param {boolean} value */
proto.cbv1.CreateTaskRequest.prototype.setIsBackHaul = function(value) {
  jspb.Message.setProto3BooleanField(this, 10, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateSubTaskRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateSubTaskRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateSubTaskRequest.displayName = 'proto.cbv1.CreateSubTaskRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateSubTaskRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateSubTaskRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateSubTaskRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateSubTaskRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    typeName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assetId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    payloadId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    orderedQuantity: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    isBillable: jspb.Message.getFieldWithDefault(msg, 8, false),
    purchaseOrderName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    salesOrderNumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    maintenanceOrderNumber: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateSubTaskRequest}
 */
proto.cbv1.CreateSubTaskRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateSubTaskRequest;
  return proto.cbv1.CreateSubTaskRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateSubTaskRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateSubTaskRequest}
 */
proto.cbv1.CreateSubTaskRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssetId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPayloadId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrderedQuantity(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBillable(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurchaseOrderName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalesOrderNumber(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaintenanceOrderNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateSubTaskRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateSubTaskRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateSubTaskRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateSubTaskRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypeName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPayloadId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrderedQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getIsBillable();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getPurchaseOrderName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSalesOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMaintenanceOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string type_name = 1;
 * @return {string}
 */
proto.cbv1.CreateSubTaskRequest.prototype.getTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.CreateSubTaskRequest.prototype.setTypeName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 asset_id = 2;
 * @return {number}
 */
proto.cbv1.CreateSubTaskRequest.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.CreateSubTaskRequest.prototype.setAssetId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 payload_id = 3;
 * @return {number}
 */
proto.cbv1.CreateSubTaskRequest.prototype.getPayloadId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.CreateSubTaskRequest.prototype.setPayloadId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.cbv1.CreateSubTaskRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.CreateSubTaskRequest.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double ordered_quantity = 5;
 * @return {number}
 */
proto.cbv1.CreateSubTaskRequest.prototype.getOrderedQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.cbv1.CreateSubTaskRequest.prototype.setOrderedQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool is_billable = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.CreateSubTaskRequest.prototype.getIsBillable = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.cbv1.CreateSubTaskRequest.prototype.setIsBillable = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string purchase_order_name = 9;
 * @return {string}
 */
proto.cbv1.CreateSubTaskRequest.prototype.getPurchaseOrderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.cbv1.CreateSubTaskRequest.prototype.setPurchaseOrderName = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string sales_order_number = 10;
 * @return {string}
 */
proto.cbv1.CreateSubTaskRequest.prototype.getSalesOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.cbv1.CreateSubTaskRequest.prototype.setSalesOrderNumber = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string maintenance_order_number = 11;
 * @return {string}
 */
proto.cbv1.CreateSubTaskRequest.prototype.getMaintenanceOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.cbv1.CreateSubTaskRequest.prototype.setMaintenanceOrderNumber = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateOrderResponse.displayName = 'proto.cbv1.CreateOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateOrderResponse}
 */
proto.cbv1.CreateOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateOrderResponse;
  return proto.cbv1.CreateOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateOrderResponse}
 */
proto.cbv1.CreateOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 order_id = 1;
 * @return {number}
 */
proto.cbv1.CreateOrderResponse.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.CreateOrderResponse.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateAssetRequest.displayName = 'proto.cbv1.CreateAssetRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateAssetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateAssetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateAssetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateAssetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assetTypeId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateAssetRequest}
 */
proto.cbv1.CreateAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateAssetRequest;
  return proto.cbv1.CreateAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateAssetRequest}
 */
proto.cbv1.CreateAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssetTypeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetTypeId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.cbv1.CreateAssetRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.CreateAssetRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 asset_type_id = 2;
 * @return {number}
 */
proto.cbv1.CreateAssetRequest.prototype.getAssetTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.CreateAssetRequest.prototype.setAssetTypeId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateAssetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateAssetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateAssetResponse.displayName = 'proto.cbv1.CreateAssetResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateAssetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateAssetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateAssetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateAssetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    asset: (f = msg.getAsset()) && proto_cybertron_order_order_pb.Asset.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateAssetResponse}
 */
proto.cbv1.CreateAssetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateAssetResponse;
  return proto.cbv1.CreateAssetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateAssetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateAssetResponse}
 */
proto.cbv1.CreateAssetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.Asset;
      reader.readMessage(value,proto_cybertron_order_order_pb.Asset.deserializeBinaryFromReader);
      msg.setAsset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateAssetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateAssetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateAssetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateAssetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAsset();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.Asset.serializeBinaryToWriter
    );
  }
};


/**
 * optional Asset asset = 1;
 * @return {?proto.cbv1.Asset}
 */
proto.cbv1.CreateAssetResponse.prototype.getAsset = function() {
  return /** @type{?proto.cbv1.Asset} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.Asset, 1));
};


/** @param {?proto.cbv1.Asset|undefined} value */
proto.cbv1.CreateAssetResponse.prototype.setAsset = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.CreateAssetResponse.prototype.clearAsset = function() {
  this.setAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CreateAssetResponse.prototype.hasAsset = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UpdateAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateAssetRequest.displayName = 'proto.cbv1.UpdateAssetRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateAssetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateAssetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateAssetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateAssetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assetTypeId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateAssetRequest}
 */
proto.cbv1.UpdateAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateAssetRequest;
  return proto.cbv1.UpdateAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateAssetRequest}
 */
proto.cbv1.UpdateAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssetTypeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssetTypeId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.UpdateAssetRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.UpdateAssetRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.UpdateAssetRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.UpdateAssetRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 asset_type_id = 3;
 * @return {number}
 */
proto.cbv1.UpdateAssetRequest.prototype.getAssetTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.UpdateAssetRequest.prototype.setAssetTypeId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateAssetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UpdateAssetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateAssetResponse.displayName = 'proto.cbv1.UpdateAssetResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateAssetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateAssetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateAssetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateAssetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    asset: (f = msg.getAsset()) && proto_cybertron_order_order_pb.Asset.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateAssetResponse}
 */
proto.cbv1.UpdateAssetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateAssetResponse;
  return proto.cbv1.UpdateAssetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateAssetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateAssetResponse}
 */
proto.cbv1.UpdateAssetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.Asset;
      reader.readMessage(value,proto_cybertron_order_order_pb.Asset.deserializeBinaryFromReader);
      msg.setAsset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateAssetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateAssetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateAssetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateAssetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAsset();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.Asset.serializeBinaryToWriter
    );
  }
};


/**
 * optional Asset asset = 1;
 * @return {?proto.cbv1.Asset}
 */
proto.cbv1.UpdateAssetResponse.prototype.getAsset = function() {
  return /** @type{?proto.cbv1.Asset} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.Asset, 1));
};


/** @param {?proto.cbv1.Asset|undefined} value */
proto.cbv1.UpdateAssetResponse.prototype.setAsset = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.UpdateAssetResponse.prototype.clearAsset = function() {
  this.setAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.UpdateAssetResponse.prototype.hasAsset = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetAssetsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetAssetsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetAssetsRequest.displayName = 'proto.cbv1.GetAssetsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetAssetsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetAssetsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetAssetsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetAssetsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetAssetsRequest}
 */
proto.cbv1.GetAssetsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetAssetsRequest;
  return proto.cbv1.GetAssetsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetAssetsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetAssetsRequest}
 */
proto.cbv1.GetAssetsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetAssetsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetAssetsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetAssetsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetAssetsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetAssetsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.GetAssetsResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.GetAssetsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetAssetsResponse.displayName = 'proto.cbv1.GetAssetsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.GetAssetsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetAssetsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetAssetsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetAssetsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetAssetsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    proto_cybertron_order_order_pb.Asset.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetAssetsResponse}
 */
proto.cbv1.GetAssetsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetAssetsResponse;
  return proto.cbv1.GetAssetsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetAssetsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetAssetsResponse}
 */
proto.cbv1.GetAssetsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.Asset;
      reader.readMessage(value,proto_cybertron_order_order_pb.Asset.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetAssetsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetAssetsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetAssetsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetAssetsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_order_order_pb.Asset.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Asset assets = 1;
 * @return {!Array<!proto.cbv1.Asset>}
 */
proto.cbv1.GetAssetsResponse.prototype.getAssetsList = function() {
  return /** @type{!Array<!proto.cbv1.Asset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_order_order_pb.Asset, 1));
};


/** @param {!Array<!proto.cbv1.Asset>} value */
proto.cbv1.GetAssetsResponse.prototype.setAssetsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.Asset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.Asset}
 */
proto.cbv1.GetAssetsResponse.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.Asset, opt_index);
};


proto.cbv1.GetAssetsResponse.prototype.clearAssetsList = function() {
  this.setAssetsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetAssetTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetAssetTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetAssetTypesRequest.displayName = 'proto.cbv1.GetAssetTypesRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetAssetTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetAssetTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetAssetTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetAssetTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetAssetTypesRequest}
 */
proto.cbv1.GetAssetTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetAssetTypesRequest;
  return proto.cbv1.GetAssetTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetAssetTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetAssetTypesRequest}
 */
proto.cbv1.GetAssetTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetAssetTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetAssetTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetAssetTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetAssetTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetAssetTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.GetAssetTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.GetAssetTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetAssetTypesResponse.displayName = 'proto.cbv1.GetAssetTypesResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.GetAssetTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetAssetTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetAssetTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetAssetTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetAssetTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    proto_cybertron_order_order_pb.AssetType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetAssetTypesResponse}
 */
proto.cbv1.GetAssetTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetAssetTypesResponse;
  return proto.cbv1.GetAssetTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetAssetTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetAssetTypesResponse}
 */
proto.cbv1.GetAssetTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.AssetType;
      reader.readMessage(value,proto_cybertron_order_order_pb.AssetType.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetAssetTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetAssetTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetAssetTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetAssetTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_order_order_pb.AssetType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AssetType assets = 1;
 * @return {!Array<!proto.cbv1.AssetType>}
 */
proto.cbv1.GetAssetTypesResponse.prototype.getAssetsList = function() {
  return /** @type{!Array<!proto.cbv1.AssetType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_order_order_pb.AssetType, 1));
};


/** @param {!Array<!proto.cbv1.AssetType>} value */
proto.cbv1.GetAssetTypesResponse.prototype.setAssetsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.AssetType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.AssetType}
 */
proto.cbv1.GetAssetTypesResponse.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.AssetType, opt_index);
};


proto.cbv1.GetAssetTypesResponse.prototype.clearAssetsList = function() {
  this.setAssetsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetAssetGroupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetAssetGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetAssetGroupsRequest.displayName = 'proto.cbv1.GetAssetGroupsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetAssetGroupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetAssetGroupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetAssetGroupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetAssetGroupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetAssetGroupsRequest}
 */
proto.cbv1.GetAssetGroupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetAssetGroupsRequest;
  return proto.cbv1.GetAssetGroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetAssetGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetAssetGroupsRequest}
 */
proto.cbv1.GetAssetGroupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetAssetGroupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetAssetGroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetAssetGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetAssetGroupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetAssetGroupsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.GetAssetGroupsResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.GetAssetGroupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetAssetGroupsResponse.displayName = 'proto.cbv1.GetAssetGroupsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.GetAssetGroupsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetAssetGroupsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetAssetGroupsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetAssetGroupsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetAssetGroupsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto_cybertron_order_order_pb.AssetGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetAssetGroupsResponse}
 */
proto.cbv1.GetAssetGroupsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetAssetGroupsResponse;
  return proto.cbv1.GetAssetGroupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetAssetGroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetAssetGroupsResponse}
 */
proto.cbv1.GetAssetGroupsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.AssetGroup;
      reader.readMessage(value,proto_cybertron_order_order_pb.AssetGroup.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetAssetGroupsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetAssetGroupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetAssetGroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetAssetGroupsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_order_order_pb.AssetGroup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AssetGroup groups = 1;
 * @return {!Array<!proto.cbv1.AssetGroup>}
 */
proto.cbv1.GetAssetGroupsResponse.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.cbv1.AssetGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_order_order_pb.AssetGroup, 1));
};


/** @param {!Array<!proto.cbv1.AssetGroup>} value */
proto.cbv1.GetAssetGroupsResponse.prototype.setGroupsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.AssetGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.AssetGroup}
 */
proto.cbv1.GetAssetGroupsResponse.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.AssetGroup, opt_index);
};


proto.cbv1.GetAssetGroupsResponse.prototype.clearGroupsList = function() {
  this.setGroupsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SubTaskActualQuantityUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.SubTaskActualQuantityUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SubTaskActualQuantityUpdate.displayName = 'proto.cbv1.SubTaskActualQuantityUpdate';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SubTaskActualQuantityUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SubTaskActualQuantityUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SubTaskActualQuantityUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SubTaskActualQuantityUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    subTaskId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    actualQuantity: +jspb.Message.getFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SubTaskActualQuantityUpdate}
 */
proto.cbv1.SubTaskActualQuantityUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SubTaskActualQuantityUpdate;
  return proto.cbv1.SubTaskActualQuantityUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SubTaskActualQuantityUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SubTaskActualQuantityUpdate}
 */
proto.cbv1.SubTaskActualQuantityUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubTaskId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setActualQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SubTaskActualQuantityUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SubTaskActualQuantityUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SubTaskActualQuantityUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SubTaskActualQuantityUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubTaskId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getActualQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int64 sub_task_id = 1;
 * @return {number}
 */
proto.cbv1.SubTaskActualQuantityUpdate.prototype.getSubTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.SubTaskActualQuantityUpdate.prototype.setSubTaskId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double actual_quantity = 2;
 * @return {number}
 */
proto.cbv1.SubTaskActualQuantityUpdate.prototype.getActualQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.cbv1.SubTaskActualQuantityUpdate.prototype.setActualQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SubTaskBillingUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.SubTaskBillingUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SubTaskBillingUpdate.displayName = 'proto.cbv1.SubTaskBillingUpdate';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SubTaskBillingUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SubTaskBillingUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SubTaskBillingUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SubTaskBillingUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    subTaskId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bolNumber: (f = msg.getBolNumber()) && proto_cybertron_types_types_pb.NullableString.toObject(includeInstance, f),
    actualQuantity: (f = msg.getActualQuantity()) && proto_cybertron_types_types_pb.NullableDouble.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SubTaskBillingUpdate}
 */
proto.cbv1.SubTaskBillingUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SubTaskBillingUpdate;
  return proto.cbv1.SubTaskBillingUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SubTaskBillingUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SubTaskBillingUpdate}
 */
proto.cbv1.SubTaskBillingUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubTaskId(value);
      break;
    case 2:
      var value = new proto_cybertron_types_types_pb.NullableString;
      reader.readMessage(value,proto_cybertron_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setBolNumber(value);
      break;
    case 3:
      var value = new proto_cybertron_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_cybertron_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setActualQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SubTaskBillingUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SubTaskBillingUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SubTaskBillingUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SubTaskBillingUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubTaskId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBolNumber();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_cybertron_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getActualQuantity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_cybertron_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 sub_task_id = 1;
 * @return {number}
 */
proto.cbv1.SubTaskBillingUpdate.prototype.getSubTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.SubTaskBillingUpdate.prototype.setSubTaskId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional NullableString bol_number = 2;
 * @return {?proto.cbv1.NullableString}
 */
proto.cbv1.SubTaskBillingUpdate.prototype.getBolNumber = function() {
  return /** @type{?proto.cbv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_types_types_pb.NullableString, 2));
};


/** @param {?proto.cbv1.NullableString|undefined} value */
proto.cbv1.SubTaskBillingUpdate.prototype.setBolNumber = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cbv1.SubTaskBillingUpdate.prototype.clearBolNumber = function() {
  this.setBolNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.SubTaskBillingUpdate.prototype.hasBolNumber = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableDouble actual_quantity = 3;
 * @return {?proto.cbv1.NullableDouble}
 */
proto.cbv1.SubTaskBillingUpdate.prototype.getActualQuantity = function() {
  return /** @type{?proto.cbv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_types_types_pb.NullableDouble, 3));
};


/** @param {?proto.cbv1.NullableDouble|undefined} value */
proto.cbv1.SubTaskBillingUpdate.prototype.setActualQuantity = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv1.SubTaskBillingUpdate.prototype.clearActualQuantity = function() {
  this.setActualQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.SubTaskBillingUpdate.prototype.hasActualQuantity = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOUpdateOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.LMOUpdateOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.LMOUpdateOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOUpdateOrderRequest.displayName = 'proto.cbv1.LMOUpdateOrderRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.LMOUpdateOrderRequest.repeatedFields_ = [13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOUpdateOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOUpdateOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOUpdateOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    truckingVendorId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    trailerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    ticketNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    purchaseOrderName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    orderStatus: jspb.Message.getFieldWithDefault(msg, 7, 0),
    mask: (f = msg.getMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f),
    actualQuantity: +jspb.Message.getFieldWithDefault(msg, 9, 0.0),
    salesOrderNumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    bolNumber: jspb.Message.getFieldWithDefault(msg, 11, ""),
    truckId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    subtaskActualQuantitiesList: jspb.Message.toObjectList(msg.getSubtaskActualQuantitiesList(),
    proto.cbv1.SubTaskActualQuantityUpdate.toObject, includeInstance),
    subtaskBillingUpdateList: jspb.Message.toObjectList(msg.getSubtaskBillingUpdateList(),
    proto.cbv1.SubTaskBillingUpdate.toObject, includeInstance),
    costCenter: jspb.Message.getFieldWithDefault(msg, 15, ""),
    vendorContractId: jspb.Message.getFieldWithDefault(msg, 16, 0),
    contractTypeId: jspb.Message.getFieldWithDefault(msg, 17, 0),
    costCenterId: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOUpdateOrderRequest}
 */
proto.cbv1.LMOUpdateOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOUpdateOrderRequest;
  return proto.cbv1.LMOUpdateOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOUpdateOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOUpdateOrderRequest}
 */
proto.cbv1.LMOUpdateOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTruckingVendorId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrailerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurchaseOrderName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {!proto.cbv1.OrderStatus} */ (reader.readEnum());
      msg.setOrderStatus(value);
      break;
    case 8:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setMask(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setActualQuantity(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalesOrderNumber(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBolNumber(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTruckId(value);
      break;
    case 13:
      var value = new proto.cbv1.SubTaskActualQuantityUpdate;
      reader.readMessage(value,proto.cbv1.SubTaskActualQuantityUpdate.deserializeBinaryFromReader);
      msg.addSubtaskActualQuantities(value);
      break;
    case 14:
      var value = new proto.cbv1.SubTaskBillingUpdate;
      reader.readMessage(value,proto.cbv1.SubTaskBillingUpdate.deserializeBinaryFromReader);
      msg.addSubtaskBillingUpdate(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostCenter(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVendorContractId(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContractTypeId(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCostCenterId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOUpdateOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOUpdateOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOUpdateOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTruckingVendorId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTrailerId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTicketNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPurchaseOrderName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOrderStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getMask();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
  f = message.getActualQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getSalesOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBolNumber();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTruckId();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getSubtaskActualQuantitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.cbv1.SubTaskActualQuantityUpdate.serializeBinaryToWriter
    );
  }
  f = message.getSubtaskBillingUpdateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.cbv1.SubTaskBillingUpdate.serializeBinaryToWriter
    );
  }
  f = message.getCostCenter();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getVendorContractId();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getContractTypeId();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getCostCenterId();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.LMOUpdateOrderRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 trucking_vendor_id = 2;
 * @return {number}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.getTruckingVendorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.LMOUpdateOrderRequest.prototype.setTruckingVendorId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 trailer_id = 3;
 * @return {number}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.getTrailerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.LMOUpdateOrderRequest.prototype.setTrailerId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string ticket_number = 4;
 * @return {string}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.getTicketNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.LMOUpdateOrderRequest.prototype.setTicketNumber = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string purchase_order_name = 5;
 * @return {string}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.getPurchaseOrderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.LMOUpdateOrderRequest.prototype.setPurchaseOrderName = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cbv1.LMOUpdateOrderRequest.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional OrderStatus order_status = 7;
 * @return {!proto.cbv1.OrderStatus}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.getOrderStatus = function() {
  return /** @type {!proto.cbv1.OrderStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {!proto.cbv1.OrderStatus} value */
proto.cbv1.LMOUpdateOrderRequest.prototype.setOrderStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional google.protobuf.FieldMask mask = 8;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.getMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 8));
};


/** @param {?proto.google.protobuf.FieldMask|undefined} value */
proto.cbv1.LMOUpdateOrderRequest.prototype.setMask = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.cbv1.LMOUpdateOrderRequest.prototype.clearMask = function() {
  this.setMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.hasMask = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional double actual_quantity = 9;
 * @return {number}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.getActualQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 9, 0.0));
};


/** @param {number} value */
proto.cbv1.LMOUpdateOrderRequest.prototype.setActualQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string sales_order_number = 10;
 * @return {string}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.getSalesOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.cbv1.LMOUpdateOrderRequest.prototype.setSalesOrderNumber = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string bol_number = 11;
 * @return {string}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.getBolNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.cbv1.LMOUpdateOrderRequest.prototype.setBolNumber = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 truck_id = 12;
 * @return {number}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.getTruckId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.cbv1.LMOUpdateOrderRequest.prototype.setTruckId = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * repeated SubTaskActualQuantityUpdate subtask_actual_quantities = 13;
 * @return {!Array<!proto.cbv1.SubTaskActualQuantityUpdate>}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.getSubtaskActualQuantitiesList = function() {
  return /** @type{!Array<!proto.cbv1.SubTaskActualQuantityUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.SubTaskActualQuantityUpdate, 13));
};


/** @param {!Array<!proto.cbv1.SubTaskActualQuantityUpdate>} value */
proto.cbv1.LMOUpdateOrderRequest.prototype.setSubtaskActualQuantitiesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.cbv1.SubTaskActualQuantityUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.SubTaskActualQuantityUpdate}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.addSubtaskActualQuantities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.cbv1.SubTaskActualQuantityUpdate, opt_index);
};


proto.cbv1.LMOUpdateOrderRequest.prototype.clearSubtaskActualQuantitiesList = function() {
  this.setSubtaskActualQuantitiesList([]);
};


/**
 * repeated SubTaskBillingUpdate subtask_billing_update = 14;
 * @return {!Array<!proto.cbv1.SubTaskBillingUpdate>}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.getSubtaskBillingUpdateList = function() {
  return /** @type{!Array<!proto.cbv1.SubTaskBillingUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.SubTaskBillingUpdate, 14));
};


/** @param {!Array<!proto.cbv1.SubTaskBillingUpdate>} value */
proto.cbv1.LMOUpdateOrderRequest.prototype.setSubtaskBillingUpdateList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.cbv1.SubTaskBillingUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.SubTaskBillingUpdate}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.addSubtaskBillingUpdate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.cbv1.SubTaskBillingUpdate, opt_index);
};


proto.cbv1.LMOUpdateOrderRequest.prototype.clearSubtaskBillingUpdateList = function() {
  this.setSubtaskBillingUpdateList([]);
};


/**
 * optional string cost_center = 15;
 * @return {string}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.getCostCenter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.cbv1.LMOUpdateOrderRequest.prototype.setCostCenter = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int64 vendor_contract_id = 16;
 * @return {number}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.getVendorContractId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.cbv1.LMOUpdateOrderRequest.prototype.setVendorContractId = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 contract_type_id = 17;
 * @return {number}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.getContractTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {number} value */
proto.cbv1.LMOUpdateOrderRequest.prototype.setContractTypeId = function(value) {
  jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int64 cost_center_id = 18;
 * @return {number}
 */
proto.cbv1.LMOUpdateOrderRequest.prototype.getCostCenterId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/** @param {number} value */
proto.cbv1.LMOUpdateOrderRequest.prototype.setCostCenterId = function(value) {
  jspb.Message.setProto3IntField(this, 18, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOUpdateOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOUpdateOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOUpdateOrderResponse.displayName = 'proto.cbv1.LMOUpdateOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOUpdateOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOUpdateOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOUpdateOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOUpdateOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto_cybertron_order_order_pb.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOUpdateOrderResponse}
 */
proto.cbv1.LMOUpdateOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOUpdateOrderResponse;
  return proto.cbv1.LMOUpdateOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOUpdateOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOUpdateOrderResponse}
 */
proto.cbv1.LMOUpdateOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.Order;
      reader.readMessage(value,proto_cybertron_order_order_pb.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOUpdateOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOUpdateOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOUpdateOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOUpdateOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.cbv1.Order}
 */
proto.cbv1.LMOUpdateOrderResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.Order} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.Order, 1));
};


/** @param {?proto.cbv1.Order|undefined} value */
proto.cbv1.LMOUpdateOrderResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.LMOUpdateOrderResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.LMOUpdateOrderResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOUpdatePendingOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOUpdatePendingOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOUpdatePendingOrderRequest.displayName = 'proto.cbv1.LMOUpdatePendingOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOUpdatePendingOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOUpdatePendingOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOUpdatePendingOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isAsapOrder: jspb.Message.getFieldWithDefault(msg, 2, false),
    deliveryWindowStart: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deliveryWindowDurationMinutes: jspb.Message.getFieldWithDefault(msg, 4, 0),
    payloadId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    orderedQuantity: +jspb.Message.getFieldWithDefault(msg, 6, 0.0),
    siteId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    truckingVendorId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    trailerId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    purchaseOrderName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    salesOrderNumber: jspb.Message.getFieldWithDefault(msg, 11, ""),
    mask: (f = msg.getMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f),
    bolNumber: jspb.Message.getFieldWithDefault(msg, 13, ""),
    description: jspb.Message.getFieldWithDefault(msg, 14, ""),
    truckId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    costCenter: jspb.Message.getFieldWithDefault(msg, 16, ""),
    vendorContractId: jspb.Message.getFieldWithDefault(msg, 17, 0),
    contractTypeId: jspb.Message.getFieldWithDefault(msg, 18, 0),
    costCenterId: jspb.Message.getFieldWithDefault(msg, 19, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOUpdatePendingOrderRequest}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOUpdatePendingOrderRequest;
  return proto.cbv1.LMOUpdatePendingOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOUpdatePendingOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOUpdatePendingOrderRequest}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAsapOrder(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeliveryWindowStart(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeliveryWindowDurationMinutes(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPayloadId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrderedQuantity(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSiteId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTruckingVendorId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTrailerId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurchaseOrderName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalesOrderNumber(value);
      break;
    case 12:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setMask(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBolNumber(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTruckId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostCenter(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVendorContractId(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContractTypeId(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCostCenterId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOUpdatePendingOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOUpdatePendingOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOUpdatePendingOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIsAsapOrder();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDeliveryWindowStart();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDeliveryWindowDurationMinutes();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPayloadId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getOrderedQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getTruckingVendorId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getTrailerId();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getPurchaseOrderName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSalesOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getMask();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
  f = message.getBolNumber();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTruckId();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getCostCenter();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getVendorContractId();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getContractTypeId();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getCostCenterId();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool is_asap_order = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.getIsAsapOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.setIsAsapOrder = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int64 delivery_window_start = 3;
 * @return {number}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.getDeliveryWindowStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.setDeliveryWindowStart = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 delivery_window_duration_minutes = 4;
 * @return {number}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.getDeliveryWindowDurationMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.setDeliveryWindowDurationMinutes = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 payload_id = 5;
 * @return {number}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.getPayloadId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.setPayloadId = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double ordered_quantity = 6;
 * @return {number}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.getOrderedQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 6, 0.0));
};


/** @param {number} value */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.setOrderedQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional int64 site_id = 7;
 * @return {number}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.setSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 trucking_vendor_id = 8;
 * @return {number}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.getTruckingVendorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.setTruckingVendorId = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 trailer_id = 9;
 * @return {number}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.getTrailerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.setTrailerId = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string purchase_order_name = 10;
 * @return {string}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.getPurchaseOrderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.setPurchaseOrderName = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string sales_order_number = 11;
 * @return {string}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.getSalesOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.setSalesOrderNumber = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional google.protobuf.FieldMask mask = 12;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.getMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 12));
};


/** @param {?proto.google.protobuf.FieldMask|undefined} value */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.setMask = function(value) {
  jspb.Message.setWrapperField(this, 12, value);
};


proto.cbv1.LMOUpdatePendingOrderRequest.prototype.clearMask = function() {
  this.setMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.hasMask = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string bol_number = 13;
 * @return {string}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.getBolNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.setBolNumber = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string description = 14;
 * @return {string}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int64 truck_id = 15;
 * @return {number}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.getTruckId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.setTruckId = function(value) {
  jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string cost_center = 16;
 * @return {string}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.getCostCenter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/** @param {string} value */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.setCostCenter = function(value) {
  jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional int64 vendor_contract_id = 17;
 * @return {number}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.getVendorContractId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {number} value */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.setVendorContractId = function(value) {
  jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int64 contract_type_id = 18;
 * @return {number}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.getContractTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/** @param {number} value */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.setContractTypeId = function(value) {
  jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 cost_center_id = 19;
 * @return {number}
 */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.getCostCenterId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/** @param {number} value */
proto.cbv1.LMOUpdatePendingOrderRequest.prototype.setCostCenterId = function(value) {
  jspb.Message.setProto3IntField(this, 19, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOUpdatePendingOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOUpdatePendingOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOUpdatePendingOrderResponse.displayName = 'proto.cbv1.LMOUpdatePendingOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOUpdatePendingOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOUpdatePendingOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOUpdatePendingOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOUpdatePendingOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto_cybertron_order_order_pb.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOUpdatePendingOrderResponse}
 */
proto.cbv1.LMOUpdatePendingOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOUpdatePendingOrderResponse;
  return proto.cbv1.LMOUpdatePendingOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOUpdatePendingOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOUpdatePendingOrderResponse}
 */
proto.cbv1.LMOUpdatePendingOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.Order;
      reader.readMessage(value,proto_cybertron_order_order_pb.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOUpdatePendingOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOUpdatePendingOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOUpdatePendingOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOUpdatePendingOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.cbv1.Order}
 */
proto.cbv1.LMOUpdatePendingOrderResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.Order} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.Order, 1));
};


/** @param {?proto.cbv1.Order|undefined} value */
proto.cbv1.LMOUpdatePendingOrderResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.LMOUpdatePendingOrderResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.LMOUpdatePendingOrderResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorUpdateOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.VendorUpdateOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.VendorUpdateOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorUpdateOrderRequest.displayName = 'proto.cbv1.VendorUpdateOrderRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.VendorUpdateOrderRequest.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorUpdateOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorUpdateOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorUpdateOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorUpdateOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    truckId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    trailerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    ticketNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    orderStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    mask: (f = msg.getMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f),
    actualQuantity: +jspb.Message.getFieldWithDefault(msg, 9, 0.0),
    bolNumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    subtaskActualQuantitiesList: jspb.Message.toObjectList(msg.getSubtaskActualQuantitiesList(),
    proto.cbv1.SubTaskActualQuantityUpdate.toObject, includeInstance),
    subtaskBillingUpdateList: jspb.Message.toObjectList(msg.getSubtaskBillingUpdateList(),
    proto.cbv1.SubTaskBillingUpdate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorUpdateOrderRequest}
 */
proto.cbv1.VendorUpdateOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorUpdateOrderRequest;
  return proto.cbv1.VendorUpdateOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorUpdateOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorUpdateOrderRequest}
 */
proto.cbv1.VendorUpdateOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTruckId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrailerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {!proto.cbv1.OrderStatus} */ (reader.readEnum());
      msg.setOrderStatus(value);
      break;
    case 7:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setMask(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setActualQuantity(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBolNumber(value);
      break;
    case 11:
      var value = new proto.cbv1.SubTaskActualQuantityUpdate;
      reader.readMessage(value,proto.cbv1.SubTaskActualQuantityUpdate.deserializeBinaryFromReader);
      msg.addSubtaskActualQuantities(value);
      break;
    case 12:
      var value = new proto.cbv1.SubTaskBillingUpdate;
      reader.readMessage(value,proto.cbv1.SubTaskBillingUpdate.deserializeBinaryFromReader);
      msg.addSubtaskBillingUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorUpdateOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorUpdateOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorUpdateOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorUpdateOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTruckId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTrailerId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTicketNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrderStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getMask();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
  f = message.getActualQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getBolNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSubtaskActualQuantitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.cbv1.SubTaskActualQuantityUpdate.serializeBinaryToWriter
    );
  }
  f = message.getSubtaskBillingUpdateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.cbv1.SubTaskBillingUpdate.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.VendorUpdateOrderRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.VendorUpdateOrderRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 truck_id = 2;
 * @return {number}
 */
proto.cbv1.VendorUpdateOrderRequest.prototype.getTruckId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.VendorUpdateOrderRequest.prototype.setTruckId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 trailer_id = 3;
 * @return {number}
 */
proto.cbv1.VendorUpdateOrderRequest.prototype.getTrailerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.VendorUpdateOrderRequest.prototype.setTrailerId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string ticket_number = 4;
 * @return {string}
 */
proto.cbv1.VendorUpdateOrderRequest.prototype.getTicketNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.VendorUpdateOrderRequest.prototype.setTicketNumber = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.cbv1.VendorUpdateOrderRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.VendorUpdateOrderRequest.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional OrderStatus order_status = 6;
 * @return {!proto.cbv1.OrderStatus}
 */
proto.cbv1.VendorUpdateOrderRequest.prototype.getOrderStatus = function() {
  return /** @type {!proto.cbv1.OrderStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.cbv1.OrderStatus} value */
proto.cbv1.VendorUpdateOrderRequest.prototype.setOrderStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional google.protobuf.FieldMask mask = 7;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.cbv1.VendorUpdateOrderRequest.prototype.getMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 7));
};


/** @param {?proto.google.protobuf.FieldMask|undefined} value */
proto.cbv1.VendorUpdateOrderRequest.prototype.setMask = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


proto.cbv1.VendorUpdateOrderRequest.prototype.clearMask = function() {
  this.setMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.VendorUpdateOrderRequest.prototype.hasMask = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional double actual_quantity = 9;
 * @return {number}
 */
proto.cbv1.VendorUpdateOrderRequest.prototype.getActualQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 9, 0.0));
};


/** @param {number} value */
proto.cbv1.VendorUpdateOrderRequest.prototype.setActualQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string bol_number = 10;
 * @return {string}
 */
proto.cbv1.VendorUpdateOrderRequest.prototype.getBolNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.cbv1.VendorUpdateOrderRequest.prototype.setBolNumber = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated SubTaskActualQuantityUpdate subtask_actual_quantities = 11;
 * @return {!Array<!proto.cbv1.SubTaskActualQuantityUpdate>}
 */
proto.cbv1.VendorUpdateOrderRequest.prototype.getSubtaskActualQuantitiesList = function() {
  return /** @type{!Array<!proto.cbv1.SubTaskActualQuantityUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.SubTaskActualQuantityUpdate, 11));
};


/** @param {!Array<!proto.cbv1.SubTaskActualQuantityUpdate>} value */
proto.cbv1.VendorUpdateOrderRequest.prototype.setSubtaskActualQuantitiesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.cbv1.SubTaskActualQuantityUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.SubTaskActualQuantityUpdate}
 */
proto.cbv1.VendorUpdateOrderRequest.prototype.addSubtaskActualQuantities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.cbv1.SubTaskActualQuantityUpdate, opt_index);
};


proto.cbv1.VendorUpdateOrderRequest.prototype.clearSubtaskActualQuantitiesList = function() {
  this.setSubtaskActualQuantitiesList([]);
};


/**
 * repeated SubTaskBillingUpdate subtask_billing_update = 12;
 * @return {!Array<!proto.cbv1.SubTaskBillingUpdate>}
 */
proto.cbv1.VendorUpdateOrderRequest.prototype.getSubtaskBillingUpdateList = function() {
  return /** @type{!Array<!proto.cbv1.SubTaskBillingUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.SubTaskBillingUpdate, 12));
};


/** @param {!Array<!proto.cbv1.SubTaskBillingUpdate>} value */
proto.cbv1.VendorUpdateOrderRequest.prototype.setSubtaskBillingUpdateList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.cbv1.SubTaskBillingUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.SubTaskBillingUpdate}
 */
proto.cbv1.VendorUpdateOrderRequest.prototype.addSubtaskBillingUpdate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.cbv1.SubTaskBillingUpdate, opt_index);
};


proto.cbv1.VendorUpdateOrderRequest.prototype.clearSubtaskBillingUpdateList = function() {
  this.setSubtaskBillingUpdateList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorUpdateOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.VendorUpdateOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorUpdateOrderResponse.displayName = 'proto.cbv1.VendorUpdateOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorUpdateOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorUpdateOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorUpdateOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorUpdateOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto_cybertron_order_order_pb.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorUpdateOrderResponse}
 */
proto.cbv1.VendorUpdateOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorUpdateOrderResponse;
  return proto.cbv1.VendorUpdateOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorUpdateOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorUpdateOrderResponse}
 */
proto.cbv1.VendorUpdateOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.Order;
      reader.readMessage(value,proto_cybertron_order_order_pb.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorUpdateOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorUpdateOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorUpdateOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorUpdateOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.cbv1.Order}
 */
proto.cbv1.VendorUpdateOrderResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.Order} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.Order, 1));
};


/** @param {?proto.cbv1.Order|undefined} value */
proto.cbv1.VendorUpdateOrderResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.VendorUpdateOrderResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.VendorUpdateOrderResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetOrderRequest.displayName = 'proto.cbv1.GetOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetOrderRequest}
 */
proto.cbv1.GetOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetOrderRequest;
  return proto.cbv1.GetOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetOrderRequest}
 */
proto.cbv1.GetOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.GetOrderRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.GetOrderRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.OrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.OrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.OrderResponse.displayName = 'proto.cbv1.OrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.OrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.OrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.OrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto_cybertron_order_order_pb.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.OrderResponse}
 */
proto.cbv1.OrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.OrderResponse;
  return proto.cbv1.OrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.OrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.OrderResponse}
 */
proto.cbv1.OrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.Order;
      reader.readMessage(value,proto_cybertron_order_order_pb.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.OrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.OrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.OrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.cbv1.Order}
 */
proto.cbv1.OrderResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.Order} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.Order, 1));
};


/** @param {?proto.cbv1.Order|undefined} value */
proto.cbv1.OrderResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.OrderResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.OrderResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOCancelOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOCancelOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOCancelOrderRequest.displayName = 'proto.cbv1.LMOCancelOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOCancelOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOCancelOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOCancelOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOCancelOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOCancelOrderRequest}
 */
proto.cbv1.LMOCancelOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOCancelOrderRequest;
  return proto.cbv1.LMOCancelOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOCancelOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOCancelOrderRequest}
 */
proto.cbv1.LMOCancelOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOCancelOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOCancelOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOCancelOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOCancelOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 order_id = 1;
 * @return {number}
 */
proto.cbv1.LMOCancelOrderRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.LMOCancelOrderRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOCancelOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOCancelOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOCancelOrderResponse.displayName = 'proto.cbv1.LMOCancelOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOCancelOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOCancelOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOCancelOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOCancelOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOCancelOrderResponse}
 */
proto.cbv1.LMOCancelOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOCancelOrderResponse;
  return proto.cbv1.LMOCancelOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOCancelOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOCancelOrderResponse}
 */
proto.cbv1.LMOCancelOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOCancelOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOCancelOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOCancelOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOCancelOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DriverAcceptOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DriverAcceptOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DriverAcceptOrderResponse.displayName = 'proto.cbv1.DriverAcceptOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DriverAcceptOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DriverAcceptOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DriverAcceptOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverAcceptOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto_cybertron_order_order_pb.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DriverAcceptOrderResponse}
 */
proto.cbv1.DriverAcceptOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DriverAcceptOrderResponse;
  return proto.cbv1.DriverAcceptOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DriverAcceptOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DriverAcceptOrderResponse}
 */
proto.cbv1.DriverAcceptOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.Order;
      reader.readMessage(value,proto_cybertron_order_order_pb.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DriverAcceptOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DriverAcceptOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DriverAcceptOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverAcceptOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.cbv1.Order}
 */
proto.cbv1.DriverAcceptOrderResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.Order} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.Order, 1));
};


/** @param {?proto.cbv1.Order|undefined} value */
proto.cbv1.DriverAcceptOrderResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.DriverAcceptOrderResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.DriverAcceptOrderResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DriverAcceptOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DriverAcceptOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DriverAcceptOrderRequest.displayName = 'proto.cbv1.DriverAcceptOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DriverAcceptOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DriverAcceptOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DriverAcceptOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverAcceptOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DriverAcceptOrderRequest}
 */
proto.cbv1.DriverAcceptOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DriverAcceptOrderRequest;
  return proto.cbv1.DriverAcceptOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DriverAcceptOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DriverAcceptOrderRequest}
 */
proto.cbv1.DriverAcceptOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DriverAcceptOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DriverAcceptOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DriverAcceptOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverAcceptOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 order_id = 1;
 * @return {number}
 */
proto.cbv1.DriverAcceptOrderRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.DriverAcceptOrderRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DriverRejectOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DriverRejectOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DriverRejectOrderRequest.displayName = 'proto.cbv1.DriverRejectOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DriverRejectOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DriverRejectOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DriverRejectOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverRejectOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DriverRejectOrderRequest}
 */
proto.cbv1.DriverRejectOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DriverRejectOrderRequest;
  return proto.cbv1.DriverRejectOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DriverRejectOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DriverRejectOrderRequest}
 */
proto.cbv1.DriverRejectOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DriverRejectOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DriverRejectOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DriverRejectOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverRejectOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 order_id = 1;
 * @return {number}
 */
proto.cbv1.DriverRejectOrderRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.DriverRejectOrderRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DriverRejectOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DriverRejectOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DriverRejectOrderResponse.displayName = 'proto.cbv1.DriverRejectOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DriverRejectOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DriverRejectOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DriverRejectOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverRejectOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DriverRejectOrderResponse}
 */
proto.cbv1.DriverRejectOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DriverRejectOrderResponse;
  return proto.cbv1.DriverRejectOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DriverRejectOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DriverRejectOrderResponse}
 */
proto.cbv1.DriverRejectOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DriverRejectOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DriverRejectOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DriverRejectOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverRejectOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DriverCompleteOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DriverCompleteOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DriverCompleteOrderRequest.displayName = 'proto.cbv1.DriverCompleteOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DriverCompleteOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DriverCompleteOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DriverCompleteOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverCompleteOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DriverCompleteOrderRequest}
 */
proto.cbv1.DriverCompleteOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DriverCompleteOrderRequest;
  return proto.cbv1.DriverCompleteOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DriverCompleteOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DriverCompleteOrderRequest}
 */
proto.cbv1.DriverCompleteOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DriverCompleteOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DriverCompleteOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DriverCompleteOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverCompleteOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 order_id = 1;
 * @return {number}
 */
proto.cbv1.DriverCompleteOrderRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.DriverCompleteOrderRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DriverCompleteOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DriverCompleteOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DriverCompleteOrderResponse.displayName = 'proto.cbv1.DriverCompleteOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DriverCompleteOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DriverCompleteOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DriverCompleteOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverCompleteOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DriverCompleteOrderResponse}
 */
proto.cbv1.DriverCompleteOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DriverCompleteOrderResponse;
  return proto.cbv1.DriverCompleteOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DriverCompleteOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DriverCompleteOrderResponse}
 */
proto.cbv1.DriverCompleteOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DriverCompleteOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DriverCompleteOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DriverCompleteOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverCompleteOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CompleteOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CompleteOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CompleteOrderRequest.displayName = 'proto.cbv1.CompleteOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CompleteOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CompleteOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CompleteOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CompleteOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CompleteOrderRequest}
 */
proto.cbv1.CompleteOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CompleteOrderRequest;
  return proto.cbv1.CompleteOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CompleteOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CompleteOrderRequest}
 */
proto.cbv1.CompleteOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CompleteOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CompleteOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CompleteOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CompleteOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 order_id = 1;
 * @return {number}
 */
proto.cbv1.CompleteOrderRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.CompleteOrderRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CompleteOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CompleteOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CompleteOrderResponse.displayName = 'proto.cbv1.CompleteOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CompleteOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CompleteOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CompleteOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CompleteOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CompleteOrderResponse}
 */
proto.cbv1.CompleteOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CompleteOrderResponse;
  return proto.cbv1.CompleteOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CompleteOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CompleteOrderResponse}
 */
proto.cbv1.CompleteOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CompleteOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CompleteOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CompleteOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CompleteOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorDeclineOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.VendorDeclineOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorDeclineOrderRequest.displayName = 'proto.cbv1.VendorDeclineOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorDeclineOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorDeclineOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorDeclineOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorDeclineOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorDeclineOrderRequest}
 */
proto.cbv1.VendorDeclineOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorDeclineOrderRequest;
  return proto.cbv1.VendorDeclineOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorDeclineOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorDeclineOrderRequest}
 */
proto.cbv1.VendorDeclineOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorDeclineOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorDeclineOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorDeclineOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorDeclineOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 order_id = 1;
 * @return {number}
 */
proto.cbv1.VendorDeclineOrderRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.VendorDeclineOrderRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorDeclineOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.VendorDeclineOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorDeclineOrderResponse.displayName = 'proto.cbv1.VendorDeclineOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorDeclineOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorDeclineOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorDeclineOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorDeclineOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorDeclineOrderResponse}
 */
proto.cbv1.VendorDeclineOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorDeclineOrderResponse;
  return proto.cbv1.VendorDeclineOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorDeclineOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorDeclineOrderResponse}
 */
proto.cbv1.VendorDeclineOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorDeclineOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorDeclineOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorDeclineOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorDeclineOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorDispatchOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.VendorDispatchOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorDispatchOrderRequest.displayName = 'proto.cbv1.VendorDispatchOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorDispatchOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorDispatchOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorDispatchOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorDispatchOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    driverUid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    truckId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    trailerId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    ticketNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    driverHasTrailer: jspb.Message.getFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorDispatchOrderRequest}
 */
proto.cbv1.VendorDispatchOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorDispatchOrderRequest;
  return proto.cbv1.VendorDispatchOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorDispatchOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorDispatchOrderRequest}
 */
proto.cbv1.VendorDispatchOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverUid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTruckId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrailerId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDriverHasTrailer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorDispatchOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorDispatchOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorDispatchOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorDispatchOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDriverUid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTruckId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTrailerId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTicketNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDriverHasTrailer();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int32 order_id = 1;
 * @return {number}
 */
proto.cbv1.VendorDispatchOrderRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.VendorDispatchOrderRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string driver_uid = 2;
 * @return {string}
 */
proto.cbv1.VendorDispatchOrderRequest.prototype.getDriverUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.VendorDispatchOrderRequest.prototype.setDriverUid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 truck_id = 3;
 * @return {number}
 */
proto.cbv1.VendorDispatchOrderRequest.prototype.getTruckId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.VendorDispatchOrderRequest.prototype.setTruckId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 trailer_id = 4;
 * @return {number}
 */
proto.cbv1.VendorDispatchOrderRequest.prototype.getTrailerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.VendorDispatchOrderRequest.prototype.setTrailerId = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string ticket_number = 5;
 * @return {string}
 */
proto.cbv1.VendorDispatchOrderRequest.prototype.getTicketNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.VendorDispatchOrderRequest.prototype.setTicketNumber = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.cbv1.VendorDispatchOrderRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cbv1.VendorDispatchOrderRequest.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool driver_has_trailer = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.VendorDispatchOrderRequest.prototype.getDriverHasTrailer = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.cbv1.VendorDispatchOrderRequest.prototype.setDriverHasTrailer = function(value) {
  jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorDispatchOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.VendorDispatchOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorDispatchOrderResponse.displayName = 'proto.cbv1.VendorDispatchOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorDispatchOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorDispatchOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorDispatchOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorDispatchOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto_cybertron_order_order_pb.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorDispatchOrderResponse}
 */
proto.cbv1.VendorDispatchOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorDispatchOrderResponse;
  return proto.cbv1.VendorDispatchOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorDispatchOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorDispatchOrderResponse}
 */
proto.cbv1.VendorDispatchOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.Order;
      reader.readMessage(value,proto_cybertron_order_order_pb.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorDispatchOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorDispatchOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorDispatchOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorDispatchOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.cbv1.Order}
 */
proto.cbv1.VendorDispatchOrderResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.Order} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.Order, 1));
};


/** @param {?proto.cbv1.Order|undefined} value */
proto.cbv1.VendorDispatchOrderResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.VendorDispatchOrderResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.VendorDispatchOrderResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOReassignOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOReassignOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOReassignOrderRequest.displayName = 'proto.cbv1.LMOReassignOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOReassignOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOReassignOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOReassignOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOReassignOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    truckingVendorId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOReassignOrderRequest}
 */
proto.cbv1.LMOReassignOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOReassignOrderRequest;
  return proto.cbv1.LMOReassignOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOReassignOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOReassignOrderRequest}
 */
proto.cbv1.LMOReassignOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTruckingVendorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOReassignOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOReassignOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOReassignOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOReassignOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTruckingVendorId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 order_id = 1;
 * @return {number}
 */
proto.cbv1.LMOReassignOrderRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.LMOReassignOrderRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 trucking_vendor_id = 2;
 * @return {number}
 */
proto.cbv1.LMOReassignOrderRequest.prototype.getTruckingVendorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.LMOReassignOrderRequest.prototype.setTruckingVendorId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOReassignOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOReassignOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOReassignOrderResponse.displayName = 'proto.cbv1.LMOReassignOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOReassignOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOReassignOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOReassignOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOReassignOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto_cybertron_order_order_pb.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOReassignOrderResponse}
 */
proto.cbv1.LMOReassignOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOReassignOrderResponse;
  return proto.cbv1.LMOReassignOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOReassignOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOReassignOrderResponse}
 */
proto.cbv1.LMOReassignOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.Order;
      reader.readMessage(value,proto_cybertron_order_order_pb.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOReassignOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOReassignOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOReassignOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOReassignOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.cbv1.Order}
 */
proto.cbv1.LMOReassignOrderResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.Order} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.Order, 1));
};


/** @param {?proto.cbv1.Order|undefined} value */
proto.cbv1.LMOReassignOrderResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.LMOReassignOrderResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.LMOReassignOrderResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorUnassignOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.VendorUnassignOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorUnassignOrderRequest.displayName = 'proto.cbv1.VendorUnassignOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorUnassignOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorUnassignOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorUnassignOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorUnassignOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorUnassignOrderRequest}
 */
proto.cbv1.VendorUnassignOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorUnassignOrderRequest;
  return proto.cbv1.VendorUnassignOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorUnassignOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorUnassignOrderRequest}
 */
proto.cbv1.VendorUnassignOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorUnassignOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorUnassignOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorUnassignOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorUnassignOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 order_id = 1;
 * @return {number}
 */
proto.cbv1.VendorUnassignOrderRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.VendorUnassignOrderRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorUnassignOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.VendorUnassignOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorUnassignOrderResponse.displayName = 'proto.cbv1.VendorUnassignOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorUnassignOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorUnassignOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorUnassignOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorUnassignOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto_cybertron_order_order_pb.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorUnassignOrderResponse}
 */
proto.cbv1.VendorUnassignOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorUnassignOrderResponse;
  return proto.cbv1.VendorUnassignOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorUnassignOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorUnassignOrderResponse}
 */
proto.cbv1.VendorUnassignOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.Order;
      reader.readMessage(value,proto_cybertron_order_order_pb.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorUnassignOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorUnassignOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorUnassignOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorUnassignOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.cbv1.Order}
 */
proto.cbv1.VendorUnassignOrderResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.Order} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.Order, 1));
};


/** @param {?proto.cbv1.Order|undefined} value */
proto.cbv1.VendorUnassignOrderResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.VendorUnassignOrderResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.VendorUnassignOrderResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorUnassignDriverOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.VendorUnassignDriverOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorUnassignDriverOrderRequest.displayName = 'proto.cbv1.VendorUnassignDriverOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorUnassignDriverOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorUnassignDriverOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorUnassignDriverOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorUnassignDriverOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    driverUid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    completeOrder: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorUnassignDriverOrderRequest}
 */
proto.cbv1.VendorUnassignDriverOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorUnassignDriverOrderRequest;
  return proto.cbv1.VendorUnassignDriverOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorUnassignDriverOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorUnassignDriverOrderRequest}
 */
proto.cbv1.VendorUnassignDriverOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverUid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleteOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorUnassignDriverOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorUnassignDriverOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorUnassignDriverOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorUnassignDriverOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriverUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompleteOrder();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string driver_uid = 1;
 * @return {string}
 */
proto.cbv1.VendorUnassignDriverOrderRequest.prototype.getDriverUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.VendorUnassignDriverOrderRequest.prototype.setDriverUid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool complete_order = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.VendorUnassignDriverOrderRequest.prototype.getCompleteOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.VendorUnassignDriverOrderRequest.prototype.setCompleteOrder = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorUnassignDriverOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.VendorUnassignDriverOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorUnassignDriverOrderResponse.displayName = 'proto.cbv1.VendorUnassignDriverOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorUnassignDriverOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorUnassignDriverOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorUnassignDriverOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorUnassignDriverOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorUnassignDriverOrderResponse}
 */
proto.cbv1.VendorUnassignDriverOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorUnassignDriverOrderResponse;
  return proto.cbv1.VendorUnassignDriverOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorUnassignDriverOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorUnassignDriverOrderResponse}
 */
proto.cbv1.VendorUnassignDriverOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorUnassignDriverOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorUnassignDriverOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorUnassignDriverOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorUnassignDriverOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.OrderEventStreamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.OrderEventStreamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.OrderEventStreamRequest.displayName = 'proto.cbv1.OrderEventStreamRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.OrderEventStreamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.OrderEventStreamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.OrderEventStreamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrderEventStreamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.OrderEventStreamRequest}
 */
proto.cbv1.OrderEventStreamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.OrderEventStreamRequest;
  return proto.cbv1.OrderEventStreamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.OrderEventStreamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.OrderEventStreamRequest}
 */
proto.cbv1.OrderEventStreamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.OrderEventStreamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.OrderEventStreamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.OrderEventStreamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrderEventStreamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.OrderEventStreamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.OrderEventStreamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.OrderEventStreamResponse.displayName = 'proto.cbv1.OrderEventStreamResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.OrderEventStreamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.OrderEventStreamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.OrderEventStreamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrderEventStreamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto_cybertron_order_order_pb.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.OrderEventStreamResponse}
 */
proto.cbv1.OrderEventStreamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.OrderEventStreamResponse;
  return proto.cbv1.OrderEventStreamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.OrderEventStreamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.OrderEventStreamResponse}
 */
proto.cbv1.OrderEventStreamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.Order;
      reader.readMessage(value,proto_cybertron_order_order_pb.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.OrderEventStreamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.OrderEventStreamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.OrderEventStreamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrderEventStreamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.cbv1.Order}
 */
proto.cbv1.OrderEventStreamResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.Order} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.Order, 1));
};


/** @param {?proto.cbv1.Order|undefined} value */
proto.cbv1.OrderEventStreamResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.OrderEventStreamResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.OrderEventStreamResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.MappableOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.MappableOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.MappableOrderRequest.displayName = 'proto.cbv1.MappableOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.MappableOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.MappableOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.MappableOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MappableOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, 0),
    maxResults: jspb.Message.getFieldWithDefault(msg, 3, 0),
    orderBy: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.MappableOrderRequest}
 */
proto.cbv1.MappableOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.MappableOrderRequest;
  return proto.cbv1.MappableOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.MappableOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.MappableOrderRequest}
 */
proto.cbv1.MappableOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxResults(value);
      break;
    case 4:
      var value = /** @type {!proto.cbv1.OrderOrderBy} */ (reader.readEnum());
      msg.setOrderBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.MappableOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.MappableOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.MappableOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MappableOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMaxResults();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOrderBy();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int32 site_id = 1;
 * @return {number}
 */
proto.cbv1.MappableOrderRequest.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.MappableOrderRequest.prototype.setSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_token = 2;
 * @return {number}
 */
proto.cbv1.MappableOrderRequest.prototype.getPageToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.MappableOrderRequest.prototype.setPageToken = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 max_results = 3;
 * @return {number}
 */
proto.cbv1.MappableOrderRequest.prototype.getMaxResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.MappableOrderRequest.prototype.setMaxResults = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional OrderOrderBy order_by = 4;
 * @return {!proto.cbv1.OrderOrderBy}
 */
proto.cbv1.MappableOrderRequest.prototype.getOrderBy = function() {
  return /** @type {!proto.cbv1.OrderOrderBy} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.cbv1.OrderOrderBy} value */
proto.cbv1.MappableOrderRequest.prototype.setOrderBy = function(value) {
  jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.MappableOrderStreamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.MappableOrderStreamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.MappableOrderStreamRequest.displayName = 'proto.cbv1.MappableOrderStreamRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.MappableOrderStreamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.MappableOrderStreamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.MappableOrderStreamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MappableOrderStreamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.MappableOrderStreamRequest}
 */
proto.cbv1.MappableOrderStreamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.MappableOrderStreamRequest;
  return proto.cbv1.MappableOrderStreamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.MappableOrderStreamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.MappableOrderStreamRequest}
 */
proto.cbv1.MappableOrderStreamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.MappableOrderStreamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.MappableOrderStreamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.MappableOrderStreamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MappableOrderStreamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 site_id = 1;
 * @return {number}
 */
proto.cbv1.MappableOrderStreamRequest.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.MappableOrderStreamRequest.prototype.setSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.MappableOrderStreamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.MappableOrderStreamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.MappableOrderStreamResponse.displayName = 'proto.cbv1.MappableOrderStreamResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.MappableOrderStreamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.MappableOrderStreamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.MappableOrderStreamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MappableOrderStreamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: (f = msg.getEvent()) && proto_cybertron_order_order_pb.Event.toObject(includeInstance, f),
    order: (f = msg.getOrder()) && proto_cybertron_order_order_pb.MappableOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.MappableOrderStreamResponse}
 */
proto.cbv1.MappableOrderStreamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.MappableOrderStreamResponse;
  return proto.cbv1.MappableOrderStreamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.MappableOrderStreamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.MappableOrderStreamResponse}
 */
proto.cbv1.MappableOrderStreamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.Event;
      reader.readMessage(value,proto_cybertron_order_order_pb.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 2:
      var value = new proto_cybertron_order_order_pb.MappableOrder;
      reader.readMessage(value,proto_cybertron_order_order_pb.MappableOrder.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.MappableOrderStreamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.MappableOrderStreamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.MappableOrderStreamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MappableOrderStreamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.Event.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_cybertron_order_order_pb.MappableOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional Event event = 1;
 * @return {?proto.cbv1.Event}
 */
proto.cbv1.MappableOrderStreamResponse.prototype.getEvent = function() {
  return /** @type{?proto.cbv1.Event} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.Event, 1));
};


/** @param {?proto.cbv1.Event|undefined} value */
proto.cbv1.MappableOrderStreamResponse.prototype.setEvent = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.MappableOrderStreamResponse.prototype.clearEvent = function() {
  this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.MappableOrderStreamResponse.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MappableOrder order = 2;
 * @return {?proto.cbv1.MappableOrder}
 */
proto.cbv1.MappableOrderStreamResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.MappableOrder} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.MappableOrder, 2));
};


/** @param {?proto.cbv1.MappableOrder|undefined} value */
proto.cbv1.MappableOrderStreamResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cbv1.MappableOrderStreamResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.MappableOrderStreamResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.MappableOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.MappableOrderResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.MappableOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.MappableOrderResponse.displayName = 'proto.cbv1.MappableOrderResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.MappableOrderResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.MappableOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.MappableOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.MappableOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MappableOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto_cybertron_order_order_pb.MappableOrder.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastToken: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.MappableOrderResponse}
 */
proto.cbv1.MappableOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.MappableOrderResponse;
  return proto.cbv1.MappableOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.MappableOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.MappableOrderResponse}
 */
proto.cbv1.MappableOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    case 2:
      var value = new proto_cybertron_order_order_pb.MappableOrder;
      reader.readMessage(value,proto_cybertron_order_order_pb.MappableOrder.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNextPageToken(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.MappableOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.MappableOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.MappableOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MappableOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_cybertron_order_order_pb.MappableOrder.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLastToken();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 site_id = 1;
 * @return {number}
 */
proto.cbv1.MappableOrderResponse.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.MappableOrderResponse.prototype.setSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated MappableOrder orders = 2;
 * @return {!Array<!proto.cbv1.MappableOrder>}
 */
proto.cbv1.MappableOrderResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.cbv1.MappableOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_order_order_pb.MappableOrder, 2));
};


/** @param {!Array<!proto.cbv1.MappableOrder>} value */
proto.cbv1.MappableOrderResponse.prototype.setOrdersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cbv1.MappableOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.MappableOrder}
 */
proto.cbv1.MappableOrderResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cbv1.MappableOrder, opt_index);
};


proto.cbv1.MappableOrderResponse.prototype.clearOrdersList = function() {
  this.setOrdersList([]);
};


/**
 * optional int32 next_page_token = 3;
 * @return {number}
 */
proto.cbv1.MappableOrderResponse.prototype.getNextPageToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.MappableOrderResponse.prototype.setNextPageToken = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 last_token = 4;
 * @return {number}
 */
proto.cbv1.MappableOrderResponse.prototype.getLastToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.MappableOrderResponse.prototype.setLastToken = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.MappableOrderCompletedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.MappableOrderCompletedResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.MappableOrderCompletedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.MappableOrderCompletedResponse.displayName = 'proto.cbv1.MappableOrderCompletedResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.MappableOrderCompletedResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.MappableOrderCompletedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.MappableOrderCompletedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.MappableOrderCompletedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MappableOrderCompletedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto_cybertron_order_order_pb.MappableOrderCompleted.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastToken: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.MappableOrderCompletedResponse}
 */
proto.cbv1.MappableOrderCompletedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.MappableOrderCompletedResponse;
  return proto.cbv1.MappableOrderCompletedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.MappableOrderCompletedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.MappableOrderCompletedResponse}
 */
proto.cbv1.MappableOrderCompletedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    case 2:
      var value = new proto_cybertron_order_order_pb.MappableOrderCompleted;
      reader.readMessage(value,proto_cybertron_order_order_pb.MappableOrderCompleted.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNextPageToken(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.MappableOrderCompletedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.MappableOrderCompletedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.MappableOrderCompletedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MappableOrderCompletedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_cybertron_order_order_pb.MappableOrderCompleted.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLastToken();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 site_id = 1;
 * @return {number}
 */
proto.cbv1.MappableOrderCompletedResponse.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.MappableOrderCompletedResponse.prototype.setSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated MappableOrderCompleted orders = 2;
 * @return {!Array<!proto.cbv1.MappableOrderCompleted>}
 */
proto.cbv1.MappableOrderCompletedResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.cbv1.MappableOrderCompleted>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_order_order_pb.MappableOrderCompleted, 2));
};


/** @param {!Array<!proto.cbv1.MappableOrderCompleted>} value */
proto.cbv1.MappableOrderCompletedResponse.prototype.setOrdersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cbv1.MappableOrderCompleted=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.MappableOrderCompleted}
 */
proto.cbv1.MappableOrderCompletedResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cbv1.MappableOrderCompleted, opt_index);
};


proto.cbv1.MappableOrderCompletedResponse.prototype.clearOrdersList = function() {
  this.setOrdersList([]);
};


/**
 * optional int32 next_page_token = 3;
 * @return {number}
 */
proto.cbv1.MappableOrderCompletedResponse.prototype.getNextPageToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.MappableOrderCompletedResponse.prototype.setNextPageToken = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 last_token = 4;
 * @return {number}
 */
proto.cbv1.MappableOrderCompletedResponse.prototype.getLastToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.MappableOrderCompletedResponse.prototype.setLastToken = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.StreamMappableOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.StreamMappableOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.StreamMappableOrderRequest.displayName = 'proto.cbv1.StreamMappableOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.StreamMappableOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.StreamMappableOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.StreamMappableOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StreamMappableOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.StreamMappableOrderRequest}
 */
proto.cbv1.StreamMappableOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.StreamMappableOrderRequest;
  return proto.cbv1.StreamMappableOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.StreamMappableOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.StreamMappableOrderRequest}
 */
proto.cbv1.StreamMappableOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.StreamMappableOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.StreamMappableOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.StreamMappableOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StreamMappableOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.StreamMappableOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.StreamMappableOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.StreamMappableOrderResponse.displayName = 'proto.cbv1.StreamMappableOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.StreamMappableOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.StreamMappableOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.StreamMappableOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StreamMappableOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    mappableOrderEvent: (f = msg.getMappableOrderEvent()) && proto_cybertron_order_order_pb.MappableOrderEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.StreamMappableOrderResponse}
 */
proto.cbv1.StreamMappableOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.StreamMappableOrderResponse;
  return proto.cbv1.StreamMappableOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.StreamMappableOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.StreamMappableOrderResponse}
 */
proto.cbv1.StreamMappableOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.MappableOrderEvent;
      reader.readMessage(value,proto_cybertron_order_order_pb.MappableOrderEvent.deserializeBinaryFromReader);
      msg.setMappableOrderEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.StreamMappableOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.StreamMappableOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.StreamMappableOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StreamMappableOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMappableOrderEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.MappableOrderEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional MappableOrderEvent mappable_order_event = 1;
 * @return {?proto.cbv1.MappableOrderEvent}
 */
proto.cbv1.StreamMappableOrderResponse.prototype.getMappableOrderEvent = function() {
  return /** @type{?proto.cbv1.MappableOrderEvent} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.MappableOrderEvent, 1));
};


/** @param {?proto.cbv1.MappableOrderEvent|undefined} value */
proto.cbv1.StreamMappableOrderResponse.prototype.setMappableOrderEvent = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.StreamMappableOrderResponse.prototype.clearMappableOrderEvent = function() {
  this.setMappableOrderEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.StreamMappableOrderResponse.prototype.hasMappableOrderEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DriverGetOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DriverGetOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DriverGetOrderRequest.displayName = 'proto.cbv1.DriverGetOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DriverGetOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DriverGetOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DriverGetOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverGetOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DriverGetOrderRequest}
 */
proto.cbv1.DriverGetOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DriverGetOrderRequest;
  return proto.cbv1.DriverGetOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DriverGetOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DriverGetOrderRequest}
 */
proto.cbv1.DriverGetOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DriverGetOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DriverGetOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DriverGetOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverGetOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DriverGetOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DriverGetOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DriverGetOrderResponse.displayName = 'proto.cbv1.DriverGetOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DriverGetOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DriverGetOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DriverGetOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverGetOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto_cybertron_order_order_pb.DriverOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DriverGetOrderResponse}
 */
proto.cbv1.DriverGetOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DriverGetOrderResponse;
  return proto.cbv1.DriverGetOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DriverGetOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DriverGetOrderResponse}
 */
proto.cbv1.DriverGetOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.DriverOrder;
      reader.readMessage(value,proto_cybertron_order_order_pb.DriverOrder.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DriverGetOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DriverGetOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DriverGetOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverGetOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.DriverOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional DriverOrder order = 1;
 * @return {?proto.cbv1.DriverOrder}
 */
proto.cbv1.DriverGetOrderResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.DriverOrder} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.DriverOrder, 1));
};


/** @param {?proto.cbv1.DriverOrder|undefined} value */
proto.cbv1.DriverGetOrderResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.DriverGetOrderResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.DriverGetOrderResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DriverStopRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DriverStopRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DriverStopRequest.displayName = 'proto.cbv1.DriverStopRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DriverStopRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DriverStopRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DriverStopRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverStopRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    driverStopId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    guid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isWorkRelated: jspb.Message.getFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DriverStopRequest}
 */
proto.cbv1.DriverStopRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DriverStopRequest;
  return proto.cbv1.DriverStopRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DriverStopRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DriverStopRequest}
 */
proto.cbv1.DriverStopRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDriverStopId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGuid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsWorkRelated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DriverStopRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DriverStopRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DriverStopRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverStopRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriverStopId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getGuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsWorkRelated();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 driver_stop_id = 1;
 * @return {number}
 */
proto.cbv1.DriverStopRequest.prototype.getDriverStopId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.DriverStopRequest.prototype.setDriverStopId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string guid = 2;
 * @return {string}
 */
proto.cbv1.DriverStopRequest.prototype.getGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.DriverStopRequest.prototype.setGuid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_work_related = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.DriverStopRequest.prototype.getIsWorkRelated = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.cbv1.DriverStopRequest.prototype.setIsWorkRelated = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DriverStopResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DriverStopResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DriverStopResponse.displayName = 'proto.cbv1.DriverStopResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DriverStopResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DriverStopResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DriverStopResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverStopResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DriverStopResponse}
 */
proto.cbv1.DriverStopResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DriverStopResponse;
  return proto.cbv1.DriverStopResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DriverStopResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DriverStopResponse}
 */
proto.cbv1.DriverStopResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DriverStopResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DriverStopResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DriverStopResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverStopResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.cbv1.DriverStopResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.DriverStopResponse.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.AutoCloseOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.AutoCloseOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.AutoCloseOrderRequest.displayName = 'proto.cbv1.AutoCloseOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.AutoCloseOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.AutoCloseOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.AutoCloseOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AutoCloseOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    guid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shouldAutoClose: jspb.Message.getFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.AutoCloseOrderRequest}
 */
proto.cbv1.AutoCloseOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.AutoCloseOrderRequest;
  return proto.cbv1.AutoCloseOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.AutoCloseOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.AutoCloseOrderRequest}
 */
proto.cbv1.AutoCloseOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGuid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShouldAutoClose(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.AutoCloseOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.AutoCloseOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.AutoCloseOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AutoCloseOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShouldAutoClose();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 order_id = 1;
 * @return {number}
 */
proto.cbv1.AutoCloseOrderRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.AutoCloseOrderRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string guid = 2;
 * @return {string}
 */
proto.cbv1.AutoCloseOrderRequest.prototype.getGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.AutoCloseOrderRequest.prototype.setGuid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool should_auto_close = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.AutoCloseOrderRequest.prototype.getShouldAutoClose = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.cbv1.AutoCloseOrderRequest.prototype.setShouldAutoClose = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.AutoCloseOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.AutoCloseOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.AutoCloseOrderResponse.displayName = 'proto.cbv1.AutoCloseOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.AutoCloseOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.AutoCloseOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.AutoCloseOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AutoCloseOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.AutoCloseOrderResponse}
 */
proto.cbv1.AutoCloseOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.AutoCloseOrderResponse;
  return proto.cbv1.AutoCloseOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.AutoCloseOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.AutoCloseOrderResponse}
 */
proto.cbv1.AutoCloseOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.AutoCloseOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.AutoCloseOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.AutoCloseOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AutoCloseOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.cbv1.AutoCloseOrderResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.AutoCloseOrderResponse.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UnassignTrailerOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UnassignTrailerOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UnassignTrailerOrderRequest.displayName = 'proto.cbv1.UnassignTrailerOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UnassignTrailerOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UnassignTrailerOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UnassignTrailerOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UnassignTrailerOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    trailerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    completeOrder: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UnassignTrailerOrderRequest}
 */
proto.cbv1.UnassignTrailerOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UnassignTrailerOrderRequest;
  return proto.cbv1.UnassignTrailerOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UnassignTrailerOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UnassignTrailerOrderRequest}
 */
proto.cbv1.UnassignTrailerOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrailerId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleteOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UnassignTrailerOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UnassignTrailerOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UnassignTrailerOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UnassignTrailerOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrailerId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompleteOrder();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int32 trailer_id = 1;
 * @return {number}
 */
proto.cbv1.UnassignTrailerOrderRequest.prototype.getTrailerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.UnassignTrailerOrderRequest.prototype.setTrailerId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool complete_order = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.UnassignTrailerOrderRequest.prototype.getCompleteOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.UnassignTrailerOrderRequest.prototype.setCompleteOrder = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UnassignTrailerOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UnassignTrailerOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UnassignTrailerOrderResponse.displayName = 'proto.cbv1.UnassignTrailerOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UnassignTrailerOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UnassignTrailerOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UnassignTrailerOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UnassignTrailerOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UnassignTrailerOrderResponse}
 */
proto.cbv1.UnassignTrailerOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UnassignTrailerOrderResponse;
  return proto.cbv1.UnassignTrailerOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UnassignTrailerOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UnassignTrailerOrderResponse}
 */
proto.cbv1.UnassignTrailerOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UnassignTrailerOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UnassignTrailerOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UnassignTrailerOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UnassignTrailerOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UnassignTruckOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UnassignTruckOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UnassignTruckOrderRequest.displayName = 'proto.cbv1.UnassignTruckOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UnassignTruckOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UnassignTruckOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UnassignTruckOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UnassignTruckOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    truckId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    completeOrder: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UnassignTruckOrderRequest}
 */
proto.cbv1.UnassignTruckOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UnassignTruckOrderRequest;
  return proto.cbv1.UnassignTruckOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UnassignTruckOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UnassignTruckOrderRequest}
 */
proto.cbv1.UnassignTruckOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTruckId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleteOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UnassignTruckOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UnassignTruckOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UnassignTruckOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UnassignTruckOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTruckId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompleteOrder();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int32 truck_id = 1;
 * @return {number}
 */
proto.cbv1.UnassignTruckOrderRequest.prototype.getTruckId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.UnassignTruckOrderRequest.prototype.setTruckId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool complete_order = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.UnassignTruckOrderRequest.prototype.getCompleteOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.UnassignTruckOrderRequest.prototype.setCompleteOrder = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UnassignTruckOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UnassignTruckOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UnassignTruckOrderResponse.displayName = 'proto.cbv1.UnassignTruckOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UnassignTruckOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UnassignTruckOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UnassignTruckOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UnassignTruckOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UnassignTruckOrderResponse}
 */
proto.cbv1.UnassignTruckOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UnassignTruckOrderResponse;
  return proto.cbv1.UnassignTruckOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UnassignTruckOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UnassignTruckOrderResponse}
 */
proto.cbv1.UnassignTruckOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UnassignTruckOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UnassignTruckOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UnassignTruckOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UnassignTruckOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.PurchaseOrderSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.PurchaseOrderSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.PurchaseOrderSearchRequest.displayName = 'proto.cbv1.PurchaseOrderSearchRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.PurchaseOrderSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.PurchaseOrderSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.PurchaseOrderSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.PurchaseOrderSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchText: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.PurchaseOrderSearchRequest}
 */
proto.cbv1.PurchaseOrderSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.PurchaseOrderSearchRequest;
  return proto.cbv1.PurchaseOrderSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.PurchaseOrderSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.PurchaseOrderSearchRequest}
 */
proto.cbv1.PurchaseOrderSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.PurchaseOrderSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.PurchaseOrderSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.PurchaseOrderSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.PurchaseOrderSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string search_text = 1;
 * @return {string}
 */
proto.cbv1.PurchaseOrderSearchRequest.prototype.getSearchText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.PurchaseOrderSearchRequest.prototype.setSearchText = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.PurchaseOrderSearchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.PurchaseOrderSearchResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.PurchaseOrderSearchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.PurchaseOrderSearchResponse.displayName = 'proto.cbv1.PurchaseOrderSearchResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.PurchaseOrderSearchResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.PurchaseOrderSearchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.PurchaseOrderSearchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.PurchaseOrderSearchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.PurchaseOrderSearchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    purchaseOrderNamesList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.PurchaseOrderSearchResponse}
 */
proto.cbv1.PurchaseOrderSearchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.PurchaseOrderSearchResponse;
  return proto.cbv1.PurchaseOrderSearchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.PurchaseOrderSearchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.PurchaseOrderSearchResponse}
 */
proto.cbv1.PurchaseOrderSearchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addPurchaseOrderNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.PurchaseOrderSearchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.PurchaseOrderSearchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.PurchaseOrderSearchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.PurchaseOrderSearchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurchaseOrderNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string purchase_order_names = 1;
 * @return {!Array<string>}
 */
proto.cbv1.PurchaseOrderSearchResponse.prototype.getPurchaseOrderNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.cbv1.PurchaseOrderSearchResponse.prototype.setPurchaseOrderNamesList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.PurchaseOrderSearchResponse.prototype.addPurchaseOrderNames = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv1.PurchaseOrderSearchResponse.prototype.clearPurchaseOrderNamesList = function() {
  this.setPurchaseOrderNamesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SalesOrderSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.SalesOrderSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SalesOrderSearchRequest.displayName = 'proto.cbv1.SalesOrderSearchRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SalesOrderSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SalesOrderSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SalesOrderSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SalesOrderSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchText: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SalesOrderSearchRequest}
 */
proto.cbv1.SalesOrderSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SalesOrderSearchRequest;
  return proto.cbv1.SalesOrderSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SalesOrderSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SalesOrderSearchRequest}
 */
proto.cbv1.SalesOrderSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SalesOrderSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SalesOrderSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SalesOrderSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SalesOrderSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string search_text = 1;
 * @return {string}
 */
proto.cbv1.SalesOrderSearchRequest.prototype.getSearchText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.SalesOrderSearchRequest.prototype.setSearchText = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SalesOrderSearchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.SalesOrderSearchResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.SalesOrderSearchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SalesOrderSearchResponse.displayName = 'proto.cbv1.SalesOrderSearchResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.SalesOrderSearchResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SalesOrderSearchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SalesOrderSearchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SalesOrderSearchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SalesOrderSearchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    salesOrdersList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SalesOrderSearchResponse}
 */
proto.cbv1.SalesOrderSearchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SalesOrderSearchResponse;
  return proto.cbv1.SalesOrderSearchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SalesOrderSearchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SalesOrderSearchResponse}
 */
proto.cbv1.SalesOrderSearchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSalesOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SalesOrderSearchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SalesOrderSearchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SalesOrderSearchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SalesOrderSearchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSalesOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string sales_orders = 1;
 * @return {!Array<string>}
 */
proto.cbv1.SalesOrderSearchResponse.prototype.getSalesOrdersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.cbv1.SalesOrderSearchResponse.prototype.setSalesOrdersList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.SalesOrderSearchResponse.prototype.addSalesOrders = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv1.SalesOrderSearchResponse.prototype.clearSalesOrdersList = function() {
  this.setSalesOrdersList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.MaintenanceOrderSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.MaintenanceOrderSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.MaintenanceOrderSearchRequest.displayName = 'proto.cbv1.MaintenanceOrderSearchRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.MaintenanceOrderSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.MaintenanceOrderSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.MaintenanceOrderSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MaintenanceOrderSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchText: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.MaintenanceOrderSearchRequest}
 */
proto.cbv1.MaintenanceOrderSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.MaintenanceOrderSearchRequest;
  return proto.cbv1.MaintenanceOrderSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.MaintenanceOrderSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.MaintenanceOrderSearchRequest}
 */
proto.cbv1.MaintenanceOrderSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.MaintenanceOrderSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.MaintenanceOrderSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.MaintenanceOrderSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MaintenanceOrderSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string search_text = 1;
 * @return {string}
 */
proto.cbv1.MaintenanceOrderSearchRequest.prototype.getSearchText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.MaintenanceOrderSearchRequest.prototype.setSearchText = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.MaintenanceOrderSearchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.MaintenanceOrderSearchResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.MaintenanceOrderSearchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.MaintenanceOrderSearchResponse.displayName = 'proto.cbv1.MaintenanceOrderSearchResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.MaintenanceOrderSearchResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.MaintenanceOrderSearchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.MaintenanceOrderSearchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.MaintenanceOrderSearchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MaintenanceOrderSearchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    maintenanceOrdersList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.MaintenanceOrderSearchResponse}
 */
proto.cbv1.MaintenanceOrderSearchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.MaintenanceOrderSearchResponse;
  return proto.cbv1.MaintenanceOrderSearchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.MaintenanceOrderSearchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.MaintenanceOrderSearchResponse}
 */
proto.cbv1.MaintenanceOrderSearchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addMaintenanceOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.MaintenanceOrderSearchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.MaintenanceOrderSearchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.MaintenanceOrderSearchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MaintenanceOrderSearchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaintenanceOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string maintenance_orders = 1;
 * @return {!Array<string>}
 */
proto.cbv1.MaintenanceOrderSearchResponse.prototype.getMaintenanceOrdersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.cbv1.MaintenanceOrderSearchResponse.prototype.setMaintenanceOrdersList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.MaintenanceOrderSearchResponse.prototype.addMaintenanceOrders = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv1.MaintenanceOrderSearchResponse.prototype.clearMaintenanceOrdersList = function() {
  this.setMaintenanceOrdersList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetSiteOrderETAsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetSiteOrderETAsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetSiteOrderETAsRequest.displayName = 'proto.cbv1.GetSiteOrderETAsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetSiteOrderETAsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetSiteOrderETAsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetSiteOrderETAsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetSiteOrderETAsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetSiteOrderETAsRequest}
 */
proto.cbv1.GetSiteOrderETAsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetSiteOrderETAsRequest;
  return proto.cbv1.GetSiteOrderETAsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetSiteOrderETAsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetSiteOrderETAsRequest}
 */
proto.cbv1.GetSiteOrderETAsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetSiteOrderETAsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetSiteOrderETAsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetSiteOrderETAsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetSiteOrderETAsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 site_id = 1;
 * @return {number}
 */
proto.cbv1.GetSiteOrderETAsRequest.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.GetSiteOrderETAsRequest.prototype.setSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetSiteOrderETAsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetSiteOrderETAsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetSiteOrderETAsResponse.displayName = 'proto.cbv1.GetSiteOrderETAsResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetSiteOrderETAsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetSiteOrderETAsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetSiteOrderETAsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetSiteOrderETAsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    etas: (f = msg.getEtas()) && proto_cybertron_order_order_pb.SiteOrderETAs.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetSiteOrderETAsResponse}
 */
proto.cbv1.GetSiteOrderETAsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetSiteOrderETAsResponse;
  return proto.cbv1.GetSiteOrderETAsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetSiteOrderETAsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetSiteOrderETAsResponse}
 */
proto.cbv1.GetSiteOrderETAsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    case 2:
      var value = new proto_cybertron_order_order_pb.SiteOrderETAs;
      reader.readMessage(value,proto_cybertron_order_order_pb.SiteOrderETAs.deserializeBinaryFromReader);
      msg.setEtas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetSiteOrderETAsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetSiteOrderETAsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetSiteOrderETAsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetSiteOrderETAsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEtas();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_cybertron_order_order_pb.SiteOrderETAs.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 site_id = 1;
 * @return {number}
 */
proto.cbv1.GetSiteOrderETAsResponse.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.GetSiteOrderETAsResponse.prototype.setSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional SiteOrderETAs etas = 2;
 * @return {?proto.cbv1.SiteOrderETAs}
 */
proto.cbv1.GetSiteOrderETAsResponse.prototype.getEtas = function() {
  return /** @type{?proto.cbv1.SiteOrderETAs} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.SiteOrderETAs, 2));
};


/** @param {?proto.cbv1.SiteOrderETAs|undefined} value */
proto.cbv1.GetSiteOrderETAsResponse.prototype.setEtas = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cbv1.GetSiteOrderETAsResponse.prototype.clearEtas = function() {
  this.setEtas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.GetSiteOrderETAsResponse.prototype.hasEtas = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.AcceptOrderForDriverRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.AcceptOrderForDriverRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.AcceptOrderForDriverRequest.displayName = 'proto.cbv1.AcceptOrderForDriverRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.AcceptOrderForDriverRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.AcceptOrderForDriverRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.AcceptOrderForDriverRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AcceptOrderForDriverRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.AcceptOrderForDriverRequest}
 */
proto.cbv1.AcceptOrderForDriverRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.AcceptOrderForDriverRequest;
  return proto.cbv1.AcceptOrderForDriverRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.AcceptOrderForDriverRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.AcceptOrderForDriverRequest}
 */
proto.cbv1.AcceptOrderForDriverRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.AcceptOrderForDriverRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.AcceptOrderForDriverRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.AcceptOrderForDriverRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AcceptOrderForDriverRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 order_id = 1;
 * @return {number}
 */
proto.cbv1.AcceptOrderForDriverRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.AcceptOrderForDriverRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.AcceptOrderForDriverResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.AcceptOrderForDriverResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.AcceptOrderForDriverResponse.displayName = 'proto.cbv1.AcceptOrderForDriverResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.AcceptOrderForDriverResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.AcceptOrderForDriverResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.AcceptOrderForDriverResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AcceptOrderForDriverResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto_cybertron_order_order_pb.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.AcceptOrderForDriverResponse}
 */
proto.cbv1.AcceptOrderForDriverResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.AcceptOrderForDriverResponse;
  return proto.cbv1.AcceptOrderForDriverResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.AcceptOrderForDriverResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.AcceptOrderForDriverResponse}
 */
proto.cbv1.AcceptOrderForDriverResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.Order;
      reader.readMessage(value,proto_cybertron_order_order_pb.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.AcceptOrderForDriverResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.AcceptOrderForDriverResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.AcceptOrderForDriverResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AcceptOrderForDriverResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.cbv1.Order}
 */
proto.cbv1.AcceptOrderForDriverResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.Order} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.Order, 1));
};


/** @param {?proto.cbv1.Order|undefined} value */
proto.cbv1.AcceptOrderForDriverResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.AcceptOrderForDriverResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.AcceptOrderForDriverResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ManuallyCompleteTaskRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ManuallyCompleteTaskRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ManuallyCompleteTaskRequest.displayName = 'proto.cbv1.ManuallyCompleteTaskRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ManuallyCompleteTaskRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ManuallyCompleteTaskRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ManuallyCompleteTaskRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ManuallyCompleteTaskRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ManuallyCompleteTaskRequest}
 */
proto.cbv1.ManuallyCompleteTaskRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ManuallyCompleteTaskRequest;
  return proto.cbv1.ManuallyCompleteTaskRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ManuallyCompleteTaskRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ManuallyCompleteTaskRequest}
 */
proto.cbv1.ManuallyCompleteTaskRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ManuallyCompleteTaskRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ManuallyCompleteTaskRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ManuallyCompleteTaskRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ManuallyCompleteTaskRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 task_id = 1;
 * @return {number}
 */
proto.cbv1.ManuallyCompleteTaskRequest.prototype.getTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.ManuallyCompleteTaskRequest.prototype.setTaskId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ManuallyCompleteTaskResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ManuallyCompleteTaskResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ManuallyCompleteTaskResponse.displayName = 'proto.cbv1.ManuallyCompleteTaskResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ManuallyCompleteTaskResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ManuallyCompleteTaskResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ManuallyCompleteTaskResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ManuallyCompleteTaskResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto_cybertron_order_order_pb.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ManuallyCompleteTaskResponse}
 */
proto.cbv1.ManuallyCompleteTaskResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ManuallyCompleteTaskResponse;
  return proto.cbv1.ManuallyCompleteTaskResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ManuallyCompleteTaskResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ManuallyCompleteTaskResponse}
 */
proto.cbv1.ManuallyCompleteTaskResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.Order;
      reader.readMessage(value,proto_cybertron_order_order_pb.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ManuallyCompleteTaskResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ManuallyCompleteTaskResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ManuallyCompleteTaskResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ManuallyCompleteTaskResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.cbv1.Order}
 */
proto.cbv1.ManuallyCompleteTaskResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.Order} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.Order, 1));
};


/** @param {?proto.cbv1.Order|undefined} value */
proto.cbv1.ManuallyCompleteTaskResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.ManuallyCompleteTaskResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.ManuallyCompleteTaskResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SwapDriverOnOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.SwapDriverOnOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SwapDriverOnOrderRequest.displayName = 'proto.cbv1.SwapDriverOnOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SwapDriverOnOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SwapDriverOnOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SwapDriverOnOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SwapDriverOnOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    newDriverId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SwapDriverOnOrderRequest}
 */
proto.cbv1.SwapDriverOnOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SwapDriverOnOrderRequest;
  return proto.cbv1.SwapDriverOnOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SwapDriverOnOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SwapDriverOnOrderRequest}
 */
proto.cbv1.SwapDriverOnOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewDriverId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SwapDriverOnOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SwapDriverOnOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SwapDriverOnOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SwapDriverOnOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNewDriverId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 order_id = 1;
 * @return {number}
 */
proto.cbv1.SwapDriverOnOrderRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.SwapDriverOnOrderRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string new_driver_id = 2;
 * @return {string}
 */
proto.cbv1.SwapDriverOnOrderRequest.prototype.getNewDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.SwapDriverOnOrderRequest.prototype.setNewDriverId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SwapDriverOnOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.SwapDriverOnOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SwapDriverOnOrderResponse.displayName = 'proto.cbv1.SwapDriverOnOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SwapDriverOnOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SwapDriverOnOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SwapDriverOnOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SwapDriverOnOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto_cybertron_order_order_pb.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SwapDriverOnOrderResponse}
 */
proto.cbv1.SwapDriverOnOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SwapDriverOnOrderResponse;
  return proto.cbv1.SwapDriverOnOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SwapDriverOnOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SwapDriverOnOrderResponse}
 */
proto.cbv1.SwapDriverOnOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.Order;
      reader.readMessage(value,proto_cybertron_order_order_pb.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SwapDriverOnOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SwapDriverOnOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SwapDriverOnOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SwapDriverOnOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.cbv1.Order}
 */
proto.cbv1.SwapDriverOnOrderResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.Order} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.Order, 1));
};


/** @param {?proto.cbv1.Order|undefined} value */
proto.cbv1.SwapDriverOnOrderResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.SwapDriverOnOrderResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.SwapDriverOnOrderResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.FleetManagerDriverRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.FleetManagerDriverRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.FleetManagerDriverRequest.displayName = 'proto.cbv1.FleetManagerDriverRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.FleetManagerDriverRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.FleetManagerDriverRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.FleetManagerDriverRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FleetManagerDriverRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    day: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.FleetManagerDriverRequest}
 */
proto.cbv1.FleetManagerDriverRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.FleetManagerDriverRequest;
  return proto.cbv1.FleetManagerDriverRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.FleetManagerDriverRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.FleetManagerDriverRequest}
 */
proto.cbv1.FleetManagerDriverRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.FleetManagerDriverRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.FleetManagerDriverRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.FleetManagerDriverRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FleetManagerDriverRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 year = 1;
 * @return {number}
 */
proto.cbv1.FleetManagerDriverRequest.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.FleetManagerDriverRequest.prototype.setYear = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 month = 2;
 * @return {number}
 */
proto.cbv1.FleetManagerDriverRequest.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.FleetManagerDriverRequest.prototype.setMonth = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 day = 3;
 * @return {number}
 */
proto.cbv1.FleetManagerDriverRequest.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.FleetManagerDriverRequest.prototype.setDay = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.FleetManagerDriverResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.FleetManagerDriverResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.FleetManagerDriverResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.FleetManagerDriverResponse.displayName = 'proto.cbv1.FleetManagerDriverResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.FleetManagerDriverResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.FleetManagerDriverResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.FleetManagerDriverResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.FleetManagerDriverResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FleetManagerDriverResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    driversList: jspb.Message.toObjectList(msg.getDriversList(),
    proto_cybertron_order_order_pb.FleetManagerDriver.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.FleetManagerDriverResponse}
 */
proto.cbv1.FleetManagerDriverResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.FleetManagerDriverResponse;
  return proto.cbv1.FleetManagerDriverResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.FleetManagerDriverResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.FleetManagerDriverResponse}
 */
proto.cbv1.FleetManagerDriverResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.FleetManagerDriver;
      reader.readMessage(value,proto_cybertron_order_order_pb.FleetManagerDriver.deserializeBinaryFromReader);
      msg.addDrivers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.FleetManagerDriverResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.FleetManagerDriverResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.FleetManagerDriverResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FleetManagerDriverResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriversList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_order_order_pb.FleetManagerDriver.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FleetManagerDriver drivers = 1;
 * @return {!Array<!proto.cbv1.FleetManagerDriver>}
 */
proto.cbv1.FleetManagerDriverResponse.prototype.getDriversList = function() {
  return /** @type{!Array<!proto.cbv1.FleetManagerDriver>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_order_order_pb.FleetManagerDriver, 1));
};


/** @param {!Array<!proto.cbv1.FleetManagerDriver>} value */
proto.cbv1.FleetManagerDriverResponse.prototype.setDriversList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.FleetManagerDriver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.FleetManagerDriver}
 */
proto.cbv1.FleetManagerDriverResponse.prototype.addDrivers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.FleetManagerDriver, opt_index);
};


proto.cbv1.FleetManagerDriverResponse.prototype.clearDriversList = function() {
  this.setDriversList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DeleteAttachmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DeleteAttachmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DeleteAttachmentRequest.displayName = 'proto.cbv1.DeleteAttachmentRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DeleteAttachmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DeleteAttachmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DeleteAttachmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DeleteAttachmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DeleteAttachmentRequest}
 */
proto.cbv1.DeleteAttachmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DeleteAttachmentRequest;
  return proto.cbv1.DeleteAttachmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DeleteAttachmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DeleteAttachmentRequest}
 */
proto.cbv1.DeleteAttachmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DeleteAttachmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DeleteAttachmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DeleteAttachmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DeleteAttachmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.DeleteAttachmentRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.DeleteAttachmentRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DeleteAttachmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DeleteAttachmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DeleteAttachmentResponse.displayName = 'proto.cbv1.DeleteAttachmentResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DeleteAttachmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DeleteAttachmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DeleteAttachmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DeleteAttachmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DeleteAttachmentResponse}
 */
proto.cbv1.DeleteAttachmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DeleteAttachmentResponse;
  return proto.cbv1.DeleteAttachmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DeleteAttachmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DeleteAttachmentResponse}
 */
proto.cbv1.DeleteAttachmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DeleteAttachmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DeleteAttachmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DeleteAttachmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DeleteAttachmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.cbv1.DeleteAttachmentResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.DeleteAttachmentResponse.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.AttachmentUrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.AttachmentUrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.AttachmentUrlRequest.displayName = 'proto.cbv1.AttachmentUrlRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.AttachmentUrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.AttachmentUrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.AttachmentUrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AttachmentUrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.AttachmentUrlRequest}
 */
proto.cbv1.AttachmentUrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.AttachmentUrlRequest;
  return proto.cbv1.AttachmentUrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.AttachmentUrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.AttachmentUrlRequest}
 */
proto.cbv1.AttachmentUrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.AttachmentUrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.AttachmentUrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.AttachmentUrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AttachmentUrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.AttachmentUrlRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.AttachmentUrlRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.AttachmentUrlResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.AttachmentUrlResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.AttachmentUrlResponse.displayName = 'proto.cbv1.AttachmentUrlResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.AttachmentUrlResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.AttachmentUrlResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.AttachmentUrlResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AttachmentUrlResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.AttachmentUrlResponse}
 */
proto.cbv1.AttachmentUrlResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.AttachmentUrlResponse;
  return proto.cbv1.AttachmentUrlResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.AttachmentUrlResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.AttachmentUrlResponse}
 */
proto.cbv1.AttachmentUrlResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.AttachmentUrlResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.AttachmentUrlResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.AttachmentUrlResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AttachmentUrlResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.cbv1.AttachmentUrlResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.AttachmentUrlResponse.prototype.setUrl = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.StatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.StatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.StatsRequest.displayName = 'proto.cbv1.StatsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.StatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.StatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.StatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.StatsRequest}
 */
proto.cbv1.StatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.StatsRequest;
  return proto.cbv1.StatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.StatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.StatsRequest}
 */
proto.cbv1.StatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.StatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.StatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.StatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.StatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.StatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.StatsResponse.displayName = 'proto.cbv1.StatsResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.StatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.StatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.StatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stats: (f = msg.getStats()) && proto_cybertron_order_order_pb.DetentionStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.StatsResponse}
 */
proto.cbv1.StatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.StatsResponse;
  return proto.cbv1.StatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.StatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.StatsResponse}
 */
proto.cbv1.StatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.DetentionStats;
      reader.readMessage(value,proto_cybertron_order_order_pb.DetentionStats.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.StatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.StatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.StatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.StatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.DetentionStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional DetentionStats stats = 1;
 * @return {?proto.cbv1.DetentionStats}
 */
proto.cbv1.StatsResponse.prototype.getStats = function() {
  return /** @type{?proto.cbv1.DetentionStats} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.DetentionStats, 1));
};


/** @param {?proto.cbv1.DetentionStats|undefined} value */
proto.cbv1.StatsResponse.prototype.setStats = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.StatsResponse.prototype.clearStats = function() {
  this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.StatsResponse.prototype.hasStats = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOBulkCancelOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.LMOBulkCancelOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.LMOBulkCancelOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOBulkCancelOrderRequest.displayName = 'proto.cbv1.LMOBulkCancelOrderRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.LMOBulkCancelOrderRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOBulkCancelOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOBulkCancelOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOBulkCancelOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOBulkCancelOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderIdsList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOBulkCancelOrderRequest}
 */
proto.cbv1.LMOBulkCancelOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOBulkCancelOrderRequest;
  return proto.cbv1.LMOBulkCancelOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOBulkCancelOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOBulkCancelOrderRequest}
 */
proto.cbv1.LMOBulkCancelOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setOrderIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOBulkCancelOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOBulkCancelOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOBulkCancelOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOBulkCancelOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 order_ids = 1;
 * @return {!Array<number>}
 */
proto.cbv1.LMOBulkCancelOrderRequest.prototype.getOrderIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<number>} value */
proto.cbv1.LMOBulkCancelOrderRequest.prototype.setOrderIdsList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.LMOBulkCancelOrderRequest.prototype.addOrderIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv1.LMOBulkCancelOrderRequest.prototype.clearOrderIdsList = function() {
  this.setOrderIdsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOBulkCancelOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOBulkCancelOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOBulkCancelOrderResponse.displayName = 'proto.cbv1.LMOBulkCancelOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOBulkCancelOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOBulkCancelOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOBulkCancelOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOBulkCancelOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOBulkCancelOrderResponse}
 */
proto.cbv1.LMOBulkCancelOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOBulkCancelOrderResponse;
  return proto.cbv1.LMOBulkCancelOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOBulkCancelOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOBulkCancelOrderResponse}
 */
proto.cbv1.LMOBulkCancelOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOBulkCancelOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOBulkCancelOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOBulkCancelOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOBulkCancelOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOBulkSetAsScheduledOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.LMOBulkSetAsScheduledOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.displayName = 'proto.cbv1.LMOBulkSetAsScheduledOrdersRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOBulkSetAsScheduledOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderIdsList: jspb.Message.getRepeatedField(msg, 1),
    deliveryWindowStart: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deliveryWindowDurationMinutes: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOBulkSetAsScheduledOrdersRequest}
 */
proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOBulkSetAsScheduledOrdersRequest;
  return proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOBulkSetAsScheduledOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOBulkSetAsScheduledOrdersRequest}
 */
proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setOrderIdsList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeliveryWindowStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeliveryWindowDurationMinutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOBulkSetAsScheduledOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getDeliveryWindowStart();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDeliveryWindowDurationMinutes();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated int64 order_ids = 1;
 * @return {!Array<number>}
 */
proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.prototype.getOrderIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<number>} value */
proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.prototype.setOrderIdsList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.prototype.addOrderIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.prototype.clearOrderIdsList = function() {
  this.setOrderIdsList([]);
};


/**
 * optional int64 delivery_window_start = 2;
 * @return {number}
 */
proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.prototype.getDeliveryWindowStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.prototype.setDeliveryWindowStart = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 delivery_window_duration_minutes = 3;
 * @return {number}
 */
proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.prototype.getDeliveryWindowDurationMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.LMOBulkSetAsScheduledOrdersRequest.prototype.setDeliveryWindowDurationMinutes = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOBulkSetAsScheduledOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOBulkSetAsScheduledOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOBulkSetAsScheduledOrdersResponse.displayName = 'proto.cbv1.LMOBulkSetAsScheduledOrdersResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOBulkSetAsScheduledOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOBulkSetAsScheduledOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOBulkSetAsScheduledOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOBulkSetAsScheduledOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOBulkSetAsScheduledOrdersResponse}
 */
proto.cbv1.LMOBulkSetAsScheduledOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOBulkSetAsScheduledOrdersResponse;
  return proto.cbv1.LMOBulkSetAsScheduledOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOBulkSetAsScheduledOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOBulkSetAsScheduledOrdersResponse}
 */
proto.cbv1.LMOBulkSetAsScheduledOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOBulkSetAsScheduledOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOBulkSetAsScheduledOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOBulkSetAsScheduledOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOBulkSetAsScheduledOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOBulkSetAsASAPOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.LMOBulkSetAsASAPOrdersRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.LMOBulkSetAsASAPOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOBulkSetAsASAPOrdersRequest.displayName = 'proto.cbv1.LMOBulkSetAsASAPOrdersRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.LMOBulkSetAsASAPOrdersRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOBulkSetAsASAPOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOBulkSetAsASAPOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOBulkSetAsASAPOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOBulkSetAsASAPOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderIdsList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOBulkSetAsASAPOrdersRequest}
 */
proto.cbv1.LMOBulkSetAsASAPOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOBulkSetAsASAPOrdersRequest;
  return proto.cbv1.LMOBulkSetAsASAPOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOBulkSetAsASAPOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOBulkSetAsASAPOrdersRequest}
 */
proto.cbv1.LMOBulkSetAsASAPOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setOrderIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOBulkSetAsASAPOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOBulkSetAsASAPOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOBulkSetAsASAPOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOBulkSetAsASAPOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 order_ids = 1;
 * @return {!Array<number>}
 */
proto.cbv1.LMOBulkSetAsASAPOrdersRequest.prototype.getOrderIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<number>} value */
proto.cbv1.LMOBulkSetAsASAPOrdersRequest.prototype.setOrderIdsList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.LMOBulkSetAsASAPOrdersRequest.prototype.addOrderIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv1.LMOBulkSetAsASAPOrdersRequest.prototype.clearOrderIdsList = function() {
  this.setOrderIdsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOBulkSetAsASAPOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOBulkSetAsASAPOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOBulkSetAsASAPOrdersResponse.displayName = 'proto.cbv1.LMOBulkSetAsASAPOrdersResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOBulkSetAsASAPOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOBulkSetAsASAPOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOBulkSetAsASAPOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOBulkSetAsASAPOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOBulkSetAsASAPOrdersResponse}
 */
proto.cbv1.LMOBulkSetAsASAPOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOBulkSetAsASAPOrdersResponse;
  return proto.cbv1.LMOBulkSetAsASAPOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOBulkSetAsASAPOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOBulkSetAsASAPOrdersResponse}
 */
proto.cbv1.LMOBulkSetAsASAPOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOBulkSetAsASAPOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOBulkSetAsASAPOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOBulkSetAsASAPOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOBulkSetAsASAPOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOBulkMoveScheduleForOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.LMOBulkMoveScheduleForOrdersRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.LMOBulkMoveScheduleForOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOBulkMoveScheduleForOrdersRequest.displayName = 'proto.cbv1.LMOBulkMoveScheduleForOrdersRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.LMOBulkMoveScheduleForOrdersRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOBulkMoveScheduleForOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOBulkMoveScheduleForOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOBulkMoveScheduleForOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOBulkMoveScheduleForOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderIdsList: jspb.Message.getRepeatedField(msg, 1),
    minutesMoved: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOBulkMoveScheduleForOrdersRequest}
 */
proto.cbv1.LMOBulkMoveScheduleForOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOBulkMoveScheduleForOrdersRequest;
  return proto.cbv1.LMOBulkMoveScheduleForOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOBulkMoveScheduleForOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOBulkMoveScheduleForOrdersRequest}
 */
proto.cbv1.LMOBulkMoveScheduleForOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setOrderIdsList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinutesMoved(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOBulkMoveScheduleForOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOBulkMoveScheduleForOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOBulkMoveScheduleForOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOBulkMoveScheduleForOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getMinutesMoved();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated int64 order_ids = 1;
 * @return {!Array<number>}
 */
proto.cbv1.LMOBulkMoveScheduleForOrdersRequest.prototype.getOrderIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<number>} value */
proto.cbv1.LMOBulkMoveScheduleForOrdersRequest.prototype.setOrderIdsList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.LMOBulkMoveScheduleForOrdersRequest.prototype.addOrderIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv1.LMOBulkMoveScheduleForOrdersRequest.prototype.clearOrderIdsList = function() {
  this.setOrderIdsList([]);
};


/**
 * optional int64 minutes_moved = 2;
 * @return {number}
 */
proto.cbv1.LMOBulkMoveScheduleForOrdersRequest.prototype.getMinutesMoved = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.LMOBulkMoveScheduleForOrdersRequest.prototype.setMinutesMoved = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOBulkMoveScheduleForOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOBulkMoveScheduleForOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOBulkMoveScheduleForOrdersResponse.displayName = 'proto.cbv1.LMOBulkMoveScheduleForOrdersResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOBulkMoveScheduleForOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOBulkMoveScheduleForOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOBulkMoveScheduleForOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOBulkMoveScheduleForOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOBulkMoveScheduleForOrdersResponse}
 */
proto.cbv1.LMOBulkMoveScheduleForOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOBulkMoveScheduleForOrdersResponse;
  return proto.cbv1.LMOBulkMoveScheduleForOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOBulkMoveScheduleForOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOBulkMoveScheduleForOrdersResponse}
 */
proto.cbv1.LMOBulkMoveScheduleForOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOBulkMoveScheduleForOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOBulkMoveScheduleForOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOBulkMoveScheduleForOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOBulkMoveScheduleForOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DriverCanUploadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DriverCanUploadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DriverCanUploadRequest.displayName = 'proto.cbv1.DriverCanUploadRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DriverCanUploadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DriverCanUploadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DriverCanUploadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverCanUploadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DriverCanUploadRequest}
 */
proto.cbv1.DriverCanUploadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DriverCanUploadRequest;
  return proto.cbv1.DriverCanUploadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DriverCanUploadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DriverCanUploadRequest}
 */
proto.cbv1.DriverCanUploadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DriverCanUploadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DriverCanUploadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DriverCanUploadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverCanUploadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 order_id = 1;
 * @return {number}
 */
proto.cbv1.DriverCanUploadRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.DriverCanUploadRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DriverCanUploadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DriverCanUploadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DriverCanUploadResponse.displayName = 'proto.cbv1.DriverCanUploadResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DriverCanUploadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DriverCanUploadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DriverCanUploadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverCanUploadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    canUpload: jspb.Message.getFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DriverCanUploadResponse}
 */
proto.cbv1.DriverCanUploadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DriverCanUploadResponse;
  return proto.cbv1.DriverCanUploadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DriverCanUploadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DriverCanUploadResponse}
 */
proto.cbv1.DriverCanUploadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanUpload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DriverCanUploadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DriverCanUploadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DriverCanUploadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverCanUploadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanUpload();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool can_upload = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.DriverCanUploadResponse.prototype.getCanUpload = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.DriverCanUploadResponse.prototype.setCanUpload = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetVendorMappableOrderCompletedSlimRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetVendorMappableOrderCompletedSlimRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetVendorMappableOrderCompletedSlimRequest.displayName = 'proto.cbv1.GetVendorMappableOrderCompletedSlimRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetVendorMappableOrderCompletedSlimRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetVendorMappableOrderCompletedSlimRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetVendorMappableOrderCompletedSlimRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetVendorMappableOrderCompletedSlimRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetVendorMappableOrderCompletedSlimRequest}
 */
proto.cbv1.GetVendorMappableOrderCompletedSlimRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetVendorMappableOrderCompletedSlimRequest;
  return proto.cbv1.GetVendorMappableOrderCompletedSlimRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetVendorMappableOrderCompletedSlimRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetVendorMappableOrderCompletedSlimRequest}
 */
proto.cbv1.GetVendorMappableOrderCompletedSlimRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetVendorMappableOrderCompletedSlimRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetVendorMappableOrderCompletedSlimRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetVendorMappableOrderCompletedSlimRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetVendorMappableOrderCompletedSlimRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 site_id = 1;
 * @return {number}
 */
proto.cbv1.GetVendorMappableOrderCompletedSlimRequest.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.GetVendorMappableOrderCompletedSlimRequest.prototype.setSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetVendorMappableOrderCompletedSlimResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.GetVendorMappableOrderCompletedSlimResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.GetVendorMappableOrderCompletedSlimResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetVendorMappableOrderCompletedSlimResponse.displayName = 'proto.cbv1.GetVendorMappableOrderCompletedSlimResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.GetVendorMappableOrderCompletedSlimResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetVendorMappableOrderCompletedSlimResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetVendorMappableOrderCompletedSlimResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetVendorMappableOrderCompletedSlimResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetVendorMappableOrderCompletedSlimResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    completedOrdersList: jspb.Message.toObjectList(msg.getCompletedOrdersList(),
    proto_cybertron_order_order_pb.VendorMappableOrderCompletedSlim.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetVendorMappableOrderCompletedSlimResponse}
 */
proto.cbv1.GetVendorMappableOrderCompletedSlimResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetVendorMappableOrderCompletedSlimResponse;
  return proto.cbv1.GetVendorMappableOrderCompletedSlimResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetVendorMappableOrderCompletedSlimResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetVendorMappableOrderCompletedSlimResponse}
 */
proto.cbv1.GetVendorMappableOrderCompletedSlimResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.VendorMappableOrderCompletedSlim;
      reader.readMessage(value,proto_cybertron_order_order_pb.VendorMappableOrderCompletedSlim.deserializeBinaryFromReader);
      msg.addCompletedOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetVendorMappableOrderCompletedSlimResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetVendorMappableOrderCompletedSlimResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetVendorMappableOrderCompletedSlimResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetVendorMappableOrderCompletedSlimResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompletedOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_order_order_pb.VendorMappableOrderCompletedSlim.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VendorMappableOrderCompletedSlim completed_orders = 1;
 * @return {!Array<!proto.cbv1.VendorMappableOrderCompletedSlim>}
 */
proto.cbv1.GetVendorMappableOrderCompletedSlimResponse.prototype.getCompletedOrdersList = function() {
  return /** @type{!Array<!proto.cbv1.VendorMappableOrderCompletedSlim>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_order_order_pb.VendorMappableOrderCompletedSlim, 1));
};


/** @param {!Array<!proto.cbv1.VendorMappableOrderCompletedSlim>} value */
proto.cbv1.GetVendorMappableOrderCompletedSlimResponse.prototype.setCompletedOrdersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.VendorMappableOrderCompletedSlim=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.VendorMappableOrderCompletedSlim}
 */
proto.cbv1.GetVendorMappableOrderCompletedSlimResponse.prototype.addCompletedOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.VendorMappableOrderCompletedSlim, opt_index);
};


proto.cbv1.GetVendorMappableOrderCompletedSlimResponse.prototype.clearCompletedOrdersList = function() {
  this.setCompletedOrdersList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DriverUpdateLoadDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DriverUpdateLoadDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DriverUpdateLoadDetailsRequest.displayName = 'proto.cbv1.DriverUpdateLoadDetailsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DriverUpdateLoadDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DriverUpdateLoadDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverUpdateLoadDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    truckName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    trailerName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    ticketNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    actualQuantity: +jspb.Message.getFieldWithDefault(msg, 6, 0.0),
    bolNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    truckId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    trailerId: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DriverUpdateLoadDetailsRequest}
 */
proto.cbv1.DriverUpdateLoadDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DriverUpdateLoadDetailsRequest;
  return proto.cbv1.DriverUpdateLoadDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DriverUpdateLoadDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DriverUpdateLoadDetailsRequest}
 */
proto.cbv1.DriverUpdateLoadDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTruckName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setActualQuantity(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBolNumber(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTruckId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTrailerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DriverUpdateLoadDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DriverUpdateLoadDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverUpdateLoadDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTruckName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTrailerName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTicketNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActualQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getBolNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTruckId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getTrailerId();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string truck_name = 2;
 * @return {string}
 */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.getTruckName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.setTruckName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string trailer_name = 3;
 * @return {string}
 */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.getTrailerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.setTrailerName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string ticket_number = 4;
 * @return {string}
 */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.getTicketNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.setTicketNumber = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double actual_quantity = 6;
 * @return {number}
 */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.getActualQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 6, 0.0));
};


/** @param {number} value */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.setActualQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string bol_number = 7;
 * @return {string}
 */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.getBolNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.setBolNumber = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 truck_id = 8;
 * @return {number}
 */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.getTruckId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.setTruckId = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 trailer_id = 9;
 * @return {number}
 */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.getTrailerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cbv1.DriverUpdateLoadDetailsRequest.prototype.setTrailerId = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DriverUpdateLoadDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DriverUpdateLoadDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DriverUpdateLoadDetailsResponse.displayName = 'proto.cbv1.DriverUpdateLoadDetailsResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DriverUpdateLoadDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DriverUpdateLoadDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DriverUpdateLoadDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverUpdateLoadDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto_cybertron_order_order_pb.DriverOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DriverUpdateLoadDetailsResponse}
 */
proto.cbv1.DriverUpdateLoadDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DriverUpdateLoadDetailsResponse;
  return proto.cbv1.DriverUpdateLoadDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DriverUpdateLoadDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DriverUpdateLoadDetailsResponse}
 */
proto.cbv1.DriverUpdateLoadDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.DriverOrder;
      reader.readMessage(value,proto_cybertron_order_order_pb.DriverOrder.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DriverUpdateLoadDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DriverUpdateLoadDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DriverUpdateLoadDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverUpdateLoadDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.DriverOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional DriverOrder order = 1;
 * @return {?proto.cbv1.DriverOrder}
 */
proto.cbv1.DriverUpdateLoadDetailsResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.DriverOrder} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.DriverOrder, 1));
};


/** @param {?proto.cbv1.DriverOrder|undefined} value */
proto.cbv1.DriverUpdateLoadDetailsResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.DriverUpdateLoadDetailsResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.DriverUpdateLoadDetailsResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DispatcherCalendarOrderSummariesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DispatcherCalendarOrderSummariesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DispatcherCalendarOrderSummariesRequest.displayName = 'proto.cbv1.DispatcherCalendarOrderSummariesRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DispatcherCalendarOrderSummariesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DispatcherCalendarOrderSummariesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DispatcherCalendarOrderSummariesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DispatcherCalendarOrderSummariesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DispatcherCalendarOrderSummariesRequest}
 */
proto.cbv1.DispatcherCalendarOrderSummariesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DispatcherCalendarOrderSummariesRequest;
  return proto.cbv1.DispatcherCalendarOrderSummariesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DispatcherCalendarOrderSummariesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DispatcherCalendarOrderSummariesRequest}
 */
proto.cbv1.DispatcherCalendarOrderSummariesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DispatcherCalendarOrderSummariesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DispatcherCalendarOrderSummariesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DispatcherCalendarOrderSummariesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DispatcherCalendarOrderSummariesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DispatcherCalendarOrderSummariesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.DispatcherCalendarOrderSummariesResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.DispatcherCalendarOrderSummariesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DispatcherCalendarOrderSummariesResponse.displayName = 'proto.cbv1.DispatcherCalendarOrderSummariesResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.DispatcherCalendarOrderSummariesResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DispatcherCalendarOrderSummariesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DispatcherCalendarOrderSummariesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DispatcherCalendarOrderSummariesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DispatcherCalendarOrderSummariesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pendingInProgressOrdersList: jspb.Message.toObjectList(msg.getPendingInProgressOrdersList(),
    proto_cybertron_order_order_pb.DispatcherCalendarOrderSummary.toObject, includeInstance),
    inProgressOrdersList: jspb.Message.toObjectList(msg.getInProgressOrdersList(),
    proto_cybertron_order_order_pb.DispatcherCalendarInProgressOrderSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DispatcherCalendarOrderSummariesResponse}
 */
proto.cbv1.DispatcherCalendarOrderSummariesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DispatcherCalendarOrderSummariesResponse;
  return proto.cbv1.DispatcherCalendarOrderSummariesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DispatcherCalendarOrderSummariesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DispatcherCalendarOrderSummariesResponse}
 */
proto.cbv1.DispatcherCalendarOrderSummariesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.DispatcherCalendarOrderSummary;
      reader.readMessage(value,proto_cybertron_order_order_pb.DispatcherCalendarOrderSummary.deserializeBinaryFromReader);
      msg.addPendingInProgressOrders(value);
      break;
    case 2:
      var value = new proto_cybertron_order_order_pb.DispatcherCalendarInProgressOrderSummary;
      reader.readMessage(value,proto_cybertron_order_order_pb.DispatcherCalendarInProgressOrderSummary.deserializeBinaryFromReader);
      msg.addInProgressOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DispatcherCalendarOrderSummariesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DispatcherCalendarOrderSummariesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DispatcherCalendarOrderSummariesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DispatcherCalendarOrderSummariesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPendingInProgressOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_order_order_pb.DispatcherCalendarOrderSummary.serializeBinaryToWriter
    );
  }
  f = message.getInProgressOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_cybertron_order_order_pb.DispatcherCalendarInProgressOrderSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DispatcherCalendarOrderSummary pending_in_progress_orders = 1;
 * @return {!Array<!proto.cbv1.DispatcherCalendarOrderSummary>}
 */
proto.cbv1.DispatcherCalendarOrderSummariesResponse.prototype.getPendingInProgressOrdersList = function() {
  return /** @type{!Array<!proto.cbv1.DispatcherCalendarOrderSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_order_order_pb.DispatcherCalendarOrderSummary, 1));
};


/** @param {!Array<!proto.cbv1.DispatcherCalendarOrderSummary>} value */
proto.cbv1.DispatcherCalendarOrderSummariesResponse.prototype.setPendingInProgressOrdersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.DispatcherCalendarOrderSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.DispatcherCalendarOrderSummary}
 */
proto.cbv1.DispatcherCalendarOrderSummariesResponse.prototype.addPendingInProgressOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.DispatcherCalendarOrderSummary, opt_index);
};


proto.cbv1.DispatcherCalendarOrderSummariesResponse.prototype.clearPendingInProgressOrdersList = function() {
  this.setPendingInProgressOrdersList([]);
};


/**
 * repeated DispatcherCalendarInProgressOrderSummary in_progress_orders = 2;
 * @return {!Array<!proto.cbv1.DispatcherCalendarInProgressOrderSummary>}
 */
proto.cbv1.DispatcherCalendarOrderSummariesResponse.prototype.getInProgressOrdersList = function() {
  return /** @type{!Array<!proto.cbv1.DispatcherCalendarInProgressOrderSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_order_order_pb.DispatcherCalendarInProgressOrderSummary, 2));
};


/** @param {!Array<!proto.cbv1.DispatcherCalendarInProgressOrderSummary>} value */
proto.cbv1.DispatcherCalendarOrderSummariesResponse.prototype.setInProgressOrdersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cbv1.DispatcherCalendarInProgressOrderSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.DispatcherCalendarInProgressOrderSummary}
 */
proto.cbv1.DispatcherCalendarOrderSummariesResponse.prototype.addInProgressOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cbv1.DispatcherCalendarInProgressOrderSummary, opt_index);
};


proto.cbv1.DispatcherCalendarOrderSummariesResponse.prototype.clearInProgressOrdersList = function() {
  this.setInProgressOrdersList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DispatcherUpdateTaskSiteSingle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DispatcherUpdateTaskSiteSingle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DispatcherUpdateTaskSiteSingle.displayName = 'proto.cbv1.DispatcherUpdateTaskSiteSingle';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DispatcherUpdateTaskSiteSingle.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DispatcherUpdateTaskSiteSingle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DispatcherUpdateTaskSiteSingle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DispatcherUpdateTaskSiteSingle.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    newSiteId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DispatcherUpdateTaskSiteSingle}
 */
proto.cbv1.DispatcherUpdateTaskSiteSingle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DispatcherUpdateTaskSiteSingle;
  return proto.cbv1.DispatcherUpdateTaskSiteSingle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DispatcherUpdateTaskSiteSingle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DispatcherUpdateTaskSiteSingle}
 */
proto.cbv1.DispatcherUpdateTaskSiteSingle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNewSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DispatcherUpdateTaskSiteSingle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DispatcherUpdateTaskSiteSingle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DispatcherUpdateTaskSiteSingle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DispatcherUpdateTaskSiteSingle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNewSiteId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 task_id = 1;
 * @return {number}
 */
proto.cbv1.DispatcherUpdateTaskSiteSingle.prototype.getTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherUpdateTaskSiteSingle.prototype.setTaskId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 new_site_id = 2;
 * @return {number}
 */
proto.cbv1.DispatcherUpdateTaskSiteSingle.prototype.getNewSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherUpdateTaskSiteSingle.prototype.setNewSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetSubTaskTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetSubTaskTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetSubTaskTypesRequest.displayName = 'proto.cbv1.GetSubTaskTypesRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetSubTaskTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetSubTaskTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetSubTaskTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetSubTaskTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetSubTaskTypesRequest}
 */
proto.cbv1.GetSubTaskTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetSubTaskTypesRequest;
  return proto.cbv1.GetSubTaskTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetSubTaskTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetSubTaskTypesRequest}
 */
proto.cbv1.GetSubTaskTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetSubTaskTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetSubTaskTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetSubTaskTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetSubTaskTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetSubTaskTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.GetSubTaskTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.GetSubTaskTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetSubTaskTypesResponse.displayName = 'proto.cbv1.GetSubTaskTypesResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.GetSubTaskTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetSubTaskTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetSubTaskTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetSubTaskTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetSubTaskTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    subtaskTypesList: jspb.Message.toObjectList(msg.getSubtaskTypesList(),
    proto_cybertron_order_order_pb.SubTaskType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetSubTaskTypesResponse}
 */
proto.cbv1.GetSubTaskTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetSubTaskTypesResponse;
  return proto.cbv1.GetSubTaskTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetSubTaskTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetSubTaskTypesResponse}
 */
proto.cbv1.GetSubTaskTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.SubTaskType;
      reader.readMessage(value,proto_cybertron_order_order_pb.SubTaskType.deserializeBinaryFromReader);
      msg.addSubtaskTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetSubTaskTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetSubTaskTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetSubTaskTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetSubTaskTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubtaskTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_order_order_pb.SubTaskType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SubTaskType subtask_types = 1;
 * @return {!Array<!proto.cbv1.SubTaskType>}
 */
proto.cbv1.GetSubTaskTypesResponse.prototype.getSubtaskTypesList = function() {
  return /** @type{!Array<!proto.cbv1.SubTaskType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_order_order_pb.SubTaskType, 1));
};


/** @param {!Array<!proto.cbv1.SubTaskType>} value */
proto.cbv1.GetSubTaskTypesResponse.prototype.setSubtaskTypesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.SubTaskType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.SubTaskType}
 */
proto.cbv1.GetSubTaskTypesResponse.prototype.addSubtaskTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.SubTaskType, opt_index);
};


proto.cbv1.GetSubTaskTypesResponse.prototype.clearSubtaskTypesList = function() {
  this.setSubtaskTypesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListCostCenterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ListCostCenterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListCostCenterRequest.displayName = 'proto.cbv1.ListCostCenterRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListCostCenterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListCostCenterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListCostCenterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListCostCenterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchTerm: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessLineId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListCostCenterRequest}
 */
proto.cbv1.ListCostCenterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListCostCenterRequest;
  return proto.cbv1.ListCostCenterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListCostCenterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListCostCenterRequest}
 */
proto.cbv1.ListCostCenterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchTerm(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBusinessLineId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListCostCenterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListCostCenterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListCostCenterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListCostCenterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchTerm();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessLineId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string search_term = 1;
 * @return {string}
 */
proto.cbv1.ListCostCenterRequest.prototype.getSearchTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.ListCostCenterRequest.prototype.setSearchTerm = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 business_line_id = 2;
 * @return {number}
 */
proto.cbv1.ListCostCenterRequest.prototype.getBusinessLineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.ListCostCenterRequest.prototype.setBusinessLineId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListCostCenterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListCostCenterResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListCostCenterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListCostCenterResponse.displayName = 'proto.cbv1.ListCostCenterResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListCostCenterResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListCostCenterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListCostCenterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListCostCenterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListCostCenterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    costCentersList: jspb.Message.toObjectList(msg.getCostCentersList(),
    proto_cybertron_order_order_pb.CostCenter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListCostCenterResponse}
 */
proto.cbv1.ListCostCenterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListCostCenterResponse;
  return proto.cbv1.ListCostCenterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListCostCenterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListCostCenterResponse}
 */
proto.cbv1.ListCostCenterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.CostCenter;
      reader.readMessage(value,proto_cybertron_order_order_pb.CostCenter.deserializeBinaryFromReader);
      msg.addCostCenters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListCostCenterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListCostCenterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListCostCenterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListCostCenterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostCentersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_order_order_pb.CostCenter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CostCenter cost_centers = 1;
 * @return {!Array<!proto.cbv1.CostCenter>}
 */
proto.cbv1.ListCostCenterResponse.prototype.getCostCentersList = function() {
  return /** @type{!Array<!proto.cbv1.CostCenter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_order_order_pb.CostCenter, 1));
};


/** @param {!Array<!proto.cbv1.CostCenter>} value */
proto.cbv1.ListCostCenterResponse.prototype.setCostCentersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.CostCenter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.CostCenter}
 */
proto.cbv1.ListCostCenterResponse.prototype.addCostCenters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.CostCenter, opt_index);
};


proto.cbv1.ListCostCenterResponse.prototype.clearCostCentersList = function() {
  this.setCostCentersList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateCostCenterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.CreateCostCenterRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.CreateCostCenterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateCostCenterRequest.displayName = 'proto.cbv1.CreateCostCenterRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.CreateCostCenterRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateCostCenterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateCostCenterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateCostCenterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateCostCenterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessLineIdsList: jspb.Message.getRepeatedField(msg, 2)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateCostCenterRequest}
 */
proto.cbv1.CreateCostCenterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateCostCenterRequest;
  return proto.cbv1.CreateCostCenterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateCostCenterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateCostCenterRequest}
 */
proto.cbv1.CreateCostCenterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setBusinessLineIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateCostCenterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateCostCenterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateCostCenterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateCostCenterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessLineIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.cbv1.CreateCostCenterRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.CreateCostCenterRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int32 business_line_ids = 2;
 * @return {!Array<number>}
 */
proto.cbv1.CreateCostCenterRequest.prototype.getBusinessLineIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<number>} value */
proto.cbv1.CreateCostCenterRequest.prototype.setBusinessLineIdsList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.CreateCostCenterRequest.prototype.addBusinessLineIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.cbv1.CreateCostCenterRequest.prototype.clearBusinessLineIdsList = function() {
  this.setBusinessLineIdsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateCostCenterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateCostCenterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateCostCenterResponse.displayName = 'proto.cbv1.CreateCostCenterResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateCostCenterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateCostCenterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateCostCenterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateCostCenterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    costCenter: (f = msg.getCostCenter()) && proto_cybertron_order_order_pb.CostCenter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateCostCenterResponse}
 */
proto.cbv1.CreateCostCenterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateCostCenterResponse;
  return proto.cbv1.CreateCostCenterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateCostCenterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateCostCenterResponse}
 */
proto.cbv1.CreateCostCenterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.CostCenter;
      reader.readMessage(value,proto_cybertron_order_order_pb.CostCenter.deserializeBinaryFromReader);
      msg.setCostCenter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateCostCenterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateCostCenterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateCostCenterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateCostCenterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostCenter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.CostCenter.serializeBinaryToWriter
    );
  }
};


/**
 * optional CostCenter cost_center = 1;
 * @return {?proto.cbv1.CostCenter}
 */
proto.cbv1.CreateCostCenterResponse.prototype.getCostCenter = function() {
  return /** @type{?proto.cbv1.CostCenter} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.CostCenter, 1));
};


/** @param {?proto.cbv1.CostCenter|undefined} value */
proto.cbv1.CreateCostCenterResponse.prototype.setCostCenter = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.CreateCostCenterResponse.prototype.clearCostCenter = function() {
  this.setCostCenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CreateCostCenterResponse.prototype.hasCostCenter = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ArchiveCostCenterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ArchiveCostCenterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ArchiveCostCenterRequest.displayName = 'proto.cbv1.ArchiveCostCenterRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ArchiveCostCenterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ArchiveCostCenterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ArchiveCostCenterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ArchiveCostCenterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ArchiveCostCenterRequest}
 */
proto.cbv1.ArchiveCostCenterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ArchiveCostCenterRequest;
  return proto.cbv1.ArchiveCostCenterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ArchiveCostCenterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ArchiveCostCenterRequest}
 */
proto.cbv1.ArchiveCostCenterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ArchiveCostCenterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ArchiveCostCenterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ArchiveCostCenterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ArchiveCostCenterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.ArchiveCostCenterRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.ArchiveCostCenterRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ArchiveCostCenterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ArchiveCostCenterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ArchiveCostCenterResponse.displayName = 'proto.cbv1.ArchiveCostCenterResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ArchiveCostCenterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ArchiveCostCenterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ArchiveCostCenterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ArchiveCostCenterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, false),
    reason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ArchiveCostCenterResponse}
 */
proto.cbv1.ArchiveCostCenterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ArchiveCostCenterResponse;
  return proto.cbv1.ArchiveCostCenterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ArchiveCostCenterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ArchiveCostCenterResponse}
 */
proto.cbv1.ArchiveCostCenterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ArchiveCostCenterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ArchiveCostCenterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ArchiveCostCenterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ArchiveCostCenterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.ArchiveCostCenterResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.ArchiveCostCenterResponse.prototype.setStatus = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.cbv1.ArchiveCostCenterResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.ArchiveCostCenterResponse.prototype.setReason = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateCostCenterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.UpdateCostCenterRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.UpdateCostCenterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateCostCenterRequest.displayName = 'proto.cbv1.UpdateCostCenterRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.UpdateCostCenterRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateCostCenterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateCostCenterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateCostCenterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateCostCenterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessLineIdsList: jspb.Message.getRepeatedField(msg, 3)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateCostCenterRequest}
 */
proto.cbv1.UpdateCostCenterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateCostCenterRequest;
  return proto.cbv1.UpdateCostCenterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateCostCenterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateCostCenterRequest}
 */
proto.cbv1.UpdateCostCenterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setBusinessLineIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateCostCenterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateCostCenterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateCostCenterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateCostCenterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessLineIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
};


/**
 * optional int64 ID = 1;
 * @return {number}
 */
proto.cbv1.UpdateCostCenterRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.UpdateCostCenterRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.UpdateCostCenterRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.UpdateCostCenterRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 business_line_ids = 3;
 * @return {!Array<number>}
 */
proto.cbv1.UpdateCostCenterRequest.prototype.getBusinessLineIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/** @param {!Array<number>} value */
proto.cbv1.UpdateCostCenterRequest.prototype.setBusinessLineIdsList = function(value) {
  jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.UpdateCostCenterRequest.prototype.addBusinessLineIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


proto.cbv1.UpdateCostCenterRequest.prototype.clearBusinessLineIdsList = function() {
  this.setBusinessLineIdsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateCostCenterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UpdateCostCenterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateCostCenterResponse.displayName = 'proto.cbv1.UpdateCostCenterResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateCostCenterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateCostCenterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateCostCenterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateCostCenterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    costCenter: (f = msg.getCostCenter()) && proto_cybertron_order_order_pb.CostCenter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateCostCenterResponse}
 */
proto.cbv1.UpdateCostCenterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateCostCenterResponse;
  return proto.cbv1.UpdateCostCenterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateCostCenterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateCostCenterResponse}
 */
proto.cbv1.UpdateCostCenterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_order_order_pb.CostCenter;
      reader.readMessage(value,proto_cybertron_order_order_pb.CostCenter.deserializeBinaryFromReader);
      msg.setCostCenter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateCostCenterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateCostCenterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateCostCenterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateCostCenterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostCenter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_order_order_pb.CostCenter.serializeBinaryToWriter
    );
  }
};


/**
 * optional CostCenter cost_center = 1;
 * @return {?proto.cbv1.CostCenter}
 */
proto.cbv1.UpdateCostCenterResponse.prototype.getCostCenter = function() {
  return /** @type{?proto.cbv1.CostCenter} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_order_order_pb.CostCenter, 1));
};


/** @param {?proto.cbv1.CostCenter|undefined} value */
proto.cbv1.UpdateCostCenterResponse.prototype.setCostCenter = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.UpdateCostCenterResponse.prototype.clearCostCenter = function() {
  this.setCostCenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.UpdateCostCenterResponse.prototype.hasCostCenter = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.cbv1);
