import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, map, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { SelectPayloadQuantityComponent } from '~common/select-payload-quantity/select-payload-quantity.component';
import { SelectPayloadTypeComponent } from '~common/select-payload-type/select-payload-type.component';
import { SelectPayloadUnitsComponent } from '~common/select-payload-units/select-payload-units.component';
import { CreatePayloadRequest } from '~proto/payload/payload_api_pb';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { trackById } from '~utilities/trackById';
import { CreateOrderPayloadSelectorConfirmTrackingComponent } from '../create-order-payload-selector-confirm-tracking/create-order-payload-selector-confirm-tracking.component';
var CreateOrderPayloadSelectorComponent = /** @class */ (function () {
    function CreateOrderPayloadSelectorComponent(payloadService, matDialog, snackBar) {
        this.payloadService = payloadService;
        this.matDialog = matDialog;
        this.snackBar = snackBar;
        this.pinnedAndRecentPayloads$$ = new BehaviorSubject([]);
        this.selected = new EventEmitter();
        this.invalid = false;
        this.tab = '';
        this.openAutomatically = false;
        this.quantityPrompt = true;
        this.allowPinning = false;
        this.allowedPayloadTypes = [];
        this.trackById = trackById;
        this.textChanges$$ = new BehaviorSubject(null);
    }
    Object.defineProperty(CreateOrderPayloadSelectorComponent.prototype, "payloadGroups", {
        set: function (groups) {
            this.pinnedAndRecentPayloads$$.next(toPinnedAndOtherGroups(groups));
        },
        enumerable: true,
        configurable: true
    });
    CreateOrderPayloadSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searching$ = this.payloadService.searching$;
        var searchResults$ = this.textChanges$$.pipe(debounceTime(200), switchMap(function (searchText) {
            if (searchText && searchText.length >= 2) {
                return _this.payloadService.searchPayloads$(searchText);
            }
            return of([]);
        }), withLatestFrom(this.pinnedAndRecentPayloads$$), map(function (_a) {
            var _b = tslib_1.__read(_a, 2), searchResults = _b[0], payloadGroups = _b[1];
            if (!payloadGroups) {
                return searchResults;
            }
            var payloads = payloadGroups.reduce(function (arr, group) { return tslib_1.__spread(arr, group.payloadsList); }, []);
            var payloadMap = idArrayToRecord(payloads);
            return searchResults.filter(function (payload) {
                var passed = true;
                if (_this.allowedPayloadTypes.length > 0) {
                    passed = passed && _this.allowedPayloadTypes.includes(payload.type.id);
                }
                return passed && !payloadMap[payload.id];
            });
        }));
        this.currentSitesPayload$ = combineLatest([
            this.pinnedAndRecentPayloads$$,
            this.textChanges$$.pipe(debounceTime(100), startWith('')),
            searchResults$,
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 3), items = _b[0], text = _b[1], searchResults = _b[2];
            if (!items) {
                return [];
            }
            if (!text || text === '') {
                var filteredItems = items.filter(function (item) { return item.payloadsList.length > 0; });
                _this.topResult =
                    filteredItems.length && filteredItems[0].payloadsList.length ? filteredItems[0].payloadsList[0] : null;
                return filteredItems;
            }
            var cleanedItems = items.map(function (item) { return (tslib_1.__assign({}, item, { payloadsList: item.payloadsList.filter(function (entry) {
                    return entry.name.toLocaleLowerCase().includes(text.toLocaleLowerCase());
                }) })); });
            var mergedGroups = tslib_1.__spread([
                {
                    name: 'search results',
                    payloadsList: searchResults,
                }
            ], cleanedItems);
            var filtered = mergedGroups.filter(function (item) { return item.payloadsList.length > 0; });
            if (filtered.length && filtered[0].payloadsList.length) {
                _this.topResult = filtered[0].payloadsList[0];
            }
            else {
                _this.topResult = null;
            }
            return filtered;
        }));
        this.canAddNew$ = combineLatest([this.textChanges$$.pipe(startWith('')), this.pinnedAndRecentPayloads$$]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), textChange = _b[0], groups = _b[1];
            if (!textChange || textChange === '') {
                return false;
            }
            var asLower = textChange.toLocaleLowerCase();
            return groups.every(function (group) {
                return group.payloadsList.every(function (payload) { return payload.name.toLocaleLowerCase() !== asLower; });
            });
        }));
    };
    CreateOrderPayloadSelectorComponent.prototype.inputTextChange = function (event) {
        this.textChanges$$.next(event.trim());
    };
    CreateOrderPayloadSelectorComponent.prototype.togglePin = function (event, payloadId, isFavorited) {
        event.stopPropagation();
        this.payloadService.updatePayloadFavorites(payloadId, isFavorited);
    };
    CreateOrderPayloadSelectorComponent.prototype.selectPayload = function (payload, isNew) {
        var _this = this;
        if (isNew === void 0) { isNew = false; }
        if (this.quantityPrompt) {
            var dialog = this.matDialog.open(SelectPayloadQuantityComponent);
            dialog.componentInstance.payload = payload;
            dialog.afterClosed().subscribe(function (quantity) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var trackPayloadOnSite;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!isNew) return [3 /*break*/, 2];
                            trackPayloadOnSite = this.matDialog.open(CreateOrderPayloadSelectorConfirmTrackingComponent);
                            trackPayloadOnSite.componentInstance.payload = payload;
                            return [4 /*yield*/, trackPayloadOnSite.afterClosed().toPromise()];
                        case 1:
                            _a.sent();
                            this.showCreatedSnackbar(payload);
                            _a.label = 2;
                        case 2:
                            this.selected.emit({ payload: payload, quantity: quantity });
                            this.smartDropdown.close();
                            return [2 /*return*/];
                    }
                });
            }); });
        }
        else {
            if (isNew) {
                this.showCreatedSnackbar(payload);
            }
            this.selected.emit({ payload: payload, quantity: 1 });
            this.smartDropdown.close();
        }
    };
    CreateOrderPayloadSelectorComponent.prototype.createNewPayload = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var createPayloadRequest, unitsDialog, unit, payloadTypeDialog, payloadType;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        createPayloadRequest = new CreatePayloadRequest();
                        createPayloadRequest.setName(this.textChanges$$.value);
                        unitsDialog = this.matDialog.open(SelectPayloadUnitsComponent);
                        unitsDialog.componentInstance.payloadName = createPayloadRequest.getName();
                        return [4 /*yield*/, unitsDialog.afterClosed().toPromise()];
                    case 1:
                        unit = _a.sent();
                        if (!unit) {
                            return [2 /*return*/];
                        }
                        createPayloadRequest.setUnitId(unit.id);
                        payloadTypeDialog = this.matDialog.open(SelectPayloadTypeComponent);
                        payloadTypeDialog.componentInstance.payloadName = createPayloadRequest.getName();
                        payloadTypeDialog.componentInstance.allowedPayloadTypes = this.allowedPayloadTypes;
                        return [4 /*yield*/, payloadTypeDialog.afterClosed().toPromise()];
                    case 2:
                        payloadType = _a.sent();
                        if (!payloadType) {
                            return [2 /*return*/];
                        }
                        createPayloadRequest.setPayloadTypeId(payloadType.id);
                        this.payloadService.createPayload$(createPayloadRequest).subscribe(function (payload) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                if (!payload) {
                                    return [2 /*return*/];
                                }
                                this.selectPayload(payload, true);
                                return [2 /*return*/];
                            });
                        }); });
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateOrderPayloadSelectorComponent.prototype.enterKeyPressed = function () {
        if (this.topResult) {
            this.selectPayload(this.topResult);
        }
        else {
            this.createNewPayload();
        }
    };
    CreateOrderPayloadSelectorComponent.prototype.showCreatedSnackbar = function (payload) {
        var _this = this;
        var titleString = payload.name + " has been created!";
        var actionString = "Add Payload Details";
        var snack = this.snackBar.open(titleString, actionString, { duration: 10000 });
        snack.onAction().subscribe(function () {
            var link = "/lmo/payloads/" + _this.payload.id + "/edit";
            window.open(link, '_blank');
        });
    };
    return CreateOrderPayloadSelectorComponent;
}());
export { CreateOrderPayloadSelectorComponent };
function toPinnedAndOtherGroups(payloadGroups) {
    if (payloadGroups === void 0) { payloadGroups = []; }
    if (payloadGroups === null || payloadGroups.length === 0) {
        return [];
    }
    var allPayloads = payloadGroups.reduce(function (arr, group) { return tslib_1.__spread(arr, group.payloadsList); }, []);
    var pinned = [];
    var recent = [];
    allPayloads.forEach(function (payload) {
        if (payload.favorited) {
            pinned.push(payload);
        }
        else {
            recent.push(payload);
        }
    });
    return [
        {
            name: 'Pinned',
            payloadsList: pinned,
        },
        {
            name: 'Recent',
            payloadsList: recent,
        },
    ];
}
