/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_cybertron_user_user_pb = require('../../../proto/cybertron/user/user_pb.js');
var proto_cybertron_site_site_pb = require('../../../proto/cybertron/site/site_pb.js');
var proto_cybertron_account_account_pb = require('../../../proto/cybertron/account/account_pb.js');
var proto_cybertron_payload_payload_pb = require('../../../proto/cybertron/payload/payload_pb.js');
var proto_cybertron_truck_truck_pb = require('../../../proto/cybertron/truck/truck_pb.js');
var proto_cybertron_businessline_businessline_pb = require('../../../proto/cybertron/businessline/businessline_pb.js');
var proto_cybertron_trailer_trailer_pb = require('../../../proto/cybertron/trailer/trailer_pb.js');
var proto_geo_geo_pb = require('../../../proto/geo/geo_pb.js');
var proto_cybertron_types_types_pb = require('../../../proto/cybertron/types/types_pb.js');
var proto_cybertron_fields_fields_pb = require('../../../proto/cybertron/fields/fields_pb.js');
var proto_cybertron_drivercertification_driverCertification_pb = require('../../../proto/cybertron/drivercertification/driverCertification_pb.js');
var proto_cybertron_contracts_contracts_pb = require('../../../proto/cybertron/contracts/contracts_pb.js');
goog.exportSymbol('proto.cbv1.Asset', null, global);
goog.exportSymbol('proto.cbv1.AssetGroup', null, global);
goog.exportSymbol('proto.cbv1.AssetType', null, global);
goog.exportSymbol('proto.cbv1.Billing', null, global);
goog.exportSymbol('proto.cbv1.CostCenter', null, global);
goog.exportSymbol('proto.cbv1.CostCenterBusinessLine', null, global);
goog.exportSymbol('proto.cbv1.DetentionStats', null, global);
goog.exportSymbol('proto.cbv1.DispatcherCalendarInProgressOrderSummary', null, global);
goog.exportSymbol('proto.cbv1.DispatcherCalendarInProgressTaskSummary', null, global);
goog.exportSymbol('proto.cbv1.DispatcherCalendarOrderSummary', null, global);
goog.exportSymbol('proto.cbv1.DispatcherCalendarPendingTaskSummary', null, global);
goog.exportSymbol('proto.cbv1.DispatcherCalendarSubTaskSummary', null, global);
goog.exportSymbol('proto.cbv1.DriverAttachment', null, global);
goog.exportSymbol('proto.cbv1.DriverOrder', null, global);
goog.exportSymbol('proto.cbv1.Event', null, global);
goog.exportSymbol('proto.cbv1.FleetManagerDriver', null, global);
goog.exportSymbol('proto.cbv1.FleetManagerDriverScheduleOrder', null, global);
goog.exportSymbol('proto.cbv1.FleetManagerPlan', null, global);
goog.exportSymbol('proto.cbv1.MappableOrder', null, global);
goog.exportSymbol('proto.cbv1.MappableOrderCompleted', null, global);
goog.exportSymbol('proto.cbv1.MappableOrderEvent', null, global);
goog.exportSymbol('proto.cbv1.MinutesStats', null, global);
goog.exportSymbol('proto.cbv1.Order', null, global);
goog.exportSymbol('proto.cbv1.OrderAttachment', null, global);
goog.exportSymbol('proto.cbv1.OrderStats', null, global);
goog.exportSymbol('proto.cbv1.OrderUploadState', null, global);
goog.exportSymbol('proto.cbv1.SiteOrderETAs', null, global);
goog.exportSymbol('proto.cbv1.SubTask', null, global);
goog.exportSymbol('proto.cbv1.SubTaskBilling', null, global);
goog.exportSymbol('proto.cbv1.SubTaskType', null, global);
goog.exportSymbol('proto.cbv1.Task', null, global);
goog.exportSymbol('proto.cbv1.TaskStats', null, global);
goog.exportSymbol('proto.cbv1.TaskStatusHistory', null, global);
goog.exportSymbol('proto.cbv1.TaskSummary', null, global);
goog.exportSymbol('proto.cbv1.VendorMappableOrderCompletedSlim', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.Order = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.Order.repeatedFields_, null);
};
goog.inherits(proto.cbv1.Order, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.Order.displayName = 'proto.cbv1.Order';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.Order.repeatedFields_ = [4,21,23,28];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.Order.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.Order.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.Order} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Order.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lmo: (f = msg.getLmo()) && proto_cybertron_account_account_pb.Account.toObject(includeInstance, f),
    truckingVendor: (f = msg.getTruckingVendor()) && proto_cybertron_account_account_pb.Account.toObject(includeInstance, f),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.cbv1.Task.toObject, includeInstance),
    driver: (f = msg.getDriver()) && proto_cybertron_user_user_pb.Driver.toObject(includeInstance, f),
    truck: (f = msg.getTruck()) && proto_cybertron_truck_truck_pb.Truck.toObject(includeInstance, f),
    trailer: (f = msg.getTrailer()) && proto_cybertron_trailer_trailer_pb.Trailer.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 8, ""),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    statusUnix: jspb.Message.getFieldWithDefault(msg, 10, 0),
    scheduledUnix: jspb.Message.getFieldWithDefault(msg, 11, 0),
    createdUnix: jspb.Message.getFieldWithDefault(msg, 12, 0),
    updatedUnix: jspb.Message.getFieldWithDefault(msg, 13, 0),
    salesOrderNumber: jspb.Message.getFieldWithDefault(msg, 14, ""),
    orderStats: (f = msg.getOrderStats()) && proto.cbv1.OrderStats.toObject(includeInstance, f),
    isAsap: jspb.Message.getFieldWithDefault(msg, 17, false),
    toGoUpForGrabsAt: jspb.Message.getFieldWithDefault(msg, 18, 0),
    deliveryWindowStart: jspb.Message.getFieldWithDefault(msg, 19, 0),
    deliveryWindowDurationMinutes: jspb.Message.getFieldWithDefault(msg, 20, 0),
    customFieldsList: jspb.Message.toObjectList(msg.getCustomFieldsList(),
    proto_cybertron_fields_fields_pb.CustomField.toObject, includeInstance),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    proto.cbv1.OrderAttachment.toObject, includeInstance),
    canBeDispatchedUnix: jspb.Message.getFieldWithDefault(msg, 24, 0),
    saUniqueId: jspb.Message.getFieldWithDefault(msg, 25, ""),
    fleetManagerPlan: (f = msg.getFleetManagerPlan()) && proto.cbv1.FleetManagerPlan.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 27, 0),
    driverCertificationsList: jspb.Message.toObjectList(msg.getDriverCertificationsList(),
    proto_cybertron_drivercertification_driverCertification_pb.DriverCertification.toObject, includeInstance),
    ticketNumber: jspb.Message.getFieldWithDefault(msg, 29, ""),
    costCenter: jspb.Message.getFieldWithDefault(msg, 30, ""),
    vendorContract: (f = msg.getVendorContract()) && proto_cybertron_contracts_contracts_pb.VendorContract.toObject(includeInstance, f),
    contractType: (f = msg.getContractType()) && proto_cybertron_contracts_contracts_pb.VendorContractType.toObject(includeInstance, f),
    costCenterObj: (f = msg.getCostCenterObj()) && proto.cbv1.CostCenter.toObject(includeInstance, f),
    businessLine: (f = msg.getBusinessLine()) && proto_cybertron_businessline_businessline_pb.BusinessLine.toObject(includeInstance, f),
    displayLoadId: jspb.Message.getFieldWithDefault(msg, 35, ""),
    createdName: jspb.Message.getFieldWithDefault(msg, 36, ""),
    createdEmail: jspb.Message.getFieldWithDefault(msg, 37, ""),
    billingstatus: jspb.Message.getFieldWithDefault(msg, 38, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.Order}
 */
proto.cbv1.Order.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.Order;
  return proto.cbv1.Order.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.Order} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.Order}
 */
proto.cbv1.Order.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_cybertron_account_account_pb.Account;
      reader.readMessage(value,proto_cybertron_account_account_pb.Account.deserializeBinaryFromReader);
      msg.setLmo(value);
      break;
    case 3:
      var value = new proto_cybertron_account_account_pb.Account;
      reader.readMessage(value,proto_cybertron_account_account_pb.Account.deserializeBinaryFromReader);
      msg.setTruckingVendor(value);
      break;
    case 4:
      var value = new proto.cbv1.Task;
      reader.readMessage(value,proto.cbv1.Task.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    case 5:
      var value = new proto_cybertron_user_user_pb.Driver;
      reader.readMessage(value,proto_cybertron_user_user_pb.Driver.deserializeBinaryFromReader);
      msg.setDriver(value);
      break;
    case 6:
      var value = new proto_cybertron_truck_truck_pb.Truck;
      reader.readMessage(value,proto_cybertron_truck_truck_pb.Truck.deserializeBinaryFromReader);
      msg.setTruck(value);
      break;
    case 7:
      var value = new proto_cybertron_trailer_trailer_pb.Trailer;
      reader.readMessage(value,proto_cybertron_trailer_trailer_pb.Trailer.deserializeBinaryFromReader);
      msg.setTrailer(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 9:
      var value = /** @type {!proto.cbv1.OrderStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatusUnix(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScheduledUnix(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedUnix(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdatedUnix(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalesOrderNumber(value);
      break;
    case 15:
      var value = new proto.cbv1.OrderStats;
      reader.readMessage(value,proto.cbv1.OrderStats.deserializeBinaryFromReader);
      msg.setOrderStats(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAsap(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setToGoUpForGrabsAt(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeliveryWindowStart(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeliveryWindowDurationMinutes(value);
      break;
    case 21:
      var value = new proto_cybertron_fields_fields_pb.CustomField;
      reader.readMessage(value,proto_cybertron_fields_fields_pb.CustomField.deserializeBinaryFromReader);
      msg.addCustomFields(value);
      break;
    case 23:
      var value = new proto.cbv1.OrderAttachment;
      reader.readMessage(value,proto.cbv1.OrderAttachment.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCanBeDispatchedUnix(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setSaUniqueId(value);
      break;
    case 26:
      var value = new proto.cbv1.FleetManagerPlan;
      reader.readMessage(value,proto.cbv1.FleetManagerPlan.deserializeBinaryFromReader);
      msg.setFleetManagerPlan(value);
      break;
    case 27:
      var value = /** @type {!proto.cbv1.OrderType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 28:
      var value = new proto_cybertron_drivercertification_driverCertification_pb.DriverCertification;
      reader.readMessage(value,proto_cybertron_drivercertification_driverCertification_pb.DriverCertification.deserializeBinaryFromReader);
      msg.addDriverCertifications(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketNumber(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostCenter(value);
      break;
    case 31:
      var value = new proto_cybertron_contracts_contracts_pb.VendorContract;
      reader.readMessage(value,proto_cybertron_contracts_contracts_pb.VendorContract.deserializeBinaryFromReader);
      msg.setVendorContract(value);
      break;
    case 32:
      var value = new proto_cybertron_contracts_contracts_pb.VendorContractType;
      reader.readMessage(value,proto_cybertron_contracts_contracts_pb.VendorContractType.deserializeBinaryFromReader);
      msg.setContractType(value);
      break;
    case 33:
      var value = new proto.cbv1.CostCenter;
      reader.readMessage(value,proto.cbv1.CostCenter.deserializeBinaryFromReader);
      msg.setCostCenterObj(value);
      break;
    case 34:
      var value = new proto_cybertron_businessline_businessline_pb.BusinessLine;
      reader.readMessage(value,proto_cybertron_businessline_businessline_pb.BusinessLine.deserializeBinaryFromReader);
      msg.setBusinessLine(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayLoadId(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedName(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedEmail(value);
      break;
    case 38:
      var value = /** @type {!proto.cbv1.BillingStatus} */ (reader.readEnum());
      msg.setBillingstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.Order.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.Order.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.Order} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Order.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLmo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_cybertron_account_account_pb.Account.serializeBinaryToWriter
    );
  }
  f = message.getTruckingVendor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_cybertron_account_account_pb.Account.serializeBinaryToWriter
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cbv1.Task.serializeBinaryToWriter
    );
  }
  f = message.getDriver();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_cybertron_user_user_pb.Driver.serializeBinaryToWriter
    );
  }
  f = message.getTruck();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_cybertron_truck_truck_pb.Truck.serializeBinaryToWriter
    );
  }
  f = message.getTrailer();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_cybertron_trailer_trailer_pb.Trailer.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getStatusUnix();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getScheduledUnix();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getUpdatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getSalesOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getOrderStats();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.cbv1.OrderStats.serializeBinaryToWriter
    );
  }
  f = message.getIsAsap();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getToGoUpForGrabsAt();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getDeliveryWindowStart();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getDeliveryWindowDurationMinutes();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getCustomFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto_cybertron_fields_fields_pb.CustomField.serializeBinaryToWriter
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.cbv1.OrderAttachment.serializeBinaryToWriter
    );
  }
  f = message.getCanBeDispatchedUnix();
  if (f !== 0) {
    writer.writeInt64(
      24,
      f
    );
  }
  f = message.getSaUniqueId();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getFleetManagerPlan();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.cbv1.FleetManagerPlan.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      27,
      f
    );
  }
  f = message.getDriverCertificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      28,
      f,
      proto_cybertron_drivercertification_driverCertification_pb.DriverCertification.serializeBinaryToWriter
    );
  }
  f = message.getTicketNumber();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getCostCenter();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getVendorContract();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto_cybertron_contracts_contracts_pb.VendorContract.serializeBinaryToWriter
    );
  }
  f = message.getContractType();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto_cybertron_contracts_contracts_pb.VendorContractType.serializeBinaryToWriter
    );
  }
  f = message.getCostCenterObj();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.cbv1.CostCenter.serializeBinaryToWriter
    );
  }
  f = message.getBusinessLine();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto_cybertron_businessline_businessline_pb.BusinessLine.serializeBinaryToWriter
    );
  }
  f = message.getDisplayLoadId();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getCreatedName();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getCreatedEmail();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getBillingstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      38,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.Order.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.Order.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Account lmo = 2;
 * @return {?proto.cbv1.Account}
 */
proto.cbv1.Order.prototype.getLmo = function() {
  return /** @type{?proto.cbv1.Account} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_account_account_pb.Account, 2));
};


/** @param {?proto.cbv1.Account|undefined} value */
proto.cbv1.Order.prototype.setLmo = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cbv1.Order.prototype.clearLmo = function() {
  this.setLmo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Order.prototype.hasLmo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Account trucking_vendor = 3;
 * @return {?proto.cbv1.Account}
 */
proto.cbv1.Order.prototype.getTruckingVendor = function() {
  return /** @type{?proto.cbv1.Account} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_account_account_pb.Account, 3));
};


/** @param {?proto.cbv1.Account|undefined} value */
proto.cbv1.Order.prototype.setTruckingVendor = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv1.Order.prototype.clearTruckingVendor = function() {
  this.setTruckingVendor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Order.prototype.hasTruckingVendor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Task tasks = 4;
 * @return {!Array<!proto.cbv1.Task>}
 */
proto.cbv1.Order.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.cbv1.Task>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.Task, 4));
};


/** @param {!Array<!proto.cbv1.Task>} value */
proto.cbv1.Order.prototype.setTasksList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cbv1.Task=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.Task}
 */
proto.cbv1.Order.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cbv1.Task, opt_index);
};


proto.cbv1.Order.prototype.clearTasksList = function() {
  this.setTasksList([]);
};


/**
 * optional Driver driver = 5;
 * @return {?proto.cbv1.Driver}
 */
proto.cbv1.Order.prototype.getDriver = function() {
  return /** @type{?proto.cbv1.Driver} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_user_user_pb.Driver, 5));
};


/** @param {?proto.cbv1.Driver|undefined} value */
proto.cbv1.Order.prototype.setDriver = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.cbv1.Order.prototype.clearDriver = function() {
  this.setDriver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Order.prototype.hasDriver = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Truck truck = 6;
 * @return {?proto.cbv1.Truck}
 */
proto.cbv1.Order.prototype.getTruck = function() {
  return /** @type{?proto.cbv1.Truck} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_truck_truck_pb.Truck, 6));
};


/** @param {?proto.cbv1.Truck|undefined} value */
proto.cbv1.Order.prototype.setTruck = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


proto.cbv1.Order.prototype.clearTruck = function() {
  this.setTruck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Order.prototype.hasTruck = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Trailer trailer = 7;
 * @return {?proto.cbv1.Trailer}
 */
proto.cbv1.Order.prototype.getTrailer = function() {
  return /** @type{?proto.cbv1.Trailer} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_trailer_trailer_pb.Trailer, 7));
};


/** @param {?proto.cbv1.Trailer|undefined} value */
proto.cbv1.Order.prototype.setTrailer = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


proto.cbv1.Order.prototype.clearTrailer = function() {
  this.setTrailer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Order.prototype.hasTrailer = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.cbv1.Order.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.cbv1.Order.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional OrderStatus status = 9;
 * @return {!proto.cbv1.OrderStatus}
 */
proto.cbv1.Order.prototype.getStatus = function() {
  return /** @type {!proto.cbv1.OrderStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {!proto.cbv1.OrderStatus} value */
proto.cbv1.Order.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional int32 status_unix = 10;
 * @return {number}
 */
proto.cbv1.Order.prototype.getStatusUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cbv1.Order.prototype.setStatusUnix = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 scheduled_unix = 11;
 * @return {number}
 */
proto.cbv1.Order.prototype.getScheduledUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.cbv1.Order.prototype.setScheduledUnix = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 created_unix = 12;
 * @return {number}
 */
proto.cbv1.Order.prototype.getCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.cbv1.Order.prototype.setCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 updated_unix = 13;
 * @return {number}
 */
proto.cbv1.Order.prototype.getUpdatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.cbv1.Order.prototype.setUpdatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string sales_order_number = 14;
 * @return {string}
 */
proto.cbv1.Order.prototype.getSalesOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.cbv1.Order.prototype.setSalesOrderNumber = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional OrderStats order_stats = 15;
 * @return {?proto.cbv1.OrderStats}
 */
proto.cbv1.Order.prototype.getOrderStats = function() {
  return /** @type{?proto.cbv1.OrderStats} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.OrderStats, 15));
};


/** @param {?proto.cbv1.OrderStats|undefined} value */
proto.cbv1.Order.prototype.setOrderStats = function(value) {
  jspb.Message.setWrapperField(this, 15, value);
};


proto.cbv1.Order.prototype.clearOrderStats = function() {
  this.setOrderStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Order.prototype.hasOrderStats = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bool is_asap = 17;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.Order.prototype.getIsAsap = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 17, false));
};


/** @param {boolean} value */
proto.cbv1.Order.prototype.setIsAsap = function(value) {
  jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional int64 to_go_up_for_grabs_at = 18;
 * @return {number}
 */
proto.cbv1.Order.prototype.getToGoUpForGrabsAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/** @param {number} value */
proto.cbv1.Order.prototype.setToGoUpForGrabsAt = function(value) {
  jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 delivery_window_start = 19;
 * @return {number}
 */
proto.cbv1.Order.prototype.getDeliveryWindowStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/** @param {number} value */
proto.cbv1.Order.prototype.setDeliveryWindowStart = function(value) {
  jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 delivery_window_duration_minutes = 20;
 * @return {number}
 */
proto.cbv1.Order.prototype.getDeliveryWindowDurationMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/** @param {number} value */
proto.cbv1.Order.prototype.setDeliveryWindowDurationMinutes = function(value) {
  jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * repeated CustomField custom_fields = 21;
 * @return {!Array<!proto.cbv1.CustomField>}
 */
proto.cbv1.Order.prototype.getCustomFieldsList = function() {
  return /** @type{!Array<!proto.cbv1.CustomField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_fields_fields_pb.CustomField, 21));
};


/** @param {!Array<!proto.cbv1.CustomField>} value */
proto.cbv1.Order.prototype.setCustomFieldsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.cbv1.CustomField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.CustomField}
 */
proto.cbv1.Order.prototype.addCustomFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.cbv1.CustomField, opt_index);
};


proto.cbv1.Order.prototype.clearCustomFieldsList = function() {
  this.setCustomFieldsList([]);
};


/**
 * repeated OrderAttachment attachments = 23;
 * @return {!Array<!proto.cbv1.OrderAttachment>}
 */
proto.cbv1.Order.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.cbv1.OrderAttachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.OrderAttachment, 23));
};


/** @param {!Array<!proto.cbv1.OrderAttachment>} value */
proto.cbv1.Order.prototype.setAttachmentsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.cbv1.OrderAttachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.OrderAttachment}
 */
proto.cbv1.Order.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.cbv1.OrderAttachment, opt_index);
};


proto.cbv1.Order.prototype.clearAttachmentsList = function() {
  this.setAttachmentsList([]);
};


/**
 * optional int64 can_be_dispatched_unix = 24;
 * @return {number}
 */
proto.cbv1.Order.prototype.getCanBeDispatchedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/** @param {number} value */
proto.cbv1.Order.prototype.setCanBeDispatchedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional string sa_unique_id = 25;
 * @return {string}
 */
proto.cbv1.Order.prototype.getSaUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/** @param {string} value */
proto.cbv1.Order.prototype.setSaUniqueId = function(value) {
  jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional FleetManagerPlan fleet_manager_plan = 26;
 * @return {?proto.cbv1.FleetManagerPlan}
 */
proto.cbv1.Order.prototype.getFleetManagerPlan = function() {
  return /** @type{?proto.cbv1.FleetManagerPlan} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.FleetManagerPlan, 26));
};


/** @param {?proto.cbv1.FleetManagerPlan|undefined} value */
proto.cbv1.Order.prototype.setFleetManagerPlan = function(value) {
  jspb.Message.setWrapperField(this, 26, value);
};


proto.cbv1.Order.prototype.clearFleetManagerPlan = function() {
  this.setFleetManagerPlan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Order.prototype.hasFleetManagerPlan = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional OrderType type = 27;
 * @return {!proto.cbv1.OrderType}
 */
proto.cbv1.Order.prototype.getType = function() {
  return /** @type {!proto.cbv1.OrderType} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/** @param {!proto.cbv1.OrderType} value */
proto.cbv1.Order.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 27, value);
};


/**
 * repeated DriverCertification driver_certifications = 28;
 * @return {!Array<!proto.cbv1.DriverCertification>}
 */
proto.cbv1.Order.prototype.getDriverCertificationsList = function() {
  return /** @type{!Array<!proto.cbv1.DriverCertification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_drivercertification_driverCertification_pb.DriverCertification, 28));
};


/** @param {!Array<!proto.cbv1.DriverCertification>} value */
proto.cbv1.Order.prototype.setDriverCertificationsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 28, value);
};


/**
 * @param {!proto.cbv1.DriverCertification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.DriverCertification}
 */
proto.cbv1.Order.prototype.addDriverCertifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 28, opt_value, proto.cbv1.DriverCertification, opt_index);
};


proto.cbv1.Order.prototype.clearDriverCertificationsList = function() {
  this.setDriverCertificationsList([]);
};


/**
 * optional string ticket_number = 29;
 * @return {string}
 */
proto.cbv1.Order.prototype.getTicketNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/** @param {string} value */
proto.cbv1.Order.prototype.setTicketNumber = function(value) {
  jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string cost_center = 30;
 * @return {string}
 */
proto.cbv1.Order.prototype.getCostCenter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/** @param {string} value */
proto.cbv1.Order.prototype.setCostCenter = function(value) {
  jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional VendorContract vendor_contract = 31;
 * @return {?proto.cbv1.VendorContract}
 */
proto.cbv1.Order.prototype.getVendorContract = function() {
  return /** @type{?proto.cbv1.VendorContract} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_contracts_contracts_pb.VendorContract, 31));
};


/** @param {?proto.cbv1.VendorContract|undefined} value */
proto.cbv1.Order.prototype.setVendorContract = function(value) {
  jspb.Message.setWrapperField(this, 31, value);
};


proto.cbv1.Order.prototype.clearVendorContract = function() {
  this.setVendorContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Order.prototype.hasVendorContract = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional VendorContractType contract_type = 32;
 * @return {?proto.cbv1.VendorContractType}
 */
proto.cbv1.Order.prototype.getContractType = function() {
  return /** @type{?proto.cbv1.VendorContractType} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_contracts_contracts_pb.VendorContractType, 32));
};


/** @param {?proto.cbv1.VendorContractType|undefined} value */
proto.cbv1.Order.prototype.setContractType = function(value) {
  jspb.Message.setWrapperField(this, 32, value);
};


proto.cbv1.Order.prototype.clearContractType = function() {
  this.setContractType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Order.prototype.hasContractType = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional CostCenter cost_center_obj = 33;
 * @return {?proto.cbv1.CostCenter}
 */
proto.cbv1.Order.prototype.getCostCenterObj = function() {
  return /** @type{?proto.cbv1.CostCenter} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.CostCenter, 33));
};


/** @param {?proto.cbv1.CostCenter|undefined} value */
proto.cbv1.Order.prototype.setCostCenterObj = function(value) {
  jspb.Message.setWrapperField(this, 33, value);
};


proto.cbv1.Order.prototype.clearCostCenterObj = function() {
  this.setCostCenterObj(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Order.prototype.hasCostCenterObj = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional BusinessLine business_line = 34;
 * @return {?proto.cbv1.BusinessLine}
 */
proto.cbv1.Order.prototype.getBusinessLine = function() {
  return /** @type{?proto.cbv1.BusinessLine} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_businessline_businessline_pb.BusinessLine, 34));
};


/** @param {?proto.cbv1.BusinessLine|undefined} value */
proto.cbv1.Order.prototype.setBusinessLine = function(value) {
  jspb.Message.setWrapperField(this, 34, value);
};


proto.cbv1.Order.prototype.clearBusinessLine = function() {
  this.setBusinessLine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Order.prototype.hasBusinessLine = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional string display_load_id = 35;
 * @return {string}
 */
proto.cbv1.Order.prototype.getDisplayLoadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/** @param {string} value */
proto.cbv1.Order.prototype.setDisplayLoadId = function(value) {
  jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string created_name = 36;
 * @return {string}
 */
proto.cbv1.Order.prototype.getCreatedName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/** @param {string} value */
proto.cbv1.Order.prototype.setCreatedName = function(value) {
  jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string created_email = 37;
 * @return {string}
 */
proto.cbv1.Order.prototype.getCreatedEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/** @param {string} value */
proto.cbv1.Order.prototype.setCreatedEmail = function(value) {
  jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional BillingStatus billingStatus = 38;
 * @return {!proto.cbv1.BillingStatus}
 */
proto.cbv1.Order.prototype.getBillingstatus = function() {
  return /** @type {!proto.cbv1.BillingStatus} */ (jspb.Message.getFieldWithDefault(this, 38, 0));
};


/** @param {!proto.cbv1.BillingStatus} value */
proto.cbv1.Order.prototype.setBillingstatus = function(value) {
  jspb.Message.setProto3EnumField(this, 38, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CostCenter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.CostCenter.repeatedFields_, null);
};
goog.inherits(proto.cbv1.CostCenter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CostCenter.displayName = 'proto.cbv1.CostCenter';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.CostCenter.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CostCenter.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CostCenter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CostCenter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CostCenter.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lmoId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    businessLinesList: jspb.Message.toObjectList(msg.getBusinessLinesList(),
    proto.cbv1.CostCenterBusinessLine.toObject, includeInstance),
    canBeArchived: jspb.Message.getFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CostCenter}
 */
proto.cbv1.CostCenter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CostCenter;
  return proto.cbv1.CostCenter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CostCenter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CostCenter}
 */
proto.cbv1.CostCenter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLmoId(value);
      break;
    case 4:
      var value = new proto.cbv1.CostCenterBusinessLine;
      reader.readMessage(value,proto.cbv1.CostCenterBusinessLine.deserializeBinaryFromReader);
      msg.addBusinessLines(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanBeArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CostCenter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CostCenter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CostCenter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CostCenter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLmoId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getBusinessLinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cbv1.CostCenterBusinessLine.serializeBinaryToWriter
    );
  }
  f = message.getCanBeArchived();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 ID = 1;
 * @return {number}
 */
proto.cbv1.CostCenter.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.CostCenter.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.CostCenter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.CostCenter.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 lmo_id = 3;
 * @return {number}
 */
proto.cbv1.CostCenter.prototype.getLmoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.CostCenter.prototype.setLmoId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated CostCenterBusinessLine business_lines = 4;
 * @return {!Array<!proto.cbv1.CostCenterBusinessLine>}
 */
proto.cbv1.CostCenter.prototype.getBusinessLinesList = function() {
  return /** @type{!Array<!proto.cbv1.CostCenterBusinessLine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.CostCenterBusinessLine, 4));
};


/** @param {!Array<!proto.cbv1.CostCenterBusinessLine>} value */
proto.cbv1.CostCenter.prototype.setBusinessLinesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cbv1.CostCenterBusinessLine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.CostCenterBusinessLine}
 */
proto.cbv1.CostCenter.prototype.addBusinessLines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cbv1.CostCenterBusinessLine, opt_index);
};


proto.cbv1.CostCenter.prototype.clearBusinessLinesList = function() {
  this.setBusinessLinesList([]);
};


/**
 * optional bool can_be_archived = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.CostCenter.prototype.getCanBeArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.cbv1.CostCenter.prototype.setCanBeArchived = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CostCenterBusinessLine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CostCenterBusinessLine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CostCenterBusinessLine.displayName = 'proto.cbv1.CostCenterBusinessLine';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CostCenterBusinessLine.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CostCenterBusinessLine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CostCenterBusinessLine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CostCenterBusinessLine.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CostCenterBusinessLine}
 */
proto.cbv1.CostCenterBusinessLine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CostCenterBusinessLine;
  return proto.cbv1.CostCenterBusinessLine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CostCenterBusinessLine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CostCenterBusinessLine}
 */
proto.cbv1.CostCenterBusinessLine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CostCenterBusinessLine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CostCenterBusinessLine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CostCenterBusinessLine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CostCenterBusinessLine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 ID = 1;
 * @return {number}
 */
proto.cbv1.CostCenterBusinessLine.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.CostCenterBusinessLine.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.CostCenterBusinessLine.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.CostCenterBusinessLine.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.Task = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.Task.repeatedFields_, null);
};
goog.inherits(proto.cbv1.Task, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.Task.displayName = 'proto.cbv1.Task';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.Task.repeatedFields_ = [19,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.Task.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.Task.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.Task} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Task.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    site: (f = msg.getSite()) && proto_cybertron_site_site_pb.SiteShort.toObject(includeInstance, f),
    payload: (f = msg.getPayload()) && proto_cybertron_payload_payload_pb.Payload.toObject(includeInstance, f),
    payloadBundle: (f = msg.getPayloadBundle()) && proto_cybertron_payload_payload_pb.PayloadBundle.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    scheduledUnix: jspb.Message.getFieldWithDefault(msg, 8, 0),
    orderedQuantity: +jspb.Message.getFieldWithDefault(msg, 9, 0.0),
    actualQuantity: +jspb.Message.getFieldWithDefault(msg, 10, 0.0),
    billing: (f = msg.getBilling()) && proto.cbv1.Billing.toObject(includeInstance, f),
    sequence: jspb.Message.getFieldWithDefault(msg, 12, 0),
    stats: (f = msg.getStats()) && proto.cbv1.TaskStats.toObject(includeInstance, f),
    isBillable: jspb.Message.getFieldWithDefault(msg, 14, false),
    createdUnix: jspb.Message.getFieldWithDefault(msg, 15, 0),
    updatedUnix: jspb.Message.getFieldWithDefault(msg, 16, 0),
    statusUnix: jspb.Message.getFieldWithDefault(msg, 18, 0),
    subTasksList: jspb.Message.toObjectList(msg.getSubTasksList(),
    proto.cbv1.SubTask.toObject, includeInstance),
    isReturnTrip: jspb.Message.getFieldWithDefault(msg, 21, false),
    taskstatushistoriesList: jspb.Message.toObjectList(msg.getTaskstatushistoriesList(),
    proto.cbv1.TaskStatusHistory.toObject, includeInstance),
    isBackHaulTask: jspb.Message.getFieldWithDefault(msg, 23, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.Task}
 */
proto.cbv1.Task.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.Task;
  return proto.cbv1.Task.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.Task} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.Task}
 */
proto.cbv1.Task.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.cbv1.TaskType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new proto_cybertron_site_site_pb.SiteShort;
      reader.readMessage(value,proto_cybertron_site_site_pb.SiteShort.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    case 4:
      var value = new proto_cybertron_payload_payload_pb.Payload;
      reader.readMessage(value,proto_cybertron_payload_payload_pb.Payload.deserializeBinaryFromReader);
      msg.setPayload(value);
      break;
    case 5:
      var value = new proto_cybertron_payload_payload_pb.PayloadBundle;
      reader.readMessage(value,proto_cybertron_payload_payload_pb.PayloadBundle.deserializeBinaryFromReader);
      msg.setPayloadBundle(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {!proto.cbv1.TaskStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScheduledUnix(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrderedQuantity(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setActualQuantity(value);
      break;
    case 11:
      var value = new proto.cbv1.Billing;
      reader.readMessage(value,proto.cbv1.Billing.deserializeBinaryFromReader);
      msg.setBilling(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSequence(value);
      break;
    case 13:
      var value = new proto.cbv1.TaskStats;
      reader.readMessage(value,proto.cbv1.TaskStats.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBillable(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedUnix(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdatedUnix(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatusUnix(value);
      break;
    case 19:
      var value = new proto.cbv1.SubTask;
      reader.readMessage(value,proto.cbv1.SubTask.deserializeBinaryFromReader);
      msg.addSubTasks(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReturnTrip(value);
      break;
    case 22:
      var value = new proto.cbv1.TaskStatusHistory;
      reader.readMessage(value,proto.cbv1.TaskStatusHistory.deserializeBinaryFromReader);
      msg.addTaskstatushistories(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBackHaulTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.Task.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.Task.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.Task} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Task.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_cybertron_site_site_pb.SiteShort.serializeBinaryToWriter
    );
  }
  f = message.getPayload();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_cybertron_payload_payload_pb.Payload.serializeBinaryToWriter
    );
  }
  f = message.getPayloadBundle();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_cybertron_payload_payload_pb.PayloadBundle.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getScheduledUnix();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getOrderedQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getActualQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getBilling();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.cbv1.Billing.serializeBinaryToWriter
    );
  }
  f = message.getSequence();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.cbv1.TaskStats.serializeBinaryToWriter
    );
  }
  f = message.getIsBillable();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getUpdatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getStatusUnix();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getSubTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.cbv1.SubTask.serializeBinaryToWriter
    );
  }
  f = message.getIsReturnTrip();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getTaskstatushistoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.cbv1.TaskStatusHistory.serializeBinaryToWriter
    );
  }
  f = message.getIsBackHaulTask();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.Task.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.Task.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional TaskType type = 2;
 * @return {!proto.cbv1.TaskType}
 */
proto.cbv1.Task.prototype.getType = function() {
  return /** @type {!proto.cbv1.TaskType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.cbv1.TaskType} value */
proto.cbv1.Task.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional SiteShort site = 3;
 * @return {?proto.cbv1.SiteShort}
 */
proto.cbv1.Task.prototype.getSite = function() {
  return /** @type{?proto.cbv1.SiteShort} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_site_site_pb.SiteShort, 3));
};


/** @param {?proto.cbv1.SiteShort|undefined} value */
proto.cbv1.Task.prototype.setSite = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv1.Task.prototype.clearSite = function() {
  this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Task.prototype.hasSite = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Payload payload = 4;
 * @return {?proto.cbv1.Payload}
 */
proto.cbv1.Task.prototype.getPayload = function() {
  return /** @type{?proto.cbv1.Payload} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_payload_payload_pb.Payload, 4));
};


/** @param {?proto.cbv1.Payload|undefined} value */
proto.cbv1.Task.prototype.setPayload = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.cbv1.Task.prototype.clearPayload = function() {
  this.setPayload(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Task.prototype.hasPayload = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PayloadBundle payload_bundle = 5;
 * @return {?proto.cbv1.PayloadBundle}
 */
proto.cbv1.Task.prototype.getPayloadBundle = function() {
  return /** @type{?proto.cbv1.PayloadBundle} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_payload_payload_pb.PayloadBundle, 5));
};


/** @param {?proto.cbv1.PayloadBundle|undefined} value */
proto.cbv1.Task.prototype.setPayloadBundle = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.cbv1.Task.prototype.clearPayloadBundle = function() {
  this.setPayloadBundle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Task.prototype.hasPayloadBundle = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.cbv1.Task.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cbv1.Task.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional TaskStatus status = 7;
 * @return {!proto.cbv1.TaskStatus}
 */
proto.cbv1.Task.prototype.getStatus = function() {
  return /** @type {!proto.cbv1.TaskStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {!proto.cbv1.TaskStatus} value */
proto.cbv1.Task.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int32 scheduled_unix = 8;
 * @return {number}
 */
proto.cbv1.Task.prototype.getScheduledUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cbv1.Task.prototype.setScheduledUnix = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional double ordered_quantity = 9;
 * @return {number}
 */
proto.cbv1.Task.prototype.getOrderedQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 9, 0.0));
};


/** @param {number} value */
proto.cbv1.Task.prototype.setOrderedQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double actual_quantity = 10;
 * @return {number}
 */
proto.cbv1.Task.prototype.getActualQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 10, 0.0));
};


/** @param {number} value */
proto.cbv1.Task.prototype.setActualQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional Billing billing = 11;
 * @return {?proto.cbv1.Billing}
 */
proto.cbv1.Task.prototype.getBilling = function() {
  return /** @type{?proto.cbv1.Billing} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.Billing, 11));
};


/** @param {?proto.cbv1.Billing|undefined} value */
proto.cbv1.Task.prototype.setBilling = function(value) {
  jspb.Message.setWrapperField(this, 11, value);
};


proto.cbv1.Task.prototype.clearBilling = function() {
  this.setBilling(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Task.prototype.hasBilling = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int32 sequence = 12;
 * @return {number}
 */
proto.cbv1.Task.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.cbv1.Task.prototype.setSequence = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional TaskStats stats = 13;
 * @return {?proto.cbv1.TaskStats}
 */
proto.cbv1.Task.prototype.getStats = function() {
  return /** @type{?proto.cbv1.TaskStats} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.TaskStats, 13));
};


/** @param {?proto.cbv1.TaskStats|undefined} value */
proto.cbv1.Task.prototype.setStats = function(value) {
  jspb.Message.setWrapperField(this, 13, value);
};


proto.cbv1.Task.prototype.clearStats = function() {
  this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Task.prototype.hasStats = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool is_billable = 14;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.Task.prototype.getIsBillable = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 14, false));
};


/** @param {boolean} value */
proto.cbv1.Task.prototype.setIsBillable = function(value) {
  jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional int32 created_unix = 15;
 * @return {number}
 */
proto.cbv1.Task.prototype.getCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.cbv1.Task.prototype.setCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 updated_unix = 16;
 * @return {number}
 */
proto.cbv1.Task.prototype.getUpdatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.cbv1.Task.prototype.setUpdatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 status_unix = 18;
 * @return {number}
 */
proto.cbv1.Task.prototype.getStatusUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/** @param {number} value */
proto.cbv1.Task.prototype.setStatusUnix = function(value) {
  jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * repeated SubTask sub_tasks = 19;
 * @return {!Array<!proto.cbv1.SubTask>}
 */
proto.cbv1.Task.prototype.getSubTasksList = function() {
  return /** @type{!Array<!proto.cbv1.SubTask>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.SubTask, 19));
};


/** @param {!Array<!proto.cbv1.SubTask>} value */
proto.cbv1.Task.prototype.setSubTasksList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.cbv1.SubTask=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.SubTask}
 */
proto.cbv1.Task.prototype.addSubTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.cbv1.SubTask, opt_index);
};


proto.cbv1.Task.prototype.clearSubTasksList = function() {
  this.setSubTasksList([]);
};


/**
 * optional bool is_return_trip = 21;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.Task.prototype.getIsReturnTrip = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 21, false));
};


/** @param {boolean} value */
proto.cbv1.Task.prototype.setIsReturnTrip = function(value) {
  jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * repeated TaskStatusHistory taskStatusHistories = 22;
 * @return {!Array<!proto.cbv1.TaskStatusHistory>}
 */
proto.cbv1.Task.prototype.getTaskstatushistoriesList = function() {
  return /** @type{!Array<!proto.cbv1.TaskStatusHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.TaskStatusHistory, 22));
};


/** @param {!Array<!proto.cbv1.TaskStatusHistory>} value */
proto.cbv1.Task.prototype.setTaskstatushistoriesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.cbv1.TaskStatusHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.TaskStatusHistory}
 */
proto.cbv1.Task.prototype.addTaskstatushistories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.cbv1.TaskStatusHistory, opt_index);
};


proto.cbv1.Task.prototype.clearTaskstatushistoriesList = function() {
  this.setTaskstatushistoriesList([]);
};


/**
 * optional bool is_back_haul_task = 23;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.Task.prototype.getIsBackHaulTask = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 23, false));
};


/** @param {boolean} value */
proto.cbv1.Task.prototype.setIsBackHaulTask = function(value) {
  jspb.Message.setProto3BooleanField(this, 23, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.TaskStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.TaskStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.TaskStats.displayName = 'proto.cbv1.TaskStats';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.TaskStats.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.TaskStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.TaskStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.TaskStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    arrivalUnix: jspb.Message.getFieldWithDefault(msg, 5, 0),
    exitUnix: jspb.Message.getFieldWithDefault(msg, 6, 0),
    minutesOnSite: jspb.Message.getFieldWithDefault(msg, 7, 0),
    driverEta: jspb.Message.getFieldWithDefault(msg, 8, 0),
    manuallyCompleted: jspb.Message.getFieldWithDefault(msg, 9, false),
    estimatedDistance: jspb.Message.getFieldWithDefault(msg, 10, 0),
    estimatedDuration: jspb.Message.getFieldWithDefault(msg, 11, 0),
    totalRealDistance: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.TaskStats}
 */
proto.cbv1.TaskStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.TaskStats;
  return proto.cbv1.TaskStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.TaskStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.TaskStats}
 */
proto.cbv1.TaskStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setArrivalUnix(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExitUnix(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinutesOnSite(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDriverEta(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManuallyCompleted(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEstimatedDistance(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEstimatedDuration(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalRealDistance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.TaskStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.TaskStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.TaskStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.TaskStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArrivalUnix();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getExitUnix();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMinutesOnSite();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getDriverEta();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getManuallyCompleted();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getEstimatedDistance();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getEstimatedDuration();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getTotalRealDistance();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
};


/**
 * optional int32 arrival_unix = 5;
 * @return {number}
 */
proto.cbv1.TaskStats.prototype.getArrivalUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.TaskStats.prototype.setArrivalUnix = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 exit_unix = 6;
 * @return {number}
 */
proto.cbv1.TaskStats.prototype.getExitUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.TaskStats.prototype.setExitUnix = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 minutes_on_site = 7;
 * @return {number}
 */
proto.cbv1.TaskStats.prototype.getMinutesOnSite = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.TaskStats.prototype.setMinutesOnSite = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 driver_eta = 8;
 * @return {number}
 */
proto.cbv1.TaskStats.prototype.getDriverEta = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cbv1.TaskStats.prototype.setDriverEta = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool manually_completed = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.TaskStats.prototype.getManuallyCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.cbv1.TaskStats.prototype.setManuallyCompleted = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int32 estimated_distance = 10;
 * @return {number}
 */
proto.cbv1.TaskStats.prototype.getEstimatedDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cbv1.TaskStats.prototype.setEstimatedDistance = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 estimated_duration = 11;
 * @return {number}
 */
proto.cbv1.TaskStats.prototype.getEstimatedDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.cbv1.TaskStats.prototype.setEstimatedDuration = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 total_real_distance = 12;
 * @return {number}
 */
proto.cbv1.TaskStats.prototype.getTotalRealDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.cbv1.TaskStats.prototype.setTotalRealDistance = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.TaskStatusHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.TaskStatusHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.TaskStatusHistory.displayName = 'proto.cbv1.TaskStatusHistory';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.TaskStatusHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.TaskStatusHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.TaskStatusHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.TaskStatusHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createunix: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.TaskStatusHistory}
 */
proto.cbv1.TaskStatusHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.TaskStatusHistory;
  return proto.cbv1.TaskStatusHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.TaskStatusHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.TaskStatusHistory}
 */
proto.cbv1.TaskStatusHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cbv1.TaskStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateunix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.TaskStatusHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.TaskStatusHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.TaskStatusHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.TaskStatusHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCreateunix();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional TaskStatus status = 1;
 * @return {!proto.cbv1.TaskStatus}
 */
proto.cbv1.TaskStatusHistory.prototype.getStatus = function() {
  return /** @type {!proto.cbv1.TaskStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.cbv1.TaskStatus} value */
proto.cbv1.TaskStatusHistory.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 createUnix = 2;
 * @return {number}
 */
proto.cbv1.TaskStatusHistory.prototype.getCreateunix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.TaskStatusHistory.prototype.setCreateunix = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SubTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.SubTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SubTask.displayName = 'proto.cbv1.SubTask';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SubTask.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SubTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SubTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SubTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: (f = msg.getType()) && proto.cbv1.SubTaskType.toObject(includeInstance, f),
    asset: (f = msg.getAsset()) && proto.cbv1.Asset.toObject(includeInstance, f),
    payload: (f = msg.getPayload()) && proto_cybertron_payload_payload_pb.Payload.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    orderedQuantity: +jspb.Message.getFieldWithDefault(msg, 6, 0.0),
    actualQuantity: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    isBillable: jspb.Message.getFieldWithDefault(msg, 9, false),
    createdUnix: jspb.Message.getFieldWithDefault(msg, 10, 0),
    updatedUnix: jspb.Message.getFieldWithDefault(msg, 11, 0),
    statusUnix: jspb.Message.getFieldWithDefault(msg, 12, 0),
    billing: (f = msg.getBilling()) && proto.cbv1.SubTaskBilling.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SubTask}
 */
proto.cbv1.SubTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SubTask;
  return proto.cbv1.SubTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SubTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SubTask}
 */
proto.cbv1.SubTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.cbv1.SubTaskType;
      reader.readMessage(value,proto.cbv1.SubTaskType.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 3:
      var value = new proto.cbv1.Asset;
      reader.readMessage(value,proto.cbv1.Asset.deserializeBinaryFromReader);
      msg.setAsset(value);
      break;
    case 4:
      var value = new proto_cybertron_payload_payload_pb.Payload;
      reader.readMessage(value,proto_cybertron_payload_payload_pb.Payload.deserializeBinaryFromReader);
      msg.setPayload(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrderedQuantity(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setActualQuantity(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBillable(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedUnix(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdatedUnix(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatusUnix(value);
      break;
    case 13:
      var value = new proto.cbv1.SubTaskBilling;
      reader.readMessage(value,proto.cbv1.SubTaskBilling.deserializeBinaryFromReader);
      msg.setBilling(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SubTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SubTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SubTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SubTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.cbv1.SubTaskType.serializeBinaryToWriter
    );
  }
  f = message.getAsset();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cbv1.Asset.serializeBinaryToWriter
    );
  }
  f = message.getPayload();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_cybertron_payload_payload_pb.Payload.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrderedQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getActualQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getIsBillable();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getUpdatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getStatusUnix();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getBilling();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.cbv1.SubTaskBilling.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.SubTask.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.SubTask.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional SubTaskType type = 2;
 * @return {?proto.cbv1.SubTaskType}
 */
proto.cbv1.SubTask.prototype.getType = function() {
  return /** @type{?proto.cbv1.SubTaskType} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.SubTaskType, 2));
};


/** @param {?proto.cbv1.SubTaskType|undefined} value */
proto.cbv1.SubTask.prototype.setType = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cbv1.SubTask.prototype.clearType = function() {
  this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.SubTask.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Asset asset = 3;
 * @return {?proto.cbv1.Asset}
 */
proto.cbv1.SubTask.prototype.getAsset = function() {
  return /** @type{?proto.cbv1.Asset} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.Asset, 3));
};


/** @param {?proto.cbv1.Asset|undefined} value */
proto.cbv1.SubTask.prototype.setAsset = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv1.SubTask.prototype.clearAsset = function() {
  this.setAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.SubTask.prototype.hasAsset = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Payload payload = 4;
 * @return {?proto.cbv1.Payload}
 */
proto.cbv1.SubTask.prototype.getPayload = function() {
  return /** @type{?proto.cbv1.Payload} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_payload_payload_pb.Payload, 4));
};


/** @param {?proto.cbv1.Payload|undefined} value */
proto.cbv1.SubTask.prototype.setPayload = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.cbv1.SubTask.prototype.clearPayload = function() {
  this.setPayload(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.SubTask.prototype.hasPayload = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.cbv1.SubTask.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.SubTask.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double ordered_quantity = 6;
 * @return {number}
 */
proto.cbv1.SubTask.prototype.getOrderedQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 6, 0.0));
};


/** @param {number} value */
proto.cbv1.SubTask.prototype.setOrderedQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double actual_quantity = 7;
 * @return {number}
 */
proto.cbv1.SubTask.prototype.getActualQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.cbv1.SubTask.prototype.setActualQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional bool is_billable = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.SubTask.prototype.getIsBillable = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.cbv1.SubTask.prototype.setIsBillable = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int32 created_unix = 10;
 * @return {number}
 */
proto.cbv1.SubTask.prototype.getCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cbv1.SubTask.prototype.setCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 updated_unix = 11;
 * @return {number}
 */
proto.cbv1.SubTask.prototype.getUpdatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.cbv1.SubTask.prototype.setUpdatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 status_unix = 12;
 * @return {number}
 */
proto.cbv1.SubTask.prototype.getStatusUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.cbv1.SubTask.prototype.setStatusUnix = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional SubTaskBilling billing = 13;
 * @return {?proto.cbv1.SubTaskBilling}
 */
proto.cbv1.SubTask.prototype.getBilling = function() {
  return /** @type{?proto.cbv1.SubTaskBilling} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.SubTaskBilling, 13));
};


/** @param {?proto.cbv1.SubTaskBilling|undefined} value */
proto.cbv1.SubTask.prototype.setBilling = function(value) {
  jspb.Message.setWrapperField(this, 13, value);
};


proto.cbv1.SubTask.prototype.clearBilling = function() {
  this.setBilling(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.SubTask.prototype.hasBilling = function() {
  return jspb.Message.getField(this, 13) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SubTaskType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.SubTaskType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SubTaskType.displayName = 'proto.cbv1.SubTaskType';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SubTaskType.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SubTaskType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SubTaskType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SubTaskType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SubTaskType}
 */
proto.cbv1.SubTaskType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SubTaskType;
  return proto.cbv1.SubTaskType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SubTaskType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SubTaskType}
 */
proto.cbv1.SubTaskType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SubTaskType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SubTaskType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SubTaskType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SubTaskType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.SubTaskType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.SubTaskType.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.SubTaskType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.SubTaskType.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.AssetType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.AssetType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.AssetType.displayName = 'proto.cbv1.AssetType';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.AssetType.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.AssetType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.AssetType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AssetType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    group: (f = msg.getGroup()) && proto.cbv1.AssetGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.AssetType}
 */
proto.cbv1.AssetType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.AssetType;
  return proto.cbv1.AssetType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.AssetType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.AssetType}
 */
proto.cbv1.AssetType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.cbv1.AssetGroup;
      reader.readMessage(value,proto.cbv1.AssetGroup.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.AssetType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.AssetType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.AssetType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AssetType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cbv1.AssetGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.AssetType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.AssetType.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.AssetType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.AssetType.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AssetGroup group = 3;
 * @return {?proto.cbv1.AssetGroup}
 */
proto.cbv1.AssetType.prototype.getGroup = function() {
  return /** @type{?proto.cbv1.AssetGroup} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.AssetGroup, 3));
};


/** @param {?proto.cbv1.AssetGroup|undefined} value */
proto.cbv1.AssetType.prototype.setGroup = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv1.AssetType.prototype.clearGroup = function() {
  this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.AssetType.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.AssetGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.AssetGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.AssetGroup.displayName = 'proto.cbv1.AssetGroup';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.AssetGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.AssetGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.AssetGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AssetGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.AssetGroup}
 */
proto.cbv1.AssetGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.AssetGroup;
  return proto.cbv1.AssetGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.AssetGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.AssetGroup}
 */
proto.cbv1.AssetGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.AssetGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.AssetGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.AssetGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AssetGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.AssetGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.AssetGroup.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.AssetGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.AssetGroup.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.Billing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.Billing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.Billing.displayName = 'proto.cbv1.Billing';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.Billing.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.Billing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.Billing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Billing.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    purchaseOrderName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ticketNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bolNumber: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.Billing}
 */
proto.cbv1.Billing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.Billing;
  return proto.cbv1.Billing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.Billing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.Billing}
 */
proto.cbv1.Billing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurchaseOrderName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBolNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.Billing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.Billing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.Billing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Billing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPurchaseOrderName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTicketNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBolNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 task_id = 1;
 * @return {number}
 */
proto.cbv1.Billing.prototype.getTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.Billing.prototype.setTaskId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string purchase_order_name = 2;
 * @return {string}
 */
proto.cbv1.Billing.prototype.getPurchaseOrderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.Billing.prototype.setPurchaseOrderName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ticket_number = 3;
 * @return {string}
 */
proto.cbv1.Billing.prototype.getTicketNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.Billing.prototype.setTicketNumber = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string bol_number = 4;
 * @return {string}
 */
proto.cbv1.Billing.prototype.getBolNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.Billing.prototype.setBolNumber = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SubTaskBilling = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.SubTaskBilling, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SubTaskBilling.displayName = 'proto.cbv1.SubTaskBilling';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SubTaskBilling.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SubTaskBilling.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SubTaskBilling} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SubTaskBilling.toObject = function(includeInstance, msg) {
  var f, obj = {
    subTaskId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ticketNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bolNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    purchaseOrderName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    salesOrderNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    maintenanceOrderNumber: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SubTaskBilling}
 */
proto.cbv1.SubTaskBilling.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SubTaskBilling;
  return proto.cbv1.SubTaskBilling.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SubTaskBilling} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SubTaskBilling}
 */
proto.cbv1.SubTaskBilling.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBolNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurchaseOrderName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalesOrderNumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaintenanceOrderNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SubTaskBilling.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SubTaskBilling.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SubTaskBilling} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SubTaskBilling.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubTaskId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTicketNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBolNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPurchaseOrderName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getSalesOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMaintenanceOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int64 sub_task_id = 1;
 * @return {number}
 */
proto.cbv1.SubTaskBilling.prototype.getSubTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.SubTaskBilling.prototype.setSubTaskId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string ticket_number = 2;
 * @return {string}
 */
proto.cbv1.SubTaskBilling.prototype.getTicketNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.SubTaskBilling.prototype.setTicketNumber = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bol_number = 3;
 * @return {string}
 */
proto.cbv1.SubTaskBilling.prototype.getBolNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.SubTaskBilling.prototype.setBolNumber = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string purchase_order_name = 4;
 * @return {string}
 */
proto.cbv1.SubTaskBilling.prototype.getPurchaseOrderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.SubTaskBilling.prototype.setPurchaseOrderName = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 updated_at = 5;
 * @return {number}
 */
proto.cbv1.SubTaskBilling.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.SubTaskBilling.prototype.setUpdatedAt = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 created_at = 6;
 * @return {number}
 */
proto.cbv1.SubTaskBilling.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.SubTaskBilling.prototype.setCreatedAt = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string sales_order_number = 7;
 * @return {string}
 */
proto.cbv1.SubTaskBilling.prototype.getSalesOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cbv1.SubTaskBilling.prototype.setSalesOrderNumber = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string maintenance_order_number = 8;
 * @return {string}
 */
proto.cbv1.SubTaskBilling.prototype.getMaintenanceOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.cbv1.SubTaskBilling.prototype.setMaintenanceOrderNumber = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.Asset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.Asset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.Asset.displayName = 'proto.cbv1.Asset';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.Asset.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.Asset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.Asset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Asset.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: (f = msg.getType()) && proto.cbv1.AssetType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.Asset}
 */
proto.cbv1.Asset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.Asset;
  return proto.cbv1.Asset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.Asset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.Asset}
 */
proto.cbv1.Asset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.cbv1.AssetType;
      reader.readMessage(value,proto.cbv1.AssetType.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.Asset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.Asset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.Asset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Asset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cbv1.AssetType.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.Asset.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.Asset.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.Asset.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.Asset.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AssetType type = 3;
 * @return {?proto.cbv1.AssetType}
 */
proto.cbv1.Asset.prototype.getType = function() {
  return /** @type{?proto.cbv1.AssetType} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.AssetType, 3));
};


/** @param {?proto.cbv1.AssetType|undefined} value */
proto.cbv1.Asset.prototype.setType = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv1.Asset.prototype.clearType = function() {
  this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Asset.prototype.hasType = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.Event.displayName = 'proto.cbv1.Event';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    event: jspb.Message.getFieldWithDefault(msg, 2, ""),
    siteName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    driverUid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    coordinates: (f = msg.getCoordinates()) && proto_geo_geo_pb.Point.toObject(includeInstance, f),
    createdUnix: jspb.Message.getFieldWithDefault(msg, 6, 0),
    speed: jspb.Message.getFieldWithDefault(msg, 7, 0),
    task: (f = msg.getTask()) && proto.cbv1.Task.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.Event}
 */
proto.cbv1.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.Event;
  return proto.cbv1.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.Event}
 */
proto.cbv1.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverUid(value);
      break;
    case 5:
      var value = new proto_geo_geo_pb.Point;
      reader.readMessage(value,proto_geo_geo_pb.Point.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedUnix(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpeed(value);
      break;
    case 8:
      var value = new proto.cbv1.Task;
      reader.readMessage(value,proto.cbv1.Task.deserializeBinaryFromReader);
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEvent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDriverUid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_geo_geo_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getSpeed();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getTask();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.cbv1.Task.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.Event.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.Event.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string event = 2;
 * @return {string}
 */
proto.cbv1.Event.prototype.getEvent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.Event.prototype.setEvent = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string site_name = 3;
 * @return {string}
 */
proto.cbv1.Event.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.Event.prototype.setSiteName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string driver_uid = 4;
 * @return {string}
 */
proto.cbv1.Event.prototype.getDriverUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.Event.prototype.setDriverUid = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Point coordinates = 5;
 * @return {?proto.cbv1.Point}
 */
proto.cbv1.Event.prototype.getCoordinates = function() {
  return /** @type{?proto.cbv1.Point} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Point, 5));
};


/** @param {?proto.cbv1.Point|undefined} value */
proto.cbv1.Event.prototype.setCoordinates = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.cbv1.Event.prototype.clearCoordinates = function() {
  this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Event.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 created_unix = 6;
 * @return {number}
 */
proto.cbv1.Event.prototype.getCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.Event.prototype.setCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 speed = 7;
 * @return {number}
 */
proto.cbv1.Event.prototype.getSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.Event.prototype.setSpeed = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional Task task = 8;
 * @return {?proto.cbv1.Task}
 */
proto.cbv1.Event.prototype.getTask = function() {
  return /** @type{?proto.cbv1.Task} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.Task, 8));
};


/** @param {?proto.cbv1.Task|undefined} value */
proto.cbv1.Event.prototype.setTask = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.cbv1.Event.prototype.clearTask = function() {
  this.setTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Event.prototype.hasTask = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.TaskSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.TaskSummary.repeatedFields_, null);
};
goog.inherits(proto.cbv1.TaskSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.TaskSummary.displayName = 'proto.cbv1.TaskSummary';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.TaskSummary.repeatedFields_ = [19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.TaskSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.TaskSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.TaskSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.TaskSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    site: (f = msg.getSite()) && proto_cybertron_site_site_pb.SiteShort.toObject(includeInstance, f),
    payload: (f = msg.getPayload()) && proto_cybertron_payload_payload_pb.Payload.toObject(includeInstance, f),
    payloadBundle: (f = msg.getPayloadBundle()) && proto_cybertron_payload_payload_pb.PayloadBundle.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    scheduledUnix: jspb.Message.getFieldWithDefault(msg, 7, 0),
    orderedQuantity: +jspb.Message.getFieldWithDefault(msg, 8, 0.0),
    sequence: jspb.Message.getFieldWithDefault(msg, 9, 0),
    stats: (f = msg.getStats()) && proto.cbv1.TaskStats.toObject(includeInstance, f),
    isBillable: jspb.Message.getFieldWithDefault(msg, 11, false),
    createdUnix: jspb.Message.getFieldWithDefault(msg, 12, 0),
    updatedUnix: jspb.Message.getFieldWithDefault(msg, 13, 0),
    statusUnix: jspb.Message.getFieldWithDefault(msg, 17, 0),
    subTasksList: jspb.Message.toObjectList(msg.getSubTasksList(),
    proto.cbv1.SubTask.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.TaskSummary}
 */
proto.cbv1.TaskSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.TaskSummary;
  return proto.cbv1.TaskSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.TaskSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.TaskSummary}
 */
proto.cbv1.TaskSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.cbv1.TaskType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new proto_cybertron_site_site_pb.SiteShort;
      reader.readMessage(value,proto_cybertron_site_site_pb.SiteShort.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    case 4:
      var value = new proto_cybertron_payload_payload_pb.Payload;
      reader.readMessage(value,proto_cybertron_payload_payload_pb.Payload.deserializeBinaryFromReader);
      msg.setPayload(value);
      break;
    case 5:
      var value = new proto_cybertron_payload_payload_pb.PayloadBundle;
      reader.readMessage(value,proto_cybertron_payload_payload_pb.PayloadBundle.deserializeBinaryFromReader);
      msg.setPayloadBundle(value);
      break;
    case 6:
      var value = /** @type {!proto.cbv1.TaskStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScheduledUnix(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrderedQuantity(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSequence(value);
      break;
    case 10:
      var value = new proto.cbv1.TaskStats;
      reader.readMessage(value,proto.cbv1.TaskStats.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBillable(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedUnix(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdatedUnix(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatusUnix(value);
      break;
    case 19:
      var value = new proto.cbv1.SubTask;
      reader.readMessage(value,proto.cbv1.SubTask.deserializeBinaryFromReader);
      msg.addSubTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.TaskSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.TaskSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.TaskSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.TaskSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_cybertron_site_site_pb.SiteShort.serializeBinaryToWriter
    );
  }
  f = message.getPayload();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_cybertron_payload_payload_pb.Payload.serializeBinaryToWriter
    );
  }
  f = message.getPayloadBundle();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_cybertron_payload_payload_pb.PayloadBundle.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getScheduledUnix();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getOrderedQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getSequence();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.cbv1.TaskStats.serializeBinaryToWriter
    );
  }
  f = message.getIsBillable();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getUpdatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getStatusUnix();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getSubTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.cbv1.SubTask.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.TaskSummary.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.TaskSummary.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional TaskType type = 2;
 * @return {!proto.cbv1.TaskType}
 */
proto.cbv1.TaskSummary.prototype.getType = function() {
  return /** @type {!proto.cbv1.TaskType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.cbv1.TaskType} value */
proto.cbv1.TaskSummary.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional SiteShort site = 3;
 * @return {?proto.cbv1.SiteShort}
 */
proto.cbv1.TaskSummary.prototype.getSite = function() {
  return /** @type{?proto.cbv1.SiteShort} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_site_site_pb.SiteShort, 3));
};


/** @param {?proto.cbv1.SiteShort|undefined} value */
proto.cbv1.TaskSummary.prototype.setSite = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv1.TaskSummary.prototype.clearSite = function() {
  this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.TaskSummary.prototype.hasSite = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Payload payload = 4;
 * @return {?proto.cbv1.Payload}
 */
proto.cbv1.TaskSummary.prototype.getPayload = function() {
  return /** @type{?proto.cbv1.Payload} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_payload_payload_pb.Payload, 4));
};


/** @param {?proto.cbv1.Payload|undefined} value */
proto.cbv1.TaskSummary.prototype.setPayload = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.cbv1.TaskSummary.prototype.clearPayload = function() {
  this.setPayload(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.TaskSummary.prototype.hasPayload = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PayloadBundle payload_bundle = 5;
 * @return {?proto.cbv1.PayloadBundle}
 */
proto.cbv1.TaskSummary.prototype.getPayloadBundle = function() {
  return /** @type{?proto.cbv1.PayloadBundle} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_payload_payload_pb.PayloadBundle, 5));
};


/** @param {?proto.cbv1.PayloadBundle|undefined} value */
proto.cbv1.TaskSummary.prototype.setPayloadBundle = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.cbv1.TaskSummary.prototype.clearPayloadBundle = function() {
  this.setPayloadBundle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.TaskSummary.prototype.hasPayloadBundle = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional TaskStatus status = 6;
 * @return {!proto.cbv1.TaskStatus}
 */
proto.cbv1.TaskSummary.prototype.getStatus = function() {
  return /** @type {!proto.cbv1.TaskStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.cbv1.TaskStatus} value */
proto.cbv1.TaskSummary.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int32 scheduled_unix = 7;
 * @return {number}
 */
proto.cbv1.TaskSummary.prototype.getScheduledUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.TaskSummary.prototype.setScheduledUnix = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional double ordered_quantity = 8;
 * @return {number}
 */
proto.cbv1.TaskSummary.prototype.getOrderedQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 8, 0.0));
};


/** @param {number} value */
proto.cbv1.TaskSummary.prototype.setOrderedQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional int32 sequence = 9;
 * @return {number}
 */
proto.cbv1.TaskSummary.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cbv1.TaskSummary.prototype.setSequence = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional TaskStats stats = 10;
 * @return {?proto.cbv1.TaskStats}
 */
proto.cbv1.TaskSummary.prototype.getStats = function() {
  return /** @type{?proto.cbv1.TaskStats} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.TaskStats, 10));
};


/** @param {?proto.cbv1.TaskStats|undefined} value */
proto.cbv1.TaskSummary.prototype.setStats = function(value) {
  jspb.Message.setWrapperField(this, 10, value);
};


proto.cbv1.TaskSummary.prototype.clearStats = function() {
  this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.TaskSummary.prototype.hasStats = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool is_billable = 11;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.TaskSummary.prototype.getIsBillable = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 11, false));
};


/** @param {boolean} value */
proto.cbv1.TaskSummary.prototype.setIsBillable = function(value) {
  jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int32 created_unix = 12;
 * @return {number}
 */
proto.cbv1.TaskSummary.prototype.getCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.cbv1.TaskSummary.prototype.setCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 updated_unix = 13;
 * @return {number}
 */
proto.cbv1.TaskSummary.prototype.getUpdatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.cbv1.TaskSummary.prototype.setUpdatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 status_unix = 17;
 * @return {number}
 */
proto.cbv1.TaskSummary.prototype.getStatusUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {number} value */
proto.cbv1.TaskSummary.prototype.setStatusUnix = function(value) {
  jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * repeated SubTask sub_tasks = 19;
 * @return {!Array<!proto.cbv1.SubTask>}
 */
proto.cbv1.TaskSummary.prototype.getSubTasksList = function() {
  return /** @type{!Array<!proto.cbv1.SubTask>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.SubTask, 19));
};


/** @param {!Array<!proto.cbv1.SubTask>} value */
proto.cbv1.TaskSummary.prototype.setSubTasksList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.cbv1.SubTask=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.SubTask}
 */
proto.cbv1.TaskSummary.prototype.addSubTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.cbv1.SubTask, opt_index);
};


proto.cbv1.TaskSummary.prototype.clearSubTasksList = function() {
  this.setSubTasksList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.MinutesStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.MinutesStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.MinutesStats.displayName = 'proto.cbv1.MinutesStats';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.MinutesStats.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.MinutesStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.MinutesStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MinutesStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    minutesValid: jspb.Message.getFieldWithDefault(msg, 1, false),
    minutesLate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    minutesDelayed: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.MinutesStats}
 */
proto.cbv1.MinutesStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.MinutesStats;
  return proto.cbv1.MinutesStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.MinutesStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.MinutesStats}
 */
proto.cbv1.MinutesStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMinutesValid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinutesLate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinutesDelayed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.MinutesStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.MinutesStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.MinutesStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MinutesStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinutesValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMinutesLate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMinutesDelayed();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional bool minutes_valid = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.MinutesStats.prototype.getMinutesValid = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.MinutesStats.prototype.setMinutesValid = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 minutes_late = 2;
 * @return {number}
 */
proto.cbv1.MinutesStats.prototype.getMinutesLate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.MinutesStats.prototype.setMinutesLate = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 minutes_delayed = 3;
 * @return {number}
 */
proto.cbv1.MinutesStats.prototype.getMinutesDelayed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.MinutesStats.prototype.setMinutesDelayed = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.FleetManagerPlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.FleetManagerPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.FleetManagerPlan.displayName = 'proto.cbv1.FleetManagerPlan';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.FleetManagerPlan.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.FleetManagerPlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.FleetManagerPlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FleetManagerPlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    driverValid: jspb.Message.getFieldWithDefault(msg, 1, false),
    driverUid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    driverName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    plannedStartUnix: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.FleetManagerPlan}
 */
proto.cbv1.FleetManagerPlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.FleetManagerPlan;
  return proto.cbv1.FleetManagerPlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.FleetManagerPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.FleetManagerPlan}
 */
proto.cbv1.FleetManagerPlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDriverValid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverUid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlannedStartUnix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.FleetManagerPlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.FleetManagerPlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.FleetManagerPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FleetManagerPlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriverValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDriverUid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDriverName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlannedStartUnix();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional bool driver_valid = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.FleetManagerPlan.prototype.getDriverValid = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.FleetManagerPlan.prototype.setDriverValid = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string driver_uid = 2;
 * @return {string}
 */
proto.cbv1.FleetManagerPlan.prototype.getDriverUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.FleetManagerPlan.prototype.setDriverUid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string driver_name = 3;
 * @return {string}
 */
proto.cbv1.FleetManagerPlan.prototype.getDriverName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.FleetManagerPlan.prototype.setDriverName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 planned_start_unix = 4;
 * @return {number}
 */
proto.cbv1.FleetManagerPlan.prototype.getPlannedStartUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.FleetManagerPlan.prototype.setPlannedStartUnix = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.MappableOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.MappableOrder.repeatedFields_, null);
};
goog.inherits(proto.cbv1.MappableOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.MappableOrder.displayName = 'proto.cbv1.MappableOrder';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.MappableOrder.repeatedFields_ = [5,24];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.MappableOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.MappableOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.MappableOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MappableOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vendorName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    truckName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    driver: (f = msg.getDriver()) && proto_cybertron_user_user_pb.Driver.toObject(includeInstance, f),
    taskSummariesList: jspb.Message.toObjectList(msg.getTaskSummariesList(),
    proto.cbv1.TaskSummary.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    createdUnix: jspb.Message.getFieldWithDefault(msg, 7, 0),
    updatedUnix: jspb.Message.getFieldWithDefault(msg, 8, 0),
    trailerName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    statusUnix: jspb.Message.getFieldWithDefault(msg, 10, 0),
    orderStats: (f = msg.getOrderStats()) && proto.cbv1.OrderStats.toObject(includeInstance, f),
    isAsap: jspb.Message.getFieldWithDefault(msg, 17, false),
    upForGrabsUnix: jspb.Message.getFieldWithDefault(msg, 18, 0),
    isUpForGrabs: jspb.Message.getFieldWithDefault(msg, 19, ""),
    saUniqueId: jspb.Message.getFieldWithDefault(msg, 20, ""),
    minutesStats: (f = msg.getMinutesStats()) && proto.cbv1.MinutesStats.toObject(includeInstance, f),
    fleetManagerPlan: (f = msg.getFleetManagerPlan()) && proto.cbv1.FleetManagerPlan.toObject(includeInstance, f),
    isAvailableToDispatch: jspb.Message.getFieldWithDefault(msg, 23, false),
    driverCertificationsList: jspb.Message.toObjectList(msg.getDriverCertificationsList(),
    proto_cybertron_drivercertification_driverCertification_pb.DriverCertification.toObject, includeInstance),
    displayId: jspb.Message.getFieldWithDefault(msg, 25, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.MappableOrder}
 */
proto.cbv1.MappableOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.MappableOrder;
  return proto.cbv1.MappableOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.MappableOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.MappableOrder}
 */
proto.cbv1.MappableOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendorName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTruckName(value);
      break;
    case 4:
      var value = new proto_cybertron_user_user_pb.Driver;
      reader.readMessage(value,proto_cybertron_user_user_pb.Driver.deserializeBinaryFromReader);
      msg.setDriver(value);
      break;
    case 5:
      var value = new proto.cbv1.TaskSummary;
      reader.readMessage(value,proto.cbv1.TaskSummary.deserializeBinaryFromReader);
      msg.addTaskSummaries(value);
      break;
    case 6:
      var value = /** @type {!proto.cbv1.OrderStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedUnix(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdatedUnix(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerName(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatusUnix(value);
      break;
    case 15:
      var value = new proto.cbv1.OrderStats;
      reader.readMessage(value,proto.cbv1.OrderStats.deserializeBinaryFromReader);
      msg.setOrderStats(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAsap(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpForGrabsUnix(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsUpForGrabs(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setSaUniqueId(value);
      break;
    case 21:
      var value = new proto.cbv1.MinutesStats;
      reader.readMessage(value,proto.cbv1.MinutesStats.deserializeBinaryFromReader);
      msg.setMinutesStats(value);
      break;
    case 22:
      var value = new proto.cbv1.FleetManagerPlan;
      reader.readMessage(value,proto.cbv1.FleetManagerPlan.deserializeBinaryFromReader);
      msg.setFleetManagerPlan(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAvailableToDispatch(value);
      break;
    case 24:
      var value = new proto_cybertron_drivercertification_driverCertification_pb.DriverCertification;
      reader.readMessage(value,proto_cybertron_drivercertification_driverCertification_pb.DriverCertification.deserializeBinaryFromReader);
      msg.addDriverCertifications(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.MappableOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.MappableOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.MappableOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MappableOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVendorName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTruckName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDriver();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_cybertron_user_user_pb.Driver.serializeBinaryToWriter
    );
  }
  f = message.getTaskSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.cbv1.TaskSummary.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getUpdatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getTrailerName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStatusUnix();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getOrderStats();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.cbv1.OrderStats.serializeBinaryToWriter
    );
  }
  f = message.getIsAsap();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getUpForGrabsUnix();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getIsUpForGrabs();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getSaUniqueId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getMinutesStats();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.cbv1.MinutesStats.serializeBinaryToWriter
    );
  }
  f = message.getFleetManagerPlan();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.cbv1.FleetManagerPlan.serializeBinaryToWriter
    );
  }
  f = message.getIsAvailableToDispatch();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getDriverCertificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      24,
      f,
      proto_cybertron_drivercertification_driverCertification_pb.DriverCertification.serializeBinaryToWriter
    );
  }
  f = message.getDisplayId();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.MappableOrder.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.MappableOrder.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string vendor_name = 2;
 * @return {string}
 */
proto.cbv1.MappableOrder.prototype.getVendorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.MappableOrder.prototype.setVendorName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string truck_name = 3;
 * @return {string}
 */
proto.cbv1.MappableOrder.prototype.getTruckName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.MappableOrder.prototype.setTruckName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Driver driver = 4;
 * @return {?proto.cbv1.Driver}
 */
proto.cbv1.MappableOrder.prototype.getDriver = function() {
  return /** @type{?proto.cbv1.Driver} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_user_user_pb.Driver, 4));
};


/** @param {?proto.cbv1.Driver|undefined} value */
proto.cbv1.MappableOrder.prototype.setDriver = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.cbv1.MappableOrder.prototype.clearDriver = function() {
  this.setDriver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.MappableOrder.prototype.hasDriver = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated TaskSummary task_summaries = 5;
 * @return {!Array<!proto.cbv1.TaskSummary>}
 */
proto.cbv1.MappableOrder.prototype.getTaskSummariesList = function() {
  return /** @type{!Array<!proto.cbv1.TaskSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.TaskSummary, 5));
};


/** @param {!Array<!proto.cbv1.TaskSummary>} value */
proto.cbv1.MappableOrder.prototype.setTaskSummariesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.cbv1.TaskSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.TaskSummary}
 */
proto.cbv1.MappableOrder.prototype.addTaskSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.cbv1.TaskSummary, opt_index);
};


proto.cbv1.MappableOrder.prototype.clearTaskSummariesList = function() {
  this.setTaskSummariesList([]);
};


/**
 * optional OrderStatus status = 6;
 * @return {!proto.cbv1.OrderStatus}
 */
proto.cbv1.MappableOrder.prototype.getStatus = function() {
  return /** @type {!proto.cbv1.OrderStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.cbv1.OrderStatus} value */
proto.cbv1.MappableOrder.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int32 created_unix = 7;
 * @return {number}
 */
proto.cbv1.MappableOrder.prototype.getCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.MappableOrder.prototype.setCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 updated_unix = 8;
 * @return {number}
 */
proto.cbv1.MappableOrder.prototype.getUpdatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cbv1.MappableOrder.prototype.setUpdatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string trailer_name = 9;
 * @return {string}
 */
proto.cbv1.MappableOrder.prototype.getTrailerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.cbv1.MappableOrder.prototype.setTrailerName = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 status_unix = 10;
 * @return {number}
 */
proto.cbv1.MappableOrder.prototype.getStatusUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cbv1.MappableOrder.prototype.setStatusUnix = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional OrderStats order_stats = 15;
 * @return {?proto.cbv1.OrderStats}
 */
proto.cbv1.MappableOrder.prototype.getOrderStats = function() {
  return /** @type{?proto.cbv1.OrderStats} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.OrderStats, 15));
};


/** @param {?proto.cbv1.OrderStats|undefined} value */
proto.cbv1.MappableOrder.prototype.setOrderStats = function(value) {
  jspb.Message.setWrapperField(this, 15, value);
};


proto.cbv1.MappableOrder.prototype.clearOrderStats = function() {
  this.setOrderStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.MappableOrder.prototype.hasOrderStats = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bool is_asap = 17;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.MappableOrder.prototype.getIsAsap = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 17, false));
};


/** @param {boolean} value */
proto.cbv1.MappableOrder.prototype.setIsAsap = function(value) {
  jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional int64 up_for_grabs_unix = 18;
 * @return {number}
 */
proto.cbv1.MappableOrder.prototype.getUpForGrabsUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/** @param {number} value */
proto.cbv1.MappableOrder.prototype.setUpForGrabsUnix = function(value) {
  jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string is_up_for_grabs = 19;
 * @return {string}
 */
proto.cbv1.MappableOrder.prototype.getIsUpForGrabs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/** @param {string} value */
proto.cbv1.MappableOrder.prototype.setIsUpForGrabs = function(value) {
  jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string sa_unique_id = 20;
 * @return {string}
 */
proto.cbv1.MappableOrder.prototype.getSaUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/** @param {string} value */
proto.cbv1.MappableOrder.prototype.setSaUniqueId = function(value) {
  jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional MinutesStats minutes_stats = 21;
 * @return {?proto.cbv1.MinutesStats}
 */
proto.cbv1.MappableOrder.prototype.getMinutesStats = function() {
  return /** @type{?proto.cbv1.MinutesStats} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.MinutesStats, 21));
};


/** @param {?proto.cbv1.MinutesStats|undefined} value */
proto.cbv1.MappableOrder.prototype.setMinutesStats = function(value) {
  jspb.Message.setWrapperField(this, 21, value);
};


proto.cbv1.MappableOrder.prototype.clearMinutesStats = function() {
  this.setMinutesStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.MappableOrder.prototype.hasMinutesStats = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional FleetManagerPlan fleet_manager_plan = 22;
 * @return {?proto.cbv1.FleetManagerPlan}
 */
proto.cbv1.MappableOrder.prototype.getFleetManagerPlan = function() {
  return /** @type{?proto.cbv1.FleetManagerPlan} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.FleetManagerPlan, 22));
};


/** @param {?proto.cbv1.FleetManagerPlan|undefined} value */
proto.cbv1.MappableOrder.prototype.setFleetManagerPlan = function(value) {
  jspb.Message.setWrapperField(this, 22, value);
};


proto.cbv1.MappableOrder.prototype.clearFleetManagerPlan = function() {
  this.setFleetManagerPlan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.MappableOrder.prototype.hasFleetManagerPlan = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional bool is_available_to_dispatch = 23;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.MappableOrder.prototype.getIsAvailableToDispatch = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 23, false));
};


/** @param {boolean} value */
proto.cbv1.MappableOrder.prototype.setIsAvailableToDispatch = function(value) {
  jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * repeated DriverCertification driver_certifications = 24;
 * @return {!Array<!proto.cbv1.DriverCertification>}
 */
proto.cbv1.MappableOrder.prototype.getDriverCertificationsList = function() {
  return /** @type{!Array<!proto.cbv1.DriverCertification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_drivercertification_driverCertification_pb.DriverCertification, 24));
};


/** @param {!Array<!proto.cbv1.DriverCertification>} value */
proto.cbv1.MappableOrder.prototype.setDriverCertificationsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 24, value);
};


/**
 * @param {!proto.cbv1.DriverCertification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.DriverCertification}
 */
proto.cbv1.MappableOrder.prototype.addDriverCertifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, proto.cbv1.DriverCertification, opt_index);
};


proto.cbv1.MappableOrder.prototype.clearDriverCertificationsList = function() {
  this.setDriverCertificationsList([]);
};


/**
 * optional string display_id = 25;
 * @return {string}
 */
proto.cbv1.MappableOrder.prototype.getDisplayId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/** @param {string} value */
proto.cbv1.MappableOrder.prototype.setDisplayId = function(value) {
  jspb.Message.setProto3StringField(this, 25, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.MappableOrderCompleted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.MappableOrderCompleted.repeatedFields_, null);
};
goog.inherits(proto.cbv1.MappableOrderCompleted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.MappableOrderCompleted.displayName = 'proto.cbv1.MappableOrderCompleted';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.MappableOrderCompleted.repeatedFields_ = [5,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.MappableOrderCompleted.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.MappableOrderCompleted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.MappableOrderCompleted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MappableOrderCompleted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vendorName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    truckName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    driver: (f = msg.getDriver()) && proto_cybertron_user_user_pb.Driver.toObject(includeInstance, f),
    taskSummariesList: jspb.Message.toObjectList(msg.getTaskSummariesList(),
    proto.cbv1.TaskSummary.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    createdUnix: jspb.Message.getFieldWithDefault(msg, 7, 0),
    updatedUnix: jspb.Message.getFieldWithDefault(msg, 8, 0),
    trailerName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    statusUnix: jspb.Message.getFieldWithDefault(msg, 10, 0),
    missingDataList: jspb.Message.getRepeatedField(msg, 11),
    orderStats: (f = msg.getOrderStats()) && proto.cbv1.OrderStats.toObject(includeInstance, f),
    isAsap: jspb.Message.getFieldWithDefault(msg, 17, false),
    saUniqueId: jspb.Message.getFieldWithDefault(msg, 18, ""),
    displayId: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.MappableOrderCompleted}
 */
proto.cbv1.MappableOrderCompleted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.MappableOrderCompleted;
  return proto.cbv1.MappableOrderCompleted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.MappableOrderCompleted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.MappableOrderCompleted}
 */
proto.cbv1.MappableOrderCompleted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendorName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTruckName(value);
      break;
    case 4:
      var value = new proto_cybertron_user_user_pb.Driver;
      reader.readMessage(value,proto_cybertron_user_user_pb.Driver.deserializeBinaryFromReader);
      msg.setDriver(value);
      break;
    case 5:
      var value = new proto.cbv1.TaskSummary;
      reader.readMessage(value,proto.cbv1.TaskSummary.deserializeBinaryFromReader);
      msg.addTaskSummaries(value);
      break;
    case 6:
      var value = /** @type {!proto.cbv1.OrderStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedUnix(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdatedUnix(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerName(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatusUnix(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addMissingData(value);
      break;
    case 15:
      var value = new proto.cbv1.OrderStats;
      reader.readMessage(value,proto.cbv1.OrderStats.deserializeBinaryFromReader);
      msg.setOrderStats(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAsap(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setSaUniqueId(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.MappableOrderCompleted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.MappableOrderCompleted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.MappableOrderCompleted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MappableOrderCompleted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVendorName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTruckName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDriver();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_cybertron_user_user_pb.Driver.serializeBinaryToWriter
    );
  }
  f = message.getTaskSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.cbv1.TaskSummary.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getUpdatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getTrailerName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStatusUnix();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getMissingDataList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getOrderStats();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.cbv1.OrderStats.serializeBinaryToWriter
    );
  }
  f = message.getIsAsap();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getSaUniqueId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getDisplayId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.MappableOrderCompleted.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.MappableOrderCompleted.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string vendor_name = 2;
 * @return {string}
 */
proto.cbv1.MappableOrderCompleted.prototype.getVendorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.MappableOrderCompleted.prototype.setVendorName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string truck_name = 3;
 * @return {string}
 */
proto.cbv1.MappableOrderCompleted.prototype.getTruckName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.MappableOrderCompleted.prototype.setTruckName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Driver driver = 4;
 * @return {?proto.cbv1.Driver}
 */
proto.cbv1.MappableOrderCompleted.prototype.getDriver = function() {
  return /** @type{?proto.cbv1.Driver} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_user_user_pb.Driver, 4));
};


/** @param {?proto.cbv1.Driver|undefined} value */
proto.cbv1.MappableOrderCompleted.prototype.setDriver = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.cbv1.MappableOrderCompleted.prototype.clearDriver = function() {
  this.setDriver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.MappableOrderCompleted.prototype.hasDriver = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated TaskSummary task_summaries = 5;
 * @return {!Array<!proto.cbv1.TaskSummary>}
 */
proto.cbv1.MappableOrderCompleted.prototype.getTaskSummariesList = function() {
  return /** @type{!Array<!proto.cbv1.TaskSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.TaskSummary, 5));
};


/** @param {!Array<!proto.cbv1.TaskSummary>} value */
proto.cbv1.MappableOrderCompleted.prototype.setTaskSummariesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.cbv1.TaskSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.TaskSummary}
 */
proto.cbv1.MappableOrderCompleted.prototype.addTaskSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.cbv1.TaskSummary, opt_index);
};


proto.cbv1.MappableOrderCompleted.prototype.clearTaskSummariesList = function() {
  this.setTaskSummariesList([]);
};


/**
 * optional OrderStatus status = 6;
 * @return {!proto.cbv1.OrderStatus}
 */
proto.cbv1.MappableOrderCompleted.prototype.getStatus = function() {
  return /** @type {!proto.cbv1.OrderStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.cbv1.OrderStatus} value */
proto.cbv1.MappableOrderCompleted.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int32 created_unix = 7;
 * @return {number}
 */
proto.cbv1.MappableOrderCompleted.prototype.getCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.MappableOrderCompleted.prototype.setCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 updated_unix = 8;
 * @return {number}
 */
proto.cbv1.MappableOrderCompleted.prototype.getUpdatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cbv1.MappableOrderCompleted.prototype.setUpdatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string trailer_name = 9;
 * @return {string}
 */
proto.cbv1.MappableOrderCompleted.prototype.getTrailerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.cbv1.MappableOrderCompleted.prototype.setTrailerName = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 status_unix = 10;
 * @return {number}
 */
proto.cbv1.MappableOrderCompleted.prototype.getStatusUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cbv1.MappableOrderCompleted.prototype.setStatusUnix = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated string missing_data = 11;
 * @return {!Array<string>}
 */
proto.cbv1.MappableOrderCompleted.prototype.getMissingDataList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/** @param {!Array<string>} value */
proto.cbv1.MappableOrderCompleted.prototype.setMissingDataList = function(value) {
  jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.MappableOrderCompleted.prototype.addMissingData = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


proto.cbv1.MappableOrderCompleted.prototype.clearMissingDataList = function() {
  this.setMissingDataList([]);
};


/**
 * optional OrderStats order_stats = 15;
 * @return {?proto.cbv1.OrderStats}
 */
proto.cbv1.MappableOrderCompleted.prototype.getOrderStats = function() {
  return /** @type{?proto.cbv1.OrderStats} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.OrderStats, 15));
};


/** @param {?proto.cbv1.OrderStats|undefined} value */
proto.cbv1.MappableOrderCompleted.prototype.setOrderStats = function(value) {
  jspb.Message.setWrapperField(this, 15, value);
};


proto.cbv1.MappableOrderCompleted.prototype.clearOrderStats = function() {
  this.setOrderStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.MappableOrderCompleted.prototype.hasOrderStats = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bool is_asap = 17;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.MappableOrderCompleted.prototype.getIsAsap = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 17, false));
};


/** @param {boolean} value */
proto.cbv1.MappableOrderCompleted.prototype.setIsAsap = function(value) {
  jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional string sa_unique_id = 18;
 * @return {string}
 */
proto.cbv1.MappableOrderCompleted.prototype.getSaUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/** @param {string} value */
proto.cbv1.MappableOrderCompleted.prototype.setSaUniqueId = function(value) {
  jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string display_id = 19;
 * @return {string}
 */
proto.cbv1.MappableOrderCompleted.prototype.getDisplayId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/** @param {string} value */
proto.cbv1.MappableOrderCompleted.prototype.setDisplayId = function(value) {
  jspb.Message.setProto3StringField(this, 19, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.VendorMappableOrderCompletedSlim = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.VendorMappableOrderCompletedSlim.repeatedFields_, null);
};
goog.inherits(proto.cbv1.VendorMappableOrderCompletedSlim, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.VendorMappableOrderCompletedSlim.displayName = 'proto.cbv1.VendorMappableOrderCompletedSlim';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.VendorMappableOrderCompletedSlim.repeatedFields_ = [11,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.VendorMappableOrderCompletedSlim.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.VendorMappableOrderCompletedSlim} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorMappableOrderCompletedSlim.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    driverName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    statusUnix: jspb.Message.getFieldWithDefault(msg, 4, 0),
    orderedQuantity: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    payloadName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    payloadUnitsName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    payloadUnitsAbbreviation: jspb.Message.getFieldWithDefault(msg, 8, ""),
    truckName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    trailerName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    missingDataList: jspb.Message.getRepeatedField(msg, 11),
    updatedUnix: jspb.Message.getFieldWithDefault(msg, 12, 0),
    saUniqueId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    taskSummariesList: jspb.Message.toObjectList(msg.getTaskSummariesList(),
    proto.cbv1.DispatcherCalendarInProgressTaskSummary.toObject, includeInstance),
    displayLoadId: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.VendorMappableOrderCompletedSlim}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.VendorMappableOrderCompletedSlim;
  return proto.cbv1.VendorMappableOrderCompletedSlim.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.VendorMappableOrderCompletedSlim} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.VendorMappableOrderCompletedSlim}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.cbv1.OrderStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatusUnix(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrderedQuantity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayloadName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayloadUnitsName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayloadUnitsAbbreviation(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTruckName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addMissingData(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedUnix(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSaUniqueId(value);
      break;
    case 14:
      var value = new proto.cbv1.DispatcherCalendarInProgressTaskSummary;
      reader.readMessage(value,proto.cbv1.DispatcherCalendarInProgressTaskSummary.deserializeBinaryFromReader);
      msg.addTaskSummaries(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayLoadId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.VendorMappableOrderCompletedSlim.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.VendorMappableOrderCompletedSlim} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.VendorMappableOrderCompletedSlim.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDriverName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatusUnix();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOrderedQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getPayloadName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPayloadUnitsName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPayloadUnitsAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTruckName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTrailerName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMissingDataList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getUpdatedUnix();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getSaUniqueId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTaskSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.cbv1.DispatcherCalendarInProgressTaskSummary.serializeBinaryToWriter
    );
  }
  f = message.getDisplayLoadId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional OrderStatus status = 2;
 * @return {!proto.cbv1.OrderStatus}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.getStatus = function() {
  return /** @type {!proto.cbv1.OrderStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.cbv1.OrderStatus} value */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string driver_name = 3;
 * @return {string}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.getDriverName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.setDriverName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 status_unix = 4;
 * @return {number}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.getStatusUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.setStatusUnix = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double ordered_quantity = 5;
 * @return {number}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.getOrderedQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.setOrderedQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string payload_name = 6;
 * @return {string}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.getPayloadName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.setPayloadName = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string payload_units_name = 7;
 * @return {string}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.getPayloadUnitsName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.setPayloadUnitsName = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string payload_units_abbreviation = 8;
 * @return {string}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.getPayloadUnitsAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.setPayloadUnitsAbbreviation = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string truck_name = 9;
 * @return {string}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.getTruckName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.setTruckName = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string trailer_name = 10;
 * @return {string}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.getTrailerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.setTrailerName = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated string missing_data = 11;
 * @return {!Array<string>}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.getMissingDataList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/** @param {!Array<string>} value */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.setMissingDataList = function(value) {
  jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.addMissingData = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


proto.cbv1.VendorMappableOrderCompletedSlim.prototype.clearMissingDataList = function() {
  this.setMissingDataList([]);
};


/**
 * optional int64 updated_unix = 12;
 * @return {number}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.getUpdatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.setUpdatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string sa_unique_id = 13;
 * @return {string}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.getSaUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.setSaUniqueId = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated DispatcherCalendarInProgressTaskSummary task_summaries = 14;
 * @return {!Array<!proto.cbv1.DispatcherCalendarInProgressTaskSummary>}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.getTaskSummariesList = function() {
  return /** @type{!Array<!proto.cbv1.DispatcherCalendarInProgressTaskSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.DispatcherCalendarInProgressTaskSummary, 14));
};


/** @param {!Array<!proto.cbv1.DispatcherCalendarInProgressTaskSummary>} value */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.setTaskSummariesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.cbv1.DispatcherCalendarInProgressTaskSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.DispatcherCalendarInProgressTaskSummary}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.addTaskSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.cbv1.DispatcherCalendarInProgressTaskSummary, opt_index);
};


proto.cbv1.VendorMappableOrderCompletedSlim.prototype.clearTaskSummariesList = function() {
  this.setTaskSummariesList([]);
};


/**
 * optional string display_load_id = 15;
 * @return {string}
 */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.getDisplayLoadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.cbv1.VendorMappableOrderCompletedSlim.prototype.setDisplayLoadId = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.MappableOrderEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.MappableOrderEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.MappableOrderEvent.displayName = 'proto.cbv1.MappableOrderEvent';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.MappableOrderEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.MappableOrderEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.MappableOrderEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MappableOrderEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: jspb.Message.getFieldWithDefault(msg, 1, ""),
    order: (f = msg.getOrder()) && proto.cbv1.MappableOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.MappableOrderEvent}
 */
proto.cbv1.MappableOrderEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.MappableOrderEvent;
  return proto.cbv1.MappableOrderEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.MappableOrderEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.MappableOrderEvent}
 */
proto.cbv1.MappableOrderEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvent(value);
      break;
    case 2:
      var value = new proto.cbv1.MappableOrder;
      reader.readMessage(value,proto.cbv1.MappableOrder.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.MappableOrderEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.MappableOrderEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.MappableOrderEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.MappableOrderEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.cbv1.MappableOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional string event = 1;
 * @return {string}
 */
proto.cbv1.MappableOrderEvent.prototype.getEvent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.MappableOrderEvent.prototype.setEvent = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MappableOrder order = 2;
 * @return {?proto.cbv1.MappableOrder}
 */
proto.cbv1.MappableOrderEvent.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.MappableOrder} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.MappableOrder, 2));
};


/** @param {?proto.cbv1.MappableOrder|undefined} value */
proto.cbv1.MappableOrderEvent.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cbv1.MappableOrderEvent.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.MappableOrderEvent.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DriverOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.DriverOrder.repeatedFields_, null);
};
goog.inherits(proto.cbv1.DriverOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DriverOrder.displayName = 'proto.cbv1.DriverOrder';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.DriverOrder.repeatedFields_ = [4,12,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DriverOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DriverOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DriverOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lmo: (f = msg.getLmo()) && proto_cybertron_account_account_pb.Account.toObject(includeInstance, f),
    truckingVendor: (f = msg.getTruckingVendor()) && proto_cybertron_account_account_pb.Account.toObject(includeInstance, f),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.cbv1.Task.toObject, includeInstance),
    driver: (f = msg.getDriver()) && proto_cybertron_user_user_pb.Driver.toObject(includeInstance, f),
    truck: (f = msg.getTruck()) && proto_cybertron_truck_truck_pb.Truck.toObject(includeInstance, f),
    trailer: (f = msg.getTrailer()) && proto_cybertron_trailer_trailer_pb.Trailer.toObject(includeInstance, f),
    loadNumber: jspb.Message.getFieldWithDefault(msg, 8, 0),
    description: jspb.Message.getFieldWithDefault(msg, 10, ""),
    status: jspb.Message.getFieldWithDefault(msg, 11, 0),
    uploadedFilesList: jspb.Message.toObjectList(msg.getUploadedFilesList(),
    proto.cbv1.DriverAttachment.toObject, includeInstance),
    statusUnix: jspb.Message.getFieldWithDefault(msg, 13, 0),
    scheduledUnix: jspb.Message.getFieldWithDefault(msg, 14, 0),
    createdUnix: jspb.Message.getFieldWithDefault(msg, 15, 0),
    updatedUnix: jspb.Message.getFieldWithDefault(msg, 16, 0),
    isAsap: jspb.Message.getFieldWithDefault(msg, 17, false),
    autoCloseRecommended: jspb.Message.getFieldWithDefault(msg, 18, false),
    canUploadDocuments: jspb.Message.getFieldWithDefault(msg, 19, false),
    saUniqueId: jspb.Message.getFieldWithDefault(msg, 20, ""),
    canEditOrder: jspb.Message.getFieldWithDefault(msg, 21, false),
    driverCertificationsList: jspb.Message.toObjectList(msg.getDriverCertificationsList(),
    proto_cybertron_drivercertification_driverCertification_pb.DriverCertification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DriverOrder}
 */
proto.cbv1.DriverOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DriverOrder;
  return proto.cbv1.DriverOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DriverOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DriverOrder}
 */
proto.cbv1.DriverOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_cybertron_account_account_pb.Account;
      reader.readMessage(value,proto_cybertron_account_account_pb.Account.deserializeBinaryFromReader);
      msg.setLmo(value);
      break;
    case 3:
      var value = new proto_cybertron_account_account_pb.Account;
      reader.readMessage(value,proto_cybertron_account_account_pb.Account.deserializeBinaryFromReader);
      msg.setTruckingVendor(value);
      break;
    case 4:
      var value = new proto.cbv1.Task;
      reader.readMessage(value,proto.cbv1.Task.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    case 5:
      var value = new proto_cybertron_user_user_pb.Driver;
      reader.readMessage(value,proto_cybertron_user_user_pb.Driver.deserializeBinaryFromReader);
      msg.setDriver(value);
      break;
    case 6:
      var value = new proto_cybertron_truck_truck_pb.Truck;
      reader.readMessage(value,proto_cybertron_truck_truck_pb.Truck.deserializeBinaryFromReader);
      msg.setTruck(value);
      break;
    case 7:
      var value = new proto_cybertron_trailer_trailer_pb.Trailer;
      reader.readMessage(value,proto_cybertron_trailer_trailer_pb.Trailer.deserializeBinaryFromReader);
      msg.setTrailer(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLoadNumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 11:
      var value = /** @type {!proto.cbv1.OrderStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 12:
      var value = new proto.cbv1.DriverAttachment;
      reader.readMessage(value,proto.cbv1.DriverAttachment.deserializeBinaryFromReader);
      msg.addUploadedFiles(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatusUnix(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScheduledUnix(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedUnix(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdatedUnix(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAsap(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoCloseRecommended(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanUploadDocuments(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setSaUniqueId(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanEditOrder(value);
      break;
    case 22:
      var value = new proto_cybertron_drivercertification_driverCertification_pb.DriverCertification;
      reader.readMessage(value,proto_cybertron_drivercertification_driverCertification_pb.DriverCertification.deserializeBinaryFromReader);
      msg.addDriverCertifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DriverOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DriverOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DriverOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLmo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_cybertron_account_account_pb.Account.serializeBinaryToWriter
    );
  }
  f = message.getTruckingVendor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_cybertron_account_account_pb.Account.serializeBinaryToWriter
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cbv1.Task.serializeBinaryToWriter
    );
  }
  f = message.getDriver();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_cybertron_user_user_pb.Driver.serializeBinaryToWriter
    );
  }
  f = message.getTruck();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_cybertron_truck_truck_pb.Truck.serializeBinaryToWriter
    );
  }
  f = message.getTrailer();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_cybertron_trailer_trailer_pb.Trailer.serializeBinaryToWriter
    );
  }
  f = message.getLoadNumber();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getUploadedFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.cbv1.DriverAttachment.serializeBinaryToWriter
    );
  }
  f = message.getStatusUnix();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getScheduledUnix();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getCreatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getUpdatedUnix();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getIsAsap();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getAutoCloseRecommended();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getCanUploadDocuments();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getSaUniqueId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getCanEditOrder();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getDriverCertificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto_cybertron_drivercertification_driverCertification_pb.DriverCertification.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.DriverOrder.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.DriverOrder.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Account lmo = 2;
 * @return {?proto.cbv1.Account}
 */
proto.cbv1.DriverOrder.prototype.getLmo = function() {
  return /** @type{?proto.cbv1.Account} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_account_account_pb.Account, 2));
};


/** @param {?proto.cbv1.Account|undefined} value */
proto.cbv1.DriverOrder.prototype.setLmo = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cbv1.DriverOrder.prototype.clearLmo = function() {
  this.setLmo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.DriverOrder.prototype.hasLmo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Account trucking_vendor = 3;
 * @return {?proto.cbv1.Account}
 */
proto.cbv1.DriverOrder.prototype.getTruckingVendor = function() {
  return /** @type{?proto.cbv1.Account} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_account_account_pb.Account, 3));
};


/** @param {?proto.cbv1.Account|undefined} value */
proto.cbv1.DriverOrder.prototype.setTruckingVendor = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv1.DriverOrder.prototype.clearTruckingVendor = function() {
  this.setTruckingVendor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.DriverOrder.prototype.hasTruckingVendor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Task tasks = 4;
 * @return {!Array<!proto.cbv1.Task>}
 */
proto.cbv1.DriverOrder.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.cbv1.Task>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.Task, 4));
};


/** @param {!Array<!proto.cbv1.Task>} value */
proto.cbv1.DriverOrder.prototype.setTasksList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cbv1.Task=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.Task}
 */
proto.cbv1.DriverOrder.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cbv1.Task, opt_index);
};


proto.cbv1.DriverOrder.prototype.clearTasksList = function() {
  this.setTasksList([]);
};


/**
 * optional Driver driver = 5;
 * @return {?proto.cbv1.Driver}
 */
proto.cbv1.DriverOrder.prototype.getDriver = function() {
  return /** @type{?proto.cbv1.Driver} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_user_user_pb.Driver, 5));
};


/** @param {?proto.cbv1.Driver|undefined} value */
proto.cbv1.DriverOrder.prototype.setDriver = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.cbv1.DriverOrder.prototype.clearDriver = function() {
  this.setDriver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.DriverOrder.prototype.hasDriver = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Truck truck = 6;
 * @return {?proto.cbv1.Truck}
 */
proto.cbv1.DriverOrder.prototype.getTruck = function() {
  return /** @type{?proto.cbv1.Truck} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_truck_truck_pb.Truck, 6));
};


/** @param {?proto.cbv1.Truck|undefined} value */
proto.cbv1.DriverOrder.prototype.setTruck = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


proto.cbv1.DriverOrder.prototype.clearTruck = function() {
  this.setTruck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.DriverOrder.prototype.hasTruck = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Trailer trailer = 7;
 * @return {?proto.cbv1.Trailer}
 */
proto.cbv1.DriverOrder.prototype.getTrailer = function() {
  return /** @type{?proto.cbv1.Trailer} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_trailer_trailer_pb.Trailer, 7));
};


/** @param {?proto.cbv1.Trailer|undefined} value */
proto.cbv1.DriverOrder.prototype.setTrailer = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


proto.cbv1.DriverOrder.prototype.clearTrailer = function() {
  this.setTrailer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.DriverOrder.prototype.hasTrailer = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 load_number = 8;
 * @return {number}
 */
proto.cbv1.DriverOrder.prototype.getLoadNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cbv1.DriverOrder.prototype.setLoadNumber = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string description = 10;
 * @return {string}
 */
proto.cbv1.DriverOrder.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.cbv1.DriverOrder.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional OrderStatus status = 11;
 * @return {!proto.cbv1.OrderStatus}
 */
proto.cbv1.DriverOrder.prototype.getStatus = function() {
  return /** @type {!proto.cbv1.OrderStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {!proto.cbv1.OrderStatus} value */
proto.cbv1.DriverOrder.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * repeated DriverAttachment uploaded_files = 12;
 * @return {!Array<!proto.cbv1.DriverAttachment>}
 */
proto.cbv1.DriverOrder.prototype.getUploadedFilesList = function() {
  return /** @type{!Array<!proto.cbv1.DriverAttachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.DriverAttachment, 12));
};


/** @param {!Array<!proto.cbv1.DriverAttachment>} value */
proto.cbv1.DriverOrder.prototype.setUploadedFilesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.cbv1.DriverAttachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.DriverAttachment}
 */
proto.cbv1.DriverOrder.prototype.addUploadedFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.cbv1.DriverAttachment, opt_index);
};


proto.cbv1.DriverOrder.prototype.clearUploadedFilesList = function() {
  this.setUploadedFilesList([]);
};


/**
 * optional int32 status_unix = 13;
 * @return {number}
 */
proto.cbv1.DriverOrder.prototype.getStatusUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.cbv1.DriverOrder.prototype.setStatusUnix = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 scheduled_unix = 14;
 * @return {number}
 */
proto.cbv1.DriverOrder.prototype.getScheduledUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.cbv1.DriverOrder.prototype.setScheduledUnix = function(value) {
  jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 created_unix = 15;
 * @return {number}
 */
proto.cbv1.DriverOrder.prototype.getCreatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.cbv1.DriverOrder.prototype.setCreatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 updated_unix = 16;
 * @return {number}
 */
proto.cbv1.DriverOrder.prototype.getUpdatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.cbv1.DriverOrder.prototype.setUpdatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional bool is_asap = 17;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.DriverOrder.prototype.getIsAsap = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 17, false));
};


/** @param {boolean} value */
proto.cbv1.DriverOrder.prototype.setIsAsap = function(value) {
  jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool auto_close_recommended = 18;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.DriverOrder.prototype.getAutoCloseRecommended = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 18, false));
};


/** @param {boolean} value */
proto.cbv1.DriverOrder.prototype.setAutoCloseRecommended = function(value) {
  jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool can_upload_documents = 19;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.DriverOrder.prototype.getCanUploadDocuments = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 19, false));
};


/** @param {boolean} value */
proto.cbv1.DriverOrder.prototype.setCanUploadDocuments = function(value) {
  jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional string sa_unique_id = 20;
 * @return {string}
 */
proto.cbv1.DriverOrder.prototype.getSaUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/** @param {string} value */
proto.cbv1.DriverOrder.prototype.setSaUniqueId = function(value) {
  jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool can_edit_order = 21;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.DriverOrder.prototype.getCanEditOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 21, false));
};


/** @param {boolean} value */
proto.cbv1.DriverOrder.prototype.setCanEditOrder = function(value) {
  jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * repeated DriverCertification driver_certifications = 22;
 * @return {!Array<!proto.cbv1.DriverCertification>}
 */
proto.cbv1.DriverOrder.prototype.getDriverCertificationsList = function() {
  return /** @type{!Array<!proto.cbv1.DriverCertification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_drivercertification_driverCertification_pb.DriverCertification, 22));
};


/** @param {!Array<!proto.cbv1.DriverCertification>} value */
proto.cbv1.DriverOrder.prototype.setDriverCertificationsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.cbv1.DriverCertification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.DriverCertification}
 */
proto.cbv1.DriverOrder.prototype.addDriverCertifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.cbv1.DriverCertification, opt_index);
};


proto.cbv1.DriverOrder.prototype.clearDriverCertificationsList = function() {
  this.setDriverCertificationsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DriverAttachment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DriverAttachment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DriverAttachment.displayName = 'proto.cbv1.DriverAttachment';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DriverAttachment.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DriverAttachment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DriverAttachment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverAttachment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    orderId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fileName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    uploadUnix: jspb.Message.getFieldWithDefault(msg, 4, 0),
    bucketName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    category: jspb.Message.getFieldWithDefault(msg, 6, 0),
    url: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DriverAttachment}
 */
proto.cbv1.DriverAttachment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DriverAttachment;
  return proto.cbv1.DriverAttachment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DriverAttachment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DriverAttachment}
 */
proto.cbv1.DriverAttachment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUploadUnix(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBucketName(value);
      break;
    case 6:
      var value = /** @type {!proto.cbv1.AttachmentCategory} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DriverAttachment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DriverAttachment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DriverAttachment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DriverAttachment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUploadUnix();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBucketName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cbv1.DriverAttachment.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.DriverAttachment.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 order_id = 2;
 * @return {number}
 */
proto.cbv1.DriverAttachment.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.DriverAttachment.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string file_name = 3;
 * @return {string}
 */
proto.cbv1.DriverAttachment.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.DriverAttachment.prototype.setFileName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 upload_unix = 4;
 * @return {number}
 */
proto.cbv1.DriverAttachment.prototype.getUploadUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.DriverAttachment.prototype.setUploadUnix = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string bucket_name = 5;
 * @return {string}
 */
proto.cbv1.DriverAttachment.prototype.getBucketName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.DriverAttachment.prototype.setBucketName = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional AttachmentCategory category = 6;
 * @return {!proto.cbv1.AttachmentCategory}
 */
proto.cbv1.DriverAttachment.prototype.getCategory = function() {
  return /** @type {!proto.cbv1.AttachmentCategory} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.cbv1.AttachmentCategory} value */
proto.cbv1.DriverAttachment.prototype.setCategory = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string url = 7;
 * @return {string}
 */
proto.cbv1.DriverAttachment.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cbv1.DriverAttachment.prototype.setUrl = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.OrderStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.OrderStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.OrderStats.displayName = 'proto.cbv1.OrderStats';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.OrderStats.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.OrderStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.OrderStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrderStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    deadheadDistance: jspb.Message.getFieldWithDefault(msg, 1, 0),
    deadheadDuration: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lineHaulDistance: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lineHaulDuration: jspb.Message.getFieldWithDefault(msg, 4, 0),
    returnDistance: jspb.Message.getFieldWithDefault(msg, 5, 0),
    returnDuration: jspb.Message.getFieldWithDefault(msg, 6, 0),
    orderDuration: jspb.Message.getFieldWithDefault(msg, 7, 0),
    driverAcceptLocation: (f = msg.getDriverAcceptLocation()) && proto_geo_geo_pb.Point.toObject(includeInstance, f),
    deadheadCost: jspb.Message.getFieldWithDefault(msg, 9, 0),
    loadingDetentionCost: jspb.Message.getFieldWithDefault(msg, 10, 0),
    lineHaulCost: jspb.Message.getFieldWithDefault(msg, 11, 0),
    totalCost: jspb.Message.getFieldWithDefault(msg, 12, 0),
    detentionPercentLineHaul: jspb.Message.getFieldWithDefault(msg, 13, 0),
    deadheadPercentLineHaul: jspb.Message.getFieldWithDefault(msg, 14, 0),
    loaded: jspb.Message.getFieldWithDefault(msg, 15, false),
    initiallyLoaded: jspb.Message.getFieldWithDefault(msg, 16, false),
    manuallyCompleted: jspb.Message.getFieldWithDefault(msg, 17, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.OrderStats}
 */
proto.cbv1.OrderStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.OrderStats;
  return proto.cbv1.OrderStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.OrderStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.OrderStats}
 */
proto.cbv1.OrderStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeadheadDistance(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeadheadDuration(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLineHaulDistance(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLineHaulDuration(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReturnDistance(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReturnDuration(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderDuration(value);
      break;
    case 8:
      var value = new proto_geo_geo_pb.Point;
      reader.readMessage(value,proto_geo_geo_pb.Point.deserializeBinaryFromReader);
      msg.setDriverAcceptLocation(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeadheadCost(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLoadingDetentionCost(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLineHaulCost(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCost(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDetentionPercentLineHaul(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeadheadPercentLineHaul(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLoaded(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInitiallyLoaded(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManuallyCompleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.OrderStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.OrderStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.OrderStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrderStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeadheadDistance();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDeadheadDuration();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLineHaulDistance();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLineHaulDuration();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getReturnDistance();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getReturnDuration();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOrderDuration();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getDriverAcceptLocation();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_geo_geo_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getDeadheadCost();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getLoadingDetentionCost();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getLineHaulCost();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getTotalCost();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getDetentionPercentLineHaul();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getDeadheadPercentLineHaul();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getLoaded();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getInitiallyLoaded();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getManuallyCompleted();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
};


/**
 * optional int32 deadhead_distance = 1;
 * @return {number}
 */
proto.cbv1.OrderStats.prototype.getDeadheadDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.OrderStats.prototype.setDeadheadDistance = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 deadhead_duration = 2;
 * @return {number}
 */
proto.cbv1.OrderStats.prototype.getDeadheadDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.OrderStats.prototype.setDeadheadDuration = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 line_haul_distance = 3;
 * @return {number}
 */
proto.cbv1.OrderStats.prototype.getLineHaulDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.OrderStats.prototype.setLineHaulDistance = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 line_haul_duration = 4;
 * @return {number}
 */
proto.cbv1.OrderStats.prototype.getLineHaulDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.OrderStats.prototype.setLineHaulDuration = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 return_distance = 5;
 * @return {number}
 */
proto.cbv1.OrderStats.prototype.getReturnDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.OrderStats.prototype.setReturnDistance = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 return_duration = 6;
 * @return {number}
 */
proto.cbv1.OrderStats.prototype.getReturnDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.OrderStats.prototype.setReturnDuration = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 order_duration = 7;
 * @return {number}
 */
proto.cbv1.OrderStats.prototype.getOrderDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.OrderStats.prototype.setOrderDuration = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional Point driver_accept_location = 8;
 * @return {?proto.cbv1.Point}
 */
proto.cbv1.OrderStats.prototype.getDriverAcceptLocation = function() {
  return /** @type{?proto.cbv1.Point} */ (
    jspb.Message.getWrapperField(this, proto_geo_geo_pb.Point, 8));
};


/** @param {?proto.cbv1.Point|undefined} value */
proto.cbv1.OrderStats.prototype.setDriverAcceptLocation = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.cbv1.OrderStats.prototype.clearDriverAcceptLocation = function() {
  this.setDriverAcceptLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.OrderStats.prototype.hasDriverAcceptLocation = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 deadhead_cost = 9;
 * @return {number}
 */
proto.cbv1.OrderStats.prototype.getDeadheadCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cbv1.OrderStats.prototype.setDeadheadCost = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 loading_detention_cost = 10;
 * @return {number}
 */
proto.cbv1.OrderStats.prototype.getLoadingDetentionCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cbv1.OrderStats.prototype.setLoadingDetentionCost = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 line_haul_cost = 11;
 * @return {number}
 */
proto.cbv1.OrderStats.prototype.getLineHaulCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.cbv1.OrderStats.prototype.setLineHaulCost = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 total_cost = 12;
 * @return {number}
 */
proto.cbv1.OrderStats.prototype.getTotalCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.cbv1.OrderStats.prototype.setTotalCost = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 detention_percent_line_haul = 13;
 * @return {number}
 */
proto.cbv1.OrderStats.prototype.getDetentionPercentLineHaul = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.cbv1.OrderStats.prototype.setDetentionPercentLineHaul = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 deadhead_percent_line_haul = 14;
 * @return {number}
 */
proto.cbv1.OrderStats.prototype.getDeadheadPercentLineHaul = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.cbv1.OrderStats.prototype.setDeadheadPercentLineHaul = function(value) {
  jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional bool loaded = 15;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.OrderStats.prototype.getLoaded = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 15, false));
};


/** @param {boolean} value */
proto.cbv1.OrderStats.prototype.setLoaded = function(value) {
  jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool initially_loaded = 16;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.OrderStats.prototype.getInitiallyLoaded = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 16, false));
};


/** @param {boolean} value */
proto.cbv1.OrderStats.prototype.setInitiallyLoaded = function(value) {
  jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool manually_completed = 17;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.OrderStats.prototype.getManuallyCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 17, false));
};


/** @param {boolean} value */
proto.cbv1.OrderStats.prototype.setManuallyCompleted = function(value) {
  jspb.Message.setProto3BooleanField(this, 17, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SiteOrderETAs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.SiteOrderETAs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SiteOrderETAs.displayName = 'proto.cbv1.SiteOrderETAs';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SiteOrderETAs.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SiteOrderETAs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SiteOrderETAs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SiteOrderETAs.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderEtasMap: (f = msg.getOrderEtasMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SiteOrderETAs}
 */
proto.cbv1.SiteOrderETAs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SiteOrderETAs;
  return proto.cbv1.SiteOrderETAs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SiteOrderETAs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SiteOrderETAs}
 */
proto.cbv1.SiteOrderETAs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getOrderEtasMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readInt32, null, 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SiteOrderETAs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SiteOrderETAs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SiteOrderETAs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SiteOrderETAs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderEtasMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeInt32);
  }
};


/**
 * map<int32, int32> order_etas = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.cbv1.SiteOrderETAs.prototype.getOrderEtasMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


proto.cbv1.SiteOrderETAs.prototype.clearOrderEtasMap = function() {
  this.getOrderEtasMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.FleetManagerDriverScheduleOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.FleetManagerDriverScheduleOrder.repeatedFields_, null);
};
goog.inherits(proto.cbv1.FleetManagerDriverScheduleOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.FleetManagerDriverScheduleOrder.displayName = 'proto.cbv1.FleetManagerDriverScheduleOrder';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.FleetManagerDriverScheduleOrder.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.FleetManagerDriverScheduleOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.FleetManagerDriverScheduleOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.FleetManagerDriverScheduleOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FleetManagerDriverScheduleOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    taskSummariesList: jspb.Message.toObjectList(msg.getTaskSummariesList(),
    proto.cbv1.TaskSummary.toObject, includeInstance),
    startUnix: jspb.Message.getFieldWithDefault(msg, 3, 0),
    endUnix: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.FleetManagerDriverScheduleOrder}
 */
proto.cbv1.FleetManagerDriverScheduleOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.FleetManagerDriverScheduleOrder;
  return proto.cbv1.FleetManagerDriverScheduleOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.FleetManagerDriverScheduleOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.FleetManagerDriverScheduleOrder}
 */
proto.cbv1.FleetManagerDriverScheduleOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.cbv1.TaskSummary;
      reader.readMessage(value,proto.cbv1.TaskSummary.deserializeBinaryFromReader);
      msg.addTaskSummaries(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartUnix(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndUnix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.FleetManagerDriverScheduleOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.FleetManagerDriverScheduleOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.FleetManagerDriverScheduleOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FleetManagerDriverScheduleOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTaskSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cbv1.TaskSummary.serializeBinaryToWriter
    );
  }
  f = message.getStartUnix();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEndUnix();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.FleetManagerDriverScheduleOrder.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.FleetManagerDriverScheduleOrder.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated TaskSummary task_summaries = 2;
 * @return {!Array<!proto.cbv1.TaskSummary>}
 */
proto.cbv1.FleetManagerDriverScheduleOrder.prototype.getTaskSummariesList = function() {
  return /** @type{!Array<!proto.cbv1.TaskSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.TaskSummary, 2));
};


/** @param {!Array<!proto.cbv1.TaskSummary>} value */
proto.cbv1.FleetManagerDriverScheduleOrder.prototype.setTaskSummariesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cbv1.TaskSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.TaskSummary}
 */
proto.cbv1.FleetManagerDriverScheduleOrder.prototype.addTaskSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cbv1.TaskSummary, opt_index);
};


proto.cbv1.FleetManagerDriverScheduleOrder.prototype.clearTaskSummariesList = function() {
  this.setTaskSummariesList([]);
};


/**
 * optional int64 start_unix = 3;
 * @return {number}
 */
proto.cbv1.FleetManagerDriverScheduleOrder.prototype.getStartUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.FleetManagerDriverScheduleOrder.prototype.setStartUnix = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 end_unix = 4;
 * @return {number}
 */
proto.cbv1.FleetManagerDriverScheduleOrder.prototype.getEndUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.FleetManagerDriverScheduleOrder.prototype.setEndUnix = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.FleetManagerDriver = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.FleetManagerDriver.repeatedFields_, null);
};
goog.inherits(proto.cbv1.FleetManagerDriver, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.FleetManagerDriver.displayName = 'proto.cbv1.FleetManagerDriver';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.FleetManagerDriver.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.FleetManagerDriver.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.FleetManagerDriver.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.FleetManagerDriver} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FleetManagerDriver.toObject = function(includeInstance, msg) {
  var f, obj = {
    tempId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto.cbv1.FleetManagerDriverScheduleOrder.toObject, includeInstance),
    calendarDateUnix: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.FleetManagerDriver}
 */
proto.cbv1.FleetManagerDriver.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.FleetManagerDriver;
  return proto.cbv1.FleetManagerDriver.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.FleetManagerDriver} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.FleetManagerDriver}
 */
proto.cbv1.FleetManagerDriver.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTempId(value);
      break;
    case 2:
      var value = new proto.cbv1.FleetManagerDriverScheduleOrder;
      reader.readMessage(value,proto.cbv1.FleetManagerDriverScheduleOrder.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCalendarDateUnix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.FleetManagerDriver.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.FleetManagerDriver.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.FleetManagerDriver} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.FleetManagerDriver.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTempId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cbv1.FleetManagerDriverScheduleOrder.serializeBinaryToWriter
    );
  }
  f = message.getCalendarDateUnix();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 temp_id = 1;
 * @return {number}
 */
proto.cbv1.FleetManagerDriver.prototype.getTempId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.FleetManagerDriver.prototype.setTempId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated FleetManagerDriverScheduleOrder orders = 2;
 * @return {!Array<!proto.cbv1.FleetManagerDriverScheduleOrder>}
 */
proto.cbv1.FleetManagerDriver.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.cbv1.FleetManagerDriverScheduleOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.FleetManagerDriverScheduleOrder, 2));
};


/** @param {!Array<!proto.cbv1.FleetManagerDriverScheduleOrder>} value */
proto.cbv1.FleetManagerDriver.prototype.setOrdersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cbv1.FleetManagerDriverScheduleOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.FleetManagerDriverScheduleOrder}
 */
proto.cbv1.FleetManagerDriver.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cbv1.FleetManagerDriverScheduleOrder, opt_index);
};


proto.cbv1.FleetManagerDriver.prototype.clearOrdersList = function() {
  this.setOrdersList([]);
};


/**
 * optional int64 calendar_date_unix = 3;
 * @return {number}
 */
proto.cbv1.FleetManagerDriver.prototype.getCalendarDateUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.FleetManagerDriver.prototype.setCalendarDateUnix = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.OrderAttachment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.OrderAttachment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.OrderAttachment.displayName = 'proto.cbv1.OrderAttachment';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.OrderAttachment.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.OrderAttachment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.OrderAttachment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrderAttachment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    orderId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fileName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    canDelete: jspb.Message.getFieldWithDefault(msg, 4, false),
    uploadedUnix: jspb.Message.getFieldWithDefault(msg, 5, 0),
    category: jspb.Message.getFieldWithDefault(msg, 6, 0),
    uploadedBy: jspb.Message.getFieldWithDefault(msg, 7, ""),
    bucketName: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.OrderAttachment}
 */
proto.cbv1.OrderAttachment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.OrderAttachment;
  return proto.cbv1.OrderAttachment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.OrderAttachment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.OrderAttachment}
 */
proto.cbv1.OrderAttachment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanDelete(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUploadedUnix(value);
      break;
    case 6:
      var value = /** @type {!proto.cbv1.AttachmentCategory} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadedBy(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBucketName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.OrderAttachment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.OrderAttachment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.OrderAttachment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrderAttachment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCanDelete();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getUploadedUnix();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getUploadedBy();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBucketName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.OrderAttachment.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.OrderAttachment.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 order_id = 2;
 * @return {number}
 */
proto.cbv1.OrderAttachment.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.OrderAttachment.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string file_name = 3;
 * @return {string}
 */
proto.cbv1.OrderAttachment.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.OrderAttachment.prototype.setFileName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool can_delete = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.OrderAttachment.prototype.getCanDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.cbv1.OrderAttachment.prototype.setCanDelete = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 uploaded_unix = 5;
 * @return {number}
 */
proto.cbv1.OrderAttachment.prototype.getUploadedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.OrderAttachment.prototype.setUploadedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional AttachmentCategory category = 6;
 * @return {!proto.cbv1.AttachmentCategory}
 */
proto.cbv1.OrderAttachment.prototype.getCategory = function() {
  return /** @type {!proto.cbv1.AttachmentCategory} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.cbv1.AttachmentCategory} value */
proto.cbv1.OrderAttachment.prototype.setCategory = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string uploaded_by = 7;
 * @return {string}
 */
proto.cbv1.OrderAttachment.prototype.getUploadedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cbv1.OrderAttachment.prototype.setUploadedBy = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string bucket_name = 8;
 * @return {string}
 */
proto.cbv1.OrderAttachment.prototype.getBucketName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.cbv1.OrderAttachment.prototype.setBucketName = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DetentionStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DetentionStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DetentionStats.displayName = 'proto.cbv1.DetentionStats';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DetentionStats.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DetentionStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DetentionStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DetentionStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    percentage: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DetentionStats}
 */
proto.cbv1.DetentionStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DetentionStats;
  return proto.cbv1.DetentionStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DetentionStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DetentionStats}
 */
proto.cbv1.DetentionStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DetentionStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DetentionStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DetentionStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DetentionStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPercentage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 percentage = 1;
 * @return {number}
 */
proto.cbv1.DetentionStats.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.DetentionStats.prototype.setPercentage = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.OrderUploadState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.OrderUploadState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.OrderUploadState.displayName = 'proto.cbv1.OrderUploadState';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.OrderUploadState.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.OrderUploadState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.OrderUploadState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrderUploadState.toObject = function(includeInstance, msg) {
  var f, obj = {
    uploading: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.OrderUploadState}
 */
proto.cbv1.OrderUploadState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.OrderUploadState;
  return proto.cbv1.OrderUploadState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.OrderUploadState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.OrderUploadState}
 */
proto.cbv1.OrderUploadState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUploading(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.OrderUploadState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.OrderUploadState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.OrderUploadState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrderUploadState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploading();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool uploading = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.OrderUploadState.prototype.getUploading = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.OrderUploadState.prototype.setUploading = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DispatcherCalendarSubTaskSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DispatcherCalendarSubTaskSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DispatcherCalendarSubTaskSummary.displayName = 'proto.cbv1.DispatcherCalendarSubTaskSummary';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DispatcherCalendarSubTaskSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DispatcherCalendarSubTaskSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DispatcherCalendarSubTaskSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    subTaskTypeName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orderedQuantity: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    payloadName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 5, ""),
    payloadTypeId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    payloadTypeName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    actualQuantity: +jspb.Message.getFieldWithDefault(msg, 8, 0.0),
    payloadId: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DispatcherCalendarSubTaskSummary}
 */
proto.cbv1.DispatcherCalendarSubTaskSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DispatcherCalendarSubTaskSummary;
  return proto.cbv1.DispatcherCalendarSubTaskSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DispatcherCalendarSubTaskSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DispatcherCalendarSubTaskSummary}
 */
proto.cbv1.DispatcherCalendarSubTaskSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubTaskTypeName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrderedQuantity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayloadName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPayloadTypeId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayloadTypeName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setActualQuantity(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPayloadId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DispatcherCalendarSubTaskSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DispatcherCalendarSubTaskSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DispatcherCalendarSubTaskSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSubTaskTypeName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrderedQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getPayloadName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPayloadTypeId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPayloadTypeName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getActualQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getPayloadId();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string sub_task_type_name = 2;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.getSubTaskTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.setSubTaskTypeName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double ordered_quantity = 3;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.getOrderedQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.setOrderedQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string payload_name = 4;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.getPayloadName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.setPayloadName = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string unit_abbreviation = 5;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.setUnitAbbreviation = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 payload_type_id = 6;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.getPayloadTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.setPayloadTypeId = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string payload_type_name = 7;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.getPayloadTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.setPayloadTypeName = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double actual_quantity = 8;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.getActualQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 8, 0.0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.setActualQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional int64 payload_id = 9;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.getPayloadId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarSubTaskSummary.prototype.setPayloadId = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DispatcherCalendarPendingTaskSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.DispatcherCalendarPendingTaskSummary.repeatedFields_, null);
};
goog.inherits(proto.cbv1.DispatcherCalendarPendingTaskSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DispatcherCalendarPendingTaskSummary.displayName = 'proto.cbv1.DispatcherCalendarPendingTaskSummary';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.DispatcherCalendarPendingTaskSummary.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DispatcherCalendarPendingTaskSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DispatcherCalendarPendingTaskSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DispatcherCalendarPendingTaskSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderedQuantity: +jspb.Message.getFieldWithDefault(msg, 1, 0.0),
    sequence: jspb.Message.getFieldWithDefault(msg, 2, 0),
    siteName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    payloadName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 5, ""),
    payloadTypeId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    payloadTypeName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isBillable: jspb.Message.getFieldWithDefault(msg, 8, false),
    siteId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    subTasksList: jspb.Message.toObjectList(msg.getSubTasksList(),
    proto.cbv1.DispatcherCalendarSubTaskSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DispatcherCalendarPendingTaskSummary}
 */
proto.cbv1.DispatcherCalendarPendingTaskSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DispatcherCalendarPendingTaskSummary;
  return proto.cbv1.DispatcherCalendarPendingTaskSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DispatcherCalendarPendingTaskSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DispatcherCalendarPendingTaskSummary}
 */
proto.cbv1.DispatcherCalendarPendingTaskSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrderedQuantity(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSequence(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayloadName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPayloadTypeId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayloadTypeName(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBillable(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSiteId(value);
      break;
    case 10:
      var value = new proto.cbv1.DispatcherCalendarSubTaskSummary;
      reader.readMessage(value,proto.cbv1.DispatcherCalendarSubTaskSummary.deserializeBinaryFromReader);
      msg.addSubTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DispatcherCalendarPendingTaskSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DispatcherCalendarPendingTaskSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DispatcherCalendarPendingTaskSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderedQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getSequence();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPayloadName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPayloadTypeId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPayloadTypeName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsBillable();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getSubTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.cbv1.DispatcherCalendarSubTaskSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional double ordered_quantity = 1;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.getOrderedQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.setOrderedQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional int64 sequence = 2;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.setSequence = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string site_name = 3;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.setSiteName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string payload_name = 4;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.getPayloadName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.setPayloadName = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string unit_abbreviation = 5;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.setUnitAbbreviation = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 payload_type_id = 6;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.getPayloadTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.setPayloadTypeId = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string payload_type_name = 7;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.getPayloadTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.setPayloadTypeName = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_billable = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.getIsBillable = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.setIsBillable = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int64 site_id = 9;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.setSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated DispatcherCalendarSubTaskSummary sub_tasks = 10;
 * @return {!Array<!proto.cbv1.DispatcherCalendarSubTaskSummary>}
 */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.getSubTasksList = function() {
  return /** @type{!Array<!proto.cbv1.DispatcherCalendarSubTaskSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.DispatcherCalendarSubTaskSummary, 10));
};


/** @param {!Array<!proto.cbv1.DispatcherCalendarSubTaskSummary>} value */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.setSubTasksList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.cbv1.DispatcherCalendarSubTaskSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.DispatcherCalendarSubTaskSummary}
 */
proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.addSubTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.cbv1.DispatcherCalendarSubTaskSummary, opt_index);
};


proto.cbv1.DispatcherCalendarPendingTaskSummary.prototype.clearSubTasksList = function() {
  this.setSubTasksList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DispatcherCalendarOrderSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.DispatcherCalendarOrderSummary.repeatedFields_, null);
};
goog.inherits(proto.cbv1.DispatcherCalendarOrderSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DispatcherCalendarOrderSummary.displayName = 'proto.cbv1.DispatcherCalendarOrderSummary';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.DispatcherCalendarOrderSummary.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DispatcherCalendarOrderSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DispatcherCalendarOrderSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DispatcherCalendarOrderSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    statusUnix: jspb.Message.getFieldWithDefault(msg, 3, 0),
    customerAccountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    taskSummariesList: jspb.Message.toObjectList(msg.getTaskSummariesList(),
    proto.cbv1.DispatcherCalendarPendingTaskSummary.toObject, includeInstance),
    inProgress: jspb.Message.getFieldWithDefault(msg, 6, false),
    scheduleWindowStartUnix: jspb.Message.getFieldWithDefault(msg, 7, 0),
    scheduleWindowEndUnix: jspb.Message.getFieldWithDefault(msg, 8, 0),
    isAsap: jspb.Message.getFieldWithDefault(msg, 9, false),
    isUpForGrabs: jspb.Message.getFieldWithDefault(msg, 10, false),
    upForGrabsUnix: jspb.Message.getFieldWithDefault(msg, 11, 0),
    trailerName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    isMyOrder: jspb.Message.getFieldWithDefault(msg, 13, false),
    updatedUnix: jspb.Message.getFieldWithDefault(msg, 14, 0),
    folderName: jspb.Message.getFieldWithDefault(msg, 15, ""),
    type: jspb.Message.getFieldWithDefault(msg, 16, 0),
    displayLoadId: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DispatcherCalendarOrderSummary}
 */
proto.cbv1.DispatcherCalendarOrderSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DispatcherCalendarOrderSummary;
  return proto.cbv1.DispatcherCalendarOrderSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DispatcherCalendarOrderSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DispatcherCalendarOrderSummary}
 */
proto.cbv1.DispatcherCalendarOrderSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.cbv1.OrderStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatusUnix(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerAccountName(value);
      break;
    case 5:
      var value = new proto.cbv1.DispatcherCalendarPendingTaskSummary;
      reader.readMessage(value,proto.cbv1.DispatcherCalendarPendingTaskSummary.deserializeBinaryFromReader);
      msg.addTaskSummaries(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInProgress(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScheduleWindowStartUnix(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScheduleWindowEndUnix(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAsap(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsUpForGrabs(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpForGrabsUnix(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerName(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMyOrder(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedUnix(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setFolderName(value);
      break;
    case 16:
      var value = /** @type {!proto.cbv1.OrderType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayLoadId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DispatcherCalendarOrderSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DispatcherCalendarOrderSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DispatcherCalendarOrderSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStatusUnix();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCustomerAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTaskSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.cbv1.DispatcherCalendarPendingTaskSummary.serializeBinaryToWriter
    );
  }
  f = message.getInProgress();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getScheduleWindowStartUnix();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getScheduleWindowEndUnix();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getIsAsap();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsUpForGrabs();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getUpForGrabsUnix();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getTrailerName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIsMyOrder();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getUpdatedUnix();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getFolderName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getDisplayLoadId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional OrderStatus status = 2;
 * @return {!proto.cbv1.OrderStatus}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.getStatus = function() {
  return /** @type {!proto.cbv1.OrderStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.cbv1.OrderStatus} value */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 status_unix = 3;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.getStatusUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.setStatusUnix = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string customer_account_name = 4;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.getCustomerAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.setCustomerAccountName = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated DispatcherCalendarPendingTaskSummary task_summaries = 5;
 * @return {!Array<!proto.cbv1.DispatcherCalendarPendingTaskSummary>}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.getTaskSummariesList = function() {
  return /** @type{!Array<!proto.cbv1.DispatcherCalendarPendingTaskSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.DispatcherCalendarPendingTaskSummary, 5));
};


/** @param {!Array<!proto.cbv1.DispatcherCalendarPendingTaskSummary>} value */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.setTaskSummariesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.cbv1.DispatcherCalendarPendingTaskSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.DispatcherCalendarPendingTaskSummary}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.addTaskSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.cbv1.DispatcherCalendarPendingTaskSummary, opt_index);
};


proto.cbv1.DispatcherCalendarOrderSummary.prototype.clearTaskSummariesList = function() {
  this.setTaskSummariesList([]);
};


/**
 * optional bool in_progress = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.getInProgress = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.setInProgress = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int64 schedule_window_start_unix = 7;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.getScheduleWindowStartUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.setScheduleWindowStartUnix = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 schedule_window_end_unix = 8;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.getScheduleWindowEndUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.setScheduleWindowEndUnix = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool is_asap = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.getIsAsap = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.setIsAsap = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_up_for_grabs = 10;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.getIsUpForGrabs = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 10, false));
};


/** @param {boolean} value */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.setIsUpForGrabs = function(value) {
  jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int64 up_for_grabs_unix = 11;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.getUpForGrabsUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.setUpForGrabsUnix = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string trailer_name = 12;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.getTrailerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.setTrailerName = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool is_my_order = 13;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.getIsMyOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 13, false));
};


/** @param {boolean} value */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.setIsMyOrder = function(value) {
  jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int64 updated_unix = 14;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.getUpdatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.setUpdatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string folder_name = 15;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.getFolderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.setFolderName = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional OrderType type = 16;
 * @return {!proto.cbv1.OrderType}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.getType = function() {
  return /** @type {!proto.cbv1.OrderType} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {!proto.cbv1.OrderType} value */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional string display_load_id = 17;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.getDisplayLoadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarOrderSummary.prototype.setDisplayLoadId = function(value) {
  jspb.Message.setProto3StringField(this, 17, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.DispatcherCalendarInProgressTaskSummary.repeatedFields_, null);
};
goog.inherits(proto.cbv1.DispatcherCalendarInProgressTaskSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DispatcherCalendarInProgressTaskSummary.displayName = 'proto.cbv1.DispatcherCalendarInProgressTaskSummary';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DispatcherCalendarInProgressTaskSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DispatcherCalendarInProgressTaskSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderedQuantity: +jspb.Message.getFieldWithDefault(msg, 1, 0.0),
    sequence: jspb.Message.getFieldWithDefault(msg, 2, 0),
    siteName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    payloadName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 5, ""),
    payloadTypeId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    payloadTypeName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isBillable: jspb.Message.getFieldWithDefault(msg, 8, false),
    siteId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    arrivalUnix: jspb.Message.getFieldWithDefault(msg, 10, 0),
    driverEtaInMinutes: jspb.Message.getFieldWithDefault(msg, 11, 0),
    status: jspb.Message.getFieldWithDefault(msg, 12, 0),
    subTasksList: jspb.Message.toObjectList(msg.getSubTasksList(),
    proto.cbv1.DispatcherCalendarSubTaskSummary.toObject, includeInstance),
    type: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DispatcherCalendarInProgressTaskSummary}
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DispatcherCalendarInProgressTaskSummary;
  return proto.cbv1.DispatcherCalendarInProgressTaskSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DispatcherCalendarInProgressTaskSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DispatcherCalendarInProgressTaskSummary}
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrderedQuantity(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSequence(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayloadName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPayloadTypeId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayloadTypeName(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBillable(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSiteId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArrivalUnix(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDriverEtaInMinutes(value);
      break;
    case 12:
      var value = /** @type {!proto.cbv1.TaskStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 13:
      var value = new proto.cbv1.DispatcherCalendarSubTaskSummary;
      reader.readMessage(value,proto.cbv1.DispatcherCalendarSubTaskSummary.deserializeBinaryFromReader);
      msg.addSubTasks(value);
      break;
    case 14:
      var value = /** @type {!proto.cbv1.TaskType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DispatcherCalendarInProgressTaskSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DispatcherCalendarInProgressTaskSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderedQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getSequence();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPayloadName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPayloadTypeId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPayloadTypeName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsBillable();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getArrivalUnix();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getDriverEtaInMinutes();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getSubTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.cbv1.DispatcherCalendarSubTaskSummary.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
};


/**
 * optional double ordered_quantity = 1;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.getOrderedQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.setOrderedQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional int64 sequence = 2;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.setSequence = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string site_name = 3;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.setSiteName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string payload_name = 4;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.getPayloadName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.setPayloadName = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string unit_abbreviation = 5;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.setUnitAbbreviation = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 payload_type_id = 6;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.getPayloadTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.setPayloadTypeId = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string payload_type_name = 7;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.getPayloadTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.setPayloadTypeName = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_billable = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.getIsBillable = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.setIsBillable = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int64 site_id = 9;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.setSiteId = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 arrival_unix = 10;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.getArrivalUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.setArrivalUnix = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 driver_eta_in_minutes = 11;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.getDriverEtaInMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.setDriverEtaInMinutes = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional TaskStatus status = 12;
 * @return {!proto.cbv1.TaskStatus}
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.getStatus = function() {
  return /** @type {!proto.cbv1.TaskStatus} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {!proto.cbv1.TaskStatus} value */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * repeated DispatcherCalendarSubTaskSummary sub_tasks = 13;
 * @return {!Array<!proto.cbv1.DispatcherCalendarSubTaskSummary>}
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.getSubTasksList = function() {
  return /** @type{!Array<!proto.cbv1.DispatcherCalendarSubTaskSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.DispatcherCalendarSubTaskSummary, 13));
};


/** @param {!Array<!proto.cbv1.DispatcherCalendarSubTaskSummary>} value */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.setSubTasksList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.cbv1.DispatcherCalendarSubTaskSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.DispatcherCalendarSubTaskSummary}
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.addSubTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.cbv1.DispatcherCalendarSubTaskSummary, opt_index);
};


proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.clearSubTasksList = function() {
  this.setSubTasksList([]);
};


/**
 * optional TaskType type = 14;
 * @return {!proto.cbv1.TaskType}
 */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.getType = function() {
  return /** @type {!proto.cbv1.TaskType} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {!proto.cbv1.TaskType} value */
proto.cbv1.DispatcherCalendarInProgressTaskSummary.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 14, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.DispatcherCalendarInProgressOrderSummary.repeatedFields_, null);
};
goog.inherits(proto.cbv1.DispatcherCalendarInProgressOrderSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DispatcherCalendarInProgressOrderSummary.displayName = 'proto.cbv1.DispatcherCalendarInProgressOrderSummary';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DispatcherCalendarInProgressOrderSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DispatcherCalendarInProgressOrderSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    statusUnix: jspb.Message.getFieldWithDefault(msg, 3, 0),
    customerAccountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    taskSummariesList: jspb.Message.toObjectList(msg.getTaskSummariesList(),
    proto.cbv1.DispatcherCalendarInProgressTaskSummary.toObject, includeInstance),
    trailerName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    updatedUnix: jspb.Message.getFieldWithDefault(msg, 7, 0),
    folderName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    truckName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    driverUid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    driverName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    orderStatus: jspb.Message.getFieldWithDefault(msg, 12, ""),
    driverStatus: (f = msg.getDriverStatus()) && proto_cybertron_user_user_pb.DriverStatus.toObject(includeInstance, f),
    driverHosMinutesRemaining: jspb.Message.getFieldWithDefault(msg, 14, 0),
    minutesStats: (f = msg.getMinutesStats()) && proto.cbv1.MinutesStats.toObject(includeInstance, f),
    driverLastWaypoint: jspb.Message.getFieldWithDefault(msg, 16, 0),
    type: jspb.Message.getFieldWithDefault(msg, 17, 0),
    displayLoadId: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DispatcherCalendarInProgressOrderSummary}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DispatcherCalendarInProgressOrderSummary;
  return proto.cbv1.DispatcherCalendarInProgressOrderSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DispatcherCalendarInProgressOrderSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DispatcherCalendarInProgressOrderSummary}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.cbv1.OrderStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatusUnix(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerAccountName(value);
      break;
    case 5:
      var value = new proto.cbv1.DispatcherCalendarInProgressTaskSummary;
      reader.readMessage(value,proto.cbv1.DispatcherCalendarInProgressTaskSummary.deserializeBinaryFromReader);
      msg.addTaskSummaries(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerName(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedUnix(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFolderName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTruckName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverUid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderStatus(value);
      break;
    case 13:
      var value = new proto_cybertron_user_user_pb.DriverStatus;
      reader.readMessage(value,proto_cybertron_user_user_pb.DriverStatus.deserializeBinaryFromReader);
      msg.setDriverStatus(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDriverHosMinutesRemaining(value);
      break;
    case 15:
      var value = new proto.cbv1.MinutesStats;
      reader.readMessage(value,proto.cbv1.MinutesStats.deserializeBinaryFromReader);
      msg.setMinutesStats(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDriverLastWaypoint(value);
      break;
    case 17:
      var value = /** @type {!proto.cbv1.OrderType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayLoadId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DispatcherCalendarInProgressOrderSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DispatcherCalendarInProgressOrderSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStatusUnix();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCustomerAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTaskSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.cbv1.DispatcherCalendarInProgressTaskSummary.serializeBinaryToWriter
    );
  }
  f = message.getTrailerName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUpdatedUnix();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getFolderName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTruckName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDriverUid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDriverName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOrderStatus();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDriverStatus();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_cybertron_user_user_pb.DriverStatus.serializeBinaryToWriter
    );
  }
  f = message.getDriverHosMinutesRemaining();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getMinutesStats();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.cbv1.MinutesStats.serializeBinaryToWriter
    );
  }
  f = message.getDriverLastWaypoint();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getDisplayLoadId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional OrderStatus status = 2;
 * @return {!proto.cbv1.OrderStatus}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.getStatus = function() {
  return /** @type {!proto.cbv1.OrderStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.cbv1.OrderStatus} value */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 status_unix = 3;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.getStatusUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.setStatusUnix = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string customer_account_name = 4;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.getCustomerAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.setCustomerAccountName = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated DispatcherCalendarInProgressTaskSummary task_summaries = 5;
 * @return {!Array<!proto.cbv1.DispatcherCalendarInProgressTaskSummary>}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.getTaskSummariesList = function() {
  return /** @type{!Array<!proto.cbv1.DispatcherCalendarInProgressTaskSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.DispatcherCalendarInProgressTaskSummary, 5));
};


/** @param {!Array<!proto.cbv1.DispatcherCalendarInProgressTaskSummary>} value */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.setTaskSummariesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.cbv1.DispatcherCalendarInProgressTaskSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.DispatcherCalendarInProgressTaskSummary}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.addTaskSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.cbv1.DispatcherCalendarInProgressTaskSummary, opt_index);
};


proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.clearTaskSummariesList = function() {
  this.setTaskSummariesList([]);
};


/**
 * optional string trailer_name = 6;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.getTrailerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.setTrailerName = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 updated_unix = 7;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.getUpdatedUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.setUpdatedUnix = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string folder_name = 8;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.getFolderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.setFolderName = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string truck_name = 9;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.getTruckName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.setTruckName = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string driver_uid = 10;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.getDriverUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.setDriverUid = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string driver_name = 11;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.getDriverName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.setDriverName = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string order_status = 12;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.getOrderStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.setOrderStatus = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional DriverStatus driver_status = 13;
 * @return {?proto.cbv1.DriverStatus}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.getDriverStatus = function() {
  return /** @type{?proto.cbv1.DriverStatus} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_user_user_pb.DriverStatus, 13));
};


/** @param {?proto.cbv1.DriverStatus|undefined} value */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.setDriverStatus = function(value) {
  jspb.Message.setWrapperField(this, 13, value);
};


proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.clearDriverStatus = function() {
  this.setDriverStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.hasDriverStatus = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional int64 driver_hos_minutes_remaining = 14;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.getDriverHosMinutesRemaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.setDriverHosMinutesRemaining = function(value) {
  jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional MinutesStats minutes_stats = 15;
 * @return {?proto.cbv1.MinutesStats}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.getMinutesStats = function() {
  return /** @type{?proto.cbv1.MinutesStats} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.MinutesStats, 15));
};


/** @param {?proto.cbv1.MinutesStats|undefined} value */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.setMinutesStats = function(value) {
  jspb.Message.setWrapperField(this, 15, value);
};


proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.clearMinutesStats = function() {
  this.setMinutesStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.hasMinutesStats = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional int64 driver_last_waypoint = 16;
 * @return {number}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.getDriverLastWaypoint = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.setDriverLastWaypoint = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional OrderType type = 17;
 * @return {!proto.cbv1.OrderType}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.getType = function() {
  return /** @type {!proto.cbv1.OrderType} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {!proto.cbv1.OrderType} value */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional string display_load_id = 18;
 * @return {string}
 */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.getDisplayLoadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/** @param {string} value */
proto.cbv1.DispatcherCalendarInProgressOrderSummary.prototype.setDisplayLoadId = function(value) {
  jspb.Message.setProto3StringField(this, 18, value);
};


goog.object.extend(exports, proto.cbv1);
