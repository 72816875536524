// package: cbv1
// file: proto/cybertron/fields/fields_api.proto

var proto_cybertron_fields_fields_api_pb = require("../../../proto/cybertron/fields/fields_api_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var FieldsAPI = (function () {
  function FieldsAPI() {}
  FieldsAPI.serviceName = "cbv1.FieldsAPI";
  return FieldsAPI;
}());

FieldsAPI.CreateCustomFieldSetting = {
  methodName: "CreateCustomFieldSetting",
  service: FieldsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_fields_fields_api_pb.CreateCustomFieldSettingRequest,
  responseType: proto_cybertron_fields_fields_api_pb.CreateCustomFieldSettingResponse
};

FieldsAPI.GetCustomFieldSettings = {
  methodName: "GetCustomFieldSettings",
  service: FieldsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_fields_fields_api_pb.GetCustomFieldSettingsRequest,
  responseType: proto_cybertron_fields_fields_api_pb.GetCustomFieldSettingsResponse
};

FieldsAPI.UpdateCustomFieldSettings = {
  methodName: "UpdateCustomFieldSettings",
  service: FieldsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_fields_fields_api_pb.UpdateCustomFieldSettingsRequest,
  responseType: proto_cybertron_fields_fields_api_pb.UpdateCustomFieldSettingsResponse
};

exports.FieldsAPI = FieldsAPI;

function FieldsAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

FieldsAPIClient.prototype.createCustomFieldSetting = function createCustomFieldSetting(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(FieldsAPI.CreateCustomFieldSetting, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

FieldsAPIClient.prototype.getCustomFieldSettings = function getCustomFieldSettings(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(FieldsAPI.GetCustomFieldSettings, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

FieldsAPIClient.prototype.updateCustomFieldSettings = function updateCustomFieldSettings(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(FieldsAPI.UpdateCustomFieldSettings, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.FieldsAPIClient = FieldsAPIClient;

