import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CreateNewTrailerRequest } from '~proto/trailer/trailer_api_pb';
import { TrailerService } from '~services/trailer.service';
import { trackById } from '~utilities/trackById';

interface FormValue {
  name: string;
  model: string;
}

@Component({
  selector: 'ct-add-trailer',
  styleUrls: ['./add-trailer.component.scss'],
  templateUrl: './add-trailer.component.html',
})
export class AddTrailerComponent implements OnInit {
  public networkActive$ = new BehaviorSubject(false);
  public formGroup: FormGroup;
  public trackById = trackById;

  constructor(private trailerService: TrailerService, private fb: FormBuilder, private router: Router) {}

  private formValue(): FormValue {
    return this.formGroup.value;
  }

  public ngOnInit() {
    this.formGroup = this.fb.group({
      model: ['', [Validators.required]],
      name: ['', [Validators.required]],
    });
  }

  public addTrailer() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    this.networkActive$.next(true);
    const formValue = this.formValue();
    const createTrailerReq = new CreateNewTrailerRequest();
    createTrailerReq.setName(formValue.name);
    createTrailerReq.setModelName(formValue.model);
    this.trailerService
      .addTrailer(createTrailerReq)
      .pipe(
        finalize(() => {
          this.networkActive$.next(false);
        }),
      )
      .subscribe((trailer) => {
        this.router.navigate(['/', 'trailers']);
      });
  }
}
