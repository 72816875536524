import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, share, tap } from 'rxjs/operators';
import { BusinessLine } from '~proto/businessline/businessline_pb';
import {
  ResetPasswordOnFirstTimeLoginRequest,
  ResetPasswordOnFirstTimeLoginResponse,
  UserDetailsForIntercomRequest,
  UserDetailsForIntercomResponse,
} from '~proto/user/user_api_pb';
import { TriggerUserActionRequest, TriggerUserActionResponse } from '~proto/user/user_api_pb';
import { UserAPI } from '~proto/user/user_api_pb_service';
import { AuthService } from '~services/auth.service';
import { GrpcService } from '~services/grpc.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private userRole$$: BehaviorSubject<Number> = new BehaviorSubject<Number>(null);
  private isFirstTimeUser$$: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(null);
  private isBusinessLineEnabled$$: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(null);
  //  private businessLinesList$$: BehaviorSubject<UserDetailsForIntercom<BusinessLine.AsObject>> = new BehaviorSubject<UserDetailsForIntercom<BusinessLine.AsObject>>(null);
  private businessLinesList$$: BehaviorSubject<BusinessLine[]> = new BehaviorSubject<BusinessLine[]>([]);
  private isSsoUser$$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private isBusinessLineCostCenterRelationEnabled$$: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
  private userActionsList$$: BehaviorSubject<(0 | 1)[]> = new BehaviorSubject<(0 | 1)[]>([]);
  private isSalesOrderApiEnabled$$: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
  private isMaintenanceOrderApiEnabled$$: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);

  constructor(private authService: AuthService, private grpcService: GrpcService) {
    this.authService.isLoggedIn$.subscribe(() => {
      this.loadUserRole();
    });
  }

  get userRole$(): Observable<Number> {
    return this.userRole$$.asObservable().pipe(
      filter((value) => value !== null),
      share(),
    );
  }

  get isFirstTimeUser$(): Observable<Boolean> {
    return this.isFirstTimeUser$$.asObservable().pipe(
      filter((value) => value !== null),
      share(),
    );
  }

  get isBusinessLineEnabled$(): Observable<Boolean> {
    return this.isBusinessLineEnabled$$.asObservable().pipe(
      filter((value) => value !== null),
      share(),
    );
  }

  get businessLinesList$(): Observable<BusinessLine.AsObject[]> {
    return this.businessLinesList$$.asObservable().pipe(
      filter((value) => value !== null),
      map((values) => values && values.map((value) => value.toObject())),
      share(),
    );
  }

  get isSsoUser$(): Observable<Boolean> {
    return this.isSsoUser$$.asObservable().pipe(
      filter((value) => value !== null),
      share(),
    );
  }

  get isBusinessLineCostCenterRelationEnabled$(): Observable<Boolean> {
    return this.isBusinessLineCostCenterRelationEnabled$$.asObservable().pipe(
      filter((value) => value !== null),
      share(),
    );
  }

  get userActionsList$(): Observable<(0 | 1)[]> {
    return this.userActionsList$$.asObservable().pipe(
      filter((value) => value !== null),
      map((values) => values && values.map((value) => value)),
      share(),
    );
  }

  get isSalesOrderApiEnabled$(): Observable<Boolean> {
    return this.isSalesOrderApiEnabled$$.asObservable().pipe(
      filter((value) => value !== null),
      share(),
    );
  }

  get isMaintenanceOrderApiEnabled$(): Observable<Boolean> {
    return this.isMaintenanceOrderApiEnabled$$.asObservable().pipe(
      filter((value) => value !== null),
      share(),
    );
  }

  private loadUserRole() {
    const request = new UserDetailsForIntercomRequest();
    (this.grpcService.invoke$(UserAPI.UserDetailsForIntercom, request) as Observable<
      UserDetailsForIntercomResponse
    >).subscribe((response) => {
      this.userRole$$.next(response.toObject().userDetails.role);
      this.isFirstTimeUser$$.next(response.toObject().userDetails.isFirstTimeLogin);
      this.isBusinessLineEnabled$$.next(response.toObject().userDetails.isBusinessLineEnabled);
      this.businessLinesList$$.next(response.getUserDetails().getBusinessLinesList());
      this.isSsoUser$$.next(response.toObject().userDetails.isSsoUser);
      this.isBusinessLineCostCenterRelationEnabled$$.next(
        response.toObject().userDetails.isBusinessLineCostCenterRelationEnabled,
      );
      this.userActionsList$$.next(response.toObject().userDetails.userActionsList);
      this.isSalesOrderApiEnabled$$.next(response.toObject().userDetails.isSalesOrderApiEnabled);
      this.isMaintenanceOrderApiEnabled$$.next(response.toObject().userDetails.isMaintenanceOrderApiEnabled);
    });
  }

  public updatePassword$(password: string): Observable<ResetPasswordOnFirstTimeLoginResponse> {
    const user = this.authService.getUserFromCache();
    const request = new ResetPasswordOnFirstTimeLoginRequest();
    request.setEmail(user.email);
    request.setPassword(password);
    return (this.grpcService.invoke$(UserAPI.ResetPasswordOnFirstTimeLogin, request) as Observable<
      ResetPasswordOnFirstTimeLoginResponse
    >).pipe(tap(() => this.loadUserRole()));
  }

  public updateUserActions$(): Observable<boolean> {
    const request = new TriggerUserActionRequest();
    request.setActionType(1);
    return this.grpcService
      .invoke$(UserAPI.TriggerUserAction, request)
      .pipe(map((response: TriggerUserActionResponse) => response.toObject().status));
  }
}
