import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { differenceInHours } from 'date-fns';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';
import { OrdersService } from 'src/app/lmo/services/orders.service';
import { ContractsService } from '~lmo/services/contracts.service';
import { createCustomOrderRequestFactory, CreateOrderFormValue, CustomStop } from '~lmo/utilities/create-order';
import { BusinessLine } from '~proto/businessline/businessline_pb';
import { VendorContract, VendorContractType } from '~proto/contracts/contracts_pb';
import { DetentionStats } from '~proto/order/order_pb';
import { Payload } from '~proto/payload/payload_pb';
import { OrderType } from '~proto/types/types_pb';
import { UserDetailsForIntercomRequest, UserDetailsForIntercomResponse } from '~proto/user/user_api_pb';
import { UserAPI } from '~proto/user/user_api_pb_service';
import { ConstantsService } from '~services/constants.service';
import { GrpcService } from '~services/grpc.service';
import { RoleService } from '~services/role.service';
import { trackById } from '~utilities/trackById';
import { CreateOrderRecentsService } from '../../services/create-order-recents.service';
import { CreateOrderCustomStops2Component } from '../create-order-custom-stops2/create-order-custom-stops2.component';

export type PayloadOnlyFormValue = Pick<
  CreateOrderFormValue,
  | 'costCenter'
  | 'description'
  | 'isAsapOrder'
  | 'loads'
  | 'orderNumber'
  | 'purchaseOrderName'
  | 'scheduledDeliveryWindow'
  | 'truckingVendor'
  | 'vendorContractId'
  | 'contractTypeId'
  | 'costCenterId'
  | 'businessLine'
  | 'maintenanceOrderNumber'
>;

@Component({
  selector: 'ct-create-order-custom-type',
  styleUrls: ['../create-order/create-order.component.scss'],
  templateUrl: './create-order-custom-type.component.html',
})
export class CreateOrderCustomTypeComponent implements OnInit {
  @ViewChild(CreateOrderCustomStops2Component, { static: false })
  public customStopsComp: CreateOrderCustomStops2Component;
  public customStops = new FormControl();
  public showDetentionWarning$: Observable<boolean>;
  public networkActive$ = new BehaviorSubject<boolean>(false);
  public formGroup: FormGroup;
  public stats: DetentionStats.AsObject;
  public payloads$: Observable<Payload.AsObject[]>;
  public truckingContracts: VendorContract.AsObject[] = [];
  public trackById = trackById;

  public contractTypes$: Observable<VendorContractType.AsObject[]>;
  public contractType$: Observable<VendorContractType.AsObject>;
  public businessLinesList$$ = new BehaviorSubject<BusinessLine.AsObject[]>(null);
  public isBusinessLineEnabled$$ = new BehaviorSubject<Boolean>(false);
  public isCostCenterRequired$$ = new BehaviorSubject<Boolean>(false);
  public isPurchaseOrderRequired$$ = new BehaviorSubject<Boolean>(false);
  public isSalesOrderRequired$$ = new BehaviorSubject<Boolean>(false);
  public isMaintenanceOrderRequired$$ = new BehaviorSubject<Boolean>(false);
  public isBusinessLineCostCenterRelationEnabled$$ = new BehaviorSubject<Boolean>(false);
  private isCCValid: string;
  private isPOValid: string;
  private isSOValid: boolean;
  private isMOValid: boolean;

  constructor(
    private orderService: OrdersService,
    private fb: FormBuilder,
    private router: Router,
    private recentService: CreateOrderRecentsService,
    private snackBar: MatSnackBar,
    private contractService: ContractsService,
    // Leave this here so we make sure all the constants are loaded
    private _constantsService: ConstantsService, // tslint-ignore-line
    private roleService: RoleService,
    private grpcService: GrpcService,
  ) {}

  public ngOnInit() {
    this.getStats();
    this.loadRequiredFieldSettings();

    this.formGroup = this.fb.group({
      businessLine: [null],
      contractTypeId: [null],
      costCenter: [null],
      costCenterId: [null],
      description: [null, []],
      isAsapOrder: [null, Validators.required],
      loads: [null, Validators.compose([Validators.required, Validators.min(1), Validators.max(50)])],
      scheduledDeliveryWindow: [null, [Validators.required, dateNotNull]],
      truckingVendor: [null],
      vendorContractId: [null],
    });

    this.recentService.loadSalesOrder();

    this.listenToAsap();
    this.listenToWarnings();
    this.setupPayloads();

    this.listenContractTypeChanges();
    this.listenContractChange();
    this.checkIfBusinessLineEnabled();

    this.checkIsBusinessLineCostCenterRelationEnabled();

    this.formGroup.get('businessLine').valueChanges.subscribe(() => {
      this.formGroup.get('costCenter').setValue(null);
      this.formGroup.get('costCenterId').setValue(null);
    });
  }

  private listenContractTypeChanges() {
    this.contractType$ = this.formGroup.get('contractTypeId').valueChanges;
    this.contractTypes$ = this.contractService.contractTypes$;

    combineLatest([
      this.formGroup.get('contractTypeId').valueChanges,
      this.contractService.nonExpiredContracts$,
    ]).subscribe(([contractTypeId, contracts]) => {
      const contractTypeContracts = contracts.filter((contract) => {
        if (contract.contractType) {
          return +contractTypeId <= 0 || contract.contractType.id === +contractTypeId;
        }
        return +contractTypeId <= 0;
      });
      const vendor = this.formGroup.get('truckingVendor').value;
      if (vendor) {
        const vendorContract = contractTypeContracts.find((contract) => contract.vendor.id === vendor.id);
        if (!(vendorContract && vendorContract.id > 0)) {
          this.formGroup.get('truckingVendor').setValue(null);
        }
      }
    });
  }

  private listenContractChange() {
    combineLatest([
      this.formGroup.get('truckingVendor').valueChanges,
      this.formGroup.get('contractTypeId').valueChanges.pipe(startWith(-1)),
      this.contractService.nonExpiredContracts$,
    ]).subscribe(([vendor, contractTypeId, contracts]) => {
      const contractTypeContracts = contracts.filter((contract) => {
        if (contract.contractType) {
          return +contractTypeId <= 0 || contract.contractType.id === +contractTypeId;
        }
        return +contractTypeId <= 0;
      });
      const contractId = this.formGroup.get('vendorContractId').value;
      if (contractId) {
        const contractById = contractTypeContracts.find((contract) => contract.id === +contractId);
        if (!(contractById && contractById.id > 0)) {
          this.formGroup.get('vendorContractId').setValue(null);
        }
      }
      if (vendor) {
        this.truckingContracts = contracts.filter(
          (contract) =>
            contract.vendor.id === vendor.id &&
            contract.isEffective &&
            (contractTypeId <= 0 || (contract.contractType && contract.contractType.id === contractTypeId)),
        );
      }
    });

    combineLatest([
      this.formGroup.get('vendorContractId').valueChanges,
      this.contractService.nonExpiredContracts$,
    ]).subscribe(([contractId, contracts]) => {
      if (contractId) {
        const contractById = contracts.find((contract) => contract.id === +contractId);
        this.formGroup.get('contractTypeId').setValue(contractById.contractType ? contractById.contractType.id : -1);
      }
    });
  }

  public selected(event: any) {
    if (event.pickupFrom && event.pickupFrom.direction) {
      event.pickupFrom.directions = event.pickupFrom.direction;
    }
    console.log('event is', event);
    this.formGroup.patchValue(event);
    console.log('form group value is', this.formGroup.value);
  }

  public async create() {
    const valueFromForm = this.formValue();
    const formValue: PayloadOnlyFormValue = valueFromForm;
    const customStopsValue = this.customStopsValue();
    this.requiredFieldsCheck(formValue, customStopsValue);

    if (!this.isCCValid || !this.isPOValid || !this.isSOValid || !this.isMOValid) {
      this.snackBar.open(
        `
        ${this.isCostCenterRequired$$.value && !formValue.costCenter ? 'Cost Center, ' : ''}
        ${this.isPurchaseOrderRequired$$.value && !customStopsValue[0].purchaseOrderName ? 'Purchase Order Name, ' : ''}
        ${!this.isSOValid ? 'Sales Order Number' : ''}
        ${!this.isMOValid ? 'Maintenance Order Number' : ''}
        fields are mandatory`,
        null,
        {
          duration: 3000,
        },
      );
      return;
    }

    const createOrderRequest = createCustomOrderRequestFactory(formValue, customStopsValue);
    const payloadSummary = this.customStopsComp.validationErrors;

    if (payloadSummary && payloadSummary.invalidTotals) {
      this.snackBar.open(payloadSummary.invalidTotals, null, {
        duration: 3000,
      });
      return;
    }

    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    if (!this.customStopsComp.customStops.valid) {
      return;
    }

    this.networkActive$.next(true);
    createOrderRequest.setType(OrderType.ORDER_TYPE_CUSTOM);

    this.orderService
      .createOrder$(createOrderRequest)
      .pipe(
        finalize(() => {
          this.networkActive$.next(false);
        }),
      )
      .subscribe((order) => {
        this.snackBar.open('Load successfully scheduled!', null, {
          duration: 3000,
        });
        this.router.navigate([
          '/',
          'lmo',
          'jobs',
          createOrderRequest.getTaskRequestsList()[createOrderRequest.getTaskRequestsList().length - 1].getSiteId(),
          'orders',
          'pending',
          order.orderId,
        ]);
      });
  }

  private requiredFieldsCheck(formValue: PayloadOnlyFormValue, customStopsValue: CustomStop[]) {
    this.isCCValid = this.isCostCenterRequired$$.value ? formValue.costCenter : 'true';
    this.isPOValid = this.isPurchaseOrderRequired$$.value ? customStopsValue[0].purchaseOrderName : 'true';
    this.isSOValid = this.isSalesOrderRequired$$.value ? this.checkDropOffSO(customStopsValue) : true;
    this.isMOValid = this.isMaintenanceOrderRequired$$.value ? this.checkDropOffMO(customStopsValue) : true;
  }

  private checkDropOffSO(customStopsValue) {
    return (
      customStopsValue &&
      customStopsValue.map((customStop) => {
        if (customStop.stopType === 1 && customStop.salesOrderNumber === null) {
          return false;
        }
        return true;
      })
    );
  }

  private checkDropOffMO(customStopsValue) {
    return (
      customStopsValue &&
      customStopsValue.map((customStop) => {
        if (customStop.stopType === 1 && customStop.maintenanceOrderNumber === null) {
          return false;
        }
        return true;
      })
    );
  }

  private formValue(): CreateOrderFormValue {
    return this.formGroup.value as CreateOrderFormValue;
  }

  private customStopsValue(): CustomStop[] {
    return this.customStops.value;
  }

  private getStats() {
    this.orderService.getStats$().subscribe((stats: DetentionStats.AsObject) => {
      this.stats = stats;
    });
  }

  private listenToAsap() {
    this.formGroup
      .get('isAsapOrder')
      .valueChanges.pipe(startWith(this.formGroup.get('isAsapOrder').value))
      .subscribe((value: boolean) => {
        if (value) {
          this.formGroup.get('scheduledDeliveryWindow').disable();
          this.formGroup.get('scheduledDeliveryWindow').reset();
        } else {
          this.formGroup.get('scheduledDeliveryWindow').enable();
          if (this.formGroup.get('loads').value && this.formGroup.get('loads').value > 1) {
          }
        }
      });
  }

  private setupPayloads() {
    this.payloads$ = this.customStops.valueChanges.pipe(
      startWith([]),
      map((stops: CustomStop[]) => stops.filter((stop) => stop.payload).map((stop) => stop.payload)),
    );
  }

  private listenToWarnings() {
    this.showDetentionWarning$ = this.formGroup.valueChanges.pipe(
      map((formValue: CreateOrderFormValue) => {
        if (
          !formValue.isAsapOrder &&
          formValue.scheduledDeliveryWindow &&
          formValue.scheduledDeliveryWindow.length > 1
        ) {
          if (differenceInHours(formValue.scheduledDeliveryWindow[1], formValue.scheduledDeliveryWindow[0]) < 3) {
            return true;
          }
        }
        return false;
      }),
    );
  }

  private businessLinesList() {
    this.roleService.businessLinesList$.subscribe((businessLines: BusinessLine.AsObject[]) => {
      this.businessLinesList$$.next(businessLines);
      if (businessLines.length === 1) {
        this.formGroup.controls['businessLine'].setValue(businessLines[0].id);
      }
    });
  }

  private checkIfBusinessLineEnabled() {
    this.roleService.isBusinessLineEnabled$.subscribe((isBusinessLineEnabled) => {
      this.isBusinessLineEnabled$$.next(isBusinessLineEnabled);
      if (isBusinessLineEnabled) {
        this.businessLinesList();
      }
    });
  }

  private checkIsBusinessLineCostCenterRelationEnabled() {
    this.roleService.isBusinessLineCostCenterRelationEnabled$.subscribe((isBLCCRelationEnabled: Boolean) => {
      this.isBusinessLineCostCenterRelationEnabled$$.next(isBLCCRelationEnabled);
    });
  }

  private loadRequiredFieldSettings() {
    const request = new UserDetailsForIntercomRequest();
    (this.grpcService.invoke$(UserAPI.UserDetailsForIntercom, request) as Observable<
      UserDetailsForIntercomResponse
    >).subscribe((response) => {
      this.isCostCenterRequired$$.next(response.toObject().userDetails.isCostCenterRequired);
      this.isPurchaseOrderRequired$$.next(response.toObject().userDetails.isPurchaseOrderRequired);
      this.isSalesOrderRequired$$.next(response.toObject().userDetails.isSalesOrderRequired);
      this.isMaintenanceOrderRequired$$.next(response.toObject().userDetails.isMaintenanceOrderRequired);
    });
  }
}

function dateNotNull(formControl: FormControl) {
  if (!formControl.value) {
    return null;
  }
  const arrayValue = formControl.value as any[];
  const filtered = arrayValue.filter((value) => !!value);

  if (arrayValue.length !== filtered.length) {
    return { invalidDate: 'Invalid Date' };
  }
  return null;
}
