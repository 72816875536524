import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { crowFliesDistance } from '~utilities/distance';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { trackById } from '~utilities/trackById';
import { CreateJobComponent } from '../create-job/create-job.component';
var CreateOrderSiteSelectorComponent = /** @class */ (function () {
    function CreateOrderSiteSelectorComponent(sites, matDialog) {
        this.sites = sites;
        this.matDialog = matDialog;
        this.withoutSiteIds$$ = new BehaviorSubject([]);
        this.trackById = trackById;
        this.selected = new EventEmitter();
        this.invalid = false;
        this.tabIndex = '';
        this.label = 'pickup from';
        this.disabled = false;
        this.textChanges$$ = new BehaviorSubject(null);
    }
    Object.defineProperty(CreateOrderSiteSelectorComponent.prototype, "withoutSiteIds", {
        set: function (siteIds) {
            this.withoutSiteIds$$.next(siteIds || []);
        },
        enumerable: true,
        configurable: true
    });
    CreateOrderSiteSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searching$ = this.sites.searching$;
        var searchResults$ = combineLatest([
            this.sites.sites$,
            this.textChanges$$.pipe(debounceTime(200)).pipe(switchMap(function (searchText) {
                if (searchText && searchText.length >= 2) {
                    return _this.sites.searchForSite$(searchText);
                }
                return of([]);
            })),
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), sites = _b[0], searchResults = _b[1];
            if (!sites) {
                return searchResults;
            }
            var siteMap = idArrayToRecord(sites);
            return searchResults.filter(function (site) { return !site.archived && !siteMap[site.id]; });
        }));
        this.sitesWithHeaders$ = combineLatest([
            this.sites.sites$,
            this.withoutSiteIds$$,
            this.textChanges$$.pipe(debounceTime(100), startWith('')),
            searchResults$,
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 4), sites = _b[0], bannedSiteIds = _b[1], text = _b[2], searchResults = _b[3];
            if (!sites) {
                return [];
            }
            var sitesAfterFilteringSkippedSites = sites.filter(function (site) { return !bannedSiteIds.includes(site.id); });
            var pinned = [];
            var recent = [];
            sitesAfterFilteringSkippedSites.forEach(function (site) {
                if (site.favorited) {
                    pinned.push(site);
                }
                else {
                    recent.push(site);
                }
            });
            var sitesWithHeaders = [
                {
                    name: 'Pinned',
                    sitesList: pinned,
                },
                {
                    name: 'Recent',
                    sitesList: recent,
                },
            ];
            if (!text || text === '') {
                _this.topResult = sitesAfterFilteringSkippedSites.length ? sitesAfterFilteringSkippedSites[0] : null;
                return sitesWithHeaders;
            }
            var asLowercase = text.toLocaleLowerCase();
            var cleanedItems = sitesWithHeaders.map(function (item) {
                var filteredSites = item.sitesList.filter(function (site) {
                    return site.name.toLocaleLowerCase().includes(asLowercase);
                });
                return tslib_1.__assign({}, item, { sitesList: filteredSites });
            });
            var mergedGroups = tslib_1.__spread(cleanedItems, [
                {
                    name: 'Search Results',
                    sitesList: searchResults,
                },
            ]);
            var filtered = mergedGroups.filter(function (item) { return item.sitesList.length > 0; });
            if (filtered.length && filtered[0].sitesList.length) {
                _this.topResult = filtered[0].sitesList[0];
            }
            else {
                _this.topResult = null;
            }
            return filtered;
        }));
        this.canAddNew$ = combineLatest([this.sitesWithHeaders$, this.textChanges$$.pipe(startWith(''))]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), currentSitesPayload = _b[0], textChange = _b[1];
            if (!textChange || textChange === '') {
                return false;
            }
            var asLower = textChange.toLocaleLowerCase();
            return currentSitesPayload.every(function (group) {
                return group.sitesList.every(function (payload) { return !payload.name.toLocaleLowerCase().includes(asLower); });
            });
        }));
    };
    CreateOrderSiteSelectorComponent.prototype.togglePin = function (event, siteID, isFavorited) {
        event.stopPropagation();
        this.sites.setFavorite(isFavorited, siteID);
    };
    CreateOrderSiteSelectorComponent.prototype.inputTextChange = function (event) {
        this.textChanges$$.next(event.trim());
    };
    // calculate as the crow flies distance
    CreateOrderSiteSelectorComponent.prototype.distance = function (lat1, lon1, lat2, lon2) {
        return crowFliesDistance(lat1, lon1, lat2, lon2);
    };
    CreateOrderSiteSelectorComponent.prototype.selectSite = function (pickupFrom) {
        this.selected.emit({ pickupFrom: pickupFrom });
        this.smartDropdown.close();
    };
    CreateOrderSiteSelectorComponent.prototype.enterKeyPressed = function () {
        if (this.topResult) {
            this.selectSite(this.topResult);
        }
        else {
            // this.createNewSite();
        }
    };
    CreateOrderSiteSelectorComponent.prototype.addOrderSite = function () {
        var _this = this;
        var dialog = this.matDialog.open(CreateJobComponent, {
            minHeight: '100%',
            minWidth: '100%',
        });
        dialog.componentInstance.modalInstance = true;
        dialog.componentInstance.jobName = this.textChanges$$.value;
        dialog.afterClosed().subscribe(function (result) {
            if (result) {
                _this.selectSite(result);
            }
        });
    };
    return CreateOrderSiteSelectorComponent;
}());
export { CreateOrderSiteSelectorComponent };
