import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'ct-map',
  styleUrls: ['./map.component.scss'],
  templateUrl: './map.component.html',
})
export class MapComponent implements OnInit, AfterViewInit {
  @ViewChild('map', { static: false }) private mapElement: ElementRef;
  public map: google.maps.Map;
  public mineMarker: google.maps.Marker;

  @Input() set mapLatitude(value: number) {
    this.latitude = value;
    this.initMap();
  }

  @Input() set mapLongitude(value: number) {
    this.longitude = value;
    this.initMap();
  }

  public latitude = 39.7392;
  public longitude = -104.9903;

  constructor() {}

  public ngOnInit() {}

  public ngAfterViewInit(): void {
    this.initMap();
  }

  private initMap() {
    if (this.map == null) {
      const center = {
        lat: this.latitude,
        lng: this.longitude,
      };
      const mapConfig = {
        center: center,
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: 3,
        },
        mapTypeId: google.maps.MapTypeId.HYBRID,
        streetViewControl: false,
        zoom: 12,
        zoomControlOptions: {
          position: 3,
        },
      };

      if (this.mapElement != null) {
        this.map = new google.maps.Map(this.mapElement.nativeElement, mapConfig);
        this.mineMarker = new google.maps.Marker({
          animation: google.maps.Animation.DROP,
          draggable: false,
          map: this.map,
          position: this.map.getCenter(),
        });
      }
    } else {
      this.map.setCenter({
        lat: +this.latitude,
        lng: +this.longitude,
      });
      this.mineMarker.setPosition(this.map.getCenter());
    }
  }
}
